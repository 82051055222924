import {
  TalentPoolJobCandidateItemBffID,
  FetchTalentPoolJobCandidatesBffURL,
  FetchTalentPoolJobCandidateBffURL,
  TalentPoolJobCandidateItemBffUuid
} from './talentPoolJobCandidatesTypes';

import { BaseBffRoutes } from '../BaseBffRoutes';

export class TalentPoolJobCandidatesBffRoutes extends BaseBffRoutes {
  protected static route = 'talentPoolJobCandidates';

  static fetchTalentPoolJobCandidatesRoute() {
    return this.fetchItemsRoute<FetchTalentPoolJobCandidatesBffURL>();
  }

  static fetchTalentPoolJobCandidatesIndexRoute() {
    return this.fetchItemsIndexRoute<FetchTalentPoolJobCandidatesBffURL>();
  }

  static fetchTalentPoolJobCandidateRoute(
    talentPoolItemBffID: TalentPoolJobCandidateItemBffID
  ) {
    return this.fetchItemRoute<FetchTalentPoolJobCandidateBffURL>(
      talentPoolItemBffID
    );
  }

  static fetchTalentPoolJobCandidateIndexRoute(
    talentPoolItemBffUuid: TalentPoolJobCandidateItemBffUuid
  ) {
    return this.fetchItemIndexRoute<FetchTalentPoolJobCandidateBffURL>(
      talentPoolItemBffUuid
    );
  }
}
