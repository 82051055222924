import {
  CertificationItemBffID,
  CertificationItemBffNanoID,
  FetchCertificationsBffURL,
  FetchCertificationBffURL,
  FetchCertificationByNanoIdBffURL,
  CreateCertificationBffURL,
  UpdateCertificationBffURL,
  DeleteCertificationBffURL
} from './certificationsTypes';

import { BaseBffRoutes } from '../BaseBffRoutes';

export class CertificationsBffRoutes extends BaseBffRoutes {
  protected static route = 'certifications';

  static fetchCertificationsRoute() {
    return this.fetchItemsRoute<FetchCertificationsBffURL>();
  }

  static fetchCertificationsIndexRoute() {
    return this.fetchItemsIndexRoute<FetchCertificationsBffURL>();
  }

  static fetchCertificationRoute(
    certificationItemBffID: CertificationItemBffID
  ) {
    return this.fetchItemRoute<FetchCertificationBffURL>(
      certificationItemBffID
    );
  }

  static fetchCertificationIndexRoute(
    certificationItemBffNanoID: CertificationItemBffNanoID
  ) {
    return this.fetchItemIndexRoute<FetchCertificationBffURL>(
      certificationItemBffNanoID
    );
  }

  static fetchCertificationByNanoIdRoute(
    certificationItemBffNanoID: CertificationItemBffNanoID
  ) {
    return this.fetchItemByNanoIdRoute<FetchCertificationByNanoIdBffURL>(
      certificationItemBffNanoID
    );
  }

  static createCertificationRoute() {
    return this.createItemRoute<CreateCertificationBffURL>();
  }

  static updateCertificationRoute(
    certificationItemBffNanoID:
      | CertificationItemBffID
      | CertificationItemBffNanoID
  ) {
    return this.updateItemRoute<UpdateCertificationBffURL>(
      certificationItemBffNanoID
    );
  }

  static deleteCertificationRoute(
    certificationItemBffNanoID: CertificationItemBffNanoID
  ) {
    return this.deleteItemRoute<DeleteCertificationBffURL>(
      certificationItemBffNanoID
    );
  }
}
