import { usePaginatedItems } from '../../../common/hooks/usePaginatedItems';

import {
  FetchCountriesQuery,
  FetchCountriesCacheKey,
  FetchCountriesTotalCount,
  FetchCountriesPage,
  FetchCountriesPageSize,
  FetchCountriesError,
  FetchCountriesErrorMessage,
  FetchCountriesIsLoading,
  FetchCountriesIsFetched,
  FetchCountriesIsPlaceholderData,
  FetchCountriesEnabled,
  FetchCountriesPrefetchNextPage,
  FetchCountriesRefetchInterval,
  FetchCountriesCountType,
  FetchCountriesFilters,
  FetchCountriesSort,
  FetchCountryID,
  FetchCountryQuery,
  FetchCountryCacheKey
} from '../../countriesTypes';

import { CountriesBffRequests } from '../../CountriesBffRequests';

interface PaginatedCountriesBaseOptions<PaginatedCountriesResponseType> {
  cacheKey: FetchCountriesCacheKey;
  query: FetchCountriesQuery;
  countType?: FetchCountriesCountType;
  initialPage?: FetchCountriesPage;
  initialPageSize?: FetchCountriesPageSize;
  initialFilters?: FetchCountriesFilters;
  initialSort?: FetchCountriesSort;
  enabled?: FetchCountriesEnabled;
  prefetchNextPage?: FetchCountriesPrefetchNextPage;
  refetchInterval?: FetchCountriesRefetchInterval;
  placeholderCountries?: PaginatedCountriesResponseType[];
  placeholderCountriesCount?: FetchCountriesTotalCount;
  placeholderData?: () => PaginatedCountriesResponse<PaginatedCountriesResponseType>;
}

interface PaginatedCountriesOptionsWithoutItem {
  fetchItemCacheKey?: never;
  itemQuery?: never;
}

interface PaginatedCountriesOptionsWithItem {
  fetchItemCacheKey: FetchCountryCacheKey;
  itemQuery: FetchCountryQuery;
}

type PaginatedCountriesOptions<PaginatedCountriesResponseType> =
  PaginatedCountriesBaseOptions<PaginatedCountriesResponseType> &
    (PaginatedCountriesOptionsWithoutItem | PaginatedCountriesOptionsWithItem);

interface PaginatedCountriesResponse<PaginatedCountriesResponseType> {
  data: PaginatedCountriesResponseType[];
  totalCount: FetchCountriesTotalCount;
}

function usePaginatedCountries<PaginatedCountriesResponseType>({
  cacheKey,
  query,
  countType,
  initialPage,
  initialPageSize,
  initialFilters,
  initialSort,
  enabled,
  prefetchNextPage,
  refetchInterval,
  placeholderCountries,
  placeholderCountriesCount,
  placeholderData,
  fetchItemCacheKey,
  itemQuery
}: PaginatedCountriesOptions<PaginatedCountriesResponseType>) {
  const {
    items,
    itemsTotalCount,
    itemsError,
    itemsErrorMessage,
    itemsIsFetched,
    itemsIsLoading,
    itemsIsPlaceholderData,
    itemsCurrentPage,
    itemsCurrentPageSize,
    itemsCurrentFilters,
    itemsCurrentSort,
    fetchItems,
    paginateItems,
    changeItemsPageSize,
    filterItems,
    changeItemsFilters,
    clearItemsFilters,
    sortItems,
    prefetchItems,
    prefetchItem
  } = usePaginatedItems<PaginatedCountriesResponseType>({
    cacheKey,
    initialPage,
    initialPageSize,
    initialFilters,
    initialSort,
    enabled,
    prefetchNextPage,
    refetchInterval,
    placeholderItems: placeholderCountries,
    placeholderItemsCount: placeholderCountriesCount,
    placeholderData,
    queryFn: (params) =>
      CountriesBffRequests.fetchCountries<PaginatedCountriesResponseType>({
        query,
        countType,
        page: params.page as FetchCountriesPage,
        pageSize: params.pageSize as FetchCountriesPageSize,
        filters: params.filters as FetchCountriesFilters,
        sort: params.sort as FetchCountriesSort
      }),
    ...(itemQuery
      ? {
          fetchItemCacheKey,
          queryItemFn: (itemId) =>
            CountriesBffRequests.fetchCountry(itemId as FetchCountryID, {
              query: itemQuery
            })
        }
      : {})
  });

  return {
    countries: items as PaginatedCountriesResponseType[],
    countriesTotalCount: itemsTotalCount as FetchCountriesTotalCount,
    countriesError: itemsError as FetchCountriesError,
    countriesErrorMessage: itemsErrorMessage as FetchCountriesErrorMessage,
    countriesIsLoading: itemsIsLoading as FetchCountriesIsLoading,
    countriesIsFetched: itemsIsFetched as FetchCountriesIsFetched,
    countriesIsPlaceholderData:
      itemsIsPlaceholderData as FetchCountriesIsPlaceholderData,
    countriesCurrentPage: itemsCurrentPage as FetchCountriesPage,
    countriesCurrentPageSize: itemsCurrentPageSize as FetchCountriesPageSize,
    countriesCurrentFilters: itemsCurrentFilters as FetchCountriesFilters,
    countriesCurrentSort: itemsCurrentSort as FetchCountriesSort,
    fetchCountries: fetchItems,
    paginateCountries: paginateItems,
    changeCountriesPageSize: changeItemsPageSize,
    filterCountries: filterItems,
    changeCountriesFilters: changeItemsFilters,
    clearCountriesFilters: clearItemsFilters,
    sortCountries: sortItems,
    prefetchCountries: prefetchItems,
    prefetchCountry: prefetchItem
  };
}

export default usePaginatedCountries;
