import { ReactNode } from 'react';
import { Control, FieldPath, FieldValues } from 'react-hook-form';
import debounce from 'lodash/debounce';

import { ErrorMessage, IsDisabled, IsRequired } from '../../../../../types';

import { JobFields, JobCompanyIds } from '../../../jobsTypes';

import {
  FetchCompaniesPageSize,
  FetchCompaniesSort,
  CompanyFields
} from '../../../../companies/companiesTypes';

import {
  fetchCompaniesQuery,
  FetchCompaniesResponse
} from '../../../../companies/queries/fetchCompanies.query';

import { usePaginatedCompanies } from '../../../../companies/hooks/usePaginatedCompanies';

import { CompaniesCache } from '../../../../companies/CompaniesCache';

import { MultiSelectField } from '../../../../../helpers/forms/formFields/MultiSelectField';

import { ImagesUrl } from '../../../../images/ImagesUrl';

import { JobSelectCompaniesFieldJob } from './JobSelectCompaniesField.types';

interface JobSelectCompaniesFieldProps<T extends FieldValues> {
  isRequired?: IsRequired;
  isDisabled?: IsDisabled;
  control: Control<T>;
  job?: JobSelectCompaniesFieldJob | null;
  currentJobCompanyIds?: JobCompanyIds;
  errorMessage: ErrorMessage;
  labelAddon?: ReactNode;
}

function JobSelectCompaniesField<T extends FieldValues>({
  isRequired,
  isDisabled,
  control,
  job,
  errorMessage,
  labelAddon
}: JobSelectCompaniesFieldProps<T>) {
  const {
    companies,
    companiesIsLoading,
    companiesErrorMessage,
    changeCompaniesFilters
  } = usePaginatedCompanies<FetchCompaniesResponse>({
    query: fetchCompaniesQuery,
    cacheKey: CompaniesCache.postJobCacheKey(),
    initialPageSize: 50 as FetchCompaniesPageSize,
    initialSort: {
      [CompanyFields.ID]: { ascending: false }
    } as unknown as FetchCompaniesSort
  });

  // useEffect(() => {
  //   if (!currentJobCompaniesId && job?.companies?.name) {
  //     changeCompaniesFilters({
  //       [CompanyFields.NAME]: {
  //         operator: 'ilike',
  //         value: `%${job?.companies?.name}%`
  //       }
  //     });
  //   }
  // }, [currentJobCompaniesId, job?.companies?.name, changeCompaniesFilters]);

  const defaultJobCompanies = job?.companies
    ? job?.companies.map((company) => ({
        value: company.id,
        label: company.name,
        image: ImagesUrl.file(company.image)
      }))
    : undefined;

  const debouncedFilterCompanies = debounce<(updatedValue: string) => void>(
    (updatedValue) =>
      changeCompaniesFilters({
        [CompanyFields.NAME]: {
          operator: 'ilike',
          value: `%${updatedValue}%`
        }
      }),
    500
  );

  return (
    <MultiSelectField
      withImages
      isRequired={isRequired}
      isDisabled={isDisabled}
      control={control}
      label="Competitor companies"
      labelAddon={labelAddon}
      placeholder="Select company"
      name={JobFields.COMPANY_IDS as FieldPath<T>}
      options={companies.map((company) => ({
        value: company.id,
        label: company.name,
        image: ImagesUrl.file(company.image)
      }))}
      defaultValue={defaultJobCompanies}
      errorMessage={errorMessage || companiesErrorMessage}
      isLoading={companiesIsLoading}
      onInputChange={debouncedFilterCompanies}
    />
  );
}

export default JobSelectCompaniesField;
