import { Icon, IconProps } from '@chakra-ui/icon';

const MenuIconScreening = ({
  w = '24px',
  h = '24px',
  fill = 'gray.500',
  ...props
}: IconProps) => (
  <Icon viewBox="0 0 24 24" w={w} h={h} fill={fill} {...props}>
    <path fill="#AB57B2" d="M18 12a6 6 0 10-12 0 6 6 0 0012 0z" />
  </Icon>
);

export default MenuIconScreening;
