import { JobNanoId } from './jobsTypes';

import { JobCandidateNanoId } from '../jobCandidates/jobCandidatesTypes';

import { JobResumeNanoId } from '../jobResumes/jobResumesTypes';
import { TalentPoolNanoId } from '../talentPools/talentPoolsTypes';
import { PipelineRoutes } from '../pipeline/PipelineRoutes';

const baseJobPath = '/hire';
const baseKanbanPath = PipelineRoutes.kanban();
const basePipelinePath = PipelineRoutes.index();

export class JobsRoutes {
  static index() {
    return `${baseJobPath}/jobs`;
  }

  static hire() {
    return baseJobPath;
  }

  static inactive() {
    return `${baseJobPath}/inactive`;
  }

  static screening() {
    return `${baseJobPath}/screening`;
  }

  static pipeline() {
    return `${basePipelinePath}`;
  }

  static talentPool() {
    return `${baseJobPath}/talent-pool`;
  }

  static viewTalentPool(poolNanoId: TalentPoolNanoId) {
    return `${baseJobPath}/talent-pool/${poolNanoId}`;
  }
  static newTalentPool(poolNanoId: TalentPoolNanoId) {
    return `${baseJobPath}/talent-pool/${poolNanoId}/new`;
  }

  static getTalentPool() {
    return `${baseJobPath}/get-candidates`;
  }

  static quickActions() {
    return `${baseJobPath}/quick-actions`;
  }

  static post() {
    return `${baseJobPath}/post`;
  }

  static previewJob(jobNanoId: JobNanoId) {
    return `${baseKanbanPath}/preview/${jobNanoId}`;
  }

  static postTalents(jobNanoId: JobNanoId) {
    return `${baseJobPath}/post/${jobNanoId}/talents`;
  }

  static postDescription(jobNanoId: JobNanoId) {
    return `${baseJobPath}/post/${jobNanoId}/description`;
  }

  static postDetails(jobNanoId: JobNanoId) {
    return `${baseJobPath}/post/${jobNanoId}/details`;
  }

  static postSelection(jobNanoId: JobNanoId) {
    return `${baseJobPath}/post/${jobNanoId}/selection`;
  }

  static postIntake(jobNanoId: JobNanoId) {
    return `${baseJobPath}/post/${jobNanoId}/intake`;
  }

  static postScreening(jobNanoId: JobNanoId) {
    return `${baseJobPath}/post/${jobNanoId}/screening`;
  }

  static postAJob(jobNanoId: JobNanoId) {
    return `${baseJobPath}/post/${jobNanoId}/postAJob`;
  }

  static postWorkflow(jobNanoId: JobNanoId) {
    return `${baseJobPath}/post/${jobNanoId}/workflow`;
  }

  static postAutomation(jobNanoId: JobNanoId) {
    return `${baseJobPath}/post/${jobNanoId}/automation`;
  }

  static postJobCandidates(jobNanoId: JobNanoId) {
    return `${baseJobPath}/post/${jobNanoId}/candidates`;
  }

  static postJobCandidatesList(jobNanoId: JobNanoId) {
    return `${baseJobPath}/post/${jobNanoId}/candidates/list`;
  }

  static postJobCandidatesValidation(jobNanoId: JobNanoId) {
    return `${baseJobPath}/post/${jobNanoId}/candidates/validation`;
  }

  static postCandidatesLead(jobNanoId: JobNanoId) {
    return `${baseJobPath}/post/${jobNanoId}/candidates/lead`;
  }

  static postCandidatesLeadEdit(
    jobNanoId: JobNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${baseJobPath}/post/${jobNanoId}/candidates/lead/${jobCandidateNanoId}/edit`;
  }

  static postCandidatesLeadInfo(
    jobNanoId: JobNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${baseJobPath}/post/${jobNanoId}/candidates/lead/${jobCandidateNanoId}/info`;
  }

  static postCandidatesLeadProfile(
    jobNanoId: JobNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${baseJobPath}/post/${jobNanoId}/candidates/lead/${jobCandidateNanoId}`;
  }

  static postJobCandidatesJobResumeAbout(
    jobNanoId: JobNanoId,
    jobResumeNanoId: JobResumeNanoId
  ) {
    return `${baseJobPath}/post/${jobNanoId}/candidates/resume/${jobResumeNanoId}/about`;
  }

  static postJobCandidatesJobResumeExperience(
    jobNanoId: JobNanoId,
    jobResumeNanoId: JobResumeNanoId
  ) {
    return `${baseJobPath}/post/${jobNanoId}/candidates/resume/${jobResumeNanoId}/experience`;
  }

  static postJobCandidatesJobResumeEducation(
    jobNanoId: JobNanoId,
    jobResumeNanoId: JobResumeNanoId
  ) {
    return `${baseJobPath}/post/${jobNanoId}/candidates/resume/${jobResumeNanoId}/education`;
  }

  static postJobCandidatesJobResumeCertification(
    jobNanoId: JobNanoId,
    jobResumeNanoId: JobResumeNanoId
  ) {
    return `${baseJobPath}/post/${jobNanoId}/candidates/resume/${jobResumeNanoId}/certification`;
  }

  static postJobCandidatesJobResumeSkills(
    jobNanoId: JobNanoId,
    jobResumeNanoId: JobResumeNanoId
  ) {
    return `${baseJobPath}/post/${jobNanoId}/candidates/resume/${jobResumeNanoId}/skills`;
  }

  static postJobCandidatesJobResumeProfile(
    jobNanoId: JobNanoId,
    jobResumeNanoId: JobResumeNanoId
  ) {
    return `${baseJobPath}/post/${jobNanoId}/candidates/resume/${jobResumeNanoId}/profile`;
  }

  static viewIndex() {
    return `${baseKanbanPath}/view`;
  }

  static viewCandidates(jobNanoId: JobNanoId) {
    return `${baseKanbanPath}/view/${jobNanoId}/candidates`;
  }

  static viewCandidateDetails(
    jobNanoId: JobNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${baseKanbanPath}/view/${jobNanoId}/candidates/${jobCandidateNanoId}`;
  }

  static viewSourcedCandidates(jobNanoId: JobNanoId) {
    return `${baseKanbanPath}/view/${jobNanoId}/candidates/sourced`;
  }

  static viewDetails(jobNanoId: JobNanoId) {
    return `${baseKanbanPath}/view/${jobNanoId}/details`;
  }

  static viewTimeline(jobNanoId: JobNanoId) {
    return `${baseKanbanPath}/view/${jobNanoId}/timeline`;
  }

  static viewScreening(jobNanoId: JobNanoId) {
    return `${baseKanbanPath}/view/${jobNanoId}/screening`;
  }

  static viewSettings(jobNanoId: JobNanoId) {
    return `${baseKanbanPath}/view/${jobNanoId}/settings`;
  }

  static editIndex() {
    return `${baseKanbanPath}/edit`;
  }

  static editPreferences(jobNanoId: JobNanoId) {
    return `${baseKanbanPath}/edit/${jobNanoId}/preferences`;
  }

  static editTeam(jobNanoId: JobNanoId) {
    return `${baseKanbanPath}/edit/${jobNanoId}/team`;
  }

  static editDescription(jobNanoId: JobNanoId) {
    return `${baseKanbanPath}/edit/${jobNanoId}/description`;
  }

  static editSkills(jobNanoId: JobNanoId) {
    return `${baseKanbanPath}/edit/${jobNanoId}/skills`;
  }

  static editHiringWorkflow(jobNanoId: JobNanoId) {
    return `${baseKanbanPath}/edit/${jobNanoId}/workflow`;
  }

  static editScoreCard(jobNanoId: JobNanoId) {
    return `${baseKanbanPath}/edit/${jobNanoId}/scorecard`;
  }

  static editAutomation(jobNanoId: JobNanoId) {
    return `${baseKanbanPath}/edit/${jobNanoId}/automation`;
  }
}
