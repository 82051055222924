import {
  Box,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
  useDisclosure
} from '@chakra-ui/react';
import { MouseEvent } from 'react';
import { Button } from '../../../../../../../../helpers/Button';
import { Text } from '../../../../../../../../helpers/Text';
import { CheckIcon } from '../../../../../../../../icons/CheckIcon';
import { ChevronDownIcon } from '../../../../../../../../icons/ChevronDownIcon';
import { WorkflowStageNanoId } from '../../../../../../../workflowStages/workflowStagesTypes';
import { StageTaskType } from '../../../../ViewJobCandidateDetailsPage.types';
import { useViewJobCandidateDetailsTasksWidgetContext } from '../../../../contexts/ViewJobCandidateDetailsTasksWidgetContext';
import { ViewJobCandidateDetailsSelectedTaskTool } from '../ViewJobCandidateDetailsSelectedTaskTool';

interface ViewJobCandidateDetailsTasksDropdownProps {
  stageTasks: StageTaskType[];
  stageId?: WorkflowStageNanoId;
}

export default function ViewJobCandidateDetailsTasksDropdown({
  stageTasks
}: ViewJobCandidateDetailsTasksDropdownProps) {
  const { taskStatus, selectedTask, handleSelectTask, selectedTaskAction } =
    useViewJobCandidateDetailsTasksWidgetContext();

  const { isOpen, onToggle, onClose } = useDisclosure();

  const changeTask = (e: MouseEvent<HTMLButtonElement>) => {
    handleSelectTask(e);

    onClose();
  };

  const currentTask = stageTasks.find((task) => task.nanoId === selectedTask);

  const isTaskActionCompleted = currentTask?.status === 'completed';

  const hasSelectedAction =
    selectedTask && selectedTaskAction?.split('__').includes(selectedTask);

  return (
    <Popover isOpen={isOpen} onClose={onClose} isLazy>
      <PopoverTrigger>
        <Flex w="full">
          <Button
            as={
              selectedTask &&
              selectedTaskAction?.split('__').includes(selectedTask)
                ? 'section'
                : undefined
            }
            w="full"
            p={0}
            h="100%"
            minH={6}
            minW={0}
            size="small"
            display="flex"
            cursor={
              taskStatus.label === 'in-progress' ? 'not-allowed' : 'pointer'
            }
            whiteSpace="nowrap"
            alignItems="stretch"
            textOverflow="ellipsis"
            wordBreak="break-all"
            onClick={(e) => {
              e.stopPropagation();

              if (taskStatus.label !== 'in-progress') {
                onToggle();
              }
            }}
            border="none"
            textAlign="left"
            boxShadow="none"
            fontSize="inherit"
            hierarchy="unstyled"
            aria-label="Dropdown"
          >
            {isTaskActionCompleted || hasSelectedAction ? (
              <ViewJobCandidateDetailsSelectedTaskTool />
            ) : (
              <Flex
                w="full"
                gap={1}
                whiteSpace="nowrap"
                wordBreak="break-all"
                alignItems="center"
                fontSize="inherit"
              >
                <Text
                  noOfLines={1}
                  color="inherit"
                  whiteSpace="nowrap"
                  fontWeight="medium"
                  wordBreak="break-all"
                  textOverflow="ellipsis"
                  textTransform="capitalize"
                  display="inline-block"
                >
                  {currentTask?.name ?? 'Select Task'}
                </Text>

                <ChevronDownIcon ml="auto" fill="gray.500" />
              </Flex>
            )}
          </Button>
        </Flex>
      </PopoverTrigger>

      <PopoverContent p={1} minW={48} maxW={40} overflow="hidden">
        <Flex flexDir="column" overflow="auto">
          {stageTasks.map((task) => (
            <Tooltip
              label={task.name.length > 20 ? task.name : undefined}
              key={task.nanoId}
            >
              <Flex>
                <Button
                  px={2}
                  py={1}
                  h="auto"
                  w="full"
                  gap={2}
                  size="small"
                  display="flex"
                  justifyContent="flex-start"
                  color="inherit"
                  textAlign="left"
                  lineHeight={1.5}
                  fontSize="inherit"
                  borderRadius="none"
                  fontWeight="inherit"
                  hierarchy="unstyled"
                  textTransform="capitalize"
                  isDisabled={selectedTask === task.nanoId}
                  onClick={(e) => {
                    e.stopPropagation();
                    changeTask(e);
                  }}
                  value={task.nanoId}
                  key={task.nanoId}
                  _hover={{
                    bg: 'gray.100'
                  }}
                >
                  <Box
                    display="block"
                    overflow="hidden"
                    wordBreak="break-all"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                  >
                    {task.name}
                  </Box>

                  {task.status === 'completed' && <CheckIcon ml="auto" />}
                </Button>
              </Flex>
            </Tooltip>
          ))}
        </Flex>
      </PopoverContent>
    </Popover>
  );
}
