import {
  FetchEducationsCacheKey,
  FetchEducationCacheKey
} from './educationsTypes';

import { JobCandidateId } from '../jobCandidates/jobCandidatesTypes';

import { JobResumeId } from '../jobResumes/jobResumesTypes';

import { JobResumeRecruiterId } from '../jobResumeRecruiters/jobResumeRecruitersTypes';
import { ContactId } from '../contacts/contactsTypes';

export class EducationsCache {
  static indexCacheKey() {
    return 'educations' as FetchEducationsCacheKey;
  }

  static showCacheKey() {
    return 'education' as FetchEducationCacheKey;
  }

  static studyFieldCacheKey() {
    return 'educations-study-field' as FetchEducationsCacheKey;
  }

  static degreeCacheKey() {
    return 'educations-degree' as FetchEducationsCacheKey;
  }

  static jobCandidateListCacheKey(jobCandidateId: JobCandidateId) {
    return `educations-job-candidate-${jobCandidateId}` as FetchEducationsCacheKey;
  }

  static jobResumeListCacheKey(jobResumeId: JobResumeId) {
    return `educations-job-resume-${jobResumeId}` as FetchEducationsCacheKey;
  }

  static jobResumeRecruiterListCacheKey(
    jobResumeRecruiterId: JobResumeRecruiterId
  ) {
    return `educations-job-resume-recruiter-${jobResumeRecruiterId}` as FetchEducationsCacheKey;
  }

  static contactListCacheKey(contactId: ContactId) {
    return `educations-contact-${contactId}` as FetchEducationsCacheKey;
  }
}
