import { Fragment, ReactNode, useCallback, useEffect, useState } from 'react';
import { Box, Flex } from '@chakra-ui/layout';
import { useDisclosure } from '@chakra-ui/hooks';

import { IsDisabled, IsLoading } from '../../../../types';

import { SkillId, SkillName } from '../../skillsTypes';

import { PlusIcon } from '../../../../icons/PlusIcon';

import { Button } from '../../../../helpers/Button';
import { ReactSelectMenuButton } from '../../../../helpers/ReactSelectMenuButton';
import {
  OnSelectChange,
  Select,
  SelectOptionType
} from '../../../../helpers/Select';
import { Text } from '../../../../helpers/Text';

import {
  OnSkillInputChange,
  OnSkillDropdownSelect,
  OnNewSkillDropdownSelect
} from './SkillDropdown.types';

interface SkillDropdownProps {
  options: SelectOptionType[];
  disabled?: IsDisabled;
  isLoading?: IsLoading;
  onSkillInputChange: OnSkillInputChange;
  onSelectSkill: OnSkillDropdownSelect;
  onNewSkill: OnNewSkillDropdownSelect;
}

function SkillDropdown({
  options,
  disabled,
  isLoading,
  onSkillInputChange,
  onSelectSkill,
  onNewSkill
}: SkillDropdownProps) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [inputValue, setInputValue] = useState<string>('');
  const [doc, setDocument] = useState<Document>();

  useEffect(() => {
    setDocument(document);
  }, []);

  const toggleDropDown = useCallback<() => void>(() => {
    if (isOpen) {
      return onClose();
    }

    onOpen();
  }, [isOpen, onOpen, onClose]);

  const onSelectChange: OnSelectChange = (value) => {
    onClose();

    if (value) {
      onSelectSkill({
        id: value.value as SkillId,
        name: value.label as SkillName
      });
    }
  };

  const handleInputChange = useCallback<(value: string) => void>(
    (value) => {
      console.log('handleInputChange', { value });
      if (!value || value === '') {
        return;
      }

      setInputValue(value);
      onSkillInputChange(value as SkillName);
    },
    [setInputValue, onSkillInputChange]
  );

  const openAddNewSkill = () => {
    if (!inputValue) {
      return;
    }

    onSelectSkill(null);

    onNewSkill(inputValue as SkillName);

    onClose();
  };

  return (
    <Fragment>
      <Dropdown
        isOpen={isOpen}
        onClose={toggleDropDown}
        target={
          <Flex minW="95px" minH="24px">
            {!isOpen && (
              <Button
                py={0}
                paddingInline={2}
                height={6}
                hierarchy="secondary"
                disabled={disabled}
                onClick={toggleDropDown}
              >
                <Text fontSize="14px" lineHeight="22px">
                  Add Skill
                </Text>

                <PlusIcon w={4} h={4} ml={1} />
              </Button>
            )}
          </Flex>
        }
      >
        <Select
          autoFocus
          menuIsOpen
          menuPlacement="auto"
          onInputChange={handleInputChange}
          onChange={onSelectChange}
          components={{
            IndicatorSeparator: null,
            DropdownIndicator: null,
            MenuList: (props) =>
              ReactSelectMenuButton({
                ...props,
                buttonLabel: `Add ${inputValue}`,
                buttonAction: openAddNewSkill
              })
          }}
          menuPortalTarget={doc?.body}
          menuPosition="fixed"
          options={options}
          isLoading={isLoading}
          styles={{
            menuPortal: (provided) => ({
              ...provided,
              zIndex: 9999,
              width: '250px'
            }),
            menu: (provided) => ({ ...provided, zIndex: 9999 })
          }}
          chakraStyles={{
            dropdownIndicator: (provided) => ({
              ...provided,
              bg: 'transparent',
              w: 6
            }),
            option: (provided) => ({
              ...provided,
              h: 10,
              px: 4,
              fontSize: '14px'
            }),
            indicatorSeparator: (provided) => ({
              ...provided,
              display: 'none'
            }),
            container: (provided) => ({
              ...provided,
              border: 'none',
              boxShadow: 'none',
              width: 'auto'
            }),
            control: () => ({
              border: '1px solid',
              borderRadius: '4px',
              borderColor: 'rgba(5, 128, 164, 0.5)',
              boxShadow: '0px 2px 2px rgb(5 128 164 / 8%)',
              width: 'max-content',
              bg: 'white',
              px: 2,
              minW: '90px'
            }),
            input: (provided) => ({
              ...provided,
              mx: '1px',
              my: '2px',
              h: '16px',
              p: 0,
              color: 'primary.500'
            }),
            placeholder: (provided) => ({
              ...provided,
              mx: '1px',
              my: 0,
              lineHeight: '22px',
              color: 'primary.500'
            }),
            singleValue: (provided) => ({
              ...provided,
              mx: '1px',
              my: 0,
              lineHeight: '22px',
              color: 'primary.500'
            }),
            valueContainer: (provided) => ({
              ...provided,
              px: 0,
              h: 6
            })
          }}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary: '#0580A4'
            }
          })}
          placeholder=""
          tabSelectsValue={false}
        />
      </Dropdown>
    </Fragment>
  );
}

interface DropdownProps {
  isOpen: boolean;
  target: ReactNode;
  children: ReactNode;
  onClose: () => void;
}

const Menu = (props: JSX.IntrinsicElements['div']) => {
  return (
    <Box
      css={{
        width: '350px',
        borderRadius: 4,
        marginTop: 0,

        position: 'absolute',
        zIndex: 2
      }}
      {...props}
    />
  );
};

const Dropdown = ({ children, isOpen, target, onClose }: DropdownProps) => (
  <Flex css={{ position: 'relative' }}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </Flex>
);

const Blanket = (props: JSX.IntrinsicElements['div']) => (
  <Box
    css={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: 'fixed',
      zIndex: 1
    }}
    {...props}
  />
);

export default SkillDropdown;
