import { useMutation, useQueryClient } from 'react-query';

import {
  FetchSkillBadgesCacheKey,
  FetchSkillBadgeCacheKey,
  FetchSkillBadgesIndexCacheKey,
  FetchSkillBadgeIndexCacheKey,
  UpdateSkillBadgeErrorMessage,
  UpdateSkillBadgeIsLoading,
  SkillBadgeFields,
  UpdateSkillBadgeNanoId
} from '../../skillBadgesTypes';

import {
  SkillBadgesBffRequests,
  UpdateSkillBadgeData,
  UpdateSkillBadgeErrorResponse
} from '../../SkillBadgesBffRequests';

import { parseRequestError } from '../../../../utils/parseRequestError';

interface UpdateSkillBadgeOptions {
  skillBadgeNanoId: UpdateSkillBadgeNanoId;
  cacheKeys?: (
    | FetchSkillBadgesCacheKey
    | FetchSkillBadgeCacheKey
    | FetchSkillBadgesIndexCacheKey
    | FetchSkillBadgeIndexCacheKey
  )[];
  filterColumn?: SkillBadgeFields.ID | SkillBadgeFields.NANO_ID;
}

function useUpdateSkillBadge({
  skillBadgeNanoId,
  cacheKeys = []
}: UpdateSkillBadgeOptions) {
  const queryClient = useQueryClient();

  const { data, error, isLoading, mutateAsync, reset } = useMutation<
    UpdateSkillBadgeData,
    UpdateSkillBadgeErrorResponse,
    UpdateSkillBadgeData
  >(
    (queryInput) =>
      SkillBadgesBffRequests.updateSkillBadge(skillBadgeNanoId, queryInput),
    {
      onSettled: async () =>
        cacheKeys?.map((cacheKey) => queryClient.invalidateQueries(cacheKey))
    }
  );

  return {
    updateSkillBadgeData: data,
    updateSkillBadgeError: error,
    updateSkillBadgeErrorMessage: parseRequestError(
      error
    ) as UpdateSkillBadgeErrorMessage,
    updateSkillBadgeIsLoading: isLoading as UpdateSkillBadgeIsLoading,
    updateSkillBadge: (updateSkillBadgeData: UpdateSkillBadgeData) =>
      mutateAsync(updateSkillBadgeData),
    updateSkillBadgeReset: () => reset()
  };
}

export default useUpdateSkillBadge;
