import { Box } from '@chakra-ui/react';
import { ReactNode, RefObject, useEffect } from 'react';
import {
  JobCandidateCandidateShares,
  JobCandidateCompanyPhone,
  JobCandidatePhone,
  MayBeJobCandidateCity
} from '../../../../../jobCandidates/jobCandidatesTypes';
import { JobId, JobJobTitle, JobName, JobNanoId } from '../../../../jobsTypes';
import { type ViewJobCandidateDetailsCandidate } from '../../pages/ViewJobCandidateDetailsProfilePage/ViewJobCandidateDetailsProfilePage.types';
import { ViewJobCandidateDetailsHeader } from '../ViewJobCandidateDetailsHeader';
import { ViewJobCandidateDetailsPageTabs } from '../ViewJobCandidateDetailsPageTabs';
import { useViewJobCandidateDetailsSubmissionContext } from '../../contexts/ViewJobCandidateDetailsSubmissionContext';
import { useViewJobCandidateDetailsSendAssessmentContext } from '../../contexts/ViewJobCandidateDetailsSendAssessmentContext';

interface ViewJobCandidateDetailsCandidateDetailsProps {
  children: ReactNode;
  requiresCredit: boolean;
  leftSideBarIsOpen?: boolean;
  leftSideBarOnOpen?: () => void;
  leftSideBarOnClose?: () => void;
  jobCandidate: ViewJobCandidateDetailsCandidate & {
    candidateShares: JobCandidateCandidateShares;
    city: MayBeJobCandidateCity;
    companyPhone: JobCandidateCompanyPhone;
    phone: JobCandidatePhone;
  };
  job: {
    id: JobId;
    nanoId: JobNanoId;
    jobTitle: JobJobTitle;
    name: JobName;
  };
  prospect?: boolean;
  isHiringPortalPath: boolean;
  isBasePath?: boolean;
  isBioOutsideViewport?: RefObject<HTMLDivElement>;
}

function ViewJobCandidateDetailsCandidateDetails({
  job,
  children,
  prospect,
  isBasePath,
  jobCandidate,
  requiresCredit,
  isHiringPortalPath,
  isBioOutsideViewport,
  leftSideBarOnOpen,
  leftSideBarOnClose
}: ViewJobCandidateDetailsCandidateDetailsProps) {
  const { isCandidateSubmissionOpen } =
    useViewJobCandidateDetailsSubmissionContext();

  const { isSendEmailAssessmentOpen } =
    useViewJobCandidateDetailsSendAssessmentContext();

  const isRightSidebarOpen =
    isCandidateSubmissionOpen || isSendEmailAssessmentOpen;

  useEffect(() => {
    if (isRightSidebarOpen) {
      leftSideBarOnClose && leftSideBarOnClose();
    } else {
      leftSideBarOnOpen && leftSideBarOnOpen();
    }
  }, [isRightSidebarOpen, leftSideBarOnOpen, leftSideBarOnClose]);

  return (
    <Box pos="relative">
      <ViewJobCandidateDetailsHeader
        jobCandidate={jobCandidate}
        requiresCredit={requiresCredit}
        jobCandidateNanoId={jobCandidate.nanoId}
        isBioOutsideViewport={isBioOutsideViewport}
        job={job}
      />

      <ViewJobCandidateDetailsPageTabs
        isHiringPortalPath={isHiringPortalPath}
        jobCandidate={jobCandidate}
        isBasePath={isBasePath}
        prospect={prospect}
        job={job}
      >
        {children}
      </ViewJobCandidateDetailsPageTabs>
    </Box>
  );
}

export default ViewJobCandidateDetailsCandidateDetails;
