import { ReactNode, useCallback, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import debounce from 'lodash/debounce';
import first from 'lodash/first';

import { ErrorMessage, IsDisabled, IsRequired } from '../../../../../types';

import {
  FetchDepartmentsEnabled,
  FetchDepartmentsPageSize,
  DepartmentFields,
  DepartmentId,
  DepartmentName,
  DepartmentNanoId,
  DepartmentUserId,
  FetchDepartmentsFilters,
  FetchDepartmentsSort,
  DepartmentCompanyId
} from '../../../../departments/departmentsTypes';

import {
  fetchDepartmentsQuery,
  FetchDepartmentsResponse
} from '../../../../departments/queries/fetchDepartments.query';

import { usePaginatedDepartments } from '../../../../departments/hooks/usePaginatedDepartments';
import { useCurrentUser } from '../../../../../auth/hooks/useAuth';
import { useCreateDepartment } from '../../../../departments/hooks/useCreateDepartment';

import { DepartmentsCache } from '../../../../departments/DepartmentsCache';

import {
  SelectField,
  SelectFieldReactHookFormControl,
  SelectFieldReactHookFormFieldPath
} from '../../../../../helpers/forms/formFields/SelectField';

import { SelectOptionType } from '../../../../../helpers/forms/formFields/SelectField/components/SelectFieldControl';

import { generateNanoId } from '../../../../../utils/generateNanoId';
import { CompanyId } from '../../../../companies/companiesTypes';

interface SelectDepartmentFormFieldRequiredProps<T extends FieldValues> {
  isRequired?: IsRequired;
  isDisabled?: IsDisabled;
  control: SelectFieldReactHookFormControl<T>;
  name?: SelectFieldReactHookFormFieldPath<T>;
  item?: {
    department: {
      id: DepartmentId;
      name: DepartmentName;
    };
  } | null;
  companyId?: CompanyId;
  disabledDepartmentId?: DepartmentId;
  errorMessage?: ErrorMessage;
  labelAddon?: ReactNode;
  withoutAddNewButton?: boolean;
  withoutLabel?: boolean;
  placeholder?: string;
  label?: string;
}

function SelectDepartmentFormField<T extends FieldValues>({
  isRequired,
  isDisabled,
  control,
  name,
  item,
  companyId,
  disabledDepartmentId,
  errorMessage,
  label,
  placeholder,
  labelAddon,
  withoutAddNewButton,
  withoutLabel
}: SelectDepartmentFormFieldRequiredProps<T>) {
  const currentUser = useCurrentUser();

  const {
    createDepartment,
    createDepartmentIsLoading,
    createDepartmentErrorMessage
  } = useCreateDepartment({
    cacheKeys: [DepartmentsCache.indexCacheKey()]
  });

  const {
    departments,
    departmentsIsLoading,
    departmentsErrorMessage,
    changeDepartmentsFilters
  } = usePaginatedDepartments<FetchDepartmentsResponse>({
    query: fetchDepartmentsQuery,
    cacheKey: DepartmentsCache.indexCacheKey(),
    initialFilters: {
      ...(item?.department?.name
        ? {
            [DepartmentFields.NAME]: {
              operator: 'ilike',
              value: `${item?.department?.name}`
            }
          }
        : {}),
      ...(companyId
        ? {
            [DepartmentFields.COMPANY_ID]: {
              operator: 'eq',
              value: companyId
            }
          }
        : {})
    } as unknown as FetchDepartmentsFilters,
    initialSort: {
      [DepartmentFields.NAME]: { ascending: true }
    } as unknown as FetchDepartmentsSort
  });

  const {
    departmentsIsLoading: existingDepartmentsIsLoading,
    departmentsErrorMessage: existingDepartmentsErrorMessage,
    fetchDepartments
  } = usePaginatedDepartments<FetchDepartmentsResponse>({
    query: fetchDepartmentsQuery,
    cacheKey: DepartmentsCache.existsCacheKey(),
    enabled: false as FetchDepartmentsEnabled,
    initialPageSize: 10 as FetchDepartmentsPageSize,
    initialFilters: {
      ...(companyId
        ? {
            [DepartmentFields.COMPANY_ID]: {
              operator: 'eq',
              value: companyId
            }
          }
        : {})
    } as unknown as FetchDepartmentsFilters,
    initialSort: {
      [DepartmentFields.NAME]: { ascending: true }
    } as unknown as FetchDepartmentsSort
  });

  const defaultItemUserDepartment = item?.department
    ? { value: item.department.id, label: item.department.name }
    : undefined;

  const [inputValue, setInputValue] = useState<string>('');

  const debouncedFilterDepartments = debounce<(updatedValue: string) => void>(
    (updatedValue) =>
      changeDepartmentsFilters({
        [DepartmentFields.NAME]: {
          operator: 'ilike',
          value: `${updatedValue}%`
        }
      }),
    500
  );

  const handleInputChange = useCallback<(value: string) => void>(
    (value) => {
      setInputValue(value);

      if (!value || value === '') {
        return;
      }

      debouncedFilterDepartments(value);
    },
    [debouncedFilterDepartments]
  );

  const onAddNewDepartment = useCallback(async () => {
    const { data: existingDepartments } = await fetchDepartments({
      nextFilters: {
        [DepartmentFields.NAME]: { operator: 'ilike', value: inputValue }
      }
    });

    const existingDepartment = first(existingDepartments);

    if (existingDepartment) {
      const existingDepartmentValue = {
        label: existingDepartment.name,
        value: existingDepartment.id
      };

      setInputValue('');
      return existingDepartmentValue as SelectOptionType;
    }

    const department = await createDepartment({
      name: inputValue as DepartmentName,
      userId: currentUser.id as DepartmentUserId,
      nanoId: generateNanoId<DepartmentNanoId>(),
      companyId: (companyId || currentUser.companyId) as DepartmentCompanyId
    });

    if (department?.id && department?.name) {
      const departmentValue = {
        label: department.name,
        value: department.id
      };

      setInputValue('');
      return departmentValue as SelectOptionType;
    }
  }, [
    inputValue,
    fetchDepartments,
    currentUser.id,
    currentUser.companyId,
    createDepartment,
    companyId
  ]);

  return (
    <SelectField
      isRequired={isRequired}
      isDisabled={isDisabled}
      control={control}
      label={withoutLabel ? '' : label || 'Department'}
      labelAddon={labelAddon}
      placeholder={placeholder || 'Select department'}
      name={name || ('departmentId' as SelectFieldReactHookFormFieldPath<T>)}
      options={departments.map((department) => ({
        value: department.id,
        label: department.name,
        isDisabled: department.id === disabledDepartmentId
      }))}
      defaultValue={defaultItemUserDepartment}
      errorMessage={
        errorMessage ||
        departmentsErrorMessage ||
        createDepartmentErrorMessage ||
        existingDepartmentsErrorMessage
      }
      isLoading={
        departmentsIsLoading ||
        createDepartmentIsLoading ||
        existingDepartmentsIsLoading
      }
      onInputChange={handleInputChange}
      withAddNewButton={!!inputValue && !withoutAddNewButton}
      addNewButtonHierarchy="ghost"
      addNewButtonLabel={`${
        existingDepartmentsIsLoading
          ? 'Checking:'
          : `Add${createDepartmentIsLoading ? 'ing:' : ''}`
      } ${inputValue}`}
      addNewButtonToValueAction={onAddNewDepartment}
    />
  );
}

export default SelectDepartmentFormField;
