import { Icon, IconProps } from '@chakra-ui/icon';

const Error404Icon = ({ ...props }: IconProps) => (
  <Icon viewBox="0 0 150 120" width={150} height={120} {...props}>
    <path
      d="M124.6 55.9c0 14.2-5.8 27-15.1 36.2-9.1 9.1-21.7 14.6-35.6 14.6-13.8 0-26.4-5.6-35.6-14.6-9.3-9.2-15.1-22-15.1-36.2 0-28.1 22.7-50.8 50.7-50.8 28 0 50.7 22.8 50.7 50.8ZM120.2 22.6a4.1 4.1 0 1 0 0-8.2 4.1 4.1 0 0 0 0 8.2ZM126.2 6.6a2.8 2.8 0 1 0 0-5.6 2.8 2.8 0 0 0 0 5.6ZM24.8 22.5a2.8 2.8 0 1 0 0-5.6 2.8 2.8 0 0 0 0 5.6ZM33.7 101.4a5.2 5.2 0 1 0 0-10.4 5.2 5.2 0 0 0 0 10.4Z"
      fill="#E6F2F6"
    />
    <g filter="url(#a)">
      <path
        d="M119.218 26.781v55.405a4.11 4.11 0 0 1-4.109 4.133H31.654c-2.263 0-4.138-1.857-4.138-4.133V26.781a4.135 4.135 0 0 1 4.138-4.133h83.455c2.263 0 4.109 1.857 4.109 4.133Z"
        fill="url(#b)"
      />
    </g>
    <path
      d="M119.218 26.781v6.2H27.516v-6.2a4.135 4.135 0 0 1 4.138-4.133h83.455c2.263 0 4.109 1.857 4.109 4.133Z"
      fill="#DAE1E3"
    />
    <path
      d="M33.292 29.357c.855 0 1.549-.697 1.549-1.558 0-.86-.694-1.557-1.549-1.557s-1.548.697-1.548 1.557.693 1.558 1.548 1.558ZM38.353 29.357c.855 0 1.548-.697 1.548-1.558 0-.86-.693-1.557-1.548-1.557-.855 0-1.548.697-1.548 1.557s.693 1.558 1.548 1.558ZM43.386 29.357c.855 0 1.548-.697 1.548-1.558 0-.86-.693-1.557-1.548-1.557-.855 0-1.548.697-1.548 1.557s.693 1.558 1.548 1.558Z"
      fill="#98A5A9"
    />
    <path
      d="M46.66 70.719h53.414M55.086 76.438h36.562"
      stroke="#D5DDEA"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m115.163 98.553-19.947-19.93 4.573-4.574 19.931 19.947-4.557 4.557Z"
      fill="#697275"
    />
    <path
      d="m122.097 105.566-15.295-15.295a3.282 3.282 0 0 1 0-4.636 3.284 3.284 0 0 1 4.636 0l15.295 15.295a3.284 3.284 0 0 1 0 4.636 3.284 3.284 0 0 1-4.636 0Z"
      fill="#697275"
    />
    <path
      d="M121.009 106.544 101.33 86.865a1.19 1.19 0 0 1 0-1.671l5.03-5.03a1.19 1.19 0 0 1 1.672 0l19.678 19.678a1.189 1.189 0 0 1 0 1.672l-5.03 5.03a1.19 1.19 0 0 1-1.671 0ZM72.636 14.398c-20.877 0-37.796 16.92-37.796 37.797 0 20.861 16.92 37.796 37.78 37.796 20.877 0 37.797-16.92 37.797-37.796 0-20.877-16.92-37.797-37.78-37.797Zm0 68.592c-16.761 0-30.338-13.797-30.338-30.811s13.577-30.795 30.338-30.795c16.762 0 30.338 13.797 30.338 30.81 0 17.015-13.592 30.796-30.338 30.796Z"
      fill="#98A5A9"
    />
    <path
      d="M64.51 53.386v2.746h-1.597v2.609H59.85v-2.609H53.19v-3.157l6.126-8.787h3.596v9.198h1.598Zm-4.527-5.629-3.862 5.63h3.862v-5.63ZM74.614 58.74c-3.233 0-6.061-2.778-6.061-7.276 0-4.498 2.828-7.276 6.061-7.276s6.062 2.778 6.062 7.276c.135 4.366-2.694 7.277-6.062 7.277Zm0-2.778c1.482 0 3.098-1.455 3.098-4.63 0-3.043-1.481-4.63-3.098-4.63-1.482 0-3.098 1.455-3.098 4.63.135 3.175 1.616 4.63 3.098 4.63Z"
      fill="#C0C9CC"
    />
    <path
      d="M62.893 53.386v2.746h-1.598v2.609h-3.063v-2.609h-6.658v-3.157l6.126-8.787h3.462v9.198h1.731Zm-4.527-5.629-3.862 5.63h3.862v-5.63Z"
      fill="url(#c)"
    />
    <path
      d="M72.998 58.74c-3.233 0-6.062-2.778-6.062-7.276 0-4.498 2.83-7.276 6.062-7.276 3.233 0 6.062 2.778 6.062 7.276.134 4.366-2.694 7.277-6.062 7.277Zm0-2.778c1.482 0 3.098-1.455 3.098-4.63 0-3.043-1.482-4.63-3.098-4.63-1.482 0-3.098 1.455-3.098 4.63.135 3.175 1.616 4.63 3.098 4.63Z"
      fill="url(#d)"
    />
    <path
      d="M90.031 58.74c-1.398 0-2.622-.416-3.671-1.25-1.035-.835-1.58-1.878-1.636-3.13h2.979c.126.485.398.882.818 1.192.433.31.937.464 1.51.464.728 0 1.294-.209 1.7-.626.42-.43.63-.962.63-1.594 0-.646-.218-1.178-.651-1.595-.42-.417-.965-.626-1.637-.626-.903-.11-1.552-.11-2.115-.11h-1.213s.084-2.25 0-2.022l4.447-2.83h-6.468v-2.425h9.42v2.684c-3.76 2.571-3.76 2.507-3.76 2.571 0 0 1.516.04 2.829.809 1.02.597 2.021 1.616 2.021 3.766 0 1.426-.482 2.57-1.447 3.431-.951.861-2.203 1.292-3.756 1.292Z"
      fill="#C0C9CC"
    />
    <path
      d="M88.414 58.74c-1.398 0-2.622-.416-3.671-1.25-1.035-.835-1.58-1.878-1.637-3.13h2.98c.125.485.398.882.818 1.192.433.31.937.464 1.51.464.727 0 1.294-.209 1.7-.626.42-.43.629-.962.629-1.594 0-.646-.217-1.178-.65-1.595-.42-.417-.966-.626-1.637-.626-.903-.11-1.552-.11-2.115-.11h-1.213s.084-2.25 0-2.022l4.447-2.83h-6.469v-2.425h9.42v2.684c-3.76 2.571-3.76 2.507-3.76 2.571 0 0 1.516.04 2.83.809 1.02.597 2.021 1.616 2.021 3.766 0 1.426-.482 2.57-1.448 3.431-.95.861-2.202 1.292-3.755 1.292Z"
      fill="url(#e)"
    />
    <defs>
      <linearGradient
        id="b"
        x1={73.337}
        y1={21.176}
        x2={73.337}
        y2={87.005}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDFEFF" />
        <stop offset={0.996} stopColor="#ECF0F5" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={57.234}
        y1={44.188}
        x2={57.234}
        y2={58.741}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#697275" />
        <stop offset={1} stopColor="#98A5A9" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={73}
        y1={44.188}
        x2={73}
        y2={58.741}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#697275" />
        <stop offset={1} stopColor="#98A5A9" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={88.362}
        y1={44.188}
        x2={88.362}
        y2={58.741}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#697275" />
        <stop offset={1} stopColor="#98A5A9" />
      </linearGradient>
      <filter
        id="a"
        x={5.516}
        y={11.648}
        width={135.702}
        height={107.671}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={11} />
        <feGaussianBlur stdDeviation={11} />
        <feColorMatrix values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1061_80" />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_1061_80"
          result="shape"
        />
      </filter>
    </defs>
  </Icon>
);
export default Error404Icon;
