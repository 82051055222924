import { useDisclosure } from '@chakra-ui/react';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react';
import {
  ViewJobCandidateDetailsSendAssessmentContextType,
  ViewJobCandidateDetailsSendAssessmentFields,
  ViewJobCandidateDetailsSendAssessmentProviderProps
} from './ViewJobCandidateDetailsSendAssessmentContext.types';
import { useChakraToast } from '../../../../../../helpers/useChakraToast';
import { ViewJobCandidateDetailsAssessmentNav } from '../../components/ViewJobCandidateDetailsSendAssessmentSidebar/components/ViewJobCandidateDetailsAssessmentNav';
import { useViewJobCandidateDetailsSubmissionContext } from '../ViewJobCandidateDetailsSubmissionContext';

const ViewJobCandidateDetailsSendAssessmentContext =
  createContext<ViewJobCandidateDetailsSendAssessmentContextType>({
    totalSteps: 2,
    currentStep: 1,
    assessmentFields: [],
    handleAssessmentField: function (): void {
      /*  */
    },
    isLastStep: false,
    isFirstStep: false,
    isEmailAssessmentSent: false,
    isSendEmailAssessmentOpen: false,
    isSendPhoneAssessmentOpen: false,
    openEmailAssessmentSent: function (): void {
      /*  */
    },
    cancelEmailAssessmentSent: function (): void {
      /*  */
    },
    openSendEmailAssessment: function (): void {
      /*  */
    },
    closeSendEmailAssessment: function (): void {
      /*  */
    },
    openSendPhoneAssessment: function (): void {
      /*  */
    },
    closeSendPhoneAssessment: function (): void {
      /*  */
    },
    goToPrevStep: function (): void {
      /*  */
    },
    goToNextStep: function (): void {
      /*  */
    },
    sendEmailAssessment: function (): void {
      /*  */
    },
    sendPhoneAssessment: function (): void {
      /*  */
    }
  });

export function ViewJobCandidateDetailsSendAssessmentProvider({
  children
}: ViewJobCandidateDetailsSendAssessmentProviderProps) {
  const toast = useChakraToast();
  const defaultSubmissionFields: ViewJobCandidateDetailsSendAssessmentFields[] =
    Object.values(ViewJobCandidateDetailsSendAssessmentFields);

  const { closeCandidateSubmission } =
    useViewJobCandidateDetailsSubmissionContext();

  const totalSteps = 2;
  const [currentStep, setCurrentStep] = useState(1);
  const [assessmentFields, setAssessmentFields] = useState<
    ViewJobCandidateDetailsSendAssessmentFields[]
  >(defaultSubmissionFields);

  const {
    isOpen: isSendEmailAssessmentOpen,
    onClose: closeSendEmailAssessment,
    onOpen: openSendEmailAssessment
  } = useDisclosure({
    onClose: () => {
      setAssessmentFields(defaultSubmissionFields);
      setCurrentStep(1);
    },
    onOpen: () => closeCandidateSubmission()
  });

  const {
    isOpen: isEmailAssessmentSent,
    onOpen: openEmailAssessmentSent,
    onClose: cancelEmailAssessmentSent
  } = useDisclosure();

  const {
    isOpen: isSendPhoneAssessmentOpen,
    onClose: closeSendPhoneAssessment,
    onOpen: openSendPhoneAssessment
  } = useDisclosure();

  const goToNextStep = useCallback(() => {
    if (currentStep === totalSteps) return;

    setCurrentStep(currentStep + 1);
  }, [currentStep]);

  const goToPrevStep = useCallback(() => {
    if (currentStep === 1) return;

    setCurrentStep(currentStep - 1);
  }, [currentStep]);

  const sendPhoneAssessment = useCallback(() => {
    closeSendPhoneAssessment();

    toast({
      title: 'Candidate phone assessment saved',
      status: 'success'
    });
  }, [closeSendPhoneAssessment, toast]);

  const sendEmailAssessment = useCallback(() => {
    closeSendEmailAssessment();

    openEmailAssessmentSent();

    toast({
      title: 'Candidate email assessment sent',
      status: 'success'
    });
  }, [closeSendEmailAssessment, openEmailAssessmentSent, toast]);

  const handleAssessmentField = useCallback(
    (field: ViewJobCandidateDetailsSendAssessmentFields) => {
      if (assessmentFields.includes(field)) {
        const updatedFields = assessmentFields.filter((f) => f !== field);

        setAssessmentFields(updatedFields);
      } else {
        setAssessmentFields([...assessmentFields, field]);
      }
    },
    [assessmentFields]
  );

  const values: ViewJobCandidateDetailsSendAssessmentContextType = useMemo(
    () => ({
      totalSteps,
      currentStep,
      goToNextStep,
      goToPrevStep,
      assessmentFields,
      handleAssessmentField,
      sendEmailAssessment,
      sendPhoneAssessment,
      isEmailAssessmentSent,
      openEmailAssessmentSent,
      cancelEmailAssessmentSent,
      openSendEmailAssessment,
      openSendPhoneAssessment,
      closeSendEmailAssessment,
      closeSendPhoneAssessment,
      isSendEmailAssessmentOpen,
      isSendPhoneAssessmentOpen,
      isFirstStep: currentStep === 1,
      isLastStep: currentStep === totalSteps
    }),
    [
      currentStep,
      goToNextStep,
      goToPrevStep,
      assessmentFields,
      handleAssessmentField,
      sendEmailAssessment,
      sendPhoneAssessment,
      isEmailAssessmentSent,
      openEmailAssessmentSent,
      cancelEmailAssessmentSent,
      openSendEmailAssessment,
      openSendPhoneAssessment,
      closeSendEmailAssessment,
      closeSendPhoneAssessment,
      isSendEmailAssessmentOpen,
      isSendPhoneAssessmentOpen
    ]
  );

  return (
    <ViewJobCandidateDetailsSendAssessmentContext.Provider value={values}>
      {children}

      {values.isSendEmailAssessmentOpen && (
        <ViewJobCandidateDetailsAssessmentNav />
      )}
    </ViewJobCandidateDetailsSendAssessmentContext.Provider>
  );
}

export const useViewJobCandidateDetailsSendAssessmentContext = () =>
  useContext(ViewJobCandidateDetailsSendAssessmentContext);
