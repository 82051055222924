import { FieldBrand, ItemRecordID } from '../../../types';

import { UserUuid } from '../../users/usersTypes';

export type JobCandidateRecordId = FieldBrand<
  ItemRecordID,
  'JobCandidateRecordId'
>;

export type JobCandidateUserUuid = FieldBrand<UserUuid, 'JobCandidateUserUuid'>;

export const enum JobCandidateIndexFields {
  ID = 'id',
  RECORD_ID = 'recordId',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  CREATED_AT = 'createdAt',

  USER_UUID = 'userUuid'
}
