import {
  WorkflowStageTaskDueDate,
  WorkflowStageTaskDueDates
} from '../../workflowStageTasksTypes';

function renderWorkflowStageTaskDueDate(
  WorkflowStageTaskDueDate: WorkflowStageTaskDueDate
) {
  switch (WorkflowStageTaskDueDate) {
    case WorkflowStageTaskDueDates.TODAY:
      return 'Today';
    case WorkflowStageTaskDueDates.ONE_DAY:
      return '1 Day';
    case WorkflowStageTaskDueDates.TWO_DAYS:
      return '2 Days';
    case WorkflowStageTaskDueDates.THREE_DAYS:
      return '3 Days';
    case WorkflowStageTaskDueDates.FOUR_DAYS:
      return '4 Days';
    case WorkflowStageTaskDueDates.FIVE_DAYS:
      return '5 Days';
    default:
      return 'Unknown';
  }
}

export default renderWorkflowStageTaskDueDate;
