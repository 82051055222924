import {
  PermissionItemBffID,
  PermissionItemBffNanoID,
  FetchPermissionsBffURL,
  FetchPermissionBffURL,
  FetchPermissionByNanoIdBffURL,
  CreatePermissionBffURL,
  UpdatePermissionBffURL,
  DeletePermissionBffURL,
  ClearPermissionsCacheBffURL
} from './permissionsTypes';

import { BaseBffRoutes } from '../BaseBffRoutes';

export class PermissionsBffRoutes extends BaseBffRoutes {
  protected static route = 'permissions';

  static fetchPermissionsRoute() {
    return this.fetchItemsRoute<FetchPermissionsBffURL>();
  }

  static fetchPermissionsIndexRoute() {
    return this.fetchItemsIndexRoute<FetchPermissionsBffURL>();
  }

  static fetchPermissionRoute(permissionItemBffID: PermissionItemBffID) {
    return this.fetchItemRoute<FetchPermissionBffURL>(permissionItemBffID);
  }

  static fetchPermissionIndexRoute(
    permissionItemBffNanoID: PermissionItemBffNanoID
  ) {
    return this.fetchItemIndexRoute<FetchPermissionBffURL>(
      permissionItemBffNanoID
    );
  }

  static fetchPermissionByNanoIdRoute(
    permissionItemBffNanoID: PermissionItemBffNanoID
  ) {
    return this.fetchItemByNanoIdRoute<FetchPermissionByNanoIdBffURL>(
      permissionItemBffNanoID
    );
  }

  static createPermissionRoute() {
    return this.createItemRoute<CreatePermissionBffURL>();
  }

  static updatePermissionRoute(
    permissionItemBffNanoID: PermissionItemBffNanoID
  ) {
    return this.updateItemRoute<UpdatePermissionBffURL>(
      permissionItemBffNanoID
    );
  }

  static deletePermissionRoute(
    permissionItemBffNanoID: PermissionItemBffNanoID
  ) {
    return this.deleteItemRoute<DeletePermissionBffURL>(
      permissionItemBffNanoID
    );
  }

  static clearPermissionsCacheRoute() {
    return `${this.basePath}/${this.route}/clear-cache` as ClearPermissionsCacheBffURL;
  }
}
