import { Box, Flex, Grid, Stack } from '@chakra-ui/react';
import { Props as DayzedProps, useDayzed } from 'dayzed';
import { ChevronDoubleRightIcon } from '../../../../icons/ChevronDoubleRightIcon';
import { ChevronRightIcon } from '../../../../icons/ChevronRightIcon';
import { Button } from '../../../Button';
import { IconButton } from '../../../buttons/IconButton';
import { Heading } from '../../../Heading';
import { Text } from '../../../Text';
import { useEffect, useState } from 'react';
import { DateUtils } from '../../../../utils/DateUtils';
import size from 'lodash/size';

const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const WEEK_NAMES_SHORT = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

interface CalendarHelperProps {
  date?: Date | null;
  maxDate?: Date | null;
  minDate?: Date | null;
  selected?: Date | Date[] | null;
  monthsToDisplay?: number;
  disableWeekend?: boolean;
  onChange(selectedDate: Date): void;
}

export default function CalendarHelper({
  date,
  maxDate,
  minDate,
  selected,
  onChange,
  disableWeekend,
  monthsToDisplay
}: CalendarHelperProps) {
  const initialDate =
    date && DateUtils.isWeekend(date)
      ? DateUtils.getNextMonday(date)
      : date || new Date();
  const [currentDate, setCurrentDate] = useState<Date | undefined>(initialDate);

  useEffect(() => {
    if (date && DateUtils.isWeekend(date)) {
      setCurrentDate(DateUtils.getNextMonday(date));
      onChange(DateUtils.getNextMonday(date));
    } else {
      setCurrentDate(date || new Date());
    }
  }, [date, onChange]);

  function onDateSelected({ date }: { date: Date }) {
    setCurrentDate(date);
    onChange(date);
  }

  const props: DayzedProps = {
    monthsToDisplay,
    onDateSelected,
    minDate: minDate || undefined,
    maxDate: maxDate || undefined,
    selected: selected !== null ? selected : undefined
  };

  const dayzedData = useDayzed(props as DayzedProps);

  const { calendars, getBackProps, getForwardProps, getDateProps } = dayzedData;

  if (size(calendars) === 0) {
    return null;
  }

  return (
    <>
      {calendars.map((calendar) => (
        <Stack
          w="full"
          key={`${calendar.month}${calendar.year}`}
          opacity="1"
          spacing={4}
          textAlign="center"
        >
          <Flex alignItems="center" gap={1}>
            <IconButton
              size="extra-small"
              color="gray.600"
              boxShadow="none"
              hierarchy="ghost"
              aria-label="Previous year"
              icon={
                <ChevronDoubleRightIcon
                  transform={'rotate(180deg)'}
                  w={3}
                  h={3}
                />
              }
              {...getBackProps({
                calendars,
                offset: 12
              })}
            />

            <IconButton
              size="extra-small"
              color="gray.600"
              boxShadow="none"
              hierarchy="ghost"
              aria-label="Previous month"
              icon={<ChevronRightIcon transform={'rotate(180deg)'} />}
              {...getBackProps({ calendars })}
            />

            <Heading level="h5" flex={1} fontWeight="medium">
              {MONTH_NAMES[calendar.month]} {calendar.year}
            </Heading>

            <IconButton
              size="extra-small"
              color="gray.600"
              boxShadow="none"
              hierarchy="ghost"
              aria-label="Next month"
              icon={<ChevronRightIcon />}
              {...getForwardProps({ calendars })}
            />

            <IconButton
              size="extra-small"
              color="gray.600"
              boxShadow="none"
              hierarchy="ghost"
              aria-label="Next year"
              icon={<ChevronDoubleRightIcon w={3} h={3} />}
              {...getForwardProps({ calendars, offset: 12 })}
            />
          </Flex>

          <Stack spacing={2}>
            <Box>
              {WEEK_NAMES_SHORT.map((weekday) => (
                <Box
                  key={`${calendar.month}${calendar.year}${weekday}`}
                  display="inline-block"
                  width="calc(100% / 7)"
                  bgColor="transparent"
                  textTransform="uppercase"
                  border="none"
                >
                  <Text textStyle="upperCase1Medium" color="gray.600">
                    {weekday}
                  </Text>
                </Box>
              ))}
            </Box>

            <Grid gridTemplateColumns="repeat(7, 1fr)" gap={3}>
              {calendar.weeks.map((week, weekIndex) =>
                week.map((dateObj, index) => {
                  const key = `${calendar.month}${calendar.year}${weekIndex}${index}`;
                  if (!dateObj) {
                    return (
                      <Box key={key} border="none" bgColor="transparent" />
                    );
                  }

                  const { date, selected, today, selectable } = dateObj;
                  const isSelectable =
                    selectable &&
                    (!disableWeekend || !DateUtils.isWeekend(date));

                  const isCurrentDate =
                    currentDate &&
                    DateUtils.hasSame({ date, otherDate: currentDate }, 'day');

                  return (
                    <Flex key={key}>
                      <Button
                        minW="0"
                        key={key}
                        {...getDateProps({ dateObj })}
                        border="none"
                        borderRadius="lg"
                        hierarchy="unstyled"
                        transitionDuration="slow"
                        fontWeight={
                          selected || isCurrentDate ? 'medium' : 'normal'
                        }
                        color={
                          isCurrentDate
                            ? 'white'
                            : selected
                            ? 'green.600'
                            : 'gray.800'
                        }
                        fontSize="sm"
                        size="medium"
                        boxSize={8}
                        // isDisabled={!selectable}
                        isDisabled={!isSelectable}
                        _hover={
                          isSelectable
                            ? {
                                bgColor: isCurrentDate
                                  ? 'primary.500'
                                  : selected
                                  ? 'green.100'
                                  : 'gray.100'
                              }
                            : undefined
                        }
                        bgColor={
                          today && isSelectable && !selected && !isCurrentDate
                            ? 'gray.200'
                            : isCurrentDate
                            ? 'primary.500'
                            : selected
                            ? 'green.100'
                            : 'transparent'
                        }
                      >
                        {date.getDate()}
                      </Button>
                    </Flex>
                  );
                })
              )}
            </Grid>
          </Stack>
        </Stack>
      ))}
    </>
  );
}
