import { Box, Grid, HStack, Tag } from '@chakra-ui/react';
import React from 'react';
import { Text } from '../../../../../../../../helpers/Text';
import { ClockIcon } from '../../../../../../../../icons/ClockIcon';
import { useViewJobCandidateDetailsTasksWidgetContext } from '../../../../contexts/ViewJobCandidateDetailsTasksWidgetContext';
import startCase from 'lodash/startCase';

function ViewJobCandidateDetailsTaskStatus() {
  const { taskStatus } = useViewJobCandidateDetailsTasksWidgetContext();

  return (
    <Grid
      gap={2}
      gridTemplateColumns="max-content 1fr"
      alignItems="center"
      justifyContent="center"
    >
      <HStack flexGrow={0}>
        <ClockIcon w={4} h={4} />
        <Text textStyle="body2Regular">3 days ago</Text>
      </HStack>

      <Box display="block">
        <Tag
          size="sm"
          color="white"
          noOfLines={1}
          lineHeight="20px"
          bg={taskStatus.color}
          wordBreak="break-all"
          borderRadius="base"
        >
          {startCase(taskStatus.label)}
        </Tag>
      </Box>
    </Grid>
  );
}

export default ViewJobCandidateDetailsTaskStatus;
