import { Flex, Stack } from '@chakra-ui/react';
import { useCurrentUser } from '../../../../../../../../../../../auth/hooks/useAuth';
import { AvatarHelper } from '../../../../../../../../../../../helpers/AvatarHelper';
import { Text } from '../../../../../../../../../../../helpers/Text';
import { ThumbUpIcon } from '../../../../../../../../../../../icons/ThumbUpIcon';
import { ViewJobCandidateModalPhoneSidebarEnterNoteForm } from '../../components';
import { ThumbDownIcon } from '../../../../../../../../../../../icons/ThumbDownIcon';

type ViewJobCandidateModalPhoneSidebarEnterCallNoteProps = {
  onDiscard: () => void;
  onSave: () => void;
  isNotInterested?: boolean;
};

const ViewJobCandidateModalPhoneSidebarEnterCallNote = ({
  isNotInterested,
  onDiscard,
  onSave
}: ViewJobCandidateModalPhoneSidebarEnterCallNoteProps) => {
  const currentUser = useCurrentUser();

  return (
    <Stack spacing={4}>
      <Flex justifyContent="space-between">
        <Flex alignItems="center" gap={2}>
          <AvatarHelper image={currentUser.image} />

          <Text textStyle="body1Medium" noOfLines={1}>
            {currentUser.name}
          </Text>
        </Flex>

        {isNotInterested ? (
          <Flex
            w={9}
            h={9}
            pb="9px"
            pt="11px"
            bg="red.100"
            alignItems="center"
            justifyContent="center"
            borderRadius="full"
          >
            <ThumbDownIcon color="red.400" w={4} h={4} />
          </Flex>
        ) : (
          <Flex
            w={9}
            h={9}
            pt="9px"
            pb="11px"
            bg="green.100"
            alignItems="center"
            justifyContent="center"
            borderRadius="full"
          >
            <ThumbUpIcon color="green.400" w={4} h={4} />
          </Flex>
        )}
      </Flex>

      <ViewJobCandidateModalPhoneSidebarEnterNoteForm
        allowScheduling={isNotInterested ? false : true}
        onDiscard={onDiscard}
        onSave={onSave}
      />
    </Stack>
  );
};

export default ViewJobCandidateModalPhoneSidebarEnterCallNote;
