import { FieldBrand, ItemRecordID } from '../../../types';

import { UserUuid } from '../../users/usersTypes';

export type CompanyMemberRecordId = FieldBrand<
  ItemRecordID,
  'CompanyMemberRecordId'
>;

export type CompanyMemberUserUuid = FieldBrand<
  UserUuid,
  'CompanyMemberUserUuid'
>;

export const enum CompanyMemberIndexFields {
  ID = 'id',
  RECORD_ID = 'recordId',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  CREATED_AT = 'createdAt',

  USER_UUID = 'userUuid'
}
