import Head from 'next/head';

import { APP_NAME } from '../../config';

interface PageHeadProps {
  i18nTitle: string;
}

function PageHead({ i18nTitle }: PageHeadProps) {
  const title = `${i18nTitle} - ${APP_NAME}`;
  return (
    <Head>
      <title>{title}</title>
    </Head>
  );
}

export default PageHead;
