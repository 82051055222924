import React, { ForwardedRef, ReactNode } from 'react';
import {
  Checkbox as ChakraCheckbox,
  CheckboxProps as ChakraCheckboxProps
} from '@chakra-ui/checkbox';

import { mapSize } from '../../../../utils/mapSize';

export interface CheckboxFieldProps extends ChakraCheckboxProps {
  id?: string;
  label?: ReactNode;
  children?: ReactNode;
  size?: 'extra-small' | 'small' | 'medium' | 'large';
  defaultChecked?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  isReadOnly?: boolean;
}

/**
 * Checkbox
 *
 * React component used in forms when a user needs to select
 * multiple values from several options.
 */
const CheckboxField = React.forwardRef<HTMLInputElement, CheckboxFieldProps>(
  (
    {
      id,
      label,
      isDisabled,
      children,
      defaultChecked,
      size = 'medium',
      isReadOnly,
      ...props
    }: CheckboxFieldProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <ChakraCheckbox
        id={id}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        size={mapSize(size)}
        defaultChecked={defaultChecked}
        ref={ref}
        {...props}
      >
        {label || children}
      </ChakraCheckbox>
    );
  }
);

CheckboxField.displayName = 'CheckBoxField';

export default CheckboxField;
