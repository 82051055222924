import {
  Avatar,
  AvatarGroup,
  Box,
  ButtonGroup,
  Collapse,
  Flex,
  Stack,
  useDisclosure
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { ReactNode, useState } from 'react';
import { Button } from '../../../../../../helpers/Button';
import { Heading } from '../../../../../../helpers/Heading';
import { Text } from '../../../../../../helpers/Text';
import { IconButton } from '../../../../../../helpers/buttons/IconButton';
import { AlarmIcon } from '../../../../../../icons/AlarmIcon';
import { ChevronDownIcon } from '../../../../../../icons/ChevronDownIcon';
import { DeleteIcon } from '../../../../../../icons/DeleteIcon';
import { EditIcon } from '../../../../../../icons/EditIcon';
import { EmailIcon } from '../../../../../../icons/EmailIcon';
import { IncomingIcon } from '../../../../../../icons/IncomingIcon';
import { MessageIcon } from '../../../../../../icons/MessageIcon';
import { PhoneIcon } from '../../../../../../icons/PhoneIcon';
import { FetchJobCandidateResponse } from '../../../../../jobCandidates/queries/fetchJobCandidate.query';

interface SourcingTalentProfileActivitiesProps {
  isOpen: boolean;
  onClose: () => void;
  jobCandidate: FetchJobCandidateResponse;
}

const SourcingTalentProfileActivities = ({
  isOpen
}: SourcingTalentProfileActivitiesProps) => {
  const [hidden, setHidden] = useState(!isOpen);

  return (
    <motion.div
      hidden={hidden}
      initial={false}
      onAnimationStart={() => setHidden(false)}
      onAnimationComplete={() => setHidden(!isOpen)}
      animate={{ width: isOpen ? '400px' : 0 }}
      style={{
        overflow: 'auto',
        height: '100%'
      }}
    >
      <Stack
        px={6}
        py={8}
        w="100%"
        bg="white"
        spacing={4}
        overflow="auto"
        borderLeft="1px solid"
        borderLeftColor="gray.200"
      >
        <Heading level="h3">Activities</Heading>

        <Stack spacing={3}>
          <PeopleTalentProfileActivityItem>
            <Stack spacing={2}>
              <Flex gap={3} alignItems="center">
                <Flex
                  h={7}
                  w={7}
                  bg="yellow.100"
                  borderRadius="full"
                  alignItems="center"
                  justifyContent="center"
                >
                  <AlarmIcon w={4} h={4} fill="yellow.500" />
                </Flex>
                <Text textStyle="body1Medium">
                  <Text
                    as="span"
                    textStyle="body1Medium"
                    color="yellow.500"
                    mr={1}
                  >
                    Reminder
                  </Text>
                  Tomorrow
                </Text>

                <Stack spacing={0} ml="auto">
                  <Text
                    color="gray.600"
                    textAlign="right"
                    textStyle="body2Regular"
                  >
                    1 hour interview
                  </Text>
                  <Text
                    as="span"
                    color="gray.800"
                    textAlign="right"
                    textStyle="body2Medium"
                    ml={1}
                  >
                    2:30:-3:30PM
                  </Text>
                </Stack>
              </Flex>

              <Flex gap={2} alignItems="center">
                <Flex flex={1} flexDir="column">
                  <Text color="gray.600" textStyle="body2Regular">
                    Interview Panel
                  </Text>

                  <AvatarGroup spacing={-2}>
                    <Avatar src="/images/default-avatar-3.png" size="sm" />
                    <Avatar src="/images/default-avatar-1.png" size="sm" />
                    <Avatar src="/images/default-avatar-4.png" size="sm" />
                    <Avatar src="/images/default-avatar-5.png" size="sm" />
                    <Avatar src="/images/default-avatar-2.png" size="sm" />
                  </AvatarGroup>
                </Flex>

                <Stack spacing={0}>
                  <Text
                    mt={3}
                    textStyle="body2Regular"
                    color="gray.600"
                    textAlign="right"
                  >
                    Scheduled by
                    <Text
                      as="span"
                      textStyle="body2Medium"
                      color="gray.800"
                      ml={1}
                    >
                      Tony lomi
                    </Text>
                  </Text>
                  <Text
                    textStyle="body2Regular"
                    color="gray.600"
                    textAlign="right"
                  >
                    Written Test - Onsite
                  </Text>
                </Stack>
              </Flex>
            </Stack>
          </PeopleTalentProfileActivityItem>

          <PeopleTalentProfileActivityItem isTruncated>
            <Stack spacing={3}>
              <Flex gap={3} alignItems="center">
                <Avatar w={7} h={7} size="xs" src="/images/team-mate-4.png" />
                <Text textStyle="body1Medium">Tamera Petren</Text>
                <IncomingIcon />
                <Text ml="auto" color="gray.600" textStyle="body2Regular">
                  1 mo ago
                </Text>
              </Flex>

              <Flex gap={2}>
                <Flex
                  h={7}
                  w={7}
                  bg="blue.50"
                  borderRadius="full"
                  alignItems="center"
                  justifyContent="center"
                >
                  <EmailIcon w={4} h={4} fill="blue.400" />
                </Flex>

                <Stack spacing={0}>
                  <Text textStyle="body2Medium">
                    Lighting designer @ Apple Inc.
                  </Text>
                  <Text color="gray.600" textStyle="body2Regular">
                    Re: What are the benefits of skills V2?
                  </Text>
                </Stack>
              </Flex>
            </Stack>
          </PeopleTalentProfileActivityItem>

          <PeopleTalentProfileActivityItem isTruncated>
            <Stack spacing={3}>
              <Flex gap={3} alignItems="center">
                <Avatar
                  w={7}
                  h={7}
                  size="xs"
                  src="/images/default-avatar-4.png"
                />
                <Text textStyle="body1Medium">Adam John logged a call</Text>
                <Text ml="auto" color="gray.600" textStyle="body2Regular">
                  1 mo ago
                </Text>
              </Flex>

              <Flex gap={2}>
                <Flex
                  h={7}
                  w={7}
                  bg="blue.50"
                  borderRadius="full"
                  alignItems="center"
                  justifyContent="center"
                >
                  <PhoneIcon w={3} h={3} fill="blue.400" />
                </Flex>

                <Stack spacing={0}>
                  <Text textStyle="body2Medium">
                    Lighting designer @ Apple Inc.
                  </Text>
                  <Text color="gray.600" textStyle="body2Regular">
                    Re: What are the benefits of skills V2?
                  </Text>
                </Stack>
              </Flex>
            </Stack>
          </PeopleTalentProfileActivityItem>

          <PeopleTalentProfileActivityItem isTruncated>
            <Stack spacing={3}>
              <Flex gap={3} alignItems="center">
                <Avatar w={7} h={7} size="xs" src="/images/team-mate-1.png" />
                <Text textStyle="body1Medium">Elisha Cuthbert</Text>
                <Text ml="auto" color="gray.600" textStyle="body2Regular">
                  1 mo ago
                </Text>
              </Flex>

              <Flex gap={2}>
                <Flex
                  h={7}
                  w={7}
                  bg="purple.50"
                  borderRadius="full"
                  alignItems="center"
                  justifyContent="center"
                >
                  <MessageIcon w={4} h={4} fill="purple.400" />
                </Flex>

                <Stack spacing={0}>
                  <Text textStyle="body2Medium">
                    Lighting designer @ Apple Inc.
                  </Text>
                  <Text color="gray.600" textStyle="body2Regular">
                    Re: What are the benefits of skills V2?
                  </Text>
                </Stack>
              </Flex>
            </Stack>
          </PeopleTalentProfileActivityItem>

          <PeopleTalentProfileActivityItem isTruncated>
            <Stack spacing={3}>
              <Flex gap={3} alignItems="center">
                <Avatar
                  w={7}
                  h={7}
                  size="xs"
                  src="/images/default-avatar-7.png"
                />
                <Text textStyle="body1Medium">Vito Corleone</Text>
                <Text ml="auto" color="gray.600" textStyle="body2Regular">
                  1 mo ago
                </Text>
              </Flex>

              <Flex gap={2}>
                <Flex
                  h={7}
                  w={7}
                  bg="green.50"
                  borderRadius="full"
                  alignItems="center"
                  justifyContent="center"
                >
                  <EmailIcon w={4} h={4} fill="green.400" />
                </Flex>

                <Stack spacing={0}>
                  <Text textStyle="body2Medium">
                    Lighting designer @ Apple Inc.
                  </Text>
                  <Text color="gray.600" textStyle="body2Regular">
                    Re: What are the benefits of skills V2?
                  </Text>
                </Stack>
              </Flex>
            </Stack>
          </PeopleTalentProfileActivityItem>

          <PeopleTalentProfileActivityItem isTruncated>
            <Stack spacing={3}>
              <Flex gap={3} alignItems="center">
                <Avatar
                  w={7}
                  h={7}
                  size="xs"
                  src="/images/default-avatar-1.png"
                />
                <Text textStyle="body1Medium">Michele Young added a note</Text>
                <Text ml="auto" color="gray.600" textStyle="body2Regular">
                  1 mo ago
                </Text>
              </Flex>

              <Flex gap={2}>
                <Flex
                  h={7}
                  w={7}
                  bg="purple.50"
                  borderRadius="full"
                  alignItems="center"
                  justifyContent="center"
                >
                  <MessageIcon w={4} h={4} fill="purple.400" />
                </Flex>

                <Stack spacing={0}>
                  <Text textStyle="body2Medium">
                    Lighting designer @ Apple Inc.
                  </Text>
                  <Text color="gray.600" textStyle="body2Regular">
                    Re: What are the benefits of skills V2?
                  </Text>
                </Stack>
              </Flex>
            </Stack>
          </PeopleTalentProfileActivityItem>

          <PeopleTalentProfileActivityItem isTruncated>
            <Stack spacing={3}>
              <Flex gap={3} alignItems="center">
                <Avatar
                  w={7}
                  h={7}
                  size="xs"
                  src="/images/default-avatar-4.png"
                />
                <Text textStyle="body1Medium">Adam John logged a call</Text>
                <Text ml="auto" color="gray.600" textStyle="body2Regular">
                  1 mo ago
                </Text>
              </Flex>

              <Flex gap={2}>
                <Flex
                  h={7}
                  w={7}
                  bg="blue.50"
                  borderRadius="full"
                  alignItems="center"
                  justifyContent="center"
                >
                  <PhoneIcon w={3} h={3} fill="blue.400" />
                </Flex>

                <Stack spacing={0}>
                  <Text textStyle="body2Medium">
                    Lighting designer @ Apple Inc.
                  </Text>
                  <Text color="gray.600" textStyle="body2Regular">
                    Re: What are the benefits of skills V2?
                  </Text>
                </Stack>
              </Flex>
            </Stack>
          </PeopleTalentProfileActivityItem>
        </Stack>
      </Stack>
    </motion.div>
  );
};

export default SourcingTalentProfileActivities;

interface PeopleTalentProfileActivityItemProps {
  children: ReactNode;
  isTruncated?: boolean;
}

const PeopleTalentProfileActivityItem = ({
  children,
  isTruncated
}: PeopleTalentProfileActivityItemProps) => {
  const { isOpen: showAll, onToggle: toggleShowAll } = useDisclosure();

  return (
    <Stack
      p={4}
      spacing={0}
      border="1px solid"
      borderColor="gray.200"
      borderRadius="base"
    >
      <Flex flexDir="column" gap={2}>
        {children}

        <Flex>
          <Flex flex={1} />
          <Flex flex={1}>
            {isTruncated && (
              <Flex justifyContent="center">
                <Button
                  hierarchy="link"
                  size="medium"
                  onClick={toggleShowAll}
                  gap={1}
                >
                  {showAll ? <Text>See less</Text> : <Text>See more</Text>}
                  <ChevronDownIcon
                    transitionDuration="slow"
                    transitionProperty="transform"
                    transform={showAll ? 'rotate(0.5turn)' : 'rotate(0turn)'}
                  />
                </Button>
              </Flex>
            )}
          </Flex>

          <ButtonGroup justifyContent="flex-end">
            <IconButton
              minW={5}
              size="small"
              aria-label="Delete"
              hierarchy="link"
              icon={<EditIcon fill="gray.500" />}
            />
            <IconButton
              minW={5}
              size="small"
              aria-label="Delete"
              hierarchy="link"
              icon={<DeleteIcon fill="gray.500" />}
            />
          </ButtonGroup>
        </Flex>
      </Flex>

      <Collapse in={showAll}>
        <Box pt={4}>HELLO WORLD</Box>
      </Collapse>
    </Stack>
  );
};
