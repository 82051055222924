import {
  FieldBrand,
  ItemID,
  ItemUUID,
  ItemNanoId,
  ItemCreatedAt,
  MayBe,
  ItemIDs
} from '../../../types';
import {
  DisciplineId,
  DisciplineName
} from '../../disciplines/disciplinesTypes';

import {
  UserId,
  UserNanoId,
  UserName,
  UserImage
} from '../../users/usersTypes';

export type JobFunctionId = FieldBrand<ItemID, 'JobFunctionId'>;
export type JobFunctionIds = FieldBrand<ItemIDs, 'JobFunctionIds'>;
export type JobFunctionUuid = FieldBrand<ItemUUID, 'JobFunctionUuid'>;
export type MayBeJobFunctionUuid = MayBe<JobFunctionUuid>;
export type JobFunctionNanoId = FieldBrand<ItemNanoId, 'JobFunctionNanoId'>;
export type MayBeJobFunctionNanoId = MayBe<JobFunctionNanoId>;
export type JobFunctionName = FieldBrand<string, 'JobFunctionName'>;
export type JobFunctionDisciplineId = FieldBrand<
  DisciplineId,
  'JobFunctionDisciplineId'
>;
export type JobFunctionDiscipline = FieldBrand<
  { id: DisciplineId; name: DisciplineName },
  'JobFunctionDiscipline'
>;
export type JobFunctionUserId = FieldBrand<UserId, 'JobFunctionUserId'>;
export type JobFunctionUser = FieldBrand<
  {
    id: UserId;
    nanoId: UserNanoId;
    name: UserName;
    image: UserImage;
  },
  'JobFunctionUser'
>;
export type JobFunctionCreatedAt = FieldBrand<
  ItemCreatedAt,
  'JobFunctionCreatedAt'
>;

export const enum JobFunctionFields {
  ID = 'id',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  NAME = 'name',
  DISCIPLINE = 'discipline',
  DISCIPLINE_ID = 'disciplineId',
  USER_ID = 'userId',
  USER = 'user',
  CREATED_AT = 'createdAt'
}
