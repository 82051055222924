import { DateTime, DateTimeUnit } from 'luxon';

export enum DateTimeFormats {
  YYYY_LL_DD = 'yyyy-LL-dd',
  DD_MMM_YYYY = 'dd MMM yyyy',
  MMM_DD = 'MMM dd',
  CCC_MMM_DD = 'ccc, MMM dd',
  CCCC_MMM_DD = 'cccc, MMM dd',
  MM_YYYY = 'MM yyyy',
  MMM_YYYY = 'MMM yyyy',
  MM_DD_YYYY = 'MM/dd/yyyy',
  MMMM_YYYY = 'MMMM yyyy',
  D = 'd',
  F = 'f',
  DDDD = 'DDDD',
  CCC = 'ccc',
  CCCC = 'cccc',
  YYYY = 'yyyy',
  CCC_LL_DD_YYYY_HH_MM_A = 'ccc LL/dd/yyyy hh:mm a',
  HH_MM_A = 'hh:mm a',
  H_MM_A = 'h:mm a'
}

export type DateTimeType = DateTime;

export type DateTimeUnitType = DateTimeUnit;
