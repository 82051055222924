import { Avatar, Flex, HStack, Stack } from '@chakra-ui/react';
import React from 'react';
import { Text } from '../../../Text';

export default function ScheduleHelperGuests() {
  return (
    <Flex
      px={4}
      py={3}
      gap={4}
      border="1px solid"
      borderColor="gray.200"
      borderRadius="base"
    >
      <HStack>
        <Avatar size="sm" />

        <Stack spacing={0} flexDir="column">
          <Text lineHeight={1.5} textStyle="body1Medium">
            Benedict Swan
          </Text>

          <Text lineHeight={1.5} textStyle="body2Regular" color="gray.600">
            ben10@gmail.com
          </Text>
        </Stack>
      </HStack>

      <Stack ml="auto" justifyContent="flex-end" alignItems="flex-end">
        <Text color="gray.600" textAlign="right" textStyle="body2Regular">
          Interview panel
        </Text>

        <HStack spacing={-2}>
          <Avatar
            src="/images/default-avatar-3.png"
            size="xs"
            border="1px solid"
            borderColor="white"
          />

          <Avatar
            src="/images/default-avatar-1.png"
            size="xs"
            border="1px solid"
            borderColor="white"
          />

          <Avatar
            src="/images/default-avatar-4.png"
            size="xs"
            border="1px solid"
            borderColor="white"
          />

          <Avatar
            src="/images/default-avatar-5.png"
            size="xs"
            border="1px solid"
            borderColor="white"
          />

          <Avatar
            src="/images/default-avatar-2.png"
            size="xs"
            border="1px solid"
            borderColor="white"
          />
        </HStack>
      </Stack>
    </Flex>
  );
}
