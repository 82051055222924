import { Icon, IconProps } from '@chakra-ui/icon';

const AddCandidateIcon = ({
  w = '20px',
  h = '20px',
  fill = 'white',
  ...props
}: IconProps) => (
  <Icon viewBox="0 0 20 20" w={w} h={h} fill={fill} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.688 5.289C3.688 3.97 4.918 2.5 6.04 2.5c0 0 .914 0 1.56.747.085.1.06.243-.053.31-.502.3-1.507.901-1.507 3.574 0 1.033.737 2.448 1.122 3.118.127.22.079.501-.12.658l-1.002.797c-.926.736-1.349 1.076-1.756 1.681a.901.901 0 0 1-.739.414h-.88a.499.499 0 0 1-.316-.087c-.44-.35-.517-1.288-.517-1.894 0-.69.798-1.796 2.98-3.111.177-.075.177-.491 0-.678-.993-.862-1.124-1.799-1.124-2.74Zm10.63 1.628c0-1.952-1.5-3.48-3.33-3.48-1.829 0-3.33 1.59-3.33 3.48 0 1.85.555 2.522 1.262 3.379l.045.054c.18.218.465.795 0 1.167-.357.276-.703.532-1.032.775-1.597 1.183-2.772 2.053-2.772 3.332 0 1.084.29 1.518 1.417 1.85a.631.631 0 0 0 .178.025h8.343c.08 0 .162-.012.239-.036 1.478-.466 1.478-.976 1.478-1.839 0-1.253-1.219-2.164-3.879-4.07a.392.392 0 0 1-.094-.086c-.187-.263-.174-.882.041-1.118.912-.87 1.434-1.482 1.434-3.433ZM19.84 4.332h-1.837V2.5H16.67v1.832h-1.83v1.334h1.83V7.5h1.333V5.666h1.837V4.332Z"
    />
  </Icon>
);

export default AddCandidateIcon;
