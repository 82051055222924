import { Avatar } from './Avatar';
import { Button } from './Button';
import { Checkbox } from './Checkbox';
import { Container } from './Container';
import { Heading } from './Heading';
import { Input } from './Input';
import { Link } from './Link';
import { Radio } from './Radio';
import { Switch } from './Switch';
import { Table } from './Table';
import { Textarea } from './Textarea';

const components = {
  Avatar,
  Button,
  Checkbox,
  Container,
  Heading,
  Link,
  Input,
  Radio,
  Switch,
  Table,
  Textarea
};

export default components;
