import {
  FieldBrand,
  ItemID,
  ItemUUID,
  ItemNanoId,
  ItemCreatedAt,
  MayBe
} from '../../../types';

import {
  UserId,
  UserNanoId,
  UserName,
  UserFullName,
  UserEmail,
  UserImage,
  UserRoles
} from '../../users/usersTypes';

export type CountryId = FieldBrand<ItemID, 'CountryId'>;
export type MayBeCountryId = MayBe<CountryId>;
export type CountryUuid = FieldBrand<ItemUUID, 'CountryUuid'>;
export type MayBeCountryUuid = MayBe<CountryUuid>;
export type CountryNanoId = FieldBrand<ItemNanoId, 'CountryNanoId'>;
export type MayBeCountryNanoId = MayBe<CountryNanoId>;
export type CountryName = FieldBrand<string, 'CountryName'>;
export type CountryCode = FieldBrand<string, 'CountryCode'>;
export type CountryLabel = FieldBrand<string, 'CountryLabel'>;
export type CountryUserId = FieldBrand<UserId, 'CountryUserId'>;
export type MayBeCountryUserId = MayBe<CountryUserId>;
export type CountryUser = FieldBrand<
  {
    id: UserId;
    nanoId: UserNanoId;
    name: UserName;
    fullName: UserFullName;
    email: UserEmail;
    image: UserImage;
    roles: UserRoles;
  },
  'CountryUser'
>;
export type MayBeCountryUser = MayBe<CountryUser>;
export type CountryCreatedAt = FieldBrand<ItemCreatedAt, 'CountryCreatedAt'>;

export const enum CountryFields {
  ID = 'id',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  NAME = 'name',
  LABEL = 'label',
  ORDER = 'order',
  CREATED_AT = 'createdAt'
}
