import { Avatar, HStack } from '@chakra-ui/react';
import { Text } from '../../../../../../helpers/Text';

export default function ViewJobCandidateDetailsJobHiringTeam() {
  return (
    <HStack>
      <Text>Hiring team</Text>

      <HStack spacing={-3} overflow="hidden" transitionDuration="slow">
        <Avatar
          h={7}
          w={7}
          border="1px solid"
          borderColor="white"
          src="/images/recruiter-avatar-3.jpg"
        />

        <Avatar
          h={7}
          w={7}
          border="1px solid"
          borderColor="white"
          src="/images/recruiter-avatar-18.jpg"
        />

        <Avatar
          h={7}
          w={7}
          border="1px solid"
          borderColor="white"
          src="/images/recruiter-avatar-8.jpg"
        />

        <Avatar
          h={7}
          w={7}
          border="1px solid"
          borderColor="white"
          src="/images/recruiter-avatar-5.jpg"
        />
      </HStack>
    </HStack>
  );
}
