import {
  FetchIndustryExpertisesCacheKey,
  FetchIndustryExpertiseCacheKey,
  UpdateIndustryExpertiseID,
  UpdateIndustryExpertiseErrorMessage,
  UpdateIndustryExpertiseIsLoading
} from '../../industryExpertisesTypes';

import {
  IndustryExpertisesBffRequests,
  UpdateIndustryExpertiseRequestData,
  UpdateIndustryExpertiseErrorResponse
} from '../../IndustryExpertisesBffRequests';

import { useUpdateItem } from '../../../common/hooks/useUpdateItem';

interface UpdateIndustryExpertiseOptions {
  industryExpertiseId: UpdateIndustryExpertiseID;
  cacheKeys?: (
    | FetchIndustryExpertisesCacheKey
    | FetchIndustryExpertiseCacheKey
  )[];
}

function useUpdateIndustryExpertise({
  industryExpertiseId,
  cacheKeys = []
}: UpdateIndustryExpertiseOptions) {
  const {
    updateItemData,
    updateItemError,
    updateItemErrorMessage,
    updateItemIsLoading,
    updateItem,
    updateItemReset
  } = useUpdateItem<
    UpdateIndustryExpertiseRequestData,
    UpdateIndustryExpertiseErrorResponse
  >({
    mutationFn: (queryInput) =>
      IndustryExpertisesBffRequests.updateIndustryExpertise(
        industryExpertiseId,
        queryInput
      ),
    cacheKeys
  });

  return {
    updateIndustryExpertiseData: updateItemData,
    updateIndustryExpertiseError: updateItemError,
    updateIndustryExpertiseErrorMessage:
      updateItemErrorMessage as UpdateIndustryExpertiseErrorMessage,
    updateIndustryExpertiseIsLoading:
      updateItemIsLoading as UpdateIndustryExpertiseIsLoading,
    updateIndustryExpertise: updateItem,
    updateIndustryExpertiseReset: updateItemReset
  };
}

export default useUpdateIndustryExpertise;
