import { useCallback, useEffect, useState } from 'react';
import find from 'lodash/find';
import first from 'lodash/first';
import size from 'lodash/size';

import {
  SelectFieldControl,
  SelectStyleObject
} from '../../../../../../../../../helpers/forms/formFields/SelectField/components/SelectFieldControl';

import {
  JobId,
  JobNanoId
} from '../../../../../../../../../main/jobs/jobsTypes';
import { WorkflowStageOption } from './SelectWorkflowStageField.types';
import {
  FetchWorkflowStagesEnabled,
  FetchWorkflowStagesFilters,
  FetchWorkflowStagesSort,
  WorkflowStageFields,
  WorkflowStageId,
  WorkflowStageJobCandidates,
  WorkflowStageName,
  WorkflowStageNanoId,
  WorkflowStageWorkflowStageTasks
} from '../../../../../../../../../main/workflowStages/workflowStagesTypes';

import { usePaginatedWorkflowStages } from '../../../../../../../../../main/workflowStages/hooks/usePaginatedWorkflowStages';
import {
  fetchWorkflowStagesQuery,
  FetchWorkflowStagesResponse
} from '../../../../../../../../../main/workflowStages/queries/fetchWorkflowStages.query';
import { WorkflowStagesCache } from '../../../../../../../../../main/workflowStages/WorkflowStagesCache';

interface SelectWorkflowStageFieldProps {
  handleSelectWorkflowStage: (selectedOption: WorkflowStageOption) => void;
  selectedJob: {
    id: JobId;
    nanoId: JobNanoId;
  };
  selectedWorkflowStage: {
    id: WorkflowStageId;
    name: WorkflowStageName;
    nanoId: WorkflowStageNanoId;
    jobCandidates: WorkflowStageJobCandidates;
    workflowStageTasks: WorkflowStageWorkflowStageTasks;
  };
  isTaskUpdated: boolean;
  handleIsTaskUpdatedFalse: () => void;
}

function SelectWorkflowStageField({
  handleSelectWorkflowStage,
  selectedJob,
  selectedWorkflowStage,
  isTaskUpdated,
  handleIsTaskUpdatedFalse
}: SelectWorkflowStageFieldProps) {
  const {
    workflowStages: workflowStagesResponse,
    workflowStagesErrorMessage,
    workflowStagesIsLoading,
    fetchWorkflowStages
  } = usePaginatedWorkflowStages<FetchWorkflowStagesResponse>({
    query: fetchWorkflowStagesQuery,
    cacheKey: WorkflowStagesCache.jobIndexCacheKey(
      selectedJob.nanoId as JobNanoId
    ),
    initialFilters: {
      [WorkflowStageFields.JOB_ID]: { operator: 'eq', value: selectedJob.id }
    } as unknown as FetchWorkflowStagesFilters,
    initialSort: {
      [WorkflowStageFields.ARRANGEMENT]: { ascending: true }
    } as unknown as FetchWorkflowStagesSort,
    enabled: false as FetchWorkflowStagesEnabled
  });

  const [workflowStages, setWorkflowStages] = useState<
    FetchWorkflowStagesResponse[]
  >(workflowStagesResponse);
  const [isRefetched, setIsRefetched] = useState(false);

  const fetchUpdatedWorkflowStages = useCallback<
    (selectedJobId?: JobId) => void
  >(
    (selectedJobId) => {
      fetchWorkflowStages({
        nextFilters: {
          [WorkflowStageFields.JOB_ID]: {
            operator: 'eq',
            value: selectedJobId || selectedJob.id
          }
        }
      }).then((res) => setWorkflowStages(res.data));
    },
    [fetchWorkflowStages, selectedJob.id]
  );

  const options = workflowStages.map(
    (workflowStage: FetchWorkflowStagesResponse) => ({
      value: workflowStage.id,
      label: workflowStage.name,
      nanoId: workflowStage.nanoId,
      jobCandidates: workflowStage.jobCandidates,
      workflowStageTasks: workflowStage.workflowStageTasks
    })
  );

  useEffect(() => {
    fetchUpdatedWorkflowStages(selectedJob.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedJob]);

  useEffect(() => {
    if (size(workflowStages) > 0) {
      const workflowStage =
        selectedWorkflowStage.id && isRefetched
          ? find(workflowStages, { id: selectedWorkflowStage.id })
          : first(workflowStages);
      handleSelectWorkflowStage({
        value: workflowStage?.id,
        label: workflowStage?.name,
        nanoId: workflowStage?.nanoId,
        jobCandidates: workflowStage?.jobCandidates,
        workflowStageTasks: workflowStage?.workflowStageTasks
      } as WorkflowStageOption);

      setIsRefetched(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedJob, workflowStages, isRefetched]);

  useEffect(() => {
    if (isTaskUpdated) {
      setTimeout(() => {
        fetchUpdatedWorkflowStages();
        setIsRefetched(true);
      }, 10);
      handleIsTaskUpdatedFalse();
    }
  }, [isTaskUpdated, fetchUpdatedWorkflowStages, handleIsTaskUpdatedFalse]);

  return (
    <SelectFieldControl
      placeholder="Select Workflow Stage"
      options={options}
      onChange={(value, selectedOption) =>
        handleSelectWorkflowStage(selectedOption as WorkflowStageOption)
      }
      value={selectedWorkflowStage.id}
      errorMessage={workflowStagesErrorMessage}
      isLoading={workflowStagesIsLoading}
      isSearchable={false}
      reactSelectStyles={{
        control: (provided: SelectStyleObject) => ({
          ...provided,
          fontWeight: 500
        })
      }}
    />
  );
}

export default SelectWorkflowStageField;
