import { Icon, IconProps } from '@chakra-ui/icon';

const TaskIcon = ({
  w = '24px',
  h = '24px',
  fill = 'gray.500',

  ...props
}: IconProps) => (
  <Icon w={w} h={h} viewBox="0 0 16 16" fill={fill} {...props}>
    <path
      fillRule="evenodd"
      d="M3.637 4.084c0-.288.244-.521.545-.521h.091a1 1 0 011 1v.565c0 .288.244.521.546.521h1.09a.534.534 0 00.546-.521c0-.288.244-.522.545-.522.301 0 .546.234.546.522 0 .288.244.521.545.521h1.091a.534.534 0 00.546-.521v-.566a1 1 0 011-1h.09c.302 0 .546.234.546.522v7.826a.534.534 0 01-.546.522H4.183a.534.534 0 01-.545-.522V4.084zM2 13.478c0 .288.244.522.545.522h10.91a.534.534 0 00.545-.522V2.522A.534.534 0 0013.454 2H2.545A.534.534 0 002 2.522v10.956z"
      clipRule="evenodd"
    />

    <path
      fillRule="evenodd"
      d="M10.181 7.74a.522.522 0 00-.521-.521H6.34a.522.522 0 100 1.043h3.32a.522.522 0 00.521-.522zM10.181 9.824a.522.522 0 00-.521-.522H6.34a.522.522 0 000 1.044h3.32a.522.522 0 00.521-.522z"
      clipRule="evenodd"
    />
  </Icon>
);
export default TaskIcon;
