import { Icon, IconProps } from '@chakra-ui/icon';

const ArrowLeftRightIcon = ({
  w = '24px',
  h = '24px',
  fill = 'gray.500',
  ...props
}: IconProps) => (
  <Icon w={w} h={h} viewBox="0 0 16 16" fill={fill} {...props}>
    <path d="M1.187 4.632a.637.637 0 010-.9l2.545-2.545a.636.636 0 011.086.45v1.909h9.545a.636.636 0 010 1.272H4.818v1.91a.636.636 0 01-1.086.45L1.187 4.632zm13.626 6.736l-2.545-2.546a.636.636 0 00-1.086.45v1.91H1.637a.636.636 0 100 1.272h9.545v1.91a.637.637 0 001.086.45l2.545-2.546a.637.637 0 000-.9z" />
  </Icon>
);
export default ArrowLeftRightIcon;
