const usageRules = {
  required: 'Skill Usage is required'
};

function useAddEditEmploymentSkillBadgeFormValidationRules() {
  return {
    usageRules
  };
}

export default useAddEditEmploymentSkillBadgeFormValidationRules;
