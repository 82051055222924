import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  ReactNode,
  Dispatch,
  SetStateAction
} from 'react';

// Define an interface for the context value
interface TotalTalentCreditsContextType {
  totalTalentCredits: number | undefined;
  setTotalTalentCredits: Dispatch<SetStateAction<number | undefined>>;
}

// Create the context with the correct type
const TotalTalentCreditsContext = createContext<
  TotalTalentCreditsContextType | undefined
>(undefined);

export const TotalTalentCreditsProvider = ({
  children
}: {
  children: ReactNode;
}): JSX.Element => {
  const [totalTalentCredits, setTotalTalentCredits] = useState<
    number | undefined
  >(0);

  const value = useMemo(
    () => ({ totalTalentCredits, setTotalTalentCredits }),
    [totalTalentCredits]
  );

  return (
    <TotalTalentCreditsContext.Provider value={value}>
      {children}
    </TotalTalentCreditsContext.Provider>
  );
};

export const useTotalTalentCredits = () => {
  const context = useContext(TotalTalentCreditsContext);
  if (!context) {
    throw new Error(
      'useTotalTalentCredits must be used within a TotalTalentCreditsProvider'
    );
  }
  return context;
};
