import { FetchItemsPageSize } from '../../../../../../types';

import { PaginatedItemsReducerAction } from '../../reducers/paginatedItemsReducer';

import { CHANGE_ITEMS_PAGE_SIZE } from '../../usePaginatedItems.constants';

function changeItemsPageSizeAction(
  nextPageSize: FetchItemsPageSize
): PaginatedItemsReducerAction {
  return {
    type: CHANGE_ITEMS_PAGE_SIZE,
    nextPageSize
  };
}

export default changeItemsPageSizeAction;
