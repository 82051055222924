import { Box, Flex, Stack } from '@chakra-ui/react';
import { Text } from '../../../Text';
import { AccentText, HighlightText, SearchItem } from '../../HeaderSearch';
import { HeaderSaveSearch } from '../HeaderSaveSearch';

const HeaderSearchResult = ({ searchParam }: { searchParam: string }) => {
  return (
    <Flex alignItems="flex-start" gap={4}>
      <Flex gap={4} flexDir="column" flex={1}>
        <Flex alignItems="center">
          <Text textStyle="body1Medium" color="gray.600">
            Showing 8 results for{' '}
            <Box as="span" color="gray.900">
              &apos;{searchParam}&apos;
            </Box>
          </Text>

          <HeaderSaveSearch />
        </Flex>

        <Stack maxH={64} spacing={4} overflow="auto" pr={4}>
          <SearchItem
            avatarUrl="/images/default-avatar-3.png"
            title="Michelle Yeoh"
            content={
              <>
                Changed Kevin <AccentText>Hart&apos;s</AccentText> application
                from <HighlightText>Sourcing</HighlightText> to{' '}
                <HighlightText>Screening</HighlightText>
              </>
            }
          />
          <SearchItem
            avatarUrl="/images/default-avatar-1.png"
            title="Add a job/ Select a job to post/ Update"
            content={
              <>
                In <HighlightText>Waiting for review</HighlightText> to{' '}
                <HighlightText>Automobili Lamborghini</HighlightText>
              </>
            }
          />
          <SearchItem
            avatarUrl="/images/default-avatar-7.png"
            title="Add a job/ Select a job to post/ Update"
            content={
              <>
                In <HighlightText>Waiting for review</HighlightText> to{' '}
                <HighlightText>Automobili Lamborghini</HighlightText>
              </>
            }
          />
          <SearchItem
            avatarUrl="/images/default-avatar-2.png"
            title="Michelle Nunez"
            content={
              <>
                In <HighlightText>C#/React developer</HighlightText> on{' '}
                <HighlightText>Mitsubishi Tech</HighlightText>
              </>
            }
          />
          <SearchItem
            avatarUrl="/images/default-avatar-5.png"
            title="Michelle Yeoh"
            content={
              <>
                Changed Kevin <AccentText>Hart&apos;s</AccentText> application
                from <HighlightText>Sourcing</HighlightText> to{' '}
                <HighlightText>Screening</HighlightText>
              </>
            }
          />
          <SearchItem
            avatarUrl="/images/default-avatar-1.png"
            title="Add a job/ Select a job to post/ Update"
            content={
              <>
                In <HighlightText>Waiting for review</HighlightText> to{' '}
                <HighlightText>Automobili Lamborghini</HighlightText>
              </>
            }
          />
          <SearchItem
            avatarUrl="/images/default-avatar-4.png"
            title="Add a job/ Select a job to post/ Update"
            content={
              <>
                In <HighlightText>Waiting for review</HighlightText> to{' '}
                <HighlightText>Automobili Lamborghini</HighlightText>
              </>
            }
          />
          <SearchItem
            avatarUrl="/images/default-avatar-6.png"
            title="Michelle Nunez"
            content={
              <>
                In <HighlightText>C#/React developer</HighlightText> on{' '}
                <HighlightText>Mitsubishi Tech</HighlightText>
              </>
            }
          />
        </Stack>
      </Flex>
    </Flex>
  );
};

export default HeaderSearchResult;
