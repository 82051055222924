import snakeCase from 'lodash/snakeCase';

import {
  FetchSpecializationsQuery,
  SpecializationFields,
  SpecializationId,
  SpecializationNanoId,
  SpecializationName,
  SpecializationUser,
  SpecializationCreatedAt
} from '../specializationsTypes';

export interface FetchSpecializationsResponse {
  [SpecializationFields.ID]: SpecializationId;
  [SpecializationFields.NANO_ID]: SpecializationNanoId;
  [SpecializationFields.NAME]: SpecializationName;
  [SpecializationFields.USER]: SpecializationUser;
  [SpecializationFields.CREATED_AT]: SpecializationCreatedAt;
}

export const fetchSpecializationsColumns = [
  SpecializationFields.ID,
  SpecializationFields.NANO_ID,
  SpecializationFields.NAME,
  SpecializationFields.CREATED_AT
];

const fetchSpecializationsSBColumns = fetchSpecializationsColumns.map(
  (fetchSpecializationsColumn) => snakeCase(fetchSpecializationsColumn)
);

export const fetchSpecializationsQuery = `
  ${fetchSpecializationsSBColumns.join(',')},
  user:user_id(id,nano_id,name,image:image_id(id,nano_id,name,file,key,created_at))
` as FetchSpecializationsQuery;
