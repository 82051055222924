import { FieldBrand, ItemRecordID } from '../../../types';

import { UserUuid } from '../../users/usersTypes';

export type CandidateSubmissionJobCandidateRecordId = FieldBrand<
  ItemRecordID,
  'CandidateSubmissionJobCandidateRecordId'
>;

export type CandidateSubmissionJobCandidateUserUuid = FieldBrand<
  UserUuid,
  'CandidateSubmissionJobCandidateUserUuid'
>;

export const enum CandidateSubmissionJobCandidateIndexFields {
  ID = 'id',
  RECORD_ID = 'recordId',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  CREATED_AT = 'createdAt',

  USER_UUID = 'userUuid'
}
