import { usePaginatedItems } from '../../../common/hooks/usePaginatedItems';

import {
  FetchSkillBadgesQuery,
  FetchSkillBadgesCacheKey,
  FetchSkillBadgesTotalCount,
  FetchSkillBadgesPage,
  FetchSkillBadgesPageSize,
  FetchSkillBadgesError,
  FetchSkillBadgesErrorMessage,
  FetchSkillBadgesIsLoading,
  FetchSkillBadgesIsFetched,
  FetchSkillBadgesIsPlaceholderData,
  FetchSkillBadgesEnabled,
  FetchSkillBadgesPrefetchNextPage,
  FetchSkillBadgesCountType,
  FetchSkillBadgesFilters,
  FetchSkillBadgesSort,
  FetchSkillBadgeID,
  FetchSkillBadgeQuery,
  FetchSkillBadgeCacheKey
} from '../../skillBadgesTypes';

// import { SkillBadgesRequests } from '../../SkillBadgesRequests';
import { SkillBadgesBffRequests } from '../../SkillBadgesBffRequests';

interface PaginatedSkillBadgesBaseOptions<PaginatedSkillBadgesResponseType> {
  cacheKey: FetchSkillBadgesCacheKey;
  query: FetchSkillBadgesQuery;
  countType?: FetchSkillBadgesCountType;
  initialPage?: FetchSkillBadgesPage;
  initialPageSize?: FetchSkillBadgesPageSize;
  initialFilters?: FetchSkillBadgesFilters;
  initialSort?: FetchSkillBadgesSort;
  enabled?: FetchSkillBadgesEnabled;
  prefetchNextPage?: FetchSkillBadgesPrefetchNextPage;
  placeholderSkillBadges?: PaginatedSkillBadgesResponseType[];
  placeholderSkillBadgesCount?: FetchSkillBadgesTotalCount;
  placeholderData?: () => PaginatedSkillBadgesResponse<PaginatedSkillBadgesResponseType>;
  onSuccess?: (
    data: PaginatedSkillBadgesResponse<PaginatedSkillBadgesResponseType>
  ) => void;
}

interface PaginatedSkillBadgesOptionsWithoutItem {
  fetchItemCacheKey?: never;
  itemQuery?: never;
}

interface PaginatedSkillBadgesOptionsWithItem {
  fetchItemCacheKey: FetchSkillBadgeCacheKey;
  itemQuery: FetchSkillBadgeQuery;
}

type PaginatedSkillBadgesOptions<PaginatedSkillBadgesResponseType> =
  PaginatedSkillBadgesBaseOptions<PaginatedSkillBadgesResponseType> &
    (
      | PaginatedSkillBadgesOptionsWithoutItem
      | PaginatedSkillBadgesOptionsWithItem
    );

interface PaginatedSkillBadgesResponse<PaginatedSkillBadgesResponseType> {
  data: PaginatedSkillBadgesResponseType[];
  totalCount?: FetchSkillBadgesTotalCount;
}

function usePaginatedSkillBadges<PaginatedSkillBadgesResponseType>({
  cacheKey,
  query,
  countType,
  initialPage,
  initialPageSize,
  initialFilters,
  initialSort,
  enabled,
  prefetchNextPage,
  placeholderSkillBadges,
  placeholderSkillBadgesCount,
  placeholderData,
  fetchItemCacheKey,
  itemQuery,
  onSuccess
}: PaginatedSkillBadgesOptions<PaginatedSkillBadgesResponseType>) {
  const {
    items,
    itemsTotalCount,
    itemsError,
    itemsErrorMessage,
    itemsIsFetched,
    itemsIsLoading,
    itemsIsPlaceholderData,
    itemsCurrentPage,
    itemsCurrentPageSize,
    itemsCurrentFilters,
    itemsCurrentSort,
    paginateItems,
    changeItemsPageSize,
    filterItems,
    changeItemsFilters,
    clearItemsFilters,
    sortItems,
    prefetchItems,
    prefetchItem
  } = usePaginatedItems<PaginatedSkillBadgesResponseType>({
    cacheKey,
    initialPage,
    initialPageSize,
    initialFilters,
    initialSort,
    enabled,
    prefetchNextPage,
    placeholderItems: placeholderSkillBadges,
    placeholderItemsCount: placeholderSkillBadgesCount,
    placeholderData,
    onSuccess: (data) =>
      onSuccess?.(
        data as PaginatedSkillBadgesResponse<PaginatedSkillBadgesResponseType>
      ),
    queryFn: (params) =>
      SkillBadgesBffRequests.fetchSkillBadges<PaginatedSkillBadgesResponseType>(
        {
          query,
          countType,
          page: params.page as FetchSkillBadgesPage,
          pageSize: params.pageSize as FetchSkillBadgesPageSize,
          filters: params.filters as FetchSkillBadgesFilters,
          sort: params.sort as FetchSkillBadgesSort
        }
      ),
    // SkillBadgesRequests.fetchSkillBadges<PaginatedSkillBadgesResponseType>({
    //   query,
    //   countType,
    //   page: params.page as FetchSkillBadgesPage,
    //   pageSize: params.pageSize as FetchSkillBadgesPageSize,
    //   filters: params.filters as FetchSkillBadgesFilters,
    //   sort: params.sort as FetchSkillBadgesSort
    // }),
    ...(itemQuery
      ? {
          fetchItemCacheKey,
          queryItemFn: (itemId) =>
            SkillBadgesBffRequests.fetchSkillBadge(
              itemId as FetchSkillBadgeID,
              {
                query: itemQuery
              }
            )
        }
      : {})
  });

  return {
    skillBadges: items as PaginatedSkillBadgesResponseType[],
    skillBadgesTotalCount: itemsTotalCount as FetchSkillBadgesTotalCount,
    skillBadgesError: itemsError as FetchSkillBadgesError,
    skillBadgesErrorMessage: itemsErrorMessage as FetchSkillBadgesErrorMessage,
    skillBadgesIsLoading: itemsIsLoading as FetchSkillBadgesIsLoading,
    skillBadgesIsFetched: itemsIsFetched as FetchSkillBadgesIsFetched,
    skillBadgesIsPlaceholderData:
      itemsIsPlaceholderData as FetchSkillBadgesIsPlaceholderData,
    skillBadgesCurrentPage: itemsCurrentPage as FetchSkillBadgesPage,
    skillBadgesCurrentPageSize:
      itemsCurrentPageSize as FetchSkillBadgesPageSize,
    skillBadgesCurrentFilters: itemsCurrentFilters as FetchSkillBadgesFilters,
    skillBadgesCurrentSort: itemsCurrentSort as FetchSkillBadgesSort,
    paginateSkillBadges: paginateItems,
    changeSkillBadgesPageSize: changeItemsPageSize,
    filterSkillBadges: filterItems,
    changeSkillBadgesFilters: changeItemsFilters,
    clearSkillBadgesFilters: clearItemsFilters,
    sortSkillBadges: sortItems,
    prefetchSkillBadges: prefetchItems,
    prefetchSkillBadge: prefetchItem
  };
}

export default usePaginatedSkillBadges;
