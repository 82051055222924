import {
  JobCandidateCandidateShares,
  MayBeJobCandidateCities,
  MayBeJobCandidateCity,
  JobCandidateId,
  JobCandidateJobTitle,
  JobCandidateLinkedinUrl,
  JobCandidateName,
  JobCandidateNanoId,
  JobCandidateSearchTypeLabel,
  MayBeJobCandidateCompany,
  MayBeJobCandidateImage
} from '../../../jobCandidates/jobCandidatesTypes';

import {
  JobId,
  JobInterviewers,
  JobJobTitle,
  JobName,
  JobNanoId,
  MayBeJobCities,
  MayBeJobCity,
  MayBeJobCompany,
  MayBeJobHiringManager
} from '../../../jobs/jobsTypes';
import {
  CandidateShareAutoJoin,
  CandidateShareCandidateShareInvitations,
  CandidateShareCompanyMemberInvitations,
  CandidateShareId,
  CandidateShareMessage,
  CandidateShareRecipients,
  CandidateShareShareSettings
} from '../../candidateSharesTypes';

export interface ShareCandidateProfileJob {
  id: JobId;
  nanoId: JobNanoId;
  jobTitle: JobJobTitle;
  name: JobName;
  cities: MayBeJobCities;
  city: MayBeJobCity;
  interviewers: JobInterviewers;
  hiringManager: MayBeJobHiringManager;
  company: MayBeJobCompany;
}

export type ShareCandidateProfileJobCandidates =
  ShareCandidateProfileJobCandidate[];

export type ShareCandidateProfileJobCandidate = {
  id: JobCandidateId;
  nanoId: JobCandidateNanoId;
  name: JobCandidateName;
  jobTitle: JobCandidateJobTitle;
  company: MayBeJobCandidateCompany;
  image: MayBeJobCandidateImage;
  searchTypeLabel: JobCandidateSearchTypeLabel;
  linkedinUrl: JobCandidateLinkedinUrl;
  candidateShares: JobCandidateCandidateShares;
  city: MayBeJobCandidateCity;
  cities: MayBeJobCandidateCities;
};

export interface ShareCandidateProfileCandidateShare {
  id: CandidateShareId;
  message: CandidateShareMessage;
  recipients: CandidateShareRecipients;
  shareSettings: CandidateShareShareSettings;
  autoJoin: CandidateShareAutoJoin;
  candidateShareInvitations: CandidateShareCandidateShareInvitations;
  companyMemberInvitations: CandidateShareCompanyMemberInvitations;
}

export enum ShareCandidateProfileRecruiterEnums {
  RECRUITER = 'Recruiter',
  RECRUITER_ADMIN = 'Recruiter Admin',
  RECRUITER_COORDINATOR = 'Recruiter Coordinator'
}

export type ShareCandidateProfileFieldsToShareProps = {
  label: string;
  name: string;
  checked: boolean;
}[];
