import fromPairs from 'lodash/fromPairs';

import {
  UserId,
  UserUuid,
  UserNanoId,
  UserName,
  UserEmail,
  UserTitle,
  UserPhone,
  MayBeUserApprovedAt,
  MayBeUserProfileCreatedAt,
  MayBeUserWorkEmailApprovedAt,
  MayBeUserImageId,
  MayBeUserImage,
  MayBeUserCompanyId,
  MayBeUserCompany,
  MayBeUserCountryId,
  MayBeUserStateId,
  MayBeUserState,
  MayBeUserCityId,
  MayBeUserCity,
  MayBeUserJobResumeRecruiterId,
  MayBeUserJobResumeRecruiter,
  UserCompanyMembers,
  UserRoleNames,
  MayBeUserCompanyRoleName,
  UserCreatedAt
} from '../../../main/users/usersTypes';

import { PermissionAction } from '../../../main/permissions/permissionsTypes';

import { CurrentUserInfo, AuthenticatedUserActions } from './useAuth.types';

export class AuthenticatedUser {
  id: UserId;
  uuid: UserUuid;
  nanoId: UserNanoId;
  name: UserName;
  email: UserEmail;
  title: UserTitle;
  phone: UserPhone;
  approvedAt: MayBeUserApprovedAt;
  profileCreatedAt: MayBeUserProfileCreatedAt;
  workEmailApprovedAt: MayBeUserWorkEmailApprovedAt;
  imageId: MayBeUserImageId;
  image: MayBeUserImage;
  companyId: MayBeUserCompanyId;
  company: MayBeUserCompany;
  countryId: MayBeUserCountryId;
  stateId: MayBeUserStateId;
  state: MayBeUserState;
  cityId: MayBeUserCityId;
  city: MayBeUserCity;
  jobResumeRecruiterId: MayBeUserJobResumeRecruiterId;
  jobResumeRecruiter: MayBeUserJobResumeRecruiter;
  companyMembers: UserCompanyMembers;
  createdAt: UserCreatedAt;
  roleNames: UserRoleNames;
  companyRoleName: MayBeUserCompanyRoleName;
  actions: AuthenticatedUserActions;

  constructor(values: CurrentUserInfo) {
    this.id = values.id;
    this.uuid = values.uuid;
    this.nanoId = values.nanoId;
    this.name = values.name;
    this.email = values.email;
    this.title = values.title;
    this.phone = values.phone;
    this.approvedAt = values.approvedAt;
    this.profileCreatedAt = values.profileCreatedAt;
    this.workEmailApprovedAt = values.workEmailApprovedAt;
    this.imageId = values.imageId;
    this.image = values.image;
    this.companyId = values.companyId;
    this.company = values.company;
    this.countryId = values.countryId;
    this.stateId = values.stateId;
    this.state = values.state;
    this.cityId = values.cityId;
    this.city = values.city;
    this.jobResumeRecruiterId = values.jobResumeRecruiterId;
    this.jobResumeRecruiter = values.jobResumeRecruiter;
    this.companyMembers = values.companyMembers;
    this.roleNames = values.roleNames;
    this.companyRoleName = values.companyRoleName;
    this.createdAt = values.createdAt;

    this.actions = fromPairs(
      (values.actions || []).map((action) => [action, true])
    );
  }

  can(action: string) {
    const result = this.actions[action as PermissionAction] || false;
    if (!result) {
      console.log('Permission', action, result);
    }
    return result;
  }

  hasOneOfPermissions(actions: string[]) {
    return actions.some((action) => this.can(action));
  }

  hasAllPermissions(actions: string[]) {
    return actions.every((action) => this.can(action));
  }

  isAnonymous() {
    return false;
  }
}
