import React, { ReactNode } from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps
} from '@chakra-ui/modal';
import { Divider, SystemProps } from '@chakra-ui/react';

import {
  ModalIsOpen,
  I18nText,
  IsLoading,
  IsDisabled,
  IsHidden
} from '../../types';

import { PureButtonHelper } from '../buttons/PureButtonHelper';

interface SimpleModalProps {
  isOpen: ModalIsOpen;
  size?: ModalProps['size'];
  cancelIsDisabled?: IsDisabled;
  cancelButtonText?: I18nText;
  submitIsLoading?: IsLoading;
  submitIsDisabled?: IsDisabled;
  submitButtonHierarchy?:
    | 'primary'
    | 'danger'
    | 'secondary'
    | 'tertiary'
    | 'link'
    | 'unstyled';
  footerIsHidden?: IsHidden;
  headerDividerIsHidden?: IsHidden;
  disabled?: IsDisabled;
  submitButtonText?: I18nText;
  header: ReactNode;
  children: ReactNode;
  onCancel: () => void;
  onSubmit?: () => void;
  scrollBehavior?: 'inside' | 'outside';
  isCentered?: boolean;
  headerBg?: SystemProps['bg'];
  closeButtonIsHidden?: IsHidden;
  returnFocusOnClose?: boolean;
  px?: number;
  pb?: number;
}

function SimpleModal({
  isOpen,
  size = '2xl',
  cancelIsDisabled,
  cancelButtonText,
  submitButtonText,
  submitIsLoading,
  submitIsDisabled,
  submitButtonHierarchy,
  footerIsHidden,
  headerDividerIsHidden,
  disabled,
  header,
  children,
  onCancel,
  onSubmit,
  scrollBehavior = 'inside',
  isCentered,
  closeButtonIsHidden,
  headerBg,
  px,
  pb,
  returnFocusOnClose
}: SimpleModalProps) {
  return (
    <Modal
      size={size}
      isOpen={isOpen}
      onClose={onCancel}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      returnFocusOnClose={returnFocusOnClose}
      scrollBehavior={scrollBehavior}
      isCentered={isCentered}
    >
      <ModalOverlay />

      <ModalContent>
        <ModalHeader bg={headerBg} px={px} pb={pb}>
          {header}{' '}
          {headerDividerIsHidden ? null : (
            <Divider borderColor="gray.200" opacity={1} mt={3} />
          )}
        </ModalHeader>

        {closeButtonIsHidden ? null : <ModalCloseButton />}

        <ModalBody py={0}>{children}</ModalBody>

        {footerIsHidden ? null : (
          <ModalFooter>
            <PureButtonHelper
              hierarchy="tertiary"
              size="medium"
              onClick={onCancel}
              mr={3}
              disabled={cancelIsDisabled || disabled}
              i18nText={cancelButtonText || 'Cancel'}
            />
            <PureButtonHelper
              size="medium"
              hierarchy={submitButtonHierarchy || 'primary'}
              onClick={onSubmit}
              isLoading={submitIsLoading}
              disabled={submitIsDisabled || disabled}
              i18nText={submitButtonText || 'Submit'}
            />
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
}

export default SimpleModal;
