import { FetchContactsCacheKey, FetchContactCacheKey } from './contactsTypes';

export class ContactsCache {
  static indexCacheKey() {
    return 'contacts' as FetchContactsCacheKey;
  }

  static existsCacheKey() {
    return 'contacts-exists' as FetchContactsCacheKey;
  }

  static showCacheKey() {
    return 'contact' as FetchContactCacheKey;
  }
}
