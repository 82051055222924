import { Flex, useDisclosure } from '@chakra-ui/react';
import { useViewJobCandidateDetailsTasksWidgetContext } from '../../../../contexts/ViewJobCandidateDetailsTasksWidgetContext';
import { ViewJobCandidateDetailsDisqualificationDetails } from '../ViewJobCandidateDetailsDisqualificationDetails';
import { ViewJobCandidateDetailsEmptyTaskHover } from '../ViewJobCandidateDetailsEmptyTaskHover';
import { ViewJobCandidateDetailsTaskStatus } from '../ViewJobCandidateDetailsTaskStatus';
import { ViewJobCandidateDetailsTaskTools } from '../ViewJobCandidateDetailsTaskTools';
import { ViewJobCandidateDetailsTasksDropdown } from '../ViewJobCandidateDetailsTasksDropdown';

export default function ViewJobCandidateDetailsTasksSection() {
  const {
    stageTasks,
    taskStatus,
    isSubActionsDropdownOpen,
    isCandidateDisqualified
  } = useViewJobCandidateDetailsTasksWidgetContext();

  const {
    isOpen: isHovered,
    onOpen: onMouseHover,
    onClose: onMouseLeave
  } = useDisclosure();

  return (
    <Flex
      p={3}
      w={52}
      gap={2}
      flexDir="column"
      onMouseOver={() => {
        onMouseHover();
      }}
      onMouseLeave={() => {
        isSubActionsDropdownOpen ? undefined : onMouseLeave();
      }}
    >
      {isCandidateDisqualified ? (
        <ViewJobCandidateDetailsDisqualificationDetails />
      ) : (
        <>
          <Flex
            h={8}
            w="full"
            pl={2.5}
            pr={1.5}
            bg="white"
            color="gray.700"
            border="1px solid"
            borderColor="gray.300"
            borderRadius="base"
            fontWeight="normal"
          >
            <ViewJobCandidateDetailsTasksDropdown stageTasks={stageTasks} />
          </Flex>

          <Flex h={6} alignItems="center" position="relative">
            {isHovered && (
              <Flex
                w="100%"
                h="100%"
                gap={2}
                flex={1}
                position="absolute"
                transitionDuration="slow"
                opacity={isHovered ? 1 : 0}
                visibility={isHovered ? 'visible' : 'hidden'}
              >
                {taskStatus.label === 'start-task' ? (
                  <ViewJobCandidateDetailsTaskTools />
                ) : (
                  <ViewJobCandidateDetailsEmptyTaskHover />
                )}
              </Flex>
            )}

            <Flex
              flex={1}
              alignItems="flex-start"
              opacity={isHovered ? 0 : 1}
              visibility={isHovered ? 'hidden' : 'visible'}
            >
              <ViewJobCandidateDetailsTaskStatus />
            </Flex>
          </Flex>
        </>
      )}
    </Flex>
  );
}
