const nameRules = {
  required: 'Name is required'
};

const applicationRules = {
  required: {
    value: true,
    message: 'Application is required'
  },
  min: {
    value: 1,
    message: 'Application is required'
  }
};

const dueDateRules = {
  required: {
    value: true,
    message: 'Due date is required'
  },
  min: {
    value: 1,
    message: 'Due date is required'
  }
};

const automationRules = {
  required: 'Automation is required'
};

function useEditWorkflowStageTaskFormValidationRules() {
  return {
    nameRules,
    applicationRules,
    dueDateRules,
    automationRules
  };
}

export default useEditWorkflowStageTaskFormValidationRules;
