import { Styles } from '@chakra-ui/theme-tools';

const styles: Styles = {
  global: {
    html: {
      overflowY: 'clip'
    },
    body: {
      fontSize: '14px',
      lineHeight: '24px',
      overflowY: 'clip'
    },
    a: {
      color: 'primary.500',
      _hover: {
        textDecoration: 'underline'
      }
    },
    '::-moz-selection': {
      background: 'primary.500',
      color: 'white',
      opacity: '1'
    },
    '::selection': {
      background: 'primary.500',
      color: 'white',
      opacity: '1'
    },
    '.remove-border': {
      '@media screen and (min-width: 60em)': {
        borderRight: 'none !important'
      }
    },
    '.add-border': {
      '@media screen and (min-width: 60em)': {
        borderRight: '1px solid #E1EAED !important'
      }
    },
    '.custom-nxmoov-multi-select-option': {
      background: 'white !important',
      color: '#17191A !important',
      cursor: 'pointer !important',
      '&:hover': {
        background: '#F4F9FB !important'
      },
      '&.custom-nxmoov-multi-select-option-disabled': {
        cursor: 'not-allowed !important',
        background: '#F4F6F7 !important'
      }
    },
    '*': {
      scrollbarWidth: '6px',
      '::-webkit-scrollbar': {
        width: '6px',
        height: '6px'
      },
      '::-webkit-scrollbar-track': {
        background: 'gray.100',
        border: 'none'
      },
      '::-webkit-scrollbar-thumb': {
        background: 'gray.400',
        borderRadius: '16px',
        visibility: 'hidden'
      },
      '::-webkit-scrollbar-thumb: hover': {
        background: 'gray.500'
      },
      ':hover': {
        '::-webkit-scrollbar-track': {
          background: 'gray.200'
        },
        '::-webkit-scrollbar-thumb': {
          visibility: 'visible'
        }
      }
    }
  }
};

export default styles;
