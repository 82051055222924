import {
  SkillBadgeExperienceType,
  SkillBadgeExperienceTypes
} from '../../skillBadgesTypes';

function renderSkillBadgeExperienceType(
  SkillBadgeExperienceType: SkillBadgeExperienceType
) {
  switch (SkillBadgeExperienceType) {
    case SkillBadgeExperienceTypes.CURRENT_JOB:
      return 'Current Job';
    case SkillBadgeExperienceTypes.PAST_JOB:
      return 'Past Job';
    case SkillBadgeExperienceTypes.ALL_JOBS:
      return 'All Jobs';
    default:
      return 'Unknown';
  }
}

export default renderSkillBadgeExperienceType;
