import {
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack
} from '@chakra-ui/react';
import { AlertMessage } from '../../../../../../helpers/AlertMessage';
import { Button, ButtonHierarchyType } from '../../../../../../helpers/Button';
import { Heading } from '../../../../../../helpers/Heading';
import { ErrorMessage, IsLoading } from '../../../../../../types';
import { ReactNode } from 'react';

interface InboxDestructiveActionPopupWithHeader {
  header: ReactNode;
  title?: never;
}

interface InboxDestructiveActionPopupWithTitle {
  header?: never;
  title: string;
}

type InboxDestructiveActionPopupProps = {
  content: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  discardAction: () => void;
  actionButtonText: string;
  discardErrorMessage?: ErrorMessage;
  isDiscarding?: IsLoading;
  discardBtnHierarchy?: ButtonHierarchyType;
} & (
  | InboxDestructiveActionPopupWithHeader
  | InboxDestructiveActionPopupWithTitle
);

const InboxDestructiveActionPopup = ({
  header,
  isOpen,
  onClose,
  discardAction,
  title,
  content,
  actionButtonText,
  discardErrorMessage,
  isDiscarding,
  discardBtnHierarchy = 'primary'
}: InboxDestructiveActionPopupProps) => {
  return (
    <Modal
      size="lg"
      closeOnEsc={false}
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={6}>
          <Stack spacing={8}>
            <Flex flexDir="column" gap={4}>
              {header || (
                <Heading level="h3" fontWeight="normal">
                  {title}
                </Heading>
              )}

              <Heading level="h4" fontWeight="normal" color="gray.600">
                {content}
              </Heading>

              <AlertMessage message={discardErrorMessage} />
            </Flex>

            <Flex gap={2} justifyContent="flex-end">
              <Button size="medium" hierarchy="tertiary" onClick={onClose}>
                Cancel
              </Button>
              <Button
                size="medium"
                hierarchy={discardBtnHierarchy}
                onClick={discardAction}
                isLoading={isDiscarding}
              >
                {actionButtonText}
              </Button>
            </Flex>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default InboxDestructiveActionPopup;
