import {
  FetchJobsQuery,
  FetchJobsCountType,
  FetchJobsPage,
  FetchJobsPageSize,
  FetchJobsFilters,
  FetchJobsSort,
  FetchJobQuery,
  JobId,
  JobNanoId,
  JobName,
  JobDepartmentKey,
  JobDescription,
  JobCityId,
  JobHiringManagerId,
  CreateJobError,
  UpdateJobError,
  JobUserId,
  JobCompanyId,
  JobCityIds,
  DeleteJobError,
  BatchDestroyJobsError,
  JobDepartmentId,
  FetchJobsCitiesCountType,
  FetchJobsCitiesPage,
  FetchJobsCitiesPageSize,
  FetchJobsCitiesFilters,
  FetchJobsCitiesSort,
  FetchJobsHiringManagersCountType,
  FetchJobsHiringManagersPage,
  FetchJobsHiringManagersPageSize,
  FetchJobsHiringManagersFilters,
  FetchJobsHiringManagersSort,
  FetchJobsCitiesQuery,
  FetchJobsHiringManagersQuery,
  AddCandidatesToJobError,
  AddJobCandidatesToJobError,
  JobDisciplineId,
  JobJobFunctionId,
  JobJobTitleId,
  IntakeJobError,
  JobWorkplace,
  JobWorkAuthorization,
  JobNotes,
  MayBeJobCityIds,
  MayBeJobHiringManagerId,
  MayBeJobRecruitingCoordinatorId,
  JobInterviewerIds,
  JobCompanyIds,
  JobEmployment,
  JobBaseSalary,
  JobExperience,
  JobSeniorityLevel,
  JobGeography,
  JobNumberOfOpenings,
  MayBeJobRecruiterId,
  JobCertificationIds,
  JobDegree,
  JobStudyField,
  JobPaidTimeOff,
  JobSpecializationIds,
  JobPriority,
  CancelJobError,
  PublishJobError
} from './jobsTypes';

import { BaseBffRequests } from '../BaseBffRequests';
import { JobsBffRoutes } from './JobsBffRoutes';
import { JobTitleId } from '../jobTitles/jobTitlesTypes';
import { JobCandidateId } from '../jobCandidates/jobCandidatesTypes';
import { RecipientId } from '../recipients/recipientsTypes';
import { PauseJobError } from './jobsTypes/pauseJob.types';

interface FetchJobsRequestParams {
  query: FetchJobsQuery;
  countType?: FetchJobsCountType;
  page?: FetchJobsPage;
  pageSize?: FetchJobsPageSize;
  filters?: FetchJobsFilters;
  sort?: FetchJobsSort;
}

interface FetchJobsCitiesRequestParams {
  query: FetchJobsCitiesQuery;
  countType?: FetchJobsCitiesCountType;
  page?: FetchJobsCitiesPage;
  pageSize?: FetchJobsCitiesPageSize;
  filters?: FetchJobsCitiesFilters;
  sort?: FetchJobsCitiesSort;
}

interface FetchJobsHiringManagersRequestParams {
  query: FetchJobsHiringManagersQuery;
  countType?: FetchJobsHiringManagersCountType;
  page?: FetchJobsHiringManagersPage;
  pageSize?: FetchJobsHiringManagersPageSize;
  filters?: FetchJobsHiringManagersFilters;
  sort?: FetchJobsHiringManagersSort;
}

interface FetchJobRequestParams {
  query: FetchJobQuery;
}

export interface CreateJobRequestData {
  id?: JobId;
  nanoId: JobNanoId;
  userId: JobUserId;
  companyId: JobCompanyId;
}

export interface UpdateJobRequestData {
  name?: JobName;
  jobTitleId?: JobTitleId;
  departmentKey?: JobDepartmentKey;
  departmentId?: JobDepartmentId;
  description?: JobDescription;
  cityId?: JobCityId | null;
  cityIds?: JobCityIds | null;
  hiringManagerId?: JobHiringManagerId | null;
  jobPriority?: JobPriority;
}

export interface IntakeJobRequestData {
  jobTitleId?: JobJobTitleId;
  departmentId?: JobDepartmentId;
  disciplineId?: JobDisciplineId;
  workplace?: JobWorkplace;
  workAuthorization?: JobWorkAuthorization;
  notes?: JobNotes;
  cityIds?: MayBeJobCityIds;
  hiringManagerId?: MayBeJobHiringManagerId;
  recruitingCoordinatorId?: MayBeJobRecruitingCoordinatorId;
  interviewerIds?: JobInterviewerIds;
  companyIds?: JobCompanyIds;
  employment?: JobEmployment;
  baseSalary?: JobBaseSalary;
  experience?: JobExperience;
  seniorityLevel?: JobSeniorityLevel;
  geography?: JobGeography;
  numberOfOpenings?: JobNumberOfOpenings;
  recruiterId?: MayBeJobRecruiterId;
  jobFunctionId?: JobJobFunctionId;
  certificationIds?: JobCertificationIds;
  degree?: JobDegree;
  studyField?: JobStudyField;
  paidTimeOff?: JobPaidTimeOff;
  specializationIds?: JobSpecializationIds;
}

export interface BatchDestroyJobsRequestData {
  ids: JobId[];
}

export interface FillFromJobRequestData {
  jobNanoId: JobNanoId;
}

export interface AddCandidatesToJobRequestData {
  data: unknown;
}

export interface AddJobCandidatesToJobRequestData {
  jobCandidateIds: JobCandidateId[];
  recipientIds?: RecipientId[];
}

export interface CreateJobErrorResponse {
  message: CreateJobError;
}

export interface DeleteJobErrorResponse {
  message: DeleteJobError;
}

export interface UpdateJobErrorResponse {
  message: UpdateJobError;
}

export interface IntakeJobErrorResponse {
  message: IntakeJobError;
}

export interface FillFromJobErrorResponse {
  message: UpdateJobError;
}

export interface AddCandidatesToJobErrorResponse {
  message: AddCandidatesToJobError;
}

export interface AddJobCandidatesToJobErrorResponse {
  message: AddJobCandidatesToJobError;
}

export interface BatchDestroyJobsErrorResponse {
  message: BatchDestroyJobsError;
}

export interface CancelJobErrorResponse {
  message: CancelJobError;
}

export interface PublishJobErrorResponse {
  message: PublishJobError;
}

export interface PauseJobErrorResponse {
  message: PauseJobError;
}

export class JobsBffRequests extends BaseBffRequests {
  static fetchJobs<T>(params: FetchJobsRequestParams) {
    return this.fetchItems<T>(JobsBffRoutes.fetchJobsRoute(), params);
  }

  static fetchJob<T>(jobId: JobId, params: FetchJobRequestParams) {
    return this.fetchItem<T>(JobsBffRoutes.fetchJobRoute(jobId), params);
  }

  static fetchJobByNanoId<T>(
    jobNanoId: JobNanoId,
    params: FetchJobRequestParams
  ) {
    return this.fetchItemByNanoId<T>(
      JobsBffRoutes.fetchJobByNanoIdRoute(jobNanoId),
      params
    );
  }

  static fetchJobsCities<T>(params: FetchJobsCitiesRequestParams) {
    return this.fetchItems<T>(JobsBffRoutes.fetchJobsCitiesRoute(), params);
  }

  static fetchJobsHiringManagers<T>(
    params: FetchJobsHiringManagersRequestParams
  ) {
    return this.fetchItems<T>(
      JobsBffRoutes.fetchJobsHiringManagersRoute(),
      params
    );
  }

  static createJob(createJobData: CreateJobRequestData) {
    return this.createItem<CreateJobRequestData, CreateJobRequestData>(
      JobsBffRoutes.createJobRoute(),
      createJobData
    );
  }

  static deleteJob<T>(deleteJobNanoId: JobNanoId) {
    return this.deleteItem<T>(JobsBffRoutes.deleteJobRoute(deleteJobNanoId));
  }

  static async updateJob(
    updateJobNanoId: JobNanoId,
    updateJobData: UpdateJobRequestData
  ) {
    const { data, error } = await this.updateItem<
      { data: UpdateJobRequestData; error: string | null },
      UpdateJobRequestData
    >(JobsBffRoutes.updateJobRoute(updateJobNanoId), updateJobData);

    if (error) {
      throw error;
    }

    return data;
  }

  static async intakeJob(
    intakeJobNanoId: JobNanoId,
    intakeJobData: IntakeJobRequestData
  ) {
    const { data, error } = await this.postItem<
      { data: IntakeJobRequestData; error: string | null },
      IntakeJobRequestData
    >(JobsBffRoutes.intakeJobRoute(intakeJobNanoId), intakeJobData);

    if (error) {
      throw error;
    }

    return data;
  }

  static async fillFromJob(
    fillFromJobNanoId: JobNanoId,
    fillFromJobData: FillFromJobRequestData
  ) {
    const { data, error } = await this.postItem<
      { data: FillFromJobRequestData; error: string | null },
      FillFromJobRequestData
    >(JobsBffRoutes.fillFromJobRoute(fillFromJobNanoId), fillFromJobData);

    if (error) {
      throw error;
    }

    return data;
  }

  static async addCandidatesToJob(
    addCandidatesToJobNanoId: JobNanoId,
    addCandidatesToJobData: AddCandidatesToJobRequestData
  ) {
    const { data, error } = await this.postItem<
      { data: AddCandidatesToJobRequestData; error: string | null },
      AddCandidatesToJobRequestData
    >(
      JobsBffRoutes.addCandidatesToJobRoute(addCandidatesToJobNanoId),
      addCandidatesToJobData
    );

    if (error) {
      throw error;
    }

    return data;
  }

  static async batchDestroyJobs(
    batchDestroyJobsData: BatchDestroyJobsRequestData
  ) {
    const { data, error } = await this.postItems<
      { data: BatchDestroyJobsRequestData; error: string | null },
      BatchDestroyJobsRequestData
    >(JobsBffRoutes.batchDestroyJobsRoute(), batchDestroyJobsData);

    if (error) {
      throw error;
    }

    return data;
  }

  static async addJobCandidatesToJob(
    addJobCandidatesToJobNanoId: JobNanoId | JobId,
    addJobCandidatesToJobData: AddJobCandidatesToJobRequestData
  ) {
    const { data, error } = await this.postItem<
      { data: AddJobCandidatesToJobRequestData; error: string | null },
      AddJobCandidatesToJobRequestData
    >(
      JobsBffRoutes.addJobCandidatesToJobRoute(addJobCandidatesToJobNanoId),
      addJobCandidatesToJobData
    );

    if (error) {
      throw error;
    }

    return data;
  }

  static async cancelJob<T>(cancelJobNanoId: JobNanoId | JobId) {
    return this.postItem<T, Record<string, unknown>>(
      JobsBffRoutes.cancelJobRoute(cancelJobNanoId),
      {}
    );
  }

  static async publishJob<T>(publishJobNanoId: JobNanoId | JobId) {
    return this.postItem<T, Record<string, unknown>>(
      JobsBffRoutes.publishJobRoute(publishJobNanoId),
      {}
    );
  }

  static async pauseJob<T>(pauseJobNanoId: JobNanoId | JobId) {
    return this.postItem<T, Record<string, unknown>>(
      JobsBffRoutes.pauseJobRoute(pauseJobNanoId),
      {}
    );
  }
}
