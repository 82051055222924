import forEach from 'lodash/forEach';
import last from 'lodash/last';
import reduce from 'lodash/reduce';
import some from 'lodash/some';
import sortBy from 'lodash/sortBy';

import { useCallback, useMemo } from 'react';
import { DateTimeType, DateUtils } from '../../../../../../utils/DateUtils';
import { EmploymentsCache } from '../../../../../employments/EmploymentsCache';
import {
  EmploymentFields,
  EmploymentTypes,
  FetchEmploymentsFilters,
  FetchEmploymentsSort
} from '../../../../../employments/employmentsTypes';
import { usePaginatedEmployments } from '../../../../../employments/hooks/usePaginatedEmployments';
import {
  FetchEmploymentsResponse,
  fetchEmploymentsQuery
} from '../../../../../employments/queries/fetchEmployments.query';
import { JobCandidateId } from '../../../../../jobCandidates/jobCandidatesTypes';

const useEmploymentData = (jobCandidateId: JobCandidateId) => {
  const { employments, employmentsIsFetched } =
    usePaginatedEmployments<FetchEmploymentsResponse>({
      query: fetchEmploymentsQuery,
      cacheKey: EmploymentsCache.jobCandidateListCacheKey(jobCandidateId),
      initialFilters: {
        ...(jobCandidateId
          ? {
              [EmploymentFields.JOB_CANDIDATE_ID]: {
                operator: 'eq',
                value: jobCandidateId
              }
            }
          : {})
      } as unknown as FetchEmploymentsFilters,
      initialSort: {
        [EmploymentFields.START_DATE]: {
          ascending: false
        }
      } as unknown as FetchEmploymentsSort
    });

  const partTimeEmployments = useCallback<
    (employmentList: FetchEmploymentsResponse[]) => number
  >(
    (employmentList) =>
      reduce(
        employmentList,
        (a, b) => {
          const today = DateUtils.startOfToday();
          if (b.employmentType === EmploymentTypes.PART_TIME) {
            let end = (
              b.currently ? today : DateUtils.calendarDay(b.endDate as string)
            ) as DateTimeType;

            let start = DateUtils.calendarDay(
              b.startDate as string
            ) as DateTimeType;

            forEach(employmentList, (employment) => {
              if (employment.employmentType != EmploymentTypes.PART_TIME) {
                const empEndDate = DateUtils.calendarDay(
                  employment.endDate as string
                ) as DateTimeType;
                const empStartDate = DateUtils.calendarDay(
                  employment.startDate as string
                ) as DateTimeType;

                if (empStartDate <= start && start <= empEndDate) {
                  start = empEndDate;
                }

                if (empStartDate <= end && end <= empEndDate) {
                  end = empStartDate;
                }
              }
            });

            const yearDifference = (end
              ?.diff(start as DateTimeType, 'years')
              ?.toObject() || {}) as { years: number };

            return a + yearDifference.years / 2;
          }

          return a;
        },
        0
      ),
    []
  );

  const professionalExperience = useMemo(() => {
    const partTimeEmploymentYears = partTimeEmployments(employments);

    const lastFilteredEmployment = last(employments);

    const currentEmploymentExists = some(
      employments,
      (employment) => employment.currently
    );

    const startDate = DateUtils.calendarDay(
      lastFilteredEmployment?.startDate as string
    );

    const latestEndDate = last(sortBy(employments, ['endDate']))?.endDate;

    const today = DateUtils.startOfToday();

    const endDate = currentEmploymentExists
      ? today
      : DateUtils.calendarDay(latestEndDate as string);

    const yearDifference = (endDate
      ?.diff(startDate as DateTimeType, 'years')
      ?.toObject() || {}) as { years: number };

    const noOfYears = yearDifference.years - partTimeEmploymentYears;

    if (isNaN(noOfYears)) {
      return '-';
    }

    const months = Math.ceil(noOfYears * 12);

    const difference =
      noOfYears < 1
        ? `${months} month${months === 1 ? '' : 's'}`
        : `${Math.ceil(noOfYears)} year${noOfYears == 1 ? '' : 's'}`;

    return difference;
  }, [employments, partTimeEmployments]);

  return {
    employments,
    employmentsIsFetched,
    partTimeEmployments,
    professionalExperience
  };
};

export default useEmploymentData;
