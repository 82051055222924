import React, { ChangeEvent, useCallback, useState } from 'react';
import { Box, Flex, Stack } from '@chakra-ui/layout';
import { Avatar } from '@chakra-ui/avatar';
import axios from 'axios';
import first from 'lodash/first';

import { AvatarPlaceholderIcon } from '../../../../../../icons/AvatarPlaceholderIcon';

import { AlertMessage } from '../../../../../AlertMessage';
import { Button } from '../../../../../Button';
import { Text } from '../../../../../Text';
import { InputField } from '../../../InputField';

import { ImagesBffRequests } from '../../../../../../main/images/ImagesBffRequests';
import { TypographyProps } from '@chakra-ui/react';

interface UploadAvatarFieldControlProps {
  id?: string;
  label?: string;
  size?: number | string;
  hasError?: boolean;
  errorMessage?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  showDefaultAvatars?: boolean;
  value: string | null;
  onChange: (value: string | null) => void;
  placeholder?: string;
  fontSize?: TypographyProps['fontSize'];
}

function UploadAvatarFieldControl({
  label,
  showDefaultAvatars,
  errorMessage,
  onChange,
  size = 32,
  placeholder,
  fontSize
}: UploadAvatarFieldControlProps) {
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [imageErrorMessage, setErrorMessage] = useState<string | undefined>();

  const handleAvatarChange = useCallback<
    (e: ChangeEvent<HTMLInputElement>) => void
  >(
    async (e) => {
      setErrorMessage(undefined);
      const file = first(e.target.files);

      if (!file) {
        setImageUrl(null);
        return;
      }

      const objectUrl = URL.createObjectURL(file);

      setImageUrl(objectUrl);

      try {
        const { id, url } = await ImagesBffRequests.createImage({
          fileName: file.name,
          contentType: file.type,
          fileSize: file.size
        });

        await axios.put(url, file, {
          headers: { 'Content-Type': file.type, 'x-amz-acl': 'public-read' }
        });

        onChange(id);
      } catch (err) {
        setErrorMessage('Failed image upload');
      }
    },
    [setImageUrl, setErrorMessage, onChange]
  );

  return (
    <Stack spacing={4}>
      {label && (
        <Text textStyle="body1Medium" fontSize={fontSize || 'sm'}>
          {label}
        </Text>
      )}
      <Stack direction="row" spacing={6}>
        <Box as="label" htmlFor="upload-avatar">
          {imageUrl ? (
            <Avatar src={imageUrl} w={size} h={size} />
          ) : (
            <AvatarPlaceholderIcon w={size} h={size} />
          )}
        </Box>
        <Stack spacing={4} justifyContent="center">
          <Box>
            <InputField
              hidden
              accept=".jpg, .jpeg, .png, .webp"
              type="file"
              id="upload-avatar"
              onChange={handleAvatarChange}
            />

            <Box as="label" htmlFor="upload-avatar">
              <Button
                as="section"
                size="small"
                hierarchy="secondary"
                cursor="pointer"
              >
                {placeholder ? placeholder : 'Choose image'}
              </Button>
            </Box>
          </Box>

          {showDefaultAvatars && (
            <>
              <Text textStyle="body1Regular" color="gray.600">
                Or choose one of our defaults
              </Text>

              <Flex gap={4}>
                <Button hierarchy="unstyled" size="medium">
                  <Avatar src="/images/default-avatar-1.png" w={10} h={10} />
                </Button>

                <Button hierarchy="unstyled" size="medium">
                  <Avatar src="/images/default-avatar-2.png" w={10} h={10} />
                </Button>

                <Button hierarchy="unstyled" size="medium">
                  <Avatar src="/images/default-avatar-3.png" w={10} h={10} />
                </Button>
              </Flex>
            </>
          )}
        </Stack>
      </Stack>
      <AlertMessage message={imageErrorMessage || errorMessage} />
    </Stack>
  );
}

export default UploadAvatarFieldControl;
