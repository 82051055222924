import snakeCase from 'lodash/snakeCase';

import {
  FetchSkillsQuery,
  SkillFields,
  SkillId,
  SkillNanoId,
  SkillName,
  SkillKind,
  SkillSkillType,
  SkillSuggested,
  SkillUser,
  SkillCreatedAt
} from '../skillsTypes';

import { FetchSkillsApiQuery } from '../../../api/skills/skillsApiTypes';

export interface FetchSkillsResponse {
  [SkillFields.ID]: SkillId;
  [SkillFields.NANO_ID]: SkillNanoId;
  [SkillFields.NAME]: SkillName;
  [SkillFields.KIND]: SkillKind;
  [SkillFields.SKILL_TYPE]: SkillSkillType;
  [SkillFields.SUGGESTED]: SkillSuggested;
  [SkillFields.USER]: SkillUser;
  [SkillFields.CREATED_AT]: SkillCreatedAt;
}

export const fetchSkillsColumns = [
  SkillFields.ID,
  SkillFields.NANO_ID,
  SkillFields.NAME,
  SkillFields.KIND,
  SkillFields.SKILL_TYPE,
  SkillFields.SUGGESTED,
  SkillFields.CREATED_AT
];

const fetchSkillsSBColumns = fetchSkillsColumns.map((fetchSkillsColumn) =>
  snakeCase(fetchSkillsColumn)
);

export const fetchSkillsQuery = `
  ${fetchSkillsSBColumns.join(',')},
  user:user_id(id,nano_id,name,image:image_id(id,nano_id,name,file,key,created_at))
` as FetchSkillsQuery;

export const fetchSkillsApiQuery = fetchSkillsSBColumns.join(
  ','
) as FetchSkillsApiQuery;
