import { forwardRef } from '@chakra-ui/react';
import { SystemStyleObject } from '@chakra-ui/styled-system';
import { SystemProps } from '@chakra-ui/system';
import React, { Fragment } from 'react';
import { TextStyle } from '../../../theme';
import { I18nText, IsDisabled, IsLoading } from '../../../types';
import { ButtonWithRef } from '../../ButtonWithRef';
import { Text } from '../../Text';

interface PureButtonWithRefHelperProps {
  as?: React.ElementType;
  isLoading?: IsLoading;
  i18nText: I18nText;
  disabled?: IsDisabled;
  isDisabled?: IsDisabled;
  hierarchy?:
    | 'primary'
    | 'danger'
    | 'warning'
    | 'secondary'
    | 'tertiary'
    | 'link'
    | 'unstyled'
    | 'outline'
    | 'solid';
  colorScheme?: string;
  display?: SystemProps['display'];
  minW?: SystemProps['minWidth'];
  w?: SystemProps['width'];
  ml?: SystemProps['margin'];
  mr?: SystemProps['margin'];
  mt?: SystemProps['margin'];
  px?: SystemProps['padding'];
  p?: SystemProps['padding'];
  py?: SystemProps['padding'];
  size?: 'small' | 'medium' | 'large';
  justifyContent?: 'left' | 'center' | 'right';
  color?: SystemProps['color'];
  background?: SystemProps['background'];
  textStyle?: TextStyle;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  onClick?: () => void | Promise<void>;
  flexShrink?: number;
  flexGrow?: number;
  _hover?: SystemStyleObject;
  fontWeight?: SystemProps['fontWeight'];
}

const PureButtonWithRefHelper = forwardRef(
  (
    {
      as,
      isLoading,
      i18nText,
      disabled,
      textStyle,
      ...props
    }: PureButtonWithRefHelperProps,
    ref
  ) => {
    return (
      <ButtonWithRef
        as={as}
        ref={ref}
        disabled={isLoading || disabled}
        cursor="pointer"
        {...props}
      >
        {isLoading ? (
          <>
            <Text as="span" opacity={0}>
              {i18nText}
            </Text>

            <Text
              as="span"
              top="0"
              h="100%"
              display="flex"
              position="absolute"
              alignItems="center"
            >
              &bull; &bull; &bull;
            </Text>
          </>
        ) : (
          <Fragment>
            {textStyle ? (
              <Text as="span" textStyle={textStyle}>
                {i18nText}
              </Text>
            ) : (
              i18nText
            )}
          </Fragment>
        )}
      </ButtonWithRef>
    );
  }
);

PureButtonWithRefHelper.displayName = 'PureButtonWithRefHelper';

export default PureButtonWithRefHelper;
