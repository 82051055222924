import { SystemStyleObject } from '@chakra-ui/react';

const baseStyle: SystemStyleObject = {
  excessLabel: {
    bg: 'gray.50',
    border: '2px solid',
    borderColor: 'gray.300',
    color: 'gray.800',
    fontSize: 'sm',
    zIndex: 99
  }
};

export const Avatar = {
  baseStyle
};
