import {
  FetchIndustriesCacheKey,
  FetchIndustryCacheKey
} from './industriesTypes';

export class IndustriesCache {
  static indexCacheKey() {
    return 'industries' as FetchIndustriesCacheKey;
  }

  static existsCacheKey() {
    return 'industries-exist' as FetchIndustriesCacheKey;
  }

  static showCacheKey() {
    return 'industry' as FetchIndustryCacheKey;
  }

  static companyFormCacheKey() {
    return 'industries-company-form' as FetchIndustriesCacheKey;
  }

  static newIndustriesCacheKey() {
    return 'industries-new' as FetchIndustriesCacheKey;
  }
}
