const defaultProps = {
  colorScheme: 'primary'
};

const baseStyle = {
  control: {
    borderRadius: 'base',
    borderWidth: '2px'
  }
};

const circleControl = {
  control: {
    borderRadius: 'full',
    borderWidth: '2px',
    borderColor: 'gray.500'
  }
};

const whiteControl = {
  control: {
    bg: 'white'
  }
};

const sizes = {
  xs: {
    control: { height: 4, width: 4 },
    icon: { fontSize: '0.45rem' }
  },
  sm: {
    control: { height: 5, width: 5 },
    icon: { fontSize: '0.625rem' }
  },
  md: {
    control: { height: 6, width: 6 },
    icon: { fontSize: '0.75rem' }
  },
  lg: {
    control: { height: 7, width: 7 },
    icon: { fontSize: '0.875rem' }
  }
};

export const Checkbox = {
  defaultProps,
  baseStyle,
  sizes,
  variants: {
    circle: circleControl,
    white: whiteControl
  }
};
