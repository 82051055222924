import { Icon, IconProps } from '@chakra-ui/icon';

const NXCareer = ({ h = 6, w = 6, ...props }: IconProps) => (
  <Icon w={w} h={h} viewBox="0 0 24 24" {...props} fill="none">
    <path
      fill="#004585"
      d="M19.669 6.32h-1.296a.71.71 0 00-.073 1.416v4.188c0 .486-.128.857-.381 1.101a1.183 1.183 0 01-.477.274 1.16 1.16 0 00-1.116-.845H7.888c-.53 0-.978.358-1.115.845a1.19 1.19 0 01-.477-.274c-.253-.244-.38-.615-.38-1.101V7.736a.71.71 0 00-.074-1.416H4.545a.71.71 0 000 1.42h.872v4.183c0 .63.181 1.122.538 1.464.254.243.55.362.791.42a1.16 1.16 0 001.142.964h3.198v4.053l-6.066 2.49v.598h.323a1.087 1.087 0 10.834 0h.288V21.6l5.113-1.86.139 2.068a1.113 1.113 0 10.783 0l.139-2.068 5.113 1.86v.31h.287a1.086 1.086 0 10.834 0h.324v-.597l-6.067-2.49v-4.052h3.197c.574 0 1.05-.417 1.142-.964.241-.058.538-.177.791-.42.357-.342.539-.834.539-1.464V7.74h.871a.71.71 0 10-.001-1.42z"
    />
    <path
      fill="#287CC9"
      d="M9.15 11.952h5.911c.977 0 1.768-.83 1.768-1.852V1.853C16.83.83 16.038 0 15.061 0h-5.91c-.977 0-1.768.83-1.768 1.853V10.1c0 1.024.791 1.853 1.768 1.853z"
    />
  </Icon>
);
export default NXCareer;
