const messageRules = {
  required: 'Message is required'
};

const recipientIdsRules = {
  required: 'Profile recipients is required'
};

function useAddCandidateShareFormValidationRules() {
  return {
    messageRules,
    recipientIdsRules
  };
}

export default useAddCandidateShareFormValidationRules;
