import { Flex, Stack, useDisclosure } from '@chakra-ui/react';
import { Fragment } from 'react';
import { Button } from '../../../../../../../../../../../helpers/Button';
import { Heading } from '../../../../../../../../../../../helpers/Heading';
import { PlusIcon } from '../../../../../../../../../../../icons/PlusIcon';
import { JobLocationHelper } from '../../../../../../../../../helpers/JobLocationHelper';
import { ViewJobSkills } from '../../../../../../../../../pages/ViewJobDetailsPage/components/ViewJobSkills';
import { FetchJobResponse } from '../../../../../../../../../queries/fetchJob.query';
import { renderJobExperience } from '../../../../../../../../../utils/renderJobExperience';
import { renderJobGeography } from '../../../../../../../../../utils/renderJobGeography';
import { renderJobNumberOfOpenings } from '../../../../../../../../../utils/renderJobNumberOfOpenings';
import { renderJobSeniorityLevel } from '../../../../../../../../../utils/renderJobSeniorityLevel';
import { renderJobWorkAuthorization } from '../../../../../../../../../utils/renderJobWorkAuthorization';
import { renderJobWorkplace } from '../../../../../../../../../utils/renderJobWorkplace';
import { ViewJobCandidateModalPhoneSidebarCandidatesReviewed } from '../ViewJobCandidateModalPhoneSidebarCandidatesReviewed';
import { ViewJobCandidateModalPhoneSidebarJobDetailsItem } from '../ViewJobCandidateModalPhoneSidebarJobDetailsItem';

const ViewJobCandidateModalPhoneSidebarJobDetailsContent = ({
  job
}: {
  job: FetchJobResponse;
}) => {
  const {
    isOpen: isOpenCandidatesReviewed,
    onOpen: onOpenCandidatesReviewed,
    onClose: onCloseCandidatesReviewed
  } = useDisclosure();

  return (
    <Stack
      spacing={4}
      bg={{ base: 'white', lg: 'unset' }}
      p={{ base: 6, lg: 0 }}
      border={{ base: '1px solid', lg: 'none' }}
      borderColor={{ base: 'gray.100', lg: 'none' }}
      borderRadius="base"
    >
      {!isOpenCandidatesReviewed ? (
        <Fragment>
          <Flex alignItems="center" justifyContent="space-between">
            <Heading level="h5" fontSize="16px">
              Job details
            </Heading>

            <Button
              hierarchy="secondary"
              leftIcon={<PlusIcon w="16px" h="16px" />}
              fontSize="12px"
              size="small"
              onClick={onOpenCandidatesReviewed}
            >
              Candidates reviewed
            </Button>
          </Flex>

          <Flex
            flexFlow={{ base: 'column', md: 'row wrap' }}
            columnGap={6}
            rowGap={8}
            pos="relative"
            sx={{
              '.phone-sidebar-jdi:nth-child(2n):before': {
                borderLeft: '1px solid',
                borderLeftColor: 'gray.300',
                position: 'absolute',
                content: '""',
                height: '100%',
                width: '1px',
                left: -6
              }
            }}
          >
            {!!job.department?.name && (
              <ViewJobCandidateModalPhoneSidebarJobDetailsItem
                label="Department"
                value={job.department?.name}
              />
            )}

            {!!job.name && (
              <ViewJobCandidateModalPhoneSidebarJobDetailsItem
                label="title"
                value={job.name}
              />
            )}

            {!!job.userJobId && (
              <ViewJobCandidateModalPhoneSidebarJobDetailsItem
                label="job id"
                value={job.userJobId}
              />
            )}

            {!!job.workplace && (
              <ViewJobCandidateModalPhoneSidebarJobDetailsItem
                label="workplace type"
                value={renderJobWorkplace(job.workplace)}
              />
            )}

            {!!job.employment && (
              <ViewJobCandidateModalPhoneSidebarJobDetailsItem
                label="employment type"
                value={job.employment}
              />
            )}

            {!!job.experience && (
              <ViewJobCandidateModalPhoneSidebarJobDetailsItem
                label="experience"
                value={renderJobExperience(job.experience)}
              />
            )}

            {!!job.seniorityLevel && (
              <ViewJobCandidateModalPhoneSidebarJobDetailsItem
                label="seniority level"
                value={renderJobSeniorityLevel(job.seniorityLevel)}
              />
            )}

            {!!job.seniorityLevel && (
              <ViewJobCandidateModalPhoneSidebarJobDetailsItem
                label="job location"
                value={
                  <JobLocationHelper
                    job={job}
                    textStyle="body1Medium"
                    withDash
                  />
                }
              />
            )}

            {!!job.seniorityLevel && (
              <ViewJobCandidateModalPhoneSidebarJobDetailsItem
                label="work authorization"
                value={renderJobWorkAuthorization(job.workAuthorization)}
              />
            )}

            {!!job.geography && (
              <ViewJobCandidateModalPhoneSidebarJobDetailsItem
                label="geography"
                value={renderJobGeography(job.geography)}
              />
            )}

            {!!job.numberOfOpenings && (
              <ViewJobCandidateModalPhoneSidebarJobDetailsItem
                label="number of openings"
                value={renderJobNumberOfOpenings(job.numberOfOpenings)}
              />
            )}
          </Flex>

          <ViewJobSkills job={job} showCheckIcon={false} />
        </Fragment>
      ) : (
        <Fragment>
          <Flex alignItems="center" justifyContent="space-between">
            <Heading level="h5" fontSize="16px">
              Candidates reviewed
            </Heading>

            <Button
              hierarchy="secondary"
              leftIcon={<PlusIcon w="16px" h="16px" />}
              fontSize="12px"
              size="small"
              onClick={onCloseCandidatesReviewed}
            >
              View Job details
            </Button>
          </Flex>

          <ViewJobCandidateModalPhoneSidebarCandidatesReviewed job={job} />
        </Fragment>
      )}
    </Stack>
  );
};

export default ViewJobCandidateModalPhoneSidebarJobDetailsContent;
