import { Icon, IconProps } from '@chakra-ui/icon';

const PhoneIcon = ({
  w = '16px',
  h = '16px',
  fill = 'gray.500',
  ...props
}: IconProps) => (
  <Icon viewBox="0 0 16 16" w={w} h={h} fill={fill} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m11.334 9.247-2.29 2.291-4.582-4.581 2.29-2.291a.695.695 0 0 0 0-.984L3.275.204a.695.695 0 0 0-.983 0L0 2.494C0 10.195 5.805 16 13.505 16l2.291-2.29a.695.695 0 0 0 0-.984l-3.478-3.479a.696.696 0 0 0-.984 0Z"
    />
  </Icon>
);

export default PhoneIcon;
