import snakeCase from 'lodash/snakeCase';

import {
  FetchHtmlPageQuery,
  HtmlPageCreatedAt,
  HtmlPageFields,
  HtmlPageId,
  HtmlPageNanoId,
  HtmlPageName,
  HtmlPageTitle,
  HtmlPageBody
} from '../htmlPagesTypes';

export interface FetchHtmlPageResponse {
  [HtmlPageFields.ID]: HtmlPageId;
  [HtmlPageFields.NANO_ID]: HtmlPageNanoId;
  [HtmlPageFields.NAME]: HtmlPageName;
  [HtmlPageFields.TITLE]: HtmlPageTitle;
  [HtmlPageFields.BODY]: HtmlPageBody;
  [HtmlPageFields.CREATED_AT]: HtmlPageCreatedAt;
}

export const fetchHtmlPageColumns = [
  HtmlPageFields.ID,
  HtmlPageFields.NANO_ID,
  HtmlPageFields.NAME,
  HtmlPageFields.TITLE,
  HtmlPageFields.BODY,
  HtmlPageFields.CREATED_AT
];

const fetchHtmlPageSBColumns = fetchHtmlPageColumns.map((fetchHtmlPageColumn) =>
  snakeCase(fetchHtmlPageColumn)
);

export const fetchHtmlPageQuery = fetchHtmlPageSBColumns.join(
  ','
) as FetchHtmlPageQuery;
