import { JobCandidateNanoId } from '../jobCandidates/jobCandidatesTypes';
import { JobNanoId } from '../jobs/jobsTypes';
import { HiringPortalRoutes } from './HiringPortalRoutes';

const basePath = (
  jobNanoId: JobNanoId,
  jobCandidateNanoId: JobCandidateNanoId
) => HiringPortalRoutes.viewCandidateDetails(jobNanoId, jobCandidateNanoId);

export class HiringPortalJobCandidateDetailsRoutes {
  static index(jobNanoId: JobNanoId, jobCandidateNanoId: JobCandidateNanoId) {
    return `${basePath(jobNanoId, jobCandidateNanoId)}`;
  }

  static profile(jobNanoId: JobNanoId, jobCandidateNanoId: JobCandidateNanoId) {
    return `${basePath(jobNanoId, jobCandidateNanoId)}/profile`;
  }

  static tasks(jobNanoId: JobNanoId, jobCandidateNanoId: JobCandidateNanoId) {
    return `${basePath(jobNanoId, jobCandidateNanoId)}/tasks`;
  }

  static files(jobNanoId: JobNanoId, jobCandidateNanoId: JobCandidateNanoId) {
    return `${basePath(jobNanoId, jobCandidateNanoId)}/files`;
  }

  static assessment(
    jobNanoId: JobNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${basePath(jobNanoId, jobCandidateNanoId)}/assessment`;
  }

  static projects(
    jobNanoId: JobNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${basePath(jobNanoId, jobCandidateNanoId)}/projects`;
  }

  static references(
    jobNanoId: JobNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${basePath(jobNanoId, jobCandidateNanoId)}/references`;
  }

  static activities(
    jobNanoId: JobNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${basePath(jobNanoId, jobCandidateNanoId)}/activities`;
  }

  static scoreCards(
    jobNanoId: JobNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${basePath(jobNanoId, jobCandidateNanoId)}/score-cards`;
  }
}
