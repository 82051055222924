import compact from 'lodash/compact';

import { APP_HOST } from '../../config';

import { HiringPortalRoutes } from './HiringPortalRoutes';

const baseSettingsPath = HiringPortalRoutes.settings();

export class HiringPortalSettingsRoutes {
  static index() {
    return baseSettingsPath;
  }

  static personal() {
    return `${baseSettingsPath}/personal`;
  }

  static personalAbout() {
    return `${baseSettingsPath}/personal/about`;
  }
}

const basePersonalSettingsPath = HiringPortalSettingsRoutes.personal();

export class HirignPortalPersonalSettingsRoutes {
  static index() {
    return basePersonalSettingsPath;
  }

  static about() {
    return `${basePersonalSettingsPath}/about`;
  }

  static qualifications() {
    return `${basePersonalSettingsPath}/qualifications`;
  }

  static experience() {
    return `${basePersonalSettingsPath}/experience`;
  }

  static certification() {
    return `${basePersonalSettingsPath}/certification`;
  }

  static education() {
    return `${basePersonalSettingsPath}/education`;
  }

  static security() {
    return `${basePersonalSettingsPath}/security`;
  }

  static notifications() {
    return `${basePersonalSettingsPath}/notifications`;
  }

  static emailSignature(options = { absolute: false }) {
    return compact([
      options.absolute ? APP_HOST : null,
      basePersonalSettingsPath,
      '/email-signature'
    ]).join('');
  }
}
