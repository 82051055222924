import { Icon, IconProps } from '@chakra-ui/icon';

const PendingCompletionIcon = ({
  w = '24px',
  h = '24px',
  ...props
}: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="none" w={w} h={h} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.665 12.667a.667.667 0 0 1-1.333 0v-4a.667.667 0 0 1 1.333 0v4ZM12 16a.667.667 0 1 1 0-1.334.667.667 0 0 1 0 1.334ZM12 4c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8Z"
      fill="#EEA401"
    />
  </Icon>
);

export default PendingCompletionIcon;
