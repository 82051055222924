import { Icon, IconProps } from '@chakra-ui/icon';

const CompaniesIcon = ({
  w = '24px',
  h = '24px',
  fill = 'gray.500',
  ...props
}: IconProps) => (
  <Icon viewBox="0 0 24 24" w={w} h={h} fill={fill} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.5 21h.75a.75.75 0 0 0 .75-.75V13.5a.75.75 0 0 0-.75-.75h-.75V21ZM4.5 20.25v-7.5h-.75a.75.75 0 0 0-.75.75v6.75c0 .414.335.75.75.75h.75v-.75ZM15.75 6.75H13.5v-1.5h2.25v1.5Zm0 3H13.5v-1.5h2.25v1.5Zm0 3H13.5v-1.5h2.25v1.5Zm0 3H13.5v-1.5h2.25v1.5Zm-5.25-9H8.25v-1.5h2.25v1.5Zm0 3H8.25v-1.5h2.25v1.5Zm0 3H8.25v-1.5h2.25v1.5Zm0 3H8.25v-1.5h2.25v1.5ZM17.25 3H6.75a.75.75 0 0 0-.75.75v16.5c0 .414.335.75.75.75h4.5v-3h1.5v3h4.5a.75.75 0 0 0 .75-.75V3.75a.75.75 0 0 0-.75-.75Z"
    />
  </Icon>
);
export default CompaniesIcon;
