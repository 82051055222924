import { Icon, IconProps } from '@chakra-ui/icon';

const LocationIcon = ({ w = '20px', h = '20px', ...props }: IconProps) => (
  <Icon viewBox="0 0 20 20" w={w} h={h} fill="currentcolor" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.175 8.263a2.09 2.09 0 0 1 2.087-2.087 2.09 2.09 0 0 1 2.087 2.087 2.09 2.09 0 0 1-2.087 2.087 2.09 2.09 0 0 1-2.087-2.087M10.261 18a.696.696 0 0 0 .494-.205c.235-.238 5.767-5.855 5.767-9.534C16.522 4.34 13.338 2 10.26 2 7.183 2 4 4.342 4 8.26c0 3.68 5.532 9.297 5.767 9.535.13.131.308.205.494.205"
    />
  </Icon>
);

export default LocationIcon;
