import {
  Avatar,
  AvatarGroup,
  Box,
  ColorProps,
  Flex,
  HStack,
  Stack,
  SystemProps,
  ThemeTypings,
  VStack
} from '@chakra-ui/react';
import compact from 'lodash/compact';
import size from 'lodash/size';
import { ReactNode } from 'react';
import { Button } from '../../../../../helpers/Button';
import { Heading } from '../../../../../helpers/Heading';
import { Logo } from '../../../../../helpers/Logo';
import { NextLinkHelper } from '../../../../../helpers/NextLinkHelper';
import { Text } from '../../../../../helpers/Text';
import { LocationIcon } from '../../../../../icons/LocationIcon';

interface ShareCandidateCardEmailItemProps {
  title: string;
  name?: string;
  designation?: string;
  company: { name: string; logoUrl: string };
  buttonActionText?: string;

  buttonColor?: ThemeTypings['colorSchemes'];
  titleColor?: ColorProps['color'];
  buttonLinkUrl?: string;
  location?: string;
  jobType?: string;
  jobTitle?: string;
  operationMode?: string;
}

export const ShareJobCardEmailItem = ({
  title,
  company,
  buttonLinkUrl,
  buttonActionText,
  buttonColor = 'primary',

  location,
  jobType,
  jobTitle,
  operationMode
}: ShareCandidateCardEmailItemProps) => (
  <Flex
    w={96}
    gap={8}
    bg="white"
    borderRadius="4"
    overflow="hidden"
    border="1px solid"
    borderColor="gray.200"
    flexDir="column"
  >
    <Flex px={4} pt={4} justifyContent={'center'}>
      <Flex gap={1} flex={1} justifyContent={'center'}>
        <Avatar size="sm" src={company.logoUrl} />
        <Stack spacing={0}>
          <Text lineHeight="20px">{company.name}</Text>
          <Text
            fontSize="10px"
            color="gray.600"
            lineHeight="12px"
            fontWeight="medium"
          >
            Talent Acquisition
          </Text>
        </Stack>
      </Flex>
    </Flex>

    <Stack spacing={3}>
      {operationMode ? (
        <Text
          textStyle="body1Medium"
          textAlign="center"
          fontWeight={'400'}
          size={'12px'}
          color="#697275"
        >
          {operationMode}
        </Text>
      ) : null}
      {jobTitle ? (
        <Text
          textStyle="body1Medium"
          textAlign="center"
          size={'16px'}
          color="gray.900"
          fontWeight={'500'}
        >
          {jobTitle}
        </Text>
      ) : null}
      {jobType ? (
        <Text
          textStyle="body1Medium"
          textAlign="center"
          size={'12px'}
          color="gray.600"
          fontWeight={'400'}
        >
          {jobType}
        </Text>
      ) : null}
      {location ? (
        <Flex justifyContent={'center'} gap={'8px'}>
          <LocationIcon fill={'#697275'} />
          <Text
            textStyle="body1Medium"
            textAlign="center"
            size={'14px'}
            fontWeight={'400'}
            color="gray.600"
          >
            {location}
          </Text>
        </Flex>
      ) : null}
      {title ? (
        <Box px={'15px'} pt={'24px'}>
          <Flex
            justifyContent={'space-between'}
            bg={'#F8FAFB'}
            py={'8px'}
            px={'16px'}
            borderRadius={'4px'}
          >
            <Box>
              <Text
                textStyle="body1Medium"
                textAlign="left"
                size={'12px'}
                fontWeight={'400'}
                color="#697275"
              >
                Experience
              </Text>
              <Text
                textStyle="body1Medium"
                textAlign="left"
                size={'14px'}
                fontWeight={'500'}
                color="gray.900"
              >
                4-6 years
              </Text>
            </Box>
            <Flex
              borderStyle={'solid'}
              borderColor={'gray.400'}
              borderWidth={'1px'}
              // height={'16px'}
              alignItems={'end'}
              marginTop={'30px'}
            />
            <Box>
              <Text
                textStyle="body1Medium"
                textAlign="right"
                size={'12px'}
                fontWeight={'400'}
                color="#697275"
              >
                Workplace type
              </Text>
              <Text
                textStyle="body1Medium"
                fontWeight={'500'}
                textAlign="right"
                size={'14px'}
                color="gray.900"
              >
                On site
              </Text>
            </Box>
          </Flex>
        </Box>
      ) : null}
    </Stack>

    {buttonLinkUrl ? (
      <Flex
        alignItems="center"
        justifyContent="center"
        __css={{
          display: 'flex',
          '> a': {
            textDecoration: 'red'
          }
        }}
      >
        <NextLinkHelper href={buttonLinkUrl} passHref>
          <Button
            as="a"
            w={60}
            size="small"
            colorScheme={buttonColor}
            hierarchy="solid"
          >
            {buttonActionText || 'Open Link'}
          </Button>
        </NextLinkHelper>
      </Flex>
    ) : null}

    <Flex
      h={12}
      gap={2}
      bg="#F7F7F9"
      alignItems="center"
      justifyContent="center"
      display={{ base: 'none', md: 'flex' }}
      boxShadow="0px 0px 2px rgba(0, 0, 0, 0.25)"
    >
      <Flex alignItems="center">
        <Text color="gray.600" mt={-2.5}>
          Powered by
        </Text>
        <Logo h={8} w={20} />
      </Flex>
    </Flex>
  </Flex>
);

export default ShareJobCardEmailItem;
