import { Flex, IconButtonProps } from '@chakra-ui/react';
import { StarIcon } from '../../icons/StarIcon';
import { IconButton, ButtonGroup } from '@chakra-ui/button';
import { MouseEvent, useState } from 'react';

interface Rating extends IconButtonProps {
  readonly?: boolean;
  value: number;
  iconSize?: number;
  starStroke?: string;
}

export type RatingProps = Omit<Rating, 'aria-label' | 'onChange'>;

const Rating = ({
  mb,
  mt,
  onClick,
  readonly,
  iconSize,
  starStroke,
  value = 0,
  py = 1.5,
  px = 0.5,
  ...props
}: RatingProps) => {
  const [ratingValue, setRatingValue] = useState(value);
  const [hoverValue, setHoverValue] = useState<number | null>(null);

  const setRating = (e: MouseEvent<HTMLButtonElement>) => {
    const { value } = e.currentTarget;
    setRatingValue(parseInt(value));

    onClick && onClick(e);
  };

  const handleMouseEnter = (index: number) => {
    if (!readonly) {
      setHoverValue(index);
    }
  };

  const handleMouseLeave = () => {
    setHoverValue(null);
  };

  return (
    <Flex alignItems="center" mb={mb} mt={mt}>
      <ButtonGroup spacing={0} isDisabled={readonly}>
        {[...Array(5)].map((_star, index) => {
          index += 1;
          return (
            <IconButton
              key={index}
              py={py}
              h="auto"
              px={px}
              minW={4}
              size="xs"
              border="none"
              value={index}
              display="flex"
              borderRadius={0}
              variant="unstyled"
              aria-label={`Rating ${index}`}
              onClick={setRating}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
              _disabled={{
                cursor: 'default'
              }}
              icon={
                <StarIcon
                  filled={(hoverValue || ratingValue) >= index}
                  w={iconSize}
                  h={iconSize}
                  stroke={
                    (hoverValue || ratingValue) >= index
                      ? '#EEA401'
                      : starStroke
                  }
                />
              }
              {...props}
            />
          );
        })}
      </ButtonGroup>
    </Flex>
  );
};

export default Rating;
