import {
  MayBe,
  FieldBrand,
  ItemID,
  ItemUUID,
  ItemNanoId,
  ItemCreatedAt
} from '../../../types';

import {
  UserId,
  UserNanoId,
  UserName,
  UserImage
} from '../../users/usersTypes';

export type SkillId = FieldBrand<ItemID, 'SkillId'>;
export type SkillIds = FieldBrand<ItemID[], 'SkillIds'>;
export type SkillUuid = FieldBrand<ItemUUID, 'SkillUuid'>;
export type MayBeSkillUuid = MayBe<SkillUuid>;
export type SkillNanoId = FieldBrand<ItemNanoId, 'SkillNanoId'>;
export type MayBeSkillNanoId = MayBe<SkillNanoId>;
export type SkillName = FieldBrand<string, 'SkillName'>;
export type SkillKind = FieldBrand<SkillKinds, 'SkillKind'>;
export type SkillSkillType = FieldBrand<SkillTypes, 'SkillSkillType'>;
export type MayBeSkillSkillType = MayBe<SkillSkillType>;
export type SkillKindValue = FieldBrand<SkillKindValues, 'SkillKindValue'>;
export type MayBeSkillKind = MayBe<SkillKind>;
export type SkillSuggested = FieldBrand<boolean, 'SkillSuggested'>;
export type SkillUserId = FieldBrand<UserId, 'SkillUserId'>;
export type SkillUser = FieldBrand<
  {
    id: UserId;
    nanoId: UserNanoId;
    name: UserName;
    image: UserImage;
  },
  'SkillUser'
>;
export type SkillCreatedAt = FieldBrand<ItemCreatedAt, 'SkillCreatedAt'>;

export const enum SkillKinds {
  UNKNOWN = 'unknown',
  COMPUTER = 'computer',
  INDUSTRY = 'industry'
}

export const enum SkillTypes {
  UNKNOWN = 'unknown',
  IT = 'it',
  PROFESSIONAL = 'professional',
  SOFT = 'soft'
}

export const enum SkillKindValues {
  UNKNOWN = 'unknown',
  COMPUTER = 'computer',
  INDUSTRY = 'industry'
}

export const skillKinds = [
  SkillKinds.UNKNOWN,
  SkillKinds.COMPUTER,
  SkillKinds.INDUSTRY
] as SkillKind[];

export const skillTypes = [
  SkillTypes.UNKNOWN,
  SkillTypes.IT,
  SkillTypes.PROFESSIONAL,
  SkillTypes.SOFT
] as SkillSkillType[];

export const enum SkillFields {
  ID = 'id',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  NAME = 'name',
  KIND = 'kind',
  SKILL_TYPE = 'skillType',
  SUGGESTED = 'suggested',
  USER_ID = 'userId',
  USER = 'user',
  CREATED_AT = 'createdAt'
}
