import React, { ReactNode } from 'react';
import NextLink, { LinkProps } from 'next/link';

interface NextLinkHelperProps {
  href: LinkProps['href'];
  passHref?: boolean;
  children: ReactNode;
}

function NextLinkHelper({ href, passHref, children }: NextLinkHelperProps) {
  return (
    <NextLink href={href} passHref={passHref}>
      {children}
    </NextLink>
  );
}

export default NextLinkHelper;
