import React, { memo } from 'react';
import NextLink, { LinkProps } from 'next/link';
import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps
} from '@chakra-ui/layout';
import { As } from '@chakra-ui/system';

import { Button, ButtonProps } from '../Button';
import { Text } from '../Text';

type PureNextLinkHelperProps = {
  type?: 'link' | 'button' | 'text';
  href: LinkProps['href'];
  i18nText: string;
  as?: As;
  pb?: string;
  color?: string;
  fontWeight?: string;
  size?: 'small' | 'medium' | 'large';
} & Omit<ChakraLinkProps, 'href'> &
  Omit<ButtonProps, 'type' | 'children'>;

function PureNextLinkHelper({
  type = 'link',
  href,
  i18nText,
  as,
  pb,
  color,
  fontWeight,
  size,
  w,
  _hover,
  ...props
}: PureNextLinkHelperProps) {
  switch (type) {
    case 'link':
      return (
        <NextLink href={href} passHref>
          <ChakraLink pb={pb} color={color} w={w} _hover={_hover}>
            {i18nText}
          </ChakraLink>
        </NextLink>
      );

    case 'button':
      return (
        <NextLink href={href} passHref>
          <Button as={as} size={size} pb={pb} w={w} _hover={_hover} {...props}>
            {i18nText}
          </Button>
        </NextLink>
      );

    case 'text':
      return (
        <NextLink href={href} passHref>
          <Text as={as} fontWeight={fontWeight}>
            {i18nText}
          </Text>
        </NextLink>
      );

    default:
      return null;
  }
}

export default memo<PureNextLinkHelperProps>(PureNextLinkHelper);
