import {
  JobFunctionItemBffID,
  JobFunctionItemBffNanoID,
  FetchJobFunctionsBffURL,
  FetchJobFunctionBffURL,
  FetchJobFunctionByNanoIdBffURL,
  CreateJobFunctionBffURL,
  UpdateJobFunctionBffURL,
  DeleteJobFunctionBffURL
} from './jobFunctionsTypes';

import { BaseBffRoutes } from '../BaseBffRoutes';

export class JobFunctionsBffRoutes extends BaseBffRoutes {
  protected static route = 'jobFunctions';

  static fetchJobFunctionsRoute() {
    return this.fetchItemsRoute<FetchJobFunctionsBffURL>();
  }

  static fetchJobFunctionsIndexRoute() {
    return this.fetchItemsIndexRoute<FetchJobFunctionsBffURL>();
  }

  static fetchJobFunctionRoute(jobFunctionItemBffID: JobFunctionItemBffID) {
    return this.fetchItemRoute<FetchJobFunctionBffURL>(jobFunctionItemBffID);
  }

  static fetchJobFunctionIndexRoute(
    jobFunctionItemBffNanoID: JobFunctionItemBffNanoID
  ) {
    return this.fetchItemIndexRoute<FetchJobFunctionBffURL>(
      jobFunctionItemBffNanoID
    );
  }

  static fetchJobFunctionByNanoIdRoute(
    jobFunctionItemBffNanoID: JobFunctionItemBffNanoID
  ) {
    return this.fetchItemByNanoIdRoute<FetchJobFunctionByNanoIdBffURL>(
      jobFunctionItemBffNanoID
    );
  }

  static createJobFunctionRoute() {
    return this.createItemRoute<CreateJobFunctionBffURL>();
  }

  static updateJobFunctionRoute(
    jobFunctionItemBffNanoID: JobFunctionItemBffID | JobFunctionItemBffNanoID
  ) {
    return this.updateItemRoute<UpdateJobFunctionBffURL>(
      jobFunctionItemBffNanoID
    );
  }

  static deleteJobFunctionRoute(
    jobFunctionItemBffNanoID: JobFunctionItemBffNanoID
  ) {
    return this.deleteItemRoute<DeleteJobFunctionBffURL>(
      jobFunctionItemBffNanoID
    );
  }
}
