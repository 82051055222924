import { useMutation, useQueryClient } from 'react-query';

import {
  FetchItemsCacheKey,
  FetchItemsIndexCacheKey,
  FetchItemCacheKey,
  FetchItemIndexCacheKey,
  UpdateItemError,
  UpdateItemErrorMessage,
  UpdateItemIsLoading
} from '../../../../types';

import { parseRequestError } from '../../../../utils/parseRequestError';

interface UpdateItemOptions<UpdateItemRequestData> {
  mutationFn: (
    queryInput: UpdateItemRequestData
  ) => Promise<UpdateItemRequestData>;
  cacheKeys?: (
    | FetchItemsCacheKey
    | FetchItemsIndexCacheKey
    | FetchItemCacheKey
    | FetchItemIndexCacheKey
  )[];
}

function useUpdateItem<
  UpdateItemRequestData,
  UpdateItemErrorResponse extends { message: UpdateItemError }
>({ mutationFn, cacheKeys = [] }: UpdateItemOptions<UpdateItemRequestData>) {
  const queryClient = useQueryClient();

  const { data, error, isLoading, mutateAsync, reset } = useMutation<
    UpdateItemRequestData,
    UpdateItemErrorResponse,
    UpdateItemRequestData
  >((queryInput) => mutationFn(queryInput), {
    onSettled: async () =>
      cacheKeys?.map((cacheKey) => queryClient.invalidateQueries(cacheKey))
  });

  return {
    updateItemData: data,
    updateItemError: error,
    updateItemErrorMessage: parseRequestError(error) as UpdateItemErrorMessage,
    updateItemIsLoading: isLoading as UpdateItemIsLoading,
    updateItem: (updateItemData: UpdateItemRequestData) =>
      mutateAsync(updateItemData),
    updateItemReset: () => reset()
  };
}

export default useUpdateItem;
