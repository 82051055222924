import { FetchItemsPage } from '../../../../../../types';

import { PaginatedItemsReducerAction } from '../../reducers/paginatedItemsReducer';

import { PAGINATE_ITEMS } from '../../usePaginatedItems.constants';

function paginateItemsAction(
  nextPage: FetchItemsPage
): PaginatedItemsReducerAction {
  return {
    type: PAGINATE_ITEMS,
    nextPage
  };
}

export default paginateItemsAction;
