import { Icon, IconProps } from '@chakra-ui/icon';

const CompletedFieldIcon = ({
  w = '24px',
  h = '24px',
  ...props
}: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="none" w={w} h={h} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 4a8 8 0 1 1 0 16 8 8 0 0 1 0-16Z"
      fill="#0BAD90"
    />
    <path
      d="m8.729 12.002 2.181 2.182 4.364-4.364"
      stroke="#fff"
      strokeWidth={1.636}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

export default CompletedFieldIcon;
