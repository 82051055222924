import { Icon, IconProps } from '@chakra-ui/icon';

const RemoveIcon = ({
  w = 6,
  h = 6,
  fill = '#777984',
  ...props
}: IconProps) => (
  <Icon viewBox="0 0 24 24" w={w} h={h} fill={fill} {...props}>
    <path d="M15.373 8.627a.909.909 0 0 0-1.282 0l-2.09 2.091-2.083-2.09a.913.913 0 1 0-1.29 1.29L10.717 12l-2.09 2.082a.91.91 0 1 0 1.281 1.281L12 13.283l2.082 2.082a.909.909 0 0 0 1.282-1.282L13.282 12l2.082-2.082a.91.91 0 0 0 .009-1.29Z" />
    <path d="M12 2a10 10 0 1 0 0 20 10 10 0 0 0 0-20Zm0 18.182a8.181 8.181 0 1 1 0-16.363 8.181 8.181 0 0 1 0 16.363Z" />
  </Icon>
);

export default RemoveIcon;
