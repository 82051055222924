import React, { ReactNode } from 'react';
import { Flex } from '@chakra-ui/layout';
import { Avatar } from '@chakra-ui/avatar';

import { Text } from '../../../../../Text';

import { CheckIcon } from '../../../../../../icons/CheckIcon';

function SelectFieldFormatOptionLabel({
  image,
  label,
  withCheckIcon,
  withImages
}: {
  image?: string | ReactNode;
  withCheckIcon?: boolean;
  withImages?: boolean;
  label: string;
}) {
  const img1 =
    withImages && typeof image === 'string' ? (
      <Avatar size="2xs" src={image} />
    ) : null;

  const img2 = withImages && typeof image !== 'string' ? image : null;

  return (
    <Flex gap={3} direction="row" zIndex={1} w="100%" alignItems="center">
      {img1 || img2}

      <Text noOfLines={1}>{label}</Text>

      {withCheckIcon ? <CheckIcon fill="green.500" /> : null}
    </Flex>
  );
}

export default SelectFieldFormatOptionLabel;
