import { Icon, IconProps } from '@chakra-ui/icon';

const ChevronUpIcon = ({
  w = '20px',
  h = '20px',
  fill = '#98A5A9',
  ...props
}: IconProps) => (
  <Icon viewBox="0 0 16 16" w={w} h={h} fill={fill} {...props}>
    <path d="m8.002 5.279-4.32 4.32 1.12 1.12 3.2-3.2 3.2 3.2 1.12-1.12-4.32-4.32Z" />
  </Icon>
);

export default ChevronUpIcon;
