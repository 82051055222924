import { Icon, IconProps } from '@chakra-ui/icon';

const ThumbDownIcon = ({ w = '16px', h = '16px', ...props }: IconProps) => (
  <Icon viewBox="0 0 16 16" w={w} h={h} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.249.894H.801v7.2h2.448M4.148 8.097l2.01 7.2c1.109 0 2.008-.88 2.008-1.964V9.406h5.025c1.231 0 2.173-1.073 1.985-2.262l-.72-4.582C14.303 1.604 13.46.897 12.47.897H4.148v7.2Z"
      fill="currentColor"
    />
  </Icon>
);

export default ThumbDownIcon;
