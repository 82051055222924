import { FieldBrand, ItemRecordID } from '../../../types';

import { UserUuid } from '../../users/usersTypes';

export type SkillLicenseRecordId = FieldBrand<
  ItemRecordID,
  'SkillLicenseRecordId'
>;

export type SkillLicenseUserUuid = FieldBrand<UserUuid, 'SkillLicenseUserUuid'>;

export const enum SkillLicenseIndexFields {
  ID = 'id',
  RECORD_ID = 'recordId',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  CREATED_AT = 'createdAt',

  USER_UUID = 'userUuid'
}
