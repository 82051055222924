import { Portal, useDisclosure } from '@chakra-ui/react';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import filter from 'lodash/filter';
import some from 'lodash/some';
import map from 'lodash/map';
import includes from 'lodash/includes';
import uniqBy from 'lodash/uniqBy';

import { FetchJobEnabled, JobNanoId } from '../main/jobs/jobsTypes';
import {
  ShareCandidateProfile,
  ShareCandidateProfileJobCandidates,
  ShareCandidateProfileJobCandidate,
  ShareCandidateProfileCandidateShare,
  ShareCandidateProfileFieldsToShareProps
} from '../main/candidateShares/components/ShareCandidateProfile';
import { useFetchJobByNanoId } from '../main/jobs/hooks/useFetchJobByNanoId';
import {
  FetchJobResponse,
  fetchJobQuery
} from '../main/jobs/queries/fetchJob.query';
import { JobsCache } from '../main/jobs/JobsCache';
import { FullPageLoader } from '../helpers/FullPageLoader';

interface ShareCandidatesProfileContextProps {
  isShareProfileOpened: boolean;
  checkedJobCandidates: ShareCandidateProfileJobCandidates;
  onOpenShareProfile: () => void;
  onCloseShareProfile: () => void;
  toggleJobCandidateCheck: (
    jobCandidate: ShareCandidateProfileJobCandidate,
    event?: ShareCandidatesProfileCheckEvent
  ) => void;
  toggleMultipleJobCandidatesCheck: (
    jobCandidates: ShareCandidateProfileJobCandidates,
    event: ShareCandidatesProfileCheckEvent
  ) => void;
  shareJobCandidate: (jobCandidate: ShareCandidateProfileJobCandidate) => void;
  setSharedJobCandidateJobNanoId: (jobNanoId: JobNanoId) => void;
}

interface ShareCandidatesProfileProviderProps {
  jobNanoId: JobNanoId;
  children: ReactNode;
}

interface ShareCandidatesProfileCheckEvent {
  target: { checked: boolean };
}

const ShareCandidatesProfileContext =
  createContext<ShareCandidatesProfileContextProps>({
    isShareProfileOpened: false,
    checkedJobCandidates: [],
    onOpenShareProfile: () => {
      /* do nothing */
    },
    onCloseShareProfile: () => {
      /* do nothing */
    },
    toggleJobCandidateCheck: () => {
      /* do nothing */
    },
    toggleMultipleJobCandidatesCheck: () => {
      /* do nothing */
    },
    shareJobCandidate: () => {
      /* do nothing */
    },
    setSharedJobCandidateJobNanoId: () => {
      /* do nothing */
    }
  });

export const ShareCandidatesProfileProvider = ({
  children,
  jobNanoId
}: ShareCandidatesProfileProviderProps) => {
  const [jobCandidates, setJobCandidates] =
    useState<ShareCandidateProfileJobCandidates>([]);
  const [candidateShare, setCandidateShare] =
    useState<ShareCandidateProfileCandidateShare | null>(null);
  const [fieldsToShare, setFieldsToShare] =
    useState<ShareCandidateProfileFieldsToShareProps>(allFieldsToShare);
  const [sharedJobCandidateJobNanoId, setSharedJobCandidateJobNanoId] =
    useState<JobNanoId | null>(null);

  const { job, jobIsFetched, jobIsLoading } =
    useFetchJobByNanoId<FetchJobResponse>({
      query: fetchJobQuery,
      jobNanoId: jobNanoId || (sharedJobCandidateJobNanoId as JobNanoId),
      enabled: (!!jobNanoId ||
        !!sharedJobCandidateJobNanoId) as FetchJobEnabled,
      cacheKey: JobsCache.showCacheKey()
    });

  useEffect(() => {
    setJobCandidates([]);
  }, [jobNanoId]);

  const {
    isOpen: isShareProfileOpened,
    onOpen: onOpenShareProfile,
    onClose: onCloseShareProfile
  } = useDisclosure({
    onClose: () => {
      if (sharedJobCandidateJobNanoId) {
        setJobCandidates([]);
        setCandidateShare(null);
        setSharedJobCandidateJobNanoId(null);
      }
    }
  });

  const toggleJobCandidateCheck = useCallback<
    (
      jobCandidate: ShareCandidateProfileJobCandidate,
      event?: ShareCandidatesProfileCheckEvent
    ) => void
  >(
    (jobCandidate, event) => {
      const checked = event?.target?.checked;
      const isJobCandidateExists = some(
        jobCandidates,
        (candidate) => candidate.id === jobCandidate.id
      );

      let newJobCandidates = [] as ShareCandidateProfileJobCandidates;

      if (isJobCandidateExists && !checked) {
        newJobCandidates = filter(
          jobCandidates,
          (candidate) => candidate.id !== jobCandidate.id
        );
      } else {
        newJobCandidates = [...jobCandidates, jobCandidate];
      }

      setJobCandidates(newJobCandidates);
    },
    [jobCandidates]
  );

  const toggleMultipleJobCandidatesCheck = useCallback<
    (
      candidates: ShareCandidateProfileJobCandidates,
      event: ShareCandidatesProfileCheckEvent
    ) => void
  >(
    (candidates, event) => {
      const checked = event?.target?.checked;
      if (checked) {
        const newJobCandidates = [...jobCandidates, ...candidates];

        const uniqueCandidates = uniqBy(newJobCandidates, 'id');

        setJobCandidates(uniqueCandidates);
      } else {
        const uncheckedJobCandidateIds = map(
          candidates,
          (candidate) => candidate.id
        );

        const newJobCandidates = filter(
          jobCandidates,
          (jobCandidate) => !includes(uncheckedJobCandidateIds, jobCandidate.id)
        );

        setJobCandidates(newJobCandidates);
      }
    },
    [jobCandidates]
  );

  const shareJobCandidate = useCallback<
    (jobCandidate: ShareCandidateProfileJobCandidate) => void
  >(
    (jobCandidate) => {
      const isJobCandidateExists = some(
        jobCandidates,
        (candidate) => candidate.id === jobCandidate.id
      );

      if (isJobCandidateExists) {
        onOpenShareProfile();
      } else {
        const newJobCandidates = [...jobCandidates, jobCandidate];
        setJobCandidates(newJobCandidates);

        onOpenShareProfile();
      }
    },
    [jobCandidates, onOpenShareProfile]
  );

  const onProfilesShared = useCallback<() => void>(() => {
    setJobCandidates([]);
    setCandidateShare(null);
  }, []);

  return (
    <ShareCandidatesProfileContext.Provider
      value={{
        isShareProfileOpened,
        onOpenShareProfile,
        onCloseShareProfile,
        toggleJobCandidateCheck,
        shareJobCandidate,
        toggleMultipleJobCandidatesCheck,
        checkedJobCandidates: jobCandidates,
        setSharedJobCandidateJobNanoId
      }}
    >
      {children}

      {!jobIsFetched && jobIsLoading && sharedJobCandidateJobNanoId && (
        <FullPageLoader />
      )}

      {isShareProfileOpened && job ? (
        <Portal>
          <ShareCandidateProfile
            isOpen={isShareProfileOpened}
            onClose={onCloseShareProfile}
            job={job}
            jobCandidates={jobCandidates}
            updateJobCandidates={setJobCandidates}
            candidateShare={candidateShare}
            updateCandidateShare={setCandidateShare}
            onProfilesShared={onProfilesShared}
            fieldsToShare={fieldsToShare}
            updateFieldsToShare={setFieldsToShare}
          />
        </Portal>
      ) : null}
    </ShareCandidatesProfileContext.Provider>
  );
};

export const useShareCandidatesProfileContext = () =>
  useContext(ShareCandidatesProfileContext);

export const allFieldsToShare: ShareCandidateProfileFieldsToShareProps = [
  { label: 'Current Company', name: 'currentCompany', checked: true },
  { label: 'Assessment', name: 'assessment', checked: true },
  { label: 'Resume', name: 'resume', checked: true },
  { label: 'Projects', name: 'projects', checked: true },
  { label: 'Interview', name: 'interview', checked: true },
  { label: 'Candidate name', name: 'candidateName', checked: true },
  { label: 'Job Title', name: 'jobTitle', checked: true },
  { label: 'Email', name: 'email', checked: true },
  { label: 'Phone number', name: 'phoneNumber', checked: true },
  { label: 'Photos', name: 'photos', checked: true },
  { label: 'Location', name: 'location', checked: true },
  { label: 'LinkedIn URL', name: 'linkedinUrl', checked: true },
  { label: 'Education', name: 'education', checked: true },
  {
    label: 'Certification/Licenses',
    name: 'certification',
    checked: true
  },
  { label: 'Skills & Industry knowledge', name: 'skills', checked: true },
  { label: 'About', name: 'about', checked: true },
  { label: 'Job Function', name: 'jobFunction', checked: true },
  { label: 'Work Authorization', name: 'workAuthorization', checked: true },
  { label: 'Specialization', name: 'specialization', checked: true },
  { label: 'Salary', name: 'salary', checked: true }
];
