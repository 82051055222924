import { usePaginatedItems } from '../../../common/hooks/usePaginatedItems';

import {
  FetchWorkflowStagesQuery,
  FetchWorkflowStagesCacheKey,
  FetchWorkflowStagesTotalCount,
  FetchWorkflowStagesPage,
  FetchWorkflowStagesPageSize,
  FetchWorkflowStagesError,
  FetchWorkflowStagesErrorMessage,
  FetchWorkflowStagesIsLoading,
  FetchWorkflowStagesIsFetched,
  FetchWorkflowStagesIsPlaceholderData,
  FetchWorkflowStagesEnabled,
  FetchWorkflowStagesPrefetchNextPage,
  FetchWorkflowStagesCountType,
  FetchWorkflowStagesFilters,
  FetchWorkflowStagesSort,
  FetchWorkflowStageID,
  FetchWorkflowStageQuery,
  FetchWorkflowStageCacheKey
} from '../../workflowStagesTypes';

import { WorkflowStagesBffRequests } from '../../WorkflowStagesBffRequests';

interface PaginatedWorkflowStagesBaseOptions<
  PaginatedWorkflowStagesResponseType
> {
  cacheKey: FetchWorkflowStagesCacheKey;
  query: FetchWorkflowStagesQuery;
  countType?: FetchWorkflowStagesCountType;
  initialPage?: FetchWorkflowStagesPage;
  initialPageSize?: FetchWorkflowStagesPageSize;
  initialFilters?: FetchWorkflowStagesFilters;
  initialSort?: FetchWorkflowStagesSort;
  enabled?: FetchWorkflowStagesEnabled;
  prefetchNextPage?: FetchWorkflowStagesPrefetchNextPage;
  placeholderWorkflowStages?: PaginatedWorkflowStagesResponseType[];
  placeholderWorkflowStagesCount?: FetchWorkflowStagesTotalCount;
  placeholderData?: () => PaginatedWorkflowStagesResponse<PaginatedWorkflowStagesResponseType>;
}

interface PaginatedWorkflowStagesOptionsWithoutItem {
  fetchItemCacheKey?: never;
  itemQuery?: never;
}

interface PaginatedWorkflowStagesOptionsWithItem {
  fetchItemCacheKey: FetchWorkflowStageCacheKey;
  itemQuery: FetchWorkflowStageQuery;
}

type PaginatedWorkflowStagesOptions<PaginatedWorkflowStagesResponseType> =
  PaginatedWorkflowStagesBaseOptions<PaginatedWorkflowStagesResponseType> &
    (
      | PaginatedWorkflowStagesOptionsWithoutItem
      | PaginatedWorkflowStagesOptionsWithItem
    );

interface PaginatedWorkflowStagesResponse<PaginatedWorkflowStagesResponseType> {
  data: PaginatedWorkflowStagesResponseType[];
  totalCount: FetchWorkflowStagesTotalCount;
}

function usePaginatedWorkflowStages<PaginatedWorkflowStagesResponseType>({
  cacheKey,
  query,
  countType,
  initialPage,
  initialPageSize,
  initialFilters,
  initialSort,
  enabled,
  prefetchNextPage,
  placeholderWorkflowStages,
  placeholderWorkflowStagesCount,
  placeholderData,
  fetchItemCacheKey,
  itemQuery
}: PaginatedWorkflowStagesOptions<PaginatedWorkflowStagesResponseType>) {
  const {
    items,
    itemsTotalCount,
    itemsError,
    itemsErrorMessage,
    itemsIsFetched,
    itemsIsLoading,
    itemsIsPlaceholderData,
    itemsCurrentPage,
    itemsCurrentPageSize,
    itemsCurrentFilters,
    itemsCurrentSort,
    fetchItems,
    paginateItems,
    changeItemsPageSize,
    filterItems,
    changeItemsFilters,
    clearItemsFilters,
    sortItems,
    prefetchItems,
    prefetchItem
  } = usePaginatedItems<PaginatedWorkflowStagesResponseType>({
    cacheKey,
    initialPage,
    initialPageSize,
    initialFilters,
    initialSort,
    enabled,
    prefetchNextPage,
    placeholderItems: placeholderWorkflowStages,
    placeholderItemsCount: placeholderWorkflowStagesCount,
    placeholderData,
    queryFn: (params) =>
      WorkflowStagesBffRequests.fetchWorkflowStages<PaginatedWorkflowStagesResponseType>(
        {
          query,
          countType,
          page: params.page as FetchWorkflowStagesPage,
          pageSize: params.pageSize as FetchWorkflowStagesPageSize,
          filters: params.filters as FetchWorkflowStagesFilters,
          sort: params.sort as FetchWorkflowStagesSort
        }
      ),
    ...(itemQuery
      ? {
          fetchItemCacheKey,
          queryItemFn: (itemId) =>
            WorkflowStagesBffRequests.fetchWorkflowStage(
              itemId as FetchWorkflowStageID,
              {
                query: itemQuery
              }
            )
        }
      : {})
  });

  return {
    workflowStages: items as PaginatedWorkflowStagesResponseType[],
    workflowStagesTotalCount: itemsTotalCount as FetchWorkflowStagesTotalCount,
    workflowStagesError: itemsError as FetchWorkflowStagesError,
    workflowStagesErrorMessage:
      itemsErrorMessage as FetchWorkflowStagesErrorMessage,
    workflowStagesIsLoading: itemsIsLoading as FetchWorkflowStagesIsLoading,
    workflowStagesIsFetched: itemsIsFetched as FetchWorkflowStagesIsFetched,
    workflowStagesIsPlaceholderData:
      itemsIsPlaceholderData as FetchWorkflowStagesIsPlaceholderData,
    workflowStagesCurrentPage: itemsCurrentPage as FetchWorkflowStagesPage,
    workflowStagesCurrentPageSize:
      itemsCurrentPageSize as FetchWorkflowStagesPageSize,
    workflowStagesCurrentFilters:
      itemsCurrentFilters as FetchWorkflowStagesFilters,
    workflowStagesCurrentSort: itemsCurrentSort as FetchWorkflowStagesSort,
    fetchWorkflowStages: fetchItems,
    paginateWorkflowStages: paginateItems,
    changeWorkflowStagesPageSize: changeItemsPageSize,
    filterWorkflowStages: filterItems,
    changeWorkflowStagesFilters: changeItemsFilters,
    clearWorkflowStagesFilters: clearItemsFilters,
    sortWorkflowStages: sortItems,
    prefetchWorkflowStages: prefetchItems,
    prefetchWorkflowStage: prefetchItem
  };
}

export default usePaginatedWorkflowStages;
