import React from 'react';
import { Text } from '../../../../../../helpers/Text';
import { Box, Flex } from '@chakra-ui/react';

interface ViewJobCandidateDetailsCandidateUpdatedLabelProps {
  date: string;
  isHovered: boolean;
}

export default function ViewJobCandidateDetailsCandidateUpdatedLabel({
  date,
  isHovered
}: ViewJobCandidateDetailsCandidateUpdatedLabelProps) {
  return (
    <Box ml="auto" h={6} overflow="hidden">
      <Flex
        pos="relative"
        flexDir="column"
        alignItems="flex-end"
        top={isHovered ? -6 : 0}
        transitionDuration="slow"
      >
        <Box>
          <Text
            px={2}
            py="1px"
            color="green.600"
            border="1px solid"
            textStyle="body2Medium"
            borderColor="green.200"
            bg="green.50"
            borderRadius="base"
            transform="auto"
          >
            Updated
          </Text>
        </Box>

        <Box>
          <Text
            py="1px"
            color="green.600"
            textStyle="body2Medium"
            textTransform="capitalize"
            textAlign="center"
          >
            {date}
          </Text>
        </Box>
      </Flex>
    </Box>
  );
}
