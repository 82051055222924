import {
  ReactHookFormFieldError,
  useReactHookForm
} from '../../../../../../common/hooks/base/useReactHookForm';

import {
  EmploymentSkillBadgeFields,
  EmploymentSkillBadgeUsage
} from '../../../../employmentSkillBadgesTypes';

import { useAddEditEmploymentSkillBadgeFormValidationRules } from '../useAddEditEmploymentSkillBadgeFormValidationRules';

import {
  AddEditEmploymentSkillBadgeFormData,
  OnAddEditEmploymentSkillBadgeForm
} from '../../AddEditEmploymentSkillBadgeForm.types';

const defaultAddEditEmploymentSkillBadgeFormValues: AddEditEmploymentSkillBadgeFormData =
  {
    usage: '' as EmploymentSkillBadgeUsage
  };

interface AddEditEmploymentSkillBadgeFormOptions {
  defaultValues?: Partial<AddEditEmploymentSkillBadgeFormData>;
  onAddEditEmploymentSkillBadgeForm: OnAddEditEmploymentSkillBadgeForm;
}

function useAddEditEmploymentSkillBadgeForm({
  defaultValues = {},
  onAddEditEmploymentSkillBadgeForm
}: AddEditEmploymentSkillBadgeFormOptions) {
  const {
    control,
    watch,
    errors,
    isLoading,
    isValid,
    isSubmitted,
    isSubmitSuccessful,
    errorMessage,
    register,
    resetForm,
    handleSubmitReactHookForm,
    setValue
  } = useReactHookForm<AddEditEmploymentSkillBadgeFormData>({
    defaultValues: {
      ...defaultAddEditEmploymentSkillBadgeFormValues,
      ...defaultValues
    }
  });

  const { usageRules } = useAddEditEmploymentSkillBadgeFormValidationRules();

  return {
    control,
    watch,
    validationErrors: {
      usageValidationError: (errors.usage as ReactHookFormFieldError)?.message
    },
    addEditEmploymentSkillBadgeFormIsLoading: isLoading,
    addEditEmploymentSkillBadgeFormIsValid: isValid,
    addEditEmploymentSkillBadgeFormIsSubmitted: isSubmitted,
    addEditEmploymentSkillBadgeFormIsSubmitSuccessful: isSubmitSuccessful,
    addEditEmploymentSkillBadgeFormErrorMessage: errorMessage,
    resetAddEditEmploymentSkillBadgeForm: resetForm,
    setAddEditEmploymentSkillBadgeFormValue: setValue,
    registerFields: {
      registerUsage: register(EmploymentSkillBadgeFields.USAGE, usageRules)
    },
    handleAddEditEmploymentSkillBadgeForm: handleSubmitReactHookForm({
      onSubmit: (data) =>
        onAddEditEmploymentSkillBadgeForm?.({
          usage: data.usage as EmploymentSkillBadgeUsage
        })
    })
  };
}

export default useAddEditEmploymentSkillBadgeForm;
