import {
  FetchEducationsQuery,
  FetchEducationsCountType,
  FetchEducationsPage,
  FetchEducationsPageSize,
  FetchEducationsFilters,
  FetchEducationsSort,
  FetchEducationsIndexQueryName,
  FetchEducationsIndexCountType,
  FetchEducationsIndexPage,
  FetchEducationsIndexPageSize,
  FetchEducationsIndexFilters,
  FetchEducationsIndexSort,
  FetchEducationQuery,
  FetchEducationIndexQueryName,
  CreateEducationError,
  UpdateEducationError,
  DeleteEducationError,
  EducationId,
  EducationNanoId,
  EducationDegree,
  MayBeEducationStartDate,
  MayBeEducationEndDate,
  EducationUserId,
  MayBeEducationCompanyId,
  MayBeEducationJobCandidateId,
  MayBeEducationJobResumeId,
  MayBeEducationJobResumeRecruiterId
} from './educationsTypes';

import { BaseBffRequests } from '../BaseBffRequests';

import { EducationsBffRoutes } from './EducationsBffRoutes';

interface FetchEducationsRequestParams {
  query: FetchEducationsQuery;
  countType?: FetchEducationsCountType;
  page?: FetchEducationsPage;
  pageSize?: FetchEducationsPageSize;
  filters?: FetchEducationsFilters;
  sort?: FetchEducationsSort;
}

interface FetchEducationsIndexRequestParams {
  queryName: FetchEducationsIndexQueryName;
  countType?: FetchEducationsIndexCountType;
  page?: FetchEducationsIndexPage;
  pageSize?: FetchEducationsIndexPageSize;
  filters?: FetchEducationsIndexFilters;
  sort?: FetchEducationsIndexSort;
}

interface FetchEducationRequestParams {
  query: FetchEducationQuery;
}

interface FetchEducationIndexRequestParams {
  queryName: FetchEducationIndexQueryName;
}

export interface EducationRequestData {
  degree?: EducationDegree;
  startDate?: MayBeEducationStartDate;
  endDate?: MayBeEducationEndDate;
}

export interface CreateEducationDefaultRequestData
  extends EducationRequestData {
  id?: EducationId;
  userId: EducationUserId;
  companyId?: MayBeEducationCompanyId;
  nanoId: EducationNanoId;
}

interface CreateEducationWithJobCandidateIdRequestData
  extends CreateEducationDefaultRequestData {
  jobCandidateId: MayBeEducationJobCandidateId;
}

interface CreateEducationWithJobResumeIdRequestData
  extends CreateEducationDefaultRequestData {
  jobResumeId: MayBeEducationJobResumeId;
}

interface CreateEducationWithJobResumeRecruiterIdRequestData
  extends CreateEducationDefaultRequestData {
  jobResumeRecruiterId: MayBeEducationJobResumeRecruiterId;
}

export type CreateEducationRequestData =
  | CreateEducationWithJobCandidateIdRequestData
  | CreateEducationWithJobResumeIdRequestData
  | CreateEducationWithJobResumeRecruiterIdRequestData;

export interface UpdateEducationRequestData extends EducationRequestData {
  degree?: EducationDegree;
}

export interface DeleteEducationRequestData {
  educationNanoId: EducationNanoId;
}

export interface CreateEducationErrorResponse {
  message: CreateEducationError;
}

export interface UpdateEducationErrorResponse {
  message: UpdateEducationError;
}

export interface DeleteEducationErrorResponse {
  message: DeleteEducationError;
}

export class EducationsBffRequests extends BaseBffRequests {
  static fetchEducations<T>(params: FetchEducationsRequestParams) {
    return this.fetchItems<T>(
      EducationsBffRoutes.fetchEducationsRoute(),
      params
    );
  }

  static fetchEducationsIndex<T>(params: FetchEducationsIndexRequestParams) {
    return this.fetchItemsIndex<T>(
      EducationsBffRoutes.fetchEducationsIndexRoute(),
      params
    );
  }

  static fetchEducation<T>(
    educationId: EducationId,
    params: FetchEducationRequestParams
  ) {
    return this.fetchItem<T>(
      EducationsBffRoutes.fetchEducationRoute(educationId),
      params
    );
  }

  static fetchEducationIndex<T>(
    educationNanoId: EducationNanoId,
    params: FetchEducationIndexRequestParams
  ) {
    return this.fetchItemIndex<T>(
      EducationsBffRoutes.fetchEducationIndexRoute(educationNanoId),
      params
    );
  }

  static fetchEducationByNanoId<T>(
    educationNanoId: EducationNanoId,
    params: FetchEducationRequestParams
  ) {
    return this.fetchItemByNanoId<T>(
      EducationsBffRoutes.fetchEducationByNanoIdRoute(educationNanoId),
      params
    );
  }

  static createEducation<T>(createEducationData: CreateEducationRequestData) {
    return this.createItem<T, CreateEducationRequestData>(
      EducationsBffRoutes.createEducationRoute(),
      createEducationData
    );
  }

  static async updateEducation<T>(
    updateEducationNanoId: EducationNanoId,
    updateEducationData: UpdateEducationRequestData
  ) {
    return this.updateItem<T, UpdateEducationRequestData>(
      EducationsBffRoutes.updateEducationRoute(updateEducationNanoId),
      updateEducationData
    );
  }

  static async deleteEducation<T>(deleteEducationNanoId: EducationNanoId) {
    return this.deleteItem<T>(
      EducationsBffRoutes.deleteEducationRoute(deleteEducationNanoId)
    );
  }
}
