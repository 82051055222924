import { Flex, StackDivider } from '@chakra-ui/layout';

const LoginMethodDivider = () => (
  <StackDivider
    border="none"
    display="flex"
    justifyContent="center"
    alignItems="center"
    _before={{
      content: '""',
      bg: 'gray.300',
      h: '1px',
      w: '40px'
    }}
    _after={{
      content: '""',
      bg: 'gray.300',
      h: '1px',
      w: '40px'
    }}
  >
    <Flex as={'span'} color="gray.500" textStyle="upperCase1Medium" px={3}>
      or
    </Flex>
  </StackDivider>
);

export default LoginMethodDivider;
