import {
  Box,
  Popover,
  PopoverAnchor,
  PopoverBody,
  PopoverContent,
  Portal,
  Stack,
  useDisclosure
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { FetchJobResponse } from '../../../../../../../../../queries/fetchJob.query';
import { ViewJobCandidateModalPhoneSidebarJobDetailsContent } from '../ViewJobCandidateModalPhoneSidebarJobDetailsContent';
import { ViewJobDescription } from '../../../../../../../../../pages/ViewJobDetailsPage/components/ViewJobDescription';

type ViewJobCandidateModalPhoneSidebarJobDetailsPopoverProps = {
  children: ReactNode;
  job: FetchJobResponse;
  isOpenShowDescription: boolean;
  onCloseShowDescription: () => void;
};

const ViewJobCandidateModalPhoneSidebarJobDetailsPopover = ({
  job,
  children,
  isOpenShowDescription,
  onCloseShowDescription
}: ViewJobCandidateModalPhoneSidebarJobDetailsPopoverProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const closePopover = () => {
    onClose();
    onCloseShowDescription();
  };

  return (
    <Box onMouseLeave={closePopover} onMouseEnter={onOpen}>
      <Popover
        isLazy
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={closePopover}
        placement="left"
        closeOnBlur={false}
      >
        <PopoverAnchor>
          <Box cursor="pointer">{children}</Box>
        </PopoverAnchor>

        <Portal>
          <PopoverContent
            w="440px"
            maxW="100%"
            maxH="100vh"
            overflow="auto"
            boxShadow="0 6px 14px rgba(0, 0, 0, 0.12)"
          >
            <PopoverBody p={6}>
              <Stack>
                {isOpenShowDescription ? (
                  <ViewJobDescription job={job} />
                ) : (
                  <ViewJobCandidateModalPhoneSidebarJobDetailsContent
                    job={job}
                  />
                )}
              </Stack>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </Box>
  );
};

export default ViewJobCandidateModalPhoneSidebarJobDetailsPopover;
