import {
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Stack,
  useDisclosure
} from '@chakra-ui/react';
import { MouseEvent } from 'react';
import { Button } from '../../../../../../../../helpers/Button';
import { TextareaField } from '../../../../../../../../helpers/forms/formFields/TextareaField';
import { Text } from '../../../../../../../../helpers/Text';
import { ChevronDownIcon } from '../../../../../../../../icons/ChevronDownIcon';
import { useViewJobCandidateDetailsSubmissionContext } from '../../../../contexts/ViewJobCandidateDetailsSubmissionContext';
import { Heading } from '../../../../../../../../helpers/Heading';
import { BaseSalaryInputFormFieldControl } from '../../../../../../../common/components/formFields/BaseSlaryInputFormField/components/BaseSalaryInputFormFieldControl';
import { SelectCandidateShelfLifeFormFieldControl } from '../../../../../../../common/components/formFields/SelectCandidateShelfLifeFormField/components/SelectCandidateShelfLifeFormFieldControl';
import {
  CandidateSubmissionBaseSalary,
  CandidateSubmissionCandidateShelfLife,
  CandidateSubmissionComment,
  CandidateSubmissionOverallEvaluation,
  CandidateSubmissionOverallEvaluationEnums
} from '../../../../../../../candidateSubmissions/candidateSubmissionsTypes';
import { renderCandidateSubmissionOverallEvaluation } from '../../../../../../../candidateSubmissions/utils/renderCandidateSubmissionOverallEvaluation';

export default function CustomiseCandidateSubmissionEvaluationOverall() {
  const {
    selectedOverallEvaluation,
    setSelectedOverallEvaluation,
    overallComment,
    setOverallComment,
    baseSalary,
    setBaseSalary,
    candidateShelfLife,
    setCandidateShelfLife
  } = useViewJobCandidateDetailsSubmissionContext();

  const {
    isOpen: isOpenDropdown,
    onToggle: onToggleDropdown,
    onClose: onCloseDropdown
  } = useDisclosure();

  const overallEvaluationArray = Object.values(
    CandidateSubmissionOverallEvaluationEnums
  ) as CandidateSubmissionOverallEvaluation[];

  const changeEvaluation = (e: MouseEvent<HTMLButtonElement>) => {
    const { value } = e.currentTarget;

    setSelectedOverallEvaluation(value as CandidateSubmissionOverallEvaluation);

    onCloseDropdown();
  };

  return (
    <Flex flexDir="column" gap={5}>
      <Flex>
        <Popover
          isOpen={isOpenDropdown}
          onClose={onCloseDropdown}
          placement="bottom-start"
          isLazy
        >
          <PopoverTrigger>
            <Flex alignItems="stretch">
              <Button
                size="medium"
                onClick={(e) => {
                  e.stopPropagation();
                  onToggleDropdown();
                }}
                hierarchy="tertiary"
                aria-label="Dropdown"
              >
                <Flex gap={1} alignItems="center" fontSize="inherit">
                  <Text fontWeight="medium">
                    {selectedOverallEvaluation
                      ? renderCandidateSubmissionOverallEvaluation(
                          selectedOverallEvaluation
                        )
                      : 'Select overall Evaluation?'}
                  </Text>
                  <ChevronDownIcon fill="gray.500" />
                </Flex>
              </Button>
            </Flex>
          </PopoverTrigger>

          <Portal>
            <PopoverContent minW={60} w={60} p={1} maxH={60} overflow="hidden">
              <Flex flexDir="column" overflow="auto">
                {overallEvaluationArray.map((value) => (
                  <Button
                    px={2}
                    py={1}
                    h="auto"
                    size="small"
                    color="inherit"
                    textAlign="left"
                    lineHeight={1.5}
                    fontSize="inherit"
                    borderRadius="none"
                    fontWeight="inherit"
                    hierarchy="unstyled"
                    isDisabled={selectedOverallEvaluation === value}
                    onClick={(e) => {
                      e.stopPropagation();
                      changeEvaluation(e);
                    }}
                    value={value}
                    key={value}
                    _hover={{
                      bg: 'gray.100'
                    }}
                  >
                    {renderCandidateSubmissionOverallEvaluation(value)}
                  </Button>
                ))}
              </Flex>
            </PopoverContent>
          </Portal>
        </Popover>
      </Flex>

      <Stack spacing={2}>
        <Heading level="h5" textStyle="body1Medium" fontWeight="semibold">
          Recruitment Considerations
        </Heading>

        <SelectCandidateShelfLifeFormFieldControl
          onChange={(val) =>
            setCandidateShelfLife(val as CandidateSubmissionCandidateShelfLife)
          }
          value={candidateShelfLife}
        />

        <BaseSalaryInputFormFieldControl
          label="Submissionn Salary"
          value={baseSalary}
          onChange={(salary) =>
            setBaseSalary(salary as CandidateSubmissionBaseSalary)
          }
        />

        <Text textStyle="body1Medium">Add a comment</Text>
        <TextareaField
          rows={7}
          value={overallComment}
          onChange={(event) => {
            setOverallComment(
              event.currentTarget.value as CandidateSubmissionComment
            );
          }}
        />
      </Stack>
    </Flex>
  );
}
