import { Icon, IconProps } from '@chakra-ui/icon';

const ChevronRightIcon = ({
  w = '20px',
  h = '20px',
  fill = 'currentcolor',
  ...props
}: IconProps) => (
  <Icon viewBox="0 0 20 20" w={w} h={h} fill={fill} {...props}>
    <path d="m13.402 10.002-5.4-5.4-1.4 1.4 4 4-4 4 1.4 1.4 5.4-5.4Z" />
  </Icon>
);

export default ChevronRightIcon;
