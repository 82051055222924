import first from 'lodash/first';
import last from 'lodash/last';
import size from 'lodash/size';
import slice from 'lodash/slice';

import {
  BaseSalaryFrequencyEnums,
  BaseSalaryFrequencyOptionsHash,
  BaseSalaryRadioInputOptionsEnums
} from '../../components/formFields/BaseSlaryInputFormField';

//upto_50k_annually
//range_50.50_60.50_hourly
//exact_60k_monthly

const baseSalaryRadioText = (salary_enum: BaseSalaryRadioInputOptionsEnums) => {
  switch (salary_enum) {
    case BaseSalaryRadioInputOptionsEnums.EXACT:
      return 'Exactly';

    case BaseSalaryRadioInputOptionsEnums.UPTO:
      return 'Up to:';

    default:
      return '';
  }
};

function renderBaseSalary(baseSalary: string) {
  if (!baseSalary) return '-';
  const arr: string[] = baseSalary?.split('_');
  const arrLength = size(arr);
  if (arrLength >= 3) {
    const salary_enum = first(arr) as BaseSalaryRadioInputOptionsEnums;
    const frequency_enum = last(arr) as BaseSalaryFrequencyEnums;
    const salary = baseSalaryRadioText(salary_enum);
    const frequency = BaseSalaryFrequencyOptionsHash[frequency_enum];
    const amount = slice(arr, 1, -1)?.join(' - ');
    return salary
      ? `${salary}: $${amount} ${frequency}`
      : `$${amount} ${frequency}`;
  } else {
    return `$${baseSalary?.replace('_', ' - ')}`;
  }
}

export default renderBaseSalary;
