import { ControlPanelPeopleRoutes } from './ControlPanelPeopleRoutes';

const basePeoplePath = ControlPanelPeopleRoutes.candidates();

export class CandidatesRoutes {
  static index() {
    return basePeoplePath;
  }

  static add() {
    return `${basePeoplePath}/add`;
  }

  static uploadedList() {
    return `${basePeoplePath}/add/uploaded-list`;
  }

  static leads() {
    return `${basePeoplePath}/leads`;
  }

  static targets() {
    return `${basePeoplePath}/targets`;
  }

  static prospects() {
    return `${basePeoplePath}/prospects`;
  }

  static applicants() {
    return `${basePeoplePath}/applicants`;
  }

  static jobCandidates() {
    return `${basePeoplePath}/job-candidates`;
  }

  static contacts() {
    return `${basePeoplePath}/contacts`;
  }

  static users() {
    return `${basePeoplePath}/users`;
  }

  static other() {
    return `${basePeoplePath}/other`;
  }
}
