import snakeCase from 'lodash/snakeCase';

import {
  FetchJobTitlesQuery,
  JobTitleFields,
  JobTitleId,
  JobTitleNanoId,
  JobTitleName,
  JobTitleUser,
  JobTitleCreatedAt,
  JobTitleJobFunctionId,
  JobTitleJobFunction
} from '../jobTitlesTypes';

export interface FetchJobTitlesResponse {
  [JobTitleFields.ID]: JobTitleId;
  [JobTitleFields.NANO_ID]: JobTitleNanoId;
  [JobTitleFields.NAME]: JobTitleName;
  [JobTitleFields.USER]: JobTitleUser;
  [JobTitleFields.CREATED_AT]: JobTitleCreatedAt;
  [JobTitleFields.JOB_FUNCTION]: JobTitleJobFunction;
  [JobTitleFields.JOB_FUNCTION_ID]: JobTitleJobFunctionId;
}

export const fetchJobTitlesColumns = [
  JobTitleFields.ID,
  JobTitleFields.NANO_ID,
  JobTitleFields.NAME,
  JobTitleFields.CREATED_AT,
  JobTitleFields.JOB_FUNCTION_ID
];

const fetchJobTitlesSBColumns = fetchJobTitlesColumns.map(
  (fetchJobTitlesColumn) => snakeCase(fetchJobTitlesColumn)
);

export const fetchJobTitlesQuery = `
  ${fetchJobTitlesSBColumns.join(',')},
  user:user_id(id,nano_id,name,image:image_id(id,nano_id,name,file,key,created_at))
` as FetchJobTitlesQuery;
