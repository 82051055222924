import { usePaginatedItems } from '../../../common/hooks/usePaginatedItems';

import {
  FetchCandidateSubmissionJobCandidatesQuery,
  FetchCandidateSubmissionJobCandidatesCacheKey,
  FetchCandidateSubmissionJobCandidatesTotalCount,
  FetchCandidateSubmissionJobCandidatesPage,
  FetchCandidateSubmissionJobCandidatesPageSize,
  FetchCandidateSubmissionJobCandidatesError,
  FetchCandidateSubmissionJobCandidatesErrorMessage,
  FetchCandidateSubmissionJobCandidatesIsLoading,
  FetchCandidateSubmissionJobCandidatesIsFetched,
  FetchCandidateSubmissionJobCandidatesIsPlaceholderData,
  FetchCandidateSubmissionJobCandidatesEnabled,
  FetchCandidateSubmissionJobCandidatesPrefetchNextPage,
  FetchCandidateSubmissionJobCandidatesRefetchInterval,
  FetchCandidateSubmissionJobCandidatesCountType,
  FetchCandidateSubmissionJobCandidatesFilters,
  FetchCandidateSubmissionJobCandidatesSort,
  FetchCandidateSubmissionJobCandidateID,
  FetchCandidateSubmissionJobCandidateQuery,
  FetchCandidateSubmissionJobCandidateCacheKey
} from '../../candidateSubmissionJobCandidatesTypes';

import { CandidateSubmissionJobCandidatesBffRequests } from '../../CandidateSubmissionJobCandidatesBffRequests';

interface PaginatedCandidateSubmissionJobCandidatesBaseOptions<
  PaginatedCandidateSubmissionJobCandidatesResponseType
> {
  cacheKey: FetchCandidateSubmissionJobCandidatesCacheKey;
  query: FetchCandidateSubmissionJobCandidatesQuery;
  countType?: FetchCandidateSubmissionJobCandidatesCountType;
  initialPage?: FetchCandidateSubmissionJobCandidatesPage;
  initialPageSize?: FetchCandidateSubmissionJobCandidatesPageSize;
  initialFilters?: FetchCandidateSubmissionJobCandidatesFilters;
  initialSort?: FetchCandidateSubmissionJobCandidatesSort;
  enabled?: FetchCandidateSubmissionJobCandidatesEnabled;
  prefetchNextPage?: FetchCandidateSubmissionJobCandidatesPrefetchNextPage;
  refetchInterval?: FetchCandidateSubmissionJobCandidatesRefetchInterval;
  placeholderCandidateSubmissionJobCandidates?: PaginatedCandidateSubmissionJobCandidatesResponseType[];
  placeholderCandidateSubmissionJobCandidatesCount?: FetchCandidateSubmissionJobCandidatesTotalCount;
  placeholderData?: () => PaginatedCandidateSubmissionJobCandidatesResponse<PaginatedCandidateSubmissionJobCandidatesResponseType>;
}

interface PaginatedCandidateSubmissionJobCandidatesOptionsWithoutItem {
  fetchItemCacheKey?: never;
  itemQuery?: never;
}

interface PaginatedCandidateSubmissionJobCandidatesOptionsWithItem {
  fetchItemCacheKey: FetchCandidateSubmissionJobCandidateCacheKey;
  itemQuery: FetchCandidateSubmissionJobCandidateQuery;
}

type PaginatedCandidateSubmissionJobCandidatesOptions<
  PaginatedCandidateSubmissionJobCandidatesResponseType
> = PaginatedCandidateSubmissionJobCandidatesBaseOptions<PaginatedCandidateSubmissionJobCandidatesResponseType> &
  (
    | PaginatedCandidateSubmissionJobCandidatesOptionsWithoutItem
    | PaginatedCandidateSubmissionJobCandidatesOptionsWithItem
  );

interface PaginatedCandidateSubmissionJobCandidatesResponse<
  PaginatedCandidateSubmissionJobCandidatesResponseType
> {
  data: PaginatedCandidateSubmissionJobCandidatesResponseType[];
  totalCount: FetchCandidateSubmissionJobCandidatesTotalCount;
}

function usePaginatedCandidateSubmissionJobCandidates<
  PaginatedCandidateSubmissionJobCandidatesResponseType
>({
  cacheKey,
  query,
  countType,
  initialPage,
  initialPageSize,
  initialFilters,
  initialSort,
  enabled,
  prefetchNextPage,
  refetchInterval,
  placeholderCandidateSubmissionJobCandidates,
  placeholderCandidateSubmissionJobCandidatesCount,
  placeholderData,
  fetchItemCacheKey,
  itemQuery
}: PaginatedCandidateSubmissionJobCandidatesOptions<PaginatedCandidateSubmissionJobCandidatesResponseType>) {
  const {
    items,
    itemsTotalCount,
    itemsError,
    itemsErrorMessage,
    itemsIsFetched,
    itemsIsLoading,
    itemsIsPlaceholderData,
    itemsCurrentPage,
    itemsCurrentPageSize,
    itemsCurrentFilters,
    itemsCurrentSort,
    fetchItems,
    paginateItems,
    changeItemsPageSize,
    filterItems,
    changeItemsFilters,
    clearItemsFilters,
    sortItems,
    prefetchItems,
    prefetchItem
  } = usePaginatedItems<PaginatedCandidateSubmissionJobCandidatesResponseType>({
    cacheKey,
    initialPage,
    initialPageSize,
    initialFilters,
    initialSort,
    enabled,
    prefetchNextPage,
    refetchInterval,
    placeholderItems: placeholderCandidateSubmissionJobCandidates,
    placeholderItemsCount: placeholderCandidateSubmissionJobCandidatesCount,
    placeholderData,
    queryFn: (params) =>
      CandidateSubmissionJobCandidatesBffRequests.fetchCandidateSubmissionJobCandidates<PaginatedCandidateSubmissionJobCandidatesResponseType>(
        {
          query,
          countType,
          page: params.page as FetchCandidateSubmissionJobCandidatesPage,
          pageSize:
            params.pageSize as FetchCandidateSubmissionJobCandidatesPageSize,
          filters:
            params.filters as FetchCandidateSubmissionJobCandidatesFilters,
          sort: params.sort as FetchCandidateSubmissionJobCandidatesSort
        }
      ),
    ...(itemQuery
      ? {
          fetchItemCacheKey,
          queryItemFn: (itemId) =>
            CandidateSubmissionJobCandidatesBffRequests.fetchCandidateSubmissionJobCandidate(
              itemId as FetchCandidateSubmissionJobCandidateID,
              {
                query: itemQuery
              }
            )
        }
      : {})
  });

  return {
    candidateSubmissionJobCandidates:
      items as PaginatedCandidateSubmissionJobCandidatesResponseType[],
    candidateSubmissionJobCandidatesTotalCount:
      itemsTotalCount as FetchCandidateSubmissionJobCandidatesTotalCount,
    candidateSubmissionJobCandidatesError:
      itemsError as FetchCandidateSubmissionJobCandidatesError,
    candidateSubmissionJobCandidatesErrorMessage:
      itemsErrorMessage as FetchCandidateSubmissionJobCandidatesErrorMessage,
    candidateSubmissionJobCandidatesIsLoading:
      itemsIsLoading as FetchCandidateSubmissionJobCandidatesIsLoading,
    candidateSubmissionJobCandidatesIsFetched:
      itemsIsFetched as FetchCandidateSubmissionJobCandidatesIsFetched,
    candidateSubmissionJobCandidatesIsPlaceholderData:
      itemsIsPlaceholderData as FetchCandidateSubmissionJobCandidatesIsPlaceholderData,
    candidateSubmissionJobCandidatesCurrentPage:
      itemsCurrentPage as FetchCandidateSubmissionJobCandidatesPage,
    candidateSubmissionJobCandidatesCurrentPageSize:
      itemsCurrentPageSize as FetchCandidateSubmissionJobCandidatesPageSize,
    candidateSubmissionJobCandidatesCurrentFilters:
      itemsCurrentFilters as FetchCandidateSubmissionJobCandidatesFilters,
    candidateSubmissionJobCandidatesCurrentSort:
      itemsCurrentSort as FetchCandidateSubmissionJobCandidatesSort,
    fetchCandidateSubmissionJobCandidates: fetchItems,
    paginateCandidateSubmissionJobCandidates: paginateItems,
    changeCandidateSubmissionJobCandidatesPageSize: changeItemsPageSize,
    filterCandidateSubmissionJobCandidates: filterItems,
    changeCandidateSubmissionJobCandidatesFilters: changeItemsFilters,
    clearCandidateSubmissionJobCandidatesFilters: clearItemsFilters,
    sortCandidateSubmissionJobCandidates: sortItems,
    prefetchCandidateSubmissionJobCandidates: prefetchItems,
    prefetchCandidateSubmissionJobCandidate: prefetchItem
  };
}

export default usePaginatedCandidateSubmissionJobCandidates;
