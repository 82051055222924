import { useCallback, useEffect } from 'react';
import {
  Box,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Tooltip,
  useDisclosure
} from '@chakra-ui/react';
import first from 'lodash/first';
import lodashSize from 'lodash/size';

import { Text } from '../../../../../../../../helpers/Text';
import { useChakraToast } from '../../../../../../../../helpers/useChakraToast';

import { CheckIcon } from '../../../../../../../../icons/CheckIcon';
import { ClockIcon } from '../../../../../../../../icons/ClockIcon';
import { DropUpIcon } from '../../../../../../../../icons/DropUpIcon';

import {
  DateTimeType,
  DateUtils
} from '../../../../../../../../utils/DateUtils';

import { useUpdateJobCandidate } from '../../../../../../../jobCandidates/hooks/useUpdateJobCandidate';
import { JobCandidatesCache } from '../../../../../../../jobCandidates/JobCandidatesCache';

import {
  JobCandidateId,
  JobCandidateNewWorkflowStageTaskAt,
  JobCandidateViewedAt,
  MayBeJobCandidateWorkflowStageTask,
  JobCandidateWorkflowStageTaskApplicationType,
  JobCandidateWorkflowStageTaskApplicationTypes,
  JobCandidateWorkflowStageTaskId,
  MayBeJobCandidateDueDate,
  MayBeJobCandidateWorkflowStageTaskScheduledAt
} from '../../../../../../../jobCandidates/jobCandidatesTypes';
import { WorkflowStageTaskId } from '../../../../../../../workflowStageTasks/workflowStageTasksTypes';
import {
  ViewJobCandidateTableJobCandidateWorkflowStageTasks,
  ViewJobCandidateTableUpdatedJobCandidateWorkflowStageTasks
} from '../../ViewJobCandidatesTableRow.types';
import { JobNanoId } from '../../../../../../jobsTypes';

interface JobCandidateWorkflowStageTasksListProps {
  jobCandidate: {
    id: JobCandidateId;
    dueDate: MayBeJobCandidateDueDate;
    workflowStageTaskScheduledAt: MayBeJobCandidateWorkflowStageTaskScheduledAt;
    workflowStageTask: MayBeJobCandidateWorkflowStageTask;
  };
  job: {
    nanoId: JobNanoId;
  };
  allWorkflowStageTasks: ViewJobCandidateTableUpdatedJobCandidateWorkflowStageTasks[];
  pendingWorkflowStageTasks: ViewJobCandidateTableJobCandidateWorkflowStageTasks[];
  completedWorkflowStageTasks: ViewJobCandidateTableUpdatedJobCandidateWorkflowStageTasks[];
  size?: 'sm' | 'md' | 'lg';
  withoutDropdownIcon?: boolean;
}

function JobCandidateWorkflowStageTasksList({
  jobCandidate,
  job,
  allWorkflowStageTasks,
  pendingWorkflowStageTasks,
  completedWorkflowStageTasks,
  size,
  withoutDropdownIcon
}: JobCandidateWorkflowStageTasksListProps) {
  const {
    updateJobCandidateErrorMessage,
    updateJobCandidate,
    updateJobCandidateIsLoading
  } = useUpdateJobCandidate({
    jobCandidateId: jobCandidate.id,
    cacheKeys: [
      JobCandidatesCache.showCacheKey(),
      JobCandidatesCache.jobIndexCacheKey(job.nanoId)
    ]
  });

  const toast = useChakraToast();

  const {
    isOpen: menuIsOpen,
    onOpen: menuOnOpen,
    onClose: menuOnClose
  } = useDisclosure();

  const {
    isOpen: pendingTaskTooltipIsOpen,
    onOpen: pendingTaskTooltipOnOpen,
    onClose: pendingTaskTooltipOnClose
  } = useDisclosure();

  const {
    isOpen: currentTaskTooltipIsOpen,
    onOpen: currentTaskTooltipOnOpen,
    onClose: currentTaskTooltipOnClose
  } = useDisclosure();

  const dueDay = DateUtils.calendarDay(jobCandidate.dueDate) as DateTimeType;

  const scheduledDay = DateUtils.calendarDay(
    jobCandidate.workflowStageTaskScheduledAt
  ) as DateTimeType;

  const today = DateUtils.startOfToday();

  const handleSelectWorkflowStageTask = useCallback<
    (workflowStageTaskId: WorkflowStageTaskId) => void
  >(
    async (workflowStageTaskId) => {
      await updateJobCandidate({
        workflowStageTaskId:
          workflowStageTaskId as JobCandidateWorkflowStageTaskId,
        newWorkflowStageTaskAt:
          DateUtils.now() as JobCandidateNewWorkflowStageTaskAt,
        workflowStageTaskScheduledAt: null,
        workflowStageTaskApplicationType:
          JobCandidateWorkflowStageTaskApplicationTypes.NONE as JobCandidateWorkflowStageTaskApplicationType,
        dueDate: null,
        viewedAt: DateUtils.now() as JobCandidateViewedAt
      });
    },
    [updateJobCandidate]
  );

  useEffect(() => {
    updateJobCandidateErrorMessage &&
      toast({ title: updateJobCandidateErrorMessage, status: 'error' });
  }, [updateJobCandidateErrorMessage, toast]);

  return (
    <Box
      onClick={(e) => e.stopPropagation()}
      onMouseOver={menuOnOpen}
      onMouseLeave={menuOnClose}
    >
      {jobCandidate.workflowStageTaskScheduledAt ? (
        <Menu isLazy offset={[-10, 5]} placement="top" isOpen={menuIsOpen}>
          <MenuButton
            as={HStack}
            cursor="pointer"
            onMouseOver={currentTaskTooltipOnOpen}
            onMouseOut={currentTaskTooltipOnClose}
          >
            <HStack spacing={1.5}>
              {today.equals(scheduledDay) ? (
                <ClockIcon fill="green.900" />
              ) : today > dueDay ? (
                <ClockIcon fill="red.500" />
              ) : (
                <ClockIcon fill="primary.500" />
              )}
              <Text
                textStyle={size === 'sm' ? 'body2Regular' : 'body1Regular'}
                color="gray.900"
                isTruncated
              >
                <Tooltip
                  label={jobCandidate.workflowStageTask?.name}
                  isOpen={currentTaskTooltipIsOpen}
                  placement="top"
                >
                  {jobCandidate.workflowStageTask?.name}
                </Tooltip>
              </Text>
            </HStack>
          </MenuButton>

          {lodashSize(completedWorkflowStageTasks) > 0 ? (
            <MenuList zIndex={4}>
              {completedWorkflowStageTasks.map((workflowStageTask) => (
                <MenuItem key={workflowStageTask.nanoId}>
                  <HStack spacing={1.5}>
                    <CheckIcon />
                    <Text
                      textStyle={
                        size === 'sm' ? 'body2Regular' : 'body1Regular'
                      }
                      color="gray.900"
                    >
                      {workflowStageTask.name}
                    </Text>
                  </HStack>
                </MenuItem>
              ))}
            </MenuList>
          ) : null}
        </Menu>
      ) : (
        <Menu isLazy offset={[-10, 5]} isOpen={menuIsOpen}>
          <MenuButton
            as={HStack}
            cursor="pointer"
            onMouseOver={pendingTaskTooltipOnOpen}
            onMouseOut={pendingTaskTooltipOnClose}
          >
            <HStack spacing={1.5}>
              {updateJobCandidateIsLoading ? <Spinner size="sm" /> : null}
              <Text
                textStyle={size === 'sm' ? 'body2Regular' : 'body1Regular'}
                color="gray.900"
                isTruncated
              >
                <Tooltip
                  label={
                    jobCandidate.workflowStageTask?.name ||
                    first(pendingWorkflowStageTasks)?.name
                  }
                  isOpen={pendingTaskTooltipIsOpen}
                  placement="top"
                >
                  {`${
                    jobCandidate.workflowStageTask?.name ||
                    first(pendingWorkflowStageTasks)?.name
                  }?`}
                </Tooltip>
              </Text>
              {withoutDropdownIcon ? null : <DropUpIcon mb="5px" />}
            </HStack>
          </MenuButton>

          <MenuList zIndex={4}>
            {allWorkflowStageTasks.map((workflowStageTask) => (
              <MenuItem
                key={workflowStageTask.nanoId}
                cursor={
                  jobCandidate.workflowStageTask?.nanoId ===
                    workflowStageTask.nanoId || workflowStageTask.completed
                    ? 'not-allowed'
                    : 'pointer'
                }
                onClick={() =>
                  jobCandidate.workflowStageTask?.nanoId ===
                    workflowStageTask.nanoId || workflowStageTask.completed
                    ? null
                    : handleSelectWorkflowStageTask(workflowStageTask.id)
                }
              >
                <HStack spacing={1.5}>
                  {workflowStageTask.completed ? (
                    <CheckIcon />
                  ) : jobCandidate.workflowStageTaskScheduledAt &&
                    jobCandidate.workflowStageTask?.nanoId ===
                      workflowStageTask.nanoId ? (
                    today.equals(scheduledDay) ? (
                      <ClockIcon fill="green.900" />
                    ) : today > dueDay ? (
                      <ClockIcon fill="red.500" />
                    ) : (
                      <ClockIcon fill="primary.500" />
                    )
                  ) : null}
                  <Text
                    textStyle={size === 'sm' ? 'body2Regular' : 'body1Regular'}
                    color="gray.900"
                  >
                    {workflowStageTask.name}
                  </Text>
                </HStack>
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      )}
    </Box>
  );
}

export default JobCandidateWorkflowStageTasksList;
