import { Box, Stack, useDisclosure } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { Heading } from '../../../../../../../../helpers/Heading';
import { Text } from '../../../../../../../../helpers/Text';
import { renderJobCandidateStatus } from '../../../../../../../jobCandidates/utils/renderJobCandidateStatus';
import { ViewJobCandidateDetailsCandidate } from '../../../../pages/ViewJobCandidateDetailsProfilePage/ViewJobCandidateDetailsProfilePage.types';
import { ViewJobCandidateDetailsCandidateUpdatedLabel } from '../../../ViewJobCandidateDetailsCandidateUpdatedLabel';

interface ViewJobCandidateDetailsProfileOverviewSourceProps {
  jobCandidate: ViewJobCandidateDetailsCandidate;
}

function ViewJobCandidateDetailsProfileOverviewSource({
  jobCandidate
}: ViewJobCandidateDetailsProfileOverviewSourceProps) {
  const {
    isOpen: isHovered,
    onOpen: onMouseHover,
    onClose: onMouseLeave
  } = useDisclosure();

  return (
    <Box
      p={4}
      gap={3}
      flex={1}
      bg="white"
      pos="relative"
      borderRadius="base"
      onMouseOver={onMouseHover}
      onMouseLeave={onMouseLeave}
    >
      <Stack spacing={3} color="gray.600">
        <SectionItem label="Source" value="Nxmoov Applicant" />

        <SectionItem
          label="Status"
          value={renderJobCandidateStatus(jobCandidate.status)}
        />

        <SectionItem label="Availability" value="2 weeks from offer" />
      </Stack>

      <Box p={4} right={0} top={0} pos="absolute">
        <ViewJobCandidateDetailsCandidateUpdatedLabel
          isHovered={isHovered}
          date="Yesterday"
        />
      </Box>
    </Box>
  );
}

export default ViewJobCandidateDetailsProfileOverviewSource;

interface SectionItemProps {
  label: string;
  value: ReactNode;
}

const SectionItem = ({ label, value }: SectionItemProps) => (
  <Stack>
    <Heading
      m={0}
      p={0}
      level="h4"
      fontSize="md"
      lineHeight={1.5}
      color="gray.700"
      fontWeight="semibold"
    >
      {label}
    </Heading>

    <Text textStyle="body1Regular" lineHeight={1.5}>
      {value}
    </Text>
  </Stack>
);
