import { Icon, IconProps } from '@chakra-ui/icon';

const HelpIcon = ({
  w = '24px',
  h = '24px',
  fill = 'gray.500',
  ...props
}: IconProps) => (
  <Icon viewBox="0 0 24 24" w={w} h={h} fill={fill} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.394 12.395c-.36.194-.644.54-.644.95v.155a.75.75 0 1 1-1.5 0V12a.75.75 0 0 1 .75-.75c.8 0 1.5-.701 1.5-1.5s-.7-1.5-1.5-1.5c-.609 0-1.194.504-1.407.992a.75.75 0 1 1-1.374-.6C9.698 7.545 10.867 6.749 12 6.749c1.626 0 3 1.374 3 3 0 1.127-.66 2.133-1.606 2.645Zm-1.396 4.855a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 3c-4.963 0-9 4.037-9 9s4.037 9 9 9 9-4.037 9-9-4.037-9-9-9Z"
    />
  </Icon>
);
export default HelpIcon;
