import { Icon, IconProps } from '@chakra-ui/icon';

const CopyIcon = ({
  w = '16px',
  h = '16px',
  stroke,
  fill,
  ...props
}: IconProps) => (
  <Icon
    w={w}
    h={h}
    viewBox="0 0 16 16"
    stroke={stroke || fill || 'currentcolor'}
    fill="none"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M10.7 15.2H1.6V4.423h9.1V15.2Z"
      // stroke="currentColor"
      strokeWidth={1.75}
      strokeLinecap="square"
    />
    <path
      d="M4.5 1.6h9.1v10.779"
      // stroke="currentColor"
      strokeWidth={1.75}
      strokeLinecap="square"
    />
  </Icon>
);

export default CopyIcon;
