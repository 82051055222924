import omit from 'lodash/omit';

import {
  FetchItemsPage,
  FetchItemsPageSize,
  FetchItemsFilters,
  FetchItemsSort
} from '../../../../../../types';

import { INITIAL_FILTERS, INITIAL_PAGE } from '../../../../../../constants';

import {
  PAGINATE_ITEMS,
  CHANGE_ITEMS_PAGE_SIZE,
  FILTER_ITEMS,
  CHANGE_ITEMS_FILTERS,
  CLEAR_ITEMS_FILTERS,
  SORT_ITEMS
} from '../../usePaginatedItems.constants';

export interface PaginatedItemsReducerState {
  currentPage: FetchItemsPage;
  currentPageSize: FetchItemsPageSize;
  currentFilters: FetchItemsFilters;
  currentSort: FetchItemsSort;
}

export type PaginatedItemsReducerAction =
  | {
      type: 'PAGINATE_ITEMS';
      nextPage: FetchItemsPage;
    }
  | {
      type: 'CHANGE_ITEMS_PAGE_SIZE';
      nextPageSize: FetchItemsPageSize;
    }
  | {
      type: 'FILTER_ITEMS';
      nextFilters: FetchItemsFilters;
    }
  | {
      type: 'CHANGE_ITEMS_FILTERS';
      changedFilters: Partial<FetchItemsFilters>;
      removeFilters: string[];
    }
  | {
      type: 'CLEAR_ITEMS_FILTERS';
    }
  | {
      type: 'SORT_ITEMS';
      nextSort: FetchItemsSort;
    };

export type PaginatedItemsReducerType = (
  prevState: PaginatedItemsReducerState,
  action: PaginatedItemsReducerAction
) => PaginatedItemsReducerState;

export function paginatedItemsReducer(
  state: PaginatedItemsReducerState,
  action: PaginatedItemsReducerAction
): PaginatedItemsReducerState {
  switch (action.type) {
    case PAGINATE_ITEMS:
      return {
        ...state,
        currentPage: action.nextPage
      };

    case CHANGE_ITEMS_PAGE_SIZE:
      return {
        ...state,
        currentPageSize: action.nextPageSize,
        currentPage: INITIAL_PAGE
      };

    case FILTER_ITEMS:
      return {
        ...state,
        currentFilters: action.nextFilters,
        currentPage: INITIAL_PAGE
      };

    case CHANGE_ITEMS_FILTERS:
      return {
        ...state,
        currentFilters: omit(
          {
            ...(state.currentFilters || {}),
            ...(action.changedFilters || {})
          } as FetchItemsFilters,
          action.removeFilters
        ) as FetchItemsFilters,
        currentPage: INITIAL_PAGE
      };

    case CLEAR_ITEMS_FILTERS:
      return {
        ...state,
        currentFilters: INITIAL_FILTERS,
        currentPage: INITIAL_PAGE
      };

    case SORT_ITEMS:
      return {
        ...state,
        currentSort: action.nextSort,
        currentPage: INITIAL_PAGE
      };

    default:
      return state;
  }
}
