import {
  FieldBrand,
  ItemID,
  ItemUUID,
  ItemNanoId,
  ItemCreatedAt,
  MayBe
} from '../../../types';
import {
  CandidateShareAutoJoin,
  CandidateShareId,
  CandidateShareMessage,
  CandidateShareShareSettings,
  CandidateShareStatus,
  CandidateShareUserId
} from '../../candidateShares/candidateSharesTypes';
import {
  MayBeRecipientImage,
  MayBeRecipientImageId,
  RecipientId,
  RecipientName
} from '../../recipients/recipientsTypes';

export type CandidateShareRecipientId = FieldBrand<
  ItemID,
  'CandidateShareRecipientId'
>;
export type CandidateShareRecipientUuid = FieldBrand<
  ItemUUID,
  'CandidateShareRecipientUuid'
>;
export type MayBeCandidateShareRecipientUuid =
  MayBe<CandidateShareRecipientUuid>;
export type CandidateShareRecipientNanoId = FieldBrand<
  ItemNanoId,
  'CandidateShareRecipientNanoId'
>;
export type MayBeCandidateShareRecipientNanoId =
  MayBe<CandidateShareRecipientNanoId>;
export type CandidateShareRecipientRecipientId = FieldBrand<
  RecipientId,
  'CandidateShareRecipientRecipientId'
>;
export type CandidateShareRecipientRecipient = FieldBrand<
  {
    id: RecipientId;
    name: RecipientName;
    fullName: RecipientName;
    image: MayBeRecipientImage;
    imageId: MayBeRecipientImageId;
  },
  'CandidateShareRecipientRecipient'
>;
export type CandidateShareRecipientCreatedAt = FieldBrand<
  ItemCreatedAt,
  'CandidateShareRecipientCreatedAt'
>;
export type CandidateShareRecipientUpdatedAt = FieldBrand<
  ItemCreatedAt,
  'CandidateShareRecipientUpdatedAt'
>;
export type CandidateShareRecipientCandidateShareId = FieldBrand<
  CandidateShareId,
  'CandidateShareRecipientCandidateShareId'
>;
export type CandidateShareRecipientCandidateShare = FieldBrand<
  {
    id: CandidateShareId;
    autoJoin: CandidateShareAutoJoin;
    status: CandidateShareStatus;
    message: CandidateShareMessage;
    shareSettings: CandidateShareShareSettings;
    userId: CandidateShareUserId;
  },
  'CandidateShareRecipientCandidateShare'
>;

export const enum CandidateShareRecipientFields {
  ID = 'id',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  RECIPIENT_ID = 'recipientId',
  RECIPIENT = 'recipient',
  CANDIDATE_SHARE_ID = 'candidateShareId',
  CANDIDATE_SHARE = 'candidateShare',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  WITHOUT_HIDDEN = 'withoutHidden',
  JOB_CANDIDATE_ID = 'jobCandidateId'
}
