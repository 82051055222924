import snakeCase from 'lodash/snakeCase';

import {
  FetchIndustriesQuery,
  IndustryFields,
  IndustryId,
  IndustryNanoId,
  IndustryName,
  IndustryUser,
  IndustryCreatedAt
} from '../industriesTypes';

export interface FetchIndustriesResponse {
  [IndustryFields.ID]: IndustryId;
  [IndustryFields.NANO_ID]: IndustryNanoId;
  [IndustryFields.NAME]: IndustryName;
  [IndustryFields.USER]: IndustryUser;
  [IndustryFields.CREATED_AT]: IndustryCreatedAt;
}

export const fetchIndustriesColumns = [
  IndustryFields.ID,
  IndustryFields.NANO_ID,
  IndustryFields.NAME,
  IndustryFields.CREATED_AT
];

const fetchIndustriesSBColumns = fetchIndustriesColumns.map(
  (fetchIndustriesColumn) => snakeCase(fetchIndustriesColumn)
);

export const fetchIndustriesQuery = `
  ${fetchIndustriesSBColumns.join(',')},
  user:user_id(id,nano_id,name,image:image_id(id,nano_id,name,file,key,created_at))
` as FetchIndustriesQuery;
