import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  ReactNode,
  Dispatch,
  SetStateAction
} from 'react';

type ControlPanelLink =
  | 'dashboard'
  | 'companies'
  | 'emailTemplates'
  | 'users'
  | 'recruiters'
  | 'candidates'
  | 'talent-pools'
  | 'accountTypes'
  | 'jobs'
  | 'skills'
  | 'skillLicenses'
  | 'htmlPages'
  | 'roles'
  | 'user-management'
  | 'permissions'
  | 'images'
  | 'people'
  | 'other'
  | 'email'
  | 'files'
  | 'jobResumes'
  | 'settings'
  | 'help';

type AppLayoutLink =
  | 'dashboard'
  | 'updates'
  | 'recruit'
  | 'hire'
  | 'jobs'
  | 'job-talent-pools'
  | 'screening'
  | 'pipeline'
  | 'calendar'
  | 'inbox'
  | 'sourcing'
  | 'prospects'
  | 'talent-pools'
  | 'relationships'
  | 'career-site'
  | 'settings'
  | 'help';

export type MainActiveLink = AppLayoutLink | ControlPanelLink;

// Define an interface for the context value
interface ActiveLinkContextType {
  activeLink: MainActiveLink;
  setActiveLink: Dispatch<SetStateAction<MainActiveLink>>;
}

// Create the context with the correct type
const ActiveLinkContext = createContext<ActiveLinkContextType | undefined>(
  undefined
);

export const ActiveLinkProvider = ({
  children
}: {
  children: ReactNode;
}): JSX.Element => {
  const [activeLink, setActiveLink] = useState<MainActiveLink>('dashboard');

  const value = useMemo(() => ({ activeLink, setActiveLink }), [activeLink]);

  return (
    <ActiveLinkContext.Provider value={value}>
      {children}
    </ActiveLinkContext.Provider>
  );
};

export const useActiveLink = () => {
  const context = useContext(ActiveLinkContext);
  if (!context) {
    throw new Error('useActiveLink must be used within an ActiveLinkProvider');
  }
  return context;
};
