import { Flex, Stack, StackDivider } from '@chakra-ui/layout';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { useRightSidebarContext } from '../../../../../../context';
import { IconButton } from '../../../../../../helpers/buttons/IconButton';
import { CheckboxField } from '../../../../../../helpers/forms/formFields/CheckboxField';
import { Select } from '../../../../../../helpers/Select';
import { Text } from '../../../../../../helpers/Text';
import { ChevronRightIcon } from '../../../../../../icons/ChevronRightIcon';
import { MoreIcon } from '../../../../../../icons/MoreIcon';
import { AddTask } from './components/AddTask.tsx';

const TasksPopup = () => {
  const { isTasksSidebarOpened, toggleTasksSidebar } = useRightSidebarContext();
  const [hidden, setHidden] = useState(!isTasksSidebarOpened);

  return (
    <>
      <motion.div
        hidden={hidden}
        initial={false}
        onAnimationStart={() => setHidden(false)}
        onAnimationComplete={() => setHidden(!isTasksSidebarOpened)}
        animate={{ width: isTasksSidebarOpened ? 432 : 0 }}
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          top: '73px',
          right: '72px',
          position: 'fixed',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          height: 'calc(100vh - 73px)'
        }}
      >
        <Flex
          w="100%"
          bg="white"
          zIndex={2}
          position="relative"
          maxW="400px"
          flexDirection="column"
          borderLeft="1px solid"
          borderLeftColor="gray.200"
          p={6}
        >
          <IconButton
            p={0}
            minW={0}
            top={6}
            left={-4}
            position="absolute"
            hierarchy="link"
            color="gray.500"
            aria-label="Close"
            onClick={toggleTasksSidebar}
            _hover={{ color: 'gray.800' }}
          >
            <Flex
              w={8}
              h={8}
              bg="white"
              borderRadius="full"
              boxShadow="0 6px 14px rgba(0, 0, 0, 0.12)"
              alignItems="center"
              justifyContent="center"
            >
              <ChevronRightIcon />
            </Flex>
          </IconButton>

          <Flex pb={6}>
            <Select
              isSearchable={false}
              instanceId="stages-dropdown"
              placeholder=""
              options={[
                {
                  label: 'Sourcing',
                  value: 'sourcing'
                },
                {
                  label: 'Applied',
                  value: 'applied'
                },
                {
                  label: 'Phone Screen',
                  value: 'phone-screen'
                },
                {
                  label: 'Interview',
                  value: 'interview'
                },
                {
                  label: 'Offer',
                  value: 'offer'
                },
                {
                  label: 'Offer Pending',
                  value: 'offer-pending'
                },
                {
                  label: 'Hired',
                  value: 'hired'
                }
              ]}
            />
          </Flex>

          <Stack
            w="100%"
            spacing={3}
            overflow="auto"
            direction="column"
            divider={<StackDivider />}
          >
            {tasksList.map((task) => (
              <Flex key={task.id} direction="row" gap={4}>
                <CheckboxField size="small" />

                <Flex flex={1} display="flex">
                  <Text as="span" textStyle="body1Medium">
                    {task.name}
                  </Text>

                  <Text
                    as="span"
                    textStyle="body2Regular"
                    color="gray.600"
                    ml="auto"
                  >
                    {task.date}
                  </Text>
                </Flex>

                <IconButton
                  hierarchy="link"
                  aria-label={''}
                  ml="auto"
                  minW="0"
                  color="gray.600"
                >
                  <MoreIcon />
                </IconButton>
              </Flex>
            ))}

            <AddTask />
          </Stack>
        </Flex>
      </motion.div>
    </>
  );
};

export default TasksPopup;

const tasksList = [
  { id: '1', name: 'Schedule Interview', date: '04-03-2022' },
  { id: '2', name: 'Debrief candidate', date: '04-03-2022' },
  { id: '3', name: 'Schedule 2nd interview', date: '04-03-2022' },
  { id: '4', name: 'Request feedback', date: '04-03-2022' },
  { id: '5', name: 'Feedback review', date: '04-03-2022' }
];
