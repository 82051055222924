import { SystemStyleObject } from '@chakra-ui/react';

const defaultProps = {
  colorScheme: 'primary'
};

const baseStyle = ({ colorMode }: { colorMode: 'dark' | 'light' }) => ({
  control: {
    borderWidth: '1.5px',

    _checked: {
      background: 'transparent',
      borderStyle: 'solid',
      borderWidth: '1.5px',
      _before: {
        background: colorMode === 'light' ? 'primary.500' : 'primary.200'
      },
      _hover: {
        background: 'transparent'
      }
    },

    _disabled: {
      background: colorMode === 'light' ? 'gray.100' : 'whiteAlpha.100',

      _checked: {
        background: 'transparent',
        borderColor: colorMode === 'light' ? 'gray.400' : 'whiteAlpha.300',
        _before: {
          background: colorMode === 'light' ? 'gray.400' : 'whiteAlpha.300'
        }
      }
    }
  }
});

const sizes: Record<string, SystemStyleObject> = {
  sm: {
    control: { height: 5, width: 5 }
  },
  md: {
    control: {
      height: 6,
      width: 6
    }
  },
  lg: {
    control: { height: 7, width: 7 }
  }
};

export const Radio = {
  defaultProps,
  baseStyle,
  sizes
};
