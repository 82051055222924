import { Fragment, useCallback, useEffect, useState } from 'react';
import { FormControl } from '@chakra-ui/form-control';
import { Box, Divider, Flex, HStack, Stack } from '@chakra-ui/layout';
import { Switch } from '@chakra-ui/switch';

import { ItemNanoId } from '../../../../types';

import { CompanyNanoId } from '../../../../main/companies/companiesTypes';

import { useLeftSidebarContext } from '../../../../context';

import { useCurrentUser } from '../../../../auth/hooks/useAuth';
import { useUpdateCompany } from '../../../../main/users/hooks/useUpdateCompany';

import { AuthCache } from '../../../../auth/AuthCache';

import { AlertMessage } from '../../../../helpers/AlertMessage';
import { Text } from '../../../../helpers/Text';

import { HeaderRequiredProps } from './Header.types';
import { HeaderAccountDropdownMenu } from '../../../AppLayout/components/HeaderAccountDropdownMenu';
import { StaticSelect } from '../../../AppLayout/helpers/StaticSelect';
import { HiringPortalSettingsRoutes } from '../../../../main/hiringPortal/HiringPortalSettingsRoutes';

function Header({ onSignOut, totalTalentCredits }: HeaderRequiredProps) {
  const { dockSidebar, isDocked } = useLeftSidebarContext();
  const [hasMounted, setHasMounted] = useState(false);

  const currentUser = useCurrentUser();

  const { updateCompany, updateCompanyIsLoading, updateCompanyErrorMessage } =
    useUpdateCompany({
      userId: currentUser?.id,
      cacheKeys: [AuthCache.currentUserKey()]
    });

  const handleUpdateCompany = useCallback<
    (value: { nanoId: ItemNanoId } | null) => void
  >(
    (value) =>
      value && updateCompany({ companyNanoId: value.nanoId as CompanyNanoId }),
    [updateCompany]
  );

  useEffect(() => {
    setHasMounted(true);
  }, []);

  return (
    <Fragment>
      <Flex
        py={4}
        h="72px"
        bg="white"
        alignItems="center"
        pl={4}
        pr={6}
        position="relative"
        gap={4}
        zIndex={9}
      >
        <Stack spacing={6} direction="row" alignItems="center">
          {hasMounted && (
            <Switch
              w="24px"
              variant="custom"
              height="24px"
              defaultChecked={!isDocked}
              onChange={() => dockSidebar(!isDocked)}
            />
          )}

          <HStack spacing={3}>
            <Flex w={{ lg: '32', xl: '64' }}>
              <FormControl>
                {hasMounted && currentUser ? (
                  <>
                    <StaticSelect
                      isLoading={updateCompanyIsLoading}
                      isDisabled={updateCompanyIsLoading}
                      currentItem={currentUser.company}
                      options={(currentUser.companyMembers || []).map(
                        (companyMember) => companyMember.company
                      )}
                      onChange={handleUpdateCompany}
                    />
                    <AlertMessage message={updateCompanyErrorMessage} />
                  </>
                ) : null}
              </FormControl>
            </Flex>
            {isNaN(totalTalentCredits as number) ? null : (
              <HStack spacing={2}>
                <Box
                  height="fit-content"
                  py={0}
                  px={1}
                  borderRadius="base"
                  bg="#EEEEF0"
                >
                  <Text textStyle="body2Medium" color="gray.900">
                    {totalTalentCredits}
                  </Text>
                </Box>
                <Text textStyle="body2Medium" color="primary.500">
                  Credit{Number(totalTalentCredits) > 1 ? 's' : ''}
                </Text>
              </HStack>
            )}
          </HStack>
        </Stack>

        <Stack ml="auto" direction="row" alignItems="center" spacing={6}>
          <HeaderAccountDropdownMenu
            currentUser={currentUser}
            onSignOut={onSignOut}
            showProfile={false}
            settingsRoute={HiringPortalSettingsRoutes.personalAbout()}
          />
        </Stack>
      </Flex>

      <Divider />
    </Fragment>
  );
}

export default Header;
