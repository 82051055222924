import {
  FetchLikedJobCandidatesCacheKey,
  FetchLikedJobCandidateCacheKey,
  DeleteLikedJobCandidateErrorMessage,
  DeleteLikedJobCandidateIsLoading,
  DeleteLikedJobCandidateID
} from '../../likedJobCandidatesTypes';

import { useDeleteItem } from '../../../common/hooks/useDeleteItem';

import { DeleteLikedJobCandidateErrorResponse } from '../../LikedJobCandidatesRequests';
import { LikedJobCandidatesBffRequests } from '../../LikedJobCandidatesBffRequests';

interface DeleteLikedJobCandidateOptions {
  likedJobCandidateId: DeleteLikedJobCandidateID;
  cacheKeys?: (
    | FetchLikedJobCandidatesCacheKey
    | FetchLikedJobCandidateCacheKey
  )[];
}

function useDeleteLikedJobCandidate({
  likedJobCandidateId,
  cacheKeys = []
}: DeleteLikedJobCandidateOptions) {
  const {
    deleteItemData,
    deleteItemError,
    deleteItemErrorMessage,
    deleteItemIsLoading,
    deleteItem,
    deleteItemReset
  } = useDeleteItem<unknown, DeleteLikedJobCandidateErrorResponse>({
    mutationFn: () =>
      LikedJobCandidatesBffRequests.deleteLikedJobCandidate(
        likedJobCandidateId
      ),
    cacheKeys
  });

  return {
    deleteLikedJobCandidateData: deleteItemData,
    deleteLikedJobCandidateError: deleteItemError,
    deleteLikedJobCandidateErrorMessage:
      deleteItemErrorMessage as DeleteLikedJobCandidateErrorMessage,
    deleteLikedJobCandidateIsLoading:
      deleteItemIsLoading as DeleteLikedJobCandidateIsLoading,
    deleteLikedJobCandidate: deleteItem,
    deleteLikedJobCandidateReset: deleteItemReset
  };
}

export default useDeleteLikedJobCandidate;
