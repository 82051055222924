import { Icon, IconProps } from '@chakra-ui/icon';

const ClockOutlineIcon = ({
  w = '16px',
  h = '16px',
  stroke = 'currentColor',
  ...props
}: IconProps) => (
  <Icon viewBox="0 0 16 16" w={w} h={h} fill="none" stroke={stroke} {...props}>
    <path
      clipRule="evenodd"
      d="M8 1a7 7 0 1 1 0 14A7 7 0 0 1 8 1v0Z"
      // stroke="currentColor"
      strokeWidth={1.6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 4.18v3.818h3.818"
      // stroke="currentColor"
      strokeWidth={1.6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
export default ClockOutlineIcon;
