import {
  IndustryExpertiseRequirements,
  IndustryExpertiseRequirementsTypes
} from '../../industryExpertisesTypes';

function renderIndustryExpertiseRequirementsBackground(
  requirements: IndustryExpertiseRequirements
) {
  switch (requirements) {
    case IndustryExpertiseRequirementsTypes.NICE_TO_HAVE:
      return { main: '#697275', experience: '#DAE1E3' };
    case IndustryExpertiseRequirementsTypes.IMPORTANT:
      return { main: '#EEA401', experience: '#EAC676' };
    case IndustryExpertiseRequirementsTypes.VERY_IMPORTANT:
      return { main: '#F1472F', experience: '#F8A599' };
    default:
      return { main: '#0BAD90', experience: '#59C7B4' };
  }
}

export default renderIndustryExpertiseRequirementsBackground;
