import {
  Flex,
  Grid,
  HStack,
  StackDivider,
  Tooltip,
  useDisclosure
} from '@chakra-ui/react';
import { MouseEvent, ReactNode, useCallback } from 'react';
import { Heading } from '../../../../../helpers/Heading';
import { IconButton } from '../../../../../helpers/buttons/IconButton';
import { AppLayoutRequiredProps } from '../../../../../layouts/AppLayout';
import { ModalIsOpen } from '../../../../../types';
import {
  JobCreatedAt,
  JobDepartmentKey,
  JobId,
  JobJobFunctionId,
  JobJobTitle,
  JobName,
  JobNanoId,
  MayBeJobCities,
  MayBeJobCity
} from '../../../../jobs/jobsTypes';

import { AddNewLeadModal } from '../../../../jobs/components/modals/AddNewLeadModal';

import { useSetActiveLink } from '../../../../../common/hooks/utils/useSetActiveLink';
import { useShareCandidatesProfileContext } from '../../../../../context';
import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { Button } from '../../../../../helpers/Button';
import { ChevronDownIcon } from '../../../../../icons/ChevronDownIcon';
import { DropUpIcon } from '../../../../../icons/DropUpIcon';
import { EmailIcon } from '../../../../../icons/EmailIcon';
import { FilterIcon } from '../../../../../icons/FilterIcon';
import { FolderIcon } from '../../../../../icons/FolderIcon';
import { LinkedInIcon } from '../../../../../icons/LinkedInIcon';
import { MessageIcon } from '../../../../../icons/MessageIcon';
import { PhoneIcon } from '../../../../../icons/PhoneIcon';
import { ShareIcon } from '../../../../../icons/ShareIcon';
import { JobCandidateDetailsJobCandidate } from '../../../../jobs/pages/ViewJobCandidateDetailsPage/ViewJobCandidateDetailsPage.types';
import { ViewJobCandidateDetailsAddComment } from '../../../../jobs/pages/ViewJobCandidateDetailsPage/components/ViewJobCandidateDetailsAddComment';
import { ViewJobCandidateDetailsBio } from '../../../../jobs/pages/ViewJobCandidateDetailsPage/components/ViewJobCandidateDetailsBio';
import { ViewJobCandidateDetailsComposeEmail } from '../../../../jobs/pages/ViewJobCandidateDetailsPage/components/ViewJobCandidateDetailsComposeEmail';
import { ViewJobCandidateDetailsHeaderMenuAddJob } from '../../../../jobs/pages/ViewJobCandidateDetailsPage/components/ViewJobCandidateDetailsHeaderMenuAddJob';
import { renderJobTitle } from '../../../../jobs/utils/renderJobTitle';

interface SourcingProspectLayoutProps extends AppLayoutRequiredProps {
  poolNanoId?: JobNanoId;
  children?: ReactNode;
  job?: {
    departmentKey: JobDepartmentKey;
    city: MayBeJobCity;
    cities: MayBeJobCities;
    createdAt: JobCreatedAt;
    nanoId: JobNanoId;
    name: JobName;
    jobTitle: JobJobTitle;
    jobFunctionId: JobJobFunctionId;
    id: JobId;
  } | null;
  isOpenRightSidebar?: boolean;
  withDetailsTab?: boolean;
  jobCandidate?: JobCandidateDetailsJobCandidate;
  hasScrolled?: boolean;
  requiresCredit?: boolean;
  jobCandidateIsFetched?: boolean;
  jobCandidateErrorMessage?: string;
}

interface ShareCandidatesProfileCheckEvent {
  target: { checked: boolean };
}

function SourcingProspectLayout({
  poolNanoId,
  children,
  job,
  withDetailsTab,
  jobCandidate,
  hasScrolled,
  requiresCredit,
  jobCandidateErrorMessage
}: SourcingProspectLayoutProps) {
  const { isOpen, onClose } = useDisclosure();

  const jobTitle = job ? renderJobTitle(job) : null;

  const { onOpenShareProfile, toggleJobCandidateCheck, checkedJobCandidates } =
    useShareCandidatesProfileContext();

  const handleCheckCandidate = useCallback<
    (e: MouseEvent<HTMLButtonElement>) => void
  >(() => {
    const simulatedEvent: ShareCandidatesProfileCheckEvent = {
      target: { checked: true }
    };

    const isCandidateChecked = jobCandidate
      ? checkedJobCandidates.includes(jobCandidate)
      : null;

    if (!isCandidateChecked && jobCandidate) {
      toggleJobCandidateCheck(jobCandidate, simulatedEvent);
    }

    onOpenShareProfile();
  }, [
    checkedJobCandidates,
    toggleJobCandidateCheck,
    jobCandidate,
    onOpenShareProfile
  ]);

  useSetActiveLink('prospects');

  return (
    <Grid gridTemplateRows="max-content 1fr">
      {job ? (
        <Flex
          top={0}
          zIndex={99}
          overflow="hidden"
          position="sticky"
          borderBottom="1px splid"
          borderBottomColor="gray.200"
          justifyContent="space-between"
          bg={hasScrolled ? 'white' : 'gray.50'}
        >
          <Flex
            py={3}
            pl={5}
            alignItems="center"
            justifyContent="space-between"
            bg={hasScrolled ? 'white' : 'gray.50'}
          >
            <Heading level="h2" color="gray.900" noOfLines={1}>
              Prospects
              <Flex hidden alignItems={'center'} gap={'10px'}>
                {jobTitle ? (
                  <Tooltip
                    label={jobTitle}
                    aria-label="Job Name"
                    placement="top"
                  >
                    {jobTitle}
                  </Tooltip>
                ) : null}
                <DropUpIcon />
              </Flex>
            </Heading>

            <Flex
              gap={2}
              alignItems="center"
              transitionDuration="slow"
              zIndex={999}
              top={hasScrolled ? 0 : '100%'}
              opacity={hasScrolled ? 1 : 0}
              pl={'30px'}
              bg={hasScrolled ? 'white' : 'gray.50'}
            >
              {jobCandidateErrorMessage ? (
                <AlertMessage message={jobCandidateErrorMessage} />
              ) : null}

              {jobCandidate ? (
                <ViewJobCandidateDetailsBio
                  requiresCredit={requiresCredit}
                  jobCandidate={jobCandidate}
                  isCollapsed
                  job={job}
                />
              ) : null}

              <Flex
                w={6}
                h={6}
                bg="#069"
                alignItems="center"
                borderRadius="full"
                justifyContent="center"
              >
                <LinkedInIcon borderRadius="full" w={3.5} h={3.5} />
              </Flex>
            </Flex>
          </Flex>

          <Flex pr={'20px'}>
            <Flex gap={4} flex={1} alignItems="center">
              <HStack ml="auto" spacing={4} flexWrap="wrap" alignItems="center">
                {jobCandidateErrorMessage ? (
                  <AlertMessage message={jobCandidateErrorMessage} />
                ) : null}
                {jobCandidate ? (
                  <ViewJobCandidateDetailsHeaderMenuAddJob
                    jobCandidate={
                      jobCandidate as JobCandidateDetailsJobCandidate
                    }
                  />
                ) : null}
                <Button
                  h={8}
                  size="medium"
                  display="flex"
                  color="gray.600"
                  hierarchy="unstyled"
                  leftIcon={<ShareIcon w={5} h={5} />}
                  onClick={handleCheckCandidate}
                  // onChange={}
                >
                  Share
                </Button>

                <Button
                  h={8}
                  size="medium"
                  display="flex"
                  color="gray.600"
                  hierarchy="unstyled"
                  pr={4}
                  leftIcon={<FolderIcon w={5} h={5} />}
                >
                  Move
                </Button>
              </HStack>
            </Flex>

            <Flex gap={3} alignItems="center">
              <ViewJobCandidateDetailsComposeEmail
                trigger={
                  <Tooltip label="Email" textTransform="capitalize" hasArrow>
                    <IconButton
                      h={6}
                      w={6}
                      minW={0}
                      minH={0}
                      value="email"
                      display="flex"
                      aria-label=""
                      alignItems="center"
                      borderRadius="full"
                      justifyContent="center"
                      icon={<EmailIcon w={3.5} h={3.5} />}
                      hierarchy="tertiary"
                    />
                  </Tooltip>
                }
              />

              <ViewJobCandidateDetailsAddComment
                trigger={
                  <Tooltip label="Comment" textTransform="capitalize" hasArrow>
                    <IconButton
                      h={6}
                      w={6}
                      minW={0}
                      minH={0}
                      value="comment"
                      display="flex"
                      aria-label=""
                      alignItems="center"
                      borderRadius="full"
                      justifyContent="center"
                      icon={<MessageIcon w={3.5} h={3.5} />}
                      hierarchy="tertiary"
                    />
                  </Tooltip>
                }
              />

              <HStack
                spacing={0}
                overflow="hidden"
                borderRadius="base"
                divider={<StackDivider borderColor="primary.100" />}
              >
                <Button
                  h={7}
                  px={4}
                  size="extra-small"
                  borderRadius="none"
                  leftIcon={<PhoneIcon w={3} h={3} fill="white" />}
                >
                  Call
                </Button>

                <IconButton
                  h={7}
                  display="flex"
                  aria-label=""
                  size="extra-small"
                  alignItems="center"
                  borderRadius="none"
                  justifyContent="center"
                  icon={<ChevronDownIcon w={3.5} h={3.5} fill="white" />}
                />
              </HStack>

              <IconButton
                w={8}
                h={8}
                // px={6}
                ml="auto"
                minW="unset"
                display="flex"
                hierarchy="unstyled"
                aria-label="add candidate"
                borderRadius={'10px'}
              >
                <FilterIcon w={5} h={5} />
              </IconButton>
            </Flex>
          </Flex>
        </Flex>
      ) : null}

      <Grid h="full" templateRows={withDetailsTab ? 'max-content 1fr' : '1fr'}>
        {children}
      </Grid>

      {isOpen && poolNanoId ? (
        <AddNewLeadModal
          modalIsOpen={isOpen as ModalIsOpen}
          onCloseModal={onClose}
          jobNanoId={poolNanoId as JobNanoId}
        />
      ) : null}
    </Grid>
  );
}

export default SourcingProspectLayout;
