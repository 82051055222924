import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { FormLabel, Spinner } from '@chakra-ui/react';
import { Box, Flex, HStack, Stack } from '@chakra-ui/layout';
import includes from 'lodash/includes';
import orderBy from 'lodash/orderBy';
import compact from 'lodash/compact';

import { ErrorMessage, I18nText } from '../../../../../types';

import {
  FetchIndustriesPageSize,
  FetchIndustriesSort,
  IndustryId,
  IndustryFields,
  IndustryName,
  FetchIndustriesEnabled
} from '../../../industriesTypes';

import {
  FetchIndustryExpertisesCacheKey,
  FetchIndustryExpertisesFilters,
  FetchIndustryExpertisesPageSize,
  FetchIndustryExpertisesSort,
  IndustryExpertiseExperience,
  IndustryExpertiseFields,
  IndustryExpertiseJobCandidateId,
  IndustryExpertiseJobId,
  IndustryExpertiseJobResumeRecruiterId,
  IndustryExpertiseNanoId,
  IndustryExpertiseIndustryId,
  IndustryExpertiseRequirements,
  IndustryExpertiseUserId,
  IndustryExpertiseTalentPoolId
} from '../../../../industryExpertises/industryExpertisesTypes';

import {
  fetchIndustryExpertisesQuery,
  FetchIndustryExpertisesResponse
} from '../../../../industryExpertises/queries/fetchIndustryExpertises.query';
import {
  fetchIndustriesQuery,
  FetchIndustriesResponse
} from '../../../queries/fetchIndustries.query';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';
import { usePaginatedIndustries } from '../../../hooks/usePaginatedIndustries';
import { usePaginatedIndustryExpertises } from '../../../../industryExpertises/hooks/usePaginatedIndustryExpertises';
import { useCreateIndustryExpertise } from '../../../../industryExpertises/hooks/useCreateIndustryExpertise';

import { IndustryExpertiseWrapper } from '../../../../industryExpertises/components/IndustryExpertiseWrapper';
import { AddEditIndustryExpertiseForm } from '../../../../industryExpertises/components/AddEditIndustryExpertiseForm';

import {
  AddIndustryExpertiseWrapper,
  AddIndustryExpertiseWrapperOnSelectIndustry
} from '../../wrappers/AddIndustryExpertiseWrapper';
import { AddIndustryDropdownWrapper } from '../../wrappers/AddIndustryDropdownWrapper';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { Text } from '../../../../../helpers/Text';

import { generateNanoId } from '../../../../../utils/generateNanoId';

import {
  CommonIndustriesBoxJob,
  CommonIndustriesBoxJobCandidateId,
  CommonIndustriesBoxJobResumeRecruiter,
  CommonIndustriesBoxTalentPool
} from './CommonIndustriesBox.types';

import { OnIndustryDropdownSelect } from '../../../helpers/IndustryDropdown';
import { IndustriesCache } from '../../../IndustriesCache';

interface NewIndustryType {
  id: IndustryId;
  name: IndustryName;
}

interface CommonIndustriesBoxDefaultProps {
  label: I18nText;
  errorMessage?: ErrorMessage;
  industryExpertisesCacheKey: FetchIndustryExpertisesCacheKey;
  requiredIndustryIds?: IndustryId[];
  createOnly?: boolean;
  withoutAddBlock?: boolean;
  onIndustryExpertisesUpdated?: (
    industryExpertises: FetchIndustryExpertisesResponse[]
  ) => void;
  withoutEditDeleteFunctionality?: boolean;
  withoutPortal?: boolean;
  showIndustryExpertiseIndustryNameOnly?: boolean;
}

interface CommonIndustriesBoxWithJobProps {
  job: CommonIndustriesBoxJob;
  talentPool?: never;
  jobCandidateId?: never;
  jobResumeRecruiter?: never;
}

interface CommonIndustriesBoxWithTalentPoolProps {
  job?: never;
  talentPool: CommonIndustriesBoxTalentPool;
  jobCandidateId?: never;
  jobResumeRecruiter?: never;
}

interface CommonIndustriesBoxWithJobCandidateProps {
  job?: never;
  talentPool?: never;
  jobCandidateId: CommonIndustriesBoxJobCandidateId;
  jobResumeRecruiter?: never;
}

interface CommonIndustriesBoxWithJobResumeRecruiterProps {
  job?: never;
  talentPool?: never;
  jobCandidateId?: never;
  jobResumeRecruiter: CommonIndustriesBoxJobResumeRecruiter;
}

type CommonIndustriesBoxProps = CommonIndustriesBoxDefaultProps &
  (
    | CommonIndustriesBoxWithJobProps
    | CommonIndustriesBoxWithTalentPoolProps
    | CommonIndustriesBoxWithJobCandidateProps
    | CommonIndustriesBoxWithJobResumeRecruiterProps
  );

function CommonIndustriesBox({
  label,
  errorMessage,
  industryExpertisesCacheKey,
  jobCandidateId,
  job,
  talentPool,
  jobResumeRecruiter,
  requiredIndustryIds,
  createOnly,
  withoutAddBlock,
  onIndustryExpertisesUpdated,
  withoutEditDeleteFunctionality,
  showIndustryExpertiseIndustryNameOnly,
  withoutPortal
}: CommonIndustriesBoxProps) {
  const currentUser = useCurrentUser();

  const {
    industryExpertises,
    industryExpertisesIsFetched,
    industryExpertisesErrorMessage
  } = usePaginatedIndustryExpertises<FetchIndustryExpertisesResponse>({
    query: fetchIndustryExpertisesQuery,
    cacheKey: industryExpertisesCacheKey,
    initialPageSize: 20 as FetchIndustryExpertisesPageSize,
    initialFilters: {
      ...(job?.id
        ? {
            [IndustryExpertiseFields.JOB_ID]: {
              operator: 'eq',
              value: `${job.id}`
            }
          }
        : {}),
      ...(talentPool?.id
        ? {
            [IndustryExpertiseFields.TALENT_POOL_ID]: {
              operator: 'eq',
              value: `${talentPool.id}`
            }
          }
        : {}),
      ...(jobResumeRecruiter?.id
        ? {
            [IndustryExpertiseFields.JOB_RESUME_RECRUITER_ID]: {
              operator: 'eq',
              value: `${jobResumeRecruiter.id}`
            }
          }
        : {}),
      ...(jobCandidateId
        ? {
            [IndustryExpertiseFields.JOB_CANDIDATE_ID]: {
              operator: 'eq',
              value: `${jobCandidateId}`
            }
          }
        : {})
    } as unknown as FetchIndustryExpertisesFilters,
    initialSort: {
      [IndustryExpertiseFields.CREATED_AT]: { ascending: false }
    } as unknown as FetchIndustryExpertisesSort,
    onSuccess: (data) => onIndustryExpertisesUpdated?.(data.data)
  });

  const { industries, industriesIsFetched, industriesErrorMessage } =
    usePaginatedIndustries<FetchIndustriesResponse>({
      query: fetchIndustriesQuery,
      cacheKey: IndustriesCache.indexCacheKey(),
      enabled: !withoutAddBlock as FetchIndustriesEnabled,
      initialPageSize: 10 as FetchIndustriesPageSize,
      // initialFilters: {
      //   ...(job
      //     ? {
      //         [IndustryFields.ID]: {
      //           operator: 'in',
      //           value: join(job.industryIds, ',') || []
      //         }
      //       }
      //     : {}),
      //   ...(talentPool
      //     ? {
      //         [IndustryFields.ID]: {
      //           operator: 'in',
      //           value: join(talentPool.industryIds, ',') || []
      //         }
      //       }
      //     : {}),
      //   ...(jobResumeRecruiter
      //     ? {
      //         [IndustryFields.ID]: {
      //           operator: 'in',
      //           value: join(jobResumeRecruiter.industryIds, ',') || []
      //         }
      //       }
      //     : {})
      // } as unknown as FetchIndustriesFilters,
      initialSort: {
        [IndustryFields.CREATED_AT]: { ascending: false }
      } as unknown as FetchIndustriesSort
    });

  const industryIdsOnly = industryExpertises.map(
    (industryExpertise) => industryExpertise.industry?.id
  );

  const industryIds = compact([
    ...industryExpertises.map(
      (industryExpertise) => industryExpertise.industryId
    ),
    ...industries.map((industry) => industry.id)
  ]);

  const [selectedIndustryExpertise, setSelectedIndustryExpertise] =
    useState<FetchIndustryExpertisesResponse | null>(null);

  const clearSelectedIndustryExpertise = useCallback<() => void>(
    () => setSelectedIndustryExpertise(null),
    [setSelectedIndustryExpertise]
  );

  const [selectedIndustry, setSelectedIndustry] =
    useState<FetchIndustriesResponse | null>(null);

  const [newIndustry, setNewIndustry] = useState<NewIndustryType | null>(null);

  const clearIndustries = useCallback<() => void>(() => {
    setSelectedIndustry(null);
    setNewIndustry(null);
  }, [setSelectedIndustry, setNewIndustry]);

  const {
    createIndustryExpertiseErrorMessage,
    createIndustryExpertise,
    createIndustryExpertiseIsLoading
  } = useCreateIndustryExpertise({
    cacheKeys: [industryExpertisesCacheKey]
  });

  const handleSelectIndustry =
    useCallback<AddIndustryExpertiseWrapperOnSelectIndustry>(
      async (industry) => {
        if (createOnly) {
          await createIndustryExpertise({
            requirements: 'none' as IndustryExpertiseRequirements,
            experience: 'none' as IndustryExpertiseExperience,
            industryId: industry.id as IndustryExpertiseIndustryId,
            jobId: job?.id as IndustryExpertiseJobId,
            talentPoolId: talentPool?.id as IndustryExpertiseTalentPoolId,
            jobCandidateId: jobCandidateId as IndustryExpertiseJobCandidateId,
            jobResumeRecruiterId:
              jobResumeRecruiter?.id as IndustryExpertiseJobResumeRecruiterId,
            userId: currentUser.id as IndustryExpertiseUserId,
            nanoId: generateNanoId<IndustryExpertiseNanoId>()
          });
          return;
        }

        setSelectedIndustry(industry);
      },
      [
        createOnly,
        job?.id,
        jobCandidateId,
        jobResumeRecruiter?.id,
        currentUser.id,
        createIndustryExpertise,
        setSelectedIndustry,
        talentPool?.id
      ]
    );
  const handleSelectNewIndustry = useCallback<OnIndustryDropdownSelect>(
    async (industry) => {
      if (createOnly) {
        await createIndustryExpertise({
          requirements: 'none' as IndustryExpertiseRequirements,
          experience: 'none' as IndustryExpertiseExperience,
          industryId: industry?.id as IndustryExpertiseIndustryId,
          jobId: job?.id as IndustryExpertiseJobId,
          talentPoolId: talentPool?.id as IndustryExpertiseTalentPoolId,
          jobCandidateId: jobCandidateId as IndustryExpertiseJobCandidateId,
          jobResumeRecruiterId:
            jobResumeRecruiter?.id as IndustryExpertiseJobResumeRecruiterId,
          userId: currentUser.id as IndustryExpertiseUserId,
          nanoId: generateNanoId<IndustryExpertiseNanoId>()
        });
        return;
      }

      setNewIndustry(industry);
    },
    [
      createOnly,
      job?.id,
      jobCandidateId,
      jobResumeRecruiter?.id,
      currentUser.id,
      createIndustryExpertise,
      setNewIndustry,
      talentPool?.id
    ]
  );

  const handleSelectIndustryExpertise = useCallback<
    (industryExpertise: FetchIndustryExpertisesResponse) => void
  >(
    (industryExpertise) => {
      if (createOnly) return;

      setSelectedIndustryExpertise(industryExpertise);
    },
    [createOnly]
  );

  const addEditIndustryExpertiseFormFormWrapper = useRef<HTMLDivElement>(null);

  useEffect(() => {
    (selectedIndustry || newIndustry) &&
      addEditIndustryExpertiseFormFormWrapper.current &&
      addEditIndustryExpertiseFormFormWrapper.current.scrollIntoView({
        behavior: 'smooth'
      });
  }, [selectedIndustry, newIndustry]);

  return (
    <Stack spacing={4}>
      <FormLabel display="flex" m={0}>
        {label}
      </FormLabel>

      <AlertMessage message={industryExpertisesErrorMessage} />

      <LoadingSkeleton loaded={industryExpertisesIsFetched} count={2}>
        <Flex flexWrap="wrap" gap={3}>
          {industryExpertises.map((industryExpertise) =>
            showIndustryExpertiseIndustryNameOnly ? (
              <Box
                key={industryExpertise.id}
                px={2}
                py={0}
                bg="white"
                border="1px solid"
                borderColor="gray.300"
                borderRadius="base"
              >
                <Text textStyle="body1Regular" color="gray.600">
                  {industryExpertise.industry?.name}
                </Text>
              </Box>
            ) : (
              <IndustryExpertiseWrapper
                key={industryExpertise.id}
                industryExpertise={industryExpertise}
                isEditing={
                  selectedIndustryExpertise?.id === industryExpertise.id
                }
                industryExpertisesCacheKey={industryExpertisesCacheKey}
                onEdit={setSelectedIndustryExpertise}
                withoutPortal={withoutPortal}
                allowEditing={!withoutEditDeleteFunctionality}
              />
            )
          )}
        </Flex>
      </LoadingSkeleton>

      {selectedIndustryExpertise ? (
        <AddEditIndustryExpertiseForm
          jobId={job?.id}
          jobCandidateId={jobCandidateId}
          jobResumeRecruiterId={jobResumeRecruiter?.id}
          industryExpertise={selectedIndustryExpertise}
          industryExpertisesCacheKey={industryExpertisesCacheKey}
          onAdd={clearSelectedIndustryExpertise}
          onDiscard={clearSelectedIndustryExpertise}
        />
      ) : null}

      {withoutAddBlock ? null : (
        <Fragment>
          <AlertMessage message={industriesErrorMessage} />

          <LoadingSkeleton loaded={industriesIsFetched} count={2}>
            <HStack spacing={2}>
              <Text>Add applicable industries</Text>
              {createIndustryExpertiseIsLoading ? (
                <Spinner color="primary.500" size="xs" />
              ) : null}
            </HStack>
            <Flex flexWrap="wrap" gap={3}>
              {orderBy(industries, (industry) =>
                includes(requiredIndustryIds, industry.id) ? 0 : 1
              ).map((industry) => (
                <Fragment key={industry.id}>
                  {includes(industryIdsOnly, industry.id) ? null : (
                    <AddIndustryExpertiseWrapper
                      industry={industry}
                      isSelected={selectedIndustry?.id === industry.id}
                      isRequired={includes(requiredIndustryIds, industry.id)}
                      onSelectIndustry={handleSelectIndustry}
                    />
                  )}
                </Fragment>
              ))}

              {industryExpertisesIsFetched && industriesIsFetched ? (
                <Box minW="90px">
                  <AddIndustryDropdownWrapper
                    industryIds={industryIds}
                    industriesCacheKey={IndustriesCache.newIndustriesCacheKey()}
                    industryExpertises={industryExpertises}
                    onSelectExistingIndustry={handleSelectIndustry}
                    onSelectExistingIndustryExpertise={
                      handleSelectIndustryExpertise
                    }
                    onSelectIndustry={handleSelectNewIndustry}
                  />
                </Box>
              ) : null}
              <AlertMessage
                message={createIndustryExpertiseErrorMessage || errorMessage}
              />
            </Flex>
          </LoadingSkeleton>
        </Fragment>
      )}

      {selectedIndustry || newIndustry ? (
        <Box ref={addEditIndustryExpertiseFormFormWrapper}>
          <AddEditIndustryExpertiseForm
            key={(selectedIndustry || newIndustry)?.id}
            jobId={job?.id}
            jobCandidateId={jobCandidateId}
            jobResumeRecruiterId={jobResumeRecruiter?.id}
            industry={selectedIndustry || newIndustry || undefined}
            industryExpertisesCacheKey={industryExpertisesCacheKey}
            onAdd={clearIndustries}
            onDiscard={clearIndustries}
          />
        </Box>
      ) : null}
    </Stack>
  );
}

export default CommonIndustriesBox;
