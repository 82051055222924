import { Icon, IconProps } from '@chakra-ui/icon';

const CertifiedIcon = ({ w = '16px', h = '16px', ...props }: IconProps) => (
  <Icon viewBox="0 0 16 16" w={w} h={h} fill="currentcolor" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m2.688 12.652 1.743-.259.91 1.51c.109.181.304.29.514.29h.036a.599.599 0 0 0 .511-.354l1.452-3.251a5.389 5.389 0 0 1-4.187-2.182l-1.61 3.396a.599.599 0 0 0 .63.85ZM8.148 10.588l1.453 3.251a.599.599 0 0 0 1.06.065l.91-1.51 1.744.259a.6.6 0 0 0 .63-.85l-1.61-3.397a5.388 5.388 0 0 1-4.187 2.182ZM7.996 4a1.2 1.2 0 1 1 .001 2.398 1.2 1.2 0 0 1 0-2.398Zm0 5.397a4.203 4.203 0 0 0 4.198-4.198A4.203 4.203 0 0 0 7.995 1a4.204 4.204 0 0 0-4.198 4.199 4.204 4.204 0 0 0 4.198 4.198Z"
      // fill=""
    />
  </Icon>
);

export default CertifiedIcon;
