import snakeCase from 'lodash/snakeCase';

import {
  FetchSkillLicensesQuery,
  SkillLicenseFields,
  SkillLicenseId,
  SkillLicenseNanoId,
  SkillLicenseName,
  SkillLicenseUser,
  SkillLicenseCreatedAt
} from '../skillLicensesTypes';

export interface FetchSkillLicensesResponse {
  [SkillLicenseFields.ID]: SkillLicenseId;
  [SkillLicenseFields.NANO_ID]: SkillLicenseNanoId;
  [SkillLicenseFields.NAME]: SkillLicenseName;
  [SkillLicenseFields.USER]: SkillLicenseUser;
  [SkillLicenseFields.CREATED_AT]: SkillLicenseCreatedAt;
}

export const fetchSkillLicensesColumns = [
  SkillLicenseFields.ID,
  SkillLicenseFields.NANO_ID,
  SkillLicenseFields.NAME,
  SkillLicenseFields.CREATED_AT
];

const fetchSkillLicensesSBColumns = fetchSkillLicensesColumns.map(
  (fetchSkillLicensesColumn) => snakeCase(fetchSkillLicensesColumn)
);

export const fetchSkillLicensesQuery = `
  ${fetchSkillLicensesSBColumns.join(',')},
  user:user_id(id,nano_id,name,image:image_id(id,nano_id,name,file,key,created_at))
` as FetchSkillLicensesQuery;
