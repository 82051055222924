import { Icon, IconProps } from '@chakra-ui/icon';

const JobsIcon = ({
  w = '24px',
  h = '24px',
  fill = 'gray.500',
  ...props
}: IconProps) => (
  <Icon viewBox="0 0 24 24" w={w} h={h} fill={fill} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 16.253c-.442 0-.8-.783-.8-.372v2.24A2.278 2.278 0 0 0 5.476 20.4H18.52a2.278 2.278 0 0 0 2.278-2.279v-2.24c0-.41-.359.372-.8.372h-6.4a.744.744 0 0 1-.745.744h-1.71a.744.744 0 0 1-.745-.744h-6.4ZM14.4 5.833H9.6V5.09h4.8v.744Zm7.2 2a2 2 0 0 0-2-2H16V4.344c0-.41-.358-.744-.8-.744H8.8c-.44 0-.8.333-.8.744v1.489H4.4a1.998 1.998 0 0 0-2 1.998l.001 3.85a3.083 3.083 0 0 0 3.082 3.084h4.918v-1.489h3.2v1.49l4.968-.034a3.051 3.051 0 0 0 3.031-3.05v-3.85Z"
    />
  </Icon>
);
export default JobsIcon;
