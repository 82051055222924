import { Flex, SystemProps } from '@chakra-ui/react';

import Rating, {
  RatingProps
} from '../../../../../../../../../../helpers/Rating/Rating';
import { Text } from '../../../../../../../../../../helpers/Text';

interface EvaluationProps extends RatingProps {
  label: string;
  value: number;
  smaller?: boolean;
  maxWidth?: SystemProps['maxWidth'];
}

const CandidateEvaluation = ({
  label,
  value,
  smaller,
  maxWidth
}: EvaluationProps) => {
  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      maxWidth={maxWidth}
    >
      <Text
        textStyle={smaller ? 'body2Regular' : 'body1Regular'}
        lineHeight="20px"
      >
        {label}
      </Text>
      <Rating value={value} iconSize={smaller ? 4 : 5} />
    </Flex>
  );
};

export default CandidateEvaluation;
