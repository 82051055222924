import { Icon, IconProps } from '@chakra-ui/icon';

const UserIcon = ({
  w = '64px',
  h = '64px',
  width,
  height,
  ...props
}: IconProps) => (
  <Icon
    viewBox="0 0 64 64"
    fill="none"
    w={w || width}
    h={h || height}
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="M63.7 31.508c0 7.702-2.745 14.709-7.334 20.2a31.408 31.408 0 0 1-24.215 11.307c-9.341 0-17.7-4.056-23.477-10.489a31.546 31.546 0 0 1-7.99-21.018C.685 14.095 14.78 0 32.193 0 49.605 0 63.7 14.095 63.7 31.508Z"
        fill="#E1EAED"
      />
      <path
        d="M56.198 51.278c-5.743 6.82-14.378 11.138-24.072 11.138a31.388 31.388 0 0 1-23.34-10.33c.978-.767 2.2-1.453 3.707-2.059l1.14-.444c2.16-.847 4.97-1.896 8.758-3.47.855-.363 1.548-1.009 1.914-1.856.204-.444.326-.969.326-1.493v-6.981c-.122-.162-.244-.283-.326-.444a6.68 6.68 0 0 1-1.14-3.067l-.611-.283c-1.833.444-1.71-1.493-2.159-5.205-.204-1.534.04-1.856.733-2.098l.57-.767c-3.503-7.87-1.751-12.792 1.711-14.527-1.14-2.502-1.222-3.39-1.222-3.39s7.373 1.21 9.939.726c3.218-.645 8.187.121 10.02 4.358 3.055 1.211 4.277 3.108 4.52 5.206.449 3.309-1.465 6.94-1.832 8.353.04.08.081.121.081.162.49.242.652.645.49 1.977-.449 3.51-.327 5.69-2.16 5.205l-1.629 2.825c-.081.323-.081.444-.122.686-.081.525-.04 1.574-.04 7.344 0 .686.203 1.332.57 1.937.407.606.977 1.13 1.71 1.413 0 0 .041 0 .082.04 4.195 1.735 7.21 2.865 9.409 3.713 1.14.363 2.118.847 2.973 1.331Z"
        fill="#fff"
      />
      <path
        d="M45.065 24.623c.163-.902-.246-2.008-.574-2.786-.041-.123-.123-.246-.164-.41-1.434-2.868-4.589-4.015-7.62-4.18-7.745-.409-8.441 1.066-10.859-1.146.738 1.147.82 2.909-.327 5.121-.82 1.557-2.377 2.254-3.729 2.704-3.523-7.99-1.762-12.988 1.721-14.75-1.147-2.54-1.23-3.441-1.23-3.441s7.417 1.229 9.957.737c3.237-.655 8.236.123 10.08 4.425 3.072 1.23 4.26 3.155 4.547 5.286.451 3.318-1.434 6.965-1.802 8.44Z"
        fill="url(#b)"
      />
      <path
        d="M42.236 45.035v1.393H24.33v-1.803c.205-.45.328-.983.328-1.516v-7.13c-.123-.163-.246-.286-.328-.45v-.41c.369.656.86 1.27 1.475 1.762l5.613 3.892a3.583 3.583 0 0 0 4.548.082l5.244-4.711c.164-.164.37-.328.533-.492-.082.532-.041 1.598-.041 7.457a3.115 3.115 0 0 0 .533 1.925Z"
        fill="url(#c)"
      />
    </g>
    <defs>
      <linearGradient
        id="b"
        x1={19.857}
        y1={15.181}
        x2={46.941}
        y2={15.181}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#97A5A8" />
        <stop offset={1} stopColor="#96A9AE" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={33.289}
        y1={46.496}
        x2={33.289}
        y2={40.267}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#E2E5EC" />
      </linearGradient>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h64v63.015H0z" />
      </clipPath>
    </defs>
  </Icon>
);

export default UserIcon;
