import React, { useCallback } from 'react';
import { GridItem, SimpleGrid, Stack } from '@chakra-ui/react';
import { FieldValues, Path } from 'react-hook-form';
import find from 'lodash/find';

import { ErrorMessage } from '../../../../../../../../../types';

import { UserPhoneNumberLabel } from '../../../../../../../../userPhoneNumbers/userPhoneNumbersTypes';

import { RemoveIcon } from '../../../../../../../../../icons/RemoveIcon';

import { IconButton } from '../../../../../../../../../helpers/buttons/IconButton';
import { InputField } from '../../../../../../../../../helpers/forms/formFields/InputField';
import { SelectField } from '../../../../../../../../../helpers/forms/formFields/SelectField';
import { Text } from '../../../../../../../../../helpers/Text';

import { EditUserFormAddPhoneFieldControl } from './EditUserFormAddPhoneField.types';

const phoneNumberValidationRules = {
  required: 'Phone number is required'
};

const labelValidationRules = {
  required: 'Designation is required'
};

const labelOptions = [
  { value: 'personal', label: 'Mobile Phone' },
  { value: 'work', label: 'Work Phone' },
  { value: 'alt1', label: 'Mobile Phone Alt1' },
  { value: 'alt2', label: 'Mobile Phone Alt2' }
];

interface EditUserFormAddPhoneFieldProps<T extends FieldValues> {
  control: EditUserFormAddPhoneFieldControl<T>;
  name: string;
  index: number;
  withoutRemove?: boolean;
  error?: { [key: string]: { message?: ErrorMessage } };
  labelValue?: UserPhoneNumberLabel;
  onRemove: (index: number) => void;
}

function EditUserFormAddPhoneField<T extends FieldValues>({
  control,
  name,
  index,
  error,
  labelValue,
  withoutRemove,
  onRemove
}: EditUserFormAddPhoneFieldProps<T>) {
  const handleRemove = useCallback<() => void>(
    () => onRemove(index),
    [index, onRemove]
  );

  return (
    <Stack spacing={0.5}>
      <SimpleGrid
        spacing={3}
        alignItems="center"
        columns={{ base: 1, lg: 3 }}
        gridTemplateColumns={'4fr 1fr max-content'}
      >
        <InputField
          id={`${name}.${index}.phoneNumber`}
          {...control.register(
            `${name}.${index}.phoneNumber` as Path<T>,
            phoneNumberValidationRules
          )}
          type="tel"
          isInvalid={!!error?.phoneNumber?.message}
          mask="(***) ***-****"
        />

        <SelectField
          options={labelOptions}
          control={control}
          name={`${name}.${index}.label` as Path<T>}
          rules={labelValidationRules}
          defaultValue={find(labelOptions, { value: labelValue })}
          isInvalid={!!error?.label?.message}
        />

        <IconButton
          size="small"
          value={index}
          aria-label={''}
          hierarchy="link"
          onClick={handleRemove}
          cursor={withoutRemove ? 'not-allowed' : 'pointer'}
          icon={
            <RemoveIcon
              fill={withoutRemove ? 'red.200' : 'red.500'}
              w={5}
              h={5}
            />
          }
        />
      </SimpleGrid>
      <SimpleGrid
        spacing={3}
        alignItems="center"
        columns={{ base: 1, lg: 3 }}
        gridTemplateColumns={'4fr 1fr 32px'}
      >
        <Text color="red.500" textStyle="body1Medium" wordBreak="break-all">
          {error?.phoneNumber?.message}
        </Text>

        <GridItem colSpan={2}>
          <Text color="red.500" textStyle="body1Medium" wordBreak="break-all">
            {error?.label?.message}
          </Text>
        </GridItem>
      </SimpleGrid>
    </Stack>
  );
}

export default EditUserFormAddPhoneField;
