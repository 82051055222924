import snakeCase from 'lodash/snakeCase';

import {
  FetchJobCandidatesQuery,
  JobCandidateFields,
  JobCandidateId,
  JobCandidateNanoId,
  JobCandidateName,
  JobCandidateEmail,
  JobCandidatePhone,
  JobCandidateJobTitle,
  JobCandidatePersonalEmail,
  JobCandidateCompanyPhone,
  MayBeJobCandidateCompanyId,
  MayBeJobCandidateCompany,
  JobCandidateImage,
  JobCandidateState,
  JobCandidateCity,
  JobCandidateCityId,
  JobCandidateJob,
  JobCandidateCreatedAt,
  JobCandidateImageId,
  JobCandidateWorkflowStage,
  JobCandidateSearchType,
  JobCandidateSearchTypeLabel,
  JobCandidateWorkflowStageTask,
  MayBeJobCandidateDisqualifiedAt,
  MayBeJobCandidateWorkflowStageTaskScheduledAt,
  MayBeJobCandidateNewWorkflowStageTaskAt,
  MayBeJobCandidateViewedAt,
  JobCandidateWorkflowStageTaskApplicationType,
  MayBeJobCandidateDueDate,
  JobCandidateUser,
  JobCandidateDisqualificationReason,
  JobCandidateStatus,
  JobCandidateCityIds,
  JobCandidateCities,
  JobCandidateTalentPools,
  JobCandidateFirstName,
  JobCandidateLastName,
  JobCandidateCompanyEmail,
  JobCandidateLinkedinUrl,
  JobCandidateLikedJobCandidateIds,
  JobCandidateLikedJobCandidates,
  JobCandidateCandidateShareIds,
  JobCandidateCandidateShares
} from '../jobCandidatesTypes';

import { FetchJobCandidatesApiQuery } from '../../../api/jobCandidates/jobCandidatesApiTypes';

export interface FetchJobCandidatesResponse {
  [JobCandidateFields.ID]: JobCandidateId;
  [JobCandidateFields.NANO_ID]: JobCandidateNanoId;
  [JobCandidateFields.NAME]: JobCandidateName;
  [JobCandidateFields.FIRST_NAME]: JobCandidateFirstName;
  [JobCandidateFields.LAST_NAME]: JobCandidateLastName;
  [JobCandidateFields.EMAIL]: JobCandidateEmail;
  [JobCandidateFields.PHONE]: JobCandidatePhone;
  [JobCandidateFields.LINKEDIN_URL]: JobCandidateLinkedinUrl;
  [JobCandidateFields.JOB_TITLE]: JobCandidateJobTitle;
  [JobCandidateFields.PERSONAL_EMAIL]: JobCandidatePersonalEmail;
  [JobCandidateFields.COMPANY_PHONE]: JobCandidateCompanyPhone;
  [JobCandidateFields.COMPANY_EMAIL]: JobCandidateCompanyEmail;
  [JobCandidateFields.COMPANY_ID]: MayBeJobCandidateCompanyId;
  [JobCandidateFields.COMPANY]: MayBeJobCandidateCompany;
  [JobCandidateFields.IMAGE_ID]: JobCandidateImageId;
  [JobCandidateFields.IMAGE]: JobCandidateImage;
  [JobCandidateFields.CITY]: JobCandidateCity;
  [JobCandidateFields.CITY_ID]: JobCandidateCityId;
  [JobCandidateFields.STATE]: JobCandidateState;
  [JobCandidateFields.JOB]: JobCandidateJob;
  [JobCandidateFields.CREATED_AT]: JobCandidateCreatedAt;
  [JobCandidateFields.USER]: JobCandidateUser;
  [JobCandidateFields.WORKFLOW_STAGE]: JobCandidateWorkflowStage;
  [JobCandidateFields.SEARCH_TYPE]: JobCandidateSearchType;
  [JobCandidateFields.SEARCH_TYPE_LABEL]: JobCandidateSearchTypeLabel;
  [JobCandidateFields.WORKFLOW_STAGE_TASK]: JobCandidateWorkflowStageTask;
  [JobCandidateFields.DISQUALIFIED_AT]: MayBeJobCandidateDisqualifiedAt;
  [JobCandidateFields.DISQUALIFICATION_REASON]: JobCandidateDisqualificationReason;
  [JobCandidateFields.WORKFLOW_STAGE_TASK_SCHEDULED_AT]: MayBeJobCandidateWorkflowStageTaskScheduledAt;
  [JobCandidateFields.NEW_WORKFLOW_STAGE_TASK_AT]: MayBeJobCandidateNewWorkflowStageTaskAt;
  [JobCandidateFields.VIEWED_AT]: MayBeJobCandidateViewedAt;
  [JobCandidateFields.WORKFLOW_STAGE_TASK_APPLICATION_TYPE]: JobCandidateWorkflowStageTaskApplicationType;
  [JobCandidateFields.DUE_DATE]: MayBeJobCandidateDueDate;
  [JobCandidateFields.STATUS]: JobCandidateStatus;
  [JobCandidateFields.CITY_IDS]: JobCandidateCityIds;
  [JobCandidateFields.CITIES]: JobCandidateCities;
  [JobCandidateFields.TALENT_POOLS]: JobCandidateTalentPools;
  [JobCandidateFields.LIKED_JOB_CANDIDATE_IDS]: JobCandidateLikedJobCandidateIds;
  [JobCandidateFields.LIKED_JOB_CANDIDATES]: JobCandidateLikedJobCandidates;
  [JobCandidateFields.CANDIDATE_SHARE_IDS]: JobCandidateCandidateShareIds;
  [JobCandidateFields.CANDIDATE_SHARES]: JobCandidateCandidateShares;
  [JobCandidateFields.CANDIDATE_SUBMISSION_IDS]: JobCandidateCandidateShareIds;
  [JobCandidateFields.CANDIDATE_SUBMISSIONS]: JobCandidateCandidateShares;
}

export const fetchJobCandidatesColumns = [
  JobCandidateFields.ID,
  JobCandidateFields.NANO_ID,
  JobCandidateFields.NAME,
  JobCandidateFields.FIRST_NAME,
  JobCandidateFields.LAST_NAME,
  JobCandidateFields.EMAIL,
  JobCandidateFields.PHONE,
  JobCandidateFields.JOB_TITLE,
  JobCandidateFields.PERSONAL_EMAIL,
  JobCandidateFields.COMPANY_PHONE,
  JobCandidateFields.COMPANY_EMAIL,
  JobCandidateFields.COMPANY_ID,
  JobCandidateFields.CITY_ID,
  JobCandidateFields.IMAGE_ID,
  JobCandidateFields.CREATED_AT,
  JobCandidateFields.SEARCH_TYPE,
  JobCandidateFields.SEARCH_TYPE_LABEL,
  JobCandidateFields.DISQUALIFIED_AT,
  JobCandidateFields.DISQUALIFICATION_REASON,
  JobCandidateFields.WORKFLOW_STAGE_TASK_SCHEDULED_AT,
  JobCandidateFields.NEW_WORKFLOW_STAGE_TASK_AT,
  JobCandidateFields.VIEWED_AT,
  JobCandidateFields.WORKFLOW_STAGE_TASK_APPLICATION_TYPE,
  JobCandidateFields.DUE_DATE,
  JobCandidateFields.STATUS
];

const fetchJobCandidatesSBColumns = fetchJobCandidatesColumns.map(
  (fetchJobCandidatesColumn) => snakeCase(fetchJobCandidatesColumn)
);

export const fetchJobCandidatesQuery = `
  ${fetchJobCandidatesSBColumns.join(',')},
  company:company_id(id,nano_id,name,created_at,image:image_id(id,nano_id,name,file,key,created_at)),
  state:state_id(id,nano_id,name,iso_code),
  city:city_id(id,nano_id,name,label),
  image:image_id(id,nano_id,name,file,key,created_at),
  job:job_id(id,nano_id,name),
  workflow_stage_task:workflow_stage_task_id(id,nano_id,name,arrangement,application,due_date),
  workflow_stage:workflow_stage_id(id,nano_id,name,arrangement,color,workflow_stage_tasks!fk_rails_bd60de3db2(id,nano_id,name,arrangement,application,due_date)),
  user:user_id(id,name,image:image_id(id,nano_id,name,file,created_at)),
  talent_pools:talent_pool_job_candidates(talent_pool_id,created_at)
` as FetchJobCandidatesQuery;

export const fetchJobCandidatesApiQuery = fetchJobCandidatesSBColumns.join(
  ','
) as FetchJobCandidatesApiQuery;
