import { useCallback, useRef } from 'react';
import { FetchJobResponse } from '../../../../../../../jobs/queries/fetchJob.query';
import { CreateJobRequestData } from '../../../../../../../jobs/JobsRequests';
import { PostJobDetailsForm } from '../../../../../../../jobs/pages/PostJobDetailsPage/components/PostJobDetailsForm';

interface AddControlPanelJobDetailsProps {
  setJob: (job: FetchJobResponse) => void;
  closeModal: () => void;
  goToIntake: () => void;
}

const AddControlPanelJobDetails = ({
  setJob,
  closeModal,
  goToIntake
}: AddControlPanelJobDetailsProps) => {
  const formRef = useRef<HTMLFormElement>(null);

  const handleJobDetailsPosted = useCallback<
    (job?: CreateJobRequestData) => void
  >(
    (job) => {
      setJob(job as unknown as FetchJobResponse);
      goToIntake();
    },
    [setJob, goToIntake]
  );

  return (
    <PostJobDetailsForm
      ref={formRef}
      onJobDetailsPosted={handleJobDetailsPosted}
      onCancel={closeModal}
    />
  );
};

export default AddControlPanelJobDetails;
