import { Fragment, useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { IconButton } from '@chakra-ui/button';
import { FormControl } from '@chakra-ui/form-control';
import {
  Box,
  Divider,
  Flex,
  HStack,
  Stack,
  StackDivider
} from '@chakra-ui/layout';
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/menu';
import { Switch } from '@chakra-ui/switch';

import { ItemNanoId } from '../../../../types';

import { CompanyNanoId } from '../../../../main/companies/companiesTypes';

import { useLeftSidebarContext } from '../../../../context';

import { useCurrentUser } from '../../../../auth/hooks/useAuth';
import { useUpdateCompany } from '../../../../main/users/hooks/useUpdateCompany';

import { AuthCache } from '../../../../auth/AuthCache';

import { PlusIcon } from '../../../../icons/PlusIcon';

import { AlertMessage } from '../../../../helpers/AlertMessage';
import { HeaderSearch } from '../../../../helpers/HeaderSearch';
import { NotificationsDropdown } from '../../../../helpers/NotificationsDropdown';
import { StaticSelect } from '../../helpers/StaticSelect';
import { Text } from '../../../../helpers/Text';

import { HeaderAccountDropdownMenu } from '../HeaderAccountDropdownMenu';

import { HeaderRequiredProps } from './Header.types';
import { CandidatesRoutes } from '../../../../main/control-panel/pages/People/CandidatesRoutes';
import { HiringPortalRoutes } from '../../../../main/hiringPortal/HiringPortalRoutes';
import { JobsRoutes } from '../../../../main/jobs/JobsRoutes';
import { Portal } from '@chakra-ui/react';

function Header({
  onSignOut,
  headerMenuItems,
  totalTalentCredits
}: HeaderRequiredProps) {
  const { dockSidebar, isDocked } = useLeftSidebarContext();
  const [hasMounted, setHasMounted] = useState(false);

  const router = useRouter();

  const currentUser = useCurrentUser();

  const { updateCompany, updateCompanyIsLoading, updateCompanyErrorMessage } =
    useUpdateCompany({
      userId: currentUser?.id,
      cacheKeys: [AuthCache.currentUserKey()]
    });

  const handleUpdateCompany = useCallback<
    (value: { nanoId: ItemNanoId } | null) => void
  >(
    (value) =>
      value && updateCompany({ companyNanoId: value.nanoId as CompanyNanoId }),
    [updateCompany]
  );

  const menuItems = headerMenuItems || [
    { title: 'Add Candidate', link: CandidatesRoutes.add() },
    { title: 'Add Company', link: HiringPortalRoutes.index() },
    { title: 'Add Job', link: JobsRoutes.post() },
    { title: 'Add Department' }
  ];

  useEffect(() => {
    setHasMounted(true);
  }, []);

  return (
    <Fragment>
      <Flex
        py={4}
        h="72px"
        bg="white"
        alignItems="center"
        pl={4}
        pr={6}
        position="relative"
        gap={4}
        zIndex={99}
      >
        <Stack spacing={6} direction="row" alignItems="center">
          {hasMounted && (
            <Switch
              w="24px"
              variant="custom"
              height="24px"
              defaultChecked={!isDocked}
              onChange={() => dockSidebar(!isDocked)}
            />
          )}

          <HStack spacing={3}>
            <Flex w={{ lg: '32', xl: '64' }}>
              <FormControl>
                {hasMounted && currentUser ? (
                  <>
                    <StaticSelect
                      isLoading={updateCompanyIsLoading}
                      isDisabled={updateCompanyIsLoading}
                      currentItem={currentUser.company}
                      options={(currentUser.companyMembers || []).map(
                        (companyMember) => companyMember.company
                      )}
                      onChange={handleUpdateCompany}
                    />
                    <AlertMessage message={updateCompanyErrorMessage} />
                  </>
                ) : null}
              </FormControl>
            </Flex>
            {isNaN(totalTalentCredits as number) ? null : (
              <HStack spacing={2}>
                <Box
                  height="fit-content"
                  py={0}
                  px={1}
                  borderRadius="base"
                  bg="#EEEEF0"
                >
                  <Text textStyle="body2Medium" color="gray.900">
                    {totalTalentCredits}
                  </Text>
                </Box>
                <Text textStyle="body2Medium" color="primary.500">
                  Credit{Number(totalTalentCredits) > 1 ? 's' : ''}
                </Text>
              </HStack>
            )}
          </HStack>
        </Stack>

        <Stack ml="auto" direction="row" alignItems="center" spacing={6}>
          {hasMounted && (
            <Box w="100%" display={{ base: 'none', lg: 'block' }}>
              <HeaderSearch />
            </Box>
          )}

          <Stack
            ml="auto"
            direction="row"
            alignItems="center"
            spacing={6}
            divider={<StackDivider borderColor="gray.200" />}
          >
            <HeaderAccountDropdownMenu
              currentUser={currentUser}
              onSignOut={onSignOut}
            />

            <Stack direction="row" spacing={6}>
              <NotificationsDropdown />

              <Box>
                <Menu isLazy>
                  <MenuButton
                    as={IconButton}
                    aria-label="Add"
                    borderRadius="full"
                  >
                    <PlusIcon />
                  </MenuButton>
                  <Portal>
                    <MenuList>
                      {menuItems?.map(({ title, link }, index) => (
                        <MenuItem
                          key={index}
                          onClick={() => link && router.push(link)}
                          textTransform="capitalize"
                        >
                          {title}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Portal>
                </Menu>
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </Flex>

      <Divider />
    </Fragment>
  );
}

export default Header;
