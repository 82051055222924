import { Icon, IconProps } from '@chakra-ui/icon';

const DashboardIcon = ({
  w = '24px',
  h = '24px',
  fill = 'gray.500',
  ...props
}: IconProps) => (
  <Icon viewBox="0 0 24 24" w={w} h={h} fill={fill} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.75 3h-6a.75.75 0 0 0-.75.75v6c0 .415.336.75.75.75h6a.75.75 0 0 0 .75-.75v-6A.75.75 0 0 0 9.75 3ZM20.25 3h-6a.75.75 0 0 0-.75.75v6c0 .415.336.75.75.75h6a.75.75 0 0 0 .75-.75v-6a.75.75 0 0 0-.75-.75ZM9.75 13.5h-6a.75.75 0 0 0-.75.75v6c0 .415.336.75.75.75h6a.75.75 0 0 0 .75-.75v-6a.75.75 0 0 0-.75-.75ZM20.25 13.5h-6a.75.75 0 0 0-.75.75v6c0 .415.336.75.75.75h6a.75.75 0 0 0 .75-.75v-6a.75.75 0 0 0-.75-.75Z"
    />
  </Icon>
);
export default DashboardIcon;
