import React, { Fragment, ReactNode } from 'react';
import { Stack } from '@chakra-ui/layout';
import { Skeleton } from '@chakra-ui/skeleton';
import range from 'lodash/range';

interface LoadingSkeletonProps {
  loaded: boolean;
  children?: ReactNode;
  count?: number;
}

function LoadingSkeleton({
  loaded,
  children,
  count = 7
}: LoadingSkeletonProps) {
  return (
    <Fragment>
      {loaded ? (
        children
      ) : (
        <Stack width="100%">
          {range(0, count).map((index) => (
            <Skeleton key={`loading-skeleton-${index}`} height="20px" />
          ))}
        </Stack>
      )}
    </Fragment>
  );
}

export default LoadingSkeleton;
