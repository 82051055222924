import { useCallback, useState } from 'react';
import { signIn as nextAuthSignIn } from 'next-auth/react';

import { SuccessMessage } from '../../../types';

import { AuthRoutes } from '../../AuthRoutes';

function useSignIn(onSignInWithEmail?: (email: string) => void) {
  const [signInSuccessMessage, setSignInSuccessMessage] =
    useState<SuccessMessage>(undefined);
  const [singInIsLoading, setSignInIsLoading] = useState(false);

  const signInWithEmail = useCallback<
    ({ email }: { email: string }, options?: { callbackUrl?: string }) => void
  >(
    async ({ email }, options = {}) => {
      setSignInIsLoading(true);
      await nextAuthSignIn('email', {
        email,
        redirect: false,
        callbackUrl: options.callbackUrl || AuthRoutes.welcomeBack()
      });

      setSignInIsLoading(false);
      setSignInSuccessMessage(
        'Sign in link has been sent to your email. Please, check your email and follow the instructions.'
      );

      onSignInWithEmail?.(email);
    },
    [onSignInWithEmail]
  );

  return {
    signInSuccessMessage,
    signInWithEmail,
    singInIsLoading,
    signInWithMicrosoft: (
      options: { callbackUrl?: string } = {
        callbackUrl: AuthRoutes.welcomeBack()
      }
    ) => nextAuthSignIn('azure-ad', options),
    signInWithGoogle: (
      options: { callbackUrl?: string } = {
        callbackUrl: AuthRoutes.welcomeBack()
      }
    ) => nextAuthSignIn('google', options),
    signInWithLinkedIn: (
      options: { callbackUrl?: string } = {
        callbackUrl: AuthRoutes.welcomeBack()
      }
    ) => nextAuthSignIn('linkedin', options)
  };
}

export default useSignIn;
