import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState
} from 'react';

type colorScheme = 'light' | 'dark';

interface LeftSidebarContextProps {
  colorScheme?: colorScheme;
  isDocked?: boolean;
  dockSidebar: (status: boolean) => void;
  setSidebarColor: (color: colorScheme) => void;
}

const LeftSidebarContext = createContext<LeftSidebarContextProps>({
  colorScheme: 'light',
  isDocked: true,
  setSidebarColor: () => {
    /* do nothing */
  },
  dockSidebar: () => {
    /* do nothing */
  }
});

export const LeftSidebarProvider = ({
  children
}: {
  children: ReactNode;
}): JSX.Element => {
  const [isDocked, setIsDocked] = useState<boolean>();
  const [colorScheme, setColorScheme] = useState<colorScheme>('light');

  const toggleDock = (status: boolean) => {
    setIsDocked(status);
    localStorage.setItem('isSidebarDocked', `${status}`);
  };

  useEffect(() => {
    const existingDockStatus = JSON.parse(
      localStorage.getItem('isSidebarDocked') || 'false'
    );
    setIsDocked(Boolean(existingDockStatus));
  }, []);

  const setSidebarColor = (color: colorScheme) => {
    setColorScheme(color);
  };

  return (
    <LeftSidebarContext.Provider
      value={{
        colorScheme,
        isDocked,
        dockSidebar: toggleDock,
        setSidebarColor
      }}
    >
      {children}
    </LeftSidebarContext.Provider>
  );
};

export const useLeftSidebarContext = () => useContext(LeftSidebarContext);
