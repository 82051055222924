import React from 'react';
import { Flex } from '@chakra-ui/layout';

import { IndustryName } from '../../industriesTypes';

import { PlusIcon } from '../../../../icons/PlusIcon';

import { Button } from '../../../../helpers/Button';
import { Text } from '../../../../helpers/Text';
import { Spinner } from '@chakra-ui/react';

interface AddIndustryExpertiseProps {
  name: IndustryName;
  isDisabled?: boolean;
  isSelected: boolean;
  isRequired?: boolean;
  withoutPlusButton?: boolean;
  onSelectAction?: () => void;
  isLoading?: boolean;
}

function AddIndustryExpertise({
  name,
  isDisabled,
  isSelected,
  isRequired,
  withoutPlusButton,
  onSelectAction,
  isLoading
}: AddIndustryExpertiseProps) {
  const defaultBorderColor = isRequired ? 'red.500' : 'gray.200';
  return (
    <Button
      p={0}
      height={6}
      display="flex"
      borderRadius={4}
      hierarchy="unstyled"
      border="1px solid"
      borderColor={isSelected ? 'primary.500' : defaultBorderColor}
      color={isSelected ? 'white' : 'gray.600'}
      bg={isSelected ? 'primary.500' : 'white'}
      isDisabled={isDisabled}
      onClick={onSelectAction}
    >
      <Text px={2} fontSize="14px" lineHeight="22px">
        {name}
      </Text>

      {withoutPlusButton ? null : (
        <Flex
          w={6}
          h={6}
          alignItems="center"
          justifyContent="center"
          borderLeft="1px solid"
          borderColor={isSelected ? 'white' : 'gray.200'}
        >
          {isLoading ? (
            <Spinner w={4} h={4} color={'gray.500'} />
          ) : (
            <PlusIcon w={4} h={4} color={isSelected ? 'white' : 'gray.500'} />
          )}
        </Flex>
      )}
    </Button>
  );
}

export default AddIndustryExpertise;
