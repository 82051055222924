import {
  FetchImagesBffURL,
  FetchImageBffURL,
  CreateImageBffURL,
  ImageItemBffID
} from './imagesTypes';

import { BaseBffRoutes } from '../BaseBffRoutes';

export class ImagesBffRoutes extends BaseBffRoutes {
  protected static route = 'images';

  static fetchImagesRoute() {
    return `${this.basePath}/${this.route}` as FetchImagesBffURL;
  }

  static fetchImageRoute(imageItemBffID: ImageItemBffID) {
    return `${this.basePath}/${this.route}/${imageItemBffID}` as FetchImageBffURL;
  }

  static createImageRoute() {
    return `${this.basePath}/${this.route}/create` as CreateImageBffURL;
  }
}
