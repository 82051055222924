import { usePaginatedItems } from '../../../common/hooks/usePaginatedItems';

import {
  FetchCitiesQuery,
  FetchCitiesCacheKey,
  FetchCitiesTotalCount,
  FetchCitiesPage,
  FetchCitiesPageSize,
  FetchCitiesError,
  FetchCitiesErrorMessage,
  FetchCitiesIsLoading,
  FetchCitiesIsFetched,
  FetchCitiesIsPlaceholderData,
  FetchCitiesEnabled,
  FetchCitiesPrefetchNextPage,
  FetchCitiesRefetchInterval,
  FetchCitiesCountType,
  FetchCitiesFilters,
  FetchCitiesSort,
  FetchCityID,
  FetchCityQuery,
  FetchCityCacheKey
} from '../../citiesTypes';

import { CitiesBffRequests } from '../../CitiesBffRequests';

interface PaginatedCitiesBaseOptions<PaginatedCitiesResponseType> {
  cacheKey: FetchCitiesCacheKey;
  query: FetchCitiesQuery;
  countType?: FetchCitiesCountType;
  initialPage?: FetchCitiesPage;
  initialPageSize?: FetchCitiesPageSize;
  initialFilters?: FetchCitiesFilters;
  initialSort?: FetchCitiesSort;
  enabled?: FetchCitiesEnabled;
  prefetchNextPage?: FetchCitiesPrefetchNextPage;
  refetchInterval?: FetchCitiesRefetchInterval;
  placeholderCities?: PaginatedCitiesResponseType[];
  placeholderCitiesCount?: FetchCitiesTotalCount;
  placeholderData?: () => PaginatedCitiesResponse<PaginatedCitiesResponseType>;
}

interface PaginatedCitiesOptionsWithoutItem {
  fetchItemCacheKey?: never;
  itemQuery?: never;
}

interface PaginatedCitiesOptionsWithItem {
  fetchItemCacheKey: FetchCityCacheKey;
  itemQuery: FetchCityQuery;
}

type PaginatedCitiesOptions<PaginatedCitiesResponseType> =
  PaginatedCitiesBaseOptions<PaginatedCitiesResponseType> &
    (PaginatedCitiesOptionsWithoutItem | PaginatedCitiesOptionsWithItem);

interface PaginatedCitiesResponse<PaginatedCitiesResponseType> {
  data: PaginatedCitiesResponseType[];
  totalCount: FetchCitiesTotalCount;
}

function usePaginatedCities<PaginatedCitiesResponseType>({
  cacheKey,
  query,
  countType,
  initialPage,
  initialPageSize,
  initialFilters,
  initialSort,
  enabled,
  prefetchNextPage,
  refetchInterval,
  placeholderCities,
  placeholderCitiesCount,
  placeholderData,
  fetchItemCacheKey,
  itemQuery
}: PaginatedCitiesOptions<PaginatedCitiesResponseType>) {
  const {
    items,
    itemsTotalCount,
    itemsError,
    itemsErrorMessage,
    itemsIsFetched,
    itemsIsLoading,
    itemsIsPlaceholderData,
    itemsCurrentPage,
    itemsCurrentPageSize,
    itemsCurrentFilters,
    itemsCurrentSort,
    fetchItems,
    paginateItems,
    changeItemsPageSize,
    filterItems,
    changeItemsFilters,
    clearItemsFilters,
    sortItems,
    prefetchItems,
    prefetchItem
  } = usePaginatedItems<PaginatedCitiesResponseType>({
    cacheKey,
    initialPage,
    initialPageSize,
    initialFilters,
    initialSort,
    enabled,
    prefetchNextPage,
    refetchInterval,
    placeholderItems: placeholderCities,
    placeholderItemsCount: placeholderCitiesCount,
    placeholderData,
    queryFn: (params) =>
      CitiesBffRequests.fetchCities<PaginatedCitiesResponseType>({
        query,
        countType,
        page: params.page as FetchCitiesPage,
        pageSize: params.pageSize as FetchCitiesPageSize,
        filters: params.filters as FetchCitiesFilters,
        sort: params.sort as FetchCitiesSort
      }),
    ...(itemQuery
      ? {
          fetchItemCacheKey,
          queryItemFn: (itemId) =>
            CitiesBffRequests.fetchCity(itemId as FetchCityID, {
              query: itemQuery
            })
        }
      : {})
  });

  return {
    cities: items as PaginatedCitiesResponseType[],
    citiesTotalCount: itemsTotalCount as FetchCitiesTotalCount,
    citiesError: itemsError as FetchCitiesError,
    citiesErrorMessage: itemsErrorMessage as FetchCitiesErrorMessage,
    citiesIsLoading: itemsIsLoading as FetchCitiesIsLoading,
    citiesIsFetched: itemsIsFetched as FetchCitiesIsFetched,
    citiesIsPlaceholderData:
      itemsIsPlaceholderData as FetchCitiesIsPlaceholderData,
    citiesCurrentPage: itemsCurrentPage as FetchCitiesPage,
    citiesCurrentPageSize: itemsCurrentPageSize as FetchCitiesPageSize,
    citiesCurrentFilters: itemsCurrentFilters as FetchCitiesFilters,
    citiesCurrentSort: itemsCurrentSort as FetchCitiesSort,
    fetchCities: fetchItems,
    paginateCities: paginateItems,
    changeCitiesPageSize: changeItemsPageSize,
    filterCities: filterItems,
    changeCitiesFilters: changeItemsFilters,
    clearCitiesFilters: clearItemsFilters,
    sortCities: sortItems,
    prefetchCities: prefetchItems,
    prefetchCity: prefetchItem
  };
}

export default usePaginatedCities;
