import {
  IconButton as ChakraIconButton,
  IconButtonProps as ChakraIconButtonProps
} from '@chakra-ui/button';
import React, { ForwardedRef } from 'react';

import { mapSize } from '../../../utils/mapSize';

interface ButtonOptions extends ChakraIconButtonProps {
  hierarchy?:
    | 'primary'
    | 'danger'
    | 'warning'
    | 'secondary'
    | 'tertiary'
    | 'solid'
    | 'ghost'
    | 'link'
    | 'outline'
    | 'unstyled';
  size?: 'extra-small' | 'small' | 'medium' | 'large';
}

export type IconButtonProps = Omit<ButtonOptions, 'variant'>;

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    { size = 'medium', children, hierarchy, ...props }: IconButtonProps,
    ref: ForwardedRef<HTMLButtonElement>
  ) => (
    <ChakraIconButton
      ref={ref}
      {...props}
      variant={hierarchy}
      size={mapSize(size)}
    >
      {children}
    </ChakraIconButton>
  )
);

IconButton.displayName = 'IconButton';

export default IconButton;
