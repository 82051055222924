import { Icon, IconProps } from '@chakra-ui/icon';

const ShareIcon = ({
  w = '24px',
  h = '24px',
  fill = 'gray.500',
  ...props
}: IconProps) => (
  <Icon viewBox="0 0 24 24" w={w} h={h} fill={fill} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 14.22v4.988l8-8.104L13 3v4.987c-3.538.156-10 1.636-10 10.13V21l1.462-2.494C6.308 15.313 8.154 14.3 13 14.221"
    />
  </Icon>
);
export default ShareIcon;
