import { Flex, HStack, Stack, useDisclosure } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { Heading } from '../../../../../../../../helpers/Heading';
import { Text } from '../../../../../../../../helpers/Text';
import { CheckIcon } from '../../../../../../../../icons/CheckIcon';
import { ViewJobCandidateDetailsCandidateUpdatedLabel } from '../../../ViewJobCandidateDetailsCandidateUpdatedLabel';
import { useViewJobCandidateDetailsSubmissionContext } from '../../../../contexts/ViewJobCandidateDetailsSubmissionContext';
import {
  CandidateSubmissionShareSettingsField,
  CandidateSubmissionShareSettingsFields
} from '../../../../../../../candidateSubmissions/candidateSubmissionsTypes';

function ViewJobCandidateDetailsProfileOverviewPreferences() {
  const {
    isOpen: isHovered,
    onOpen: onMouseHover,
    onClose: onMouseLeave
  } = useDisclosure();

  const { sharedFields } = useViewJobCandidateDetailsSubmissionContext();

  return (
    <Stack
      p={4}
      gap={3}
      flex={1}
      bg="white"
      borderRadius="base"
      onMouseOver={onMouseHover}
      onMouseLeave={onMouseLeave}
    >
      <Flex gap={2} alignItems="center">
        <Heading
          m={0}
          p={0}
          level="h4"
          fontSize="md"
          lineHeight={1}
          fontWeight="semibold"
        >
          Preferences
        </Heading>

        <ViewJobCandidateDetailsCandidateUpdatedLabel
          isHovered={isHovered}
          date="Yesterday"
        />
      </Flex>

      <Stack spacing={3} color="gray.600">
        {sharedFields.includes(
          CandidateSubmissionShareSettingsFields.AssessmentPreferencesEmployment as CandidateSubmissionShareSettingsField
        ) && (
          <OverviewPreferenceItem
            isMatch
            label="employment"
            value="Fulltime, Remote/ Fulltime, Onsite, Contract"
          />
        )}

        {sharedFields.includes(
          CandidateSubmissionShareSettingsFields.AssessmentPreferencesWorkplace as CandidateSubmissionShareSettingsField
        ) && <OverviewPreferenceItem label="Workplace type" value="Onsite" />}

        {sharedFields.includes(
          CandidateSubmissionShareSettingsFields.AssessmentPreferencesPreferredLocation as CandidateSubmissionShareSettingsField
        ) && (
          <OverviewPreferenceItem
            isMatch
            label="preferred locations"
            value="Remote/ Tampa,FL/ Miami, FL"
          />
        )}

        {sharedFields.includes(
          CandidateSubmissionShareSettingsFields.AssessmentPreferencesSalary as CandidateSubmissionShareSettingsField
        ) && (
          <OverviewPreferenceItem
            label="Salary"
            value="$ 120,000 - $ 150,000 PA"
          />
        )}
      </Stack>
    </Stack>
  );
}

export default ViewJobCandidateDetailsProfileOverviewPreferences;

interface OverviewPreferenceItem {
  label: string;
  value: ReactNode;
  isMatch?: boolean;
}

const OverviewPreferenceItem = ({
  label,
  value,
  isMatch
}: OverviewPreferenceItem) => (
  <Stack>
    <HStack>
      <Text
        noOfLines={1}
        color="gray.800"
        wordBreak="break-all"
        textStyle="upperCase1Medium"
      >
        {label}
      </Text>

      {isMatch && <CheckIcon w={4} h={4} fill="green.600" />}
    </HStack>

    <Text textStyle="body1Regular" lineHeight={1.5}>
      {value}
    </Text>
  </Stack>
);
