import { Flex, HStack, useDisclosure } from '@chakra-ui/react';
import lowerCase from 'lodash/lowerCase';
import startCase from 'lodash/startCase';
import { AvatarHelper } from '../../../../../../helpers/AvatarHelper';
import { Heading } from '../../../../../../helpers/Heading';
import JobsCandidateNxmrHelper from '../../../../../jobCandidates/helpers/JobCandidateNxmrHelper/JobCandidateNxmrHelper';
import {
  JobCandidateId,
  JobCandidateImage,
  JobCandidateJobTitle
} from '../../../../../jobCandidates/jobCandidatesTypes';

interface ViewJobCandidateDetailsTalentPoolProps {
  jobCandidate: {
    id: JobCandidateId;
    image: JobCandidateImage;
    jobTitle: JobCandidateJobTitle;
  };
  viewJobCandidate: (jobCandidateId: JobCandidateId) => void;
}

function ViewJobCandidateDetailsTalentPool({
  jobCandidate,
  viewJobCandidate
}: ViewJobCandidateDetailsTalentPoolProps) {
  const title = startCase(lowerCase(jobCandidate.jobTitle));

  const {
    isOpen: isHovered,
    onOpen: onMouseHover,
    onClose: onMouseLeave
  } = useDisclosure();

  return (
    <Flex
      p={2}
      gap={2}
      as="button"
      textAlign="left"
      alignItems="center"
      onMouseOver={onMouseHover}
      onMouseLeave={onMouseLeave}
      onClick={() => viewJobCandidate(jobCandidate.id)}
      bg={isHovered ? 'gray.200' : 'transparent'}
    >
      <AvatarHelper size="sm" image={jobCandidate.image} />

      <Flex gap={1} flex={1} flexDir="column">
        <Heading
          m={0}
          p={0}
          level="h4"
          noOfLines={2}
          fontSize="sm"
          lineHeight={1.25}
          fontWeight="medium"
        >
          {title}
        </Heading>

        <HStack spacing={2.5}>
          <JobsCandidateNxmrHelper value={5} />
        </HStack>
      </Flex>
    </Flex>
  );
}

export default ViewJobCandidateDetailsTalentPool;
