import join from 'lodash/join';
import lowerCase from 'lodash/lowerCase';
import startCase from 'lodash/startCase';

import { RenderLinkedinUrlProps } from './renderLinkedinUrl.types';

function renderLinkedinUrl({ item, companyItem }: RenderLinkedinUrlProps) {
  const { name, firstName, lastName, company } = item || {};
  const userName = startCase(
    lowerCase(
      firstName && lastName ? join([firstName, lastName], ' ') : name || ''
    )
  );

  const searchLinkedinUrl = companyItem
    ? `https://linkedin.com/search/results/companies/?keywords=${companyItem?.name}&origin=SWITCH_SEARCH_VERTICAL`
    : `https://linkedin.com/search/results/all/?keywords=${userName}${
        company?.name ? `%20in%20${company?.name}` : ''
      }&origin=GLOBAL_SEARCH_HEADER`;

  const linkedinUrl = companyItem?.linkedinUrl || item?.linkedinUrl;

  return linkedinUrl || searchLinkedinUrl;
}

export default renderLinkedinUrl;
