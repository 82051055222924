import React from 'react';
import {
  Button,
  ButtonProps
} from '../../../../../../../../../../../../../helpers/Button';
import { ViewJobCandidateModalPhoneSidebarConnectedType } from '../../ViewJobCandidateModalPhoneSidebarConnectedHelper';

interface ViewJobCandidateModalPhoneSidebarConnectedSecondaryButtonProps
  extends ButtonProps {
  label: string;
  value: ViewJobCandidateModalPhoneSidebarConnectedType;
  selectedOption?: ViewJobCandidateModalPhoneSidebarConnectedType | null;
}

const ViewJobCandidateModalPhoneSidebarConnectedSecondaryButton = ({
  label,
  value,
  selectedOption,
  ...props
}: ViewJobCandidateModalPhoneSidebarConnectedSecondaryButtonProps) => {
  return (
    <Button
      size="medium"
      value={value}
      paddingLeft={2}
      paddingRight={2}
      hierarchy="tertiary"
      bg={selectedOption === value ? '#FDF6E6' : 'white'}
      color={selectedOption === value ? '#EEA401' : 'gray.900'}
      borderColor={selectedOption === value ? '#EEA401' : 'gray.300'}
      _hover={{
        color: '#EEA401',
        borderColor: '#EEA401',
        bg: selectedOption === 'schedule' ? '#FDF6E6' : 'white'
      }}
      {...props}
    >
      {label}
    </Button>
  );
};

export default ViewJobCandidateModalPhoneSidebarConnectedSecondaryButton;
