import {
  FetchCandidateSharesQuery,
  FetchCandidateSharesCountType,
  FetchCandidateSharesPage,
  FetchCandidateSharesPageSize,
  FetchCandidateSharesFilters,
  FetchCandidateSharesSort,
  FetchCandidateSharesIndexQueryName,
  FetchCandidateSharesIndexCountType,
  FetchCandidateSharesIndexPage,
  FetchCandidateSharesIndexPageSize,
  FetchCandidateSharesIndexFilters,
  FetchCandidateSharesIndexSort,
  FetchCandidateShareQuery,
  FetchCandidateShareIndexQueryName,
  CreateCandidateShareError,
  DeleteCandidateShareError,
  CandidateShareId,
  CandidateShareNanoId,
  CandidateShareAutoJoin,
  CandidateShareMessage,
  CandidateShareJobId,
  CandidateShareJobCandidateIds,
  CandidateShareRecipientIds,
  CandidateShareShareSettings,
  CandidateShareCandidateShareInvitations,
  CandidateShareRecipients,
  DeleteRecipientInCandidateShareError,
  DeleteJobCandidateInCandidateShareError,
  UpdateCandidateShareError,
  CandidateShareCompanyMemberInvitations,
  SendEmailsCandidateShareError
} from './candidateSharesTypes';
import { RecipientId } from '../recipients/recipientsTypes';
import {
  JobCandidateId,
  JobCandidateNanoId
} from '../jobCandidates/jobCandidatesTypes';

import { BaseBffRequests } from '../BaseBffRequests';

import { CandidateSharesBffRoutes } from './CandidateSharesBffRoutes';

interface FetchCandidateSharesRequestParams {
  query: FetchCandidateSharesQuery;
  countType?: FetchCandidateSharesCountType;
  page?: FetchCandidateSharesPage;
  pageSize?: FetchCandidateSharesPageSize;
  filters?: FetchCandidateSharesFilters;
  sort?: FetchCandidateSharesSort;
}

interface FetchCandidateSharesIndexRequestParams {
  queryName: FetchCandidateSharesIndexQueryName;
  countType?: FetchCandidateSharesIndexCountType;
  page?: FetchCandidateSharesIndexPage;
  pageSize?: FetchCandidateSharesIndexPageSize;
  filters?: FetchCandidateSharesIndexFilters;
  sort?: FetchCandidateSharesIndexSort;
}

interface FetchCandidateShareRequestParams {
  query: FetchCandidateShareQuery;
}

interface FetchCandidateShareIndexRequestParams {
  queryName: FetchCandidateShareIndexQueryName;
}

export interface CandidateShareRequestData {
  autoJoin: CandidateShareAutoJoin;
  message: CandidateShareMessage;
  jobId: CandidateShareJobId;
  jobCandidateIds: CandidateShareJobCandidateIds;
  recipientIds: CandidateShareRecipientIds;
  recipients?: CandidateShareRecipients;
  shareSettings: CandidateShareShareSettings;
  candidateShareInvitations: CandidateShareCandidateShareInvitations;
  companyMemberInvitations: CandidateShareCompanyMemberInvitations;
}

export interface CreateCandidateShareRequestData
  extends CandidateShareRequestData {
  id?: CandidateShareId;
}

export interface UpdateCandidateShareRequestData
  extends CandidateShareRequestData {
  id?: CandidateShareId;
}

export interface CreateCandidateShareErrorResponse {
  message: CreateCandidateShareError;
}

export interface UpdateCandidateShareErrorResponse {
  message: UpdateCandidateShareError;
}

export interface DeleteCandidateShareErrorResponse {
  message: DeleteCandidateShareError;
}

export interface DeleteRecipientInCandidateShareErrorResponse {
  message: DeleteRecipientInCandidateShareError;
}

export interface DeleteJobCandidateInCandidateShareErrorResponse {
  message: DeleteJobCandidateInCandidateShareError;
}

export interface SendEmailsCandidateShareErrorResponse {
  message: SendEmailsCandidateShareError;
}

export class CandidateSharesBffRequests extends BaseBffRequests {
  static fetchCandidateShares<T>(params: FetchCandidateSharesRequestParams) {
    return this.fetchItems<T>(
      CandidateSharesBffRoutes.fetchCandidateSharesRoute(),
      params
    );
  }

  static fetchCandidateSharesIndex<T>(
    params: FetchCandidateSharesIndexRequestParams
  ) {
    return this.fetchItemsIndex<T>(
      CandidateSharesBffRoutes.fetchCandidateSharesIndexRoute(),
      params
    );
  }

  static fetchCandidateShare<T>(
    candidateShareId: CandidateShareId,
    params: FetchCandidateShareRequestParams
  ) {
    return this.fetchItem<T>(
      CandidateSharesBffRoutes.fetchCandidateShareRoute(candidateShareId),
      params
    );
  }

  static fetchCandidateShareIndex<T>(
    candidateShareNanoId: CandidateShareNanoId,
    params: FetchCandidateShareIndexRequestParams
  ) {
    return this.fetchItemIndex<T>(
      CandidateSharesBffRoutes.fetchCandidateShareIndexRoute(
        candidateShareNanoId
      ),
      params
    );
  }

  static fetchCandidateShareByNanoId<T>(
    candidateShareNanoId: CandidateShareNanoId,
    params: FetchCandidateShareRequestParams
  ) {
    return this.fetchItemByNanoId<T>(
      CandidateSharesBffRoutes.fetchCandidateShareByNanoIdRoute(
        candidateShareNanoId
      ),
      params
    );
  }

  static createCandidateShare<T>(
    createCandidateShareData: CreateCandidateShareRequestData
  ) {
    return this.createItem<T, CreateCandidateShareRequestData>(
      CandidateSharesBffRoutes.createCandidateShareRoute(),
      createCandidateShareData
    );
  }

  static async updateCandidateShare<T>(
    updateCandidateShareNanoId: CandidateShareNanoId | CandidateShareId,
    updateCandidateShareData: UpdateCandidateShareRequestData
  ) {
    return this.updateItem<T, UpdateCandidateShareRequestData>(
      CandidateSharesBffRoutes.updateCandidateShareRoute(
        updateCandidateShareNanoId
      ),
      updateCandidateShareData
    );
  }

  static async deleteCandidateShare<T>(
    deleteCandidateShareId: CandidateShareId | CandidateShareNanoId
  ) {
    return this.deleteItem<T>(
      CandidateSharesBffRoutes.deleteCandidateShareRoute(deleteCandidateShareId)
    );
  }

  static async deleteRecipientInCandidateShare<T>(
    deleteCandidateShareId: CandidateShareId | CandidateShareNanoId,
    deleteRecipientId: RecipientId
  ) {
    return this.deleteItem<T>(
      CandidateSharesBffRoutes.deleteRecipientInCandidateShareRoute(
        deleteCandidateShareId,
        deleteRecipientId
      )
    );
  }

  static async deleteJobCandidateInCandidateShare<T>(
    deleteCandidateShareId: CandidateShareId | CandidateShareNanoId,
    deleteJobCandidateId: JobCandidateId | JobCandidateNanoId
  ) {
    return this.deleteItem<T>(
      CandidateSharesBffRoutes.deleteJobCandidateInCandidateShareRoute(
        deleteCandidateShareId,
        deleteJobCandidateId
      )
    );
  }

  static async sendEmailsCandidateShare<T>(
    sendEmailsCandidateShareId: CandidateShareId | CandidateShareNanoId
  ) {
    return this.postItem<T, unknown>(
      CandidateSharesBffRoutes.sendEmailsCandidateShareRoute(
        sendEmailsCandidateShareId
      ),
      {}
    );
  }
}
