import {
  useDisclosure,
  Popover,
  PopoverTrigger,
  Flex,
  Portal,
  PopoverContent
} from '@chakra-ui/react';
import React, { MouseEvent, useState } from 'react';
import { ChevronDownIcon } from '../../../../icons/ChevronDownIcon';
import { ButtonProps } from '../../../../helpers/Button/Button.types';
import { Button } from '../../../../helpers/Button';
import { Text } from '../../../../helpers/Text';

type PriorityType = 'low' | 'medium' | 'high';

interface JobPriorityDropdownProps extends ButtonProps {
  priority: PriorityType;
}

function JobPriorityDropdown({ priority, onClick }: JobPriorityDropdownProps) {
  const [selectedPriority, setSelectedPriority] = useState(priority);
  const { isOpen, onToggle, onClose } = useDisclosure();

  const priorityArray: PriorityType[] = ['low', 'medium', 'high'];

  const changePriority = (e: MouseEvent<HTMLButtonElement>) => {
    const { value } = e.currentTarget;

    setSelectedPriority(value as PriorityType);

    !!onClick && onClick(e);

    onClose();
  };

  const priorityColor = () => {
    switch (selectedPriority) {
      case 'high':
        return 'red.500';
      case 'medium':
        return 'yellow.500';
      default:
        return 'primary.500';
    }
  };

  return (
    <Popover isOpen={isOpen} onClose={onClose} isLazy>
      <PopoverTrigger>
        <Flex alignItems="stretch">
          <Button
            p={0}
            h="100%"
            minH={6}
            minW={0}
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              onToggle();
            }}
            border="none"
            textAlign="left"
            boxShadow="none"
            fontSize="inherit"
            hierarchy="unstyled"
            aria-label="Dropdown"
          >
            <Flex gap={1} alignItems="center" fontSize="inherit">
              <Text
                fontWeight="medium"
                textTransform="capitalize"
                color={priorityColor()}
              >
                {selectedPriority}
              </Text>
              <ChevronDownIcon fill="gray.500" />
            </Flex>
          </Button>
        </Flex>
      </PopoverTrigger>

      <Portal>
        <PopoverContent minW={20} w={32} p={1} maxH={60} overflow="hidden">
          <Flex flexDir="column" overflow="auto">
            {priorityArray.map((value) => (
              <Button
                px={2}
                py={1}
                h="auto"
                size="small"
                color="inherit"
                textAlign="left"
                lineHeight={1.5}
                fontSize="inherit"
                borderRadius="none"
                fontWeight="inherit"
                hierarchy="unstyled"
                textTransform="capitalize"
                isDisabled={selectedPriority === value}
                onClick={(e) => {
                  e.stopPropagation();
                  changePriority(e);
                }}
                value={value}
                key={value}
                _hover={{
                  bg: 'gray.100'
                }}
              >
                {value}
              </Button>
            ))}
          </Flex>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}

export default JobPriorityDropdown;
