import {
  useReactHookForm,
  ReactHookFormFieldError
} from '../../../../../../common/hooks/base/useReactHookForm';

import {
  JobFields,
  JobDescription,
  JobUserJobId,
  JobJobTitleId,
  JobDepartmentId,
  JobJobFunctionId,
  JobDisciplineId
} from '../../../../jobsTypes';

import { usePostJobDetailsFormValidationRules } from '../usePostJobDetailsFormValidationRules';

import {
  OnPostJobDetailsForm,
  PostJobDetailsFormData
} from '../../PostJobDetailsForm.types';

const defaultPostJobDetailsFormValues: PostJobDetailsFormData = {
  jobTitleId: '' as JobJobTitleId,
  departmentId: '' as JobDepartmentId,
  description: '' as JobDescription,
  userJobId: '' as JobUserJobId,
  jobFunctionId: '' as JobJobFunctionId,
  disciplineId: '' as JobDisciplineId
};

interface PostJobDetailsFormOptions {
  defaultValues?: Partial<PostJobDetailsFormData>;
  onPostJobDetailsForm: OnPostJobDetailsForm;
}

function usePostJobDetailsForm({
  defaultValues = {},
  onPostJobDetailsForm
}: PostJobDetailsFormOptions) {
  const {
    control,
    watch,
    errors,
    isLoading,
    isValid,
    isSubmitted,
    isSubmitSuccessful,
    errorMessage,
    register,
    resetForm,
    handleSubmitReactHookForm,
    setValue
  } = useReactHookForm<PostJobDetailsFormData>({
    defaultValues: { ...defaultPostJobDetailsFormValues, ...defaultValues }
  });

  const {
    jobTitleIdRules,
    departmentIdRules,
    descriptionRules,
    jobFunctionIdRules
  } = usePostJobDetailsFormValidationRules();

  return {
    control,
    watch,
    validationErrors: {
      jobTitleIdValidationError: (errors.jobTitleId as ReactHookFormFieldError)
        ?.message,
      departmentIdValidationError: (
        errors.departmentId as ReactHookFormFieldError
      )?.message,
      descriptionValidationError: (
        errors.description as ReactHookFormFieldError
      )?.message,
      userJobIdValidationError: (errors.userJobId as ReactHookFormFieldError)
        ?.message,
      jobFunctionIdValidationError: (
        errors.jobFunctionId as ReactHookFormFieldError
      )?.message,
      disciplineIdValidationError: (
        errors.disciplineId as ReactHookFormFieldError
      )?.message
    },
    postJobDetailsFormIsLoading: isLoading,
    postJobDetailsFormIsValid: isValid,
    postJobDetailsFormIsSubmitted: isSubmitted,
    postJobDetailsFormIsSubmitSuccessful: isSubmitSuccessful,
    postJobDetailsFormErrorMessage: errorMessage,
    resetPostJobDetailsForm: resetForm,
    setPostJobDetailsFormValue: setValue,
    registerFields: {
      registerJobTitleId: register(JobFields.JOB_TITLE_ID, jobTitleIdRules),
      registerDepartmentId: register(
        JobFields.DEPARTMENT_ID,
        departmentIdRules
      ),
      registerDescription: register(JobFields.DESCRIPTION, descriptionRules),
      registerUserJobId: register(JobFields.USER_JOB_ID), // except some
      registerJobFunctionId: register(
        JobFields.JOB_FUNCTION_ID,
        jobFunctionIdRules
      ),
      registerDisciplineId: register(JobFields.DISCIPLINE_ID)
    },
    handlePostJobDetailsForm: handleSubmitReactHookForm({
      onSubmit: (data) =>
        onPostJobDetailsForm?.({
          jobTitleId: data.jobTitleId as JobJobTitleId,
          departmentId: data.departmentId as JobDepartmentId,
          description: data.description as JobDescription,
          userJobId: data.userJobId as JobUserJobId,
          jobFunctionId: data.jobFunctionId as JobJobFunctionId,
          disciplineId: data.disciplineId as JobDisciplineId
        })
    })
  };
}

export default usePostJobDetailsForm;
