import { Flex, Stack } from '@chakra-ui/react';
import size from 'lodash/size';
import compact from 'lodash/compact';

import { AlertMessage } from '../../../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../../../helpers/LoadingSkeleton';
import { Text } from '../../../../../../../helpers/Text';

import { usePaginatedEducations } from '../../../../../../educations/hooks/usePaginatedEducations';

import {
  FetchEducationsResponse,
  fetchEducationsQuery
} from '../../../../../../educations/queries/fetchEducations.query';

import { EducationsCache } from '../../../../../../educations/EducationsCache';

import { JobCandidateId } from '../../../../../../jobCandidates/jobCandidatesTypes';
import {
  EducationFields,
  FetchEducationsFilters,
  FetchEducationsSort
} from '../../../../../../educations/educationsTypes';
import { renderLocation } from '../../../../../../common/utils/renderLocation';
import { DateUtils } from '../../../../../../../utils/DateUtils';

interface HiringPortalLeadDrawerEducationProps {
  jobCandidateId: JobCandidateId;
}

function HiringPortalLeadDrawerEducation({
  jobCandidateId
}: HiringPortalLeadDrawerEducationProps) {
  const { educations, educationsIsFetched, educationsErrorMessage } =
    usePaginatedEducations<FetchEducationsResponse>({
      query: fetchEducationsQuery,
      cacheKey: EducationsCache.jobCandidateListCacheKey(jobCandidateId),
      initialFilters: {
        [EducationFields.JOB_CANDIDATE_ID]: {
          operator: 'eq',
          value: jobCandidateId
        }
      } as unknown as FetchEducationsFilters,
      initialSort: {
        [EducationFields.CREATED_AT]: {
          ascending: false
        }
      } as unknown as FetchEducationsSort
    });

  return (
    <LoadingSkeleton count={3} loaded={educationsIsFetched}>
      <AlertMessage message={educationsErrorMessage} />
      {size(educations) ? (
        <Stack p={4} bg="gray.50" spacing={3}>
          <Text textStyle="upperCase1Medium" color="gray.600">
            Education
          </Text>

          <Stack spacing={4}>
            {educations.map((education) => (
              <Flex flexDir="column" key={education.nanoId}>
                <Text textStyle="body1Medium">
                  {compact([education.degree, education.studyField]).join(', ')}
                </Text>

                <Flex
                  gap={2}
                  alignItems="baseline"
                  justifyContent="space-between"
                >
                  <Text textStyle="body2Regular" color="gray.600">
                    {compact([
                      education.schoolName,
                      renderLocation(education)
                    ]).join(', ')}
                  </Text>

                  <Text fontSize="xs" color="gray.600">
                    (
                    {compact([
                      DateUtils.formatYear(education.startDate),
                      DateUtils.formatYear(education.endDate)
                    ]).join('-')}
                    )
                  </Text>
                </Flex>
              </Flex>
            ))}
          </Stack>
        </Stack>
      ) : null}
    </LoadingSkeleton>
  );
}

export default HiringPortalLeadDrawerEducation;
