import {
  FetchIndustryExpertisesQuery,
  FetchIndustryExpertisesCountType,
  FetchIndustryExpertisesPage,
  FetchIndustryExpertisesPageSize,
  FetchIndustryExpertisesFilters,
  FetchIndustryExpertisesSort,
  FetchIndustryExpertisesIndexQueryName,
  FetchIndustryExpertisesIndexCountType,
  FetchIndustryExpertisesIndexPage,
  FetchIndustryExpertisesIndexPageSize,
  FetchIndustryExpertisesIndexFilters,
  FetchIndustryExpertisesIndexSort,
  FetchIndustryExpertiseQuery,
  FetchIndustryExpertiseIndexQueryName,
  CreateIndustryExpertiseError,
  UpdateIndustryExpertiseError,
  DeleteIndustryExpertiseError,
  IndustryExpertiseId,
  IndustryExpertiseNanoId,
  IndustryExpertiseUserId,
  IndustryExpertiseIndustryId,
  IndustryExpertiseJobId,
  IndustryExpertiseRequirements,
  IndustryExpertiseExperience,
  IndustryExpertiseJobResumeRecruiterId,
  IndustryExpertiseJobCandidateId,
  IndustryExpertiseTalentPoolId
} from './industryExpertisesTypes';

import { BaseBffRequests } from '../BaseBffRequests';

import { IndustryExpertisesBffRoutes } from './IndustryExpertisesBffRoutes';

interface FetchIndustryExpertisesRequestParams {
  query: FetchIndustryExpertisesQuery;
  countType?: FetchIndustryExpertisesCountType;
  page?: FetchIndustryExpertisesPage;
  pageSize?: FetchIndustryExpertisesPageSize;
  filters?: FetchIndustryExpertisesFilters;
  sort?: FetchIndustryExpertisesSort;
}

interface FetchIndustryExpertisesIndexRequestParams {
  queryName: FetchIndustryExpertisesIndexQueryName;
  countType?: FetchIndustryExpertisesIndexCountType;
  page?: FetchIndustryExpertisesIndexPage;
  pageSize?: FetchIndustryExpertisesIndexPageSize;
  filters?: FetchIndustryExpertisesIndexFilters;
  sort?: FetchIndustryExpertisesIndexSort;
}

interface FetchIndustryExpertiseRequestParams {
  query: FetchIndustryExpertiseQuery;
}

interface FetchIndustryExpertiseIndexRequestParams {
  queryName: FetchIndustryExpertiseIndexQueryName;
}

export interface IndustryExpertiseRequestData {
  jobId?: IndustryExpertiseJobId;
  jobResumeRecruiterId?: IndustryExpertiseJobResumeRecruiterId;
  jobCandidateId?: IndustryExpertiseJobCandidateId;
  talentPoolId?: IndustryExpertiseTalentPoolId;
  requirements?: IndustryExpertiseRequirements;
  experience?: IndustryExpertiseExperience;
}

export interface CreateIndustryExpertiseRequestData
  extends IndustryExpertiseRequestData {
  id?: IndustryExpertiseId;
  userId: IndustryExpertiseUserId;
  nanoId: IndustryExpertiseNanoId;
  industryId: IndustryExpertiseIndustryId;
}

export interface UpdateIndustryExpertiseRequestData
  extends IndustryExpertiseRequestData {
  jobId?: IndustryExpertiseJobId;
}

export interface DeleteIndustryExpertiseRequestData {
  industryExpertiseId: IndustryExpertiseId;
}

export interface ReplaceIndustryExpertiseRequestData {
  industryExpertiseId: IndustryExpertiseId;
}

export interface CreateIndustryExpertiseErrorResponse {
  message: CreateIndustryExpertiseError;
}

export interface UpdateIndustryExpertiseErrorResponse {
  message: UpdateIndustryExpertiseError;
}

export interface DeleteIndustryExpertiseErrorResponse {
  message: DeleteIndustryExpertiseError;
}

export class IndustryExpertisesBffRequests extends BaseBffRequests {
  static fetchIndustryExpertises<T>(
    params: FetchIndustryExpertisesRequestParams
  ) {
    return this.fetchItems<T>(
      IndustryExpertisesBffRoutes.fetchIndustryExpertisesRoute(),
      params
    );
  }

  static fetchIndustryExpertisesIndex<T>(
    params: FetchIndustryExpertisesIndexRequestParams
  ) {
    return this.fetchItemsIndex<T>(
      IndustryExpertisesBffRoutes.fetchIndustryExpertisesIndexRoute(),
      params
    );
  }

  static fetchIndustryExpertise<T>(
    industryExpertiseId: IndustryExpertiseId,
    params: FetchIndustryExpertiseRequestParams
  ) {
    return this.fetchItem<T>(
      IndustryExpertisesBffRoutes.fetchIndustryExpertiseRoute(
        industryExpertiseId
      ),
      params
    );
  }

  static fetchIndustryExpertiseIndex<T>(
    industryExpertiseNanoId: IndustryExpertiseNanoId,
    params: FetchIndustryExpertiseIndexRequestParams
  ) {
    return this.fetchItemIndex<T>(
      IndustryExpertisesBffRoutes.fetchIndustryExpertiseIndexRoute(
        industryExpertiseNanoId
      ),
      params
    );
  }

  static fetchIndustryExpertiseByNanoId<T>(
    industryExpertiseNanoId: IndustryExpertiseNanoId,
    params: FetchIndustryExpertiseRequestParams
  ) {
    return this.fetchItemByNanoId<T>(
      IndustryExpertisesBffRoutes.fetchIndustryExpertiseByNanoIdRoute(
        industryExpertiseNanoId
      ),
      params
    );
  }

  static createIndustryExpertise<T>(
    createIndustryExpertiseData: CreateIndustryExpertiseRequestData
  ) {
    return this.createItem<T, CreateIndustryExpertiseRequestData>(
      IndustryExpertisesBffRoutes.createIndustryExpertiseRoute(),
      createIndustryExpertiseData
    );
  }

  static async updateIndustryExpertise<T>(
    updateIndustryExpertiseId: IndustryExpertiseId,
    updateIndustryExpertiseData: UpdateIndustryExpertiseRequestData
  ) {
    return this.updateItem<T, UpdateIndustryExpertiseRequestData>(
      IndustryExpertisesBffRoutes.updateIndustryExpertiseRoute(
        updateIndustryExpertiseId
      ),
      updateIndustryExpertiseData
    );
  }

  static async deleteIndustryExpertise<T>(
    deleteIndustryExpertiseId: IndustryExpertiseId
  ) {
    return this.deleteItem<T>(
      IndustryExpertisesBffRoutes.deleteIndustryExpertiseRoute(
        deleteIndustryExpertiseId
      )
    );
  }
}
