import React from 'react';
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';

import { ErrorMessage, IsDisabled } from '../../../../types';

import { WysiwygFieldControl } from './components/WysiwygFieldControl';

interface WysiwygFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: FieldPath<T>;
  placeholder?: string;
  defaultValue?: string | null;
  errorMessage?: ErrorMessage;
  isDisabled?: IsDisabled;
  isRequired?: boolean;
}

function WysiwygField<T extends FieldValues>({
  control,
  name,
  placeholder,
  defaultValue,
  isDisabled,
  isRequired,
  errorMessage
}: WysiwygFieldProps<T>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <WysiwygFieldControl
          name={name}
          placeholder={placeholder}
          defaultValue={defaultValue}
          value={value as string}
          isDisabled={isDisabled}
          isRequired={isRequired}
          errorMessage={errorMessage}
          onChange={onChange}
        />
      )}
    />
  );
}

export default WysiwygField;
