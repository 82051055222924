import { IndustryExpertiseRequirements } from '../../../../industryExpertisesTypes';

const experienceRules = {
  required: 'Experience is required'
};

const requirementsRules = {
  required: 'Requirements are required',
  validate: {
    isValid: (value?: IndustryExpertiseRequirements) =>
      value ? true : 'Please select skill necessity requirements'
  }
};

function useAddEditIndustryExpertiseFormValidationRules() {
  return {
    experienceRules,
    requirementsRules
  };
}

export default useAddEditIndustryExpertiseFormValidationRules;
