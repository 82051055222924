import { Icon, IconProps } from '@chakra-ui/icon';

const SmileyIcon = ({
  w = '16px',
  h = '16px',
  fill = 'gray.500',
  ...props
}: IconProps) => (
  <Icon viewBox="0 0 16 16" w={w} h={h} fill={fill} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.333 7.416a1.168 1.168 0 0 1 0-2.333 1.168 1.168 0 0 1 0 2.333Zm.584 2.334a2.92 2.92 0 0 1-2.916 2.917A2.92 2.92 0 0 1 5.084 9.75h1.167c0 .965.785 1.75 1.75 1.75.964 0 1.75-.785 1.75-1.75h1.166ZM4.5 6.25a1.168 1.168 0 0 1 2.333 0 1.168 1.168 0 0 1-2.333 0ZM8 1C4.14 1 1 4.14 1 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7Z"
    />
  </Icon>
);
export default SmileyIcon;
