import { CompanyId } from '../companies/companiesTypes';

import {
  UserEmail,
  FetchUsersCacheKey,
  FetchUsersIndexCacheKey,
  FetchUserCacheKey,
  FetchUserIndexCacheKey
} from './usersTypes';

export class UsersCache {
  static indexCacheKey() {
    return 'users' as FetchUsersCacheKey;
  }

  static listIndexCacheKey() {
    return 'users-index' as FetchUsersIndexCacheKey;
  }

  static emailIndexCacheKey(userEmail: UserEmail) {
    return `users-email-${userEmail}` as FetchUsersCacheKey;
  }

  static recruitersCustomersCacheKey() {
    return 'users-recruiters-customers' as FetchUsersCacheKey;
  }

  static recruitersLeadsCacheKey() {
    return 'users-recruiters-leads' as FetchUsersCacheKey;
  }

  static recruitersProspectsCacheKey() {
    return 'users-recruiters-prospects' as FetchUsersCacheKey;
  }

  static recruitersUsersCacheKey() {
    return 'users-recruiters-users' as FetchUsersCacheKey;
  }

  static candidatesApplicantsCacheKey() {
    return 'users-candidates-applicants' as FetchUsersCacheKey;
  }

  static candidatesLeadsCacheKey() {
    return 'users-candidates-leads' as FetchUsersCacheKey;
  }

  static candidatesProspectsCacheKey() {
    return 'users-candidates-prospects' as FetchUsersCacheKey;
  }

  static candidatesUsersCacheKey() {
    return 'users-candidates-users' as FetchUsersCacheKey;
  }

  static employeesLeadsCacheKey() {
    return 'users-employees-leads' as FetchUsersCacheKey;
  }

  static employeesProspectsCacheKey() {
    return 'users-employees-prospects' as FetchUsersCacheKey;
  }

  static employeesUsersCacheKey() {
    return 'users-employees-users' as FetchUsersCacheKey;
  }

  static showCacheKey() {
    return 'user' as FetchUserCacheKey;
  }

  static showIndexCacheKey() {
    return 'user-index' as FetchUserIndexCacheKey;
  }

  static postJobCacheKey() {
    return 'users-post-job' as FetchUsersCacheKey;
  }

  static companyCacheKey(companyId: CompanyId) {
    return `users-company-${companyId}` as FetchUsersCacheKey;
  }
}
