import { Stack } from '@chakra-ui/react';
import { useState } from 'react';
import { Heading } from '../../../../../../../../helpers/Heading';
import { CustomiseCandidateSubmissionEvaluationItem } from '../CustomiseCandidateSubmissionEvaluationItem';
import { CustomiseCandidateSubmissionEvaluationVisibility } from '../CustomiseCandidateSubmissionEvaluationVisibility';
import CustomiseCandidateSubmissionEvaluationOverall from '../CustomiseCandidateSubmissionEvaluationOverall/CustomiseCandidateSubmissionEvaluationOverall';
import { useViewJobCandidateDetailsSubmissionContext } from '../../../../contexts/ViewJobCandidateDetailsSubmissionContext';
import { CandidateSubmissionEvaluationsEnum } from '../../../../../../../candidateSubmissions/candidateSubmissionsTypes';

export default function CustomiseCandidateSubmissionEvaluation() {
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const { evaluations } = useViewJobCandidateDetailsSubmissionContext();

  const handleToggle = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const evaluationsArr = Object.keys(
    evaluations
  ) as CandidateSubmissionEvaluationsEnum[];

  return (
    <Stack spacing={5}>
      <Heading level="h5" textStyle="body1Medium" fontWeight="semibold">
        Candidate evaluation
      </Heading>

      <Stack spacing={3}>
        {evaluationsArr?.map((evaluation, index) => (
          <CustomiseCandidateSubmissionEvaluationItem
            key={evaluation}
            label={evaluation}
            index={0}
            isOpen={openIndex === index}
            onToggle={() => handleToggle(index)}
          />
        ))}
      </Stack>

      <CustomiseCandidateSubmissionEvaluationOverall />

      <CustomiseCandidateSubmissionEvaluationVisibility />
    </Stack>
  );
}
