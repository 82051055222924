import {
  FetchSkillLicensesCacheKey,
  FetchSkillLicenseCacheKey
} from './skillLicensesTypes';

export class SkillLicensesCache {
  static indexCacheKey() {
    return 'skillLicenses' as FetchSkillLicensesCacheKey;
  }

  static showCacheKey() {
    return 'skillLicense' as FetchSkillLicenseCacheKey;
  }
}
