import { Icon, IconProps } from '@chakra-ui/icon';

const DeleteIcon = ({
  w = '16px',
  h = '16px',
  fill = 'gray.500',
  ...props
}: IconProps) => (
  <Icon viewBox="0 0 16 16" w={w} h={h} fill={fill} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5 3h3v1h-3V3Zm6.999 1h-3V2.5A.5.5 0 0 0 10 2H6a.5.5 0 0 0-.5.5V4H2.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h10.999a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5ZM10.5 12h-1V9h1v3Zm-2 0h-1V9h1v3Zm-2 0h-1V9h1v3Zm6-5h-9v6.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5V7Z"
    />
  </Icon>
);

export default DeleteIcon;
