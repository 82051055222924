import {
  FetchSkillsCacheKey,
  FetchSkillCacheKey,
  FetchSkillsIndexCacheKey
} from './skillsTypes';

export class SkillsCache {
  static indexCacheKey() {
    return 'skills' as FetchSkillsCacheKey;
  }

  static showCacheKey() {
    return 'skill' as FetchSkillCacheKey;
  }

  static computerCacheKey() {
    return 'skills-computer' as FetchSkillsCacheKey;
  }

  static computerIndexCacheKey() {
    return 'skills-computer-index' as FetchSkillsIndexCacheKey;
  }

  static computerNewSkillsCacheKey() {
    return 'skills-computer-new' as FetchSkillsCacheKey;
  }

  static computerNewSkillsIndexCacheKey() {
    return 'skills-computer-new-index' as FetchSkillsIndexCacheKey;
  }

  static industryCacheKey() {
    return 'skills-industry' as FetchSkillsCacheKey;
  }

  static industryIndexCacheKey() {
    return 'skills-industry-index' as FetchSkillsIndexCacheKey;
  }

  static industryNewSkillsCacheKey() {
    return 'skills-industry-new' as FetchSkillsCacheKey;
  }

  static industryNewSkillsIndexCacheKey() {
    return 'skills-industry-new-index' as FetchSkillsIndexCacheKey;
  }

  static existsCacheKey() {
    return 'skills-exists' as FetchSkillsCacheKey;
  }

  static existsIndexCacheKey() {
    return 'skills-exists-index' as FetchSkillsIndexCacheKey;
  }
}
