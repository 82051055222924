import { usePaginatedItems } from '../../../common/hooks/usePaginatedItems';

import {
  FetchDepartmentsQuery,
  FetchDepartmentsCacheKey,
  FetchDepartmentsTotalCount,
  FetchDepartmentsPage,
  FetchDepartmentsPageSize,
  FetchDepartmentsError,
  FetchDepartmentsErrorMessage,
  FetchDepartmentsIsLoading,
  FetchDepartmentsIsFetched,
  FetchDepartmentsIsPlaceholderData,
  FetchDepartmentsEnabled,
  FetchDepartmentsPrefetchNextPage,
  FetchDepartmentsCountType,
  FetchDepartmentsFilters,
  FetchDepartmentsSort,
  FetchDepartmentID,
  FetchDepartmentQuery,
  FetchDepartmentCacheKey
} from '../../departmentsTypes';

import { DepartmentsBffRequests } from '../../DepartmentsBffRequests';

interface PaginatedDepartmentsBaseOptions<PaginatedDepartmentsResponseType> {
  cacheKey: FetchDepartmentsCacheKey;
  query: FetchDepartmentsQuery;
  countType?: FetchDepartmentsCountType;
  initialPage?: FetchDepartmentsPage;
  initialPageSize?: FetchDepartmentsPageSize;
  initialFilters?: FetchDepartmentsFilters;
  initialSort?: FetchDepartmentsSort;
  enabled?: FetchDepartmentsEnabled;
  prefetchNextPage?: FetchDepartmentsPrefetchNextPage;
  placeholderDepartments?: PaginatedDepartmentsResponseType[];
  placeholderDepartmentsCount?: FetchDepartmentsTotalCount;
  placeholderData?: () => PaginatedDepartmentsResponse<PaginatedDepartmentsResponseType>;
  onSuccess?: (
    data: PaginatedDepartmentsResponse<PaginatedDepartmentsResponseType>
  ) => void;
}

interface PaginatedDepartmentsOptionsWithoutItem {
  fetchItemCacheKey?: never;
  itemQuery?: never;
}

interface PaginatedDepartmentsOptionsWithItem {
  fetchItemCacheKey: FetchDepartmentCacheKey;
  itemQuery: FetchDepartmentQuery;
}

type PaginatedDepartmentsOptions<PaginatedDepartmentsResponseType> =
  PaginatedDepartmentsBaseOptions<PaginatedDepartmentsResponseType> &
    (
      | PaginatedDepartmentsOptionsWithoutItem
      | PaginatedDepartmentsOptionsWithItem
    );

interface PaginatedDepartmentsResponse<PaginatedDepartmentsResponseType> {
  data: PaginatedDepartmentsResponseType[];
  totalCount?: FetchDepartmentsTotalCount;
  meta?: { totalCount?: FetchDepartmentsTotalCount };
}

function usePaginatedDepartments<PaginatedDepartmentsResponseType>({
  cacheKey,
  query,
  countType,
  initialPage,
  initialPageSize,
  initialFilters,
  initialSort,
  enabled,
  prefetchNextPage,
  placeholderDepartments,
  placeholderDepartmentsCount,
  placeholderData,
  fetchItemCacheKey,
  itemQuery,
  onSuccess
}: PaginatedDepartmentsOptions<PaginatedDepartmentsResponseType>) {
  const {
    items,
    itemsTotalCount,
    itemsError,
    itemsErrorMessage,
    itemsIsFetched,
    itemsIsLoading,
    itemsIsPlaceholderData,
    itemsCurrentPage,
    itemsCurrentPageSize,
    itemsCurrentFilters,
    itemsCurrentSort,
    fetchItems,
    paginateItems,
    changeItemsPageSize,
    filterItems,
    changeItemsFilters,
    clearItemsFilters,
    sortItems,
    prefetchItems,
    prefetchItem
  } = usePaginatedItems<PaginatedDepartmentsResponseType>({
    cacheKey,
    initialPage,
    initialPageSize,
    initialFilters,
    initialSort,
    enabled,
    prefetchNextPage,
    placeholderItems: placeholderDepartments,
    placeholderItemsCount: placeholderDepartmentsCount,
    placeholderData,
    onSuccess: (data) =>
      onSuccess?.(
        data as PaginatedDepartmentsResponse<PaginatedDepartmentsResponseType>
      ),
    queryFn: (params) =>
      DepartmentsBffRequests.fetchDepartments<PaginatedDepartmentsResponseType>(
        {
          query,
          countType,
          page: params.page as FetchDepartmentsPage,
          pageSize: params.pageSize as FetchDepartmentsPageSize,
          filters: params.filters as FetchDepartmentsFilters,
          sort: params.sort as FetchDepartmentsSort
        }
      ),
    // DepartmentsRequests.fetchDepartments<PaginatedDepartmentsResponseType>({
    //   query,
    //   countType,
    //   page: params.page as FetchDepartmentsPage,
    //   pageSize: params.pageSize as FetchDepartmentsPageSize,
    //   filters: params.filters as FetchDepartmentsFilters,
    //   sort: params.sort as FetchDepartmentsSort
    // }),
    ...(itemQuery
      ? {
          fetchItemCacheKey,
          queryItemFn: (itemId) =>
            DepartmentsBffRequests.fetchDepartment(
              itemId as FetchDepartmentID,
              {
                query: itemQuery
              }
            )
          // DepartmentsRequests.fetchDepartment(itemId as FetchDepartmentID, {
          //   query: itemQuery
          // })
        }
      : {})
  });

  return {
    departments: items as PaginatedDepartmentsResponseType[],
    departmentsTotalCount: itemsTotalCount as FetchDepartmentsTotalCount,
    departmentsError: itemsError as FetchDepartmentsError,
    departmentsErrorMessage: itemsErrorMessage as FetchDepartmentsErrorMessage,
    departmentsIsLoading: itemsIsLoading as FetchDepartmentsIsLoading,
    departmentsIsFetched: itemsIsFetched as FetchDepartmentsIsFetched,
    departmentsIsPlaceholderData:
      itemsIsPlaceholderData as FetchDepartmentsIsPlaceholderData,
    departmentsCurrentPage: itemsCurrentPage as FetchDepartmentsPage,
    departmentsCurrentPageSize:
      itemsCurrentPageSize as FetchDepartmentsPageSize,
    departmentsCurrentFilters: itemsCurrentFilters as FetchDepartmentsFilters,
    departmentsCurrentSort: itemsCurrentSort as FetchDepartmentsSort,
    fetchDepartments: fetchItems,
    paginateDepartments: paginateItems,
    changeDepartmentsPageSize: changeItemsPageSize,
    filterDepartments: filterItems,
    changeDepartmentsFilters: changeItemsFilters,
    clearDepartmentsFilters: clearItemsFilters,
    sortDepartments: sortItems,
    prefetchDepartments: prefetchItems,
    prefetchDepartment: prefetchItem
  };
}

export default usePaginatedDepartments;
