import { JobWorkplace } from '../../jobsTypes';
import { TalentPoolWorkplace } from '../../../talentPools/talentPoolsTypes';

import { workplaceOptionsHash } from '../../jobs.data';

function renderJobWorkplace(workplace: JobWorkplace | TalentPoolWorkplace) {
  return workplaceOptionsHash[workplace];
}

export default renderJobWorkplace;
