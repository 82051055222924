import { Icon, IconProps } from '@chakra-ui/icon';

const MentionIcon = ({
  w = '16px',
  h = '16px',
  fill = 'gray.500',
  ...props
}: IconProps) => (
  <Icon viewBox="0 0 16 16" w={w} h={h} fill={fill} {...props}>
    <path d="M7.224 14.176c-1.685 0-3.04-.528-4.064-1.584-1.013-1.067-1.52-2.395-1.52-3.984 0-1.781.608-3.253 1.824-4.416 1.227-1.163 2.683-1.744 4.368-1.744 1.6 0 2.917.464 3.952 1.392 1.035.928 1.552 2.15 1.552 3.664 0 1.312-.277 2.304-.832 2.976-.555.672-1.259 1.008-2.112 1.008-.757 0-1.328-.272-1.712-.816-.47.523-1.125.784-1.968.784-.757 0-1.365-.261-1.824-.784-.448-.533-.672-1.19-.672-1.968 0-.97.304-1.797.912-2.48C5.747 5.541 6.504 5.2 7.4 5.2c.821 0 1.387.315 1.696.944l.16-.784h1.568l-.736 3.52c-.17.843.016 1.264.56 1.264.917 0 1.376-.89 1.376-2.672 0-1.195-.405-2.123-1.216-2.784-.8-.661-1.797-.992-2.992-.992-1.323 0-2.459.448-3.408 1.344-.95.885-1.424 2.075-1.424 3.568 0 1.29.384 2.336 1.152 3.136.768.79 1.797 1.184 3.088 1.184 1.12 0 2.07-.192 2.848-.576v1.36a8.854 8.854 0 0 1-2.848.464Zm-1.152-4.72c.224.352.55.528.976.528.427 0 .779-.133 1.056-.4a2.06 2.06 0 0 0 .576-1.072c.107-.512.048-.95-.176-1.312-.213-.363-.533-.544-.96-.544-.427 0-.784.139-1.072.416-.288.267-.475.624-.56 1.072-.107.512-.053.95.16 1.312Z" />
  </Icon>
);
export default MentionIcon;
