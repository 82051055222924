import {
  CandidateShareRecipientItemBffID,
  CandidateShareRecipientItemBffNanoID,
  FetchCandidateShareRecipientsBffURL,
  FetchCandidateShareRecipientBffURL,
  FetchCandidateShareRecipientByNanoIdBffURL,
  CreateCandidateShareRecipientBffURL,
  DeleteCandidateShareRecipientBffURL
} from './candidateShareRecipientsTypes';

import { BaseBffRoutes } from '../BaseBffRoutes';

export class CandidateShareRecipientsBffRoutes extends BaseBffRoutes {
  protected static route = 'candidateShareRecipients';

  static fetchCandidateShareRecipientsRoute() {
    return this.fetchItemsRoute<FetchCandidateShareRecipientsBffURL>();
  }

  static fetchCandidateShareRecipientsIndexRoute() {
    return this.fetchItemsIndexRoute<FetchCandidateShareRecipientsBffURL>();
  }

  static fetchCandidateShareRecipientRoute(
    candidateShareRecipientItemBffID: CandidateShareRecipientItemBffID
  ) {
    return this.fetchItemRoute<FetchCandidateShareRecipientBffURL>(
      candidateShareRecipientItemBffID
    );
  }

  static fetchCandidateShareRecipientIndexRoute(
    candidateShareRecipientItemBffNanoID: CandidateShareRecipientItemBffNanoID
  ) {
    return this.fetchItemIndexRoute<FetchCandidateShareRecipientBffURL>(
      candidateShareRecipientItemBffNanoID
    );
  }

  static fetchCandidateShareRecipientByNanoIdRoute(
    candidateShareRecipientItemBffNanoID: CandidateShareRecipientItemBffNanoID
  ) {
    return this.fetchItemByNanoIdRoute<FetchCandidateShareRecipientByNanoIdBffURL>(
      candidateShareRecipientItemBffNanoID
    );
  }

  static createCandidateShareRecipientRoute() {
    return this.createItemRoute<CreateCandidateShareRecipientBffURL>();
  }

  static deleteCandidateShareRecipientRoute(
    candidateShareRecipientItemBffID:
      | CandidateShareRecipientItemBffNanoID
      | CandidateShareRecipientItemBffID
  ) {
    return this.deleteItemRoute<DeleteCandidateShareRecipientBffURL>(
      candidateShareRecipientItemBffID
    );
  }
}
