import debounce from 'lodash/debounce';

import {
  SelectFieldControl,
  SelectStyleObject
} from '../../../../../../../../../helpers/forms/formFields/SelectField/components/SelectFieldControl';

import { usePaginatedJobs } from '../../../../../../../../../main/jobs/hooks/usePaginatedJobs';
import { JobsCache } from '../../../../../../../../../main/jobs/JobsCache';

import {
  FetchJobsCountType,
  FetchJobsFilters,
  FetchJobsSort,
  JobFields,
  JobId,
  JobName,
  JobNanoId
} from '../../../../../../../../../main/jobs/jobsTypes';
import { JobOption } from './SelectJobField.types';

import { activeJobsFilters } from '../../../../../../../../../main/jobs/pages/JobsIndexPage/JobsIndexPage.query';
import { fetchJobQuery } from '../../../../../../../../../main/jobs/queries/fetchJob.query';
import {
  fetchJobsQuery,
  FetchJobsResponse
} from '../../../../../../../../../main/jobs/queries/fetchJobs.query';

import { jobsInitialPageSize } from '../../../../../../../../../main/jobs/jobs.data';

interface SelectJobFieldProps {
  handleSelectJob: (selectedOption: JobOption) => void;
  selectedJob: {
    id: JobId;
    name: JobName;
    nanoId: JobNanoId;
  };
}

function SelectJobField({ handleSelectJob, selectedJob }: SelectJobFieldProps) {
  const { jobs, jobsErrorMessage, jobsIsLoading, changeJobsFilters } =
    usePaginatedJobs<FetchJobsResponse>({
      cacheKey: JobsCache.indexCacheKey(),
      query: fetchJobsQuery,
      countType: 'exact' as FetchJobsCountType,
      fetchItemCacheKey: JobsCache.showCacheKey(),
      itemQuery: fetchJobQuery,
      initialFilters: {
        ...activeJobsFilters,
        [JobFields.NAME]: {
          operator: 'ilike',
          value: `${selectedJob.name}%`
        }
      } as unknown as FetchJobsFilters,
      initialSort: {
        [JobFields.NAME]: { ascending: true }
      } as unknown as FetchJobsSort,
      initialPageSize: jobsInitialPageSize
    });

  const debouncedFilterJobs = debounce<(updatedValue: string) => void>(
    (updatedValue) => {
      return changeJobsFilters({
        [JobFields.NAME]: {
          operator: 'ilike',
          value: `%${updatedValue}%`
        }
      });
    },
    500
  );

  const options = jobs.map((job: FetchJobsResponse) => ({
    value: job.id,
    label: job.name,
    nanoId: job.nanoId
  }));

  return (
    <SelectFieldControl
      placeholder="Select Job"
      options={options}
      onChange={(value, selectedOption) =>
        handleSelectJob(selectedOption as JobOption)
      }
      value={selectedJob.id}
      errorMessage={jobsErrorMessage}
      isLoading={jobsIsLoading}
      onInputChange={debouncedFilterJobs}
      reactSelectStyles={{
        control: (provided: SelectStyleObject) => ({
          ...provided,
          fontWeight: 500
        })
      }}
    />
  );
}

export default SelectJobField;
