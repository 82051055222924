import { Icon, IconProps } from '@chakra-ui/icon';

const NoteIcon = ({
  w = '16px',
  h = '16px',
  fill = 'gray.500',
  ...props
}: IconProps) => (
  <Icon viewBox="0 0 16 16" w={w} h={h} fill={fill} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.637 4.584c0-.288.244-.521.545-.521h.091a1 1 0 0 1 1 1v.565c0 .288.244.521.546.521h1.09a.534.534 0 0 0 .546-.521c0-.288.244-.522.545-.522.301 0 .546.234.546.522 0 .288.244.521.545.521h1.091a.534.534 0 0 0 .546-.521v-.566a1 1 0 0 1 1-1h.09c.302 0 .546.234.546.522v7.826a.534.534 0 0 1-.546.522H4.183a.534.534 0 0 1-.545-.522V4.584ZM2 13.978c0 .288.244.522.545.522h10.91a.534.534 0 0 0 .545-.522V3.022a.534.534 0 0 0-.546-.522H2.545A.534.534 0 0 0 2 3.022v10.956Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.181 8.24a.522.522 0 0 0-.521-.521H6.34a.522.522 0 1 0 0 1.043h3.32a.522.522 0 0 0 .521-.522ZM10.181 10.324a.522.522 0 0 0-.521-.522H6.34a.522.522 0 0 0 0 1.044h3.32a.522.522 0 0 0 .521-.522Z"
    />
  </Icon>
);
export default NoteIcon;
