import { ReactNode } from 'react';
import {
  Heading as ChakraHeading,
  HeadingProps as ChakraHeadingProps
} from '@chakra-ui/layout';

type level = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

interface HeadingProps extends ChakraHeadingProps {
  level: level;
  size?: level;
  children: ReactNode;
  alignment?: 'left' | 'right' | 'center';
}

const Heading = ({
  children,
  level,
  size,
  alignment,
  ...props
}: HeadingProps) => {
  return (
    <ChakraHeading
      as={level}
      textAlign={alignment}
      size={size ?? level}
      {...props}
    >
      {children}
    </ChakraHeading>
  );
};

export default Heading;
