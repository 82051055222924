import join from 'lodash/join';
import lowerCase from 'lodash/lowerCase';
import startCase from 'lodash/startCase';

import {
  JobCandidateFirstName,
  JobCandidateLastName,
  JobCandidateName
} from '../../jobCandidatesTypes';

function renderJobCandidateName(jobCandidate: {
  name: JobCandidateName;
  firstName?: JobCandidateFirstName;
  lastName?: JobCandidateLastName;
}) {
  const { firstName, lastName, name } = jobCandidate;

  return startCase(
    lowerCase(
      firstName && lastName ? join([firstName, lastName], ' ') : name || ''
    )
  );
}

export default renderJobCandidateName;
