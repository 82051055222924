import {
  FieldBrand,
  MayBe,
  ItemID,
  ItemUUID,
  ItemNanoId,
  ItemCreatedAt
} from '../../../types';

import {
  UserId,
  UserNanoId,
  UserName,
  UserImage
} from '../../users/usersTypes';

export type SkillLicenseId = FieldBrand<ItemID, 'SkillLicenseId'>;
export type SkillLicenseUuid = FieldBrand<ItemUUID, 'SkillLicenseUuid'>;
export type MayBeSkillLicenseUuid = MayBe<SkillLicenseUuid>;
export type SkillLicenseNanoId = FieldBrand<ItemNanoId, 'SkillLicenseNanoId'>;
export type MayBeSkillLicenseNanoId = MayBe<SkillLicenseNanoId>;
export type SkillLicenseName = FieldBrand<string, 'SkillLicenseName'>;
export type SkillLicenseUserId = FieldBrand<UserId, 'SkillLicenseUserId'>;
export type SkillLicenseUser = FieldBrand<
  {
    id: UserId;
    nanoId: UserNanoId;
    name: UserName;
    image: UserImage;
  },
  'SkillLicenseUser'
>;
export type SkillLicenseCreatedAt = FieldBrand<
  ItemCreatedAt,
  'SkillLicenseCreatedAt'
>;

export const enum SkillLicenseFields {
  ID = 'id',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  NAME = 'name',
  USER_ID = 'userId',
  USER = 'user',
  CREATED_AT = 'createdAt'
}
