import { Collapse, Stack, StackDivider } from '@chakra-ui/react';
import { MouseEvent, useState } from 'react';
import { Button } from '../../../../../../../../../../../../../helpers/Button';
import {
  ViewJobCandidateModalPhoneSidebarCaptureReferrals,
  ViewJobCandidateModalPhoneSidebarEnterCallNote
} from '../../../../actions';

type ViewJobCandidateModalPhoneSidebarNotInterestedType =
  | 'call-note'
  | 'capture-referrals'
  | 'complete-task'
  | 'add-reminder';

type ViewJobCandidateModalPhoneSidebarNotInterestedHelperProps = {
  onSave: () => void;
};

const ViewJobCandidateModalPhoneSidebarNotInterestedHelper = ({
  onSave
}: ViewJobCandidateModalPhoneSidebarNotInterestedHelperProps) => {
  const [selectedOption, setSelectedOption] =
    useState<ViewJobCandidateModalPhoneSidebarNotInterestedType | null>();

  const toggleSelection = (e: MouseEvent<HTMLButtonElement>) => {
    const { value } = e.currentTarget;

    if (!value || selectedOption === value) {
      setSelectedOption(null);
      return;
    }

    setSelectedOption(
      value as ViewJobCandidateModalPhoneSidebarNotInterestedType
    );
  };

  const onDiscard = () => {
    setSelectedOption(null);
  };

  return (
    <>
      <Collapse in={!selectedOption} unmountOnExit>
        <Stack
          flex={1}
          spacing={0}
          overflow="hidden"
          borderRadius="4px"
          border="1px solid"
          borderColor="gray.300"
          divider={<StackDivider />}
          boxShadow="0px 2px 2px rgba(23, 25, 26, 0.06)"
        >
          <Stack direction="row" spacing={0} divider={<StackDivider />}>
            <Button
              w="full"
              size="small"
              hierarchy="tertiary"
              borderRadius={0}
              boxShadow="none"
              border="none"
              value="call-note"
              onClick={toggleSelection}
            >
              Enter a new call note
            </Button>

            <Button
              w="full"
              size="small"
              border="none"
              boxShadow="none"
              borderRadius={0}
              hierarchy="tertiary"
              value="capture-referrals"
              onClick={toggleSelection}
            >
              Capture Referrals
            </Button>
          </Stack>
        </Stack>
      </Collapse>

      <Collapse in={selectedOption === 'call-note'} unmountOnExit>
        <ViewJobCandidateModalPhoneSidebarEnterCallNote
          isNotInterested
          onDiscard={onDiscard}
          onSave={onSave}
        />
      </Collapse>

      <Collapse in={selectedOption === 'capture-referrals'} unmountOnExit>
        <ViewJobCandidateModalPhoneSidebarCaptureReferrals
          onDiscard={onDiscard}
          onSave={onSave}
        />
      </Collapse>
    </>
  );
};

export default ViewJobCandidateModalPhoneSidebarNotInterestedHelper;
