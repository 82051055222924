import { FetchWorkflowStageTasksCacheKey } from '../../workflowStageTasksTypes';

import { useCreateItem } from '../../../common/hooks/useCreateItem';

// import {
//   WorkflowStageTasksBffRequests,
//   CreateWorkflowStageTaskRequestData,
//   CreateWorkflowStageTaskErrorResponse
// } from '../../WorkflowStageTasksBffRequests';

import {
  CreateWorkflowStageTaskRequestData,
  CreateWorkflowStageTaskErrorResponse
} from '../../WorkflowStageTasksRequests';
import { WorkflowStageTasksBffRequests } from '../../WorkflowStageTasksBffRequests';

interface CreateWorkflowStageTaskOptions {
  cacheKeys?: FetchWorkflowStageTasksCacheKey[];
}

function useCreateWorkflowStageTask({
  cacheKeys = []
}: CreateWorkflowStageTaskOptions) {
  const {
    createItemData,
    createItemError,
    createItemErrorMessage,
    createItemIsLoading,
    createItem,
    createItemReset
  } = useCreateItem<
    CreateWorkflowStageTaskRequestData,
    CreateWorkflowStageTaskErrorResponse
  >({
    mutationFn: (queryInput) =>
      // WorkflowStageTasksRequests.createWorkflowStageTask(queryInput),
      WorkflowStageTasksBffRequests.createWorkflowStageTask(queryInput),
    cacheKeys
  });

  return {
    createWorkflowStageTaskData: createItemData,
    createWorkflowStageTaskError: createItemError,
    createWorkflowStageTaskErrorMessage: createItemErrorMessage,
    createWorkflowStageTaskIsLoading: createItemIsLoading,
    createWorkflowStageTask: createItem,
    createWorkflowStageTaskReset: createItemReset
  };
}

export default useCreateWorkflowStageTask;
