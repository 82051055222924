import {
  FieldBrand,
  ItemID,
  ItemUUID,
  ItemNanoId,
  ItemCreatedAt,
  MayBe
} from '../../../types';

import {
  CompanyId,
  CompanyNanoId,
  CompanyName,
  CompanyImage
} from '../../companies/companiesTypes';

import { RoleId, RoleName, RoleNanoId } from '../../roles/rolesTypes';

import {
  UserId,
  UserNanoId,
  UserBlockedAt,
  UserName,
  UserFullName,
  UserEmail,
  UserCreatedAt,
  MayBeUserCompanyEmail,
  UserImage,
  UserRoles,
  UserAccountType
} from '../../users/usersTypes';

export type CompanyMemberId = FieldBrand<ItemID, 'CompanyMemberId'>;
export type CompanyMemberUuid = FieldBrand<ItemUUID, 'CompanyMemberUuid'>;
export type MayBeCompanyMemberUuid = MayBe<CompanyMemberUuid>;
export type CompanyMemberNanoId = FieldBrand<ItemNanoId, 'CompanyMemberNanoId'>;
export type MayBeCompanyMemberNanoId = MayBe<CompanyMemberNanoId>;
export type CompanyMemberCompanyId = FieldBrand<
  CompanyId,
  'CompanyMemberCompanyId'
>;
export type MayBeCompanyMemberCompanyId = MayBe<CompanyMemberCompanyId>;
export type CompanyMemberCompany = FieldBrand<
  {
    id: CompanyId;
    nanoId: CompanyNanoId;
    name: CompanyName;
    image: CompanyImage;
  },
  'CompanyMemberCompany'
>;
export type MayBeCompanyMemberCompany = MayBe<CompanyMemberCompany>;
export type CompanyMemberCompanyNanoId = CompanyNanoId;
export type CompanyMemberCompanyName = CompanyName;
export type CompanyMemberCompanyImage = CompanyImage;
export type CompanyMemberUserId = FieldBrand<UserId, 'CompanyMemberUserId'>;
export type MayBeCompanyMemberUserId = MayBe<CompanyMemberUserId>;
export type CompanyMemberUser = FieldBrand<
  {
    id: UserId;
    nanoId: UserNanoId;
    blockedAt: UserBlockedAt;
    name: UserName;
    fullName: UserFullName;
    email: UserEmail;
    createdAt: UserCreatedAt;
    companyEmail: MayBeUserCompanyEmail;
    image: UserImage;
    roles: UserRoles;
    accountType: UserAccountType;
  },
  'CompanyMemberUser'
>;
export type MayBeCompanyMemberUser = MayBe<CompanyMemberUser>;
export type CompanyMemberRoleId = FieldBrand<RoleId, 'CompanyMemberRoleId'>;
export type MayBeCompanyMemberRoleId = MayBe<CompanyMemberRoleId>;
export type CompanyMemberRole = FieldBrand<
  {
    id: RoleId;
    nanoId: RoleNanoId;
    name: RoleName;
  },
  'CompanyMemberRole'
>;
export type MayBeCompanyMemberRole = MayBe<CompanyMemberRole>;
export type CompanyMemberCreatedAt = FieldBrand<
  ItemCreatedAt,
  'CompanyMemberCreatedAt'
>;

export const enum CompanyMemberFields {
  ID = 'id',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  COMPANY_ID = 'companyId',
  USER_BASE_USER_ID = 'userBaseUserId',
  COMPANY = 'company',
  USER_ID = 'userId',
  USER = 'user',
  ROLE_ID = 'roleId',
  ROLE = 'role',
  USER_NAME = 'userName',
  NAME_OR_EMAIL = 'nameOrEmail',
  CREATED_AT = 'createdAt',
  USER_ACCOUNT_TYPE_NAME = 'userAccountTypeName',
  COMPANY_NANO_ID = 'company.nanoId',
  COMPANY_NAME = 'company.name',
  COMPANY_IMAGE = 'company.image'
}
