import {
  FetchContactsQuery,
  FetchContactsCountType,
  FetchContactsPage,
  FetchContactsPageSize,
  FetchContactsFilters,
  FetchContactsSort,
  FetchContactsIndexQueryName,
  FetchContactsIndexCountType,
  FetchContactsIndexPage,
  FetchContactsIndexPageSize,
  FetchContactsIndexFilters,
  FetchContactsIndexSort,
  FetchContactQuery,
  FetchContactIndexQueryName,
  CreateContactError,
  UpdateContactError,
  DeleteContactError,
  SendLeadInvitationContactError,
  ToggleRemovedContactError,
  ContactId,
  ContactNanoId,
  ContactName,
  MayBeContactFirstName,
  MayBeContactLastName,
  MayBeContactMiddleName,
  MayBeContactLinkedinUrl,
  MayBeContactImageId,
  MayBeContactCityId,
  ContactUserId,
  ContactAccountTypeId,
  SendLeadInvitationsContactError
} from './contactsTypes';

import { BaseBffRequests } from '../BaseBffRequests';

import { ContactsBffRoutes } from './ContactsBffRoutes';

interface FetchContactsRequestParams {
  query: FetchContactsQuery;
  countType?: FetchContactsCountType;
  page?: FetchContactsPage;
  pageSize?: FetchContactsPageSize;
  filters?: FetchContactsFilters;
  sort?: FetchContactsSort;
}

interface FetchContactsIndexRequestParams {
  queryName: FetchContactsIndexQueryName;
  countType?: FetchContactsIndexCountType;
  page?: FetchContactsIndexPage;
  pageSize?: FetchContactsIndexPageSize;
  filters?: FetchContactsIndexFilters;
  sort?: FetchContactsIndexSort;
}

interface FetchContactRequestParams {
  query: FetchContactQuery;
}

interface FetchContactIndexRequestParams {
  queryName: FetchContactIndexQueryName;
}

export interface ContactRequestData {
  name?: ContactName;
  firstName?: MayBeContactFirstName;
  lastName?: MayBeContactLastName;
  middleName?: MayBeContactMiddleName;
  linkedinUrl?: MayBeContactLinkedinUrl;
  imageId?: MayBeContactImageId;
  cityId?: MayBeContactCityId;
}

export interface CreateContactDefaultRequestData extends ContactRequestData {
  id?: ContactId;
  userId?: ContactUserId;
  nanoId: ContactNanoId;
}

export type CreateContactRequestData = CreateContactDefaultRequestData;

export type UpdateContactRequestData = ContactRequestData;
export type SendLeadInvitationContactRequestData = ContactRequestData;
export type ToggleRemovedContactRequestData = ContactRequestData;

export interface SendLeadInvitationsContactRequestData {
  ids: ContactId[];
  accountTypeId: ContactAccountTypeId;
}

export interface DeleteContactRequestData {
  contactNanoId: ContactNanoId;
}

export interface CreateContactErrorResponse {
  message: CreateContactError;
}

export interface UpdateContactErrorResponse {
  message: UpdateContactError;
}

export interface DeleteContactErrorResponse {
  message: DeleteContactError;
}

export interface SendLeadInvitationContactErrorResponse {
  message: SendLeadInvitationContactError;
}

export interface SendLeadInvitationsContactErrorResponse {
  message: SendLeadInvitationsContactError;
}

export interface ToggleRemovedContactErrorResponse {
  message: ToggleRemovedContactError;
}

export class ContactsBffRequests extends BaseBffRequests {
  static fetchContacts<T>(params: FetchContactsRequestParams) {
    return this.fetchItems<T>(ContactsBffRoutes.fetchContactsRoute(), params);
  }

  static fetchContactsIndex<T>(params: FetchContactsIndexRequestParams) {
    return this.fetchItemsIndex<T>(
      ContactsBffRoutes.fetchContactsIndexRoute(),
      params
    );
  }

  static fetchContact<T>(
    contactId: ContactId,
    params: FetchContactRequestParams
  ) {
    return this.fetchItem<T>(
      ContactsBffRoutes.fetchContactRoute(contactId),
      params
    );
  }

  static fetchContactIndex<T>(
    contactNanoId: ContactNanoId,
    params: FetchContactIndexRequestParams
  ) {
    return this.fetchItemIndex<T>(
      ContactsBffRoutes.fetchContactIndexRoute(contactNanoId),
      params
    );
  }

  static fetchContactByNanoId<T>(
    contactNanoId: ContactNanoId,
    params: FetchContactRequestParams
  ) {
    return this.fetchItemByNanoId<T>(
      ContactsBffRoutes.fetchContactByNanoIdRoute(contactNanoId),
      params
    );
  }

  static createContact<T>(createContactData: CreateContactRequestData) {
    return this.createItem<T, CreateContactRequestData>(
      ContactsBffRoutes.createContactRoute(),
      createContactData
    );
  }

  static async updateContact<T>(
    updateContactNanoId: ContactNanoId,
    updateContactData: UpdateContactRequestData
  ) {
    return this.updateItem<T, UpdateContactRequestData>(
      ContactsBffRoutes.updateContactRoute(updateContactNanoId),
      updateContactData
    );
  }

  static async deleteContact<T>(deleteContactNanoId: ContactNanoId) {
    return this.deleteItem<T>(
      ContactsBffRoutes.deleteContactRoute(deleteContactNanoId)
    );
  }

  static async sendLeadInvitationContact<T>(
    sendLeadInvitationContactNanoId: ContactNanoId
  ) {
    return this.postItem<T, UpdateContactRequestData>(
      ContactsBffRoutes.sendLeadInvitationContactRoute(
        sendLeadInvitationContactNanoId
      ),
      {}
    );
  }

  static async sendLeadInvitationsContact<T>(
    sendLeadInvitationsContactData: SendLeadInvitationsContactRequestData
  ) {
    return this.postItems<T, SendLeadInvitationsContactRequestData>(
      ContactsBffRoutes.sendLeadInvitationsContactRoute(),
      sendLeadInvitationsContactData
    );
  }

  static async toggleRemovedContact<T>(
    toggleRemovedContactNanoId: ContactNanoId
  ) {
    return this.postItem<T, UpdateContactRequestData>(
      ContactsBffRoutes.toggleRemovedContactRoute(toggleRemovedContactNanoId),
      {}
    );
  }
}
