import {
  IndustryExpertiseRequirements,
  IndustryExpertiseRequirementsTypes
} from '../../industryExpertisesTypes';

function renderIndustryExpertiseRequirements(
  industryExpertiseRequirements: IndustryExpertiseRequirements
) {
  switch (industryExpertiseRequirements) {
    case IndustryExpertiseRequirementsTypes.NONE:
      return 'None';
    case IndustryExpertiseRequirementsTypes.VERY_IMPORTANT:
      return 'Very Important';
    case IndustryExpertiseRequirementsTypes.IMPORTANT:
      return 'Important';
    case IndustryExpertiseRequirementsTypes.NICE_TO_HAVE:
      return 'Nice to have';
    default:
      return 'Unknown';
  }
}

export default renderIndustryExpertiseRequirements;
