import {
  SkillBadgeItemBffID,
  SkillBadgeItemBffNanoID,
  FetchSkillBadgesBffURL,
  FetchSkillBadgeBffURL,
  FetchSkillBadgeByNanoIdBffURL,
  CreateSkillBadgeBffURL,
  UpdateSkillBadgeBffURL,
  DeleteSkillBadgeBffURL
} from './skillBadgesTypes';

import { BaseBffRoutes } from '../BaseBffRoutes';

export class SkillBadgesBffRoutes extends BaseBffRoutes {
  protected static route = 'skillBadges';

  static fetchSkillBadgesRoute() {
    return this.fetchItemsRoute<FetchSkillBadgesBffURL>();
  }

  static fetchSkillBadgesIndexRoute() {
    return this.fetchItemsIndexRoute<FetchSkillBadgesBffURL>();
  }

  static fetchSkillBadgeRoute(skillBadgeItemBffID: SkillBadgeItemBffID) {
    return this.fetchItemRoute<FetchSkillBadgeBffURL>(skillBadgeItemBffID);
  }

  static fetchSkillBadgeIndexRoute(
    skillBadgeItemBffNanoID: SkillBadgeItemBffNanoID
  ) {
    return this.fetchItemIndexRoute<FetchSkillBadgeBffURL>(
      skillBadgeItemBffNanoID
    );
  }

  static fetchSkillBadgeByNanoIdRoute(
    skillBadgeItemBffNanoID: SkillBadgeItemBffNanoID
  ) {
    return this.fetchItemByNanoIdRoute<FetchSkillBadgeByNanoIdBffURL>(
      skillBadgeItemBffNanoID
    );
  }

  static createSkillBadgeRoute() {
    return this.createItemRoute<CreateSkillBadgeBffURL>();
  }

  static updateSkillBadgeRoute(
    skillBadgeItemBffNanoID: SkillBadgeItemBffNanoID
  ) {
    return this.updateItemRoute<UpdateSkillBadgeBffURL>(
      skillBadgeItemBffNanoID
    );
  }

  static deleteSkillBadgeRoute(
    skillBadgeItemBffNanoID: SkillBadgeItemBffNanoID
  ) {
    return this.deleteItemRoute<DeleteSkillBadgeBffURL>(
      skillBadgeItemBffNanoID
    );
  }
}
