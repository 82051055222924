import {
  FieldBrand,
  ItemID,
  ItemUUID,
  ItemNanoId,
  ItemCreatedAt,
  MayBe
} from '../../../types';
import {
  CandidateSubmissionId,
  CandidateSubmissionMessage,
  CandidateSubmissionShareSettings,
  CandidateSubmissionStatus,
  CandidateSubmissionUserId
} from '../../candidateSubmissions/candidateSubmissionsTypes';
import {
  JobCandidateCandidateShares,
  JobCandidateCities,
  JobCandidateCity,
  JobCandidateCompanyName,
  JobCandidateCreatedAt,
  JobCandidateFirstName,
  JobCandidateId,
  JobCandidateImage,
  JobCandidateJob,
  JobCandidateJobTitle,
  JobCandidateLastName,
  JobCandidateLikedJobCandidates,
  JobCandidateLinkedinUrl,
  JobCandidateName,
  JobCandidateNanoId,
  JobCandidatePipelineStatus,
  JobCandidateSearchType,
  JobCandidateSearchTypeLabel,
  JobCandidateWorkflowStage,
  JobCandidateWorkflowStageTask,
  JobCandidateWorkflowStageTaskApplicationType,
  MayBeJobCandidateCompany,
  MayBeJobCandidateDisqualifiedAt,
  MayBeJobCandidateDueDate,
  MayBeJobCandidateNewWorkflowStageTaskAt,
  MayBeJobCandidateViewedAt,
  MayBeJobCandidateWorkflowStageTaskScheduledAt
} from '../../jobCandidates/jobCandidatesTypes';

export type CandidateSubmissionJobCandidateId = FieldBrand<
  ItemID,
  'CandidateSubmissionJobCandidateId'
>;
export type CandidateSubmissionJobCandidateUuid = FieldBrand<
  ItemUUID,
  'CandidateSubmissionJobCandidateUuid'
>;
export type MayBeCandidateSubmissionJobCandidateUuid =
  MayBe<CandidateSubmissionJobCandidateUuid>;
export type CandidateSubmissionJobCandidateNanoId = FieldBrand<
  ItemNanoId,
  'CandidateSubmissionJobCandidateNanoId'
>;
export type MayBeCandidateSubmissionJobCandidateNanoId =
  MayBe<CandidateSubmissionJobCandidateNanoId>;
export type CandidateSubmissionJobCandidateCreatedAt = FieldBrand<
  ItemCreatedAt,
  'CandidateSubmissionJobCandidateCreatedAt'
>;
export type CandidateSubmissionJobCandidateJobCandidateId = FieldBrand<
  JobCandidateId,
  'CandidateSubmissionJobCandidateJobCandidateId'
>;
export type CandidateSubmissionJobCandidateJobCandidate = FieldBrand<
  {
    name: JobCandidateName;
    firstName: JobCandidateFirstName;
    lastName: JobCandidateLastName;
    id: JobCandidateId;
    nanoId: JobCandidateNanoId;
    image: JobCandidateImage;
    companyName: JobCandidateCompanyName;
    jobTitle: JobCandidateJobTitle;
    city: JobCandidateCity;
    cities: JobCandidateCities;
    linkedinUrl: JobCandidateLinkedinUrl;
    searchType: JobCandidateSearchType;
    searchTypeLabel: JobCandidateSearchTypeLabel;
    likedJobCandidates: JobCandidateLikedJobCandidates;
    createdAt: JobCandidateCreatedAt;
    pipelineStatus: JobCandidatePipelineStatus;
    job: JobCandidateJob;
    workflowStage: JobCandidateWorkflowStage;
    disqualifiedAt: MayBeJobCandidateDisqualifiedAt;
    workflowStageTask: JobCandidateWorkflowStageTask;
    company: MayBeJobCandidateCompany;
    candidateShares: JobCandidateCandidateShares;
    dueDate: MayBeJobCandidateDueDate;
    workflowStageTaskScheduledAt: MayBeJobCandidateWorkflowStageTaskScheduledAt;
    newWorkflowStageTaskAt: MayBeJobCandidateNewWorkflowStageTaskAt;
    viewedAt: MayBeJobCandidateViewedAt;
    workflowStageTaskApplicationType: JobCandidateWorkflowStageTaskApplicationType;
  },
  'CandidateSubmissionJobCandidateJobCandidate'
>;
export type CandidateSubmissionJobCandidateCandidateSubmissionId = FieldBrand<
  CandidateSubmissionId,
  'CandidateSubmissionJobCandidateCandidateSubmissionId'
>;
export type CandidateSubmissionJobCandidateCandidateSubmission = FieldBrand<
  {
    id: CandidateSubmissionId;
    status: CandidateSubmissionStatus;
    message: CandidateSubmissionMessage;
    shareSettings: CandidateSubmissionShareSettings;
    userId: CandidateSubmissionUserId;
  },
  'CandidateSubmissionJobCandidateCandidateSubmission'
>;

export const enum CandidateSubmissionJobCandidateFields {
  ID = 'id',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  JOB_CANDIDATE_ID = 'jobCandidateId',
  JOB_CANDIDATE = 'jobCandidate',
  JOB_CANDIDATE_NAME = 'jobCandidateName',
  CANDIDATE_SUBMISSION_ID = 'candidateSubmissionId',
  CANDIDATE_SUBMISSION = 'candidateSubmission',
  CREATED_AT = 'createdAt',
  JOB_ID = 'jobId',
  RECIPIENT_IDS = 'recipientIds',
  JOB_CANDIDATE_PIPELINE_STATUS = 'jobCandidatePipelineStatus'
}
