import { ReactNode } from 'react';
import NextLink from 'next/link';
import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps
} from '@chakra-ui/layout';
import { Url } from 'url';

interface BasicLinkProps extends Omit<ChakraLinkProps, 'to' | 'as'> {
  children: ReactNode;
}

interface InternalLinkProps extends BasicLinkProps {
  to: Url;
  as?: Url;
  replace?: boolean;
  scroll?: boolean;
  shallow?: boolean;
  prefetch?: boolean;
  locale?: string | false;
  isExternal?: never;
}

export interface ExternalLinkProps extends BasicLinkProps {
  to: string;
  isExternal?: boolean;
  as?: never;
  replace?: never;
  scroll?: never;
  shallow?: never;
  prefetch?: never;
  locale?: never;
}

export type LinkProps = InternalLinkProps | ExternalLinkProps;

const Link = ({
  to,
  isExternal,
  as,
  replace,
  scroll,
  shallow,
  prefetch,
  locale,
  children,
  ...props
}: LinkProps) => {
  return (
    <>
      {isExternal ? (
        <ChakraLink href={to} isExternal {...props}>
          {children}
        </ChakraLink>
      ) : (
        <NextLink
          href={to}
          passHref
          as={as}
          replace={replace}
          scroll={scroll}
          shallow={shallow}
          prefetch={prefetch}
          locale={locale}
        >
          <ChakraLink {...props}>{children}</ChakraLink>
        </NextLink>
      )}
    </>
  );
};

export default Link;
