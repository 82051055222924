import { Flex } from '@chakra-ui/react';
import { JobCandidateNanoId } from '../../../../../jobCandidates/jobCandidatesTypes';
import { JobId, JobJobTitle, JobName, JobNanoId } from '../../../../jobsTypes';
import { JobCandidateDetailsJobCandidate } from '../../ViewJobCandidateDetailsPage.types';
import { ViewJobCandidateDetailsBio } from '../ViewJobCandidateDetailsBio';
import { ViewJobCandidateDetailsCandidateHeaderMenu } from '../ViewJobCandidateDetailsCandidateHeaderMenu';
import { ViewJobCandidateDetailsStageTasksManager } from '../ViewJobCandidateDetailsStageTasksManager';
import { RefObject } from 'react';

interface ViewJobCandidateDetailsHeaderProps {
  requiresCredit: boolean;
  jobCandidate: JobCandidateDetailsJobCandidate;
  jobCandidateNanoId: JobCandidateNanoId;
  job: {
    id: JobId;
    nanoId: JobNanoId;
    jobTitle: JobJobTitle;
    name: JobName;
  };
  isBioOutsideViewport?: RefObject<HTMLDivElement>;
}

function ViewJobCandidateDetailsHeader({
  job,
  jobCandidate,
  requiresCredit,
  jobCandidateNanoId,
  isBioOutsideViewport
}: ViewJobCandidateDetailsHeaderProps) {
  return (
    <Flex
      p={4}
      gap={4}
      justifyContent="space-between"
      flexDir={{ base: 'column', xl: 'row' }}
      bg="white"
    >
      <ViewJobCandidateDetailsBio
        requiresCredit={requiresCredit}
        jobCandidate={jobCandidate}
        isBioOutsideViewport={isBioOutsideViewport}
        job={job}
      />

      <Flex flexDir="column">
        <ViewJobCandidateDetailsCandidateHeaderMenu
          jobNanoId={job.nanoId}
          jobCandidateNanoId={jobCandidateNanoId}
        />

        <ViewJobCandidateDetailsStageTasksManager
          jobCandidateNanoId={jobCandidateNanoId}
          requiresCredit={requiresCredit}
          jobCandidate={jobCandidate}
          jobCandidateDetails={jobCandidate}
          job={job}
        />
      </Flex>
    </Flex>
  );
}

export default ViewJobCandidateDetailsHeader;
