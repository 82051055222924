import { PipelineRoutes } from '../pipeline/PipelineRoutes';

const baseUpdatesPath = PipelineRoutes.updates();

export class UpdatesRoutes {
  static index() {
    return `${baseUpdatesPath}`;
  }

  static important() {
    return `${baseUpdatesPath}/important`;
  }

  static schedule() {
    return `${baseUpdatesPath}/schedule`;
  }
  static feedback() {
    return `${baseUpdatesPath}/feedback`;
  }
  static comments() {
    return `${baseUpdatesPath}/comments`;
  }
  static email() {
    return `${baseUpdatesPath}/email`;
  }
  static alerts() {
    return `${baseUpdatesPath}/alerts`;
  }
  static reminders() {
    return `${baseUpdatesPath}/reminders`;
  }
  static done() {
    return `${baseUpdatesPath}/done`;
  }
}
