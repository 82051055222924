import {
  FetchWorkflowStageTasksCacheKey,
  FetchWorkflowStageTaskCacheKey
} from './workflowStageTasksTypes';

import { WorkflowStageNanoId } from '../workflowStages/workflowStagesTypes';

export class WorkflowStageTasksCache {
  static indexCacheKey() {
    return 'workflowStageTasks' as FetchWorkflowStageTasksCacheKey;
  }

  static workflowStageIndexCacheKey(workflowStageNanoId: WorkflowStageNanoId) {
    return `workflowStageTasks-${workflowStageNanoId}` as FetchWorkflowStageTasksCacheKey;
  }

  static showCacheKey() {
    return 'workflowStageTask' as FetchWorkflowStageTaskCacheKey;
  }
}
