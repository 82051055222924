import { Box, Flex, HStack, Stack, StackDivider } from '@chakra-ui/layout';
import {
  IconButton as ChakraIconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList
} from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import filter from 'lodash/filter';
import lodashSize from 'lodash/size';

import { CancelIcon } from '../../icons/CancelIcon';
import { EditIcon } from '../../icons/EditIcon';
import { MoreIcon } from '../../icons/MoreIcon';

import { IconButton } from '../buttons/IconButton';

import { Text } from '../Text';

interface EditDeleteButtonGroupDefaultProps {
  isDisabled?: boolean;
  size?: 'sm' | 'md';
}

interface EditDeleteButtonGroupWithDeleteButtonProps {
  withoutDeleteButton?: never;
  deleteDisabled?: boolean;
  onDelete: () => void;
}

interface EditDeleteButtonGroupWithoutDeleteButtonProps {
  withoutDeleteButton: boolean;
  deleteDisabled?: never;
  onDelete?: never;
}

interface EditDeleteButtonGroupWithButtonMoreProps {
  withMoreButton: boolean;
  moreDisabled?: boolean;
  moreMenuList: {
    title: string;
    onClick: () => void;
    icon?: FunctionComponent;
  }[];
}

interface EditDeleteButtonGroupWithoutMoreButtonProps {
  withMoreButton?: never;
  moreDisabled?: never;
  moreMenuList?: never;
}

interface EditDeleteButtonGroupWithEditButtonProps {
  withoutEditButton?: never;
  editDisabled?: boolean;
  onEdit: () => void;
}

interface EditDeleteButtonGroupWithoutEditButtonProps {
  withoutEditButton: boolean;
  editDisabled?: never;
  onEdit?: never;
}

type EditDeleteButtonGroupProps = EditDeleteButtonGroupDefaultProps &
  (
    | EditDeleteButtonGroupWithDeleteButtonProps
    | EditDeleteButtonGroupWithoutDeleteButtonProps
  ) &
  (
    | EditDeleteButtonGroupWithEditButtonProps
    | EditDeleteButtonGroupWithoutEditButtonProps
  ) &
  (
    | EditDeleteButtonGroupWithButtonMoreProps
    | EditDeleteButtonGroupWithoutMoreButtonProps
  );

function EditDeleteButtonGroup({
  withoutDeleteButton,
  withoutEditButton,
  withMoreButton,
  isDisabled,
  editDisabled,
  deleteDisabled,
  moreDisabled,
  onEdit,
  onDelete,
  moreMenuList,
  size = 'sm'
}: EditDeleteButtonGroupProps) {
  const removedButtonsArray = [
    withoutDeleteButton,
    withoutEditButton,
    !withMoreButton
  ];
  const unremovedButtons = filter(
    removedButtonsArray,
    (removedButton) => !removedButton
  );
  const mdWidth = lodashSize(unremovedButtons) * 8;
  const smWidth = lodashSize(unremovedButtons) * 6;
  return (
    <Stack
      spacing={0}
      bg="white"
      ml="auto"
      height={size === 'md' ? 8 : 6}
      width={size === 'md' ? `${mdWidth}` : `${smWidth}`}
      color="gray.500"
      borderRadius={4}
      direction="row"
      border="1px solid"
      borderColor="gray.200"
      overflow="hidden"
      boxShadow="0px 1.2px 1.2px rgba(23, 25, 26, 0.06)"
      divider={<StackDivider borderColor="gray.200" />}
    >
      {withoutDeleteButton ? null : (
        <Flex
          width={size === 'md' ? 8 : 6}
          alignItems="stretch"
          justifyContent="stretch"
        >
          <IconButton
            p="0"
            h="100%"
            w="100%"
            disabled={isDisabled || deleteDisabled}
            minW={0}
            size="small"
            border="none"
            display="flex"
            borderRadius={0}
            alignItems="center"
            hierarchy="unstyled"
            aria-label="Cancel Icon"
            onClick={onDelete}
            icon={<CancelIcon h={4} />}
            _hover={{ bg: 'gray.100', _disabled: { bg: 'white' } }}
          />
        </Flex>
      )}

      {withoutEditButton ? null : (
        <Flex
          width={size === 'md' ? 8 : 6}
          alignItems="center"
          justifyContent="center"
        >
          <IconButton
            p="0"
            h="100%"
            w="100%"
            minW={0}
            disabled={isDisabled || editDisabled}
            size="small"
            border="none"
            display="flex"
            borderRadius={0}
            alignItems="center"
            hierarchy="unstyled"
            aria-label="Edit Icon"
            onClick={onEdit}
            icon={<EditIcon h={4} />}
            _hover={{ bg: 'gray.100', _disabled: { bg: 'white' } }}
          />
        </Flex>
      )}
      {withMoreButton ? (
        <Flex
          width={size === 'md' ? 8 : 6}
          alignItems="center"
          justifyContent="center"
        >
          <Menu isLazy>
            <MenuButton
              as={ChakraIconButton}
              h="100%"
              w="100%"
              minW={0}
              minH={0}
              disabled={isDisabled || moreDisabled}
              size="sm"
              border="none"
              display="flex"
              justifyContent="center"
              borderRadius={0}
              alignItems="center"
              variant="unstyled"
              aria-label="More Icon"
              sx={{
                span: {
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }
              }}
              _hover={{ bg: 'gray.100', _disabled: { bg: 'white' } }}
            >
              <MoreIcon h={4} w={4} />
            </MenuButton>
            <MenuList>
              {moreMenuList?.map(({ title, onClick, icon }, index) => (
                <MenuItem onClick={onClick} key={`${title}-${index}`}>
                  <HStack spacing={1.5}>
                    {icon ? (
                      <Box as={icon} fill="gray.500" w={4} h={4} />
                    ) : null}
                    <Text textStyle="body1Regular" color="gray.700">
                      {title}
                    </Text>
                  </HStack>
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Flex>
      ) : null}
    </Stack>
  );
}

export default EditDeleteButtonGroup;
