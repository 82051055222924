import {
  FetchCandidateSharesCacheKey,
  FetchCandidateShareCacheKey,
  UpdateCandidateShareNanoId,
  UpdateCandidateShareErrorMessage,
  UpdateCandidateShareIsLoading,
  UpdateCandidateShareID
} from '../../candidateSharesTypes';

import {
  CandidateSharesBffRequests,
  UpdateCandidateShareRequestData,
  UpdateCandidateShareErrorResponse
} from '../../CandidateSharesBffRequests';

import { useUpdateItem } from '../../../common/hooks/useUpdateItem';

interface UpdateCandidateShareOptions {
  candidateShareId: UpdateCandidateShareNanoId | UpdateCandidateShareID;
  cacheKeys?: (FetchCandidateSharesCacheKey | FetchCandidateShareCacheKey)[];
}

function useUpdateCandidateShare({
  candidateShareId,
  cacheKeys = []
}: UpdateCandidateShareOptions) {
  const {
    updateItemData,
    updateItemError,
    updateItemErrorMessage,
    updateItemIsLoading,
    updateItem,
    updateItemReset
  } = useUpdateItem<
    UpdateCandidateShareRequestData,
    UpdateCandidateShareErrorResponse
  >({
    mutationFn: (queryInput) =>
      CandidateSharesBffRequests.updateCandidateShare(
        candidateShareId,
        queryInput
      ),
    cacheKeys
  });

  return {
    updateCandidateShareData: updateItemData,
    updateCandidateShareError: updateItemError,
    updateCandidateShareErrorMessage:
      updateItemErrorMessage as UpdateCandidateShareErrorMessage,
    updateCandidateShareIsLoading:
      updateItemIsLoading as UpdateCandidateShareIsLoading,
    updateCandidateShare: updateItem,
    updateCandidateShareReset: updateItemReset
  };
}

export default useUpdateCandidateShare;
