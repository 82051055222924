import {
  Box,
  ButtonGroup,
  Checkbox,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack
} from '@chakra-ui/react';
import { Button } from '../../../../../../../../../../../helpers/Button';
import { IconButton } from '../../../../../../../../../../../helpers/buttons/IconButton';
import { Text } from '../../../../../../../../../../../helpers/Text';
import { ChevronDownIcon } from '../../../../../../../../../../../icons/ChevronDownIcon';
import { ThumbUpIcon } from '../../../../../../../../../../../icons/ThumbUpIcon';

const ViewJobCandidateModalPhoneSidebarCallNoteHelper = ({
  onDiscard,
  onSave
}: {
  onDiscard: () => void;
  onSave: () => void;
}) => {
  return (
    <Stack spacing={3}>
      <Flex alignItems="center" gap={2} justifyContent="space-between">
        <Flex alignItems="center" gap={1} flex={1} w="100%">
          <Text textStyle="body1Regular" noOfLines={1}>
            Introduction call?
          </Text>

          <Menu isLazy placement="bottom-end">
            <MenuButton
              p={0}
              minH={0}
              minW={0}
              size="small"
              border="none"
              as={IconButton}
              boxShadow="none"
              hierarchy="unstyled"
              aria-label="Dropdown"
              icon={<ChevronDownIcon fill="gray.500" />}
            />
            <MenuList>
              <MenuItem>Screening Call</MenuItem>
            </MenuList>
          </Menu>
        </Flex>

        <Flex
          w={9}
          h={9}
          pt="9px"
          pb="11px"
          bg="green.100"
          alignItems="center"
          justifyContent="center"
          borderRadius="full"
        >
          <ThumbUpIcon color="green.400" w={4} h={4} />
        </Flex>
      </Flex>

      <Box>
        <Checkbox size="sm">Task completed</Checkbox>
      </Box>

      <ButtonGroup justifyContent="flex-end">
        <Button size="small" hierarchy="link" onClick={onDiscard}>
          Cancel
        </Button>

        <Button size="small" onClick={onSave}>
          Continue
        </Button>
      </ButtonGroup>
    </Stack>
  );
};

export default ViewJobCandidateModalPhoneSidebarCallNoteHelper;
