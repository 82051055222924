import {
  Avatar,
  Box,
  Flex,
  HStack,
  Stack,
  useDisclosure
} from '@chakra-ui/react';
import { Button } from '../../../../../../../../../../../helpers/Button';
import { Heading } from '../../../../../../../../../../../helpers/Heading';
import { RelativeDateHelper } from '../../../../../../../../../../../helpers/RelativeDateHelper';
import { Text } from '../../../../../../../../../../../helpers/Text';
import { LocationIcon } from '../../../../../../../../../../../icons/LocationIcon';
import { WorldGlobeIcon } from '../../../../../../../../../../../icons/WorldGlobeIcon';
import { FetchJobResponse } from '../../../../../../../../../queries/fetchJob.query';
import { ViewJobCandidateModalPhoneSidebarJobDetailsPopover } from '../ViewJobCandidateModalPhoneSidebarJobDetailsPopover';
import { ImagesUrl } from '../../../../../../../../../../images/ImagesUrl';

type ViewJobCandidateModalPhoneSidebarJobDetailsCardProps = {
  job: FetchJobResponse;
};

const ViewJobCandidateModalPhoneSidebarJobDetailsCard = ({
  job
}: ViewJobCandidateModalPhoneSidebarJobDetailsCardProps) => {
  const {
    isOpen: isOpenShowDescription,
    onOpen: onOpenShowDescription,
    onClose: onCloseShowDescription
  } = useDisclosure();

  return (
    <ViewJobCandidateModalPhoneSidebarJobDetailsPopover
      job={job}
      isOpenShowDescription={isOpenShowDescription}
      onCloseShowDescription={onCloseShowDescription}
    >
      <Stack>
        <Stack
          bg="white"
          spacing={0}
          borderRadius={4}
          border="1px solid"
          borderColor="gray.200"
        >
          <Stack spacing={6} p={3}>
            <Flex alignItems="center">
              {job.hiringManager ? (
                <Stack
                  spacing={2}
                  borderRight={{ md: '1px solid' }}
                  borderColor={{ md: 'gray.200' }}
                  width="230px"
                  pr={6}
                >
                  <Text textStyle="upperCase1Regular" color="gray.600">
                    Hiring Manager
                  </Text>
                  <HStack spacing={2} alignItems="flex-start">
                    <Avatar
                      src={
                        ImagesUrl.file(job.hiringManager?.image) || undefined
                      }
                      size="xs"
                    />

                    <Text
                      textStyle="body1Medium"
                      color="gray.900"
                      noOfLines={1}
                    >
                      {job.hiringManager?.name}
                    </Text>
                  </HStack>
                </Stack>
              ) : null}
            </Flex>

            <Stack spacing={4}>
              <Stack spacing={1}>
                <Text textStyle="upperCase1Regular" color="gray.600">
                  {job?.department?.name} {job?.employment}
                </Text>

                <Box>
                  <Heading level="h4" color="gray.700" noOfLines={1}>
                    {job?.name}
                  </Heading>
                </Box>
              </Stack>

              {location ? (
                <HStack spacing={3}>
                  <LocationIcon w="20px" h="20px" color="gray.500" />
                  <Text
                    textStyle="body1Regular"
                    color="gray.900"
                    fontWeight={400}
                  >
                    {job?.city?.name}
                  </Text>
                </HStack>
              ) : null}

              <Stack direction="row" spacing={4}>
                <Button
                  w="full"
                  size="small"
                  hierarchy="secondary"
                  onClick={onOpenShowDescription}
                >
                  View Job Description
                </Button>
              </Stack>
            </Stack>
          </Stack>

          <Flex
            as="footer"
            alignItems="center"
            borderTop="1px solid"
            borderTopColor="gray.200"
            px={4}
            py={2}
            gap={4}
          >
            <Flex textTransform="capitalize" alignItems="center" gap={2}>
              <WorldGlobeIcon color={'gray.500'} />
              Published
            </Flex>

            <Flex ml="auto">
              <RelativeDateHelper date={job?.createdAt as string} />
            </Flex>
          </Flex>
        </Stack>
      </Stack>
    </ViewJobCandidateModalPhoneSidebarJobDetailsPopover>
  );
};

export default ViewJobCandidateModalPhoneSidebarJobDetailsCard;
