import { useCallback, useEffect } from 'react';
import { Flex, HStack, Spinner, Stack, useDisclosure } from '@chakra-ui/react';

import { AvatarHelper } from '../../../../../../../../helpers/AvatarHelper';
import { CheckboxField } from '../../../../../../../../helpers/forms/formFields/CheckboxField';
import { IconButton } from '../../../../../../../../helpers/buttons/IconButton';
import { Text } from '../../../../../../../../helpers/Text';
import { RelativeDateHelper } from '../../../../../../../../helpers/RelativeDateHelper';
import { useChakraToast } from '../../../../../../../../helpers/useChakraToast';

import { MoreIcon } from '../../../../../../../../icons/MoreIcon';

import {
  JobCandidateId,
  JobCandidateImage,
  JobCandidateName,
  JobCandidateWorkflowStageTaskApplicationType,
  JobCandidateWorkflowStageTaskApplicationTypes,
  JobCandidateWorkflowStageTaskId,
  MayBeJobCandidateDisqualifiedAt,
  MayBeJobCandidateDueDate,
  MayBeJobCandidateViewedAt,
  MayBeJobCandidateWorkflowStageTaskScheduledAt
} from '../../../../../../../../main/jobCandidates/jobCandidatesTypes';
import {
  WorkflowStageTaskId,
  WorkflowStageTaskName
} from '../../../../../../../../main/workflowStageTasks/workflowStageTasksTypes';
import {
  WorkflowStageId,
  WorkflowStageNanoId
} from '../../../../../../../../main/workflowStages/workflowStagesTypes';
import { JobId, JobNanoId } from '../../../../../../../../main/jobs/jobsTypes';

import { CandidateTaskMenuBadge } from '../CandidateTaskMenuBadge';
import { EditWorkflowStageTaskContainer } from '../EditWorkflowStageTaskContainer';

import { useUpdateJobCandidate } from '../../../../../../../../main/jobCandidates/hooks/useUpdateJobCandidate';
import { useCreateJobCandidatesCompletedWorkflowStageTask } from '../../../../../../../../main/jobCandidatesCompletedWorkflowStageTasks/hooks/useCreateJobCandidatesCompletedWorkflowStageTask';

import { JobCandidatesCache } from '../../../../../../../../main/jobCandidates/JobCandidatesCache';
import { JobCandidatesCompletedWorkflowStageTasksCache } from '../../../../../../../../main/jobCandidatesCompletedWorkflowStageTasks/JobCandidatesCompletedWorkflowStageTasksCache';
import {
  JobCandidatesCompletedWorkflowStageTaskJobCandidateId,
  JobCandidatesCompletedWorkflowStageTaskJobId,
  JobCandidatesCompletedWorkflowStageTaskWorkflowStageTaskId
} from '../../../../../../../../main/jobCandidatesCompletedWorkflowStageTasks/jobCandidatesCompletedWorkflowStageTasksTypes';

import { renderJobCandidateName } from '../../../../../../../../main/jobCandidates/utils/renderJobCandidateName';

interface JobCandidate {
  id: JobCandidateId;
  name: JobCandidateName;
  image: JobCandidateImage;
  dueDate: MayBeJobCandidateDueDate;
  workflowStageTaskScheduledAt: MayBeJobCandidateWorkflowStageTaskScheduledAt;
  viewedAt: MayBeJobCandidateViewedAt;
  disqualifiedAt: MayBeJobCandidateDisqualifiedAt;
  workflowStageTask: {
    name: WorkflowStageTaskName;
    id: WorkflowStageTaskId;
  };
}

interface CandiateTaskMenuProps {
  jobCandidate: JobCandidate;
  job: { id: JobId; nanoId: JobNanoId };
  workflowStage: {
    id: WorkflowStageId;
    nanoId: WorkflowStageNanoId;
  };
  handleIsTaskUpdatedTrue: () => void;
  allJobCandidates: JobCandidate[];
}

function CandidateTaskMenu({
  jobCandidate,
  workflowStage,
  job,
  handleIsTaskUpdatedTrue,
  allJobCandidates
}: CandiateTaskMenuProps) {
  const {
    updateJobCandidateErrorMessage,
    updateJobCandidate,
    updateJobCandidateIsLoading
  } = useUpdateJobCandidate({
    jobCandidateId: jobCandidate.id,
    cacheKeys: [JobCandidatesCache.showCacheKey()]
  });

  const {
    createJobCandidatesCompletedWorkflowStageTask,
    createJobCandidatesCompletedWorkflowStageTaskErrorMessage,
    createJobCandidatesCompletedWorkflowStageTaskIsLoading
  } = useCreateJobCandidatesCompletedWorkflowStageTask({
    cacheKeys: [
      JobCandidatesCompletedWorkflowStageTasksCache.jobIndexCacheKey(job.nanoId)
    ]
  });

  const toast = useChakraToast();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleUnselectWorkflowStageTask = useCallback(async () => {
    await updateJobCandidate({
      workflowStageTaskId: null as unknown as JobCandidateWorkflowStageTaskId,
      newWorkflowStageTaskAt: null,
      workflowStageTaskScheduledAt: null,
      workflowStageTaskApplicationType:
        JobCandidateWorkflowStageTaskApplicationTypes.NONE as JobCandidateWorkflowStageTaskApplicationType,
      dueDate: null,
      viewedAt: null
    });

    handleIsTaskUpdatedTrue();
  }, [updateJobCandidate, handleIsTaskUpdatedTrue]);

  const handleCompleteWorkflowStageTask = useCallback<() => void>(async () => {
    await createJobCandidatesCompletedWorkflowStageTask({
      jobCandidateId:
        jobCandidate.id as JobCandidatesCompletedWorkflowStageTaskJobCandidateId,
      workflowStageTaskId: jobCandidate.workflowStageTask
        ?.id as JobCandidatesCompletedWorkflowStageTaskWorkflowStageTaskId,
      jobId: job.id as JobCandidatesCompletedWorkflowStageTaskJobId
    });

    handleUnselectWorkflowStageTask();
  }, [
    createJobCandidatesCompletedWorkflowStageTask,
    handleUnselectWorkflowStageTask,
    jobCandidate,
    job
  ]);

  const handleCloseEditTaskContainer = useCallback(() => {
    onClose();
    // handleIsTaskUpdatedTrue()
  }, [onClose]);

  useEffect(() => {
    updateJobCandidateErrorMessage &&
      toast({ title: updateJobCandidateErrorMessage, status: 'error' });
  }, [updateJobCandidateErrorMessage, toast]);

  useEffect(() => {
    createJobCandidatesCompletedWorkflowStageTaskErrorMessage &&
      toast({
        title: createJobCandidatesCompletedWorkflowStageTaskErrorMessage,
        status: 'error'
      });
  }, [createJobCandidatesCompletedWorkflowStageTaskErrorMessage, toast]);

  return (
    <Stack spacing={3}>
      <Stack spacing={2} onClick={onOpen}>
        <Flex justifyContent="space-between" alignItems="center">
          <HStack spacing={4}>
            <CheckboxField
              size="small"
              sx={{
                '.chakra-checkbox__control': { width: '16px', height: '16px' }
              }}
              onChange={({ target: { checked } }) =>
                checked && handleCompleteWorkflowStageTask()
              }
            />
            <HStack spacing={1.5}>
              {createJobCandidatesCompletedWorkflowStageTaskIsLoading ||
              updateJobCandidateIsLoading ? (
                <Spinner size="sm" />
              ) : null}
              <Text textStyle="body1Medium" color="gray.900">
                {jobCandidate.workflowStageTask?.name}
              </Text>
            </HStack>
          </HStack>
          <HStack spacing={4}>
            <CandidateTaskMenuBadge jobCandidate={jobCandidate} />
            <IconButton
              hierarchy="link"
              aria-label={'More'}
              ml="auto"
              minW="0"
              color="gray.600"
            >
              <MoreIcon />
            </IconButton>
          </HStack>
        </Flex>
        <Flex justifyContent="space-between" alignItems="center" px={8}>
          <HStack spacing={2}>
            <AvatarHelper image={jobCandidate.image} width={5} height={5} />
            <Text textStyle="body1Regular" color="#0C1B3C">
              {renderJobCandidateName(jobCandidate)}
            </Text>
          </HStack>
          <Text textStyle="body2Regular" color="gray.600">
            <RelativeDateHelper
              date={jobCandidate.workflowStageTaskScheduledAt as string}
            />
          </Text>
        </Flex>
      </Stack>
      {isOpen && (
        <EditWorkflowStageTaskContainer
          isOpen={isOpen}
          onCloseMenu={handleCloseEditTaskContainer}
          workflowStageNanoId={workflowStage.nanoId}
          workflowStageTaskId={jobCandidate.workflowStageTask.id}
          jobCandidate={jobCandidate}
          allJobCandidates={allJobCandidates}
          handleIsTaskUpdatedTrue={handleIsTaskUpdatedTrue}
        />
      )}
    </Stack>
  );
}

export default CandidateTaskMenu;
