import { usePaginatedItems } from '../../../common/hooks/usePaginatedItems';

import {
  FetchSkillLicensesQuery,
  FetchSkillLicensesCacheKey,
  FetchSkillLicensesTotalCount,
  FetchSkillLicensesPage,
  FetchSkillLicensesPageSize,
  FetchSkillLicensesError,
  FetchSkillLicensesErrorMessage,
  FetchSkillLicensesIsLoading,
  FetchSkillLicensesIsFetched,
  FetchSkillLicensesIsPlaceholderData,
  FetchSkillLicensesEnabled,
  FetchSkillLicensesPrefetchNextPage,
  FetchSkillLicensesRefetchInterval,
  FetchSkillLicensesCountType,
  FetchSkillLicensesFilters,
  FetchSkillLicensesSort,
  FetchSkillLicenseID,
  FetchSkillLicenseQuery,
  FetchSkillLicenseCacheKey
} from '../../skillLicensesTypes';

import { SkillLicensesBffRequests } from '../../SkillLicensesBffRequests';

interface PaginatedSkillLicensesBaseOptions<
  PaginatedSkillLicensesResponseType
> {
  cacheKey: FetchSkillLicensesCacheKey;
  query: FetchSkillLicensesQuery;
  countType?: FetchSkillLicensesCountType;
  initialPage?: FetchSkillLicensesPage;
  initialPageSize?: FetchSkillLicensesPageSize;
  initialFilters?: FetchSkillLicensesFilters;
  initialSort?: FetchSkillLicensesSort;
  enabled?: FetchSkillLicensesEnabled;
  prefetchNextPage?: FetchSkillLicensesPrefetchNextPage;
  refetchInterval?: FetchSkillLicensesRefetchInterval;
  placeholderSkillLicenses?: PaginatedSkillLicensesResponseType[];
  placeholderSkillLicensesCount?: FetchSkillLicensesTotalCount;
  placeholderData?: () => PaginatedSkillLicensesResponse<PaginatedSkillLicensesResponseType>;
}

interface PaginatedSkillLicensesOptionsWithoutItem {
  fetchItemCacheKey?: never;
  itemQuery?: never;
}

interface PaginatedSkillLicensesOptionsWithItem {
  fetchItemCacheKey: FetchSkillLicenseCacheKey;
  itemQuery: FetchSkillLicenseQuery;
}

type PaginatedSkillLicensesOptions<PaginatedSkillLicensesResponseType> =
  PaginatedSkillLicensesBaseOptions<PaginatedSkillLicensesResponseType> &
    (
      | PaginatedSkillLicensesOptionsWithoutItem
      | PaginatedSkillLicensesOptionsWithItem
    );

interface PaginatedSkillLicensesResponse<PaginatedSkillLicensesResponseType> {
  data: PaginatedSkillLicensesResponseType[];
  totalCount: FetchSkillLicensesTotalCount;
}

function usePaginatedSkillLicenses<PaginatedSkillLicensesResponseType>({
  cacheKey,
  query,
  countType,
  initialPage,
  initialPageSize,
  initialFilters,
  initialSort,
  enabled,
  prefetchNextPage,
  refetchInterval,
  placeholderSkillLicenses,
  placeholderSkillLicensesCount,
  placeholderData,
  fetchItemCacheKey,
  itemQuery
}: PaginatedSkillLicensesOptions<PaginatedSkillLicensesResponseType>) {
  const {
    items,
    itemsTotalCount,
    itemsError,
    itemsErrorMessage,
    itemsIsFetched,
    itemsIsLoading,
    itemsIsPlaceholderData,
    itemsCurrentPage,
    itemsCurrentPageSize,
    itemsCurrentFilters,
    itemsCurrentSort,
    fetchItems,
    paginateItems,
    changeItemsPageSize,
    filterItems,
    changeItemsFilters,
    clearItemsFilters,
    sortItems,
    prefetchItems,
    prefetchItem
  } = usePaginatedItems<PaginatedSkillLicensesResponseType>({
    cacheKey,
    initialPage,
    initialPageSize,
    initialFilters,
    initialSort,
    enabled,
    prefetchNextPage,
    refetchInterval,
    placeholderItems: placeholderSkillLicenses,
    placeholderItemsCount: placeholderSkillLicensesCount,
    placeholderData,
    queryFn: (params) =>
      SkillLicensesBffRequests.fetchSkillLicenses<PaginatedSkillLicensesResponseType>(
        {
          query,
          countType,
          page: params.page as FetchSkillLicensesPage,
          pageSize: params.pageSize as FetchSkillLicensesPageSize,
          filters: params.filters as FetchSkillLicensesFilters,
          sort: params.sort as FetchSkillLicensesSort
        }
      ),
    ...(itemQuery
      ? {
          fetchItemCacheKey,
          queryItemFn: (itemId) =>
            SkillLicensesBffRequests.fetchSkillLicense(
              itemId as FetchSkillLicenseID,
              {
                query: itemQuery
              }
            )
        }
      : {})
  });

  return {
    skillLicenses: items as PaginatedSkillLicensesResponseType[],
    skillLicensesTotalCount: itemsTotalCount as FetchSkillLicensesTotalCount,
    skillLicensesError: itemsError as FetchSkillLicensesError,
    skillLicensesErrorMessage:
      itemsErrorMessage as FetchSkillLicensesErrorMessage,
    skillLicensesIsLoading: itemsIsLoading as FetchSkillLicensesIsLoading,
    skillLicensesIsFetched: itemsIsFetched as FetchSkillLicensesIsFetched,
    skillLicensesIsPlaceholderData:
      itemsIsPlaceholderData as FetchSkillLicensesIsPlaceholderData,
    skillLicensesCurrentPage: itemsCurrentPage as FetchSkillLicensesPage,
    skillLicensesCurrentPageSize:
      itemsCurrentPageSize as FetchSkillLicensesPageSize,
    skillLicensesCurrentFilters:
      itemsCurrentFilters as FetchSkillLicensesFilters,
    skillLicensesCurrentSort: itemsCurrentSort as FetchSkillLicensesSort,
    fetchSkillLicenses: fetchItems,
    paginateSkillLicenses: paginateItems,
    changeSkillLicensesPageSize: changeItemsPageSize,
    filterSkillLicenses: filterItems,
    changeSkillLicensesFilters: changeItemsFilters,
    clearSkillLicensesFilters: clearItemsFilters,
    sortSkillLicenses: sortItems,
    prefetchSkillLicenses: prefetchItems,
    prefetchSkillLicense: prefetchItem
  };
}

export default usePaginatedSkillLicenses;
