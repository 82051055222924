const baseSettingsPath = '/settings';

export class SettingsRoutes {
  static index() {
    return baseSettingsPath;
  }

  static personal() {
    return `${baseSettingsPath}/personal`;
  }

  static account() {
    return `${baseSettingsPath}/account`;
  }

  static personalAbout() {
    return `${baseSettingsPath}/personal/about`;
  }

  static company() {
    return `${baseSettingsPath}/company`;
  }

  static recruiting() {
    return `${baseSettingsPath}/recruiting`;
  }

  static subscription() {
    return `${baseSettingsPath}/subscription`;
  }
}
