import { Icon, IconProps } from '@chakra-ui/icon';

const ListItemIcon = ({
  w = '16px',
  h = '16px',
  fill = 'gray.500',
  ...props
}: IconProps) => (
  <Icon viewBox="0 0 16 16" w={w} h={h} fill={fill} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 2H2.4a.4.4 0 00-.4.4V4a.4.4 0 00.4.4H4a.4.4 0 00.4-.4V2.4A.4.4 0 004 2M4 6.799H2.4a.4.4 0 00-.4.4v1.6a.4.4 0 00.4.4H4a.4.4 0 00.4-.4v-1.6a.4.4 0 00-.4-.4M4 11.6H2.4a.4.4 0 00-.4.4v1.6a.4.4 0 00.4.4H4a.4.4 0 00.4-.4V12a.4.4 0 00-.4-.4M5.597 3.8h8.4V2.6h-8.4v1.2zM5.597 8.6h8.4V7.4h-8.4v1.2zM5.597 13.4h8.4v-1.2h-8.4v1.2z"
    />
  </Icon>
);

export default ListItemIcon;
