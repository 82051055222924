import { CompanyNanoId } from './companiesTypes';

const baseCompaniesPath = '/companies';

export class CompaniesRoutes {
  static index() {
    return baseCompaniesPath;
  }

  static editCompany(companyNanoId: CompanyNanoId) {
    return `${baseCompaniesPath}/edit/${companyNanoId}`;
  }
}
