import { useCallback } from 'react';

import { AddIndustryExpertise } from '../../../helpers/AddIndustryExpertise';

import {
  AddIndustryExpertiseWrapperOnSelectIndustry,
  AddIndustryExpertiseWrapperIndustry
} from './AddIndustryExpertiseWrapper.types';

interface AddIndustryExpertiseWrapperProps {
  industry: AddIndustryExpertiseWrapperIndustry;
  isDisabled?: boolean;
  isSelected: boolean;
  isRequired?: boolean;
  withoutPlusButton?: boolean;
  onSelectIndustry: AddIndustryExpertiseWrapperOnSelectIndustry;
  isLoading?: boolean;
}

function AddIndustryExpertiseWrapper({
  industry,
  isDisabled,
  isSelected,
  isRequired,
  withoutPlusButton,
  isLoading,
  onSelectIndustry
}: AddIndustryExpertiseWrapperProps) {
  const handleSelect = useCallback<() => void>(
    () => onSelectIndustry(industry),
    [onSelectIndustry, industry]
  );

  return (
    <AddIndustryExpertise
      name={industry.name}
      isDisabled={isDisabled}
      isSelected={isSelected}
      isRequired={isRequired}
      withoutPlusButton={withoutPlusButton}
      onSelectAction={withoutPlusButton ? undefined : handleSelect}
      isLoading={isLoading}
    />
  );
}

export default AddIndustryExpertiseWrapper;
