import { Icon, IconProps } from '@chakra-ui/icon';

const RecruitersIcon = ({
  w = '24px',
  h = '24px',
  fill = 'gray.500',
  ...props
}: IconProps) => (
  <Icon viewBox="0 0 24 24" w={w} h={h} fill={fill} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m14.367 14.306-.818 1.565a.824.824 0 0 1-.732.433h-1.636a.823.823 0 0 1-.732-.433l-.818-1.565a.69.69 0 0 1-.065-.189A5.044 5.044 0 0 0 12 14.74c.887 0 1.716-.228 2.433-.622a.69.69 0 0 1-.065.189Zm.325-.338c1.335-.84 2.217-2.283 2.217-3.925V7.697C16.91 5.102 14.712 3 12 3 9.29 3 7.09 5.102 7.09 7.696v2.348c0 1.641.884 3.085 2.219 3.923C5.809 14.09 3 16.841 3 20.218V21h7.732l.77-3.782a.83.83 0 0 1 .68-.348h-.364c.274 0 .53.13.682.348L13.273 21H21v-.783c0-3.376-2.809-6.128-6.308-6.25Z"
    />
  </Icon>
);
export default RecruitersIcon;
