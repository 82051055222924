import React, { Fragment } from 'react';
import {
  DateTimeFormats,
  DateTimeType,
  DateUtils
} from '../../utils/DateUtils';

interface RelativeDateHelperProps {
  date: string;
  calendarDate?: boolean;
  showFullDateAfterYesterday?: boolean;
}

function RelativeDateHelper({
  date,
  calendarDate = false,
  showFullDateAfterYesterday
}: RelativeDateHelperProps) {
  const today = DateUtils.startOfToday();

  const dateToCompare = DateUtils.calendarDay(date) as DateTimeType;

  const dateDifference = today.diff(dateToCompare, 'days')?.toObject();

  const formattedDate = calendarDate
    ? DateUtils.toRelativeCalendar(date)
    : DateUtils.toRelative(date);

  const fullDate =
    (dateDifference.days as number) > 1
      ? DateUtils.formatDate(date, DateTimeFormats.DDDD)
      : formattedDate;

  return (
    <Fragment>{showFullDateAfterYesterday ? fullDate : formattedDate}</Fragment>
  );
}

export default RelativeDateHelper;
