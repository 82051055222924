import React, { ReactNode } from 'react';
import dynamic from 'next/dynamic';
import {
  FormControl,
  FormErrorMessage,
  FormLabel
} from '@chakra-ui/form-control';
import { Box, Flex } from '@chakra-ui/react';

import { ErrorMessage, IsDisabled } from '../../../../../../types';

const TinyMce = dynamic(() => import('../../../../../TinyMce/TinyMce'), {
  ssr: false
});

interface TinyMceFieldControlProps {
  name: string;
  value: string;
  placeholder?: string;
  defaultValue?: string;
  isInvalid?: boolean;
  isDisabled?: IsDisabled;
  isRequired?: boolean;
  asPlainText?: boolean;
  errorMessage?: ErrorMessage;
  onChange: (updatedValue: string) => void;
  label?: string;
  labelAddon?: ReactNode;
  isGrayBg?: boolean;
}

function TinyMceFieldControl({
  placeholder,
  defaultValue,
  errorMessage,
  isInvalid,
  isDisabled,
  isRequired,
  asPlainText,
  onChange,
  label,
  labelAddon,
  isGrayBg
}: TinyMceFieldControlProps) {
  return (
    <FormControl
      isInvalid={isInvalid || typeof errorMessage === 'string'}
      isDisabled={isDisabled}
      isRequired={isRequired}
      h="100%"
      maxH="500px"
      display="flex"
      flexDir="column"
    >
      {(label || labelAddon) && (
        <Flex alignItems="center">
          {label && <FormLabel display="flex">{label}</FormLabel>}

          {labelAddon && (
            <FormLabel
              mr={0}
              ml="auto"
              display="flex"
              as="aside"
              alignItems="center"
              requiredIndicator={<></>}
            >
              {labelAddon}
            </FormLabel>
          )}
        </Flex>
      )}

      {errorMessage ? (
        <FormErrorMessage
          mb={3}
          className="mt-2"
          textStyle="body2Medium"
          wordBreak="break-all"
        >
          {errorMessage}
        </FormErrorMessage>
      ) : null}

      <Box flex={1} h="100%" overflow="auto">
        <TinyMce
          placeholder={placeholder}
          defaultValue={defaultValue}
          asPlainText={asPlainText}
          onChange={onChange}
          isGrayBg={isGrayBg}
        />
      </Box>
    </FormControl>
  );
}

export default TinyMceFieldControl;
