import {
  Avatar,
  ColorProps,
  Flex,
  Grid,
  GridItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Stack
} from '@chakra-ui/react';
import compact from 'lodash/compact';
import { ReactNode } from 'react';
import { Button } from '../../../../../../../../../../helpers/Button';
import { Heading } from '../../../../../../../../../../helpers/Heading';
import { Text } from '../../../../../../../../../../helpers/Text';
import { ClockIcon } from '../../../../../../../../../../icons/ClockIcon';
import { LocationIcon } from '../../../../../../../../../../icons/LocationIcon';
import { NoteIcon } from '../../../../../../../../../../icons/NoteIcon';

export type ViewJobCandidateDetailsProfileCalendarEventType = {
  id: string;
  end: string;
  link?: string;
  title: string;
  start: string;
  comment?: string;
  description?: string;
  participants?: string;
  type?: 'meeting' | 'presentation' | 'seminar' | 'team';
};

type ViewJobCandidateDetailsProfileCalendarEventItemProps = {
  event: ViewJobCandidateDetailsProfileCalendarEventType;
};

export default function ViewJobCandidateDetailsProfileWeekCalendarEvents({
  event
}: ViewJobCandidateDetailsProfileCalendarEventItemProps) {
  const eventTypeColor = (): ColorProps['color'] => {
    switch (event.type) {
      case 'meeting':
        return 'primary.500';
      case 'presentation':
        return 'purple.500';
      case 'seminar':
        return 'yellow.500';
      case 'team':
        return 'blue.500';
      default:
        return 'green.500';
    }
  };

  return (
    <Popover placement="right" arrowSize={20} offset={[0, 14]}>
      <PopoverTrigger>
        <Stack
          px={3}
          py={2.5}
          as="button"
          spacing={1}
          pos="relative"
          display="flex"
          textAlign="left"
          border="1px solid"
          borderRadius="base"
          borderColor="gray.200"
          transitionDuration="slow"
          borderLeftWidth={4}
          borderLeftColor="transparent"
          _hover={{
            bg: 'gray.50',
            borderColor: eventTypeColor()
          }}
          _before={{
            top: '-1px',
            left: '-4px',
            content: "''",
            pos: 'absolute',
            bg: eventTypeColor(),
            h: 'calc(100% + 2px)',
            borderRadius: '4px 0 0 4px',
            w: 1
          }}
        >
          <Text noOfLines={1} textStyle="body1Medium">
            {event.title}
          </Text>

          <Text color="gray.600" textStyle="body2Regular">
            {compact([event.start, event.end]).join(' - ')}
          </Text>
        </Stack>
      </PopoverTrigger>

      <Portal>
        <PopoverContent
          w={96}
          boxShadow="10px 10px 25px -5px rgba(0, 0, 0, 0.1), 25px 0 25px 25px rgba(0, 0, 0, 0.04)"
        >
          <PopoverArrow />

          <PopoverBody p={4}>
            <Stack spacing={0}>
              <CalendarItemGrid>
                <Heading level="h3" fontSize="lg" fontWeight="medium">
                  {event.title}
                </Heading>
              </CalendarItemGrid>

              <CalendarItemGrid icon={<ClockIcon />}>
                <Text>
                  {compact([event.start, event.end]).join(' - ')}

                  <Text
                    ml={2}
                    as="span"
                    color="white"
                    display="inline-block"
                    textStyle="body2Medium"
                    textTransform="capitalize"
                    bg={eventTypeColor()}
                    borderRadius="base"
                    px={2}
                  >
                    {event.type}
                  </Text>
                </Text>
              </CalendarItemGrid>

              <CalendarItemGrid icon={<LocationIcon />}>
                <Flex gap={2} flexDir="column">
                  <Text>Microsoft Teams Meeting</Text>

                  <Flex gap={2}>
                    <Button size="small" px={10}>
                      Join
                    </Button>

                    <Button px={5} size="small" hierarchy="secondary">
                      Edit
                    </Button>
                  </Flex>
                </Flex>
              </CalendarItemGrid>

              <CalendarItemGrid
                icon={<Avatar src="/images/default-avatar-1.png" w={5} h={5} />}
              >
                <Flex gap={0} flexDir="column">
                  <Text>You&apos;re the organizer</Text>
                  <Text textStyle="body2Regular">
                    Accepted 2, Declined 1, Didn&apos;t respond 3
                  </Text>
                </Flex>
              </CalendarItemGrid>

              <CalendarItemGrid icon={<NoteIcon />}>
                <Text textStyle="body2Regular">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </Text>
              </CalendarItemGrid>
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}

interface CalendarItemGridProps {
  icon?: ReactNode;
  children: ReactNode;
}

function CalendarItemGrid({ icon, children }: CalendarItemGridProps) {
  return (
    <Grid
      gridTemplateColumns="32px 1fr"
      _first={{
        '>*': {
          pt: 0
        }
      }}
      _last={{
        '>*': {
          pb: 0,
          border: 'none'
        }
      }}
    >
      <GridItem py={2}>
        <Flex
          w={6}
          h={6}
          alignItems="center"
          justifyContent="center"
          sx={{
            '>svg': {
              fill: 'gray.500'
            }
          }}
        >
          {icon}
        </Flex>
      </GridItem>

      <GridItem
        py={2}
        color="gray.700"
        borderBottom="1px solid"
        borderColor="gray.200"
      >
        {children}
      </GridItem>
    </Grid>
  );
}
