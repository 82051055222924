import React, { ForwardedRef, useCallback, useEffect, useState } from 'react';
import { Box, Grid, Flex, GridItem, SimpleGrid } from '@chakra-ui/layout';
import { ButtonGroup, chakra } from '@chakra-ui/react';

import { IsLoading } from '../../../../../../types';

import {
  JobFields,
  JobNanoId,
  JobDepartment,
  JobJobFunction,
  JobDiscipline,
  JobJobTitle,
  JobDisciplineId,
  JobJobFunctionId,
  JobJobTitleId,
  JobUserId,
  JobCompanyId
} from '../../../../jobsTypes';

import { useUpdateJob } from '../../../../hooks/useUpdateJob';
import { usePostJobDetailsForm } from '../../hooks/usePostJobDetailsForm';

import { JobsCache } from '../../../../JobsCache';

import { AlertMessage } from '../../../../../../helpers/AlertMessage';
import { FormWithRef } from '../../../../../../helpers/FormWithRef';
import { Heading } from '../../../../../../helpers/Heading';
import { TinyMceField } from '../../../../../../helpers/forms/formFields/TinyMceField';
import { Button } from '../../../../../../helpers/Button';

import { PostJobDetailsFormData } from '../../PostJobDetailsForm.types';
import { SelectJobTitleSelectJobTitleAssociatesProps } from '../../../../../common/components/formFields/SelectJobTitleFormField/SelectJobTitle.types';
import { DisciplineId } from '../../../../../disciplines/disciplinesTypes';
import { SelectJobTitleFormField } from '../../../../../common/components/formFields/SelectJobTitleFormField';
import { SelectJobFunctionFormField } from '../../../../../common/components/formFields/SelectJobFunctionFormField';
import { SelectDepartmentFormField } from '../../../../../common/components/formFields/SelectDepartmentFormField';
import { useCurrentUser } from '../../../../../../auth/hooks/useAuth';
import { CompanyId } from '../../../../../companies/companiesTypes';
import { CreateJobRequestData } from '../../../../JobsBffRequests';
import { useCreateJob } from '../../../../hooks/useCreateJob';
import { generateNanoId } from '../../../../../../utils/generateNanoId';

interface PostJobDetailsFormWithButtonsProps {
  withoutButtons?: never;
  onJobDetailsPosted: (job?: CreateJobRequestData) => void;
  setUpdateJobIsLoading?: never;
  onCancel: () => void;
}

interface PostJobDetailsFormWithoutButtonsProps {
  withoutButtons: boolean;
  onJobDetailsPosted: (job?: CreateJobRequestData) => void;
  setUpdateJobIsLoading?: (isLoading: IsLoading) => void;
  onCancel?: never;
}

interface PostJobDetailsFormRequiredProps {
  job?: {
    nanoId: JobNanoId;
    jobTitle: JobJobTitle;
    department: JobDepartment;
    jobFunction: JobJobFunction;
    discipline: JobDiscipline;
  } & PostJobDetailsFormData;
}

type PostJobDetailsFormProps = PostJobDetailsFormRequiredProps &
  (PostJobDetailsFormWithButtonsProps | PostJobDetailsFormWithoutButtonsProps);

const PostJobDetailsForm = React.forwardRef<
  HTMLFormElement,
  PostJobDetailsFormProps
>(
  (
    {
      job,
      onJobDetailsPosted,
      setUpdateJobIsLoading,
      onCancel,
      withoutButtons
    }: PostJobDetailsFormProps,
    ref: ForwardedRef<HTMLFormElement>
  ) => {
    const currentUser = useCurrentUser();

    const { updateJobErrorMessage, updateJobIsLoading, updateJob } =
      useUpdateJob({
        jobNanoId: job?.nanoId as JobNanoId,
        cacheKeys: [JobsCache.postJobDetailsCacheKey()]
      });

    const { createJob, createJobErrorMessage, createJobIsLoading } =
      useCreateJob({
        cacheKeys: [JobsCache.indexCacheKey()]
      });

    const {
      watch,
      control,
      validationErrors,
      setPostJobDetailsFormValue,
      handlePostJobDetailsForm
    } = usePostJobDetailsForm({
      defaultValues: job
        ? {
            jobTitleId: job.jobTitleId,
            departmentId: job.departmentId,
            description: job.description,
            userJobId: job.userJobId,
            jobFunctionId: job.jobFunctionId,
            disciplineId: job.disciplineId
          }
        : {},
      onPostJobDetailsForm: async (data) => {
        if (job) {
          await updateJob(data);
          onJobDetailsPosted();
        } else {
          const newJob = await createJob({
            ...data,
            nanoId: generateNanoId<JobNanoId>(),
            userId: currentUser.id as JobUserId,
            companyId: currentUser.companyId as JobCompanyId
          });

          onJobDetailsPosted(newJob);
        }
      }
    });

    const [selectedJobTitleIsNew, setSelectedJobTitleIsNew] =
      useState<boolean>(false);

    const currentJobTitleId = watch(JobFields.JOB_TITLE_ID);
    const currentJobDisciplineId = watch(JobFields.DISCIPLINE_ID);
    const currentJobFunctionId = watch(JobFields.JOB_FUNCTION_ID);

    const handleJobTitleAssociates = useCallback<
      (jobTitleAssociates: SelectJobTitleSelectJobTitleAssociatesProps) => void
    >(
      ({ jobFunctionId, disciplineId, isNewJobTitle, jobTitleId }) => {
        setSelectedJobTitleIsNew(isNewJobTitle || false);
        if (jobTitleId) {
          setPostJobDetailsFormValue(
            JobFields.JOB_TITLE_ID,
            jobTitleId as JobJobTitleId
          );
        }
        if (jobFunctionId) {
          setPostJobDetailsFormValue(
            JobFields.JOB_FUNCTION_ID,
            jobFunctionId as JobJobFunctionId
          );
        }
        if (disciplineId) {
          setPostJobDetailsFormValue(
            JobFields.DISCIPLINE_ID,
            disciplineId as JobDisciplineId
          );
        }
      },
      [setPostJobDetailsFormValue]
    );

    const handleSelectDisciplineId = useCallback<
      (disciplineId: DisciplineId) => void
    >(
      (disciplineId) => {
        setPostJobDetailsFormValue(
          JobFields.DISCIPLINE_ID,
          disciplineId as JobDisciplineId
        );
      },
      [setPostJobDetailsFormValue]
    );

    useEffect(() => {
      setUpdateJobIsLoading?.(updateJobIsLoading);
    }, [updateJobIsLoading, setUpdateJobIsLoading]);

    return (
      <chakra.form
        as={FormWithRef}
        ref={ref}
        onSubmit={handlePostJobDetailsForm}
        h="100%"
        overflow="hidden"
      >
        <Grid h="100%" gridTemplateRows="max-content 1fr" gap={10}>
          <Box>
            <Heading level="h5" fontWeight="semibold" mb={4}>
              Details
            </Heading>

            <SimpleGrid
              columns={{ base: 1, lg: selectedJobTitleIsNew ? 3 : 2 }}
              position="relative"
              gap={6}
              zIndex="1"
            >
              <SelectJobTitleFormField
                label="Title"
                control={control}
                item={job}
                selectedJobTitleId={currentJobTitleId}
                selectJobTitleAssocaites={handleJobTitleAssociates}
                errorMessage={validationErrors.jobTitleIdValidationError}
              />

              <SelectDepartmentFormField
                control={control}
                companyId={currentUser.companyId as unknown as CompanyId}
                item={job}
                errorMessage={validationErrors.departmentIdValidationError}
              />

              {selectedJobTitleIsNew ? (
                <SelectJobFunctionFormField
                  control={control}
                  item={job}
                  selectedJobTitleId={currentJobTitleId}
                  selectedFunctionId={currentJobFunctionId}
                  selectDisciplineId={handleSelectDisciplineId}
                  selectedDisciplineId={currentJobDisciplineId}
                  errorMessage={validationErrors.jobFunctionIdValidationError}
                />
              ) : null}
            </SimpleGrid>
          </Box>

          <Grid gridTemplateRows="max-content 1fr" overflow="hidden" gap={4}>
            <Heading level="h5" fontWeight="semibold">
              Job Description
            </Heading>
            <GridItem overflow="hidden">
              <TinyMceField
                isRequired
                control={control}
                name={JobFields.DESCRIPTION}
                defaultValue={job?.description}
                errorMessage={validationErrors.descriptionValidationError}
              />
            </GridItem>
          </Grid>

          {updateJobErrorMessage || createJobErrorMessage || !withoutButtons ? (
            <Flex flexDirection="column" gap={4}>
              <AlertMessage
                message={updateJobErrorMessage || createJobErrorMessage}
              />

              {withoutButtons ? null : (
                <ButtonGroup justifyContent="flex-end">
                  <Button
                    size="medium"
                    type="reset"
                    hierarchy="tertiary"
                    onClick={onCancel}
                    isDisabled={updateJobIsLoading || createJobIsLoading}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="medium"
                    type="submit"
                    isDisabled={updateJobIsLoading || createJobIsLoading}
                    isLoading={updateJobIsLoading || createJobIsLoading}
                  >
                    Save
                  </Button>
                </ButtonGroup>
              )}
            </Flex>
          ) : null}
        </Grid>
      </chakra.form>
    );
  }
);

PostJobDetailsForm.displayName = 'PostJobDetailsForm';

export default PostJobDetailsForm;
