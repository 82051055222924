import { ReactNode, useCallback } from 'react';
import { useRouter } from 'next/router';
import { Show, useMediaQuery } from '@chakra-ui/react';
import { Box, Grid, GridItem } from '@chakra-ui/layout';

import { HiringPortalPermissions } from '../../../hiringPortal/HiringPortalPermissions';
import { TalentPoolsPermissions } from '../../../talentPools/TalentPoolsPermissions';

import { HiringPortalRoutes } from '../../../hiringPortal/HiringPortalRoutes';
import { HiringPortalSettingsRoutes } from '../../../hiringPortal/HiringPortalSettingsRoutes';

import { JobsIcon } from '../../../../icons/JobsIcon';
import { PersonIcon } from '../../../../icons/PersonIcon';
import { CandidatesIcon } from '../../../../icons/CandidatesIcon';
import { SettingsIcon } from '../../../../icons/SettingsIcon';
import { HelpIcon } from '../../../../icons/HelpIcon';

import { CheckPermissionLayout } from '../CheckPermissionLayout';

import { Header } from '../../../../layouts/HiringPortalLayout/components/Header';
import { LeftSidebar } from '../../../../layouts/HiringPortalLayout/components/LeftSidebar';

import { SidebarMenuItem } from '../../../../helpers/SidebarMenuItem';

import { useSubscribeToPreviousRouter } from '../../../../common/hooks/utils/usePreviousRouter';
import { useLeftSidebarContext } from '../../../../context';
import { useSignOut } from '../../../../auth/hooks/useSignOut';

interface AppLayoutProps {
  children: ReactNode;
}

function MainAppLayout({ children }: AppLayoutProps) {
  useSubscribeToPreviousRouter();

  const router = useRouter();

  const { isDocked } = useLeftSidebarContext();

  // const activeLink = 'candidates';
  const activeLink = router.pathname;

  const [isDesktop] = useMediaQuery('(min-width: 992px)', {
    ssr: true,
    fallback: false
  });

  const handleMenuItemOnMouseOver = useCallback<(url: string) => void>(
    (url) => router.prefetch(url),
    [router]
  );

  const handleSignOut = useSignOut();

  return (
    <CheckPermissionLayout
      appAction={HiringPortalPermissions.HIRING_PORTAL_ACCESS}
      action={HiringPortalPermissions.READ_HIRING_PORTAL_CANDIDATES_PAGE}
    >
      <Show above="lg">
        <LeftSidebar colorScheme="light">
          <SidebarMenuItem
            title="layout-example"
            url="/layout-example"
            icon={JobsIcon}
            isActive={activeLink === 'layout-example'}
            onMouseOver={handleMenuItemOnMouseOver}
          />
          <SidebarMenuItem
            title="layout-example - page1"
            icon={PersonIcon}
            url="/layout-example/page1"
            isActive={activeLink === 'layout-example/page1'}
            onMouseOver={handleMenuItemOnMouseOver}
          />
          <SidebarMenuItem
            title="layout-example - page2"
            url="/layout-example/page2"
            icon={CandidatesIcon}
            isActive={activeLink === 'layout-example/page2'}
            onMouseOver={handleMenuItemOnMouseOver}
            action={TalentPoolsPermissions.READ_TALENT_POOLS_INDEX_PAGE}
          />
        </LeftSidebar>
      </Show>
      <Grid
        gridTemplateRows={{
          lg: 'max-content 1fr 48px'
        }}
        height="100%"
        as="section"
        bg="gray.50"
        h="100vh"
        flexDirection="column"
        transitionDuration="slow"
        transitionProperty="margin-left"
        ml={{ lg: isDocked ? 20 : 60 }}
        position="relative"
        zIndex={1}
      >
        {isDesktop ? (
          <GridItem display={{ base: 'none', lg: 'unset' }}>
            <Header onSignOut={handleSignOut} totalTalentCredits={200} />
          </GridItem>
        ) : null}

        <GridItem
          overflow="auto"
          h={{ base: 'calc(100vh - 68px)', lg: 'auto' }}
        >
          {children}
        </GridItem>
      </Grid>
    </CheckPermissionLayout>
  );
}

export default MainAppLayout;
