import { Icon, IconProps } from '@chakra-ui/icon';

const CalendarIcon = ({
  w = '24px',
  h = '24px',
  fill = 'gray.500',
  ...props
}: IconProps) => (
  <Icon viewBox="0 0 24 24" w={w} h={h} fill={fill} {...props}>
    <g fillRule="evenodd" clipRule="evenodd">
      <path d="M21.002 5.468a1 1 0 0 0-1-.999h-2.016a.735.735 0 0 1-.735-.734.735.735 0 0 0-.735-.735h-.03a.735.735 0 0 0-.735.735.735.735 0 0 1-.735.734h-1.53a.735.735 0 0 1-.735-.734.735.735 0 0 0-.735-.735h-.03a.735.735 0 0 0-.735.735.735.735 0 0 1-.735.734h-1.53a.735.735 0 0 1-.736-.734A.735.735 0 0 0 7.516 3h-.031a.735.735 0 0 0-.735.735.735.735 0 0 1-.734.734H4.002a1 1 0 0 0-1 1L3 6.41a1 1 0 0 0 1 1h16.002a1 1 0 0 0 1-1v-.94ZM18 13.454c0 .311-.252.563-.562.563h-1.875a.563.563 0 0 1-.563-.563v-1.813c0-.311.252-.563.563-.563h1.875c.31 0 .562.252.562.563v1.813Zm-4.5 0c0 .311-.252.563-.562.563h-1.876a.563.563 0 0 1-.562-.563v-1.813c0-.311.252-.563.563-.563h1.875c.31 0 .562.252.562.563v1.813Zm0 4.41c0 .311-.252.563-.562.563h-1.876a.563.563 0 0 1-.562-.562V16.05c0-.31.252-.563.563-.563h1.875c.31 0 .562.252.562.563v1.814ZM9 13.455c0 .311-.252.563-.562.563H6.563A.563.563 0 0 1 6 13.454v-1.813c0-.311.252-.563.563-.563h1.875c.31 0 .562.252.562.563v1.813Zm0 4.41c0 .311-.252.563-.562.563H6.563A.562.562 0 0 1 6 17.865V16.05c0-.31.252-.563.563-.563h1.875c.31 0 .562.252.562.563v1.814ZM4 8.876a1 1 0 0 0-1 1v8.873a2.249 2.249 0 0 0 2.248 2.25h13.508A2.247 2.247 0 0 0 21 18.748V9.875a1 1 0 0 0-1-1H4Z" />
    </g>
  </Icon>
);
export default CalendarIcon;
