import { ReactNode } from 'react';
import { Box, Flex } from '@chakra-ui/layout';
import { MenuListProps } from 'chakra-react-select';
import { GroupBase } from 'react-select/dist/declarations/src/types';

import { PlusIcon } from '../../icons/PlusIcon';

import { Button, ButtonHierarchyType } from '../Button';
import { Text } from '../Text';

interface ReactSelectMenuButtonProps<
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
> extends MenuListProps<Option, IsMulti, Group> {
  buttonLabel?: string;
  buttonAction?: () => void;
  buttonDisabled?: boolean;
  buttonLoading?: boolean;
  withoutPlusIcon?: boolean;
  buttonHierarchy?: ButtonHierarchyType;
  children: ReactNode;
}

function ReactSelectMenuButton<
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  buttonAction,
  buttonLabel,
  buttonDisabled,
  buttonLoading,
  buttonHierarchy = 'secondary',
  withoutPlusIcon,
  children
}: ReactSelectMenuButtonProps<Option, IsMulti, Group>) {
  return (
    <Box>
      <Flex
        flexDir="column"
        bg="white"
        border="1px solid"
        borderColor="gray.300"
        py={2}
        borderRadius={4}
      >
        <Flex flexDir="column" maxH="148px" overflow="auto">
          {children}
        </Flex>
        <Flex px={2} mt={1}>
          <Button
            flex={buttonHierarchy === 'ghost' ? 'unset' : 1}
            size="small"
            display="flex"
            alignItems="center"
            hierarchy={buttonHierarchy}
            isDisabled={buttonDisabled}
            isLoading={buttonLoading}
            onClick={buttonAction}
            w={buttonHierarchy === 'ghost' ? 'fit-content' : '100%'}
          >
            {withoutPlusIcon ? null : <PlusIcon h={4} w={4} mr={1} />}
            <Text as="span" lineHeight={4}>
              {buttonLabel}
            </Text>
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
}

export default ReactSelectMenuButton;
