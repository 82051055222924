import { useFetchItem } from '../../../common/hooks/useFetchItem';

import {
  UserId,
  FetchUserQuery,
  FetchUserCacheKey,
  FetchUserEnabled,
  FetchUserError,
  FetchUserErrorMessage,
  FetchUserIsLoading,
  FetchUserIsFetched,
  FetchUserIsRefetching,
  FetchUserIsPlaceholderData
} from '../../usersTypes';

import { UsersBffRequests } from '../../UsersBffRequests';

interface FetchUserOptions<FetchUserResponseType> {
  cacheKey: FetchUserCacheKey;
  query: FetchUserQuery;
  userId: UserId;
  enabled?: FetchUserEnabled;
  placeholderUser?: FetchUserResponseType;
  placeholderData?: () => FetchUserResponseType;
}

function useFetchUser<FetchUserResponseType>({
  cacheKey,
  query,
  userId,
  enabled,
  placeholderUser,
  placeholderData
}: FetchUserOptions<FetchUserResponseType>) {
  const {
    item,
    itemError,
    itemErrorMessage,
    itemIsFetched,
    itemIsRefetching,
    itemIsLoading,
    itemIsPlaceholderData,
    refetchItem
  } = useFetchItem<FetchUserResponseType, FetchUserError>({
    cacheKey,
    itemId: userId,
    enabled,
    placeholderItem: placeholderUser,
    placeholderData,
    queryFn: () =>
      UsersBffRequests.fetchUser<FetchUserResponseType>(userId, {
        query
      })
  });

  return {
    user: item,
    userError: itemError as FetchUserError,
    userErrorMessage: itemErrorMessage as FetchUserErrorMessage,
    userIsFetched: itemIsFetched as FetchUserIsFetched,
    userIsRefetching: itemIsRefetching as FetchUserIsRefetching,
    userIsLoading: itemIsLoading as FetchUserIsLoading,
    userIsPlaceholderData: itemIsPlaceholderData as FetchUserIsPlaceholderData,
    refetchUser: refetchItem
  };
}

export default useFetchUser;
