import { useEffect } from 'react';
import { FieldValues } from 'react-hook-form';
import debounce from 'lodash/debounce';

import { ErrorMessage, IsDisabled, IsRequired } from '../../../../../types';

import {
  JobFields,
  JobRecruitingCoordinatorId,
  MayBeJobRecruitingCoordinator
} from '../../../jobsTypes';

import {
  UserFields,
  UserActionTypes,
  FetchUsersFilters,
  FetchUsersSort
} from '../../../../users/usersTypes';

import {
  fetchUsersQuery,
  FetchUsersResponse
} from '../../../../users/queries/fetchUsers.query';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';
import { usePaginatedUsers } from '../../../../users/hooks/usePaginatedUsers';

import { UsersCache } from '../../../../users/UsersCache';

import {
  SelectField,
  SelectFieldReactHookFormControl,
  SelectFieldReactHookFormFieldPath
} from '../../../../../helpers/forms/formFields/SelectField';

import { ImagesUrl } from '../../../../images/ImagesUrl';

interface JobSelectRecruitingCoordinatorFieldProps<T extends FieldValues> {
  isRequired?: IsRequired;
  isDisabled?: IsDisabled;
  control: SelectFieldReactHookFormControl<T>;
  job?: {
    recruitingCoordinator: MayBeJobRecruitingCoordinator;
  } | null;
  currentJobRecruitingCoordinatorId?: JobRecruitingCoordinatorId | null;
  errorMessage: ErrorMessage;
  showAddForm?: () => void;
}

function JobSelectRecruitingCoordinatorField<T extends FieldValues>({
  isRequired,
  isDisabled,
  control,
  job,
  currentJobRecruitingCoordinatorId,
  errorMessage,
  showAddForm
}: JobSelectRecruitingCoordinatorFieldProps<T>) {
  const currentUser = useCurrentUser();

  const { users, usersIsLoading, usersErrorMessage, changeUsersFilters } =
    usePaginatedUsers<FetchUsersResponse>({
      query: fetchUsersQuery,
      cacheKey: UsersCache.postJobCacheKey(),
      initialFilters: {
        [UserFields.ACTION_TYPE]: {
          operator: 'eq',
          value: UserActionTypes.RECRUITING_COORDINATOR
        },
        [UserFields.COMPANY_ID]: {
          operator: 'eq',
          value: currentUser.companyId
        }
      } as unknown as FetchUsersFilters,
      initialSort: {
        [UserFields.NAME]: { ascending: true }
      } as unknown as FetchUsersSort
    });

  useEffect(() => {
    if (
      !currentJobRecruitingCoordinatorId &&
      job?.recruitingCoordinator?.name
    ) {
      changeUsersFilters({
        [UserFields.NAME]: {
          operator: 'ilike',
          value: `%${job?.recruitingCoordinator?.name}%`
        }
      });
    }
  }, [
    currentJobRecruitingCoordinatorId,
    job?.recruitingCoordinator?.name,
    changeUsersFilters
  ]);

  const defaultJobRecruitingCoordinator = job?.recruitingCoordinator
    ? {
        value: job.recruitingCoordinator.id,
        label: job.recruitingCoordinator.name,
        image: ImagesUrl.file(job.recruitingCoordinator.image)
      }
    : undefined;

  const debouncedFilterUsers = debounce<(updatedValue: string) => void>(
    (updatedValue) =>
      changeUsersFilters({
        [UserFields.NAME]: {
          operator: 'ilike',
          value: `%${updatedValue}%`
        }
      }),
    500
  );

  return (
    <SelectField
      withImages
      withAddNewButton
      addNewButtonLabel="Add Recruitment Coordinator"
      addNewButtonAction={showAddForm}
      isRequired={isRequired}
      isDisabled={isDisabled}
      control={control}
      label="Recruiting Coordinator"
      placeholder="Select"
      name={
        JobFields.RECRUITING_COORDINATOR_ID as SelectFieldReactHookFormFieldPath<T>
      }
      options={users.map((user) => ({
        value: user.id,
        label: user.name,
        image: ImagesUrl.file(user.image)
      }))}
      defaultValue={defaultJobRecruitingCoordinator}
      errorMessage={errorMessage || usersErrorMessage}
      isLoading={usersIsLoading}
      onInputChange={debouncedFilterUsers}
    />
  );
}

export default JobSelectRecruitingCoordinatorField;
