import {
  FieldBrand,
  ItemID,
  ItemUUID,
  ItemNanoId,
  ItemCreatedAt,
  MayBe
} from '../../../types';

import {
  UserId,
  UserNanoId,
  UserName,
  MayBeUserName,
  UserFullName,
  UserEmail,
  UserImage,
  UserRoles,
  UserCompanyId,
  UserCompany,
  UserAccountType,
  UserAccountTypeId
} from '../../users/usersTypes';

export type CompanyMemberInvitationId = FieldBrand<
  ItemID,
  'CompanyMemberInvitationId'
>;
export type CompanyMemberInvitationUuid = FieldBrand<
  ItemUUID,
  'CompanyMemberInvitationUuid'
>;
export type MayBeCompanyMemberInvitationUuid =
  MayBe<CompanyMemberInvitationUuid>;
export type CompanyMemberInvitationNanoId = FieldBrand<
  ItemNanoId,
  'CompanyMemberInvitationNanoId'
>;
export type MayBeCompanyMemberInvitationNanoId =
  MayBe<CompanyMemberInvitationNanoId>;
export type CompanyMemberInvitationName = MayBeUserName;
export type CompanyMemberInvitationEmail = FieldBrand<
  UserEmail,
  'CompanyMemberInvitationEmail'
>;
export type CompanyMemberInvitationJobAccess = FieldBrand<
  string[],
  'CompanyMemberInvitationJobAccess'
>;
export type CompanyMemberInvitationStatus = FieldBrand<
  CompanyMemberInvitationStatuses,
  'CompanyMemberInvitationStatus'
>;
export type CompanyMemberInvitationCompanyId = UserCompanyId;
export type MayBeCompanyMemberInvitationCompanyId =
  MayBe<CompanyMemberInvitationCompanyId>;

export type CompanyMemberInvitationCompany = UserCompany;
export type MayBeCompanyMemberInvitationCompany =
  MayBe<CompanyMemberInvitationCompany>;
export type CompanyMemberInvitationUserId = FieldBrand<
  UserId,
  'CompanyMemberInvitationUserId'
>;
export type MayBeCompanyMemberInvitationUserId =
  MayBe<CompanyMemberInvitationUserId>;
export type CompanyMemberInvitationUser = FieldBrand<
  {
    id: UserId;
    nanoId: UserNanoId;
    name: UserName;
    fullName: UserFullName;
    email: UserEmail;
    image: UserImage;
    roles: UserRoles;
  },
  'CompanyMemberInvitationUser'
>;
export type MayBeCompanyMemberInvitationUser =
  MayBe<CompanyMemberInvitationUser>;
export type CompanyMemberInvitationInvitedUserId = FieldBrand<
  UserId,
  'CompanyMemberInvitationInvitedUserId'
>;
export type MayBeCompanyMemberInvitationInvitedUserId =
  MayBe<CompanyMemberInvitationInvitedUserId>;
export type CompanyMemberInvitationInvitedUser = FieldBrand<
  {
    id: UserId;
    nanoId: UserNanoId;
    name: UserName;
    fullName: UserFullName;
    email: UserEmail;
    image: UserImage;
    roles: UserRoles;
  },
  'CompanyMemberInvitationInvitedUser'
>;
export type MayBeCompanyMemberInvitationInvitedUser =
  MayBe<CompanyMemberInvitationUser>;
export type CompanyMemberInvitationAccountTypeId = UserAccountTypeId;
export type MayBeCompanyMemberInvitationAccountTypeId =
  MayBe<CompanyMemberInvitationAccountTypeId>;
export type CompanyMemberInvitationAccountType = UserAccountType;
export type MayBeCompanyMemberInvitationAccountType =
  MayBe<CompanyMemberInvitationAccountType>;
export type CompanyMemberInvitationCreatedAt = FieldBrand<
  ItemCreatedAt,
  'CompanyMemberInvitationCreatedAt'
>;

export const enum CompanyMemberInvitationStatuses {
  NONE = 'none',
  SENT = 'sent',
  OPENED = 'opened'
}

export const enum CompanyMemberInvitationFields {
  ID = 'id',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  NAME = 'name',
  EMAIL = 'email',
  NAME_OR_EMAIL = 'nameOrEmail',
  STATUS = 'status',
  COMPANY_ID = 'companyId',
  COMPANY = 'company',
  USER_ID = 'userId',
  USER = 'user',
  INVITED_USER_ID = 'invitedUserId',
  INVITED_USER = 'invitedUser',
  ACCOUNT_TYPE_ID = 'accountTypeId',
  ACCOUNT_TYPE = 'accountType',
  CREATED_AT = 'createdAt',
  JOB_ACCESS = 'jobAccess'
}
