import { Box, Flex } from '@chakra-ui/react';
import { Text } from '../../../../helpers/Text';

interface JobsCandidateNxmrHelperProps {
  value: number;
}

const JobsCandidateNxmrHelper = ({ value }: JobsCandidateNxmrHelperProps) => {
  const color = value >= 9 ? '#0BAD90' : value >= 5 ? '#EEA401' : '#6872E5';

  return (
    <Flex alignItems="center" overflow="hidden">
      <Text
        h={4}
        w="52px"
        bg="white"
        color={color}
        lineHeight="14px"
        textAlign="center"
        border="1px solid"
        borderColor={color}
        borderRadius="4px 0 0 4px"
        textStyle="upperCase1Medium"
      >
        nxmr
      </Text>

      <Box
        h={4}
        w={8}
        bg={color}
        color="white"
        lineHeight="14px"
        textAlign="center"
        border="1px solid"
        borderColor={color}
        borderRadius="0 4px 4px 0"
        textStyle="upperCase1Medium"
      >
        {value.toFixed(1)}
      </Box>
    </Flex>
  );
};

export default JobsCandidateNxmrHelper;
