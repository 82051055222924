import {
  FetchAccountTypesCacheKey,
  FetchAccountTypeCacheKey
} from './accountTypesTypes';

export class AccountTypesCache {
  static indexCacheKey() {
    return 'accountTypes' as FetchAccountTypesCacheKey;
  }

  static signUpCacheKey() {
    return 'accountTypes-signUp' as FetchAccountTypesCacheKey;
  }

  static invitationCacheKey() {
    return 'accountTypes-invitation' as FetchAccountTypesCacheKey;
  }

  static showCacheKey() {
    return 'accountType' as FetchAccountTypeCacheKey;
  }
}
