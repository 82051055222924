import {
  FetchWorkflowStageTasksCacheKey,
  FetchWorkflowStageTaskCacheKey,
  UpdateWorkflowStageTaskNanoId,
  UpdateWorkflowStageTaskErrorMessage,
  UpdateWorkflowStageTaskIsLoading
} from '../../workflowStageTasksTypes';

import {
  UpdateWorkflowStageTaskRequestData,
  UpdateWorkflowStageTaskErrorResponse
} from '../../WorkflowStageTasksRequests';

import { useUpdateItem } from '../../../common/hooks/useUpdateItem';
import { WorkflowStageTasksBffRequests } from '../../WorkflowStageTasksBffRequests';

interface UpdateWorkflowStageTaskOptions {
  workflowStageTaskNanoId: UpdateWorkflowStageTaskNanoId;
  cacheKeys?: (
    | FetchWorkflowStageTasksCacheKey
    | FetchWorkflowStageTaskCacheKey
  )[];
}

function useUpdateWorkflowStageTask({
  workflowStageTaskNanoId,
  cacheKeys = []
}: UpdateWorkflowStageTaskOptions) {
  const {
    updateItemData,
    updateItemError,
    updateItemErrorMessage,
    updateItemIsLoading,
    updateItem,
    updateItemReset
  } = useUpdateItem<
    UpdateWorkflowStageTaskRequestData,
    UpdateWorkflowStageTaskErrorResponse
  >({
    mutationFn: (queryInput) =>
      // WorkflowStageTasksRequests.updateWorkflowStageTask(
      //   workflowStageTaskNanoId,
      //   queryInput
      // ),
      WorkflowStageTasksBffRequests.updateWorkflowStageTask(
        workflowStageTaskNanoId,
        queryInput
      ),
    cacheKeys
  });

  return {
    updateWorkflowStageTaskData: updateItemData,
    updateWorkflowStageTaskError: updateItemError,
    updateWorkflowStageTaskErrorMessage:
      updateItemErrorMessage as UpdateWorkflowStageTaskErrorMessage,
    updateWorkflowStageTaskIsLoading:
      updateItemIsLoading as UpdateWorkflowStageTaskIsLoading,
    updateWorkflowStageTask: updateItem,
    updateWorkflowStageTaskReset: updateItemReset
  };
}

export default useUpdateWorkflowStageTask;
