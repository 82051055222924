import { usePaginatedItems } from '../../../common/hooks/usePaginatedItems';

import {
  FetchCandidateShareRecipientsQuery,
  FetchCandidateShareRecipientsCacheKey,
  FetchCandidateShareRecipientsTotalCount,
  FetchCandidateShareRecipientsPage,
  FetchCandidateShareRecipientsPageSize,
  FetchCandidateShareRecipientsError,
  FetchCandidateShareRecipientsErrorMessage,
  FetchCandidateShareRecipientsIsLoading,
  FetchCandidateShareRecipientsIsFetched,
  FetchCandidateShareRecipientsIsPlaceholderData,
  FetchCandidateShareRecipientsEnabled,
  FetchCandidateShareRecipientsPrefetchNextPage,
  FetchCandidateShareRecipientsRefetchInterval,
  FetchCandidateShareRecipientsCountType,
  FetchCandidateShareRecipientsFilters,
  FetchCandidateShareRecipientsSort,
  FetchCandidateShareRecipientID,
  FetchCandidateShareRecipientQuery,
  FetchCandidateShareRecipientCacheKey
} from '../../candidateShareRecipientsTypes';

import { CandidateShareRecipientsBffRequests } from '../../CandidateShareRecipientsBffRequests';

interface PaginatedCandidateShareRecipientsBaseOptions<
  PaginatedCandidateShareRecipientsResponseType
> {
  cacheKey: FetchCandidateShareRecipientsCacheKey;
  query: FetchCandidateShareRecipientsQuery;
  countType?: FetchCandidateShareRecipientsCountType;
  initialPage?: FetchCandidateShareRecipientsPage;
  initialPageSize?: FetchCandidateShareRecipientsPageSize;
  initialFilters?: FetchCandidateShareRecipientsFilters;
  initialSort?: FetchCandidateShareRecipientsSort;
  enabled?: FetchCandidateShareRecipientsEnabled;
  prefetchNextPage?: FetchCandidateShareRecipientsPrefetchNextPage;
  refetchInterval?: FetchCandidateShareRecipientsRefetchInterval;
  placeholderCandidateShareRecipients?: PaginatedCandidateShareRecipientsResponseType[];
  placeholderCandidateShareRecipientsCount?: FetchCandidateShareRecipientsTotalCount;
  placeholderData?: () => PaginatedCandidateShareRecipientsResponse<PaginatedCandidateShareRecipientsResponseType>;
}

interface PaginatedCandidateShareRecipientsOptionsWithoutItem {
  fetchItemCacheKey?: never;
  itemQuery?: never;
}

interface PaginatedCandidateShareRecipientsOptionsWithItem {
  fetchItemCacheKey: FetchCandidateShareRecipientCacheKey;
  itemQuery: FetchCandidateShareRecipientQuery;
}

type PaginatedCandidateShareRecipientsOptions<
  PaginatedCandidateShareRecipientsResponseType
> = PaginatedCandidateShareRecipientsBaseOptions<PaginatedCandidateShareRecipientsResponseType> &
  (
    | PaginatedCandidateShareRecipientsOptionsWithoutItem
    | PaginatedCandidateShareRecipientsOptionsWithItem
  );

interface PaginatedCandidateShareRecipientsResponse<
  PaginatedCandidateShareRecipientsResponseType
> {
  data: PaginatedCandidateShareRecipientsResponseType[];
  totalCount: FetchCandidateShareRecipientsTotalCount;
}

function usePaginatedCandidateShareRecipients<
  PaginatedCandidateShareRecipientsResponseType
>({
  cacheKey,
  query,
  countType,
  initialPage,
  initialPageSize,
  initialFilters,
  initialSort,
  enabled,
  prefetchNextPage,
  refetchInterval,
  placeholderCandidateShareRecipients,
  placeholderCandidateShareRecipientsCount,
  placeholderData,
  fetchItemCacheKey,
  itemQuery
}: PaginatedCandidateShareRecipientsOptions<PaginatedCandidateShareRecipientsResponseType>) {
  const {
    items,
    itemsTotalCount,
    itemsError,
    itemsErrorMessage,
    itemsIsFetched,
    itemsIsLoading,
    itemsIsPlaceholderData,
    itemsCurrentPage,
    itemsCurrentPageSize,
    itemsCurrentFilters,
    itemsCurrentSort,
    fetchItems,
    paginateItems,
    changeItemsPageSize,
    filterItems,
    changeItemsFilters,
    clearItemsFilters,
    sortItems,
    prefetchItems,
    prefetchItem
  } = usePaginatedItems<PaginatedCandidateShareRecipientsResponseType>({
    cacheKey,
    initialPage,
    initialPageSize,
    initialFilters,
    initialSort,
    enabled,
    prefetchNextPage,
    refetchInterval,
    placeholderItems: placeholderCandidateShareRecipients,
    placeholderItemsCount: placeholderCandidateShareRecipientsCount,
    placeholderData,
    queryFn: (params) =>
      CandidateShareRecipientsBffRequests.fetchCandidateShareRecipients<PaginatedCandidateShareRecipientsResponseType>(
        {
          query,
          countType,
          page: params.page as FetchCandidateShareRecipientsPage,
          pageSize: params.pageSize as FetchCandidateShareRecipientsPageSize,
          filters: params.filters as FetchCandidateShareRecipientsFilters,
          sort: params.sort as FetchCandidateShareRecipientsSort
        }
      ),
    ...(itemQuery
      ? {
          fetchItemCacheKey,
          queryItemFn: (itemId) =>
            CandidateShareRecipientsBffRequests.fetchCandidateShareRecipient(
              itemId as FetchCandidateShareRecipientID,
              {
                query: itemQuery
              }
            )
        }
      : {})
  });

  return {
    candidateShareRecipients:
      items as PaginatedCandidateShareRecipientsResponseType[],
    candidateShareRecipientsTotalCount:
      itemsTotalCount as FetchCandidateShareRecipientsTotalCount,
    candidateShareRecipientsError:
      itemsError as FetchCandidateShareRecipientsError,
    candidateShareRecipientsErrorMessage:
      itemsErrorMessage as FetchCandidateShareRecipientsErrorMessage,
    candidateShareRecipientsIsLoading:
      itemsIsLoading as FetchCandidateShareRecipientsIsLoading,
    candidateShareRecipientsIsFetched:
      itemsIsFetched as FetchCandidateShareRecipientsIsFetched,
    candidateShareRecipientsIsPlaceholderData:
      itemsIsPlaceholderData as FetchCandidateShareRecipientsIsPlaceholderData,
    candidateShareRecipientsCurrentPage:
      itemsCurrentPage as FetchCandidateShareRecipientsPage,
    candidateShareRecipientsCurrentPageSize:
      itemsCurrentPageSize as FetchCandidateShareRecipientsPageSize,
    candidateShareRecipientsCurrentFilters:
      itemsCurrentFilters as FetchCandidateShareRecipientsFilters,
    candidateShareRecipientsCurrentSort:
      itemsCurrentSort as FetchCandidateShareRecipientsSort,
    fetchCandidateShareRecipients: fetchItems,
    paginateCandidateShareRecipients: paginateItems,
    changeCandidateShareRecipientsPageSize: changeItemsPageSize,
    filterCandidateShareRecipients: filterItems,
    changeCandidateShareRecipientsFilters: changeItemsFilters,
    clearCandidateShareRecipientsFilters: clearItemsFilters,
    sortCandidateShareRecipients: sortItems,
    prefetchCandidateShareRecipients: prefetchItems,
    prefetchCandidateShareRecipient: prefetchItem
  };
}

export default usePaginatedCandidateShareRecipients;
