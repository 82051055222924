import { Icon, IconProps } from '@chakra-ui/icon';

const EyeIcon = ({
  w = '16px',
  h = '16px',
  width,
  height,
  fill = '#98A5A9',
  ...props
}: IconProps) => (
  <Icon
    viewBox="0 0 16 16"
    w={w || width}
    h={h || height}
    fill={fill}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.028 5c1.7 0 3 1.3 3 3s-1.3 3-3 3-3-1.3-3-3 1.3-3 3-3Zm0 9c3.6 0 6.4-3.1 7.6-4.9.5-.7.5-1.6 0-2.3-1.2-1.7-4-4.8-7.6-4.8-3.6 0-6.4 3.1-7.6 4.9-.5.7-.5 1.6 0 2.2 1.2 1.8 4 4.9 7.6 4.9Z"
    />
  </Icon>
);
export default EyeIcon;
