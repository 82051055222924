import { Avatar } from '@chakra-ui/avatar';
import { Flex } from '@chakra-ui/layout';

import { Text } from '../../../../helpers/Text';

interface FormatOptionLabelProps {
  image?: string;
  label: string;
}

function FormatOptionLabel({ image, label }: FormatOptionLabelProps) {
  return (
    <Flex gap={3} direction="row" zIndex={1} w="100%">
      <Avatar size="xs" src={image} borderRadius={0} />
      <Text isTruncated>{label}</Text>
    </Flex>
  );
}

export default FormatOptionLabel;
