import { SystemStyleObject } from '@chakra-ui/react';

const size: Record<string, SystemStyleObject> = {
  large: {
    px: 4,
    h: 12,
    fontSize: 'md',
    borderRadius: 4
  },

  medium: {
    px: 4,
    h: 10,
    fontSize: 'sm',
    borderRadius: 4
  },

  small: {
    px: 4,
    h: 8,
    fontSize: 'xs',
    borderRadius: 4
  }
};

const sizes = {
  lg: {
    field: size.large,
    addon: size.large
  },
  md: {
    field: size.medium,
    addon: size.medium
  },
  sm: {
    field: size.small,
    addon: size.small
  }
};

export const Input = {
  sizes
};
