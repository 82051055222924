import { useQueryClient } from 'react-query';

import { RouterPath, previousRouterCacheKey } from './usePreviousRouter.types';

function usePreviousRouter() {
  const queryClient = useQueryClient();

  const previousRoute = queryClient.getQueryData<RouterPath>(
    previousRouterCacheKey
  );

  return { previousRoute: previousRoute || ('' as RouterPath) };
}

export default usePreviousRouter;
