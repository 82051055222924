import React, {
  forwardRef,
  ForwardedRef,
  ReactNode,
  useCallback,
  useEffect,
  useState,
  PropsWithChildren,
  KeyboardEvent
} from 'react';
import {
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Tooltip
} from '@chakra-ui/react';
import { SystemProps } from '@chakra-ui/system';
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel
} from '@chakra-ui/form-control';
import { Flex, HStack } from '@chakra-ui/layout';
import {
  Props as ChakraSelectProps,
  Select as ReactSelect,
  OnChangeValue,
  GroupBase,
  InputActionMeta,
  MultiValue,
  components,
  OptionProps,
  ValueContainerProps,
  ClearIndicatorProps
} from 'chakra-react-select';
import first from 'lodash/first';
import includes from 'lodash/includes';
import size from 'lodash/size';
import clone from 'lodash/clone';

import { MultiSelectFieldFormatOptionLabel } from '../../helpers/MultiSelectFieldFormatOptionLabel';

import { toCamelCase } from '../../../../../../utils/toCamelCase';

import {
  SelectOptionType,
  SelectOptions,
  MultiSelectFieldControlOnInputChange,
  SelectOptionWithImageType,
  MultiSelectFieldRefInstance
} from './MultiSelectFieldControl.types';

import { ReactSelectMenuButton } from '../../../../../ReactSelectMenuButton';
import { ButtonHierarchyType } from '../../../../../Button';
import { CheckboxField } from '../../../CheckboxField';
import { Text } from '../../../../../Text';

import { DotIcon } from '../../../../../../icons/DotIcon';
import { CancelIcon } from '../../../../../../icons/CancelIcon';

interface MultiSelectFieldControlProps
  extends Omit<ChakraSelectProps, 'onChange'> {
  id?: string;
  label?: string;
  labelAddon?: ReactNode;
  hasError?: boolean;
  errorMessage?: string | null;
  isRequired?: boolean;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  helperText?: string;
  placeholder?: string;
  withImages?: boolean;
  withAddNewButton?: boolean;
  addNewButtonLabel?: string;
  addNewButtonHierarchy?: ButtonHierarchyType;
  addNewButtonDisabled?: boolean;
  addNewButtonWithoutPlusIcon?: boolean;
  addNewButtonAction?: () => void;
  addNewButtonToValueAction?: () => Promise<
    SelectOptionType | SelectOptionType[] | undefined
  >;
  defaultValue?: SelectOptionType[];
  value?: string[] | number[];
  options: SelectOptions;
  onInputChange?: MultiSelectFieldControlOnInputChange;
  onChange: (value: string[], selectedOptions?: SelectOptionType[]) => void;
  visibility?: SystemProps['visibility'];
  maxValues?: number;
  reactSelectStyles?: Record<string, unknown>;
  showDropdownIndicator?: boolean;
  showCounterAsValue?: boolean;
}

const MultiSelectFieldControl = forwardRef<
  MultiSelectFieldRefInstance,
  MultiSelectFieldControlProps
>(
  (
    {
      id,
      label,
      labelAddon,
      // size = 'medium',
      isRequired,
      isDisabled,
      isLoading,
      hasError,
      errorMessage,
      isReadOnly,
      helperText,
      placeholder,
      withImages,
      withAddNewButton,
      addNewButtonLabel,
      addNewButtonHierarchy,
      addNewButtonWithoutPlusIcon,
      addNewButtonDisabled,
      addNewButtonAction,
      addNewButtonToValueAction,
      options,
      defaultValue,
      onInputChange,
      onChange,
      visibility,
      maxValues,
      reactSelectStyles,
      name,
      showDropdownIndicator = false,
      showCounterAsValue = false
    }: MultiSelectFieldControlProps,
    ref: ForwardedRef<MultiSelectFieldRefInstance>
  ) => {
    const [selectedValues, setSelectedValues] = useState<
      MultiValue<SelectOptionType> | undefined
    >(defaultValue || undefined);
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = useCallback<
      (updatedValue: string, actionMeta: InputActionMeta) => void
    >(
      (updatedValue, actionMeta) => {
        if (actionMeta.action === 'input-change') {
          setInputValue(updatedValue);
          return onInputChange?.(updatedValue, actionMeta);
        }

        return undefined;
      },
      [onInputChange]
    );

    const handleChange = useCallback<
      (newValue: OnChangeValue<SelectOptionType, true>) => void
    >(
      (newValues) => {
        setSelectedValues(newValues);
        setInputValue('');
        onChange(
          newValues.map(({ value: newValue }) => newValue),
          newValues as SelectOptionType[]
        );
      },
      [onChange]
    );

    const [menuOpen, setMenuOpen] = useState(false);
    const [doc, setDocument] = useState<Document>();

    useEffect(() => {
      setDocument(document);
    }, []);

    const closeMenu = () => {
      setMenuOpen(false);
    };

    const addNewAction = useCallback(async () => {
      if (addNewButtonToValueAction) {
        const newSelectedValue = await addNewButtonToValueAction();
        if (newSelectedValue) {
          if (Array.isArray(newSelectedValue)) {
            handleChange([...newSelectedValue]);
          } else {
            handleChange([
              ...(Array.isArray(selectedValues) ? selectedValues : []),
              newSelectedValue
            ]);
          }
        }
      } else {
        addNewButtonAction && addNewButtonAction();
        setInputValue('');
      }
      closeMenu();
    }, [
      addNewButtonAction,
      addNewButtonToValueAction,
      selectedValues,
      handleChange
    ]);

    const handleKeyDown = useCallback<
      (event: KeyboardEvent<HTMLDivElement>) => void
    >(
      (event) => {
        if (event.key === 'Enter' && inputValue && withAddNewButton) {
          event.preventDefault();
          addNewAction();
        }
      },
      [inputValue, withAddNewButton, addNewAction]
    );

    const selectedValueValues = selectedValues?.map(({ value }) => value);

    const dropdownIndicatorDisplayValue = () => {
      if (size(selectedValues) && showCounterAsValue) return 'none';

      if (showDropdownIndicator) return 'flex';

      return 'none';
    };

    const isInvalid =
      hasError ||
      (typeof errorMessage === 'string' && errorMessage.length !== 0);

    const showTooltip =
      showCounterAsValue && (menuOpen || size(selectedValueValues) === 1);

    return (
      <Tooltip
        label={placeholder}
        aria-label="title"
        placement="top"
        isDisabled={!showTooltip}
      >
        <FormControl
          isInvalid={isInvalid}
          isDisabled={isDisabled}
          isRequired={isRequired}
          isReadOnly={isReadOnly}
          id={!id && label ? toCamelCase(label) : id}
          visibility={visibility}
        >
          {(label || labelAddon) && (
            <Flex alignItems="center">
              {label && <FormLabel display="flex">{label}</FormLabel>}

              {labelAddon && (
                <FormLabel
                  mr={0}
                  ml="auto"
                  display="flex"
                  as="aside"
                  alignItems="center"
                  requiredIndicator={<></>}
                >
                  {labelAddon}
                </FormLabel>
              )}
            </Flex>
          )}

          <ReactSelect<
            SelectOptionType | SelectOptionWithImageType,
            true,
            GroupBase<SelectOptionType | SelectOptionWithImageType>
          >
            isMulti
            name={name}
            ref={ref}
            isInvalid={isInvalid}
            inputId={id}
            placeholder={placeholder}
            onInputChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            focusBorderColor="primary.500"
            hideSelectedOptions={false}
            selectedOptionStyle="check"
            menuIsOpen={menuOpen}
            onMenuOpen={() => setMenuOpen(true)}
            onMenuClose={closeMenu}
            closeMenuOnSelect={true}
            blurInputOnSelect={false}
            formatOptionLabel={(data) => (
              <MultiSelectFieldFormatOptionLabel
                {...{ ...data }}
                withImages={withImages}
              />
            )}
            menuPortalTarget={doc?.body}
            menuPosition="fixed"
            styles={{
              menuPortal: (provided) => ({
                ...provided,
                zIndex: 9999
              }),
              menu: (provided) => ({ ...provided, zIndex: 9999 })
            }}
            className="form-select-class"
            components={{
              ...(withAddNewButton
                ? {
                    MenuList: (props) =>
                      ReactSelectMenuButton({
                        ...props,
                        buttonLabel: addNewButtonLabel,
                        buttonAction: addNewAction,
                        buttonDisabled: maxValues
                          ? size(selectedValues) >= maxValues
                          : addNewButtonDisabled,
                        buttonHierarchy: addNewButtonHierarchy,
                        withoutPlusIcon: addNewButtonWithoutPlusIcon
                      })
                  }
                : {}),
              ...(showCounterAsValue ? { ValueContainer } : {}),
              Option: (props) =>
                Option({
                  ...props,
                  withImages
                }),
              ClearIndicator
            }}
            aria-label={label}
            isDisabled={isDisabled}
            isOptionDisabled={(option) =>
              maxValues
                ? size(selectedValues) >= maxValues &&
                  !includes(selectedValueValues, option.value)
                : option.isDisabled || false
            }
            isLoading={isLoading}
            menuPlacement="auto"
            menuShouldScrollIntoView
            defaultValue={defaultValue}
            value={selectedValues}
            inputValue={inputValue}
            options={options}
            chakraStyles={{
              dropdownIndicator: (provided) => ({
                ...provided,
                bg: 'transparent',
                display: dropdownIndicatorDisplayValue(),
                w: 6
              }),
              option: (provided) => ({
                ...provided,
                h: 10,
                px: 4
              }),
              indicatorSeparator: (provided) => ({
                ...provided,
                display: 'none'
              }),
              clearIndicator: (provided) => ({
                ...provided,
                w: 4,
                h: 4,
                p: '8px 8px 8px 0px'
              }),
              loadingIndicator: (provided) => ({
                ...provided,
                w: 4,
                h: 4
              }),
              ...reactSelectStyles
            }}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary: '#0580A4'
              }
            })}
          />

          {errorMessage ? (
            <FormErrorMessage wordBreak="break-all">
              {errorMessage}
            </FormErrorMessage>
          ) : (
            helperText && <FormHelperText>{helperText}</FormHelperText>
          )}
        </FormControl>
      </Tooltip>
    );
  }
);

const Option = ({
  withImages,
  ...props
}: PropsWithChildren<
  OptionProps<
    SelectOptionType | SelectOptionWithImageType,
    true,
    GroupBase<SelectOptionType | SelectOptionWithImageType>
  > & { withImages?: boolean }
>) => {
  return (
    <components.Option
      {...props}
      className={`custom-nxmoov-multi-select-option ${
        props.isDisabled ? 'custom-nxmoov-multi-select-option-disabled' : ''
      }`}
    >
      <CheckboxField
        isChecked={props.isSelected}
        size="small"
        cursor={props.isDisabled ? 'not-allowed' : 'pointer'}
        label={
          <MultiSelectFieldFormatOptionLabel
            {...{ ...props.data }}
            withImages={withImages}
          />
        }
      />
    </components.Option>
  );
};

const ClearIndicator = ({
  innerProps,
  ...props
}: PropsWithChildren<
  ClearIndicatorProps<
    SelectOptionType | SelectOptionWithImageType,
    true,
    GroupBase<SelectOptionType | SelectOptionWithImageType>
  >
>) => {
  return (
    <components.ClearIndicator
      innerProps={{ ...innerProps, style: { padding: '8px 8px 8px 0' } }}
      {...props}
    >
      <CancelIcon />
    </components.ClearIndicator>
  );
};

const ValueContainer = (
  props: PropsWithChildren<
    ValueContainerProps<
      SelectOptionType | SelectOptionWithImageType,
      true,
      GroupBase<SelectOptionType | SelectOptionWithImageType>
    >
  >
) => {
  const { hasValue, children, getValue, selectProps } = props;

  const { placeholder, menuIsOpen, inputValue } = selectProps;

  const label = selectProps['aria-label'] as string;

  const selectedValues = getValue();

  const newChildren = clone(children) as Array<ReactNode>;

  const hidePlaceholder = !!inputValue || menuIsOpen;

  if (!hasValue)
    return (
      <components.ValueContainer {...props}>
        {newChildren[1]}
        {hidePlaceholder ? null : (
          <Text position="absolute">{placeholder}</Text>
        )}
      </components.ValueContainer>
    );

  if (size(selectedValues) === 1) {
    return (
      <components.ValueContainer {...props}>
        {newChildren[1]}
        {hidePlaceholder ? null : (
          <Text noOfLines={1} position="absolute">
            {first(selectedValues)?.label}
          </Text>
        )}
      </components.ValueContainer>
    );
  }
  return (
    <components.ValueContainer {...props}>
      {newChildren[1]}
      {hidePlaceholder ? null : (
        <Popover trigger="hover">
          <PopoverTrigger>
            <HStack spacing={0.5} position="absolute">
              <Text color="primary.500" noOfLines={1}>
                {label || placeholder}
              </Text>
              <Flex
                alignItems="center"
                justifyContent="center"
                w={5}
                h={5}
                bgColor="primary.500"
                borderRadius="100%"
                color="white"
              >
                {size(selectedValues)}
              </Flex>
            </HStack>
          </PopoverTrigger>
          <Portal>
            {menuIsOpen ? null : (
              <PopoverContent w="200px">
                <PopoverBody>
                  {selectedValues?.map(({ value, label }) => (
                    <HStack spacing={1.5} key={value} alignItems="start">
                      <DotIcon color="gray.500" mt={2} />
                      <Text textStyle="body1Regular" color="gray.900">
                        {label}
                      </Text>
                    </HStack>
                  ))}
                </PopoverBody>
              </PopoverContent>
            )}
          </Portal>
        </Popover>
      )}
    </components.ValueContainer>
  );
};

MultiSelectFieldControl.displayName = 'MultiSelectFieldControl';

export default MultiSelectFieldControl;
