import {
  FetchWorkflowStagesQuery,
  FetchWorkflowStagesCountType,
  FetchWorkflowStagesPage,
  FetchWorkflowStagesPageSize,
  FetchWorkflowStagesFilters,
  FetchWorkflowStagesSort,
  FetchWorkflowStageQuery,
  CreateWorkflowStageError,
  UpdateWorkflowStageError,
  DeleteWorkflowStageError,
  WorkflowStageId,
  WorkflowStageNanoId,
  WorkflowStageName,
  WorkflowStageArrangement,
  WorkflowStageJobId,
  WorkflowStageUserId
} from './workflowStagesTypes';

import { BaseBffRequests } from '../BaseBffRequests';
import { WorkflowStagesBffRoutes } from './WorkflowStagesBffRoutes';

interface FetchWorkflowStagesRequestParams {
  query: FetchWorkflowStagesQuery;
  countType?: FetchWorkflowStagesCountType;
  page?: FetchWorkflowStagesPage;
  pageSize?: FetchWorkflowStagesPageSize;
  filters?: FetchWorkflowStagesFilters;
  sort?: FetchWorkflowStagesSort;
}

interface FetchWorkflowStageRequestParams {
  query: FetchWorkflowStageQuery;
}

export interface CreateWorkflowStageRequestData {
  nanoId: WorkflowStageNanoId;
  jobId: WorkflowStageJobId;
  userId?: WorkflowStageUserId;
  name?: WorkflowStageName;
  arrangement?: WorkflowStageArrangement;
}

export interface UpdateWorkflowStageRequestData {
  name?: WorkflowStageName;
}

export interface DeleteWorkflowStageRequestData {
  workflowStageNanoId: WorkflowStageNanoId;
}

export interface CreateWorkflowStageErrorResponse {
  message: CreateWorkflowStageError;
}

export interface UpdateWorkflowStageErrorResponse {
  message: UpdateWorkflowStageError;
}

export interface DeleteWorkflowStageErrorResponse {
  message: DeleteWorkflowStageError;
}

export class WorkflowStagesBffRequests extends BaseBffRequests {
  static fetchWorkflowStages<T>(params: FetchWorkflowStagesRequestParams) {
    return this.fetchItems<T>(
      WorkflowStagesBffRoutes.fetchWorkflowStagesRoute(),
      params
    );
  }

  static fetchWorkflowStage<T>(
    workflowStageId: WorkflowStageId,
    params: FetchWorkflowStageRequestParams
  ) {
    return this.fetchItem<T>(
      WorkflowStagesBffRoutes.fetchWorkflowStageRoute(workflowStageId),
      params
    );
  }

  static fetchWorkflowStageByNanoId<T>(
    workflowStageNanoId: WorkflowStageNanoId,
    params: FetchWorkflowStageRequestParams
  ) {
    return this.fetchItemByNanoId<T>(
      WorkflowStagesBffRoutes.fetchWorkflowStageByNanoIdRoute(
        workflowStageNanoId
      ),
      params
    );
  }

  static createWorkflowStage<T>(
    createWorkflowStageData: CreateWorkflowStageRequestData
  ) {
    return this.createItem<T, CreateWorkflowStageRequestData>(
      WorkflowStagesBffRoutes.createWorkflowStageRoute(),
      createWorkflowStageData
    );
  }

  static async updateWorkflowStage(
    updateWorkflowStageNanoId: WorkflowStageNanoId,
    updateWorkflowStageData: UpdateWorkflowStageRequestData
  ) {
    const { data, error } = await this.updateItem<
      { data: UpdateWorkflowStageRequestData; error: string | null },
      UpdateWorkflowStageRequestData
    >(
      WorkflowStagesBffRoutes.updateWorkflowStageRoute(
        updateWorkflowStageNanoId
      ),
      updateWorkflowStageData
    );

    if (error) {
      throw error;
    }

    return data;
  }

  static async deleteWorkflowStage(
    deleteWorkflowStageNanoId: WorkflowStageNanoId
  ) {
    const { data, error } = await this.deleteItem<{
      data: DeleteWorkflowStageRequestData;
      error: string | null;
    }>(
      WorkflowStagesBffRoutes.deleteWorkflowStageRoute(
        deleteWorkflowStageNanoId
      )
    );

    if (error) {
      throw error;
    }

    return data;
  }
}
