import { useCallback } from 'react';
import { FormControl, FormLabel, Stack } from '@chakra-ui/react';
import { Control, useFieldArray } from 'react-hook-form';
import size from 'lodash/size';

import {
  UserPhoneNumberLabel,
  UserPhoneNumberPhoneNumber
} from '../../../../../../../../userPhoneNumbers/userPhoneNumbersTypes';

import { EditUserFormAddPhoneField } from '../../../../../../../../users/components/forms/AddEditUserForm/components/EditUserForm/components/EditUserFormAddPhoneField';

import { PlusIcon } from '../../../../../../../../../icons/PlusIcon';

import { Button } from '../../../../../../../../../helpers/Button';

import { AddEditContactFormData } from '../../../../AddEditContactForm.types';

interface AddEditUserFormAddPhoneFieldsProps {
  control: Control<AddEditContactFormData>;
  errorMessages: { [key: string]: { message?: string } }[];
}

function AddEditUserFormAddPhoneFields({
  control,
  errorMessages
}: AddEditUserFormAddPhoneFieldsProps) {
  const { fields, append, remove } = useFieldArray<
    AddEditContactFormData,
    'contactPhoneNumbers'
  >({
    control,
    name: 'contactPhoneNumbers'
  });

  const handleAddNew = useCallback<() => void>(
    () =>
      append(
        {
          phoneNumber: '' as UserPhoneNumberPhoneNumber,
          label: '' as UserPhoneNumberLabel
        },
        {
          shouldFocus: false
        }
      ),
    [append]
  );

  const handleRemoveItem = useCallback<(index: number) => void>(
    (index: number) => {
      if (fields.length > 1) {
        remove(index);
      }
    },
    [fields.length, remove]
  );

  // if (fields.length === 0) {
  //   handleAddNew();
  // }

  return (
    <FormControl>
      <FormLabel htmlFor={`contactPhoneNumbers.${0}.phoneNumber`}>
        Phone number
      </FormLabel>

      <Stack spacing={3}>
        {fields.map((field, index) => (
          <EditUserFormAddPhoneField
            key={field.id}
            control={control}
            name="contactPhoneNumbers"
            index={index}
            error={errorMessages?.[index]}
            labelValue={field.label}
            onRemove={handleRemoveItem}
            withoutRemove={size(fields) === 1}
          />
        ))}
      </Stack>

      <Button
        mt={3}
        size="small"
        hierarchy="link"
        onClick={handleAddNew}
        leftIcon={<PlusIcon color="primary.500" w={4} h={4} mr={-1} />}
      >
        Add phone number
      </Button>
    </FormControl>
  );
}

export default AddEditUserFormAddPhoneFields;
