import {
  FieldBrand,
  MayBe,
  ItemID,
  ItemIDs,
  ItemUUID,
  ItemNanoId,
  ItemCreatedAt
} from '../../../types';

import { CityId, CityLabel, CityName } from '../../cities/citiesTypes';

import {
  CompanyId,
  CompanyImage,
  CompanyName,
  CompanyNanoId
} from '../../companies/companiesTypes';

import {
  CountryId,
  CountryName,
  CountryCode,
  CountryLabel
} from '../../countries/countriesTypes';

import {
  JobFunctionId,
  JobFunctionNanoId,
  JobFunctionName
} from '../../jobFunctions/jobFunctionsTypes';

import {
  JobTitleId,
  JobTitleNanoId,
  JobTitleName
} from '../../jobTitles/jobTitlesTypes';

import {
  ImageId,
  ImageNanoId,
  ImageName,
  ImageFile,
  ImageKey,
  ImageCreatedAt
} from '../../images/imagesTypes';

import {
  UserId,
  UserNanoId,
  UserName,
  UserFullName,
  UserEmail,
  UserImage,
  UserRoles,
  UserCompany
} from '../../users/usersTypes';

import {
  UserEmailEmail,
  UserEmailId,
  UserEmailLabel,
  UserEmailNanoId
} from '../../userEmails/userEmailsTypes';

import {
  UserPhoneNumberId,
  UserPhoneNumberLabel,
  UserPhoneNumberNanoId,
  UserPhoneNumberPhoneNumber
} from '../../userPhoneNumbers/userPhoneNumbersTypes';

import {
  EmploymentId,
  EmploymentNanoId,
  MayBeEmploymentTitle,
  MayBeEmploymentStartDate,
  MayBeEmploymentEndDate,
  MayBeEmploymentCityId,
  MayBeEmploymentCity,
  MayBeEmploymentCompanyId,
  MayBeEmploymentCompany,
  EmploymentJobTitle,
  EmploymentJobTitleId,
  EmploymentJobFunctions,
  EmploymentJobFunctionIds,
  EmploymentIndustries,
  EmploymentIndustryIds,
  EmploymentCurrently
} from '../../employments/employmentsTypes';

import {
  CertificationId,
  CertificationName,
  CertificationNanoId,
  CertificationCredentialsId,
  MayBeCertificationCompanyId,
  MayBeCertificationIssueDate,
  MayBeCertificationStartDate,
  MayBeCertificationEndDate
} from '../../certifications/certificationsTypes';

import {
  EducationId,
  EducationNanoId,
  EducationDegree,
  EducationSchoolName,
  EducationSchoolType,
  EducationStudyField,
  MayBeEducationStartDate,
  MayBeEducationEndDate,
  MayBeEducationCity
} from '../../educations/educationsTypes';

import {
  StateId,
  StateIsoCode,
  StateLabel,
  StateName
} from '../../states/statesTypes';
import { SkillIds } from '../../skills/skillsTypes';
import { AccountTypeId } from '../../accountTypes/accountTypesTypes';

export type ContactId = FieldBrand<ItemID, 'ContactId'>;
export type MayBeContactId = MayBe<ContactId>;
export type ContactIds = FieldBrand<ItemIDs, 'ContactIds'>;
export type ContactUuid = FieldBrand<ItemUUID, 'ContactUuid'>;
export type MayBeContactUuid = MayBe<ContactUuid>;
export type ContactNanoId = FieldBrand<ItemNanoId, 'ContactNanoId'>;
export type MayBeContactNanoId = MayBe<ContactNanoId>;
export type ContactName = FieldBrand<string, 'ContactName'>;
export type ContactFirstName = FieldBrand<string, 'ContactFirstName'>;
export type MayBeContactFirstName = MayBe<ContactFirstName>;
export type ContactLastName = FieldBrand<string, 'ContactLastName'>;
export type MayBeContactLastName = MayBe<ContactLastName>;
export type ContactMiddleName = FieldBrand<string, 'ContactMiddleName'>;
export type MayBeContactMiddleName = MayBe<ContactMiddleName>;
export type ContactPersonalEmail = FieldBrand<
  UserEmail,
  'ContactPersonalEmail'
>;
export type MayBeContactPersonalEmail = MayBe<ContactPersonalEmail>;
export type ContactWorkEmail = FieldBrand<UserEmail, 'ContactWorkEmail'>;
export type MayBeContactWorkEmail = MayBe<ContactWorkEmail>;
export type ContactLeadAt = FieldBrand<string, 'ContactLeadAt'>;
export type MayBeContactLeadAt = MayBe<ContactLeadAt>;
export type ContactLinkedinUrl = FieldBrand<string, 'ContactLinkedinUrl'>;
export type MayBeContactLinkedinUrl = MayBe<ContactLinkedinUrl>;
export type ContactEmails = FieldBrand<string[], 'ContactEmails'>;
export type ContactPhones = FieldBrand<string[], 'ContactPhones'>;
export type ContactUserId = FieldBrand<UserId, 'ContactUserId'>;
export type MayBeContactUserId = MayBe<ContactUserId>;
export type ContactUser = FieldBrand<
  {
    id: UserId;
    nanoId: UserNanoId;
    name: UserName;
    fullName: UserFullName;
    email: UserEmail;
    image: UserImage;
    company: UserCompany;
    roles: UserRoles;
  },
  'ContactUser'
>;
export type MayBeContactUser = MayBe<ContactUser>;
export type ContactJobFunctionId = FieldBrand<
  JobFunctionId,
  'ContactJobFunctionId'
>;
export type MayBeContactJobFunctionId = MayBe<ContactJobFunctionId>;
export type ContactJobFunction = FieldBrand<
  {
    id: JobFunctionId;
    nanoId: JobFunctionNanoId;
    name: JobFunctionName;
  },
  'ContactJobFunction'
>;
export type MayBeContactJobFunction = MayBe<ContactJobFunction>;
export type ContactJobTitleId = FieldBrand<JobTitleId, 'ContactJobTitleId'>;
export type MayBeContactJobTitleId = MayBe<ContactJobTitleId>;
export type ContactJobTitle = FieldBrand<
  {
    id: JobTitleId;
    nanoId: JobTitleNanoId;
    name: JobTitleName;
  },
  'ContactJobTitle'
>;
export type MayBeContactJobTitle = MayBe<ContactJobTitle>;
export type ContactCountryId = FieldBrand<CountryId, 'ContactCountryId'>;
export type MayBeContactCountryId = MayBe<ContactCountryId>;
export type ContactCountry = FieldBrand<
  { id: CountryId; name: CountryName; label: CountryLabel; code: CountryCode },
  'CountryContact'
>;
export type MayBeContactCountry = MayBe<ContactCountry>;
export type ContactImageId = FieldBrand<CountryId, 'ContactImageId'>;
export type MayBeContactImageId = MayBe<ContactImageId>;
export type ContactImage = FieldBrand<
  {
    id: ImageId;
    nanoId: ImageNanoId;
    name: ImageName;
    file: ImageFile;
    key: ImageKey;
    createdAt: ImageCreatedAt;
  },
  'ContactImage'
>;
export type MayBeContactImage = MayBe<ContactImage>;
export type ContactCompanyId = FieldBrand<CompanyId, 'ContactCompanyId'>;
export type MayBeContactCompanyId = MayBe<ContactCompanyId>;
export type ContactCompany = FieldBrand<
  {
    id: CompanyId;
    nanoId: CompanyNanoId;
    name: CompanyName;
    image: CompanyImage;
  },
  'ContactCompany'
>;
export type MayBeContactCompany = MayBe<ContactCompany>;
export type ContactStateId = FieldBrand<StateId, 'ContactStateId'>;
export type MayBeContactStateId = MayBe<ContactStateId>;
export type ContactState = FieldBrand<
  { id: StateId; name: StateName; label: StateLabel; isoCode: StateIsoCode },
  'ContactState'
>;
export type MayBeContactState = MayBe<ContactState>;
export type ContactCityId = FieldBrand<CityId, 'ContactCityId'>;
export type MayBeContactCityId = MayBe<ContactCityId>;
export type ContactCity = FieldBrand<
  { id: CityId; name: CityName; label: CityLabel },
  'ContactCity'
>;
export type MayBeContactCity = MayBe<ContactCity>;
export type ContactZipCode = FieldBrand<string, 'ContactZipCode'>;
export type MayBeContactZipCode = MayBe<ContactZipCode>;
export type ContactCreatedAt = FieldBrand<ItemCreatedAt, 'ContactCreatedAt'>;

export type ContactOtherDetails = FieldBrand<string, 'ContactOtherDetails'>;
export type MayBeContactOtherDetails = MayBe<ContactOtherDetails>;

export type ContactSource = FieldBrand<string, 'ContactSource'>;
export type MayBeContactSource = MayBe<ContactSource>;

export type ContactExperience = FieldBrand<string, 'ContactExperience'>;
export type MayBeContactExperience = MayBe<ContactExperience>;
export type ContactSkillIds = SkillIds;

export type ContactContactEmails = FieldBrand<
  {
    id: UserEmailId;
    nanoId: UserEmailNanoId;
    email: UserEmailEmail;
    label: UserEmailLabel;
  }[],
  'ContactContactEmails'
>;
export type ContactContactPhoneNumbers = FieldBrand<
  {
    id: UserPhoneNumberId;
    nanoId: UserPhoneNumberNanoId;
    phoneNumber: UserPhoneNumberPhoneNumber;
    label: UserPhoneNumberLabel;
  }[],
  'ContactContactPhoneNumbers'
>;
export type ContactEmployment = FieldBrand<
  {
    id: EmploymentId;
    nanoId: EmploymentNanoId;
    title: MayBeEmploymentTitle;
    startDate: MayBeEmploymentStartDate;
    endDate: MayBeEmploymentEndDate;
    cityId: MayBeEmploymentCityId;
    city: MayBeEmploymentCity;
    companyId: MayBeEmploymentCompanyId;
    company: MayBeEmploymentCompany;
    jobTitle: EmploymentJobTitle;
    jobTitleId: EmploymentJobTitleId;
    jobFunctions: EmploymentJobFunctions;
    jobFunctionIds: EmploymentJobFunctionIds;
    industries: EmploymentIndustries;
    industryIds: EmploymentIndustryIds;
    currently: EmploymentCurrently;
  },
  'ContactEmployment'
>;
export type ContactEmployments = FieldBrand<
  ContactEmployment[],
  'ContactEmployments'
>;
export type ContactEducations = FieldBrand<
  {
    id: EducationId;
    nanoId: EducationNanoId;
    degree: EducationDegree;
    studyField: EducationStudyField;
    schoolName: EducationSchoolName;
    schoolType: EducationSchoolType;
    startDate: MayBeEducationStartDate;
    endDate: MayBeEducationEndDate;
    city: MayBeEducationCity;
  }[],
  'ContactEducations'
>;
export type ContactCertifications = FieldBrand<
  {
    id: CertificationId;
    nanoId: CertificationNanoId;
    name: CertificationName;
    credentialsId: CertificationCredentialsId;
    startDate: MayBeCertificationStartDate;
    endDate: MayBeCertificationEndDate;
    issueDate: MayBeCertificationIssueDate;
    companyId: MayBeCertificationCompanyId;
    company: MayBeCertificationCompanyId;
  }[],
  'ContactCertifications'
>;

export type ContactAccountTypeId = FieldBrand<
  AccountTypeId,
  'ContactAccountTypeId'
>;

export const enum ContactFields {
  ID = 'id',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  NAME = 'name',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  LINKEDIN_URL = 'linkedinUrl',
  MIDDLE_NAME = 'middleName',
  PERSONAL_EMAIL = 'personalEmail',
  WORK_EMAIL = 'workEmail',
  LEAD_AT = 'leadAt',
  REMOVED_AT = 'removedAt',
  IMAGE_ID = 'imageId',
  IMAGE = 'image',
  COMPANY_ID = 'companyId',
  COMPANY = 'company',
  INDUSTRY_ID = 'industryId',
  INDUSTRY = 'industry',
  COUNTRY_ID = 'countryId',
  COUNTRY = 'country',
  STATE_ID = 'stateId',
  STATE = 'state',
  CITY_ID = 'cityId',
  CITY = 'city',
  ZIP_CODE = 'zipCode',
  SOURCE = 'source',
  JOB_FUNCTION_ID = 'jobFunctionId',
  JOB_FUNCTION = 'jobFunction',
  JOB_TITLE_ID = 'jobTitleId',
  JOB_TITLE = 'jobTitle',
  ROLE = 'role',
  ROLE_ID = 'roleId',
  USER_ID = 'userId',
  USER = 'user',
  EMPLOYMENTS = 'profileEmployments',
  EDUCATIONS = 'profileEducations',
  CERTIFICATIONS = 'profileCertifications',
  CONTACT_EMAILS = 'contactEmails',
  CONTACT_PHONE_NUMBERS = 'contactPhoneNumbers',
  EXPERIENCE = 'experience',
  OTHER_DETAILS = 'otherDetails',
  SKILL_IDS = 'skillIds',
  NAME_OR_EMAIL = 'nameOrEmail',
  CREATED_AT = 'createdAt',
  ACCOOUNT_TYPE_ID = 'accountTypeId'
}
