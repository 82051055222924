import { useDisclosure } from '@chakra-ui/react';
import first from 'lodash/first';
import reduce from 'lodash/reduce';
import uniqBy from 'lodash/uniqBy';
import { createContext, useCallback, useContext, useState } from 'react';
import { useCurrentUser } from '../../../../../../auth/hooks/useAuth';
import { useChakraToast } from '../../../../../../helpers/useChakraToast';
import { CandidateSubmissionJobCandidatesCache } from '../../../../../candidateSubmissionJobCandidates/CandidateSubmissionJobCandidatesCache';
import {
  CandidateSubmissionJobCandidateFields,
  FetchCandidateSubmissionJobCandidatesEnabled,
  FetchCandidateSubmissionJobCandidatesFilters
} from '../../../../../candidateSubmissionJobCandidates/candidateSubmissionJobCandidatesTypes';
import { usePaginatedCandidateSubmissionJobCandidates } from '../../../../../candidateSubmissionJobCandidates/hooks/usePaginatedCandidateSubmissionJobCandidates';
import {
  fetchCandidateSubmissionJobCandidatesQuery,
  FetchCandidateSubmissionJobCandidatesResponse
} from '../../../../../candidateSubmissionJobCandidates/queries/fetchCandidateSubmissionJobCandidates.query';
import { CreateCandidateSubmissionRequestData } from '../../../../../candidateSubmissions/CandidateSubmissionsBffRequests';
import { CandidateSubmissionsCache } from '../../../../../candidateSubmissions/CandidateSubmissionsCache';
import {
  CandidateSubmissionBaseSalary,
  CandidateSubmissionCandidateShelfLife,
  CandidateSubmissionComment,
  CandidateSubmissionEvaluation,
  CandidateSubmissionEvaluations,
  CandidateSubmissionEvaluationsEnum,
  CandidateSubmissionOverallEvaluation,
  CandidateSubmissionRecipients,
  CandidateSubmissionShareSettingsField,
  CandidateSubmissionShareSettingsFields,
  CandidateSubmissionVisibilityOptionType,
  CandidateSubmissionVisibilityOptionTypeEnum,
  FetchCandidateSubmissionsCacheKey
} from '../../../../../candidateSubmissions/candidateSubmissionsTypes';
import { useCreateCandidateSubmission } from '../../../../../candidateSubmissions/hooks/useCreateCandidateSubmission';
import { useSendEmailsCandidateSubmission } from '../../../../../candidateSubmissions/hooks/useSendEmailsCandidateSubmission';
import { useFetchJobCandidateByNanoId } from '../../../../../jobCandidates/hooks/useFetchJobCandidateByNanoId';
import { JobCandidatesCache } from '../../../../../jobCandidates/JobCandidatesCache';
import {
  FetchJobCandidateEnabled,
  JobCandidateNanoId
} from '../../../../../jobCandidates/jobCandidatesTypes';
import {
  fetchJobCandidateQuery,
  FetchJobCandidateResponse
} from '../../../../../jobCandidates/queries/fetchJobCandidate.query';
import { WorkflowStagesCache } from '../../../../../workflowStages/WorkflowStagesCache';
import { useFetchJobByNanoId } from '../../../../hooks/useFetchJobByNanoId';
import { JobsCache } from '../../../../JobsCache';
import { FetchJobEnabled } from '../../../../jobsTypes';
import {
  fetchJobQuery,
  FetchJobResponse
} from '../../../../queries/fetchJob.query';
import { CustomiseCandidateSubmissionNav } from '../../components/ViewJobCandidateDetailsSubmissionSidebar/components/CustomiseCandidateSubmissionNav';
import {
  ViewJobCandidateDetailsSubmissionContextType,
  ViewJobCandidateDetailsSubmissionProviderProps
} from './ViewJobCandidateDetailsSubmissionContext.types';

const ViewJobCandidateDetailsSubmissionContext =
  createContext<ViewJobCandidateDetailsSubmissionContextType>({
    totalSteps: 4,
    currentStep: 1,
    shareSettings: [],
    sharedFields: [],
    handleShareField: function (): void {
      /*  */
    },
    isLastStep: false,
    isFirstStep: false,
    isCandidateSubmissionOpen: false,
    selectedOverallEvaluation: undefined,
    overallComment: '' as CandidateSubmissionComment,
    evaluations: {} as CandidateSubmissionEvaluations,
    visibilityOptionType:
      CandidateSubmissionVisibilityOptionTypeEnum.AllHiringTeam as CandidateSubmissionVisibilityOptionType,
    recipients: [] as unknown as CandidateSubmissionRecipients,
    candidateShelfLife: undefined,
    baseSalary: '' as CandidateSubmissionBaseSalary,
    errorMessage: '',
    setCandidateShelfLife: () => {
      /*  */
    },
    setBaseSalary: () => {
      /*  */
    },
    setVisibilityOptionType: () => {
      /*  */
    },
    updateEvaluations: () => {
      /*  */
    },
    setOverallComment: () => {
      /*  */
    },
    setSelectedOverallEvaluation: () => {
      /*  */
    },
    openCandidateSubmission: function (): void {
      /*  */
    },
    closeCandidateSubmission: function (): void {
      /*  */
    },
    goToPrevStep: function (): void {
      /*  */
    },
    goToNextStep: function (): void {
      /*  */
    },
    sendSubmission: function (): void {
      /*  */
    }
  });

export function ViewJobCandidateDetailsSubmissionProvider({
  children,
  jobNanoId,
  jobCandidateNanoId,
  isSubmitProfileOpen,
  onRemoveOpenSubmitFromQuery,
  isHiringPortalPath
}: ViewJobCandidateDetailsSubmissionProviderProps) {
  const toast = useChakraToast();
  const currentUser = useCurrentUser();
  const { job } = useFetchJobByNanoId<FetchJobResponse>({
    query: fetchJobQuery,
    jobNanoId,
    enabled: !!jobNanoId as FetchJobEnabled,
    cacheKey: JobsCache.showCacheKey()
  });
  const { jobCandidate } =
    useFetchJobCandidateByNanoId<FetchJobCandidateResponse>({
      jobCandidateNanoId: jobCandidateNanoId as JobCandidateNanoId,
      enabled: !!jobCandidateNanoId as FetchJobCandidateEnabled,
      cacheKey: JobCandidatesCache.showCacheKey(),
      query: fetchJobCandidateQuery
    });

  const { candidateSubmissionJobCandidates } =
    usePaginatedCandidateSubmissionJobCandidates<FetchCandidateSubmissionJobCandidatesResponse>(
      {
        query: fetchCandidateSubmissionJobCandidatesQuery,
        cacheKey:
          CandidateSubmissionJobCandidatesCache.jobCandidateIndexCacheKey(
            jobCandidateNanoId
          ),
        enabled:
          isHiringPortalPath as FetchCandidateSubmissionJobCandidatesEnabled,
        initialFilters: {
          [CandidateSubmissionJobCandidateFields.JOB_CANDIDATE_ID]: {
            operator: 'eq',
            value: jobCandidate?.id
          },
          [CandidateSubmissionJobCandidateFields.RECIPIENT_IDS]: {
            operator: 'eq',
            value: [currentUser.id]
          }
        } as unknown as FetchCandidateSubmissionJobCandidatesFilters
      }
    );

  const {
    createCandidateSubmission,
    createCandidateSubmissionErrorMessage,
    createCandidateSubmissionIsLoading
  } = useCreateCandidateSubmission({
    cacheKeys: [
      CandidateSubmissionsCache.indexCacheKey(),
      JobCandidatesCache.jobIndexCacheKey(
        jobNanoId
      ) as unknown as FetchCandidateSubmissionsCacheKey,
      WorkflowStagesCache.jobIndexCacheKey(
        jobNanoId
      ) as unknown as FetchCandidateSubmissionsCacheKey
    ]
  });

  const {
    sendEmailsCandidateSubmission,
    sendEmailsCandidateSubmissionErrorMessage,
    sendEmailsCandidateSubmissionIsLoading
  } = useSendEmailsCandidateSubmission({});

  const defaultSubmissionFields = Object.values(
    CandidateSubmissionShareSettingsFields
  ) as CandidateSubmissionShareSettingsField[];

  const defaultEvaluations: CandidateSubmissionEvaluations = {
    [CandidateSubmissionEvaluationsEnum.JobFit]: {
      rating: 0,
      comment: ''
    },
    [CandidateSubmissionEvaluationsEnum.Communication]: {
      rating: 0,
      comment: ''
    },
    [CandidateSubmissionEvaluationsEnum.Impression]: {
      rating: 0,
      comment: ''
    },
    [CandidateSubmissionEvaluationsEnum.Motivation]: {
      rating: 0,
      comment: ''
    },
    [CandidateSubmissionEvaluationsEnum.Overall]: {
      rating: 0,
      comment: ''
    }
  };

  const totalSteps = 4;
  const [currentStep, setCurrentStep] = useState(1);
  const [sharedFields, setSharedFields] = useState<
    CandidateSubmissionShareSettingsField[]
  >(defaultSubmissionFields);

  const [selectedOverallEvaluation, setSelectedOverallEvaluation] =
    useState<CandidateSubmissionOverallEvaluation>();
  const [overallComment, setOverallComment] =
    useState<CandidateSubmissionComment>('' as CandidateSubmissionComment);
  const [evaluations, setEvaluations] = useState(defaultEvaluations);
  const [visibilityOptionType, setVisibilityOptionType] = useState(
    CandidateSubmissionVisibilityOptionTypeEnum.AllHiringTeam as CandidateSubmissionVisibilityOptionType
  );
  const [candidateShelfLife, setCandidateShelfLife] = useState<
    CandidateSubmissionCandidateShelfLife | undefined
  >();
  const [baseSalary, setBaseSalary] = useState<CandidateSubmissionBaseSalary>(
    '' as CandidateSubmissionBaseSalary
  );

  const {
    onOpen: openCandidateSubmission,
    onClose: closeCandidateSubmission,
    isOpen: isCandidateSubmissionOpen
  } = useDisclosure({
    onClose: () => {
      setCurrentStep(1);
      if (isSubmitProfileOpen) {
        onRemoveOpenSubmitFromQuery?.();
      }
    },
    defaultIsOpen: isSubmitProfileOpen
  });

  const goToNextStep = useCallback(() => {
    if (currentStep === totalSteps) return;

    setCurrentStep(currentStep + 1);
  }, [currentStep]);

  const goToPrevStep = useCallback(() => {
    if (currentStep === 1) return;

    setCurrentStep(currentStep - 1);
  }, [currentStep]);

  const updateEvaluations = useCallback<
    (evaluation: CandidateSubmissionEvaluation) => void
  >(
    (evaluation) => {
      const { evaluationType, ...rest } = evaluation;
      setEvaluations({ ...evaluations, [evaluationType]: rest });
    },
    [evaluations]
  );

  const { hiringManager, interviewers, recruiter, recruitingCoordinator } =
    job || {};

  const allHiringTeamRecipients =
    visibilityOptionType ===
    CandidateSubmissionVisibilityOptionTypeEnum.AllHiringTeam
      ? [
          hiringManager,
          ...(interviewers || []),
          recruiter,
          recruitingCoordinator
        ]
      : undefined;

  const recruiterRecipients =
    visibilityOptionType ===
    CandidateSubmissionVisibilityOptionTypeEnum.AllHiringTeam
      ? [hiringManager, recruiter, recruitingCoordinator]
      : undefined;

  const hiringManagerRecipient =
    visibilityOptionType ===
    CandidateSubmissionVisibilityOptionTypeEnum.HiringManagers
      ? [hiringManager]
      : undefined;

  const recipients = (
    allHiringTeamRecipients ||
    recruiterRecipients ||
    hiringManagerRecipient
  )?.filter((val) => Boolean(val)) as unknown as CandidateSubmissionRecipients;

  const uniqRecipients = uniqBy(
    recipients,
    'id'
  ) as CandidateSubmissionRecipients;

  const sendSubmission = useCallback(async () => {
    const recipientIds = uniqRecipients?.map(({ id }) => id);

    const payloadShareFields = reduce(
      sharedFields,
      (acc, field) => ({ ...acc, [field]: true }),
      {}
    );

    const payload = {
      recipientIds,
      shareSettings: payloadShareFields,
      jobId: job?.id,
      jobCandidateIds: [jobCandidate?.id],
      comment: overallComment,
      candidateSubmissionInvitations: [],
      companyMemberInvitations: [],
      overallEvaluation: selectedOverallEvaluation,
      evaluations,
      baseSalary,
      candidateShelfLife
    };

    const response = await createCandidateSubmission(
      payload as unknown as CreateCandidateSubmissionRequestData
    );

    if (response.id) {
      await sendEmailsCandidateSubmission({
        candidateSubmissionId: response.id
      });
      closeCandidateSubmission();
      toast({
        title: 'Candidate submission sent',
        status: 'success'
      });
    }
  }, [
    toast,
    sharedFields,
    overallComment,
    selectedOverallEvaluation,
    evaluations,
    uniqRecipients,
    jobCandidate,
    job,
    baseSalary,
    candidateShelfLife,
    createCandidateSubmission,
    sendEmailsCandidateSubmission,
    closeCandidateSubmission
  ]);

  const handleShareField = useCallback(
    (field: CandidateSubmissionShareSettingsField) => {
      if (sharedFields.includes(field)) {
        const updatedFields = sharedFields.filter((f) => f !== field);

        if (
          field ===
            CandidateSubmissionShareSettingsFields.ProfileCandidateName &&
          !updatedFields.includes(
            CandidateSubmissionShareSettingsFields.ProfileJobTitle as CandidateSubmissionShareSettingsField
          )
        ) {
          updatedFields.push(
            CandidateSubmissionShareSettingsFields.ProfileJobTitle as CandidateSubmissionShareSettingsField
          );
        }

        setSharedFields(updatedFields);
      } else {
        setSharedFields([...sharedFields, field]);
      }
    },
    [sharedFields]
  );

  const candidateSubmissionShareSettings = first(
    candidateSubmissionJobCandidates
  )?.candidateSubmission?.shareSettings;

  const shareSettingsArr = candidateSubmissionShareSettings
    ? (Object.keys(candidateSubmissionShareSettings).filter(
        (key) =>
          candidateSubmissionShareSettings[
            key as keyof typeof candidateSubmissionShareSettings
          ]
      ) as CandidateSubmissionShareSettingsField[])
    : [];

  const shareSettings = isHiringPortalPath ? shareSettingsArr : sharedFields;

  const values = {
    totalSteps,
    currentStep,
    goToNextStep,
    goToPrevStep,
    sendSubmission,
    shareSettings,
    sharedFields,
    handleShareField,
    openCandidateSubmission,
    closeCandidateSubmission,
    isCandidateSubmissionOpen,
    isFirstStep: currentStep === 1,
    isLastStep: currentStep === totalSteps,
    selectedOverallEvaluation,
    setSelectedOverallEvaluation,
    overallComment,
    setOverallComment,
    evaluations,
    updateEvaluations,
    visibilityOptionType,
    setVisibilityOptionType,
    recipients: uniqRecipients,
    candidateShelfLife,
    setCandidateShelfLife,
    baseSalary,
    setBaseSalary,
    errorMessage:
      createCandidateSubmissionErrorMessage ||
      sendEmailsCandidateSubmissionErrorMessage
  };

  return (
    <ViewJobCandidateDetailsSubmissionContext.Provider value={values}>
      {children}

      {values.isCandidateSubmissionOpen && (
        <CustomiseCandidateSubmissionNav
          isLoading={
            sendEmailsCandidateSubmissionIsLoading ||
            createCandidateSubmissionIsLoading
          }
        />
      )}
    </ViewJobCandidateDetailsSubmissionContext.Provider>
  );
}

export const useViewJobCandidateDetailsSubmissionContext = () =>
  useContext(ViewJobCandidateDetailsSubmissionContext);
