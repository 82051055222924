import { TalentPoolId } from '../talentPools/talentPoolsTypes';
import {
  FetchTalentPoolJobCandidatesCacheKey,
  FetchTalentPoolJobCandidateCacheKey
} from './talentPoolJobCandidatesTypes';

export class TalentPoolJobCandidatesCache {
  static indexCacheKey() {
    return 'talentPoolJobCandidates' as FetchTalentPoolJobCandidatesCacheKey;
  }

  static talentPoolListIndexCacheKey(talentPoolId: TalentPoolId) {
    return `talentPoolJobCandidates-talentPool-${talentPoolId}` as FetchTalentPoolJobCandidatesCacheKey;
  }

  static showCacheKey() {
    return 'talentPoolJobCandidate' as FetchTalentPoolJobCandidateCacheKey;
  }
}
