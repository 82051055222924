import { Flex } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { NextLinkHelper } from '../../../../../../helpers/NextLinkHelper';
import { ButtonWithRef } from '../../../../../../helpers/ButtonWithRef';

interface ViewJobCandidateDetailsPageNavItem {
  url: string;
  label: string;
  isActive: boolean;
  icon: FunctionComponent;
}

export default function ViewJobCandidateDetailsPageNavItem({
  url,
  icon,
  label,
  isActive
}: ViewJobCandidateDetailsPageNavItem) {
  return (
    <NextLinkHelper href={url} passHref>
      <ButtonWithRef
        as="a"
        px={2}
        py={2}
        gap={1.5}
        size="medium"
        fontSize="md"
        display="flex"
        lineHeight={1.25}
        hierarchy="link"
        cursor="pointer"
        fontWeight="normal"
        textDecoration="none"
        bg={isActive ? 'primary.100' : 'transparant'}
        _hover={{
          textDecoration: 'none'
        }}
      >
        <Flex
          w={5}
          h={5}
          as={icon}
          fill={isActive ? 'primary.500' : 'gray.500'}
        />

        {label}
      </ButtonWithRef>
    </NextLinkHelper>
  );
}
