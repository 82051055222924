import { JobSeniorityLevel } from '../../jobsTypes';
import { TalentPoolSeniorityLevel } from '../../../talentPools/talentPoolsTypes';

import { seniorityLevelOptionsHash } from '../../jobs.data';

function renderJobSeniorityLevel(
  seniorityLevel: JobSeniorityLevel | TalentPoolSeniorityLevel
) {
  return seniorityLevelOptionsHash[seniorityLevel];
}

export default renderJobSeniorityLevel;
