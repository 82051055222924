import { usePaginatedItems } from '../../../common/hooks/usePaginatedItems';

import {
  FetchCompanyMembersQuery,
  FetchCompanyMembersCacheKey,
  FetchCompanyMembersTotalCount,
  FetchCompanyMembersPage,
  FetchCompanyMembersPageSize,
  FetchCompanyMembersError,
  FetchCompanyMembersErrorMessage,
  FetchCompanyMembersIsLoading,
  FetchCompanyMembersIsFetched,
  FetchCompanyMembersIsPlaceholderData,
  FetchCompanyMembersEnabled,
  FetchCompanyMembersPrefetchNextPage,
  FetchCompanyMembersRefetchInterval,
  FetchCompanyMembersCountType,
  FetchCompanyMembersFilters,
  FetchCompanyMembersSort,
  FetchCompanyMemberID,
  FetchCompanyMemberQuery,
  FetchCompanyMemberCacheKey
} from '../../companyMembersTypes';

import { CompanyMembersBffRequests } from '../../CompanyMembersBffRequests';

interface PaginatedCompanyMembersBaseOptions<
  PaginatedCompanyMembersResponseType
> {
  cacheKey: FetchCompanyMembersCacheKey;
  query: FetchCompanyMembersQuery;
  countType?: FetchCompanyMembersCountType;
  initialPage?: FetchCompanyMembersPage;
  initialPageSize?: FetchCompanyMembersPageSize;
  initialFilters?: FetchCompanyMembersFilters;
  initialSort?: FetchCompanyMembersSort;
  enabled?: FetchCompanyMembersEnabled;
  prefetchNextPage?: FetchCompanyMembersPrefetchNextPage;
  refetchInterval?: FetchCompanyMembersRefetchInterval;
  placeholderCompanyMembers?: PaginatedCompanyMembersResponseType[];
  placeholderCompanyMembersCount?: FetchCompanyMembersTotalCount;
  placeholderData?: () => PaginatedCompanyMembersResponse<PaginatedCompanyMembersResponseType>;
}

interface PaginatedCompanyMembersOptionsWithoutItem {
  fetchItemCacheKey?: never;
  itemQuery?: never;
}

interface PaginatedCompanyMembersOptionsWithItem {
  fetchItemCacheKey: FetchCompanyMemberCacheKey;
  itemQuery: FetchCompanyMemberQuery;
}

type PaginatedCompanyMembersOptions<PaginatedCompanyMembersResponseType> =
  PaginatedCompanyMembersBaseOptions<PaginatedCompanyMembersResponseType> &
    (
      | PaginatedCompanyMembersOptionsWithoutItem
      | PaginatedCompanyMembersOptionsWithItem
    );

interface PaginatedCompanyMembersResponse<PaginatedCompanyMembersResponseType> {
  data: PaginatedCompanyMembersResponseType[];
  totalCount: FetchCompanyMembersTotalCount;
}

function usePaginatedCompanyMembers<PaginatedCompanyMembersResponseType>({
  cacheKey,
  query,
  countType,
  initialPage,
  initialPageSize,
  initialFilters,
  initialSort,
  enabled,
  prefetchNextPage,
  refetchInterval,
  placeholderCompanyMembers,
  placeholderCompanyMembersCount,
  placeholderData,
  fetchItemCacheKey,
  itemQuery
}: PaginatedCompanyMembersOptions<PaginatedCompanyMembersResponseType>) {
  const {
    items,
    itemsTotalCount,
    itemsError,
    itemsErrorMessage,
    itemsIsFetched,
    itemsIsLoading,
    itemsIsPlaceholderData,
    itemsCurrentPage,
    itemsCurrentPageSize,
    itemsCurrentFilters,
    itemsCurrentSort,
    fetchItems,
    paginateItems,
    changeItemsPageSize,
    filterItems,
    changeItemsFilters,
    clearItemsFilters,
    sortItems,
    prefetchItems,
    prefetchItem
  } = usePaginatedItems<PaginatedCompanyMembersResponseType>({
    cacheKey,
    initialPage,
    initialPageSize,
    initialFilters,
    initialSort,
    enabled,
    prefetchNextPage,
    refetchInterval,
    placeholderItems: placeholderCompanyMembers,
    placeholderItemsCount: placeholderCompanyMembersCount,
    placeholderData,
    queryFn: (params) =>
      CompanyMembersBffRequests.fetchCompanyMembers<PaginatedCompanyMembersResponseType>(
        {
          query,
          countType,
          page: params.page as FetchCompanyMembersPage,
          pageSize: params.pageSize as FetchCompanyMembersPageSize,
          filters: params.filters as FetchCompanyMembersFilters,
          sort: params.sort as FetchCompanyMembersSort
        }
      ),
    ...(itemQuery
      ? {
          fetchItemCacheKey,
          queryItemFn: (itemId) =>
            CompanyMembersBffRequests.fetchCompanyMember(
              itemId as FetchCompanyMemberID,
              {
                query: itemQuery
              }
            )
        }
      : {})
  });

  return {
    companyMembers: items as PaginatedCompanyMembersResponseType[],
    companyMembersTotalCount: itemsTotalCount as FetchCompanyMembersTotalCount,
    companyMembersError: itemsError as FetchCompanyMembersError,
    companyMembersErrorMessage:
      itemsErrorMessage as FetchCompanyMembersErrorMessage,
    companyMembersIsLoading: itemsIsLoading as FetchCompanyMembersIsLoading,
    companyMembersIsFetched: itemsIsFetched as FetchCompanyMembersIsFetched,
    companyMembersIsPlaceholderData:
      itemsIsPlaceholderData as FetchCompanyMembersIsPlaceholderData,
    companyMembersCurrentPage: itemsCurrentPage as FetchCompanyMembersPage,
    companyMembersCurrentPageSize:
      itemsCurrentPageSize as FetchCompanyMembersPageSize,
    companyMembersCurrentFilters:
      itemsCurrentFilters as FetchCompanyMembersFilters,
    companyMembersCurrentSort: itemsCurrentSort as FetchCompanyMembersSort,
    fetchCompanyMembers: fetchItems,
    paginateCompanyMembers: paginateItems,
    changeCompanyMembersPageSize: changeItemsPageSize,
    filterCompanyMembers: filterItems,
    changeCompanyMembersFilters: changeItemsFilters,
    clearCompanyMembersFilters: clearItemsFilters,
    sortCompanyMembers: sortItems,
    prefetchCompanyMembers: prefetchItems,
    prefetchCompanyMember: prefetchItem
  };
}

export default usePaginatedCompanyMembers;
