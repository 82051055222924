import { Stack } from '@chakra-ui/react';
import { Fragment, useState } from 'react';
import { ViewJobCandidateModalPhoneSidebarCallCategoryType } from '../../ViewJobCandidateModalPhoneSidebarHelper.types';
import {
  ViewJobCandidateModalPhoneSidebarConnectedHelper,
  ViewJobCandidateModalPhoneSidebarSnoozeHelper,
  ViewJobCandidateModalPhoneSidebarVoiceMailHelper,
  ViewJobCandidateModalPhoneSidebarWrongNumberHelper
} from './helpers';
import { useViewJobCandidateModalPhoneSidebarContext } from '../context';

interface ViewJobCandidateModalPhoneSidebarCallActionsHelperProps {
  callCategory: ViewJobCandidateModalPhoneSidebarCallCategoryType;
  withoutMargin: boolean;
}

export type ViewJobCandidateModalPhoneSidebarCallSectionType =
  | 'connected'
  | 'snooze'
  | 'voicemail'
  | 'wrong-number'
  | undefined
  | null;

export type ViewJobCandidateModalPhoneSidebarCallActionType = 'open' | 'close';

export type ViewJobCandidateModalPhoneSidebarToggleCallActionsType = (
  action?: ViewJobCandidateModalPhoneSidebarCallSectionType
) => void;

type ViewJobCandidateModalPhoneSidebarToggleSectionParamType = {
  section: ViewJobCandidateModalPhoneSidebarCallSectionType;
  actionType: ViewJobCandidateModalPhoneSidebarCallActionType;
};

export type ViewJobCandidateModalPhoneSidebarToggleSectionType = ({
  actionType,
  section
}: ViewJobCandidateModalPhoneSidebarToggleSectionParamType) => void;

const ViewJobCandidateModalPhoneSidebarCallActionsHelper = ({
  callCategory,
  withoutMargin
}: ViewJobCandidateModalPhoneSidebarCallActionsHelperProps) => {
  const [openedSection, setOpenedSection] =
    useState<ViewJobCandidateModalPhoneSidebarCallSectionType | null>();

  const { showJobDetails } = useViewJobCandidateModalPhoneSidebarContext();

  const toggleSection: ViewJobCandidateModalPhoneSidebarToggleSectionType = ({
    actionType,
    section
  }) => {
    if (actionType === 'open' && !openedSection) {
      setOpenedSection(section);
    }

    if (actionType === 'close' && openedSection === section) {
      setOpenedSection(null);
    }
  };

  return (
    <Stack spacing={4}>
      <ViewJobCandidateModalPhoneSidebarConnectedHelper
        openedSection={openedSection}
        callCategory={callCategory}
        toggleSection={toggleSection}
        withoutMargin={withoutMargin}
      />

      {!showJobDetails && (
        <Fragment>
          <ViewJobCandidateModalPhoneSidebarSnoozeHelper
            openedSection={openedSection}
            toggleSection={toggleSection}
          />
          <ViewJobCandidateModalPhoneSidebarVoiceMailHelper
            openedSection={openedSection}
            toggleSection={toggleSection}
          />
          <ViewJobCandidateModalPhoneSidebarWrongNumberHelper
            openedSection={openedSection}
            toggleSection={toggleSection}
          />
        </Fragment>
      )}
    </Stack>
  );
};

export default ViewJobCandidateModalPhoneSidebarCallActionsHelper;
