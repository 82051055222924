import React, { useEffect } from 'react';
import { FieldValues } from 'react-hook-form';
import { SystemStyleObject } from '@chakra-ui/react';
import compact from 'lodash/compact';
import debounce from 'lodash/debounce';
import find from 'lodash/find';

import { DEFAULT_COUNTRY_ID } from '../../../../../config';

import { ErrorMessage } from '../../../../../types';

import {
  StateFields,
  FetchStatesFilters,
  FetchStatesSort,
  StateId,
  StateName,
  StateLabel,
  StateIsoCode
} from '../../../../states/statesTypes';

import { MayBeCountryId } from '../../../../countries/countriesTypes';

import {
  fetchStatesQuery,
  FetchStatesResponse
} from '../../../../states/queries/fetchStates.query';

import { usePaginatedStates } from '../../../../../main/states/hooks/usePaginatedStates';

import { StatesCache } from '../../../../states/StatesCache';

import {
  SelectField,
  SelectFieldReactHookFormControl,
  SelectFieldReactHookFormFieldPath
} from '../../../../../helpers/forms/formFields/SelectField';

interface SelectStateFormFieldItem {
  countryId?: MayBeCountryId;
  state: {
    id: StateId;
    label: StateLabel;
  } | null;
}

interface SelectStateFormWithItemFieldProps<T extends FieldValues> {
  isRequired?: boolean;
  control: SelectFieldReactHookFormControl<T>;
  item: SelectStateFormFieldItem;
  stateIsoCode?: never;
  stateName?: never;
  currentCountryId?: MayBeCountryId;
  errorMessage: ErrorMessage;
  label?: string;
  placeholder?: string;
  showLabel?: boolean;
}

interface SelectStateFormWithStateNameFieldProps<T extends FieldValues> {
  isRequired?: boolean;
  control: SelectFieldReactHookFormControl<T>;
  item?: never;
  stateIsoCode: StateIsoCode;
  stateName: StateName;
  currentCountryId?: MayBeCountryId;
  errorMessage: ErrorMessage;
  label?: string;
  placeholder?: string;
  showLabel?: boolean;
}

interface SelectStateFormBlankFieldProps<T extends FieldValues> {
  isRequired?: boolean;
  control: SelectFieldReactHookFormControl<T>;
  item?: never;
  stateIsoCode?: never;
  stateName?: never;
  currentCountryId?: MayBeCountryId;
  errorMessage: ErrorMessage;
  label?: string;
  placeholder?: string;
  showLabel?: boolean;
}

type SelectStateFormFieldProps<T extends FieldValues> = (
  | SelectStateFormWithItemFieldProps<T>
  | SelectStateFormWithStateNameFieldProps<T>
  | SelectStateFormBlankFieldProps<T>
) & {
  reactSelectStyles?: Record<string, unknown>;
  isBorderless?: boolean;
};

function SelectStateFormField<T extends FieldValues>({
  isRequired = false,
  showLabel = true,
  label,
  placeholder,
  control,
  currentCountryId,
  item,
  stateIsoCode,
  stateName,
  errorMessage,
  reactSelectStyles,
  isBorderless
}: SelectStateFormFieldProps<T>) {
  const {
    states,
    statesIsFetched,
    statesIsLoading,
    statesErrorMessage,
    changeStatesFilters
  } = usePaginatedStates<FetchStatesResponse>({
    query: fetchStatesQuery,
    cacheKey: StatesCache.indexCacheKey(),
    initialFilters: {
      [StateFields.COUNTRY_ID]: {
        operator: 'eq',
        value: item?.countryId || DEFAULT_COUNTRY_ID
      },
      ...(stateName || item?.state?.label
        ? {
            [StateFields.LABEL]: {
              operator: 'ilike',
              value: `${stateName || item?.state?.label}`
            }
          }
        : {})
    } as unknown as FetchStatesFilters,
    initialSort: {
      [StateFields.NAME]: { ascending: true }
    } as unknown as FetchStatesSort
  });

  const defaultCurrentStateFromStateLabel = stateName
    ? find(
        states,
        (state) =>
          state.label === compact([stateName, stateIsoCode]).join(', ') ||
          state.name === stateName
      )
    : null;

  const defaultCurrentStateFromStateLabelValue =
    defaultCurrentStateFromStateLabel
      ? {
          value: defaultCurrentStateFromStateLabel.id,
          label: defaultCurrentStateFromStateLabel.label
        }
      : undefined;

  const defaultCurrentUserState = item?.state
    ? {
        value: item.state.id,
        label: item.state.label
      }
    : undefined;

  const debouncedFilterStates = debounce<(updatedValue: string) => void>(
    (updatedValue) =>
      changeStatesFilters({
        [StateFields.LABEL]: {
          operator: 'ilike',
          value: updatedValue
        }
      }),
    500
  );

  useEffect(() => {
    if (currentCountryId) {
      changeStatesFilters({
        [StateFields.COUNTRY_ID]: {
          operator: 'eq',
          value: currentCountryId
        }
      });
    }
  }, [currentCountryId, changeStatesFilters]);

  return (
    <SelectField
      isRequired={isRequired}
      control={control}
      label={showLabel ? label || 'Location' : undefined}
      placeholder={placeholder || 'Location'}
      isDisabled={!statesIsFetched || !currentCountryId}
      name={'stateId' as SelectFieldReactHookFormFieldPath<T>}
      options={states.map((state) => ({
        value: state.id,
        label: state.label
      }))}
      defaultValue={
        defaultCurrentStateFromStateLabelValue || defaultCurrentUserState
      }
      errorMessage={errorMessage || statesErrorMessage}
      isLoading={statesIsLoading}
      onInputChange={debouncedFilterStates}
      reactSelectStyles={
        isBorderless
          ? {
              control: (base: SystemStyleObject) => ({
                ...base,
                borderColor: 'transparent',
                p: 0
              })
            }
          : reactSelectStyles
      }
    />
  );
}

export default SelectStateFormField;
