import {
  UserId,
  UserNanoId,
  UserItemBffID,
  UserItemBffNanoID,
  FetchUserBffURL,
  FetchUserByNanoIdBffURL,
  FetchUsersBffURL,
  CreateUserBffURL,
  UpdateUserBffURL,
  DeleteUserBffURL,
  InviteUserBffURL,
  UpdateSecuritiesBffURL,
  UpdateCompanyBffURL,
  ApproveUserWorkEmailBffURL,
  CreateUserProfileBffURL,
  SendEmailUsersBffURL,
  UserItemBffUuid,
  ToggleBlockedUserBffURL
} from './usersTypes';

import { BaseBffRoutes } from '../BaseBffRoutes';

export class UsersBffRoutes extends BaseBffRoutes {
  protected static route = 'users';

  static fetchUsersRoute() {
    return this.fetchItemsRoute<FetchUsersBffURL>();
  }

  static fetchUsersIndexRoute() {
    return this.fetchItemsIndexRoute<FetchUsersBffURL>();
  }

  static fetchUserRoute(userItemBffID: UserItemBffID) {
    return this.fetchItemRoute<FetchUserBffURL>(userItemBffID);
  }

  static fetchUserIndexRoute(
    userItemBffNanoID: UserItemBffNanoID | UserItemBffUuid
  ) {
    return this.fetchItemIndexRoute<FetchUserBffURL>(userItemBffNanoID);
  }

  static fetchCurrentUserRoute() {
    return this.fetchItemRoute<FetchUserBffURL>('current' as UserItemBffID);
  }

  static fetchUserByNanoIdRoute(userItemBffNanoID: UserItemBffNanoID) {
    return this.fetchItemByNanoIdRoute<FetchUserByNanoIdBffURL>(
      userItemBffNanoID
    );
  }

  static createUserRoute() {
    return this.createItemRoute<CreateUserBffURL>();
  }

  static updateUserRoute(userItemBffNanoID: UserItemBffID | UserItemBffNanoID) {
    return this.updateItemRoute<UpdateUserBffURL>(userItemBffNanoID);
  }

  static deleteUserRoute(userItemBffNanoID: UserItemBffNanoID) {
    return this.deleteItemRoute<DeleteUserBffURL>(userItemBffNanoID);
  }

  static inviteUserRoute() {
    return `${this.basePath}/${this.route}/invite` as InviteUserBffURL;
  }

  static updateSecuritiesRoute(userId: UserId) {
    return `${this.basePath}/${this.route}/${userId}/update-securities` as UpdateSecuritiesBffURL;
  }

  static updateCompanyRoute(userId: UserId) {
    return `${this.basePath}/${this.route}/${userId}/update-company` as UpdateCompanyBffURL;
  }

  static approveUserWorkEmailRoute(userNanoId: UserNanoId) {
    return `${this.basePath}/${this.route}/${userNanoId}/work-email` as ApproveUserWorkEmailBffURL;
  }

  static createUserProfileRoute(userNanoId: UserNanoId) {
    return `${this.basePath}/${this.route}/${userNanoId}/create-profile` as CreateUserProfileBffURL;
  }

  static sendEmailUsersRoute() {
    return `${this.basePath}/${this.route}/send-email` as SendEmailUsersBffURL;
  }

  static toggleBlockedUserRoute(userId: UserId) {
    return `${this.basePath}/${this.route}/${userId}/toggle-blocked` as ToggleBlockedUserBffURL;
  }
}
