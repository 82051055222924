import {
  MayBe,
  FieldBrand,
  ItemID,
  ItemUUID,
  ItemNanoId,
  ItemCreatedAt
} from '../../../types';

import {
  IndustryId,
  IndustryName,
  IndustryNanoId
} from '../../industries/industriesTypes';
import { JobCandidateId } from '../../jobCandidates/jobCandidatesTypes';
import { JobResumeRecruiterId } from '../../jobResumeRecruiters/jobResumeRecruitersTypes';
import { JobId, JobJobTitle, JobNanoId } from '../../jobs/jobsTypes';
import { TalentPoolId } from '../../talentPools/talentPoolsTypes';

import {
  UserId,
  UserNanoId,
  UserName,
  UserImage
} from '../../users/usersTypes';

export type IndustryExpertiseId = FieldBrand<ItemID, 'IndustryExpertiseId'>;
export type IndustryExpertiseUuid = FieldBrand<
  ItemUUID,
  'IndustryExpertiseUuid'
>;
export type MayBeIndustryExpertiseUuid = MayBe<IndustryExpertiseUuid>;
export type IndustryExpertiseNanoId = FieldBrand<
  ItemNanoId,
  'IndustryExpertiseNanoId'
>;
export type MayBeIndustryExpertiseNanoId = MayBe<IndustryExpertiseNanoId>;
export type IndustryExpertiseUserId = FieldBrand<
  UserId,
  'IndustryExpertiseUserId'
>;
export type IndustryExpertiseUser = FieldBrand<
  {
    id: UserId;
    nanoId: UserNanoId;
    name: UserName;
    image: UserImage;
  },
  'IndustryExpertiseUser'
>;
export type IndustryExpertiseJobResumeRecruiterId = FieldBrand<
  JobResumeRecruiterId,
  'IndustryExpertiseJobResumeRecruiterId'
>;
export type IndustryExpertiseJobCandidateId = FieldBrand<
  JobCandidateId,
  'IndustryExpertiseJobCandidateId'
>;
export type IndustryExpertiseTalentPoolId = FieldBrand<
  TalentPoolId,
  'IndustryExpertiseTalentPoolId'
>;
export type IndustryExpertiseJobId = FieldBrand<
  JobId,
  'IndustryExpertiseJobId'
>;
export type MayBeIndustryExpertiseJobId = MayBe<IndustryExpertiseJobId>;
export type IndustryExpertiseJob = FieldBrand<
  {
    id: JobId;
    nanoId: JobNanoId;
    jobTitle: JobJobTitle;
  },
  'IndustryExpertiseJob'
>;
export type IndustryExpertiseIndustryId = FieldBrand<
  IndustryId,
  'IndustryExpertiseIndustryId'
>;
export type IndustryExpertiseIndustry = FieldBrand<
  {
    id: IndustryId;
    nanoId: IndustryNanoId;
    name: IndustryName;
  },
  'IndustryExpertiseIndustry'
>;
export type IndustryExpertiseCreatedAt = FieldBrand<
  ItemCreatedAt,
  'IndustryExpertiseCreatedAt'
>;
export type IndustryExpertiseExperience = FieldBrand<
  string,
  'IndustryExpertiseExperience'
>;

export const enum IndustryExpertiseRequirementsTypes {
  NONE = 'none',
  VERY_IMPORTANT = 'required',
  IMPORTANT = 'important',
  NICE_TO_HAVE = 'nice_to_have'
}
export type IndustryExpertiseRequirements = FieldBrand<
  IndustryExpertiseRequirementsTypes,
  'IndustryExpertiseRequirements'
>;
export const industryExpertiseRequirements: IndustryExpertiseRequirements[] = [
  IndustryExpertiseRequirementsTypes.VERY_IMPORTANT as IndustryExpertiseRequirements,
  IndustryExpertiseRequirementsTypes.IMPORTANT as IndustryExpertiseRequirements,
  IndustryExpertiseRequirementsTypes.NICE_TO_HAVE as IndustryExpertiseRequirements
];

export const enum IndustryExpertiseFields {
  ID = 'id',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  USER_ID = 'userId',
  USER = 'user',
  CREATED_AT = 'createdAt',
  INDUSTRY_ID = 'industryId',
  INDUSTRY = 'industry',
  JOB_RESUME_RECRUITER_ID = 'jobResumeRecruiterId',
  JOB_CANDIDATE_ID = 'jobCandidateId',
  TALENT_POOL_ID = 'talentPoolId',
  JOB_ID = 'jobId',
  JOB = 'job',
  REQUIREMENTS = 'requirements',
  EXPERIENCE = 'experience'
}
