import { MayBeCompanyId } from '../../../companies/companiesTypes';
import { Roles } from '../../../roles/rolesTypes';
import { MayBeUserCompanyRoleName, UserId } from '../../../users/usersTypes';

import { FetchJobsFilters, JobFields, JobStatuses } from '../../jobsTypes';

export interface JobsFiltersProps {
  companyId?: MayBeCompanyId;
  userId?: UserId;
  userCompanyRoleName?: MayBeUserCompanyRoleName;
  extraFilters?: FetchJobsFilters;
}

export function activeJobsInCompanyFilters({
  companyId,
  userCompanyRoleName,
  userId,
  extraFilters
}: JobsFiltersProps) {
  const isRecruiterCoordinator =
    userCompanyRoleName === Roles.RECRUITER_COORDINATOR;

  const isRecruiter =
    userCompanyRoleName === Roles.RECRUITER ||
    userCompanyRoleName === Roles.RECRUITER_ADMIN;

  const isHiringManager =
    userCompanyRoleName === Roles.HIRING_MANAGER ||
    userCompanyRoleName === Roles.COLLABORATOR;

  return {
    ...(isHiringManager && userId
      ? {
          [JobFields.HIRING_MANAGER_ID]: {
            operator: 'eq',
            value: userId
          }
        }
      : {
          [JobFields.HIRING_MANAGER_ID]: {
            operator: 'not.is',
            value: null
          }
        }),
    [JobFields.ARCHIVED_AT]: {
      operator: 'is',
      value: null
    },
    [JobFields.COMPANY_ID]: {
      operator: 'eq',
      value: companyId || '0'
    },
    ...(isRecruiter && userId
      ? {
          [JobFields.RECRUITER_ID]: {
            operator: 'eq',
            value: userId
          }
        }
      : {}),
    ...(isRecruiterCoordinator && userId
      ? {
          [JobFields.RECRUITING_COORDINATOR_ID]: {
            operator: 'eq',
            value: userId
          }
        }
      : {}),
    ...(extraFilters || {})
  } as unknown as FetchJobsFilters;
}

export function publishedJobsInCompanyFilters({
  companyId,
  userCompanyRoleName,
  userId,
  extraFilters
}: JobsFiltersProps) {
  const isRecruiterCoordinator =
    userCompanyRoleName === Roles.RECRUITER_COORDINATOR;

  const isRecruiter =
    userCompanyRoleName === Roles.RECRUITER ||
    userCompanyRoleName === Roles.RECRUITER_ADMIN;

  const isHiringManager =
    userCompanyRoleName === Roles.HIRING_MANAGER ||
    userCompanyRoleName === Roles.COLLABORATOR;

  return {
    ...(isHiringManager && userId
      ? {
          [JobFields.HIRING_MANAGER_ID]: {
            operator: 'eq',
            value: userId
          }
        }
      : {
          [JobFields.HIRING_MANAGER_ID]: {
            operator: 'not.is',
            value: null
          }
        }),
    [JobFields.CITY_ID]: {
      operator: 'not.is',
      value: null
    },
    [JobFields.ARCHIVED_AT]: {
      operator: 'is',
      value: null
    },
    [JobFields.COMPANY_ID]: {
      operator: 'eq',
      value: companyId || '0'
    },
    [JobFields.STATUS]: {
      operator: 'eq',
      value: JobStatuses.PUBLISHED
    },
    ...(isRecruiter && userId
      ? {
          [JobFields.RECRUITER_ID]: {
            operator: 'eq',
            value: userId
          }
        }
      : {}),
    ...(isRecruiterCoordinator && userId
      ? {
          [JobFields.RECRUITING_COORDINATOR_ID]: {
            operator: 'eq',
            value: userId
          }
        }
      : {}),
    ...(extraFilters || {})
  } as unknown as FetchJobsFilters;
}

export function closedJobsInCompanyFilters({
  companyId,
  userCompanyRoleName,
  userId,
  extraFilters
}: JobsFiltersProps) {
  const isRecruiterCoordinator =
    userCompanyRoleName === Roles.RECRUITER_COORDINATOR;

  const isRecruiter =
    userCompanyRoleName === Roles.RECRUITER ||
    userCompanyRoleName === Roles.RECRUITER_ADMIN;

  const isHiringManager =
    userCompanyRoleName === Roles.HIRING_MANAGER ||
    userCompanyRoleName === Roles.COLLABORATOR;

  return {
    ...(isHiringManager && userId
      ? {
          [JobFields.HIRING_MANAGER_ID]: {
            operator: 'eq',
            value: userId
          }
        }
      : {
          [JobFields.HIRING_MANAGER_ID]: {
            operator: 'not.is',
            value: null
          }
        }),
    [JobFields.CITY_ID]: {
      operator: 'not.is',
      value: null
    },
    [JobFields.ARCHIVED_AT]: {
      operator: 'is',
      value: null
    },
    [JobFields.COMPANY_ID]: {
      operator: 'eq',
      value: companyId || '0'
    },
    [JobFields.STATUS]: {
      operator: 'eq',
      value: JobStatuses.CLOSED
    },
    ...(isRecruiter && userId
      ? {
          [JobFields.RECRUITER_ID]: {
            operator: 'eq',
            value: userId
          }
        }
      : {}),
    ...(isRecruiterCoordinator && userId
      ? {
          [JobFields.RECRUITING_COORDINATOR_ID]: {
            operator: 'eq',
            value: userId
          }
        }
      : {}),
    ...(extraFilters || {})
  } as unknown as FetchJobsFilters;
}

export function draftJobsInCompanyFilters({
  companyId,
  userCompanyRoleName,
  userId,
  extraFilters
}: JobsFiltersProps) {
  const isRecruiterCoordinator =
    userCompanyRoleName === Roles.RECRUITER_COORDINATOR;

  const isRecruiter =
    userCompanyRoleName === Roles.RECRUITER ||
    userCompanyRoleName === Roles.RECRUITER_ADMIN;

  const isHiringManager =
    userCompanyRoleName === Roles.HIRING_MANAGER ||
    userCompanyRoleName === Roles.COLLABORATOR;

  return {
    ...(isHiringManager && userId
      ? {
          [JobFields.HIRING_MANAGER_ID]: {
            operator: 'eq',
            value: userId
          }
        }
      : {}),
    [JobFields.ARCHIVED_AT]: {
      operator: 'is',
      value: null
    },
    [JobFields.COMPANY_ID]: {
      operator: 'eq',
      value: companyId || '0'
    },
    [JobFields.STATUS]: {
      operator: 'eq',
      value: JobStatuses.DRAFT
    },
    ...(isRecruiter
      ? {
          [JobFields.RECRUITER_ID]: {
            operator: 'eq',
            value: userId
          }
        }
      : {}),
    ...(isRecruiterCoordinator
      ? {
          [JobFields.RECRUITING_COORDINATOR_ID]: {
            operator: 'eq',
            value: userId
          }
        }
      : {}),
    ...(extraFilters || {})
  } as unknown as FetchJobsFilters;
}

export const activeJobsFilters = {
  [JobFields.HIRING_MANAGER_ID]: {
    operator: 'not.is',
    value: null
  },
  // [JobFields.CITY_ID]: {
  //   operator: 'not.is',
  //   value: null
  // },
  [JobFields.ARCHIVED_AT]: {
    operator: 'is',
    value: null
  }
} as unknown as FetchJobsFilters;

export function inActiveJobsInCompanyFilters(
  companyId: MayBeCompanyId | undefined
) {
  return {
    [JobFields.ARCHIVED_AT]: {
      operator: 'not.is',
      value: null
    },
    [JobFields.COMPANY_ID]: {
      operator: 'eq',
      value: companyId || '0'
    }
  } as unknown as FetchJobsFilters;
}

export const inActiveJobsFilters = {
  [JobFields.ARCHIVED_AT]: {
    operator: 'not.is',
    value: null
  }
} as unknown as FetchJobsFilters;

export function archivedJobsInCompanyFilters(
  companyId: MayBeCompanyId | undefined
) {
  return {
    [JobFields.ARCHIVED_AT]: {
      operator: 'not.is',
      value: null
    },
    [JobFields.COMPANY_ID]: {
      operator: 'eq',
      value: companyId || '0'
    }
  } as unknown as FetchJobsFilters;
}
