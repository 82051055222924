import {
  Flex,
  Grid,
  Stack,
  StackDivider,
  useDisclosure
} from '@chakra-ui/react';
import { MouseEvent, useState } from 'react';
import { Button } from '../../../../../../helpers/Button';
import { Loader } from '../../../../../../helpers/Loader';
import { PlusIcon } from '../../../../../../icons/PlusIcon';
import { ModalIsOpen } from '../../../../../../types';
import { JobCandidatesCache } from '../../../../../jobCandidates/JobCandidatesCache';
import { usePaginatedJobCandidates } from '../../../../../jobCandidates/hooks/usePaginatedJobCandidates';
import {
  FetchJobCandidatesEnabled,
  FetchJobCandidatesFilters,
  FetchJobCandidatesPageSize,
  JobCandidateFields,
  JobCandidateNanoId
} from '../../../../../jobCandidates/jobCandidatesTypes';
import {
  FetchJobCandidatesResponse,
  fetchJobCandidatesQuery
} from '../../../../../jobCandidates/queries/fetchJobCandidates.query';
import { WorkflowStageNanoId } from '../../../../../workflowStages/workflowStagesTypes';
import { AddNewLeadModal } from '../../../../components/modals/AddNewLeadModal';
import { JobId, JobNanoId } from '../../../../jobsTypes';
import { JobCandidateDetailsPageWorkflowStage } from '../../ViewJobCandidateDetailsPage.types';
import { ViewJobCandidateDetailsStagesDropdown } from '../ViewJobCandidateDetailsStageTasksManager/components/ViewJobCandidateDetailsStagesDropdown';
import { PipelineStageCandidate } from './components/PipelineStageCandidate';

interface ViewJobCandidateDetailsPipelineStagesProps {
  workflowStages: JobCandidateDetailsPageWorkflowStage[];
  jobCandidate: {
    nanoId: JobCandidateNanoId;
    workflowStage: JobCandidateDetailsPageWorkflowStage;
  };
  job: {
    id: JobId;
    nanoId: JobNanoId;
  };
  isHiringPortalPage: boolean;
}

function ViewJobCandidateDetailsPipelineStages({
  job,
  jobCandidate,
  workflowStages,
  isHiringPortalPage
}: ViewJobCandidateDetailsPipelineStagesProps) {
  const [selectedStage, setSelectedStage] = useState<WorkflowStageNanoId>(
    jobCandidate.workflowStage.nanoId
  );

  const {
    isOpen: isOpenAddCandidate,
    onClose: onCloseAddCandidate,
    onOpen: onOpenAddCandidate
  } = useDisclosure();

  const selectedStageColor = workflowStages.find(
    (stage) => stage.nanoId === selectedStage
  )?.color;

  const getSelectedStageId = (nanoId: WorkflowStageNanoId) => {
    return workflowStages.find((stage) => stage.nanoId === nanoId)?.id;
  };

  const {
    jobCandidates,
    filterJobCandidates,
    jobCandidatesIsFetched,
    jobCandidatesTotalCount
  } = usePaginatedJobCandidates<FetchJobCandidatesResponse>({
    query: fetchJobCandidatesQuery,
    enabled: !!selectedStage as FetchJobCandidatesEnabled,
    initialPageSize: 6 as FetchJobCandidatesPageSize,
    cacheKey: JobCandidatesCache.jobIndexCacheKey(job.nanoId),
    initialFilters: {
      [JobCandidateFields.JOB_ID]: { operator: 'eq', value: job.id },
      [JobCandidateFields.NANO_ID]: {
        operator: 'not.eq',
        value: jobCandidate.nanoId
      },
      [JobCandidateFields.WORKFLOW_STAGE_ID]: {
        operator: 'eq',
        value: getSelectedStageId(selectedStage)
      }
    } as unknown as FetchJobCandidatesFilters
  });

  const changeStage = (e: MouseEvent<HTMLButtonElement>) => {
    const { value } = e.currentTarget;

    setSelectedStage(value as WorkflowStageNanoId);

    filterJobCandidates({
      [JobCandidateFields.JOB_ID]: { operator: 'eq', value: job.id },
      [JobCandidateFields.WORKFLOW_STAGE_ID]: {
        operator: 'eq',
        value: getSelectedStageId(value as WorkflowStageNanoId)
      }
    } as unknown as FetchJobCandidatesFilters);
  };

  return (
    <>
      <Grid
        py={4}
        h="full"
        px={3.5}
        flex={1}
        overflow="hidden"
        flexDirection="column"
        gridTemplateRows="max-content 1fr max-content"
      >
        <Flex
          pb={3}
          gap={2}
          alignItems="center"
          borderBottom="4px solid"
          borderBottomColor={selectedStageColor ?? 'gray.200'}
        >
          <ViewJobCandidateDetailsStagesDropdown
            workflowStageNanoId={selectedStage}
            workflowStages={workflowStages}
            onClick={changeStage}
            rightElement={
              jobCandidatesTotalCount && (
                <Flex
                  h={5}
                  px={1.5}
                  minW={5}
                  color="white"
                  fontSize="xs"
                  lineHeight={1}
                  alignItems="center"
                  borderRadius="full"
                  justifyContent="center"
                  fontWeight="medium"
                  bg="primary.500"
                >
                  {jobCandidatesTotalCount}
                </Flex>
              )
            }
          />
        </Flex>

        <Flex
          pt={3}
          gap={3}
          flex={1}
          px={3.5}
          mx={-3.5}
          overflow="auto"
          flexDirection="column"
        >
          <Loader loaded={jobCandidatesIsFetched}>
            <Stack spacing={0.5} divider={<StackDivider />}>
              {jobCandidates.map((candidate) => {
                if (candidate.nanoId !== jobCandidate.nanoId)
                  return (
                    <PipelineStageCandidate
                      workflowStage={workflowStages}
                      jobCandidate={candidate}
                      isHiringPortalPage={isHiringPortalPage}
                      key={candidate.id}
                    />
                  );
              })}
            </Stack>
          </Loader>
        </Flex>

        <Flex flex={1} pt={2}>
          <Button
            h={9}
            flex={1}
            size="small"
            hierarchy="secondary"
            leftIcon={<PlusIcon />}
            onClick={onOpenAddCandidate}
          >
            Add Candidate
          </Button>
        </Flex>
      </Grid>

      {isOpenAddCandidate ? (
        <AddNewLeadModal
          modalIsOpen={isOpenAddCandidate as ModalIsOpen}
          onCloseModal={onCloseAddCandidate}
          jobNanoId={job.nanoId}
        />
      ) : null}
    </>
  );
}

export default ViewJobCandidateDetailsPipelineStages;
