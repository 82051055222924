import {
  AccountTypeItemBffID,
  AccountTypeItemBffNanoID,
  FetchAccountTypesBffURL,
  FetchAccountTypeBffURL,
  FetchAccountTypeByNanoIdBffURL,
  CreateAccountTypeBffURL,
  UpdateAccountTypeBffURL,
  DeleteAccountTypeBffURL
} from './accountTypesTypes';

import { BaseBffRoutes } from '../BaseBffRoutes';

export class AccountTypesBffRoutes extends BaseBffRoutes {
  protected static route = 'accountTypes';

  static fetchAccountTypesRoute() {
    return this.fetchItemsRoute<FetchAccountTypesBffURL>();
  }

  static fetchAccountTypesIndexRoute() {
    return this.fetchItemsIndexRoute<FetchAccountTypesBffURL>();
  }

  static fetchAccountTypeRoute(accountTypeItemBffID: AccountTypeItemBffID) {
    return this.fetchItemRoute<FetchAccountTypeBffURL>(accountTypeItemBffID);
  }

  static fetchAccountTypeIndexRoute(
    accountTypeItemBffNanoID: AccountTypeItemBffNanoID
  ) {
    return this.fetchItemIndexRoute<FetchAccountTypeBffURL>(
      accountTypeItemBffNanoID
    );
  }

  static fetchAccountTypeByNanoIdRoute(
    accountTypeItemBffNanoID: AccountTypeItemBffNanoID
  ) {
    return this.fetchItemByNanoIdRoute<FetchAccountTypeByNanoIdBffURL>(
      accountTypeItemBffNanoID
    );
  }

  static createAccountTypeRoute() {
    return this.createItemRoute<CreateAccountTypeBffURL>();
  }

  static updateAccountTypeRoute(
    accountTypeItemBffNanoID: AccountTypeItemBffID | AccountTypeItemBffNanoID
  ) {
    return this.updateItemRoute<UpdateAccountTypeBffURL>(
      accountTypeItemBffNanoID
    );
  }

  static deleteAccountTypeRoute(
    accountTypeItemBffNanoID: AccountTypeItemBffNanoID
  ) {
    return this.deleteItemRoute<DeleteAccountTypeBffURL>(
      accountTypeItemBffNanoID
    );
  }
}
