import { Icon, IconProps } from '@chakra-ui/icon';

const ActivitiesIcon = ({
  w = '24px',
  h = '24px',
  fill = 'gray.500',
  ...props
}: IconProps) => (
  <Icon w={w} h={h} viewBox="0 0 16 16" fill={fill} {...props}>
    <path d="M14.125 7.127H11.5a.875.875 0 00-.813.55L9.75 10.02l-2.687-6.72a.875.875 0 00-1.626 0l-1.53 3.826H1.876a.875.875 0 000 1.75H4.5c.358 0 .68-.218.813-.55l.937-2.345 2.687 6.72a.875.875 0 001.626 0l1.53-3.825h2.032a.875.875 0 100-1.75z" />
  </Icon>
);
export default ActivitiesIcon;
