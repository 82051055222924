import { usePaginatedItems } from '../../../common/hooks/usePaginatedItems';

import {
  FetchIndustriesQuery,
  FetchIndustriesCacheKey,
  FetchIndustriesTotalCount,
  FetchIndustriesPage,
  FetchIndustriesPageSize,
  FetchIndustriesError,
  FetchIndustriesErrorMessage,
  FetchIndustriesIsLoading,
  FetchIndustriesIsFetched,
  FetchIndustriesIsPlaceholderData,
  FetchIndustriesEnabled,
  FetchIndustriesPrefetchNextPage,
  FetchIndustriesCountType,
  FetchIndustriesFilters,
  FetchIndustriesSort,
  FetchIndustryID,
  FetchIndustryQuery,
  FetchIndustryCacheKey
} from '../../industriesTypes';

import { IndustriesBffRequests } from '../../IndustriesBffRequests';

interface PaginatedIndustriesBaseOptions<PaginatedIndustriesResponseType> {
  cacheKey: FetchIndustriesCacheKey;
  query: FetchIndustriesQuery;
  countType?: FetchIndustriesCountType;
  initialPage?: FetchIndustriesPage;
  initialPageSize?: FetchIndustriesPageSize;
  initialFilters?: FetchIndustriesFilters;
  initialSort?: FetchIndustriesSort;
  enabled?: FetchIndustriesEnabled;
  prefetchNextPage?: FetchIndustriesPrefetchNextPage;
  placeholderIndustries?: PaginatedIndustriesResponseType[];
  placeholderIndustriesCount?: FetchIndustriesTotalCount;
  placeholderData?: () => PaginatedIndustriesResponse<PaginatedIndustriesResponseType>;
}

interface PaginatedIndustriesOptionsWithoutItem {
  fetchItemCacheKey?: never;
  itemQuery?: never;
}

interface PaginatedIndustriesOptionsWithItem {
  fetchItemCacheKey: FetchIndustryCacheKey;
  itemQuery: FetchIndustryQuery;
}

type PaginatedIndustriesOptions<PaginatedIndustriesResponseType> =
  PaginatedIndustriesBaseOptions<PaginatedIndustriesResponseType> &
    (
      | PaginatedIndustriesOptionsWithoutItem
      | PaginatedIndustriesOptionsWithItem
    );

interface PaginatedIndustriesResponse<PaginatedIndustriesResponseType> {
  data: PaginatedIndustriesResponseType[];
  totalCount: FetchIndustriesTotalCount;
}

function usePaginatedIndustries<PaginatedIndustriesResponseType>({
  cacheKey,
  query,
  countType,
  initialPage,
  initialPageSize,
  initialFilters,
  initialSort,
  enabled,
  prefetchNextPage,
  placeholderIndustries,
  placeholderIndustriesCount,
  placeholderData,
  fetchItemCacheKey,
  itemQuery
}: PaginatedIndustriesOptions<PaginatedIndustriesResponseType>) {
  const {
    items,
    itemsTotalCount,
    itemsError,
    itemsErrorMessage,
    itemsIsFetched,
    itemsIsLoading,
    itemsIsPlaceholderData,
    itemsCurrentPage,
    itemsCurrentPageSize,
    itemsCurrentFilters,
    itemsCurrentSort,
    fetchItems,
    paginateItems,
    changeItemsPageSize,
    filterItems,
    changeItemsFilters,
    clearItemsFilters,
    sortItems,
    prefetchItems,
    prefetchItem
  } = usePaginatedItems<PaginatedIndustriesResponseType>({
    cacheKey,
    initialPage,
    initialPageSize,
    initialFilters,
    initialSort,
    enabled,
    prefetchNextPage,
    placeholderItems: placeholderIndustries,
    placeholderItemsCount: placeholderIndustriesCount,
    placeholderData,
    queryFn: (params) =>
      IndustriesBffRequests.fetchIndustries<PaginatedIndustriesResponseType>({
        query,
        countType,
        page: params.page as FetchIndustriesPage,
        pageSize: params.pageSize as FetchIndustriesPageSize,
        filters: params.filters as FetchIndustriesFilters,
        sort: params.sort as FetchIndustriesSort
      }),
    ...(itemQuery
      ? {
          fetchItemCacheKey,
          queryItemFn: (itemId) =>
            IndustriesBffRequests.fetchIndustry(itemId as FetchIndustryID, {
              query: itemQuery
            })
        }
      : {})
  });

  return {
    industries: items as PaginatedIndustriesResponseType[],
    industriesTotalCount: itemsTotalCount as FetchIndustriesTotalCount,
    industriesError: itemsError as FetchIndustriesError,
    industriesErrorMessage: itemsErrorMessage as FetchIndustriesErrorMessage,
    industriesIsLoading: itemsIsLoading as FetchIndustriesIsLoading,
    industriesIsFetched: itemsIsFetched as FetchIndustriesIsFetched,
    industriesIsPlaceholderData:
      itemsIsPlaceholderData as FetchIndustriesIsPlaceholderData,
    industriesCurrentPage: itemsCurrentPage as FetchIndustriesPage,
    industriesCurrentPageSize: itemsCurrentPageSize as FetchIndustriesPageSize,
    industriesCurrentFilters: itemsCurrentFilters as FetchIndustriesFilters,
    industriesCurrentSort: itemsCurrentSort as FetchIndustriesSort,
    fetchIndustries: fetchItems,
    paginateIndustries: paginateItems,
    changeIndustriesPageSize: changeItemsPageSize,
    filterIndustries: filterItems,
    changeIndustriesFilters: changeItemsFilters,
    clearIndustriesFilters: clearItemsFilters,
    sortIndustries: sortItems,
    prefetchIndustries: prefetchItems,
    prefetchIndustry: prefetchItem
  };
}

export default usePaginatedIndustries;
