import React from 'react';
import { FieldValues } from 'react-hook-form';
import debounce from 'lodash/debounce';
import uniq from 'lodash/uniq';
import flatMap from 'lodash/flatMap';

import { ErrorMessage, I18nText } from '../../../../../types';

import {
  FetchEducationsFilters,
  FetchEducationsPageSize,
  FetchEducationsSort,
  EducationFields
} from '../../../../educations/educationsTypes';

import {
  fetchEducationsQuery,
  FetchEducationsResponse
} from '../../../../educations/queries/fetchEducations.query';

import { usePaginatedEducations } from '../../../../educations/hooks/usePaginatedEducations';

import { EducationsCache } from '../../../../educations/EducationsCache';

import {
  SelectField,
  SelectFieldReactHookFormControl,
  SelectFieldReactHookFormFieldPath
} from '../../../../../helpers/forms/formFields/SelectField';

interface SelectDegreeFormFieldItem {
  degree: string;
}

interface SelectDegreeFormFieldProps<T extends FieldValues> {
  control: SelectFieldReactHookFormControl<T>;
  label?: I18nText;
  placeholder?: string;
  item?: SelectDegreeFormFieldItem;
  errorMessage: ErrorMessage;
  withoutLabel?: boolean;
}

function SelectDegreeFormField<T extends FieldValues>({
  control,
  label = 'Degree',
  placeholder,
  item,
  errorMessage,
  withoutLabel
}: SelectDegreeFormFieldProps<T>) {
  const {
    educations,
    educationsIsLoading,
    educationsErrorMessage,
    changeEducationsFilters
  } = usePaginatedEducations<FetchEducationsResponse>({
    query: fetchEducationsQuery,
    cacheKey: EducationsCache.degreeCacheKey(),
    initialPageSize: 100 as FetchEducationsPageSize,
    initialFilters: {
      ...(item?.degree
        ? {
            [EducationFields.DEGREE]: {
              operator: 'ilike',
              value: `${item.degree}%`
            }
          }
        : {})
    } as unknown as FetchEducationsFilters,
    initialSort: {
      [EducationFields.DEGREE]: { ascending: true }
    } as unknown as FetchEducationsSort
  });

  const defaultCurrentDegree = item?.degree
    ? {
        value: item?.degree,
        label: item?.degree
      }
    : undefined;

  const debouncedFilterEducations = debounce<(updatedValue: string) => void>(
    (updatedValue) =>
      changeEducationsFilters({
        [EducationFields.DEGREE]: {
          operator: 'ilike',
          value: `${updatedValue}%`
        }
      })
  );

  const options = uniq(flatMap(educations, (education) => education.degree));

  return (
    <SelectField
      label={withoutLabel ? undefined : label}
      options={options.map((degree) => ({
        value: degree,
        label: degree
      }))}
      placeholder={placeholder || 'Select degree'}
      control={control}
      name={'degree' as SelectFieldReactHookFormFieldPath<T>}
      isLoading={educationsIsLoading}
      defaultValue={defaultCurrentDegree}
      errorMessage={errorMessage || educationsErrorMessage}
      onInputChange={debouncedFilterEducations}
    />
  );
}

export default SelectDegreeFormField;
