import { Box, Flex, HStack, Stack, StackDivider } from '@chakra-ui/react';
import compact from 'lodash/compact';
import { Fragment } from 'react';
import { AlertMessage } from '../../../../../../../../../../../helpers/AlertMessage';
import { AvatarHelper } from '../../../../../../../../../../../helpers/AvatarHelper';
import { Button } from '../../../../../../../../../../../helpers/Button';
import { LoadingSkeleton } from '../../../../../../../../../../../helpers/LoadingSkeleton';
import { RelativeDateHelper } from '../../../../../../../../../../../helpers/RelativeDateHelper';
import { Text } from '../../../../../../../../../../../helpers/Text';
import { LocationIcon } from '../../../../../../../../../../../icons/LocationIcon';
import { ThumbDownIcon } from '../../../../../../../../../../../icons/ThumbDownIcon';
import { ThumbUpIcon } from '../../../../../../../../../../../icons/ThumbUpIcon';
import { JobCandidatesCache } from '../../../../../../../../../../jobCandidates/JobCandidatesCache';
import { JobCandidateLabelHelper } from '../../../../../../../../../../jobCandidates/helpers/JobCandidateLabelHelper';
import { usePaginatedJobCandidates } from '../../../../../../../../../../jobCandidates/hooks/usePaginatedJobCandidates';
import {
  FetchJobCandidatesCountType,
  FetchJobCandidatesEnabled,
  FetchJobCandidatesFilters,
  JobCandidateDisqualificationReasons,
  JobCandidateFields,
  JobCandidateSearchTypeLabel
} from '../../../../../../../../../../jobCandidates/jobCandidatesTypes';
import {
  FetchJobCandidatesResponse,
  fetchJobCandidatesQuery
} from '../../../../../../../../../../jobCandidates/queries/fetchJobCandidates.query';
import { FetchJobResponse } from '../../../../../../../../../queries/fetchJob.query';

interface ViewJobCandidateModalPhoneSidebarCandidatesReviewedProps {
  job: FetchJobResponse;
}

const ViewJobCandidateModalPhoneSidebarCandidatesReviewed = ({
  job
}: ViewJobCandidateModalPhoneSidebarCandidatesReviewedProps) => {
  const { jobCandidates, jobCandidatesIsFetched, jobCandidatesErrorMessage } =
    usePaginatedJobCandidates<FetchJobCandidatesResponse>({
      query: fetchJobCandidatesQuery,
      cacheKey: JobCandidatesCache.jobIndexCacheKey(job.nanoId),
      enabled: !!job.id as FetchJobCandidatesEnabled,
      countType: 'exact' as FetchJobCandidatesCountType,
      initialFilters: {
        [JobCandidateFields.JOB_ID]: { operator: 'eq', value: job.id }
      } as unknown as FetchJobCandidatesFilters
    });

  return (
    <Fragment>
      <AlertMessage message={jobCandidatesErrorMessage} />

      <LoadingSkeleton loaded={jobCandidatesIsFetched}>
        <Stack divider={<StackDivider />}>
          {jobCandidates.map((candidate) => (
            <Flex
              gap={4}
              bg="white"
              textAlign="left"
              transitionDuration="fast"
              transitionProperty="background"
              key={candidate.nanoId}
            >
              <AvatarHelper image={candidate.image} size="sm" />

              <Stack spacing={0} flex={1}>
                <Flex gap={2}>
                  <Text textStyle="body1Medium" noOfLines={1}>
                    {candidate.name}
                  </Text>

                  <Flex ml="auto" gap={2}>
                    {candidate.disqualificationReason ===
                    JobCandidateDisqualificationReasons.NONE ? (
                      <Flex
                        w={6}
                        h={6}
                        pt="9px"
                        pb="11px"
                        bg="green.100"
                        alignItems="center"
                        justifyContent="center"
                        borderRadius="full"
                      >
                        <ThumbUpIcon color="green.400" w={2.5} h={2.5} />
                      </Flex>
                    ) : (
                      <Flex
                        w={6}
                        h={6}
                        pb="9px"
                        pt="11px"
                        bg="red.100"
                        alignItems="center"
                        justifyContent="center"
                        borderRadius="full"
                      >
                        <ThumbDownIcon color="red.500" w={2.5} h={2.5} />
                      </Flex>
                    )}

                    <JobCandidateLabelHelper
                      searchTypeLabel={
                        candidate.searchTypeLabel as JobCandidateSearchTypeLabel
                      }
                    />
                  </Flex>
                </Flex>

                <Stack spacing={1}>
                  <Text textStyle="body2Regular" color="gray.600">
                    {compact([candidate.jobTitle, candidate.company]).join(
                      ' @ '
                    )}
                  </Text>

                  <Flex gap={2}>
                    {candidate.city && (
                      <HStack spacing={1}>
                        <LocationIcon color="gray.500" h={4} w={4} />
                        <Text
                          textStyle="body2Regular"
                          color="gray.900"
                          fontWeight={400}
                        >
                          {candidate.city.name}
                        </Text>
                      </HStack>
                    )}

                    <Text textStyle="body2Regular" color="gray.600" ml="auto">
                      <RelativeDateHelper date={candidate.createdAt} />
                    </Text>
                  </Flex>

                  <Box>
                    <Button hierarchy="link" size="medium">
                      See notes
                    </Button>
                  </Box>
                </Stack>
              </Stack>
            </Flex>
          ))}
        </Stack>
      </LoadingSkeleton>
    </Fragment>
  );
};

export default ViewJobCandidateModalPhoneSidebarCandidatesReviewed;
