import {
  CompanyMemberInvitationItemBffID,
  CompanyMemberInvitationItemBffNanoID,
  FetchCompanyMemberInvitationsBffURL,
  FetchCompanyMemberInvitationBffURL,
  FetchCompanyMemberInvitationByNanoIdBffURL,
  CreateCompanyMemberInvitationBffURL,
  UpdateCompanyMemberInvitationBffURL,
  DeleteCompanyMemberInvitationBffURL,
  SendCompanyMemberInvitationBffURL
} from './companyMemberInvitationsTypes';

import { BaseBffRoutes } from '../BaseBffRoutes';

export class CompanyMemberInvitationsBffRoutes extends BaseBffRoutes {
  protected static route = 'companyMemberInvitations';

  static fetchCompanyMemberInvitationsRoute() {
    return this.fetchItemsRoute<FetchCompanyMemberInvitationsBffURL>();
  }

  static fetchCompanyMemberInvitationsIndexRoute() {
    return this.fetchItemsIndexRoute<FetchCompanyMemberInvitationsBffURL>();
  }

  static fetchCompanyMemberInvitationRoute(
    companyMemberInvitationItemBffID: CompanyMemberInvitationItemBffID
  ) {
    return this.fetchItemRoute<FetchCompanyMemberInvitationBffURL>(
      companyMemberInvitationItemBffID
    );
  }

  static fetchCompanyMemberInvitationIndexRoute(
    companyMemberInvitationItemBffNanoID: CompanyMemberInvitationItemBffNanoID
  ) {
    return this.fetchItemIndexRoute<FetchCompanyMemberInvitationBffURL>(
      companyMemberInvitationItemBffNanoID
    );
  }

  static fetchCompanyMemberInvitationByNanoIdRoute(
    companyMemberInvitationItemBffNanoID: CompanyMemberInvitationItemBffNanoID
  ) {
    return this.fetchItemByNanoIdRoute<FetchCompanyMemberInvitationByNanoIdBffURL>(
      companyMemberInvitationItemBffNanoID
    );
  }

  static createCompanyMemberInvitationRoute() {
    return this.createItemRoute<CreateCompanyMemberInvitationBffURL>();
  }

  static updateCompanyMemberInvitationRoute(
    companyMemberInvitationItemBffNanoID:
      | CompanyMemberInvitationItemBffID
      | CompanyMemberInvitationItemBffNanoID
  ) {
    return this.updateItemRoute<UpdateCompanyMemberInvitationBffURL>(
      companyMemberInvitationItemBffNanoID
    );
  }

  static deleteCompanyMemberInvitationRoute(
    companyMemberInvitationItemBffNanoID:
      | CompanyMemberInvitationItemBffID
      | CompanyMemberInvitationItemBffNanoID
  ) {
    return this.deleteItemRoute<DeleteCompanyMemberInvitationBffURL>(
      companyMemberInvitationItemBffNanoID
    );
  }

  static sendCompanyMemberInvitationRoute(
    companyMemberInvitationItemBffNanoID: CompanyMemberInvitationItemBffNanoID
  ) {
    return this.postItemRoute<SendCompanyMemberInvitationBffURL>(
      'send',
      companyMemberInvitationItemBffNanoID
    );
  }
}
