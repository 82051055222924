import { ItemNanoId, ModelBrand } from '../types';

import { MayBeAccountTypeId } from '../main/accountTypes/accountTypesTypes';

import { MayBeCompanyId } from '../main/companies/companiesTypes';

import { MayBeJobResumeRecruiterId } from '../main/jobResumeRecruiters/jobResumeRecruitersTypes';

import {
  UserId,
  UserEmail,
  UserName,
  UserCaptureType,
  UserSearchType
} from '../main/users/usersTypes';

export type AuthId = ModelBrand<UserId, 'AuthId'>;
export type AuthName = ModelBrand<UserName, 'AuthName'>;
export type AuthEmail = ModelBrand<UserEmail, 'AuthEmail'>;
export type AuthCaptureType = ModelBrand<UserCaptureType, 'AuthCaptureType'>;
export type AuthSearchType = ModelBrand<UserSearchType, 'AuthSearchType'>;
export type AuthPassword = ModelBrand<string, 'AuthPassword'>;
export type AuthPasswordConfirmation = ModelBrand<
  string,
  'AuthPasswordConfirmation'
>;
export type AuthAccountTypeId = ModelBrand<
  MayBeAccountTypeId,
  'AuthAccountTypeId'
>;
export type AuthCompanyId = ModelBrand<MayBeCompanyId, 'AuthCompanyId'>;
export type AuthJobResumeRecruiterId = ModelBrand<
  MayBeJobResumeRecruiterId,
  'AuthJobResumeRecruiterId'
>;
export type AuthNanoId = ModelBrand<ItemNanoId, 'AuthNanoId'>;
export type AuthPhone = ModelBrand<string, 'AuthPhone'>;
export type AuthPolicy = ModelBrand<boolean, 'AuthPolicy'>;

export const enum AuthFields {
  NAME = 'name',
  EMAIL = 'email',
  PASSWORD = 'password',
  PASSWORD_CONFIRMATION = 'passwordConfirmation',
  ACCOUNT_TYPE = 'accountType',
  ACCOUNT_TYPE_ID = 'accountTypeId',
  COMPANY = 'company',
  COMPANY_ID = 'companyId',
  POLICY = 'policy'
}
