import {
  EmploymentSkillBadgeItemBffID,
  EmploymentSkillBadgeItemBffNanoID,
  FetchEmploymentSkillBadgesBffURL,
  FetchEmploymentSkillBadgeBffURL,
  FetchEmploymentSkillBadgeByNanoIdBffURL,
  CreateEmploymentSkillBadgeBffURL,
  UpdateEmploymentSkillBadgeBffURL,
  DeleteEmploymentSkillBadgeBffURL
} from './employmentSkillBadgesTypes';

import { BaseBffRoutes } from '../BaseBffRoutes';

export class EmploymentSkillBadgesBffRoutes extends BaseBffRoutes {
  protected static route = 'employmentSkillBadges';

  static fetchEmploymentSkillBadgesRoute() {
    return this.fetchItemsRoute<FetchEmploymentSkillBadgesBffURL>();
  }

  static fetchEmploymentSkillBadgesIndexRoute() {
    return this.fetchItemsIndexRoute<FetchEmploymentSkillBadgesBffURL>();
  }

  static fetchEmploymentSkillBadgeRoute(
    employmentSkillBadgeItemBffID: EmploymentSkillBadgeItemBffID
  ) {
    return this.fetchItemRoute<FetchEmploymentSkillBadgeBffURL>(
      employmentSkillBadgeItemBffID
    );
  }

  static fetchEmploymentSkillBadgeIndexRoute(
    employmentSkillBadgeItemBffNanoID: EmploymentSkillBadgeItemBffNanoID
  ) {
    return this.fetchItemIndexRoute<FetchEmploymentSkillBadgeBffURL>(
      employmentSkillBadgeItemBffNanoID
    );
  }

  static fetchEmploymentSkillBadgeByNanoIdRoute(
    employmentSkillBadgeItemBffNanoID: EmploymentSkillBadgeItemBffNanoID
  ) {
    return this.fetchItemByNanoIdRoute<FetchEmploymentSkillBadgeByNanoIdBffURL>(
      employmentSkillBadgeItemBffNanoID
    );
  }

  static createEmploymentSkillBadgeRoute() {
    return this.createItemRoute<CreateEmploymentSkillBadgeBffURL>();
  }

  static updateEmploymentSkillBadgeRoute(
    employmentSkillBadgeItemBffID:
      | EmploymentSkillBadgeItemBffID
      | EmploymentSkillBadgeItemBffNanoID
  ) {
    return this.updateItemRoute<UpdateEmploymentSkillBadgeBffURL>(
      employmentSkillBadgeItemBffID
    );
  }

  static deleteEmploymentSkillBadgeRoute(
    employmentSkillBadgeItemBffID:
      | EmploymentSkillBadgeItemBffID
      | EmploymentSkillBadgeItemBffNanoID
  ) {
    return this.deleteItemRoute<DeleteEmploymentSkillBadgeBffURL>(
      employmentSkillBadgeItemBffID
    );
  }
}
