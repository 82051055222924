export const enum IconsEnum {
  ADD_CANDIDATE = 'add_candidate',
  AVATAR_PLACEHOLDER = 'avatar_placeholder',
  BELL = 'bell',
  CHEVRON_DOWN = 'chevron_down',
  GOOGLE = 'google',
  EYE = 'eye',
  FOLDERS_BUTTON = 'folders_button',
  LINKED_IN = 'linked_in',
  NOTES_BUTTON = 'notes_button',
  PLUS = 'plus',
  SEARCH = 'search',
  TASKS_BUTTON = 'tasks_button',
  ACTIVITIES_BUTTON = 'activities_button',
  SCHEDULE_BUTTON = 'schedule_button',
  UPDATES_BUTTON = 'updates_button',
  EMAIL_BUTTON = 'email_button',
  CALENDAR_BUTTON = 'calendar_button'
}
