import get from 'lodash/get';
import has from 'lodash/has';

interface ResponseError {
  response: {
    data: { message: string };
  };
}

interface MessageError {
  message: string;
}

function parseRequestError<T extends MessageError | ResponseError>(
  error: T | null
) {
  if (!error) {
    return null;
  }

  if (has(error, 'response.data.message')) {
    return get(error, 'response.data.message');
  }

  if (has(error, 'message')) {
    return get(error, 'message');
  }

  if (error instanceof Error) {
    return error.message;
  }

  return 'Server-side error';
}

export default parseRequestError;
