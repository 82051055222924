import { Icon, IconProps } from '@chakra-ui/icon';

const QuestionMarkIcon = ({ w = '16px', h = '16px', ...props }: IconProps) => (
  <Icon viewBox="0 0 16 16" w={w} h={h} fill="none" {...props}>
    <path
      fillRule="evenodd"
      d="M8.5 8.434V9a.5.5 0 01-1 0V8a.5.5 0 01.5-.5c.533 0 1-.468 1-1s-.467-1-1-1c-.406 0-.796.336-.938.661a.499.499 0 11-.916-.4C6.466 5.03 7.245 4.5 8 4.5c1.084 0 2 .916 2 2 0 .912-.648 1.706-1.5 1.934zM8 11.5a.5.5 0 110-1 .5.5 0 010 1zM8 2C4.691 2 2 4.691 2 8s2.691 6 6 6 6-2.691 6-6-2.691-6-6-6z"
      clipRule="evenodd"
      fill="currentColor"
    />
  </Icon>
);
export default QuestionMarkIcon;
