import { ReactNode } from 'react';
import { Flex } from '@chakra-ui/layout';

import { Text } from '../../../../helpers/Text';

interface IndustryExpertiseRequirementItemProps {
  label: string | null;
  icon: ReactNode;
}

function IndustryExpertiseRequirementItem({
  label,
  icon
}: IndustryExpertiseRequirementItemProps) {
  return (
    <Flex gap={1} alignItems="center">
      <Flex
        w={9}
        h={9}
        bg="rgba(255,255,255,0.1)"
        borderRadius="full"
        alignItems="center"
        justifyContent="center"
      >
        {icon}
      </Flex>
      <Text textStyle="body1Medium" textTransform="capitalize">
        {label}
      </Text>
    </Flex>
  );
}

export default IndustryExpertiseRequirementItem;
