import { Icon, IconProps } from '@chakra-ui/icon';

const ActivitiesButtonIcon = ({
  w = '40px',
  h = '40px',
  ...props
}: IconProps) => (
  <Icon viewBox="0 0 40 40" w={w} h={h} {...props}>
    <circle cx={20} cy={20} r={20} fill="#AB56B2" />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M24.992 20.708L22.95 24.81c-.152.304-.379.456-.681.456-.303 0-.53-.152-.68-.456l-3.858-7.747-1.588 3.19c-.151.304-.378.456-.68.456H11c.378 4.633 4.235 8.355 9 8.355s8.622-3.722 9-8.355h-4.008z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M15.008 19.354l2.042-4.1c.227-.532 1.06-.532 1.362 0L22.269 23l1.588-3.19c.151-.228.378-.456.68-.456H29C28.622 14.722 24.765 11 20 11s-8.622 3.722-9 8.354h4.008z"
      clipRule="evenodd"
    />
  </Icon>
);

export default ActivitiesButtonIcon;
