import { FunctionComponent } from 'react';
import {
  Flex,
  Stack,
  useCheckbox,
  useCheckboxGroup,
  UseCheckboxProps
} from '@chakra-ui/react';

import { CalendarIcon } from '../../../../icons/CalendarIcon';
import { CandidatesIcon } from '../../../../icons/CandidatesIcon';
import { EmailIcon } from '../../../../icons/EmailIcon';
import { JobsIcon } from '../../../../icons/JobsIcon';
import { Text } from '../../../Text';
import { HeaderRecentSearch } from '../HeaderRecentSearch';
import { HeaderSearchResult } from '../HeaderSearchResult';

const HeaderSearchDropdown = ({ searchParam }: { searchParam: string }) => {
  const { getCheckboxProps } = useCheckboxGroup();

  return (
    <Stack spacing={4}>
      <Flex gap={2}>
        <CustomCheckbox
          text="Calender"
          icon={CalendarIcon}
          {...getCheckboxProps({ value: 'Calender' })}
        />
        <CustomCheckbox
          text={'Email'}
          icon={EmailIcon}
          {...getCheckboxProps({ value: 'email' })}
        />
        <CustomCheckbox
          text="Jobs"
          icon={JobsIcon}
          {...getCheckboxProps({ value: 'Jobs' })}
        />
        <CustomCheckbox
          text="Candidates"
          icon={CandidatesIcon}
          {...getCheckboxProps({ value: 'Candidates' })}
        />
      </Flex>

      {searchParam ? (
        <HeaderSearchResult searchParam={searchParam} />
      ) : (
        <HeaderRecentSearch />
      )}
    </Stack>
  );
};

export default HeaderSearchDropdown;

interface CustomCheckboxProps extends UseCheckboxProps {
  icon: FunctionComponent;
  text: string;
}

const CustomCheckbox = ({ icon, text, ...props }: CustomCheckboxProps) => {
  const { state, getInputProps, getLabelProps, htmlProps } = useCheckbox(props);

  return (
    <Flex
      as="label"
      px={4}
      py={2}
      gap={2}
      cursor="pointer"
      borderRadius="4"
      border="1px solid"
      bg={state.isChecked ? 'gray.600' : 'white'}
      borderColor={state.isChecked ? 'gray.600' : 'gray.300'}
      boxShadow="0px 2px 2px rgba(23, 25, 26, 0.06)"
      transitionDuration="slow"
      transitionProperty="all"
      {...htmlProps}
    >
      <input {...getInputProps()} hidden />

      <Flex
        as={icon}
        fill={state.isChecked ? 'white' : 'gray.500'}
        transitionDuration="slow"
        transitionProperty="all"
      />

      <Text
        as="span"
        color={state.isChecked ? 'white' : 'gray.900'}
        {...getLabelProps()}
      >
        {text}
      </Text>
    </Flex>
  );
};
