import { RefCallBack } from 'react-hook-form';
import { Props as ChakraSelectProps } from 'chakra-react-select';

import { ErrorMessage } from '../../../../../../../types';

import {
  SelectFieldControl,
  SelectOptionType
} from '../../../../../../../helpers/forms/formFields/SelectField/components/SelectFieldControl';
import { CandidateShelfLife } from './SelectCandidateShelfLifeFormFieldContro.types';
import { removeUnderscore } from '../../../../../../../utils/removeUnderscore';

const options = Object.values(CandidateShelfLife).map((val) => ({
  value: val,
  label: removeUnderscore(val)
}));

interface SelectCandidateShelfLifeFormFieldControlProps {
  errorMessage?: ErrorMessage;
  label?: string;
  isRequired?: boolean;
  onChange: (
    value: string | number | null,
    selectedOption?: SelectOptionType
  ) => void;
  value?: ChakraSelectProps['value'];
  defaultValue?: SelectOptionType;
  ref?: RefCallBack;
  name?: string;
}

function SelectCandidateShelfLifeFormFieldControl({
  isRequired,
  errorMessage,
  label = 'Candidate shelf life',
  name = 'candidateShelfLife',
  onChange,
  value,
  ref,
  defaultValue
}: SelectCandidateShelfLifeFormFieldControlProps) {
  return (
    <SelectFieldControl
      isRequired={isRequired}
      label={label}
      options={options}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      ref={ref}
      placeholder="Candidate shelf life"
      name={name}
      errorMessage={errorMessage}
    />
  );
}

export default SelectCandidateShelfLifeFormFieldControl;
