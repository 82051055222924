import { FetchSkillLicensesCacheKey } from '../../skillLicensesTypes';

import { useCreateItem } from '../../../common/hooks/useCreateItem';

import {
  SkillLicensesBffRequests,
  CreateSkillLicenseRequestData,
  CreateSkillLicenseErrorResponse
} from '../../SkillLicensesBffRequests';

interface CreateSkillLicenseOptions {
  cacheKeys?: FetchSkillLicensesCacheKey[];
}

function useCreateSkillLicense({ cacheKeys = [] }: CreateSkillLicenseOptions) {
  const {
    createItemData,
    createItemError,
    createItemErrorMessage,
    createItemIsLoading,
    createItem,
    createItemReset
  } = useCreateItem<
    CreateSkillLicenseRequestData,
    CreateSkillLicenseErrorResponse
  >({
    mutationFn: (queryInput) =>
      SkillLicensesBffRequests.createSkillLicense(queryInput),
    cacheKeys
  });

  return {
    createSkillLicenseData: createItemData,
    createSkillLicenseError: createItemError,
    createSkillLicenseErrorMessage: createItemErrorMessage,
    createSkillLicenseIsLoading: createItemIsLoading,
    createSkillLicense: createItem,
    createSkillLicenseReset: createItemReset
  };
}

export default useCreateSkillLicense;
