import { FieldBrand, ItemRecordID } from '../../../types';

import { UserUuid } from '../../users/usersTypes';

export type CandidateSubmissionRecordId = FieldBrand<
  ItemRecordID,
  'CandidateSubmissionRecordId'
>;

export type CandidateSubmissionUserUuid = FieldBrand<
  UserUuid,
  'CandidateSubmissionUserUuid'
>;

export const enum CandidateSubmissionIndexFields {
  ID = 'id',
  RECORD_ID = 'recordId',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  CREATED_AT = 'createdAt',

  USER_UUID = 'userUuid'
}
