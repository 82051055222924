import {
  MayBe,
  FieldBrand,
  ItemID,
  ItemUUID,
  ItemNanoId,
  ItemCreatedAt
} from '../../../types';
import {
  EmploymentCurrently,
  EmploymentEndDate,
  EmploymentId,
  EmploymentJobTitle,
  EmploymentName,
  EmploymentStartDate,
  MayBeEmploymentCompany
} from '../../employments/employmentsTypes';
import { SkillBadgeId } from '../../skillBadges/skillBadgesTypes';

import {
  UserId,
  UserNanoId,
  UserName,
  UserImage
} from '../../users/usersTypes';

export type EmploymentSkillBadgeId = FieldBrand<
  ItemID,
  'EmploymentSkillBadgeId'
>;
export type MayBeEmploymentSkillBadgeId = MayBe<EmploymentSkillBadgeId>;
export type EmploymentSkillBadgeUuid = FieldBrand<
  ItemUUID,
  'EmploymentSkillBadgeUuid'
>;
export type MayBeEmploymentSkillBadgeUuid = MayBe<EmploymentSkillBadgeUuid>;
export type EmploymentSkillBadgeNanoId = FieldBrand<
  ItemNanoId,
  'EmploymentSkillBadgeNanoId'
>;
export type MayBeEmploymentSkillBadgeNanoId = MayBe<EmploymentSkillBadgeNanoId>;
export type EmploymentSkillBadgeUserId = FieldBrand<
  UserId,
  'EmploymentSkillBadgeUserId'
>;
export type EmploymentSkillBadgeUser = FieldBrand<
  {
    id: UserId;
    nanoId: UserNanoId;
    name: UserName;
    image: UserImage;
  },
  'EmploymentSkillBadgeUser'
>;
export type EmploymentSkillBadgeCreatedAt = FieldBrand<
  ItemCreatedAt,
  'EmploymentSkillBadgeCreatedAt'
>;
export type EmploymentSkillBadgeSkillBadgeId = FieldBrand<
  SkillBadgeId,
  'EmploymentSkillBadgeSkillBadgeId'
>;
export type EmploymentSkillBadgeEmploymentId = FieldBrand<
  EmploymentId,
  'EmploymentSkillBadgeEmploymentId'
>;
export type EmploymentSkillBadgeEmployment = FieldBrand<
  {
    id: EmploymentId;
    jobTitle: EmploymentJobTitle;
    name: EmploymentName;
    company: MayBeEmploymentCompany;
    startDate: EmploymentStartDate;
    endDate: EmploymentEndDate;
    currently: EmploymentCurrently;
  },
  'EmploymentSkillBadgeEmployment'
>;
export const enum EmploymentSkillBadgeUsageTypes {
  NONE = 'none',
  NEVER_USED = 'never_used',
  DAILY_USER = 'daily_user',
  WEEKLY_USER = 'weekly_user',
  MONTHLY_USER = 'monthly_user'
}

export type EmploymentSkillBadgeUsage = FieldBrand<
  EmploymentSkillBadgeUsageTypes,
  'EmploymentSkillBadgeUsage'
>;
export type EmploymentSkillBadgeExperience = FieldBrand<
  string,
  'EmploymentSkillBadgeExperience'
>;

export const employmentSkillBadgeUsageTypes: EmploymentSkillBadgeUsage[] = [
  // EmploymentSkillBadgeUsageTypes.NEVER_USED as EmploymentSkillBadgeUsage,
  EmploymentSkillBadgeUsageTypes.DAILY_USER as EmploymentSkillBadgeUsage,
  EmploymentSkillBadgeUsageTypes.WEEKLY_USER as EmploymentSkillBadgeUsage,
  EmploymentSkillBadgeUsageTypes.MONTHLY_USER as EmploymentSkillBadgeUsage
];

export const enum EmploymentSkillBadgeFields {
  ID = 'id',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  USER_ID = 'userId',
  USER = 'user',
  SKILL_BADGE_ID = 'skillBadgeId',
  EMPLOYMENT_ID = 'employmentId',
  EMPLOYMENT = 'employment',
  USAGE = 'usage',
  EXPERIENCE = 'experience',
  CREATED_AT = 'createdAt'
}
