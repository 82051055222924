import {
  FetchWorkflowStagesCacheKey,
  FetchWorkflowStageCacheKey
} from './workflowStagesTypes';

import { JobNanoId } from '../jobs/jobsTypes';

export class WorkflowStagesCache {
  static indexCacheKey() {
    return 'workflowStages' as FetchWorkflowStagesCacheKey;
  }

  static jobIndexCacheKey(jobNanoId: JobNanoId) {
    return `workflowStages-${jobNanoId}` as FetchWorkflowStagesCacheKey;
  }

  static showCacheKey() {
    return 'workflowStage' as FetchWorkflowStageCacheKey;
  }
}
