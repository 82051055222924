import React, { useEffect } from 'react';
import { FieldValues } from 'react-hook-form';
import { SystemStyleObject } from '@chakra-ui/react';
import compact from 'lodash/compact';
import debounce from 'lodash/debounce';
import find from 'lodash/find';
import isUndefined from 'lodash/isUndefined';

import { DEFAULT_COUNTRY_ID } from '../../../../../config';

import { ErrorMessage } from '../../../../../types';

import {
  CityFields,
  CityId,
  CityLabel,
  CityName,
  FetchCitiesEnabled,
  FetchCitiesFilters,
  FetchCitiesSort
} from '../../../../cities/citiesTypes';

import { MayBeCountryId } from '../../../../countries/countriesTypes';

import { MayBeStateId, StateIsoCode } from '../../../../states/statesTypes';

import {
  fetchCitiesQuery,
  FetchCitiesResponse
} from '../../../../cities/queries/fetchCities.query';

import { usePaginatedCities } from '../../../../../main/cities/hooks/usePaginatedCities';

import { CitiesCache } from '../../../../cities/CitiesCache';

import {
  SelectField,
  SelectFieldReactHookFormControl,
  SelectFieldReactHookFormFieldPath
} from '../../../../../helpers/forms/formFields/SelectField';

interface SelectCityFormFieldItem {
  countryId?: MayBeCountryId;
  city: {
    id: CityId;
    label: CityLabel;
  } | null;
}

interface SelectCityFormWithItemFieldProps<T extends FieldValues> {
  isRequired?: boolean;
  control: SelectFieldReactHookFormControl<T>;
  item: SelectCityFormFieldItem;
  stateIsoCode?: never;
  cityName?: never;
  currentCountryId?: MayBeCountryId;
  currentStateId?: MayBeStateId;
  errorMessage: ErrorMessage;
  label?: string;
  placeholder?: string;
  showLabel?: boolean;
  name?: SelectFieldReactHookFormFieldPath<T>;
}

interface SelectCityFormWithCityNameFieldProps<T extends FieldValues> {
  isRequired?: boolean;
  control: SelectFieldReactHookFormControl<T>;
  item?: never;
  stateIsoCode: StateIsoCode;
  cityName: CityName;
  currentCountryId?: MayBeCountryId;
  currentStateId?: MayBeStateId;
  errorMessage: ErrorMessage;
  label?: string;
  placeholder?: string;
  showLabel?: boolean;
  name?: SelectFieldReactHookFormFieldPath<T>;
}

interface SelectCityFormBlankFieldProps<T extends FieldValues> {
  isRequired?: boolean;
  control: SelectFieldReactHookFormControl<T>;
  item?: never;
  stateIsoCode?: never;
  cityName?: never;
  currentCountryId?: MayBeCountryId;
  currentStateId?: MayBeStateId;
  errorMessage: ErrorMessage;
  label?: string;
  placeholder?: string;
  showLabel?: boolean;
  name?: SelectFieldReactHookFormFieldPath<T>;
}

type SelectCityFormFieldProps<T extends FieldValues> = (
  | SelectCityFormWithItemFieldProps<T>
  | SelectCityFormWithCityNameFieldProps<T>
  | SelectCityFormBlankFieldProps<T>
) & {
  reactSelectStyles?: Record<string, unknown>;
  isBorderless?: boolean;
};

function SelectCityFormField<T extends FieldValues>({
  isRequired = false,
  showLabel = true,
  label,
  placeholder,
  control,
  item,
  stateIsoCode,
  cityName,
  currentCountryId,
  currentStateId,
  errorMessage,
  reactSelectStyles,
  isBorderless,
  name
}: SelectCityFormFieldProps<T>) {
  const {
    cities,
    citiesIsLoading,
    citiesErrorMessage,
    changeCitiesFilters,
    filterCities
  } = usePaginatedCities<FetchCitiesResponse>({
    query: fetchCitiesQuery,
    cacheKey: CitiesCache.signUpCacheKey(),
    enabled: (isUndefined(currentCountryId) ||
      isUndefined(currentStateId) ||
      (Boolean(currentCountryId) &&
        Boolean(currentStateId))) as FetchCitiesEnabled,
    initialFilters: {
      [CityFields.COUNTRY_ID]: {
        operator: 'eq',
        value: item?.countryId || DEFAULT_COUNTRY_ID
      },
      ...(cityName || item?.city?.label
        ? {
            [CityFields.LABEL]: {
              operator: 'ilike',
              value: `%${cityName || item?.city?.label}%`
            }
          }
        : {}),
      ...(item?.city?.id && !item.city?.label
        ? {
            [CityFields.ID]: {
              operator: 'ilike',
              value: `${item?.city?.id}%`
            }
          }
        : {})
    } as unknown as FetchCitiesFilters,
    initialSort: {
      [CityFields.NAME]: { ascending: true }
    } as unknown as FetchCitiesSort
  });

  const defaultCurrentCityFromCityLabel = cityName
    ? find(
        cities,
        (city) =>
          city.label === compact([cityName, stateIsoCode]).join(', ') ||
          city.name === cityName
      )
    : item?.city?.id && !item?.city?.label
    ? find(cities, (city) => city.id === item.city?.id)
    : null;

  const defaultCurrentCityFromCityLabelValue = defaultCurrentCityFromCityLabel
    ? {
        value: defaultCurrentCityFromCityLabel.id,
        label: defaultCurrentCityFromCityLabel.label
      }
    : undefined;

  const defaultCurrentUserCity =
    item?.city?.id && item?.city?.label
      ? {
          value: item.city.id,
          label: item.city.label
        }
      : undefined;

  const debouncedFilterCities = debounce<(updatedValue: string) => void>(
    (updatedValue) =>
      changeCitiesFilters({
        [CityFields.LABEL]: {
          operator: 'ilike',
          value: `${updatedValue}%`
        }
      }),
    500
  );

  useEffect(() => {
    if (currentStateId) {
      filterCities({
        [CityFields.STATE_ID]: { operator: 'eq', value: currentStateId }
      });
    }
  }, [currentStateId, filterCities]);

  return (
    <SelectField
      isRequired={isRequired}
      control={control}
      label={showLabel ? label || 'Location' : undefined}
      placeholder={placeholder || 'Location'}
      isDisabled={
        !isUndefined(currentCountryId) &&
        !isUndefined(currentStateId) &&
        (!currentCountryId || !currentStateId)
      }
      name={name || ('cityId' as SelectFieldReactHookFormFieldPath<T>)}
      options={cities.map((city) => ({
        value: city.id,
        label: city.label
      }))}
      defaultValue={
        defaultCurrentCityFromCityLabelValue || defaultCurrentUserCity
      }
      errorMessage={errorMessage || citiesErrorMessage}
      isLoading={citiesIsLoading}
      onInputChange={debouncedFilterCities}
      reactSelectStyles={
        isBorderless
          ? {
              control: (base: SystemStyleObject) => ({
                ...base,
                borderColor: 'transparent',
                p: 0
              })
            }
          : reactSelectStyles
      }
    />
  );
}

export default SelectCityFormField;
