import React, { useCallback } from 'react';
import { GridItem, SimpleGrid, Stack } from '@chakra-ui/react';
import { FieldValues, Path } from 'react-hook-form';
import find from 'lodash/find';
import trim from 'lodash/trim';

import { ErrorMessage } from '../../../../../../../../../types';

import { UserEmailLabel } from '../../../../../../../../userEmails/userEmailsTypes';

import { RemoveIcon } from '../../../../../../../../../icons/RemoveIcon';

import { IconButton } from '../../../../../../../../../helpers/buttons/IconButton';
import { InputField } from '../../../../../../../../../helpers/forms/formFields/InputField';
import { SelectField } from '../../../../../../../../../helpers/forms/formFields/SelectField';
import { Text } from '../../../../../../../../../helpers/Text';

import { isEmail } from '../../../../../../../../../utils/isEmail';

import { EditUserFormAddEmailFieldControl } from './EditUserFormAddEmailField.types';

const emailValidationRules = {
  required: 'Email is required',
  validate: {
    isValid: (value: string) => isEmail(trim(value)) || 'Email is invalid'
  }
};

const labelValidationRules = {
  required: 'Designation is required'
};

const labelOptions = [
  { value: 'personal', label: 'Personal' },
  { value: 'work', label: 'Work Email' },
  { value: 'alt1', label: 'Email Alt1' },
  { value: 'alt2', label: 'Email Alt2' }
];

interface EditUserFormAddEmailFieldProps<T extends FieldValues> {
  control: EditUserFormAddEmailFieldControl<T>;
  name: string;
  index: number;
  withoutRemove?: boolean;
  error?: { [key: string]: { message?: ErrorMessage } };
  labelValue?: UserEmailLabel;
  onRemove: (index: number) => void;
  withoutRules?: boolean;
}

function EditUserFormAddEmailField<T extends FieldValues>({
  control,
  name,
  index,
  error,
  labelValue,
  withoutRemove,
  withoutRules,
  onRemove
}: EditUserFormAddEmailFieldProps<T>) {
  const handleRemove = useCallback<() => void>(
    () => onRemove(index),
    [index, onRemove]
  );

  return (
    <Stack spacing={0.5}>
      <SimpleGrid
        spacing={3}
        alignItems="center"
        columns={{ base: 1, lg: 3 }}
        gridTemplateColumns={'4fr 1fr max-content'}
      >
        <InputField
          id={`${name}.${index}.email`}
          {...control.register(
            `${name}.${index}.email` as Path<T>,
            withoutRules ? undefined : emailValidationRules
          )}
          isInvalid={!!error?.email?.message}
        />

        <SelectField
          options={labelOptions}
          control={control}
          rules={withoutRules ? undefined : labelValidationRules}
          name={`${name}.${index}.label` as Path<T>}
          defaultValue={find(labelOptions, { value: labelValue })}
          isInvalid={!!error?.label?.message}
        />

        <IconButton
          size="small"
          value={index}
          aria-label={''}
          hierarchy="link"
          onClick={handleRemove}
          cursor={withoutRemove ? 'not-allowed' : 'pointer'}
          icon={
            <RemoveIcon
              fill={withoutRemove ? 'red.200' : 'red.500'}
              w={5}
              h={5}
            />
          }
        />
      </SimpleGrid>
      <SimpleGrid
        spacing={3}
        alignItems="center"
        columns={{ base: 1, lg: 3 }}
        gridTemplateColumns={'4fr 1fr 32px'}
      >
        <Text
          color="red.500"
          textStyle="body1Medium"
          wordBreak="break-all"
          role="error"
        >
          {error?.email?.message}
        </Text>

        <GridItem colSpan={2}>
          <Text
            color="red.500"
            textStyle="body1Medium"
            wordBreak="break-all"
            role="error"
          >
            {error?.label?.message}
          </Text>
        </GridItem>
      </SimpleGrid>
    </Stack>
  );
}

export default EditUserFormAddEmailField;
