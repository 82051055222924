import React from 'react';
import { FieldValues } from 'react-hook-form';
import { Box, SystemStyleObject } from '@chakra-ui/react';
import { SelectField } from '../../../../../helpers/forms/formFields/SelectField';
import { useReactHookForm } from '../../../../../common/hooks/base/useReactHookForm';
import {
  defaultValues,
  SelectCompanyFormFieldProps
} from './OverAllEvaluationFormField.types';

function OverAllEvaluationFormField<T extends FieldValues>({
  i18nPlaceholder,
  options,
  id,
  name,
  isDisabled,
  placeholder,
  backgroundColor
}: SelectCompanyFormFieldProps<T>) {
  const { control } = useReactHookForm({ defaultValues: defaultValues });

  return (
    <Box mb={'20px'}>
      <SelectField
        reactSelectStyles={{
          container: () => ({
            backgroundColor: backgroundColor // Your desired background color
          })
        }}
        id={id}
        placeholder={placeholder}
        i18nPlaceholder={i18nPlaceholder}
        options={
          options &&
          options.map((evaluation) => ({
            value: evaluation.id,
            label: evaluation.name
          }))
        }
        control={control}
        name={name}
        isDisabled={isDisabled}
      />
    </Box>
  );
}

export default OverAllEvaluationFormField;
