import {
  CandidateSubmissionOverallEvaluation,
  CandidateSubmissionOverallEvaluationEnums
} from '../../candidateSubmissionsTypes';

function renderCandidateSubmissionOverallEvaluation(
  candidateSubmissionOverallEvaluation: CandidateSubmissionOverallEvaluation
) {
  switch (candidateSubmissionOverallEvaluation) {
    case CandidateSubmissionOverallEvaluationEnums.HireNow:
      return `Hire 'em now`;
    case CandidateSubmissionOverallEvaluationEnums.GreatFit:
      return `Great fit`;
    case CandidateSubmissionOverallEvaluationEnums.GoodFit:
      return `Good fit`;
    case CandidateSubmissionOverallEvaluationEnums.OkFit:
      return `Ok fit`;
    case CandidateSubmissionOverallEvaluationEnums.NotAFit:
      return `Not a fit`;
    default:
      return 'Unknown';
  }
}

export default renderCandidateSubmissionOverallEvaluation;
