import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  useDisclosure
} from '@chakra-ui/react';

import { I18nText } from '../../../../../types';

import { HtmlPageNanoId } from '../../../htmlPagesTypes';

import {
  fetchHtmlPageQuery,
  FetchHtmlPageResponse
} from '../../../queries/fetchHtmlPage.query';

import { useFetchHtmlPageByNanoId } from '../../../hooks/useFetchHtmlPageByNanoId';

import { HtmlPagesCache } from '../../../HtmlPagesCache';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { PureLinkHelper } from '../../../../../helpers/PureLinkHelper';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';

interface HtmlPageModalLinkProps {
  htmlPageNanoId: HtmlPageNanoId;
  i18nText: I18nText;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  defaultIsOpen?: boolean;
  closeOnEsc?: boolean;
  closeOnOverlayClick?: boolean;
}

function HtmlPageModalLink({
  htmlPageNanoId,
  i18nText,
  size = 'lg',
  defaultIsOpen = false,
  closeOnEsc = true,
  closeOnOverlayClick = true
}: HtmlPageModalLinkProps) {
  const { isOpen, onClose, onOpen } = useDisclosure({ defaultIsOpen });

  const { htmlPage, htmlPageIsFetched, htmlPageErrorMessage } =
    useFetchHtmlPageByNanoId<FetchHtmlPageResponse>({
      htmlPageNanoId,
      query: fetchHtmlPageQuery,
      cacheKey: HtmlPagesCache.showCacheKey()
    });

  return (
    <>
      <PureLinkHelper href="" i18nText={i18nText} onClick={onOpen} />
      <Modal
        size={size}
        isOpen={isOpen}
        onClose={onClose}
        closeOnEsc={closeOnEsc}
        closeOnOverlayClick={closeOnOverlayClick}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody p={8}>
            <Stack spacing={8}>
              <AlertMessage message={htmlPageErrorMessage} />
              <LoadingSkeleton loaded={htmlPageIsFetched}>
                {htmlPage?.body}
              </LoadingSkeleton>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default HtmlPageModalLink;
