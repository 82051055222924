import {
  MayBe,
  FieldBrand,
  ItemID,
  ItemUUID,
  ItemNanoId,
  ItemCreatedAt,
  ItemsCount
} from '../../../types';

import {
  CompanyId,
  CompanyName,
  CompanyNanoId
} from '../../companies/companiesTypes';

import {
  UserId,
  UserNanoId,
  UserName,
  UserImage
} from '../../users/usersTypes';

export type DepartmentId = FieldBrand<ItemID, 'DepartmentId'>;
export type DepartmentUuid = FieldBrand<ItemUUID, 'DepartmentUuid'>;
export type MayBeDepartmentUuid = MayBe<DepartmentUuid>;
export type DepartmentNanoId = FieldBrand<ItemNanoId, 'DepartmentNanoId'>;
export type MayBeDepartmentNanoId = MayBe<DepartmentNanoId>;
export type DepartmentName = FieldBrand<string, 'DepartmentName'>;
export type DepartmentUserId = FieldBrand<UserId, 'DepartmentUserId'>;
export type DepartmentUser = FieldBrand<
  {
    id: UserId;
    nanoId: UserNanoId;
    name: UserName;
    image: UserImage;
  },
  'DepartmentUser'
>;
export type DepartmentCompanyId = FieldBrand<CompanyId, 'DepartmentCompanyId'>;
export type DepartmentCompany = FieldBrand<
  {
    id: CompanyId;
    nanoId: CompanyNanoId;
    name: CompanyName;
  },
  'DepartmentCompany'
>;
export type DepartmentCreatedAt = FieldBrand<
  ItemCreatedAt,
  'DepartmentCreatedAt'
>;
export type DepartmentJobsCount = FieldBrand<ItemsCount, 'DepartmentJobsCount'>;

export const enum DepartmentFields {
  ID = 'id',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  NAME = 'name',
  USER_ID = 'userId',
  USER = 'user',
  CREATED_AT = 'createdAt',
  COMPANY_ID = 'companyId',
  COMPANY = 'company',
  JOBS_COUNT = 'jobsCount'
}
