import { CircularProgress, Flex } from '@chakra-ui/react';
import { ReactNode } from 'react';

const FullPageLoader = ({ loader }: { loader?: ReactNode }) => {
  return (
    <Flex
      top={0}
      w="100%"
      h="100%"
      flex={1}
      left={0}
      zIndex={999}
      bg="whiteAlpha.900"
      position="absolute"
      alignItems="center"
      justifyContent="center"
    >
      {loader ? (
        loader
      ) : (
        <CircularProgress size={75} thickness="3px" isIndeterminate />
      )}
    </Flex>
  );
};

export default FullPageLoader;
