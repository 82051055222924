import { Icon, IconProps } from '@chakra-ui/icon';

const FoldersButtonIcon = ({ w = '40px', h = '40px', ...props }: IconProps) => (
  <Icon viewBox="0 0 40 40" w={w} h={h} {...props}>
    <circle cx={20} cy={20} r={20} fill="#6872E5" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 11.75v1.5h5.625c.473 0 .916.222 1.2.6l1.8 2.4H27.5v-4.5a.75.75 0 0 0-.75-.75h-13.5a.75.75 0 0 0-.75.75Z"
      fill="#F8FAFB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.25 17.75h-7.875l-2.025-2.7a.75.75 0 0 0-.6-.3h-6a.75.75 0 0 0-.75.75v12.75c0 .414.335.75.75.75h16.5a.75.75 0 0 0 .75-.75V18.5a.75.75 0 0 0-.75-.75Z"
      fill="#F8FAFB"
    />
  </Icon>
);

export default FoldersButtonIcon;
