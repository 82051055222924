import find from 'lodash/find';
import first from 'lodash/first';
import toString from 'lodash/toString';
import { Fragment, useCallback, useEffect } from 'react';

import {
  FetchSkillsCacheKey,
  FetchSkillsEnabled,
  FetchSkillsFilters,
  FetchSkillsPageSize,
  FetchSkillsSort,
  SkillFields,
  SkillId,
  SkillKind,
  SkillKinds,
  SkillNanoId,
  SkillUserId
} from '../../../skillsTypes';

import { FetchSkillBadgesResponse } from '../../../../skillBadges/queries/fetchSkillBadges.query';
import {
  fetchSkillsQuery,
  FetchSkillsResponse
} from '../../../queries/fetchSkills.query';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';
import { useCreateSkill } from '../../../hooks/useCreateSkill';
import { usePaginatedSkills } from '../../../hooks/usePaginatedSkills';

import { SkillsCache } from '../../../SkillsCache';

import {
  OnNewSkillDropdownSelect,
  OnSkillDropdownSelect,
  OnSkillInputChange,
  SkillDropdown
} from '../../../helpers/SkillDropdown';

import { AlertMessage } from '../../../../../helpers/AlertMessage';

import { generateNanoId } from '../../../../../utils/generateNanoId';
import { IsLoading } from '../../../../../types';

interface AddSkillDropdownWrapperProps {
  skillKind: SkillKinds;
  skillIds: SkillId[];
  skillBadges: FetchSkillBadgesResponse[];
  skillsCacheKey: FetchSkillsCacheKey;
  onSelectExistingSkill: (skill: FetchSkillsResponse) => void;
  onSelectExistingSkillBadge: (skillBadge: FetchSkillBadgesResponse) => void;
  onSelectSkill: OnSkillDropdownSelect;
  selectSkillIsLoading?: IsLoading;
}

function AddSkillDropdownWrapper({
  skillKind,
  skillIds,
  skillsCacheKey,
  skillBadges,
  onSelectExistingSkill,
  onSelectExistingSkillBadge,
  onSelectSkill,
  selectSkillIsLoading
}: AddSkillDropdownWrapperProps) {
  const currentUser = useCurrentUser();

  const skillIdsFilter = skillIds.join(',');

  const { createSkillErrorMessage, createSkillIsLoading, createSkill } =
    useCreateSkill({
      cacheKeys: [skillsCacheKey]
    });

  const { skills, skillsIsLoading, skillsErrorMessage, changeSkillsFilters } =
    usePaginatedSkills<FetchSkillsResponse>({
      query: fetchSkillsQuery,
      cacheKey: skillsCacheKey,
      initialFilters: {
        [SkillFields.ID]: {
          operator: 'not.in',
          value: skillIdsFilter
        },
        [SkillFields.KIND]: { operator: 'eq', value: skillKind },
        [SkillFields.SUGGESTED]: { operator: 'eq', value: false }
      } as unknown as FetchSkillsFilters,
      initialSort: {
        [SkillFields.CREATED_AT]: { ascending: false }
      } as unknown as FetchSkillsSort
    });

  const {
    skillsIsLoading: existingSkillsIsLoading,
    skillsErrorMessage: existingSkillsErrorMessage,
    fetchSkills
  } = usePaginatedSkills<FetchSkillsResponse>({
    query: fetchSkillsQuery,
    cacheKey: SkillsCache.existsCacheKey(),
    enabled: false as FetchSkillsEnabled,
    initialPageSize: 10 as FetchSkillsPageSize,
    initialFilters: {
      [SkillFields.KIND]: { operator: 'eq', value: skillKind }
    } as unknown as FetchSkillsFilters
  });

  const handleNewSkill = useCallback<OnNewSkillDropdownSelect>(
    async (skillName) => {
      const { data: existingSkills } = await fetchSkills({
        nextFilters: {
          [SkillFields.NAME]: { operator: 'eq', value: skillName },
          [SkillFields.KIND]: {
            operator: 'eq',
            value: toString(skillKind)
          }
        }
      });

      const existingSkill = first<FetchSkillsResponse>(existingSkills);

      if (existingSkill) {
        const existingSkillBadge = find(
          skillBadges,
          (skillBadge) => skillBadge.skill?.nanoId === existingSkill.nanoId
        );

        existingSkillBadge
          ? onSelectExistingSkillBadge(existingSkillBadge)
          : onSelectExistingSkill(existingSkill);
        return;
      }

      const skill = await createSkill({
        name: skillName,
        kind: skillKind as SkillKind,
        userId: currentUser.id as SkillUserId,
        nanoId: generateNanoId<SkillNanoId>()
      });

      if (skill?.id && skill?.name) {
        onSelectSkill({
          id: skill.id,
          name: skill.name
        });
      }
    },
    [
      currentUser.id,
      skillKind,
      skillBadges,
      fetchSkills,
      createSkill,
      onSelectExistingSkill,
      onSelectExistingSkillBadge,
      onSelectSkill
    ]
  );

  const handleSkillInputChange = useCallback<OnSkillInputChange>(
    (skillName) =>
      changeSkillsFilters({
        [SkillFields.NAME]: { operator: 'ilike', value: `${skillName}%` }
      }),
    [changeSkillsFilters]
  );

  useEffect(() => {
    changeSkillsFilters({
      [SkillFields.ID]: {
        operator: 'not.in',
        value: skillIdsFilter
      }
    });
  }, [skillIdsFilter, changeSkillsFilters]);

  return (
    <Fragment>
      {/* Comment 1. ADD SKILL DROPDOWN LIST COMPONENT */}
      <SkillDropdown
        options={skills.map((skill) => ({
          value: skill.id,
          label: skill.name
        }))}
        disabled={
          skillsIsLoading || createSkillIsLoading || existingSkillsIsLoading
        }
        isLoading={
          selectSkillIsLoading ||
          skillsIsLoading ||
          createSkillIsLoading ||
          existingSkillsIsLoading
        }
        onSkillInputChange={handleSkillInputChange}
        onSelectSkill={onSelectSkill}
        onNewSkill={handleNewSkill}
      />
      <AlertMessage
        message={
          skillsErrorMessage ||
          createSkillErrorMessage ||
          existingSkillsErrorMessage
        }
      />
    </Fragment>
  );
}

export default AddSkillDropdownWrapper;
