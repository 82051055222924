import { ControlPanelRoutes } from '../../ControlPanelRoutes';

const basePeoplePath = ControlPanelRoutes.people();

export class ControlPanelPeopleRoutes {
  static index() {
    return basePeoplePath;
  }

  static recruiters() {
    return `${basePeoplePath}/recruiters`;
  }

  static employees() {
    return `${basePeoplePath}/employees`;
  }

  static candidates() {
    return `${basePeoplePath}/candidates`;
  }

  static other() {
    return `${basePeoplePath}/other/users`;
  }
}
