import {
  FetchDepartmentsQuery,
  FetchDepartmentsCountType,
  FetchDepartmentsPage,
  FetchDepartmentsPageSize,
  FetchDepartmentsFilters,
  FetchDepartmentsSort,
  FetchDepartmentsIndexQueryName,
  FetchDepartmentsIndexCountType,
  FetchDepartmentsIndexPage,
  FetchDepartmentsIndexPageSize,
  FetchDepartmentsIndexFilters,
  FetchDepartmentsIndexSort,
  FetchDepartmentQuery,
  FetchDepartmentIndexQueryName,
  CreateDepartmentError,
  UpdateDepartmentError,
  DeleteDepartmentError,
  DepartmentId,
  DepartmentNanoId,
  DepartmentName,
  DepartmentUserId,
  ReplaceDepartmentError,
  DepartmentCompanyId
} from './departmentsTypes';

import { BaseBffRequests } from '../BaseBffRequests';

import { DepartmentsBffRoutes } from './DepartmentsBffRoutes';

interface FetchDepartmentsRequestParams {
  query: FetchDepartmentsQuery;
  countType?: FetchDepartmentsCountType;
  page?: FetchDepartmentsPage;
  pageSize?: FetchDepartmentsPageSize;
  filters?: FetchDepartmentsFilters;
  sort?: FetchDepartmentsSort;
}

interface FetchDepartmentsIndexRequestParams {
  queryName: FetchDepartmentsIndexQueryName;
  countType?: FetchDepartmentsIndexCountType;
  page?: FetchDepartmentsIndexPage;
  pageSize?: FetchDepartmentsIndexPageSize;
  filters?: FetchDepartmentsIndexFilters;
  sort?: FetchDepartmentsIndexSort;
}

interface FetchDepartmentRequestParams {
  query: FetchDepartmentQuery;
}

interface FetchDepartmentIndexRequestParams {
  queryName: FetchDepartmentIndexQueryName;
}

export interface DepartmentRequestData {
  name?: DepartmentName;
}

export interface CreateDepartmentRequestData extends DepartmentRequestData {
  id?: DepartmentId;
  userId: DepartmentUserId;
  nanoId: DepartmentNanoId;
  companyId: DepartmentCompanyId;
}

export interface UpdateDepartmentRequestData extends DepartmentRequestData {
  name?: DepartmentName;
}

export interface DeleteDepartmentRequestData {
  departmentId: DepartmentId;
}

export interface ReplaceDepartmentRequestData {
  departmentId: DepartmentId;
}

export interface CreateDepartmentErrorResponse {
  message: CreateDepartmentError;
}

export interface UpdateDepartmentErrorResponse {
  message: UpdateDepartmentError;
}

export interface DeleteDepartmentErrorResponse {
  message: DeleteDepartmentError;
}

export interface ReplaceDepartmentErrorResponse {
  message: ReplaceDepartmentError;
}

export class DepartmentsBffRequests extends BaseBffRequests {
  static fetchDepartments<T>(params: FetchDepartmentsRequestParams) {
    return this.fetchItems<T>(
      DepartmentsBffRoutes.fetchDepartmentsRoute(),
      params
    );
  }

  static fetchDepartmentsIndex<T>(params: FetchDepartmentsIndexRequestParams) {
    return this.fetchItemsIndex<T>(
      DepartmentsBffRoutes.fetchDepartmentsIndexRoute(),
      params
    );
  }

  static fetchDepartment<T>(
    departmentId: DepartmentId,
    params: FetchDepartmentRequestParams
  ) {
    return this.fetchItem<T>(
      DepartmentsBffRoutes.fetchDepartmentRoute(departmentId),
      params
    );
  }

  static fetchDepartmentIndex<T>(
    departmentNanoId: DepartmentNanoId,
    params: FetchDepartmentIndexRequestParams
  ) {
    return this.fetchItemIndex<T>(
      DepartmentsBffRoutes.fetchDepartmentIndexRoute(departmentNanoId),
      params
    );
  }

  static fetchDepartmentByNanoId<T>(
    departmentNanoId: DepartmentNanoId,
    params: FetchDepartmentRequestParams
  ) {
    return this.fetchItemByNanoId<T>(
      DepartmentsBffRoutes.fetchDepartmentByNanoIdRoute(departmentNanoId),
      params
    );
  }

  static createDepartment<T>(
    createDepartmentData: CreateDepartmentRequestData
  ) {
    return this.createItem<T, CreateDepartmentRequestData>(
      DepartmentsBffRoutes.createDepartmentRoute(),
      createDepartmentData
    );
  }

  static async updateDepartment<T>(
    updateDepartmentId: DepartmentId,
    updateDepartmentData: UpdateDepartmentRequestData
  ) {
    return this.updateItem<T, UpdateDepartmentRequestData>(
      DepartmentsBffRoutes.updateDepartmentRoute(updateDepartmentId),
      updateDepartmentData
    );
  }

  static async deleteDepartment<T>(deleteDepartmentId: DepartmentId) {
    return this.deleteItem<T>(
      DepartmentsBffRoutes.deleteDepartmentRoute(deleteDepartmentId)
    );
  }

  static async replaceDepartment<T>(
    replaceDepartmentId: DepartmentId,
    replaceDepartmentData: ReplaceDepartmentRequestData
  ) {
    return this.postItem<T, ReplaceDepartmentRequestData>(
      DepartmentsBffRoutes.replaceDepartmentRoute(replaceDepartmentId),
      replaceDepartmentData
    );
  }
}
