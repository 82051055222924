import { Flex } from '@chakra-ui/react';
import { CalendarIcon } from '../../../../icons/CalendarIcon';
import { ClockIcon } from '../../../../icons/ClockIcon';
import { DateTimeFormats, DateUtils } from '../../../../utils/DateUtils';
import { Text } from '../../../Text';
import { ScheduleHelperDuration } from '../../ScheduleHelper.types';

interface ScheduleHelperSelectedTimeSlotsProps {
  duration: ScheduleHelperDuration;
  selectedSlots: string[];
}

export default function ScheduleHelperSelectedTimeSlots({
  duration,
  selectedSlots
}: ScheduleHelperSelectedTimeSlotsProps) {
  const formatDateRange = (range: string) => {
    const [start, end] = range.split('/');

    const formattedDate = DateUtils.formatDate(
      start,
      DateTimeFormats.CCCC_MMM_DD
    );

    const formattedStartTime = DateUtils.formatDate(
      start,
      DateTimeFormats.HH_MM_A
    );

    const formattedEndTime = DateUtils.formatDate(end, DateTimeFormats.HH_MM_A);

    return `${formattedDate} (${formattedStartTime} - ${formattedEndTime})`;
  };

  const sortedSlots: string[] = selectedSlots.sort((a, b) => {
    const dateA = new Date(a.split('/')[0]);
    const dateB = new Date(b.split('/')[0]);
    return dateA.getTime() - dateB.getTime();
  });

  return (
    <Flex flexDir="column" gap={2} color="gray.700">
      <DurationText duration={duration} />

      <Flex gap={3}>
        <Flex h={6} alignItems="center">
          <CalendarIcon w={4} h={4} />
        </Flex>

        <Flex flexDir="column" gap={0.5}>
          {selectedSlots.length === 0 ? (
            <Text>--</Text>
          ) : (
            <>
              {sortedSlots.map((range, index) => (
                <Text key={index}>{formatDateRange(range)}</Text>
              ))}
            </>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}

interface DurationTextProps {
  duration: ScheduleHelperDuration;
}

const DurationText = ({ duration }: DurationTextProps) => {
  const { hour, minute } = duration;
  const hoursText = hour ? `${hour} hour${hour > 1 ? 's' : ''}` : '';
  const minutesText = minute ? `${minute} minute${minute > 1 ? 's' : ''}` : '';

  const combinedText = [hoursText, minutesText]
    .filter(Boolean)
    .join(' ')
    .trim();

  return (
    <Flex gap={3} alignItems="center">
      <ClockIcon w={4} h={4} />
      <Text>{combinedText}</Text>
    </Flex>
  );
};
