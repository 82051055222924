import { JobCandidateNanoId } from '../../../../../jobCandidates/jobCandidatesTypes';
import { JobNanoId } from '../../../../jobsTypes';
import { ViewJobCandidateDetailsRoutes } from '../../ViewJobCandidateDetailsRoutes';

const basePath = (
  jobNanoId: JobNanoId,
  jobCandidateNanoId: JobCandidateNanoId
) => ViewJobCandidateDetailsRoutes.activities(jobNanoId, jobCandidateNanoId);

export class ViewJobCandidateDetailsActivitiesRoutes {
  static index(jobNanoId: JobNanoId, jobCandidateNanoId: JobCandidateNanoId) {
    return `${basePath(jobNanoId, jobCandidateNanoId)}`;
  }

  static all(jobNanoId: JobNanoId, jobCandidateNanoId: JobCandidateNanoId) {
    return `${basePath(jobNanoId, jobCandidateNanoId)}/all-activities`;
  }

  static phoneCalls(
    jobNanoId: JobNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${basePath(jobNanoId, jobCandidateNanoId)}/phone-calls`;
  }

  static callConnections(
    jobNanoId: JobNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${basePath(jobNanoId, jobCandidateNanoId)}/call-connections`;
  }

  static emails(jobNanoId: JobNanoId, jobCandidateNanoId: JobCandidateNanoId) {
    return `${basePath(jobNanoId, jobCandidateNanoId)}/emails`;
  }

  static meetings(
    jobNanoId: JobNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${basePath(jobNanoId, jobCandidateNanoId)}/meetings`;
  }

  static stageChanges(
    jobNanoId: JobNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${basePath(jobNanoId, jobCandidateNanoId)}/stage-changes`;
  }

  static comments(
    jobNanoId: JobNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${basePath(jobNanoId, jobCandidateNanoId)}/comments`;
  }

  static tasks(jobNanoId: JobNanoId, jobCandidateNanoId: JobCandidateNanoId) {
    return `${basePath(jobNanoId, jobCandidateNanoId)}/tasks`;
  }
}
