import { Box, Flex, Text } from '@chakra-ui/layout';
import { FunctionComponent, ReactNode, memo, useCallback } from 'react';
import { Collapse, Stack, useDisclosure } from '@chakra-ui/react';

import { useLeftSidebarContext } from '../../context';

import { useAuth } from '../../auth/hooks/useAuth';

import { NextLinkHelper } from '../NextLinkHelper';

import { SKIP_PERMISSIONS_CHECKS } from '../../config';

import { PermissionAction } from '../../main/permissions/permissionsTypes';
import { LoadingSkeleton } from '../LoadingSkeleton';

interface SidebarMenuItemProps {
  url: string;
  title: string;
  isActive?: boolean;
  isChildActive?: boolean;
  icon?: FunctionComponent;
  onMouseOver?: (url: string) => void;
  children?: ReactNode;
  action?: string;
}

function SidebarMenuItem({
  url,
  icon,
  title,
  isActive,
  children,
  onMouseOver,
  isChildActive,
  action
}: SidebarMenuItemProps) {
  const { authFetched, currentUser } = useAuth();
  const { colorScheme, isDocked } = useLeftSidebarContext();

  const handleMouseOver = useCallback<() => void>(
    () => onMouseOver?.(url),
    [url, onMouseOver]
  );

  const {
    isOpen: isOpenWrapper,
    onOpen: onOpenWrapper,
    onClose: onCloseWrapper
  } = useDisclosure();
  const {
    isOpen: isOpenMenu,
    onOpen: onOpenMenu,
    onClose: onCloseMenu
  } = useDisclosure();

  return (
    <LoadingSkeleton loaded={authFetched}>
      {SKIP_PERMISSIONS_CHECKS ||
      !action ||
      (action && currentUser.can(action as PermissionAction)) ? (
        <Stack
          w="100%"
          spacing={0}
          onMouseEnter={isActive || isChildActive ? undefined : onOpenWrapper}
          onMouseLeave={isActive || isChildActive ? undefined : onCloseWrapper}
        >
          <NextLinkHelper href={url} passHref>
            <Flex
              py={4}
              px={6}
              as={'a'}
              flex={1}
              borderRadius={4}
              textStyle="body1Regular"
              textDecoration="none"
              onMouseOver={handleMouseOver}
              onMouseEnter={onOpenMenu}
              onMouseLeave={onCloseMenu}
              color={
                isActive
                  ? colorScheme === 'light'
                    ? 'primary.600'
                    : 'white'
                  : colorScheme === 'light'
                  ? 'gray.600'
                  : 'whiteAlpha.700'
              }
              bg={
                isActive
                  ? colorScheme === 'light'
                    ? 'primary.100'
                    : 'whiteAlpha.300'
                  : ''
              }
              _hover={{
                textDecoration: 'none',
                bg: `${
                  isActive
                    ? colorScheme === 'light'
                      ? 'primary.100'
                      : 'whiteAlpha.300'
                    : colorScheme === 'light'
                    ? 'gray.100'
                    : 'whiteAlpha.100'
                }`,
                color: `${
                  isActive
                    ? colorScheme === 'light'
                      ? 'primary.600'
                      : 'white'
                    : colorScheme === 'light'
                    ? 'gray.600'
                    : 'white'
                }`
              }}
            >
              <Flex
                as={icon}
                mr={3}
                transitionProperty="fill"
                transitionDuration="slow"
                fill={
                  isActive
                    ? colorScheme === 'light'
                      ? 'primary.500'
                      : 'white'
                    : colorScheme === 'light'
                    ? 'gray.500'
                    : 'whiteAlpha.600'
                }
                color={
                  isActive
                    ? colorScheme === 'light'
                      ? 'primary.500'
                      : 'white'
                    : colorScheme === 'light'
                    ? 'gray.500'
                    : 'whiteAlpha.600'
                }
              />

              {!isDocked ? (
                <Box as="span">{title}</Box>
              ) : (
                <Flex
                  pos="absolute"
                  bg={colorScheme === 'dark' ? '#2C273D' : 'gray.100'}
                  mt={-2}
                  borderRadius={4}
                  ml="3.75rem"
                  overflow="hidden"
                  maxWidth={isOpenMenu ? '250px' : 0}
                >
                  <Text px={3} py={2} whiteSpace="nowrap">
                    {title}
                  </Text>
                </Flex>
              )}
            </Flex>
          </NextLinkHelper>

          <Collapse in={isOpenWrapper || isActive || isChildActive}>
            {children}
          </Collapse>
        </Stack>
      ) : (
        <></>
      )}
    </LoadingSkeleton>
  );
}

export default memo<SidebarMenuItemProps>(SidebarMenuItem);
