import { useCallback } from 'react';
import { FormControl, FormLabel, Stack } from '@chakra-ui/react';
import { Control, useFieldArray } from 'react-hook-form';
import size from 'lodash/size';
import find from 'lodash/find';

import {
  CertificationName,
  CertificationCredentialsId
} from '../../../../../../../../certifications/certificationsTypes';

import { PlusIcon } from '../../../../../../../../../icons/PlusIcon';

import { Button } from '../../../../../../../../../helpers/Button';

import { EditUserFormAddCertificationField } from '../EditUserFormAddCertificationField';

import { AddEditUserFormData } from '../../../../AddEditUserForm.types';
import {
  UserCertifications,
  UserCertification
} from '../../../../../../../usersTypes';

interface EditUserFormAddCertificationFieldsProps {
  control: Control<AddEditUserFormData>;
  label: string;
  user: {
    profileCertifications: UserCertifications;
  };
  errorMessages: { [key: string]: { message?: string } }[];
}

function EditUserFormAddCertificationFields({
  label,
  control,
  user,
  errorMessages
}: EditUserFormAddCertificationFieldsProps) {
  const { fields, append, remove } = useFieldArray<
    AddEditUserFormData,
    'profileCertifications'
  >({
    control,
    name: 'profileCertifications'
  });

  const handleAddNew = useCallback<() => void>(
    () =>
      append(
        {
          name: '' as CertificationName,
          companyId: null,
          credentialsId: '' as CertificationCredentialsId,
          issueDate: null
        },
        {
          shouldFocus: false
        }
      ),
    [append]
  );

  const handleRemoveItem = useCallback<(index: number) => void>(
    (index) => {
      if (fields.length > 1) {
        remove(index);
      }
    },
    [fields.length, remove]
  );

  // if (fields.length === 0) {
  //   handleAddNew();
  // }

  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>

      <Stack spacing={3}>
        {fields.map((field, index) => (
          <EditUserFormAddCertificationField
            key={field.id}
            control={control}
            name="profileCertifications"
            certification={
              find(
                user.profileCertifications,
                (emp, i) => i === index
              ) as UserCertification
            }
            index={index}
            error={errorMessages?.[index]}
            onRemove={handleRemoveItem}
            withoutRemove={size(fields) === 1}
          />
        ))}
      </Stack>

      <Button
        mt={3}
        size="small"
        hierarchy="link"
        onClick={handleAddNew}
        leftIcon={<PlusIcon color="primary.500" w={4} h={4} mr={-1} />}
      >
        Add certification
      </Button>
    </FormControl>
  );
}

export default EditUserFormAddCertificationFields;
