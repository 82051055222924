import { Avatar, Flex, HStack } from '@chakra-ui/react';
import some from 'lodash/some';
import { useRouter } from 'next/router';
import { Text } from '../../../../../../helpers/Text';
import { CandidatesIcon } from '../../../../../../icons/CandidatesIcon';
import { CareerSiteIcon } from '../../../../../../icons/CareerSiteIcon';
import { ChevronDownIcon } from '../../../../../../icons/ChevronDownIcon';
import { EditIcon } from '../../../../../../icons/EditIcon';
import { EyeIcon } from '../../../../../../icons/EyeIcon';
import { FilesIcon } from '../../../../../../icons/FilesIcon';
import { RecruitersIcon } from '../../../../../../icons/RecruitersIcon';
import { TaskIcon } from '../../../../../../icons/TaskIcon';
import { HiringPortalJobCandidateDetailsRoutes } from '../../../../../hiringPortal/HiringPortalJobCandidateDetailsRoutes';
import { JobCandidateNanoId } from '../../../../../jobCandidates/jobCandidatesTypes';
import { SourcingJobCandidateDetailsRoutes } from '../../../../../sourcing/SourcingJobCandidateDetailsRoutes';
import { JobNanoId } from '../../../../jobsTypes';
import { useViewJobCandidateDetailsSubmissionContext } from '../../contexts/ViewJobCandidateDetailsSubmissionContext';
import { ViewJobCandidateDetailsRoutes } from '../../ViewJobCandidateDetailsRoutes';
import { ViewJobCandidateDetailsPageNavItem } from '../ViewJobCandidateDetailsPageNavItem';
import {
  CandidateSubmissionShareSettingsField,
  CandidateSubmissionShareSettingsFields
} from '../../../../../candidateSubmissions/candidateSubmissionsTypes';

interface ViewJobCandidateDetailsPageNavProps {
  jobNanoId: JobNanoId;
  jobCandidateNanoId: JobCandidateNanoId;
  prospect?: boolean;
  isBasePath?: boolean;
  isHiringPortalPath: boolean;
}

interface EditRoute {
  edit: (
    jobNanoId: JobNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) => string;
}

export default function ViewJobCandidateDetailsPageNav({
  jobNanoId,
  jobCandidateNanoId,
  prospect,
  isBasePath,
  isHiringPortalPath
}: ViewJobCandidateDetailsPageNavProps) {
  const router = useRouter();

  const isEditing = router.pathname.includes('edit');

  const hiringPortalRoutes =
    isHiringPortalPath && !isEditing
      ? HiringPortalJobCandidateDetailsRoutes
      : null;

  const prospectRoutes = prospect ? SourcingJobCandidateDetailsRoutes : null;

  const route =
    hiringPortalRoutes || prospectRoutes || ViewJobCandidateDetailsRoutes;

  const { shareSettings } = useViewJobCandidateDetailsSubmissionContext();

  const assessmentFields = [
    CandidateSubmissionShareSettingsFields.AssessmentPreferences,
    CandidateSubmissionShareSettingsFields.AssessmentQualifications,
    CandidateSubmissionShareSettingsFields.AssessmentSkillsExpertise,
    CandidateSubmissionShareSettingsFields.AssessmentQuestions,
    CandidateSubmissionShareSettingsFields.AssessmentProjects,
    CandidateSubmissionShareSettingsFields.AssessmentReferences,
    CandidateSubmissionShareSettingsFields.AssessmentUpdatedResume
  ];

  const hasAsessmentField = some(shareSettings, (field) =>
    assessmentFields.includes(field)
  );

  return (
    <HStack
      h={16}
      py={3}
      top={14}
      zIndex={9}
      spacing={2}
      overflow="auto"
      pos="sticky"
      bg="gray.50"
      px={0}
    >
      <ViewJobCandidateDetailsPageNavItem
        label="Profile"
        icon={RecruitersIcon}
        isActive={isBasePath || router.pathname.includes('profile')}
        url={`${route.profile(
          jobNanoId as JobNanoId,
          jobCandidateNanoId as JobCandidateNanoId
        )}`}
      />

      {isEditing ? (
        <ViewJobCandidateDetailsPageNavItem
          icon={EditIcon}
          label="Edit"
          isActive={router.pathname.includes('edit')}
          url={`${(route as unknown as EditRoute).edit(
            jobNanoId as JobNanoId,
            jobCandidateNanoId as JobCandidateNanoId
          )}`}
        />
      ) : null}

      {shareSettings.includes(
        CandidateSubmissionShareSettingsFields.Files as CandidateSubmissionShareSettingsField
      ) && (
        <ViewJobCandidateDetailsPageNavItem
          icon={FilesIcon}
          label="Files"
          isActive={router.pathname.includes('files')}
          url={`${route.files(
            jobNanoId as JobNanoId,
            jobCandidateNanoId as JobCandidateNanoId
          )}`}
        />
      )}

      {hasAsessmentField && (
        <ViewJobCandidateDetailsPageNavItem
          icon={EyeIcon}
          label="Assessment"
          isActive={router.pathname.includes('assessment')}
          url={`${route.assessment(
            jobNanoId as JobNanoId,
            jobCandidateNanoId as JobCandidateNanoId
          )}`}
        />
      )}

      {shareSettings.includes(
        CandidateSubmissionShareSettingsFields.AssessmentProjects as CandidateSubmissionShareSettingsField
      ) && (
        <ViewJobCandidateDetailsPageNavItem
          icon={TaskIcon}
          label="Projects"
          isActive={router.pathname.includes('projects')}
          url={`${route.projects(
            jobNanoId as JobNanoId,
            jobCandidateNanoId as JobCandidateNanoId
          )}`}
        />
      )}

      {shareSettings.includes(
        CandidateSubmissionShareSettingsFields.AssessmentReferences as CandidateSubmissionShareSettingsField
      ) && (
        <ViewJobCandidateDetailsPageNavItem
          icon={CandidatesIcon}
          label="References"
          isActive={router.pathname.includes('references')}
          url={`${route.references(
            jobNanoId as JobNanoId,
            jobCandidateNanoId as JobCandidateNanoId
          )}`}
        />
      )}

      {shareSettings.includes(
        CandidateSubmissionShareSettingsFields.ScoreCards as CandidateSubmissionShareSettingsField
      ) && (
        <ViewJobCandidateDetailsPageNavItem
          icon={CareerSiteIcon}
          label="Score cards"
          isActive={router.pathname.includes('score-cards')}
          url={`${route.scoreCards(
            jobNanoId as JobNanoId,
            jobCandidateNanoId as JobCandidateNanoId
          )}`}
        />
      )}

      <Flex flex={1}>
        <Flex ml="auto" alignItems="center" gap={2}>
          <Text textStyle="body1Medium" color="gray.600">
            Owner
          </Text>

          <Flex
            px={2}
            py={1.5}
            gap={2}
            bg="white"
            cursor="pointer"
            alignItems="center"
            border="1px solid"
            borderColor="gray.300"
            borderRadius="base"
          >
            <Avatar size="xs" src="/images/team-mate-2.png" />
            <Text textStyle="body1Medium" noOfLines={1}>
              Anna Smith
            </Text>

            <ChevronDownIcon w={4} h={4} />
          </Flex>
        </Flex>
      </Flex>
    </HStack>
  );
}
