import {
  JobCandidatesCompletedWorkflowStageTaskItemBffID,
  FetchJobCandidatesCompletedWorkflowStageTasksBffURL,
  FetchJobCandidatesCompletedWorkflowStageTaskBffURL,
  CreateJobCandidatesCompletedWorkflowStageTaskBffURL,
  UpdateJobCandidatesCompletedWorkflowStageTaskBffURL,
  DeleteJobCandidatesCompletedWorkflowStageTaskBffURL,
  JobCandidatesCompletedWorkflowStageTaskItemBffUuid
} from './jobCandidatesCompletedWorkflowStageTasksTypes';

import { BaseBffRoutes } from '../BaseBffRoutes';

export class JobCandidatesCompletedWorkflowStageTasksBffRoutes extends BaseBffRoutes {
  protected static route = 'jobCandidatesCompletedWorkflowStageTasks';

  static fetchJobCandidatesCompletedWorkflowStageTasksRoute() {
    return this.fetchItemsRoute<FetchJobCandidatesCompletedWorkflowStageTasksBffURL>();
  }

  static fetchJobCandidatesCompletedWorkflowStageTasksIndexRoute() {
    return this.fetchItemsIndexRoute<FetchJobCandidatesCompletedWorkflowStageTasksBffURL>();
  }

  static fetchJobCandidatesCompletedWorkflowStageTaskRoute(
    jobCandidatesCompletedWorkflowStageTaskItemBffID: JobCandidatesCompletedWorkflowStageTaskItemBffID
  ) {
    return this.fetchItemRoute<FetchJobCandidatesCompletedWorkflowStageTaskBffURL>(
      jobCandidatesCompletedWorkflowStageTaskItemBffID
    );
  }

  static fetchJobCandidatesCompletedWorkflowStageTaskIndexRoute(
    jobCandidatesCompletedWorkflowStageTaskItemBffUuid: JobCandidatesCompletedWorkflowStageTaskItemBffUuid
  ) {
    return this.fetchItemIndexRoute<FetchJobCandidatesCompletedWorkflowStageTaskBffURL>(
      jobCandidatesCompletedWorkflowStageTaskItemBffUuid
    );
  }

  static createJobCandidatesCompletedWorkflowStageTaskRoute() {
    return this.createItemRoute<CreateJobCandidatesCompletedWorkflowStageTaskBffURL>();
  }

  static updateJobCandidatesCompletedWorkflowStageTaskRoute(
    jobCandidatesCompletedWorkflowStageTaskItemBffID: JobCandidatesCompletedWorkflowStageTaskItemBffID
  ) {
    return this.updateItemRoute<UpdateJobCandidatesCompletedWorkflowStageTaskBffURL>(
      jobCandidatesCompletedWorkflowStageTaskItemBffID
    );
  }

  static deleteJobCandidatesCompletedWorkflowStageTaskRoute(
    jobCandidatesCompletedWorkflowStageTaskItemBffID: JobCandidatesCompletedWorkflowStageTaskItemBffID
  ) {
    return this.deleteItemRoute<DeleteJobCandidatesCompletedWorkflowStageTaskBffURL>(
      jobCandidatesCompletedWorkflowStageTaskItemBffID
    );
  }
}
