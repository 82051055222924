import {
  CompanyMemberItemBffID,
  CompanyMemberItemBffNanoID,
  FetchCompanyMembersBffURL,
  FetchCompanyMemberBffURL,
  FetchCompanyMemberByNanoIdBffURL,
  CreateCompanyMemberBffURL,
  UpdateCompanyMemberBffURL,
  DeleteCompanyMemberBffURL
} from './companyMembersTypes';

import { BaseBffRoutes } from '../BaseBffRoutes';

export class CompanyMembersBffRoutes extends BaseBffRoutes {
  protected static route = 'companyMembers';

  static fetchCompanyMembersRoute() {
    return this.fetchItemsRoute<FetchCompanyMembersBffURL>();
  }

  static fetchCompanyMembersIndexRoute() {
    return this.fetchItemsIndexRoute<FetchCompanyMembersBffURL>();
  }

  static fetchCompanyMemberRoute(
    companyMemberItemBffID: CompanyMemberItemBffID
  ) {
    return this.fetchItemRoute<FetchCompanyMemberBffURL>(
      companyMemberItemBffID
    );
  }

  static fetchCompanyMemberIndexRoute(
    companyMemberItemBffNanoID: CompanyMemberItemBffNanoID
  ) {
    return this.fetchItemIndexRoute<FetchCompanyMemberBffURL>(
      companyMemberItemBffNanoID
    );
  }

  static fetchCompanyMemberByNanoIdRoute(
    companyMemberItemBffNanoID: CompanyMemberItemBffNanoID
  ) {
    return this.fetchItemByNanoIdRoute<FetchCompanyMemberByNanoIdBffURL>(
      companyMemberItemBffNanoID
    );
  }

  static createCompanyMemberRoute() {
    return this.createItemRoute<CreateCompanyMemberBffURL>();
  }

  static updateCompanyMemberRoute(
    companyMemberItemBffNanoID:
      | CompanyMemberItemBffID
      | CompanyMemberItemBffNanoID
  ) {
    return this.updateItemRoute<UpdateCompanyMemberBffURL>(
      companyMemberItemBffNanoID
    );
  }

  static deleteCompanyMemberRoute(
    companyMemberItemBffNanoID: CompanyMemberItemBffNanoID
  ) {
    return this.deleteItemRoute<DeleteCompanyMemberBffURL>(
      companyMemberItemBffNanoID
    );
  }
}
