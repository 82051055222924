import { useMutation, useQueryClient } from 'react-query';

import { ErrorMessage, IsLoading } from '../../../../types';

import {
  FetchSkillBadgesCacheKey,
  FetchSkillBadgesIndexCacheKey
} from '../../skillBadgesTypes';

import {
  SkillBadgesBffRequests,
  CreateSkillBadgeData,
  CreateSkillBadgeErrorResponse
} from '../../SkillBadgesBffRequests';

import { parseRequestError } from '../../../../utils/parseRequestError';

interface CreateSkillBadgeOptions {
  cacheKeys?: (FetchSkillBadgesCacheKey | FetchSkillBadgesIndexCacheKey)[];
}

function useCreateSkillBadge({ cacheKeys = [] }: CreateSkillBadgeOptions) {
  const queryClient = useQueryClient();

  const { data, error, isLoading, mutateAsync, reset } = useMutation<
    CreateSkillBadgeData,
    CreateSkillBadgeErrorResponse,
    CreateSkillBadgeData
  >((queryInput) => SkillBadgesBffRequests.createSkillBadge(queryInput), {
    onSettled: async () =>
      cacheKeys?.map((cacheKey) => queryClient.invalidateQueries(cacheKey))
  });

  return {
    createSkillBadgeData: data,
    createSkillBadgeError: error,
    createSkillBadgeErrorMessage: parseRequestError(error) as ErrorMessage,
    createSkillBadgeIsLoading: isLoading as IsLoading,
    createSkillBadge: (createSkillBadgeData: CreateSkillBadgeData) =>
      mutateAsync(createSkillBadgeData),
    createSkillBadgeReset: () => reset()
  };
}

export default useCreateSkillBadge;
