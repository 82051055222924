import React, { ForwardedRef, useCallback, useEffect, useState } from 'react';
import { ButtonGroup } from '@chakra-ui/react';
import {
  Box,
  Flex,
  GridItem,
  SimpleGrid,
  Stack,
  StackDivider
} from '@chakra-ui/layout';
import { Collapse } from '@chakra-ui/transition';
import find from 'lodash/find';
import size from 'lodash/size';
import first from 'lodash/first';

import { IsLoading, IsRequired } from '../../../../../../types';

import { HiringTeamFields } from '../../PostJobIntakePage.types';

import {
  JobCertifications,
  JobDepartment,
  JobDiscipline,
  JobDisciplineId,
  JobFields,
  JobJobFunction,
  JobJobFunctionId,
  JobJobTitle,
  JobJobTitleId,
  JobNanoId,
  JobSpecializations,
  MayBeJobCityId,
  MayBeJobCityIds,
  MayBeJobCompanyId
} from '../../../../jobsTypes';

import { SkillKinds } from '../../../../../skills/skillsTypes';

import { UserActionTypes } from '../../../../../users/usersTypes';

import { useCurrentUser } from '../../../../../../auth/hooks/useAuth';
import { usePostJobIntakeForm } from './hooks/usePostJobIntakeForm';

import { JobsCache } from '../../../../JobsCache';

import { SkillsCache } from '../../../../../skills/SkillsCache';

import { InviteUserForm } from '../../../../components/forms/InviteUserForm';

import {
  JobSelectCompaniesField,
  JobSelectCompaniesFieldJob
} from '../../../../components/formFields/JobSelectCompaniesField';
import {
  JobSelectCitiesField,
  JobSelectCitiesFieldJob
} from '../../../../components/formFields/JobSelectCitiesField';
import {
  JobSelectRecruiterField,
  JobSelectRecruiterFieldJob
} from '../../../../components/formFields/JobSelectRecruiterField';
import {
  JobSelectHiringManagerField,
  JobSelectHiringManagerFieldJob
} from '../../../../components/formFields/JobSelectHiringManagerField';
import {
  JobSelectRecruitingCoordinatorField,
  JobSelectRecruitingCoordinatorFieldJob
} from '../../../../components/formFields/JobSelectRecruitingCoordinatorField';
import {
  JobSelectInterviewerField,
  JobSelectInterviewerFieldJob
} from '../../../../components/formFields/JobSelectInterviewerField';
import {
  JobSelectSkillBadgesField,
  JobSelectSkillBadgesFieldJob
} from '../../../../components/formFields/JobSelectSkillBadgesField';
import {
  JobSelectIndustryExpertisesField,
  JobSelectIndustryExpertisesFieldJob
} from '../../../../components/formFields/JobSelectIndustryExpertisesField';

import { AlertMessage } from '../../../../../../helpers/AlertMessage';
import { CollapseFormBox } from '../../../../../../helpers/CollapseFormBox';
import { FormWithRef } from '../../../../../../helpers/FormWithRef';
import { Heading } from '../../../../../../helpers/Heading';
import { SelectField } from '../../../../../../helpers/forms/formFields/SelectField';
import { TextareaField } from '../../../../../../helpers/forms/formFields/TextareaField';
import { Text } from '../../../../../../helpers/Text';
import { Button } from '../../../../../../helpers/Button';

import {
  employmentOptions,
  experienceOptions,
  geographyOptions,
  seniorityLevelOptions,
  workAuthorizationOptions,
  workplaceOptions
} from '../../../../jobs.data';

import { PostJobIntakeFormData } from './PostJobIntakeForm.types';
import { DisciplineId } from '../../../../../disciplines/disciplinesTypes';
import { CompanyId } from '../../../../../companies/companiesTypes';

import { SelectJobTitleFormField } from '../../../../../common/components/formFields/SelectJobTitleFormField';
import { SelectJobFunctionFormField } from '../../../../../common/components/formFields/SelectJobFunctionFormField';
import { SelectDepartmentFormField } from '../../../../../common/components/formFields/SelectDepartmentFormField';
import { SelectJobTitleSelectJobTitleAssociatesProps } from '../../../../../common/components/formFields/SelectJobTitleFormField/SelectJobTitle.types';
import { SelectCertificationsFormField } from '../../../../../common/components/formFields/SelectCertificationsFormField';
import { BaseSalaryInputFormField } from '../../../../../common/components/formFields/BaseSlaryInputFormField';
import { SelectDegreeFormField } from '../../../../../common/components/formFields/SelectDegreeFormField';
import { SelectStudyFieldFormField } from '../../../../../common/components/formFields/SelectStudyFieldFormField';
import { SelectSpecializationsFormField } from '../../../../../common/components/formFields/SelectSpecializationsFormField';

import { paidTimeOffOptions } from '../../../../../jobResumeRecruiters/JobResumeRecruiters.data';
import { useIntakeJob } from '../../../../hooks/useIntakeJob';

interface PostJobIntakeFormWithButtonsProps {
  withoutButtons?: never;
  onCancel?: () => void;
  onJobUpdated?: () => void;
  setUpdateJobIsLoading?: never;
  submitButtonText?: string;
}

interface PostJobIntakeFormWithoutButtonsProps {
  withoutButtons: boolean;
  onJobUpdated: () => void;
  setUpdateJobIsLoading: (isLoading: IsLoading) => void;
  onCancel?: never;
  submitButtonText?: never;
}

interface PostJobIntakeRequiredFormProps {
  job: {
    nanoId: JobNanoId;
    cityIds: MayBeJobCityIds;
    cityId: MayBeJobCityId;
    jobTitle: JobJobTitle;
    discipline: JobDiscipline;
    jobFunction: JobJobFunction;
    department: JobDepartment;
    companyId: MayBeJobCompanyId;
    certifications: JobCertifications;
    specializations: JobSpecializations;
  } & PostJobIntakeFormData &
    JobSelectSkillBadgesFieldJob &
    JobSelectInterviewerFieldJob &
    JobSelectRecruitingCoordinatorFieldJob &
    JobSelectHiringManagerFieldJob &
    JobSelectRecruiterFieldJob &
    JobSelectCitiesFieldJob &
    JobSelectCompaniesFieldJob &
    JobSelectIndustryExpertisesFieldJob;
}

type PostJobIntakeFormProps = PostJobIntakeRequiredFormProps &
  (PostJobIntakeFormWithButtonsProps | PostJobIntakeFormWithoutButtonsProps);

const PostJobIntakeForm = React.forwardRef<
  HTMLFormElement,
  PostJobIntakeFormProps
>(
  (
    {
      job,
      onJobUpdated,
      setUpdateJobIsLoading,
      withoutButtons,
      onCancel,
      submitButtonText
    }: PostJobIntakeFormProps,
    ref: ForwardedRef<HTMLFormElement>
  ) => {
    const currentUser = useCurrentUser();

    const { intakeJobErrorMessage, intakeJobIsLoading, intakeJob } =
      useIntakeJob({
        jobNanoId: job.nanoId,
        cacheKeys: [
          JobsCache.postJobIntakeCacheKey(),
          JobsCache.postJobDetailsCacheKey()
        ]
      });

    const {
      control,
      watch,
      registerFields,
      validationErrors,
      handlePostJobIntakeForm,
      setPostJobIntakeFormValue
    } = usePostJobIntakeForm({
      defaultValues: {
        jobTitleId: job.jobTitleId,
        disciplineId: job.disciplineId,
        departmentId: job.departmentId,
        userJobId: job.userJobId,
        workplace: job.workplace,
        workAuthorization: job.workAuthorization,
        notes: job.notes,
        cityIds: size(job.cityIds)
          ? job.cityIds
          : ([currentUser.cityId] as unknown as null),
        hiringManagerId: job.hiringManagerId,
        recruiterId: job.recruiterId,
        recruitingCoordinatorId: job.recruitingCoordinatorId,
        interviewerIds: job.interviewerIds,
        companyIds: job.companyIds,
        employment: job.employment,
        geography: job.geography,
        numberOfOpenings: job.numberOfOpenings,
        baseSalary: job.baseSalary,
        experience: job.experience,
        seniorityLevel: job.seniorityLevel,
        jobFunctionId: job.jobFunctionId,
        certificationIds: job.certificationIds,
        degree: job.degree,
        studyField: job.studyField,
        specializationIds: job.specializationIds
      },
      onPostJobIntakeForm: async (data) => {
        await intakeJob(data);
        onJobUpdated?.();
      },
      onPostJobIntakeFormError: (errors) => {
        const errorObjsArr = Object.values(errors);
        first(errorObjsArr)?.ref?.focus?.();
      }
    });

    useEffect(() => {
      setUpdateJobIsLoading?.(intakeJobIsLoading);
    }, [intakeJobIsLoading, setUpdateJobIsLoading]);

    const [activeHiringTeamForm, setActiveHiringTeamForm] = useState<
      HiringTeamFields | undefined
    >(undefined);
    const [selectedJobTitleIsNew, setSelectedJobTitleIsNew] =
      useState<boolean>(false);

    const currentJobHiringManagerId = watch(JobFields.HIRING_MANAGER_ID);
    const currentJobRecruiterId = watch(JobFields.RECRUITER_ID);
    const currentJobRecruitingCoordinatorId = watch(
      JobFields.RECRUITING_COORDINATOR_ID
    );
    const currentJobInterviewerIds = watch(JobFields.INTERVIEWER_IDS);
    const currentJobCompanyIds = watch(JobFields.COMPANY_IDS);
    const currentJobTitleId = watch(JobFields.JOB_TITLE_ID);
    const currentJobDisciplineId = watch(JobFields.DISCIPLINE_ID);
    const currentJobFunctionId = watch(JobFields.JOB_FUNCTION_ID);

    const handleJobTitleAssociates = useCallback<
      (jobTitleAssociates: SelectJobTitleSelectJobTitleAssociatesProps) => void
    >(
      ({ jobFunctionId, disciplineId, isNewJobTitle, jobTitleId }) => {
        setSelectedJobTitleIsNew(isNewJobTitle || false);
        if (jobTitleId) {
          setPostJobIntakeFormValue(
            JobFields.JOB_TITLE_ID,
            jobTitleId as JobJobTitleId
          );
        }
        if (jobFunctionId) {
          setPostJobIntakeFormValue(
            JobFields.JOB_FUNCTION_ID,
            jobFunctionId as JobJobFunctionId
          );
        }
        if (disciplineId) {
          setPostJobIntakeFormValue(
            JobFields.DISCIPLINE_ID,
            disciplineId as JobDisciplineId
          );
        }
      },
      [setPostJobIntakeFormValue]
    );

    const handleSelectDisciplineId = useCallback<
      (disciplineId: DisciplineId) => void
    >(
      (disciplineId) => {
        setPostJobIntakeFormValue(
          JobFields.DISCIPLINE_ID,
          disciplineId as JobDisciplineId
        );
      },
      [setPostJobIntakeFormValue]
    );

    const openAddHiringTeamForm = (tab: HiringTeamFields) => {
      setActiveHiringTeamForm(tab);
    };

    const closeAddHiringTeamForm = () => {
      setActiveHiringTeamForm(undefined);
    };

    return (
      <FormWithRef ref={ref} onSubmit={handlePostJobIntakeForm}>
        <Stack gap={10}>
          <Box>
            <Flex
              flexDir="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Heading level="h5" fontWeight="semibold" mb={4}>
                Preferences
              </Heading>

              <Text color="red.500">* indicates required</Text>
            </Flex>

            <SimpleGrid columns={{ base: 1, lg: 2, xl: 3 }} gap={6}>
              <SelectJobTitleFormField
                label="Title"
                control={control}
                item={job}
                selectedJobTitleId={currentJobTitleId}
                selectJobTitleAssocaites={handleJobTitleAssociates}
                errorMessage={validationErrors.jobTitleIdValidationError}
              />

              <SelectDepartmentFormField
                control={control}
                item={job}
                companyId={job.companyId as unknown as CompanyId}
                errorMessage={validationErrors.departmentIdValidationError}
              />

              <SelectField
                isRequired
                control={control}
                label="Employment type"
                placeholder="Select an employment"
                name={JobFields.EMPLOYMENT}
                options={employmentOptions}
                defaultValue={find(
                  employmentOptions,
                  (employmentOption) =>
                    employmentOption.value === job?.employment
                )}
                errorMessage={validationErrors.employmentValidationError}
              />

              {/* <InputField
                isRequired
                label="Job ID"
                name={JobFields.USER_JOB_ID}
                errorMessage={validationErrors.userJobIdValidationError}
                ref={registerFields.registerUserJobId.ref}
                onChange={registerFields.registerUserJobId.onChange}
              /> */}

              <SelectField
                isRequired
                control={control}
                label="Work Authorization"
                placeholder="Select authorization"
                name={JobFields.WORK_AUTHORIZATION}
                options={workAuthorizationOptions}
                defaultValue={find(
                  workAuthorizationOptions,
                  (workAuthorizationOption) =>
                    workAuthorizationOption.value === job?.workAuthorization
                )}
                errorMessage={validationErrors.workAuthorizationValidationError}
              />

              <SelectField
                isRequired
                control={control}
                label="Geographies"
                placeholder="Select geography"
                name={JobFields.GEOGRAPHY}
                options={geographyOptions}
                defaultValue={find(
                  geographyOptions,
                  (geographyOptions) =>
                    geographyOptions.value === job?.geography
                )}
                errorMessage={validationErrors.geographyValidationError}
              />

              <SelectField
                isRequired
                control={control}
                label="Workplace"
                autoFocus={!!validationErrors.workplaceValidationError}
                placeholder="Select a workplace"
                name={JobFields.WORKPLACE}
                options={workplaceOptions}
                defaultValue={find(
                  workplaceOptions,
                  (workplaceOption) => workplaceOption.value === job?.workplace
                )}
                errorMessage={validationErrors.workplaceValidationError}
              />
              {/* <SelectField
                isRequired
                control={control}
                label="Number of openings"
                placeholder="Openings"
                name={JobFields.NUMBER_OF_OPENINGS}
                options={numberOfOpeningsOptions}
                defaultValue={find(
                  numberOfOpeningsOptions,
                  (option) => option.value === job?.geography
                )}
                errorMessage={validationErrors.employmentValidationError}
              /> */}

              <GridItem colSpan={{ base: 1, lg: 2, xl: 3 }}>
                <JobSelectCitiesField
                  control={control}
                  currentUser={currentUser}
                  job={job}
                  errorMessage={validationErrors.cityIdsValidationError}
                  useCurrentUserCity
                />
              </GridItem>

              {selectedJobTitleIsNew ? (
                <GridItem colSpan={{ base: 1, lg: 2, xl: 3 }}>
                  <SelectJobFunctionFormField
                    control={control}
                    item={job}
                    selectedJobTitleId={currentJobTitleId}
                    selectedFunctionId={currentJobFunctionId}
                    selectDisciplineId={handleSelectDisciplineId}
                    selectedDisciplineId={currentJobDisciplineId}
                    errorMessage={validationErrors.jobFunctionIdValidationError}
                  />
                </GridItem>
              ) : null}

              <GridItem colSpan={{ base: 1, lg: 2, xl: 3 }}>
                <SelectSpecializationsFormField
                  isRequired={true as IsRequired}
                  control={control}
                  item={job}
                  errorMessage={
                    validationErrors.specializationIdsValidationError
                  }
                />
              </GridItem>

              <GridItem colSpan={{ base: 1, lg: 2, xl: 3 }}>
                <JobSelectIndustryExpertisesField
                  label="Industry expertise"
                  job={job}
                />
              </GridItem>

              <GridItem colSpan={{ base: 1, lg: 2, xl: 3 }}>
                <JobSelectCompaniesField
                  isRequired={true as IsRequired}
                  control={control}
                  job={job}
                  currentJobCompanyIds={currentJobCompanyIds}
                  errorMessage={validationErrors.companyIdsValidationError}
                />
              </GridItem>

              <SelectField
                isRequired
                control={control}
                label="Seniority Level"
                placeholder="Select seniority level"
                name={JobFields.SENIORITY_LEVEL}
                options={seniorityLevelOptions}
                defaultValue={find(
                  seniorityLevelOptions,
                  (seniorityLevelOption) =>
                    seniorityLevelOption.value === job?.seniorityLevel
                )}
                errorMessage={validationErrors.seniorityLevelValidationError}
              />

              <SelectDegreeFormField
                control={control}
                item={job}
                errorMessage={validationErrors.degreeValidationError}
              />

              <SelectStudyFieldFormField
                control={control}
                item={job}
                errorMessage={validationErrors.studyFieldValidationError}
              />

              <GridItem colSpan={{ base: 1, lg: 2, xl: 3 }}>
                <SelectCertificationsFormField
                  control={control}
                  item={job}
                  errorMessage={
                    validationErrors.certificationIdsValidationError
                  }
                />
              </GridItem>

              <SelectField
                isRequired
                control={control}
                label="Experience"
                placeholder="Select experience"
                name={JobFields.EXPERIENCE}
                options={experienceOptions}
                defaultValue={find(
                  experienceOptions,
                  (experienceOption) =>
                    experienceOption.value === job?.experience
                )}
                errorMessage={validationErrors.experienceValidationError}
              />

              <SelectField
                control={control}
                label="Paid time off"
                placeholder="Paid time off"
                name={JobFields.PAID_TIME_OFF}
                options={paidTimeOffOptions}
                defaultValue={find(
                  paidTimeOffOptions,
                  (paidTimeOffOption) =>
                    paidTimeOffOption.value === job?.paidTimeOff
                )}
                errorMessage={validationErrors.paidTimeOffValidationError}
              />

              <BaseSalaryInputFormField
                isRequired
                control={control}
                name={JobFields.BASE_SALARY}
                label="Base Salary"
                placeholder="Enter base salary"
                errorMessage={validationErrors.baseSalaryValidationError}
              />
            </SimpleGrid>
          </Box>

          <Box>
            <Heading level="h5" fontWeight="semibold" mb={4}>
              Hiring Team
            </Heading>

            <Box>
              <SimpleGrid columns={{ base: 1, lg: 2, xl: 3 }} gap={6}>
                <JobSelectRecruiterField
                  isRequired={true as IsRequired}
                  control={control}
                  job={job}
                  currentJobRecruiterId={currentJobRecruiterId}
                  errorMessage={validationErrors.hiringManagerIdValidationError}
                  showAddForm={() => {
                    openAddHiringTeamForm('recruiter');
                  }}
                />

                <JobSelectHiringManagerField
                  isRequired={true as IsRequired}
                  control={control}
                  job={job}
                  currentJobHiringManagerId={currentJobHiringManagerId}
                  errorMessage={validationErrors.hiringManagerIdValidationError}
                  showAddForm={() => {
                    openAddHiringTeamForm('hiringManager');
                  }}
                  emailRequired
                />

                <JobSelectRecruitingCoordinatorField
                  isRequired={true as IsRequired}
                  control={control}
                  job={job}
                  currentJobRecruitingCoordinatorId={
                    currentJobRecruitingCoordinatorId
                  }
                  errorMessage={
                    validationErrors.recruitingCoordinatorIdValidationError
                  }
                  showAddForm={() => {
                    openAddHiringTeamForm('recruitmentCoordinator');
                  }}
                />
              </SimpleGrid>

              <Box
                as={Collapse}
                in={
                  activeHiringTeamForm &&
                  activeHiringTeamForm !== 'interviewPanelist'
                }
                unmountOnExit={true}
              >
                <Box pt={6}>
                  <CollapseFormBox
                    title="Add Recruiter"
                    isOpen={activeHiringTeamForm === 'recruiter'}
                  >
                    <InviteUserForm
                      key={UserActionTypes.RECRUITER}
                      actionType={UserActionTypes.RECRUITER}
                      onCancel={closeAddHiringTeamForm}
                      onSave={closeAddHiringTeamForm}
                    />
                  </CollapseFormBox>

                  <CollapseFormBox
                    title="Add Hiring Manager"
                    isOpen={activeHiringTeamForm === 'hiringManager'}
                  >
                    <InviteUserForm
                      key={UserActionTypes.HIRING_MANAGER}
                      actionType={UserActionTypes.HIRING_MANAGER}
                      onCancel={closeAddHiringTeamForm}
                      onSave={closeAddHiringTeamForm}
                    />
                  </CollapseFormBox>

                  <CollapseFormBox
                    title="Add Recruiting Coordinator"
                    isOpen={activeHiringTeamForm === 'recruitmentCoordinator'}
                  >
                    <InviteUserForm
                      key={UserActionTypes.RECRUITING_COORDINATOR}
                      actionType={UserActionTypes.RECRUITING_COORDINATOR}
                      onCancel={closeAddHiringTeamForm}
                      onSave={closeAddHiringTeamForm}
                    />
                  </CollapseFormBox>
                </Box>
              </Box>

              <Box mt={6}>
                <JobSelectInterviewerField
                  isRequired={true as IsRequired}
                  control={control}
                  job={job}
                  currentJobInterviewerIds={currentJobInterviewerIds}
                  errorMessage={validationErrors.interviewerIdsValidationError}
                  showAddForm={() => {
                    openAddHiringTeamForm('interviewPanelist');
                  }}
                />

                <Box
                  as={Collapse}
                  in={activeHiringTeamForm === 'interviewPanelist'}
                  unmountOnExit={true}
                >
                  <Box pt={6}>
                    <CollapseFormBox
                      title="Add Panelist"
                      isOpen={activeHiringTeamForm === 'interviewPanelist'}
                    >
                      <InviteUserForm
                        key={UserActionTypes.INTERVIEW_PANELIST}
                        actionType={UserActionTypes.INTERVIEW_PANELIST}
                        onCancel={closeAddHiringTeamForm}
                        onSave={closeAddHiringTeamForm}
                      />
                    </CollapseFormBox>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box>
            <Heading level="h5" fontWeight="black" mb={4}>
              Skills and Knowledge
            </Heading>

            <Stack gap={8} divider={<StackDivider />}>
              {job ? (
                <JobSelectSkillBadgesField
                  label="Computer skills"
                  job={job}
                  skillKind={SkillKinds.COMPUTER}
                  skillsCacheKey={SkillsCache.computerCacheKey()}
                  newSkillsCacheKey={SkillsCache.computerNewSkillsCacheKey()}
                />
              ) : null}

              {job ? (
                <JobSelectSkillBadgesField
                  label="Industry Knowledge"
                  job={job}
                  skillKind={SkillKinds.INDUSTRY}
                  skillsCacheKey={SkillsCache.industryCacheKey()}
                  newSkillsCacheKey={SkillsCache.industryNewSkillsCacheKey()}
                />
              ) : null}
            </Stack>
          </Box>

          <Box>
            <Heading level="h5" fontWeight="black" mb={4}>
              Job Notes
            </Heading>

            <TextareaField
              rows={10}
              name={JobFields.NOTES}
              errorMessage={validationErrors.notesValidationError}
              ref={registerFields.registerNotes.ref}
              onChange={registerFields.registerNotes.onChange}
            />
          </Box>
        </Stack>

        {intakeJobErrorMessage || !withoutButtons ? (
          <Flex flexDirection="column" gap={4} mt={6}>
            <AlertMessage message={intakeJobErrorMessage} />

            {withoutButtons ? null : (
              <ButtonGroup justifyContent="flex-end">
                {onCancel ? (
                  <Button
                    size="medium"
                    type="reset"
                    hierarchy="tertiary"
                    onClick={onCancel}
                    isDisabled={intakeJobIsLoading}
                  >
                    Cancel
                  </Button>
                ) : null}
                <Button
                  size="medium"
                  type="submit"
                  isDisabled={intakeJobIsLoading}
                  isLoading={intakeJobIsLoading}
                >
                  {submitButtonText || 'Save'}
                </Button>
              </ButtonGroup>
            )}
          </Flex>
        ) : null}
      </FormWithRef>
    );
  }
);

PostJobIntakeForm.displayName = 'PostJobIntakeForm';

export default PostJobIntakeForm;
