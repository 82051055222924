import { Box, Grid, HStack } from '@chakra-ui/react';
import startCase from 'lodash/startCase';
import { Text } from '../../../../../../../../helpers/Text';
import { ClockIcon } from '../../../../../../../../icons/ClockIcon';
import { useViewJobCandidateDetailsTasksWidgetContext } from '../../../../contexts/ViewJobCandidateDetailsTasksWidgetContext';
import { ViewJobCandidateDetailsTaskSubActionsDropdown } from '../ViewJobCandidateDetailsTaskSubActionsDropdown';

function ViewJobCandidateDetailsEmptyTaskHover() {
  const {
    skipTask,
    taskStatus,
    cancelTask,
    openSubActionsDropdown,
    closeSubActionsDropdown,
    disqualifyCandidate
  } = useViewJobCandidateDetailsTasksWidgetContext();

  return (
    <Grid
      gap={2}
      flex={1}
      gridTemplateColumns="max-content 1fr max-content"
      alignItems="center"
      justifyContent="center"
    >
      <HStack flexGrow={0}>
        <ClockIcon w={4} h={4} />
        <Text textStyle="body2Regular">3 days ago</Text>
      </HStack>

      <Box display="block">
        <Text
          noOfLines={1}
          wordBreak="break-all"
          textStyle="body2Medium"
          color={taskStatus.color}
        >
          {startCase(taskStatus.label)}
        </Text>
      </Box>

      {taskStatus.label !== 'completed' && (
        <Box>
          <ViewJobCandidateDetailsTaskSubActionsDropdown
            skipTask={skipTask}
            cancelTask={cancelTask}
            openDropdown={openSubActionsDropdown}
            closeDropdown={closeSubActionsDropdown}
            disqualifyCandidate={disqualifyCandidate}
            actionSelected={taskStatus.label === 'in-progress'}
          />
        </Box>
      )}
    </Grid>
  );
}

export default ViewJobCandidateDetailsEmptyTaskHover;
