import {
  FetchContactCacheKey,
  FetchContactsCacheKey
} from '../../contactsTypes';

import { useCreateItem } from '../../../common/hooks/useCreateItem';

import {
  ContactsBffRequests,
  CreateContactRequestData,
  CreateContactErrorResponse
} from '../../ContactsBffRequests';

interface CreateContactOptions {
  cacheKeys?: (FetchContactsCacheKey | FetchContactCacheKey)[];
}

function useCreateContact({ cacheKeys = [] }: CreateContactOptions) {
  const {
    createItemData,
    createItemError,
    createItemErrorMessage,
    createItemIsLoading,
    createItem,
    createItemReset
  } = useCreateItem<CreateContactRequestData, CreateContactErrorResponse>({
    mutationFn: (queryInput) => ContactsBffRequests.createContact(queryInput),
    cacheKeys
  });

  return {
    createContactData: createItemData,
    createContactError: createItemError,
    createContactErrorMessage: createItemErrorMessage,
    createContactIsLoading: createItemIsLoading,
    createContact: createItem,
    createContactReset: createItemReset
  };
}

export default useCreateContact;
