import {
  FetchContactsCacheKey,
  FetchContactCacheKey,
  UpdateContactNanoId,
  UpdateContactErrorMessage,
  UpdateContactIsLoading
} from '../../contactsTypes';

import {
  ContactsBffRequests,
  UpdateContactRequestData,
  UpdateContactErrorResponse
} from '../../ContactsBffRequests';

import { useUpdateItem } from '../../../common/hooks/useUpdateItem';

interface UpdateContactOptions {
  contactNanoId: UpdateContactNanoId;
  cacheKeys?: (FetchContactsCacheKey | FetchContactCacheKey)[];
}

function useUpdateContact({
  contactNanoId,
  cacheKeys = []
}: UpdateContactOptions) {
  const {
    updateItemData,
    updateItemError,
    updateItemErrorMessage,
    updateItemIsLoading,
    updateItem,
    updateItemReset
  } = useUpdateItem<UpdateContactRequestData, UpdateContactErrorResponse>({
    mutationFn: (queryInput) =>
      ContactsBffRequests.updateContact(contactNanoId, queryInput),
    cacheKeys
  });

  return {
    updateContactData: updateItemData,
    updateContactError: updateItemError,
    updateContactErrorMessage:
      updateItemErrorMessage as UpdateContactErrorMessage,
    updateContactIsLoading: updateItemIsLoading as UpdateContactIsLoading,
    updateContact: updateItem,
    updateContactReset: updateItemReset
  };
}

export default useUpdateContact;
