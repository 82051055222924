import truncate from 'lodash/truncate';
import { EmploymentJobTitle, EmploymentName } from '../../employmentsTypes';

interface EmploymentJobTitleProp {
  name: EmploymentName;
  jobTitle?: EmploymentJobTitle;
}

function renderEmploymentJobTitle(employment: EmploymentJobTitleProp) {
  return truncate(employment.jobTitle?.name || employment.name, { length: 32 });
}

export default renderEmploymentJobTitle;
