import { FetchIndustryExpertisesCacheKey } from '../../industryExpertisesTypes';

import { useCreateItem } from '../../../common/hooks/useCreateItem';

import {
  IndustryExpertisesBffRequests,
  CreateIndustryExpertiseRequestData,
  CreateIndustryExpertiseErrorResponse
} from '../../IndustryExpertisesBffRequests';

interface CreateIndustryExpertiseOptions {
  cacheKeys?: FetchIndustryExpertisesCacheKey[];
}

function useCreateIndustryExpertise({
  cacheKeys = []
}: CreateIndustryExpertiseOptions) {
  const {
    createItemData,
    createItemError,
    createItemErrorMessage,
    createItemIsLoading,
    createItem,
    createItemReset
  } = useCreateItem<
    CreateIndustryExpertiseRequestData,
    CreateIndustryExpertiseErrorResponse
  >({
    mutationFn: (queryInput) =>
      IndustryExpertisesBffRequests.createIndustryExpertise(queryInput),
    cacheKeys
  });

  return {
    createIndustryExpertiseData: createItemData,
    createIndustryExpertiseError: createItemError,
    createIndustryExpertiseErrorMessage: createItemErrorMessage,
    createIndustryExpertiseIsLoading: createItemIsLoading,
    createIndustryExpertise: createItem,
    createIndustryExpertiseReset: createItemReset
  };
}

export default useCreateIndustryExpertise;
