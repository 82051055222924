import {
  FetchJobsCacheKey,
  FetchJobCacheKey,
  IntakeJobErrorMessage,
  IntakeJobIsLoading,
  IntakeJobNanoId
} from '../../jobsTypes';

import { usePostItem } from '../../../common/hooks/usePostItem';

import {
  JobsBffRequests,
  IntakeJobRequestData,
  IntakeJobErrorResponse
} from '../../JobsBffRequests';

interface IntakeJobOptions {
  jobNanoId: IntakeJobNanoId;
  cacheKeys?: (FetchJobsCacheKey | FetchJobCacheKey)[];
}

function useIntakeJob({ jobNanoId, cacheKeys = [] }: IntakeJobOptions) {
  const {
    postItemData,
    postItemError,
    postItemErrorMessage,
    postItemIsLoading,
    postItem,
    postItemReset
  } = usePostItem<IntakeJobRequestData, IntakeJobErrorResponse>({
    mutationFn: (queryInput) =>
      JobsBffRequests.intakeJob(jobNanoId, queryInput),
    cacheKeys
  });

  return {
    intakeJobData: postItemData,
    intakeJobError: postItemError,
    intakeJobErrorMessage: postItemErrorMessage as IntakeJobErrorMessage,
    intakeJobIsLoading: postItemIsLoading as IntakeJobIsLoading,
    intakeJob: postItem,
    intakeJobReset: postItemReset
  };
}

export default useIntakeJob;
