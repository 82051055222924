import { Fragment, memo, useState, useEffect, useCallback } from 'react';
import { IconButton } from '@chakra-ui/button';
import { Box, Divider, Flex, Stack, StackDivider } from '@chakra-ui/layout';
import {
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList
} from '@chakra-ui/menu';
import { Show } from '@chakra-ui/react';
import { Switch } from '@chakra-ui/switch';

import {
  useControlPanelContext,
  useLeftSidebarContext,
  useRecruiterContext
} from '../../../../context';

import { useCurrentUser } from '../../../../auth/hooks/useAuth';

import { CandidatesRoutes } from '../../../../main/control-panel/pages/People/CandidatesRoutes';
import { ControlPanelRoutes } from '../../../../main/control-panel/ControlPanelRoutes';
import { ControlPanelSettingsRoutes } from '../../../../main/control-panel/pages/Settings/ControlPanelSettingsRoutes';

import { ChevronDownIcon } from '../../../../icons/ChevronDownIcon';
import { PlusIcon } from '../../../../icons/PlusIcon';

import { AddRecruiterDrawer } from '../../../../main/users/components/drawers/AddRecruiterDrawer';

import { HeaderAvatar } from '../../../../helpers/HeaderAvatar';
import { HeaderSearch } from '../../../../helpers/HeaderSearch';
import { NextLinkHelper } from '../../../../helpers/NextLinkHelper';
import { NotificationsDropdown } from '../../../../helpers/NotificationsDropdown';
import { Text } from '../../../../helpers/Text';

import { HeaderRequiredProps } from './Header.types';

import { ControlPanelJobsAddJob } from '../../../../main/control-panel/pages/Jobs/components/drawers/add/ControlPanelJobsAddJob';
import { AddContactDrawer } from '../../../../main/contacts/components/drawers/AddContactDrawer';

function Header({ onSignOut, onSaveContact }: HeaderRequiredProps) {
  const { dockSidebar, isDocked } = useLeftSidebarContext();
  const [hasMounted, setHasMounted] = useState(false);
  const currentUser = useCurrentUser();
  const { isAddPersonOpened, isAddJobOpened, toggleAddJob, toggleAddPerson } =
    useControlPanelContext();
  const { toggleAddRecruiter } = useRecruiterContext();

  const onContactSaved = useCallback<() => void>(
    () => onSaveContact(),
    [onSaveContact]
  );

  useEffect(() => {
    setHasMounted(true);
  }, []);

  return (
    <Fragment>
      <Flex
        py={4}
        h="72px"
        bg="white"
        alignItems="center"
        pl={4}
        pr={6}
        position="relative"
        gap={4}
        zIndex={9}
      >
        <Flex>
          {hasMounted ? (
            <Switch
              w="24px"
              variant="custom"
              height="24px"
              defaultChecked={!isDocked}
              onChange={() => dockSidebar(!isDocked)}
            />
          ) : null}
        </Flex>

        <Stack ml="auto" direction="row" alignItems="center" spacing={6}>
          {hasMounted ? (
            <Box w="100%">
              <HeaderSearch />
            </Box>
          ) : null}

          <Stack
            ml="auto"
            direction="row"
            alignItems="center"
            spacing={6}
            divider={<StackDivider borderColor="gray.200" />}
          >
            <Box>
              <Menu isLazy placement="bottom-end">
                <MenuButton>
                  <Stack direction="row" alignItems="center">
                    <Show above="xl">
                      <Text w={32} noOfLines={1}>
                        {currentUser.name}
                      </Text>
                    </Show>

                    {currentUser?.image ? (
                      <HeaderAvatar image={currentUser.image} />
                    ) : null}

                    <ChevronDownIcon />
                  </Stack>
                </MenuButton>

                <MenuList>
                  <NextLinkHelper href={ControlPanelRoutes.profile()} passHref>
                    <MenuItem as="a" _hover={{ textDecoration: 'none' }}>
                      My Profile
                    </MenuItem>
                  </NextLinkHelper>
                  <NextLinkHelper
                    href={ControlPanelSettingsRoutes.personalAbout()}
                    passHref
                  >
                    <MenuItem as="a" _hover={{ textDecoration: 'none' }}>
                      Account
                    </MenuItem>
                  </NextLinkHelper>

                  <MenuDivider />
                  <MenuItem color="red.500" onClick={onSignOut}>
                    Logout
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>

            <Stack direction="row" spacing={6}>
              <NotificationsDropdown />

              <Box>
                <Menu isLazy>
                  <MenuButton
                    as={IconButton}
                    aria-label="Add"
                    borderRadius="full"
                  >
                    <PlusIcon />
                  </MenuButton>

                  <MenuList>
                    <MenuItem onClick={toggleAddRecruiter}>
                      Add Recruiter
                    </MenuItem>
                    <NextLinkHelper href={CandidatesRoutes.add()} passHref>
                      <MenuItem as="a" _hover={{ textDecor: 'none' }}>
                        Add Candidate
                      </MenuItem>
                    </NextLinkHelper>
                    <MenuItem onClick={toggleAddPerson}>Add Person</MenuItem>
                    <MenuItem>Add Company</MenuItem>
                    <MenuItem onClick={toggleAddJob}>Add Job</MenuItem>
                    <MenuItem>Add Department</MenuItem>
                  </MenuList>
                </Menu>
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </Flex>

      <Divider />

      <AddRecruiterDrawer />

      <ControlPanelJobsAddJob isOpen={isAddJobOpened} onClose={toggleAddJob} />

      <AddContactDrawer
        isOpen={isAddPersonOpened}
        onClose={toggleAddPerson}
        onSave={onContactSaved}
      />
    </Fragment>
  );
}

export default memo<HeaderRequiredProps>(Header);
