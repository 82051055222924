import { Drawer, DrawerContent, DrawerOverlay } from '@chakra-ui/react';

import { AddControlPanelJobDrawerContent } from '../AddControlPanelJobDrawerContent';

interface ControlPanelJobsAddJobProps {
  isOpen: boolean;
  onClose: () => void;
}

const ControlPanelJobsAddJob = ({
  isOpen,
  onClose
}: ControlPanelJobsAddJobProps) => {
  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      autoFocus={false}
      closeOnOverlayClick={false}
    >
      <DrawerOverlay width="calc(100% - 72px)" />

      <DrawerContent
        w="100%"
        maxW="54rem"
        boxShadow="none"
        marginRight={{ base: 0, lg: '72px' }}
      >
        <AddControlPanelJobDrawerContent onClose={onClose} />
      </DrawerContent>
    </Drawer>
  );
};

export default ControlPanelJobsAddJob;
