import { ButtonGroup } from '@chakra-ui/button';
import { Flex, Stack } from '@chakra-ui/layout';
import { useCallback, useEffect, useState } from 'react';

import { JobId } from '../../../jobs/jobsTypes';
import { JobCandidateId } from '../../../jobCandidates/jobCandidatesTypes';
import { TalentPoolId } from '../../../talentPools/talentPoolsTypes';

import { JobResumeRecruiterId } from '../../../jobResumeRecruiters/jobResumeRecruitersTypes';

import { IndustryId, IndustryName } from '../../../industries/industriesTypes';

import {
  FetchIndustryExpertisesCacheKey,
  IndustryExpertiseFields,
  IndustryExpertiseId,
  IndustryExpertiseIndustryId,
  IndustryExpertiseJobCandidateId,
  IndustryExpertiseJobId,
  IndustryExpertiseJobResumeRecruiterId,
  IndustryExpertiseNanoId,
  IndustryExpertiseRequirements,
  industryExpertiseRequirements,
  IndustryExpertiseTalentPoolId,
  IndustryExpertiseUserId
} from '../../industryExpertisesTypes';

import { useCurrentUser } from '../../../../auth/hooks/useAuth';
import { useCreateIndustryExpertise } from '../../hooks/useCreateIndustryExpertise';
import { useUpdateIndustryExpertise } from '../../hooks/useUpdateIndustryExpertise';
import { useAddEditIndustryExpertiseForm } from './hooks/useAddEditIndustryExpertiseForm';

import { AlertMessage } from '../../../../helpers/AlertMessage';
import { ButtonHelper } from '../../../../helpers/buttons/ButtonHelper';
import { PureButtonHelper } from '../../../../helpers/buttons/PureButtonHelper';
import { RadioGroupField } from '../../../../helpers/forms/formFields/RadioGroupField';
import { Text } from '../../../../helpers/Text';
// import { YearsOfExperienceSliderField } from '../../../../helpers/forms/formFields/YearsOfExperienceSliderField';

import { generateNanoId } from '../../../../utils/generateNanoId';

import { renderIndustryExpertiseRequirements } from '../../utils/renderIndustryExpertiseRequirements';

import { AddEditIndustryExpertiseFormIndustryExpertise } from './AddEditIndustryExpertiseForm.types';

interface AddEditIndustryExpertiseFormDefaultProps {
  jobId?: JobId;
  jobCandidateId?: JobCandidateId;
  jobResumeRecruiterId?: JobResumeRecruiterId;
  talentPoolId?: TalentPoolId;
  industryExpertisesCacheKey: FetchIndustryExpertisesCacheKey;
  onAdd: () => void;
  onDiscard: () => void;
  isIntakeForm?: boolean;
}

interface AddEditIndustryExpertiseFormWithIndustryExpertiseProps {
  industryExpertise?: AddEditIndustryExpertiseFormIndustryExpertise;
  industry?: never;
}

interface AddEditIndustryExpertiseFormWithIndustryProps {
  industryExpertise?: never;
  industry?: {
    id: IndustryId;
    name: IndustryName;
  };
}

type AddEditIndustryExpertiseFormProps =
  AddEditIndustryExpertiseFormDefaultProps &
    (
      | AddEditIndustryExpertiseFormWithIndustryExpertiseProps
      | AddEditIndustryExpertiseFormWithIndustryProps
    );

function AddEditIndustryExpertiseForm({
  jobId,
  jobCandidateId,
  jobResumeRecruiterId,
  talentPoolId,
  industryExpertisesCacheKey,
  industryExpertise,
  industry,
  onAdd,
  onDiscard,
  isIntakeForm
}: AddEditIndustryExpertiseFormProps) {
  const currentUser = useCurrentUser();

  const [currentIndustryExpertiseId, setCurrentIndustryExpertiseId] =
    useState<IndustryExpertiseId | null>(industryExpertise?.id || null);

  const {
    createIndustryExpertiseIsLoading,
    createIndustryExpertiseErrorMessage,
    createIndustryExpertise
  } = useCreateIndustryExpertise({
    cacheKeys: [industryExpertisesCacheKey]
  });

  const {
    updateIndustryExpertiseIsLoading,
    updateIndustryExpertiseErrorMessage,
    updateIndustryExpertise
  } = useUpdateIndustryExpertise({
    industryExpertiseId: industryExpertise?.id as IndustryExpertiseId,
    cacheKeys: [industryExpertisesCacheKey]
  });

  const {
    control,
    watch,
    validationErrors,
    addEditIndustryExpertiseFormIsLoading,
    addEditIndustryExpertiseFormErrorMessage,
    handleAddEditIndustryExpertiseForm,
    setAddEditIndustryExpertiseFormValue
  } = useAddEditIndustryExpertiseForm({
    ...(industryExpertise
      ? {
          defaultValues: {
            experience: industryExpertise.experience,
            requirements: industryExpertise.requirements
          }
        }
      : {}),
    onAddEditIndustryExpertiseForm: async (data) => {
      if (industry?.id) {
        await createIndustryExpertise({
          ...data,
          industryId: industry.id as IndustryExpertiseIndustryId,
          jobId: jobId as IndustryExpertiseJobId,
          talentPoolId: talentPoolId as IndustryExpertiseTalentPoolId,
          jobCandidateId: jobCandidateId as IndustryExpertiseJobCandidateId,
          jobResumeRecruiterId:
            jobResumeRecruiterId as IndustryExpertiseJobResumeRecruiterId,
          userId: currentUser.id as IndustryExpertiseUserId,
          nanoId: generateNanoId<IndustryExpertiseNanoId>()
        });
      }

      if (industryExpertise?.id) {
        await updateIndustryExpertise(data);
      }

      onAdd();
    },
    isIntakeForm
  });

  const handleFormSubmit = useCallback<() => void>(
    () => handleAddEditIndustryExpertiseForm(),
    [handleAddEditIndustryExpertiseForm]
  );

  const requirements = watch(IndustryExpertiseFields.REQUIREMENTS);

  const handleEditIndustryExpertiseRequirements = useCallback(
    () =>
      setAddEditIndustryExpertiseFormValue(
        IndustryExpertiseFields.REQUIREMENTS as 'requirements',
        '' as IndustryExpertiseRequirements
      ),
    [setAddEditIndustryExpertiseFormValue]
  );

  useEffect(() => {
    if (
      industryExpertise?.id &&
      industryExpertise?.id !== currentIndustryExpertiseId
    ) {
      setAddEditIndustryExpertiseFormValue(
        IndustryExpertiseFields.REQUIREMENTS as 'requirements',
        industryExpertise.requirements
      );

      setAddEditIndustryExpertiseFormValue(
        IndustryExpertiseFields.EXPERIENCE as 'experience',
        industryExpertise.experience
      );

      setCurrentIndustryExpertiseId(industryExpertise.id);
    }
  }, [
    currentIndustryExpertiseId,
    industryExpertise?.id,
    industryExpertise?.experience,
    industryExpertise?.requirements,
    setCurrentIndustryExpertiseId,
    setAddEditIndustryExpertiseFormValue
  ]);

  return (
    <Stack
      p={4}
      spacing={4}
      flexDirection="column"
      bg="gray.50"
      borderRadius={4}
      border="1px solid"
      borderColor="gray.200"
    >
      <Text textStyle="body1Medium">
        {industry?.name || industryExpertise?.industry?.name}
        {requirements ? (
          <ButtonHelper
            hierarchy="link"
            ml={2}
            size="small"
            onClick={handleEditIndustryExpertiseRequirements}
          >
            {renderIndustryExpertiseRequirements(requirements)} &#8595;
          </ButtonHelper>
        ) : null}
      </Text>

      <Flex gap={4} alignItems="flex-start" flexWrap="wrap">
        <Flex alignItems="center" flex={1}>
          <RadioGroupField
            control={control}
            name={IndustryExpertiseFields.REQUIREMENTS}
            options={industryExpertiseRequirements.map((requirement) => ({
              value: requirement,
              label: renderIndustryExpertiseRequirements(requirement)
            }))}
            errorMessage={validationErrors.requirementsValidationError}
          />
        </Flex>
        {/* {!requirements ? (
          <Flex alignItems="center" flex={1}>
            <RadioGroupField
              control={control}
              name={IndustryExpertiseFields.REQUIREMENTS}
              options={industryExpertiseRequirements.map((requirement) => ({
                value: requirement,
                label: renderIndustryExpertiseRequirements(requirement)
              }))}
              errorMessage={validationErrors.requirementsValidationError}
            />
          </Flex>
        ) : (
          <Flex alignItems="center" flex={1}>
            <YearsOfExperienceSliderField
              control={control}
              name={IndustryExpertiseFields.EXPERIENCE}
              label="Years of experience"
              errorMessage={validationErrors.experienceValidationError}
            />
          </Flex>
        )} */}
      </Flex>

      <Flex>
        <ButtonGroup ml="auto" gap={4}>
          <PureButtonHelper
            size="medium"
            hierarchy="unstyled"
            color="red.900"
            i18nText="Discard"
            onClick={onDiscard}
          />
          <PureButtonHelper
            size="medium"
            i18nText={industryExpertise ? 'Update' : 'Add'}
            isLoading={
              addEditIndustryExpertiseFormIsLoading ||
              createIndustryExpertiseIsLoading ||
              updateIndustryExpertiseIsLoading
            }
            onClick={handleFormSubmit}
          />
        </ButtonGroup>
      </Flex>

      <AlertMessage
        message={
          addEditIndustryExpertiseFormErrorMessage ||
          createIndustryExpertiseErrorMessage ||
          updateIndustryExpertiseErrorMessage
        }
      />
    </Stack>
  );
}

export default AddEditIndustryExpertiseForm;
