import { usePaginatedItems } from '../../../common/hooks/usePaginatedItems';

import {
  FetchJobTitlesQuery,
  FetchJobTitlesCacheKey,
  FetchJobTitlesTotalCount,
  FetchJobTitlesPage,
  FetchJobTitlesPageSize,
  FetchJobTitlesError,
  FetchJobTitlesErrorMessage,
  FetchJobTitlesIsLoading,
  FetchJobTitlesIsFetched,
  FetchJobTitlesIsPlaceholderData,
  FetchJobTitlesEnabled,
  FetchJobTitlesPrefetchNextPage,
  FetchJobTitlesCountType,
  FetchJobTitlesFilters,
  FetchJobTitlesSort,
  FetchJobTitleID,
  FetchJobTitleQuery,
  FetchJobTitleCacheKey
} from '../../jobTitlesTypes';

import { JobTitlesBffRequests } from '../../JobTitlesBffRequests';

interface PaginatedJobTitlesBaseOptions<PaginatedJobTitlesResponseType> {
  cacheKey: FetchJobTitlesCacheKey;
  query: FetchJobTitlesQuery;
  countType?: FetchJobTitlesCountType;
  initialPage?: FetchJobTitlesPage;
  initialPageSize?: FetchJobTitlesPageSize;
  initialFilters?: FetchJobTitlesFilters;
  initialSort?: FetchJobTitlesSort;
  enabled?: FetchJobTitlesEnabled;
  prefetchNextPage?: FetchJobTitlesPrefetchNextPage;
  placeholderJobTitles?: PaginatedJobTitlesResponseType[];
  placeholderJobTitlesCount?: FetchJobTitlesTotalCount;
  placeholderData?: () => PaginatedJobTitlesResponse<PaginatedJobTitlesResponseType>;
}

interface PaginatedJobTitlesOptionsWithoutItem {
  fetchItemCacheKey?: never;
  itemQuery?: never;
}

interface PaginatedJobTitlesOptionsWithItem {
  fetchItemCacheKey: FetchJobTitleCacheKey;
  itemQuery: FetchJobTitleQuery;
}

type PaginatedJobTitlesOptions<PaginatedJobTitlesResponseType> =
  PaginatedJobTitlesBaseOptions<PaginatedJobTitlesResponseType> &
    (PaginatedJobTitlesOptionsWithoutItem | PaginatedJobTitlesOptionsWithItem);

interface PaginatedJobTitlesResponse<PaginatedJobTitlesResponseType> {
  data: PaginatedJobTitlesResponseType[];
  totalCount: FetchJobTitlesTotalCount;
}

function usePaginatedJobTitles<PaginatedJobTitlesResponseType>({
  cacheKey,
  query,
  countType,
  initialPage,
  initialPageSize,
  initialFilters,
  initialSort,
  enabled,
  prefetchNextPage,
  placeholderJobTitles,
  placeholderJobTitlesCount,
  placeholderData,
  fetchItemCacheKey,
  itemQuery
}: PaginatedJobTitlesOptions<PaginatedJobTitlesResponseType>) {
  const {
    items,
    itemsTotalCount,
    itemsError,
    itemsErrorMessage,
    itemsIsFetched,
    itemsIsLoading,
    itemsIsPlaceholderData,
    itemsCurrentPage,
    itemsCurrentPageSize,
    itemsCurrentFilters,
    itemsCurrentSort,
    fetchItems,
    paginateItems,
    changeItemsPageSize,
    filterItems,
    changeItemsFilters,
    clearItemsFilters,
    sortItems,
    prefetchItems,
    prefetchItem
  } = usePaginatedItems<PaginatedJobTitlesResponseType>({
    cacheKey,
    initialPage,
    initialPageSize,
    initialFilters,
    initialSort,
    enabled,
    prefetchNextPage,
    placeholderItems: placeholderJobTitles,
    placeholderItemsCount: placeholderJobTitlesCount,
    placeholderData,
    queryFn: (params) =>
      JobTitlesBffRequests.fetchJobTitles<PaginatedJobTitlesResponseType>({
        query,
        countType,
        page: params.page as FetchJobTitlesPage,
        pageSize: params.pageSize as FetchJobTitlesPageSize,
        filters: params.filters as FetchJobTitlesFilters,
        sort: params.sort as FetchJobTitlesSort
      }),
    // JobTitlesRequests.fetchJobTitles<PaginatedJobTitlesResponseType>({
    //   query,
    //   countType,
    //   page: params.page as FetchJobTitlesPage,
    //   pageSize: params.pageSize as FetchJobTitlesPageSize,
    //   filters: params.filters as FetchJobTitlesFilters,
    //   sort: params.sort as FetchJobTitlesSort
    // }),
    ...(itemQuery
      ? {
          fetchItemCacheKey,
          queryItemFn: (itemId) =>
            JobTitlesBffRequests.fetchJobTitle(itemId as FetchJobTitleID, {
              query: itemQuery
            })
          // JobTitlesRequests.fetchJobTitle(itemId as FetchJobTitleID, {
          //   query: itemQuery
          // })
        }
      : {})
  });

  return {
    jobTitles: items as PaginatedJobTitlesResponseType[],
    jobTitlesTotalCount: itemsTotalCount as FetchJobTitlesTotalCount,
    jobTitlesError: itemsError as FetchJobTitlesError,
    jobTitlesErrorMessage: itemsErrorMessage as FetchJobTitlesErrorMessage,
    jobTitlesIsLoading: itemsIsLoading as FetchJobTitlesIsLoading,
    jobTitlesIsFetched: itemsIsFetched as FetchJobTitlesIsFetched,
    jobTitlesIsPlaceholderData:
      itemsIsPlaceholderData as FetchJobTitlesIsPlaceholderData,
    jobTitlesCurrentPage: itemsCurrentPage as FetchJobTitlesPage,
    jobTitlesCurrentPageSize: itemsCurrentPageSize as FetchJobTitlesPageSize,
    jobTitlesCurrentFilters: itemsCurrentFilters as FetchJobTitlesFilters,
    jobTitlesCurrentSort: itemsCurrentSort as FetchJobTitlesSort,
    fetchJobTitles: fetchItems,
    paginateJobTitles: paginateItems,
    changeJobTitlesPageSize: changeItemsPageSize,
    filterJobTitles: filterItems,
    changeJobTitlesFilters: changeItemsFilters,
    clearJobTitlesFilters: clearItemsFilters,
    sortJobTitles: sortItems,
    prefetchJobTitles: prefetchItems,
    prefetchJobTitle: prefetchItem
  };
}

export default usePaginatedJobTitles;
