import { FieldBrand, ItemRecordID } from '../../../types';

export type UserRecordId = FieldBrand<ItemRecordID, 'UserRecordId'>;

export const enum UserIndexFields {
  ID = 'id',
  RECORD_ID = 'recordId',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  NAME = 'name',
  EMAIL = 'email',
  TITLE = 'title',
  PHONE = 'phone',
  APPROVED_AT = 'approvedAt',
  PROFILE_CREATED_AT = 'profileCreatedAt',
  WORK_EMAIL_APPROVED_AT = 'workEmailApprovedAt',
  QUALIFICATIONS = 'qualifications',
  CREATED_AT = 'createdAt',

  ROLE_NAMES = 'roleNames',
  ACTIONS = 'actions',

  ROLES = 'roles',
  ROLES_NANO_ID = 'roles.nanoId',
  ROLES_NAME = 'roles.name',

  PERMISSIONS = 'permissions',
  PERMISSIONS_NANO_ID = 'permissions.nanoId',
  PERMISSIONS_ACTION = 'permissions.action',
  PERMISSIONS_FRONTEND = 'permissions.frontend',

  COMPANY_MEMBER_IDS = 'companyMemberIds',
  COMPANY_MEMBERS = 'companyMembers',
  COMPANY_MEMBERS_NANO_ID = 'companyMembers.nanoId',
  COMPANY_MEMBERS_COMPANY_NANO_ID = 'companyMembers.company.nanoId',
  COMPANY_MEMBERS_COMPANY_NAME = 'companyMembers.company.name',

  COMPANY_MEMBERS_COMPANY_IMAGE_NANO_ID = 'companyMembers.company.image.nanoId',
  COMPANY_MEMBERS_COMPANY_IMAGE_NAME = 'companyMembers.company.image.name',
  COMPANY_MEMBERS_COMPANY_IMAGE_FILE = 'companyMembers.company.image.file',
  COMPANY_MEMBERS_COMPANY_IMAGE_KEY = 'companyMembers.company.image.key',
  COMPANY_MEMBERS_COMPANY_IMAGE_CREATED_AT = 'companyMembers.company.image.createdAt',

  IMAGE_ID = 'imageId',
  IMAGE = 'image',
  IMAGE_NANO_ID = 'image.nanoId',
  IMAGE_NAME = 'image.name',
  IMAGE_FILE = 'image.file',
  IMAGE_KEY = 'image.key',
  IMAGE_CREATED_AT = 'image.createdAt',

  COUNTRY_ID = 'countryId',

  STATE_ID = 'stateId',
  STATE = 'state',
  STATE_NANO_ID = 'state.nanoId',
  STATE_NAME = 'state.name',
  STATE_ISO_CODE = 'state.isoCode',

  CITY_ID = 'cityId',
  CITY = 'city',
  CITY_NANO_ID = 'city.nanoId',
  CITY_NAME = 'city.name',
  CITY_LABEL = 'city.label',

  COMPANY_ROLE_NAME = 'companyRoleName',

  COMPANY_ID = 'companyId',
  COMPANY = 'company',
  COMPANY_NANO_ID = 'company.nanoId',
  COMPANY_NAME = 'company.name',
  COMPANY_IMAGE_NANO_ID = 'company.image.nanoId',
  COMPANY_IMAGE_NAME = 'company.image.name',
  COMPANY_IMAGE_FILE = 'company.image.file',
  COMPANY_IMAGE_KEY = 'company.image.key',
  COMPANY_IMAGE_CREATED_AT = 'company.image.createdAt',

  JOB_RESUME_RECRUITER_ID = 'jobResumeRecruiterId',
  JOB_RESUME_RECRUITER = 'jobResumeRecruiter',
  JOB_RESUME_RECRUITER_NANO_ID = 'jobResumeRecruiter.nanoId',
  JOB_RESUME_RECRUITER_JOB_NANO_ID = 'jobResumeRecruiter.job.nanoId'
}
