import {
  CityItemBffID,
  CityItemBffNanoID,
  FetchCitiesBffURL,
  FetchCityBffURL,
  FetchCityByNanoIdBffURL,
  CreateCityBffURL,
  UpdateCityBffURL,
  DeleteCityBffURL
} from './citiesTypes';

import { BaseBffRoutes } from '../BaseBffRoutes';

export class CitiesBffRoutes extends BaseBffRoutes {
  protected static route = 'cities';

  static fetchCitiesRoute() {
    return this.fetchItemsRoute<FetchCitiesBffURL>();
  }

  static fetchCitiesIndexRoute() {
    return this.fetchItemsIndexRoute<FetchCitiesBffURL>();
  }

  static fetchCityRoute(cityItemBffID: CityItemBffID) {
    return this.fetchItemRoute<FetchCityBffURL>(cityItemBffID);
  }

  static fetchCityIndexRoute(cityItemBffNanoID: CityItemBffNanoID) {
    return this.fetchItemIndexRoute<FetchCityBffURL>(cityItemBffNanoID);
  }

  static fetchCityByNanoIdRoute(cityItemBffNanoID: CityItemBffNanoID) {
    return this.fetchItemByNanoIdRoute<FetchCityByNanoIdBffURL>(
      cityItemBffNanoID
    );
  }

  static createCityRoute() {
    return this.createItemRoute<CreateCityBffURL>();
  }

  static updateCityRoute(cityItemBffNanoID: CityItemBffID | CityItemBffNanoID) {
    return this.updateItemRoute<UpdateCityBffURL>(cityItemBffNanoID);
  }

  static deleteCityRoute(cityItemBffNanoID: CityItemBffNanoID) {
    return this.deleteItemRoute<DeleteCityBffURL>(cityItemBffNanoID);
  }
}
