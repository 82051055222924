import {
  IndustryItemBffID,
  IndustryItemBffNanoID,
  FetchIndustriesBffURL,
  FetchIndustryBffURL,
  FetchIndustryByNanoIdBffURL,
  CreateIndustryBffURL,
  UpdateIndustryBffURL,
  DeleteIndustryBffURL
} from './industriesTypes';

import { BaseBffRoutes } from '../BaseBffRoutes';

export class IndustriesBffRoutes extends BaseBffRoutes {
  protected static route = 'industries';

  static fetchIndustriesRoute() {
    return this.fetchItemsRoute<FetchIndustriesBffURL>();
  }

  static fetchIndustriesIndexRoute() {
    return this.fetchItemsIndexRoute<FetchIndustriesBffURL>();
  }

  static fetchIndustryRoute(industryItemBffID: IndustryItemBffID) {
    return this.fetchItemRoute<FetchIndustryBffURL>(industryItemBffID);
  }

  static fetchIndustryIndexRoute(industryItemBffNanoID: IndustryItemBffNanoID) {
    return this.fetchItemIndexRoute<FetchIndustryBffURL>(industryItemBffNanoID);
  }

  static fetchIndustryByNanoIdRoute(
    industryItemBffNanoID: IndustryItemBffNanoID
  ) {
    return this.fetchItemByNanoIdRoute<FetchIndustryByNanoIdBffURL>(
      industryItemBffNanoID
    );
  }

  static createIndustryRoute() {
    return this.createItemRoute<CreateIndustryBffURL>();
  }

  static updateIndustryRoute(
    industryItemBffNanoID: IndustryItemBffID | IndustryItemBffNanoID
  ) {
    return this.updateItemRoute<UpdateIndustryBffURL>(industryItemBffNanoID);
  }

  static deleteIndustryRoute(industryItemBffNanoID: IndustryItemBffNanoID) {
    return this.deleteItemRoute<DeleteIndustryBffURL>(industryItemBffNanoID);
  }
}
