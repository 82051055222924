import { Icon, IconProps } from '@chakra-ui/icon';

const AlarmIcon = ({
  w = '16px',
  h = '16px',
  fill = 'gray.500',
  ...props
}: IconProps) => (
  <Icon viewBox="0 0 16 16" w={w} h={h} fill={fill} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.054 6.389c.11-.056.222-.111.278-.222.222-.5.333-1 .333-1.5 0-1.834-1.5-3.334-3.333-3.334-.834 0-1.612.334-2.223.89-.11.11-.166.221-.166.333a6.455 6.455 0 0 1 5.11 3.833M1.944 6.389a6.656 6.656 0 0 1 5.112-3.833c0-.112-.056-.223-.167-.334-.611-.555-1.389-.889-2.222-.889a3.343 3.343 0 0 0-3.334 3.334c0 .5.111 1 .334 1.444a.67.67 0 0 0 .277.278"
      // fill="#98A5A9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.776 9.661H7.443V6.328h1.11V8.55h2.223v1.111Zm2.611 4.058-1.111-1.111a5.516 5.516 0 0 0 1.278-3.5c0-3.056-2.5-5.556-5.556-5.556-3.055 0-5.555 2.5-5.555 5.556 0 1.333.444 2.555 1.277 3.5l-1.11 1.11a.537.537 0 0 0 0 .778.504.504 0 0 0 .388.167.504.504 0 0 0 .39-.167l1.11-1.11c.945.833 2.167 1.277 3.5 1.277 1.334 0 2.556-.444 3.5-1.278l1.111 1.111a.6.6 0 0 0 .39.167.6.6 0 0 0 .388-.167.537.537 0 0 0 0-.777Z"
      // fill="#98A5A9"
    />
  </Icon>
);
export default AlarmIcon;
