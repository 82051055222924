import {
  CandidateSubmissionItemBffID,
  CandidateSubmissionItemBffNanoID,
  FetchCandidateSubmissionsBffURL,
  FetchCandidateSubmissionBffURL,
  FetchCandidateSubmissionByNanoIdBffURL,
  CreateCandidateSubmissionBffURL,
  DeleteCandidateSubmissionBffURL,
  DeleteRecipientInCandidateSubmissionBffURL,
  DeleteJobCandidateInCandidateSubmissionBffURL,
  UpdateCandidateSubmissionBffURL,
  SendEmailsCandidateSubmissionBffURL
} from './candidateSubmissionsTypes';
import { RecipientItemBffID } from '../recipients/recipientsTypes';
import {
  JobCandidateItemBffID,
  JobCandidateItemBffNanoID
} from '../jobCandidates/jobCandidatesTypes';

import { BaseBffRoutes } from '../BaseBffRoutes';

export class CandidateSubmissionsBffRoutes extends BaseBffRoutes {
  protected static route = 'candidateSubmissions';

  static fetchCandidateSubmissionsRoute() {
    return this.fetchItemsRoute<FetchCandidateSubmissionsBffURL>();
  }

  static fetchCandidateSubmissionsIndexRoute() {
    return this.fetchItemsIndexRoute<FetchCandidateSubmissionsBffURL>();
  }

  static fetchCandidateSubmissionRoute(
    candidateSubmissionItemBffID: CandidateSubmissionItemBffID
  ) {
    return this.fetchItemRoute<FetchCandidateSubmissionBffURL>(
      candidateSubmissionItemBffID
    );
  }

  static fetchCandidateSubmissionIndexRoute(
    candidateSubmissionItemBffNanoID: CandidateSubmissionItemBffNanoID
  ) {
    return this.fetchItemIndexRoute<FetchCandidateSubmissionBffURL>(
      candidateSubmissionItemBffNanoID
    );
  }

  static fetchCandidateSubmissionByNanoIdRoute(
    candidateSubmissionItemBffNanoID: CandidateSubmissionItemBffNanoID
  ) {
    return this.fetchItemByNanoIdRoute<FetchCandidateSubmissionByNanoIdBffURL>(
      candidateSubmissionItemBffNanoID
    );
  }

  static createCandidateSubmissionRoute() {
    return this.createItemRoute<CreateCandidateSubmissionBffURL>();
  }

  static updateCandidateSubmissionRoute(
    updaeCandidateSubmissionItemBffID:
      | CandidateSubmissionItemBffNanoID
      | CandidateSubmissionItemBffID
  ) {
    return this.updateItemRoute<UpdateCandidateSubmissionBffURL>(
      updaeCandidateSubmissionItemBffID
    );
  }

  static deleteCandidateSubmissionRoute(
    deleteCandidateSubmissionItemBffID:
      | CandidateSubmissionItemBffNanoID
      | CandidateSubmissionItemBffID
  ) {
    return this.deleteItemRoute<DeleteCandidateSubmissionBffURL>(
      deleteCandidateSubmissionItemBffID
    );
  }

  static deleteRecipientInCandidateSubmissionRoute(
    candidateSubmissionItemBffID:
      | CandidateSubmissionItemBffNanoID
      | CandidateSubmissionItemBffID,
    recipientItemBffID: RecipientItemBffID
  ) {
    return `${this.basePath}/${this.route}/${candidateSubmissionItemBffID}/recipients/${recipientItemBffID}/delete` as DeleteRecipientInCandidateSubmissionBffURL;
  }

  static deleteJobCandidateInCandidateSubmissionRoute(
    candidateSubmissionItemBffID:
      | CandidateSubmissionItemBffNanoID
      | CandidateSubmissionItemBffID,
    jobCandidateItemBffID: JobCandidateItemBffID | JobCandidateItemBffNanoID
  ) {
    return `${this.basePath}/${this.route}/${candidateSubmissionItemBffID}/job_candidates/${jobCandidateItemBffID}/delete` as DeleteJobCandidateInCandidateSubmissionBffURL;
  }

  static sendEmailsCandidateSubmissionRoute(
    candidateSubmissionItemBffID:
      | CandidateSubmissionItemBffNanoID
      | CandidateSubmissionItemBffID
  ) {
    return `${this.basePath}/${this.route}/${candidateSubmissionItemBffID}/send_emails` as SendEmailsCandidateSubmissionBffURL;
  }
}
