import {
  useReactHookForm,
  ReactHookFormFieldError
} from '../../../../../../../common/hooks/base/useReactHookForm';

import {
  UserFields,
  UserEmail,
  UserName,
  UserTitle
} from '../../../../../../users/usersTypes';

import { useInviteUserFormValidationRules } from '../useInviteUserFormValidationRules';

import {
  OnInviteUserForm,
  InviteUserFormData
} from '../../InviteUserForm.types';

const defaultInviteUserFormValues: InviteUserFormData = {
  name: '' as UserName,
  title: '' as UserTitle,
  email: '' as UserEmail
};

interface InviteUserFormOptions {
  defaultValues?: Partial<InviteUserFormData>;
  onInviteUserForm: OnInviteUserForm;
}

function useInviteUserForm({
  defaultValues = {},
  onInviteUserForm
}: InviteUserFormOptions) {
  const {
    control,
    watch,
    errors,
    isLoading,
    isValid,
    isSubmitted,
    isSubmitSuccessful,
    errorMessage,
    register,
    resetForm,
    handleSubmitReactHookForm,
    setValue
  } = useReactHookForm<InviteUserFormData>({
    defaultValues: { ...defaultInviteUserFormValues, ...defaultValues }
  });

  const { nameRules, titleRules, emailRules } =
    useInviteUserFormValidationRules();

  return {
    control,
    watch,
    validationErrors: {
      nameValidationError: (errors.name as ReactHookFormFieldError)?.message,
      titleValidationError: (errors.title as ReactHookFormFieldError)?.message,
      emailValidationError: (errors.email as ReactHookFormFieldError)?.message
    },
    inviteUserFormIsLoading: isLoading,
    inviteUserFormIsValid: isValid,
    inviteUserFormIsSubmitted: isSubmitted,
    inviteUserFormIsSubmitSuccessful: isSubmitSuccessful,
    inviteUserFormErrorMessage: errorMessage,
    resetInviteUserForm: resetForm,
    setInviteUserFormValue: setValue,
    registerFields: {
      registerName: register(UserFields.NAME, nameRules),
      registerTitle: register(UserFields.TITLE, titleRules),
      registerEmail: register(UserFields.EMAIL, emailRules)
    },
    handleInviteUserForm: handleSubmitReactHookForm({
      onSubmit: (data) =>
        onInviteUserForm?.({
          name: data.name as UserName,
          title: data.title as UserTitle,
          email: data.email as UserEmail
        })
    })
  };
}

export default useInviteUserForm;
