import React, { useCallback } from 'react';
import { Flex, Stack, StackDivider, Tooltip } from '@chakra-ui/react';
import { FieldValues, Path } from 'react-hook-form';

import { ErrorMessage } from '../../../../../../../../../types';

import { UserEmailLabel } from '../../../../../../../../userEmails/userEmailsTypes';

import { RemoveIcon } from '../../../../../../../../../icons/RemoveIcon';

import { IconButton } from '../../../../../../../../../helpers/buttons/IconButton';
import { InputField } from '../../../../../../../../../helpers/forms/formFields/InputField';

import { EditUserFormAddCertificationFieldControl } from './EditUserFormAddCertificationField.types';
import { UserCertification } from '../../../../../../../usersTypes';

import { SelectCompanyFormField } from '../../../../../../../../common/components/formFields/SelectCompanyFormField';
import { DateInputField } from '../../../../../../../../../helpers/forms/formFields/DateInputField';

interface EditUserFormAddCertificationFieldProps<T extends FieldValues> {
  control: EditUserFormAddCertificationFieldControl<T>;
  name: string;
  index: number;
  withoutRemove?: boolean;
  error?: { [key: string]: { message?: ErrorMessage } };
  labelValue?: UserEmailLabel;
  onRemove: (index: number) => void;
  certification: UserCertification;
}

function EditUserFormAddCertificationField<T extends FieldValues>({
  control,
  name,
  index,
  error,
  withoutRemove,
  certification,
  onRemove
}: EditUserFormAddCertificationFieldProps<T>) {
  const handleRemove = useCallback<() => void>(
    () => onRemove(index),
    [index, onRemove]
  );

  return (
    <Stack
      spacing={0}
      border="1px solid"
      borderRadius="base"
      position="relative"
      borderColor="gray.200"
      divider={<StackDivider />}
    >
      <Flex alignItems="center">
        <InputField
          flex={1}
          border="none"
          borderRadius={0}
          borderRight="1px solid"
          borderRightColor="gray.200"
          placeholder="Name"
          {...control.register(`${name}.${index}.name` as Path<T>)}
          errorMessage={error?.name?.message}
        />

        <Tooltip
          label={withoutRemove ? undefined : 'Remove certification'}
          placement="end"
          hasArrow
        >
          <IconButton
            mx={1}
            size="small"
            value={index}
            aria-label={''}
            hierarchy="link"
            colorScheme="red"
            onClick={handleRemove}
            cursor={withoutRemove ? 'not-allowed' : 'pointer'}
            icon={
              <RemoveIcon
                fill={withoutRemove ? 'red.200' : 'red.500'}
                w={5}
                h={5}
              />
            }
          />
        </Tooltip>
      </Flex>

      {/*<InputField*/}
      {/*  border="none"*/}
      {/*  placeholder="Organization"*/}
      {/*  {...control.register(`${name}.${index}.companyId` as Path<T>)}*/}
      {/*  errorMessage={error?.companyId?.message}*/}
      {/*/>*/}

      <SelectCompanyFormField
        control={control}
        withoutLabel
        name={`${name}.${index}.companyId` as Path<T>}
        i18nPlaceholder="Organization"
        errorMessage={error?.companyId?.message}
        item={certification}
        isBorderless
      />

      <Stack spacing={0} direction="row" divider={<StackDivider />}>
        <Flex flex={1} position="relative">
          <InputField
            border="none"
            placeholder="Credential ID"
            {...control.register(`${name}.${index}.credentialsId` as Path<T>)}
            errorMessage={error?.credentialsId?.message}
          />
        </Flex>

        <Flex flex={1} position="relative">
          <DateInputField
            border="none"
            placeholder="Issue Date"
            type="month"
            {...control.register(`${name}.${index}.issueDate` as Path<T>)}
            errorMessage={error?.issueDate?.message}
          />
        </Flex>
      </Stack>
    </Stack>
  );
}

export default EditUserFormAddCertificationField;
