import { Flex, Stack, Box } from '@chakra-ui/react';
import { SearchIcon } from '../../../../icons/SearchIcon';
import { Text } from '../../../Text';
import { AccentText, HighlightText, SearchItem } from '../../HeaderSearch';

const HeaderRecentSearch = () => {
  return (
    <Flex alignItems="flex-start" gap={4}>
      <Flex flex={1} gap={4} flexDir="column">
        <Text textStyle="body1Medium" color="gray.600">
          Recent Search
        </Text>

        <Stack maxH={64} spacing={4} overflow="auto" pr={4}>
          <SearchItem
            avatarUrl="/images/default-avatar-5.png"
            title="Add a job/ Select a job to post/ Update"
            content={
              <>
                In <HighlightText>Waiting for review</HighlightText> to{' '}
                <HighlightText>Automobili Lamborghini</HighlightText>
              </>
            }
          />
          <SearchItem
            avatarUrl="/images/default-avatar-7.png"
            title="Michelle Yeoh"
            content={
              <>
                Changed Kevin <AccentText>Hart&apos;s</AccentText> application
                from <HighlightText>Sourcing</HighlightText> to{' '}
                <HighlightText>Screening</HighlightText>
              </>
            }
          />
          <SearchItem
            avatarUrl="/images/default-avatar-1.png"
            title="Michelle Nunez"
            content={
              <>
                In <HighlightText>C#/React developer</HighlightText> on{' '}
                <HighlightText>Mitsubishi Tech</HighlightText>
              </>
            }
          />
          <SearchItem
            avatarUrl="/images/default-avatar-3.png"
            title="Add a job/ Select a job to post/ Update"
            content={
              <>
                In <HighlightText>Waiting for review</HighlightText> to{' '}
                <HighlightText>Automobili Lamborghini</HighlightText>
              </>
            }
          />
          <SearchItem
            avatarUrl="/images/default-avatar-2.png"
            title="Michelle Yeoh"
            content={
              <>
                Changed Kevin <AccentText>Hart&apos;s</AccentText> application
                from <HighlightText>Sourcing</HighlightText> to{' '}
                <HighlightText>Screening</HighlightText>
              </>
            }
          />
          <SearchItem
            avatarUrl="/images/default-avatar-4.png"
            title="Add a job/ Select a job to post/ Update"
            content={
              <>
                In <HighlightText>Waiting for review</HighlightText> to{' '}
                <HighlightText>Automobili Lamborghini</HighlightText>
              </>
            }
          />
          <SearchItem
            avatarUrl="/images/default-avatar-6.png"
            title="Add a job/ Select a job to post/ Update"
            content={
              <>
                In <HighlightText>Waiting for review</HighlightText> to{' '}
                <HighlightText>Automobili Lamborghini</HighlightText>
              </>
            }
          />
          <SearchItem
            avatarUrl="/images/default-avatar-1.png"
            title="Michelle Nunez"
            content={
              <>
                In <HighlightText>C#/React developer</HighlightText> on{' '}
                <HighlightText>Mitsubishi Tech</HighlightText>
              </>
            }
          />
        </Stack>
      </Flex>

      <Flex bg="gray.50" w={56} flexDir="column" p={4} gap={2}>
        <Text textStyle="body1Medium" color="gray.600">
          Saved searches
        </Text>

        {savedSearches.map((searchItem, index) => (
          <Flex key={index} gap={2}>
            <SearchIcon />
            <Box as="span">{searchItem}</Box>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default HeaderRecentSearch;

const savedSearches = [
  'Developer Jobs',
  'Adam Sandler',
  'Calendar invite',
  'Job search',
  'Engineer opening'
];
