import {
  FetchLikedJobCandidatesTable,
  FetchLikedJobCandidatesQuery,
  FetchLikedJobCandidatesCountType,
  FetchLikedJobCandidatesPage,
  FetchLikedJobCandidatesPageSize,
  FetchLikedJobCandidatesFilters,
  FetchLikedJobCandidatesSort,
  FetchLikedJobCandidateTable,
  FetchLikedJobCandidateQuery,
  FetchLikedJobCandidateID,
  CreateLikedJobCandidateTable,
  CreateLikedJobCandidateError,
  UpdateLikedJobCandidateTable,
  UpdateLikedJobCandidateID,
  UpdateLikedJobCandidateError,
  DeleteLikedJobCandidateTable,
  DeleteLikedJobCandidateID,
  DeleteLikedJobCandidateError,
  LikedJobCandidateId,
  LikedJobCandidateJobCandidateId,
  LikedJobCandidateUserId
} from './likedJobCandidatesTypes';

import { BaseRequests } from '../BaseRequests';

export interface FetchLikedJobCandidatesOptions {
  query: FetchLikedJobCandidatesQuery;
  countType?: FetchLikedJobCandidatesCountType;
  page?: FetchLikedJobCandidatesPage;
  pageSize?: FetchLikedJobCandidatesPageSize;
  filters?: FetchLikedJobCandidatesFilters;
  sort?: FetchLikedJobCandidatesSort;
}

export interface FetchLikedJobCandidateOptions {
  query: FetchLikedJobCandidateQuery;
}

export interface LikedJobCandidateRequestData {
  userId?: LikedJobCandidateUserId;
  jobCandidateId: LikedJobCandidateJobCandidateId;
}

export interface CreateLikedJobCandidateRequestData
  extends LikedJobCandidateRequestData {
  id?: LikedJobCandidateId;
}
export interface UpdateLikedJobCandidateRequestData
  extends LikedJobCandidateRequestData {
  id?: LikedJobCandidateId;
}

export interface CreateLikedJobCandidateErrorResponse {
  message: CreateLikedJobCandidateError;
}

export interface UpdateLikedJobCandidateErrorResponse {
  message: UpdateLikedJobCandidateError;
}

export interface DeleteLikedJobCandidateErrorResponse {
  message: DeleteLikedJobCandidateError;
}

export class LikedJobCandidatesRequests extends BaseRequests {
  static async fetchLikedJobCandidates<T>(
    options: FetchLikedJobCandidatesOptions
  ) {
    return this.fetchItems<T>(
      'LikedJobCandidates' as FetchLikedJobCandidatesTable,
      options
    );
  }

  static async fetchLikedJobCandidate<T>(
    fetchLikedJobCandidateId: FetchLikedJobCandidateID,
    options: FetchLikedJobCandidateOptions
  ) {
    return this.fetchItem<T>(
      'LikedJobCandidates' as FetchLikedJobCandidateTable,
      fetchLikedJobCandidateId,
      options
    );
  }

  static async createLikedJobCandidate(
    createLikedJobCandidateData: CreateLikedJobCandidateRequestData
  ) {
    return this.createItem<CreateLikedJobCandidateRequestData>(
      'LikedJobCandidates' as CreateLikedJobCandidateTable,
      createLikedJobCandidateData
    );
  }

  static async updateLikedJobCandidate(
    updateLikedJobCandidateId: UpdateLikedJobCandidateID,
    updateLikedJobCandidateData: UpdateLikedJobCandidateRequestData,
    updateLikedJobCandidateOptions: {
      filterColumn?: keyof UpdateLikedJobCandidateRequestData;
    } = {}
  ) {
    return this.updateItem<UpdateLikedJobCandidateRequestData>(
      'LikedJobCandidates' as UpdateLikedJobCandidateTable,
      updateLikedJobCandidateId,
      updateLikedJobCandidateData,
      updateLikedJobCandidateOptions
    );
  }

  static async deleteLikedJobCandidate(
    deleteLikedJobCandidateId: DeleteLikedJobCandidateID
  ) {
    return this.deleteItem(
      'LikedJobCandidates' as DeleteLikedJobCandidateTable,
      deleteLikedJobCandidateId,
      {}
    );
  }
}
