import React from 'react';
import {
  Button,
  ButtonProps
} from '../../../../../../../../../../../helpers/Button';

interface ViewJobCandidateModalPhoneSidebarCallActionButtonProps
  extends ButtonProps {
  label: string;
  value: string;
}

const ViewJobCandidateModalPhoneSidebarCallActionButton = ({
  label,
  value,
  ...props
}: ViewJobCandidateModalPhoneSidebarCallActionButtonProps) => {
  return (
    <Button
      w="full"
      size="small"
      hierarchy="tertiary"
      borderRadius={0}
      boxShadow="none"
      border="none"
      value={value}
      {...props}
    >
      {label}
    </Button>
  );
};

export default ViewJobCandidateModalPhoneSidebarCallActionButton;
