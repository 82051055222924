import {
  Box,
  ButtonGroup,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  Stack,
  StackDivider,
  SystemStyleObject,
  useDisclosure
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { Button } from '../../../../../../helpers/Button';
import { Text } from '../../../../../../helpers/Text';
import { InputField } from '../../../../../../helpers/forms/formFields/InputField';
import { MultiSelectFieldControl } from '../../../../../../helpers/forms/formFields/MultiSelectField/components/MultiSelectFieldControl';
import { WysiwygFieldControl } from '../../../../../../helpers/forms/formFields/WysiwygField/components/WysiwygFieldControl';
import { AttachIcon } from '../../../../../../icons/AttachIcon';
import { ClockOutlineIcon } from '../../../../../../icons/ClockOutlineIcon';

interface ViewJobCandidateDetailsComposeEmailProps {
  trigger: ReactNode;
}

function ViewJobCandidateDetailsComposeEmail({
  trigger
}: ViewJobCandidateDetailsComposeEmailProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Flex onClick={onOpen}>{trigger}</Flex>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        returnFocusOnClose={false}
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <ModalContent
          mb={2}
          w="2xl"
          maxW="100%"
          pos="fixed"
          bottom={0}
          borderRadius="base"
          boxShadow="0 10px 25px -5px rgba(0, 0, 0, 0.1), 0 0 25px 25px rgba(0, 0, 0, 0.04)"
        >
          <ModalBody
            p={4}
            sx={{
              '.rdw-fontsize-wrapper, .rdw-image-wrapper': {
                display: 'none !important'
              },
              '.rdw-fontfamily-wrapper': {
                display: 'none !important'
              }
            }}
          >
            <Stack spacing={4}>
              <Flex gap={4} flexDir="column" flex={1}>
                <Flex gap={4} alignItems="center" w="full">
                  <Text fontSize="md" fontWeight="semibold">
                    Compose Email
                  </Text>
                </Flex>

                <Stack
                  spacing={2}
                  color="gray.600"
                  textStyle="body1Regular"
                  divider={<StackDivider />}
                >
                  <Flex as="label" gap={1} cursor="pointer" alignItems="center">
                    <Box as="span">To:</Box>

                    <MultiSelectFieldControl
                      size="sm"
                      withImages
                      onChange={console.log}
                      reactSelectStyles={{
                        control: (base: SystemStyleObject) => ({
                          ...base,
                          borderColor: 'transparent',
                          p: 0
                        })
                      }}
                      options={[]}
                    />
                  </Flex>

                  <Flex as="label" gap={1} cursor="pointer" alignItems="center">
                    <Box as="span">Cc:</Box>{' '}
                    <MultiSelectFieldControl
                      size="sm"
                      withImages
                      onChange={console.log}
                      reactSelectStyles={{
                        control: (base: SystemStyleObject) => ({
                          ...base,
                          borderColor: 'transparent',
                          p: 0
                        })
                      }}
                      options={[]}
                    />
                  </Flex>

                  <Flex as="label" gap={1} cursor="pointer" alignItems="center">
                    <Box as="span">Subject:</Box>
                    <InputField size="small" borderColor="white" />
                  </Flex>
                </Stack>

                <Box h="320px">
                  <WysiwygFieldControl
                    name=""
                    value=""
                    defaultValue=""
                    onChange={() => {
                      //
                    }}
                  />
                </Box>
              </Flex>
            </Stack>
          </ModalBody>

          <ModalFooter pt={0}>
            <Flex w="full">
              <Flex gap={4}>
                <Button
                  color="gray.600"
                  size="small"
                  hierarchy="link"
                  leftIcon={<AttachIcon color="gray.500" />}
                >
                  Attach
                </Button>
                <Button
                  color="gray.600"
                  size="small"
                  hierarchy="link"
                  leftIcon={<ClockOutlineIcon color="gray.500" />}
                >
                  Schedule
                </Button>
              </Flex>

              <ButtonGroup ml="auto" spacing={2}>
                <Button
                  px={4}
                  size="small"
                  onClick={onClose}
                  hierarchy="tertiary"
                >
                  Discard
                </Button>

                <Button size="small" px={6}>
                  Send
                </Button>
              </ButtonGroup>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ViewJobCandidateDetailsComposeEmail;
