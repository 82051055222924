import React, { useCallback } from 'react';
import { ButtonGroup } from '@chakra-ui/button';
import { GridItem, SimpleGrid } from '@chakra-ui/layout';

import {
  UserActionType,
  UserActionTypes,
  UserCaptureType,
  UserCaptureTypes,
  UserFields,
  UserNanoId,
  UserSearchType,
  UserSearchTypes
} from '../../../../users/usersTypes';

import { useInviteUser } from '../../../../users/hooks/useInviteUser';
import { useInviteUserForm } from './hooks/useInviteUserForm';

import { UsersCache } from '../../../../users/UsersCache';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { InputField } from '../../../../../helpers/forms/formFields/InputField';
import { PureButtonHelper } from '../../../../../helpers/buttons/PureButtonHelper';
import { useChakraToast } from '../../../../../helpers/useChakraToast';

import { generateNanoId } from '../../../../../utils/generateNanoId';
import { Flex } from '@chakra-ui/react';

interface InviteUserFormProps {
  actionType: UserActionTypes;
  onCancel: () => void;
  onSave: () => void;
  isHorizontal?: boolean;
}

function InviteUserForm({
  onSave,
  onCancel,
  actionType,
  isHorizontal
}: InviteUserFormProps) {
  const { inviteUser } = useInviteUser({
    cacheKeys: [UsersCache.postJobCacheKey()]
  });

  const toast = useChakraToast();

  const {
    registerFields,
    validationErrors,
    inviteUserFormIsLoading,
    inviteUserFormErrorMessage,
    handleInviteUserForm
  } = useInviteUserForm({
    onInviteUserForm: async (data) => {
      await inviteUser({
        ...data,
        searchType: UserSearchTypes.PROSPECT as UserSearchType,
        captureType: UserCaptureTypes.EMPLOYEE as UserCaptureType,
        actionType: actionType as UserActionType,
        nanoId: generateNanoId<UserNanoId>()
      });
      onSave();
      toast({ title: 'User successfully invited' });
    }
  });

  const handleFormSubmit = useCallback<() => void>(
    () => handleInviteUserForm(),
    [handleInviteUserForm]
  );

  return (
    <Flex
      gap={6}
      flexDir={isHorizontal ? 'row' : 'column'}
      alignItems="baseline"
    >
      <SimpleGrid
        gap={6}
        flex={1}
        columns={!isHorizontal ? { base: 1, lg: 3 } : undefined}
        gridTemplateColumns={isHorizontal ? 'repeat(3, 1fr)' : undefined}
      >
        <GridItem>
          <InputField
            isRequired
            autoFocus
            placeholder="Name"
            name={UserFields.NAME}
            label={!isHorizontal ? 'Name' : ''}
            errorMessage={validationErrors.nameValidationError}
            ref={registerFields.registerName.ref}
            onChange={registerFields.registerName.onChange}
          />
        </GridItem>

        <GridItem>
          <InputField
            isRequired
            placeholder="Title"
            name={UserFields.TITLE}
            label={!isHorizontal ? 'Title' : ''}
            errorMessage={validationErrors.titleValidationError}
            ref={registerFields.registerTitle.ref}
            onChange={registerFields.registerTitle.onChange}
          />
        </GridItem>

        <GridItem>
          <InputField
            isRequired
            placeholder="Email"
            name={UserFields.EMAIL}
            label={!isHorizontal ? 'Email' : ''}
            errorMessage={validationErrors.emailValidationError}
            ref={registerFields.registerEmail.ref}
            onChange={registerFields.registerEmail.onChange}
          />
        </GridItem>
      </SimpleGrid>

      <AlertMessage message={inviteUserFormErrorMessage} />

      <ButtonGroup justifyContent="flex-end">
        <PureButtonHelper
          size="small"
          hierarchy="tertiary"
          onClick={onCancel}
          i18nText="Cancel"
        />
        <PureButtonHelper
          size="small"
          isLoading={inviteUserFormIsLoading}
          i18nText="Save"
          onClick={handleFormSubmit}
        />
      </ButtonGroup>
    </Flex>
  );
}

export default InviteUserForm;
