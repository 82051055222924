import { Icon, IconProps } from '@chakra-ui/icon';

const ProjectsIcon = ({ w = '16px', h = '16px', ...props }: IconProps) => (
  <Icon viewBox="0 0 16 16" w={w} h={h} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 1a.467.467 0 00-.467.467V2.4a.467.467 0 00.934 0v-.933A.467.467 0 008 1zM3.8 4.733a.467.467 0 000-.66l-.74-.74a.467.467 0 00-.66.661l.74.74a.467.467 0 00.66 0zm8.4 0a.467.467 0 00.66 0l.74-.739a.467.467 0 10-.66-.66l-.74.739a.467.467 0 000 .66zM8 3.8a3.695 3.695 0 00-3.659 3.173l-.009.066a3.688 3.688 0 00.737 2.79c.358.461.598.903.598 1.345v1.493a.467.467 0 00.466.466h3.734a.467.467 0 00.466-.466v-1.493c0-.442.24-.884.598-1.346.588-.76.885-1.75.737-2.79l-.01-.066A3.695 3.695 0 008 3.8zM1 8c0 .258.209.467.467.467H2.4a.467.467 0 000-.934h-.933A.467.467 0 001 8zm12.133 0c0 .258.21.467.467.467h.933a.467.467 0 100-.934H13.6a.467.467 0 00-.467.467zM6.6 14.533c0 .258.209.467.467.467h1.866a.467.467 0 100-.933H7.067a.467.467 0 00-.467.466z"
      fill="currentColor"
    />
  </Icon>
);
export default ProjectsIcon;
