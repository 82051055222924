import React from 'react';
import Image from 'next/image';

interface NextImageHelperProps {
  alt: string | undefined;
  src: string;
  layout: 'fixed';
  width: number;
  height: number;
}

function NextImageHelper({
  alt,
  src,
  layout,
  width,
  height
}: NextImageHelperProps) {
  return (
    <Image alt={alt} src={src} layout={layout} width={width} height={height} />
  );
}

export default NextImageHelper;
