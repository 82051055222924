import { JobGeography } from '../../jobsTypes';

import { geographyOptionsHash } from '../../jobs.data';
import { TalentPoolGeography } from '../../../talentPools/talentPoolsTypes';

function renderJobGeography(geography: JobGeography | TalentPoolGeography) {
  return geographyOptionsHash[geography];
}

export default renderJobGeography;
