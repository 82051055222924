import {
  Button,
  ButtonProps
} from '../../../../../../../../../../../helpers/Button';

import { ButtonGroup } from '@chakra-ui/react';

const ViewJobCandidateModalPhoneSidebarCallResponseDiscardButton = ({
  ...props
}: ButtonProps) => (
  <ButtonGroup mt={3}>
    <Button
      ml="auto"
      size="small"
      hierarchy="link"
      colorScheme="red"
      {...props}
    >
      Discard
    </Button>
  </ButtonGroup>
);

export default ViewJobCandidateModalPhoneSidebarCallResponseDiscardButton;
