import {
  FetchSkillBadgesQuery,
  FetchSkillBadgesCountType,
  FetchSkillBadgesPage,
  FetchSkillBadgesPageSize,
  FetchSkillBadgesFilters,
  FetchSkillBadgesSort,
  FetchSkillBadgesIndexQueryName,
  FetchSkillBadgesIndexCountType,
  FetchSkillBadgesIndexPage,
  FetchSkillBadgesIndexPageSize,
  FetchSkillBadgesIndexFilters,
  FetchSkillBadgesIndexSort,
  FetchSkillBadgeQuery,
  FetchSkillBadgeIndexQueryName,
  CreateSkillBadgeError,
  UpdateSkillBadgeError,
  DeleteSkillBadgeError,
  SkillBadgeId,
  SkillBadgeNanoId,
  SkillBadgeRating,
  SkillBadgeExperience,
  SkillBadgeCertified,
  SkillBadgeRequirements,
  SkillBadgeSkillId,
  SkillBadgeJobId,
  SkillBadgeUserId,
  SkillBadgeJobCandidateId,
  SkillBadgeJobResumeId,
  SkillBadgeJobResumeRecruiterId,
  SkillBadgeTalentPoolId,
  SkillBadgeProfileId,
  SkillBadgeContactId
} from './skillBadgesTypes';

import { BaseBffRequests } from '../BaseBffRequests';

import { SkillBadgesBffRoutes } from './SkillBadgesBffRoutes';

interface FetchSkillBadgesRequestParams {
  query: FetchSkillBadgesQuery;
  countType?: FetchSkillBadgesCountType;
  page?: FetchSkillBadgesPage;
  pageSize?: FetchSkillBadgesPageSize;
  filters?: FetchSkillBadgesFilters;
  sort?: FetchSkillBadgesSort;
}

interface FetchSkillBadgesIndexRequestParams {
  queryName: FetchSkillBadgesIndexQueryName;
  countType?: FetchSkillBadgesIndexCountType;
  page?: FetchSkillBadgesIndexPage;
  pageSize?: FetchSkillBadgesIndexPageSize;
  filters?: FetchSkillBadgesIndexFilters;
  sort?: FetchSkillBadgesIndexSort;
}

interface FetchSkillBadgeRequestParams {
  query: FetchSkillBadgeQuery;
}

interface FetchSkillBadgeIndexRequestParams {
  queryName: FetchSkillBadgeIndexQueryName;
}

export interface SkillBadgeRequestData {
  rating?: SkillBadgeRating;
  experience?: SkillBadgeExperience;
  certified?: SkillBadgeCertified;
  requirements?: SkillBadgeRequirements;
}

export interface SkillBadgeData {
  rating?: SkillBadgeRating;
  experience?: SkillBadgeExperience;
  certified?: SkillBadgeCertified;
  // employmentIds?: MayBeSkillBadgeEmploymentIds;
}

export interface CreateJobSkillBadgeData extends SkillBadgeData {
  skillId: SkillBadgeSkillId;
  jobId: SkillBadgeJobId;
  userId: SkillBadgeUserId;
  id?: SkillBadgeId;
  nanoId: SkillBadgeNanoId;
}

export interface CreateJobCandidateSkillBadgeData extends SkillBadgeData {
  skillId: SkillBadgeSkillId;
  jobCandidateId: SkillBadgeJobCandidateId;
  userId: SkillBadgeUserId;
  id?: SkillBadgeId;
  nanoId: SkillBadgeNanoId;
}

export interface CreateJobResumeSkillBadgeData extends SkillBadgeData {
  skillId: SkillBadgeSkillId;
  jobResumeId: SkillBadgeJobResumeId;
  userId: SkillBadgeUserId;
  id?: SkillBadgeId;
  nanoId: SkillBadgeNanoId;
}

export interface CreateJobResumeRecruiterSkillBadgeData extends SkillBadgeData {
  skillId: SkillBadgeSkillId;
  jobResumeRecruiterId: SkillBadgeJobResumeRecruiterId;
  userId: SkillBadgeUserId;
  nanoId: SkillBadgeNanoId;
  id?: SkillBadgeId;
}

export interface CreateTalentPoolSkillBadgeData extends SkillBadgeData {
  skillId: SkillBadgeSkillId;
  talentPoolId: SkillBadgeTalentPoolId;
  profileId: SkillBadgeProfileId;
  contactId: SkillBadgeContactId;
  userId: SkillBadgeUserId;
  id?: SkillBadgeId;
  nanoId: SkillBadgeNanoId;
}

export type CreateSkillBadgeData =
  | CreateJobSkillBadgeData
  | CreateJobCandidateSkillBadgeData
  | CreateJobResumeSkillBadgeData
  | CreateJobResumeRecruiterSkillBadgeData
  | CreateTalentPoolSkillBadgeData;

export interface UpdateSkillBadgeData extends SkillBadgeData {
  id?: SkillBadgeId;
  nanoId?: SkillBadgeNanoId;
}

export interface CreateSkillBadgeRequestData extends SkillBadgeRequestData {
  nanoId: SkillBadgeNanoId;
}

export interface UpdateSkillBadgeRequestData extends SkillBadgeRequestData {
  rating?: SkillBadgeRating;
  experience?: SkillBadgeExperience;
  certified?: SkillBadgeCertified;
  requirements?: SkillBadgeRequirements;
}

export interface DeleteSkillBadgeRequestData {
  skillBadgeNanoId: SkillBadgeNanoId;
}

export interface CreateSkillBadgeErrorResponse {
  message: CreateSkillBadgeError;
}

export interface UpdateSkillBadgeErrorResponse {
  message: UpdateSkillBadgeError;
}

export interface DeleteSkillBadgeErrorResponse {
  message: DeleteSkillBadgeError;
}

export class SkillBadgesBffRequests extends BaseBffRequests {
  static fetchSkillBadges<T>(params: FetchSkillBadgesRequestParams) {
    return this.fetchItems<T>(
      SkillBadgesBffRoutes.fetchSkillBadgesRoute(),
      params
    );
  }

  static fetchSkillBadgesIndex<T>(params: FetchSkillBadgesIndexRequestParams) {
    return this.fetchItemsIndex<T>(
      SkillBadgesBffRoutes.fetchSkillBadgesIndexRoute(),
      params
    );
  }

  static fetchSkillBadge<T>(
    skillBadgeId: SkillBadgeId,
    params: FetchSkillBadgeRequestParams
  ) {
    return this.fetchItem<T>(
      SkillBadgesBffRoutes.fetchSkillBadgeRoute(skillBadgeId),
      params
    );
  }

  static fetchSkillBadgeIndex<T>(
    skillBadgeNanoId: SkillBadgeNanoId,
    params: FetchSkillBadgeIndexRequestParams
  ) {
    return this.fetchItemIndex<T>(
      SkillBadgesBffRoutes.fetchSkillBadgeIndexRoute(skillBadgeNanoId),
      params
    );
  }

  static fetchSkillBadgeByNanoId<T>(
    skillBadgeNanoId: SkillBadgeNanoId,
    params: FetchSkillBadgeRequestParams
  ) {
    return this.fetchItemByNanoId<T>(
      SkillBadgesBffRoutes.fetchSkillBadgeByNanoIdRoute(skillBadgeNanoId),
      params
    );
  }

  static createSkillBadge<T>(
    createSkillBadgeData: CreateSkillBadgeRequestData
  ) {
    return this.createItem<T, CreateSkillBadgeRequestData>(
      SkillBadgesBffRoutes.createSkillBadgeRoute(),
      createSkillBadgeData
    );
  }

  static async updateSkillBadge<T>(
    updateSkillBadgeNanoId: SkillBadgeNanoId,
    updateSkillBadgeData: UpdateSkillBadgeRequestData
  ) {
    return this.updateItem<T, UpdateSkillBadgeRequestData>(
      SkillBadgesBffRoutes.updateSkillBadgeRoute(updateSkillBadgeNanoId),
      updateSkillBadgeData
    );
  }

  static async deleteSkillBadge<T>(deleteSkillBadgeNanoId: SkillBadgeNanoId) {
    return this.deleteItem<T>(
      SkillBadgesBffRoutes.deleteSkillBadgeRoute(deleteSkillBadgeNanoId)
    );
  }
}
