import { useCallback, useEffect, useState } from 'react';

import { AlertMessage } from '../../../../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../../../../helpers/LoadingSkeleton';
import { CollapseFormBox } from '../../../../../../../../helpers/CollapseFormBox';

import {
  FetchWorkflowStageTaskCacheTime,
  WorkflowStageTaskId
} from '../../../../../../../../main/workflowStageTasks/workflowStageTasksTypes';
import { WorkflowStageNanoId } from '../../../../../../../../main/workflowStages/workflowStagesTypes';
import {
  JobCandidateId,
  JobCandidateImage,
  JobCandidateName,
  JobCandidateNewWorkflowStageTaskAt,
  JobCandidateWorkflowStageTaskApplicationType,
  JobCandidateWorkflowStageTaskApplicationTypes,
  JobCandidateWorkflowStageTaskId
} from '../../../../../../../../main/jobCandidates/jobCandidatesTypes';

import {
  fetchWorkflowStageTaskQuery,
  FetchWorkflowStageTaskResponse
} from '../../../../../../../../main/workflowStageTasks/queries/fetchWorkflowStageTask.query';

import { WorkflowStageTasksCache } from '../../../../../../../../main/workflowStageTasks/WorkflowStageTasksCache';

import { useFetchWorkflowStageTask } from '../../../../../../../../main/workflowStageTasks/hooks/useFetchWorkflowStageTask';

import { EditWorkflowStageTaskForm } from '../../../../../../../../main/workflowStageTasks/components/forms/EditWorkflowStageTaskForm';

import { DateUtils } from '../../../../../../../../utils/DateUtils';
import { JobCandidatesRequests } from '../../../../../../../../main/jobCandidates/JobCandidatesRequests';

interface EditWorkflowStageTaskContainerProps {
  workflowStageNanoId: WorkflowStageNanoId;
  workflowStageTaskId: WorkflowStageTaskId;
  onCloseMenu: () => void;
  isOpen: boolean;
  jobCandidate: {
    id: JobCandidateId;
  };
  allJobCandidates: {
    id: JobCandidateId;
    name: JobCandidateName;
    image: JobCandidateImage;
  }[];
  handleIsTaskUpdatedTrue: () => void;
}

function EditWorkflowStageTaskContainer({
  workflowStageNanoId,
  workflowStageTaskId,
  onCloseMenu,
  isOpen,
  jobCandidate,
  allJobCandidates,
  handleIsTaskUpdatedTrue
}: EditWorkflowStageTaskContainerProps) {
  const {
    workflowStageTask,
    workflowStageTaskErrorMessage,
    workflowStageTaskIsFetched
  } = useFetchWorkflowStageTask<FetchWorkflowStageTaskResponse>({
    workflowStageTaskId,
    query: fetchWorkflowStageTaskQuery,
    cacheKey: WorkflowStageTasksCache.showCacheKey(),
    cacheTime: 0 as FetchWorkflowStageTaskCacheTime
  });

  const [selectedJobCandidateId, setSelectedJobCandidateId] =
    useState<JobCandidateId>(jobCandidate.id);

  const [selectJobCandidateIsLoading, setSelectJobCandidateIsLoading] =
    useState<boolean>(false);

  const handleSelectCandidate = useCallback<
    (jobCandidateId: JobCandidateId) => void
  >((jobCandidateId) => {
    setSelectedJobCandidateId(jobCandidateId);
  }, []);

  useEffect(() => {
    setSelectedJobCandidateId(jobCandidate.id);
  }, [jobCandidate.id]);

  const handleSaveTaskChange = useCallback(async () => {
    if (selectedJobCandidateId !== jobCandidate.id) {
      setSelectJobCandidateIsLoading(true);
      await JobCandidatesRequests.updateJobCandidate(selectedJobCandidateId, {
        workflowStageTaskId:
          workflowStageTaskId as JobCandidateWorkflowStageTaskId,
        newWorkflowStageTaskAt:
          DateUtils.now() as JobCandidateNewWorkflowStageTaskAt,
        workflowStageTaskScheduledAt: null,
        workflowStageTaskApplicationType:
          JobCandidateWorkflowStageTaskApplicationTypes.NONE as JobCandidateWorkflowStageTaskApplicationType,
        dueDate: null,
        viewedAt: null
      });

      await JobCandidatesRequests.updateJobCandidate(jobCandidate.id, {
        workflowStageTaskId: null as unknown as JobCandidateWorkflowStageTaskId,
        newWorkflowStageTaskAt: null,
        workflowStageTaskScheduledAt: null,
        workflowStageTaskApplicationType:
          JobCandidateWorkflowStageTaskApplicationTypes.NONE as JobCandidateWorkflowStageTaskApplicationType,
        dueDate: null,
        viewedAt: null
      });

      handleIsTaskUpdatedTrue();
      setSelectJobCandidateIsLoading(false);
    }
    onCloseMenu();
  }, [
    jobCandidate.id,
    selectedJobCandidateId,
    handleIsTaskUpdatedTrue,
    workflowStageTaskId,
    onCloseMenu
  ]);

  return (
    <CollapseFormBox title="Edit Task" isOpen={isOpen}>
      <AlertMessage message={workflowStageTaskErrorMessage} />
      <LoadingSkeleton loaded={workflowStageTaskIsFetched}>
        {workflowStageTask ? (
          <EditWorkflowStageTaskForm
            workflowStageNanoId={workflowStageNanoId}
            workflowStageTask={workflowStageTask}
            onSave={handleSaveTaskChange}
            onCancel={onCloseMenu}
            gridColumns={1}
            jobCandidate={{ id: selectedJobCandidateId }}
            allJobCandidates={allJobCandidates}
            handleSelectCandidate={handleSelectCandidate}
            selectJobCandidateIsLoading={selectJobCandidateIsLoading}
          />
        ) : null}
      </LoadingSkeleton>
    </CollapseFormBox>
  );
}

export default EditWorkflowStageTaskContainer;
