import {
  MayBe,
  FieldBrand,
  ItemID,
  ItemUUID,
  ItemNanoId,
  ItemCreatedAt
} from '../../../types';

import {
  UserId,
  UserNanoId,
  UserName,
  UserImage
} from '../../users/usersTypes';

import {
  WorkflowStageId,
  WorkflowStageName,
  WorkflowStageNanoId
} from '../../workflowStages/workflowStagesTypes';

export type WorkflowStageTaskId = FieldBrand<ItemID, 'WorkflowStageTaskId'>;
export type MayBeWorkflowStageTaskId = MayBe<WorkflowStageTaskId>;
export type WorkflowStageTaskUuid = FieldBrand<
  ItemUUID,
  'WorkflowStageTaskUuid'
>;
export type MayBeWorkflowStageTaskUuid = MayBe<WorkflowStageTaskUuid>;
export type WorkflowStageTaskNanoId = FieldBrand<
  ItemNanoId,
  'WorkflowStageTaskNanoId'
>;
export type MayBeWorkflowStageTaskNanoId = MayBe<WorkflowStageTaskNanoId>;
export type WorkflowStageTaskName = FieldBrand<string, 'WorkflowStageTaskName'>;
export type WorkflowStageTaskApplication = FieldBrand<
  WorkflowStageTaskApplications,
  'WorkflowStageTaskApplication'
>;
export type WorkflowStageTaskDueDate = FieldBrand<
  WorkflowStageTaskDueDates,
  'WorkflowStageTaskDueDate'
>;
export type WorkflowStageTaskAutomation = FieldBrand<
  WorkflowStageTaskAutomations,
  'WorkflowStageTaskAutomation'
>;
export type WorkflowStageTaskArrangement = FieldBrand<
  number,
  'WorkflowStageTaskArrangement'
>;
export type WorkflowStageTaskUserId = FieldBrand<
  UserId,
  'WorkflowStageTaskUserId'
>;
export type WorkflowStageTaskUser = FieldBrand<
  {
    id: UserId;
    nanoId: UserNanoId;
    name: UserName;
    image: UserImage;
  },
  'WorkflowStageTaskUser'
>;
export type WorkflowStageTaskWorkflowStageId = FieldBrand<
  WorkflowStageId,
  'WorkflowStageTaskWorkflowStageId'
>;
export type WorkflowStageTaskWorkflowStage = FieldBrand<
  {
    id: WorkflowStageId;
    nanoId: WorkflowStageNanoId;
    name: WorkflowStageName;
  },
  'WorkflowStageTaskWorkflowStage'
>;
export type WorkflowStageTaskCreatedAt = FieldBrand<
  ItemCreatedAt,
  'WorkflowStageTaskCreatedAt'
>;

export const enum WorkflowStageTaskApplications {
  NONE = 'none',
  EMAIL = 'email',
  CALENDAR = 'calendar',
  OFFER = 'offer',
  PHONE = 'phone'
}

export const enum WorkflowStageTaskDueDates {
  NONE = 'none',
  TODAY = 'today',
  ONE_DAY = 'one_day',
  TWO_DAYS = 'two_days',
  THREE_DAYS = 'three_days',
  FOUR_DAYS = 'four_days',
  FIVE_DAYS = 'five_days'
}

export const enum WorkflowStageTaskAutomations {
  NONE = 'none',
  SEND_EMAIL = 'send_email',
  SCHEDULE_MEETING = 'schedule_meeting',
  SCHEDULE_INTERVIEW = 'schedule_interview',
  PHONE_OTHER = 'phone_other',
  RECRUITING_CALL = 'recruiting_call',
  SCREEN_CALL = 'screen_call',
  FOLLOW_UP_CALL = 'follow_up_call',
  DEBRIEF_CALL = 'debrief_call',
  SCHEDULING_CALL = 'scheduling_call',
  CALENDAR_OTHER = 'calendar_other',
  SHARE_PROFILE = 'share_profile',
  REQUEST_RESUME = 'request_resume',
  EMAIL_OTHER = 'email_other',
  OFFER_APPROVAL = 'offer_approval'
}

export const workflowStageTaskApplications: WorkflowStageTaskApplication[] = [
  WorkflowStageTaskApplications.EMAIL as WorkflowStageTaskApplication,
  WorkflowStageTaskApplications.CALENDAR as WorkflowStageTaskApplication,
  WorkflowStageTaskApplications.OFFER as WorkflowStageTaskApplication,
  WorkflowStageTaskApplications.PHONE as WorkflowStageTaskApplication
];

export const workflowStageTaskDueDates: WorkflowStageTaskDueDate[] = [
  WorkflowStageTaskDueDates.TODAY as WorkflowStageTaskDueDate,
  WorkflowStageTaskDueDates.ONE_DAY as WorkflowStageTaskDueDate,
  WorkflowStageTaskDueDates.TWO_DAYS as WorkflowStageTaskDueDate,
  WorkflowStageTaskDueDates.THREE_DAYS as WorkflowStageTaskDueDate,
  WorkflowStageTaskDueDates.FOUR_DAYS as WorkflowStageTaskDueDate,
  WorkflowStageTaskDueDates.FIVE_DAYS as WorkflowStageTaskDueDate
];

export const workflowStageTaskAutomations = {
  [WorkflowStageTaskApplications.EMAIL]: [
    WorkflowStageTaskAutomations.SEND_EMAIL as WorkflowStageTaskAutomation,
    WorkflowStageTaskAutomations.SHARE_PROFILE as WorkflowStageTaskAutomation,
    WorkflowStageTaskAutomations.REQUEST_RESUME as WorkflowStageTaskAutomation,
    WorkflowStageTaskAutomations.EMAIL_OTHER as WorkflowStageTaskAutomation
  ],
  [WorkflowStageTaskApplications.PHONE]: [
    WorkflowStageTaskAutomations.RECRUITING_CALL as WorkflowStageTaskAutomation,
    WorkflowStageTaskAutomations.SCREEN_CALL as WorkflowStageTaskAutomation,
    WorkflowStageTaskAutomations.FOLLOW_UP_CALL as WorkflowStageTaskAutomation,
    WorkflowStageTaskAutomations.DEBRIEF_CALL as WorkflowStageTaskAutomation,
    WorkflowStageTaskAutomations.SCHEDULING_CALL as WorkflowStageTaskAutomation,
    WorkflowStageTaskAutomations.PHONE_OTHER as WorkflowStageTaskAutomation
  ],
  [WorkflowStageTaskApplications.CALENDAR]: [
    WorkflowStageTaskAutomations.SCHEDULE_MEETING as WorkflowStageTaskAutomation,
    WorkflowStageTaskAutomations.SCHEDULE_INTERVIEW as WorkflowStageTaskAutomation,
    WorkflowStageTaskAutomations.CALENDAR_OTHER as WorkflowStageTaskAutomation
  ],
  [WorkflowStageTaskApplications.OFFER]: [
    WorkflowStageTaskAutomations.OFFER_APPROVAL as WorkflowStageTaskAutomation
  ]
};

export const enum WorkflowStageTaskFields {
  ID = 'id',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  NAME = 'name',
  APPLICATION = 'application',
  ARRANGEMENT = 'arrangement',
  WORKFLOW_STAGE_ID = 'workflowStageId',
  USER_ID = 'userId',
  USER = 'user',
  CREATED_AT = 'createdAt',
  DUE_DATE = 'dueDate',
  AUTOMATION = 'automation'
}
