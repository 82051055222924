import snakeCase from 'lodash/snakeCase';

import {
  FetchCandidateSubmissionJobCandidatesQuery,
  CandidateSubmissionJobCandidateCreatedAt,
  CandidateSubmissionJobCandidateFields,
  CandidateSubmissionJobCandidateId,
  CandidateSubmissionJobCandidateNanoId,
  CandidateSubmissionJobCandidateJobCandidateId,
  CandidateSubmissionJobCandidateJobCandidate,
  CandidateSubmissionJobCandidateCandidateSubmissionId,
  CandidateSubmissionJobCandidateCandidateSubmission
} from '../candidateSubmissionJobCandidatesTypes';

export interface FetchCandidateSubmissionJobCandidatesResponse {
  [CandidateSubmissionJobCandidateFields.ID]: CandidateSubmissionJobCandidateId;
  [CandidateSubmissionJobCandidateFields.NANO_ID]: CandidateSubmissionJobCandidateNanoId;
  [CandidateSubmissionJobCandidateFields.CREATED_AT]: CandidateSubmissionJobCandidateCreatedAt;
  [CandidateSubmissionJobCandidateFields.JOB_CANDIDATE_ID]: CandidateSubmissionJobCandidateJobCandidateId;
  [CandidateSubmissionJobCandidateFields.JOB_CANDIDATE]: CandidateSubmissionJobCandidateJobCandidate;
  [CandidateSubmissionJobCandidateFields.CANDIDATE_SUBMISSION_ID]: CandidateSubmissionJobCandidateCandidateSubmissionId;
  [CandidateSubmissionJobCandidateFields.CANDIDATE_SUBMISSION]: CandidateSubmissionJobCandidateCandidateSubmission;
  [CandidateSubmissionJobCandidateFields.CREATED_AT]: CandidateSubmissionJobCandidateCreatedAt;
}

export const fetchCandidateSubmissionJobCandidatesColumns = [
  CandidateSubmissionJobCandidateFields.ID,
  CandidateSubmissionJobCandidateFields.NANO_ID,
  CandidateSubmissionJobCandidateFields.CREATED_AT
];

const fetchCandidateSubmissionJobCandidatesSBColumns =
  fetchCandidateSubmissionJobCandidatesColumns.map(
    (fetchCandidateSubmissionJobCandidatesColumn) =>
      snakeCase(fetchCandidateSubmissionJobCandidatesColumn)
  );

export const fetchCandidateSubmissionJobCandidatesQuery =
  fetchCandidateSubmissionJobCandidatesSBColumns.join(
    ','
  ) as FetchCandidateSubmissionJobCandidatesQuery;
