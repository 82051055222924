import { useToast, ToastPosition } from '@chakra-ui/react';

interface ChakraToastOptions {
  position?: ToastPosition;
  variant?: 'subtle' | 'solid' | 'left-accent' | 'top-accent';
  status?: 'success' | 'error' | 'warning' | 'info';
  title?: string;
}

function useChakraToast(
  opts: ChakraToastOptions = {
    position: 'top-right',
    variant: 'solid',
    status: 'success'
  }
) {
  return useToast(opts);
}

export default useChakraToast;
