import { Icon, IconProps } from '@chakra-ui/icon';

const FilterIcon = ({ w = '24px', h = '24px', ...props }: IconProps) => (
  <Icon viewBox="0 0 24 24" w={w} h={h} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 9H6a.75.75 0 1 0 0 1.5h12A.75.75 0 1 0 18 9ZM20.25 4.5H3.75a.75.75 0 1 0 0 1.5h16.5a.75.75 0 1 0 0-1.5ZM15.75 13.5h-7.5a.75.75 0 1 0 0 1.5h7.5a.75.75 0 1 0 0-1.5ZM13.5 18h-3a.75.75 0 1 0 0 1.5h3a.75.75 0 1 0 0-1.5Z"
      fill="currentColor"
    />
  </Icon>
);
export default FilterIcon;
