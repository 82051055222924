import {
  UserId,
  UserUuid,
  UserNanoId,
  UserName,
  UserEmail,
  UserTitle,
  UserPhone,
  MayBeUserApprovedAt,
  MayBeUserProfileCreatedAt,
  MayBeUserWorkEmailApprovedAt,
  MayBeUserImageId,
  MayBeUserImage,
  MayBeUserCompanyId,
  MayBeUserCompany,
  MayBeUserCountryId,
  MayBeUserStateId,
  MayBeUserState,
  MayBeUserCityId,
  MayBeUserCity,
  MayBeUserJobResumeRecruiterId,
  MayBeUserJobResumeRecruiter,
  UserCompanyMembers,
  MayBeUserCompanyRoleName,
  UserRoleNames,
  UserCreatedAt
} from '../../../main/users/usersTypes';

import { DateUtils } from '../../../utils/DateUtils';

import { AuthenticatedUserActions } from './useAuth.types';

export class AnonymousUser {
  id: UserId;
  uuid: UserUuid;
  nanoId: UserNanoId;
  name: UserName;
  email: UserEmail;
  title: UserTitle;
  phone: UserPhone;
  approvedAt: MayBeUserApprovedAt;
  profileCreatedAt: MayBeUserProfileCreatedAt;
  workEmailApprovedAt: MayBeUserWorkEmailApprovedAt;
  imageId: MayBeUserImageId;
  image: MayBeUserImage;
  companyId: MayBeUserCompanyId;
  company: MayBeUserCompany;
  countryId: MayBeUserCountryId;
  stateId: MayBeUserStateId;
  state: MayBeUserState;
  cityId: MayBeUserCityId;
  city: MayBeUserCity;
  jobResumeRecruiterId: MayBeUserJobResumeRecruiterId;
  jobResumeRecruiter: MayBeUserJobResumeRecruiter;
  companyMembers: UserCompanyMembers;
  createdAt: UserCreatedAt;
  roleNames: UserRoleNames;
  companyRoleName: MayBeUserCompanyRoleName;
  actions: AuthenticatedUserActions;

  constructor() {
    this.id = '0' as UserId;
    this.uuid = 'anonymous-uuid' as UserUuid;
    this.nanoId = 'anonymous-nanoId' as UserNanoId;
    this.name = 'Anonymous' as UserName;
    this.email = 'anonymous@email.com' as UserEmail;
    this.title = 'Anonymous' as UserTitle;
    this.phone = '' as UserPhone;
    this.approvedAt = null as MayBeUserApprovedAt;
    this.profileCreatedAt = null as MayBeUserProfileCreatedAt;
    this.workEmailApprovedAt = null as MayBeUserWorkEmailApprovedAt;
    this.imageId = null;
    this.image = null;
    this.companyId = null;
    this.company = null;
    this.countryId = null;
    this.stateId = null;
    this.state = null;
    this.cityId = null;
    this.city = null;
    this.jobResumeRecruiterId = null;
    this.jobResumeRecruiter = null;
    this.companyMembers = [];
    this.roleNames = [];
    this.companyRoleName = null;
    this.createdAt = DateUtils.now() as UserCreatedAt;

    this.actions = {} as AuthenticatedUserActions;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  can(action: string) {
    return false;
  }

  hasOneOfPermissions(actions: string[]) {
    return actions.some((action) => this.can(action));
  }

  hasAllPermissions(actions: string[]) {
    return actions.every((action) => this.can(action));
  }

  isAnonymous() {
    return true;
  }
}
