import { HStack, Stack } from '@chakra-ui/react';
import { Text } from '../../../../../../../../../../helpers/Text';
import { calendarEvents } from '../../calendarEvents';
import { ViewJobCandidateDetailsProfileCalendarEventItem } from '../ViewJobCandidateDetailsProfileCalendarEventItem';
import {
  DateUtils,
  DateTimeFormats
} from '../../../../../../../../../../utils/DateUtils';

interface ViewJobCandidateDetailsProfileCalendarEventsProps {
  selectedDay: string;
}

export default function ViewJobCandidateDetailsProfileCalendarEvents({
  selectedDay
}: ViewJobCandidateDetailsProfileCalendarEventsProps) {
  const selectedDayKey = DateUtils.formatDate(
    selectedDay,
    DateTimeFormats.YYYY_LL_DD
  ) as string;
  const eventsForSelectedDay = calendarEvents[selectedDayKey] || [];

  return (
    <Stack spacing={3}>
      <Stack spacing={3}>
        <HStack>
          <Text textStyle="body1Medium">
            {DateUtils.formatDate(selectedDay, DateTimeFormats.MMM_DD)}
          </Text>
          <Text color="gray.600">
            {DateUtils.formatDate(selectedDay, DateTimeFormats.CCCC)}
          </Text>
        </HStack>

        {eventsForSelectedDay.map((event) => (
          <ViewJobCandidateDetailsProfileCalendarEventItem
            key={event.id}
            event={event}
          />
        ))}

        {eventsForSelectedDay.length === 0 && (
          <Text color="gray.500">No events today</Text>
        )}
      </Stack>
    </Stack>
  );
}
