import { Icon, IconProps } from '@chakra-ui/icon';

const FilesIcon = ({
  w = '24px',
  h = '24px',
  fill = 'gray.500',
  ...props
}: IconProps) => (
  <Icon viewBox="0 0 24 24" w={w} h={h} fill={fill} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.285 4.5h11.429c.42 0 .762.336.762.75v13.5c0 .414.341.75.762.75.42 0 .762-.336.762-.75v-15a.756.756 0 0 0-.762-.75H6.285a.756.756 0 0 0-.762.75c0 .414.342.75.762.75Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.047 15h4.571v1.5H7.047V15Zm0-3h7.619v1.5h-7.62V12Zm0-3h7.619v1.5h-7.62V9ZM4.762 21h12.19c.421 0 .762-.336.762-.75V6a.756.756 0 0 0-.762-.75H4.762A.756.756 0 0 0 4 6v14.25c0 .414.341.75.762.75Z"
    />
  </Icon>
);
export default FilesIcon;
