import {
  FetchJobCandidateCacheKey,
  FetchJobCandidateNanoID,
  FetchJobCandidateQuery,
  FetchJobCandidateEnabled,
  FetchJobCandidateError,
  FetchJobCandidateErrorMessage,
  FetchJobCandidateIsFetched,
  FetchJobCandidateIsRefetching,
  FetchJobCandidateIsLoading,
  FetchJobCandidateIsPlaceholderData
} from '../../jobCandidatesTypes';
import { useFetchItemByNanoId } from '../../../common/hooks/useFetchItemByNanoId';
import { JobCandidatesBffRequests } from '../../JobCandidatesBffRequests';

interface FetchJobCandidateByNanoIdOptions<FetchJobCandidateResponseType> {
  jobCandidateNanoId: FetchJobCandidateNanoID;
  query: FetchJobCandidateQuery;
  cacheKey: FetchJobCandidateCacheKey;
  enabled?: FetchJobCandidateEnabled;
  placeholderJobCandidate?: FetchJobCandidateResponseType;
  placeholderData?: () => FetchJobCandidateResponseType;
  onSuccess?: (data: FetchJobCandidateResponseType | null) => void;
}

function useFetchJobCandidateByNanoId<FetchJobCandidateResponseType>({
  jobCandidateNanoId,
  query,
  cacheKey,
  enabled,
  placeholderJobCandidate,
  placeholderData,
  onSuccess
}: FetchJobCandidateByNanoIdOptions<FetchJobCandidateResponseType>) {
  const {
    item,
    itemError,
    itemErrorMessage,
    itemIsFetched,
    itemIsRefetching,
    itemIsLoading,
    itemIsPlaceholderData,
    refetchItem
  } = useFetchItemByNanoId<
    FetchJobCandidateResponseType,
    FetchJobCandidateError
  >({
    itemNanoId: jobCandidateNanoId,
    cacheKey,
    enabled,
    placeholderItem: placeholderJobCandidate,
    placeholderData,
    onSuccess,
    queryFn: () =>
      JobCandidatesBffRequests.fetchJobCandidateByNanoId<FetchJobCandidateResponseType>(
        jobCandidateNanoId,
        {
          query
        }
      )
  });

  return {
    jobCandidate: item,
    jobCandidateError: itemError,
    jobCandidateErrorMessage: itemErrorMessage as FetchJobCandidateErrorMessage,
    jobCandidateIsFetched: itemIsFetched as FetchJobCandidateIsFetched,
    jobCandidateIsRefetching: itemIsRefetching as FetchJobCandidateIsRefetching,
    jobCandidateIsLoading: itemIsLoading as FetchJobCandidateIsLoading,
    jobCandidateIsPlaceholderData:
      itemIsPlaceholderData as FetchJobCandidateIsPlaceholderData,
    refetchJobCandidate: refetchItem
  };
}

export default useFetchJobCandidateByNanoId;
