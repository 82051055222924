import { FetchCountriesCacheKey, FetchCountryCacheKey } from './countriesTypes';

export class CountriesCache {
  static indexCacheKey() {
    return 'countries' as FetchCountriesCacheKey;
  }

  static showCacheKey() {
    return 'country' as FetchCountryCacheKey;
  }

  static companyFormCacheKey() {
    return 'countries-company-form' as FetchCountriesCacheKey;
  }
}
