import {
  FetchSkillsQuery,
  FetchSkillsCountType,
  FetchSkillsPage,
  FetchSkillsPageSize,
  FetchSkillsFilters,
  FetchSkillsSort,
  FetchSkillsIndexQueryName,
  FetchSkillsIndexCountType,
  FetchSkillsIndexPage,
  FetchSkillsIndexPageSize,
  FetchSkillsIndexFilters,
  FetchSkillsIndexSort,
  FetchSkillQuery,
  FetchSkillIndexQueryName,
  CreateSkillError,
  UpdateSkillError,
  DeleteSkillError,
  SkillId,
  SkillNanoId,
  SkillName,
  MayBeSkillKind,
  SkillUserId
} from './skillsTypes';

import { BaseBffRequests } from '../BaseBffRequests';

import { SkillsBffRoutes } from './SkillsBffRoutes';

interface FetchSkillsRequestParams {
  query: FetchSkillsQuery;
  countType?: FetchSkillsCountType;
  page?: FetchSkillsPage;
  pageSize?: FetchSkillsPageSize;
  filters?: FetchSkillsFilters;
  sort?: FetchSkillsSort;
}

interface FetchSkillsIndexRequestParams {
  queryName: FetchSkillsIndexQueryName;
  countType?: FetchSkillsIndexCountType;
  page?: FetchSkillsIndexPage;
  pageSize?: FetchSkillsIndexPageSize;
  filters?: FetchSkillsIndexFilters;
  sort?: FetchSkillsIndexSort;
}

interface FetchSkillRequestParams {
  query: FetchSkillQuery;
}

interface FetchSkillIndexRequestParams {
  queryName: FetchSkillIndexQueryName;
}

export interface SkillRequestData {
  name?: SkillName;
  kind?: MayBeSkillKind;
}

export interface CreateSkillRequestData extends SkillRequestData {
  id?: SkillId;
  userId: SkillUserId;
  nanoId: SkillNanoId;
}

export interface UpdateSkillRequestData extends SkillRequestData {
  name?: SkillName;
}

export interface DeleteSkillRequestData {
  skillNanoId: SkillNanoId;
}

export interface CreateSkillErrorResponse {
  message: CreateSkillError;
}

export interface UpdateSkillErrorResponse {
  message: UpdateSkillError;
}

export interface DeleteSkillErrorResponse {
  message: DeleteSkillError;
}

export class SkillsBffRequests extends BaseBffRequests {
  static fetchSkills<T>(params: FetchSkillsRequestParams) {
    return this.fetchItems<T>(SkillsBffRoutes.fetchSkillsRoute(), params);
  }

  static fetchSkillsIndex<T>(params: FetchSkillsIndexRequestParams) {
    return this.fetchItemsIndex<T>(
      SkillsBffRoutes.fetchSkillsIndexRoute(),
      params
    );
  }

  static fetchSkill<T>(skillId: SkillId, params: FetchSkillRequestParams) {
    return this.fetchItem<T>(SkillsBffRoutes.fetchSkillRoute(skillId), params);
  }

  static fetchSkillIndex<T>(
    skillNanoId: SkillNanoId,
    params: FetchSkillIndexRequestParams
  ) {
    return this.fetchItemIndex<T>(
      SkillsBffRoutes.fetchSkillIndexRoute(skillNanoId),
      params
    );
  }

  static fetchSkillByNanoId<T>(
    skillNanoId: SkillNanoId,
    params: FetchSkillRequestParams
  ) {
    return this.fetchItemByNanoId<T>(
      SkillsBffRoutes.fetchSkillByNanoIdRoute(skillNanoId),
      params
    );
  }

  static createSkill<T>(createSkillData: CreateSkillRequestData) {
    return this.createItem<T, CreateSkillRequestData>(
      SkillsBffRoutes.createSkillRoute(),
      createSkillData
    );
  }

  static async updateSkill<T>(
    updateSkillNanoId: SkillNanoId,
    updateSkillData: UpdateSkillRequestData
  ) {
    return this.updateItem<T, UpdateSkillRequestData>(
      SkillsBffRoutes.updateSkillRoute(updateSkillNanoId),
      updateSkillData
    );
  }

  static async deleteSkill<T>(deleteSkillNanoId: SkillNanoId) {
    return this.deleteItem<T>(
      SkillsBffRoutes.deleteSkillRoute(deleteSkillNanoId)
    );
  }
}
