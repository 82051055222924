import {
  Box,
  ButtonGroup,
  Collapse,
  Stack,
  useDisclosure
} from '@chakra-ui/react';
import PhoneItem from '../../../VewiJobCandidateModalHeader/components/ViewJobCandidateModalCallDropdown/components/PhoneItem';
import { InputField } from '../../../../../../../../../helpers/forms/formFields/InputField';
import { Button } from '../../../../../../../../../helpers/Button';

type ViewJobCandidateModalPhoneSidebarPhoneNumbersHelperProps = {
  companyPhoneNumber: string;
  personalPhoneNumber: string;
};

const ViewJobCandidateModalPhoneSidebarPhoneNumbersHelper = ({
  companyPhoneNumber,
  personalPhoneNumber
}: ViewJobCandidateModalPhoneSidebarPhoneNumbersHelperProps) => {
  const phoneNumbers = [
    {
      phoneNumber: personalPhoneNumber,
      label: 'Personal'
    },
    {
      phoneNumber: companyPhoneNumber,
      label: 'Business'
    }
  ];

  return (
    <Stack spacing={4}>
      {phoneNumbers.map(
        ({ phoneNumber, label }, index) =>
          phoneNumber && (
            <PhoneSidebarPhoneNumbersItem
              key={index}
              label={label}
              phoneNumber={phoneNumber}
            />
          )
      )}
    </Stack>
  );
};

export default ViewJobCandidateModalPhoneSidebarPhoneNumbersHelper;

type PhoneSidebarPhoneNumbersItemProps = {
  label: string;
  phoneNumber: string;
};

const PhoneSidebarPhoneNumbersItem = ({
  label,
  phoneNumber
}: PhoneSidebarPhoneNumbersItemProps) => {
  const {
    isOpen: isOpenEditPhoneNumber,
    onOpen: onOpenEditPhoneNumber,
    onClose: onCloseEditPhoneNumber
  } = useDisclosure();

  return (
    <Box>
      <PhoneItem
        label={label}
        phoneNumber={phoneNumber}
        toggleEdit={onOpenEditPhoneNumber}
      />
      <Collapse in={isOpenEditPhoneNumber}>
        <Box pt={3}>
          <Stack
            p={3}
            spacing={3}
            bg="gray.50"
            border="1px solid"
            borderRadius="base"
            flexDirection="column"
            borderColor="gray.100"
          >
            <InputField label="Name" labelFontSize="sm" />

            <InputField label="Email" labelFontSize="sm" />

            <InputField label="Company" labelFontSize="sm" />

            <InputField label="Company email" labelFontSize="sm" />

            <InputField label="Phone" labelFontSize="sm" />

            <ButtonGroup justifyContent="flex-end">
              <Button
                size="small"
                hierarchy="link"
                onClick={onCloseEditPhoneNumber}
              >
                Cancel
              </Button>

              <Button size="small" onClick={onCloseEditPhoneNumber}>
                Update
              </Button>
            </ButtonGroup>
          </Stack>
        </Box>
      </Collapse>
    </Box>
  );
};
