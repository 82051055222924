import {
  FetchCandidateSharesCacheKey,
  FetchCandidateShareCacheKey,
  DeleteRecipientInCandidateShareErrorMessage,
  DeleteRecipientInCandidateShareIsLoading,
  DeleteRecipientInCandidateShareCandidateShareNanoId,
  DeleteRecipientInCandidateShareCandidateShareID,
  DeleteRecipientInCandidateShareRecipientID
} from '../../candidateSharesTypes';

import { useDeleteItem } from '../../../common/hooks/useDeleteItem';

import {
  CandidateSharesBffRequests,
  DeleteRecipientInCandidateShareErrorResponse
} from '../../CandidateSharesBffRequests';

interface DeleteRecipientInCandidateShareOptions {
  candidateShareId:
    | DeleteRecipientInCandidateShareCandidateShareID
    | DeleteRecipientInCandidateShareCandidateShareNanoId;
  recipientId: DeleteRecipientInCandidateShareRecipientID;
  cacheKeys?: (FetchCandidateSharesCacheKey | FetchCandidateShareCacheKey)[];
}

function useDeleteRecipientInCandidateShare({
  candidateShareId,
  recipientId,
  cacheKeys = []
}: DeleteRecipientInCandidateShareOptions) {
  const {
    deleteItemData,
    deleteItemError,
    deleteItemErrorMessage,
    deleteItemIsLoading,
    deleteItem,
    deleteItemReset
  } = useDeleteItem<unknown, DeleteRecipientInCandidateShareErrorResponse>({
    mutationFn: () =>
      CandidateSharesBffRequests.deleteRecipientInCandidateShare(
        candidateShareId,
        recipientId
      ),
    cacheKeys
  });

  return {
    deleteRecipientInCandidateShareData: deleteItemData,
    deleteRecipientInCandidateShareError: deleteItemError,
    deleteRecipientInCandidateShareErrorMessage:
      deleteItemErrorMessage as DeleteRecipientInCandidateShareErrorMessage,
    deleteRecipientInCandidateShareIsLoading:
      deleteItemIsLoading as DeleteRecipientInCandidateShareIsLoading,
    deleteRecipientInCandidateShare: deleteItem,
    deleteRecipientInCandidateShareReset: deleteItemReset
  };
}

export default useDeleteRecipientInCandidateShare;
