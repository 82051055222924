import {
  JobCandidateSearchTypeLabels,
  JobCandidateSearchTypeLabel,
  JobCandidateSearchTypes,
  JobCandidateSearchType
} from '../../jobCandidatesTypes';

import {
  WorkflowStageArrangement,
  WorkflowStageId,
  WorkflowStageName
} from '../../../workflowStages/workflowStagesTypes';

export const labelColors = {
  [JobCandidateSearchTypeLabels.NONE as JobCandidateSearchTypeLabel]: {
    bg: '#3E6EEB',
    border: 'rgba(15, 49, 139, 0.2)',
    color: '#ffffff'
  },
  [JobCandidateSearchTypes.USER as JobCandidateSearchType]: {
    bg: '#3E6EEB',
    border: 'rgba(15, 49, 139, 0.2)',
    color: '#ffffff'
  },
  [JobCandidateSearchTypeLabels.NEW as JobCandidateSearchTypeLabel]: {
    bg: '#0066FF',
    border: '#0066FF',
    color: '#ffffff'
  },
  [JobCandidateSearchTypeLabels.LEAD as JobCandidateSearchTypeLabel]: {
    bg: '#F2EBFB',
    border: '#DAC6F4',
    color: '#7830D6'
  },
  [JobCandidateSearchTypeLabels.PROSPECT as JobCandidateSearchTypeLabel]: {
    bg: '#EDF8FB',
    border: '#CDEAF4',
    color: '#21667C'
  },
  [JobCandidateSearchTypeLabels.APPLICANT as JobCandidateSearchTypeLabel]: {
    bg: '#E8FAF5',
    border: '#BDF1E2',
    color: '#0F8E6A'
  },
  [JobCandidateSearchTypeLabels.CANDIDATE as JobCandidateSearchTypeLabel]: {
    bg: '#E8FAF5',
    border: '#BDF1E2',
    color: '#000000'
  }
};

export type JobCandidateLabelCandidateWorkflowStage = {
  id: WorkflowStageId;
  name: WorkflowStageName;
  arrangement: WorkflowStageArrangement;
};
