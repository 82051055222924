import { Flex, Stack } from '@chakra-ui/react';
import {
  ScoreCardQuestionType,
  ScoreCardState
} from '../../pages/ViewJobSettingsPage';
import { ScoreCardItemComment } from './components/ScoreCardItemComment';

interface JobHiringScorecardItemProps {
  state: ScoreCardState;
  questions: ScoreCardQuestionType[];
}

const InterviewScorecardItem = ({
  state,
  questions
}: JobHiringScorecardItemProps) => {
  return (
    <>
      <Flex flexDir="column">
        <Stack
          // px={1}
          pb={1}
          pt={state === 'view' ? 4 : 4}
          spacing={state === 'view' ? 4 : 4}
        >
          {questions.map(({ question, answer }, index) => (
            <ScoreCardItemComment
              key={index}
              title={question}
              rating={answer}
              state={state}
              interview={true}
            />
          ))}
        </Stack>
      </Flex>
    </>
  );
};

export default InterviewScorecardItem;
