import { FieldBrand, ItemRecordID } from '../../../types';

import { UserUuid } from '../../users/usersTypes';

export type LikedJobCandidateRecordId = FieldBrand<
  ItemRecordID,
  'LikedJobCandidateRecordId'
>;

export type LikedJobCandidateUserUuid = FieldBrand<
  UserUuid,
  'LikedJobCandidateUserUuid'
>;

export const enum LikedJobCandidateIndexFields {
  ID = 'id',
  RECORD_ID = 'recordId',
  UUID = 'uuid',
  CREATED_AT = 'createdAt',

  USER_UUID = 'userUuid'
}
