import {
  EmploymentSkillBadgeUsage,
  EmploymentSkillBadgeUsageTypes
} from '../../employmentSkillBadgesTypes';

function renderEmploymentSkillBadgeUsage(
  employmentSkillBadgeUsage: EmploymentSkillBadgeUsage
) {
  switch (employmentSkillBadgeUsage) {
    case EmploymentSkillBadgeUsageTypes.NEVER_USED:
      return 'Never used';
    case EmploymentSkillBadgeUsageTypes.DAILY_USER:
      return 'Daily user';
    case EmploymentSkillBadgeUsageTypes.WEEKLY_USER:
      return 'Weekly user';
    case EmploymentSkillBadgeUsageTypes.MONTHLY_USER:
      return 'Monthly user';
    default:
      return 'Unknown';
  }
}

export default renderEmploymentSkillBadgeUsage;
