import { useDisclosure } from '@chakra-ui/react';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState
} from 'react';
import { RecruiterSignUpModal } from '../main/jobs/components/modals/RecruiterSignUpModal';

interface RecruiterContextProps {
  isAddRecruiterOpened: boolean;
  toggleAddRecruiter: () => void;
  isViewRecruiterOpened: boolean;
  toggleViewRecruiter: () => void;
  isRecruiterSignUpModalOpened: boolean;
  toggleRecruiterSignUpModal: () => void;
  isRecruiterRequirementsModalOpened: boolean;
  toggleRecruiterRequirementsModal: () => void;
  isRecruiterGetStartedModalOpened: boolean;
  toggleRecruiterGetStartedModal: () => void;
  isRecruiterCreateProfileModalOpened: boolean;
  toggleRecruiterCreateProfileModal: () => void;
  isRecruiterConfirmEmailModalOpened: boolean;
  toggleRecruiterConfirmEmailModal: () => void;
}

interface RecruiterProviderProps {
  children: ReactNode;
}

const RecruiterContext = createContext<RecruiterContextProps>({
  isAddRecruiterOpened: false,
  isViewRecruiterOpened: false,
  toggleAddRecruiter: () => {
    /* do nothing */
  },
  toggleViewRecruiter: () => {
    /* do nothing */
  },
  isRecruiterSignUpModalOpened: false,
  toggleRecruiterSignUpModal: () => {
    /* do nothing */
  },
  isRecruiterRequirementsModalOpened: false,
  toggleRecruiterRequirementsModal: () => {
    /* do nothing */
  },
  isRecruiterGetStartedModalOpened: false,
  toggleRecruiterGetStartedModal: () => {
    /* do nothing */
  },
  isRecruiterCreateProfileModalOpened: false,
  toggleRecruiterCreateProfileModal: () => {
    /* do nothing */
  },
  isRecruiterConfirmEmailModalOpened: false,
  toggleRecruiterConfirmEmailModal: () => {
    /* do nothing */
  }
});

type menus = 'add' | 'view';

export const RecruiterProvider = ({ children }: RecruiterProviderProps) => {
  const [isOpenedMenu, setIsOpenedMenu] = useState<menus>();
  const { isOpen: isRecruiterSignUpModalOpened, onToggle: toggleSignUpModal } =
    useDisclosure({ defaultIsOpen: false });
  const {
    isOpen: isRecruiterRequirementsModalOpened,
    onToggle: toggleRequirementsModal
  } = useDisclosure();
  const {
    isOpen: isRecruiterGetStartedModalOpened,
    onToggle: toggleGetStartedModal
  } = useDisclosure();
  const {
    isOpen: isRecruiterCreateProfileModalOpened,
    onToggle: toggleCreateProfileModal
  } = useDisclosure();
  const {
    isOpen: isRecruiterConfirmEmailModalOpened,
    onToggle: toggleConfirmEmailModal
  } = useDisclosure();

  const toggleMenuOpen = useCallback(
    (menu: menus) => {
      if (isOpenedMenu !== menu) setIsOpenedMenu(menu);
      else setIsOpenedMenu(undefined);
    },
    [isOpenedMenu]
  );

  const toggleAddRecruiter = useCallback(() => {
    toggleMenuOpen('add');
  }, [toggleMenuOpen]);

  const toggleViewRecruiter = useCallback(() => {
    toggleMenuOpen('view');
  }, [toggleMenuOpen]);

  const toggleRecruiterSignUpModal = useCallback(() => {
    toggleSignUpModal();
  }, [toggleSignUpModal]);

  const toggleRecruiterRequirementsModal = useCallback(() => {
    toggleRequirementsModal();
  }, [toggleRequirementsModal]);

  const toggleRecruiterGetStartedModal = useCallback(() => {
    toggleGetStartedModal();
  }, [toggleGetStartedModal]);

  const toggleRecruiterCreateProfileModal = useCallback(() => {
    toggleCreateProfileModal();
  }, [toggleCreateProfileModal]);

  const toggleRecruiterConfirmEmailModal = useCallback(() => {
    toggleConfirmEmailModal();
  }, [toggleConfirmEmailModal]);

  return (
    <RecruiterContext.Provider
      value={{
        isAddRecruiterOpened: isOpenedMenu === 'add',
        isViewRecruiterOpened: isOpenedMenu === 'view',
        toggleAddRecruiter,
        toggleViewRecruiter,
        isRecruiterSignUpModalOpened,
        toggleRecruiterSignUpModal,
        isRecruiterGetStartedModalOpened,
        toggleRecruiterGetStartedModal,
        isRecruiterCreateProfileModalOpened,
        toggleRecruiterCreateProfileModal,
        isRecruiterRequirementsModalOpened,
        toggleRecruiterRequirementsModal,
        isRecruiterConfirmEmailModalOpened,
        toggleRecruiterConfirmEmailModal
      }}
    >
      {children}

      {isRecruiterSignUpModalOpened && (
        <RecruiterSignUpModal
          isRecruiterSignUpModalOpened={isRecruiterSignUpModalOpened}
          toggleRecruiterSignUpModal={toggleRecruiterSignUpModal}
        />
      )}
    </RecruiterContext.Provider>
  );
};

export const useRecruiterContext = () => useContext(RecruiterContext);
