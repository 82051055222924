import { Collapse, Stack, StackDivider } from '@chakra-ui/react';
import { MouseEvent, useState } from 'react';
import { ViewJobCandidateModalPhoneSidebarCallActionButton } from '../../../../components';
import {
  ViewJobCandidateModalPhoneSidebarAddReminderHelper,
  ViewJobCandidateModalPhoneSidebarEnterCallNote
} from '../../../../actions';

type ViewJobCandidateModalPhoneSidebarInterestedType =
  | 'call-note'
  | 'add-reminder';

type ViewJobCandidateModalPhoneSidebarInterestedHelperProps = {
  onSave: () => void;
};

const ViewJobCandidateModalPhoneSidebarInterestedHelper = ({
  onSave
}: ViewJobCandidateModalPhoneSidebarInterestedHelperProps) => {
  const [selectedOption, setSelectedOption] =
    useState<ViewJobCandidateModalPhoneSidebarInterestedType | null>();

  const toggleSelection = (e: MouseEvent<HTMLButtonElement>) => {
    const { value } = e.currentTarget;

    if (!value || selectedOption === value) {
      setSelectedOption(null);
      return;
    }

    setSelectedOption(value as ViewJobCandidateModalPhoneSidebarInterestedType);
  };

  const onDiscard = () => {
    setSelectedOption(null);
  };

  return (
    <>
      <Collapse in={!selectedOption} unmountOnExit>
        <Stack
          flex={1}
          spacing={0}
          overflow="hidden"
          borderRadius="4px"
          border="1px solid"
          borderColor="gray.300"
          divider={<StackDivider />}
          boxShadow="0px 2px 2px rgba(23, 25, 26, 0.06)"
        >
          <Stack direction="row" spacing={0} divider={<StackDivider />}>
            <ViewJobCandidateModalPhoneSidebarCallActionButton
              value="call-note"
              label="Enter a new call note"
              onClick={toggleSelection}
            />
            <ViewJobCandidateModalPhoneSidebarCallActionButton
              value="add-reminder"
              label="Add a reminder"
              onClick={toggleSelection}
            />
          </Stack>
        </Stack>
      </Collapse>

      <Collapse in={selectedOption === 'call-note'} unmountOnExit>
        <ViewJobCandidateModalPhoneSidebarEnterCallNote
          onDiscard={onDiscard}
          onSave={onSave}
        />
      </Collapse>

      <Collapse in={selectedOption === 'add-reminder'} unmountOnExit>
        <ViewJobCandidateModalPhoneSidebarAddReminderHelper
          onDiscard={onDiscard}
          onSave={onSave}
        />
      </Collapse>
    </>
  );
};

export default ViewJobCandidateModalPhoneSidebarInterestedHelper;
