import { Box, Collapse, Flex, Stack } from '@chakra-ui/react';
import { LoginMethodDivider } from '../../../../../../../../../../../auth/pages/SignInPage/helpers/LoginMethodDivider';
import { Button } from '../../../../../../../../../../../helpers/Button';

import {
  ViewJobCandidateModalPhoneSidebarCallResponseDiscardButton,
  ViewJobCandidateModalPhoneSidebarCallResponseTrigger,
  ViewJobCandidateModalPhoneSidebarCallResponseWrapper
} from '../../components';
import {
  ViewJobCandidateModalPhoneSidebarCallSectionType,
  ViewJobCandidateModalPhoneSidebarToggleSectionType
} from '../../ViewJobCandidateModalPhoneSidebarCallActionsHelper';
import { useState } from 'react';

type ViewJobCandidateModalPhoneSidebarWrongNumberHelperProps = {
  openedSection: ViewJobCandidateModalPhoneSidebarCallSectionType;
  toggleSection: ViewJobCandidateModalPhoneSidebarToggleSectionType;
};

const ViewJobCandidateModalPhoneSidebarWrongNumberHelper = ({
  openedSection,
  toggleSection
}: ViewJobCandidateModalPhoneSidebarWrongNumberHelperProps) => {
  const [isFocused, setIsFocused] = useState(false);

  const openWrongNumberSection = () => {
    toggleSection({ actionType: 'open', section: 'wrong-number' });
  };

  const onDiscard = () => {
    setIsFocused(false);
    toggleSection({ actionType: 'close', section: 'wrong-number' });
  };

  return (
    <Flex
      flexDir="column"
      onClick={() => {
        if (!isFocused) {
          setIsFocused(true);
        }
      }}
      onMouseLeave={() => {
        if (!isFocused) {
          onDiscard();
        }
      }}
    >
      <ViewJobCandidateModalPhoneSidebarCallResponseTrigger
        label="Wrong number"
        onMouseEnter={openWrongNumberSection}
      />

      <Collapse in={openedSection === 'wrong-number'} unmountOnExit>
        <Box pt={2}>
          <ViewJobCandidateModalPhoneSidebarCallResponseWrapper>
            <Stack spacing={3}>
              <Button size="medium">Request new number</Button>

              <LoginMethodDivider />

              <Button size="medium" hierarchy="tertiary">
                Add new number
              </Button>
            </Stack>

            <ViewJobCandidateModalPhoneSidebarCallResponseDiscardButton
              onClick={onDiscard}
            />
          </ViewJobCandidateModalPhoneSidebarCallResponseWrapper>
        </Box>
      </Collapse>
    </Flex>
  );
};

export default ViewJobCandidateModalPhoneSidebarWrongNumberHelper;
