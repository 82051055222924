import { Box, Flex, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { ViewJobCandidateDetailsTaskSubActionsDropdown } from '../ViewJobCandidateDetailsTaskSubActionsDropdown';
import { IconButton } from '../../../../../../../../helpers/buttons/IconButton';
import { CalendarIcon } from '../../../../../../../../icons/CalendarIcon';
import { EmailIcon } from '../../../../../../../../icons/EmailIcon';
import { PhoneIcon } from '../../../../../../../../icons/PhoneIcon';
import { useViewJobCandidateDetailsTasksWidgetContext } from '../../../../contexts/ViewJobCandidateDetailsTasksWidgetContext';
import { ListItemIcon } from '../../../../../../../../icons/ListItemIcon';

function ViewJobCandidateDetailsTaskTools() {
  const {
    handleSelectTaskAction,
    skipTask,
    cancelTask,
    disqualifyCandidate,
    closeSubActionsDropdown,
    openSubActionsDropdown,
    taskStatus
  } = useViewJobCandidateDetailsTasksWidgetContext();

  return (
    <Flex flex={1} justifyContent="space-between">
      <Tooltip label="Call" textTransform="capitalize" hasArrow>
        <IconButton
          h={6}
          w={6}
          minW={0}
          minH={0}
          value="phone"
          display="flex"
          aria-label=""
          hierarchy="unstyled"
          borderRadius="full"
          alignItems="center"
          justifyContent="center"
          onClick={handleSelectTaskAction}
          icon={<PhoneIcon w={2.5} h={2.5} fill="#287CC9" />}
          border="1px solid transparent"
          bg="#E9F1F9"
          _hover={{
            bg: '#D4E5F4',
            borderColor: '#287CC9'
          }}
        />
      </Tooltip>

      <Tooltip label="Email" textTransform="capitalize" hasArrow>
        <IconButton
          h={6}
          w={6}
          minW={0}
          minH={0}
          value="email"
          display="flex"
          aria-label=""
          hierarchy="unstyled"
          borderRadius="full"
          alignItems="center"
          justifyContent="center"
          onClick={handleSelectTaskAction}
          icon={<EmailIcon w={3} h={3} fill="#1DC67D" />}
          border="1px solid transparent"
          bg="#E8F9F2"
          _hover={{
            bg: '#D2F4E5',
            borderColor: '#1DC67D'
          }}
        />
      </Tooltip>

      <Tooltip label="Schedule" textTransform="capitalize" hasArrow>
        <IconButton
          h={6}
          w={6}
          minW={0}
          minH={0}
          value="calendar"
          display="flex"
          aria-label=""
          hierarchy="unstyled"
          borderRadius="full"
          alignItems="center"
          justifyContent="center"
          onClick={handleSelectTaskAction}
          icon={<CalendarIcon w={3} h={3} fill="#507D7B" />}
          border="1px solid transparent"
          bg="#EDF2F1"
          _hover={{
            bg: '#DCE5E5',
            borderColor: '#507D7B'
          }}
        />
      </Tooltip>

      <Tooltip label="Assign" textTransform="capitalize" hasArrow>
        <IconButton
          h={6}
          w={6}
          minW={0}
          minH={0}
          value="tasks"
          display="flex"
          aria-label=""
          hierarchy="unstyled"
          borderRadius="full"
          alignItems="center"
          justifyContent="center"
          onClick={handleSelectTaskAction}
          icon={<ListItemIcon w={3} h={3} fill="#AB56B2" />}
          border="1px solid transparent"
          bg="#EFF0FC"
          _hover={{
            bg: '#E1E3FA',
            borderColor: '#AB56B2'
          }}
        />
      </Tooltip>

      <Box>
        <ViewJobCandidateDetailsTaskSubActionsDropdown
          skipTask={skipTask}
          cancelTask={cancelTask}
          openDropdown={openSubActionsDropdown}
          closeDropdown={closeSubActionsDropdown}
          actionSelected={taskStatus.label === 'in-progress'}
          disqualifyCandidate={disqualifyCandidate}
        />
      </Box>
    </Flex>
  );
}

export default ViewJobCandidateDetailsTaskTools;
