import { createContext, ReactNode, useContext, useState } from 'react';

interface RightSidebarContextProps {
  isTasksSidebarOpened: boolean;
  isNotesSidebarOpened: boolean;
  isFoldersSidebarOpened: boolean;
  isProductsSidebarOpened: boolean;
  toggleTasksSidebar: () => void;
  toggleNotesSidebar: () => void;
  toggleFoldersSidebar: () => void;
  toggleProductsSidebar: () => void;
}

interface RightSidebarProviderProps {
  children: ReactNode;
}

const RightSidebarContext = createContext<RightSidebarContextProps>({
  isTasksSidebarOpened: false,
  isNotesSidebarOpened: false,
  isFoldersSidebarOpened: false,
  isProductsSidebarOpened: false,
  toggleTasksSidebar: () => {
    /* do nothing */
  },
  toggleNotesSidebar: () => {
    /* do nothing */
  },
  toggleFoldersSidebar: () => {
    /* do nothing */
  },
  toggleProductsSidebar: () => {
    /* do nothing */
  }
});

type menus = 'tasks' | 'folders' | 'notes' | 'products';

export const RightSidebarProvider = ({
  children
}: RightSidebarProviderProps) => {
  const [isOpenedMenu, setIsOpenedMenu] = useState<menus>();

  const toggleMenuOpen = (menu: menus) => {
    if (isOpenedMenu !== menu) setIsOpenedMenu(menu);
    else setIsOpenedMenu(undefined);
  };

  const toggleTasksSidebar = () => {
    toggleMenuOpen('tasks');
  };

  const toggleNotesSidebar = () => {
    toggleMenuOpen('notes');
  };

  const toggleFoldersSidebar = () => {
    toggleMenuOpen('folders');
  };

  const toggleProductsSidebar = () => {
    toggleMenuOpen('products');
  };

  return (
    <RightSidebarContext.Provider
      value={{
        isTasksSidebarOpened: isOpenedMenu === 'tasks',
        isNotesSidebarOpened: isOpenedMenu === 'notes',
        isFoldersSidebarOpened: isOpenedMenu === 'folders',
        isProductsSidebarOpened: isOpenedMenu === 'products',
        toggleTasksSidebar,
        toggleNotesSidebar,
        toggleFoldersSidebar,
        toggleProductsSidebar
      }}
    >
      {children}
    </RightSidebarContext.Provider>
  );
};

export const useRightSidebarContext = () => useContext(RightSidebarContext);
