import React from 'react';
import { FieldValues } from 'react-hook-form';
import { SystemStyleObject } from '@chakra-ui/react';
import compact from 'lodash/compact';
import debounce from 'lodash/debounce';
import find from 'lodash/find';

import { ErrorMessage } from '../../../../../types';

import {
  CountryFields,
  FetchCountriesFilters,
  FetchCountriesSort,
  CountryId,
  CountryName,
  CountryCode,
  MayBeCountryId,
  CountryLabel
} from '../../../../countries/countriesTypes';

import {
  fetchCountriesQuery,
  FetchCountriesResponse
} from '../../../../countries/queries/fetchCountries.query';

import { usePaginatedCountries } from '../../../../../main/countries/hooks/usePaginatedCountries';

import { CountriesCache } from '../../../../countries/CountriesCache';

import {
  SelectField,
  SelectFieldReactHookFormControl,
  SelectFieldReactHookFormFieldPath
} from '../../../../../helpers/forms/formFields/SelectField';

interface SelectCountryFormFieldItem {
  countryId?: MayBeCountryId;
  country: {
    id: CountryId;
    label: CountryLabel;
    name: CountryName;
  } | null;
}

interface SelectCountryFormWithItemFieldProps<T extends FieldValues> {
  isRequired?: boolean;
  control: SelectFieldReactHookFormControl<T>;
  item: SelectCountryFormFieldItem;
  countryCode?: never;
  countryName?: never;
  errorMessage: ErrorMessage;
  label?: string;
  placeholder?: string;
  showLabel?: boolean;
}

interface SelectCountryFormWithCountryNameFieldProps<T extends FieldValues> {
  isRequired?: boolean;
  control: SelectFieldReactHookFormControl<T>;
  item?: never;
  countryCode: CountryCode;
  countryName: CountryName;
  errorMessage: ErrorMessage;
  label?: string;
  placeholder?: string;
  showLabel?: boolean;
}

interface SelectCountryFormBlankFieldProps<T extends FieldValues> {
  isRequired?: boolean;
  control: SelectFieldReactHookFormControl<T>;
  item?: never;
  countryCode?: never;
  countryName?: never;
  errorMessage: ErrorMessage;
  label?: string;
  placeholder?: string;
  showLabel?: boolean;
}

type SelectCountryFormFieldProps<T extends FieldValues> = (
  | SelectCountryFormWithItemFieldProps<T>
  | SelectCountryFormWithCountryNameFieldProps<T>
  | SelectCountryFormBlankFieldProps<T>
) & {
  reactSelectStyles?: Record<string, unknown>;
  isBorderless?: boolean;
};

function SelectCountryFormField<T extends FieldValues>({
  isRequired = false,
  showLabel = true,
  label,
  placeholder,
  control,
  item,
  countryCode,
  countryName,
  errorMessage,
  reactSelectStyles,
  isBorderless
}: SelectCountryFormFieldProps<T>) {
  const {
    countries,
    countriesIsLoading,
    countriesErrorMessage,
    changeCountriesFilters
  } = usePaginatedCountries<FetchCountriesResponse>({
    query: fetchCountriesQuery,
    cacheKey: CountriesCache.indexCacheKey(),
    initialFilters: {
      ...(countryName || item?.country?.name
        ? {
            [CountryFields.NAME]: {
              operator: 'ilike',
              value: `${countryName || item?.country?.name}`
            }
          }
        : {})
    } as unknown as FetchCountriesFilters,
    initialSort: {
      [CountryFields.NAME]: { ascending: true }
    } as unknown as FetchCountriesSort
  });

  const defaultCurrentCountryFromCountryLabel = countryName
    ? find(
        countries,
        (country) =>
          country.label === compact([countryName, countryCode]).join(', ') ||
          country.name === countryName
      )
    : null;

  const defaultCurrentCountryFromCountryLabelValue =
    defaultCurrentCountryFromCountryLabel
      ? {
          value: defaultCurrentCountryFromCountryLabel.id,
          label: defaultCurrentCountryFromCountryLabel.label
        }
      : undefined;

  const defaultCurrentUserCountry = item?.country
    ? {
        value: item.country.id,
        label: item.country.label
      }
    : undefined;

  const debouncedFilterCountries = debounce<(updatedValue: string) => void>(
    (updatedValue) =>
      changeCountriesFilters({
        [CountryFields.NAME]: {
          operator: 'ilike',
          value: `${updatedValue}`
        }
      }),
    500
  );

  return (
    <SelectField
      isRequired={isRequired}
      control={control}
      label={showLabel ? label || 'Location' : undefined}
      placeholder={placeholder || 'Location'}
      name={'countryId' as SelectFieldReactHookFormFieldPath<T>}
      options={countries.map((country) => ({
        value: country.id,
        label: country.label
      }))}
      defaultValue={
        defaultCurrentCountryFromCountryLabelValue || defaultCurrentUserCountry
      }
      errorMessage={errorMessage || countriesErrorMessage}
      isLoading={countriesIsLoading}
      onInputChange={debouncedFilterCountries}
      reactSelectStyles={
        isBorderless
          ? {
              control: (base: SystemStyleObject) => ({
                ...base,
                borderColor: 'transparent',
                p: 0
              })
            }
          : reactSelectStyles
      }
    />
  );
}

export default SelectCountryFormField;
