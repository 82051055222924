import { Flex, useClipboard } from '@chakra-ui/react';
import { useState } from 'react';
import { IconButton } from '../../../../../../../../../../helpers/buttons/IconButton';
import { Text } from '../../../../../../../../../../helpers/Text';
import { CopyIcon } from '../../../../../../../../../../icons/CopyIcon';
import { EditIcon } from '../../../../../../../../../../icons/EditIcon';
import { PhoneIcon } from '../../../../../../../../../../icons/PhoneIcon';

interface PhoneProps {
  phoneNumber: string;
  label: string;
  toggleEdit?: () => void;
}

const PhoneItem = ({ phoneNumber, label, toggleEdit }: PhoneProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const { hasCopied, onCopy } = useClipboard(phoneNumber);

  return (
    <Flex
      gap={2}
      alignItems="center"
      bg={isHovered ? 'gray.100' : 'transparent'}
      px={2}
      py={1}
      cursor="pointer"
      borderRadius={4}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      transitionProperty="background"
      transitionDuration="slow"
    >
      <PhoneIcon />

      <Flex
        as={Text}
        textStyle="body1Medium"
        position="relative"
        overflow="hidden"
      >
        {hasCopied && (
          <Text
            as="span"
            pos="absolute"
            w="100%"
            bg={isHovered ? 'gray.100' : 'white'}
          >
            Copied
          </Text>
        )}
        <Text as="span" noOfLines={1}>
          {phoneNumber}
        </Text>
        {label && (
          <Text as="span" ml={2} color="gray.600">
            ({label})
          </Text>
        )}
      </Flex>

      {toggleEdit ? (
        <IconButton
          size="small"
          hierarchy="link"
          onClick={toggleEdit}
          aria-label="Edit Phone"
          justifySelf="flex-end"
        >
          <EditIcon fill="gray.500" />
        </IconButton>
      ) : null}

      <Flex ml="auto">
        <IconButton
          aria-label="Copy Phone"
          hierarchy="link"
          justifySelf="flex-end"
          size="small"
          onClick={onCopy}
        >
          <CopyIcon />
        </IconButton>
      </Flex>
    </Flex>
  );
};

export default PhoneItem;
