import {
  CandidateShareItemBffID,
  CandidateShareItemBffNanoID,
  FetchCandidateSharesBffURL,
  FetchCandidateShareBffURL,
  FetchCandidateShareByNanoIdBffURL,
  CreateCandidateShareBffURL,
  DeleteCandidateShareBffURL,
  DeleteRecipientInCandidateShareBffURL,
  DeleteJobCandidateInCandidateShareBffURL,
  UpdateCandidateShareBffURL,
  SendEmailsCandidateShareBffURL
} from './candidateSharesTypes';
import { RecipientItemBffID } from '../recipients/recipientsTypes';
import {
  JobCandidateItemBffID,
  JobCandidateItemBffNanoID
} from '../jobCandidates/jobCandidatesTypes';

import { BaseBffRoutes } from '../BaseBffRoutes';

export class CandidateSharesBffRoutes extends BaseBffRoutes {
  protected static route = 'candidateShares';

  static fetchCandidateSharesRoute() {
    return this.fetchItemsRoute<FetchCandidateSharesBffURL>();
  }

  static fetchCandidateSharesIndexRoute() {
    return this.fetchItemsIndexRoute<FetchCandidateSharesBffURL>();
  }

  static fetchCandidateShareRoute(
    candidateShareItemBffID: CandidateShareItemBffID
  ) {
    return this.fetchItemRoute<FetchCandidateShareBffURL>(
      candidateShareItemBffID
    );
  }

  static fetchCandidateShareIndexRoute(
    candidateShareItemBffNanoID: CandidateShareItemBffNanoID
  ) {
    return this.fetchItemIndexRoute<FetchCandidateShareBffURL>(
      candidateShareItemBffNanoID
    );
  }

  static fetchCandidateShareByNanoIdRoute(
    candidateShareItemBffNanoID: CandidateShareItemBffNanoID
  ) {
    return this.fetchItemByNanoIdRoute<FetchCandidateShareByNanoIdBffURL>(
      candidateShareItemBffNanoID
    );
  }

  static createCandidateShareRoute() {
    return this.createItemRoute<CreateCandidateShareBffURL>();
  }

  static updateCandidateShareRoute(
    updaeCandidateShareItemBffID:
      | CandidateShareItemBffNanoID
      | CandidateShareItemBffID
  ) {
    return this.updateItemRoute<UpdateCandidateShareBffURL>(
      updaeCandidateShareItemBffID
    );
  }

  static deleteCandidateShareRoute(
    deleteCandidateShareItemBffID:
      | CandidateShareItemBffNanoID
      | CandidateShareItemBffID
  ) {
    return this.deleteItemRoute<DeleteCandidateShareBffURL>(
      deleteCandidateShareItemBffID
    );
  }

  static deleteRecipientInCandidateShareRoute(
    candidateShareItemBffID:
      | CandidateShareItemBffNanoID
      | CandidateShareItemBffID,
    recipientItemBffID: RecipientItemBffID
  ) {
    return `${this.basePath}/${this.route}/${candidateShareItemBffID}/recipients/${recipientItemBffID}/delete` as DeleteRecipientInCandidateShareBffURL;
  }

  static deleteJobCandidateInCandidateShareRoute(
    candidateShareItemBffID:
      | CandidateShareItemBffNanoID
      | CandidateShareItemBffID,
    jobCandidateItemBffID: JobCandidateItemBffID | JobCandidateItemBffNanoID
  ) {
    return `${this.basePath}/${this.route}/${candidateShareItemBffID}/job_candidates/${jobCandidateItemBffID}/delete` as DeleteJobCandidateInCandidateShareBffURL;
  }

  static sendEmailsCandidateShareRoute(
    candidateShareItemBffID:
      | CandidateShareItemBffNanoID
      | CandidateShareItemBffID
  ) {
    return `${this.basePath}/${this.route}/${candidateShareItemBffID}/send_emails` as SendEmailsCandidateShareBffURL;
  }
}
