import {
  DepartmentItemBffID,
  DepartmentItemBffNanoID,
  FetchDepartmentsBffURL,
  FetchDepartmentBffURL,
  FetchDepartmentByNanoIdBffURL,
  CreateDepartmentBffURL,
  UpdateDepartmentBffURL,
  DeleteDepartmentBffURL,
  ReplaceDepartmentBffURL
} from './departmentsTypes';

import { BaseBffRoutes } from '../BaseBffRoutes';

export class DepartmentsBffRoutes extends BaseBffRoutes {
  protected static route = 'departments';

  static fetchDepartmentsRoute() {
    return this.fetchItemsRoute<FetchDepartmentsBffURL>();
  }

  static fetchDepartmentsIndexRoute() {
    return this.fetchItemsIndexRoute<FetchDepartmentsBffURL>();
  }

  static fetchDepartmentRoute(departmentItemBffID: DepartmentItemBffID) {
    return this.fetchItemRoute<FetchDepartmentBffURL>(departmentItemBffID);
  }

  static fetchDepartmentIndexRoute(
    departmentItemBffNanoID: DepartmentItemBffNanoID
  ) {
    return this.fetchItemIndexRoute<FetchDepartmentBffURL>(
      departmentItemBffNanoID
    );
  }

  static fetchDepartmentByNanoIdRoute(
    departmentItemBffNanoID: DepartmentItemBffNanoID
  ) {
    return this.fetchItemByNanoIdRoute<FetchDepartmentByNanoIdBffURL>(
      departmentItemBffNanoID
    );
  }

  static createDepartmentRoute() {
    return this.createItemRoute<CreateDepartmentBffURL>();
  }

  static updateDepartmentRoute(departmentItemBffID: DepartmentItemBffID) {
    return this.updateItemRoute<UpdateDepartmentBffURL>(departmentItemBffID);
  }

  static deleteDepartmentRoute(departmentItemBffID: DepartmentItemBffID) {
    return this.deleteItemRoute<DeleteDepartmentBffURL>(departmentItemBffID);
  }

  static replaceDepartmentRoute(departmentItemBffID: DepartmentItemBffID) {
    return `${this.basePath}/${this.route}/${departmentItemBffID}/replace` as ReplaceDepartmentBffURL;
  }
}
