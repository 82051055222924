import {
  JobCandidateStatus,
  JobCandidateStatuses
} from '../../jobCandidatesTypes';

function renderJobCandidateStatus(jobCandidateStatus: JobCandidateStatus) {
  switch (jobCandidateStatus) {
    case JobCandidateStatuses.NONE:
      return 'None';
    case JobCandidateStatuses.ACTIVE_EMPLOYED:
      return 'Active Employed';
    case JobCandidateStatuses.ACTIVE_AVAILABLE:
      return 'Active Available';
    case JobCandidateStatuses.PASSIVELY_INTERESTED:
      return 'Passively Interested';
    case JobCandidateStatuses.PASSIVE_NOT_LOOKING:
      return 'Passive Not Looking';
    default:
      return 'Unknown';
  }
}

export default renderJobCandidateStatus;
