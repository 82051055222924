import {
  FieldBrand,
  MayBe,
  ItemID,
  ItemIDs,
  ItemUUID,
  ItemNanoId,
  ItemEmail,
  ItemCreatedAt
} from '../../../types';

import {
  AccountTypeId,
  AccountTypeNanoId,
  AccountTypeName,
  AccountTypeRoles,
  AccountTypeCreatedAt
} from '../../accountTypes/accountTypesTypes';

import {
  CompanyId,
  CompanyNanoId,
  CompanyImage,
  CompanyName
} from '../../companies/companiesTypes';

import {
  ImageId,
  ImageNanoId,
  ImageName,
  ImageFile,
  ImageKey,
  ImageCreatedAt
} from '../../images/imagesTypes';

import {
  CompanyMemberFields,
  CompanyMemberNanoId,
  CompanyMemberCompanyNanoId,
  CompanyMemberCompanyName,
  CompanyMemberCompanyImage
} from '../../companyMembers/companyMembersTypes';

import {
  CountryId,
  CountryName,
  CountryLabel,
  CountryCode
} from '../../countries/countriesTypes';

import { CityId, CityName, CityLabel } from '../../cities/citiesTypes';

import { IndustryId, IndustryName } from '../../industries/industriesTypes';

import { JobNanoId } from '../../jobs/jobsTypes';

import {
  JobResumeRecruiterId,
  JobResumeRecruiterNanoId
} from '../../jobResumeRecruiters/jobResumeRecruitersTypes';

import {
  PermissionFields,
  PermissionAction,
  PermissionFrontend,
  PermissionNanoId
} from '../../permissions/permissionsTypes';

import { RoleId, RoleNanoId, RoleName } from '../../roles/rolesTypes';

import {
  StateId,
  StateName,
  StateLabel,
  StateIsoCode
} from '../../states/statesTypes';

import {
  UserEmailId,
  UserEmailNanoId,
  UserEmailEmail,
  UserEmailLabel
} from '../../userEmails/userEmailsTypes';

import {
  UserPhoneNumberId,
  UserPhoneNumberNanoId,
  UserPhoneNumberPhoneNumber,
  UserPhoneNumberLabel
} from '../../userPhoneNumbers/userPhoneNumbersTypes';

import {
  UserSpecializationId,
  UserSpecializationName
} from '../../userSpecializations/userSpecializationsTypes';

import {
  EmploymentId,
  EmploymentNanoId,
  MayBeEmploymentTitle,
  MayBeEmploymentStartDate,
  MayBeEmploymentEndDate,
  MayBeEmploymentCityId,
  MayBeEmploymentCity,
  MayBeEmploymentCompanyId,
  MayBeEmploymentCompany,
  EmploymentJobTitle,
  EmploymentJobTitleId,
  EmploymentJobFunctions,
  EmploymentJobFunctionIds,
  EmploymentIndustries,
  EmploymentIndustryIds,
  EmploymentCurrently
} from '../../employments/employmentsTypes';

import {
  CertificationId,
  CertificationName,
  CertificationNanoId,
  CertificationCredentialsId,
  MayBeCertificationCompanyId,
  MayBeCertificationIssueDate,
  MayBeCertificationStartDate,
  MayBeCertificationEndDate
} from '../../certifications/certificationsTypes';

import {
  EducationId,
  EducationNanoId,
  EducationDegree,
  EducationSchoolName,
  EducationSchoolType,
  EducationStudyField,
  MayBeEducationStartDate,
  MayBeEducationEndDate,
  MayBeEducationCity
} from '../../educations/educationsTypes';

import { TalentPoolId } from '../../talentPools/talentPoolsTypes';
import { SkillIds } from '../../skills/skillsTypes';
import {
  JobFunctionId,
  JobFunctionName
} from '../../jobFunctions/jobFunctionsTypes';
import { JobTitleId, JobTitleName } from '../../jobTitles/jobTitlesTypes';
import {
  DepartmentId,
  DepartmentName,
  DepartmentNanoId
} from '../../departments/departmentsTypes';

export type UserId = FieldBrand<ItemID, 'UserId'>;
export type UserIds = FieldBrand<ItemIDs, 'UserIds'>;
export type UserUuid = FieldBrand<ItemUUID, 'UserUuid'>;
export type MayBeUserUuid = MayBe<UserUuid>;
export type UserNanoId = FieldBrand<ItemNanoId, 'UserNanoId'>;
export type UserBlockedAt = FieldBrand<string | null, 'UserBlockedAt'>;
export type UserName = FieldBrand<string, 'UserName'>;
export type MayBeUserName = MayBe<UserName>;
export type UserFirstName = FieldBrand<string, 'UserFirstName'>;
export type MayBeUserFirstName = MayBe<UserFirstName>;
export type UserLastName = FieldBrand<string, 'UserLastName'>;
export type MayBeUserLastName = MayBe<UserLastName>;
export type UserFullName = FieldBrand<string, 'UserFullName'>;
export type UserTitle = FieldBrand<string, 'UserTitle'>;
export type MayBeUserTitle = MayBe<UserTitle>;
export type UserEmail = FieldBrand<ItemEmail, 'UserEmail'>;
export type MayBeUserEmail = MayBe<UserEmail>;
export type UserWorkEmail = FieldBrand<ItemEmail, 'UserWorkEmail'>;
export type MayBeUserWorkEmail = MayBe<UserWorkEmail>;
export type UserPhone = FieldBrand<string, 'UserPhone'>;
export type MayBeUserPhone = MayBe<UserPhone>;
export type UserApprovedAt = FieldBrand<string, 'UserApprovedAt'>;
export type MayBeUserApprovedAt = MayBe<UserApprovedAt>;
export type UserProfileCreatedAt = FieldBrand<string, 'UserProfileCreatedAt'>;
export type MayBeUserProfileCreatedAt = MayBe<UserProfileCreatedAt>;
export type UserWorkEmailApprovedAt = FieldBrand<string, 'WorkEmailApprovedAt'>;
export type MayBeUserWorkEmailApprovedAt = MayBe<UserWorkEmailApprovedAt>;
export type UserAfterSignInRoute = FieldBrand<string, 'UserAfterSignInRoute'>;
export type UserSearchType = FieldBrand<UserSearchTypes, 'UserSearchType'>;
export type UserCaptureType = FieldBrand<UserCaptureTypes, 'UserCaptureType'>;
export type UserActionType = FieldBrand<UserActionTypes, 'UserActionType'>;
export type UserDescription = FieldBrand<string, 'UserDescription'>;
export type UserHasSecurityQuestion1 = FieldBrand<
  boolean,
  'UserHasSecurityQuestion1'
>;
export type UserHasSecurityAnswer1 = FieldBrand<
  boolean,
  'UserHasSecurityAnswer1'
>;
export type UserHasSecurityQuestion2 = FieldBrand<
  boolean,
  'UserHasSecurityQuestion2'
>;
export type UserHasSecurityAnswer2 = FieldBrand<
  boolean,
  'UserHasSecurityAnswer2'
>;
export type UserEmailSyncEnabledAt = FieldBrand<
  string,
  'UserEmailSyncEnabledAt'
>;
export type MayBeUserEmailSyncEnabledAt = MayBe<UserEmailSyncEnabledAt>;
export type UserEmailSyncProvider = FieldBrand<
  'none' | 'gmail' | 'microsoft',
  'UserEmailSyncProvider'
>;
export type UserScheduleInterviewsEnabledAt = FieldBrand<
  string,
  'UserScheduleInterviewsEnabledAt'
>;
export type MayBeUserScheduleInterviewsEnabledAt =
  MayBe<UserScheduleInterviewsEnabledAt>;
export type UserScheduleInterviewsProvider = FieldBrand<
  'none' | 'gmail' | 'microsoft',
  'UserScheduleInterviewsProvider'
>;
export type UserActions = FieldBrand<PermissionAction[], 'UserActions'>;
export type UserImageId = FieldBrand<ImageId, 'UserImageId'>;
export type MayBeUserImageId = MayBe<UserImageId>;
export type UserAccountTypeId = FieldBrand<AccountTypeId, 'UserAccountTypeId'>;
export type UserDepartmentId = FieldBrand<DepartmentId, 'UserDepartmentId'>;

export type MayBeUserAccountTypeId = MayBe<UserAccountTypeId>;
export type MayBeUserDepartmentId = MayBe<UserDepartmentId>;
export type UserAccountType = FieldBrand<
  {
    id: AccountTypeId;
    nanoId: AccountTypeNanoId;
    name: AccountTypeName;
    roles: AccountTypeRoles;
    createdAt: AccountTypeCreatedAt;
  },
  'UserAccountType'
>;
export type MayBeUserAccountType = MayBe<UserAccountType>;

export type UserDepartment = FieldBrand<
  {
    id: DepartmentId;
    nanoId: DepartmentNanoId;
    name: DepartmentName;
  },
  'UserDepartment'
>;
export type MayBeUserDepartment = MayBe<UserDepartment>;

export type UserJobResumeRecruiterId = FieldBrand<
  JobResumeRecruiterId,
  'UserJobResumeRecruiterId'
>;
export type MayBeUserJobResumeRecruiterId = MayBe<UserJobResumeRecruiterId>;
export type UserCompanyId = FieldBrand<CompanyId, 'UserCompanyId'>;
export type MayBeUserCompanyId = MayBe<UserCompanyId>;
export type MayBeUserCompany = MayBe<UserCompany>;
export type UserIndustryId = FieldBrand<IndustryId, 'UserIndustryId'>;
export type MayBeUserIndustryId = MayBe<UserIndustryId>;
export type MayBeUserIndustry = MayBe<UserIndustry>;
export type UserImage = FieldBrand<
  {
    id: ImageId;
    nanoId: ImageNanoId;
    name: ImageName;
    file: ImageFile;
    key: ImageKey;
    createdAt: ImageCreatedAt;
  },
  'UserImage'
>;
export type MayBeUserImage = MayBe<UserImage>;
export type UserCompany = FieldBrand<
  {
    id: CompanyId;
    nanoId: CompanyNanoId;
    name: CompanyName;
    image: CompanyImage;
  },
  'UserCompany'
>;
export type UserIndustry = FieldBrand<
  {
    id: IndustryId;
    name: IndustryName;
  },
  'UserIndustry'
>;
export type UserCountryId = FieldBrand<CountryId, 'UserCountryId'>;
export type MayBeUserCountryId = MayBe<UserCountryId>;
export type UserCountry = FieldBrand<
  { id: CountryId; name: CountryName; label: CountryLabel; code: CountryCode },
  'UserCountry'
>;
export type MayBeUserCountry = MayBe<UserCountry>;
export type UserStateId = FieldBrand<StateId, 'UserStateId'>;
export type MayBeUserStateId = MayBe<UserStateId>;
export type UserState = FieldBrand<
  { id: StateId; name: StateName; label: StateLabel; isoCode: StateIsoCode },
  'UserState'
>;
export type MayBeUserState = MayBe<UserState>;
export type UserCityId = FieldBrand<CityId, 'UserCityId'>;
export type MayBeUserCityId = MayBe<UserCityId>;
export type UserCity = FieldBrand<
  { id: CityId; name: CityName; label: CityLabel },
  'UserCity'
>;
export type MayBeUserCity = MayBe<UserCity>;
export type UserJobResumeRecruiter = FieldBrand<
  {
    id: JobResumeRecruiterId;
    nanoId: JobResumeRecruiterNanoId;
    job: { nanoId: JobNanoId };
  },
  'UserJobResumeRecruiter'
>;
export type MayBeUserJobResumeRecruiter = MayBe<UserJobResumeRecruiter>;
export type UserPersonalEmail = FieldBrand<string, 'UserPersonalEmail'>;
export type UserCompanyPhone = FieldBrand<string, 'UserCompanyPhone'>;
export type UserCompanyEmail = FieldBrand<string, 'UserCompanyEmail'>;
export type UserQualifications = FieldBrand<string, 'UserQualifications'>;
export type UserIndustryIds = FieldBrand<UserIndustryId[], 'UserIndustryIds'>;
export type UserIndustries = FieldBrand<
  { id: IndustryId; name: IndustryName }[],
  'UserIndustries'
>;
export type UserIndustryExperience = FieldBrand<
  string,
  'UserIndustryExperience'
>;
export type MayBeUserIndustryExperience = MayBe<UserIndustryExperience>;
export type UserSeniority = FieldBrand<string, 'UserSeniority'>;
export type MayBeUserSeniority = MayBe<UserSeniority>;
export type UserZipCode = FieldBrand<string, 'UserZipCode'>;
export type MayBeUserZipCode = MayBe<UserZipCode>;
export type UserSource = FieldBrand<string, 'UserSource'>;
export type MayBeUserSource = MayBe<UserSource>;
export type UserLinkedinUrl = FieldBrand<string, 'UserLinkedinUrl'>;
export type MayBeUserLinkedinUrl = MayBe<UserLinkedinUrl>;
export type UserOtherDetails = FieldBrand<string, 'UserOtherDetails'>;
export type MayBeUserOtherDetails = MayBe<UserOtherDetails>;
export type UserSpecializationIds = FieldBrand<
  UserSpecializationId[],
  'UserSpecializationIds'
>;
export type UserSpecializations = FieldBrand<
  { id: UserSpecializationId; name: UserSpecializationName }[],
  'UserSpecializations'
>;
export type UserEmailSignature = FieldBrand<string, 'UserEmailSignature'>;
export type UserEmailSignatureEnabled = FieldBrand<
  boolean,
  'UserEmailSignatureEnabled'
>;
export type UserPassword = FieldBrand<string, 'UserPassword'>;
export type UserNewPassword = FieldBrand<string, 'UserNewPassword'>;
export type UserConfirmPassword = FieldBrand<string, 'UserConfirmPassword'>;
export type UserSecretQuestion1 = FieldBrand<string, 'UserSecretQuestion1'>;
export type UserSecretAnswer1 = FieldBrand<string, 'UserSecretAnswer1'>;
export type UserSecretQuestion2 = FieldBrand<string, 'UserSecretQuestion2'>;
export type UserSecretAnswer2 = FieldBrand<string, 'UserSecretAnswer2'>;
export type UserTimeZone = FieldBrand<string, 'UserTimeZone'>;
export type MayBeUserTimeZone = MayBe<UserTimeZone>;
export type UserProvidedRole = FieldBrand<string, 'UserProvidedRole'>;

export type UserAssignToJob = FieldBrand<string, 'UserAssignToJob'>;
export type UserTags = FieldBrand<string[], 'UserTags'>;

export type UserTalentPools = FieldBrand<
  {
    talentPoolId: TalentPoolId;
    createdAt: ItemCreatedAt;
  }[],
  'UserTalentPools'
>;
export type UserSkills = FieldBrand<string[], 'UserSkills'>;
export type UserSkillIds = SkillIds;

export type MayBeUserProvidedRole = MayBe<UserProvidedRole>;
export type MayBeUserCompanyEmail = MayBe<UserCompanyEmail>;
export type UserCreatedAt = FieldBrand<ItemCreatedAt, 'UserCreatedAt'>;

export type UserPermissionsNanoId = FieldBrand<
  PermissionNanoId,
  'UserPermissionsNanoId'
>;
export type UserPermissionsAction = FieldBrand<
  PermissionAction,
  'UserPermissionsName'
>;
export type UserPermissionsFrontend = FieldBrand<
  PermissionFrontend,
  'UserPermissionsFrontend'
>;

export type UserCompanyMembersNanoId = FieldBrand<
  CompanyMemberNanoId,
  'UserCompanyMembersNanoId'
>;
export type UserCompanyMembersCompany = FieldBrand<
  {
    id: CompanyId;
    nanoId: CompanyNanoId;
    name: CompanyName;
    image: CompanyImage;
  },
  'UserCompanyMembersCompany'
>;
export type UserCompanyMembersCompanyNanoId = FieldBrand<
  CompanyMemberCompanyNanoId,
  'UserCompanyMembersCompanyNanoId'
>;
export type UserCompanyMembersCompanyName = FieldBrand<
  CompanyMemberCompanyName,
  'UserCompanyMembersCompanyName'
>;
export type UserCompanyMembersCompanyImage = FieldBrand<
  CompanyMemberCompanyImage,
  'UserCompanyMembersCompanyImage'
>;

export type UserUserEmails = FieldBrand<
  {
    id: UserEmailId;
    nanoId: UserEmailNanoId;
    email: UserEmailEmail;
    label: UserEmailLabel;
  }[],
  'UserUserEmails'
>;
export type UserUserPhoneNumbers = FieldBrand<
  {
    id: UserPhoneNumberId;
    nanoId: UserPhoneNumberNanoId;
    phoneNumber: UserPhoneNumberPhoneNumber;
    label: UserPhoneNumberLabel;
  }[],
  'UserUserPhoneNumbers'
>;
export type UserEmployment = FieldBrand<
  {
    id: EmploymentId;
    nanoId: EmploymentNanoId;
    title: MayBeEmploymentTitle;
    startDate: MayBeEmploymentStartDate;
    endDate: MayBeEmploymentEndDate;
    cityId: MayBeEmploymentCityId;
    city: MayBeEmploymentCity;
    companyId: MayBeEmploymentCompanyId;
    company: MayBeEmploymentCompany;
    jobTitle: EmploymentJobTitle;
    jobTitleId: EmploymentJobTitleId;
    jobFunctions: EmploymentJobFunctions;
    jobFunctionIds: EmploymentJobFunctionIds;
    industries: EmploymentIndustries;
    industryIds: EmploymentIndustryIds;
    currently: EmploymentCurrently;
  },
  'UserEmployment'
>;
export type UserEmployments = FieldBrand<UserEmployment[], 'UserEmployments'>;
export type UserEducation = FieldBrand<
  {
    id: EducationId;
    nanoId: EducationNanoId;
    degree: EducationDegree;
    studyField: EducationStudyField;
    schoolName: EducationSchoolName;
    schoolType: EducationSchoolType;
    startDate: MayBeEducationStartDate;
    endDate: MayBeEducationEndDate;
    city: MayBeEducationCity;
  },
  'UserEducation'
>;
export type UserEducations = FieldBrand<UserEducation[], 'UserEducations'>;
export type UserCertification = FieldBrand<
  {
    id: CertificationId;
    nanoId: CertificationNanoId;
    name: CertificationName;
    credentialsId: CertificationCredentialsId;
    startDate: MayBeCertificationStartDate;
    endDate: MayBeCertificationEndDate;
    issueDate: MayBeCertificationIssueDate;
    companyId: MayBeCertificationCompanyId;
    company: MayBeCertificationCompanyId;
  },
  'UserCertification'
>;
export type UserCertifications = FieldBrand<
  UserCertification[],
  'UserCertifications'
>;
export type UserRoles = FieldBrand<
  {
    id: RoleId;
    nanoId: RoleNanoId;
    name: RoleName;
  }[],
  'UserRoles'
>;

export type MayBeUserCompanyRoleName = MayBe<RoleName>;
export type UserRoleNames = RoleName[];

export type UserPermissions = {
  [PermissionFields.NANO_ID]: UserPermissionsNanoId;
  [PermissionFields.ACTION]: UserPermissionsAction;
  [PermissionFields.FRONTEND]: UserPermissionsFrontend;
}[];

export type UserCompanyMembers = {
  [CompanyMemberFields.NANO_ID]: UserCompanyMembersNanoId;
  [CompanyMemberFields.COMPANY]: UserCompanyMembersCompany;
  [CompanyMemberFields.COMPANY_NANO_ID]: UserCompanyMembersCompanyNanoId;
  [CompanyMemberFields.COMPANY_NAME]: UserCompanyMembersCompanyName;
  [CompanyMemberFields.COMPANY_IMAGE]: UserCompanyMembersCompanyImage;
}[];

export type UserJobFunctionId = FieldBrand<JobFunctionId, 'UserJobFunctionId'>;

export type UserJobFunction = FieldBrand<
  { id: JobFunctionId; name: JobFunctionName },
  'UserJobFunction'
>;

export type UserJobTitleId = FieldBrand<JobTitleId, 'UserJobTitleId'>;

export type UserJobTitle = FieldBrand<
  { id: JobTitleId; name: JobTitleName },
  'UserJobTitle'
>;

export type CurrentUserUuid = UserUuid;

export type UserAssociation = {
  id: UserId;
  nanoId: UserNanoId;
  name: UserName;
  image: UserImage;
};

export const enum UserSearchTypes {
  NONE = 0,
  LEAD = 1,
  PROSPECT = 2,
  USER = 3,
  CUSTOMER = 4,
  APPLICANT = 5
}

export const enum UserCaptureTypes {
  NONE = 0,
  CANDIDATE = 1,
  EMPLOYEE = 2,
  RECRUITER = 3
}

export const enum UserActionTypes {
  NONE = 0,
  HIRING_MANAGER = 1,
  RECRUITING_COORDINATOR = 2,
  INTERVIEW_PANELIST = 3,
  COLLABORATOR = 4,
  RECRUITER = 5
}

export const industryExperienceTypes = [
  { label: '0-1', value: '0_1' },
  { label: '2-3', value: '2_3' },
  { label: '4-5', value: '4_5' },
  { label: '6-7', value: '6_7' },
  { label: '8-9', value: '8_9' },
  { label: '10-14', value: '10_14' },
  { label: '15-19', value: '15_19' },
  { label: '20-29', value: '20_29' },
  { label: '30+', value: '30_plus' }
];

export const seniorityTypes = [
  { label: 'Intern', value: 'intern' },
  { label: 'Graduate', value: 'graduate' },
  { label: 'Junior', value: 'junior' },
  { label: 'Staff', value: 'staff' },
  { label: 'Senior', value: 'senior' },
  { label: 'Lead', value: 'lead' },
  { label: 'Manager', value: 'manager' },
  { label: 'Director', value: 'director' },
  { label: 'Vice President', value: 'vice_president' },
  { label: 'Executive', value: 'executive' }
];

export const enum UserFields {
  ID = 'id',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  NAME = 'name',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  FULL_NAME = 'fullName',
  TITLE = 'title',
  EMAIL = 'email',
  WORK_EMAIL = 'workEmail',
  ACCOUNT_TYPE_ID = 'accountTypeId',
  ACCOUNT_TYPE = 'accountType',
  ACCOUNT_TYPES = 'accountTypes',
  LOCATION = 'location',
  PHONE = 'phone',
  DESCRIPTION = 'description',
  PERSONAL_EMAIL = 'personalEmail',
  COMPANY_PHONE = 'companyPhone',
  COMPANY_EMAIL = 'companyEmail',
  QUALIFICATIONS = 'qualifications',
  EMAIL_SIGNATURE = 'emailSignature',
  EMAIL_SIGNATURE_ENABLED = 'emailSignatureEnabled',
  INDUSTRY_EXPERIENCE = 'industryExperience',
  SENIORITY = 'seniority',
  ZIP_CODE = 'zipCode',
  EMAIL_SYNC_ENABLED_AT = 'emailSyncEnabledAt',
  EMAIL_SYNC_PROVIDER = 'emailSyncProvider',
  SCHEDULE_INTERVIEWS_ENABLED_AT = 'scheduleInterviewsEnabledAt',
  SCHEDULE_INTERVIEWS_PROVIDER = 'scheduleInterviewsProvider',
  APPROVED_AT = 'approvedAt',
  PROVIDED_ROLE = 'providedRole',
  INDUSTRY_IDS = 'industryIds',
  INDUSTRIES = 'industries',
  SPECIALIZATION_IDS = 'specializationIds',
  SPECIALIZATIONS = 'specializations',
  USER_EMAILS = 'userEmails',
  USER_PHONE_NUMBERS = 'userPhoneNumbers',
  COMPANY_ID = 'companyId',
  COMPANY = 'company',
  INDUSTRY_ID = 'industryId',
  INDUSTRY = 'industry',
  JOB_FUNCTION_ID = 'jobFunctionId',
  JOB_FUNCTION = 'jobFunction',
  JOB_TITLE_ID = 'jobTitleId',
  JOB_TITLE = 'jobTitle',
  COUNTRY_ID = 'countryId',
  COUNTRY = 'country',
  STATE_ID = 'stateId',
  STATE = 'state',
  CITY_ID = 'cityId',
  CITY = 'city',
  IMAGE_ID = 'imageId',
  IMAGE = 'image',
  ACTION_TYPE = 'actionType',
  SEARCH_TYPE = 'searchType',
  CAPTURE_TYPE = 'captureType',
  PASSWORD = 'password',
  NEW_PASSWORD = 'newPassword',
  CONFIRM_PASSWORD = 'confirmPassword',
  SECRET_QUESTION1 = 'secretQuestion1',
  SECRET_QUESTION2 = 'secretQuestion2',
  SECRET_ANSWER1 = 'secretAnswer1',
  SECRET_ANSWER2 = 'secretAnswer2',
  AFTER_SIGN_IN_ROUTE = 'afterSignInRoute',
  HAS_SECRET_QUESTION1 = 'hasSecretQuestion1',
  HAS_SECRET_QUESTION2 = 'hasSecretQuestion2',
  HAS_SECRET_ANSWER1 = 'hasSecretAnswer1',
  HAS_SECRET_ANSWER2 = 'hasSecretAnswer2',
  TIME_ZONE = 'timeZone',
  JOB_RESUME_RECRUITER_ID = 'jobResumeRecruiterId',
  JOB_RESUME_RECRUITER_NANO_ID = 'jobResumeRecruiterNanoId',
  CREATED_AT = 'createdAt',
  PRIMARY_JOB_FUNCTION = 'primaryJobFunction',
  PHONE_NUMBERS = 'phoneNumbers',
  EMAIL_ADDRESSES = 'emailAddresses',
  EXPERIENCES = 'experiences',
  EDUCATION_IDS = 'educationIds',
  EMPLOYMENTS = 'profileEmployments',
  EDUCATIONS = 'profileEducations',
  CERTIFICATIONS = 'profileCertifications',
  SKILL_IDS = 'skillIds',
  ASSIGN_TO_JOB = 'assignToJob',
  SOURCE = 'source',
  TAGS = 'tags',
  SKILLS = 'skills',
  OTHER_DETAILS = 'otherDetails',
  LINKEDIN_URL = 'linkedinUrl',
  TALENT_POOLS = 'talentPools',
  BLOCKED_AT = 'blockedAt',
  ROLE_ID = 'roleId',
  DEPARTMENT_ID = 'departmentId',
  DEPARTMENT = 'department'
}
