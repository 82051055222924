const colors = {
  primary: {
    50: '#F4F9FB',
    100: '#E6F2F6',
    200: '#82BFD1',
    300: '#06a1ce',
    400: '#5aa9c0',
    500: '#0580A4',
    600: '#046F8F',
    700: '#04617c',
    800: '#034558',
    900: '#023a4a'
  },
  gray: {
    50: '#F8FAFB',
    100: '#F4F6F7',
    200: '#E1EAED',
    300: '#DAE1E3',
    400: '#C0C9CC',
    500: '#98A5A9',
    600: '#697275',
    800: '#16181E',
    900: '#17191A'
  },
  green: {
    100: '#E9FAF2',
    500: '#1DC67D',
    900: '#0F8E6A'
  },
  red: {
    100: '#FEEDEB',
    500: '#F1472F',
    900: '#BA3724'
  },
  yellow: {
    500: '#EEA401'
  }
};

export default colors;
