import React, { Fragment } from 'react';
import { SystemProps } from '@chakra-ui/system';
import { SystemStyleObject } from '@chakra-ui/styled-system';

import { I18nText, IsLoading, IsDisabled } from '../../../types';

import { TextStyle } from '../../../theme';

import { Button, ButtonProps } from '../../Button';
import { Text } from '../../Text';

interface PureButtonHelperProps extends ButtonProps {
  as?: React.ElementType;
  isLoading?: IsLoading;
  i18nText: I18nText;
  disabled?: IsDisabled;
  isDisabled?: IsDisabled;
  hierarchy?:
    | 'primary'
    | 'danger'
    | 'warning'
    | 'secondary'
    | 'tertiary'
    | 'link'
    | 'unstyled'
    | 'outline'
    | 'solid';
  colorScheme?: string;
  display?: SystemProps['display'];
  minW?: SystemProps['minWidth'];
  w?: SystemProps['width'];
  ml?: SystemProps['margin'];
  mr?: SystemProps['margin'];
  mt?: SystemProps['margin'];
  px?: SystemProps['padding'];
  p?: SystemProps['padding'];
  py?: SystemProps['padding'];
  size?: 'small' | 'medium' | 'large';
  justifyContent?: 'left' | 'center' | 'right';
  color?: SystemProps['color'];
  background?: SystemProps['background'];
  textStyle?: TextStyle;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  flexShrink?: number;
  flexGrow?: number;
  _hover?: SystemStyleObject;
  fontWeight?: SystemProps['fontWeight'];
}

function PureButtonHelper({
  as,
  isLoading,
  i18nText,
  disabled,
  hierarchy,
  colorScheme,
  background,
  minW,
  w,
  ml,
  mr,
  mt,
  px,
  py,
  p,
  size,
  justifyContent,
  color,
  textStyle,
  leftIcon,
  rightIcon,
  display,
  isDisabled,
  flexShrink = 0,
  flexGrow,
  onClick,
  _hover,
  fontWeight,
  ...props
}: PureButtonHelperProps) {
  return (
    <Button
      as={as}
      isLoading={isLoading}
      disabled={isLoading || disabled}
      isDisabled={isDisabled}
      hierarchy={hierarchy}
      colorScheme={colorScheme}
      display={display}
      minW={minW}
      w={w}
      ml={ml}
      mr={mr}
      mt={mt}
      px={px}
      py={py}
      p={p}
      size={size}
      cursor="pointer"
      justifyContent={justifyContent}
      color={color}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      onClick={onClick}
      flexShrink={flexShrink}
      flexGrow={flexGrow}
      background={background}
      _hover={_hover}
      fontWeight={fontWeight}
      {...props}
    >
      {isLoading ? (
        <>
          <Text as="span" opacity={0}>
            {i18nText}
          </Text>

          <Text
            as="span"
            top="0"
            h="100%"
            display="flex"
            position="absolute"
            alignItems="center"
          >
            &bull; &bull; &bull;
          </Text>
        </>
      ) : (
        <Fragment>
          {textStyle ? (
            <Text as="span" textStyle={textStyle}>
              {i18nText}
            </Text>
          ) : (
            i18nText
          )}
        </Fragment>
      )}
    </Button>
  );
}

export default PureButtonHelper;
