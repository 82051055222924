import { Icon, IconProps } from '@chakra-ui/icon';

const NXTeammate = ({ h = 6, w = 6, ...props }: IconProps) => (
  <Icon w={w} h={h} viewBox="0 0 24 24" {...props} fill="none">
    <path
      fill="#7A0085"
      d="M16.65 1H7.36c-.295 0-.579.112-.788.312-.21.2-.327.472-.327.755V7.4c0 .283.118.554.327.754.21.2.493.312.788.312h2.7l1.803 2.07a.186.186 0 00.143.064.192.192 0 00.142-.064l1.803-2.07h2.7c.295 0 .579-.112.788-.312.209-.2.326-.471.326-.754V2.067c0-.283-.117-.555-.326-.755-.21-.2-.493-.312-.788-.312z"
    />
    <path
      fill="#AB56B3"
      fillRule="evenodd"
      d="M15.367 9.187h2.194c.296 0 .58-.112.788-.312.21-.2.327-.472.327-.755V4.121h4.448c.221 0 .434.084.59.234.158.15.246.354.246.566v4a.783.783 0 01-.245.566.856.856 0 01-.591.234h-2.025l-1.352 1.552a.14.14 0 01-.107.048.145.145 0 01-.107-.048L18.18 9.721h-2.025a.856.856 0 01-.591-.234.796.796 0 01-.198-.3zM8.592 9.187H6.4c-.296 0-.58-.112-.788-.312-.21-.2-.327-.472-.327-.755V4.121H.836a.856.856 0 00-.591.234.783.783 0 00-.245.566v4c0 .212.088.416.245.566.157.15.37.234.591.234h2.025l1.352 1.552a.14.14 0 00.107.048.145.145 0 00.107-.048L5.78 9.721h2.025a.856.856 0 00.591-.234.796.796 0 00.197-.3z"
      clipRule="evenodd"
    />
    <path
      fill="#7A0085"
      d="M18.723 17.56c1.33 0 2.4-1.576 2.4-2.88 0-1.324-1.076-2.4-2.4-2.4a2.403 2.403 0 00-2.4 2.4c0 1.304 1.07 2.88 2.4 2.88zM21.516 19.525A7.597 7.597 0 0018.725 19c-1.22 0-2.19.285-2.79.524a1.67 1.67 0 00-.174.083c.193.077.373.155.527.23a2.65 2.65 0 011.477 2.371v.151h4.56a.24.24 0 00.24-.24v-1.037c0-.693-.412-1.304-1.05-1.558z"
    />
    <path
      fill="#AB56B3"
      d="M12.004 18.04c1.622 0 2.88-1.807 2.88-3.36a2.883 2.883 0 00-2.88-2.88 2.883 2.883 0 00-2.88 2.88c0 1.553 1.258 3.36 2.88 3.36zM15.6 20.268c-.74-.36-1.963-.789-3.594-.789-1.63 0-2.854.43-3.593.79a2.168 2.168 0 00-1.207 1.939v1.351c0 .133.107.24.24.24h9.12a.24.24 0 00.24-.24v-1.351c0-.821-.474-1.582-1.207-1.94z"
    />
    <path
      fill="#7A0085"
      d="M5.279 17.56c-1.33 0-2.4-1.576-2.4-2.88 0-1.324 1.076-2.4 2.4-2.4 1.323 0 2.4 1.076 2.4 2.4 0 1.304-1.071 2.88-2.4 2.88zM2.49 19.525A7.596 7.596 0 015.28 19c1.22 0 2.191.285 2.791.524.06.024.118.053.174.083a7.79 7.79 0 00-.528.23 2.65 2.65 0 00-1.476 2.371v.151H1.68a.24.24 0 01-.24-.24v-1.037c0-.693.412-1.304 1.049-1.558z"
    />
  </Icon>
);
export default NXTeammate;
