import { Flex } from '@chakra-ui/react';
import { useCurrentUser } from '../../../../../../../../../../../auth/hooks/useAuth';
import { AvatarHelper } from '../../../../../../../../../../../helpers/AvatarHelper';
import {
  Button,
  ButtonProps
} from '../../../../../../../../../../../helpers/Button';
import { Text } from '../../../../../../../../../../../helpers/Text';
import { CheckboxField } from '../../../../../../../../../../../helpers/forms/formFields/CheckboxField';
import { SnoozeSlotType } from '../../helpers';
import { ViewJobCandidateModalPhoneSidebarChooseAvailabilityTime } from '../ViewJobCandidateModalPhoneSidebarChooseAvailabilityTime';

interface ViewJobCandidateModalPhoneSidebarAvailabilitySlotProps
  extends ButtonProps {
  label: string;
  time: string;
  day?: string;
  value: string;
  selectedSlot?: SnoozeSlotType | null;
  isMultiSelect?: boolean;
  showAvatar?: boolean;
}

const ViewJobCandidateModalPhoneSidebarAvailabilitySlot = ({
  day,
  time,
  label,
  value,
  selectedSlot,
  isMultiSelect,
  showAvatar,
  onClick,
  ...props
}: ViewJobCandidateModalPhoneSidebarAvailabilitySlotProps) => {
  const currentUser = useCurrentUser();

  return (
    <Flex
      gap={1.5}
      borderRadius="base"
      bg={selectedSlot === value ? 'blackAlpha.50' : 'transparent'}
      justifyContent="space-between"
      _hover={{
        bg: 'blackAlpha.50'
      }}
    >
      <Button
        pl={2}
        minH={0}
        h="auto"
        value={value}
        display="flex"
        cursor="pointer"
        onClick={onClick}
        alignItems="center"
        hierarchy="unstyled"
        border="none"
        size="small"
        mr="auto"
        flex={1}
        {...props}
      >
        <Text textStyle="body1Regular" noOfLines={1}>
          {label}:
        </Text>

        <Flex gap={2} alignItems="center" ml="auto">
          {showAvatar ? (
            <AvatarHelper size="xs" image={currentUser.image} />
          ) : null}

          <Flex gap={1} color="gray.800" fontSize="sm" alignItems="center">
            {day ? <Text>{day}</Text> : null}
          </Flex>
        </Flex>
      </Button>

      <Flex pr={2}>
        <Flex
          h={8}
          color="gray.800"
          fontSize="sm"
          alignItems="stretch"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <ViewJobCandidateModalPhoneSidebarChooseAvailabilityTime
            time={time}
            onClick={onClick}
          />
        </Flex>

        {isMultiSelect ? <CheckboxField size="small" /> : null}
      </Flex>
    </Flex>
  );
};

export default ViewJobCandidateModalPhoneSidebarAvailabilitySlot;
