import { Icon, IconProps } from '@chakra-ui/icon';

const CareerSiteIcon = ({
  w = '24px',
  h = '24px',
  fill = 'gray.500',
  ...props
}: IconProps) => (
  <Icon viewBox="0 0 24 24" w={w} h={h} fill={fill} {...props}>
    <g fillRule="evenodd" clipRule="evenodd">
      <path d="M5 6.182a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H3.75a.739.739 0 0 0-.75.727v.455a1 1 0 0 0 1 1h1ZM12.75 11.274c0 .402.326.727.727.727h4.546a.727.727 0 1 0 0-1.454h-4.546a.727.727 0 0 0-.727.727Zm0 2.906c0 .402.326.728.727.728h2.296a.727.727 0 1 0 0-1.455h-2.296a.727.727 0 0 0-.727.727Zm-7.5 1.185a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-3.818a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v3.818ZM4 7.637a1 1 0 0 0-1 1v10.636c0 .402.336.727.75.727h16.5c.414 0 .75-.325.75-.727V8.637a1 1 0 0 0-1-1H4ZM20 6.182a1 1 0 0 0 1-1v-.455A.739.739 0 0 0 20.25 4H8.5a1 1 0 0 0-1 1v.182a1 1 0 0 0 1 1H20Z" />
    </g>
  </Icon>
);
export default CareerSiteIcon;
