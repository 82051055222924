import {
  Avatar,
  Box,
  ButtonGroup,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  Stack,
  useDisclosure
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { Button } from '../../../../../../helpers/Button';
import { Text } from '../../../../../../helpers/Text';
import { WysiwygFieldControl } from '../../../../../../helpers/forms/formFields/WysiwygField/components/WysiwygFieldControl';
import { ChevronDownIcon } from '../../../../../../icons/ChevronDownIcon';

interface ViewJobCandidateDetailsAddCommentProps {
  trigger: ReactNode;
}

function ViewJobCandidateDetailsAddComment({
  trigger
}: ViewJobCandidateDetailsAddCommentProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Flex onClick={onOpen}>{trigger}</Flex>

      <Modal isOpen={isOpen} onClose={onClose} returnFocusOnClose={false}>
        <ModalContent
          mb={2}
          w="2xl"
          maxW="100%"
          pos="fixed"
          bottom={0}
          borderRadius="base"
          boxShadow="0 10px 25px -5px rgba(0, 0, 0, 0.1), 0 0 25px 25px rgba(0, 0, 0, 0.04)"
        >
          <ModalBody
            p={4}
            sx={{
              '.rdw-fontsize-wrapper, .rdw-image-wrapper': {
                display: 'none !important'
              },
              '.rdw-fontfamily-wrapper': {
                display: 'none !important'
              }
            }}
          >
            <Stack spacing={4}>
              <HStack>
                <Avatar src="/images/default-avatar-1.png" w={6} h={6} />

                <Text>Miracle Dokidis</Text>
              </HStack>

              <Flex gap={4} flexDir="column" flex={1}>
                <Flex gap={4} alignItems="center" w="full">
                  <Text fontSize="md" fontWeight="semibold">
                    Add a comment
                  </Text>

                  <Button
                    ml="auto"
                    size="small"
                    color="gray.700"
                    boxShadow="none"
                    hierarchy="ghost"
                    rightIcon={<ChevronDownIcon w={4} h={4} />}
                  >
                    Select Template
                  </Button>
                </Flex>

                <Box h="320px">
                  <WysiwygFieldControl
                    name="Test"
                    value=""
                    defaultValue=""
                    onChange={() => {
                      //
                    }}
                  />
                </Box>
              </Flex>
            </Stack>
          </ModalBody>

          <ModalFooter pt={0}>
            <HStack flex={1} w="full">
              <Button mr="auto" hierarchy="link" size="small">
                Last call note
              </Button>

              <ButtonGroup spacing={2}>
                <Button hierarchy="tertiary" size="small" onClick={onClose}>
                  Cancel
                </Button>

                <Button size="small">Comment</Button>
              </ButtonGroup>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ViewJobCandidateDetailsAddComment;
