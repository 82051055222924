import React from 'react';
import { Tag } from '@chakra-ui/react';

import { RelativeDateHelper } from '../../../../../../../../helpers/RelativeDateHelper';

import {
  DateUtils,
  DateTimeType
} from '../../../../../../../../utils/DateUtils';

import {
  MayBeJobCandidateDisqualifiedAt,
  MayBeJobCandidateDueDate,
  MayBeJobCandidateViewedAt,
  MayBeJobCandidateWorkflowStageTaskScheduledAt
} from '../../../../../../../../main/jobCandidates/jobCandidatesTypes';

interface CandidateTaskMenuBadgeProps {
  jobCandidate: {
    dueDate: MayBeJobCandidateDueDate;
    workflowStageTaskScheduledAt: MayBeJobCandidateWorkflowStageTaskScheduledAt;
    viewedAt: MayBeJobCandidateViewedAt;
    disqualifiedAt: MayBeJobCandidateDisqualifiedAt;
  };
}

function CandidateTaskMenuBadge({ jobCandidate }: CandidateTaskMenuBadgeProps) {
  const dueDay = DateUtils.calendarDay(jobCandidate.dueDate) as DateTimeType;

  const scheduledDay = DateUtils.calendarDay(
    jobCandidate.workflowStageTaskScheduledAt
  ) as DateTimeType;

  const today = DateUtils.startOfToday();

  return today.equals(scheduledDay) ? (
    <Tag bg="green.900" color="white" borderRadius="base">
      In progress
    </Tag>
  ) : today > dueDay && jobCandidate.viewedAt ? (
    <Tag bg="red.500" color="white" borderRadius="base">
      Over due
    </Tag>
  ) : today > dueDay && !jobCandidate.viewedAt ? (
    <Tag bg="#EEA401" color="white" borderRadius="base">
      Follow up
    </Tag>
  ) : today.equals(dueDay) ? (
    <Tag bg="purple.400" color="white" borderRadius="base">
      Due{' '}
      <RelativeDateHelper date={jobCandidate.dueDate as string} calendarDate />
    </Tag>
  ) : (
    <Tag bg="#EEA401" color="white" borderRadius="base">
      Due{' '}
      <RelativeDateHelper date={jobCandidate.dueDate as string} calendarDate />
    </Tag>
  );
}

export default CandidateTaskMenuBadge;
