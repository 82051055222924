import {
  FetchJobCandidatesCompletedWorkflowStageTasksCacheKey,
  FetchJobCandidatesCompletedWorkflowStageTaskCacheKey
} from './jobCandidatesCompletedWorkflowStageTasksTypes';

import { JobNanoId } from '../jobs/jobsTypes';

export class JobCandidatesCompletedWorkflowStageTasksCache {
  static indexCacheKey() {
    return 'jobCandidatesCompletedWorkflowStageTasks' as FetchJobCandidatesCompletedWorkflowStageTasksCacheKey;
  }

  static showCacheKey() {
    return 'jobCandidatesCompletedWorkflowStageTask' as FetchJobCandidatesCompletedWorkflowStageTaskCacheKey;
  }

  static jobIndexCacheKey(jobNanoId: JobNanoId) {
    return `jobCandidatesCompletedWorkflowStageTasks-${jobNanoId}` as FetchJobCandidatesCompletedWorkflowStageTasksCacheKey;
  }
}
