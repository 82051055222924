import {
  MayBe,
  FieldBrand,
  ItemID,
  ItemUUID,
  ItemNanoId,
  ItemCreatedAt
} from '../../../types';
import {
  JobFunctionDiscipline,
  JobFunctionId,
  JobFunctionName
} from '../../jobFunctions/jobFunctionsTypes';

import {
  UserId,
  UserNanoId,
  UserName,
  UserImage
} from '../../users/usersTypes';

export type JobTitleId = FieldBrand<ItemID, 'JobTitleId'>;
export type MayBeJobTitleId = MayBe<JobTitleId>;
export type JobTitleUuid = FieldBrand<ItemUUID, 'JobTitleUuid'>;
export type MayBeJobTitleUuid = MayBe<JobTitleUuid>;
export type JobTitleNanoId = FieldBrand<ItemNanoId, 'JobTitleNanoId'>;
export type MayBeJobTitleNanoId = MayBe<JobTitleNanoId>;
export type JobTitleName = FieldBrand<string, 'JobTitleName'>;
export type JobTitleUserId = FieldBrand<UserId, 'JobTitleUserId'>;
export type JobTitleUser = FieldBrand<
  {
    id: UserId;
    nanoId: UserNanoId;
    name: UserName;
    image: UserImage;
  },
  'JobTitleUser'
>;
export type JobTitleCreatedAt = FieldBrand<ItemCreatedAt, 'JobTitleCreatedAt'>;
export type JobTitleJobFunctionId = FieldBrand<
  JobFunctionId,
  'JobTitleJobFunctionId'
>;
export type JobTitleJobFunction = FieldBrand<
  {
    id: JobFunctionId;
    name: JobFunctionName;
    discipline: JobFunctionDiscipline;
  },
  'JobTitleJobFunction'
>;

export const enum JobTitleFields {
  ID = 'id',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  NAME = 'name',
  USER_ID = 'userId',
  USER = 'user',
  CREATED_AT = 'createdAt',
  JOB_FUNCTION = 'jobFunction',
  JOB_FUNCTION_ID = 'jobFunctionId'
}
