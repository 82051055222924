import debounce from 'lodash/debounce';
import { Control, FieldPath, FieldValues } from 'react-hook-form';

import { ErrorMessage, IsDisabled, IsRequired } from '../../../../../types';

import { JobFields, JobInterviewerIds } from '../../../jobsTypes';

import {
  UserFields,
  UserActionTypes,
  FetchUsersFilters,
  FetchUsersSort
} from '../../../../users/usersTypes';

import {
  fetchUsersQuery,
  FetchUsersResponse
} from '../../../../users/queries/fetchUsers.query';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';
import { usePaginatedUsers } from '../../../../users/hooks/usePaginatedUsers';

import { UsersCache } from '../../../../users/UsersCache';

import { MultiSelectField } from '../../../../../helpers/forms/formFields/MultiSelectField';

import { ImagesUrl } from '../../../../images/ImagesUrl';

import { JobSelectInterviewerFieldJob } from './JobSelectInterviewerField.types';

interface JobSelectInterviewerFieldProps<T extends FieldValues> {
  isRequired?: IsRequired;
  isDisabled?: IsDisabled;
  control: Control<T>;
  job?: JobSelectInterviewerFieldJob | null;
  currentJobInterviewerIds?: JobInterviewerIds;
  errorMessage: ErrorMessage;
  showAddForm?: () => void;
}

function JobSelectInterviewerField<T extends FieldValues>({
  isRequired,
  isDisabled,
  control,
  job,
  errorMessage,
  showAddForm
}: JobSelectInterviewerFieldProps<T>) {
  const currentUser = useCurrentUser();

  const { users, usersIsLoading, usersErrorMessage, changeUsersFilters } =
    usePaginatedUsers<FetchUsersResponse>({
      query: fetchUsersQuery,
      cacheKey: UsersCache.postJobCacheKey(),
      initialFilters: {
        [UserFields.ACTION_TYPE]: {
          operator: 'eq',
          value: UserActionTypes.INTERVIEW_PANELIST
        },
        [UserFields.COMPANY_ID]: {
          operator: 'eq',
          value: currentUser.companyId
        }
      } as unknown as FetchUsersFilters,
      initialSort: {
        [UserFields.NAME]: { ascending: true }
      } as unknown as FetchUsersSort
    });

  // useEffect(() => {
  //   if (!currentJobInterviewerId && job?.interviewers?.name) {
  //     changeUsersFilters({
  //       [UserFields.NAME]: {
  //         operator: 'ilike',
  //         value: `%${job?.interviewers?.name}%`
  //       }
  //     });
  //   }
  // }, [currentJobInterviewerId, job?.interviewers?.name, changeUsersFilters]);

  const defaultJobInterviewers = job?.interviewers
    ? job?.interviewers.map((interviewer) => ({
        value: interviewer.id,
        label: interviewer.name,
        image: ImagesUrl.file(interviewer.image),
        withCheckIcon: true
      }))
    : undefined;

  const debouncedFilterUsers = debounce<(updatedValue: string) => void>(
    (updatedValue) =>
      changeUsersFilters({
        [UserFields.NAME]: {
          operator: 'ilike',
          value: `%${updatedValue}%`
        }
      }),
    500
  );

  return (
    <MultiSelectField
      withImages
      withAddNewButton
      addNewButtonLabel="Add Interview Panel"
      addNewButtonAction={showAddForm}
      isRequired={isRequired}
      isDisabled={isDisabled}
      control={control}
      label="Interview Panel"
      placeholder="Select"
      name={JobFields.INTERVIEWER_IDS as FieldPath<T>}
      options={users.map((user) => ({
        value: user.id,
        label: user.name || user.email,
        image: ImagesUrl.file(user.image),
        withCheckIcon: true
      }))}
      defaultValue={defaultJobInterviewers}
      errorMessage={errorMessage || usersErrorMessage}
      isLoading={usersIsLoading}
      onInputChange={debouncedFilterUsers}
    />
  );
}

export default JobSelectInterviewerField;
