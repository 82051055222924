import { useCallback } from 'react';
import { signOut as nextAuthSignOut } from 'next-auth/react';

import { AuthRoutes } from '../../AuthRoutes';

function useSignOut() {
  return useCallback<() => void>(
    () => nextAuthSignOut({ callbackUrl: AuthRoutes.signIn() }),
    []
  );
}

export default useSignOut;
