import snakeCase from 'lodash/snakeCase';

import {
  FetchJobFunctionsQuery,
  JobFunctionFields,
  JobFunctionId,
  JobFunctionNanoId,
  JobFunctionName,
  JobFunctionDiscipline,
  JobFunctionDisciplineId,
  JobFunctionCreatedAt
} from '../jobFunctionsTypes';

export interface FetchJobFunctionsResponse {
  [JobFunctionFields.ID]: JobFunctionId;
  [JobFunctionFields.NANO_ID]: JobFunctionNanoId;
  [JobFunctionFields.NAME]: JobFunctionName;
  [JobFunctionFields.DISCIPLINE]: JobFunctionDiscipline;
  [JobFunctionFields.DISCIPLINE_ID]: JobFunctionDisciplineId;
  [JobFunctionFields.CREATED_AT]: JobFunctionCreatedAt;
}

export const fetchJobFunctionsColumns = [
  JobFunctionFields.ID,
  JobFunctionFields.NANO_ID,
  JobFunctionFields.NAME,
  JobFunctionFields.DISCIPLINE_ID,
  JobFunctionFields.CREATED_AT
];

const fetchJobFunctionsSBColumns = fetchJobFunctionsColumns.map(
  (fetchJobFunctionsColumn) => snakeCase(fetchJobFunctionsColumn)
);

export const fetchJobFunctionsQuery = `
  ${fetchJobFunctionsSBColumns.join(',')},
  user:user_id(id,nano_id,name,image:image_id(id,nano_id,name,file,key,created_at))
` as FetchJobFunctionsQuery;
