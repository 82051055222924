import { Grid, GridItem, useDisclosure } from '@chakra-ui/react';

import { AlertMessage } from '../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../helpers/LoadingSkeleton';

import { AppLayoutRequiredProps } from '../../../../layouts/AppLayout';

import { useFetchJobCandidateByNanoId } from '../../../jobCandidates/hooks/useFetchJobCandidateByNanoId';
import { useFetchJobByNanoId } from '../../../jobs/hooks/useFetchJobByNanoId';

import { JobCandidatesCache } from '../../../jobCandidates/JobCandidatesCache';
import { JobsCache } from '../../../jobs/JobsCache';

import {
  JobCandidateNanoId,
  JobCandidateSearchTypeLabels
} from '../../../jobCandidates/jobCandidatesTypes';
import { JobNanoId } from '../../../jobs/jobsTypes';

import {
  fetchJobCandidateQuery,
  FetchJobCandidateResponse
} from '../../../jobCandidates/queries/fetchJobCandidate.query';
import {
  fetchJobQuery,
  FetchJobResponse
} from '../../../jobs/queries/fetchJob.query';

import { useCurrentUser } from '../../../../auth/hooks/useAuth';

import { TalentPoolCandidatesListContainer } from '../../components/helpers/TalentPoolCandidatesListContainer';

import { ReactNode } from 'react';
import { useScrollPosition } from '../../../../common/hooks/utils/useScrollPosition';
import { ShareCandidatesProfileProvider } from '../../../../context';
import { ViewJobCandidateDetailsCandidateDetails } from '../../../jobs/pages/ViewJobCandidateDetailsPage/components/ViewJobCandidateDetailsCandidateDetails';
import { JobCandidateDetailsJobCandidate } from '../../../jobs/pages/ViewJobCandidateDetailsPage/ViewJobCandidateDetailsPage.types';
import { SourcingProspectLayout } from '../../components/views/SourcingProspectLayout';
import { SourcingTalentProfileActivities } from './components/SourcingTalentProfileActivities';

interface SourcingTalentProfilePageProps extends AppLayoutRequiredProps {
  poolNanoId: JobNanoId;
  personNanoId: JobCandidateNanoId;
  children?: ReactNode;
  isBasePath?: boolean;
}

function SourcingTalentProfilePage({
  children,
  onSignOut,
  poolNanoId,
  personNanoId,
  isBasePath
}: SourcingTalentProfilePageProps) {
  const currentUser = useCurrentUser();

  const { job, jobIsFetched, jobErrorMessage } =
    useFetchJobByNanoId<FetchJobResponse>({
      query: fetchJobQuery,
      jobNanoId: poolNanoId,
      cacheKey: JobsCache.showCacheKey()
    });

  const { jobCandidate, jobCandidateIsFetched, jobCandidateErrorMessage } =
    useFetchJobCandidateByNanoId<FetchJobCandidateResponse>({
      jobCandidateNanoId: personNanoId,
      query: fetchJobCandidateQuery,
      cacheKey: JobCandidatesCache.showCacheKey()
    });

  const requiresCredit =
    (jobCandidate?.searchTypeLabel === JobCandidateSearchTypeLabels.LEAD ||
      jobCandidate?.searchTypeLabel ===
        JobCandidateSearchTypeLabels.PROSPECT) &&
    jobCandidate?.workflowStage?.arrangement === 0;

  const { onToggle: onCloseRightSidebar, isOpen: isOpenRightSidebar } =
    useDisclosure({
      onOpen: () => {
        onCloseActivityPane();
      }
    });

  const { isOpen: isOpenActivityPane, onClose: onCloseActivityPane } =
    useDisclosure({
      onOpen: () => {
        isOpenRightSidebar && onCloseRightSidebar();
      }
    });

  const {
    isOpen: isBioOutsideViewport,
    onOpen: setBioOutsideViewport,
    onClose: setBioInsideViewport
  } = useDisclosure();

  const bioContainerRef = useScrollPosition({
    setIsInsideViewport: setBioInsideViewport,
    setIsOutsideViewport: setBioOutsideViewport
  });

  return (
    <ShareCandidatesProfileProvider jobNanoId={poolNanoId}>
      <SourcingProspectLayout
        onSignOut={onSignOut}
        job={job}
        requiresCredit={requiresCredit}
        hasScrolled={isBioOutsideViewport}
        poolNanoId={poolNanoId}
        jobCandidate={jobCandidate as JobCandidateDetailsJobCandidate}
        jobCandidateIsFetched={jobCandidateIsFetched}
        jobCandidateErrorMessage={jobCandidateErrorMessage}
      >
        <Grid
          gap={2}
          height="100%"
          templateColumns={{
            base: isOpenRightSidebar
              ? '1fr'
              : isOpenActivityPane
              ? '1fr'
              : '290px 1fr',
            xl: isOpenRightSidebar
              ? '1fr'
              : isOpenActivityPane
              ? '1fr 400px'
              : '300px 1fr'
          }}
        >
          {jobErrorMessage ? (
            <AlertMessage
              message={jobCandidateErrorMessage || jobErrorMessage}
            />
          ) : null}

          <LoadingSkeleton loaded={jobIsFetched}>
            {job && currentUser ? (
              <>
                <TalentPoolCandidatesListContainer
                  job={job as FetchJobResponse}
                  currentUser={currentUser}
                />

                <LoadingSkeleton loaded={jobCandidateIsFetched && jobIsFetched}>
                  <GridItem overflow="hidden">
                    <AlertMessage message={jobCandidateErrorMessage} />

                    <ViewJobCandidateDetailsCandidateDetails
                      jobCandidate={jobCandidate as FetchJobCandidateResponse}
                      isBioOutsideViewport={bioContainerRef}
                      requiresCredit={requiresCredit}
                      isBasePath={isBasePath}
                      job={job}
                      prospect={true}
                      isHiringPortalPath={false}
                    >
                      {children}
                    </ViewJobCandidateDetailsCandidateDetails>
                  </GridItem>

                  {isOpenActivityPane && (
                    <GridItem
                      height="100%"
                      overflow="hidden"
                      hidden={!isOpenActivityPane}
                    >
                      <SourcingTalentProfileActivities
                        isOpen={isOpenActivityPane}
                        onClose={onCloseActivityPane}
                        jobCandidate={jobCandidate as FetchJobCandidateResponse}
                      />
                    </GridItem>
                  )}
                </LoadingSkeleton>
              </>
            ) : null}
          </LoadingSkeleton>
        </Grid>
      </SourcingProspectLayout>
    </ShareCandidatesProfileProvider>
  );
}

export default SourcingTalentProfilePage;
