import React, { ReactNode } from 'react';
import { ButtonGroup } from '@chakra-ui/button';
import { Flex, Stack } from '@chakra-ui/layout';
import { SystemProps } from '@chakra-ui/system';
import { Collapse } from '@chakra-ui/transition';

import { IsDisabled, IsLoading } from '../../types';

import { PureButtonHelper } from '../buttons/PureButtonHelper';
import { Heading } from '../Heading';
import { Box } from '@chakra-ui/react';

interface CollapseFormBoxProps {
  title?: string | null;
  titleLevel?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  stackSpacing?: SystemProps['margin'];
  children: ReactNode;
  isOpen: boolean;
  saveText?: string;
  cancelText?: string;
  saveIsDisabled?: IsDisabled;
  saveIsLoading?: IsLoading;
  onCancel?: () => void;
  onSave?: () => void;
  isHorizontal?: boolean;
}

function CollapseFormBox({
  title,
  children,
  isOpen,
  saveText,
  cancelText,
  saveIsDisabled,
  saveIsLoading,
  onCancel,
  onSave,
  isHorizontal,
  stackSpacing = isHorizontal ? 2 : 6,
  titleLevel = isHorizontal ? 'h5' : 'h4'
}: CollapseFormBoxProps) {
  return (
    <Flex
      as={Collapse}
      startingHeight={0}
      in={isOpen}
      unmountOnExit={true}
      animateOpacity
      w="100%"
    >
      <Stack
        p={!isHorizontal ? 4 : 0}
        spacing={stackSpacing}
        flexDirection="column"
        borderRadius={4}
        bg={!isHorizontal ? 'gray.50' : 'transparent'}
        border={!isHorizontal ? '1px solid' : 'none'}
        borderColor="gray.200"
      >
        {title ? <Heading level={titleLevel}>{title}</Heading> : null}

        <Box
          borderRadius={4}
          p={isHorizontal ? 4 : 0}
          bg={isHorizontal ? 'gray.50' : 'transparent'}
        >
          {children}
        </Box>

        {onCancel || onSave ? (
          <ButtonGroup justifyContent="flex-end">
            {onCancel ? (
              <PureButtonHelper
                size="small"
                hierarchy="tertiary"
                onClick={onCancel}
                i18nText={cancelText || 'Cancel'}
              />
            ) : null}
            {onSave ? (
              <PureButtonHelper
                size="small"
                disabled={saveIsDisabled}
                isLoading={saveIsLoading}
                i18nText={saveText || 'Save'}
                onClick={onSave}
              />
            ) : null}
          </ButtonGroup>
        ) : null}
      </Stack>
    </Flex>
  );
}

export default CollapseFormBox;
