import {
  FetchHtmlPagesCacheKey,
  FetchHtmlPageCacheKey
} from './htmlPagesTypes';

export class HtmlPagesCache {
  static indexCacheKey() {
    return 'htmlPages' as FetchHtmlPagesCacheKey;
  }

  static showCacheKey() {
    return 'htmlPage' as FetchHtmlPageCacheKey;
  }
}
