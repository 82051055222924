import fromPairs from 'lodash/fromPairs';

export enum BaseSalaryRadioInputOptionsEnums {
  RANGE = 'range',
  UPTO = 'upto',
  EXACT = 'exact'
}

export const BaseSalaryRadioInputOptions = [
  {
    label: 'Salary range',
    value: BaseSalaryRadioInputOptionsEnums.RANGE
  },
  { label: 'Up to', value: BaseSalaryRadioInputOptionsEnums.UPTO },
  { label: 'Exact pay', value: BaseSalaryRadioInputOptionsEnums.EXACT }
];

export const BaseSalaryRadioInputOptionsHash = fromPairs(
  BaseSalaryRadioInputOptions.map(({ value, label }) => [value, label])
);

export enum BaseSalaryFrequencyEnums {
  ANNUALLY = 'annually',
  MONTHLY = 'monthly',
  WEEKLY = 'weekly',
  HOURLY = 'hourly'
}

export const BaseSalaryFrequencyOptions = [
  { label: 'Anually', value: BaseSalaryFrequencyEnums.ANNUALLY },
  { label: 'Monthly', value: BaseSalaryFrequencyEnums.MONTHLY },
  { label: 'Weekly', value: BaseSalaryFrequencyEnums.WEEKLY },
  { label: 'Hourly', value: BaseSalaryFrequencyEnums.HOURLY }
];

export const BaseSalaryFrequencyOptionsHash = fromPairs(
  BaseSalaryFrequencyOptions.map(({ value, label }) => [value, label])
);
