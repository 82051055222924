import { SystemStyleObject } from '@chakra-ui/theme-tools';

const baseStyle: SystemStyleObject = {
  w: '100%',
  mx: 'auto',
  maxW: '70rem',
  px: '1rem'
};

export const Container = {
  baseStyle
};
