import { usePaginatedItems } from '../../../common/hooks/usePaginatedItems';

import {
  FetchEmploymentSkillBadgesQuery,
  FetchEmploymentSkillBadgesCacheKey,
  FetchEmploymentSkillBadgesTotalCount,
  FetchEmploymentSkillBadgesPage,
  FetchEmploymentSkillBadgesPageSize,
  FetchEmploymentSkillBadgesError,
  FetchEmploymentSkillBadgesErrorMessage,
  FetchEmploymentSkillBadgesIsLoading,
  FetchEmploymentSkillBadgesIsFetched,
  FetchEmploymentSkillBadgesIsPlaceholderData,
  FetchEmploymentSkillBadgesEnabled,
  FetchEmploymentSkillBadgesPrefetchNextPage,
  FetchEmploymentSkillBadgesCountType,
  FetchEmploymentSkillBadgesFilters,
  FetchEmploymentSkillBadgesSort,
  FetchEmploymentSkillBadgeID,
  FetchEmploymentSkillBadgeQuery,
  FetchEmploymentSkillBadgeCacheKey
} from '../../employmentSkillBadgesTypes';

import { EmploymentSkillBadgesBffRequests } from '../../EmploymentSkillBadgesBffRequests';

interface PaginatedEmploymentSkillBadgesBaseOptions<
  PaginatedEmploymentSkillBadgesResponseType
> {
  cacheKey: FetchEmploymentSkillBadgesCacheKey;
  query: FetchEmploymentSkillBadgesQuery;
  countType?: FetchEmploymentSkillBadgesCountType;
  initialPage?: FetchEmploymentSkillBadgesPage;
  initialPageSize?: FetchEmploymentSkillBadgesPageSize;
  initialFilters?: FetchEmploymentSkillBadgesFilters;
  initialSort?: FetchEmploymentSkillBadgesSort;
  enabled?: FetchEmploymentSkillBadgesEnabled;
  prefetchNextPage?: FetchEmploymentSkillBadgesPrefetchNextPage;
  placeholderEmploymentSkillBadges?: PaginatedEmploymentSkillBadgesResponseType[];
  placeholderEmploymentSkillBadgesCount?: FetchEmploymentSkillBadgesTotalCount;
  placeholderData?: () => PaginatedEmploymentSkillBadgesResponse<PaginatedEmploymentSkillBadgesResponseType>;
}

interface PaginatedEmploymentSkillBadgesOptionsWithoutItem {
  fetchItemCacheKey?: never;
  itemQuery?: never;
}

interface PaginatedEmploymentSkillBadgesOptionsWithItem {
  fetchItemCacheKey: FetchEmploymentSkillBadgeCacheKey;
  itemQuery: FetchEmploymentSkillBadgeQuery;
}

type PaginatedEmploymentSkillBadgesOptions<
  PaginatedEmploymentSkillBadgesResponseType
> = PaginatedEmploymentSkillBadgesBaseOptions<PaginatedEmploymentSkillBadgesResponseType> &
  (
    | PaginatedEmploymentSkillBadgesOptionsWithoutItem
    | PaginatedEmploymentSkillBadgesOptionsWithItem
  );

interface PaginatedEmploymentSkillBadgesResponse<
  PaginatedEmploymentSkillBadgesResponseType
> {
  data: PaginatedEmploymentSkillBadgesResponseType[];
  totalCount: FetchEmploymentSkillBadgesTotalCount;
}

function usePaginatedEmploymentSkillBadges<
  PaginatedEmploymentSkillBadgesResponseType
>({
  cacheKey,
  query,
  countType,
  initialPage,
  initialPageSize,
  initialFilters,
  initialSort,
  enabled,
  prefetchNextPage,
  placeholderEmploymentSkillBadges,
  placeholderEmploymentSkillBadgesCount,
  placeholderData,
  fetchItemCacheKey,
  itemQuery
}: PaginatedEmploymentSkillBadgesOptions<PaginatedEmploymentSkillBadgesResponseType>) {
  const {
    items,
    itemsTotalCount,
    itemsError,
    itemsErrorMessage,
    itemsIsFetched,
    itemsIsLoading,
    itemsIsPlaceholderData,
    itemsCurrentPage,
    itemsCurrentPageSize,
    itemsCurrentFilters,
    itemsCurrentSort,
    fetchItems,
    paginateItems,
    changeItemsPageSize,
    filterItems,
    changeItemsFilters,
    clearItemsFilters,
    sortItems,
    prefetchItems,
    prefetchItem
  } = usePaginatedItems<PaginatedEmploymentSkillBadgesResponseType>({
    cacheKey,
    initialPage,
    initialPageSize,
    initialFilters,
    initialSort,
    enabled,
    prefetchNextPage,
    placeholderItems: placeholderEmploymentSkillBadges,
    placeholderItemsCount: placeholderEmploymentSkillBadgesCount,
    placeholderData,
    queryFn: (params) =>
      EmploymentSkillBadgesBffRequests.fetchEmploymentSkillBadges<PaginatedEmploymentSkillBadgesResponseType>(
        {
          query,
          countType,
          page: params.page as FetchEmploymentSkillBadgesPage,
          pageSize: params.pageSize as FetchEmploymentSkillBadgesPageSize,
          filters: params.filters as FetchEmploymentSkillBadgesFilters,
          sort: params.sort as FetchEmploymentSkillBadgesSort
        }
      ),
    // EmploymentSkillBadgesRequests.fetchEmploymentSkillBadges<PaginatedEmploymentSkillBadgesResponseType>({
    //   query,
    //   countType,
    //   page: params.page as FetchEmploymentSkillBadgesPage,
    //   pageSize: params.pageSize as FetchEmploymentSkillBadgesPageSize,
    //   filters: params.filters as FetchEmploymentSkillBadgesFilters,
    //   sort: params.sort as FetchEmploymentSkillBadgesSort
    // }),
    ...(itemQuery
      ? {
          fetchItemCacheKey,
          queryItemFn: (itemId) =>
            EmploymentSkillBadgesBffRequests.fetchEmploymentSkillBadge(
              itemId as FetchEmploymentSkillBadgeID,
              {
                query: itemQuery
              }
            )
          // EmploymentSkillBadgesRequests.fetchEmploymentSkillBadge(itemId as FetchEmploymentSkillBadgeID, {
          //   query: itemQuery
          // })
        }
      : {})
  });

  return {
    employmentSkillBadges:
      items as PaginatedEmploymentSkillBadgesResponseType[],
    employmentSkillBadgesTotalCount:
      itemsTotalCount as FetchEmploymentSkillBadgesTotalCount,
    employmentSkillBadgesError: itemsError as FetchEmploymentSkillBadgesError,
    employmentSkillBadgesErrorMessage:
      itemsErrorMessage as FetchEmploymentSkillBadgesErrorMessage,
    employmentSkillBadgesIsLoading:
      itemsIsLoading as FetchEmploymentSkillBadgesIsLoading,
    employmentSkillBadgesIsFetched:
      itemsIsFetched as FetchEmploymentSkillBadgesIsFetched,
    employmentSkillBadgesIsPlaceholderData:
      itemsIsPlaceholderData as FetchEmploymentSkillBadgesIsPlaceholderData,
    employmentSkillBadgesCurrentPage:
      itemsCurrentPage as FetchEmploymentSkillBadgesPage,
    employmentSkillBadgesCurrentPageSize:
      itemsCurrentPageSize as FetchEmploymentSkillBadgesPageSize,
    employmentSkillBadgesCurrentFilters:
      itemsCurrentFilters as FetchEmploymentSkillBadgesFilters,
    employmentSkillBadgesCurrentSort:
      itemsCurrentSort as FetchEmploymentSkillBadgesSort,
    fetchEmploymentSkillBadges: fetchItems,
    paginateEmploymentSkillBadges: paginateItems,
    changeEmploymentSkillBadgesPageSize: changeItemsPageSize,
    filterEmploymentSkillBadges: filterItems,
    changeEmploymentSkillBadgesFilters: changeItemsFilters,
    clearEmploymentSkillBadgesFilters: clearItemsFilters,
    sortEmploymentSkillBadges: sortItems,
    prefetchEmploymentSkillBadges: prefetchItems,
    prefetchEmploymentSkillBadge: prefetchItem
  };
}

export default usePaginatedEmploymentSkillBadges;
