import { FetchDepartmentsCacheKey } from '../../departmentsTypes';

import { useCreateItem } from '../../../common/hooks/useCreateItem';

import {
  DepartmentsBffRequests,
  CreateDepartmentRequestData,
  CreateDepartmentErrorResponse
} from '../../DepartmentsBffRequests';

interface CreateDepartmentOptions {
  cacheKeys?: FetchDepartmentsCacheKey[];
}

function useCreateDepartment({ cacheKeys = [] }: CreateDepartmentOptions) {
  const {
    createItemData,
    createItemError,
    createItemErrorMessage,
    createItemIsLoading,
    createItem,
    createItemReset
  } = useCreateItem<CreateDepartmentRequestData, CreateDepartmentErrorResponse>(
    {
      mutationFn: (queryInput) =>
        DepartmentsBffRequests.createDepartment(queryInput),
      cacheKeys
    }
  );

  return {
    createDepartmentData: createItemData,
    createDepartmentError: createItemError,
    createDepartmentErrorMessage: createItemErrorMessage,
    createDepartmentIsLoading: createItemIsLoading,
    createDepartment: createItem,
    createDepartmentReset: createItemReset
  };
}

export default useCreateDepartment;
