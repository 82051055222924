import { Button as ChakraButton } from '@chakra-ui/button';

import { mapSize } from '../../utils/mapSize';

import { ButtonProps } from './Button.types';

function Button({
  size = 'large',
  children,
  hierarchy,
  rightIcon,
  leftIcon,
  ...props
}: ButtonProps) {
  return (
    <ChakraButton
      {...props}
      variant={hierarchy}
      size={mapSize(size)}
      rightIcon={rightIcon}
      leftIcon={leftIcon}
    >
      {children}
    </ChakraButton>
  );
}

export default Button;
