import { ReactNode } from 'react';
import {
  Text as ChakraText,
  TextProps as ChakraTextProps
} from '@chakra-ui/layout';
import omit from 'lodash/omit';

import { TextStyle } from '../../theme';

export interface TextProps extends ChakraTextProps {
  children: ReactNode;
  textStyle?: TextStyle;
  isTruncated?: boolean;
  alignment?: 'left' | 'right' | 'center';
}

function Text({
  children,
  alignment,
  textStyle = 'regular',
  ...props
}: TextProps) {
  return (
    <ChakraText
      textAlign={alignment}
      textStyle={textStyle}
      {...omit(props, 'isTruncated')}
    >
      {children}
    </ChakraText>
  );
}

export default Text;
