import {
  FetchEmploymentSkillBadgesCacheKey,
  FetchEmploymentSkillBadgeCacheKey,
  EmploymentSkillBadgeSkillBadgeId
} from './employmentSkillBadgesTypes';

export class EmploymentSkillBadgesCache {
  static indexCacheKey() {
    return 'employmentSkillBadges' as FetchEmploymentSkillBadgesCacheKey;
  }

  static showCacheKey() {
    return 'employmentSkillBadge' as FetchEmploymentSkillBadgeCacheKey;
  }

  static skillBadgeIndexCacheKey(
    employmentSkillBadgeSkillBadgeId: EmploymentSkillBadgeSkillBadgeId
  ) {
    return `employmentSkillBadge-skill-badge-form-${employmentSkillBadgeSkillBadgeId}` as FetchEmploymentSkillBadgesCacheKey;
  }
}
