import { Icon, IconProps } from '@chakra-ui/icon';

const SettingsIcon = ({
  w = '24px',
  h = '24px',
  fill = 'gray.500',
  ...props
}: IconProps) => (
  <Icon viewBox="0 0 24 24" w={w} h={h} fill={fill} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 14.456c-1.391 0-2.455-1.064-2.455-2.455 0-1.39 1.064-2.454 2.454-2.454 1.391 0 2.455 1.063 2.455 2.454 0 1.391-1.064 2.455-2.455 2.455Zm7.364-4.092h-.31c-.446 0-.832-.3-1-.713a7.657 7.657 0 0 0-.14-.327c-.189-.41-.13-.9.19-1.219l.196-.196c.654-.654.654-1.636 0-2.29-.655-.655-1.636-.655-2.29 0l-.158.156c-.337.337-.862.38-1.297.185a5.47 5.47 0 0 0-.256-.107c-.428-.166-.744-.557-.744-1.016v-.2c0-.9-.737-1.637-1.637-1.637s-1.636.736-1.636 1.636v.343c0 .43-.278.805-.684.95a8.306 8.306 0 0 0-.219.08c-.44.172-.958.093-1.273-.26l-.115-.13c-.655-.655-1.636-.655-2.291 0-.655.654-.655 1.636 0 2.29l.137.137c.346.346.38.888.188 1.338-.036.087-.07.173-.101.26-.152.42-.532.72-.978.72h-.31C3.736 10.364 3 11.1 3 12c0 .9.736 1.636 1.636 1.636h.31c.446 0 .833.3 1 .713.044.109.09.218.14.327.189.41.13.9-.19 1.219l-.196.196c-.655.654-.655 1.636 0 2.29.655.655 1.636.655 2.29 0l.158-.156c.337-.337.862-.38 1.297-.185.084.037.17.073.256.107.428.166.744.557.744 1.016v.2c0 .9.737 1.637 1.637 1.637s1.636-.736 1.636-1.636v-.31c0-.446.299-.832.713-1 .109-.044.218-.09.327-.14.41-.189.9-.13 1.218.19l.197.196c.654.654 1.636.654 2.29 0 .655-.655.655-1.636 0-2.29l-.156-.158c-.337-.337-.38-.862-.185-1.297a5.47 5.47 0 0 0 .107-.256c.166-.428.556-.744 1.015-.744h.12c.9 0 1.636-.737 1.636-1.637 0-.818-.736-1.554-1.636-1.554Z"
    />
  </Icon>
);

export default SettingsIcon;
