import {
  FetchSkillsCacheKey,
  FetchSkillsIndexCacheKey
} from '../../skillsTypes';

import { useCreateItem } from '../../../common/hooks/useCreateItem';

import {
  SkillsBffRequests,
  CreateSkillRequestData,
  CreateSkillErrorResponse
} from '../../SkillsBffRequests';

interface CreateSkillOptions {
  cacheKeys?: (FetchSkillsCacheKey | FetchSkillsIndexCacheKey)[];
}

function useCreateSkill({ cacheKeys = [] }: CreateSkillOptions) {
  const {
    createItemData,
    createItemError,
    createItemErrorMessage,
    createItemIsLoading,
    createItem,
    createItemReset
  } = useCreateItem<CreateSkillRequestData, CreateSkillErrorResponse>({
    mutationFn: (queryInput) => SkillsBffRequests.createSkill(queryInput),
    cacheKeys
  });

  return {
    createSkillData: createItemData,
    createSkillError: createItemError,
    createSkillErrorMessage: createItemErrorMessage,
    createSkillIsLoading: createItemIsLoading,
    createSkill: createItem,
    createSkillReset: createItemReset
  };
}

export default useCreateSkill;
