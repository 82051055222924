import {
  useDisclosure,
  Stack,
  ButtonGroup,
  Box,
  Flex,
  Switch
} from '@chakra-ui/react';
import React, { useCallback, useRef } from 'react';
import map from 'lodash/map';
import filter from 'lodash/filter';
import size from 'lodash/size';

import { TextareaField } from '../../../../../../helpers/forms/formFields/TextareaField';
import {
  ShareCandidateProfileRecipients,
  ShareCandidateProfileRecipientsJobCollaborators
} from '../ShareCandidateProfileRecipients';
import { Text } from '../../../../../../helpers/Text';
import { Button } from '../../../../../../helpers/Button';
import { useCurrentUser } from '../../../../../../auth/hooks/useAuth';
import { useFetchUser } from '../../../../../users/hooks/useFetchUser';
import {
  FetchUserResponse,
  fetchUserQuery
} from '../../../../../users/queries/fetchUser.query';
import { UsersCache } from '../../../../../users/UsersCache';
import {
  ShareCandidateProfileCandidateShare,
  ShareCandidateProfileJobCandidates,
  ShareCandidateProfileRecruiterEnums
} from '../../ShareCandidateProfile.types';
import { useAddCandidateShareForm } from '../../../forms/AddCandidateShareForm/hooks/useAddCandidateShareForm';
import {
  CandidateShareAutoJoin,
  CandidateShareAutoJoinEnums,
  CandidateShareCandidateShareInvitations,
  CandidateShareCompanyMemberInvitation,
  CandidateShareCompanyMemberInvitations,
  CandidateShareFields,
  CandidateShareId,
  CandidateShareJobCandidateIds,
  CandidateShareJobId,
  CandidateShareMessage,
  CandidateShareRecipient,
  CandidateShareRecipientIds,
  CandidateShareRecipients,
  CandidateShareShareSettings,
  FetchCandidateSharesCacheKey
} from '../../../../candidateSharesTypes';
import {
  JobId,
  JobNanoId,
  MayBeJobCompany
} from '../../../../../jobs/jobsTypes';
import { PureSubmitButtonHelper } from '../../../../../../helpers/buttons/PureSubmitButtonHelper';
import { AlertMessage } from '../../../../../../helpers/AlertMessage';
import { Form } from '../../../../../../helpers/Form';
import { ShareCandidateProfileInviteUserCollapseContainer } from '../ShareCandidateProfileInviteUserCollapseContainer';
import { useCreateCandidateShare } from '../../../../hooks/useCreateCandidateShare';
import { CandidateSharesCache } from '../../../../CandidateSharesCache';
import { JobCandidatesCache } from '../../../../../jobCandidates/JobCandidatesCache';
import { WorkflowStagesCache } from '../../../../../workflowStages/WorkflowStagesCache';
import { useUpdateCandidateShare } from '../../../../hooks/useUpdateCandidateShare';
import { ShareCandidateProfileInviteUserCollapseContainerRefProps } from '../ShareCandidateProfileInviteUserCollapseContainer/ShareCandidateProfileInviteUserCollapseContainer';

interface ShareCandidateProfileScreenTwoJobProps {
  id: JobId;
  nanoId: JobNanoId;
  company: MayBeJobCompany;
}

type ShareCandidateProfileScreenTwoProps = {
  backToScreenOne: () => void;
  shareCandidates: (
    candidateShare: ShareCandidateProfileCandidateShare
  ) => void;
  shareSettings: CandidateShareShareSettings;
  job: ShareCandidateProfileScreenTwoJobProps;
  jobCollaborators: ShareCandidateProfileRecipientsJobCollaborators;
  defaultJobCollaborators: ShareCandidateProfileRecipientsJobCollaborators;
  jobCollaboratorsStringified: string[];
  jobCandidates: ShareCandidateProfileJobCandidates;
  allCandidatesAreLeads: boolean;
  candidateShare: ShareCandidateProfileCandidateShare | null;
  candidateShareRecipientIds: string[];
  canddateShareCompanyMemberInvitations: string[];
  jobCandidateRecipients: CandidateShareRecipient[];
  jobCandidateCompanyMemberInvitations: CandidateShareCompanyMemberInvitation[];
};

const ShareCandidateProfileScreenTwo = ({
  backToScreenOne,
  shareCandidates,
  job,
  jobCollaborators,
  defaultJobCollaborators,
  jobCollaboratorsStringified,
  shareSettings,
  jobCandidates,
  allCandidatesAreLeads,
  candidateShare,
  canddateShareCompanyMemberInvitations,
  candidateShareRecipientIds,
  jobCandidateCompanyMemberInvitations,
  jobCandidateRecipients
}: ShareCandidateProfileScreenTwoProps) => {
  const currentUser = useCurrentUser();

  const inviteUserFormRef =
    useRef<ShareCandidateProfileInviteUserCollapseContainerRefProps>(null);

  const { user } = useFetchUser<FetchUserResponse>({
    userId: currentUser.id,
    query: fetchUserQuery,
    cacheKey: UsersCache.showCacheKey()
  });

  const { createCandidateShare } = useCreateCandidateShare({
    cacheKeys: [
      CandidateSharesCache.indexCacheKey(),
      JobCandidatesCache.jobIndexCacheKey(
        job.nanoId
      ) as unknown as FetchCandidateSharesCacheKey,
      WorkflowStagesCache.jobIndexCacheKey(
        job.nanoId
      ) as unknown as FetchCandidateSharesCacheKey
    ]
  });

  const { updateCandidateShare } = useUpdateCandidateShare({
    candidateShareId: candidateShare?.id || ('0' as CandidateShareId),
    cacheKeys: [
      CandidateSharesCache.indexCacheKey(),
      JobCandidatesCache.jobIndexCacheKey(
        job.nanoId
      ) as unknown as FetchCandidateSharesCacheKey,
      WorkflowStagesCache.jobIndexCacheKey(
        job.nanoId
      ) as unknown as FetchCandidateSharesCacheKey
    ]
  });

  const jobCandidateIds = map(jobCandidates, (candidate) => candidate.id);

  const {
    watch,
    control,
    registerFields,
    validationErrors,
    handleAddCandidateShare,
    addCandidateShareFormErrorMessage,
    addCandidateShareFormIsLoading
  } = useAddCandidateShareForm({
    defaultValues: candidateShare
      ? {
          message: candidateShare.message,
          autoJoin:
            candidateShare.autoJoin === CandidateShareAutoJoinEnums.ENABLED
              ? true
              : false,
          shareSettings,
          candidateShareInvitations: candidateShare.candidateShareInvitations,
          recipientIds: [
            ...canddateShareCompanyMemberInvitations,
            ...candidateShareRecipientIds
          ]
        }
      : {
          autoJoin: false,
          message:
            `Please provide feedback on these profiles promptly.\n\nThanks` as CandidateShareMessage,
          recipientIds: jobCollaboratorsStringified,
          shareSettings,
          candidateShareInvitations:
            [] as unknown as CandidateShareCandidateShareInvitations
        },
    onAddCandidateShare: async (data) => {
      const selectedRecipients = map(
        data.recipientIds,
        (recipientId) =>
          JSON.parse(recipientId) as
            | CandidateShareRecipient
            | CandidateShareCompanyMemberInvitation
      );
      const recipients = filter(
        selectedRecipients,
        (selectedRecipient) => selectedRecipient?.id
      ) as CandidateShareRecipients;
      const companyMemberInvitations = filter(
        selectedRecipients,
        (selectedRecipient) => !selectedRecipient?.id
      ) as CandidateShareCompanyMemberInvitations;

      const payload = {
        ...data,
        companyMemberInvitations,
        recipientIds: map(
          recipients,
          (recipient) => recipient.id
        ) as CandidateShareRecipientIds,
        autoJoin: (data.autoJoin
          ? CandidateShareAutoJoinEnums.ENABLED
          : CandidateShareAutoJoinEnums.DISABLED) as CandidateShareAutoJoin,
        jobCandidateIds: jobCandidateIds as CandidateShareJobCandidateIds,
        jobId: job.id as CandidateShareJobId
      };

      if (candidateShare?.id) {
        const response = await updateCandidateShare(payload);

        if (response.id) {
          shareCandidates({
            ...response
          } as ShareCandidateProfileCandidateShare);
        }
      } else {
        const response = await createCandidateShare(payload);

        if (response.id) {
          shareCandidates({
            ...response
          } as ShareCandidateProfileCandidateShare);
        }
      }
    }
  });

  const currentCandidateShareInvitations = watch(
    CandidateShareFields.CANDIDATE_SHARE_INVITATIONS
  );

  const {
    onOpen: onOpenAddNewRecipientForm,
    isOpen: isOpenAddNewRecipientForm,
    onClose: onCloseAddNewRecipientForm
  } = useDisclosure({
    defaultIsOpen: size(candidateShare?.candidateShareInvitations) > 0
  });

  const onShowInviteUserForm = useCallback<(newUser?: string) => void>(
    (newUser) => {
      onOpenAddNewRecipientForm();
      inviteUserFormRef.current?.openNewInviteUserForm(newUser);
    },
    [onOpenAddNewRecipientForm]
  );

  return (
    <Form as="stack" spacing={6} onSubmit={handleAddCandidateShare}>
      <ShareCandidateProfileRecipients
        showAddForm={onShowInviteUserForm}
        control={control}
        jobCollaborators={jobCollaborators}
        defaultJobCollaborators={defaultJobCollaborators}
        errorMessage={validationErrors.recipientIdsValidationError}
        jobCandidateRecipients={jobCandidateRecipients}
        jobCandidateCompanyMemberInvitations={
          jobCandidateCompanyMemberInvitations
        }
        currentCandidateShareInvitations={currentCandidateShareInvitations}
        candidateShare={candidateShare}
      />

      <ShareCandidateProfileInviteUserCollapseContainer
        job={job}
        isOpen={isOpenAddNewRecipientForm}
        onClose={onCloseAddNewRecipientForm}
        control={control}
        currentCandidateShareInvitations={currentCandidateShareInvitations}
        errorMessages={
          validationErrors.candidateShareInvitationsValidationErrors
        }
        ref={inviteUserFormRef}
      />

      {user?.accountType?.name ===
      ShareCandidateProfileRecruiterEnums.RECRUITER_ADMIN ? (
        <Stack spacing={3}>
          <Flex justifyContent="space-between">
            <Box
              as="label"
              htmlFor={CandidateShareFields.AUTO_JOIN}
              lineHeight="20px"
              fontWeight="medium"
              cursor="pointer"
            >
              Enable auto join
            </Box>

            <Switch
              id={CandidateShareFields.AUTO_JOIN}
              name={CandidateShareFields.AUTO_JOIN}
              ref={registerFields.registerAutoJoin.ref}
              onChange={registerFields.registerAutoJoin.onChange}
            />
          </Flex>

          <Stack spacing={2}>
            <Text textStyle="body1Regular" color="gray.600">
              Allow anyone that signs up with @abcd.com email address to join
              the account automatically
            </Text>
          </Stack>
        </Stack>
      ) : null}

      <TextareaField
        label="Message"
        name={CandidateShareFields.MESSAGE}
        errorMessage={validationErrors.messageValidationError}
        ref={registerFields.registerMessage.ref}
        onChange={registerFields.registerMessage.onChange}
      />

      <AlertMessage message={addCandidateShareFormErrorMessage} />

      <ButtonGroup w="full" spacing={6}>
        <Button w="full" hierarchy="secondary" onClick={backToScreenOne}>
          {allCandidatesAreLeads ? 'Cancel' : 'Back'}
        </Button>

        <PureSubmitButtonHelper
          w="full"
          i18nText="Continue"
          isLoading={addCandidateShareFormIsLoading}
        />
      </ButtonGroup>
    </Form>
  );
};

export default ShareCandidateProfileScreenTwo;
