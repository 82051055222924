import {
  EmploymentItemBffID,
  EmploymentItemBffNanoID,
  FetchEmploymentsBffURL,
  FetchEmploymentBffURL,
  FetchEmploymentByNanoIdBffURL,
  CreateEmploymentBffURL,
  UpdateEmploymentBffURL,
  DeleteEmploymentBffURL
} from './employmentsTypes';

import { BaseBffRoutes } from '../BaseBffRoutes';

export class EmploymentsBffRoutes extends BaseBffRoutes {
  protected static route = 'employments';

  static fetchEmploymentsRoute() {
    return this.fetchItemsRoute<FetchEmploymentsBffURL>();
  }

  static fetchEmploymentsIndexRoute() {
    return this.fetchItemsIndexRoute<FetchEmploymentsBffURL>();
  }

  static fetchEmploymentRoute(employmentItemBffID: EmploymentItemBffID) {
    return this.fetchItemRoute<FetchEmploymentBffURL>(employmentItemBffID);
  }

  static fetchEmploymentIndexRoute(
    employmentItemBffNanoID: EmploymentItemBffNanoID
  ) {
    return this.fetchItemIndexRoute<FetchEmploymentBffURL>(
      employmentItemBffNanoID
    );
  }

  static fetchEmploymentByNanoIdRoute(
    employmentItemBffNanoID: EmploymentItemBffNanoID
  ) {
    return this.fetchItemByNanoIdRoute<FetchEmploymentByNanoIdBffURL>(
      employmentItemBffNanoID
    );
  }

  static createEmploymentRoute() {
    return this.createItemRoute<CreateEmploymentBffURL>();
  }

  static updateEmploymentRoute(
    employmentItemBffNanoID: EmploymentItemBffID | EmploymentItemBffNanoID
  ) {
    return this.updateItemRoute<UpdateEmploymentBffURL>(
      employmentItemBffNanoID
    );
  }

  static deleteEmploymentRoute(
    employmentItemBffNanoID: EmploymentItemBffNanoID
  ) {
    return this.deleteItemRoute<DeleteEmploymentBffURL>(
      employmentItemBffNanoID
    );
  }
}
