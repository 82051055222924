import { HStack, VStack, useDisclosure, Tooltip } from '@chakra-ui/react';
import compact from 'lodash/compact';
import truncate from 'lodash/truncate';
import size from 'lodash/size';
import { useCallback } from 'react';

import { Heading } from '../../../../../../helpers/Heading';
import { LinkedInAvatar } from '../../../../../../helpers/LinkedInAvatar';
import { Text } from '../../../../../../helpers/Text';
import { IconButton } from '../../../../../../helpers/buttons/IconButton';

import { CancelIcon } from '../../../../../../icons/CancelIcon';
import { JobsIcon } from '../../../../../../icons/JobsIcon';

import { JobCandidateLabelHelper } from '../../../../../jobCandidates/helpers/JobCandidateLabelHelper';

import { JobCandidateId } from '../../../../../jobCandidates/jobCandidatesTypes';
import { ShareCandidateProfileJobCandidate } from '../../ShareCandidateProfile.types';

import { ImagesUrl } from '../../../../../images/ImagesUrl';

interface ShareCandidateItemProps {
  jobCandidate: ShareCandidateProfileJobCandidate;
  onRemoveJobCandidate: (jobCandidateId: JobCandidateId) => void;
}

const ShareCandidateItem = ({
  jobCandidate,
  onRemoveJobCandidate
}: ShareCandidateItemProps) => {
  const {
    isOpen: isHovered,
    onOpen: onMouseHover,
    onClose: onMouseLeave
  } = useDisclosure();

  const fileUrl = ImagesUrl.file(jobCandidate.image) as string;

  const handleRemoveJobCandidate = useCallback(() => {
    onRemoveJobCandidate(jobCandidate.id);
  }, [onRemoveJobCandidate, jobCandidate.id]);

  const jobAndCompany = compact([
    jobCandidate.jobTitle,
    jobCandidate.company?.name
  ]).join(' @ ');

  return (
    <HStack
      p={2}
      pl={4}
      spacing={4}
      cursor="pointer"
      position="relative"
      bg={isHovered ? '#F4F6F7' : 'transparent'}
      onMouseOver={onMouseHover}
      onMouseLeave={onMouseLeave}
      transitionProperty="background"
      transitionDuration="slow"
    >
      <LinkedInAvatar
        src={fileUrl}
        linkedInUrl={jobCandidate.linkedinUrl}
        h="56px"
        w="56px"
      />

      <VStack spacing={1} alignItems="start">
        <HStack spacing={2}>
          <Heading level="h3" color="gray.900" flex={1} noOfLines={1}>
            {jobCandidate.name}
          </Heading>

          <JobCandidateLabelHelper
            searchTypeLabel={jobCandidate.searchTypeLabel}
          />
        </HStack>

        <HStack spacing={2}>
          <JobsIcon w="20px" h="20px" color="gray.500" />
          <Text textStyle="body1Regular" color="gray.900" fontWeight={400}>
            <Tooltip
              label={jobAndCompany}
              placement="top"
              offset={[0, 0]}
              isDisabled={size(jobAndCompany) <= 33}
              hasArrow
            >
              {truncate(jobAndCompany, {
                length: 36,
                omission: '...'
              })}
            </Tooltip>
          </Text>
        </HStack>
      </VStack>

      <IconButton
        size="small"
        hierarchy="link"
        colorScheme="gray"
        icon={<CancelIcon h={5} w={5} />}
        aria-label="Remove profile"
        position="absolute"
        opacity={isHovered ? 1 : 0}
        right={2}
        top={2}
        onClick={handleRemoveJobCandidate}
      />
    </HStack>
  );
};

export default ShareCandidateItem;
