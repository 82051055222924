import { FieldValues, Path } from 'react-hook-form';
import { I18nText } from '../../../../../types';

interface Options {
  id: number;
  name: string;
}

export interface SelectCompanyFormFieldProps<T extends FieldValues> {
  i18nPlaceholder?: I18nText;
  id?: string;
  name: Path<T>; // Correctly typing the name prop
  isDisabled?: boolean;
  options: Options[];
  placeholder?: string;
  backgroundColor?: string;
}

export const defaultValues: FieldValues = {
  name: '' as string
};
