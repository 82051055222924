import { Flex } from '@chakra-ui/layout';
import { useCallback, useEffect } from 'react';
import { useLeftSidebarContext } from '../../../../context';
import { Logo } from '../../../../helpers/Logo';

type colorScheme = 'light' | 'dark';

interface LeftSidebarProps {
  colorScheme?: colorScheme;
  children: React.ReactNode;
}

export const LeftSidebar = ({
  colorScheme = 'light',
  children
}: LeftSidebarProps) => {
  const { setSidebarColor, isDocked } = useLeftSidebarContext();

  const toggleSidebarColor = useCallback(
    (colorScheme: colorScheme) => {
      setSidebarColor(colorScheme);
    },
    [setSidebarColor]
  );

  useEffect(() => {
    toggleSidebarColor(colorScheme);
  }, [colorScheme, toggleSidebarColor]);

  return (
    <>
      <Flex
        flexDirection="column"
        h="100vh"
        w={isDocked ? 20 : 60}
        bg={colorScheme === 'dark' ? '#2C273D' : 'white'}
        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.15);"
        pos="fixed"
        top={0}
        px={1}
        py={6}
        transitionDuration="slow"
        transitionProperty="width"
        zIndex={2}
        overflow="hidden"
      >
        <Flex alignItems="center" justifyContent="center" mb={9} px={2}>
          <Logo h={12} w={32} colorScheme={colorScheme} isDocked={isDocked} />
        </Flex>

        <Flex flexDirection="column" flex={1} overflow="auto" px={0}>
          {children}
        </Flex>
      </Flex>
    </>
  );
};

export default LeftSidebar;
