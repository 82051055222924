import { ReactNode, useCallback, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import debounce from 'lodash/debounce';
import first from 'lodash/first';

import { ErrorMessage, IsDisabled, IsRequired } from '../../../../../types';

import {
  FetchCertificationsEnabled,
  FetchCertificationsPageSize,
  CertificationFields,
  CertificationId,
  CertificationName,
  CertificationNanoId,
  CertificationUserId
} from '../../../../certifications/certificationsTypes';

import {
  fetchCertificationsQuery,
  FetchCertificationsResponse
} from '../../../../certifications/queries/fetchCertifications.query';

import { usePaginatedCertifications } from '../../../../certifications/hooks/usePaginatedCertifications';
import { useCurrentUser } from '../../../../../auth/hooks/useAuth';
import { useCreateCertification } from '../../../../certifications/hooks/useCreateCertification';

import { CertificationsCache } from '../../../../certifications/CertificationsCache';

import {
  MultiSelectField,
  MultiSelectFieldReactHookFormControl,
  MultiSelectFieldReactHookFormFieldPath
} from '../../../../../helpers/forms/formFields/MultiSelectField';

import { generateNanoId } from '../../../../../utils/generateNanoId';

import { SelectOptionType } from '../../../../../helpers/forms/formFields/MultiSelectField/components/MultiSelectFieldControl';

interface SelectCertificationsFormFieldRequiredProps<T extends FieldValues> {
  isRequired?: IsRequired;
  isDisabled?: IsDisabled;
  control: MultiSelectFieldReactHookFormControl<T>;
  item?: {
    certifications: {
      id: CertificationId;
      name: CertificationName;
    }[];
  } | null;
  errorMessage: ErrorMessage;
  labelAddon?: ReactNode;
  label?: string;
  withoutLabel?: boolean;
}

function SelectCertificationsFormField<T extends FieldValues>({
  isRequired,
  isDisabled,
  control,
  item,
  errorMessage,
  labelAddon,
  label,
  withoutLabel
}: SelectCertificationsFormFieldRequiredProps<T>) {
  const currentUser = useCurrentUser();

  const {
    createCertification,
    createCertificationIsLoading,
    createCertificationErrorMessage
  } = useCreateCertification({
    cacheKeys: [CertificationsCache.indexCacheKey()]
  });

  const {
    certifications,
    certificationsIsLoading,
    certificationsErrorMessage,
    changeCertificationsFilters
  } = usePaginatedCertifications<FetchCertificationsResponse>({
    query: fetchCertificationsQuery,
    cacheKey: CertificationsCache.indexCacheKey()
  });

  const {
    certificationsIsLoading: existingCertificationsIsLoading,
    certificationsErrorMessage: existingCertificationsErrorMessage,
    fetchCertifications
  } = usePaginatedCertifications<FetchCertificationsResponse>({
    query: fetchCertificationsQuery,
    cacheKey: CertificationsCache.existsCacheKey(),
    enabled: false as FetchCertificationsEnabled,
    initialPageSize: 10 as FetchCertificationsPageSize
  });

  const defaultCertification = item?.certifications
    ? item?.certifications.map((userCertification) => ({
        value: userCertification.id,
        label: userCertification.name
      }))
    : undefined;

  const [inputValue, setInputValue] = useState<string>('');

  const debouncedFilterCertifications = debounce<
    (updatedValue: string) => void
  >(
    (updatedValue) =>
      changeCertificationsFilters({
        [CertificationFields.NAME]: {
          operator: 'ilike',
          value: `${updatedValue}%`
        }
      }),
    500
  );

  const handleInputChange = useCallback<(value: string) => void>(
    (value) => {
      setInputValue(value);

      if (!value || value === '') {
        return;
      }

      debouncedFilterCertifications(value);
    },
    [debouncedFilterCertifications]
  );

  const onAddNewCertification = useCallback(async () => {
    const { data: existingCertifications } = await fetchCertifications({
      nextFilters: {
        [CertificationFields.NAME]: { operator: 'eq', value: inputValue }
      }
    });

    const existingCertification = first(existingCertifications);

    if (existingCertification) {
      const existingCertificationValue = {
        label: existingCertification.name,
        value: existingCertification.id
      };

      setInputValue('');
      return existingCertificationValue as SelectOptionType;
    }

    const certification = await createCertification({
      name: inputValue as CertificationName,
      userId: currentUser.id as CertificationUserId,
      nanoId: generateNanoId<CertificationNanoId>(),
      companyId: currentUser.companyId
    });

    if (certification?.id && certification?.name) {
      const certificationValue = {
        label: certification.name,
        value: certification.id
      };

      setInputValue('');
      return certificationValue as SelectOptionType;
    }
  }, [
    inputValue,
    fetchCertifications,
    currentUser.id,
    currentUser.companyId,
    createCertification
  ]);

  return (
    <MultiSelectField
      isRequired={isRequired}
      isDisabled={isDisabled}
      control={control}
      label={withoutLabel ? undefined : label || 'Certifications /  Licenses'}
      labelAddon={labelAddon}
      placeholder="Select certifications"
      name={'certificationIds' as MultiSelectFieldReactHookFormFieldPath<T>}
      options={certifications.map((certification) => ({
        value: certification.id,
        label: certification.name
      }))}
      defaultValue={defaultCertification}
      errorMessage={
        errorMessage ||
        certificationsErrorMessage ||
        createCertificationErrorMessage ||
        existingCertificationsErrorMessage
      }
      isLoading={
        certificationsIsLoading ||
        createCertificationIsLoading ||
        existingCertificationsIsLoading
      }
      onInputChange={handleInputChange}
      withAddNewButton={!!inputValue}
      addNewButtonHierarchy="ghost"
      addNewButtonLabel={`${
        existingCertificationsIsLoading
          ? 'Checking:'
          : `Add${createCertificationIsLoading ? 'ing:' : ''}`
      } ${inputValue}`}
      addNewButtonToValueAction={onAddNewCertification}
    />
  );
}

export default SelectCertificationsFormField;
