import { usePaginatedItems } from '../../../common/hooks/usePaginatedItems';

import {
  FetchJobFunctionsQuery,
  FetchJobFunctionsCacheKey,
  FetchJobFunctionsTotalCount,
  FetchJobFunctionsPage,
  FetchJobFunctionsPageSize,
  FetchJobFunctionsError,
  FetchJobFunctionsErrorMessage,
  FetchJobFunctionsIsLoading,
  FetchJobFunctionsIsFetched,
  FetchJobFunctionsIsPlaceholderData,
  FetchJobFunctionsEnabled,
  FetchJobFunctionsPrefetchNextPage,
  FetchJobFunctionsCountType,
  FetchJobFunctionsFilters,
  FetchJobFunctionsSort,
  FetchJobFunctionID,
  FetchJobFunctionQuery,
  FetchJobFunctionCacheKey
} from '../../jobFunctionsTypes';

import { JobFunctionsBffRequests } from '../../JobFunctionsBffRequests';

interface PaginatedJobFunctionsBaseOptions<PaginatedJobFunctionsResponseType> {
  cacheKey: FetchJobFunctionsCacheKey;
  query: FetchJobFunctionsQuery;
  countType?: FetchJobFunctionsCountType;
  initialPage?: FetchJobFunctionsPage;
  initialPageSize?: FetchJobFunctionsPageSize;
  initialFilters?: FetchJobFunctionsFilters;
  initialSort?: FetchJobFunctionsSort;
  enabled?: FetchJobFunctionsEnabled;
  prefetchNextPage?: FetchJobFunctionsPrefetchNextPage;
  placeholderJobFunctions?: PaginatedJobFunctionsResponseType[];
  placeholderJobFunctionsCount?: FetchJobFunctionsTotalCount;
  placeholderData?: () => PaginatedJobFunctionsResponse<PaginatedJobFunctionsResponseType>;
}

interface PaginatedJobFunctionsOptionsWithoutItem {
  fetchItemCacheKey?: never;
  itemQuery?: never;
}

interface PaginatedJobFunctionsOptionsWithItem {
  fetchItemCacheKey: FetchJobFunctionCacheKey;
  itemQuery: FetchJobFunctionQuery;
}

type PaginatedJobFunctionsOptions<PaginatedJobFunctionsResponseType> =
  PaginatedJobFunctionsBaseOptions<PaginatedJobFunctionsResponseType> &
    (
      | PaginatedJobFunctionsOptionsWithoutItem
      | PaginatedJobFunctionsOptionsWithItem
    );

interface PaginatedJobFunctionsResponse<PaginatedJobFunctionsResponseType> {
  data: PaginatedJobFunctionsResponseType[];
  totalCount: FetchJobFunctionsTotalCount;
}

function usePaginatedJobFunctions<PaginatedJobFunctionsResponseType>({
  cacheKey,
  query,
  countType,
  initialPage,
  initialPageSize,
  initialFilters,
  initialSort,
  enabled,
  prefetchNextPage,
  placeholderJobFunctions,
  placeholderJobFunctionsCount,
  placeholderData,
  fetchItemCacheKey,
  itemQuery
}: PaginatedJobFunctionsOptions<PaginatedJobFunctionsResponseType>) {
  const {
    items,
    itemsTotalCount,
    itemsError,
    itemsErrorMessage,
    itemsIsFetched,
    itemsIsLoading,
    itemsIsPlaceholderData,
    itemsCurrentPage,
    itemsCurrentPageSize,
    itemsCurrentFilters,
    itemsCurrentSort,
    fetchItems,
    paginateItems,
    changeItemsPageSize,
    filterItems,
    changeItemsFilters,
    clearItemsFilters,
    sortItems,
    prefetchItems,
    prefetchItem
  } = usePaginatedItems<PaginatedJobFunctionsResponseType>({
    cacheKey,
    initialPage,
    initialPageSize,
    initialFilters,
    initialSort,
    enabled,
    prefetchNextPage,
    placeholderItems: placeholderJobFunctions,
    placeholderItemsCount: placeholderJobFunctionsCount,
    placeholderData,
    queryFn: (params) =>
      JobFunctionsBffRequests.fetchJobFunctions<PaginatedJobFunctionsResponseType>(
        {
          query,
          countType,
          page: params.page as FetchJobFunctionsPage,
          pageSize: params.pageSize as FetchJobFunctionsPageSize,
          filters: params.filters as FetchJobFunctionsFilters,
          sort: params.sort as FetchJobFunctionsSort
        }
      ),
    ...(itemQuery
      ? {
          fetchItemCacheKey,
          queryItemFn: (itemId) =>
            JobFunctionsBffRequests.fetchJobFunction(
              itemId as FetchJobFunctionID,
              {
                query: itemQuery
              }
            )
        }
      : {})
  });

  return {
    jobFunctions: items as PaginatedJobFunctionsResponseType[],
    jobFunctionsTotalCount: itemsTotalCount as FetchJobFunctionsTotalCount,
    jobFunctionsError: itemsError as FetchJobFunctionsError,
    jobFunctionsErrorMessage:
      itemsErrorMessage as FetchJobFunctionsErrorMessage,
    jobFunctionsIsLoading: itemsIsLoading as FetchJobFunctionsIsLoading,
    jobFunctionsIsFetched: itemsIsFetched as FetchJobFunctionsIsFetched,
    jobFunctionsIsPlaceholderData:
      itemsIsPlaceholderData as FetchJobFunctionsIsPlaceholderData,
    jobFunctionsCurrentPage: itemsCurrentPage as FetchJobFunctionsPage,
    jobFunctionsCurrentPageSize:
      itemsCurrentPageSize as FetchJobFunctionsPageSize,
    jobFunctionsCurrentFilters: itemsCurrentFilters as FetchJobFunctionsFilters,
    jobFunctionsCurrentSort: itemsCurrentSort as FetchJobFunctionsSort,
    fetchJobFunctions: fetchItems,
    paginateJobFunctions: paginateItems,
    changeJobFunctionsPageSize: changeItemsPageSize,
    filterJobFunctions: filterItems,
    changeJobFunctionsFilters: changeItemsFilters,
    clearJobFunctionsFilters: clearItemsFilters,
    sortJobFunctions: sortItems,
    prefetchJobFunctions: prefetchItems,
    prefetchJobFunction: prefetchItem
  };
}

export default usePaginatedJobFunctions;
