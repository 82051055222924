import { ControlPanelRoutes } from '../../ControlPanelRoutes';

const baseJobsPath = ControlPanelRoutes.jobs();

export class ControlPanelJobsRoutes {
  static index() {
    return baseJobsPath;
  }

  static inactive() {
    return `${baseJobsPath}/inactive`;
  }

  static archived() {
    return `${baseJobsPath}/archived`;
  }

  static post() {
    return `${baseJobsPath}/post`;
  }
}
