import { Collapse, Stack, StackDivider } from '@chakra-ui/react';
import { ViewJobCandidateModalPhoneSidebarCallActionButton } from '../../../../components';

const ViewJobCandidateModalPhoneSidebarQuestionsHelper = () => {
  return (
    <>
      <Collapse in={true} unmountOnExit>
        <Stack
          flex={1}
          spacing={0}
          overflow="hidden"
          borderRadius="4px"
          border="1px solid"
          borderColor="gray.300"
          divider={<StackDivider />}
          boxShadow="0px 2px 2px rgba(23, 25, 26, 0.06)"
        >
          <Stack direction="row" spacing={0} divider={<StackDivider />}>
            <ViewJobCandidateModalPhoneSidebarCallActionButton
              value="request-questionnaire"
              label="Request questionnaire"
            />

            <ViewJobCandidateModalPhoneSidebarCallActionButton
              value="review-questions"
              label="Review questions"
            />
          </Stack>
        </Stack>
      </Collapse>
    </>
  );
};

export default ViewJobCandidateModalPhoneSidebarQuestionsHelper;
