import { useMutation, useQueryClient } from 'react-query';

import {
  FetchUsersCacheKey,
  UserCaptureType,
  UserCaptureTypes,
  UserEmail,
  UserName,
  UserNanoId,
  UserSearchType,
  UserSearchTypes,
  UserUuid
} from '../../usersTypes';

import { CreateUserData, CreateUserErrorResponse } from '../../UsersRequests';

import {
  UsersBffRequests,
  InviteUserRequestData
} from '../../UsersBffRequests';

import { parseRequestError } from '../../../../utils/parseRequestError';
import { generateNanoId } from '../../../../utils/generateNanoId';

interface InviteUserOptions {
  cacheKeys?: FetchUsersCacheKey[];
}

interface InviteUserData extends Omit<CreateUserData, 'uuid'> {
  email: InviteUserRequestData['email'];
}

function useInviteUser({ cacheKeys = [] }: InviteUserOptions) {
  const queryClient = useQueryClient();

  const { data, error, isLoading, mutateAsync, reset } = useMutation<
    CreateUserData,
    CreateUserErrorResponse,
    InviteUserData
  >(
    async (queryInput) => {
      const invitedUser = await UsersBffRequests.inviteUser({
        email: queryInput.email
      });

      return await UsersBffRequests.createUser({
        uuid: invitedUser.id as UserUuid,
        name: queryInput.email as string as UserName,
        email: queryInput.email as UserEmail,
        searchType: UserSearchTypes.USER as UserSearchType,
        captureType: UserCaptureTypes.RECRUITER as UserCaptureType,
        nanoId: generateNanoId<UserNanoId>()
      });
    },
    {
      onSettled: async () =>
        cacheKeys?.map((cacheKey) => queryClient.invalidateQueries(cacheKey))
    }
  );

  return {
    inviteUserData: data,
    inviteUserError: error,
    inviteUserErrorMessage: parseRequestError(error),
    inviteUserIsLoading: isLoading,
    inviteUser: (inviteUserData: InviteUserData) => mutateAsync(inviteUserData),
    inviteUserReset: () => reset()
  };
}

export default useInviteUser;
