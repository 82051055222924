import { MapSizeVariant } from './mapSize.types';

function mapSize(variant: MapSizeVariant) {
  switch (variant) {
    case 'extra-small':
      return 'xs';

    case 'small':
      return 'sm';

    case 'medium':
      return 'md';

    case 'large':
      return 'lg';
  }
}

export default mapSize;
