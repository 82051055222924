import React from 'react';
import {
  Button,
  ButtonProps
} from '../../../../../../../../../../../../../helpers/Button';
import { ViewJobCandidateModalPhoneSidebarConnectedType } from '../../ViewJobCandidateModalPhoneSidebarConnectedHelper';

interface ViewJobCandidateModalPhoneSidebarConnectedPrimaryButtonProps
  extends ButtonProps {
  label: string;
  value: ViewJobCandidateModalPhoneSidebarConnectedType;
  selectedOption?: ViewJobCandidateModalPhoneSidebarConnectedType | null;
}

const ViewJobCandidateModalPhoneSidebarConnectedPrimaryButton = ({
  label,
  value,
  selectedOption,
  ...props
}: ViewJobCandidateModalPhoneSidebarConnectedPrimaryButtonProps) => {
  return (
    <Button
      size="medium"
      value={value}
      paddingLeft={2}
      paddingRight={2}
      hierarchy="tertiary"
      bg={selectedOption === value ? 'green.100' : 'white'}
      color={selectedOption === value ? 'green.500' : 'gray.900'}
      borderColor={selectedOption === value ? 'green.500' : 'gray.300'}
      _hover={{
        color: 'green.500',
        borderColor: 'green.500',
        bg: selectedOption === 'schedule' ? 'green.100' : 'white'
      }}
      {...props}
    >
      {label}
    </Button>
  );
};

export default ViewJobCandidateModalPhoneSidebarConnectedPrimaryButton;
