import { Icon, IconProps } from '@chakra-ui/icon';

const EmailIcon = ({
  w = '24px',
  h = '24px',
  fill = 'gray.500',
  ...props
}: IconProps) => (
  <Icon viewBox="0 0 24 24" w={w} h={h} fill={fill} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.996 5A.996.996 0 0 0 3 6v1.333c0 .311.15.545.375.7l8.25 4.667c.15.078.225.078.375.078s.225 0 .375-.078l8.25-4.667a.824.824 0 0 0 .375-.7V6c0-.552-.441-1-.994-1H3.996Z"
    />
    <path d="M12 14.334c.35 0 .7-.067.988-.266.042-.028.084-.057.129-.082l6.396-3.567a1 1 0 0 1 1.487.873v6.709a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-6.709a1 1 0 0 1 1.487-.873l6.396 3.567c.045.025.087.053.129.082.288.199.638.266.988.266Z" />
  </Icon>
);
export default EmailIcon;
