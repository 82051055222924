import find from 'lodash/find';
import first from 'lodash/first';
import { Fragment, useCallback } from 'react';

import {
  FetchIndustriesCacheKey,
  FetchIndustriesEnabled,
  FetchIndustriesFilters,
  FetchIndustriesPageSize,
  FetchIndustriesSort,
  IndustryFields,
  IndustryId,
  IndustryNanoId,
  IndustryUserId
} from '../../../industriesTypes';

import { FetchIndustryExpertisesResponse } from '../../../../industryExpertises/queries/fetchIndustryExpertises.query';
import {
  fetchIndustriesQuery,
  FetchIndustriesResponse
} from '../../../queries/fetchIndustries.query';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';
import { useCreateIndustry } from '../../../hooks/useCreateIndustry';
import { usePaginatedIndustries } from '../../../hooks/usePaginatedIndustries';

import { IndustriesCache } from '../../../IndustriesCache';

import {
  OnNewIndustryDropdownSelect,
  OnIndustryDropdownSelect,
  OnIndustryInputChange,
  IndustryDropdown
} from '../../../helpers/IndustryDropdown';

import { AlertMessage } from '../../../../../helpers/AlertMessage';

import { generateNanoId } from '../../../../../utils/generateNanoId';
import { IsLoading } from '../../../../../types';

interface AddIndustryDropdownWrapperProps {
  industryIds: IndustryId[];
  industryExpertises: FetchIndustryExpertisesResponse[];
  industriesCacheKey: FetchIndustriesCacheKey;
  onSelectExistingIndustry: (industry: FetchIndustriesResponse) => void;
  onSelectExistingIndustryExpertise: (
    industryExpertise: FetchIndustryExpertisesResponse
  ) => void;
  onSelectIndustry: OnIndustryDropdownSelect;
  selectIndustryIsLoading?: IsLoading;
}

function AddIndustryDropdownWrapper({
  industryIds,
  industriesCacheKey,
  industryExpertises,
  onSelectExistingIndustry,
  onSelectExistingIndustryExpertise,
  onSelectIndustry,
  selectIndustryIsLoading
}: AddIndustryDropdownWrapperProps) {
  const currentUser = useCurrentUser();

  const industryIdsFilter = industryIds.join(',');

  const {
    createIndustryErrorMessage,
    createIndustryIsLoading,
    createIndustry
  } = useCreateIndustry({
    cacheKeys: [industriesCacheKey]
  });

  const {
    industries,
    industriesIsLoading,
    industriesErrorMessage,
    changeIndustriesFilters
  } = usePaginatedIndustries<FetchIndustriesResponse>({
    query: fetchIndustriesQuery,
    cacheKey: industriesCacheKey,
    initialFilters: {
      [IndustryFields.ID]: {
        operator: 'not.in',
        value: industryIdsFilter
      }
    } as unknown as FetchIndustriesFilters,
    initialSort: {
      [IndustryFields.CREATED_AT]: { ascending: false }
    } as unknown as FetchIndustriesSort
  });

  const {
    industriesIsLoading: existingIndustriesIsLoading,
    industriesErrorMessage: existingIndustriesErrorMessage,
    fetchIndustries
  } = usePaginatedIndustries<FetchIndustriesResponse>({
    query: fetchIndustriesQuery,
    cacheKey: IndustriesCache.existsCacheKey(),
    enabled: false as FetchIndustriesEnabled,
    initialPageSize: 10 as FetchIndustriesPageSize
  });

  const handleNewIndustry = useCallback<OnNewIndustryDropdownSelect>(
    async (industryName) => {
      const { data: existingIndustries } = await fetchIndustries({
        nextFilters: {
          [IndustryFields.NAME]: { operator: 'eq', value: industryName }
        }
      });

      const existingIndustry =
        first<FetchIndustriesResponse>(existingIndustries);

      if (existingIndustry) {
        const existingIndustryExpertise = find(
          industryExpertises,
          (industryExpertise) =>
            industryExpertise.industry?.id === existingIndustry.id
        );

        existingIndustryExpertise
          ? onSelectExistingIndustryExpertise(existingIndustryExpertise)
          : onSelectExistingIndustry(existingIndustry);
        return;
      }

      const industry = await createIndustry({
        name: industryName,
        userId: currentUser.id as IndustryUserId,
        nanoId: generateNanoId<IndustryNanoId>()
      });

      if (industry?.id && industry?.name) {
        onSelectIndustry({
          id: industry.id,
          name: industry.name
        });
      }
    },
    [
      currentUser.id,
      industryExpertises,
      fetchIndustries,
      createIndustry,
      onSelectExistingIndustry,
      onSelectExistingIndustryExpertise,
      onSelectIndustry
    ]
  );

  const handleIndustryInputChange = useCallback<OnIndustryInputChange>(
    (industryName) =>
      changeIndustriesFilters({
        [IndustryFields.NAME]: { operator: 'ilike', value: `${industryName}%` }
      }),
    [changeIndustriesFilters]
  );

  // useEffect(() => {
  //   changeIndustriesFilters({
  //     [IndustryFields.ID]: {
  //       operator: 'not.in',
  //       value: industryIdsFilter
  //     }
  //   });
  // }, [industryIdsFilter, changeIndustriesFilters]);

  return (
    <Fragment>
      <IndustryDropdown
        options={industries.map((industry) => ({
          value: industry.id,
          label: industry.name
        }))}
        disabled={
          industriesIsLoading ||
          createIndustryIsLoading ||
          existingIndustriesIsLoading
        }
        isLoading={
          selectIndustryIsLoading ||
          industriesIsLoading ||
          createIndustryIsLoading ||
          existingIndustriesIsLoading
        }
        onIndustryInputChange={handleIndustryInputChange}
        onSelectIndustry={onSelectIndustry}
        onNewIndustry={handleNewIndustry}
      />
      <AlertMessage
        message={
          industriesErrorMessage ||
          createIndustryErrorMessage ||
          existingIndustriesErrorMessage
        }
      />
    </Fragment>
  );
}

export default AddIndustryDropdownWrapper;
