import {
  FetchIndustriesQuery,
  FetchIndustriesCountType,
  FetchIndustriesPage,
  FetchIndustriesPageSize,
  FetchIndustriesFilters,
  FetchIndustriesSort,
  FetchIndustriesIndexQueryName,
  FetchIndustriesIndexCountType,
  FetchIndustriesIndexPage,
  FetchIndustriesIndexPageSize,
  FetchIndustriesIndexFilters,
  FetchIndustriesIndexSort,
  FetchIndustryQuery,
  FetchIndustryIndexQueryName,
  CreateIndustryError,
  UpdateIndustryError,
  DeleteIndustryError,
  IndustryId,
  IndustryNanoId,
  IndustryName,
  IndustryUserId
} from './industriesTypes';

import { BaseBffRequests } from '../BaseBffRequests';

import { IndustriesBffRoutes } from './IndustriesBffRoutes';

interface FetchIndustriesRequestParams {
  query: FetchIndustriesQuery;
  countType?: FetchIndustriesCountType;
  page?: FetchIndustriesPage;
  pageSize?: FetchIndustriesPageSize;
  filters?: FetchIndustriesFilters;
  sort?: FetchIndustriesSort;
}

interface FetchIndustriesIndexRequestParams {
  queryName: FetchIndustriesIndexQueryName;
  countType?: FetchIndustriesIndexCountType;
  page?: FetchIndustriesIndexPage;
  pageSize?: FetchIndustriesIndexPageSize;
  filters?: FetchIndustriesIndexFilters;
  sort?: FetchIndustriesIndexSort;
}

interface FetchIndustryRequestParams {
  query: FetchIndustryQuery;
}

interface FetchIndustryIndexRequestParams {
  queryName: FetchIndustryIndexQueryName;
}

export interface IndustryRequestData {
  name?: IndustryName;
}

export interface CreateIndustryRequestData extends IndustryRequestData {
  id?: IndustryId;
  userId: IndustryUserId;
  nanoId: IndustryNanoId;
}

export interface UpdateIndustryRequestData extends IndustryRequestData {
  name?: IndustryName;
}

export interface DeleteIndustryRequestData {
  industryNanoId: IndustryNanoId;
}

export interface CreateIndustryErrorResponse {
  message: CreateIndustryError;
}

export interface UpdateIndustryErrorResponse {
  message: UpdateIndustryError;
}

export interface DeleteIndustryErrorResponse {
  message: DeleteIndustryError;
}

export class IndustriesBffRequests extends BaseBffRequests {
  static fetchIndustries<T>(params: FetchIndustriesRequestParams) {
    return this.fetchItems<T>(
      IndustriesBffRoutes.fetchIndustriesRoute(),
      params
    );
  }

  static fetchIndustriesIndex<T>(params: FetchIndustriesIndexRequestParams) {
    return this.fetchItemsIndex<T>(
      IndustriesBffRoutes.fetchIndustriesIndexRoute(),
      params
    );
  }

  static fetchIndustry<T>(
    industryId: IndustryId,
    params: FetchIndustryRequestParams
  ) {
    return this.fetchItem<T>(
      IndustriesBffRoutes.fetchIndustryRoute(industryId),
      params
    );
  }

  static fetchIndustryIndex<T>(
    industryNanoId: IndustryNanoId,
    params: FetchIndustryIndexRequestParams
  ) {
    return this.fetchItemIndex<T>(
      IndustriesBffRoutes.fetchIndustryIndexRoute(industryNanoId),
      params
    );
  }

  static fetchIndustryByNanoId<T>(
    industryNanoId: IndustryNanoId,
    params: FetchIndustryRequestParams
  ) {
    return this.fetchItemByNanoId<T>(
      IndustriesBffRoutes.fetchIndustryByNanoIdRoute(industryNanoId),
      params
    );
  }

  static createIndustry<T>(createIndustryData: CreateIndustryRequestData) {
    return this.createItem<T, CreateIndustryRequestData>(
      IndustriesBffRoutes.createIndustryRoute(),
      createIndustryData
    );
  }

  static async updateIndustry<T>(
    updateIndustryNanoId: IndustryNanoId,
    updateIndustryData: UpdateIndustryRequestData
  ) {
    return this.updateItem<T, UpdateIndustryRequestData>(
      IndustriesBffRoutes.updateIndustryRoute(updateIndustryNanoId),
      updateIndustryData
    );
  }

  static async deleteIndustry<T>(deleteIndustryNanoId: IndustryNanoId) {
    return this.deleteItem<T>(
      IndustriesBffRoutes.deleteIndustryRoute(deleteIndustryNanoId)
    );
  }
}
