import {
  Box,
  Flex,
  Grid,
  Stack,
  StackDivider,
  useDisclosure
} from '@chakra-ui/react';
import every from 'lodash/every';
import filter from 'lodash/filter';
import flatMap from 'lodash/flatMap';
import take from 'lodash/take';
import size from 'lodash/size';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import uniqBy from 'lodash/uniqBy';
import intersectionBy from 'lodash/intersectionBy';
import includes from 'lodash/includes';

import { useState, useCallback, useMemo, ChangeEvent } from 'react';

import { Button } from '../../../../helpers/Button';
import { Heading } from '../../../../helpers/Heading';
import { JobLocationHelper } from '../../../jobs/helpers/JobLocationHelper';
import { SimpleModal } from '../../../../helpers/SimpleModal';

import {
  JobCandidateId,
  JobCandidateSearchTypeLabels
} from '../../../jobCandidates/jobCandidatesTypes';
import {
  ShareCandidateProfileCandidateShare,
  ShareCandidateProfileFieldsToShareProps,
  ShareCandidateProfileJob,
  ShareCandidateProfileJobCandidates
} from './ShareCandidateProfile.types';

import { ShareCandidateItem } from './components/ShareCandidateItem';
import { ShareCandidateProfileScreenOne } from './components/ShareCandidateProfileScreenOne';
import { ShareCandidateProfileScreenTwo } from './components/ShareCandidateProfileScreenTwo';

import { renderJobTitle } from '../../../jobs/utils/renderJobTitle';
import {
  CandidateShareCompanyMemberInvitation,
  CandidateShareRecipient,
  CandidateShareShareSettings
} from '../../candidateSharesTypes';
import { IsHidden, ModalIsOpen } from '../../../../types';
import { RecipientId } from '../../../recipients/recipientsTypes';

import { ShareCandidateProfileEmail } from './components/ShareCandidateProfileEmail';

interface ShareCandidateProfileProps {
  isOpen: boolean;
  onClose: () => void;
  job: ShareCandidateProfileJob;
  jobCandidates: ShareCandidateProfileJobCandidates;
  updateJobCandidates: (
    jobCandidates: ShareCandidateProfileJobCandidates
  ) => void;
  updateCandidateShare: (
    candidateShare: ShareCandidateProfileCandidateShare
  ) => void;
  fieldsToShare: ShareCandidateProfileFieldsToShareProps;
  updateFieldsToShare: (
    fieldsToShare: ShareCandidateProfileFieldsToShareProps
  ) => void;
  onProfilesShared: () => void;
  candidateShare: ShareCandidateProfileCandidateShare | null;
}

const ShareCandidateProfile = ({
  isOpen,
  onClose,
  job,
  jobCandidates,
  candidateShare,
  updateCandidateShare,
  onProfilesShared,
  fieldsToShare,
  updateFieldsToShare,
  updateJobCandidates
}: ShareCandidateProfileProps) => {
  const [candidates, setCandidates] =
    useState<ShareCandidateProfileJobCandidates>(jobCandidates);

  const {
    isOpen: isOpenScreenTwo,
    onOpen: showScreenTwo,
    onClose: backToScreenOne
  } = useDisclosure();

  const {
    isOpen: isOpenShowAll,
    onClose: onCloseShowAll,
    onToggle: onToggleShowAll
  } = useDisclosure();

  const {
    isOpen: isOpenComposeEmailDrawer,
    onClose: onCloseComposeEmailDrawer,
    onOpen: onOpenComposeEmailDrawer
  } = useDisclosure();

  const closeModal = () => {
    onClose();
    onCloseShowAll();
    isOpenScreenTwo && backToScreenOne();
  };

  const onCandidatesShared = useCallback<
    (candidateShare: ShareCandidateProfileCandidateShare) => void
  >(
    (candidateShare) => {
      updateCandidateShare(candidateShare);
      onOpenComposeEmailDrawer();
    },
    [onOpenComposeEmailDrawer, updateCandidateShare]
  );

  const onCandidateShareProcessDone = () => {
    setCandidates([]);
    onProfilesShared();
    onClose();
  };

  const candidatesToShow = take(
    candidates,
    !isOpenShowAll ? 2 : size(candidates)
  );

  const allCandidatesAreLeads = every(
    candidates,
    (candidate) =>
      candidate.searchTypeLabel === JobCandidateSearchTypeLabels.LEAD
  );

  const handleBack = useCallback(() => {
    if (allCandidatesAreLeads) {
      onClose();
    } else {
      backToScreenOne();
    }
  }, [allCandidatesAreLeads, onClose, backToScreenOne]);

  const onRemoveJobCandidate = useCallback<
    (jobCandidateId: JobCandidateId) => void
  >(
    (jobCandidateId) => {
      const filteredCandidates = filter(
        candidates,
        (candidate) => candidate.id !== jobCandidateId
      );

      setCandidates(filteredCandidates);

      updateJobCandidates(filteredCandidates);

      if (!size(filteredCandidates)) {
        onClose();
      }
    },
    [candidates, onClose, updateJobCandidates]
  );

  const handleCheckFieldToShare = useCallback<
    (event: ChangeEvent<HTMLInputElement>) => void
  >(
    (event) => {
      const { name, checked } = event.target;

      const newFieldsToShare = map(fieldsToShare, (fieldToShare) => {
        if (name === fieldToShare.name) {
          fieldToShare.checked = checked;
        }

        return fieldToShare;
      });

      updateFieldsToShare(newFieldsToShare);
    },
    [fieldsToShare, updateFieldsToShare]
  );

  const shareSettings = reduce(
    fieldsToShare,
    (obj, field) => {
      const newObj = {
        ...obj,
        ...(field.checked ? { [field.name]: field.checked } : {})
      };

      return newObj;
    },
    {} as CandidateShareShareSettings
  );

  const jobCandidateRecipients = useMemo(() => {
    const allJobCandidatesRecipients = map(jobCandidates, (jobCandidate) => {
      const sharesExceptCurrentShare = filter(
        jobCandidate.candidateShares,
        (cShare) => cShare.id !== candidateShare?.id
      );
      const recipients = flatMap(
        sharesExceptCurrentShare,
        (cSharee) => cSharee.recipients
      );

      return uniqBy(recipients, 'id');
    });

    const intersections = getIntersections(
      allJobCandidatesRecipients,
      'id'
    ) as CandidateShareRecipient[];

    return intersections;
  }, [jobCandidates, candidateShare]);

  const jobCandidateCompanyMemberInvitations = useMemo(() => {
    const allJobCandidatesCompanyMemberInvitations = map(
      jobCandidates,
      (jobCandidate) => {
        const sharesExceptCurrentShare = filter(
          jobCandidate.candidateShares,
          (cShare) => cShare.id !== candidateShare?.id
        );
        const companyMemberInvitations = flatMap(
          sharesExceptCurrentShare,
          (cSharee) => cSharee.companyMemberInvitations
        );

        return uniqBy(companyMemberInvitations, 'email');
      }
    );

    const intersections = getIntersections(
      allJobCandidatesCompanyMemberInvitations,
      'email'
    ) as CandidateShareCompanyMemberInvitation[];

    return intersections;
  }, [jobCandidates, candidateShare]);

  const hiringManagerAndInterviewers = [
    ...(job.hiringManager ? [job.hiringManager] : []),
    ...job.interviewers
  ];

  const jobCandidateRecipientsIds = map(jobCandidateRecipients, ({ id }) => id);

  const jobCollaborators = uniqBy(hiringManagerAndInterviewers, 'id');

  const defaultJobCollaborators = filter(
    jobCollaborators,
    (collab) =>
      !includes(jobCandidateRecipientsIds, collab.id as unknown as RecipientId)
  );

  const jobCollaboratorsStringified = map(
    defaultJobCollaborators,
    ({ id, name }) => JSON.stringify({ id, name })
  );

  const openEmailDrawer = isOpenComposeEmailDrawer && candidateShare;

  const candidateShareRecipientIds = map(
    candidateShare?.recipients,
    ({ id, name }) => JSON.stringify({ id, name })
  );

  const canddateShareCompanyMemberInvitations = map(
    candidateShare?.companyMemberInvitations,
    ({ name, email, accountTypeId }) =>
      JSON.stringify({ name, email, accountTypeId })
  );

  return (
    <>
      <SimpleModal
        size="5xl"
        isOpen={(isOpen && !openEmailDrawer) as ModalIsOpen}
        onCancel={closeModal}
        returnFocusOnClose={false}
        headerBg="gray.100"
        header={
          <Heading level="h4">
            <Flex gap={3} alignItems="center">
              {size(candidates) > 0 ? (
                <Box as="span">
                  Sharing {size(candidates)} candidate profile
                  {size(candidates) > 1 ? 's' : ''} for{' '}
                  <Box as="span" color="primary.500">
                    {renderJobTitle(job)}
                  </Box>
                </Box>
              ) : (
                <Box as="span">
                  Share Job:{'  '}
                  <Box as="span" color="primary.500">
                    {renderJobTitle(job)}
                  </Box>
                </Box>
              )}
              <JobLocationHelper job={job} withIcon />
            </Flex>
          </Heading>
        }
        headerDividerIsHidden={true as IsHidden}
        footerIsHidden={true as IsHidden}
      >
        <Box py={6} w="100%" h="100%">
          <Stack spacing={6} divider={<StackDivider />}>
            <Stack spacing={6}>
              <Grid gap={4} templateColumns="repeat(2, 1fr)">
                {candidatesToShow.map((item) => (
                  <ShareCandidateItem
                    key={item.id}
                    jobCandidate={item}
                    onRemoveJobCandidate={onRemoveJobCandidate}
                  />
                ))}
              </Grid>

              {candidates.length > 2 ? (
                <Flex justifyContent="center">
                  <Button
                    size="medium"
                    hierarchy="link"
                    onClick={onToggleShowAll}
                  >
                    Show {isOpenShowAll ? 'less' : 'all'} profiles
                  </Button>
                </Flex>
              ) : null}
            </Stack>

            {isOpenScreenTwo || allCandidatesAreLeads ? (
              <ShareCandidateProfileScreenTwo
                backToScreenOne={handleBack}
                shareCandidates={onCandidatesShared}
                job={job}
                jobCollaborators={jobCollaborators}
                defaultJobCollaborators={defaultJobCollaborators}
                jobCollaboratorsStringified={jobCollaboratorsStringified}
                shareSettings={
                  allCandidatesAreLeads
                    ? ({} as CandidateShareShareSettings)
                    : shareSettings
                }
                jobCandidates={candidates}
                allCandidatesAreLeads={allCandidatesAreLeads}
                candidateShare={candidateShare}
                candidateShareRecipientIds={candidateShareRecipientIds}
                canddateShareCompanyMemberInvitations={
                  canddateShareCompanyMemberInvitations
                }
                jobCandidateRecipients={jobCandidateRecipients}
                jobCandidateCompanyMemberInvitations={
                  jobCandidateCompanyMemberInvitations
                }
              />
            ) : (
              <ShareCandidateProfileScreenOne
                cancel={closeModal}
                goToNext={showScreenTwo}
                fieldsToShare={fieldsToShare}
                handleCheckFieldToShare={handleCheckFieldToShare}
              />
            )}
          </Stack>
        </Box>
      </SimpleModal>
      {openEmailDrawer ? (
        <ShareCandidateProfileEmail
          candidateShare={candidateShare}
          job={job}
          jobCandidates={candidates}
          isOpen={isOpenComposeEmailDrawer}
          onClose={onCloseComposeEmailDrawer}
          sendEmail={onCandidateShareProcessDone}
        />
      ) : null}
    </>
  );
};

export default ShareCandidateProfile;

const getIntersections = (
  itemsToIterate: Record<string, unknown>[][],
  key: string
) => {
  let response: Record<string, unknown>[] = [];

  for (let i = 0; i < size(itemsToIterate); i++) {
    if (i === 0) {
      response = itemsToIterate[i];
    } else {
      if (size(response) === 0) break;

      const intersections = intersectionBy(response, itemsToIterate[i], key);

      if (size(intersections)) {
        response = intersections;
      } else {
        response = [];
      }
    }
  }

  return response;
};
