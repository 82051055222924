import { Collapse, Stack, StackDivider } from '@chakra-ui/react';
import { useState } from 'react';

import { ViewJobCandidateModalPhoneSidebarAvailabilityForm } from './helpers/ViewJobCandidateModalPhoneSidebarAvailabilityForm';
import { Button } from '../../../../../../../../../../../../../helpers/Button';

type ViewJobCandidateModalPhoneSidebarInterestedType =
  | 'interview-availability'
  | 'assign-task'
  | 'meeting-availability'
  | 'complete-task';

const ViewJobCandidateModalPhoneSidebarScheduleHelper = ({
  onSave
}: {
  onSave: () => void;
}) => {
  const [selectedInterestedOption, setSelectedInterestedOption] =
    useState<ViewJobCandidateModalPhoneSidebarInterestedType | null>();

  const toggleInterestedSelection = (
    selection?: ViewJobCandidateModalPhoneSidebarInterestedType
  ) => {
    if (!selection || selectedInterestedOption === selection) {
      setSelectedInterestedOption(null);
      return;
    }

    setSelectedInterestedOption(selection);
  };

  const discardSelection = () => {
    toggleInterestedSelection();
  };

  return (
    <>
      <Collapse in={!selectedInterestedOption} unmountOnExit>
        <Stack
          flex={1}
          spacing={0}
          overflow="hidden"
          borderRadius="4px"
          border="1px solid"
          borderColor="gray.300"
          divider={<StackDivider />}
          boxShadow="0px 2px 2px rgba(23, 25, 26, 0.06)"
        >
          <Stack direction="row" spacing={0} divider={<StackDivider />}>
            <Button
              w="full"
              size="small"
              hierarchy="tertiary"
              borderRadius={0}
              boxShadow="none"
              border="none"
              value="interview-availability"
              onClick={() =>
                toggleInterestedSelection('interview-availability')
              }
            >
              Interview availability
            </Button>

            <Button
              w="full"
              size="small"
              border="none"
              boxShadow="none"
              borderRadius={0}
              hierarchy="tertiary"
              value="meeting-availability"
              onClick={() => toggleInterestedSelection('meeting-availability')}
            >
              Meeting availability
            </Button>
          </Stack>
        </Stack>
      </Collapse>

      <Collapse
        in={selectedInterestedOption === 'interview-availability'}
        unmountOnExit
      >
        <ViewJobCandidateModalPhoneSidebarAvailabilityForm
          onDiscard={discardSelection}
          onSave={onSave}
        />
      </Collapse>

      <Collapse
        in={selectedInterestedOption === 'meeting-availability'}
        unmountOnExit
      >
        <ViewJobCandidateModalPhoneSidebarAvailabilityForm
          onDiscard={discardSelection}
          onSave={onSave}
        />
      </Collapse>
    </>
  );
};

export default ViewJobCandidateModalPhoneSidebarScheduleHelper;
