import { Stack } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { Text } from '../../../../../../../../../../../helpers/Text';

interface ViewJobCandidateModalPhoneSidebarJobDetailsItemProps {
  label: string;
  value: string | ReactNode;
}

const ViewJobCandidateModalPhoneSidebarJobDetailsItem = ({
  label,
  value
}: ViewJobCandidateModalPhoneSidebarJobDetailsItemProps) => (
  <Stack
    spacing={2}
    className="phone-sidebar-jdi"
    position="relative"
    minW="180px"
    width="50%"
    flex={1}
  >
    <Text textStyle="upperCase1Regular" color="gray.600">
      {label}
    </Text>
    <Text
      textStyle="body1Medium"
      color="gray.900"
      textTransform="capitalize"
      noOfLines={1}
    >
      {value}
    </Text>
  </Stack>
);

export default ViewJobCandidateModalPhoneSidebarJobDetailsItem;
