import {
  FetchSpecializationsCacheKey,
  FetchSpecializationCacheKey
} from './specializationsTypes';

export class SpecializationsCache {
  static indexCacheKey() {
    return 'specializations' as FetchSpecializationsCacheKey;
  }

  static showCacheKey() {
    return 'specialization' as FetchSpecializationCacheKey;
  }

  static existsCacheKey() {
    return 'specializations-exists' as FetchSpecializationsCacheKey;
  }
}
