import { usePaginatedItems } from '../../../common/hooks/usePaginatedItems';

import {
  FetchCompaniesQuery,
  FetchCompaniesCacheKey,
  FetchCompaniesTotalCount,
  FetchCompaniesPage,
  FetchCompaniesPageSize,
  FetchCompaniesError,
  FetchCompaniesErrorMessage,
  FetchCompaniesIsLoading,
  FetchCompaniesIsFetched,
  FetchCompaniesIsPlaceholderData,
  FetchCompaniesEnabled,
  FetchCompaniesPrefetchNextPage,
  FetchCompaniesRefetchInterval,
  FetchCompaniesCountType,
  FetchCompaniesFilters,
  FetchCompaniesSort,
  FetchCompanyID,
  FetchCompanyQuery,
  FetchCompanyCacheKey
} from '../../companiesTypes';

import { CompaniesBffRequests } from '../../CompaniesBffRequests';

interface PaginatedCompaniesBaseOptions<PaginatedCompaniesResponseType> {
  cacheKey: FetchCompaniesCacheKey;
  query: FetchCompaniesQuery;
  countType?: FetchCompaniesCountType;
  initialPage?: FetchCompaniesPage;
  initialPageSize?: FetchCompaniesPageSize;
  initialFilters?: FetchCompaniesFilters;
  initialSort?: FetchCompaniesSort;
  enabled?: FetchCompaniesEnabled;
  prefetchNextPage?: FetchCompaniesPrefetchNextPage;
  refetchInterval?: FetchCompaniesRefetchInterval;
  placeholderCompanies?: PaginatedCompaniesResponseType[];
  placeholderCompaniesCount?: FetchCompaniesTotalCount;
  placeholderData?: () => PaginatedCompaniesResponse<PaginatedCompaniesResponseType>;
}

interface PaginatedCompaniesOptionsWithoutItem {
  fetchItemCacheKey?: never;
  itemQuery?: never;
}

interface PaginatedCompaniesOptionsWithItem {
  fetchItemCacheKey: FetchCompanyCacheKey;
  itemQuery: FetchCompanyQuery;
}

type PaginatedCompaniesOptions<PaginatedCompaniesResponseType> =
  PaginatedCompaniesBaseOptions<PaginatedCompaniesResponseType> &
    (PaginatedCompaniesOptionsWithoutItem | PaginatedCompaniesOptionsWithItem);

interface PaginatedCompaniesResponse<PaginatedCompaniesResponseType> {
  data: PaginatedCompaniesResponseType[];
  totalCount: FetchCompaniesTotalCount;
}

function usePaginatedCompanies<PaginatedCompaniesResponseType>({
  cacheKey,
  query,
  countType,
  initialPage,
  initialPageSize,
  initialFilters,
  initialSort,
  enabled,
  prefetchNextPage,
  refetchInterval,
  placeholderCompanies,
  placeholderCompaniesCount,
  placeholderData,
  fetchItemCacheKey,
  itemQuery
}: PaginatedCompaniesOptions<PaginatedCompaniesResponseType>) {
  const {
    items,
    itemsTotalCount,
    itemsError,
    itemsErrorMessage,
    itemsIsFetched,
    itemsIsLoading,
    itemsIsPlaceholderData,
    itemsCurrentPage,
    itemsCurrentPageSize,
    itemsCurrentFilters,
    itemsCurrentSort,
    fetchItems,
    paginateItems,
    changeItemsPageSize,
    filterItems,
    changeItemsFilters,
    clearItemsFilters,
    sortItems,
    prefetchItems,
    prefetchItem
  } = usePaginatedItems<PaginatedCompaniesResponseType>({
    cacheKey,
    initialPage,
    initialPageSize,
    initialFilters,
    initialSort,
    enabled,
    prefetchNextPage,
    refetchInterval,
    placeholderItems: placeholderCompanies,
    placeholderItemsCount: placeholderCompaniesCount,
    placeholderData,
    queryFn: (params) =>
      CompaniesBffRequests.fetchCompanies<PaginatedCompaniesResponseType>({
        query,
        countType,
        page: params.page as FetchCompaniesPage,
        pageSize: params.pageSize as FetchCompaniesPageSize,
        filters: params.filters as FetchCompaniesFilters,
        sort: params.sort as FetchCompaniesSort
      }),
    ...(itemQuery
      ? {
          fetchItemCacheKey,
          queryItemFn: (itemId) =>
            CompaniesBffRequests.fetchCompany(itemId as FetchCompanyID, {
              query: itemQuery
            })
        }
      : {})
  });

  return {
    companies: items as PaginatedCompaniesResponseType[],
    companiesTotalCount: itemsTotalCount as FetchCompaniesTotalCount,
    companiesError: itemsError as FetchCompaniesError,
    companiesErrorMessage: itemsErrorMessage as FetchCompaniesErrorMessage,
    companiesIsLoading: itemsIsLoading as FetchCompaniesIsLoading,
    companiesIsFetched: itemsIsFetched as FetchCompaniesIsFetched,
    companiesIsPlaceholderData:
      itemsIsPlaceholderData as FetchCompaniesIsPlaceholderData,
    companiesCurrentPage: itemsCurrentPage as FetchCompaniesPage,
    companiesCurrentPageSize: itemsCurrentPageSize as FetchCompaniesPageSize,
    companiesCurrentFilters: itemsCurrentFilters as FetchCompaniesFilters,
    companiesCurrentSort: itemsCurrentSort as FetchCompaniesSort,
    fetchCompanies: fetchItems,
    paginateCompanies: paginateItems,
    changeCompaniesPageSize: changeItemsPageSize,
    filterCompanies: filterItems,
    changeCompaniesFilters: changeItemsFilters,
    clearCompaniesFilters: clearItemsFilters,
    sortCompanies: sortItems,
    prefetchCompanies: prefetchItems,
    prefetchCompany: prefetchItem
  };
}

export default usePaginatedCompanies;
