import { BFF_URI } from '../config';

import {
  FetchItemsBffURL,
  FetchItemBffURL,
  FetchItemByNanoIdBffURL,
  CreateItemBffURL,
  UpdateItemBffURL,
  DeleteItemBffURL,
  PostItemBffURL,
  PostItemsBffURL,
  FetchItemBffID,
  FetchItemBffNanoID,
  FetchItemBffUuid,
  UpdateItemBffID,
  UpdateItemBffNanoID,
  DeleteItemBffNanoID,
  PostItemBffNanoID,
  DeleteItemBffID
} from './baseBffTypes';

export class BaseBffRoutes {
  protected static basePath = `${BFF_URI}/api`;
  protected static route: string;

  protected static fetchItemsRoute<T extends FetchItemsBffURL>() {
    return `${this.basePath}/${this.route}` as T;
  }

  protected static fetchItemsIndexRoute<T extends FetchItemsBffURL>() {
    return `${this.basePath}/${this.route}/read` as T;
  }

  protected static fetchItemRoute<T extends FetchItemBffURL>(
    fetchItemBffID: FetchItemBffID
  ) {
    return `${this.basePath}/${this.route}/${fetchItemBffID}` as T;
  }

  protected static fetchItemIndexRoute<T extends FetchItemBffURL>(
    fetchItemBffNanoID: FetchItemBffNanoID | FetchItemBffUuid
  ) {
    return `${this.basePath}/${this.route}/${fetchItemBffNanoID}/read` as T;
  }

  protected static fetchItemByNanoIdRoute<T extends FetchItemByNanoIdBffURL>(
    fetchItemBffNanoID: FetchItemBffNanoID
  ) {
    return `${this.basePath}/${this.route}/${fetchItemBffNanoID}` as T;
  }

  protected static postItemsRoute<T extends PostItemsBffURL>(route: string) {
    return `${this.basePath}/${this.route}/${route}` as T;
  }

  protected static createItemRoute<T extends CreateItemBffURL>() {
    return `${this.basePath}/${this.route}/create` as T;
  }

  protected static updateItemRoute<T extends UpdateItemBffURL>(
    updateItemBffNanoID: UpdateItemBffID | UpdateItemBffNanoID
  ) {
    return `${this.basePath}/${this.route}/${updateItemBffNanoID}/update` as T;
  }

  protected static deleteItemRoute<T extends DeleteItemBffURL>(
    deleteItemBffNanoID: DeleteItemBffID | DeleteItemBffNanoID
  ) {
    return `${this.basePath}/${this.route}/${deleteItemBffNanoID}/delete` as T;
  }

  protected static postItemRoute<T extends PostItemBffURL>(
    route: string,
    postItemBffNanoID: PostItemBffNanoID
  ) {
    return `${this.basePath}/${this.route}/${postItemBffNanoID}/${route}` as T;
  }
}
