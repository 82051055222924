import React, { forwardRef, ForwardedRef } from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel
} from '@chakra-ui/form-control';
import {
  Input as ChakraInput,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  InputRightAddon,
  InputRightElement
} from '@chakra-ui/input';
import { Flex } from '@chakra-ui/layout';
import Cleave from 'cleave.js/react';

import { mapSize } from '../../../../../../utils/mapSize';

import { CleaveInputFieldControlProps } from './CleaveInputFieldControl.types';

const CleaveInputFieldControl = forwardRef<
  HTMLInputElement,
  CleaveInputFieldControlProps
>(
  (
    {
      size = 'medium',
      label,
      labelFontSize,
      labelAddon,
      id,
      isInvalid,
      errorMessage,
      isRequired,
      isDisabled,
      isReadOnly,
      helperText,
      leftAddon,
      rightAddon,
      leftElement,
      rightElement,
      isClickableLeftElement,
      isClickableRightElement,
      type,
      hidden,
      autoComplete,
      dataLpignore,
      autoFocus,
      options,
      ...props
    }: CleaveInputFieldControlProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => (
    <FormControl
      id={id}
      isInvalid={isInvalid || typeof errorMessage === 'string'}
      isDisabled={isDisabled}
      isRequired={isRequired}
      isReadOnly={isReadOnly}
      hidden={hidden}
    >
      {(label || labelAddon) && (
        <Flex alignItems="center">
          {label && (
            <FormLabel display="flex" flex={1} fontSize={labelFontSize}>
              {label}
            </FormLabel>
          )}

          {labelAddon && (
            <FormLabel
              mr={0}
              ml="auto"
              display="flex"
              as="aside"
              fontSize={labelFontSize}
              alignItems="center"
              requiredIndicator={<></>}
            >
              {labelAddon}
            </FormLabel>
          )}
        </Flex>
      )}

      <InputGroup>
        {leftAddon && (
          <InputLeftAddon border="none" marginInlineEnd={0}>
            {leftAddon}
          </InputLeftAddon>
        )}

        {leftElement && (
          <InputLeftElement
            pointerEvents={isClickableLeftElement ? 'unset' : 'none'}
          >
            {leftElement}
          </InputLeftElement>
        )}

        <ChakraInput
          as={Cleave}
          type={type}
          variant="outline"
          size={mapSize(size)}
          borderColor="gray.200"
          focusBorderColor="primary.500"
          className="form-input-class"
          autoComplete={autoComplete}
          data-lpignore={dataLpignore}
          autoFocus={autoFocus}
          ref={ref}
          options={options}
          {...props}
        />

        {rightElement && (
          <InputRightElement
            pointerEvents={isClickableRightElement ? 'unset' : 'none'}
          >
            {rightElement}
          </InputRightElement>
        )}

        {rightAddon && (
          <InputRightAddon border="none" marginInlineStart={0}>
            {rightAddon}
          </InputRightAddon>
        )}
      </InputGroup>

      {errorMessage ? (
        <FormErrorMessage textStyle="body2Medium" wordBreak="break-all">
          {errorMessage}
        </FormErrorMessage>
      ) : (
        helperText && (
          <FormHelperText textStyle="body2Medium">{helperText}</FormHelperText>
        )
      )}
    </FormControl>
  )
);

CleaveInputFieldControl.displayName = 'CleaveInputFieldControl';

export default CleaveInputFieldControl;
