import {
  Avatar,
  Flex,
  HStack,
  Select,
  Stack,
  StackDivider
} from '@chakra-ui/react';
import { FunctionComponent, ReactNode } from 'react';
import { Heading } from '../../../../../../../../helpers/Heading';
import { Text } from '../../../../../../../../helpers/Text';
import { AddCandidateIcon } from '../../../../../../../../icons/AddCandidateIcon';
import { EmailIcon } from '../../../../../../../../icons/EmailIcon';
import { MessageIcon } from '../../../../../../../../icons/MessageIcon';
import { PhoneIcon } from '../../../../../../../../icons/PhoneIcon';
import { Search } from '../../../../../../../../helpers/Search';

function ViewJobCandidateDetailsProfileOverviewActivities() {
  return (
    <Stack p={4} gap={3} flex={1} bg="white" borderRadius="base">
      <Flex gap={2} alignItems="center">
        <Heading
          m={0}
          p={0}
          level="h4"
          fontSize="md"
          lineHeight={1}
          fontWeight="semibold"
        >
          Activities
        </Heading>

        <Flex gap={2} ml="auto" alignItems="center">
          <Text>Show</Text>

          <Select w={14} size="sm">
            <option>3</option>
            <option>6</option>
            <option>15</option>
          </Select>
        </Flex>
      </Flex>

      <Search w="100%" />

      <Stack spacing={4} color="gray.600" divider={<StackDivider />}>
        <OverviewActivityItem
          icon={EmailIcon}
          title="Ken Brown sent email"
          avatarUrl="/images/team-mate-2.png"
          name="Darrell Barnes"
          date="Thu, May 16 at 09:00 am"
        />

        <OverviewActivityItem
          icon={MessageIcon}
          title="Ken Brown added a comment"
          avatarUrl="/images/team-mate-4.png"
          name="Darrell Barnes"
          date="Thu, May 16 at 09:00 am"
        />

        <OverviewActivityItem
          icon={PhoneIcon}
          title="Dana connected on a call"
          avatarUrl="/images/team-mate-5.png"
          name="Darrell Barnes"
          date="Thu, May 16 at 09:00 am"
        />

        <OverviewActivityItem
          icon={AddCandidateIcon}
          title="Ken Brown added candidate"
          avatarUrl="/images/team-mate-1.png"
          name="Darrell Barnes"
          date="Thu, May 16 at 09:00 am"
        />

        <OverviewActivityItem
          icon={PhoneIcon}
          title="Stage changed by Ken Brown"
          avatarUrl="/images/team-mate-6.png"
          name="Darrell Barnes"
          date="Thu, May 16 at 09:00 am"
        />
      </Stack>
    </Stack>
  );
}

export default ViewJobCandidateDetailsProfileOverviewActivities;

interface OverviewActivityItemProps {
  date: string;
  name: string;
  title: ReactNode;
  avatarUrl: string;
  icon: FunctionComponent;
}

const OverviewActivityItem = ({
  date,
  icon,
  name,
  title,
  avatarUrl
}: OverviewActivityItemProps) => (
  <Stack spacing={3}>
    <Flex gap={2} alignItems="center">
      <Flex w={4} h={4} as={icon} fill="gray.500" />

      {title}
    </Flex>

    <HStack spacing={2}>
      <Avatar src={avatarUrl} name={name} size="xs" />

      <Text textStyle="body1Medium" color="gray.700" noOfLines={1}>
        {name}
      </Text>

      <Text textStyle="body2Regular" noOfLines={1}>
        {date}
      </Text>
    </HStack>
  </Stack>
);
