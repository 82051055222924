import { fromPairs } from 'lodash';

export const ratingOptions: { label: string; value: string }[] = [
  { label: 'No Experience', value: 'no_experience' },
  { label: 'Beginner', value: 'beginner' },
  { label: 'Intermediate', value: 'intermediate' },
  { label: 'Professional', value: 'professional' },
  { label: 'Expert', value: 'expert' }
];

export const ratingOptionsHash = fromPairs(
  ratingOptions.map(({ value, label }) => [value, label])
);

export const experienceOptions: { label: string; value: string }[] = [
  { label: 'Less than 1 year', value: 'lt1yr' },
  { label: '1 year', value: '1yr' },
  { label: '2 years', value: '2yr' },
  { label: '3 years', value: '3yr' },
  { label: '4 years', value: '4yr' },
  { label: '5 years', value: '5yr' },
  { label: '6 years', value: '6yr' },
  { label: '7 years', value: '7yr' },
  { label: '8 years', value: '8yr' },
  { label: '9 years', value: '9yr' },
  { label: '10+ years', value: '10yrs' }
];
