import { Box, Stack, useMediaQuery } from '@chakra-ui/react';

import { JobDescription } from '../../../../jobsTypes';

import { CompletedFieldIcon } from '../../../../../../icons/CompletedFieldIcon';
import { PendingCompletionIcon } from '../../../../../../icons/PendingCompletionIcon';

import { DomHtml } from '../../../../../../helpers/DomHtml';
import { Heading } from '../../../../../../helpers/Heading';
import { useEffect } from 'react';

interface ViewJobDescriptionProps {
  job: {
    description: JobDescription;
  };
  isCareerSiteView?: boolean;
}

function ViewJobDescription({
  job,
  isCareerSiteView
}: ViewJobDescriptionProps) {
  const [isDesktop] = useMediaQuery('(min-width: 992px)', {
    ssr: true,
    fallback: false
  });

  useEffect(() => {
    console.log('isDesktop=', isDesktop);
  }, [isDesktop]);

  return (
    <Stack
      spacing={4}
      bg={{ base: isCareerSiteView ? 'gray.50' : 'white', lg: 'unset' }}
      p={{ base: isCareerSiteView ? '19px 24px' : 6, lg: 0 }}
      border={{ base: '1px solid', lg: 'none' }}
      borderColor={{
        base: isCareerSiteView ? 'gray.200' : 'gray.100',
        lg: 'none'
      }}
      borderRadius="base"
    >
      <Heading
        level="h5"
        color="gray.900"
        fontWeight="600"
        alignItems="center"
        display="inline-flex"
      >
        <Box as="span">Job description</Box>
        {isCareerSiteView ? null : job.description?.length < 10 ? (
          <PendingCompletionIcon ml="2px" />
        ) : (
          <CompletedFieldIcon ml="2px" />
        )}
      </Heading>

      <Stack spacing={8} overflowY={{ base: 'hidden', md: 'unset' }}>
        <Stack spacing={4}>
          <DomHtml content={job.description} noOfLines={isDesktop ? 15 : 5} />
        </Stack>
      </Stack>
    </Stack>
  );
}

export default ViewJobDescription;
