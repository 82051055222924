import snakeCase from 'lodash/snakeCase';

import {
  FetchCompanyMembersQuery,
  CompanyMemberFields,
  CompanyMemberId,
  CompanyMemberNanoId,
  MayBeCompanyMemberCompanyId,
  MayBeCompanyMemberUserId,
  MayBeCompanyMemberRoleId,
  MayBeCompanyMemberRole,
  MayBeCompanyMemberUser,
  CompanyMemberCreatedAt
} from '../companyMembersTypes';

export interface FetchCompanyMembersResponse {
  [CompanyMemberFields.ID]: CompanyMemberId;
  [CompanyMemberFields.NANO_ID]: CompanyMemberNanoId;
  [CompanyMemberFields.COMPANY_ID]: MayBeCompanyMemberCompanyId;
  [CompanyMemberFields.USER_ID]: MayBeCompanyMemberUserId;
  [CompanyMemberFields.USER]: MayBeCompanyMemberUser;
  [CompanyMemberFields.ROLE_ID]: MayBeCompanyMemberRoleId;
  [CompanyMemberFields.ROLE]: MayBeCompanyMemberRole;
  [CompanyMemberFields.CREATED_AT]: CompanyMemberCreatedAt;
}

export const fetchCompanyMembersColumns = [
  CompanyMemberFields.ID,
  CompanyMemberFields.NANO_ID,
  CompanyMemberFields.COMPANY_ID,
  CompanyMemberFields.USER_ID,
  CompanyMemberFields.ROLE_ID,
  CompanyMemberFields.CREATED_AT
];

const fetchCompanyMembersSBColumns = fetchCompanyMembersColumns.map(
  (fetchCompanyMembersColumn) => snakeCase(fetchCompanyMembersColumn)
);

export const fetchCompanyMembersQuery = fetchCompanyMembersSBColumns.join(
  ','
) as FetchCompanyMembersQuery;
