import { ReactNode } from 'react';

import { Link } from '../../../../../helpers/Link';

import { LinkedInIcon } from '../../../../../icons/LinkedInIcon';

import { CompanyName } from '../../../../companies/companiesTypes';

import {
  renderLinkedinUrl,
  RenderLinkedinUrlProps
} from '../../../utils/renderLinkedinUrl';
import { ExternalLinkProps } from '../../../../../helpers/Link/Link';

interface LinkedinLinkHelperUserLinkProps {
  item: {
    name: string;
    firstName?: string | null;
    lastName?: string | null;
    company: {
      name: CompanyName;
    } | null;
    linkedinUrl: string | null;
  };
  companyItem?: never;
}

interface LinkedinLinkHelperCompanyLinkProps {
  companyItem: {
    name: CompanyName;
    linkedinUrl: string | null;
  };
  item?: never;
}

type LinkedinLinkHelperLinkProps = Omit<
  ExternalLinkProps,
  'to' | 'children' | 'isExternal'
>;

type LinkedinLinkHelperProps = {
  children?: ReactNode;
} & LinkedinLinkHelperLinkProps &
  (LinkedinLinkHelperUserLinkProps | LinkedinLinkHelperCompanyLinkProps);

function LinkedinLinkHelper({
  item,
  children,
  companyItem,
  ...props
}: LinkedinLinkHelperProps) {
  const linkedinUrl = renderLinkedinUrl({
    item,
    companyItem
  } as RenderLinkedinUrlProps);

  return (
    <Link to={linkedinUrl} isExternal {...props}>
      {children || <LinkedInIcon />}
    </Link>
  );
}

export default LinkedinLinkHelper;
