import snakeCase from 'lodash/snakeCase';

import {
  FetchEmploymentSkillBadgesQuery,
  EmploymentSkillBadgeFields,
  EmploymentSkillBadgeId,
  EmploymentSkillBadgeNanoId,
  EmploymentSkillBadgeUser,
  EmploymentSkillBadgeCreatedAt,
  EmploymentSkillBadgeSkillBadgeId,
  EmploymentSkillBadgeEmployment,
  EmploymentSkillBadgeEmploymentId,
  EmploymentSkillBadgeUsage,
  EmploymentSkillBadgeExperience
} from '../employmentSkillBadgesTypes';

export interface FetchEmploymentSkillBadgesResponse {
  [EmploymentSkillBadgeFields.ID]: EmploymentSkillBadgeId;
  [EmploymentSkillBadgeFields.NANO_ID]: EmploymentSkillBadgeNanoId;
  [EmploymentSkillBadgeFields.SKILL_BADGE_ID]: EmploymentSkillBadgeSkillBadgeId;
  [EmploymentSkillBadgeFields.USER]: EmploymentSkillBadgeUser;
  [EmploymentSkillBadgeFields.CREATED_AT]: EmploymentSkillBadgeCreatedAt;
  [EmploymentSkillBadgeFields.EMPLOYMENT]: EmploymentSkillBadgeEmployment;
  [EmploymentSkillBadgeFields.EMPLOYMENT_ID]: EmploymentSkillBadgeEmploymentId;
  [EmploymentSkillBadgeFields.USAGE]: EmploymentSkillBadgeUsage;
  [EmploymentSkillBadgeFields.EXPERIENCE]: EmploymentSkillBadgeExperience;
}

export const fetchEmploymentSkillBadgesColumns = [
  EmploymentSkillBadgeFields.ID,
  EmploymentSkillBadgeFields.NANO_ID,
  EmploymentSkillBadgeFields.SKILL_BADGE_ID,
  EmploymentSkillBadgeFields.CREATED_AT,
  EmploymentSkillBadgeFields.EMPLOYMENT_ID,
  EmploymentSkillBadgeFields.USAGE,
  EmploymentSkillBadgeFields.EXPERIENCE
];

const fetchEmploymentSkillBadgesSBColumns =
  fetchEmploymentSkillBadgesColumns.map((fetchEmploymentSkillBadgesColumn) =>
    snakeCase(fetchEmploymentSkillBadgesColumn)
  );

export const fetchEmploymentSkillBadgesQuery =
  fetchEmploymentSkillBadgesSBColumns.join(
    ','
  ) as FetchEmploymentSkillBadgesQuery;
