import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs
} from '@chakra-ui/react';

import { Button } from '../../../../../helpers/Button';
import { Heading } from '../../../../../helpers/Heading';
import { Text } from '../../../../../helpers/Text';

import { ChevronLeftIcon } from '../../../../../icons/ChevronLeftIcon';

import { AddEditContactForm } from '../../forms/AddEditContactForm/components/AddEditContactForm';

interface AddContactProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
}

function AddContactDrawer({ isOpen, onClose, onSave }: AddContactProps) {
  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      autoFocus={false}
      closeOnOverlayClick={false}
    >
      <DrawerOverlay width="calc(100% - 72px)" />

      <DrawerContent
        w="100%"
        maxW="54rem"
        boxShadow="none"
        marginRight={{ base: 0, lg: '72px' }}
      >
        <DrawerHeader px={8} pt={8} pb={4}>
          <Flex flexDir="column" alignItems="flex-start">
            <Button
              hierarchy="unstyled"
              onClick={onClose}
              display="flex"
              flexDirection="row"
              alignItems="center"
              h={6}
              mb={8}
            >
              <ChevronLeftIcon />

              <Text color="#697275" textStyle="regular" fontSize="14px" ml={1}>
                Close
              </Text>
            </Button>

            <Heading level="h2">Add Contact</Heading>
          </Flex>
        </DrawerHeader>

        <DrawerBody px={0} pt={0} pb={8}>
          <Tabs>
            <TabList
              mb={2}
              borderBottom="1px solid"
              borderBottomColor="gray.200"
              px={8}
              position="sticky"
              top={0}
              bg="white"
              zIndex={1}
            >
              <Tab mb="-1px" px="0" fontWeight="medium">
                Profile
              </Tab>
            </TabList>

            <TabPanels px={4}>
              <TabPanel>
                <AddEditContactForm closeModal={onClose} onSave={onSave} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export default AddContactDrawer;
