import {
  FetchIndustryExpertisesCacheKey,
  FetchIndustryExpertiseCacheKey,
  IndustryExpertiseJobId,
  IndustryExpertiseJobResumeRecruiterId,
  IndustryExpertiseJobCandidateId,
  IndustryExpertiseTalentPoolId
} from './industryExpertisesTypes';

export class IndustryExpertisesCache {
  static indexCacheKey() {
    return 'industryExpertises' as FetchIndustryExpertisesCacheKey;
  }

  static showCacheKey() {
    return 'industryExpertise' as FetchIndustryExpertiseCacheKey;
  }

  static postJobCacheKey(industryExpertiseJobId: IndustryExpertiseJobId) {
    return `industryExpertises-post-job-${industryExpertiseJobId}` as FetchIndustryExpertisesCacheKey;
  }

  static postJobResumeRecruiterCacheKey(
    industryExpertiseJobResumeRecruiterId: IndustryExpertiseJobResumeRecruiterId
  ) {
    return `industryExpertises-post-jobResumeRecruiter-${industryExpertiseJobResumeRecruiterId}` as FetchIndustryExpertisesCacheKey;
  }

  static postJobCandidateCacheKey(
    industryExpertiseJobCandidateId: IndustryExpertiseJobCandidateId
  ) {
    return `industryExpertises-post-jobCandidate-${industryExpertiseJobCandidateId}` as FetchIndustryExpertisesCacheKey;
  }

  static postTalentPoolCacheKey(
    industryExpertiseTalentPoolId: IndustryExpertiseTalentPoolId
  ) {
    return `industryExpertises-post-talentPool-${industryExpertiseTalentPoolId}` as FetchIndustryExpertisesCacheKey;
  }
}
