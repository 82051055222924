import snakeCase from 'lodash/snakeCase';

import {
  FetchCompanyMemberInvitationsQuery,
  CompanyMemberInvitationFields,
  CompanyMemberInvitationId,
  CompanyMemberInvitationNanoId,
  CompanyMemberInvitationName,
  CompanyMemberInvitationEmail,
  CompanyMemberInvitationStatus,
  MayBeCompanyMemberInvitationCompanyId,
  MayBeCompanyMemberInvitationUserId,
  MayBeCompanyMemberInvitationUser,
  MayBeCompanyMemberInvitationInvitedUserId,
  MayBeCompanyMemberInvitationInvitedUser,
  MayBeCompanyMemberInvitationAccountTypeId,
  MayBeCompanyMemberInvitationAccountType,
  CompanyMemberInvitationCreatedAt
} from '../companyMemberInvitationsTypes';

export interface FetchCompanyMemberInvitationsResponse {
  [CompanyMemberInvitationFields.ID]: CompanyMemberInvitationId;
  [CompanyMemberInvitationFields.NANO_ID]: CompanyMemberInvitationNanoId;
  [CompanyMemberInvitationFields.NAME]: CompanyMemberInvitationName;
  [CompanyMemberInvitationFields.EMAIL]: CompanyMemberInvitationEmail;
  [CompanyMemberInvitationFields.STATUS]: CompanyMemberInvitationStatus;
  [CompanyMemberInvitationFields.COMPANY_ID]: MayBeCompanyMemberInvitationCompanyId;
  [CompanyMemberInvitationFields.USER_ID]: MayBeCompanyMemberInvitationUserId;
  [CompanyMemberInvitationFields.USER]: MayBeCompanyMemberInvitationUser;
  [CompanyMemberInvitationFields.INVITED_USER_ID]: MayBeCompanyMemberInvitationInvitedUserId;
  [CompanyMemberInvitationFields.INVITED_USER]: MayBeCompanyMemberInvitationInvitedUser;
  [CompanyMemberInvitationFields.ACCOUNT_TYPE_ID]: MayBeCompanyMemberInvitationAccountTypeId;
  [CompanyMemberInvitationFields.ACCOUNT_TYPE]: MayBeCompanyMemberInvitationAccountType;
  [CompanyMemberInvitationFields.CREATED_AT]: CompanyMemberInvitationCreatedAt;
}

export const fetchCompanyMemberInvitationsColumns = [
  CompanyMemberInvitationFields.ID,
  CompanyMemberInvitationFields.NANO_ID,
  CompanyMemberInvitationFields.NAME,
  CompanyMemberInvitationFields.EMAIL,
  CompanyMemberInvitationFields.STATUS,
  CompanyMemberInvitationFields.COMPANY_ID,
  CompanyMemberInvitationFields.USER_ID,
  CompanyMemberInvitationFields.ACCOUNT_TYPE_ID,
  CompanyMemberInvitationFields.INVITED_USER_ID,
  CompanyMemberInvitationFields.CREATED_AT
];

const fetchCompanyMemberInvitationsSBColumns =
  fetchCompanyMemberInvitationsColumns.map(
    (fetchCompanyMemberInvitationsColumn) =>
      snakeCase(fetchCompanyMemberInvitationsColumn)
  );

export const fetchCompanyMemberInvitationsQuery =
  fetchCompanyMemberInvitationsSBColumns.join(
    ','
  ) as FetchCompanyMemberInvitationsQuery;
