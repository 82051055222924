import {
  FetchCountriesQuery,
  FetchCountriesCountType,
  FetchCountriesPage,
  FetchCountriesPageSize,
  FetchCountriesFilters,
  FetchCountriesSort,
  FetchCountriesIndexQueryName,
  FetchCountriesIndexCountType,
  FetchCountriesIndexPage,
  FetchCountriesIndexPageSize,
  FetchCountriesIndexFilters,
  FetchCountriesIndexSort,
  FetchCountryQuery,
  FetchCountryIndexQueryName,
  CreateCountryError,
  UpdateCountryError,
  DeleteCountryError,
  CountryId,
  CountryNanoId,
  CountryName
} from './countriesTypes';

import { BaseBffRequests } from '../BaseBffRequests';

import { CountriesBffRoutes } from './CountriesBffRoutes';

interface FetchCountriesRequestParams {
  query: FetchCountriesQuery;
  countType?: FetchCountriesCountType;
  page?: FetchCountriesPage;
  pageSize?: FetchCountriesPageSize;
  filters?: FetchCountriesFilters;
  sort?: FetchCountriesSort;
}

interface FetchCountriesIndexRequestParams {
  queryName: FetchCountriesIndexQueryName;
  countType?: FetchCountriesIndexCountType;
  page?: FetchCountriesIndexPage;
  pageSize?: FetchCountriesIndexPageSize;
  filters?: FetchCountriesIndexFilters;
  sort?: FetchCountriesIndexSort;
}

interface FetchCountryRequestParams {
  query: FetchCountryQuery;
}

interface FetchCountryIndexRequestParams {
  queryName: FetchCountryIndexQueryName;
}

export interface CountryRequestData {
  name?: CountryName;
}

export interface CreateCountryDefaultRequestData extends CountryRequestData {
  id?: CountryId;
  nanoId: CountryNanoId;
}

export type CreateCountryRequestData = CreateCountryDefaultRequestData;

export type UpdateCountryRequestData = CountryRequestData;

export interface DeleteCountryRequestData {
  countryNanoId: CountryNanoId;
}

export interface CreateCountryErrorResponse {
  message: CreateCountryError;
}

export interface UpdateCountryErrorResponse {
  message: UpdateCountryError;
}

export interface DeleteCountryErrorResponse {
  message: DeleteCountryError;
}

export class CountriesBffRequests extends BaseBffRequests {
  static fetchCountries<T>(params: FetchCountriesRequestParams) {
    return this.fetchItems<T>(CountriesBffRoutes.fetchCountriesRoute(), params);
  }

  static fetchCountriesIndex<T>(params: FetchCountriesIndexRequestParams) {
    return this.fetchItemsIndex<T>(
      CountriesBffRoutes.fetchCountriesIndexRoute(),
      params
    );
  }

  static fetchCountry<T>(
    countryId: CountryId,
    params: FetchCountryRequestParams
  ) {
    return this.fetchItem<T>(
      CountriesBffRoutes.fetchCountryRoute(countryId),
      params
    );
  }

  static fetchCountryIndex<T>(
    countryNanoId: CountryNanoId,
    params: FetchCountryIndexRequestParams
  ) {
    return this.fetchItemIndex<T>(
      CountriesBffRoutes.fetchCountryIndexRoute(countryNanoId),
      params
    );
  }

  static fetchCountryByNanoId<T>(
    countryNanoId: CountryNanoId,
    params: FetchCountryRequestParams
  ) {
    return this.fetchItemByNanoId<T>(
      CountriesBffRoutes.fetchCountryByNanoIdRoute(countryNanoId),
      params
    );
  }

  static createCountry<T>(createCountryData: CreateCountryRequestData) {
    return this.createItem<T, CreateCountryRequestData>(
      CountriesBffRoutes.createCountryRoute(),
      createCountryData
    );
  }

  static async updateCountry<T>(
    updateCountryNanoId: CountryNanoId,
    updateCountryData: UpdateCountryRequestData
  ) {
    return this.updateItem<T, UpdateCountryRequestData>(
      CountriesBffRoutes.updateCountryRoute(updateCountryNanoId),
      updateCountryData
    );
  }

  static async deleteCountry<T>(deleteCountryNanoId: CountryNanoId) {
    return this.deleteItem<T>(
      CountriesBffRoutes.deleteCountryRoute(deleteCountryNanoId)
    );
  }
}
