import {
  FetchStatesQuery,
  FetchStatesCountType,
  FetchStatesPage,
  FetchStatesPageSize,
  FetchStatesFilters,
  FetchStatesSort,
  FetchStatesIndexQueryName,
  FetchStatesIndexCountType,
  FetchStatesIndexPage,
  FetchStatesIndexPageSize,
  FetchStatesIndexFilters,
  FetchStatesIndexSort,
  FetchStateQuery,
  FetchStateIndexQueryName,
  CreateStateError,
  UpdateStateError,
  DeleteStateError,
  StateId,
  StateNanoId,
  StateName
} from './statesTypes';

import { BaseBffRequests } from '../BaseBffRequests';

import { StatesBffRoutes } from './StatesBffRoutes';

interface FetchStatesRequestParams {
  query: FetchStatesQuery;
  countType?: FetchStatesCountType;
  page?: FetchStatesPage;
  pageSize?: FetchStatesPageSize;
  filters?: FetchStatesFilters;
  sort?: FetchStatesSort;
}

interface FetchStatesIndexRequestParams {
  queryName: FetchStatesIndexQueryName;
  countType?: FetchStatesIndexCountType;
  page?: FetchStatesIndexPage;
  pageSize?: FetchStatesIndexPageSize;
  filters?: FetchStatesIndexFilters;
  sort?: FetchStatesIndexSort;
}

interface FetchStateRequestParams {
  query: FetchStateQuery;
}

interface FetchStateIndexRequestParams {
  queryName: FetchStateIndexQueryName;
}

export interface StateRequestData {
  name?: StateName;
}

export interface CreateStateDefaultRequestData extends StateRequestData {
  id?: StateId;
  nanoId: StateNanoId;
}

export type CreateStateRequestData = CreateStateDefaultRequestData;

export type UpdateStateRequestData = StateRequestData;

export interface DeleteStateRequestData {
  stateNanoId: StateNanoId;
}

export interface CreateStateErrorResponse {
  message: CreateStateError;
}

export interface UpdateStateErrorResponse {
  message: UpdateStateError;
}

export interface DeleteStateErrorResponse {
  message: DeleteStateError;
}

export class StatesBffRequests extends BaseBffRequests {
  static fetchStates<T>(params: FetchStatesRequestParams) {
    return this.fetchItems<T>(StatesBffRoutes.fetchStatesRoute(), params);
  }

  static fetchStatesIndex<T>(params: FetchStatesIndexRequestParams) {
    return this.fetchItemsIndex<T>(
      StatesBffRoutes.fetchStatesIndexRoute(),
      params
    );
  }

  static fetchState<T>(stateId: StateId, params: FetchStateRequestParams) {
    return this.fetchItem<T>(StatesBffRoutes.fetchStateRoute(stateId), params);
  }

  static fetchStateIndex<T>(
    stateNanoId: StateNanoId,
    params: FetchStateIndexRequestParams
  ) {
    return this.fetchItemIndex<T>(
      StatesBffRoutes.fetchStateIndexRoute(stateNanoId),
      params
    );
  }

  static fetchStateByNanoId<T>(
    stateNanoId: StateNanoId,
    params: FetchStateRequestParams
  ) {
    return this.fetchItemByNanoId<T>(
      StatesBffRoutes.fetchStateByNanoIdRoute(stateNanoId),
      params
    );
  }

  static createState<T>(createStateData: CreateStateRequestData) {
    return this.createItem<T, CreateStateRequestData>(
      StatesBffRoutes.createStateRoute(),
      createStateData
    );
  }

  static async updateState<T>(
    updateStateNanoId: StateNanoId,
    updateStateData: UpdateStateRequestData
  ) {
    return this.updateItem<T, UpdateStateRequestData>(
      StatesBffRoutes.updateStateRoute(updateStateNanoId),
      updateStateData
    );
  }

  static async deleteState<T>(deleteStateNanoId: StateNanoId) {
    return this.deleteItem<T>(
      StatesBffRoutes.deleteStateRoute(deleteStateNanoId)
    );
  }
}
