import { FieldBrand, ItemRecordID } from '../../../types';

export type SkillRecordId = FieldBrand<ItemRecordID, 'SkillRecordId'>;

export const enum SkillIndexFields {
  ID = 'id',
  RECORD_ID = 'recordId',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  NAME = 'name',
  KIND = 'kind',
  SUGGESTED = 'suggested',
  CREATED_AT = 'createdAt'
}
