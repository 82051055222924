import {
  FieldBrand,
  ItemID,
  ItemIDs,
  ItemUUID,
  ItemNanoId,
  ItemCreatedAt,
  MayBe
} from '../../../types';

import {
  EmployeesNumberId,
  EmployeesNumberStartOfRange,
  EmployeesNumberEndOfRange
} from '../../employeesNumbers/employeesNumbersTypes';

import { CityId, CityLabel, CityName } from '../../cities/citiesTypes';

import { CountryId, CountryName } from '../../countries/countriesTypes';

import { StateId, StateIsoCode, StateName } from '../../states/statesTypes';

import {
  ImageId,
  ImageNanoId,
  ImageName,
  ImageKey,
  ImageFile,
  ImageCreatedAt
} from '../../images/imagesTypes';

import {
  IndustryId,
  IndustryIds,
  IndustryName
} from '../../industries/industriesTypes';

import {
  UserId,
  UserNanoId,
  UserName,
  UserFullName,
  UserEmail,
  UserImage,
  UserRoles
} from '../../users/usersTypes';

export type CompanyId = FieldBrand<ItemID, 'CompanyId'>;
export type MayBeCompanyId = MayBe<CompanyId>;
export type CompanyIds = FieldBrand<ItemIDs, 'CompanyIds'>;
export type CompanyUuid = FieldBrand<ItemUUID, 'CompanyUuid'>;
export type MayBeCompanyUuid = MayBe<CompanyUuid>;
export type CompanyNanoId = FieldBrand<ItemNanoId, 'CompanyNanoId'>;
export type MayBeCompanyNanoId = MayBe<CompanyNanoId>;
export type CompanyName = FieldBrand<string, 'CompanyName'>;
export type CompanyDescription = FieldBrand<string, 'CompanyDescription'>;
export type CompanyWebsite = FieldBrand<string, 'CompanyWebsite'>;
export type CompanyFoundedAt = FieldBrand<string, 'CompanyFoundedAt'>;
export type CompanyMainPhone = FieldBrand<string, 'CompanyMainPhone'>;
export type CompanyZipCode = FieldBrand<string, 'CompanyZipCode'>;
export type CompanyRevenue = FieldBrand<string, 'CompanyRevenue'>;
export type CompanySector = FieldBrand<string, 'CompanySector'>;
export type CompanySize = FieldBrand<string, 'CompanySize'>;
export type CompanyHeadquarters = FieldBrand<string[], 'CompanyHeadquarters'>;
export type CompanyImageId = FieldBrand<ImageId, 'CompanyImageId'>;
export type MayBeCompanyImageId = MayBe<CompanyImageId>;
export type CompanyImage = FieldBrand<
  {
    id: ImageId;
    nanoId: ImageNanoId;
    name: ImageName;
    file: ImageFile;
    key: ImageKey;
    createdAt: ImageCreatedAt;
  },
  'CompanyImage'
>;
export type MayBeCompanyImage = MayBe<CompanyImage>;
export type CompanyIndustryId = FieldBrand<IndustryId, 'CompanyIndustryId'>;
export type MayBeCompanyIndustryId = MayBe<CompanyIndustryId>;
export type CompanyIndustryIds = FieldBrand<IndustryIds, 'CompanyIndustryIds'>;
export type CompanyEmployeesNumberId = FieldBrand<
  EmployeesNumberId,
  'CompanyEmployeesNumberId'
>;
export type MayBeCompanyEmployeesNumberId = MayBe<CompanyEmployeesNumberId>;
export type CompanyCountryId = FieldBrand<CountryId, 'CompanyCountryId'>;
export type MayBeCompanyCountryId = MayBe<CompanyCountryId>;
export type CompanyStateId = FieldBrand<StateId, 'CompanyStateId'>;
export type MayBeCompanyStateId = MayBe<CompanyStateId>;
export type CompanyCityId = FieldBrand<CityId, 'CompanyCityId'>;
export type MayBeCompanyCityId = MayBe<CompanyCityId>;
export type CompanyCompanyType = FieldBrand<string, 'CompanyCompanyType'>;
export type CompanyWorkplace = FieldBrand<string, 'CompanyWorkplace'>;
export type CompanyIndustry = FieldBrand<
  { id: IndustryId; name: IndustryName },
  'CompanyIndustry'
>;
export type MayBeCompanyIndustry = MayBe<CompanyIndustry>;
export type CompanyIndustries = FieldBrand<
  { id: IndustryId; name: IndustryName }[],
  'CompanyIndustries'
>;
export type CompanyEmployeesNumber = FieldBrand<
  {
    id: EmployeesNumberId;
    startOfRange: EmployeesNumberStartOfRange;
    endOfRange: EmployeesNumberEndOfRange;
  },
  'CompanyEmployeesNumber'
>;
export type MayBeCompanyEmployeesNumber = MayBe<CompanyEmployeesNumber>;
export type CompanyCountry = FieldBrand<
  { id: CountryId; name: CountryName },
  'CompanyCountry'
>;
export type MayBeCompanyCountry = MayBe<CompanyCountry>;
export type CompanyState = FieldBrand<
  { id: StateId; name: StateName; isoCode: StateIsoCode },
  'CompanyState'
>;
export type MayBeCompanyState = MayBe<CompanyState>;
export type CompanyCity = FieldBrand<
  { id: CityId; name: CityName; label: CityLabel },
  'CompanyCity'
>;
export type MayBeCompanyCity = MayBe<CompanyCity>;
export type CompanyUserId = FieldBrand<UserId, 'CompanyUserId'>;
export type MayBeCompanyUserId = MayBe<CompanyUserId>;
export type CompanyUser = FieldBrand<
  {
    id: UserId;
    nanoId: UserNanoId;
    name: UserName;
    fullName: UserFullName;
    email: UserEmail;
    image: UserImage;
    roles: UserRoles;
  },
  'CompanyUser'
>;
export type MayBeCompanyUser = MayBe<CompanyUser>;
export type CompanyCreatedAt = FieldBrand<ItemCreatedAt, 'CompanyCreatedAt'>;
export type CompanyLinkedinUrl = FieldBrand<string, 'CompanyLinkedinUrl'>;

export const enum CompanyFields {
  ID = 'id',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  NAME = 'name',
  ADDRESS = 'address',
  ZIP_CODE = 'zipCode',
  COMPANY_TYPE = 'companyType',
  WORKPLACE = 'workplace',
  MAIN_PHONE = 'mainPhone',
  DESCRIPTION = 'description',
  STATUS = 'status',
  WEBSITE = 'website',
  FOUNDED_AT = 'foundedAt',
  INDUSTRY_ID = 'industryId',
  INDUSTRY = 'industry',
  EMPLOYEES_NUMBER_ID = 'employeesNumberId',
  EMPLOYEES_NUMBER = 'employeesNumber',
  IMAGE_ID = 'imageId',
  IMAGE = 'image',
  COUNTRY_ID = 'countryId',
  CITY_ID = 'cityId',
  COUNTRY = 'country',
  CITY = 'city',
  STATE_ID = 'stateId',
  STATE = 'state',
  HEADQUARTERS = 'headquarters',
  REVENUE = 'revenue',
  INDUSTRY_IDS = 'industryIds',
  INDUSTRIES = 'industries',
  CREATED_AT = 'createdAt',
  LINKEDIN_URL = 'linkedinUrl'
}
