import { FieldBrand, ItemRecordID } from '../../../types';

export type TalentPoolJobCandidateRecordId = FieldBrand<
  ItemRecordID,
  'TalentPoolJobCandidateRecordId'
>;

export const enum TalentPoolJobCandidateIndexFields {
  ID = 'id',
  RECORD_ID = 'recordId',
  UUID = 'uuid',
  CREATED_AT = 'createdAt'
}
