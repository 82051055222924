import { Flex, Stack } from '@chakra-ui/layout';
import { useRightSidebarContext } from '../../../../context';
import { IconsEnum } from '../../../../icons';
import { TasksPopup } from './components/TasksPopup';
import { RightSidebarIconButton } from './components/RightSidebarIconButton';

const RightSidebar = () => {
  const {
    isFoldersSidebarOpened,
    isNotesSidebarOpened,
    isTasksSidebarOpened,
    toggleTasksSidebar,
    toggleNotesSidebar,
    toggleFoldersSidebar
  } = useRightSidebarContext();

  return (
    <Flex position="relative" zIndex={9}>
      <TasksPopup />

      <Flex
        flexDirection="column"
        h="100vh"
        w="72px"
        bg="white"
        zIndex={1}
        borderLeft="1px solid"
        borderLeftColor="gray.200"
        pos="fixed"
        right={0}
        top={0}
        py={4}
        px={3}
      >
        <Stack spacing={8}>
          <RightSidebarIconButton
            icon={IconsEnum.TASKS_BUTTON}
            isOpen={isTasksSidebarOpened}
            title="Tasks"
            onClick={toggleTasksSidebar}
          />

          <RightSidebarIconButton
            icon={IconsEnum.NOTES_BUTTON}
            isOpen={isNotesSidebarOpened}
            title="Notes"
            onClick={toggleNotesSidebar}
          />

          <RightSidebarIconButton
            icon={IconsEnum.FOLDERS_BUTTON}
            isOpen={isFoldersSidebarOpened}
            title="Notes"
            onClick={toggleFoldersSidebar}
          />
        </Stack>
      </Flex>
    </Flex>
  );
};

export default RightSidebar;
