import {
  SpecializationItemBffID,
  SpecializationItemBffNanoID,
  FetchSpecializationsBffURL,
  FetchSpecializationBffURL,
  FetchSpecializationByNanoIdBffURL,
  CreateSpecializationBffURL,
  UpdateSpecializationBffURL,
  DeleteSpecializationBffURL
} from './specializationsTypes';

import { BaseBffRoutes } from '../BaseBffRoutes';

export class SpecializationsBffRoutes extends BaseBffRoutes {
  protected static route = 'specializations';

  static fetchSpecializationsRoute() {
    return this.fetchItemsRoute<FetchSpecializationsBffURL>();
  }

  static fetchSpecializationsIndexRoute() {
    return this.fetchItemsIndexRoute<FetchSpecializationsBffURL>();
  }

  static fetchSpecializationRoute(
    specializationItemBffID: SpecializationItemBffID
  ) {
    return this.fetchItemRoute<FetchSpecializationBffURL>(
      specializationItemBffID
    );
  }

  static fetchSpecializationIndexRoute(
    specializationItemBffNanoID: SpecializationItemBffNanoID
  ) {
    return this.fetchItemIndexRoute<FetchSpecializationBffURL>(
      specializationItemBffNanoID
    );
  }

  static fetchSpecializationByNanoIdRoute(
    specializationItemBffNanoID: SpecializationItemBffNanoID
  ) {
    return this.fetchItemByNanoIdRoute<FetchSpecializationByNanoIdBffURL>(
      specializationItemBffNanoID
    );
  }

  static createSpecializationRoute() {
    return this.createItemRoute<CreateSpecializationBffURL>();
  }

  static updateSpecializationRoute(
    specializationItemBffNanoID:
      | SpecializationItemBffID
      | SpecializationItemBffNanoID
  ) {
    return this.updateItemRoute<UpdateSpecializationBffURL>(
      specializationItemBffNanoID
    );
  }

  static deleteSpecializationRoute(
    specializationItemBffNanoID: SpecializationItemBffNanoID
  ) {
    return this.deleteItemRoute<DeleteSpecializationBffURL>(
      specializationItemBffNanoID
    );
  }
}
