import { usePaginatedItems } from '../../../common/hooks/usePaginatedItems';

import {
  FetchCertificationsQuery,
  FetchCertificationsCacheKey,
  FetchCertificationsTotalCount,
  FetchCertificationsPage,
  FetchCertificationsPageSize,
  FetchCertificationsError,
  FetchCertificationsErrorMessage,
  FetchCertificationsIsLoading,
  FetchCertificationsIsFetched,
  FetchCertificationsIsPlaceholderData,
  FetchCertificationsEnabled,
  FetchCertificationsPrefetchNextPage,
  FetchCertificationsCountType,
  FetchCertificationsFilters,
  FetchCertificationsSort,
  FetchCertificationID,
  FetchCertificationQuery,
  FetchCertificationCacheKey
} from '../../certificationsTypes';

import { CertificationsBffRequests } from '../../CertificationsBffRequests';

interface PaginatedCertificationsBaseOptions<
  PaginatedCertificationsResponseType
> {
  cacheKey: FetchCertificationsCacheKey;
  query: FetchCertificationsQuery;
  countType?: FetchCertificationsCountType;
  initialPage?: FetchCertificationsPage;
  initialPageSize?: FetchCertificationsPageSize;
  initialFilters?: FetchCertificationsFilters;
  initialSort?: FetchCertificationsSort;
  enabled?: FetchCertificationsEnabled;
  prefetchNextPage?: FetchCertificationsPrefetchNextPage;
  placeholderCertifications?: PaginatedCertificationsResponseType[];
  placeholderCertificationsCount?: FetchCertificationsTotalCount;
  placeholderData?: () => PaginatedCertificationsResponse<PaginatedCertificationsResponseType>;
}

interface PaginatedCertificationsOptionsWithoutItem {
  fetchItemCacheKey?: never;
  itemQuery?: never;
}

interface PaginatedCertificationsOptionsWithItem {
  fetchItemCacheKey: FetchCertificationCacheKey;
  itemQuery: FetchCertificationQuery;
}

type PaginatedCertificationsOptions<PaginatedCertificationsResponseType> =
  PaginatedCertificationsBaseOptions<PaginatedCertificationsResponseType> &
    (
      | PaginatedCertificationsOptionsWithoutItem
      | PaginatedCertificationsOptionsWithItem
    );

interface PaginatedCertificationsResponse<PaginatedCertificationsResponseType> {
  data: PaginatedCertificationsResponseType[];
  totalCount: FetchCertificationsTotalCount;
}

function usePaginatedCertifications<PaginatedCertificationsResponseType>({
  cacheKey,
  query,
  countType,
  initialPage,
  initialPageSize,
  initialFilters,
  initialSort,
  enabled,
  prefetchNextPage,
  placeholderCertifications,
  placeholderCertificationsCount,
  placeholderData,
  fetchItemCacheKey,
  itemQuery
}: PaginatedCertificationsOptions<PaginatedCertificationsResponseType>) {
  const {
    items,
    itemsTotalCount,
    itemsError,
    itemsErrorMessage,
    itemsIsFetched,
    itemsIsLoading,
    itemsIsPlaceholderData,
    itemsCurrentPage,
    itemsCurrentPageSize,
    itemsCurrentFilters,
    itemsCurrentSort,
    fetchItems,
    paginateItems,
    changeItemsPageSize,
    filterItems,
    changeItemsFilters,
    clearItemsFilters,
    sortItems,
    prefetchItems,
    prefetchItem
  } = usePaginatedItems<PaginatedCertificationsResponseType>({
    cacheKey,
    initialPage,
    initialPageSize,
    initialFilters,
    initialSort,
    enabled,
    prefetchNextPage,
    placeholderItems: placeholderCertifications,
    placeholderItemsCount: placeholderCertificationsCount,
    placeholderData,
    queryFn: (params) =>
      CertificationsBffRequests.fetchCertifications<PaginatedCertificationsResponseType>(
        {
          query,
          countType,
          page: params.page as FetchCertificationsPage,
          pageSize: params.pageSize as FetchCertificationsPageSize,
          filters: params.filters as FetchCertificationsFilters,
          sort: params.sort as FetchCertificationsSort
        }
      ),
    ...(itemQuery
      ? {
          fetchItemCacheKey,
          queryItemFn: (itemId) =>
            CertificationsBffRequests.fetchCertification(
              itemId as FetchCertificationID,
              {
                query: itemQuery
              }
            )
        }
      : {})
  });

  return {
    certifications: items as PaginatedCertificationsResponseType[],
    certificationsTotalCount: itemsTotalCount as FetchCertificationsTotalCount,
    certificationsError: itemsError as FetchCertificationsError,
    certificationsErrorMessage:
      itemsErrorMessage as FetchCertificationsErrorMessage,
    certificationsIsLoading: itemsIsLoading as FetchCertificationsIsLoading,
    certificationsIsFetched: itemsIsFetched as FetchCertificationsIsFetched,
    certificationsIsPlaceholderData:
      itemsIsPlaceholderData as FetchCertificationsIsPlaceholderData,
    certificationsCurrentPage: itemsCurrentPage as FetchCertificationsPage,
    certificationsCurrentPageSize:
      itemsCurrentPageSize as FetchCertificationsPageSize,
    certificationsCurrentFilters:
      itemsCurrentFilters as FetchCertificationsFilters,
    certificationsCurrentSort: itemsCurrentSort as FetchCertificationsSort,
    fetchCertifications: fetchItems,
    paginateCertifications: paginateItems,
    changeCertificationsPageSize: changeItemsPageSize,
    filterCertifications: filterItems,
    changeCertificationsFilters: changeItemsFilters,
    clearCertificationsFilters: clearItemsFilters,
    sortCertifications: sortItems,
    prefetchCertifications: prefetchItems,
    prefetchCertification: prefetchItem
  };
}

export default usePaginatedCertifications;
