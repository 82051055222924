import { Box, Flex, Stack } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { useRightSidebarContext } from '../../../../../../context';
import { Text } from '../../../../../../helpers/Text';
import { NXCareer } from '../../../../../../icons/NXCareer';
import { NXRecruiter } from '../../../../../../icons/NXRecruiter';
import { NXTeammate } from '../../../../../../icons/NXTeammate';
import { HiringPortalRoutes } from '../../../../../../main/hiringPortal/HiringPortalRoutes';
import { RecruitersRoutes } from '../../../../../../main/recruiters/RecruitersRoutes';
import { ProductsMenuPopupProductItem } from './helpers/ProductsMenuPopupProductItem';

const ProductsMenuPopup = () => {
  const { isProductsSidebarOpened, toggleProductsSidebar } =
    useRightSidebarContext();
  const [hidden, setHidden] = useState(!isProductsSidebarOpened);

  return (
    <Flex
      top={0}
      left={0}
      h="100vh"
      w="100vw"
      pos="fixed"
      zIndex={999}
      onClick={toggleProductsSidebar}
      hidden={!isProductsSidebarOpened}
    >
      <motion.div
        hidden={hidden}
        initial={false}
        onAnimationStart={() => setHidden(false)}
        onAnimationComplete={() => setHidden(!isProductsSidebarOpened)}
        animate={{ width: isProductsSidebarOpened ? 192 : 0 }}
        style={{
          top: 0,
          right: '72px',
          height: '100vh',
          display: 'flex',
          position: 'fixed',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          justifyContent: 'flex-end'
        }}
      >
        <Flex
          p={6}
          w="100%"
          bg="white"
          zIndex={2}
          maxW="400px"
          position="relative"
          borderLeft="1px solid"
          flexDirection="column"
          borderLeftColor="gray.200"
          onClick={(e) => e.stopPropagation()}
        >
          <Stack spacing={6}>
            <Stack spacing={3}>
              <Text textStyle="upperCase1Medium">Business</Text>

              <Stack spacing={4}>
                <ProductsMenuPopupProductItem
                  href={RecruitersRoutes.index()}
                  icon={NXRecruiter}
                  label={
                    <Flex as="span" gap={0.5} justifyContent="center">
                      <Box as="span" color="#0BAD90">
                        nx
                      </Box>
                      <Box as="span">Recruiter</Box>
                    </Flex>
                  }
                  isActive
                />

                <ProductsMenuPopupProductItem
                  href={HiringPortalRoutes.index()}
                  icon={NXTeammate}
                  label={
                    <Flex as="span" gap={0.5} justifyContent="center">
                      <Box as="span" color="#AB56B2">
                        nx
                      </Box>
                      <Box as="span">Teammate</Box>
                    </Flex>
                  }
                />
              </Stack>
            </Stack>

            <Stack spacing={3}>
              <Text textStyle="upperCase1Medium">Personal</Text>

              <ProductsMenuPopupProductItem
                href="#"
                icon={NXCareer}
                label={
                  <Flex as="span" gap={0.5} justifyContent="center">
                    <Box as="span" color="#287CC9">
                      nx
                    </Box>
                    <Box as="span">Career</Box>
                  </Flex>
                }
              />
            </Stack>
          </Stack>
        </Flex>
      </motion.div>
    </Flex>
  );
};

export default ProductsMenuPopup;
