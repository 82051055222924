import { Box, Flex } from '@chakra-ui/layout';
import { ButtonWithRef } from '../../../../../../helpers/ButtonWithRef';
import { Icon } from '../../../../../../helpers/Icon';
import { NextLinkHelper } from '../../../../../../helpers/NextLinkHelper';
import { Text } from '../../../../../../helpers/Text';
import { IconsEnum } from '../../../../../../icons';

interface RightSidebarIconButtonWithOnClick {
  onClick: () => void;
  url?: never;
}

interface RightSidebarIconButtonWithUrl {
  onClick?: never;
  url: string;
}

type RightSidebarIconButtonProps = {
  icon: IconsEnum;
  isOpen: boolean;
  title: string;
} & (RightSidebarIconButtonWithOnClick | RightSidebarIconButtonWithUrl);

const RightSidebarIconButton = ({
  icon,
  title,
  isOpen = false,
  onClick,
  url
}: RightSidebarIconButtonProps) => {
  return (
    <Box as={url ? NextLinkHelper : undefined} href={url} passHref>
      <ButtonWithRef
        as="a"
        p={0}
        height="auto"
        border="none"
        display="flex"
        flexDir="column"
        hierarchy="unstyled"
        onClick={onClick}
        alignItems="center"
        _hover={{
          textDecoration: 'none'
        }}
      >
        <Flex
          w={12}
          h={12}
          alignItems="center"
          justifyContent="center"
          border="1px solid"
          borderColor={isOpen ? 'primary.500' : 'transparent'}
          outline="2px solid"
          outlineColor={isOpen ? 'primary.100' : 'transparent'}
          borderRadius="full"
        >
          <Icon icon={icon} />
        </Flex>

        <Text
          textStyle={isOpen ? 'body2Medium' : 'body2Regular'}
          color={isOpen ? 'gray.900' : 'gray.600'}
          mt={2}
        >
          {title}
        </Text>
      </ButtonWithRef>
    </Box>
  );
};

export default RightSidebarIconButton;
