import { JobNanoId } from '../jobs/jobsTypes';
import { TalentPoolNanoId } from '../talentPools/talentPoolsTypes';
import { HiringPortalRoutes } from './HiringPortalRoutes';

const baseSourcingPath = HiringPortalRoutes.sourcing();
const baseProspectsPath = `${baseSourcingPath}/prospects`;

export class HiringPortalSourcingRoutes {
  static index() {
    return baseSourcingPath;
  }

  static prospects() {
    return baseProspectsPath;
  }

  static addProspect() {
    return `${baseProspectsPath}/add`;
  }

  static addProspectTalents(poolNanoId: JobNanoId) {
    return `${baseProspectsPath}/add/${poolNanoId}/talents`;
  }

  static addProspectIntake(poolNanoId: TalentPoolNanoId) {
    return `${baseProspectsPath}/add/${poolNanoId}/intake`;
  }
}
