import { Collapse, SimpleGrid } from '@chakra-ui/react';
import { MouseEvent, useState } from 'react';
import { ViewJobCandidateModalPhoneSidebarCallActionCompletedButton } from './components';
import {
  ViewJobCandidateModalPhoneSidebarAssignTask,
  ViewJobCandidateModalPhoneSidebarCompleteTask
} from '../../actions';
import { useViewJobCandidateModalPhoneSidebarContext } from '../../../context';

type ViewJobCandidateModalPhoneSidebarCallActionCompletedOptionsType =
  | 'follow-up-email'
  | 'share-job-posting'
  | 'assign-task'
  | 'complete-task';

const ViewJobCandidateModalPhoneSidebarCallActionCompletedHelper = () => {
  const [selectedOption, setSelectedOption] =
    useState<ViewJobCandidateModalPhoneSidebarCallActionCompletedOptionsType | null>();

  const { toggleEmailSidebar } = useViewJobCandidateModalPhoneSidebarContext();

  const toggleSelection = (e: MouseEvent<HTMLButtonElement>) => {
    const { value } = e.currentTarget;

    if (!value || selectedOption === value) {
      setSelectedOption(null);
      return;
    }

    setSelectedOption(
      value as ViewJobCandidateModalPhoneSidebarCallActionCompletedOptionsType
    );
  };

  const onDiscard = () => {
    setSelectedOption(null);
  };

  const onSave = () => {
    setSelectedOption(null);
  };

  return (
    <>
      <Collapse in={!selectedOption} unmountOnExit>
        <SimpleGrid columns={2} gap={3}>
          <ViewJobCandidateModalPhoneSidebarCallActionCompletedButton
            label="Follow-up email"
            value="follow-up-email"
            onClick={toggleEmailSidebar}
          />

          <ViewJobCandidateModalPhoneSidebarCallActionCompletedButton
            label="Share job posting"
            value="share-job-posting"
          />

          <ViewJobCandidateModalPhoneSidebarCallActionCompletedButton
            label="Assign a task"
            value="assign-task"
            onClick={toggleSelection}
          />

          <ViewJobCandidateModalPhoneSidebarCallActionCompletedButton
            label="Complete a task"
            value="complete-task"
            onClick={toggleSelection}
          />
        </SimpleGrid>
      </Collapse>

      <Collapse in={selectedOption === 'assign-task'} unmountOnExit>
        <ViewJobCandidateModalPhoneSidebarAssignTask
          onDiscard={onDiscard}
          onSave={onSave}
        />
      </Collapse>

      <Collapse in={selectedOption === 'complete-task'} unmountOnExit>
        <ViewJobCandidateModalPhoneSidebarCompleteTask
          onDiscard={onDiscard}
          onSave={onSave}
        />
      </Collapse>
    </>
  );
};

export default ViewJobCandidateModalPhoneSidebarCallActionCompletedHelper;
