import { Fragment, useEffect, useRef, useState } from 'react';
import { Flex, HStack, ResponsiveValue, useDisclosure } from '@chakra-ui/react';
import { Prose, ProseProps } from '@nikolovlazar/chakra-ui-prose';
import parse from 'html-react-parser';

import { ChevronDownIcon } from '../../icons/ChevronDownIcon';

import { Text } from '../Text';

interface DomHtmlProps extends ProseProps {
  content: string;
  noOfLines?: ResponsiveValue<number>;
}

const DomHtml = ({ content, noOfLines, ...props }: DomHtmlProps) => {
  const [numberOfLines, setNumberOfLines] = useState<number | undefined>(
    undefined
  );
  const [isSeeMoreVisible, setIsSeeMoreVisible] = useState(false);

  const sectionRef = useRef<HTMLElement>(null);

  const { isOpen, onToggle } = useDisclosure();

  useEffect(() => {
    if (noOfLines && sectionRef.current) {
      const height = sectionRef.current?.offsetHeight;
      const lineHeight = 24;
      const allowedHeight = 24 * (noOfLines as number);

      const totalLines = (height as number) / lineHeight;

      if (
        totalLines > (noOfLines as number) ||
        (height as number) > allowedHeight
      ) {
        setIsSeeMoreVisible(true);
      } else {
        setIsSeeMoreVisible(false);
      }

      setTimeout(() => {
        setNumberOfLines(noOfLines as number);
      }, 1000);
    }
  }, [noOfLines, content]);

  return (
    <Fragment>
      <Prose
        noOfLines={isOpen ? undefined : numberOfLines}
        transitionTimingFunction="ease"
        transitionDuration="slow"
        transitionProperty="height"
        {...props}
        fontSize="inherit"
      >
        <section ref={sectionRef}>
          {typeof content === 'string' && parse(content)}
        </section>
      </Prose>
      {isSeeMoreVisible ? (
        <Flex justifyContent="center" mt={4}>
          <HStack spacing={2} cursor="pointer" onClick={onToggle}>
            <Text textStyle="body1Medium" color="primary.500">
              See {isOpen ? 'less' : 'more'}
            </Text>
            <ChevronDownIcon
              h="24px"
              w="24px"
              mb={1}
              transform={isOpen ? 'scaleY(-1)' : 'scaleY(1)'}
              transitionDuration="slow"
              transitionProperty="transform"
            />
          </HStack>
        </Flex>
      ) : null}
    </Fragment>
  );
};

export default DomHtml;
