import trim from 'lodash/trim';
import some from 'lodash/some';
import size from 'lodash/size';

import { isEmail } from '../../../../../../../utils/isEmail';
import {
  UserEmailEmail,
  UserEmailLabel
} from '../../../../../../userEmails/userEmailsTypes';

const firstNameRules = {
  required: 'First name is required'
};

const lastNameRules = {
  required: 'Last name is required'
};

const emailRules = {
  required: 'Email is required',
  validate: {
    isValid: (value: string) => isEmail(trim(value)) || 'Email is invalid'
  }
};

const workEmailsRules = {
  validate: {
    isValid: (value: { email: UserEmailEmail; label: UserEmailLabel }[]) => {
      return (
        !size(value) ||
        some(
          value,
          (val) => isEmail(trim(val.email)) && val.label === 'work'
        ) ||
        'Work Email is required'
      );
    }
  }
};

const personalEmailsRules = {
  validate: {
    isValid: (value: { email: UserEmailEmail; label: UserEmailLabel }[]) => {
      return (
        !size(value) ||
        some(
          value,
          (val) => isEmail(trim(val.email)) && val.label === 'personal'
        ) ||
        'Personal Email is required'
      );
    }
  }
};

function useAddEditContactValidationRules() {
  return {
    firstNameRules,
    lastNameRules,
    emailRules,
    workEmailsRules,
    personalEmailsRules
  };
}

export default useAddEditContactValidationRules;
