import { SkillBadgeExperience } from '../../skillBadgesTypes';

function renderSkillBadgeExperience(value: SkillBadgeExperience) {
  switch (value) {
    case '':
      return null;
    case 'none':
      return null;
    case 'lt1yr':
      return '<1 yr';
    case '10yrs':
      return '10+ yrs';
    case '1yr':
      return '1 year';
    default:
      return /^\d+yr$/.test(value) ? `${value.replace('yr', '')} years` : value;
  }
}

export default renderSkillBadgeExperience;
