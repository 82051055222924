import {
  JobItemBffID,
  JobItemBffNanoID,
  FetchJobsBffURL,
  FetchJobBffURL,
  FetchJobByNanoIdBffURL,
  CreateJobBffURL,
  UpdateJobBffURL,
  FillFromJobBffURL,
  AddCandidatesToJobBffURL,
  DeleteJobBffURL,
  BatchDestroyJobsBffURL,
  FetchJobsCitiesBffURL,
  FetchJobsHiringManagersBffURL,
  AddJobCandidatesToJobBffURL,
  IntakeJobBffURL,
  CancelJobBffURL,
  PublishJobBffURL,
  PauseJobBffURL
} from './jobsTypes';

import { BaseBffRoutes } from '../BaseBffRoutes';

export class JobsBffRoutes extends BaseBffRoutes {
  protected static route = 'jobs';

  static fetchJobsRoute() {
    return `${this.basePath}/${this.route}` as FetchJobsBffURL;
  }

  static fetchJobRoute(jobItemBffID: JobItemBffID) {
    return `${this.basePath}/${this.route}/${jobItemBffID}` as FetchJobBffURL;
  }

  static fetchJobByNanoIdRoute(jobItemBffNanoID: JobItemBffNanoID) {
    return `${this.basePath}/${this.route}/${jobItemBffNanoID}` as FetchJobByNanoIdBffURL;
  }

  static fetchJobsCitiesRoute() {
    return `${this.basePath}/${this.route}/cities` as FetchJobsCitiesBffURL;
  }

  static fetchJobsHiringManagersRoute() {
    return `${this.basePath}/${this.route}/hiring-managers` as FetchJobsHiringManagersBffURL;
  }

  static createJobRoute() {
    return `${this.basePath}/${this.route}/create` as CreateJobBffURL;
  }

  static deleteJobRoute(jobItemBffNanoID: JobItemBffNanoID) {
    return `${this.basePath}/${this.route}/${jobItemBffNanoID}/delete` as DeleteJobBffURL;
  }

  static updateJobRoute(jobItemBffNanoID: JobItemBffNanoID) {
    return `${this.basePath}/${this.route}/${jobItemBffNanoID}/update` as UpdateJobBffURL;
  }

  static intakeJobRoute(jobItemBffNanoID: JobItemBffNanoID) {
    return `${this.basePath}/${this.route}/${jobItemBffNanoID}/intake` as IntakeJobBffURL;
  }

  static fillFromJobRoute(jobItemBffNanoID: JobItemBffNanoID) {
    return `${this.basePath}/${this.route}/${jobItemBffNanoID}/fill-from-job` as FillFromJobBffURL;
  }

  static addCandidatesToJobRoute(jobItemBffNanoID: JobItemBffNanoID) {
    return `${this.basePath}/${this.route}/${jobItemBffNanoID}/add-candidates` as AddCandidatesToJobBffURL;
  }

  static batchDestroyJobsRoute() {
    return `${this.basePath}/${this.route}/batch-destroy` as BatchDestroyJobsBffURL;
  }

  static addJobCandidatesToJobRoute(
    jobItemBffNanoID: JobItemBffNanoID | JobItemBffID
  ) {
    return `${this.basePath}/${this.route}/${jobItemBffNanoID}/add-job-candidates` as AddJobCandidatesToJobBffURL;
  }

  static cancelJobRoute(jobItemBffNanoID: JobItemBffNanoID | JobItemBffID) {
    return `${this.basePath}/${this.route}/${jobItemBffNanoID}/cancel` as CancelJobBffURL;
  }

  static publishJobRoute(jobItemBffNanoID: JobItemBffNanoID | JobItemBffID) {
    return `${this.basePath}/${this.route}/${jobItemBffNanoID}/publish` as PublishJobBffURL;
  }

  static pauseJobRoute(jobItemBffNanoID: JobItemBffNanoID | JobItemBffID) {
    return `${this.basePath}/${this.route}/${jobItemBffNanoID}/pause` as PauseJobBffURL;
  }
}
