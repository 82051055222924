import snakeCase from 'lodash/snakeCase';

import {
  FetchJobQuery,
  JobFields,
  JobId,
  JobNanoId,
  JobName,
  JobDepartmentKey,
  JobDescription,
  JobUserJobId,
  JobCreatedAt,
  JobWorkplace,
  JobWorkAuthorization,
  JobGeography,
  JobNumberOfOpenings,
  JobNotes,
  JobEmployment,
  JobBaseSalary,
  JobExperience,
  JobSeniorityLevel,
  JobUserId,
  JobUser,
  MayBeJobCityId,
  MayBeJobCity,
  MayBeJobRecruiterId,
  MayBeJobRecruiter,
  MayBeJobHiringManagerId,
  MayBeJobHiringManager,
  MayBeJobRecruitingCoordinatorId,
  MayBeJobRecruitingCoordinator,
  MayBeJobCompanyId,
  MayBeJobCompany,
  JobInterviewerIds,
  JobInterviewers,
  JobCompanies,
  JobCompanyIds,
  JobSkillBadgeIds,
  JobSkillBadges,
  JobWorkflowStages,
  JobSkillIds,
  MayBeJobCityIds,
  MayBeJobCities,
  JobJobCandidates,
  JobPrimaryJobFunction,
  JobJobTitle,
  JobJobTitleId,
  JobJobFunctionId,
  JobJobFunction,
  JobDisciplineId,
  JobDiscipline,
  JobDepartmentId,
  JobDepartment,
  JobCertificationIds,
  JobCertifications,
  JobStudyField,
  JobDegree,
  JobPaidTimeOff,
  JobSpecializationIds,
  JobSpecializations,
  JobIndustryIds,
  JobIndustries,
  JobStatus,
  JobPriority
} from '../jobsTypes';

export interface FetchJobResponse {
  [JobFields.ID]: JobId;
  [JobFields.NANO_ID]: JobNanoId;
  [JobFields.NAME]: JobName;
  [JobFields.DEPARTMENT_KEY]: JobDepartmentKey;
  [JobFields.DESCRIPTION]: JobDescription;
  [JobFields.USER_JOB_ID]: JobUserJobId;
  [JobFields.WORKPLACE]: JobWorkplace;
  [JobFields.WORK_AUTHORIZATION]: JobWorkAuthorization;
  [JobFields.GEOGRAPHY]: JobGeography;
  [JobFields.NUMBER_OF_OPENINGS]: JobNumberOfOpenings;
  [JobFields.RECRUITER_ID]: MayBeJobRecruiterId;
  [JobFields.RECRUITER]: MayBeJobRecruiter;
  [JobFields.HIRING_MANAGER_ID]: MayBeJobHiringManagerId;
  [JobFields.RECRUITING_COORDINATOR_ID]: MayBeJobRecruitingCoordinatorId;
  [JobFields.INTERVIEWER_IDS]: JobInterviewerIds;
  [JobFields.COMPANY_IDS]: JobCompanyIds;
  [JobFields.SKILL_BADGE_IDS]: JobSkillBadgeIds;
  [JobFields.EMPLOYMENT]: JobEmployment;
  [JobFields.BASE_SALARY]: JobBaseSalary;
  [JobFields.EXPERIENCE]: JobExperience;
  [JobFields.SENIORITY_LEVEL]: JobSeniorityLevel;
  [JobFields.USER_ID]: JobUserId;
  [JobFields.USER]: JobUser;
  [JobFields.CITY_ID]: MayBeJobCityId;
  [JobFields.CITY]: MayBeJobCity;
  [JobFields.CITY_IDS]: MayBeJobCityIds;
  [JobFields.CITIES]: MayBeJobCities;
  [JobFields.COMPANY_ID]: MayBeJobCompanyId;
  [JobFields.COMPANY]: MayBeJobCompany;
  [JobFields.HIRING_MANAGER]: MayBeJobHiringManager;
  [JobFields.RECRUITING_COORDINATOR]: MayBeJobRecruitingCoordinator;
  [JobFields.INTERVIEWERS]: JobInterviewers;
  [JobFields.COMPANIES]: JobCompanies;
  [JobFields.SKILL_BADGES]: JobSkillBadges;
  [JobFields.WORKFLOW_STAGES]: JobWorkflowStages;
  [JobFields.GEOGRAPHY]: JobGeography;
  [JobFields.NUMBER_OF_OPENINGS]: JobNumberOfOpenings;
  [JobFields.NOTES]: JobNotes;
  [JobFields.SKILL_IDS]: JobSkillIds;
  [JobFields.CREATED_AT]: JobCreatedAt;
  [JobFields.JOB_CANDIDATES]: JobJobCandidates;
  [JobFields.PRIMARY_JOB_FUNCTION]: JobPrimaryJobFunction;
  [JobFields.JOB_TITLE]: JobJobTitle;
  [JobFields.JOB_TITLE_ID]: JobJobTitleId;
  [JobFields.JOB_FUNCTION]: JobJobFunction;
  [JobFields.JOB_FUNCTION_ID]: JobJobFunctionId;
  [JobFields.DISCIPLINE_ID]: JobDisciplineId;
  [JobFields.DISCIPLINE]: JobDiscipline;
  [JobFields.DEPARTMENT_ID]: JobDepartmentId;
  [JobFields.DEPARTMENT]: JobDepartment;
  [JobFields.CERTIFICATION_IDS]: JobCertificationIds;
  [JobFields.CERTIFICATIONS]: JobCertifications;
  [JobFields.STUDY_FIELD]: JobStudyField;
  [JobFields.DEGREE]: JobDegree;
  [JobFields.PAID_TIME_OFF]: JobPaidTimeOff;
  [JobFields.SPECIALIZATION_IDS]: JobSpecializationIds;
  [JobFields.SPECIALIZATIONS]: JobSpecializations;
  [JobFields.INDUSTRY_IDS]: JobIndustryIds;
  [JobFields.INDUSTRIES]: JobIndustries;
  [JobFields.STATUS]: JobStatus;
  [JobFields.JOB_PRIORITY]: JobPriority;
}

export const fetchJobColumns = [
  JobFields.ID,
  JobFields.NANO_ID,
  JobFields.NAME,
  JobFields.DEPARTMENT_KEY,
  JobFields.DESCRIPTION,
  JobFields.USER_JOB_ID,
  JobFields.WORKPLACE,
  JobFields.WORK_AUTHORIZATION,
  JobFields.GEOGRAPHY,
  JobFields.NUMBER_OF_OPENINGS,
  JobFields.NOTES,
  JobFields.USER_ID,
  JobFields.CITY_ID,
  JobFields.RECRUITER_ID,
  JobFields.HIRING_MANAGER_ID,
  JobFields.RECRUITING_COORDINATOR_ID,
  JobFields.COMPANY_ID,
  JobFields.EMPLOYMENT,
  JobFields.BASE_SALARY,
  JobFields.EXPERIENCE,
  JobFields.SENIORITY_LEVEL,
  JobFields.CREATED_AT,
  JobFields.PRIMARY_JOB_FUNCTION,
  JobFields.JOB_FUNCTION_ID,
  JobFields.JOB_TITLE_ID,
  JobFields.DISCIPLINE_ID,
  JobFields.DEPARTMENT_ID,
  JobFields.STATUS
];

const fetchJobSBColumns = fetchJobColumns.map((fetchJobColumn) =>
  snakeCase(fetchJobColumn)
);

export const fetchJobQuery = `
  ${fetchJobSBColumns.join(',')},
  city:city_id(id,name,label),
  recruiter:recruiter_id(id,nano_id,name,image:image_id(id,nano_id,name,file,created_at)),
  hiring_manager:hiring_manager_id(id,nano_id,name,image:image_id(id,nano_id,name,file,created_at)),
  recruiting_coordinator:recruiting_coordinator_id(id,nano_id,name,image:image_id(id,nano_id,name,file,created_at)),
  company:company_id(id,name,image:image_id(id,nano_id,name,file,key,created_at)),
  interviewers:jobs_interviewers!inner(interviewer:interviewer_id(id,nano_id,name,image:image_id(id,nano_id,name,file,key,created_at))),
  interviewer_ids:jobs_interviewers!inner(id:interviewer_id),
  company_ids:jobs_companies!inner(id:company_id),
  city_ids:cities!jobs_cities(id),
  cities!jobs_cities(id,nano_id,label),
  skill_badges(id,rating,experience,certified,requirements,skill:skill_id(id,name,kind),skill_license:skill_license_id(id,nano_id,name)),
  workflow_stages!fk_rails_70a35bfeef(id,nano_id,name,arrangement),
  job_candidates!fk_rails_8877d24aca(id,nano_id)
` as FetchJobQuery;
