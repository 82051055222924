import { Icon, IconProps } from '@chakra-ui/icon';

const NotesButtonIcon = ({ w = '40px', h = '40px', ...props }: IconProps) => (
  <Icon viewBox="0 0 40 40" w={w} h={h} {...props}>
    <circle cx={20} cy={20} r={20} fill="#EEA401" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.455 14.13c0-.432.366-.782.818-.782h.637a1 1 0 0 1 1 1v1.347c0 .433.365.783.818.783h1.636c.453 0 .818-.35.818-.783 0-.43.367-.782.819-.782.451 0 .818.351.818.782 0 .433.365.783.818.783h1.636c.453 0 .818-.35.818-.783v-1.347a1 1 0 0 1 1-1h.637c.452 0 .818.35.818.782v11.74c0 .431-.366.782-.818.782H14.273c-.452 0-.818-.35-.818-.783V14.13ZM11 28.217c0 .432.366.783.818.783h16.364c.452 0 .818-.35.818-.783V11.783c0-.432-.366-.783-.818-.783H11.818c-.452 0-.818.35-.818.783v16.434Z"
      fill="#F8FAFB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.272 19.61a.783.783 0 0 0-.783-.782h-4.98a.783.783 0 1 0 0 1.565h4.98c.433 0 .783-.35.783-.782ZM23.272 22.736a.783.783 0 0 0-.783-.783h-4.98a.783.783 0 1 0 0 1.565h4.98c.433 0 .783-.35.783-.782Z"
      fill="#F8FAFB"
    />
  </Icon>
);

export default NotesButtonIcon;
