import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Stack,
  StackDivider,
  SystemStyleObject,
  useDisclosure
} from '@chakra-ui/react';
import { Button } from '../../../../../../helpers/Button';
import { IconButton } from '../../../../../../helpers/buttons/IconButton';
import { Heading } from '../../../../../../helpers/Heading';
import { Text } from '../../../../../../helpers/Text';

import { ReactNode } from 'react';
import { useCurrentUser } from '../../../../../../auth/hooks/useAuth';
import { AvatarHelper } from '../../../../../../helpers/AvatarHelper';
import { InputField } from '../../../../../../helpers/forms/formFields/InputField';
import { WysiwygFieldControl } from '../../../../../../helpers/forms/formFields/WysiwygField/components/WysiwygFieldControl';
import { LinkedInAvatar } from '../../../../../../helpers/LinkedInAvatar';
import { AttachIcon } from '../../../../../../icons/AttachIcon';
import { CancelIcon } from '../../../../../../icons/CancelIcon';
import { ChevronDownIcon } from '../../../../../../icons/ChevronDownIcon';
import { ClockOutlineIcon } from '../../../../../../icons/ClockOutlineIcon';
import { InboxDestructiveActionPopup } from '../InboxDestructiveActionPopup';
import { MultiSelectFieldControl } from '../../../../../../helpers/forms/formFields/MultiSelectField/components/MultiSelectFieldControl';
import { ComposeNewEmailDrawerMailRecipients } from './ComposeNewEmailDrawer.types';
import { ErrorMessage, IsLoading } from '../../../../../../types';
import { AlertMessage } from '../../../../../../helpers/AlertMessage';

interface ComposeNewEmailDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  sendMessage: () => void;
  sendMessageIsLoading?: IsLoading;
  sendMessageErrorMessage?: ErrorMessage;
  withBackButton?: boolean;
  onBack?: () => void;
  title: string | ReactNode;
  subject?: string;
  emailContent?: ReactNode;
  isShareProfile?: boolean;
  emailRecipients?: ComposeNewEmailDrawerMailRecipients;
  copiedEmailRecipients?: ComposeNewEmailDrawerMailRecipients;
  isReadOnly?: boolean;
}

const ComposeNewEmailDrawer = ({
  isOpen,
  onClose,
  title,
  subject,
  sendMessage,
  sendMessageIsLoading,
  sendMessageErrorMessage,
  withBackButton,
  onBack,
  emailContent,
  isShareProfile,
  emailRecipients,
  copiedEmailRecipients,
  isReadOnly
}: ComposeNewEmailDrawerProps) => {
  const {
    isOpen: isDiscardModalOpen,
    onOpen: onOpenDiscardModal,
    onClose: onCloseDiscardModal
  } = useDisclosure();

  const currentUser = useCurrentUser();

  const discardDraft = () => {
    onCloseDiscardModal();
    onClose();
  };

  const emailRecipientsSelectValue =
    emailRecipients?.map(({ email, id, name, image }) => ({
      label: name || email,
      value: id,
      image
    })) || [];

  const copiedEmailRecipientsSelectValue =
    copiedEmailRecipients?.map(({ email, id, name, image }) => ({
      label: name || email,
      value: id,
      image
    })) || [];

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      autoFocus={false}
      closeOnOverlayClick={false}
    >
      <DrawerOverlay width="100%" />

      <DrawerContent p={0} w="100%" bg="gray.50" maxW="54rem" boxShadow="none">
        <DrawerHeader px={8} pt={8} pb={0}>
          <Flex flexDir="column" alignItems="flex-start">
            <IconButton
              h={6}
              mb={4}
              minW={0}
              display="flex"
              onClick={onClose}
              aria-label="Close"
              hierarchy="unstyled"
              flexDirection="row"
              alignItems="center"
              icon={<CancelIcon />}
            />

            {typeof title === 'string' ? (
              <Heading level="h2">{title}</Heading>
            ) : (
              title
            )}
          </Flex>
        </DrawerHeader>

        <DrawerBody px={8} pt={4} pb={8}>
          <Flex
            p={4}
            gap={2}
            bg="white"
            minH="100%"
            borderRadius={4}
            border="1px solid"
            flexDirection="column"
            borderColor="gray.100"
          >
            <Flex alignItems="center">
              <Heading level="h5">New Message</Heading>
            </Flex>

            <Stack spacing={4} flex={1}>
              <Stack spacing={2} direction="row">
                {currentUser.image ? (
                  <AvatarHelper
                    name={currentUser.name}
                    image={currentUser.image}
                  />
                ) : (
                  <LinkedInAvatar />
                )}

                <Stack spacing={0} flex={1} justifyContent="center">
                  <Text textStyle="body1Medium" noOfLines={1}>
                    {currentUser.name}
                  </Text>
                </Stack>
              </Stack>

              <Stack
                spacing={2}
                color="gray.600"
                textStyle="body1Regular"
                divider={<StackDivider />}
              >
                <Flex as="label" gap={1} cursor="pointer" alignItems="center">
                  <Box as="span">To:</Box>

                  <MultiSelectFieldControl
                    size="sm"
                    withImages
                    onChange={console.log}
                    defaultValue={emailRecipientsSelectValue}
                    reactSelectStyles={{
                      control: (base: SystemStyleObject) => ({
                        ...base,
                        borderColor: 'transparent',
                        p: 0
                      })
                    }}
                    options={emailRecipientsSelectValue}
                    isReadOnly={isReadOnly}
                  />
                </Flex>

                <Flex as="label" gap={1} cursor="pointer" alignItems="center">
                  <Box as="span">Cc:</Box>{' '}
                  <MultiSelectFieldControl
                    size="sm"
                    withImages
                    onChange={console.log}
                    defaultValue={copiedEmailRecipientsSelectValue}
                    reactSelectStyles={{
                      control: (base: SystemStyleObject) => ({
                        ...base,
                        borderColor: 'transparent',
                        p: 0
                      })
                    }}
                    options={copiedEmailRecipientsSelectValue}
                    isReadOnly={isReadOnly}
                  />
                </Flex>

                <Flex as="label" gap={1} cursor="pointer" alignItems="center">
                  <Box as="span">Subject:</Box>
                  <InputField
                    defaultValue={subject}
                    size="small"
                    borderColor="white"
                    isReadOnly={isReadOnly}
                  />
                </Flex>
              </Stack>

              {isShareProfile ? (
                <Flex
                  p="5"
                  bg="gray.50"
                  borderRadius={4}
                  alignItems="center"
                  border="1px solid"
                  borderColor="gray.300"
                  justifyContent="center"
                >
                  {emailContent}
                </Flex>
              ) : (
                <Box flex={1} minH="400px">
                  <WysiwygFieldControl
                    name=""
                    value=""
                    defaultValue=""
                    onChange={() => {
                      /*  */
                    }}
                  />
                </Box>
              )}

              <Stack spacing={2}>
                <AlertMessage message={sendMessageErrorMessage} />
                <Stack spacing={4} direction="row" divider={<StackDivider />}>
                  <Flex mr="auto">
                    {withBackButton ? (
                      <Button
                        size="small"
                        hierarchy="secondary"
                        onClick={onBack}
                      >
                        Back
                      </Button>
                    ) : null}
                  </Flex>

                  <Flex gap={4}>
                    <Button
                      color="gray.600"
                      size="small"
                      hierarchy="link"
                      leftIcon={<AttachIcon color="gray.500" />}
                    >
                      Attach
                    </Button>
                    <Button
                      color="gray.600"
                      size="small"
                      hierarchy="link"
                      leftIcon={<ClockOutlineIcon color="gray.500" />}
                    >
                      Schedule
                    </Button>
                  </Flex>

                  <Flex gap={2} mb={2}>
                    <Button
                      size="small"
                      hierarchy="tertiary"
                      onClick={onOpenDiscardModal}
                    >
                      Discard
                    </Button>

                    <Button
                      size="small"
                      rightIcon={<ChevronDownIcon w={4} h={4} fill="white" />}
                      onClick={sendMessage}
                      isLoading={sendMessageIsLoading}
                    >
                      Send
                    </Button>
                  </Flex>
                </Stack>
              </Stack>
            </Stack>
          </Flex>

          <InboxDestructiveActionPopup
            isOpen={isDiscardModalOpen}
            onClose={onCloseDiscardModal}
            discardAction={discardDraft}
            title="Discard Message"
            actionButtonText="Yes, Discard"
            content="Are you sure you want to discard this draft?"
          />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default ComposeNewEmailDrawer;
