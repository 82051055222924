import {
  Avatar,
  Box,
  Collapse,
  Flex,
  HStack,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  Portal,
  Stack,
  StackDivider,
  Tooltip,
  useDisclosure
} from '@chakra-ui/react';
import first from 'lodash/first';
import flatMap from 'lodash/flatMap';
import orderBy from 'lodash/orderBy';
import size from 'lodash/size';
import { useEffect } from 'react';
import { Button } from '../../../../../../../../helpers/Button';
import { NextLinkHelper } from '../../../../../../../../helpers/NextLinkHelper';
import { RelativeDateHelper } from '../../../../../../../../helpers/RelativeDateHelper';
import { Text } from '../../../../../../../../helpers/Text';
import { IconButton } from '../../../../../../../../helpers/buttons/IconButton';
import { ActivitiesIcon } from '../../../../../../../../icons/ActivitiesIcon';
import { CalendarIcon } from '../../../../../../../../icons/CalendarIcon';
import { ChevronDownIcon } from '../../../../../../../../icons/ChevronDownIcon';
import { EmailIcon } from '../../../../../../../../icons/EmailIcon';
import { MessageIcon } from '../../../../../../../../icons/MessageIcon';
import { MoreIcon } from '../../../../../../../../icons/MoreIcon';
import { PhoneIcon } from '../../../../../../../../icons/PhoneIcon';
import { ShareIcon } from '../../../../../../../../icons/ShareIcon';
import { ThumbUpIcon } from '../../../../../../../../icons/ThumbUpIcon';
import {
  JobCandidateLinkedinUrl,
  JobCandidateUser,
  MayBeJobCandidateDisqualifiedAt,
  MayBeJobCandidateDueDate,
  MayBeJobCandidateNewWorkflowStageTaskAt,
  MayBeJobCandidateViewedAt,
  MayBeJobCandidateWorkflowStageTask,
  MayBeJobCandidateWorkflowStageTaskScheduledAt
} from '../../../../../../../jobCandidates/jobCandidatesTypes';
import { JobsRoutes } from '../../../../../../JobsRoutes';
import {
  JobId,
  JobJobTitle,
  JobName,
  JobNanoId
} from '../../../../../../jobsTypes';
import { renderJobTitle } from '../../../../../../utils/renderJobTitle';
import { JobCandidateDetailsJobCandidate } from '../../../../ViewJobCandidateDetailsPage.types';
import { PipelineStageCandidateBio } from '../PipelineStageCandidateBio';

interface PipelineStageCandidatePopoverContentProps {
  jobCandidate: JobCandidateDetailsJobCandidate & {
    linkedinUrl: JobCandidateLinkedinUrl;
    user?: JobCandidateUser;
    disqualifiedAt: MayBeJobCandidateDisqualifiedAt;
    dueDate: MayBeJobCandidateDueDate;
    workflowStageTaskScheduledAt: MayBeJobCandidateWorkflowStageTaskScheduledAt;
    newWorkflowStageTaskAt: MayBeJobCandidateNewWorkflowStageTaskAt;
    viewedAt: MayBeJobCandidateViewedAt;
    workflowStageTask: MayBeJobCandidateWorkflowStageTask;
    job: {
      id: JobId;
      nanoId: JobNanoId;
      jobTitle: JobJobTitle;
      name: JobName;
    };
  };
}

function PipelineStageCandidatePopoverContent({
  jobCandidate
}: PipelineStageCandidatePopoverContentProps) {
  const {
    isOpen: isOpenCollapse,
    onOpen: onOpenCollapse,
    onClose: onCloseCollapse
  } = useDisclosure();

  useEffect(() => {
    onOpenCollapse();
  }, [onOpenCollapse, onCloseCollapse]);

  const sortedCandidateShares = orderBy(
    jobCandidate.candidateShares,
    ['updatedAt'],
    'desc'
  );

  const recipients = flatMap(
    sortedCandidateShares,
    (candidateShare) => candidateShare.recipients
  );

  const firstCandidateShareUpdatedTime = first(sortedCandidateShares)
    ?.updatedAt as string;

  const isShared = !!size(recipients);

  return (
    <Portal key={jobCandidate.nanoId}>
      <PopoverContent
        w="360px"
        boxShadow="0 5px 5px -5px rgba(0, 0, 0, 0.1), 0 0 10px 5px rgba(0, 0, 0, 0.04)"
      >
        <PopoverHeader p={4} border="none">
          <Stack spacing={4}>
            <PipelineStageCandidateBio
              jobCandidate={jobCandidate}
              requiresCredit={false}
            />

            <Flex gap={3} alignItems="center">
              <HStack
                spacing={0}
                overflow="hidden"
                borderRadius="base"
                divider={<StackDivider borderColor="primary.100" />}
              >
                <Button
                  h={8}
                  px={4}
                  size="small"
                  borderRadius="none"
                  leftIcon={<PhoneIcon w={3.5} h={3.5} fill="white" />}
                >
                  Call
                </Button>

                <IconButton
                  h={8}
                  display="flex"
                  aria-label=""
                  size="extra-small"
                  alignItems="center"
                  borderRadius="none"
                  justifyContent="center"
                  icon={<ChevronDownIcon w={4} h={4} fill="white" />}
                />
              </HStack>

              <Tooltip label="Email" textTransform="capitalize" hasArrow>
                <IconButton
                  h={8}
                  w={8}
                  minW={0}
                  minH={0}
                  value="email"
                  display="flex"
                  aria-label=""
                  alignItems="center"
                  borderRadius="full"
                  justifyContent="center"
                  icon={<EmailIcon w={4} h={4} />}
                  hierarchy="tertiary"
                />
              </Tooltip>

              <Tooltip label="Comment" textTransform="capitalize" hasArrow>
                <IconButton
                  h={8}
                  w={8}
                  minW={0}
                  minH={0}
                  value="comment"
                  display="flex"
                  aria-label=""
                  alignItems="center"
                  borderRadius="full"
                  justifyContent="center"
                  icon={<MessageIcon w={4} h={4} />}
                  hierarchy="tertiary"
                />
              </Tooltip>

              <Tooltip label="More" textTransform="capitalize" hasArrow>
                <IconButton
                  h={8}
                  w={8}
                  minW={0}
                  minH={0}
                  ml="auto"
                  value="more"
                  display="flex"
                  aria-label=""
                  alignItems="center"
                  borderRadius="full"
                  justifyContent="center"
                  icon={<MoreIcon w={5} h={5} />}
                  hierarchy="tertiary"
                  boxShadow="none"
                  border="none"
                />
              </Tooltip>
            </Flex>
          </Stack>
        </PopoverHeader>

        <Collapse in={isOpenCollapse} key={jobCandidate.nanoId}>
          <PopoverBody p={4} borderTop="1px solid" borderTopColor="gray.200">
            <Stack spacing={4}>
              <HStack spacing={3}>
                <ActivitiesIcon />

                <Text textStyle="body1Medium" color="gray.900">
                  Last Activity
                </Text>
              </HStack>

              <Stack spacing={3} divider={<StackDivider />}>
                <Flex
                  alignItems="center"
                  gap={2}
                  justifyContent="space-between"
                >
                  <Flex alignItems="center" gap={2}>
                    <Avatar size="sm" src="/images/default-avatar-5.png" />

                    <Text textStyle="body1Medium" noOfLines={1}>
                      Bakaran Kulon
                    </Text>
                  </Flex>

                  <Flex
                    w={9}
                    h={9}
                    pt="9px"
                    pb="11px"
                    bg="green.100"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="full"
                  >
                    <ThumbUpIcon color="green.400" w={4} h={4} />
                  </Flex>
                </Flex>

                <Stack spacing={3}>
                  <Flex alignItems="center" gap={4}>
                    <Text textStyle="body1Medium" noOfLines={1}>
                      Interested
                    </Text>

                    <Text textStyle="body2Medium" noOfLines={1} ml="auto">
                      03/21/2022 | 10:00 am
                    </Text>
                  </Flex>

                  <Text textStyle="body2Regular" p={3} bg="gray.100">
                    Voluptas soluta doloribus porro numquam libero dolorum
                    obcaecati repellat hic praesentium aut officia ut quaerat
                    neque ex sapiente vero ipsa, est ipsam.
                  </Text>
                </Stack>
              </Stack>

              {isShared && (
                <Stack
                  px={2}
                  py={1.5}
                  gap={2}
                  spacing={-2}
                  borderRadius="base"
                  bg="gray.100"
                  w="full"
                >
                  <HStack spacing={2}>
                    <Flex
                      w={4}
                      h={4}
                      border="1px solid"
                      borderRadius="full"
                      alignItems="center"
                      borderColor="gray.500"
                      justifyContent="center"
                    >
                      <ShareIcon fill="gray.500" width={2.5} h={2.5} />
                    </Flex>

                    <Text textStyle="body1Medium" noOfLines={1}>
                      Shared profile{' '}
                      <RelativeDateHelper
                        date={firstCandidateShareUpdatedTime}
                        showFullDateAfterYesterday
                      />
                    </Text>
                  </HStack>

                  <Text color="gray.700" noOfLines={2}>
                    <Box as="span" verticalAlign="middle">
                      for
                    </Box>{' '}
                    <NextLinkHelper
                      href={
                        jobCandidate.job
                          ? JobsRoutes.viewDetails(jobCandidate.job.nanoId)
                          : JobsRoutes.index()
                      }
                      passHref
                    >
                      <Box
                        as="span"
                        color="primary.600"
                        textStyle="body1Medium"
                        verticalAlign="bottom"
                      >
                        {jobCandidate.job
                          ? renderJobTitle(jobCandidate.job)
                          : 'Python Developer'}
                      </Box>
                    </NextLinkHelper>
                  </Text>
                </Stack>
              )}

              <Stack
                px={2}
                py={1.5}
                gap={2}
                spacing={-2}
                borderRadius="base"
                bg="gray.100"
                w="full"
              >
                <HStack spacing={2}>
                  <CalendarIcon w={4} h={4} />
                  <Text color="gray.700" noOfLines={1}>
                    <Box as="span" textStyle="body1Medium" color="gray.900">
                      Scheduled
                    </Box>{' '}
                    interview yesterday
                  </Text>
                </HStack>

                <Text color="gray.700" noOfLines={2}>
                  <Box as="span" textStyle="body1Medium">
                    Mike Tyson
                  </Box>{' '}
                  scheduled interview with{' '}
                  <Box as="span" color="primary.600" textStyle="body1Medium">
                    Roban Rowan +1
                  </Box>{' '}
                  for{' '}
                  <NextLinkHelper
                    href={
                      jobCandidate.job
                        ? JobsRoutes.viewDetails(jobCandidate.job.nanoId)
                        : JobsRoutes.index()
                    }
                    passHref
                  >
                    <Box as="span" color="primary.600" textStyle="body1Medium">
                      {jobCandidate.job
                        ? renderJobTitle(jobCandidate.job)
                        : 'Python Developer'}
                    </Box>
                  </NextLinkHelper>
                </Text>
              </Stack>
            </Stack>
          </PopoverBody>
        </Collapse>
      </PopoverContent>
    </Portal>
  );
}

export default PipelineStageCandidatePopoverContent;
