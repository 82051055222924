import { FieldBrand, ItemRecordID } from '../../../types';

import { UserUuid } from '../../users/usersTypes';

export type StateRecordId = FieldBrand<ItemRecordID, 'StateRecordId'>;

export type StateUserUuid = FieldBrand<UserUuid, 'StateUserUuid'>;

export const enum StateIndexFields {
  ID = 'id',
  RECORD_ID = 'recordId',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  CREATED_AT = 'createdAt',

  USER_UUID = 'userUuid'
}
