import { FetchJobCandidatesCompletedWorkflowStageTasksCacheKey } from '../../jobCandidatesCompletedWorkflowStageTasksTypes';

import { useCreateItem } from '../../../common/hooks/useCreateItem';

import {
  CreateJobCandidatesCompletedWorkflowStageTaskRequestData,
  CreateJobCandidatesCompletedWorkflowStageTaskErrorResponse
} from '../../JobCandidatesCompletedWorkflowStageTasksRequests';
import { JobCandidatesCompletedWorkflowStageTasksBffRequests } from '../../JobCandidatesCompletedWorkflowStageTasksBffRequests';

interface CreateJobCandidatesCompletedWorkflowStageTaskOptions {
  cacheKeys?: FetchJobCandidatesCompletedWorkflowStageTasksCacheKey[];
}

function useCreateJobCandidatesCompletedWorkflowStageTask({
  cacheKeys = []
}: CreateJobCandidatesCompletedWorkflowStageTaskOptions) {
  const {
    createItemData,
    createItemError,
    createItemErrorMessage,
    createItemIsLoading,
    createItem,
    createItemReset
  } = useCreateItem<
    CreateJobCandidatesCompletedWorkflowStageTaskRequestData,
    CreateJobCandidatesCompletedWorkflowStageTaskErrorResponse
  >({
    mutationFn: (queryInput) =>
      JobCandidatesCompletedWorkflowStageTasksBffRequests.createJobCandidatesCompletedWorkflowStageTask(
        queryInput
      ),
    cacheKeys
  });

  return {
    createJobCandidatesCompletedWorkflowStageTaskData: createItemData,
    createJobCandidatesCompletedWorkflowStageTaskError: createItemError,
    createJobCandidatesCompletedWorkflowStageTaskErrorMessage:
      createItemErrorMessage,
    createJobCandidatesCompletedWorkflowStageTaskIsLoading: createItemIsLoading,
    createJobCandidatesCompletedWorkflowStageTask: createItem,
    createJobCandidatesCompletedWorkflowStageTaskReset: createItemReset
  };
}

export default useCreateJobCandidatesCompletedWorkflowStageTask;
