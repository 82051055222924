import { Collapse, Stack } from '@chakra-ui/react';
import { MouseEvent, useState } from 'react';
import { Button } from '../../../../../../../../../../../helpers/Button';
import { ViewJobCandidateModalPhoneSidebarAvailabilitySlot } from '../ViewJobCandidateModalPhoneSidebarAvailabilitySlot';
import { ViewJobCandidateModalPhoneSidebarChooseAvailabilityDate } from '../ViewJobCandidateModalPhoneSidebarChooseAvailabilityDate';

export type SnoozeSlotType =
  | 'today'
  | 'tomorrow'
  | 'weekend'
  | 'next-week'
  | 'custom';

interface ViewJobCandidateModalPhoneSidebarAvailabilityProps {
  showAvatar?: boolean;
  showTimeZone?: boolean;
  isMultiSelect?: boolean;
  onSave: () => void;
  isOpenChooseDate: boolean;
  saveOnTimeChange?: boolean;
  onToggleChooseDate: () => void;
  onCloseChooseDate: () => void;
}

const ViewJobCandidateModalPhoneSidebarAvailability = ({
  onSave,
  showAvatar,
  // showTimeZone,
  isMultiSelect,
  isOpenChooseDate,
  onToggleChooseDate,
  onCloseChooseDate,
  saveOnTimeChange = true
}: ViewJobCandidateModalPhoneSidebarAvailabilityProps) => {
  const [selectedSlot, setSelectedSlot] = useState<SnoozeSlotType | null>();

  const toggleSelection = (e: MouseEvent<HTMLButtonElement>) => {
    const { value } = e.currentTarget;

    if (!value || selectedSlot === value) {
      setSelectedSlot(null);
      return;
    }

    setSelectedSlot(value as SnoozeSlotType);
    saveOnTimeChange && onSave();
  };

  const selectSnoozeSlot = () => {
    if (isOpenChooseDate) {
      onCloseChooseDate();
    }

    setSelectedSlot('custom');

    saveOnTimeChange && onSave();
  };

  return (
    <Stack spacing={0}>
      <Collapse in={!isOpenChooseDate} unmountOnExit>
        <Stack spacing={2} pb={3}>
          <ViewJobCandidateModalPhoneSidebarAvailabilitySlot
            label="Later today"
            value="today"
            time="09:00 am"
            selectedSlot={selectedSlot}
            onClick={toggleSelection}
            isMultiSelect={isMultiSelect}
            showAvatar={showAvatar}
          />

          <ViewJobCandidateModalPhoneSidebarAvailabilitySlot
            label="Tomorrow"
            value="tomorrow"
            time="10:00 am"
            selectedSlot={selectedSlot}
            onClick={toggleSelection}
            isMultiSelect={isMultiSelect}
            showAvatar={showAvatar}
          />

          <ViewJobCandidateModalPhoneSidebarAvailabilitySlot
            label="This weekend"
            value="weekend"
            time="09:00 am"
            day="Sat"
            selectedSlot={selectedSlot}
            onClick={toggleSelection}
            isMultiSelect={isMultiSelect}
            showAvatar={showAvatar}
          />

          <ViewJobCandidateModalPhoneSidebarAvailabilitySlot
            label="Next week"
            value="next-week"
            day="Mon"
            time="09:00 am"
            selectedSlot={selectedSlot}
            onClick={toggleSelection}
            isMultiSelect={isMultiSelect}
            showAvatar={showAvatar}
          />

          {selectedSlot === 'custom' ? (
            <ViewJobCandidateModalPhoneSidebarAvailabilitySlot
              label="Custom Date"
              value="custom"
              day="26/04"
              time="09:00 am"
              selectedSlot={selectedSlot}
              onClick={toggleSelection}
              isMultiSelect={isMultiSelect}
              showAvatar={showAvatar}
            />
          ) : null}
        </Stack>

        <Button
          size="small"
          fontSize="sm"
          hierarchy="ghost"
          onClick={onToggleChooseDate}
        >
          Choose a date
        </Button>
      </Collapse>

      <Collapse in={isOpenChooseDate} unmountOnExit>
        <ViewJobCandidateModalPhoneSidebarChooseAvailabilityDate
          onDiscard={onCloseChooseDate}
          onSave={selectSnoozeSlot}
        />
      </Collapse>
    </Stack>
  );
};

export default ViewJobCandidateModalPhoneSidebarAvailability;
