import {
  useDisclosure,
  Stack,
  Flex,
  ButtonGroup,
  Collapse
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { Rating } from '../../../../../../helpers/Rating';
import { TextareaField } from '../../../../../../helpers/forms/formFields/TextareaField';
import { DeleteIcon } from '../../../../../../icons/DeleteIcon';
import { EditIcon } from '../../../../../../icons/EditIcon';
import { ScoreCardState } from '../../../../pages/ViewJobSettingsPage';
import { Text } from '../../../../../../helpers/Text';
import { IconButton } from '../../../../../../helpers/buttons/IconButton';
import { CheckIcon } from '../../../../../../icons/CheckIcon';
import { InputField } from '../../../../../../helpers/forms/formFields/InputField';

type ScoreCardItemCommentProps = {
  title: string;
  rating?: number;
  state: ScoreCardState;
  interview: boolean;
};

const ScoreCardItemComment = ({
  title,
  state,
  rating,
  interview
}: ScoreCardItemCommentProps) => {
  const {
    onOpen: onOpenEditTitle,
    isOpen: isOpenEditTitle,
    onClose: onCloseEditTitle
  } = useDisclosure();

  const { isOpen: isOpenComment, onOpen: onOpenComment } = useDisclosure();
  const [updatedTitle, setUpdatedTitle] = useState(title);

  return (
    <Stack>
      <Flex gap={4} alignItems="center">
        <>
          {isOpenEditTitle ? (
            <>
              <InputField
                bg="white"
                defaultValue={updatedTitle}
                onChange={(e) => {
                  setUpdatedTitle(e.target.value);
                }}
              />
            </>
          ) : (
            <>
              <Text lineHeight={10} flex={1} fontSize={interview ? 16 : 14}>
                {updatedTitle || title}
              </Text>

              <Flex
                h={10}
                justifyContent="center"
                flex={state === 'edit' ? 1 : undefined}
              >
                <Rating
                  iconSize={4}
                  value={rating || 0}
                  onClick={onOpenComment}
                />
              </Flex>
            </>
          )}
        </>

        {state === 'edit' && (
          <ButtonGroup ml="auto">
            <DeleteItemComment />

            {isOpenEditTitle ? (
              <IconButton
                size="small"
                hierarchy="link"
                aria-label="Edit"
                onClick={onCloseEditTitle}
                icon={<CheckIcon fill="gray.500" w={4} h={4} />}
              />
            ) : (
              <IconButton
                size="small"
                hierarchy="link"
                aria-label="Edit"
                onClick={onOpenEditTitle}
                icon={<EditIcon fill="gray.500" w={5} h={5} />}
              />
            )}
          </ButtonGroup>
        )}
      </Flex>

      <Collapse in={isOpenComment}>
        <TextareaField bg="white" />
      </Collapse>
    </Stack>
  );
};

export default ScoreCardItemComment;

const DeleteItemComment = () => {
  return (
    <>
      <IconButton
        size="small"
        hierarchy="link"
        aria-label="Delete"
        icon={<DeleteIcon w={5} h={5} />}
      />
    </>
  );
};
