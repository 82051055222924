import {
  Avatar,
  Box,
  Popover,
  PopoverAnchor,
  PopoverBody,
  PopoverContent,
  Portal,
  Stack,
  useDisclosure,
  useMediaQuery
} from '@chakra-ui/react';
import { ReactNode, useRef, useState } from 'react';
import Search from '../Search/Search';
import { Text } from '../Text';
import { HeaderSearchDropdown } from './components/HeaderSearchDropdown';

const HeaderSearch = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [searchParam, setSearchParam] = useState('');

  const [isDesktop] = useMediaQuery('(min-width: 992px)', {
    ssr: true,
    fallback: false
  });

  return (
    <Box>
      {isOpen && (
        <Box
          position="fixed"
          w="100%"
          h="100%"
          top="0"
          left="0"
          zIndex="9"
          onClick={onClose}
        />
      )}

      <Popover
        offset={[0, 0]}
        isLazy
        returnFocusOnClose={false}
        isOpen={isOpen}
        onClose={onClose}
        placement="bottom-start"
        initialFocusRef={inputRef}
        closeOnBlur={false}
      >
        <PopoverAnchor>
          <Box mb={-2} pb={2} zIndex={10} pos="relative">
            <Box bg="white" borderRadius="full">
              <Search
                onFocus={onOpen}
                ref={inputRef}
                onChange={(e) => setSearchParam(e?.target?.value)}
                zIndex={5}
                pos="relative"
              />
            </Box>
          </Box>
        </PopoverAnchor>

        {isDesktop && (
          <Portal>
            <PopoverContent w="3xl">
              <PopoverBody p={4}>
                <HeaderSearchDropdown searchParam={searchParam} />
              </PopoverBody>
            </PopoverContent>
          </Portal>
        )}
      </Popover>
    </Box>
  );
};

export default HeaderSearch;

interface SearchItemProps {
  avatarUrl: string;
  title: string;
  content: ReactNode;
}

export const SearchItem = ({ avatarUrl, title, content }: SearchItemProps) => (
  <Stack
    direction="row"
    spacing={2}
    alignItems="center"
    cursor="pointer"
    p={1}
    transitionDuration="slow"
    transitionProperty="background"
    _hover={{ bg: 'gray.100' }}
  >
    <Avatar src={avatarUrl} />

    <Stack spacing={0} flex={1}>
      <Text textStyle="body1Medium">{title}</Text>
      <Text textStyle="body1Regular" color="gray.600">
        {content}
      </Text>
    </Stack>
  </Stack>
);

export const AccentText = ({ children }: { children: ReactNode }) => (
  <Text as="span" color="primary.500" textStyle="body1Medium">
    {children}
  </Text>
);

export const HighlightText = ({ children }: { children: ReactNode }) => (
  <Text as="span" color="gray.900" textStyle="body1Medium">
    {children}
  </Text>
);
