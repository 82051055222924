import snakeCase from 'lodash/snakeCase';

import {
  FetchUserQuery,
  UserFields,
  UserId,
  UserUuid,
  UserNanoId,
  UserName,
  UserEmail,
  MayBeUserWorkEmail,
  UserFullName,
  MayBeUserFirstName,
  MayBeUserLastName,
  MayBeUserPhone,
  UserPersonalEmail,
  UserCompanyPhone,
  UserCompanyEmail,
  UserTitle,
  UserDescription,
  MayBeUserZipCode,
  MayBeUserSource,
  MayBeUserLinkedinUrl,
  MayBeUserOtherDetails,
  UserCreatedAt,
  UserQualifications,
  UserEmailSignature,
  UserEmailSignatureEnabled,
  MayBeUserIndustryExperience,
  MayBeUserSeniority,
  UserHasSecurityQuestion1,
  UserHasSecurityAnswer1,
  UserHasSecurityQuestion2,
  UserHasSecurityAnswer2,
  UserEmailSyncEnabledAt,
  UserEmailSyncProvider,
  UserScheduleInterviewsEnabledAt,
  UserScheduleInterviewsProvider,
  UserApprovedAt,
  MayBeUserTimeZone,
  MayBeUserProvidedRole,
  MayBeUserImage,
  MayBeUserImageId,
  MayBeUserAccountTypeId,
  MayBeUserAccountType,
  MayBeUserCompanyId,
  MayBeUserCompany,
  MayBeUserCountryId,
  MayBeUserCountry,
  MayBeUserStateId,
  MayBeUserState,
  MayBeUserCityId,
  MayBeUserCity,
  MayBeUserIndustry,
  MayBeUserIndustryId,
  UserIndustryIds,
  UserIndustries,
  UserSpecializationIds,
  UserSpecializations,
  UserUserEmails,
  UserUserPhoneNumbers,
  UserEmployments,
  UserEducations,
  UserCertifications,
  UserSkillIds,
  MayBeUserDepartmentId,
  MayBeUserDepartment,
  UserProvidedRole
} from '../usersTypes';

export interface FetchUserResponse {
  [UserFields.ID]: UserId;
  [UserFields.UUID]: UserUuid;
  [UserFields.NANO_ID]: UserNanoId;
  [UserFields.NAME]: UserName;
  [UserFields.FULL_NAME]: UserFullName;
  [UserFields.EMAIL]: UserEmail;
  [UserFields.WORK_EMAIL]: MayBeUserWorkEmail;
  [UserFields.FIRST_NAME]: MayBeUserFirstName;
  [UserFields.LAST_NAME]: MayBeUserLastName;
  [UserFields.PHONE]: MayBeUserPhone;
  [UserFields.PERSONAL_EMAIL]: UserPersonalEmail;
  [UserFields.COMPANY_PHONE]: UserCompanyPhone;
  [UserFields.COMPANY_EMAIL]: UserCompanyEmail;
  [UserFields.TITLE]: UserTitle;
  [UserFields.DESCRIPTION]: UserDescription;
  [UserFields.ZIP_CODE]: MayBeUserZipCode;
  [UserFields.SOURCE]: MayBeUserSource;
  [UserFields.LINKEDIN_URL]: MayBeUserLinkedinUrl;
  [UserFields.OTHER_DETAILS]: MayBeUserOtherDetails;
  [UserFields.QUALIFICATIONS]: UserQualifications;
  [UserFields.EMAIL_SIGNATURE]: UserEmailSignature;
  [UserFields.EMAIL_SIGNATURE_ENABLED]: UserEmailSignatureEnabled;
  [UserFields.INDUSTRY_EXPERIENCE]: MayBeUserIndustryExperience;
  [UserFields.SENIORITY]: MayBeUserSeniority;
  [UserFields.HAS_SECRET_QUESTION1]: UserHasSecurityQuestion1;
  [UserFields.HAS_SECRET_ANSWER1]: UserHasSecurityAnswer1;
  [UserFields.HAS_SECRET_QUESTION2]: UserHasSecurityQuestion2;
  [UserFields.HAS_SECRET_ANSWER2]: UserHasSecurityAnswer2;
  [UserFields.EMAIL_SYNC_ENABLED_AT]: UserEmailSyncEnabledAt;
  [UserFields.EMAIL_SYNC_PROVIDER]: UserEmailSyncProvider;
  [UserFields.SCHEDULE_INTERVIEWS_ENABLED_AT]: UserScheduleInterviewsEnabledAt;
  [UserFields.SCHEDULE_INTERVIEWS_PROVIDER]: UserScheduleInterviewsProvider;
  [UserFields.APPROVED_AT]: UserApprovedAt;
  [UserFields.TIME_ZONE]: MayBeUserTimeZone;
  [UserFields.PROVIDED_ROLE]: MayBeUserProvidedRole;
  [UserFields.IMAGE_ID]: MayBeUserImageId;
  [UserFields.IMAGE]: MayBeUserImage;
  [UserFields.ACCOUNT_TYPE_ID]: MayBeUserAccountTypeId;
  [UserFields.ACCOUNT_TYPE]: MayBeUserAccountType;
  [UserFields.COMPANY_ID]: MayBeUserCompanyId;
  [UserFields.COMPANY]: MayBeUserCompany;
  [UserFields.INDUSTRY_ID]: MayBeUserIndustryId;
  [UserFields.INDUSTRY]: MayBeUserIndustry;
  [UserFields.COUNTRY_ID]: MayBeUserCountryId;
  [UserFields.COUNTRY]: MayBeUserCountry;
  [UserFields.STATE_ID]: MayBeUserStateId;
  [UserFields.STATE]: MayBeUserState;
  [UserFields.CITY_ID]: MayBeUserCityId;
  [UserFields.CITY]: MayBeUserCity;
  [UserFields.INDUSTRY_IDS]: UserIndustryIds;
  [UserFields.INDUSTRIES]: UserIndustries;
  [UserFields.SPECIALIZATION_IDS]: UserSpecializationIds;
  [UserFields.SPECIALIZATIONS]: UserSpecializations;
  [UserFields.USER_EMAILS]: UserUserEmails;
  [UserFields.USER_PHONE_NUMBERS]: UserUserPhoneNumbers;
  [UserFields.EMPLOYMENTS]: UserEmployments;
  [UserFields.EDUCATIONS]: UserEducations;
  [UserFields.CERTIFICATIONS]: UserCertifications;
  [UserFields.SKILL_IDS]: UserSkillIds;
  [UserFields.CREATED_AT]: UserCreatedAt;
  [UserFields.DEPARTMENT_ID]: MayBeUserDepartmentId;
  [UserFields.DEPARTMENT]: MayBeUserDepartment;
  [UserFields.PROVIDED_ROLE]: MayBeUserProvidedRole;
}

export const fetchUserColumns = [
  UserFields.ID,
  UserFields.UUID,
  UserFields.NANO_ID,
  UserFields.NAME,
  UserFields.FIRST_NAME,
  UserFields.LAST_NAME,
  UserFields.EMAIL,
  UserFields.PHONE,
  UserFields.PERSONAL_EMAIL,
  UserFields.COMPANY_PHONE,
  UserFields.COMPANY_EMAIL,
  UserFields.TITLE,
  UserFields.ZIP_CODE,
  UserFields.DESCRIPTION,
  UserFields.QUALIFICATIONS,
  UserFields.EMAIL_SIGNATURE,
  UserFields.EMAIL_SIGNATURE_ENABLED,
  UserFields.INDUSTRY_EXPERIENCE,
  UserFields.SENIORITY,
  // UserFields.HAS_SECRET_QUESTION1,
  // UserFields.HAS_SECRET_ANSWER1,
  // UserFields.HAS_SECRET_QUESTION2,
  // UserFields.HAS_SECRET_ANSWER2,
  UserFields.EMAIL_SYNC_ENABLED_AT,
  UserFields.EMAIL_SYNC_PROVIDER,
  UserFields.SCHEDULE_INTERVIEWS_ENABLED_AT,
  UserFields.SCHEDULE_INTERVIEWS_PROVIDER,
  UserFields.APPROVED_AT,
  UserFields.TIME_ZONE,
  UserFields.PROVIDED_ROLE,
  UserFields.IMAGE_ID,
  UserFields.ACCOUNT_TYPE_ID,
  UserFields.COMPANY_ID,
  UserFields.INDUSTRY_ID,
  UserFields.COUNTRY_ID,
  UserFields.CITY_ID,
  UserFields.STATE_ID,
  UserFields.CREATED_AT,
  UserFields.DEPARTMENT_ID
];

const fetchUserSBColumns = fetchUserColumns.map((fetchUserColumn) =>
  snakeCase(fetchUserColumn)
);

export const fetchUserQuery = `
  ${fetchUserSBColumns.join(',')},
  image:image_id(id,nano_id,name,file,key,created_at),
  city:city_id(id,name,label),
  state:state_id(id,nano_id,name,iso_code),
  company:company_id(id,name,image:image_id(id,nano_id,name,file,created_at))
` as FetchUserQuery;
