import { useCallback } from 'react';
import { FormControl, FormLabel, Stack } from '@chakra-ui/react';
import { Control, useFieldArray, UseFormSetValue } from 'react-hook-form';
import size from 'lodash/size';
import find from 'lodash/find';

import {
  EmploymentCurrently,
  EmploymentJobFunctionIds,
  EmploymentJobTitleId
} from '../../../../../../../../employments/employmentsTypes';

import { EditUserFormAddExperienceField } from '../EditUserFormAddExperienceField';

import { PlusIcon } from '../../../../../../../../../icons/PlusIcon';

import { Button } from '../../../../../../../../../helpers/Button';

import {
  AddEditUserFormData,
  ProfileEmploymentsProps
} from '../../../../AddEditUserForm.types';
import {
  UserEmployment,
  UserEmployments
} from '../../../../../../../usersTypes';
import { EditUserFormAddExperienceFieldCurrentEmployment } from '../EditUserFormAddExperienceField/EditUserFormAddExperienceField';

interface EditUserFormAddExperienceFieldsProps {
  control: Control<AddEditUserFormData>;
  label: string;
  errorMessages: { [key: string]: { message?: string } }[];
  user: {
    profileEmployments: UserEmployments;
  };
  currentEmployments: ProfileEmploymentsProps;
  setAddEditUserFormValue: UseFormSetValue<AddEditUserFormData>;
}

function EditUserFormAddExperienceFields({
  control,
  label,
  user,
  currentEmployments,
  setAddEditUserFormValue,
  errorMessages
}: EditUserFormAddExperienceFieldsProps) {
  const { fields, append, remove } = useFieldArray<
    AddEditUserFormData,
    'profileEmployments'
  >({
    control,
    name: 'profileEmployments'
  });

  const handleAddNew = useCallback<() => void>(
    () =>
      append(
        {
          startDate: null,
          endDate: null,
          cityId: null,
          companyId: null,
          jobFunctionIds: [] as EmploymentJobFunctionIds,
          jobTitleId: '' as EmploymentJobTitleId,
          currently: false as EmploymentCurrently
        },
        {
          shouldFocus: false
        }
      ),
    [append]
  );

  const handleRemoveItem = useCallback<(index: number) => void>(
    (index) => {
      if (fields.length > 1) {
        remove(index);
      }
    },
    [fields.length, remove]
  );

  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>

      <Stack spacing={3}>
        {fields.map((field, index) => (
          <EditUserFormAddExperienceField
            key={field.id}
            control={control}
            setAddEditUserFormValue={setAddEditUserFormValue}
            currentEmployment={
              find(
                currentEmployments,
                (emp, i) => i === index
              ) as EditUserFormAddExperienceFieldCurrentEmployment
            }
            employment={
              find(
                user.profileEmployments,
                (emp, i) => i === index
              ) as UserEmployment
            }
            name="profileEmployments"
            index={index}
            error={errorMessages?.[index]}
            onRemove={handleRemoveItem}
            withoutRemove={size(fields) === 1}
          />
        ))}
      </Stack>

      <Button
        mt={3}
        size="small"
        hierarchy="link"
        onClick={handleAddNew}
        leftIcon={<PlusIcon color="primary.500" w={4} h={4} mr={-1} />}
      >
        Add experience
      </Button>
    </FormControl>
  );
}

export default EditUserFormAddExperienceFields;
