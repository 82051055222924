import {
  MayBe,
  FieldBrand,
  ItemID,
  ItemIDs,
  ItemUUID,
  ItemNanoId,
  ItemsCount,
  ItemCreatedAt
} from '../../../types';

import { PermissionsAssociation } from '../../permissions/permissionsTypes';

import { UserIds } from '../../users/usersTypes';

export type RoleId = FieldBrand<ItemID, 'RoleId'>;
export type RoleIds = FieldBrand<ItemIDs, 'RoleIds'>;
export type RoleUuid = FieldBrand<ItemUUID, 'RoleUuid'>;
export type MayBeRoleUuid = MayBe<RoleUuid>;
export type RoleNanoId = FieldBrand<ItemNanoId, 'RoleNanoId'>;
export type MayBeRoleNanoId = MayBe<RoleNanoId>;
export type RoleName = FieldBrand<string, 'RoleName'>;
export type RoleUsersCount = FieldBrand<ItemsCount, 'RoleUsersCount'>;
export type RoleUserIds = FieldBrand<UserIds, 'RoleUserIds'>;
export type RolePermissionsCount = FieldBrand<
  ItemsCount,
  'RolePermissionsCount'
>;
export type RolePermissionIds = FieldBrand<UserIds, 'RolePermissionIds'>;
export type RolePermissions = FieldBrand<
  PermissionsAssociation,
  'RolePermissions'
>;
export type RoleCreatedAt = FieldBrand<ItemCreatedAt, 'RoleCreatedAt'>;

export type RoleAssociation = {
  id: RoleId;
  nanoId: RoleNanoId;
  name: RoleName;
  createdAt: RoleCreatedAt;
};

export type RolesAssociation = RoleAssociation[];

export const enum Roles {
  HIRING_MANAGER = 'hiring_manager',
  COLLABORATOR = 'collaborator',
  RECRUITER_ADMIN = 'recruiter_admin',
  RECRUITER_COORDINATOR = 'recruiter_coordinator',
  RECRUITER = 'recruiter',
  APPLICANT = 'applicant',
  CANDIDATE = 'candidate'
}

export const enum RoleFields {
  ID = 'id',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  NAME = 'name',
  PERMISSION_IDS = 'permissionIds',
  PERMISSIONS_COUNT = 'permissionsCount',
  USER_IDS = 'userIds',
  USERS_COUNT = 'usersCount',
  PERMISSIONS = 'permissions',
  CREATED_AT = 'createdAt'
}
