import {
  FetchSkillBadgesCacheKey,
  FetchSkillBadgeCacheKey,
  DeleteSkillBadgeErrorMessage,
  DeleteSkillBadgeIsLoading,
  DeleteSkillBadgeNanoId
} from '../../skillBadgesTypes';

import { useDeleteItem } from '../../../common/hooks/useDeleteItem';

import {
  SkillBadgesBffRequests,
  DeleteSkillBadgeErrorResponse
} from '../../SkillBadgesBffRequests';

interface DeleteSkillBadgeOptions {
  skillBadgeNanoId: DeleteSkillBadgeNanoId;
  cacheKeys?: (FetchSkillBadgesCacheKey | FetchSkillBadgeCacheKey)[];
}

function useDeleteSkillBadge({
  skillBadgeNanoId,
  cacheKeys = []
}: DeleteSkillBadgeOptions) {
  const {
    deleteItemData,
    deleteItemError,
    deleteItemErrorMessage,
    deleteItemIsLoading,
    deleteItem,
    deleteItemReset
  } = useDeleteItem<unknown, DeleteSkillBadgeErrorResponse>({
    mutationFn: () => SkillBadgesBffRequests.deleteSkillBadge(skillBadgeNanoId),
    cacheKeys
  });

  return {
    deleteSkillBadgeData: deleteItemData,
    deleteSkillBadgeError: deleteItemError,
    deleteSkillBadgeErrorMessage:
      deleteItemErrorMessage as DeleteSkillBadgeErrorMessage,
    deleteSkillBadgeIsLoading: deleteItemIsLoading as DeleteSkillBadgeIsLoading,
    deleteSkillBadge: deleteItem,
    deleteSkillBadgeReset: deleteItemReset
  };
}

export default useDeleteSkillBadge;
