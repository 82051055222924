import { useCallback } from 'react';
import { FormControl, FormLabel, Stack } from '@chakra-ui/react';
import { Control, useFieldArray } from 'react-hook-form';
import size from 'lodash/size';

import {
  EducationSchoolName,
  EducationDegree,
  EducationStudyField
} from '../../../../../../../../educations/educationsTypes';

import { EditUserFormAddEducationField } from '../EditUserFormAddEducationField';

import { PlusIcon } from '../../../../../../../../../icons/PlusIcon';

import { Button } from '../../../../../../../../../helpers/Button';

import { AddEditUserFormData } from '../../../../AddEditUserForm.types';

interface EditUserFormAddEducationFieldsProps {
  control: Control<AddEditUserFormData>;
  label: string;
  errorMessages: { [key: string]: { message?: string } }[];
}

function EditUserFormAddEducationFields({
  control,
  label,
  errorMessages
}: EditUserFormAddEducationFieldsProps) {
  const { fields, append, remove } = useFieldArray<
    AddEditUserFormData,
    'profileEducations'
  >({
    control,
    name: 'profileEducations'
  });

  const handleAddNew = useCallback<() => void>(
    () =>
      append(
        {
          schoolName: '' as EducationSchoolName,
          degree: '' as EducationDegree,
          studyField: '' as EducationStudyField,
          startDate: null,
          endDate: null
        },
        {
          shouldFocus: false
        }
      ),
    [append]
  );

  const handleRemoveItem = useCallback<(index: number) => void>(
    (index) => {
      if (fields.length > 1) {
        remove(index);
      }
    },
    [fields.length, remove]
  );

  // if (fields.length === 0) {
  //   handleAddNew();
  // }

  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>

      <Stack spacing={3}>
        {fields.map((field, index) => (
          <EditUserFormAddEducationField
            key={field.id}
            control={control}
            name="profileEducations"
            index={index}
            error={errorMessages?.[index]}
            onRemove={handleRemoveItem}
            withoutRemove={size(fields) === 1}
          />
        ))}
      </Stack>

      <Button
        mt={3}
        size="small"
        hierarchy="link"
        onClick={handleAddNew}
        leftIcon={<PlusIcon color="primary.500" w={4} h={4} mr={-1} />}
      >
        Add education
      </Button>
    </FormControl>
  );
}

export default EditUserFormAddEducationFields;
