import { Flex } from '@chakra-ui/layout';
import { Fade } from '@chakra-ui/transition';
import React, { useState } from 'react';
import { Button } from '../../../../../../../../helpers/Button';
import { CollapseFormBox } from '../../../../../../../../helpers/CollapseFormBox';
import { InputField } from '../../../../../../../../helpers/forms/formFields/InputField';
import { Select } from '../../../../../../../../helpers/Select';

const AddTask = () => {
  const [addTaskOpen, setAddTaskOpen] = useState(false);

  const toggleAddTaskOpen = () => setAddTaskOpen(!addTaskOpen);

  return (
    <Flex direction="column">
      <Flex ml="auto">
        <Fade in={!addTaskOpen}>
          <Button size="small" ml="auto" onClick={toggleAddTaskOpen}>
            Add new task
          </Button>
        </Fade>
      </Flex>

      <Flex mt={addTaskOpen ? -8 : 0} zIndex={1}>
        <CollapseFormBox
          title="Add Task"
          isOpen={addTaskOpen}
          onSave={() => toggleAddTaskOpen()}
          onCancel={toggleAddTaskOpen}
        >
          <InputField label="Task Name" />

          <Select label="Task type" id="task-type" />

          <Select label="Candidate" id="candidate" />

          <Select label="Application" id="application" />

          <Select label="Assign Task" id="assign-task" />

          <Select label="Due date" id="due-date" />
        </CollapseFormBox>
      </Flex>
    </Flex>
  );
};

export default AddTask;
