import { createContext, ReactNode, useContext, useState } from 'react';

interface ControlPanelContextProps {
  isAddJobOpened: boolean;
  isAddRecruiterOpened: boolean;
  isAddPersonOpened: boolean;
  toggleAddJob: () => void;
  toggleAddRecruiter: () => void;
  toggleAddPerson: () => void;
}

interface AppProviderProps {
  children: ReactNode;
}

const ControlPanelContext = createContext<ControlPanelContextProps>({
  isAddJobOpened: false,
  isAddRecruiterOpened: false,
  isAddPersonOpened: false,
  toggleAddJob: () => {
    /* do nothing */
  },
  toggleAddRecruiter: () => {
    /* do nothing */
  },
  toggleAddPerson: () => {
    /* do nothing */
  }
});

type menus = 'job' | 'recruiter' | 'person';

export const ControlPanelProvider = ({ children }: AppProviderProps) => {
  const [isOpenedMenu, setIsOpenedMenu] = useState<menus>();

  const toggleMenuOpen = (menu: menus) => {
    if (isOpenedMenu !== menu) setIsOpenedMenu(menu);
    else setIsOpenedMenu(undefined);
  };

  const toggleAddJob = () => {
    toggleMenuOpen('job');
  };

  const toggleAddRecruiter = () => {
    toggleMenuOpen('recruiter');
  };

  const toggleAddPerson = () => {
    toggleMenuOpen('person');
  };

  return (
    <ControlPanelContext.Provider
      value={{
        isAddJobOpened: isOpenedMenu === 'job',
        isAddRecruiterOpened: isOpenedMenu === 'recruiter',
        isAddPersonOpened: isOpenedMenu === 'person',
        toggleAddJob,
        toggleAddRecruiter,
        toggleAddPerson
      }}
    >
      {children}
    </ControlPanelContext.Provider>
  );
};

export const useControlPanelContext = () => useContext(ControlPanelContext);
