import { Fragment, useState } from 'react';
import {
  Box,
  DrawerBody,
  DrawerHeader,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs
} from '@chakra-ui/react';

import { FetchJobResponse } from '../../../../../../../jobs/queries/fetchJob.query';

import { ChevronLeftIcon } from '../../../../../../../../icons/ChevronLeftIcon';

import AddControlPanelJobDetails from '../AddControlPanelJobDetails/AddControlPanelJobDetails';
import AddControlPanelJobIntake from '../AddControlPanelJobIntake/AddControlPanelJobIntake';

import { Button } from '../../../../../../../../helpers/Button';
import { Heading } from '../../../../../../../../helpers/Heading';
import { Text } from '../../../../../../../../helpers/Text';

interface AddControlPanelJobDrawerContentProps {
  onClose: () => void;
}

const AddControlPanelJobDrawerContent = ({
  onClose
}: AddControlPanelJobDrawerContentProps) => {
  const [job, setJob] = useState<FetchJobResponse | null>(null);

  const setJobData = (job: FetchJobResponse) => {
    setJob(job);
  };

  const [tabIndex, setTabIndex] = useState(0);

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  return (
    <Fragment>
      <DrawerHeader px={8} pt={8} pb={4}>
        <Flex flexDir="column" alignItems="flex-start">
          <Flex mb={8}>
            <Button
              hierarchy="unstyled"
              onClick={onClose}
              display="flex"
              flexDirection="row"
              alignItems="center"
              h={6}
            >
              <ChevronLeftIcon />

              <Text color="#697275" textStyle="regular" fontSize="14px" ml={1}>
                Close
              </Text>
            </Button>
          </Flex>

          <Heading level="h2" w="100%">
            <Flex gap={2} w="100%" alignItems="center">
              <Box as="span" color="gray.600" textTransform="capitalize">
                Add Job
              </Box>
            </Flex>
          </Heading>
        </Flex>
      </DrawerHeader>

      <DrawerBody px={0} pt={0} pb={0}>
        <Tabs
          flex={1}
          h="100%"
          isLazy
          display="grid"
          gridTemplateRows="max-content 1fr"
          index={tabIndex}
          onChange={handleTabsChange}
        >
          <TabList
            bg="white"
            borderBottom="1px solid"
            borderBottomColor="gray.200"
            overflow="hidden"
            position="sticky"
            top={0}
            gap={7}
            px={8}
            zIndex={1}
          >
            <Tab mb="-1px" px="0" fontWeight="medium" color="gray.600">
              Job details
            </Tab>
            <Tab
              mb="-1px"
              px="0"
              fontWeight="medium"
              color="gray.600"
              isDisabled={!job?.nanoId}
            >
              Intake
            </Tab>
          </TabList>

          <TabPanels bg="gray.50" h="100%" overflow="hidden" p={6}>
            <TabPanel
              h="100%"
              overflow="auto"
              p={0}
              bg="white"
              borderRadius={4}
            >
              <Box flex={1} h="100%" p={8}>
                <AddControlPanelJobDetails
                  closeModal={onClose}
                  setJob={setJobData}
                  goToIntake={() => handleTabsChange(1)}
                />
              </Box>
            </TabPanel>

            <TabPanel
              h="100%"
              overflow="hidden"
              p={0}
              bg="white"
              borderRadius={4}
            >
              <Box flex={1} h="100%" p={8} overflowY="scroll">
                <AddControlPanelJobIntake
                  job={job as FetchJobResponse}
                  closeModal={onClose}
                />
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </DrawerBody>
    </Fragment>
  );
};

export default AddControlPanelJobDrawerContent;
