import {
  ReactHookFormFieldError,
  useReactHookForm
} from '../../../../../../common/hooks/base/useReactHookForm';

import {
  IndustryExpertiseExperience,
  IndustryExpertiseFields,
  IndustryExpertiseRequirements
} from '../../../../industryExpertisesTypes';

import { useAddEditIndustryExpertiseFormValidationRules } from '../useAddEditIndustryExpertiseFormValidationRules';

import {
  AddEditIndustryExpertiseFormData,
  OnAddEditIndustryExpertiseForm
} from '../../AddEditIndustryExpertiseForm.types';

const defaultAddEditIndustryExpertiseFormValues: AddEditIndustryExpertiseFormData =
  {
    experience: 'none' as IndustryExpertiseExperience,
    requirements: '' as IndustryExpertiseRequirements
  };

interface AddEditIndustryExpertiseFormOptions {
  defaultValues?: Partial<AddEditIndustryExpertiseFormData>;
  onAddEditIndustryExpertiseForm: OnAddEditIndustryExpertiseForm;
  isApplicantIndustryExpertiseForm?: boolean;
  isWorkHistoryScreen?: boolean;
  isIntakeForm?: boolean;
}

function useAddEditIndustryExpertiseForm({
  defaultValues = {},
  onAddEditIndustryExpertiseForm
}: AddEditIndustryExpertiseFormOptions) {
  const {
    control,
    watch,
    errors,
    isLoading,
    isValid,
    isSubmitted,
    isSubmitSuccessful,
    errorMessage,
    register,
    resetForm,
    handleSubmitReactHookForm,
    setValue
  } = useReactHookForm<AddEditIndustryExpertiseFormData>({
    defaultValues: {
      ...defaultAddEditIndustryExpertiseFormValues,
      ...defaultValues
    }
  });

  const { requirementsRules } =
    useAddEditIndustryExpertiseFormValidationRules();

  return {
    control,
    watch,
    validationErrors: {
      experienceValidationError: (errors.experience as ReactHookFormFieldError)
        ?.message,
      requirementsValidationError: (
        errors.requirements as ReactHookFormFieldError
      )?.message
    },
    addEditIndustryExpertiseFormIsLoading: isLoading,
    addEditIndustryExpertiseFormIsValid: isValid,
    addEditIndustryExpertiseFormIsSubmitted: isSubmitted,
    addEditIndustryExpertiseFormIsSubmitSuccessful: isSubmitSuccessful,
    addEditIndustryExpertiseFormErrorMessage: errorMessage,
    resetAddEditIndustryExpertiseForm: resetForm,
    setAddEditIndustryExpertiseFormValue: setValue,
    registerFields: {
      registerExperience: register(IndustryExpertiseFields.EXPERIENCE),
      registerRequirements: register(
        IndustryExpertiseFields.REQUIREMENTS,
        requirementsRules
      )
    },
    handleAddEditIndustryExpertiseForm: handleSubmitReactHookForm({
      onSubmit: (data) =>
        onAddEditIndustryExpertiseForm?.({
          experience: (data.experience ||
            'none') as IndustryExpertiseExperience,
          requirements: data.requirements as IndustryExpertiseRequirements
        })
    })
  };
}

export default useAddEditIndustryExpertiseForm;
