import { FetchEmploymentSkillBadgesCacheKey } from '../../employmentSkillBadgesTypes';

import { useCreateItem } from '../../../common/hooks/useCreateItem';

import {
  EmploymentSkillBadgesBffRequests,
  CreateEmploymentSkillBadgeRequestData,
  CreateEmploymentSkillBadgeErrorResponse
} from '../../EmploymentSkillBadgesBffRequests';

interface CreateEmploymentSkillBadgeOptions {
  cacheKeys?: FetchEmploymentSkillBadgesCacheKey[];
}

function useCreateEmploymentSkillBadge({
  cacheKeys = []
}: CreateEmploymentSkillBadgeOptions) {
  const {
    createItemData,
    createItemError,
    createItemErrorMessage,
    createItemIsLoading,
    createItem,
    createItemReset
  } = useCreateItem<
    CreateEmploymentSkillBadgeRequestData,
    CreateEmploymentSkillBadgeErrorResponse
  >({
    mutationFn: (queryInput) =>
      EmploymentSkillBadgesBffRequests.createEmploymentSkillBadge(queryInput),
    // EmploymentSkillBadgesRequests.createEmploymentSkillBadge(queryInput),
    cacheKeys
  });

  return {
    createEmploymentSkillBadgeData: createItemData,
    createEmploymentSkillBadgeError: createItemError,
    createEmploymentSkillBadgeErrorMessage: createItemErrorMessage,
    createEmploymentSkillBadgeIsLoading: createItemIsLoading,
    createEmploymentSkillBadge: createItem,
    createEmploymentSkillBadgeReset: createItemReset
  };
}

export default useCreateEmploymentSkillBadge;
