import snakeCase from 'lodash/snakeCase';

import {
  FetchCountriesQuery,
  CountryFields,
  CountryId,
  CountryNanoId,
  CountryName,
  CountryLabel,
  CountryCreatedAt
} from '../countriesTypes';

export interface FetchCountriesResponse {
  [CountryFields.ID]: CountryId;
  [CountryFields.NANO_ID]: CountryNanoId;
  [CountryFields.NAME]: CountryName;
  [CountryFields.LABEL]: CountryLabel;
  [CountryFields.CREATED_AT]: CountryCreatedAt;
}

export const fetchCountriesColumns = [
  CountryFields.ID,
  CountryFields.NANO_ID,
  CountryFields.NAME,
  CountryFields.LABEL,
  CountryFields.CREATED_AT
];

const fetchCountriesSBColumns = fetchCountriesColumns.map(
  (fetchCountriesColumn) => snakeCase(fetchCountriesColumn)
);

export const fetchCountriesQuery = fetchCountriesSBColumns.join(
  ','
) as FetchCountriesQuery;
