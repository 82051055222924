import {
  FieldBrand,
  ItemID,
  ItemIDs,
  ItemUUID,
  ItemNanoId,
  ItemCreatedAt,
  MayBe
} from '../../../types';

import {
  UserId,
  UserNanoId,
  UserName,
  UserFullName,
  UserEmail,
  UserImage,
  UserRoles
} from '../../users/usersTypes';

export type HtmlPageId = FieldBrand<ItemID, 'HtmlPageId'>;
export type HtmlPageIds = FieldBrand<ItemIDs, 'HtmlPageIds'>;
export type HtmlPageUuid = FieldBrand<ItemUUID, 'HtmlPageUuid'>;
export type MayBeHtmlPageUuid = MayBe<HtmlPageUuid>;
export type HtmlPageNanoId = FieldBrand<ItemNanoId, 'HtmlPageNanoId'>;
export type MayBeHtmlPageNanoId = MayBe<HtmlPageNanoId>;
export type HtmlPageName = FieldBrand<string, 'HtmlPageName'>;
export type HtmlPageTitle = FieldBrand<string, 'HtmlPageTitle'>;
export type HtmlPageBody = FieldBrand<string, 'HtmlPageBody'>;
export type HtmlPageUserId = FieldBrand<UserId, 'HtmlPageUserId'>;
export type MayBeHtmlPageUserId = MayBe<HtmlPageUserId>;
export type HtmlPageUser = FieldBrand<
  {
    id: UserId;
    nanoId: UserNanoId;
    name: UserName;
    fullName: UserFullName;
    email: UserEmail;
    image: UserImage;
    roles: UserRoles;
  },
  'HtmlPageUser'
>;
export type MayBeHtmlPageUser = MayBe<HtmlPageUser>;
export type HtmlPageCreatedAt = FieldBrand<ItemCreatedAt, 'HtmlPageCreatedAt'>;

export const enum HtmlPageFields {
  ID = 'id',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  NAME = 'name',
  TITLE = 'title',
  BODY = 'body',
  USER_ID = 'userId',
  USER = 'user',
  CREATED_AT = 'createdAt'
}
