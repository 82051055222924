import React, { FormEventHandler, ReactNode } from 'react';
import { Stack } from '@chakra-ui/layout';

import { ClassName, FormID, FormMethod } from '../../types';

import { FormRequiredProps } from './Form.types';

interface FormProps {
  className?: ClassName;
  id?: FormID;
  method?: FormMethod;
  as?: 'stack' | 'form';
  spacing?: number;
  children: ReactNode;
  onSubmit: FormEventHandler<HTMLDivElement | HTMLFormElement>;
}

function Form({
  formRef,
  className,
  id,
  method = 'POST' as FormMethod,
  as = 'stack',
  spacing = 6,
  children,
  onSubmit
}: FormProps & FormRequiredProps) {
  if (as === 'stack') {
    return (
      <Stack as="form" spacing={spacing} onSubmit={onSubmit} flex={1}>
        {children}
      </Stack>
    );
  }

  return (
    <form
      ref={formRef}
      id={id}
      className={className}
      method={method}
      onSubmit={onSubmit}
    >
      {children}
    </form>
  );
}

export default Form;
