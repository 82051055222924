import snakeCase from 'lodash/snakeCase';

import {
  FetchStatesQuery,
  StateFields,
  StateId,
  StateNanoId,
  StateName,
  StateLabel,
  StateCreatedAt
} from '../statesTypes';

export interface FetchStatesResponse {
  [StateFields.ID]: StateId;
  [StateFields.NANO_ID]: StateNanoId;
  [StateFields.NAME]: StateName;
  [StateFields.LABEL]: StateLabel;
  [StateFields.CREATED_AT]: StateCreatedAt;
}

export const fetchStatesColumns = [
  StateFields.ID,
  StateFields.NANO_ID,
  StateFields.NAME,
  StateFields.LABEL,
  StateFields.CREATED_AT
];

const fetchStatesSBColumns = fetchStatesColumns.map((fetchStatesColumn) =>
  snakeCase(fetchStatesColumn)
);

export const fetchStatesQuery = fetchStatesSBColumns.join(
  ','
) as FetchStatesQuery;
