import {
  Box,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Spinner,
  useDisclosure
} from '@chakra-ui/react';

import { Fragment, FunctionComponent, useCallback, useEffect } from 'react';

import { Text } from '../../../../../../helpers/Text';
import { useChakraToast } from '../../../../../../helpers/useChakraToast';
import { EditIcon } from '../../../../../../icons/EditIcon';
import { HeartIcon } from '../../../../../../icons/HeartIcon';
import { JobsIcon } from '../../../../../../icons/JobsIcon';

import { MoreIcon } from '../../../../../../icons/MoreIcon';
import { RemoveIcon } from '../../../../../../icons/RemoveIcon';
import { ShareIcon } from '../../../../../../icons/ShareIcon';
import { JobCandidateId } from '../../../../../jobCandidates/jobCandidatesTypes';
import { useCreateLikedJobCandidate } from '../../../../../likedJobCandidates/hooks/useCreateLikedJobCandidate';
import { LikedJobCandidatesCache } from '../../../../../likedJobCandidates/LikedJobCandidatesCache';
import {
  LikedJobCandidateJobCandidateId,
  LikedJobCandidateUserId
} from '../../../../../likedJobCandidates/likedJobCandidatesTypes';
import { UserId } from '../../../../../users/usersTypes';
import { AddCandidateToJob } from '../AddCandidateToJob';
import { useShareCandidatesProfileContext } from '../../../../../../context';
import { ShareCandidateProfileJobCandidate } from '../../../../../candidateShares/components/ShareCandidateProfile';
import { JobNanoId } from '../../../../jobsTypes';

interface ViewJobCandidateMenuProps {
  asPortal?: boolean;
  hasEditOption?: boolean;
  hasAddToJob?: boolean;
  hideRemoveProfile?: boolean;
  hideShareProfile?: boolean;
  handleEditCandidate?: () => void;
  jobCandidateId?: JobCandidateId;
  jobCandidate?: ShareCandidateProfileJobCandidate;
  shareJobCandidateJobNanoId?: JobNanoId;
  userId?: UserId;
  isCandidateLiked?: boolean;
  showProfile?: () => void;
  hideProfile?: () => void;
  handleRemoveProfile?: () => void;
}

function ViewJobCandidateMenu({
  asPortal = true,
  hasEditOption = false,
  hasAddToJob = false,
  hideRemoveProfile = false,
  hideShareProfile = false,
  handleEditCandidate,
  isCandidateLiked = false,
  jobCandidateId,
  jobCandidate,
  userId,
  showProfile,
  hideProfile,
  shareJobCandidateJobNanoId,
  handleRemoveProfile
}: ViewJobCandidateMenuProps) {
  const {
    createLikedJobCandidate,
    createLikedJobCandidateErrorMessage,
    createLikedJobCandidateIsLoading
  } = useCreateLikedJobCandidate({
    cacheKeys: [LikedJobCandidatesCache.userIndexCacheKey(userId as UserId)]
  });

  const {
    shareJobCandidate,
    isShareProfileOpened,
    setSharedJobCandidateJobNanoId
  } = useShareCandidatesProfileContext();

  const {
    isOpen: isOpenAddToJob,
    onOpen: onOpenAddToJob,
    onClose: onCloseAddToJob
  } = useDisclosure();

  const toast = useChakraToast();

  const handleLikeJobCandidate = useCallback(async () => {
    await createLikedJobCandidate({
      jobCandidateId: jobCandidateId as LikedJobCandidateJobCandidateId,
      userId: userId as LikedJobCandidateUserId
    });
  }, [createLikedJobCandidate, jobCandidateId, userId]);

  const handleShareCandidateProfile = useCallback(() => {
    shareJobCandidateJobNanoId &&
      setSharedJobCandidateJobNanoId(shareJobCandidateJobNanoId);
    jobCandidate && shareJobCandidate(jobCandidate);
  }, [
    jobCandidate,
    shareJobCandidate,
    shareJobCandidateJobNanoId,
    setSharedJobCandidateJobNanoId
  ]);

  useEffect(() => {
    createLikedJobCandidateErrorMessage &&
      toast({
        title: createLikedJobCandidateErrorMessage,
        status: 'error'
      });
  }, [createLikedJobCandidateErrorMessage, toast]);

  useEffect(() => {
    if (isShareProfileOpened) {
      showProfile && showProfile();
    } else {
      hideProfile && hideProfile();
    }
  }, [hideProfile, isShareProfileOpened, showProfile]);

  return (
    <Fragment>
      <AddCandidateToJob
        isOpen={isOpenAddToJob}
        onClose={onCloseAddToJob}
        onAddToJob={onCloseAddToJob}
      >
        <Box>
          <Menu isLazy placement="bottom-end" offset={[0, 3]}>
            <MenuButton
              as={IconButton}
              aria-label="More"
              variant="unstyled"
              color="gray.500"
              onClick={(e) => e.stopPropagation()}
            >
              <MoreIcon />
            </MenuButton>

            <Box as={asPortal ? Portal : undefined}>
              <MenuList
                maxWidth="170px"
                minWidth="170px"
                onClick={(e) => e.stopPropagation()}
                zIndex={4}
              >
                {hasEditOption && handleEditCandidate ? (
                  <ViewJobCandidateMenuItem
                    icon={EditIcon}
                    label="Edit Profile"
                    onClick={handleEditCandidate}
                  />
                ) : null}

                {hideShareProfile || !jobCandidate ? null : (
                  <ViewJobCandidateMenuItem
                    icon={ShareIcon}
                    label="Share Profile"
                    onClick={handleShareCandidateProfile}
                  />
                )}

                {hasAddToJob ? (
                  <ViewJobCandidateMenuItem
                    icon={JobsIcon}
                    label="Add to Job"
                    onClick={onOpenAddToJob}
                  />
                ) : null}

                {!isCandidateLiked && jobCandidateId && userId ? (
                  <ViewJobCandidateMenuItem
                    icon={HeartIcon}
                    label="Like Profile"
                    isLoading={createLikedJobCandidateIsLoading}
                    onClick={handleLikeJobCandidate}
                  />
                ) : null}

                {hideRemoveProfile ? null : (
                  <ViewJobCandidateMenuItem
                    icon={RemoveIcon}
                    label="Remove Profile"
                    onClick={handleRemoveProfile}
                  />
                )}
              </MenuList>
            </Box>
          </Menu>
        </Box>
      </AddCandidateToJob>
    </Fragment>
  );
}

interface ViewJobCandidatesHeaderMenuItemButtonProps {
  icon: FunctionComponent;
  label: string;
  onClick?: () => void;
  isLoading?: boolean;
}

const ViewJobCandidateMenuItem = ({
  icon,
  label,
  onClick,
  isLoading
}: ViewJobCandidatesHeaderMenuItemButtonProps) => {
  return (
    <MenuItem onClick={onClick}>
      <HStack spacing={1.5}>
        {isLoading ? <Spinner size="sm" /> : null}

        <Box as={icon} fill="gray.500" w={4} h={4} />

        <Text textStyle="body1Regular" color="gray.700">
          {label}
        </Text>
      </HStack>
    </MenuItem>
  );
};

export default ViewJobCandidateMenu;
