import {
  WorkflowStageTaskAutomation,
  WorkflowStageTaskAutomations
} from '../../workflowStageTasksTypes';

function renderWorkflowStageTaskAutomation(
  workflowStageTaskAutomation: WorkflowStageTaskAutomation
) {
  switch (workflowStageTaskAutomation) {
    case WorkflowStageTaskAutomations.SEND_EMAIL:
      return 'Send Email';
    case WorkflowStageTaskAutomations.SHARE_PROFILE:
      return 'Share Profile';
    case WorkflowStageTaskAutomations.REQUEST_RESUME:
      return 'Request Resume';
    case WorkflowStageTaskAutomations.SCHEDULE_MEETING:
      return 'Schedule Meeting';
    case WorkflowStageTaskAutomations.SCHEDULE_INTERVIEW:
      return 'Schedule Interview';
    case WorkflowStageTaskAutomations.RECRUITING_CALL:
      return 'Recruiting Call';
    case WorkflowStageTaskAutomations.SCREEN_CALL:
      return 'Screen Call';
    case WorkflowStageTaskAutomations.FOLLOW_UP_CALL:
      return 'Follow-up Call';
    case WorkflowStageTaskAutomations.DEBRIEF_CALL:
      return 'Debrief Call';
    case WorkflowStageTaskAutomations.SCHEDULING_CALL:
      return 'Scheduling Call';
    case WorkflowStageTaskAutomations.OFFER_APPROVAL:
      return 'Offer Approval';
    case WorkflowStageTaskAutomations.CALENDAR_OTHER:
    case WorkflowStageTaskAutomations.PHONE_OTHER:
    case WorkflowStageTaskAutomations.EMAIL_OTHER:
      return 'Other';
    default:
      return 'Unknown';
  }
}

export default renderWorkflowStageTaskAutomation;
