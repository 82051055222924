import React, { ReactNode } from 'react';
import { SystemProps } from '@chakra-ui/react';
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';

import {
  MultiSelectFieldControl,
  MultiSelectFieldControlOnInputChange,
  SelectOptions,
  SelectOptionType
} from './components/MultiSelectFieldControl';
import { ButtonHierarchyType } from '../../../Button';
import { MultiSelectFieldReactHookFormRules } from './MultiSelectField.types';

interface MultiSelectFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: FieldPath<T>;
  rules?: MultiSelectFieldReactHookFormRules<T>;
  label?: string;
  labelAddon?: ReactNode;
  placeholder?: string;
  placeholderColor?: string;
  errorMessage?: string | null;
  isDisabled?: boolean;
  isLoading?: boolean;
  withImages?: boolean;
  withAddNewButton?: boolean;
  addNewButtonLabel?: string;
  addNewButtonHierarchy?: ButtonHierarchyType;
  addNewButtonDisabled?: boolean;
  addNewButtonWithoutPlusIcon?: boolean;
  addNewButtonAction?: () => void;
  addNewButtonToValueAction?: () => Promise<
    SelectOptionType | SelectOptionType[] | undefined
  >;
  options: SelectOptions;
  defaultValue?: SelectOptionType[];
  isRequired?: boolean;
  onInputChange?: MultiSelectFieldControlOnInputChange;
  visibility?: SystemProps['visibility'];
  maxValues?: number;
  reactSelectStyles?: Record<string, unknown>;
  showDropdownIndicator?: boolean;
  showCounterAsValue?: boolean;
}

function MultiSelectField<T extends FieldValues>({
  control,
  name,
  rules,
  label,
  labelAddon,
  placeholder,
  placeholderColor,
  isDisabled,
  isLoading,
  withImages,
  options,
  defaultValue,
  errorMessage,
  isRequired,
  onInputChange,
  withAddNewButton,
  addNewButtonLabel,
  addNewButtonDisabled,
  addNewButtonHierarchy,
  addNewButtonWithoutPlusIcon,
  addNewButtonAction,
  addNewButtonToValueAction,
  visibility,
  maxValues,
  reactSelectStyles,
  showDropdownIndicator,
  showCounterAsValue
}: MultiSelectFieldProps<T>) {
  if (placeholderColor) {
    console.log('MultiSelectField', { placeholderColor });
  }
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value, ref } }) => (
        <MultiSelectFieldControl
          name={name}
          ref={ref}
          label={label}
          labelAddon={labelAddon}
          placeholder={placeholder}
          value={value}
          isDisabled={isDisabled}
          isLoading={isLoading}
          withImages={withImages}
          withAddNewButton={withAddNewButton}
          addNewButtonLabel={addNewButtonLabel}
          addNewButtonDisabled={addNewButtonDisabled}
          addNewButtonHierarchy={addNewButtonHierarchy}
          addNewButtonWithoutPlusIcon={addNewButtonWithoutPlusIcon}
          addNewButtonAction={addNewButtonAction}
          addNewButtonToValueAction={addNewButtonToValueAction}
          options={options}
          defaultValue={defaultValue}
          isRequired={isRequired}
          errorMessage={errorMessage}
          onInputChange={onInputChange}
          onChange={onChange}
          visibility={visibility}
          maxValues={maxValues}
          reactSelectStyles={reactSelectStyles}
          showDropdownIndicator={showDropdownIndicator}
          showCounterAsValue={showCounterAsValue}
        />
      )}
    />
  );
}

export default MultiSelectField;
