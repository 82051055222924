import { Avatar, Flex, HStack, Stack } from '@chakra-ui/react';
import { Heading } from '../../../../../../../../helpers/Heading';
import { Text } from '../../../../../../../../helpers/Text';
import { RecruitersIcon } from '../../../../../../../../icons/RecruitersIcon';
import { FormatNumber } from '../../../../../../../../utils/FormatNumber';

function ViewJobCandidateDetailsProfileOverviewAccess() {
  return (
    <Stack p={4} gap={3} flex={1} bg="white" borderRadius="base">
      <Flex gap={2} alignItems="center">
        <Heading
          m={0}
          p={0}
          level="h4"
          fontSize="md"
          lineHeight={1}
          fontWeight="semibold"
        >
          Hiring Team
        </Heading>
      </Flex>

      <Stack spacing={4}>
        <ProfileSharedWith
          name="Akshay Gore"
          connections={45}
          avatar="/images/default-avatar-4.png"
        />

        <ProfileSharedWith
          name="Akshay Gore"
          connections={45}
          avatar="/images/default-avatar-4.png"
          hasViewedProfile
        />

        <ProfileSharedWith
          name="Curtis Fletcher"
          connections={1200}
          avatar="/images/default-avatar-7.png"
          hasViewedProfile
        />

        <ProfileSharedWith
          name="Alice Stone"
          connections={2500}
          avatar="/images/default-avatar-5.png"
        />

        <ProfileSharedWith
          name="Darrell Barnes"
          connections={200}
          avatar="/images/default-avatar-1.png"
        />
      </Stack>
    </Stack>
  );
}

export default ViewJobCandidateDetailsProfileOverviewAccess;

interface ProfileSharedWithProps {
  name: string;
  avatar: string;
  connections: number;
  hasViewedProfile?: boolean;
}

const ProfileSharedWith = ({
  name,
  avatar,
  connections,
  hasViewedProfile
}: ProfileSharedWithProps) => {
  return (
    <HStack>
      <Avatar name={name} src={avatar} size="md" w={10} h={10} />

      <Stack spacing={0} flex={1}>
        <Text textStyle="body1Medium" lineHeight={1.5}>
          {name}
        </Text>

        <Text textStyle="body1Regular" lineHeight={1.5} color="gray.600">
          {FormatNumber.shortNumber(connections)} connections
        </Text>
      </Stack>

      <Flex
        w={8}
        h={8}
        bg={hasViewedProfile ? 'blue.500' : 'blue.100'}
        alignItems="center"
        borderRadius="full"
        justifyContent="center"
        transitionDuration="slow"
      >
        <RecruitersIcon
          w={4}
          h={4}
          fill={hasViewedProfile ? 'white' : 'blue.500'}
          transitionDuration="slow"
        />
      </Flex>
    </HStack>
  );
};
