import { useQuery } from 'react-query';

import {
  FetchCurrentUserIndexResponse,
  fetchCurrentUserIndexQueryName
} from '../../../main/users/queries/fetchCurrentUserIndex.query';

import { FetchCurrentUserError } from '../../AuthRequests';

import { AuthCache } from '../../AuthCache';

import { UsersBffRequests } from '../../../main/users/UsersBffRequests';

import { parseRequestError } from '../../../utils/parseRequestError';

interface FetchCurrentUserOptions {
  placeholderCurrentUser?: FetchCurrentUserIndexResponse;
  enabled?: boolean;
  onSuccess?: (currentUser: FetchCurrentUserIndexResponse | null) => void;
  onError?: (err: FetchCurrentUserError) => void;
}

function useFetchCurrentUser({
  placeholderCurrentUser,
  enabled,
  onSuccess,
  onError
}: FetchCurrentUserOptions) {
  const {
    data,
    error,
    isFetched,
    isRefetching,
    isLoading,
    isPlaceholderData,
    refetch
  } = useQuery<FetchCurrentUserIndexResponse | null, FetchCurrentUserError>(
    AuthCache.currentUserKey(),
    () =>
      UsersBffRequests.fetchCurrentUser<FetchCurrentUserIndexResponse>({
        queryName: fetchCurrentUserIndexQueryName
      }),
    // () => AuthRequests.currentUser({ currentUserUuid }),
    {
      placeholderData: placeholderCurrentUser,
      enabled,
      onSuccess,
      onError
    }
  );

  return {
    currentUser: data,
    currentUserError: error,
    currentUserErrorMessage: parseRequestError(error),
    currentUserIsFetched: isFetched,
    currentUserIsRefetching: isRefetching,
    currentUserIsLoading: isLoading,
    currentUserIsPlaceholderData: isPlaceholderData,
    currentUserRefetch: () => refetch()
  };
}

export default useFetchCurrentUser;
