import { Flex, Stack } from '@chakra-ui/react';
import React from 'react';
import { InputField } from '../../../../../../../helpers/forms/formFields/InputField';
import { AuthFields } from '../../../../../../../auth/authTypes';
import { PasswordInputField } from '../../../../../../../helpers/forms/formFields/PasswordInputField';
import { CheckboxField } from '../../../../../../../helpers/forms/formFields/CheckboxField';
import { HtmlPageModalLink } from '../../../../../../htmlPages/components/modals/HtmlPageModalLink';
import {
  TERMS_PAGE_NANO_ID,
  PRIVACY_POLICY_PAGE_NANO_ID
} from '../../../../../../../config';
import { HtmlPageNanoId } from '../../../../../../htmlPages/htmlPagesTypes';
import { Button } from '../../../../../../../helpers/Button';
import { useRecruiterContext } from '../../../../../../../context';

const RecruiterSignUpModalSignUpForm = () => {
  const { toggleRecruiterSignUpModal, toggleRecruiterConfirmEmailModal } =
    useRecruiterContext();

  return (
    <Stack spacing={6}>
      <InputField
        label="Name"
        placeholder="Enter Name"
        name={AuthFields.NAME}
      />

      <InputField
        label="Personal email address"
        placeholder="Personal email address"
        type="email"
        name={AuthFields.EMAIL}
      />

      <PasswordInputField
        label="Password"
        placeholder="• • • • • • • • • •"
        name={AuthFields.PASSWORD}
      />

      <PasswordInputField
        label="Re-enter password"
        placeholder="• • • • • • • • • •"
        name={AuthFields.PASSWORD_CONFIRMATION}
      />

      <Flex justifyContent="center" flexDir="column">
        <CheckboxField size="small" name={AuthFields.POLICY}>
          I accept the{' '}
          <HtmlPageModalLink
            htmlPageNanoId={TERMS_PAGE_NANO_ID as HtmlPageNanoId}
            i18nText="terms"
          />{' '}
          and{' '}
          <HtmlPageModalLink
            htmlPageNanoId={PRIVACY_POLICY_PAGE_NANO_ID as HtmlPageNanoId}
            i18nText="privacy policy"
          />
        </CheckboxField>
      </Flex>

      <Button
        onClick={() => {
          toggleRecruiterSignUpModal();
          toggleRecruiterConfirmEmailModal();
        }}
      >
        Continue
      </Button>
    </Stack>
  );
};

export default RecruiterSignUpModalSignUpForm;
