import { Icon, IconProps } from '@chakra-ui/icon';

const ChevronLeftIcon = ({
  w = '20px',
  h = '20px',
  fill = '#98A5A9',
  ...props
}: IconProps) => (
  <Icon viewBox="0 0 20 20" w={w} h={h} fill={fill} {...props}>
    <path d="M6.60039 10.0004L12.0004 15.4004L13.4004 14.0004L9.40039 10.0004L13.4004 6.00039L12.0004 4.60039L6.60039 10.0004Z" />
  </Icon>
);

export default ChevronLeftIcon;
