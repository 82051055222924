import React, { ReactNode } from 'react';
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';

import { ErrorMessage, IsDisabled } from '../../../../types';

import { TinyMceFieldControl } from './components/TinyMceFieldControl';

interface TinyMceFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: FieldPath<T>;
  placeholder?: string;
  defaultValue?: string;
  errorMessage?: ErrorMessage;
  isDisabled?: IsDisabled;
  isRequired?: boolean;
  asPlainText?: boolean;
  label?: string;
  labelAddon?: ReactNode;
  isGrayBg?: boolean;
}

function TinyMceField<T extends FieldValues>({
  control,
  name,
  placeholder,
  defaultValue,
  isDisabled,
  isRequired,
  asPlainText,
  errorMessage,
  label,
  labelAddon,
  isGrayBg
}: TinyMceFieldProps<T>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <TinyMceFieldControl
          name={name}
          placeholder={placeholder}
          defaultValue={defaultValue}
          value={value as string}
          isDisabled={isDisabled}
          isRequired={isRequired}
          asPlainText={asPlainText}
          errorMessage={errorMessage}
          onChange={onChange}
          label={label}
          labelAddon={labelAddon}
          isGrayBg={isGrayBg}
        />
      )}
    />
  );
}

export default TinyMceField;
