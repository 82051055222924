import { Icon, IconProps } from '@chakra-ui/icon';

const CalendarButtonIcon = ({
  w = '40px',
  h = '40px',
  ...props
}: IconProps) => (
  <Icon viewBox="0 0 40 40" w={w} h={h} {...props}>
    <circle cx={20} cy={20} r={20} fill="#6872E5" />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M29.002 13.468a1 1 0 0 0-1-.999h-2.65a.1.1 0 0 1-.1-.1v-.619a.75.75 0 1 0-1.5 0v.62a.1.1 0 0 1-.1.1H20.85a.1.1 0 0 1-.1-.1v-.62a.75.75 0 1 0-1.5 0v.62a.1.1 0 0 1-.1.1h-2.8a.1.1 0 0 1-.1-.1v-.62a.75.75 0 0 0-1.5 0v.62a.1.1 0 0 1-.1.1H12a1 1 0 0 0-1 1v.938a1 1 0 0 0 .999 1h16.002a1 1 0 0 0 1-1v-.94ZM26 21.454c0 .311-.252.563-.562.563h-1.875a.563.563 0 0 1-.563-.563v-1.813c0-.311.252-.563.563-.563h1.875c.31 0 .562.252.562.563v1.813Zm-4.5 0c0 .311-.252.563-.562.563h-1.875a.563.563 0 0 1-.563-.563v-1.813c0-.311.252-.563.563-.563h1.875c.31 0 .562.252.562.563v1.813Zm0 4.407c0 .31-.252.562-.562.562h-1.875a.563.563 0 0 1-.563-.562v-1.814c0-.31.252-.563.563-.563h1.875c.31 0 .562.252.562.563v1.814ZM17 21.454c0 .311-.252.563-.562.563h-1.875a.563.563 0 0 1-.563-.563v-1.813c0-.311.252-.563.563-.563h1.875c.31 0 .562.252.562.563v1.813Zm0 4.407c0 .31-.252.562-.562.562h-1.875a.563.563 0 0 1-.563-.562v-1.814c0-.31.252-.563.563-.563h1.875c.31 0 .562.252.562.563v1.814Zm-5-8.986a1 1 0 0 0-1 1v8.873a2.248 2.248 0 0 0 2.248 2.25h13.508A2.247 2.247 0 0 0 29 26.748v-8.873a1 1 0 0 0-1-1H12Z"
      clipRule="evenodd"
    />
  </Icon>
);

export default CalendarButtonIcon;
