import {
  extendTheme,
  ThemeConfig,
  ThemeDirection,
  withDefaultColorScheme
} from '@chakra-ui/react';
import { withProse } from '@nikolovlazar/chakra-ui-prose';

import components from './components';
import foundations from './foundations';
import styles from './styles';

const direction: ThemeDirection = 'ltr';
const {
  Heading: { sizes: HeaderSizes }
} = components;

const config: ThemeConfig = {
  useSystemColorMode: false,
  initialColorMode: 'light',
  cssVarPrefix: 'nxmoov'
};

export type TextStyle =
  | 'regular'
  | 'medium'
  | 'semiBold'
  | 'body1Regular'
  | 'body1Medium'
  | 'body2Regular'
  | 'body2Medium'
  | 'upperCase1Regular'
  | 'upperCase1Medium';

export const theme = extendTheme(
  {
    direction,
    ...foundations,
    components,
    styles,
    config
  },
  withDefaultColorScheme({ colorScheme: 'primary' }),
  withProse({
    baseStyle: {
      ...HeaderSizes,
      p: {
        fontSize: '14px',
        fontWeight: '400',
        marginTop: 0,
        ':last-child': {
          marginBottom: 0
        }
      }
    }
  })
);

export default theme;
