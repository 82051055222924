import { ButtonGroup, Flex } from '@chakra-ui/react';
import { useViewJobCandidateDetailsSendAssessmentContext } from '../../../../contexts/ViewJobCandidateDetailsSendAssessmentContext';
import { useLeftSidebarContext } from '../../../../../../../../context';
import { Button } from '../../../../../../../../helpers/Button';
import { ChevronLeftIcon } from '../../../../../../../../icons/ChevronLeftIcon';

export default function ViewJobCandidateDetailsAssessmentNav() {
  const { isDocked } = useLeftSidebarContext();

  const {
    isLastStep,
    isFirstStep,
    goToPrevStep,
    goToNextStep,
    sendEmailAssessment,
    closeSendEmailAssessment
  } = useViewJobCandidateDetailsSendAssessmentContext();

  return (
    <Flex
      h={16}
      py={3}
      gap={6}
      left={0}
      w="full"
      bottom={0}
      bg="white"
      pos="fixed"
      zIndex={99}
      borderTop="1px solid"
      borderTopColor="gray.200"
      transitionDuration="fast"
      justifyContent="space-between"
      pl={{ lg: isDocked ? 20 : 60 }}
    >
      <Flex flex={1} pl={{ base: 4, xl: 12 }}>
        <Button
          size="medium"
          px={8}
          hierarchy="secondary"
          onClick={closeSendEmailAssessment}
        >
          Cancel
        </Button>

        <ButtonGroup w="400px" ml="auto" justifyContent="space-between" px={6}>
          <Button
            size="medium"
            color="gray.800"
            hierarchy="link"
            isDisabled={isFirstStep}
            leftIcon={<ChevronLeftIcon />}
            onClick={goToPrevStep}
          >
            Back
          </Button>

          {isLastStep ? (
            <Button size="medium" px={8} onClick={sendEmailAssessment}>
              Send
            </Button>
          ) : (
            <Button size="medium" px={8} onClick={goToNextStep}>
              Continue
            </Button>
          )}
        </ButtonGroup>
      </Flex>
    </Flex>
  );
}
