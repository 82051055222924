import { Icon, IconProps } from '@chakra-ui/icon';

const PlusIcon = ({ w = '20px', h = '20px', ...props }: IconProps) => (
  <Icon viewBox="0 0 20 20" w={w} h={h} fill="currentColor" {...props}>
    <path d="M9.045 10.955V3.318h1.98v5.657h5.657v1.98H9.045Z" />
    <path d="M10.955 9.045H3.318v1.98h5.657v5.657h1.98V9.045Z" />
  </Icon>
);

export default PlusIcon;
