import { Icon, IconProps } from '@chakra-ui/icon';

const AddLeadIcon = ({ w = '105px', h = '122px', ...props }: IconProps) => (
  <Icon viewBox="0 0 105 122" fill="none" w={w} h={h} {...props}>
    <path
      d="M105 52.5c0 12.835-4.574 24.51-12.22 33.657C83.153 97.695 68.68 105 52.432 105c-15.566 0-29.493-6.759-39.12-17.477A52.564 52.564 0 0 1 0 52.5C0 23.485 23.485 0 52.5 0S105 23.485 105 52.5Z"
      fill="#E1EAED"
    />
    <path
      d="M92.858 86.154c-9.627 11.538-24.1 18.843-40.348 18.843-15.566 0-29.493-6.759-39.12-17.477 1.64-1.298 3.687-2.458 6.213-3.482l1.912-.751c3.618-1.434 8.329-3.209 14.678-5.872 1.434-.614 2.594-1.706 3.209-3.14a6.209 6.209 0 0 0 .546-2.526V59.94c-.205-.274-.41-.479-.546-.752A11.363 11.363 0 0 1 37.49 54l-1.024-.478c-3.072.75-2.867-2.526-3.618-8.807-.342-2.594.068-3.14 1.229-3.55l.955-1.297c-5.87-13.313-2.935-21.642 2.868-24.578-1.912-4.232-2.048-5.734-2.048-5.734s12.357 2.048 16.658 1.229c5.393-1.093 13.722.204 16.794 7.373 5.12 2.048 7.168 5.257 7.578 8.807.751 5.598-2.458 11.742-3.072 14.132.068.136.136.205.136.273.82.41 1.093 1.092.82 3.345-.751 5.94-.546 9.626-3.619 8.807l-2.73 4.779c-.137.546-.137.75-.205 1.16-.137.888-.068 2.663-.068 12.426 0 1.16.34 2.253.955 3.277.683 1.024 1.639 1.911 2.868 2.39 0 0 .068 0 .136.067 7.032 2.936 12.084 4.848 15.77 6.281 1.912.615 3.55 1.434 4.984 2.253Z"
      fill="#fff"
    />
    <path
      d="M73.95 41.027c.274-1.502-.41-3.345-.955-4.642-.069-.205-.205-.41-.273-.683-2.39-4.779-7.647-6.69-12.699-6.963-12.903-.683-14.063 1.775-18.091-1.912 1.229 1.912 1.365 4.847-.547 8.534-1.365 2.594-3.96 3.755-6.212 4.506-5.871-13.313-2.936-21.642 2.867-24.578-1.911-4.232-2.048-5.734-2.048-5.734s12.357 2.048 16.59 1.229c5.393-1.093 13.722.204 16.794 7.373 5.12 2.048 7.1 5.257 7.578 8.807.751 5.53-2.389 11.606-3.003 14.063Z"
      fill="url(#a)"
    />
    <path
      d="M69.233 75.037v2.321H39.398v-3.004a6.207 6.207 0 0 0 .547-2.526V59.95c-.205-.273-.41-.478-.547-.75v-.683c.615 1.092 1.434 2.116 2.458 2.935l9.353 6.486a5.97 5.97 0 0 0 7.578.136l8.739-7.85c.273-.274.614-.547.887-.82-.136.888-.068 2.663-.068 12.425a5.19 5.19 0 0 0 .888 3.21Z"
      fill="url(#b)"
    />
    <path
      d="M73.355 106.291c-.397 1.684-.991 3.467-1.685 4.953-1.882 3.666-4.854 6.539-8.52 8.422-3.765 1.882-8.223 2.675-12.681 1.684-10.502-2.18-17.24-12.483-15.06-22.985 2.18-10.502 12.385-17.338 22.886-15.06 3.765.793 7.035 2.676 9.809 5.252 4.656 4.656 6.638 11.393 5.25 17.734Z"
      fill="url(#c)"
    />
    <path
      d="M60.563 100.643h-4.458v-4.459A1.67 1.67 0 0 0 54.42 94.5a1.67 1.67 0 0 0-1.685 1.684v4.459h-4.458a1.67 1.67 0 0 0-1.684 1.684c0 .991.693 1.684 1.684 1.684h4.458v4.458a1.67 1.67 0 0 0 1.684 1.685 1.67 1.67 0 0 0 1.685-1.685v-4.458h4.458a1.67 1.67 0 0 0 1.684-1.684 1.67 1.67 0 0 0-1.684-1.684Z"
      fill="#fff"
    />
    <defs>
      <linearGradient
        id="a"
        x1={31.948}
        y1={25.294}
        x2={77.078}
        y2={25.294}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#97A5A8" />
        <stop offset={1} stopColor="#96A9AE" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={54.325}
        y1={77.473}
        x2={54.325}
        y2={67.093}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#E2E5EC" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={34.982}
        y1={102.327}
        x2={73.796}
        y2={102.327}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#97A5A8" />
        <stop offset={1} stopColor="#96A9AE" />
      </linearGradient>
    </defs>
  </Icon>
);

export default AddLeadIcon;
