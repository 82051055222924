import compact from 'lodash/compact';

import { APP_HOST } from '../config';

export class MainRoutes {
  static index(options = { host: false }) {
    return options.host ? APP_HOST : '/';
  }

  static settings(options = { host: false }) {
    return options.host ? APP_HOST : '/settings';
  }

  static redirect(options = { host: false }) {
    return compact<string>([options.host ? APP_HOST : null, '/redirect']).join(
      ''
    );
  }

  static requestAccess() {
    return '/request-access';
  }

  static requestAccessSuccess() {
    return '/request-access/success';
  }
}
