import {
  Avatar,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack
} from '@chakra-ui/react';
import { IconButton } from '../../../../../../../../../../../helpers/buttons/IconButton';
import { Text } from '../../../../../../../../../../../helpers/Text';
import { ChevronDownIcon } from '../../../../../../../../../../../icons/ChevronDownIcon';
import { ViewJobCandidateModalPhoneSidebarEnterNoteForm } from '../../components/ViewJobCandidateModalPhoneSidebarEnterNoteForm';

const ViewJobCandidateModalPhoneSidebarAssignTask = ({
  onDiscard,
  onSave
}: {
  onDiscard: () => void;
  onSave: () => void;
}) => {
  return (
    <Stack spacing={4}>
      <Flex alignItems="center" gap={2} justifyContent="space-between">
        <Text textStyle="body1Medium" flex={1}>
          Task:
        </Text>

        <Flex alignItems="center" gap={1}>
          <Text textStyle="body1Regular" noOfLines={1}>
            Introduction call?
          </Text>

          <Menu isLazy placement="bottom-end">
            <MenuButton
              p={0}
              h={6}
              minH={0}
              minW={0}
              size="small"
              border="none"
              as={IconButton}
              boxShadow="none"
              hierarchy="unstyled"
              aria-label="Dropdown"
              icon={<ChevronDownIcon fill="gray.500" />}
            />
            <MenuList>
              <MenuItem>Screening Call</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>

      <Flex alignItems="center" gap={2} justifyContent="space-between">
        <Text textStyle="body1Medium" flex={1}>
          Assign to:
        </Text>

        <Flex alignItems="center" gap={1}>
          <Flex gap={2}>
            <Avatar size="xs" src="/images/team-mate-6.png" />

            <Text textStyle="body1Regular" noOfLines={1}>
              Barnabus Stinson
            </Text>
          </Flex>

          <Menu isLazy placement="bottom-end">
            <MenuButton
              p={0}
              h={6}
              minH={0}
              minW={0}
              size="small"
              border="none"
              as={IconButton}
              boxShadow="none"
              hierarchy="unstyled"
              aria-label="Dropdown"
              icon={<ChevronDownIcon fill="gray.500" />}
            />
            <MenuList>
              <MenuItem>Screening Call</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>

      <ViewJobCandidateModalPhoneSidebarEnterNoteForm
        onDiscard={onDiscard}
        onSave={onSave}
      />
    </Stack>
  );
};

export default ViewJobCandidateModalPhoneSidebarAssignTask;
