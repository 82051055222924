import { Icon, IconProps } from '@chakra-ui/icon';

const ReferencesIcon = ({ w = '16px', h = '16px', ...props }: IconProps) => (
  <Icon viewBox="0 0 16 16" w={w} h={h} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.951 4.231C2.951 3.176 3.935 2 4.832 2c0 0 .732 0 1.248.598a.162.162 0 01-.042.248c-.401.24-1.206.72-1.206 2.859 0 .788.537 1.855.855 2.417a.518.518 0 01-.122.66l-.733.581c-.699.556-1.039.83-1.349 1.266-.173.244-.443.41-.743.41h-.573c-.112 0-.229-.016-.313-.092-.328-.295-.388-1.02-.388-1.493 0-.55.64-1.437 2.384-2.488.142-.06.142-.393 0-.543-.794-.69-.899-1.439-.899-2.192zm8.505 1.302c0-1.56-1.201-2.783-2.664-2.783-1.464 0-2.664 1.27-2.664 2.783 0 1.481.443 2.019 1.01 2.704l.035.043c.144.174.372.636 0 .934-.286.22-.563.425-.825.62-1.278.946-2.218 1.642-2.218 2.665 0 .862.229 1.21 1.116 1.475a.617.617 0 00.178.025h6.632c.08 0 .16-.011.238-.036 1.16-.371 1.16-.778 1.16-1.464 0-1-.972-1.729-3.093-3.248a.352.352 0 01-.093-.087c-.142-.216-.129-.698.04-.884.73-.697 1.148-1.186 1.148-2.747z"
      fill="currentColor"
    />
  </Icon>
);
export default ReferencesIcon;
