import { JobWorkAuthorization } from '../../jobsTypes';

import { workAuthorizationOptionsHash } from '../../jobs.data';
import { TalentPoolWorkAuthorization } from '../../../talentPools/talentPoolsTypes';

function renderJobWorkAuthorization(
  workAuthorization: JobWorkAuthorization | TalentPoolWorkAuthorization
) {
  return workAuthorizationOptionsHash[workAuthorization];
}

export default renderJobWorkAuthorization;
