import snakeCase from 'lodash/snakeCase';

import {
  FetchTalentPoolJobCandidatesQuery,
  TalentPoolJobCandidateFields,
  TalentPoolJobCandidateId,
  TalentPoolJobCandidateCreatedAt,
  TalentPoolJobCandidateJobCandidate,
  TalentPoolJobCandidateTalentPoolId,
  TalentPoolJobCandidateJobCandidateId
} from '../talentPoolJobCandidatesTypes';

export interface FetchTalentPoolJobCandidatesResponse {
  [TalentPoolJobCandidateFields.ID]: TalentPoolJobCandidateId;
  [TalentPoolJobCandidateFields.CREATED_AT]: TalentPoolJobCandidateCreatedAt;
  [TalentPoolJobCandidateFields.TALENT_POOL_ID]: TalentPoolJobCandidateTalentPoolId;
  [TalentPoolJobCandidateFields.JOB_CANDIDATE_ID]: TalentPoolJobCandidateJobCandidateId;
  [TalentPoolJobCandidateFields.JOB_CANDIDATE]: TalentPoolJobCandidateJobCandidate;
}

export const fetchTalentPoolJobCandidatesColumns = [
  TalentPoolJobCandidateFields.ID,
  TalentPoolJobCandidateFields.CREATED_AT,
  TalentPoolJobCandidateFields.TALENT_POOL_ID,
  TalentPoolJobCandidateFields.JOB_CANDIDATE_ID
];

const fetchTalentPoolJobCandidatesSBColumns =
  fetchTalentPoolJobCandidatesColumns.map(
    (fetchTalentPoolJobCandidatesColumn) =>
      snakeCase(fetchTalentPoolJobCandidatesColumn)
  );

export const fetchTalentPoolJobCandidatesQuery = `
  ${fetchTalentPoolJobCandidatesSBColumns.join(',')},
  job_candidate:job_candidate_id(id,nano_id,name,updatedAt,job_title,city_id,company:company_id(id,nano_id,name),image:image_id(id,nano_id,name,file,key,created_at),city:city_id(id,nano_id,name,label),city_ids:cities!job_candidate_cities(id),cities!job_candidate_cities(id, label))
  ` as FetchTalentPoolJobCandidatesQuery;
