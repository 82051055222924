import { FieldBrand, ItemRecordID } from '../../../types';

export type SkillBadgeRecordId = FieldBrand<ItemRecordID, 'SkillBadgeRecordId'>;

export const enum SkillBadgeIndexFields {
  ID = 'id',
  RECORD_ID = 'recordId',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  RATING = 'rating',
  EXPERIENCE = 'experience',
  CERTIFIED = 'certified',
  REQUIREMENTS = 'requirements',
  EXPERIENCE_TYPE = 'experienceType',

  USER_ID = 'userId',

  JOB_ID = 'jobId',

  JOB_CANDIDATE_ID = 'jobCandidateId',
  JOB_RESUME_ID = 'jobResumeId',
  JOB_RESUME_RECRUITER_ID = 'jobResumeRecruiterId',

  SKILL_ID = 'skillId',
  SKILL = 'skill',
  SKILL_RECORD_ID = 'skill.recordId',
  SKILL_NANO_ID = 'skill.nanoId',
  SKILL_NAME = 'skill.name',
  SKILL_KIND = 'skill.kind',
  SKILL_SUGGESTED = 'skill.suggested',
  SKILL_SKILL_TYPE = 'skill.skillType',

  SKILL_LICENSE_ID = 'skillLicenseId',
  SKILL_LICENSE = 'skillLicense',
  SKILL_LICENSE_NANO_ID = 'skillLicense.nanoId',
  SKILL_LICENSE_NAME = 'skillLicense.name',

  CREATED_AT = 'createdAt'
}
