import { FieldBrand, ItemRecordID } from '../../../types';

import { UserUuid } from '../../users/usersTypes';

export type CityRecordId = FieldBrand<ItemRecordID, 'CityRecordId'>;

export type CityUserUuid = FieldBrand<UserUuid, 'CityUserUuid'>;

export const enum CityIndexFields {
  ID = 'id',
  RECORD_ID = 'recordId',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  CREATED_AT = 'createdAt',

  USER_UUID = 'userUuid'
}
