import {
  FetchCandidateSharesCacheKey,
  FetchCandidateShareCacheKey,
  SendEmailsCandidateShareNanoId,
  SendEmailsCandidateShareErrorMessage,
  SendEmailsCandidateShareIsLoading,
  SendEmailsCandidateShareID
} from '../../candidateSharesTypes';

import {
  CandidateSharesBffRequests,
  SendEmailsCandidateShareErrorResponse
} from '../../CandidateSharesBffRequests';

import { usePostItem } from '../../../common/hooks/usePostItem';

interface SendEmailsCandidateShareOptions {
  candidateShareId: SendEmailsCandidateShareNanoId | SendEmailsCandidateShareID;
  cacheKeys?: (FetchCandidateSharesCacheKey | FetchCandidateShareCacheKey)[];
}

function useSendEmailsCandidateShare({
  candidateShareId,
  cacheKeys = []
}: SendEmailsCandidateShareOptions) {
  const {
    postItemData,
    postItemError,
    postItemErrorMessage,
    postItemIsLoading,
    postItem,
    postItemReset
  } = usePostItem<unknown, SendEmailsCandidateShareErrorResponse>({
    mutationFn: () =>
      CandidateSharesBffRequests.sendEmailsCandidateShare(candidateShareId),
    cacheKeys
  });

  return {
    sendEmailsCandidateShareData: postItemData,
    sendEmailsCandidateShareError: postItemError,
    sendEmailsCandidateShareErrorMessage:
      postItemErrorMessage as SendEmailsCandidateShareErrorMessage,
    sendEmailsCandidateShareIsLoading:
      postItemIsLoading as SendEmailsCandidateShareIsLoading,
    sendEmailsCandidateShare: postItem,
    sendEmailsCandidateShareReset: postItemReset
  };
}

export default useSendEmailsCandidateShare;
