import React, { ForwardedRef, ReactNode } from 'react';
import {
  Radio as ChakraRadio,
  RadioProps as ChakraRadioProps
} from '@chakra-ui/radio';

import { mapSize } from '../../../../utils/mapSize';

export interface RadioFieldProps extends ChakraRadioProps {
  id?: string;
  label?: ReactNode;
  children?: ReactNode;
  size?: 'small' | 'medium' | 'large';
  defaultChecked?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  isReadOnly?: boolean;
}

/**
 * Radio
 *
 * React component used in forms when a user needs to select
 * multiple values from several options.
 */
const RadioField = React.forwardRef<HTMLInputElement, RadioFieldProps>(
  (
    {
      id,
      label,
      isDisabled,
      children,
      defaultChecked,
      size = 'medium',
      isReadOnly,
      ...props
    }: RadioFieldProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <ChakraRadio
        id={id}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        size={mapSize(size)}
        defaultChecked={defaultChecked}
        ref={ref}
        {...props}
      >
        {label || children}
      </ChakraRadio>
    );
  }
);

RadioField.displayName = 'RadioField';

export default RadioField;
