import { STATIC_URL } from '../../config';

import { DEFAULT_TIME_ZONE } from '../../constants';

import { DateUtils } from '../../utils/DateUtils';
import {
  ImageCreatedAt,
  ImageFile,
  ImageKey,
  ImageNanoId
} from './imagesTypes';

export interface Image {
  file: ImageFile;
  key: ImageKey;
  nanoId: ImageNanoId;
  createdAt: ImageCreatedAt;
}

export class ImagesUrl {
  static file(image: Image | null | undefined) {
    if (!image) {
      return null;
    }

    return image.key
      ? `${STATIC_URL}/${image.key}`
      : [
          STATIC_URL,
          'uploads',
          'images',
          DateUtils.formatWithTimeZone(image.createdAt, DEFAULT_TIME_ZONE),
          'image',
          'file',
          image.nanoId,
          image.file
        ].join('/');
  }
}
