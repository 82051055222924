import { useCallback } from 'react';
import first from 'lodash/first';
import map from 'lodash/map';
import size from 'lodash/size';

import { useCurrentUser } from '../../../../../../auth/hooks/useAuth';
import { ImagesUrl } from '../../../../../images/ImagesUrl';
import { ComposeNewEmailDrawer } from '../../../../../inbox/components/InboxMainSection/components/ComposeNewEmailDrawer';
import { ShareCandidateCardEmailItem } from '../../../../../jobs/pages/ViewJobCandidatesPage/helpers/ShareCandidateCardEmailItem';
import { renderJobTitle } from '../../../../../jobs/utils/renderJobTitle';
import {
  ShareCandidateProfileCandidateShare,
  ShareCandidateProfileJob,
  ShareCandidateProfileJobCandidates
} from '../../ShareCandidateProfile.types';
import { ComposeNewEmailDrawerMailRecipients } from '../../../../../inbox/components/InboxMainSection/components/ComposeNewEmailDrawer/ComposeNewEmailDrawer.types';
import { Heading } from '../../../../../../helpers/Heading';
import { Box, Flex } from '@chakra-ui/react';
import { JobLocationHelper } from '../../../../../jobs/helpers/JobLocationHelper';
import { useChakraToast } from '../../../../../../helpers/useChakraToast';
import { useSendEmailsCandidateShare } from '../../../../hooks/useSendEmailsCandidateShare';
import { ShareJobCardEmailItem } from '../../../../../jobs/pages/ViewJobDetailsPage/ShareJobCardEmailItem';

interface ShareCandidateProfileEmailProps {
  candidateShare: ShareCandidateProfileCandidateShare;
  jobCandidates: ShareCandidateProfileJobCandidates;
  job: ShareCandidateProfileJob;
  isOpen: boolean;
  onClose: () => void;
  sendEmail: () => void;
}

function ShareCandidateProfileEmail({
  candidateShare,
  jobCandidates,
  job,
  isOpen,
  onClose,
  sendEmail
}: ShareCandidateProfileEmailProps) {
  const currentUser = useCurrentUser();

  const {
    sendEmailsCandidateShare,
    sendEmailsCandidateShareErrorMessage,
    sendEmailsCandidateShareIsLoading
  } = useSendEmailsCandidateShare({
    candidateShareId: candidateShare.id
  });

  const toast = useChakraToast();

  const recruiterAvatarUrl = ImagesUrl.file(currentUser.image) as string;
  const companyLogoUrl = ImagesUrl.file(job.company?.image) as string;

  const jobCandidateAvatarUrls = map(
    jobCandidates,
    (jobCandidate) => ImagesUrl.file(jobCandidate.image) as string
  );

  const jobCity = first(job.cities)?.label || job.city?.label;

  const subject = `Job Profile review for ${renderJobTitle(job)}${
    jobCity ? ` in ${jobCity}` : ''
  }`;

  const recipients = map(candidateShare.recipients, (recipient) => ({
    ...recipient,
    image: ImagesUrl.file(recipient.image)
  }));

  const emailRecipients = [
    ...recipients,
    ...candidateShare.companyMemberInvitations,
    ...candidateShare.candidateShareInvitations
  ] as ComposeNewEmailDrawerMailRecipients;

  const handleSendEmail = useCallback(async () => {
    await sendEmailsCandidateShare({});

    sendEmail();
    toast({
      title: `Profile${size(jobCandidates) > 1 ? 's' : ''} successfully shared`,
      status: 'success'
    });
  }, [jobCandidates, sendEmail, toast, sendEmailsCandidateShare]);

  const numberOfProfiles = size(jobCandidates);

  return (
    <ComposeNewEmailDrawer
      isOpen={isOpen}
      onClose={onClose}
      sendMessage={handleSendEmail}
      sendMessageErrorMessage={sendEmailsCandidateShareErrorMessage}
      sendMessageIsLoading={sendEmailsCandidateShareIsLoading}
      onBack={onClose}
      withBackButton
      title={
        <Heading level="h4">
          <Flex gap={3} alignItems="center">
            {numberOfProfiles > 0 ? (
              <Box as="span">
                Sharing {numberOfProfiles} candidate profile
                {numberOfProfiles > 1 ? 's' : ''} for{' '}
                <Box as="span" color="primary.500">
                  {renderJobTitle(job)}
                </Box>
              </Box>
            ) : (
              <Box as="span">
                Share Job: {'  '}
                <Box as="span" color="primary.500">
                  {renderJobTitle(job)}
                </Box>
              </Box>
            )}

            <JobLocationHelper job={job} withIcon />
          </Flex>
        </Heading>
      }
      subject={subject}
      emailRecipients={emailRecipients}
      isShareProfile
      emailContent={
        numberOfProfiles > 0 ? (
          <ShareCandidateCardEmailItem
            company={{
              name: job.company?.name as string,
              logoUrl: companyLogoUrl
            }}
            avatarUrls={jobCandidateAvatarUrls}
            title={`You have ${numberOfProfiles} new profile${
              numberOfProfiles > 1 ? 's' : ''
            } to review`}
            hrDepartment={renderJobTitle(job)}
            recruiterName={currentUser?.name}
            recruiterAvatarUrl={recruiterAvatarUrl}
            message={candidateShare.message}
            buttonActionText="View Profiles"
            buttonLinkUrl="/"
          />
        ) : (
          <ShareJobCardEmailItem
            company={{
              name: job.company?.name as string,
              logoUrl: companyLogoUrl
            }}
            title={`You have ${numberOfProfiles} new profile${
              numberOfProfiles > 1 ? 's' : ''
            } to review`}
            buttonLinkUrl="/"
            location="Santa Monica, CA"
            jobType="FULL TIME"
            jobTitle="Python Developer"
            operationMode="DEVELOPMENT"
          />
        )
      }
    />
  );
}

export default ShareCandidateProfileEmail;
