import {
  Avatar,
  Box,
  Collapse,
  Flex,
  Stack,
  StackDivider,
  useDisclosure
} from '@chakra-ui/react';
import React from 'react';
import { Button } from '../../../../../../../../../helpers/Button';
import { Text } from '../../../../../../../../../helpers/Text';
import { ThumbUpIcon } from '../../../../../../../../../icons/ThumbUpIcon';

const ViewJobCandidateModalPhoneSidebarLastCallNoteHelper = () => {
  const {
    isOpen: isOpenLastCallNote,
    onOpen: onOpenLastCallNote,
    onClose: onCloseLastCallNote
  } = useDisclosure();

  return (
    <Box onMouseLeave={onCloseLastCallNote}>
      <Button
        size="medium"
        borderRadius={0}
        hierarchy="link"
        onMouseEnter={onOpenLastCallNote}
      >
        Last call note
      </Button>

      <Collapse in={isOpenLastCallNote} unmountOnExit>
        <Box pt={2}>
          <Stack spacing={3} divider={<StackDivider />}>
            <Flex alignItems="center" gap={2} justifyContent="space-between">
              <Flex alignItems="center" gap={2}>
                <Avatar size="sm" src="/images/default-avatar-5.png" />

                <Text textStyle="body1Medium" noOfLines={1}>
                  Bakaran Kulon
                </Text>
              </Flex>

              <Flex
                w={9}
                h={9}
                pt="9px"
                pb="11px"
                bg="green.100"
                alignItems="center"
                justifyContent="center"
                borderRadius="full"
              >
                <ThumbUpIcon color="green.400" w={4} h={4} />
              </Flex>
            </Flex>

            <Stack spacing={3}>
              <Flex alignItems="center" gap={4}>
                <Text textStyle="body1Medium" noOfLines={1}>
                  Interested
                </Text>

                <Text textStyle="body2Medium" noOfLines={1} ml="auto">
                  03/21/2022 | 10:00 am
                </Text>
              </Flex>

              <Text textStyle="body2Regular" p={3} bg="gray.100">
                Voluptas soluta doloribus porro numquam libero dolorum obcaecati
                repellat hic praesentium aut officia ut quaerat neque ex
                sapiente vero ipsa, est ipsam.
              </Text>

              <Box>
                <Button
                  size="medium"
                  borderRadius={0}
                  hierarchy="link"
                  onMouseEnter={onOpenLastCallNote}
                >
                  See all call notes
                </Button>
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Collapse>
    </Box>
  );
};

export default ViewJobCandidateModalPhoneSidebarLastCallNoteHelper;
