import React from 'react';
import { Text } from '../../../../../../../../../../../helpers/Text';
import { Flex, Stack, SystemProps } from '@chakra-ui/react';
import CandidateEvaluation from '../../../../../VewiJobCandidateModalHeader/components/ViewJobCandidateModalCallDropdown/components/CandidateEvaluation';

interface ViewJobCandidateModalPhoneSidebarEvaluateLeadProps {
  maxHeight?: SystemProps['maxHeight'];
  eachEvaluationMaxWidth?: SystemProps['maxWidth'];
}

const ViewJobCandidateModalPhoneSidebarEvaluateLead = ({
  maxHeight,
  eachEvaluationMaxWidth
}: ViewJobCandidateModalPhoneSidebarEvaluateLeadProps) => {
  return (
    <Stack spacing={2} pt={4}>
      <Text textStyle="body1Medium" fontWeight="semibold">
        Candidate Evaluation
      </Text>

      <Flex direction="column" gap={4} wrap="wrap" maxHeight={maxHeight}>
        {evaluations.map(({ label, value }) => (
          <CandidateEvaluation
            key={label}
            label={label}
            value={value}
            maxWidth={eachEvaluationMaxWidth}
            smaller
          />
        ))}
      </Flex>
    </Stack>
  );
};

const evaluations = [
  { label: 'Job fit / Relevant background?', value: 3 },
  { label: 'Interpersonal / Communication?', value: 4 },
  { label: 'Professional Impression?', value: 2 },
  { label: 'Motivation / Intuitive?', value: 4 },
  { label: 'Overall Evaluation?', value: 3 }
];

export default ViewJobCandidateModalPhoneSidebarEvaluateLead;
