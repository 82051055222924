import { Flex, RadioGroup, Stack } from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { Button } from '../../../../../../../../helpers/Button';
import { Text } from '../../../../../../../../helpers/Text';
import { RadioField } from '../../../../../../../../helpers/forms/formFields/RadioField';
import { useChakraToast } from '../../../../../../../../helpers/useChakraToast';
import {
  JobCandidateDisqualificationReason,
  JobCandidateDisqualificationReasons,
  jobCandidateDisqualificationReasons
} from '../../../../../../../jobCandidates/jobCandidatesTypes';
import { renderJobCandidateDisqualificationReason } from '../../../../../../../jobCandidates/utils/renderJobCandidateDisqualificationReason';

interface ViewJobCandidateDetailsDisqualifyCandidateProps {
  onClose: () => void;
  disqualifyCandidate: (reason: string) => void;
}

const ViewJobCandidateDetailsDisqualifyCandidate = ({
  onClose,
  disqualifyCandidate
}: ViewJobCandidateDetailsDisqualifyCandidateProps) => {
  const toast = useChakraToast();

  const [disqualificationReason, setDisqualificationReason] =
    useState<JobCandidateDisqualificationReason>(
      JobCandidateDisqualificationReasons.NONE as JobCandidateDisqualificationReason
    );

  const actions = jobCandidateDisqualificationReasons.map(
    (jobCandidateDisqualificationReason) => ({
      label: renderJobCandidateDisqualificationReason(
        jobCandidateDisqualificationReason
      ),
      value: jobCandidateDisqualificationReason
    })
  );

  const handleClose = useCallback(() => {
    setDisqualificationReason(
      JobCandidateDisqualificationReasons.NONE as JobCandidateDisqualificationReason
    );

    onClose();
  }, [onClose]);

  const handleDisqualifyCandidate = useCallback(() => {
    disqualifyCandidate(disqualificationReason);

    toast.closeAll();

    toast({
      title: `Candidate disqualified`,
      status: 'success',
      position: 'top-right',
      duration: 2000,
      isClosable: true
    });
  }, [disqualificationReason, disqualifyCandidate, toast]);

  return (
    <Stack spacing={4}>
      <RadioGroup
        name="disqualificationReason"
        onChange={(value) =>
          setDisqualificationReason(value as JobCandidateDisqualificationReason)
        }
        value={disqualificationReason + ''}
      >
        <Stack spacing={3}>
          <Text textStyle="body1Medium" color="gray.900">
            Disqualify
          </Text>

          {actions.map((action) => (
            <RadioField
              size="small"
              value={action.value + ''}
              colorScheme="primary"
              key={action.value}
            >
              <Text textStyle="body2Regular" color="gray.900">
                {action.label}
              </Text>
            </RadioField>
          ))}
        </Stack>
      </RadioGroup>

      <Flex justifyContent="flex-end" gap={2}>
        <Button size="small" hierarchy="link" onClick={handleClose}>
          Discard
        </Button>

        <Button size="small" type="submit" onClick={handleDisqualifyCandidate}>
          Continue
        </Button>
      </Flex>
    </Stack>
  );
};

export default ViewJobCandidateDetailsDisqualifyCandidate;
