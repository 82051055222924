import { SkillBadgeRatingType } from '../../skillBadgesTypes';

function renderSkillBadgeRatingBackground(rating: SkillBadgeRatingType) {
  switch (rating) {
    case 'no_experience':
      return { main: '#697275', experience: '#DAE1E3' };
    case 'beginner':
      return { main: '#0BAD90', experience: '#59C7B4' };
    case 'intermediate':
      return { main: '#EEA401', experience: '#EAC676' };
    case 'professional':
      return { main: '#5C35AE', experience: '#9578EB' };
    case 'expert':
      return { main: '#F1472F', experience: '#F8A599' };
    default:
      return { main: '#0BAD90', experience: '#59C7B4' };
  }
}

export default renderSkillBadgeRatingBackground;
