import { useFetchItem } from '../../../common/hooks/useFetchItem';

import {
  WorkflowStageTaskId,
  FetchWorkflowStageTaskQuery,
  FetchWorkflowStageTaskCacheKey,
  FetchWorkflowStageTaskEnabled,
  FetchWorkflowStageTaskCacheTime,
  FetchWorkflowStageTaskError,
  FetchWorkflowStageTaskErrorMessage,
  FetchWorkflowStageTaskIsLoading,
  FetchWorkflowStageTaskIsFetched,
  FetchWorkflowStageTaskIsRefetching,
  FetchWorkflowStageTaskIsPlaceholderData
} from '../../workflowStageTasksTypes';

import { WorkflowStageTasksBffRequests } from '../../WorkflowStageTasksBffRequests';

interface FetchWorkflowStageTaskOptions<FetchWorkflowStageTaskResponseType> {
  cacheKey: FetchWorkflowStageTaskCacheKey;
  query: FetchWorkflowStageTaskQuery;
  workflowStageTaskId: WorkflowStageTaskId;
  enabled?: FetchWorkflowStageTaskEnabled;
  cacheTime?: FetchWorkflowStageTaskCacheTime;
  placeholderWorkflowStageTask?: FetchWorkflowStageTaskResponseType;
  placeholderData?: () => FetchWorkflowStageTaskResponseType;
}

function useFetchWorkflowStageTask<FetchWorkflowStageTaskResponseType>({
  cacheKey,
  query,
  workflowStageTaskId,
  enabled,
  cacheTime,
  placeholderWorkflowStageTask,
  placeholderData
}: FetchWorkflowStageTaskOptions<FetchWorkflowStageTaskResponseType>) {
  const {
    item,
    itemError,
    itemErrorMessage,
    itemIsFetched,
    itemIsRefetching,
    itemIsLoading,
    itemIsPlaceholderData,
    refetchItem
  } = useFetchItem<
    FetchWorkflowStageTaskResponseType,
    FetchWorkflowStageTaskError
  >({
    cacheKey,
    itemId: workflowStageTaskId,
    enabled,
    cacheTime,
    placeholderItem: placeholderWorkflowStageTask,
    placeholderData,
    queryFn: () =>
      // WorkflowStageTasksRequests.fetchWorkflowStageTask<FetchWorkflowStageTaskResponseType>(
      //   workflowStageTaskId,
      //   {
      //     query
      //   }
      // )
      WorkflowStageTasksBffRequests.fetchWorkflowStageTask<FetchWorkflowStageTaskResponseType>(
        workflowStageTaskId,
        {
          query
        }
      )
  });

  return {
    workflowStageTask: item as FetchWorkflowStageTaskResponseType,
    workflowStageTaskError: itemError as FetchWorkflowStageTaskError,
    workflowStageTaskErrorMessage:
      itemErrorMessage as FetchWorkflowStageTaskErrorMessage,
    workflowStageTaskIsFetched:
      itemIsFetched as FetchWorkflowStageTaskIsFetched,
    workflowStageTaskIsRefetching:
      itemIsRefetching as FetchWorkflowStageTaskIsRefetching,
    workflowStageTaskIsLoading:
      itemIsLoading as FetchWorkflowStageTaskIsLoading,
    workflowStageTaskIsPlaceholderData:
      itemIsPlaceholderData as FetchWorkflowStageTaskIsPlaceholderData,
    refetchWorkflowStageTask: refetchItem
  };
}

export default useFetchWorkflowStageTask;
