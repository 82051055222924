import { Icon, IconProps } from '@chakra-ui/icon';

const Kanban = ({
  w = '24px',
  h = '24px',
  fill = 'gray.500',
  ...props
}: IconProps) => (
  <Icon viewBox="0 0 24 24" w={w} h={h} fill={fill} {...props}>
    <g fill={fill as string}>
      <path d="m10.5 19.9v-15.8c0-1.5-.64-2.1-2.23-2.1h-4.04c-1.59 0-2.23.6-2.23 2.1v15.8c0 1.5.64 2.1 2.23 2.1h4.04c1.59 0 2.23-.6 2.23-2.1z" />
      <path
        d="m22 12.9v-8.8c0-1.5-.64-2.1-2.23-2.1h-4.04c-1.59 0-2.23.6-2.23 2.1v8.8c0 1.5.64 2.1 2.23 2.1h4.04c1.59 0 2.23-.6 2.23-2.1z"
        opacity=".4"
      />
    </g>
  </Icon>
);

export default Kanban;
