import { FetchIndustriesCacheKey } from '../../industriesTypes';

import { useCreateItem } from '../../../common/hooks/useCreateItem';

import {
  IndustriesBffRequests,
  CreateIndustryRequestData,
  CreateIndustryErrorResponse
} from '../../IndustriesBffRequests';

interface CreateIndustryOptions {
  cacheKeys?: FetchIndustriesCacheKey[];
}

function useCreateIndustry({ cacheKeys = [] }: CreateIndustryOptions) {
  const {
    createItemData,
    createItemError,
    createItemErrorMessage,
    createItemIsLoading,
    createItem,
    createItemReset
  } = useCreateItem<CreateIndustryRequestData, CreateIndustryErrorResponse>({
    mutationFn: (queryInput) =>
      IndustriesBffRequests.createIndustry(queryInput),
    cacheKeys
  });

  return {
    createIndustryData: createItemData,
    createIndustryError: createItemError,
    createIndustryErrorMessage: createItemErrorMessage,
    createIndustryIsLoading: createItemIsLoading,
    createIndustry: createItem,
    createIndustryReset: createItemReset
  };
}

export default useCreateIndustry;
