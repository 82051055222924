import React from 'react';
import { Avatar, AvatarProps } from '@chakra-ui/avatar';

import { ImagesUrl, Image } from '../../main/images/ImagesUrl';
import { UserIcon } from '../../icons/UserIcon';
import { Box } from '@chakra-ui/react';
import { ShieldLockIcon } from '../../icons/ShieldLockIcon';

interface AvatarHelperProps extends AvatarProps {
  name?: string;
  image: Image | null;
  size?: string;
  mt?: number;
  mb?: number;
  withShieldLockIcon?: boolean;
  withoutIcon?: boolean;
}

function AvatarHelper({
  name,
  image,
  size = 'sm',
  mt,
  mb,
  withShieldLockIcon,
  withoutIcon,
  ...props
}: AvatarHelperProps) {
  return (
    <Box pos="relative">
      <Avatar
        src={ImagesUrl.file(image) || undefined}
        icon={withoutIcon ? undefined : <UserIcon w="100%" h="110%" />}
        name={name}
        size={size}
        mt={mt}
        mb={mb}
        {...props}
      />
      {withShieldLockIcon ? (
        <ShieldLockIcon pos="absolute" top="75%" left="38%" />
      ) : null}
    </Box>
  );
}

export default AvatarHelper;
