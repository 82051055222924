import { IconButton } from '@chakra-ui/button';
import {
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger
} from '@chakra-ui/popover';
import { Stack } from '@chakra-ui/layout';
import { BellIcon } from '../../icons/BellIcon';
import { Search } from '../Search';
import { SingleNotification } from './components/SingleNotification';
import { SingleNotificationMeta } from './components/SingleNotification/SingleNotification';
import { Portal } from '@chakra-ui/react';

const NotificationsDropdown = () => {
  return (
    <>
      <Popover placement="bottom-end" offset={[0, 19]} isLazy>
        <PopoverTrigger>
          <IconButton
            aria-label=""
            variant="unstyled"
            borderRadius="full"
            borderColor="gray.200"
            boxShadow="0px 2px 2px rgba(23, 25, 26, 0.06)"
          >
            <BellIcon />
          </IconButton>
        </PopoverTrigger>

        <Portal>
          <PopoverContent
            w={80}
            overflow="hidden"
            h="auto"
            maxH="calc(100vh - 90px)"
          >
            <PopoverHeader p={4}>
              <Search w="100%" />
            </PopoverHeader>

            <PopoverBody p={0} bg="gray.100" overflow="auto">
              <Stack spacing="1px">
                {notifications.map((item) => (
                  <SingleNotification
                    key={item?.id}
                    title={item?.title}
                    date={item?.date}
                    isUnread={item?.isUnread}
                    description={item?.description}
                    avatar={item?.avatar}
                    meta={item.meta as SingleNotificationMeta}
                  />
                ))}
              </Stack>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </>
  );
};

export default NotificationsDropdown;

const notifications = [
  {
    id: '63021d1aa2695d3f523180df',
    title: 'Elinor Good',
    date: '2022-08-02T15:38:45.774Z',
    description:
      'Voluptate consectetur voluptate velit sit amet nostrud commodo.',
    isUnread: true,
    avatar: '',
    meta: {
      type: 'comment',
      name: 'Rivers Vicki'
    }
  },
  {
    id: '63021d1aed593cc7fa59f512',
    title: 'consequat culpa exercitation aliquip consectetur Lorem et',
    date: '2022-08-06T04:42:17.238Z',
    description:
      'Irure ea exercitation proident consequat consequat laboris irure.',
    isUnread: false,
    avatar: '',
    meta: {
      type: 'schedule',
      name: 'Vicki Rivers'
    }
  },
  {
    id: '63021d1aacd3bafd601ed9bc',
    title: 'amet amet veniam ut sit',
    date: '2022-08-02T18:20:30.658Z',
    description:
      'Lorem pariatur anim et tempor labore voluptate officia laboris voluptate nostrud.',
    isUnread: true,
    avatar: '',
    meta: {
      type: 'reminder',
      name: 'Mcgee Griffin'
    }
  },
  {
    id: '63021d1a915f7bc48b9d9c50',
    title: 'Vicki Rivers',
    date: '2022-08-13T05:58:55.193Z',
    description:
      'Aliqua aliqua nulla commodo proident tempor elit cillum anim pariatur dolore.',
    isUnread: false,
    avatar: '',
    meta: {
      type: 'schedule',
      name: 'Labore Ullamco'
    }
  },
  {
    id: '63021d1ae4f1baea9959c314',
    title: 'Diaz Welch',
    date: '2022-08-01T04:58:38.050Z',
    description:
      'Qui non sit eu reprehenderit est est veniam consectetur amet in consequat elit nisi.',
    isUnread: true,
    avatar: '',
    meta: {
      type: 'comment',
      name: 'Good Elinor'
    }
  },
  {
    id: '63021d1a9b02fbe20ba3e630',
    title: 'Delgado Montoya',
    date: '2022-07-06T12:20:09.567Z',
    description: 'Amet quis est aute labore.',
    isUnread: true,
    avatar: ''
  },
  {
    id: '63021d1a45e06c2f5ae623e4',
    title: 'non cillum',
    date: '2022-07-11T18:12:52.498Z',
    description: 'Reprehenderit Lorem anim est culpa.',
    isUnread: false,
    avatar: ''
  },
  {
    id: '63021d1aa4bcfda365be1f41',
    title: 'elit minim reprehenderit quis',
    date: '2022-07-24T17:23:55.361Z',
    description: 'Consequat qui laborum ea do.',
    isUnread: true,
    avatar: ''
  },
  {
    id: '63021d1add451ce346cbcb0f',
    title: 'tempor consequat reprehenderit excepteur',
    date: '2022-07-11T03:54:30.407Z',
    description:
      'Quis id est consectetur id officia commodo aliquip tempor commodo occaecat sint Lorem.',
    isUnread: true,
    avatar: ''
  },
  {
    id: '63021d1a6be8c1be4faf3f27',
    title: 'labore ullamco',
    date: '2022-07-15T22:52:05.237Z',
    description: 'Consectetur qui sunt esse laborum ut.',
    isUnread: false,
    avatar: ''
  },
  {
    id: '63021d1a68bd72026c67ee3f',
    title: 'proident quis nisi in nulla exercitation ut',
    date: '2022-07-07T04:33:49.868Z',
    description: 'Sunt laborum officia nostrud irure est culpa eiusmod.',
    isUnread: false,
    avatar: ''
  },
  {
    id: '63021d1afd53f651cfb0f197',
    title: 'adipisicing nostrud irure laboris sunt',
    date: '2022-07-05T13:04:58.902Z',
    description:
      'Magna eiusmod nostrud tempor elit nulla do exercitation ad nostrud quis.',
    isUnread: true,
    avatar: ''
  },
  {
    id: '63021d1a9a7d44134748245e',
    title: 'aliquip ut occaecat do dolor do dolor',
    date: '2022-08-12T23:06:04.314Z',
    description:
      'Nisi dolor labore qui nulla laborum sunt do anim sit labore velit laborum ex et.',
    isUnread: true,
    avatar: ''
  },
  {
    id: '63021d1a44fbd136cbc67cea',
    title: 'Verna Simpson',
    date: '2022-08-16T20:49:37.204Z',
    description:
      'Mollit quis consequat dolore dolore duis esse fugiat mollit aliquip cillum enim.',
    isUnread: true,
    avatar: ''
  },
  {
    id: '63021d1a3e174fcb8493926e',
    title: 'Carole Galloway',
    date: '2022-07-20T11:54:54.101Z',
    description: 'Ex ipsum cillum ad exercitation ipsum ea exercitation.',
    isUnread: true,
    avatar: ''
  },
  {
    id: '63021d1a6ab0492df530d8f9',
    title: 'voluptate esse amet ad do',
    date: '2022-08-09T11:21:10.306Z',
    description: 'Laboris elit officia commodo voluptate esse ad.',
    isUnread: false,
    avatar: ''
  },
  {
    id: '63021d1a480420132517a908',
    title: 'laboris ullamco eu ut',
    date: '2022-08-16T02:03:25.240Z',
    description:
      'Pariatur sit nulla adipisicing id veniam ut voluptate commodo incididunt elit cupidatat consequat consectetur.',
    isUnread: true,
    avatar: ''
  },
  {
    id: '63021d1a91726756f536ac08',
    title: 'Mcgee Griffin',
    date: '2022-08-11T22:43:00.394Z',
    description:
      'Incididunt incididunt tempor eiusmod labore voluptate eu ullamco eiusmod amet ea exercitation.',
    isUnread: false,
    avatar: ''
  },
  {
    id: '63021d1a1e7f664fc1f023e1',
    title: 'consequat ad eiusmod laborum',
    date: '2022-07-29T20:36:19.122Z',
    description:
      'Laboris reprehenderit incididunt reprehenderit velit velit Lorem reprehenderit excepteur magna laborum non.',
    isUnread: true,
    avatar: ''
  },
  {
    id: '63021d1a8e878489328ddbeb',
    title: 'consectetur non ut',
    date: '2022-07-13T16:04:06.819Z',
    description: 'Sunt ea labore eu tempor aliquip Lorem dolore cillum eu.',
    isUnread: true,
    avatar: ''
  }
];
