import { usePaginatedItems } from '../../../common/hooks/usePaginatedItems';

import {
  FetchUsersQuery,
  FetchUsersCacheKey,
  FetchUsersTotalCount,
  FetchUsersPage,
  FetchUsersPageSize,
  FetchUsersError,
  FetchUsersErrorMessage,
  FetchUsersIsLoading,
  FetchUsersIsFetched,
  FetchUsersIsPlaceholderData,
  FetchUsersEnabled,
  FetchUsersPrefetchNextPage,
  FetchUsersCountType,
  FetchUsersFilters,
  FetchUsersSort,
  FetchUserID,
  FetchUserQuery,
  FetchUserCacheKey
} from '../../usersTypes';

import { UsersBffRequests } from '../../UsersBffRequests';

interface PaginatedUsersBaseOptions<PaginatedUsersResponseType> {
  cacheKey: FetchUsersCacheKey;
  query: FetchUsersQuery;
  countType?: FetchUsersCountType;
  initialPage?: FetchUsersPage;
  initialPageSize?: FetchUsersPageSize;
  initialFilters?: FetchUsersFilters;
  initialSort?: FetchUsersSort;
  enabled?: FetchUsersEnabled;
  prefetchNextPage?: FetchUsersPrefetchNextPage;
  placeholderUsers?: PaginatedUsersResponseType[];
  placeholderUsersCount?: FetchUsersTotalCount;
  placeholderData?: () => PaginatedUsersResponse<PaginatedUsersResponseType>;
}

interface PaginatedUsersOptionsWithoutItem {
  fetchItemCacheKey?: never;
  itemQuery?: never;
}

interface PaginatedUsersOptionsWithItem {
  fetchItemCacheKey: FetchUserCacheKey;
  itemQuery: FetchUserQuery;
}

type PaginatedUsersOptions<PaginatedUsersResponseType> =
  PaginatedUsersBaseOptions<PaginatedUsersResponseType> &
    (PaginatedUsersOptionsWithoutItem | PaginatedUsersOptionsWithItem);

interface PaginatedUsersResponse<PaginatedUsersResponseType> {
  data: PaginatedUsersResponseType[];
  totalCount: FetchUsersTotalCount;
}

function usePaginatedUsers<PaginatedUsersResponseType>({
  cacheKey,
  query,
  countType,
  initialPage,
  initialPageSize,
  initialFilters,
  initialSort,
  enabled,
  prefetchNextPage,
  placeholderUsers,
  placeholderUsersCount,
  placeholderData,
  fetchItemCacheKey,
  itemQuery
}: PaginatedUsersOptions<PaginatedUsersResponseType>) {
  const {
    items,
    itemsTotalCount,
    itemsError,
    itemsErrorMessage,
    itemsIsFetched,
    itemsIsLoading,
    itemsIsPlaceholderData,
    itemsCurrentPage,
    itemsCurrentPageSize,
    itemsCurrentFilters,
    itemsCurrentSort,
    fetchItems,
    paginateItems,
    changeItemsPageSize,
    filterItems,
    changeItemsFilters,
    clearItemsFilters,
    sortItems,
    prefetchItems,
    prefetchItem
  } = usePaginatedItems<PaginatedUsersResponseType>({
    cacheKey,
    initialPage,
    initialPageSize,
    initialFilters,
    initialSort,
    enabled,
    prefetchNextPage,
    placeholderItems: placeholderUsers,
    placeholderItemsCount: placeholderUsersCount,
    placeholderData,
    queryFn: (params) =>
      UsersBffRequests.fetchUsers<PaginatedUsersResponseType>({
        query,
        countType,
        page: params.page as FetchUsersPage,
        pageSize: params.pageSize as FetchUsersPageSize,
        filters: params.filters as FetchUsersFilters,
        sort: params.sort as FetchUsersSort
      }),
    ...(itemQuery
      ? {
          fetchItemCacheKey,
          queryItemFn: (itemId) =>
            UsersBffRequests.fetchUser(itemId as FetchUserID, {
              query: itemQuery
            })
        }
      : {})
  });

  return {
    users: items as PaginatedUsersResponseType[],
    usersTotalCount: itemsTotalCount as FetchUsersTotalCount,
    usersError: itemsError as FetchUsersError,
    usersErrorMessage: itemsErrorMessage as FetchUsersErrorMessage,
    usersIsLoading: itemsIsLoading as FetchUsersIsLoading,
    usersIsFetched: itemsIsFetched as FetchUsersIsFetched,
    usersIsPlaceholderData:
      itemsIsPlaceholderData as FetchUsersIsPlaceholderData,
    usersCurrentPage: itemsCurrentPage as FetchUsersPage,
    usersCurrentPageSize: itemsCurrentPageSize as FetchUsersPageSize,
    usersCurrentFilters: itemsCurrentFilters as FetchUsersFilters,
    usersCurrentSort: itemsCurrentSort as FetchUsersSort,
    fetchUsers: fetchItems,
    paginateUsers: paginateItems,
    changeUsersPageSize: changeItemsPageSize,
    filterUsers: filterItems,
    changeUsersFilters: changeItemsFilters,
    clearUsersFilters: clearItemsFilters,
    sortUsers: sortItems,
    prefetchUsers: prefetchItems,
    prefetchUser: prefetchItem
  };
}

export default usePaginatedUsers;
