import { Flex } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

type ViewJobCandidateModalPhoneSidebarCallResponseWrapperProps = {
  children: ReactNode;
};
const ViewJobCandidateModalPhoneSidebarCallResponseWrapper = ({
  children
}: ViewJobCandidateModalPhoneSidebarCallResponseWrapperProps) => (
  <Flex
    p={3}
    bg="gray.50"
    border="1px solid"
    borderRadius="base"
    flexDirection="column"
    borderColor="gray.100"
  >
    {children}
  </Flex>
);

export default ViewJobCandidateModalPhoneSidebarCallResponseWrapper;
