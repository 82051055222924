import { usePaginatedItems } from '../../../common/hooks/usePaginatedItems';

import {
  FetchLikedJobCandidatesQuery,
  FetchLikedJobCandidatesCacheKey,
  FetchLikedJobCandidatesTotalCount,
  FetchLikedJobCandidatesPage,
  FetchLikedJobCandidatesPageSize,
  FetchLikedJobCandidatesError,
  FetchLikedJobCandidatesErrorMessage,
  FetchLikedJobCandidatesIsLoading,
  FetchLikedJobCandidatesIsFetched,
  FetchLikedJobCandidatesIsPlaceholderData,
  FetchLikedJobCandidatesEnabled,
  FetchLikedJobCandidatesPrefetchNextPage,
  FetchLikedJobCandidatesCountType,
  FetchLikedJobCandidatesFilters,
  FetchLikedJobCandidatesSort,
  FetchLikedJobCandidateID,
  FetchLikedJobCandidateQuery,
  FetchLikedJobCandidateCacheKey
} from '../../likedJobCandidatesTypes';

import { LikedJobCandidatesRequests } from '../../LikedJobCandidatesRequests';
import { LikedJobCandidatesBffRequests } from '../../LikedJobCandidatesBffRequests';

interface PaginatedLikedJobCandidatesBaseOptions<
  PaginatedLikedJobCandidatesResponseType
> {
  cacheKey: FetchLikedJobCandidatesCacheKey;
  query: FetchLikedJobCandidatesQuery;
  countType?: FetchLikedJobCandidatesCountType;
  initialPage?: FetchLikedJobCandidatesPage;
  initialPageSize?: FetchLikedJobCandidatesPageSize;
  initialFilters?: FetchLikedJobCandidatesFilters;
  initialSort?: FetchLikedJobCandidatesSort;
  enabled?: FetchLikedJobCandidatesEnabled;
  prefetchNextPage?: FetchLikedJobCandidatesPrefetchNextPage;
  placeholderLikedJobCandidates?: PaginatedLikedJobCandidatesResponseType[];
  placeholderLikedJobCandidatesCount?: FetchLikedJobCandidatesTotalCount;
  placeholderData?: () => PaginatedLikedJobCandidatesResponse<PaginatedLikedJobCandidatesResponseType>;
}

interface PaginatedLikedJobCandidatesOptionsWithoutItem {
  fetchItemCacheKey?: never;
  itemQuery?: never;
}

interface PaginatedLikedJobCandidatesOptionsWithItem {
  fetchItemCacheKey: FetchLikedJobCandidateCacheKey;
  itemQuery: FetchLikedJobCandidateQuery;
}

type PaginatedLikedJobCandidatesOptions<
  PaginatedLikedJobCandidatesResponseType
> = PaginatedLikedJobCandidatesBaseOptions<PaginatedLikedJobCandidatesResponseType> &
  (
    | PaginatedLikedJobCandidatesOptionsWithoutItem
    | PaginatedLikedJobCandidatesOptionsWithItem
  );

interface PaginatedLikedJobCandidatesResponse<
  PaginatedLikedJobCandidatesResponseType
> {
  data: PaginatedLikedJobCandidatesResponseType[];
  totalCount: FetchLikedJobCandidatesTotalCount;
}

function usePaginatedLikedJobCandidates<
  PaginatedLikedJobCandidatesResponseType
>({
  cacheKey,
  query,
  countType,
  initialPage,
  initialPageSize,
  initialFilters,
  initialSort,
  enabled,
  prefetchNextPage,
  placeholderLikedJobCandidates,
  placeholderLikedJobCandidatesCount,
  placeholderData,
  fetchItemCacheKey,
  itemQuery
}: PaginatedLikedJobCandidatesOptions<PaginatedLikedJobCandidatesResponseType>) {
  const {
    items,
    itemsTotalCount,
    itemsError,
    itemsErrorMessage,
    itemsIsFetched,
    itemsIsLoading,
    itemsIsPlaceholderData,
    itemsCurrentPage,
    itemsCurrentPageSize,
    itemsCurrentFilters,
    itemsCurrentSort,
    fetchItems,
    paginateItems,
    changeItemsPageSize,
    filterItems,
    changeItemsFilters,
    clearItemsFilters,
    sortItems,
    prefetchItems,
    prefetchItem
  } = usePaginatedItems<PaginatedLikedJobCandidatesResponseType>({
    cacheKey,
    initialPage,
    initialPageSize,
    initialFilters,
    initialSort,
    enabled,
    prefetchNextPage,
    placeholderItems: placeholderLikedJobCandidates,
    placeholderItemsCount: placeholderLikedJobCandidatesCount,
    placeholderData,
    queryFn: (params) =>
      LikedJobCandidatesBffRequests.fetchLikedJobCandidates<PaginatedLikedJobCandidatesResponseType>(
        {
          query,
          countType,
          page: params.page as FetchLikedJobCandidatesPage,
          pageSize: params.pageSize as FetchLikedJobCandidatesPageSize,
          filters: params.filters as FetchLikedJobCandidatesFilters,
          sort: params.sort as FetchLikedJobCandidatesSort
        }
      ),
    ...(itemQuery
      ? {
          fetchItemCacheKey,
          queryItemFn: (itemId) =>
            LikedJobCandidatesRequests.fetchLikedJobCandidate(
              itemId as FetchLikedJobCandidateID,
              {
                query: itemQuery
              }
            )
        }
      : {})
  });

  return {
    likedJobCandidates: items as PaginatedLikedJobCandidatesResponseType[],
    likedJobCandidatesTotalCount:
      itemsTotalCount as FetchLikedJobCandidatesTotalCount,
    likedJobCandidatesError: itemsError as FetchLikedJobCandidatesError,
    likedJobCandidatesErrorMessage:
      itemsErrorMessage as FetchLikedJobCandidatesErrorMessage,
    likedJobCandidatesIsLoading:
      itemsIsLoading as FetchLikedJobCandidatesIsLoading,
    likedJobCandidatesIsFetched:
      itemsIsFetched as FetchLikedJobCandidatesIsFetched,
    likedJobCandidatesIsPlaceholderData:
      itemsIsPlaceholderData as FetchLikedJobCandidatesIsPlaceholderData,
    likedJobCandidatesCurrentPage:
      itemsCurrentPage as FetchLikedJobCandidatesPage,
    likedJobCandidatesCurrentPageSize:
      itemsCurrentPageSize as FetchLikedJobCandidatesPageSize,
    likedJobCandidatesCurrentFilters:
      itemsCurrentFilters as FetchLikedJobCandidatesFilters,
    likedJobCandidatesCurrentSort:
      itemsCurrentSort as FetchLikedJobCandidatesSort,
    fetchLikedJobCandidates: fetchItems,
    paginateLikedJobCandidates: paginateItems,
    changeLikedJobCandidatesPageSize: changeItemsPageSize,
    filterLikedJobCandidates: filterItems,
    changeLikedJobCandidatesFilters: changeItemsFilters,
    clearLikedJobCandidatesFilters: clearItemsFilters,
    sortLikedJobCandidates: sortItems,
    prefetchLikedJobCandidates: prefetchItems,
    prefetchLikedJobCandidate: prefetchItem
  };
}

export default usePaginatedLikedJobCandidates;
