import {
  WorkflowStageTaskApplication,
  WorkflowStageTaskApplications
} from '../../workflowStageTasksTypes';

function renderWorkflowStageTaskApplication(
  workflowStageTaskApplication: WorkflowStageTaskApplication
) {
  switch (workflowStageTaskApplication) {
    case WorkflowStageTaskApplications.EMAIL:
      return 'Email';
    case WorkflowStageTaskApplications.CALENDAR:
      return 'Calendar';
    case WorkflowStageTaskApplications.OFFER:
      return 'Offer';
    case WorkflowStageTaskApplications.PHONE:
      return 'Phone';
    default:
      return 'Unknown';
  }
}

export default renderWorkflowStageTaskApplication;
