import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Stack
} from '@chakra-ui/react';

import { Button } from '../../../../../helpers/Button';
import { Heading } from '../../../../../helpers/Heading';
import { Text } from '../../../../../helpers/Text';
import { AttachIcon } from '../../../../../icons/AttachIcon';
import { HeartIcon } from '../../../../../icons/HeartIcon';
import { LinkedInIcon } from '../../../../../icons/LinkedInIcon';
import { CancelIcon } from '../../../../../icons/CancelIcon';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { AvatarHelper } from '../../../../../helpers/AvatarHelper';

import {
  FetchJobCandidateEnabled,
  JobCandidateId,
  JobCandidateNanoId
} from '../../../../jobCandidates/jobCandidatesTypes';
import { useFetchJobCandidateByNanoId } from '../../../../jobCandidates/hooks/useFetchJobCandidateByNanoId';
import {
  FetchJobCandidateResponse,
  fetchJobCandidateQuery
} from '../../../../jobCandidates/queries/fetchJobCandidate.query';
import { JobCandidatesCache } from '../../../../jobCandidates/JobCandidatesCache';

import { renderJobCandidateName } from '../../../../jobCandidates/utils/renderJobCandidateName';

import { LinkedinLinkHelper } from '../../../../common/components/helpers/LinkedinLinkHelper';

import { JobLocationHelper } from '../../../../jobs/helpers/JobLocationHelper';

import { HiringPortalLeadDrawerExperience } from './components/HiringPortalLeadDrawerExperience';
import { HiringPortalLeadDrawerEducation } from './components/HiringPortalLeadDrawerEducation';
import { HiringPortalLeadDrawerSkills } from './components/HiringPortalLeadDrawerSkills';

interface HiringPortalLeadDrawerWithLeadNanoId {
  leadNanoId: JobCandidateNanoId;
  leadId?: never;
}

interface HiringPortalLeadDrawerWithLeadId {
  leadNanoId?: never;
  leadId: JobCandidateId;
}

type HiringPortalLeadDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
} & (HiringPortalLeadDrawerWithLeadNanoId | HiringPortalLeadDrawerWithLeadId);

function HiringPortalLeadDrawer({
  leadNanoId,
  leadId,
  isOpen,
  onClose
}: HiringPortalLeadDrawerProps) {
  const { jobCandidate, jobCandidateErrorMessage, jobCandidateIsFetched } =
    useFetchJobCandidateByNanoId<FetchJobCandidateResponse>({
      jobCandidateNanoId: (leadNanoId || leadId) as JobCandidateNanoId,
      query: fetchJobCandidateQuery,
      enabled: (!!leadNanoId || !!leadId) as FetchJobCandidateEnabled,
      cacheKey: JobCandidatesCache.showCacheKey()
    });

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      autoFocus={false}
      // closeOnOverlayClick={false}
    >
      <DrawerOverlay />

      <DrawerContent w="100%" maxW="20rem" boxShadow="none">
        <DrawerHeader p={4}>
          <Flex flexDir="column" alignItems="flex-start">
            <Button
              hierarchy="unstyled"
              onClick={onClose}
              display="flex"
              flexDirection="row"
              alignItems="center"
              h={6}
            >
              <CancelIcon fill="gray.500" />

              <Text color="gray.600" textStyle="regular" fontSize="14px" ml={1}>
                Close
              </Text>
            </Button>
          </Flex>
        </DrawerHeader>

        <DrawerBody>
          <LoadingSkeleton loaded={jobCandidateIsFetched}>
            <AlertMessage message={jobCandidateErrorMessage} />
            {jobCandidate ? (
              <Stack spacing={6}>
                <Stack spacing={2} flexDirection="column">
                  <Flex justifyContent="center">
                    <Flex pos="relative">
                      <AvatarHelper size="md" image={jobCandidate.image} />

                      <Flex
                        w={4}
                        h={4}
                        right={0}
                        bottom={0}
                        pos="absolute"
                        border="1px solid"
                        borderColor="White"
                        borderRadius="full"
                        alignItems="center"
                        justifyContent="center"
                        transitionDuration="fast"
                        bg={'red.100'}
                        // bg={selectedLead.favorite ? 'red.100' : 'red.200'}
                        // _hover={{
                        //   bg: !selectedLead.favorite && 'red.300'
                        // }}
                      >
                        <HeartIcon
                          w={2}
                          h={2}
                          fill={'red.500'}
                          // fill={selectedLead.favorite ? 'red.500' : 'white'}
                        />
                      </Flex>
                    </Flex>
                  </Flex>

                  <Flex flexDir="column" gap={1}>
                    <Flex
                      gap={2}
                      flex={1}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Heading level="h5" noOfLines={1} wordBreak="break-all">
                        {renderJobCandidateName(jobCandidate)}
                      </Heading>

                      <LinkedinLinkHelper item={jobCandidate}>
                        <Flex borderRadius="full" bg="#069" p={0.5}>
                          <LinkedInIcon borderRadius="full" />
                        </Flex>
                      </LinkedinLinkHelper>
                    </Flex>

                    <Flex flexDir="column" gap={0}>
                      <Flex
                        gap={2}
                        justifyContent="center"
                        flex={1}
                        alignItems="center"
                      >
                        <Text
                          noOfLines={1}
                          color="gray.600"
                          lineHeight="24px"
                          textAlign="center"
                          wordBreak="break-all"
                          textStyle="body2Regular"
                        >
                          {jobCandidate.jobTitle}
                        </Text>

                        <Flex gap={1} alignItems="center">
                          <Text>{1}</Text>
                          <AttachIcon w={3} h={3} fill="gray.500" />
                        </Flex>
                      </Flex>

                      <Flex flexDir="column">
                        <Flex
                          h={12}
                          gap={0}
                          flexDir="column"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Text
                            noOfLines={1}
                            color="gray.900"
                            textAlign="center"
                            wordBreak="break-all"
                            textStyle="body2Medium"
                          >
                            {jobCandidate.company?.name}
                          </Text>

                          <JobLocationHelper
                            jobCandidate={jobCandidate}
                            color="gray.600"
                            textStyle="body2Regular"
                            withIcon
                          />
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                </Stack>

                <Stack spacing={4}>
                  <HiringPortalLeadDrawerExperience
                    jobCandidateId={jobCandidate.id}
                  />

                  <HiringPortalLeadDrawerEducation
                    jobCandidateId={jobCandidate.id}
                  />

                  <HiringPortalLeadDrawerSkills
                    jobCandidateId={jobCandidate.id}
                  />
                </Stack>
              </Stack>
            ) : null}
          </LoadingSkeleton>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export default HiringPortalLeadDrawer;
