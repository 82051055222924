import {
  FetchJobTitlesCacheKey,
  FetchJobTitleCacheKey
} from './jobTitlesTypes';

export class JobTitlesCache {
  static indexCacheKey() {
    return 'jobTitles' as FetchJobTitlesCacheKey;
  }

  static showCacheKey() {
    return 'jobTitle' as FetchJobTitleCacheKey;
  }

  static existsCacheKey() {
    return 'jobTitles-exists' as FetchJobTitlesCacheKey;
  }
}
