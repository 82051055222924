import { useQueryClient } from 'react-query';
import { useRouter } from 'next/router';

import { RouterPath, previousRouterCacheKey } from './usePreviousRouter.types';

function useSubscribeToPreviousRouter() {
  const router = useRouter();

  const queryClient = useQueryClient();

  router.events?.on('beforeHistoryChange', () => {
    queryClient.setQueryData(
      previousRouterCacheKey,
      router.asPath as RouterPath
    );
  });
}

export default useSubscribeToPreviousRouter;
