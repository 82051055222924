import {
  HtmlPageItemBffID,
  HtmlPageItemBffNanoID,
  FetchHtmlPagesBffURL,
  FetchHtmlPageBffURL,
  FetchHtmlPageByNanoIdBffURL,
  CreateHtmlPageBffURL,
  UpdateHtmlPageBffURL,
  DeleteHtmlPageBffURL
} from './htmlPagesTypes';

import { BaseBffRoutes } from '../BaseBffRoutes';

export class HtmlPagesBffRoutes extends BaseBffRoutes {
  protected static route = 'htmlPages';

  static fetchHtmlPagesRoute() {
    return this.fetchItemsRoute<FetchHtmlPagesBffURL>();
  }

  static fetchHtmlPagesIndexRoute() {
    return this.fetchItemsIndexRoute<FetchHtmlPagesBffURL>();
  }

  static fetchHtmlPageRoute(cityItemBffID: HtmlPageItemBffID) {
    return this.fetchItemRoute<FetchHtmlPageBffURL>(cityItemBffID);
  }

  static fetchHtmlPageIndexRoute(cityItemBffNanoID: HtmlPageItemBffNanoID) {
    return this.fetchItemIndexRoute<FetchHtmlPageBffURL>(cityItemBffNanoID);
  }

  static fetchHtmlPageByNanoIdRoute(cityItemBffNanoID: HtmlPageItemBffNanoID) {
    return this.fetchItemByNanoIdRoute<FetchHtmlPageByNanoIdBffURL>(
      cityItemBffNanoID
    );
  }

  static createHtmlPageRoute() {
    return this.createItemRoute<CreateHtmlPageBffURL>();
  }

  static updateHtmlPageRoute(
    cityItemBffNanoID: HtmlPageItemBffID | HtmlPageItemBffNanoID
  ) {
    return this.updateItemRoute<UpdateHtmlPageBffURL>(cityItemBffNanoID);
  }

  static deleteHtmlPageRoute(cityItemBffNanoID: HtmlPageItemBffNanoID) {
    return this.deleteItemRoute<DeleteHtmlPageBffURL>(cityItemBffNanoID);
  }
}
