import { Flex } from '@chakra-ui/layout';
import React from 'react';
import { Button } from '../../../../../../helpers/Button';
import { Icon } from '../../../../../../helpers/Icon';
import { Text } from '../../../../../../helpers/Text';
import { IconsEnum } from '../../../../../../icons';

interface RightSidebarIconButtonProps {
  icon: IconsEnum;
  isOpen: boolean;
  title: string;
  onClick: () => void;
}

const RightSidebarIconButton = ({
  icon,
  title,
  isOpen = false,
  onClick
}: RightSidebarIconButtonProps) => {
  return (
    <Button
      p={0}
      height="auto"
      border="none"
      hierarchy="unstyled"
      onClick={onClick}
    >
      <Flex
        w={12}
        h={12}
        alignItems="center"
        justifyContent="center"
        border="1px solid"
        borderColor={isOpen ? 'primary.500' : 'transparent'}
        outline="2px solid"
        outlineColor={isOpen ? 'primary.100' : 'transparent'}
        borderRadius="full"
      >
        <Icon icon={icon} />
      </Flex>
      <Text textStyle="body2Regular" mt={2}>
        {title}
      </Text>
    </Button>
  );
};

export default RightSidebarIconButton;
