import snakeCase from 'lodash/snakeCase';

import {
  FetchJobsQuery,
  JobCreatedAt,
  JobFields,
  JobId,
  JobNanoId,
  JobName,
  JobDescription,
  JobDepartmentKey,
  JobWorkplace,
  JobCompany,
  JobCity,
  JobHiringManagerId,
  JobHiringManager,
  JobCityId,
  JobRecruiterId,
  JobRecruiter,
  JobEmployment,
  JobBaseSalary,
  JobExperience,
  JobUpdatedAt,
  JobSeniorityLevel,
  JobJobCandidates,
  MayBeJobCityIds,
  MayBeJobCities,
  JobJobTitle,
  JobJobTitleId,
  JobJobFunction,
  JobJobFunctionId,
  JobDisciplineId,
  JobDiscipline,
  JobDepartmentId,
  JobDepartment,
  JobIndustryIds,
  JobIndustries,
  JobGeography,
  JobStatus,
  JobPriority
} from '../jobsTypes';

import { FetchJobsApiQuery } from '../../../api/jobs/jobsApiTypes';

export interface FetchJobsResponse {
  [JobFields.ID]: JobId;
  [JobFields.NANO_ID]: JobNanoId;
  [JobFields.NAME]: JobName;
  [JobFields.DESCRIPTION]: JobDescription;
  [JobFields.DEPARTMENT_KEY]: JobDepartmentKey;
  [JobFields.EMPLOYMENT]: JobEmployment;
  [JobFields.BASE_SALARY]: JobBaseSalary;
  [JobFields.EXPERIENCE]: JobExperience;
  [JobFields.WORKPLACE]: JobWorkplace;
  [JobFields.SENIORITY_LEVEL]: JobSeniorityLevel;
  [JobFields.COMPANY]: JobCompany;
  [JobFields.CITY_ID]: JobCityId;
  [JobFields.CITY]: JobCity;
  [JobFields.CITY_IDS]: MayBeJobCityIds;
  [JobFields.CITIES]: MayBeJobCities;
  [JobFields.HIRING_MANAGER_ID]: JobHiringManagerId;
  [JobFields.RECRUITER_ID]: JobRecruiterId;
  [JobFields.RECRUITER]: JobRecruiter;
  [JobFields.RECRUITER_ID]: JobRecruiterId;
  [JobFields.HIRING_MANAGER]: JobHiringManager;
  [JobFields.CREATED_AT]: JobCreatedAt;
  [JobFields.UPDATED_AT]: JobUpdatedAt;
  [JobFields.JOB_CANDIDATES]: JobJobCandidates;
  [JobFields.JOB_TITLE]: JobJobTitle;
  [JobFields.JOB_TITLE_ID]: JobJobTitleId;
  [JobFields.JOB_FUNCTION]: JobJobFunction;
  [JobFields.JOB_FUNCTION_ID]: JobJobFunctionId;
  [JobFields.DISCIPLINE_ID]: JobDisciplineId;
  [JobFields.DISCIPLINE]: JobDiscipline;
  [JobFields.DEPARTMENT_ID]: JobDepartmentId;
  [JobFields.DEPARTMENT]: JobDepartment;
  [JobFields.INDUSTRY_IDS]: JobIndustryIds;
  [JobFields.INDUSTRIES]: JobIndustries;
  [JobFields.GEOGRAPHY]: JobGeography;
  [JobFields.STATUS]: JobStatus;
  [JobFields.JOB_PRIORITY]: JobPriority;
}

export const fetchJobsColumns = [
  JobFields.ID,
  JobFields.NANO_ID,
  JobFields.NAME,
  JobFields.DESCRIPTION,
  JobFields.DEPARTMENT_KEY,
  JobFields.EMPLOYMENT,
  JobFields.BASE_SALARY,
  JobFields.EXPERIENCE,
  JobFields.WORKPLACE,
  JobFields.SENIORITY_LEVEL,
  JobFields.CITY_ID,
  JobFields.RECRUITER_ID,
  JobFields.HIRING_MANAGER_ID,
  JobFields.CREATED_AT,
  JobFields.UPDATED_AT,
  JobFields.JOB_FUNCTION_ID,
  JobFields.JOB_TITLE_ID,
  JobFields.DISCIPLINE_ID,
  JobFields.DEPARTMENT_ID,
  JobFields.STATUS
];

const fetchJobsSBColumns = fetchJobsColumns.map((fetchJobsColumn) =>
  snakeCase(fetchJobsColumn)
);

export const fetchJobsQuery = `
  ${fetchJobsSBColumns.join(',')},
  company:company_id(id,name,image:image_id(id,nano_id,name,file,key,created_at)),
  city:city_id(id,name,label),
  hiring_manager:hiring_manager_id(id,name,image:image_id(id,nano_id,name,file,key,created_at)),
  recruiter:recruiter_id(id,name,nano_id),
  job_candidates!fk_rails_8877d24aca(id,nano_id)
` as FetchJobsQuery;

export const fetchJobsApiQuery = fetchJobsSBColumns.join(
  ','
) as FetchJobsApiQuery;
