import { IconsEnum } from '../../icons';

import { AddCandidateIcon } from '../../icons/AddCandidateIcon';
import { AvatarPlaceholderIcon } from '../../icons/AvatarPlaceholderIcon';
import { BellIcon } from '../../icons/BellIcon';
import { ChevronDownIcon } from '../../icons/ChevronDownIcon';
import { EyeIcon } from '../../icons/EyeIcon';
import { GoogleIcon } from '../../icons/GoogleIcon';
import { FoldersButtonIcon } from '../../icons/FoldersButtonIcon';
import { LinkedInIcon } from '../../icons/LinkedInIcon';
import { NotesButtonIcon } from '../../icons/NotesButtonIcon';
import { PlusIcon } from '../../icons/PlusIcon';
import { SearchIcon } from '../../icons/SearchIcon';
import { TasksButtonIcon } from '../../icons/TasksButtonIcon';
import { ActivitiesButtonIcon } from '../../icons/ActivitiesButtonIcon';
import { ScheduleButtonIcon } from '../../icons/ScheduleButtonIcon';
import { UpdatesButtonIcon } from '../../icons/UpdatesButtonIcon';
import { EmailButtonIcon } from '../../icons/EmailButtonIcon';
import { CalendarButtonIcon } from '../../icons/CalendarButtonIcon';

interface IconProps {
  id?: string;
  className?: string;
  h?: string;
  w?: string;
  icon: IconsEnum;
}

function Icon({ className, icon, id, h, w }: IconProps) {
  switch (icon) {
    case IconsEnum.ADD_CANDIDATE:
      return <AddCandidateIcon id={id} className={className} />;
    case IconsEnum.AVATAR_PLACEHOLDER:
      return <AvatarPlaceholderIcon id={id} className={className} />;
    case IconsEnum.BELL:
      return <BellIcon id={id} className={className} />;
    case IconsEnum.CHEVRON_DOWN:
      return <ChevronDownIcon id={id} className={className} />;
    case IconsEnum.GOOGLE:
      return <GoogleIcon id={id} className={className} />;
    case IconsEnum.EYE:
      return <EyeIcon id={id} className={className} />;
    case IconsEnum.FOLDERS_BUTTON:
      return <FoldersButtonIcon id={id} className={className} />;
    case IconsEnum.LINKED_IN:
      return <LinkedInIcon id={id} className={className} />;
    case IconsEnum.NOTES_BUTTON:
      return <NotesButtonIcon id={id} className={className} />;
    case IconsEnum.PLUS:
      return <PlusIcon id={id} className={className} h={h} w={w} />;
    case IconsEnum.SEARCH:
      return <SearchIcon id={id} className={className} />;
    case IconsEnum.TASKS_BUTTON:
      return <TasksButtonIcon id={id} className={className} />;
    case IconsEnum.ACTIVITIES_BUTTON:
      return <ActivitiesButtonIcon id={id} className={className} />;
    case IconsEnum.SCHEDULE_BUTTON:
      return <ScheduleButtonIcon id={id} className={className} />;
    case IconsEnum.UPDATES_BUTTON:
      return <UpdatesButtonIcon id={id} className={className} />;
    case IconsEnum.EMAIL_BUTTON:
      return <EmailButtonIcon id={id} className={className} />;
    case IconsEnum.CALENDAR_BUTTON:
      return <CalendarButtonIcon id={id} className={className} />;
    default:
      return null;
  }
}

export default Icon;
