import compact from 'lodash/compact';

import {
  FetchSkillBadgesCacheKey,
  FetchSkillBadgeCacheKey,
  FetchSkillBadgesIndexCacheKey,
  SkillBadgeJobId,
  SkillBadgeJobCandidateId,
  SkillBadgeJobResumeId,
  SkillBadgeJobResumeRecruiterId,
  SkillBadgeTalentPoolId,
  SkillBadgeProfileId,
  SkillBadgeContactId
} from './skillBadgesTypes';

export class SkillBadgesCache {
  static listCacheKey() {
    return 'skillBadges' as FetchSkillBadgesCacheKey;
  }

  static listIndexCacheKey() {
    return 'skillBadges-index' as FetchSkillBadgesIndexCacheKey;
  }

  static showCacheKey() {
    return 'skillBadge' as FetchSkillBadgeCacheKey;
  }

  static postJobCacheKey(skillBadgeJobId: SkillBadgeJobId) {
    return `skillBadges-post-job-${skillBadgeJobId}` as FetchSkillBadgesCacheKey;
  }

  static postJobIndexCacheKey(skillBadgeJobId: SkillBadgeJobId) {
    return `skillBadges-post-job-index-${skillBadgeJobId}` as FetchSkillBadgesIndexCacheKey;
  }

  static postJobCandidateCacheKey(
    skillBadgeJobCandidateId: SkillBadgeJobCandidateId
  ) {
    return `skillBadges-post-job-candidate-${skillBadgeJobCandidateId}` as FetchSkillBadgesCacheKey;
  }

  static postJobCandidateIndexCacheKey(
    skillBadgeJobCandidateId: SkillBadgeJobCandidateId
  ) {
    return `skillBadges-post-job-candidate-index-${skillBadgeJobCandidateId}` as FetchSkillBadgesIndexCacheKey;
  }

  static postJobResumeCacheKey(skillBadgeJobResumeId: SkillBadgeJobResumeId) {
    return `skillBadges-post-job-resume-${skillBadgeJobResumeId}` as FetchSkillBadgesCacheKey;
  }

  static postJobResumeIndexCacheKey(
    skillBadgeJobResumeId: SkillBadgeJobResumeId
  ) {
    return `skillBadges-post-job-resume-index-${skillBadgeJobResumeId}` as FetchSkillBadgesIndexCacheKey;
  }

  static jobResumeRecruiterCacheKey(
    skillBadgeJobResumeRecruiterId: SkillBadgeJobResumeRecruiterId,
    part: string | null = null
  ) {
    return compact([
      `skillBadges-job-resume-recruiter-${skillBadgeJobResumeRecruiterId}`,
      part
    ]).join('-') as FetchSkillBadgesCacheKey;
  }

  static jobResumeRecruiterIndexCacheKey(
    skillBadgeJobResumeRecruiterId: SkillBadgeJobResumeRecruiterId
  ) {
    return `skillBadges-job-resume-recruiter-index-${skillBadgeJobResumeRecruiterId}` as FetchSkillBadgesIndexCacheKey;
  }

  static talentPoolFormCacheKey(
    skillBadgeTalentPoolId: SkillBadgeTalentPoolId
  ) {
    return `skillBadges-talent-pool-form-${skillBadgeTalentPoolId}` as FetchSkillBadgesCacheKey;
  }

  static profileCacheKey(profileId: SkillBadgeProfileId) {
    return `skillBadges-profile-form-${profileId}` as FetchSkillBadgesCacheKey;
  }

  static contactCacheKey(contactId: SkillBadgeContactId) {
    return `skillBadges-contact-form-${contactId}` as FetchSkillBadgesCacheKey;
  }

  static jobCandidateCacheKey(
    skillBadgeJobCandidateId: SkillBadgeJobCandidateId
  ) {
    return `skillBadges-job-candidate-${skillBadgeJobCandidateId}` as FetchSkillBadgesCacheKey;
  }
}
