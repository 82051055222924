import { useEffect } from 'react';
import { MainActiveLink, useActiveLink } from '../../../../context';

const useSetActiveLink = (link: MainActiveLink) => {
  const { setActiveLink } = useActiveLink();

  useEffect(() => {
    setActiveLink(link);
  }, [link, setActiveLink]);
};

export default useSetActiveLink;
