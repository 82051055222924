/* eslint-disable @typescript-eslint/no-unused-vars */
import { Collapse, Stack, StackDivider, useDisclosure } from '@chakra-ui/react';
import { MouseEvent, useState } from 'react';
import { ViewJobCandidateModalPhoneSidebarCallActionButton } from '../../../../components';
import { ScheduleMeeting } from '../../../../../../../../../../../pages/ViewJobCandidatesPage/helpers/ScheduleMeeting';

type ViewJobCandidateModalPhoneSidebarInterestedType =
  | 'schedule-interview'
  | 'assign-task'
  | 'schedule-meeting'
  | 'complete-task';

interface ViewJobCandidateModalPhoneSidebarScheduleHelperProps {
  withoutMargin: boolean;
}

const ViewJobCandidateModalPhoneSidebarScheduleHelper = ({
  withoutMargin
}: ViewJobCandidateModalPhoneSidebarScheduleHelperProps) => {
  const [selectedOption, setSelectedOption] =
    useState<ViewJobCandidateModalPhoneSidebarInterestedType | null>();

  const toggleSelection = (e: MouseEvent<HTMLButtonElement>) => {
    const { value } = e.currentTarget;

    if (!value || selectedOption === value) {
      setSelectedOption(null);
      return;
    }

    setSelectedOption(value as ViewJobCandidateModalPhoneSidebarInterestedType);
  };

  const {
    isOpen: isOpenScheduleMeeting,
    onOpen: onOpenScheduleMeeting,
    onClose: onCloseScheduleMeeting
  } = useDisclosure();

  return (
    <>
      <Collapse in={!selectedOption} unmountOnExit>
        <Stack
          flex={1}
          spacing={0}
          overflow="hidden"
          borderRadius="4px"
          border="1px solid"
          borderColor="gray.300"
          divider={<StackDivider />}
          boxShadow="0px 2px 2px rgba(23, 25, 26, 0.06)"
        >
          <Stack direction="row" spacing={0} divider={<StackDivider />}>
            <ViewJobCandidateModalPhoneSidebarCallActionButton
              value="schedule-interview"
              label="Schedule Interview"
              onClick={onOpenScheduleMeeting}
            />

            <ViewJobCandidateModalPhoneSidebarCallActionButton
              value="schedule-meeting"
              label="Schedule Meeting"
              onClick={onOpenScheduleMeeting}
            />
          </Stack>
        </Stack>
      </Collapse>

      <ScheduleMeeting
        isOpen={isOpenScheduleMeeting}
        onClose={onCloseScheduleMeeting}
        withoutMargin={withoutMargin}
      />
    </>
  );
};

export default ViewJobCandidateModalPhoneSidebarScheduleHelper;
