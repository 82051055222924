import {
  FetchLikedJobCandidatesQuery,
  FetchLikedJobCandidatesCountType,
  FetchLikedJobCandidatesPage,
  FetchLikedJobCandidatesPageSize,
  FetchLikedJobCandidatesFilters,
  FetchLikedJobCandidatesSort,
  FetchLikedJobCandidateQuery,
  CreateLikedJobCandidateError,
  UpdateLikedJobCandidateError,
  DeleteLikedJobCandidateError,
  LikedJobCandidateId,
  LikedJobCandidateJobCandidateId,
  FetchLikedJobCandidatesIndexCountType,
  FetchLikedJobCandidatesIndexPage,
  FetchLikedJobCandidatesIndexPageSize,
  FetchLikedJobCandidatesIndexFilters,
  FetchLikedJobCandidatesIndexSort,
  FetchLikedJobCandidateIndexQueryName,
  FetchLikedJobCandidatesIndexQueryName,
  LikedJobCandidateUUID,
  LikedJobCandidateUserId
} from './likedJobCandidatesTypes';

import { BaseBffRequests } from '../BaseBffRequests';

import { LikedJobCandidatesBffRoutes } from './LikedJobCandidatesBffRoutes';

interface FetchLikedJobCandidatesRequestParams {
  query: FetchLikedJobCandidatesQuery;
  countType?: FetchLikedJobCandidatesCountType;
  page?: FetchLikedJobCandidatesPage;
  pageSize?: FetchLikedJobCandidatesPageSize;
  filters?: FetchLikedJobCandidatesFilters;
  sort?: FetchLikedJobCandidatesSort;
}

interface FetchLikedJobCandidatesIndexRequestParams {
  queryName: FetchLikedJobCandidatesIndexQueryName;
  countType?: FetchLikedJobCandidatesIndexCountType;
  page?: FetchLikedJobCandidatesIndexPage;
  pageSize?: FetchLikedJobCandidatesIndexPageSize;
  filters?: FetchLikedJobCandidatesIndexFilters;
  sort?: FetchLikedJobCandidatesIndexSort;
}

interface FetchLikedJobCandidateRequestParams {
  query: FetchLikedJobCandidateQuery;
}

interface FetchLikedJobCandidateIndexRequestParams {
  queryName: FetchLikedJobCandidateIndexQueryName;
}

export interface LikedJobCandidateRequestData {
  userId?: LikedJobCandidateUserId;
  jobCandidateId?: LikedJobCandidateJobCandidateId;
}

export interface CreateLikedJobCandidateRequestData
  extends LikedJobCandidateRequestData {
  id?: LikedJobCandidateId;
}

export interface UpdateLikedJobCandidateRequestData
  extends LikedJobCandidateRequestData {
  id?: LikedJobCandidateId;
}

export interface CreateLikedJobCandidateErrorResponse {
  message: CreateLikedJobCandidateError;
}

export interface UpdateLikedJobCandidateErrorResponse {
  message: UpdateLikedJobCandidateError;
}

export interface DeleteLikedJobCandidateErrorResponse {
  message: DeleteLikedJobCandidateError;
}

export class LikedJobCandidatesBffRequests extends BaseBffRequests {
  static fetchLikedJobCandidates<T>(
    params: FetchLikedJobCandidatesRequestParams
  ) {
    return this.fetchItems<T>(
      LikedJobCandidatesBffRoutes.fetchLikedJobCandidatesRoute(),
      params
    );
  }

  static fetchLikedJobCandidatesIndex<T>(
    params: FetchLikedJobCandidatesIndexRequestParams
  ) {
    return this.fetchItemsIndex<T>(
      LikedJobCandidatesBffRoutes.fetchLikedJobCandidatesIndexRoute(),
      params
    );
  }

  static fetchLikedJobCandidate<T>(
    likedJobCandidateId: LikedJobCandidateId,
    params: FetchLikedJobCandidateRequestParams
  ) {
    return this.fetchItem<T>(
      LikedJobCandidatesBffRoutes.fetchLikedJobCandidateRoute(
        likedJobCandidateId
      ),
      params
    );
  }

  static fetchLikedJobCandidateIndex<T>(
    likedJobCandidateUuid: LikedJobCandidateUUID,
    params: FetchLikedJobCandidateIndexRequestParams
  ) {
    return this.fetchItemIndex<T>(
      LikedJobCandidatesBffRoutes.fetchLikedJobCandidateIndexRoute(
        likedJobCandidateUuid
      ),
      params
    );
  }

  static createLikedJobCandidate<T>(
    createLikedJobCandidateData: CreateLikedJobCandidateRequestData
  ) {
    return this.createItem<T, CreateLikedJobCandidateRequestData>(
      LikedJobCandidatesBffRoutes.createLikedJobCandidateRoute(),
      createLikedJobCandidateData
    );
  }

  static async updateLikedJobCandidate<T>(
    updateLikedJobCandidateId: LikedJobCandidateId,
    updateLikedJobCandidateData: UpdateLikedJobCandidateRequestData
  ) {
    return this.updateItem<T, UpdateLikedJobCandidateRequestData>(
      LikedJobCandidatesBffRoutes.updateLikedJobCandidateRoute(
        updateLikedJobCandidateId
      ),
      updateLikedJobCandidateData
    );
  }

  static async deleteLikedJobCandidate<T>(
    deleteLikedJobCandidateId: LikedJobCandidateId
  ) {
    return this.deleteItem<T>(
      LikedJobCandidatesBffRoutes.deleteLikedJobCandidateRoute(
        deleteLikedJobCandidateId
      )
    );
  }
}
