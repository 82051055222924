import { Box, Flex, SimpleGrid, VStack } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { CandidatesIcon } from '../../icons/CandidatesIcon';
import { JobsIcon } from '../../icons/JobsIcon';
import { MoreIcon } from '../../icons/MoreIcon';
import { JobsRoutes } from '../../main/jobs/JobsRoutes';
import { SourcingRoutes } from '../../main/sourcing/SourcingRoutes';
import { NextLinkHelper } from '../NextLinkHelper';
import { Text } from '../Text';
import { MainActiveLink } from '../../context';

const MobileFooterNav = ({ activeLink }: { activeLink?: MainActiveLink }) => {
  return (
    <Box position="fixed" w="100vw" bottom={0} zIndex={9}>
      <SimpleGrid
        columns={3}
        bottom={0}
        bg="white"
        boxShadow="0px -2px 16px rgba(209, 209, 209, 0.6)"
      >
        <MobileFootNavItem
          title="Jobs"
          icon={JobsIcon}
          url={JobsRoutes.index()}
          isActive={activeLink === 'jobs'}
        />

        <MobileFootNavItem
          title="Candidates"
          url={SourcingRoutes.prospects()}
          icon={CandidatesIcon}
          isActive={activeLink === 'sourcing'}
        />

        <MobileFootNavItem title="More" url="#" icon={MoreIcon} />
      </SimpleGrid>
    </Box>
  );
};

export default MobileFooterNav;

interface MobileFootNavItemProps {
  icon: FunctionComponent;
  title: string;
  isActive?: boolean;
  url: string;
}

const MobileFootNavItem = ({
  icon,
  title,
  url,
  isActive
}: MobileFootNavItemProps) => {
  return (
    <NextLinkHelper href={url} passHref>
      <VStack
        as="a"
        pt={3}
        pb={2}
        spacing={0}
        alignItems="center"
        justifyContent="center"
      >
        <Flex h={6} alignItems="center" justifyContent="center">
          <Flex as={icon} fill={isActive ? 'primary.500' : 'gray.500'} />
        </Flex>

        <Text
          textStyle="body2Regular"
          color={isActive ? 'primary.500' : 'gray.600'}
        >
          {title}
        </Text>
      </VStack>
    </NextLinkHelper>
  );
};
