import { Icon, IconProps } from '@chakra-ui/icon';

const FolderIcon = ({
  w = '16px',
  h = '16px',
  fill = 'gray.500',
  ...props
}: IconProps) => (
  <Icon viewBox="0 0 20 20" w={w} h={h} fill={fill} {...props}>
    <path
      fillRule="evenodd"
      d="M3.25 3.625v1.25h4.688c.393 0 .763.185 1 .5l1.5 2h5.312v-3.75A.625.625 0 0015.125 3H3.875a.625.625 0 00-.625.625z"
      clipRule="evenodd"
    />

    <path
      fillRule="evenodd"
      d="M16.375 8.625H9.812l-1.687-2.25a.625.625 0 00-.5-.25h-5A.625.625 0 002 6.75v10.625c0 .345.28.625.625.625h13.75c.346 0 .625-.28.625-.625V9.25a.625.625 0 00-.625-.625z"
      clipRule="evenodd"
    />
  </Icon>
);

export default FolderIcon;
