import trim from 'lodash/trim';

import { isEmail } from '../../../../../../../utils/isEmail';

const nameRules = {
  required: 'Name is required'
};

const titleRules = {
  required: 'Title is required'
};

const emailRules = {
  required: 'Email is required',
  validate: {
    isValid: (value: string) => isEmail(trim(value)) || 'Email is invalid'
  }
};

function useInviteUserFormValidationRules() {
  return {
    nameRules,
    titleRules,
    emailRules
  };
}

export default useInviteUserFormValidationRules;
