import snakeCase from 'lodash/snakeCase';

import {
  FetchCompaniesQuery,
  CompanyFields,
  CompanyId,
  CompanyNanoId,
  CompanyName,
  CompanyCity,
  CompanyCompanyType,
  CompanyEmployeesNumber,
  CompanyImage,
  CompanyIndustry,
  CompanyState,
  CompanyCreatedAt,
  CompanyRevenue,
  CompanyLinkedinUrl
} from '../companiesTypes';

import { FetchCompaniesApiQuery } from '../../../api/companies/companiesApiTypes';

export interface FetchCompaniesResponse {
  [CompanyFields.ID]: CompanyId;
  [CompanyFields.NANO_ID]: CompanyNanoId;
  [CompanyFields.NAME]: CompanyName;
  [CompanyFields.COMPANY_TYPE]: CompanyCompanyType;
  [CompanyFields.IMAGE]: CompanyImage;
  [CompanyFields.INDUSTRY]: CompanyIndustry;
  [CompanyFields.EMPLOYEES_NUMBER]: CompanyEmployeesNumber;
  [CompanyFields.CITY]: CompanyCity;
  [CompanyFields.STATE]: CompanyState;
  [CompanyFields.CREATED_AT]: CompanyCreatedAt;
  [CompanyFields.REVENUE]: CompanyRevenue;
  [CompanyFields.LINKEDIN_URL]: CompanyLinkedinUrl;
}

export const fetchCompaniesColumns = [
  CompanyFields.ID,
  CompanyFields.NANO_ID,
  CompanyFields.NAME,
  CompanyFields.COMPANY_TYPE,
  CompanyFields.CREATED_AT
];

const fetchCompaniesSBColumns = fetchCompaniesColumns.map(
  (fetchCompaniesColumn) => snakeCase(fetchCompaniesColumn)
);

export const fetchCompaniesQuery = `
  ${fetchCompaniesSBColumns.join(',')},
  user:user_id(id,name),
  image:image_id(id,nano_id,name,file,key,created_at),
  state:state_id(id,name,iso_code),
  city:city_id(id,name),
  industry:industry_id(id,name),
  employees_number:employees_number_id(id,start_of_range,end_of_range)
` as FetchCompaniesQuery;

export const fetchCompaniesApiQuery = fetchCompaniesSBColumns.join(
  ','
) as FetchCompaniesApiQuery;

export const fetchCompaniesSignUpQuery = fetchCompaniesSBColumns.join(
  ','
) as FetchCompaniesQuery;
