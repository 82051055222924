import { Flex, Link, useDisclosure } from '@chakra-ui/react';
import { FunctionComponent, ReactNode } from 'react';
import { NextLinkHelper } from '../../../../../../../../helpers/NextLinkHelper';
import { Text } from '../../../../../../../../helpers/Text';
import { useRightSidebarContext } from '../../../../../../../../context';

interface ProductsMenuPopupProductItemProps {
  label: ReactNode;
  href: string;
  icon: FunctionComponent;
  isActive?: boolean;
}
const ProductsMenuPopupProductItem = ({
  icon,
  label,
  href,
  isActive
}: ProductsMenuPopupProductItemProps) => {
  const { isOpen: isHovered, onToggle } = useDisclosure();
  const { toggleProductsSidebar } = useRightSidebarContext();

  return (
    <NextLinkHelper href={href} passHref>
      <Link
        gap={3}
        onClick={toggleProductsSidebar}
        display="flex"
        alignItems="center"
        onMouseEnter={onToggle}
        onMouseLeave={onToggle}
        _hover={{
          textDecoration: 'none'
        }}
      >
        <Flex
          w={6}
          h={6}
          as={icon}
          fill={isActive || isHovered ? 'primary.500' : 'gray.500'}
          transitionProperty="fill"
          transitionDuration="fast"
        />

        <Text
          as="span"
          color="gray.900"
          textStyle="body1Regular"
          transitionProperty="color"
          transitionDuration="fast"
        >
          {label}
        </Text>
      </Link>
    </NextLinkHelper>
  );
};

export default ProductsMenuPopupProductItem;
