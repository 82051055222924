import React from 'react';
import { Avatar } from '@chakra-ui/avatar';

import { UserImage } from '../../main/users/usersTypes';

import { ImagesUrl } from '../../main/images/ImagesUrl';

interface HeaderAvatarProps {
  image: UserImage;
  name?: string;
}

function HeaderAvatar({ image, name }: HeaderAvatarProps) {
  return (
    <Avatar
      w="40px"
      h="40px"
      src={ImagesUrl.file(image) || undefined}
      name={name}
    />
  );
}

export default HeaderAvatar;
