import { useDisclosure } from '@chakra-ui/react';
import { Button } from '../../../../../../helpers/Button';
import { PlusIcon } from '../../../../../../icons/PlusIcon';
import { useAddCandidateToJob } from '../../../../../jobCandidates/hooks/useAddCandidateToJob';
import { FetchJobCandidateCacheKey } from '../../../../../jobCandidates/jobCandidatesTypes';
import { JobsCache } from '../../../../JobsCache';
import { ViewJobCandidateAddToJobItem } from '../../../../components/modals/ViewJobCandidateModal/tabs/ViewJobCandidateModalProfileTab/components/ViewJobCandidateAddToJob/ViewJobCandidateAddToJob';
import {
  FetchJobsCountType,
  FetchJobsFilters,
  FetchJobsSort,
  JobFields,
  JobNanoId
} from '../../../../jobsTypes';
import { AddCandidateToJob } from '../../../ViewJobCandidatesPage/helpers/AddCandidateToJob';
import { JobCandidateDetailsJobCandidate } from '../../ViewJobCandidateDetailsPage.types';
import { usePaginatedJobs } from '../../../../hooks/usePaginatedJobs';
import { jobsInitialPageSize } from '../../../../jobs.data';
import { fetchJobQuery } from '../../../../queries/fetchJob.query';
import {
  FetchJobsResponse,
  fetchJobsQuery
} from '../../../../queries/fetchJobs.query';
import { ViewJobCandidateDetailsJobDetailsJob } from '../ViewJobCandidateDetailsJobDetails';

interface ViewJobCandidateDetailsHeaderMenuAddJobProps {
  jobCandidate: JobCandidateDetailsJobCandidate;
  setSelectedJobs?: (jobs: ViewJobCandidateDetailsJobDetailsJob) => void;
}

export default function ViewJobCandidateDetailsHeaderMenuAddJob({
  jobCandidate,
  setSelectedJobs
}: ViewJobCandidateDetailsHeaderMenuAddJobProps) {
  const {
    isOpen: isOpenAddToJob,
    onToggle: onToggleAddToJob,
    onClose: onCloseAddToJob
  } = useDisclosure();

  const { jobs, jobsIsLoading } = usePaginatedJobs<FetchJobsResponse>({
    cacheKey: JobsCache.jobJobCandidateIndexCacheKey(jobCandidate.id),
    query: fetchJobsQuery,
    countType: 'exact' as FetchJobsCountType,
    fetchItemCacheKey: JobsCache.showCacheKey(),
    itemQuery: fetchJobQuery,
    initialFilters: {
      [JobFields.JOB_CANDIDATE_ID]: {
        operator: 'eq',
        value: jobCandidate.id
      }
    } as unknown as FetchJobsFilters,
    initialSort: {
      [JobFields.NAME]: { ascending: true }
    } as unknown as FetchJobsSort,
    initialPageSize: jobsInitialPageSize
  });

  const {
    addCandidateToJob,
    addCandidateToJobErrorMessage,
    addCandidateToJobIsLoading
  } = useAddCandidateToJob({
    jobCandidateNanoId: jobCandidate?.nanoId,
    cacheKeys: [
      JobsCache.jobJobCandidateIndexCacheKey(
        jobCandidate.id
      ) as unknown as FetchJobCandidateCacheKey
    ]
  });

  const onAddToJob = async (job: ViewJobCandidateAddToJobItem) => {
    await addCandidateToJob({
      jobNanoId: job.nanoId as JobNanoId
    });
    setSelectedJobs?.(job as ViewJobCandidateDetailsJobDetailsJob);
    onCloseAddToJob();
  };

  return (
    <AddCandidateToJob
      isOpen={isOpenAddToJob}
      onClose={onCloseAddToJob}
      onAddToJob={onAddToJob}
      offset={[0, 8]}
      addedJobs={jobs}
      placement="bottom-start"
      addToJobErrorMessage={addCandidateToJobErrorMessage}
      addToJobIsLoading={addCandidateToJobIsLoading}
    >
      <Button
        h={8}
        size="medium"
        display="flex"
        color="primary.500"
        hierarchy="unstyled"
        disabled={jobsIsLoading}
        onClick={onToggleAddToJob}
        leftIcon={<PlusIcon w={5} h={5} fill="primary.500" />}
      >
        Add job
      </Button>
    </AddCandidateToJob>
  );
}
