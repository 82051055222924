import { ButtonGroup, Flex, Stack } from '@chakra-ui/react';
import { Button } from '../../../../../../../../../../../../../../../helpers/Button';
import { ViewJobCandidateModalPhoneSidebarTextArea } from '../../../../../../components';

interface ViewJobCandidateModalPhoneSidebarAvailabilityCommentProps {
  onDiscard: () => void;
  onSave: () => void;
}

const ViewJobCandidateModalPhoneSidebarAvailabilityComment = ({
  onDiscard,
  onSave
}: ViewJobCandidateModalPhoneSidebarAvailabilityCommentProps) => {
  return (
    <Stack spacing={4}>
      <Flex direction="column">
        <ViewJobCandidateModalPhoneSidebarTextArea
          schedulingOpenDefault
          allowScheduling
        />
      </Flex>

      <ButtonGroup justifyContent="flex-end">
        <Button size="small" hierarchy="link" onClick={onDiscard}>
          Cancel
        </Button>

        <Button size="small" onClick={onSave}>
          Save
        </Button>
      </ButtonGroup>
    </Stack>
  );
};

export default ViewJobCandidateModalPhoneSidebarAvailabilityComment;
