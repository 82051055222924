import { FetchItemsFilters } from '../../../../../../types';

import { PaginatedItemsReducerAction } from '../../reducers/paginatedItemsReducer';

import { CHANGE_ITEMS_FILTERS } from '../../usePaginatedItems.constants';

function changeItemsFiltersAction(
  changedFilters: Partial<FetchItemsFilters>,
  removeFilters: string[]
): PaginatedItemsReducerAction {
  return {
    type: CHANGE_ITEMS_FILTERS,
    changedFilters,
    removeFilters
  };
}

export default changeItemsFiltersAction;
