import {
  FetchJobTitlesQuery,
  FetchJobTitlesCountType,
  FetchJobTitlesPage,
  FetchJobTitlesPageSize,
  FetchJobTitlesFilters,
  FetchJobTitlesSort,
  FetchJobTitlesIndexQueryName,
  FetchJobTitlesIndexCountType,
  FetchJobTitlesIndexPage,
  FetchJobTitlesIndexPageSize,
  FetchJobTitlesIndexFilters,
  FetchJobTitlesIndexSort,
  FetchJobTitleQuery,
  FetchJobTitleIndexQueryName,
  CreateJobTitleError,
  UpdateJobTitleError,
  DeleteJobTitleError,
  JobTitleId,
  JobTitleNanoId,
  JobTitleName,
  JobTitleUserId,
  JobTitleJobFunctionId
} from './jobTitlesTypes';

import { BaseBffRequests } from '../BaseBffRequests';

import { JobTitlesBffRoutes } from './JobtitlesBffRoutes';

interface FetchJobTitlesRequestParams {
  query: FetchJobTitlesQuery;
  countType?: FetchJobTitlesCountType;
  page?: FetchJobTitlesPage;
  pageSize?: FetchJobTitlesPageSize;
  filters?: FetchJobTitlesFilters;
  sort?: FetchJobTitlesSort;
}

interface FetchJobTitlesIndexRequestParams {
  queryName: FetchJobTitlesIndexQueryName;
  countType?: FetchJobTitlesIndexCountType;
  page?: FetchJobTitlesIndexPage;
  pageSize?: FetchJobTitlesIndexPageSize;
  filters?: FetchJobTitlesIndexFilters;
  sort?: FetchJobTitlesIndexSort;
}

interface FetchJobTitleRequestParams {
  query: FetchJobTitleQuery;
}

interface FetchJobTitleIndexRequestParams {
  queryName: FetchJobTitleIndexQueryName;
}

export interface JobTitleRequestData {
  name?: JobTitleName;
  jobFunctionId?: JobTitleJobFunctionId;
}

export interface CreateJobTitleRequestData extends JobTitleRequestData {
  id?: JobTitleId;
  userId?: JobTitleUserId;
  nanoId?: JobTitleNanoId;
  jobFunctionId?: JobTitleJobFunctionId;
}

export interface UpdateJobTitleRequestData extends JobTitleRequestData {
  name?: JobTitleName;
}

export interface DeleteJobTitleRequestData {
  jobTitleNanoId: JobTitleNanoId;
}

export interface CreateJobTitleErrorResponse {
  message: CreateJobTitleError;
}

export interface UpdateJobTitleErrorResponse {
  message: UpdateJobTitleError;
}

export interface DeleteJobTitleErrorResponse {
  message: DeleteJobTitleError;
}

export class JobTitlesBffRequests extends BaseBffRequests {
  static fetchJobTitles<T>(params: FetchJobTitlesRequestParams) {
    return this.fetchItems<T>(JobTitlesBffRoutes.fetchJobTitlesRoute(), params);
  }

  static fetchJobTitlesIndex<T>(params: FetchJobTitlesIndexRequestParams) {
    return this.fetchItemsIndex<T>(
      JobTitlesBffRoutes.fetchJobTitlesIndexRoute(),
      params
    );
  }

  static fetchJobTitle<T>(
    jobTitleId: JobTitleId,
    params: FetchJobTitleRequestParams
  ) {
    return this.fetchItem<T>(
      JobTitlesBffRoutes.fetchJobTitleRoute(jobTitleId),
      params
    );
  }

  static fetchJobTitleIndex<T>(
    jobTitleNanoId: JobTitleNanoId,
    params: FetchJobTitleIndexRequestParams
  ) {
    return this.fetchItemIndex<T>(
      JobTitlesBffRoutes.fetchJobTitleIndexRoute(jobTitleNanoId),
      params
    );
  }

  static fetchJobTitleByNanoId<T>(
    jobTitleNanoId: JobTitleNanoId,
    params: FetchJobTitleRequestParams
  ) {
    return this.fetchItemByNanoId<T>(
      JobTitlesBffRoutes.fetchJobTitleByNanoIdRoute(jobTitleNanoId),
      params
    );
  }

  static createJobTitle<T>(createJobTitleData: CreateJobTitleRequestData) {
    return this.createItem<T, CreateJobTitleRequestData>(
      JobTitlesBffRoutes.createJobTitleRoute(),
      createJobTitleData
    );
  }

  static async updateJobTitle<T>(
    updateJobTitleId: JobTitleId,
    updateJobTitleData: UpdateJobTitleRequestData
  ) {
    return this.updateItem<T, UpdateJobTitleRequestData>(
      JobTitlesBffRoutes.updateJobTitleRoute(updateJobTitleId),
      updateJobTitleData
    );
  }

  static async deleteJobTitle<T>(deleteJobTitleId: JobTitleId) {
    return this.deleteItem<T>(
      JobTitlesBffRoutes.deleteJobTitleRoute(deleteJobTitleId)
    );
  }
}
