import {
  MayBe,
  FieldBrand,
  ItemID,
  ItemIDs,
  ItemUUID,
  ItemNanoId,
  ItemCreatedAt
} from '../../../types';

import {
  UserId,
  UserNanoId,
  UserName,
  UserImage
} from '../../users/usersTypes';

export type SpecializationId = FieldBrand<ItemID, 'SpecializationId'>;
export type SpecializationIds = FieldBrand<ItemIDs, 'SpecializationIds'>;
export type SpecializationUuid = FieldBrand<ItemUUID, 'SpecializationUuid'>;
export type MayBeSpecializationUuid = MayBe<SpecializationUuid>;
export type SpecializationNanoId = FieldBrand<
  ItemNanoId,
  'SpecializationNanoId'
>;
export type MayBeSpecializationNanoId = MayBe<SpecializationNanoId>;
export type SpecializationName = FieldBrand<string, 'SpecializationName'>;
export type SpecializationUserId = FieldBrand<UserId, 'SpecializationUserId'>;
export type SpecializationUser = FieldBrand<
  {
    id: UserId;
    nanoId: UserNanoId;
    name: UserName;
    image: UserImage;
  },
  'SpecializationUser'
>;
export type SpecializationCreatedAt = FieldBrand<
  ItemCreatedAt,
  'SpecializationCreatedAt'
>;

export const enum SpecializationFields {
  ID = 'id',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  NAME = 'name',
  USER_ID = 'userId',
  USER = 'user',
  CREATED_AT = 'createdAt'
}
