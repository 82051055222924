import { JobCandidateNanoId } from '../jobCandidates/jobCandidatesTypes';
import {
  FetchCandidateShareRecipientsCacheKey,
  FetchCandidateShareRecipientCacheKey
} from './candidateShareRecipientsTypes';

export class CandidateShareRecipientsCache {
  static indexCacheKey() {
    return 'candidateShareRecipients' as FetchCandidateShareRecipientsCacheKey;
  }

  static jobCandidateIndexCacheKey(jobCandidateNanoId: JobCandidateNanoId) {
    return `candidateShareRecipients-${jobCandidateNanoId}` as FetchCandidateShareRecipientsCacheKey;
  }

  static showCacheKey() {
    return 'candidateShareRecipient' as FetchCandidateShareRecipientCacheKey;
  }
}
