import {
  Avatar,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  SimpleGrid,
  Stack,
  useDisclosure
} from '@chakra-ui/react';
import { Fragment, useCallback } from 'react';
import { useSignIn } from '../../../../../auth/hooks/useSignIn';
import { LoginMethodDivider } from '../../../../../auth/pages/SignInPage/helpers/LoginMethodDivider';
import { Button } from '../../../../../helpers/Button';
import { Heading } from '../../../../../helpers/Heading';
import { Logo } from '../../../../../helpers/Logo';
import { Text } from '../../../../../helpers/Text';
import { PureButtonHelper } from '../../../../../helpers/buttons/PureButtonHelper';
import { GoogleIcon } from '../../../../../icons/GoogleIcon';
import { LinkedInIcon } from '../../../../../icons/LinkedInIcon';
import { StarIcon } from '../../../../../icons/StarIcon';
import { RecruiterSignUpModalSignInForm } from './components/RecruiterSignUpModalSignInForm';
import { RecruiterSignUpModalSignUpForm } from './components/RecruiterSignUpModalSignUpForm';

interface RecruiterSignUpModalProps {
  isRecruiterSignUpModalOpened: boolean;
  toggleRecruiterSignUpModal: () => void;
}

function RecruiterSignUpModal({
  isRecruiterSignUpModalOpened,
  toggleRecruiterSignUpModal
}: RecruiterSignUpModalProps) {
  const { isOpen: isOpenSignUpForm, onOpen: onOpenSignUpForm } =
    useDisclosure();

  const {
    isOpen: isOpenSignInForm,
    onOpen: onOpenSignInForm,
    onClose: onCloseSignInForm
  } = useDisclosure();

  const { signInWithGoogle, signInWithLinkedIn } = useSignIn();

  const handleSignInWithGoogle = useCallback<() => void>(
    () => signInWithGoogle(),
    [signInWithGoogle]
  );

  /* const handleSignInWithMicrosoft = useCallback<() => void>(
    () => signInWithMicrosoft(),
    [signInWithMicrosoft]
  ); */

  const handleSignInWithLinkedIn = useCallback<() => void>(
    () => signInWithLinkedIn(),
    [signInWithLinkedIn]
  );

  return (
    <Modal
      size="lg"
      closeOnEsc
      closeOnOverlayClick={false}
      isOpen={isRecruiterSignUpModalOpened}
      onClose={toggleRecruiterSignUpModal}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={8}>
          <Stack spacing={8}>
            <Stack spacing={6}>
              <Stack spacing={2} textAlign="center" alignItems="center">
                <Logo w="110px" h="40px" />

                <Heading level="h2">The Best Recruiters Live Here</Heading>

                <Text
                  maxW="sm"
                  mx="auto"
                  color="gray.600"
                  textStyle="body1Regular"
                >
                  We&apos;ll help you engage and hire the best talent available
                  and build a network you&apos;ll be proud of!
                </Text>
              </Stack>

              <Flex alignItems="center" justifyContent="center">
                {avatars.map((item, index) => (
                  <Fragment key={item.id}>
                    {index < 4 && (
                      <Flex position="relative">
                        <Avatar
                          w={20}
                          h={20}
                          ml={-6}
                          src={item.avatarUrl}
                          border="4px solid"
                          borderColor="white"
                        />
                        <Flex
                          px={1.5}
                          gap={1}
                          bg="white"
                          height={4}
                          bottom={0}
                          alignItems="center"
                          borderRadius="full"
                          position="absolute"
                          border="1px solid"
                          justifyContent="center"
                          borderColor="gray.200"
                        >
                          <StarIcon w={2.5} h={2.5} filled />
                          <Text
                            textStyle="body2Medium"
                            fontSize="10px"
                            lineHeight={4}
                          >
                            4.8
                          </Text>
                        </Flex>
                      </Flex>
                    )}

                    {index == 4 && (
                      <Flex ml={-6} position="relative">
                        <Avatar
                          w={20}
                          h={20}
                          src={item.avatarUrl}
                          border="4px solid"
                          borderColor="white"
                        />
                        <Flex
                          top="0"
                          h="100%"
                          w="100%"
                          left="0"
                          pos="absolute"
                          border="4px solid"
                          borderColor="white"
                          alignItems="center"
                          borderRadius="full"
                          justifyContent="center"
                          bg="linear-gradient(0deg, rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.55))"
                        >
                          <Text
                            textStyle="body2Medium"
                            color="white"
                            noOfLines={1}
                          >
                            + {avatars.length - 5} more
                          </Text>
                        </Flex>
                      </Flex>
                    )}
                  </Fragment>
                ))}
              </Flex>

              <SimpleGrid columns={2} gap={3}>
                <Button
                  hierarchy="tertiary"
                  border="1px solid #E0DFE5"
                  color="gray.900"
                  leftIcon={<GoogleIcon />}
                  onClick={handleSignInWithGoogle}
                >
                  Google
                </Button>

                <Button
                  hierarchy="tertiary"
                  border="1px solid #E0DFE5"
                  color="gray.900"
                  leftIcon={<LinkedInIcon />}
                  onClick={handleSignInWithLinkedIn}
                >
                  LinkedIn
                </Button>

                {/* <Button
                  hierarchy="tertiary"
                  border="1px solid #E0DFE5"
                  color="gray.900"
                  leftIcon={<MicrosoftIcon />}
                  onClick={handleSignInWithMicrosoft}
                >
                  Microsoft
                </Button> */}
              </SimpleGrid>
            </Stack>

            <LoginMethodDivider />

            {isOpenSignInForm ? (
              <Stack spacing={6}>
                <RecruiterSignUpModalSignInForm />

                <Text textAlign="center">
                  Don&apos;t have an account?{' '}
                  <Button
                    hierarchy="link"
                    size="medium"
                    onClick={() => {
                      onCloseSignInForm();
                      onOpenSignUpForm();
                    }}
                  >
                    Sign up
                  </Button>
                </Text>
              </Stack>
            ) : (
              <Stack spacing={6}>
                {isOpenSignUpForm ? (
                  <RecruiterSignUpModalSignUpForm />
                ) : (
                  <PureButtonHelper
                    i18nText="Signup with email"
                    onClick={onOpenSignUpForm}
                  />
                )}

                <Text textAlign="center">
                  Already have an account?{' '}
                  <Button
                    hierarchy="link"
                    size="medium"
                    onClick={onOpenSignInForm}
                  >
                    Sign In
                  </Button>
                </Text>
              </Stack>
            )}
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default RecruiterSignUpModal;

const avatars = [
  {
    id: 1,
    name: 'Lincoln Hathaway',
    avatarUrl: '/images/team-mate-7.png'
  },
  {
    id: 2,
    name: 'Carolina Dabs',
    avatarUrl: '/images/team-mate-5.png'
  },
  {
    id: 3,
    name: 'Pedro Billison',
    avatarUrl: '/images/team-mate-6.png'
  },
  {
    id: 4,
    name: 'Dulcy Rutledge',
    avatarUrl: '/images/team-mate-2.png'
  },
  {
    id: 5,
    name: 'Dwain Crusham',
    avatarUrl: '/images/team-mate-8.png'
  },
  {
    id: 6,
    name: 'Ashla Smartman',
    avatarUrl: '/images/team-mate-3.png'
  },
  {
    id: 7,
    name: 'Lawry Nassie',
    avatarUrl: '/images/team-mate-1.png'
  },
  {
    id: 8,
    name: 'Oralia Grabbam',
    avatarUrl: '/images/team-mate-0.png'
  },
  {
    id: 9,
    name: 'Dav McBrearty',
    avatarUrl: '/images/team-mate-7.png'
  },
  {
    id: 10,
    name: 'Chase Bock',
    avatarUrl: '/images/team-mate-0.png'
  },
  {
    id: 11,
    name: 'Vlad Hillborne',
    avatarUrl: '/images/team-mate-0.png'
  },
  {
    id: 12,
    name: 'Farleigh Slorach',
    avatarUrl: '/images/team-mate-1.png'
  },
  {
    id: 13,
    name: 'Lanie Saggs',
    avatarUrl: '/images/team-mate-0.png'
  },
  {
    id: 14,
    name: "Brooke O'Codihie",
    avatarUrl: '/images/team-mate-2.png'
  },
  {
    id: 15,
    name: 'Haze Wakenshaw',
    avatarUrl: '/images/team-mate-3.png'
  },
  {
    id: 16,
    name: 'Earle Stainburn',
    avatarUrl: '/images/team-mate-8.png'
  },
  {
    id: 17,
    name: 'Giordano Spawton',
    avatarUrl: '/images/team-mate-6.png'
  },
  {
    id: 18,
    name: 'Lenette Gammell',
    avatarUrl: '/images/team-mate-0.png'
  },
  {
    id: 19,
    name: 'Hastings Abrahams',
    avatarUrl: '/images/team-mate-0.png'
  },
  {
    id: 20,
    name: 'Jobey Casham',
    avatarUrl: '/images/team-mate-4.png'
  },
  {
    id: 21,
    name: 'Shaun Garton',
    avatarUrl: '/images/team-mate-8.png'
  },
  {
    id: 22,
    name: 'Nickie Graeber',
    avatarUrl: '/images/team-mate-6.png'
  },
  {
    id: 23,
    name: 'Melodee Callar',
    avatarUrl: '/images/team-mate-3.png'
  },
  {
    id: 24,
    name: 'Munroe Gallafant',
    avatarUrl: '/images/team-mate-5.png'
  },
  {
    id: 25,
    name: 'Roley Boldt',
    avatarUrl: '/images/team-mate-8.png'
  }
];
