import React from 'react';
import { Flex, SimpleGrid, Stack } from '@chakra-ui/layout';

import { WorkflowStageNanoId } from '../../../../workflowStages/workflowStagesTypes';

import {
  workflowStageTaskApplications,
  WorkflowStageTaskFields,
  WorkflowStageTaskId,
  WorkflowStageTaskNanoId,
  WorkflowStageTaskName,
  WorkflowStageTaskApplication,
  WorkflowStageTaskDueDate,
  workflowStageTaskDueDates,
  WorkflowStageTaskAutomation,
  workflowStageTaskAutomations
} from '../../../workflowStageTasksTypes';
import {
  JobCandidateId,
  JobCandidateName,
  JobCandidateImage
} from '../../../../jobCandidates/jobCandidatesTypes';
import { IsLoading } from '../../../../../types';

import { useUpdateWorkflowStageTask } from '../../../hooks/useUpdateWorkflowStageTask';
import { useEditWorkflowStageTaskForm } from './hooks/useEditWorkflowStageTaskForm';

import { WorkflowStageTasksCache } from '../../../WorkflowStageTasksCache';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { InputField } from '../../../../../helpers/forms/formFields/InputField';
import { PureButtonHelper } from '../../../../../helpers/buttons/PureButtonHelper';
import { PureSubmitButtonHelper } from '../../../../../helpers/buttons/PureSubmitButtonHelper';
import { SelectField } from '../../../../../helpers/forms/formFields/SelectField';

import { renderWorkflowStageTaskApplication } from '../../../utils/renderWorkflowStageTaskApplication';
import { renderWorkflowStageTaskDueDate } from '../../../utils/renderWorkflowStageTaskDueDate';
import { renderWorkflowStageTaskAutomation } from '../../../utils/renderWorkflowStageTaskAutomation';

import { SelectJobCandidateField } from '../../../../../layouts/AppLayout/components/RightSidebar/components/TasksPopup/components/formFields/SelectJobCandidateField';

interface EditWorkflowStageTaskFormProps {
  workflowStageNanoId: WorkflowStageNanoId;
  workflowStageTask: {
    id: WorkflowStageTaskId;
    nanoId: WorkflowStageTaskNanoId;
    name: WorkflowStageTaskName;
    application: WorkflowStageTaskApplication;
    dueDate: WorkflowStageTaskDueDate;
    automation: WorkflowStageTaskAutomation;
  };
  onCancel: () => void;
  onSave: () => void;
  gridColumns?: number;
  jobCandidate?: {
    id: JobCandidateId;
  };
  allJobCandidates?: {
    id: JobCandidateId;
    name: JobCandidateName;
    image: JobCandidateImage;
  }[];
  handleSelectCandidate?: (jobCandidateId: JobCandidateId) => void;
  selectJobCandidateIsLoading?: boolean;
}

function EditWorkflowStageTaskForm({
  workflowStageNanoId,
  workflowStageTask,
  onCancel,
  onSave,
  gridColumns = 2,
  jobCandidate,
  allJobCandidates,
  handleSelectCandidate,
  selectJobCandidateIsLoading
}: EditWorkflowStageTaskFormProps) {
  const { updateWorkflowStageTask } = useUpdateWorkflowStageTask({
    workflowStageTaskNanoId: workflowStageTask.nanoId,
    cacheKeys: [
      WorkflowStageTasksCache.workflowStageIndexCacheKey(workflowStageNanoId)
    ]
  });

  const {
    control,
    watch,
    registerFields,
    validationErrors,
    editWorkflowStageTaskFormIsLoading,
    editWorkflowStageTaskFormErrorMessage,
    handleEditWorkflowStageTaskForm
  } = useEditWorkflowStageTaskForm({
    defaultValues: {
      name: workflowStageTask.name,
      application: workflowStageTask.application,
      dueDate: workflowStageTask.dueDate,
      automation: workflowStageTask.automation
    },
    onEditWorkflowStageTaskForm: async (data) => {
      await updateWorkflowStageTask(data);
      onSave();
    }
  });

  const currentTaskType = watch(WorkflowStageTaskFields.APPLICATION);

  return (
    <Stack as="form" spacing={4} onSubmit={handleEditWorkflowStageTaskForm}>
      <SimpleGrid columns={gridColumns} spacing={4}>
        <InputField
          isRequired
          label="Task Name"
          autoFocus
          name={WorkflowStageTaskFields.NAME}
          errorMessage={validationErrors.nameValidationError}
          ref={registerFields.registerName.ref}
          onChange={registerFields.registerName.onChange}
        />

        <SelectField
          isRequired
          control={control}
          label="Task Type"
          name={WorkflowStageTaskFields.APPLICATION}
          options={workflowStageTaskApplications.map(
            (workflowStageTaskApplication) => ({
              value: workflowStageTaskApplication,
              label: renderWorkflowStageTaskApplication(
                workflowStageTaskApplication
              )
            })
          )}
          errorMessage={validationErrors.applicationValidationError}
        />

        {jobCandidate && allJobCandidates && handleSelectCandidate ? (
          <SelectJobCandidateField
            selectedJobCandidate={jobCandidate}
            jobCandidates={allJobCandidates}
            handleSelectCandidate={handleSelectCandidate}
          />
        ) : null}

        <SelectField
          isRequired
          control={control}
          label="Due Date"
          name={WorkflowStageTaskFields.DUE_DATE}
          options={workflowStageTaskDueDates.map(
            (workflowStageTaskDueDate) => ({
              value: workflowStageTaskDueDate,
              label: renderWorkflowStageTaskDueDate(workflowStageTaskDueDate)
            })
          )}
          errorMessage={validationErrors.dueDateValidationError}
        />

        <SelectField
          control={control}
          label="Task Result"
          name={WorkflowStageTaskFields.AUTOMATION}
          options={workflowStageTaskAutomations[
            currentTaskType as keyof typeof workflowStageTaskAutomations
          ]?.map((workflowStageTaskAutomation) => ({
            value: workflowStageTaskAutomation,
            label: renderWorkflowStageTaskAutomation(
              workflowStageTaskAutomation
            )
          }))}
          errorMessage={validationErrors.automationValidationError}
        />
      </SimpleGrid>

      <AlertMessage message={editWorkflowStageTaskFormErrorMessage} />

      <Flex justifyContent="flex-end" gap={2}>
        <PureButtonHelper
          size="small"
          hierarchy="tertiary"
          onClick={onCancel}
          i18nText="Cancel"
        />
        <PureSubmitButtonHelper
          size="small"
          isLoading={
            editWorkflowStageTaskFormIsLoading ||
            (selectJobCandidateIsLoading as IsLoading)
          }
          i18nText="Save"
        />
      </Flex>
    </Stack>
  );
}

export default EditWorkflowStageTaskForm;
