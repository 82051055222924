import { Icon, IconProps } from '@chakra-ui/icon';

const PersonIcon = ({ w = '20px', h = '20px', ...props }: IconProps) => (
  <Icon viewBox="0 0 12 12" w={w} h={h} fill="none" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m7.578 7.537-.545 1.044a.55.55 0 0 1-.488.288H5.454a.549.549 0 0 1-.488-.288l-.545-1.044a.46.46 0 0 1-.043-.126c.478.263 1.03.415 1.621.415.592 0 1.144-.152 1.622-.415a.46.46 0 0 1-.043.126Zm.217-.225c.89-.56 1.478-1.522 1.478-2.616V3.13C9.273 1.401 7.808 0 6 0 4.193 0 2.727 1.401 2.727 3.13v1.566c0 1.094.589 2.056 1.479 2.616C1.873 7.392 0 9.227 0 11.478V12h5.155l.512-2.521a.553.553 0 0 1 .454-.233H5.88c.182 0 .353.088.454.233L6.848 12H12v-.522c0-2.25-1.873-4.085-4.205-4.166Z"
      clipRule="evenodd"
    />
  </Icon>
);

export default PersonIcon;
