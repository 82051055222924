import {
  FetchCandidateSubmissionsCacheKey,
  FetchCandidateSubmissionCacheKey,
  SendEmailsCandidateSubmissionNanoId,
  SendEmailsCandidateSubmissionErrorMessage,
  SendEmailsCandidateSubmissionIsLoading,
  SendEmailsCandidateSubmissionID
} from '../../candidateSubmissionsTypes';

import {
  CandidateSubmissionsBffRequests,
  SendEmailsCandidateSubmissionErrorResponse,
  SendEmailsCandidateSubmissionRequestData
} from '../../CandidateSubmissionsBffRequests';

import { usePostItem } from '../../../common/hooks/usePostItem';

interface SendEmailsCandidateSubmissionOptions {
  // candidateSubmissionId:
  //   | SendEmailsCandidateSubmissionNanoId
  //   | SendEmailsCandidateSubmissionID;
  cacheKeys?: (
    | FetchCandidateSubmissionsCacheKey
    | FetchCandidateSubmissionCacheKey
  )[];
}

function useSendEmailsCandidateSubmission({
  // candidateSubmissionId,
  cacheKeys = []
}: SendEmailsCandidateSubmissionOptions) {
  const {
    postItemData,
    postItemError,
    postItemErrorMessage,
    postItemIsLoading,
    postItem,
    postItemReset
  } = usePostItem<
    SendEmailsCandidateSubmissionRequestData,
    SendEmailsCandidateSubmissionErrorResponse
  >({
    mutationFn: ({ candidateSubmissionId }) =>
      CandidateSubmissionsBffRequests.sendEmailsCandidateSubmission(
        candidateSubmissionId
      ),
    cacheKeys
  });

  return {
    sendEmailsCandidateSubmissionData: postItemData,
    sendEmailsCandidateSubmissionError: postItemError,
    sendEmailsCandidateSubmissionErrorMessage:
      postItemErrorMessage as SendEmailsCandidateSubmissionErrorMessage,
    sendEmailsCandidateSubmissionIsLoading:
      postItemIsLoading as SendEmailsCandidateSubmissionIsLoading,
    sendEmailsCandidateSubmission: postItem,
    sendEmailsCandidateSubmissionReset: postItemReset
  };
}

export default useSendEmailsCandidateSubmission;
