import { ButtonGroup, Stack } from '@chakra-ui/react';
import { Button } from '../../../../../../../../../../../helpers/Button';
import { InputField } from '../../../../../../../../../../../helpers/forms/formFields/InputField';

type ViewJobCandidateModalPhoneSidebarCaptureReferralsProps = {
  onDiscard: () => void;
  onSave: () => void;
};

const ViewJobCandidateModalPhoneSidebarCaptureReferrals = ({
  onDiscard,
  onSave
}: ViewJobCandidateModalPhoneSidebarCaptureReferralsProps) => {
  return (
    <Stack spacing={3}>
      <InputField label="Name" bg="white" labelFontSize="sm" />

      <InputField label="Email" bg="white" labelFontSize="sm" />

      <InputField label="Company" bg="white" labelFontSize="sm" />

      <InputField label="Company email" bg="white" labelFontSize="sm" />

      <InputField label="Phone" bg="white" />

      <ButtonGroup justifyContent="flex-end">
        <Button size="small" hierarchy="link" onClick={onDiscard}>
          Cancel
        </Button>

        <Button size="small" onClick={onSave}>
          Add Referral
        </Button>
      </ButtonGroup>
    </Stack>
  );
};

export default ViewJobCandidateModalPhoneSidebarCaptureReferrals;
