import {
  FetchCandidateSharesCacheKey,
  FetchCandidateShareCacheKey
} from './candidateSharesTypes';

export class CandidateSharesCache {
  static indexCacheKey() {
    return 'candidateShares' as FetchCandidateSharesCacheKey;
  }

  static showCacheKey() {
    return 'candidateShare' as FetchCandidateShareCacheKey;
  }
}
