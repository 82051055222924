import React, { ForwardedRef, ReactNode, useRef, useState } from 'react';
import { IconButton } from '@chakra-ui/button';

import { Icon } from '../../../Icon';
import { InputField, InputFieldProps } from '../InputField';

import { IconsEnum } from '../../../../icons';

interface Text extends InputFieldProps {
  label?: ReactNode;
}

export type PasswordInputFieldProps = Omit<Text, 'type'>;

const PasswordInputField = React.forwardRef<
  HTMLInputElement,
  PasswordInputFieldProps
>(
  (
    { ...props }: PasswordInputFieldProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const [show, setShow] = useState(false);
    const passwordInputRef = useRef<null | HTMLInputElement>(null);

    const handleClick = () => {
      setShow(!show);
      passwordInputRef?.current?.focus();
    };

    return (
      <InputField
        {...props}
        ref={ref}
        type={show ? 'text' : 'password'}
        isClickableRightElement
        rightElement={
          <IconButton
            tabIndex={-1}
            h={8}
            w={8}
            size="sm"
            variant="ghost"
            aria-label={`${show ? 'Hide' : 'Show'} Password`}
            onClick={handleClick}
            icon={<Icon icon={IconsEnum.EYE} />}
          />
        }
      />
    );
  }
);

PasswordInputField.displayName = 'PasswordInputField';

export default PasswordInputField;
