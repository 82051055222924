import {
  UserId,
  UserRecordId,
  UserNanoId,
  UserUuid,
  UserName,
  UserEmail,
  UserTitle,
  UserPhone,
  MayBeUserApprovedAt,
  MayBeUserProfileCreatedAt,
  MayBeUserWorkEmailApprovedAt,
  UserQualifications,
  MayBeUserCountryId,
  MayBeUserImageId,
  MayBeUserImage,
  MayBeUserStateId,
  MayBeUserState,
  MayBeUserCityId,
  MayBeUserCity,
  MayBeUserCompanyId,
  MayBeUserCompany,
  MayBeUserJobResumeRecruiterId,
  MayBeUserJobResumeRecruiter,
  UserCreatedAt,
  UserIndexFields,
  FetchUserIndexQuery,
  UserRoleNames,
  UserActions,
  UserPermissions,
  UserCompanyMembers,
  MayBeUserCompanyRoleName,
  FetchUserIndexQueryName
} from '../usersTypes';

export interface FetchCurrentUserIndexResponse {
  [UserIndexFields.ID]: UserId;
  [UserIndexFields.RECORD_ID]: UserRecordId;
  [UserIndexFields.UUID]: UserUuid;
  [UserIndexFields.NANO_ID]: UserNanoId;
  [UserIndexFields.NAME]: UserName;
  [UserIndexFields.EMAIL]: UserEmail;
  [UserIndexFields.PHONE]: UserPhone;
  [UserIndexFields.APPROVED_AT]: MayBeUserApprovedAt;
  [UserIndexFields.PROFILE_CREATED_AT]: MayBeUserProfileCreatedAt;
  [UserIndexFields.WORK_EMAIL_APPROVED_AT]: MayBeUserWorkEmailApprovedAt;
  [UserIndexFields.TITLE]: UserTitle;
  [UserIndexFields.QUALIFICATIONS]: UserQualifications;
  [UserIndexFields.CREATED_AT]: UserCreatedAt;

  [UserIndexFields.ROLE_NAMES]: UserRoleNames;
  [UserIndexFields.ACTIONS]: UserActions;
  [UserIndexFields.PERMISSIONS]: UserPermissions;

  [UserIndexFields.COMPANY_MEMBERS]: UserCompanyMembers;

  [UserIndexFields.IMAGE_ID]: MayBeUserImageId;
  [UserIndexFields.IMAGE]: MayBeUserImage;
  [UserIndexFields.COUNTRY_ID]: MayBeUserCountryId;
  [UserIndexFields.STATE_ID]: MayBeUserStateId;
  [UserIndexFields.STATE]: MayBeUserState;
  [UserIndexFields.CITY_ID]: MayBeUserCityId;
  [UserIndexFields.CITY]: MayBeUserCity;
  [UserIndexFields.COMPANY_ID]: MayBeUserCompanyId;
  [UserIndexFields.COMPANY]: MayBeUserCompany;
  [UserIndexFields.COMPANY_ROLE_NAME]: MayBeUserCompanyRoleName;
  [UserIndexFields.JOB_RESUME_RECRUITER_ID]: MayBeUserJobResumeRecruiterId;
  [UserIndexFields.JOB_RESUME_RECRUITER]: MayBeUserJobResumeRecruiter;
}

export const fetchCurrentUserIndexQuery = [
  UserIndexFields.RECORD_ID,
  UserIndexFields.UUID,
  UserIndexFields.NANO_ID,
  UserIndexFields.NAME,
  UserIndexFields.EMAIL,
  UserIndexFields.TITLE,
  UserIndexFields.PHONE,
  UserIndexFields.APPROVED_AT,
  UserIndexFields.PROFILE_CREATED_AT,
  UserIndexFields.WORK_EMAIL_APPROVED_AT,
  UserIndexFields.QUALIFICATIONS,
  UserIndexFields.JOB_RESUME_RECRUITER_ID,
  UserIndexFields.CREATED_AT,

  UserIndexFields.ACTIONS,

  UserIndexFields.PERMISSIONS_NANO_ID,
  UserIndexFields.PERMISSIONS_ACTION,
  UserIndexFields.PERMISSIONS_FRONTEND,

  UserIndexFields.COMPANY_MEMBER_IDS,
  UserIndexFields.COMPANY_MEMBERS_NANO_ID,
  UserIndexFields.COMPANY_MEMBERS_COMPANY_NANO_ID,
  UserIndexFields.COMPANY_MEMBERS_COMPANY_NAME,

  UserIndexFields.COMPANY_MEMBERS_COMPANY_IMAGE_NANO_ID,
  UserIndexFields.COMPANY_MEMBERS_COMPANY_IMAGE_NAME,
  UserIndexFields.COMPANY_MEMBERS_COMPANY_IMAGE_FILE,
  UserIndexFields.COMPANY_MEMBERS_COMPANY_IMAGE_KEY,
  UserIndexFields.COMPANY_MEMBERS_COMPANY_IMAGE_CREATED_AT,

  UserIndexFields.IMAGE_NANO_ID,
  UserIndexFields.IMAGE_NAME,
  UserIndexFields.IMAGE_FILE,
  UserIndexFields.IMAGE_KEY,
  UserIndexFields.IMAGE_CREATED_AT,

  UserIndexFields.STATE_ID,
  UserIndexFields.STATE_NANO_ID,
  UserIndexFields.STATE_NAME,
  UserIndexFields.STATE_ISO_CODE,

  UserIndexFields.CITY_ID,
  UserIndexFields.CITY_NANO_ID,
  UserIndexFields.CITY_NAME,
  UserIndexFields.CITY_LABEL,

  UserIndexFields.COMPANY_ID,
  UserIndexFields.COMPANY_NANO_ID,
  UserIndexFields.COMPANY_NAME,

  UserIndexFields.COMPANY_IMAGE_NANO_ID,
  UserIndexFields.COMPANY_IMAGE_NAME,
  UserIndexFields.COMPANY_IMAGE_FILE,
  UserIndexFields.COMPANY_IMAGE_KEY,
  UserIndexFields.COMPANY_IMAGE_CREATED_AT,

  UserIndexFields.JOB_RESUME_RECRUITER_NANO_ID,
  UserIndexFields.JOB_RESUME_RECRUITER_JOB_NANO_ID
] as FetchUserIndexQuery;

export const fetchCurrentUserIndexQueryName =
  'currentUser' as FetchUserIndexQueryName;
