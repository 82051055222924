import { Flex, Stack, StackDivider } from '@chakra-ui/react';
import { ButtonProps } from '../../../../../../../../../helpers/Button';
import { ViewJobCandidateModalPhoneSidebarCallCategoryType } from '../../ViewJobCandidateModalPhoneSidebarHelper.types';
import { ViewJobCandidateModalPhoneSidebarCallCategoryButton } from './helpers';

interface ViewJobCandidateModalPhoneSidebarCallCategoriesHelperProps {
  toggleCallCategory: ButtonProps['onClick'];
  selectedCallCategory: ViewJobCandidateModalPhoneSidebarCallCategoryType;
}

const ViewJobCandidateModalPhoneSidebarCallCategoriesHelper = ({
  selectedCallCategory,
  toggleCallCategory
}: ViewJobCandidateModalPhoneSidebarCallCategoriesHelperProps) => {
  return (
    <Flex>
      <Stack
        flex={1}
        spacing={0}
        overflow="hidden"
        borderRadius="4px"
        border="1px solid"
        borderColor="gray.300"
        divider={<StackDivider />}
        boxShadow="0px 2px 2px rgba(23, 25, 26, 0.06)"
      >
        <Stack direction="row" spacing={0} divider={<StackDivider />}>
          <ViewJobCandidateModalPhoneSidebarCallCategoryButton
            selectedCallCategory={selectedCallCategory}
            onClick={toggleCallCategory}
            value="recruiting-call"
            label="Recruiting call"
          />

          <ViewJobCandidateModalPhoneSidebarCallCategoryButton
            selectedCallCategory={selectedCallCategory}
            onClick={toggleCallCategory}
            value="screening-call"
            label="Screening call"
          />
        </Stack>

        <Stack direction="row" spacing={0} divider={<StackDivider />}>
          <ViewJobCandidateModalPhoneSidebarCallCategoryButton
            selectedCallCategory={selectedCallCategory}
            onClick={toggleCallCategory}
            label="Follow-up call"
            value="followup-call"
            notifications={2}
          />

          <ViewJobCandidateModalPhoneSidebarCallCategoryButton
            selectedCallCategory={selectedCallCategory}
            onClick={toggleCallCategory}
            label="Scheduling call"
            value="scheduling-call"
          />
        </Stack>
      </Stack>
    </Flex>
  );
};

export default ViewJobCandidateModalPhoneSidebarCallCategoriesHelper;
