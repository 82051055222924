import React, { FormEventHandler, ForwardedRef, ReactNode } from 'react';

import { ClassName, FormID, FormMethod } from '../../types';

interface FormProps {
  id?: FormID;
  className?: ClassName;
  method?: FormMethod;
  children: ReactNode;
  onSubmit: FormEventHandler<HTMLDivElement | HTMLFormElement>;
  onBlur?: React.FocusEventHandler<HTMLFormElement>;
}

const FormWithRef = React.forwardRef(
  (
    {
      className,
      id,
      method = 'POST' as FormMethod,
      children,
      onSubmit,
      onBlur
    }: FormProps,
    ref: ForwardedRef<HTMLFormElement>
  ) => (
    <form
      ref={ref}
      id={id}
      className={className}
      method={method}
      onSubmit={onSubmit}
      onBlur={onBlur}
    >
      {children}
    </form>
  )
);

FormWithRef.displayName = 'FormWithRef';

export default FormWithRef;
