import { Flex, Grid, Stack, StackDivider } from '@chakra-ui/react';
import size from 'lodash/size';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';

import {
  TalentPoolCandidateListItem,
  TalentPoolJobCandidate
} from '../TalentPoolCandidateListItem';

import { ErrorMessage, IsFetched } from '../../../../../types';
import { JobCandidateNanoId } from '../../../../jobCandidates/jobCandidatesTypes';
import { JobNanoId } from '../../../../jobs/jobsTypes';
import { UserId } from '../../../../users/usersTypes';

import { IconButton } from '../../../../../helpers/buttons/IconButton';
import { DropdownSelect } from '../../../../../helpers/DropdownSelect';
import { FilterIcon } from '../../../../../icons/FilterIcon';
import { DepartmentsCache } from '../../../../departments/DepartmentsCache';
import {
  DepartmentFields,
  FetchDepartmentsSort
} from '../../../../departments/departmentsTypes';
import { usePaginatedDepartments } from '../../../../departments/hooks/usePaginatedDepartments';
import {
  FetchDepartmentsResponse,
  fetchDepartmentsQuery
} from '../../../../departments/queries/fetchDepartments.query';
import { NoTalentPlaceholder } from '../../helpers/NoTalentPlaceholder';

interface TalentPoolCandidatesListProps {
  jobCandidates: TalentPoolJobCandidate[];
  jobCandidatesTotalCount: number;
  jobCandidatesIsFetched: IsFetched;
  jobCandidatesErrorMessage: ErrorMessage;
  job: {
    nanoId: JobNanoId;
  };
  personNanoId?: JobCandidateNanoId;
  currentUser: {
    id: UserId;
  };
}

function TalentPoolCandidatesList({
  job,
  currentUser,
  personNanoId,
  jobCandidates,
  jobCandidatesIsFetched,
  jobCandidatesErrorMessage
}: TalentPoolCandidatesListProps) {
  const { departments, departmentsIsLoading } =
    usePaginatedDepartments<FetchDepartmentsResponse>({
      query: fetchDepartmentsQuery,
      cacheKey: DepartmentsCache.indexCacheKey(),
      initialSort: {
        [DepartmentFields.NAME]: { ascending: true }
      } as unknown as FetchDepartmentsSort
    });

  return (
    <Grid
      gap={3}
      w={{
        base: '290px',
        xl: '300px'
      }}
      bg="white"
      pos="fixed"
      overflowY="auto"
      borderRight="1px solid"
      borderColor="gray.200"
      h="calc(100vh - 140px)"
      gridTemplateRows="max-content 1fr"
    >
      <Flex
        pl={4}
        pr={2}
        pt={4}
        top={0}
        gap={2}
        zIndex={10}
        pos="sticky"
        alignItems="center"
      >
        <Flex flex={1}>
          <DropdownSelect
            h={10}
            px={3}
            pr={2}
            w="full"
            fontSize="sm"
            size="small"
            boxShadow="none"
            popoverWidth="xs"
            border="1px solid"
            borderColor="gray.200"
            isLoading={departmentsIsLoading}
            placeholder="Select department"
            onOptionSelect={(option) => console.log('Selected:', option)}
            options={departments.map((department) => ({
              label: department.name,
              value: department.nanoId
            }))}
            hierarchy="tertiary"
          />
        </Flex>

        <IconButton
          border="none"
          boxShadow="none"
          hierarchy="tertiary"
          aria-label="filter"
          icon={<FilterIcon w={5} h={5} />}
        />
      </Flex>

      <LoadingSkeleton loaded={jobCandidatesIsFetched}>
        <Stack
          spacing={0}
          overflow="auto"
          divider={<StackDivider borderColor="gray.100" />}
        >
          <AlertMessage message={jobCandidatesErrorMessage} />
          {size(jobCandidates) ? (
            jobCandidates.map((jobCandidate) => (
              <TalentPoolCandidateListItem
                key={jobCandidate.nanoId}
                jobCandidate={jobCandidate}
                job={job}
                currentUser={currentUser}
                isActive={
                  (personNanoId as JobCandidateNanoId) === jobCandidate.nanoId
                }
              />
            ))
          ) : (
            <NoTalentPlaceholder />
          )}
        </Stack>
      </LoadingSkeleton>
    </Grid>
  );
}

export default TalentPoolCandidatesList;
