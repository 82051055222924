import { ControlPanelPeopleRoutes } from './ControlPanelPeopleRoutes';

const basePeoplePath = ControlPanelPeopleRoutes.employees();

export class EmployeesRoutes {
  static index() {
    return basePeoplePath;
  }

  static contacts() {
    return `${basePeoplePath}/contacts`;
  }

  static leads() {
    return `${basePeoplePath}/leads`;
  }

  static targets() {
    return `${basePeoplePath}/targets`;
  }

  static prospects() {
    return `${basePeoplePath}/prospects`;
  }

  static users() {
    return `${basePeoplePath}/users`;
  }
  static customers() {
    return `${basePeoplePath}/customers`;
  }
}
