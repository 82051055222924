import { RoleNanoId } from '../roles/rolesTypes';
import { PermissionNanoId } from '../permissions/permissionsTypes';

const baseControlPanelPath = '/control-panel';

export class ControlPanelRoutes {
  static index() {
    return baseControlPanelPath;
  }

  static signIn() {
    return `${baseControlPanelPath}/sign-in`;
  }

  static profile() {
    return `${baseControlPanelPath}/profile`;
  }

  static fileAttachments() {
    return `${baseControlPanelPath}/file-attachments`;
  }

  static users() {
    return `${baseControlPanelPath}/user-management/users`;
  }

  static roles() {
    return `${baseControlPanelPath}/roles`;
  }

  static role(roleNanoId: RoleNanoId) {
    return `${baseControlPanelPath}/roles/${roleNanoId}`;
  }

  static permissions() {
    return `${baseControlPanelPath}/permissions`;
  }

  static permission(permissionNanoId: PermissionNanoId) {
    return `${baseControlPanelPath}/permissions/${permissionNanoId}`;
  }

  static jobs() {
    return `${baseControlPanelPath}/jobs`;
  }

  static userManagement() {
    return `${baseControlPanelPath}/user-management`;
  }

  static people() {
    return `${baseControlPanelPath}/people`;
  }

  static talentPool() {
    return `${baseControlPanelPath}/talent-pool`;
  }

  static settings() {
    return `${baseControlPanelPath}/settings`;
  }
}
