import { ReactNode, useCallback, useEffect, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { SystemProps, SystemStyleObject } from '@chakra-ui/react';
import debounce from 'lodash/debounce';
import find from 'lodash/find';
import first from 'lodash/first';

import { ErrorMessage, IsDisabled, IsRequired } from '../../../../../types';

import {
  FetchJobTitlesEnabled,
  FetchJobTitlesPageSize,
  JobTitleFields,
  JobTitleId,
  JobTitleName,
  JobTitleNanoId,
  JobTitleUserId,
  FetchJobTitlesFilters,
  FetchJobTitlesSort,
  JobTitleJobFunctionId
} from '../../../../jobTitles/jobTitlesTypes';
import { SelectJobTitleSelectJobTitleAssociatesProps } from './SelectJobTitle.types';

import {
  fetchJobTitlesQuery,
  FetchJobTitlesResponse
} from '../../../../jobTitles/queries/fetchJobTitles.query';

import { usePaginatedJobTitles } from '../../../../jobTitles/hooks/usePaginatedJobTitles';
import { useCurrentUser } from '../../../../../auth/hooks/useAuth';
import { useCreateJobTitle } from '../../../../jobTitles/hooks/useCreateJobTitle';

import { JobTitlesCache } from '../../../../jobTitles/JobTitlesCache';

import {
  SelectField,
  SelectFieldReactHookFormControl,
  SelectFieldReactHookFormFieldPath
} from '../../../../../helpers/forms/formFields/SelectField';

import { SelectOptionType } from '../../../../../helpers/forms/formFields/SelectField/components/SelectFieldControl';

import { generateNanoId } from '../../../../../utils/generateNanoId';
import { JobFunctionId } from '../../../../jobFunctions/jobFunctionsTypes';

interface SelectJobTitleFormFieldRequiredProps<T extends FieldValues> {
  isRequired?: IsRequired;
  isDisabled?: IsDisabled;
  control: SelectFieldReactHookFormControl<T>;
  item?: {
    jobTitle: {
      id: JobTitleId;
      name: JobTitleName;
    };
    name?: string;
  } | null;
  selectedJobTitleId: JobTitleId;
  selectJobTitleAssocaites: (
    jobTitleAssociates: SelectJobTitleSelectJobTitleAssociatesProps
  ) => void;
  selectedJobFunctionId?: JobFunctionId;
  withoutAddNewButton?: boolean;
  errorMessage: ErrorMessage;
  labelAddon?: ReactNode;
  label?: string;
  placeholder?: string;
  withoutLabel?: boolean;
  name?: SelectFieldReactHookFormFieldPath<T>;
  reactSelectStyles?: Record<string, unknown>;
  isBorderless?: boolean;
  visibility?: SystemProps['visibility'];
}

function SelectJobTitleFormField<T extends FieldValues>({
  isRequired,
  isDisabled,
  control,
  item,
  errorMessage,
  labelAddon,
  label,
  placeholder,
  selectedJobTitleId,
  selectJobTitleAssocaites,
  selectedJobFunctionId,
  withoutLabel,
  name,
  reactSelectStyles,
  isBorderless,
  visibility,
  withoutAddNewButton
}: SelectJobTitleFormFieldRequiredProps<T>) {
  const currentUser = useCurrentUser();

  const {
    createJobTitle,
    createJobTitleIsLoading,
    createJobTitleErrorMessage
  } = useCreateJobTitle({
    cacheKeys: [JobTitlesCache.indexCacheKey()]
  });

  const {
    jobTitles,
    jobTitlesIsLoading,
    jobTitlesErrorMessage,
    changeJobTitlesFilters
  } = usePaginatedJobTitles<FetchJobTitlesResponse>({
    query: fetchJobTitlesQuery,
    cacheKey: JobTitlesCache.indexCacheKey(),
    initialFilters: {
      ...(item?.jobTitle?.name || item?.name
        ? {
            [JobTitleFields.NAME]: {
              operator: 'ilike',
              value: `${item?.jobTitle?.name || item?.name}`
            }
          }
        : {}),
      ...(selectedJobFunctionId
        ? {
            [JobTitleFields.JOB_FUNCTION_ID]: {
              operator: 'eq',
              value: selectedJobFunctionId
            }
          }
        : {})
    } as unknown as FetchJobTitlesFilters,
    initialSort: {
      [JobTitleFields.NAME]: { ascending: true }
    } as unknown as FetchJobTitlesSort
  });

  const {
    jobTitlesIsLoading: existingJobTitlesIsLoading,
    jobTitlesErrorMessage: existingJobTitlesErrorMessage,
    fetchJobTitles
  } = usePaginatedJobTitles<FetchJobTitlesResponse>({
    query: fetchJobTitlesQuery,
    cacheKey: JobTitlesCache.existsCacheKey(),
    enabled: false as FetchJobTitlesEnabled,
    initialPageSize: 10 as FetchJobTitlesPageSize,
    initialSort: {
      [JobTitleFields.NAME]: { ascending: true }
    } as unknown as FetchJobTitlesSort
  });

  const defaultItemFromItemName = item?.name
    ? find(jobTitles, (jobTitle) => jobTitle.name === item.name)
    : null;

  const defaultItemFromItemNameValue = defaultItemFromItemName
    ? { label: defaultItemFromItemName.name, value: defaultItemFromItemName.id }
    : undefined;

  const defaultItemUserJobTitle = item?.jobTitle
    ? { value: item.jobTitle.id, label: item.jobTitle.name }
    : undefined;

  const [inputValue, setInputValue] = useState<string>('');
  const [isNewJobTitle, setIsNewJobTitle] = useState<boolean>(false);
  const [isJobFunctionSelected, setIsJobFunctionSelected] =
    useState<boolean>(false);

  const debouncedFilterJobTitles = debounce<(updatedValue: string) => void>(
    (updatedValue) =>
      changeJobTitlesFilters({
        [JobTitleFields.NAME]: {
          operator: 'ilike',
          value: `${updatedValue}%`
        }
      }),
    500
  );

  const handleInputChange = useCallback<(value: string) => void>(
    (value) => {
      setInputValue(value);
      setIsNewJobTitle(false);
      setIsJobFunctionSelected(false);

      if (!value || value === '') {
        return;
      }

      debouncedFilterJobTitles(value);
    },
    [debouncedFilterJobTitles]
  );

  const onAddNewJobTitle = useCallback(async () => {
    const { data: existingJobTitles } = await fetchJobTitles({
      nextFilters: {
        [JobTitleFields.NAME]: { operator: 'eq', value: inputValue }
      }
    });

    const existingJobTitle = first(existingJobTitles);

    if (existingJobTitle) {
      const existingJobTitleValue = {
        label: existingJobTitle.name,
        value: existingJobTitle.id
      };

      setInputValue('');
      selectJobTitleAssocaites({
        jobFunctionId: existingJobTitle.jobFunction?.id,
        disciplineId: existingJobTitle.jobFunction?.discipline?.id
      });
      setIsNewJobTitle(false);
      setIsJobFunctionSelected(true);
      return existingJobTitleValue as SelectOptionType;
    }

    const jobFunctionId = (selectedJobFunctionId ||
      1219) as JobTitleJobFunctionId;

    const jobTitle = await createJobTitle({
      name: inputValue as JobTitleName,
      userId: currentUser.id as JobTitleUserId,
      nanoId: generateNanoId<JobTitleNanoId>(),
      jobFunctionId
    });

    if (jobTitle?.id && jobTitle?.name) {
      const jobTitleValue = {
        label: jobTitle.name,
        value: jobTitle.id
      };

      setInputValue('');

      if (selectedJobFunctionId) {
        setIsJobFunctionSelected(true);
        setIsNewJobTitle(true);
      } else {
        selectJobTitleAssocaites({
          isNewJobTitle: true
        });
        setIsNewJobTitle(true);
        setIsJobFunctionSelected(false);
      }
      return jobTitleValue as SelectOptionType;
    }
  }, [
    inputValue,
    fetchJobTitles,
    currentUser.id,
    createJobTitle,
    selectedJobFunctionId,
    selectJobTitleAssocaites
  ]);

  useEffect(() => {
    if (selectedJobFunctionId) {
      changeJobTitlesFilters({
        [JobTitleFields.JOB_FUNCTION_ID]: {
          operator: 'eq',
          value: selectedJobFunctionId
        }
      });
    }
  }, [selectedJobFunctionId, changeJobTitlesFilters]);

  useEffect(() => {
    if (defaultItemFromItemName && !selectedJobTitleId && !item?.jobTitle?.id) {
      selectJobTitleAssocaites({
        jobFunctionId: defaultItemFromItemName.jobFunctionId,
        jobTitleId: defaultItemFromItemName.id,
        isNewJobTitle: false
      });
      setIsJobFunctionSelected(true);
      setIsNewJobTitle(false);
    }
  }, [
    defaultItemFromItemName,
    selectedJobTitleId,
    item?.jobTitle?.id,
    selectJobTitleAssocaites
  ]);

  useEffect(() => {
    if (selectedJobTitleId && !isNewJobTitle && !isJobFunctionSelected) {
      const selectedJobTitle = find(
        jobTitles,
        ({ id }) => id === selectedJobTitleId
      );
      if (selectedJobTitle) {
        setIsJobFunctionSelected(true);
        selectJobTitleAssocaites({
          jobFunctionId: selectedJobTitle?.jobFunction?.id,
          disciplineId: selectedJobTitle?.jobFunction?.discipline?.id
        });
      }
    }
  }, [
    isNewJobTitle,
    selectedJobTitleId,
    jobTitles,
    isJobFunctionSelected,
    selectJobTitleAssocaites
    // item?.jobTitle.id
  ]);

  return (
    <SelectField
      isRequired={isRequired}
      isDisabled={isDisabled}
      control={control}
      label={withoutLabel ? undefined : label || 'Job Title'}
      labelAddon={labelAddon}
      placeholder={placeholder || 'Select job title'}
      name={name || ('jobTitleId' as SelectFieldReactHookFormFieldPath<T>)}
      options={jobTitles.map((jobTitle) => ({
        value: jobTitle.id,
        label: jobTitle.name
      }))}
      defaultValue={defaultItemFromItemNameValue || defaultItemUserJobTitle}
      errorMessage={
        errorMessage ||
        jobTitlesErrorMessage ||
        createJobTitleErrorMessage ||
        existingJobTitlesErrorMessage
      }
      isLoading={
        jobTitlesIsLoading ||
        createJobTitleIsLoading ||
        existingJobTitlesIsLoading
      }
      onInputChange={handleInputChange}
      withAddNewButton={!!inputValue && !withoutAddNewButton}
      addNewButtonLabel={`${
        existingJobTitlesIsLoading
          ? 'Checking:'
          : `Add${createJobTitleIsLoading ? 'ing:' : ''}`
      } ${inputValue}`}
      addNewButtonHierarchy="ghost"
      addNewButtonToValueAction={onAddNewJobTitle}
      reactSelectStyles={
        isBorderless
          ? {
              control: (base: SystemStyleObject) => ({
                ...base,
                borderColor: 'transparent',
                p: 0
              })
            }
          : reactSelectStyles
      }
      visibility={visibility}
      unsetValue
    />
  );
}

export default SelectJobTitleFormField;
