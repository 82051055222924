import snakeCase from 'lodash/snakeCase';

import {
  FetchWorkflowStageTaskQuery,
  WorkflowStageTaskCreatedAt,
  WorkflowStageTaskFields,
  WorkflowStageTaskId,
  WorkflowStageTaskNanoId,
  WorkflowStageTaskName,
  WorkflowStageTaskApplication,
  WorkflowStageTaskArrangement,
  WorkflowStageTaskDueDate,
  WorkflowStageTaskAutomation
} from '../workflowStageTasksTypes';

export interface FetchWorkflowStageTaskResponse {
  [WorkflowStageTaskFields.ID]: WorkflowStageTaskId;
  [WorkflowStageTaskFields.NANO_ID]: WorkflowStageTaskNanoId;
  [WorkflowStageTaskFields.NAME]: WorkflowStageTaskName;
  [WorkflowStageTaskFields.APPLICATION]: WorkflowStageTaskApplication;
  [WorkflowStageTaskFields.ARRANGEMENT]: WorkflowStageTaskArrangement;
  [WorkflowStageTaskFields.AUTOMATION]: WorkflowStageTaskAutomation;
  [WorkflowStageTaskFields.CREATED_AT]: WorkflowStageTaskCreatedAt;
  [WorkflowStageTaskFields.DUE_DATE]: WorkflowStageTaskDueDate;
}

export const fetchWorkflowStageTaskColumns = [
  WorkflowStageTaskFields.ID,
  WorkflowStageTaskFields.NANO_ID,
  WorkflowStageTaskFields.NAME,
  WorkflowStageTaskFields.APPLICATION,
  WorkflowStageTaskFields.ARRANGEMENT,
  WorkflowStageTaskFields.AUTOMATION,
  WorkflowStageTaskFields.CREATED_AT,
  WorkflowStageTaskFields.DUE_DATE
];

const fetchWorkflowStageTaskSBColumns = fetchWorkflowStageTaskColumns.map(
  (fetchWorkflowStageTaskColumn) => snakeCase(fetchWorkflowStageTaskColumn)
);

export const fetchWorkflowStageTaskQuery = `
  ${fetchWorkflowStageTaskSBColumns.join(',')},
  user:user_id(id,nano_id,name,image:image_id(id,nano_id,name,file,key,created_at))
` as FetchWorkflowStageTaskQuery;
