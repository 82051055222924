import {
  FetchCompanyMembersQuery,
  FetchCompanyMembersCountType,
  FetchCompanyMembersPage,
  FetchCompanyMembersPageSize,
  FetchCompanyMembersFilters,
  FetchCompanyMembersSort,
  FetchCompanyMembersIndexQueryName,
  FetchCompanyMembersIndexCountType,
  FetchCompanyMembersIndexPage,
  FetchCompanyMembersIndexPageSize,
  FetchCompanyMembersIndexFilters,
  FetchCompanyMembersIndexSort,
  FetchCompanyMemberQuery,
  FetchCompanyMemberIndexQueryName,
  CreateCompanyMemberError,
  UpdateCompanyMemberError,
  DeleteCompanyMemberError,
  CompanyMemberId,
  CompanyMemberNanoId,
  CompanyMemberCompanyId
} from './companyMembersTypes';

import { BaseBffRequests } from '../BaseBffRequests';

import { CompanyMembersBffRoutes } from './CompanyMembersBffRoutes';

interface FetchCompanyMembersRequestParams {
  query: FetchCompanyMembersQuery;
  countType?: FetchCompanyMembersCountType;
  page?: FetchCompanyMembersPage;
  pageSize?: FetchCompanyMembersPageSize;
  filters?: FetchCompanyMembersFilters;
  sort?: FetchCompanyMembersSort;
}

interface FetchCompanyMembersIndexRequestParams {
  queryName: FetchCompanyMembersIndexQueryName;
  countType?: FetchCompanyMembersIndexCountType;
  page?: FetchCompanyMembersIndexPage;
  pageSize?: FetchCompanyMembersIndexPageSize;
  filters?: FetchCompanyMembersIndexFilters;
  sort?: FetchCompanyMembersIndexSort;
}

interface FetchCompanyMemberRequestParams {
  query: FetchCompanyMemberQuery;
}

interface FetchCompanyMemberIndexRequestParams {
  queryName: FetchCompanyMemberIndexQueryName;
}

export interface CompanyMemberRequestData {
  companyId?: CompanyMemberCompanyId;
}

export interface CreateCompanyMemberDefaultRequestData
  extends CompanyMemberRequestData {
  id?: CompanyMemberId;
  nanoId: CompanyMemberNanoId;
}

export type CreateCompanyMemberRequestData =
  CreateCompanyMemberDefaultRequestData;

export type UpdateCompanyMemberRequestData = CompanyMemberRequestData;

export interface DeleteCompanyMemberRequestData {
  companyMemberNanoId: CompanyMemberNanoId;
}

export interface CreateCompanyMemberErrorResponse {
  message: CreateCompanyMemberError;
}

export interface UpdateCompanyMemberErrorResponse {
  message: UpdateCompanyMemberError;
}

export interface DeleteCompanyMemberErrorResponse {
  message: DeleteCompanyMemberError;
}

export class CompanyMembersBffRequests extends BaseBffRequests {
  static fetchCompanyMembers<T>(params: FetchCompanyMembersRequestParams) {
    return this.fetchItems<T>(
      CompanyMembersBffRoutes.fetchCompanyMembersRoute(),
      params
    );
  }

  static fetchCompanyMembersIndex<T>(
    params: FetchCompanyMembersIndexRequestParams
  ) {
    return this.fetchItemsIndex<T>(
      CompanyMembersBffRoutes.fetchCompanyMembersIndexRoute(),
      params
    );
  }

  static fetchCompanyMember<T>(
    companyMemberId: CompanyMemberId,
    params: FetchCompanyMemberRequestParams
  ) {
    return this.fetchItem<T>(
      CompanyMembersBffRoutes.fetchCompanyMemberRoute(companyMemberId),
      params
    );
  }

  static fetchCompanyMemberIndex<T>(
    companyMemberNanoId: CompanyMemberNanoId,
    params: FetchCompanyMemberIndexRequestParams
  ) {
    return this.fetchItemIndex<T>(
      CompanyMembersBffRoutes.fetchCompanyMemberIndexRoute(companyMemberNanoId),
      params
    );
  }

  static fetchCompanyMemberByNanoId<T>(
    companyMemberNanoId: CompanyMemberNanoId,
    params: FetchCompanyMemberRequestParams
  ) {
    return this.fetchItemByNanoId<T>(
      CompanyMembersBffRoutes.fetchCompanyMemberByNanoIdRoute(
        companyMemberNanoId
      ),
      params
    );
  }

  static createCompanyMember<T>(
    createCompanyMemberData: CreateCompanyMemberRequestData
  ) {
    return this.createItem<T, CreateCompanyMemberRequestData>(
      CompanyMembersBffRoutes.createCompanyMemberRoute(),
      createCompanyMemberData
    );
  }

  static async updateCompanyMember<T>(
    updateCompanyMemberNanoId: CompanyMemberNanoId,
    updateCompanyMemberData: UpdateCompanyMemberRequestData
  ) {
    return this.updateItem<T, UpdateCompanyMemberRequestData>(
      CompanyMembersBffRoutes.updateCompanyMemberRoute(
        updateCompanyMemberNanoId
      ),
      updateCompanyMemberData
    );
  }

  static async deleteCompanyMember<T>(
    deleteCompanyMemberNanoId: CompanyMemberNanoId
  ) {
    return this.deleteItem<T>(
      CompanyMembersBffRoutes.deleteCompanyMemberRoute(
        deleteCompanyMemberNanoId
      )
    );
  }
}
