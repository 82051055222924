import { Flex } from '@chakra-ui/react';
import { useMemo } from 'react';
import { CheckboxField } from '../../../../../../../../helpers/forms/formFields/CheckboxField';
import { Text } from '../../../../../../../../helpers/Text';
import { useViewJobCandidateDetailsSubmissionContext } from '../../../../contexts/ViewJobCandidateDetailsSubmissionContext';
import { CandidateSubmissionShareSettingsField } from '../../../../../../../candidateSubmissions/candidateSubmissionsTypes';

type CustomiseSubmissionProfileItemProps = {
  label: string;
  isDisabled?: boolean;
  value: CandidateSubmissionShareSettingsField;
};

export default function CustomiseCandidateSubmissionSubItem({
  label,
  isDisabled,
  value
}: CustomiseSubmissionProfileItemProps) {
  const { handleShareField, sharedFields } =
    useViewJobCandidateDetailsSubmissionContext();

  const isShared = useMemo(
    () => sharedFields.includes(value),
    [sharedFields, value]
  );

  const handleChange = () => {
    handleShareField(value);
  };

  return (
    <Flex
      py={1}
      px={2}
      gap={2}
      as="label"
      alignItems="center"
      transitionDuration="slow"
      cursor={isDisabled ? 'not-allowed' : 'pointer'}
      _hover={{ bg: 'gray.100' }}
    >
      <CheckboxField
        value={value}
        size="extra-small"
        name="sharedFields"
        isChecked={isShared}
        isDisabled={isDisabled}
        onChange={handleChange}
      />

      <Text flex={1} opacity={isDisabled ? 0.5 : 1}>
        {label}
      </Text>
    </Flex>
  );
}
