import { useCallback, useState } from 'react';
import { Fade, Flex, useDisclosure } from '@chakra-ui/react';

import { WorkflowStageTaskNanoId } from '../../../../../../../../main/workflowStageTasks/workflowStageTasksTypes';
import {
  WorkflowStageId,
  WorkflowStageNanoId
} from '../../../../../../../../main/workflowStages/workflowStagesTypes';
import {
  JobCandidateId,
  JobCandidateName,
  JobCandidateImage,
  JobCandidateNewWorkflowStageTaskAt,
  JobCandidateWorkflowStageTaskApplicationType,
  JobCandidateWorkflowStageTaskApplicationTypes,
  JobCandidateWorkflowStageTaskId
} from '../../../../../../../../main/jobCandidates/jobCandidatesTypes';

import {
  fetchWorkflowStageTaskQuery,
  FetchWorkflowStageTaskResponse
} from '../../../../../../../../main/workflowStageTasks/queries/fetchWorkflowStageTask.query';

import { JobCandidatesRequests } from '../../../../../../../../main/jobCandidates/JobCandidatesRequests';
import { WorkflowStageTasksRequests } from '../../../../../../../../main/workflowStageTasks/WorkflowStageTasksRequests';

import { AddWorkflowStageTaskForm } from '../../../../../../../../main/workflowStageTasks/components/forms/AddWorkflowStageTaskForm';

import { CollapseFormBox } from '../../../../../../../../helpers/CollapseFormBox';
import { Button } from '../../../../../../../../helpers/Button';

import { DateUtils } from '../../../../../../../../utils/DateUtils';

interface AddWorkflowStageTaskContainerProps {
  workflowStageNanoId: WorkflowStageNanoId;
  workflowStageId: WorkflowStageId;
  arrangement: number;
  allJobCandidates: {
    id: JobCandidateId;
    name: JobCandidateName;
    image: JobCandidateImage;
  }[];
  handleIsTaskUpdatedTrue: () => void;
}

function AddWorkflowStageTaskContainer({
  workflowStageNanoId,
  workflowStageId,
  arrangement,
  allJobCandidates,
  handleIsTaskUpdatedTrue
}: AddWorkflowStageTaskContainerProps) {
  const [selectedJobCandidateId, setSelectedJobCandidateId] =
    useState<JobCandidateId>('' as JobCandidateId);

  const [selectJobCandidateIsLoading, setSelectJobCandidateIsLoading] =
    useState<boolean>(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleSelectCandidate = useCallback<
    (jobCandidateId: JobCandidateId) => void
  >((jobCandidateId) => {
    setSelectedJobCandidateId(jobCandidateId);
  }, []);

  const handleSaveTaskChange = useCallback<
    (workflowStageTaskNanoId: WorkflowStageTaskNanoId) => void
  >(
    async (workflowStageTaskNanoId) => {
      if (selectedJobCandidateId) {
        setSelectJobCandidateIsLoading(true);

        const res: FetchWorkflowStageTaskResponse | null =
          await WorkflowStageTasksRequests.fetchWorkflowStageTaskByNanoId(
            workflowStageTaskNanoId,
            { query: fetchWorkflowStageTaskQuery }
          );

        if (res) {
          await JobCandidatesRequests.updateJobCandidate(
            selectedJobCandidateId,
            {
              workflowStageTaskId: res.id as JobCandidateWorkflowStageTaskId,
              newWorkflowStageTaskAt:
                DateUtils.now() as JobCandidateNewWorkflowStageTaskAt,
              workflowStageTaskScheduledAt: null,
              workflowStageTaskApplicationType:
                JobCandidateWorkflowStageTaskApplicationTypes.NONE as JobCandidateWorkflowStageTaskApplicationType,
              dueDate: null,
              viewedAt: null
            }
          );
        }

        handleIsTaskUpdatedTrue();
        setSelectJobCandidateIsLoading(false);
      }
      onClose();
    },
    [selectedJobCandidateId, handleIsTaskUpdatedTrue, onClose]
  );

  return (
    <Flex direction="column">
      <Flex ml="auto">
        <Fade in={!isOpen}>
          <Button size="small" ml="auto" onClick={onOpen}>
            Add new task
          </Button>
        </Fade>
      </Flex>
      <Flex mt={isOpen ? -8 : 0} zIndex={1}>
        <CollapseFormBox title="Add Task" isOpen={isOpen}>
          <AddWorkflowStageTaskForm
            workflowStageNanoId={workflowStageNanoId}
            workflowStageId={workflowStageId}
            arrangement={arrangement}
            onSave={(nanoId) =>
              handleSaveTaskChange(nanoId as WorkflowStageTaskNanoId)
            }
            onCancel={onClose}
            gridColumns={1}
            jobCandidate={{ id: selectedJobCandidateId }}
            allJobCandidates={allJobCandidates}
            handleSelectCandidate={handleSelectCandidate}
            selectJobCandidateIsLoading={selectJobCandidateIsLoading}
          />
        </CollapseFormBox>
      </Flex>
    </Flex>
  );
}

export default AddWorkflowStageTaskContainer;
