import { Icon, IconProps } from '@chakra-ui/icon';

const EditIcon = ({ w = '16px', h = '16px', ...props }: IconProps) => (
  <Icon viewBox="0 0 16 16" w={w} h={h} fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.058 14.4H2.945c-.38 0-.632-.237-.632-.593 0-.355.252-.592.632-.592h10.113c.38 0 .632.237.632.592 0 .356-.252.593-.632.593M12.236 5.687l1.011-.948a.549.549 0 0 0 0-.83l-2.275-2.134a.64.64 0 0 0-.885 0l-1.012.949 3.16 2.963ZM8.19 3.555l-5.056 4.74a.523.523 0 0 0-.19.416v2.133c0 .356.253.593.632.593h2.276c.19 0 .316-.06.442-.178l5.057-4.741-3.16-2.963Z"
    />
  </Icon>
);
export default EditIcon;
