import {
  ButtonGroup,
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  StackDivider,
  useDisclosure
} from '@chakra-ui/react';
import { useRef } from 'react';
import { StarIcon } from '../../../../icons/StarIcon';
import { Button } from '../../../Button';
import { InputField } from '../../../forms/formFields/InputField';
import { Heading } from '../../../Heading';

const HeaderSaveSearch = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const inputRef = useRef<null | HTMLInputElement>(null);

  return (
    <>
      <Button
        ml="auto"
        size="medium"
        hierarchy="link"
        leftIcon={<StarIcon stroke="#0580A4" />}
        onClick={onOpen}
      >
        Save this search
      </Button>

      <Modal
        size="xl"
        isOpen={isOpen}
        onClose={onClose}
        closeOnEsc={false}
        initialFocusRef={inputRef}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <Stack p={4} divider={<StackDivider />} spacing={3}>
            <Flex fontSize="18px" alignItems="center">
              <Heading level="h4" flex={1}>
                Save this search
              </Heading>

              <ModalCloseButton
                size="sm"
                position="relative"
                top={0}
                right={0}
              />
            </Flex>

            <Stack spacing={6}>
              <InputField label="Name this search" ref={inputRef} />

              <ButtonGroup justifyContent="flex-end">
                <Button size="medium" hierarchy="tertiary" onClick={onClose}>
                  Cancel
                </Button>
                <Button size="medium" onClick={onClose}>
                  Save
                </Button>
              </ButtonGroup>
            </Stack>
          </Stack>
        </ModalContent>
      </Modal>
    </>
  );
};

export default HeaderSaveSearch;
