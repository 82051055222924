import {
  FetchJobCandidatesCacheKey,
  FetchJobCandidateCacheKey,
  UpdateJobCandidateID,
  UpdateJobCandidateErrorMessage,
  UpdateJobCandidateIsLoading
} from '../../jobCandidatesTypes';

import {
  JobCandidatesBffRequests,
  UpdateJobCandidateRequestData,
  UpdateJobCandidateErrorResponse
} from '../../JobCandidatesBffRequests';

import { FetchWorkflowStagesCacheKey } from '../../../workflowStages/workflowStagesTypes';

import { useUpdateItem } from '../../../common/hooks/useUpdateItem';

interface UpdateJobCandidateOptions {
  jobCandidateId: UpdateJobCandidateID;
  cacheKeys?: (
    | FetchJobCandidatesCacheKey
    | FetchJobCandidateCacheKey
    | FetchWorkflowStagesCacheKey
  )[];
}

function useUpdateJobCandidate({
  jobCandidateId,
  cacheKeys = []
}: UpdateJobCandidateOptions) {
  const {
    updateItemData,
    updateItemError,
    updateItemErrorMessage,
    updateItemIsLoading,
    updateItem,
    updateItemReset
  } = useUpdateItem<
    UpdateJobCandidateRequestData,
    UpdateJobCandidateErrorResponse
  >({
    mutationFn: (queryInput) =>
      JobCandidatesBffRequests.updateJobCandidate(jobCandidateId, queryInput),
    cacheKeys
  });

  return {
    updateJobCandidateData: updateItemData,
    updateJobCandidateError: updateItemError,
    updateJobCandidateErrorMessage:
      updateItemErrorMessage as UpdateJobCandidateErrorMessage,
    updateJobCandidateIsLoading:
      updateItemIsLoading as UpdateJobCandidateIsLoading,
    updateJobCandidate: updateItem,
    updateJobCandidateReset: updateItemReset
  };
}

export default useUpdateJobCandidate;
