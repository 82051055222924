import snakeCase from 'lodash/snakeCase';

import {
  FetchCandidateShareRecipientsQuery,
  CandidateShareRecipientCreatedAt,
  CandidateShareRecipientFields,
  CandidateShareRecipientId,
  CandidateShareRecipientNanoId,
  CandidateShareRecipientRecipientId,
  CandidateShareRecipientRecipient,
  CandidateShareRecipientCandidateShareId,
  CandidateShareRecipientCandidateShare,
  CandidateShareRecipientUpdatedAt
} from '../candidateShareRecipientsTypes';

export interface FetchCandidateShareRecipientsResponse {
  [CandidateShareRecipientFields.ID]: CandidateShareRecipientId;
  [CandidateShareRecipientFields.NANO_ID]: CandidateShareRecipientNanoId;
  [CandidateShareRecipientFields.RECIPIENT_ID]: CandidateShareRecipientRecipientId;
  [CandidateShareRecipientFields.CREATED_AT]: CandidateShareRecipientCreatedAt;
  [CandidateShareRecipientFields.RECIPIENT]: CandidateShareRecipientRecipient;
  [CandidateShareRecipientFields.CANDIDATE_SHARE_ID]: CandidateShareRecipientCandidateShareId;
  [CandidateShareRecipientFields.CANDIDATE_SHARE]: CandidateShareRecipientCandidateShare;
  [CandidateShareRecipientFields.UPDATED_AT]: CandidateShareRecipientUpdatedAt;
}

export const fetchCandidateShareRecipientsColumns = [
  CandidateShareRecipientFields.ID,
  CandidateShareRecipientFields.NANO_ID,
  CandidateShareRecipientFields.CREATED_AT
];

const fetchCandidateShareRecipientsSBColumns =
  fetchCandidateShareRecipientsColumns.map(
    (fetchCandidateShareRecipientsColumn) =>
      snakeCase(fetchCandidateShareRecipientsColumn)
  );

export const fetchCandidateShareRecipientsQuery =
  fetchCandidateShareRecipientsSBColumns.join(
    ','
  ) as FetchCandidateShareRecipientsQuery;
