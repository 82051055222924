import {
  FetchJobFunctionsQuery,
  FetchJobFunctionsCountType,
  FetchJobFunctionsPage,
  FetchJobFunctionsPageSize,
  FetchJobFunctionsFilters,
  FetchJobFunctionsSort,
  FetchJobFunctionsIndexQueryName,
  FetchJobFunctionsIndexCountType,
  FetchJobFunctionsIndexPage,
  FetchJobFunctionsIndexPageSize,
  FetchJobFunctionsIndexFilters,
  FetchJobFunctionsIndexSort,
  FetchJobFunctionQuery,
  FetchJobFunctionIndexQueryName,
  CreateJobFunctionError,
  UpdateJobFunctionError,
  DeleteJobFunctionError,
  JobFunctionId,
  JobFunctionNanoId,
  JobFunctionName,
  JobFunctionUserId,
  JobFunctionDisciplineId
} from './jobFunctionsTypes';

import { BaseBffRequests } from '../BaseBffRequests';

import { JobFunctionsBffRoutes } from './JobFunctionsBffRoutes';

interface FetchJobFunctionsRequestParams {
  query: FetchJobFunctionsQuery;
  countType?: FetchJobFunctionsCountType;
  page?: FetchJobFunctionsPage;
  pageSize?: FetchJobFunctionsPageSize;
  filters?: FetchJobFunctionsFilters;
  sort?: FetchJobFunctionsSort;
}

interface FetchJobFunctionsIndexRequestParams {
  queryName: FetchJobFunctionsIndexQueryName;
  countType?: FetchJobFunctionsIndexCountType;
  page?: FetchJobFunctionsIndexPage;
  pageSize?: FetchJobFunctionsIndexPageSize;
  filters?: FetchJobFunctionsIndexFilters;
  sort?: FetchJobFunctionsIndexSort;
}

interface FetchJobFunctionRequestParams {
  query: FetchJobFunctionQuery;
}

interface FetchJobFunctionIndexRequestParams {
  queryName: FetchJobFunctionIndexQueryName;
}

export interface JobFunctionRequestData {
  name?: JobFunctionName;
  disciplineId: JobFunctionDisciplineId;
}

export interface CreateJobFunctionRequestData extends JobFunctionRequestData {
  id?: JobFunctionId;
  userId: JobFunctionUserId;
  nanoId: JobFunctionNanoId;
}

export interface UpdateJobFunctionRequestData extends JobFunctionRequestData {
  name?: JobFunctionName;
}

export interface DeleteJobFunctionRequestData {
  jobFunctionNanoId: JobFunctionNanoId;
}

export interface CreateJobFunctionErrorResponse {
  message: CreateJobFunctionError;
}

export interface UpdateJobFunctionErrorResponse {
  message: UpdateJobFunctionError;
}

export interface DeleteJobFunctionErrorResponse {
  message: DeleteJobFunctionError;
}

export class JobFunctionsBffRequests extends BaseBffRequests {
  static fetchJobFunctions<T>(params: FetchJobFunctionsRequestParams) {
    return this.fetchItems<T>(
      JobFunctionsBffRoutes.fetchJobFunctionsRoute(),
      params
    );
  }

  static fetchJobFunctionsIndex<T>(
    params: FetchJobFunctionsIndexRequestParams
  ) {
    return this.fetchItemsIndex<T>(
      JobFunctionsBffRoutes.fetchJobFunctionsIndexRoute(),
      params
    );
  }

  static fetchJobFunction<T>(
    jobFunctionId: JobFunctionId,
    params: FetchJobFunctionRequestParams
  ) {
    return this.fetchItem<T>(
      JobFunctionsBffRoutes.fetchJobFunctionRoute(jobFunctionId),
      params
    );
  }

  static fetchJobFunctionIndex<T>(
    jobFunctionNanoId: JobFunctionNanoId,
    params: FetchJobFunctionIndexRequestParams
  ) {
    return this.fetchItemIndex<T>(
      JobFunctionsBffRoutes.fetchJobFunctionIndexRoute(jobFunctionNanoId),
      params
    );
  }

  static fetchJobFunctionByNanoId<T>(
    jobFunctionNanoId: JobFunctionNanoId,
    params: FetchJobFunctionRequestParams
  ) {
    return this.fetchItemByNanoId<T>(
      JobFunctionsBffRoutes.fetchJobFunctionByNanoIdRoute(jobFunctionNanoId),
      params
    );
  }

  static createJobFunction<T>(
    createJobFunctionData: CreateJobFunctionRequestData
  ) {
    return this.createItem<T, CreateJobFunctionRequestData>(
      JobFunctionsBffRoutes.createJobFunctionRoute(),
      createJobFunctionData
    );
  }

  static async updateJobFunction<T>(
    updateJobFunctionNanoId: JobFunctionNanoId,
    updateJobFunctionData: UpdateJobFunctionRequestData
  ) {
    return this.updateItem<T, UpdateJobFunctionRequestData>(
      JobFunctionsBffRoutes.updateJobFunctionRoute(updateJobFunctionNanoId),
      updateJobFunctionData
    );
  }

  static async deleteJobFunction<T>(
    deleteJobFunctionNanoId: JobFunctionNanoId
  ) {
    return this.deleteItem<T>(
      JobFunctionsBffRoutes.deleteJobFunctionRoute(deleteJobFunctionNanoId)
    );
  }
}
