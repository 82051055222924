import { Icon, IconProps } from '@chakra-ui/icon';

const ThumbUpIcon = ({ w = '16px', h = '16px', ...props }: IconProps) => (
  <Icon viewBox="0 0 16 16" w={w} h={h} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.249 15.31H.801v-7.2h2.448M4.148 8.106l2.01-7.2c1.109 0 2.008.88 2.008 1.964v3.927h5.025c1.231 0 2.173 1.073 1.985 2.262l-.72 4.582c-.152.958-.995 1.665-1.986 1.665H4.148v-7.2Z"
      fill="currentColor"
    />
  </Icon>
);

export default ThumbUpIcon;
