import { Box, Collapse, Flex, useDisclosure } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useChakraToast } from '../../../../../../../../../../../helpers/useChakraToast';
import { useViewJobCandidateModalPhoneSidebarContext } from '../../../context';
import {
  ViewJobCandidateModalPhoneSidebarCallSectionType,
  ViewJobCandidateModalPhoneSidebarToggleSectionType
} from '../../ViewJobCandidateModalPhoneSidebarCallActionsHelper';
import {
  ViewJobCandidateModalPhoneSidebarAvailability,
  ViewJobCandidateModalPhoneSidebarCallResponseDiscardButton,
  ViewJobCandidateModalPhoneSidebarCallResponseTrigger,
  ViewJobCandidateModalPhoneSidebarCallResponseWrapper
} from '../../components';

export type SnoozeSlotType =
  | 'today'
  | 'tomorrow'
  | 'weekend'
  | 'next-week'
  | 'custom';

type ViewJobCandidateModalPhoneSidebarSnoozeHelperProps = {
  openedSection: ViewJobCandidateModalPhoneSidebarCallSectionType;
  toggleSection: ViewJobCandidateModalPhoneSidebarToggleSectionType;
};

const ViewJobCandidateModalPhoneSidebarSnoozeHelper = ({
  openedSection,
  toggleSection
}: ViewJobCandidateModalPhoneSidebarSnoozeHelperProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const toast = useChakraToast();
  const {
    isOpen: isHideCallSnoozed,
    onToggle: onToggleHideCallSnooze,
    onOpen: onOpenHideCallSnooze,
    onClose: onCloseHideCallSnooze
  } = useDisclosure();

  const {
    isOpen: isOpenChooseDate,
    onClose: onCloseChooseDate,
    onToggle: onToggleChooseDate
  } = useDisclosure();

  const { isCallSnoozed, onToggleIsCallSnooze } =
    useViewJobCandidateModalPhoneSidebarContext();

  const openSnoozeSection = () => {
    toggleSection({ actionType: 'open', section: 'snooze' });
  };

  const onDiscard = () => {
    setIsFocused(false);
    toggleSection({ actionType: 'close', section: 'snooze' });
  };

  const snooze = () => {
    toast({ title: 'Call snoozed', position: 'top' });
    onToggleIsCallSnooze();
    onToggleHideCallSnooze();
    onDiscard();
  };

  useEffect(() => {
    if (isCallSnoozed) {
      onOpenHideCallSnooze();
    } else onCloseHideCallSnooze();
  }, [isCallSnoozed, onCloseHideCallSnooze, onOpenHideCallSnooze]);

  return (
    <>
      {!isHideCallSnoozed && (
        <Flex
          flexDir="column"
          onClick={() => {
            if (!isFocused) {
              setIsFocused(true);
            }
          }}
          onMouseLeave={() => {
            if (!isFocused) {
              onDiscard();
            }
          }}
        >
          <ViewJobCandidateModalPhoneSidebarCallResponseTrigger
            label="Snooze"
            onMouseEnter={openSnoozeSection}
          />

          <Collapse in={openedSection === 'snooze'} unmountOnExit>
            <Box pt={2}>
              <ViewJobCandidateModalPhoneSidebarCallResponseWrapper>
                <ViewJobCandidateModalPhoneSidebarAvailability
                  onSave={snooze}
                  isOpenChooseDate={isOpenChooseDate}
                  onCloseChooseDate={onCloseChooseDate}
                  onToggleChooseDate={onToggleChooseDate}
                />

                <ViewJobCandidateModalPhoneSidebarCallResponseDiscardButton
                  onClick={onDiscard}
                />
              </ViewJobCandidateModalPhoneSidebarCallResponseWrapper>
            </Box>
          </Collapse>
        </Flex>
      )}
    </>
  );
};

export default ViewJobCandidateModalPhoneSidebarSnoozeHelper;
