import {
  CandidateSubmissionJobCandidateItemBffID,
  CandidateSubmissionJobCandidateItemBffNanoID,
  FetchCandidateSubmissionJobCandidatesBffURL,
  FetchCandidateSubmissionJobCandidateBffURL,
  FetchCandidateSubmissionJobCandidateByNanoIdBffURL,
  CreateCandidateSubmissionJobCandidateBffURL,
  DeleteCandidateSubmissionJobCandidateBffURL
} from './candidateSubmissionJobCandidatesTypes';

import { BaseBffRoutes } from '../BaseBffRoutes';

export class CandidateSubmissionJobCandidatesBffRoutes extends BaseBffRoutes {
  protected static route = 'candidateSubmissionJobCandidates';

  static fetchCandidateSubmissionJobCandidatesRoute() {
    return this.fetchItemsRoute<FetchCandidateSubmissionJobCandidatesBffURL>();
  }

  static fetchCandidateSubmissionJobCandidatesIndexRoute() {
    return this.fetchItemsIndexRoute<FetchCandidateSubmissionJobCandidatesBffURL>();
  }

  static fetchCandidateSubmissionJobCandidateRoute(
    candidateSubmissionJobCandidateItemBffID: CandidateSubmissionJobCandidateItemBffID
  ) {
    return this.fetchItemRoute<FetchCandidateSubmissionJobCandidateBffURL>(
      candidateSubmissionJobCandidateItemBffID
    );
  }

  static fetchCandidateSubmissionJobCandidateIndexRoute(
    candidateSubmissionJobCandidateItemBffNanoID: CandidateSubmissionJobCandidateItemBffNanoID
  ) {
    return this.fetchItemIndexRoute<FetchCandidateSubmissionJobCandidateBffURL>(
      candidateSubmissionJobCandidateItemBffNanoID
    );
  }

  static fetchCandidateSubmissionJobCandidateByNanoIdRoute(
    candidateSubmissionJobCandidateItemBffNanoID: CandidateSubmissionJobCandidateItemBffNanoID
  ) {
    return this.fetchItemByNanoIdRoute<FetchCandidateSubmissionJobCandidateByNanoIdBffURL>(
      candidateSubmissionJobCandidateItemBffNanoID
    );
  }

  static createCandidateSubmissionJobCandidateRoute() {
    return this.createItemRoute<CreateCandidateSubmissionJobCandidateBffURL>();
  }

  static deleteCandidateSubmissionJobCandidateRoute(
    candidateSubmissionJobCandidateItemBffID:
      | CandidateSubmissionJobCandidateItemBffNanoID
      | CandidateSubmissionJobCandidateItemBffID
  ) {
    return this.deleteItemRoute<DeleteCandidateSubmissionJobCandidateBffURL>(
      candidateSubmissionJobCandidateItemBffID
    );
  }
}
