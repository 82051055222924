import React from 'react';
import {
  Button,
  ButtonProps
} from '../../../../../../../../../../../../../helpers/Button';

interface ViewJobCandidateModalPhoneSidebarCallActionCompletedButtonProps
  extends ButtonProps {
  label: string;
  value: string;
}

const ViewJobCandidateModalPhoneSidebarCallActionCompletedButton = ({
  label,
  value,
  ...props
}: ViewJobCandidateModalPhoneSidebarCallActionCompletedButtonProps) => {
  return (
    <Button
      size="medium"
      hierarchy="tertiary"
      value={value}
      {...props}
      _hover={{
        borderColor: 'rgba(5, 128, 164, 0.5)',
        boxShadow: 'box-shadow: 0px 2px 2px rgba(5, 128, 164, 0.08)',
        color: 'primary.500'
      }}
    >
      {label}
    </Button>
  );
};

export default ViewJobCandidateModalPhoneSidebarCallActionCompletedButton;
