import { Icon, IconProps } from '@chakra-ui/icon';

const ShieldLockIcon = ({
  w = '28px',
  h = '33px',
  fill = 'none',
  ...props
}: IconProps) => (
  <Icon viewBox="0 0 28 33" w={w} h={h} fill={fill} {...props}>
    <path
      fill="#C0C9CC"
      fillRule="evenodd"
      d="M26.535 3.663 15.771.258a5.747 5.747 0 0 0-3.405 0L1.6 3.663A1.59 1.59 0 0 0 .5 5.176v4.059c0 9.286 4.54 17.987 12.14 23.318.413.31.93.447 1.41.447.482 0 .998-.138 1.41-.447 3.819-2.648 6.845-6.156 8.943-10.18a28.232 28.232 0 0 0 3.233-13.139V5.177c.034-.688-.447-1.307-1.1-1.513Z"
      clipRule="evenodd"
    />
    <path
      fill="#b1b8ba"
      fillRule="evenodd"
      d="M26.528 3.663 15.763.258A5.713 5.713 0 0 0 14.106 0l-.063 33c.482 0 .997-.138 1.41-.447 3.818-2.648 6.844-6.157 8.942-10.18a28.23 28.23 0 0 0 3.233-13.139V5.176c.035-.688-.447-1.307-1.1-1.513Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M12.346 17H10.5v-2.461c0-1.955 1.79-3.539 4-3.539s4 1.584 4 3.539V17h-1.846v-2.461c0-1.053-.964-1.906-2.154-1.906s-2.154.853-2.154 1.906V17Z"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M8.5 18a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2h-8a2 2 0 0 1-2-2v-4Zm6.547 2.834c.348-.18.583-.53.583-.93 0-.586-.5-1.06-1.118-1.06-.617 0-1.117.474-1.117 1.06 0 .415.253.775.62.948v.957a.516.516 0 0 0 1.032 0v-.976Z"
      clipRule="evenodd"
    />
    {/* <defs>
      <linearGradient
        id="a"
        x1={0.488}
        x2={27.643}
        y1={16.504}
        y2={16.504}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#97A5A8" />
        <stop offset={1} stopColor="#96A9AE" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={14.037}
        x2={27.632}
        y1={16.503}
        y2={16.503}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8C989C" />
        <stop offset={1} stopColor="#96A9AE" />
      </linearGradient>
    </defs> */}
  </Icon>
);

export default ShieldLockIcon;
