import axios from 'axios';

import {
  FetchItemsBffQuery,
  FetchItemsBffCountType,
  FetchItemsBffPage,
  FetchItemsBffPageSize,
  FetchItemsBffFilters,
  FetchItemsBffSort,
  FetchItemsIndexBffQueryName,
  FetchItemsIndexBffCountType,
  FetchItemsIndexBffPage,
  FetchItemsIndexBffPageSize,
  FetchItemsIndexBffFilters,
  FetchItemsIndexBffSort,
  FetchItemBffQuery,
  FetchItemsBffURL,
  FetchItemIndexBffQueryName,
  FetchItemBffURL,
  FetchItemByNanoIdBffURL,
  CreateItemBffURL,
  UpdateItemBffURL,
  DeleteItemBffURL,
  PostItemsBffURL,
  PostItemBffURL
} from './baseBffTypes';
import { processError } from '../api/utils/processError';
import { PermissionsBffRoutes } from './permissions/PermissionsBffRoutes';

const withCredentialsConfig = {
  withCredentials: true
};

interface FetchItemsBffRequestParams {
  query: FetchItemsBffQuery;
  countType?: FetchItemsBffCountType;
  page?: FetchItemsBffPage;
  pageSize?: FetchItemsBffPageSize;
  filters?: FetchItemsBffFilters;
  sort?: FetchItemsBffSort;
}

interface FetchItemsIndexBffRequestParams {
  queryName: FetchItemsIndexBffQueryName;
  countType?: FetchItemsIndexBffCountType;
  page?: FetchItemsIndexBffPage;
  pageSize?: FetchItemsIndexBffPageSize;
  filters?: FetchItemsIndexBffFilters;
  sort?: FetchItemsIndexBffSort;
}

interface FetchItemBffRequestParams {
  query: FetchItemBffQuery;
}

interface FetchItemIndexBffRequestParams {
  queryName: FetchItemIndexBffQueryName;
}

export class BaseBffRequests {
  private static count = 1;
  static async fetchItems<T>(
    fetchItemsBffURL: FetchItemsBffURL,
    params: FetchItemsBffRequestParams
  ) {
    try {
      const { data } = await axios.get<{ data: T[] }>(fetchItemsBffURL, {
        ...withCredentialsConfig,
        ...(params
          ? {
              params: {
                query: params.query,
                countType: params.countType,
                page: params.page,
                pageSize: params.pageSize,
                filters: JSON.stringify(params.filters),
                sort: JSON.stringify(params.sort)
              }
            }
          : {}),
        headers: { 'Content-Type': 'application/json' }
      });
      return data;
    } catch (err) {
      const error = processError(err);

      if (error.status === 403 && this.count <= 1) {
        this.count = this.count++;

        await axios.post(
          PermissionsBffRoutes.clearPermissionsCacheRoute(),
          {},
          withCredentialsConfig
        );

        const { data } = await axios.get<{ data: T[] }>(fetchItemsBffURL, {
          ...withCredentialsConfig,
          ...(params
            ? {
                params: {
                  query: params.query,
                  countType: params.countType,
                  page: params.page,
                  pageSize: params.pageSize,
                  filters: JSON.stringify(params.filters),
                  sort: JSON.stringify(params.sort)
                }
              }
            : {}),
          headers: { 'Content-Type': 'application/json' }
        });
        return data;
      }

      throw err;
    }
  }

  static async fetchItemsIndex<T>(
    fetchItemsBffURL: FetchItemsBffURL,
    params: FetchItemsIndexBffRequestParams
  ) {
    try {
      const { data } = await axios.get<{ data: T[] }>(fetchItemsBffURL, {
        ...withCredentialsConfig,
        ...(params
          ? {
              params: {
                queryName: params.queryName,
                countType: params.countType,
                page: params.page,
                pageSize: params.pageSize,
                filters: JSON.stringify(params.filters),
                sort: JSON.stringify(params.sort)
              }
            }
          : {}),
        headers: { 'Content-Type': 'application/json' }
      });
      return data;
    } catch (err) {
      const error = processError(err);

      if (error.status === 403 && this.count <= 1) {
        this.count = this.count++;

        await axios.post(
          PermissionsBffRoutes.clearPermissionsCacheRoute(),
          {},
          withCredentialsConfig
        );

        const { data } = await axios.get<{ data: T[] }>(fetchItemsBffURL, {
          ...withCredentialsConfig,
          ...(params
            ? {
                params: {
                  queryName: params.queryName,
                  countType: params.countType,
                  page: params.page,
                  pageSize: params.pageSize,
                  filters: JSON.stringify(params.filters),
                  sort: JSON.stringify(params.sort)
                }
              }
            : {}),
          headers: { 'Content-Type': 'application/json' }
        });
        return data;
      }

      throw err;
    }
  }

  static async fetchItem<T>(
    fetchItemBffURL: FetchItemBffURL,
    params: FetchItemBffRequestParams
  ) {
    try {
      const { data } = await axios.get<T>(fetchItemBffURL, {
        ...withCredentialsConfig,
        params
      });
      return data;
    } catch (err) {
      const error = processError(err);

      if (error.status === 403 && this.count <= 1) {
        this.count = this.count++;

        await axios.post(
          PermissionsBffRoutes.clearPermissionsCacheRoute(),
          {},
          withCredentialsConfig
        );

        const { data } = await axios.get<T>(fetchItemBffURL, {
          ...withCredentialsConfig,
          params
        });
        return data;
      }

      throw err;
    }
  }

  static async fetchItemIndex<T>(
    fetchItemBffURL: FetchItemBffURL,
    params: FetchItemIndexBffRequestParams
  ) {
    try {
      const { data } = await axios.get<T>(fetchItemBffURL, {
        ...withCredentialsConfig,
        params
      });
      return data;
    } catch (err) {
      const error = processError(err);

      if (error.status === 403 && this.count <= 1) {
        this.count = this.count++;

        await axios.post(
          PermissionsBffRoutes.clearPermissionsCacheRoute(),
          {},
          withCredentialsConfig
        );

        const { data } = await axios.get<T>(fetchItemBffURL, {
          ...withCredentialsConfig,
          params
        });
        return data;
      }

      throw err;
    }
  }

  static async fetchItemByNanoId<T>(
    fetchItemByNanoIdBffURL: FetchItemByNanoIdBffURL,
    params: FetchItemBffRequestParams
  ) {
    const { data } = await axios.get<T>(fetchItemByNanoIdBffURL, {
      ...withCredentialsConfig,
      params
    });
    return data;
  }

  static async createItem<ResponseDataType, RequestDataType>(
    createItemBffURL: CreateItemBffURL,
    createItemData: RequestDataType
  ) {
    try {
      const { data } = await axios.post<{ data: ResponseDataType }>(
        createItemBffURL,
        createItemData,
        withCredentialsConfig
      );
      return data.data;
    } catch (err) {
      const error = processError(err);

      if (error.status === 403 && this.count <= 1) {
        this.count = this.count++;

        await axios.post(
          PermissionsBffRoutes.clearPermissionsCacheRoute(),
          {},
          withCredentialsConfig
        );

        const { data } = await axios.post<{ data: ResponseDataType }>(
          createItemBffURL,
          createItemData,
          withCredentialsConfig
        );
        return data.data;
      }

      throw err;
    }
  }

  static async updateItem<ResponseDataType, RequestDataType>(
    updateItemBffURL: UpdateItemBffURL,
    updateItemData: RequestDataType
  ) {
    try {
      const { data } = await axios.patch<{ data: ResponseDataType }>(
        updateItemBffURL,
        updateItemData,
        withCredentialsConfig
      );
      return data.data;
    } catch (err) {
      const error = processError(err);

      if (error.status === 403 && this.count <= 1) {
        this.count = this.count++;

        await axios.post(
          PermissionsBffRoutes.clearPermissionsCacheRoute(),
          {},
          withCredentialsConfig
        );

        const { data } = await axios.patch<{ data: ResponseDataType }>(
          updateItemBffURL,
          updateItemData,
          withCredentialsConfig
        );
        return data.data;
      }

      throw err;
    }
  }

  static async deleteItem<ResponseDataType>(
    deleteItemBffURL: DeleteItemBffURL
  ) {
    try {
      const { data } = await axios.delete<ResponseDataType>(deleteItemBffURL, {
        ...withCredentialsConfig,
        headers: { 'Content-Type': 'application/json' }
      });
      return data;
    } catch (err) {
      const error = processError(err);

      if (error.status === 403 && this.count <= 1) {
        this.count = this.count++;

        await axios.post(
          PermissionsBffRoutes.clearPermissionsCacheRoute(),
          {},
          withCredentialsConfig
        );

        const { data } = await axios.delete<ResponseDataType>(
          deleteItemBffURL,
          {
            ...withCredentialsConfig,
            headers: { 'Content-Type': 'application/json' }
          }
        );
        return data;
      }

      throw err;
    }
  }

  static async postItems<ResponseDataType, RequestDataType>(
    postItemsBffURL: PostItemsBffURL,
    postItemsData: RequestDataType
  ) {
    try {
      const { data } = await axios.post<ResponseDataType>(
        postItemsBffURL,
        postItemsData,
        withCredentialsConfig
      );
      return data;
    } catch (err) {
      const error = processError(err);

      if (error.status === 403 && this.count <= 1) {
        this.count = this.count++;

        await axios.post(
          PermissionsBffRoutes.clearPermissionsCacheRoute(),
          {},
          withCredentialsConfig
        );

        const { data } = await axios.post<ResponseDataType>(
          postItemsBffURL,
          postItemsData,
          withCredentialsConfig
        );
        return data;
      }

      throw err;
    }
  }

  static async postItem<ResponseDataType, RequestDataType>(
    postItemBffURL: PostItemBffURL,
    postItemData: RequestDataType
  ) {
    try {
      const { data } = await axios.post<ResponseDataType>(
        postItemBffURL,
        postItemData,
        withCredentialsConfig
      );
      return data;
    } catch (err) {
      const error = processError(err);

      if (error.status === 403 && this.count <= 1) {
        this.count = this.count++;

        await axios.post(
          PermissionsBffRoutes.clearPermissionsCacheRoute(),
          {},
          withCredentialsConfig
        );

        const { data } = await axios.post<ResponseDataType>(
          postItemBffURL,
          postItemData,
          withCredentialsConfig
        );
        return data;
      }

      throw err;
    }
  }
}
