import {
  FetchSpecializationsQuery,
  FetchSpecializationsCountType,
  FetchSpecializationsPage,
  FetchSpecializationsPageSize,
  FetchSpecializationsFilters,
  FetchSpecializationsSort,
  FetchSpecializationsIndexQueryName,
  FetchSpecializationsIndexCountType,
  FetchSpecializationsIndexPage,
  FetchSpecializationsIndexPageSize,
  FetchSpecializationsIndexFilters,
  FetchSpecializationsIndexSort,
  FetchSpecializationQuery,
  FetchSpecializationIndexQueryName,
  CreateSpecializationError,
  UpdateSpecializationError,
  DeleteSpecializationError,
  SpecializationId,
  SpecializationNanoId,
  SpecializationName,
  SpecializationUserId
} from './specializationsTypes';

import { BaseBffRequests } from '../BaseBffRequests';

import { SpecializationsBffRoutes } from './SpecializationsBffRoutes';

interface FetchSpecializationsRequestParams {
  query: FetchSpecializationsQuery;
  countType?: FetchSpecializationsCountType;
  page?: FetchSpecializationsPage;
  pageSize?: FetchSpecializationsPageSize;
  filters?: FetchSpecializationsFilters;
  sort?: FetchSpecializationsSort;
}

interface FetchSpecializationsIndexRequestParams {
  queryName: FetchSpecializationsIndexQueryName;
  countType?: FetchSpecializationsIndexCountType;
  page?: FetchSpecializationsIndexPage;
  pageSize?: FetchSpecializationsIndexPageSize;
  filters?: FetchSpecializationsIndexFilters;
  sort?: FetchSpecializationsIndexSort;
}

interface FetchSpecializationRequestParams {
  query: FetchSpecializationQuery;
}

interface FetchSpecializationIndexRequestParams {
  queryName: FetchSpecializationIndexQueryName;
}

export interface SpecializationRequestData {
  name?: SpecializationName;
}

export interface CreateSpecializationRequestData
  extends SpecializationRequestData {
  id?: SpecializationId;
  userId: SpecializationUserId;
  nanoId: SpecializationNanoId;
}

export interface UpdateSpecializationRequestData
  extends SpecializationRequestData {
  name?: SpecializationName;
}

export interface DeleteSpecializationRequestData {
  specializationNanoId: SpecializationNanoId;
}

export interface CreateSpecializationErrorResponse {
  message: CreateSpecializationError;
}

export interface UpdateSpecializationErrorResponse {
  message: UpdateSpecializationError;
}

export interface DeleteSpecializationErrorResponse {
  message: DeleteSpecializationError;
}

export class SpecializationsBffRequests extends BaseBffRequests {
  static fetchSpecializations<T>(params: FetchSpecializationsRequestParams) {
    return this.fetchItems<T>(
      SpecializationsBffRoutes.fetchSpecializationsRoute(),
      params
    );
  }

  static fetchSpecializationsIndex<T>(
    params: FetchSpecializationsIndexRequestParams
  ) {
    return this.fetchItemsIndex<T>(
      SpecializationsBffRoutes.fetchSpecializationsIndexRoute(),
      params
    );
  }

  static fetchSpecialization<T>(
    specializationId: SpecializationId,
    params: FetchSpecializationRequestParams
  ) {
    return this.fetchItem<T>(
      SpecializationsBffRoutes.fetchSpecializationRoute(specializationId),
      params
    );
  }

  static fetchSpecializationIndex<T>(
    specializationNanoId: SpecializationNanoId,
    params: FetchSpecializationIndexRequestParams
  ) {
    return this.fetchItemIndex<T>(
      SpecializationsBffRoutes.fetchSpecializationIndexRoute(
        specializationNanoId
      ),
      params
    );
  }

  static fetchSpecializationByNanoId<T>(
    specializationNanoId: SpecializationNanoId,
    params: FetchSpecializationRequestParams
  ) {
    return this.fetchItemByNanoId<T>(
      SpecializationsBffRoutes.fetchSpecializationByNanoIdRoute(
        specializationNanoId
      ),
      params
    );
  }

  static createSpecialization<T>(
    createSpecializationData: CreateSpecializationRequestData
  ) {
    return this.createItem<T, CreateSpecializationRequestData>(
      SpecializationsBffRoutes.createSpecializationRoute(),
      createSpecializationData
    );
  }

  static async updateSpecialization<T>(
    updateSpecializationNanoId: SpecializationNanoId,
    updateSpecializationData: UpdateSpecializationRequestData
  ) {
    return this.updateItem<T, UpdateSpecializationRequestData>(
      SpecializationsBffRoutes.updateSpecializationRoute(
        updateSpecializationNanoId
      ),
      updateSpecializationData
    );
  }

  static async deleteSpecialization<T>(
    deleteSpecializationNanoId: SpecializationNanoId
  ) {
    return this.deleteItem<T>(
      SpecializationsBffRoutes.deleteSpecializationRoute(
        deleteSpecializationNanoId
      )
    );
  }
}
