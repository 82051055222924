import { Icon, IconProps } from '@chakra-ui/icon';

const MessageIcon = ({
  w = '16px',
  h = '16px',
  fill = 'gray.500',
  ...props
}: IconProps) => (
  <Icon viewBox="0 0 16 16" w={w} h={h} fill={fill} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.665 6.984H5.33V5.966h5.334v1.018Zm-1.6 2.034H5.33V8h3.734v1.018ZM14.4 7.491C14.4 4.69 11.52 2.4 8 2.4c-3.52 0-6.4 2.29-6.4 5.09s2.88 5.092 6.4 5.092c.533 0 1.067-.051 1.6-.153l2.987 1.12c.053 0 .106.051.213.051s.213-.05.32-.102c.16-.102.213-.305.213-.458l-.266-2.393c.853-.916 1.333-2.036 1.333-3.156Z"
    />
  </Icon>
);

export default MessageIcon;
