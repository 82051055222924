import {
  FetchCompaniesQuery,
  FetchCompaniesCountType,
  FetchCompaniesPage,
  FetchCompaniesPageSize,
  FetchCompaniesFilters,
  FetchCompaniesSort,
  FetchCompaniesIndexQueryName,
  FetchCompaniesIndexCountType,
  FetchCompaniesIndexPage,
  FetchCompaniesIndexPageSize,
  FetchCompaniesIndexFilters,
  FetchCompaniesIndexSort,
  FetchCompanyQuery,
  FetchCompanyIndexQueryName,
  CreateCompanyError,
  UpdateCompanyError,
  DeleteCompanyError,
  CompanyId,
  CompanyNanoId,
  CompanyName,
  CompanyDescription,
  CompanyWebsite,
  CompanyCompanyType,
  CompanyWorkplace,
  CompanyFoundedAt,
  CompanyMainPhone,
  CompanyZipCode,
  CompanyUserId,
  MayBeCompanyImageId,
  MayBeCompanyIndustryId,
  MayBeCompanyEmployeesNumberId,
  MayBeCompanyCountryId,
  MayBeCompanyStateId,
  MayBeCompanyCityId,
  CompanyIndustryIds
} from './companiesTypes';

import { BaseBffRequests } from '../BaseBffRequests';

import { CompaniesBffRoutes } from './CompaniesBffRoutes';

interface FetchCompaniesRequestParams {
  query: FetchCompaniesQuery;
  countType?: FetchCompaniesCountType;
  page?: FetchCompaniesPage;
  pageSize?: FetchCompaniesPageSize;
  filters?: FetchCompaniesFilters;
  sort?: FetchCompaniesSort;
}

interface FetchCompaniesIndexRequestParams {
  queryName: FetchCompaniesIndexQueryName;
  countType?: FetchCompaniesIndexCountType;
  page?: FetchCompaniesIndexPage;
  pageSize?: FetchCompaniesIndexPageSize;
  filters?: FetchCompaniesIndexFilters;
  sort?: FetchCompaniesIndexSort;
}

interface FetchCompanyRequestParams {
  query: FetchCompanyQuery;
}

interface FetchCompanyIndexRequestParams {
  queryName: FetchCompanyIndexQueryName;
}

export interface CompanyRequestData {
  name: CompanyName;
  description: CompanyDescription;
  website: CompanyWebsite;
  companyType: CompanyCompanyType;
  workplace: CompanyWorkplace;
  foundedAt: CompanyFoundedAt;
  mainPhone: CompanyMainPhone;
  zipCode: CompanyZipCode;
  imageId: MayBeCompanyImageId;
  industryId: MayBeCompanyIndustryId;
  industryIds: CompanyIndustryIds;
  employeesNumberId: MayBeCompanyEmployeesNumberId;
  countryId: MayBeCompanyCountryId;
  stateId: MayBeCompanyStateId;
  cityId: MayBeCompanyCityId;
}

export interface CreateCompanyDefaultRequestData extends CompanyRequestData {
  id?: CompanyId;
  nanoId: CompanyNanoId;
  userId: CompanyUserId;
}

export type CreateCompanyRequestData = CreateCompanyDefaultRequestData;

export type UpdateCompanyRequestData = CompanyRequestData;

export interface DeleteCompanyRequestData {
  companyNanoId: CompanyNanoId;
}

export interface CreateCompanyErrorResponse {
  message: CreateCompanyError;
}

export interface UpdateCompanyErrorResponse {
  message: UpdateCompanyError;
}

export interface DeleteCompanyErrorResponse {
  message: DeleteCompanyError;
}

export class CompaniesBffRequests extends BaseBffRequests {
  static fetchCompanies<T>(params: FetchCompaniesRequestParams) {
    return this.fetchItems<T>(CompaniesBffRoutes.fetchCompaniesRoute(), params);
  }

  static fetchCompaniesIndex<T>(params: FetchCompaniesIndexRequestParams) {
    return this.fetchItemsIndex<T>(
      CompaniesBffRoutes.fetchCompaniesIndexRoute(),
      params
    );
  }

  static fetchCompany<T>(
    companyId: CompanyId,
    params: FetchCompanyRequestParams
  ) {
    return this.fetchItem<T>(
      CompaniesBffRoutes.fetchCompanyRoute(companyId),
      params
    );
  }

  static fetchCompanyIndex<T>(
    companyNanoId: CompanyNanoId,
    params: FetchCompanyIndexRequestParams
  ) {
    return this.fetchItemIndex<T>(
      CompaniesBffRoutes.fetchCompanyIndexRoute(companyNanoId),
      params
    );
  }

  static fetchCompanyByNanoId<T>(
    companyNanoId: CompanyNanoId,
    params: FetchCompanyRequestParams
  ) {
    return this.fetchItemByNanoId<T>(
      CompaniesBffRoutes.fetchCompanyByNanoIdRoute(companyNanoId),
      params
    );
  }

  static createCompany<T>(createCompanyData: CreateCompanyRequestData) {
    return this.createItem<T, CreateCompanyRequestData>(
      CompaniesBffRoutes.createCompanyRoute(),
      createCompanyData
    );
  }

  static async updateCompany<T>(
    updateCompanyNanoId: CompanyNanoId,
    updateCompanyData: UpdateCompanyRequestData
  ) {
    return this.updateItem<T, UpdateCompanyRequestData>(
      CompaniesBffRoutes.updateCompanyRoute(updateCompanyNanoId),
      updateCompanyData
    );
  }

  static async deleteCompany<T>(deleteCompanyNanoId: CompanyNanoId) {
    return this.deleteItem<T>(
      CompaniesBffRoutes.deleteCompanyRoute(deleteCompanyNanoId)
    );
  }
}
