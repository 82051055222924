import { ReactNode, useCallback, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import debounce from 'lodash/debounce';
import first from 'lodash/first';

import { ErrorMessage, IsDisabled, IsRequired } from '../../../../../types';

import {
  FetchSpecializationsEnabled,
  FetchSpecializationsPageSize,
  SpecializationFields,
  SpecializationId,
  SpecializationName,
  SpecializationNanoId,
  SpecializationUserId
} from '../../../../specializations/specializationsTypes';

import {
  fetchSpecializationsQuery,
  FetchSpecializationsResponse
} from '../../../../specializations/queries/fetchSpecializations.query';

import { usePaginatedSpecializations } from '../../../../specializations/hooks/usePaginatedSpecializations';
import { useCurrentUser } from '../../../../../auth/hooks/useAuth';
import { useCreateSpecialization } from '../../../../specializations/hooks/useCreateSpecialization';

import { SpecializationsCache } from '../../../../specializations/SpecializationsCache';

import {
  MultiSelectField,
  MultiSelectFieldReactHookFormControl,
  MultiSelectFieldReactHookFormFieldPath
} from '../../../../../helpers/forms/formFields/MultiSelectField';

import { generateNanoId } from '../../../../../utils/generateNanoId';

import { SelectOptionType } from '../../../../../helpers/forms/formFields/MultiSelectField/components/MultiSelectFieldControl';

interface SelectSpecializationsFormFieldRequiredProps<T extends FieldValues> {
  isRequired?: IsRequired;
  isDisabled?: IsDisabled;
  control: MultiSelectFieldReactHookFormControl<T>;
  item?: {
    specializations: {
      id: SpecializationId;
      name: SpecializationName;
    }[];
  } | null;
  errorMessage: ErrorMessage;
  labelAddon?: ReactNode;
}

function SelectSpecializationsFormField<T extends FieldValues>({
  isRequired,
  isDisabled,
  control,
  item,
  errorMessage,
  labelAddon
}: SelectSpecializationsFormFieldRequiredProps<T>) {
  const currentUser = useCurrentUser();

  const {
    createSpecialization,
    createSpecializationIsLoading,
    createSpecializationErrorMessage
  } = useCreateSpecialization({
    cacheKeys: [SpecializationsCache.indexCacheKey()]
  });

  const {
    specializations,
    specializationsIsLoading,
    specializationsErrorMessage,
    changeSpecializationsFilters
  } = usePaginatedSpecializations<FetchSpecializationsResponse>({
    query: fetchSpecializationsQuery,
    cacheKey: SpecializationsCache.indexCacheKey()
  });

  const {
    specializationsIsLoading: existingSpecializationsIsLoading,
    specializationsErrorMessage: existingSpecializationsErrorMessage,
    fetchSpecializations
  } = usePaginatedSpecializations<FetchSpecializationsResponse>({
    query: fetchSpecializationsQuery,
    cacheKey: SpecializationsCache.existsCacheKey(),
    enabled: false as FetchSpecializationsEnabled,
    initialPageSize: 10 as FetchSpecializationsPageSize
  });

  const defaultItemUserSpecializations = item?.specializations
    ? item?.specializations.map((userSpecialization) => ({
        value: userSpecialization.id,
        label: userSpecialization.name
      }))
    : undefined;

  const [inputValue, setInputValue] = useState<string>('');

  const debouncedFilterSpecializations = debounce<
    (updatedValue: string) => void
  >(
    (updatedValue) =>
      changeSpecializationsFilters({
        [SpecializationFields.NAME]: {
          operator: 'ilike',
          value: `${updatedValue}%`
        }
      }),
    500
  );

  const handleInputChange = useCallback<(value: string) => void>(
    (value) => {
      setInputValue(value);

      if (!value || value === '') {
        return;
      }

      debouncedFilterSpecializations(value);
    },
    [debouncedFilterSpecializations]
  );

  const onAddNewSpecialization = useCallback(async () => {
    const { data: existingSpecializations } = await fetchSpecializations({
      nextFilters: {
        [SpecializationFields.NAME]: { operator: 'eq', value: inputValue }
      }
    });

    const existingSpecialization = first(existingSpecializations);

    if (existingSpecialization) {
      const existingSpecializationValue = {
        label: existingSpecialization.name,
        value: existingSpecialization.id
      };

      setInputValue('');
      return existingSpecializationValue as SelectOptionType;
    }

    const specialization = await createSpecialization({
      name: inputValue as SpecializationName,
      userId: currentUser.id as SpecializationUserId,
      nanoId: generateNanoId<SpecializationNanoId>()
    });

    if (specialization?.id && specialization?.name) {
      const specializationValue = {
        label: specialization.name,
        value: specialization.id
      };

      setInputValue('');
      return specializationValue as SelectOptionType;
    }
  }, [inputValue, fetchSpecializations, currentUser.id, createSpecialization]);

  return (
    <MultiSelectField
      isRequired={isRequired}
      isDisabled={isDisabled}
      control={control}
      label="Specializations"
      labelAddon={labelAddon}
      placeholder="Select specialization"
      name={'specializationIds' as MultiSelectFieldReactHookFormFieldPath<T>}
      options={specializations.map((specialization) => ({
        value: specialization.id,
        label: specialization.name
      }))}
      defaultValue={defaultItemUserSpecializations}
      errorMessage={
        errorMessage ||
        specializationsErrorMessage ||
        createSpecializationErrorMessage ||
        existingSpecializationsErrorMessage
      }
      isLoading={
        specializationsIsLoading ||
        createSpecializationIsLoading ||
        existingSpecializationsIsLoading
      }
      onInputChange={handleInputChange}
      withAddNewButton={!!inputValue}
      addNewButtonLabel={`Add ${inputValue}`}
      addNewButtonToValueAction={onAddNewSpecialization}
    />
  );
}

export default SelectSpecializationsFormField;
