import { Icon, IconProps } from '@chakra-ui/icon';
import { Box } from '@chakra-ui/react';

const CheckIcon = ({
  w = '16px',
  h = '16px',
  fill = 'primary.500',
  stroke = 'white',
  strokeWidth = 'white',
  ...props
}: IconProps) => (
  <Icon viewBox="0 0 16 16" w={w} h={h} fill={fill} {...props}>
    <circle cx={8} cy={8} r={8} />
    <Box
      as="path"
      d="m4.297 8.665 2 2 5.333-5.333"
      stroke={stroke as string}
      strokeWidth={strokeWidth ?? 1.333}
    />
  </Icon>
);
export default CheckIcon;
