import { useCallback } from 'react';

import { AddSkillBadge } from '../../../helpers/AddSkillBadge';

import {
  AddSkillBadgeWrapperOnSelectSkill,
  AddSkillBadgeWrapperSkill
} from './AddSkillBadgeWrapper.types';

interface AddSkillBadgeWrapperProps {
  skill: AddSkillBadgeWrapperSkill;
  isDisabled?: boolean;
  isSelected: boolean;
  isRequired?: boolean;
  withoutPlusButton?: boolean;
  onSelectSkill: AddSkillBadgeWrapperOnSelectSkill;
  isLoading?: boolean;
}

function AddSkillBadgeWrapper({
  skill,
  isDisabled,
  isSelected,
  isRequired,
  withoutPlusButton,
  isLoading,
  onSelectSkill
}: AddSkillBadgeWrapperProps) {
  const handleSelect = useCallback<() => void>(
    () => onSelectSkill(skill),
    [onSelectSkill, skill]
  );

  return (
    <AddSkillBadge
      name={skill.name}
      isDisabled={isDisabled}
      isSelected={isSelected}
      isRequired={isRequired}
      withoutPlusButton={withoutPlusButton}
      onSelectAction={withoutPlusButton ? undefined : handleSelect}
      isLoading={isLoading}
    />
  );
}

export default AddSkillBadgeWrapper;
