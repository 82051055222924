import { useCallback, useRef } from 'react';
import { useChakraToast } from '../../../../../../../../helpers/useChakraToast';

import { FetchJobResponse } from '../../../../../../../jobs/queries/fetchJob.query';

import { PostJobIntakeForm } from '../../../../../../../jobs/pages/PostJobIntakePage/components/PostJobIntakeForm';
import { Grid } from '@chakra-ui/react';

interface AddControlPanelJobIntakeProps {
  job: FetchJobResponse;
  closeModal: () => void;
}

const AddControlPanelJobIntake = ({
  job,
  closeModal
}: AddControlPanelJobIntakeProps) => {
  const formRef = useRef<HTMLFormElement>(null);

  const toast = useChakraToast();

  const handleJobUpdated = useCallback(() => {
    toast.closeAll();

    toast({ description: 'Job Added Successfully' });

    closeModal();
  }, [toast, closeModal]);

  return (
    <Grid h="100%" gap={6} minH="500px" gridTemplateRows="1fr max-content ">
      <PostJobIntakeForm
        ref={formRef}
        job={job}
        onJobUpdated={handleJobUpdated}
        onCancel={closeModal}
      />
    </Grid>
  );
};

export default AddControlPanelJobIntake;
