import { SystemStyleObject } from '@chakra-ui/theme-tools';

const baseStyle: SystemStyleObject = {
  fontFamily: 'heading',
  fontWeight: '600'
};

const sizes: Record<string, SystemStyleObject> = {
  h1: {
    fontSize: '28px',
    fontWeight: '600',
    lineHeight: '40px'
  },
  h2: {
    fontSize: '24px',
    fontWeight: '600',
    lineHeight: '36px'
  },
  h3: {
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '32px'
  },
  h4: {
    fontSize: '18px',
    fontWeight: '600',
    lineHeight: '28px'
  },
  h5: {
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '24px'
  }
};

const variant: Record<string, SystemStyleObject> = {
  h1: {},
  h2: {},
  h3: {},
  h4: {},
  h5: {},
  h6: {}
};

const defaultProps = {
  size: 'h2',
  variant: 'h2'
};

export const Heading = {
  baseStyle,
  variant,
  sizes,
  defaultProps
};
