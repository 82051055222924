/* eslint-disable prettier/prettier */
import { Stack } from '@chakra-ui/react';
import { AuthFields } from '../../../../../../../auth/authTypes';
import { Button } from '../../../../../../../helpers/Button';
import { InputField } from '../../../../../../../helpers/forms/formFields/InputField';
import { PasswordInputField } from '../../../../../../../helpers/forms/formFields/PasswordInputField';
import { useRecruiterContext } from '../../../../../../../context';

const RecruiterSignUpModalSignInForm = () => {
  const {
    toggleRecruiterSignUpModal
  } = useRecruiterContext();

  return (
  <Stack spacing={6}>
    <InputField
      label="Personal email address"
      placeholder="Personal email address"
      type="email"
      name={AuthFields.EMAIL}
    />

    <PasswordInputField
      label="Password"
      placeholder="• • • • • • • • • •"
      name={AuthFields.PASSWORD}
    />

      <Button onClick={toggleRecruiterSignUpModal}>Sign In</Button>
  </Stack>
  );
};

export default RecruiterSignUpModalSignInForm;
