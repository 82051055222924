import { ReactNode, useCallback, useEffect } from 'react';
import { FieldValues } from 'react-hook-form';
import { SystemProps, SystemStyleObject } from '@chakra-ui/react';
import debounce from 'lodash/debounce';
import find from 'lodash/find';

import { ErrorMessage, IsDisabled, IsRequired } from '../../../../../types';

import {
  JobFunctionFields,
  JobFunctionId,
  JobFunctionName,
  FetchJobFunctionsFilters,
  FetchJobFunctionsSort
} from '../../../../jobFunctions/jobFunctionsTypes';
import { DisciplineId } from '../../../../disciplines/disciplinesTypes';
import { JobTitleId } from '../../../../jobTitles/jobTitlesTypes';

import {
  fetchJobFunctionsQuery,
  FetchJobFunctionsResponse
} from '../../../../jobFunctions/queries/fetchJobFunctions.query';

import { usePaginatedJobFunctions } from '../../../../jobFunctions/hooks/usePaginatedJobFunctions';

import { JobFunctionsCache } from '../../../../jobFunctions/JobFunctionsCache';

import {
  SelectField,
  SelectFieldReactHookFormControl,
  SelectFieldReactHookFormFieldPath
} from '../../../../../helpers/forms/formFields/SelectField';
import { useUpdateJobTitle } from '../../../../jobTitles/hooks/useUpdateJobTitle';
import { JobTitlesCache } from '../../../../jobTitles/JobTitlesCache';
import { useChakraToast } from '../../../../../helpers/useChakraToast';
import { JobJobFunctionId } from '../../../../jobs/jobsTypes';

type SelectJobFunctionFormFieldWithAssociatedJobTitleProps = {
  selectedFunctionId: JobFunctionId;
  selectedJobTitleId: JobTitleId;
  selectedDisciplineId: DisciplineId;
  selectDisciplineId: (disciplineId: DisciplineId) => void;
};

type SelectJobFunctionFormFieldWithoutAssociatedJobTitleProps = {
  selectedFunctionId?: never;
  selectedJobTitleId?: never;
  selectDisciplineId?: never;
  selectedDisciplineId?: never;
};

type SelectJobFunctionFormFieldRequiredProps<T extends FieldValues> = {
  isRequired?: IsRequired;
  isDisabled?: IsDisabled;
  control: SelectFieldReactHookFormControl<T>;
  item?: {
    jobFunction: {
      id: JobFunctionId;
      name: JobFunctionName;
    } | null;
  } | null;
  errorMessage: ErrorMessage;
  labelAddon?: ReactNode;
  label?: string;
  placeholder?: string;
  reactSelectStyles?: Record<string, unknown>;
  isBorderless?: boolean;
  visibility?: SystemProps['visibility'];
  withoutUpdateJobTitle?: boolean;
  withoutLabel?: boolean;
} & (
  | SelectJobFunctionFormFieldWithAssociatedJobTitleProps
  | SelectJobFunctionFormFieldWithoutAssociatedJobTitleProps
);

function SelectJobFunctionFormField<T extends FieldValues>({
  isRequired,
  isDisabled,
  control,
  item,
  errorMessage,
  labelAddon,
  label,
  placeholder,
  selectedFunctionId,
  selectedJobTitleId,
  selectDisciplineId,
  selectedDisciplineId,
  reactSelectStyles,
  isBorderless,
  visibility,
  withoutLabel,
  withoutUpdateJobTitle
}: SelectJobFunctionFormFieldRequiredProps<T>) {
  const {
    jobFunctions,
    jobFunctionsIsLoading,
    jobFunctionsErrorMessage,
    changeJobFunctionsFilters
  } = usePaginatedJobFunctions<FetchJobFunctionsResponse>({
    query: fetchJobFunctionsQuery,
    cacheKey: JobFunctionsCache.indexCacheKey(),
    initialFilters: {
      ...(item?.jobFunction?.name
        ? {
            [JobFunctionFields.NAME]: {
              operator: 'ilike',
              value: `${item?.jobFunction?.name}`
            }
          }
        : {})
    } as unknown as FetchJobFunctionsFilters,
    initialSort: {
      [JobFunctionFields.NAME]: { ascending: true }
    } as unknown as FetchJobFunctionsSort
  });

  const {
    updateJobTitle,
    updateJobTitleData,
    updateJobTitleIsLoading,
    updateJobTitleErrorMessage
  } = useUpdateJobTitle({
    jobTitleId: selectedJobTitleId as JobTitleId,
    cacheKeys: [JobTitlesCache.indexCacheKey()]
  });

  const toast = useChakraToast();

  const defaultItemUserJobFunction = item?.jobFunction
    ? { value: item.jobFunction.id, label: item.jobFunction.name }
    : undefined;

  const debouncedFilterJobFunctions = debounce<(updatedValue: string) => void>(
    (updatedValue) =>
      changeJobFunctionsFilters({
        [JobFunctionFields.NAME]: {
          operator: 'ilike',
          value: `${updatedValue}%`
        }
      }),
    500
  );

  const handleUpdateJobTitle = useCallback(() => {
    if (!updateJobTitleData && !updateJobTitleIsLoading) {
      updateJobTitle({
        jobFunctionId: selectedFunctionId as JobJobFunctionId
      });
    }
  }, [
    updateJobTitle,
    updateJobTitleData,
    updateJobTitleIsLoading,
    selectedFunctionId
  ]);

  useEffect(() => {
    if (selectedFunctionId) {
      const selectedJobFunction = find(
        jobFunctions,
        ({ id }) => id === selectedFunctionId
      );

      if (
        selectedJobFunction &&
        selectedJobFunction?.discipline?.id !== selectedDisciplineId
      ) {
        !withoutUpdateJobTitle && handleUpdateJobTitle();
        selectDisciplineId(selectedJobFunction.discipline?.id);
      }
    }
  }, [
    selectedFunctionId,
    jobFunctions,
    selectDisciplineId,
    handleUpdateJobTitle,
    selectedDisciplineId,
    withoutUpdateJobTitle
  ]);

  useEffect(() => {
    updateJobTitleErrorMessage &&
      toast({ title: updateJobTitleErrorMessage, status: 'error' });
  }, [updateJobTitleErrorMessage, toast]);

  return (
    <>
      <SelectField
        isRequired={isRequired}
        isDisabled={isDisabled}
        control={control}
        label={withoutLabel ? undefined : label || 'Job Function'}
        labelAddon={labelAddon}
        placeholder={placeholder || 'Select job function'}
        name={'jobFunctionId' as SelectFieldReactHookFormFieldPath<T>}
        options={jobFunctions.map((jobFunction) => ({
          value: jobFunction.id,
          label: jobFunction.name
        }))}
        defaultValue={defaultItemUserJobFunction}
        errorMessage={errorMessage || jobFunctionsErrorMessage}
        isLoading={jobFunctionsIsLoading}
        onInputChange={debouncedFilterJobFunctions}
        reactSelectStyles={
          isBorderless
            ? {
                control: (base: SystemStyleObject) => ({
                  ...base,
                  borderColor: 'transparent',
                  p: 0
                })
              }
            : reactSelectStyles
        }
        visibility={visibility}
        unsetValue
      />
    </>
  );
}

export default SelectJobFunctionFormField;
