import { useEffect } from 'react';
import { ButtonGroup, Flex, Stack } from '@chakra-ui/react';
import map from 'lodash/map';
import compact from 'lodash/compact';

import { useUpdateEmploymentSkillBadge } from '../../hooks/useUpdateEmploymentSkillBadge';
import { useAddEditEmploymentSkillBadgeForm } from './hooks/useAddEditEmploymentSkillBadgeForm';

import { AddEditEmploymentSkillBadgeFormEmploymentSkillBadge } from './AddEditEmploymentSkillBadgeForm.types';
import {
  EmploymentSkillBadgeFields,
  EmploymentSkillBadgeId,
  EmploymentSkillBadgeUsage,
  EmploymentSkillBadgeUsageTypes,
  FetchEmploymentSkillBadgesCacheKey,
  employmentSkillBadgeUsageTypes
} from '../../employmentSkillBadgesTypes';

import { InputField } from '../../../../helpers/forms/formFields/InputField';
import { SelectField } from '../../../../helpers/forms/formFields/SelectField';
import { PureButtonHelper } from '../../../../helpers/buttons/PureButtonHelper';
import { PureSubmitButtonHelper } from '../../../../helpers/buttons/PureSubmitButtonHelper';
import { AlertMessage } from '../../../../helpers/AlertMessage';

import { renderEmploymentSkillBadgeUsage } from '../../utils/renderEmploymentSkillBadgeUsage';

import { DateUtils } from '../../../../utils/DateUtils';
import { ErrorMessage, IsLoading } from '../../../../types';
import { FetchSkillBadgesCacheKey } from '../../../skillBadges/skillBadgesTypes';

interface AddEditEmploymentSkillBadgeFormProps {
  employmentSkillBadgeCacheKey: FetchEmploymentSkillBadgesCacheKey;
  skillBadgesCacheKey: FetchSkillBadgesCacheKey;
  lastEmploymentSkillBadgeId: EmploymentSkillBadgeId;
  employmentSkillBadge: AddEditEmploymentSkillBadgeFormEmploymentSkillBadge;
  onSaveEmploymentSkillBadges: () => void;
  onNextEmploymentSkillBadge: () => void;
  onDiscard: () => void;
  onCancel: () => void;
  isDiscarding: IsLoading;
  discardErrorMessage: ErrorMessage;
  isEditing: boolean;
}

function AddEditEmploymentSkillBadgeForm({
  employmentSkillBadge,
  skillBadgesCacheKey,
  employmentSkillBadgeCacheKey,
  lastEmploymentSkillBadgeId,
  onSaveEmploymentSkillBadges,
  onNextEmploymentSkillBadge,
  onDiscard,
  onCancel,
  isDiscarding,
  discardErrorMessage,
  isEditing
}: AddEditEmploymentSkillBadgeFormProps) {
  const {
    updateEmploymentSkillBadge,
    updateEmploymentSkillBadgeErrorMessage,
    updateEmploymentSkillBadgeIsLoading
  } = useUpdateEmploymentSkillBadge({
    employmentSkillBadgeId: employmentSkillBadge.id,
    cacheKeys: [
      employmentSkillBadgeCacheKey,
      skillBadgesCacheKey as unknown as FetchEmploymentSkillBadgesCacheKey
    ]
  });

  const {
    control,
    validationErrors,
    addEditEmploymentSkillBadgeFormErrorMessage,
    addEditEmploymentSkillBadgeFormIsLoading,
    resetAddEditEmploymentSkillBadgeForm,
    setAddEditEmploymentSkillBadgeFormValue,
    handleAddEditEmploymentSkillBadgeForm
  } = useAddEditEmploymentSkillBadgeForm({
    defaultValues: {
      usage: employmentSkillBadge.usage
    },
    onAddEditEmploymentSkillBadgeForm: async (data) => {
      await updateEmploymentSkillBadge(data);

      resetAddEditEmploymentSkillBadgeForm({ usage: '' });
      if (lastEmploymentSkillBadgeId === employmentSkillBadge.id) {
        onSaveEmploymentSkillBadges();
      } else {
        onNextEmploymentSkillBadge();
      }
    }
  });

  useEffect(() => {
    if (employmentSkillBadge) {
      setAddEditEmploymentSkillBadgeFormValue(
        EmploymentSkillBadgeFields.USAGE as 'usage',
        (employmentSkillBadge.usage === EmploymentSkillBadgeUsageTypes.NONE
          ? ''
          : employmentSkillBadge.usage) as EmploymentSkillBadgeUsage
      );
    }
  }, [employmentSkillBadge, setAddEditEmploymentSkillBadgeFormValue]);

  const defaultSkillUsage =
    employmentSkillBadge.usage === EmploymentSkillBadgeUsageTypes.NONE ||
    !employmentSkillBadge.usage
      ? undefined
      : {
          value: employmentSkillBadge.usage,
          label: renderEmploymentSkillBadgeUsage(employmentSkillBadge.usage)
        };

  return (
    <Stack
      as="form"
      spacing={4}
      onSubmit={handleAddEditEmploymentSkillBadgeForm}
    >
      <Flex gap={4} alignItems="flex-start" flexWrap="wrap">
        <Flex alignItems="center" flex={1}>
          <InputField
            value={`${
              employmentSkillBadge.employment?.jobTitle?.name ||
              employmentSkillBadge.employment?.name
            } (${compact([
              DateUtils.formatMonth(employmentSkillBadge.employment?.startDate),
              employmentSkillBadge.employment?.currently
                ? 'PRESENT'
                : DateUtils.formatMonth(
                    employmentSkillBadge.employment?.endDate
                  )
            ]).join(' - ')})`}
            isReadOnly
          />
        </Flex>

        <Flex alignItems="center" flex={1}>
          <SelectField
            control={control}
            placeholder="Skill Usage"
            name={EmploymentSkillBadgeFields.USAGE}
            options={map(employmentSkillBadgeUsageTypes, (usageType) => ({
              value: usageType,
              label: renderEmploymentSkillBadgeUsage(usageType)
            }))}
            defaultValue={defaultSkillUsage}
            errorMessage={validationErrors.usageValidationError}
            unsetValue
          />
        </Flex>
      </Flex>

      <Flex>
        <ButtonGroup ml="auto" gap={4}>
          {isEditing ? (
            <PureButtonHelper
              size="medium"
              hierarchy="unstyled"
              color="grey.900"
              i18nText="Cancel"
              onClick={onCancel}
            />
          ) : (
            <PureButtonHelper
              size="medium"
              hierarchy="unstyled"
              color="red.900"
              i18nText="Discard"
              isLoading={isDiscarding}
              onClick={onDiscard}
            />
          )}
          <PureSubmitButtonHelper
            size="medium"
            i18nText={
              lastEmploymentSkillBadgeId === employmentSkillBadge.id
                ? 'Save'
                : 'Add'
            }
            isLoading={
              addEditEmploymentSkillBadgeFormIsLoading ||
              updateEmploymentSkillBadgeIsLoading
            }
          />
        </ButtonGroup>
      </Flex>

      <AlertMessage
        message={
          addEditEmploymentSkillBadgeFormErrorMessage ||
          updateEmploymentSkillBadgeErrorMessage ||
          discardErrorMessage
        }
      />
    </Stack>
  );
}

export default AddEditEmploymentSkillBadgeForm;
