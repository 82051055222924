import {
  MayBe,
  FieldBrand,
  ItemID,
  ItemUUID,
  ItemCreatedAt
} from '../../../types';

import {
  JobCandidateCities,
  JobCandidateCity,
  JobCandidateCityId,
  JobCandidateCityIds,
  JobCandidateFirstName,
  JobCandidateId,
  JobCandidateImage,
  JobCandidateImageId,
  JobCandidateJobTitle,
  JobCandidateLastName,
  JobCandidateLinkedinUrl,
  JobCandidateName,
  JobCandidateNanoId,
  JobCandidateSearchTypeLabel,
  JobCandidateUpdatedAt,
  MayBeJobCandidateCompany,
  MayBeJobCandidateViewedAt
} from '../../jobCandidates/jobCandidatesTypes';
import { TalentPoolId } from '../../talentPools/talentPoolsTypes';

export type TalentPoolJobCandidateId = FieldBrand<
  ItemID,
  'TalentPoolJobCandidateId'
>;
export type TalentPoolJobCandidateUuid = FieldBrand<
  ItemUUID,
  'TalentPoolJobCandidateUuid'
>;
export type MayBeTalentPoolJobCandidateUuid = MayBe<TalentPoolJobCandidateUuid>;
export type TalentPoolJobCandidateCreatedAt = FieldBrand<
  ItemCreatedAt,
  'TalentPoolJobCandidateCreatedAt'
>;
export type TalentPoolJobCandidateJobCandidateId = FieldBrand<
  JobCandidateId,
  'TalentPoolJobCandidateJobCandidateId'
>;
export type TalentPoolJobCandidateTalentPoolId = FieldBrand<
  TalentPoolId,
  'TalentPoolJobCandidateTalentPoolId'
>;

export type TalentPoolJobCandidateJobCandidate = FieldBrand<
  {
    id: JobCandidateId;
    nanoId: JobCandidateNanoId;
    name: JobCandidateName;
    firstName: JobCandidateFirstName;
    lastName: JobCandidateLastName;
    jobTitle: JobCandidateJobTitle;
    cityId: JobCandidateCityId;
    city: JobCandidateCity;
    cityIds: JobCandidateCityIds;
    cities: JobCandidateCities;
    company: MayBeJobCandidateCompany;
    imageId: JobCandidateImageId;
    image: JobCandidateImage;
    updatedAt: JobCandidateUpdatedAt;
    linkedinUrl: JobCandidateLinkedinUrl;
    searchTypeLabel: JobCandidateSearchTypeLabel;
    viewedAt: MayBeJobCandidateViewedAt;
  },
  'TalentPoolJobCandidateJobCandidate'
>;

export const enum TalentPoolJobCandidateFields {
  ID = 'id',
  UUID = 'uuid',
  TALENT_POOL_ID = 'talentPoolId',
  JOB_CANDIDATE_ID = 'jobCandidateId',
  CREATED_AT = 'createdAt',
  JOB_CANDIDATE = 'jobCandidate',
  JOB_FUNCTION_ID = 'jobFunctionId'
}
