import { Flex, Stack } from '@chakra-ui/layout';

import { MainActiveLink, useRightSidebarContext } from '../../../../context';

import { IconsEnum } from '../../../../icons';

import { IconButton } from '../../../../helpers/buttons/IconButton';
import { ProductsMenuIcon } from '../../../../icons/ProductsMenuIcon';
import { CalendarRoutes } from '../../../../main/calendar/CalendarRoutes';
import { InboxRoutes } from '../../../../main/inbox/InboxRoutes';
import { UpdatesRoutes } from '../../../../main/updates/UpdatesRoutes';
import { ProductsMenuPopup } from './components/ProductsMenuPopup';
import { RightSidebarIconButton } from './components/RightSidebarIconButton';
import { TasksPopup } from './components/TasksPopup';

interface RightSidebarProps {
  activeLink: MainActiveLink;
}

const RightSidebar = ({ activeLink }: RightSidebarProps) => {
  const { isProductsSidebarOpened, toggleProductsSidebar } =
    useRightSidebarContext();

  return (
    <Flex position="relative" zIndex={9}>
      <TasksPopup />

      <ProductsMenuPopup />

      <Flex
        flexDirection="column"
        h="100vh"
        w="72px"
        bg="white"
        zIndex={1}
        borderLeft="1px solid"
        borderLeftColor="gray.200"
        pos="fixed"
        right={0}
        top={0}
        py={4}
        px={3}
      >
        <Stack spacing={8}>
          <IconButton
            hierarchy="link"
            aria-label="Products menu"
            icon={
              <ProductsMenuIcon
                fill={isProductsSidebarOpened ? 'primary.500' : 'gray.600'}
                transitionProperty="fill"
                transitionDuration="fast"
              />
            }
            onClick={toggleProductsSidebar}
          />

          <RightSidebarIconButton
            icon={IconsEnum.UPDATES_BUTTON}
            isOpen={activeLink === 'updates'}
            title="Updates"
            url={UpdatesRoutes.index()}
          />

          <RightSidebarIconButton
            icon={IconsEnum.NOTES_BUTTON}
            isOpen={activeLink === 'inbox'}
            title="Inbox"
            url={InboxRoutes.index()}
          />

          <RightSidebarIconButton
            icon={IconsEnum.CALENDAR_BUTTON}
            isOpen={activeLink === 'calendar'}
            title="Calendar"
            url={CalendarRoutes.index()}
          />
        </Stack>
      </Flex>
    </Flex>
  );
};

export default RightSidebar;
