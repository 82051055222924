import { Icon, IconProps } from '@chakra-ui/icon';

const NXRecruiter = ({ h = 6, w = 6, ...props }: IconProps) => (
  <Icon w={w} h={h} viewBox="0 0 24 24" {...props} fill="none">
    <path
      fill="#00857F"
      d="M12.003 12.289c1.946 0 3.456-2.168 3.456-4.032A3.46 3.46 0 0012.003 4.8a3.46 3.46 0 00-3.456 3.456c0 1.864 1.51 4.032 3.456 4.032zM16.314 14.963c-.887-.432-2.355-.947-4.312-.947-1.957 0-3.425.515-4.312.947a2.601 2.601 0 00-1.448 2.327v1.622c0 .159.129.288.288.288h10.944c.16 0 .288-.13.288-.288V17.29c0-.985-.568-1.899-1.448-2.327z"
    />
    <path
      fill="#0BAD90"
      d="M23.347 7.68a.654.654 0 01-.654-.654V2.835a1.53 1.53 0 00-1.528-1.528h-4.191a.654.654 0 010-1.307h4.191A2.838 2.838 0 0124 2.835v4.191a.654.654 0 01-.653.654zM23.347 16.32a.654.654 0 00-.654.654v4.191a1.53 1.53 0 01-1.528 1.528h-4.191a.654.654 0 000 1.307h4.191A2.838 2.838 0 0024 21.165v-4.191a.654.654 0 00-.653-.654zM.653 7.68a.654.654 0 00.654-.654V2.835a1.53 1.53 0 011.528-1.528h4.191a.654.654 0 000-1.307H2.835A2.838 2.838 0 000 2.835v4.191c0 .361.292.654.653.654zM.653 16.32c.361 0 .654.293.654.654v4.191a1.53 1.53 0 001.528 1.528h4.191a.654.654 0 010 1.307H2.835A2.838 2.838 0 010 21.165v-4.191c0-.361.292-.654.653-.654z"
    />
  </Icon>
);
export default NXRecruiter;
