import {
  SkillLicenseItemBffID,
  SkillLicenseItemBffNanoID,
  FetchSkillLicensesBffURL,
  FetchSkillLicenseBffURL,
  FetchSkillLicenseByNanoIdBffURL,
  CreateSkillLicenseBffURL,
  UpdateSkillLicenseBffURL,
  DeleteSkillLicenseBffURL
} from './skillLicensesTypes';

import { BaseBffRoutes } from '../BaseBffRoutes';

export class SkillLicensesBffRoutes extends BaseBffRoutes {
  protected static route = 'skillLicenses';

  static fetchSkillLicensesRoute() {
    return this.fetchItemsRoute<FetchSkillLicensesBffURL>();
  }

  static fetchSkillLicensesIndexRoute() {
    return this.fetchItemsIndexRoute<FetchSkillLicensesBffURL>();
  }

  static fetchSkillLicenseRoute(skillLicenseItemBffID: SkillLicenseItemBffID) {
    return this.fetchItemRoute<FetchSkillLicenseBffURL>(skillLicenseItemBffID);
  }

  static fetchSkillLicenseIndexRoute(
    skillLicenseItemBffNanoID: SkillLicenseItemBffNanoID
  ) {
    return this.fetchItemIndexRoute<FetchSkillLicenseBffURL>(
      skillLicenseItemBffNanoID
    );
  }

  static fetchSkillLicenseByNanoIdRoute(
    skillLicenseItemBffNanoID: SkillLicenseItemBffNanoID
  ) {
    return this.fetchItemByNanoIdRoute<FetchSkillLicenseByNanoIdBffURL>(
      skillLicenseItemBffNanoID
    );
  }

  static createSkillLicenseRoute() {
    return this.createItemRoute<CreateSkillLicenseBffURL>();
  }

  static updateSkillLicenseRoute(
    skillLicenseItemBffNanoID: SkillLicenseItemBffID | SkillLicenseItemBffNanoID
  ) {
    return this.updateItemRoute<UpdateSkillLicenseBffURL>(
      skillLicenseItemBffNanoID
    );
  }

  static deleteSkillLicenseRoute(
    skillLicenseItemBffNanoID: SkillLicenseItemBffNanoID
  ) {
    return this.deleteItemRoute<DeleteSkillLicenseBffURL>(
      skillLicenseItemBffNanoID
    );
  }
}
