import { Fragment, ReactNode } from 'react';

import { SKIP_PERMISSIONS_CHECKS } from '../../../../config';

import { PermissionAction } from '../../../permissions/permissionsTypes';

import { useAuth } from '../../../../auth/hooks/useAuth';

import { Flex, Stack } from '@chakra-ui/react';
import { Heading } from '../../../../helpers/Heading';
import { LoadingSkeleton } from '../../../../helpers/LoadingSkeleton';
import { Logo } from '../../../../helpers/Logo';
import { PureNextLinkHelper } from '../../../../helpers/PureNextLinkHelper';
import { Text } from '../../../../helpers/Text';
import { Error403Icon } from '../../../../icons/Error403Icon';
import { MainRoutes } from '../../../MainRoutes';

interface CheckPermissionLayoutProps {
  action: string;
  appAction: string;
  children: ReactNode;
}

function CheckPermissionLayout({
  action,
  appAction,
  children
}: CheckPermissionLayoutProps) {
  const { authFetched, currentUser } = useAuth();

  return (
    <LoadingSkeleton loaded={authFetched}>
      {SKIP_PERMISSIONS_CHECKS ||
      (currentUser.can(appAction as PermissionAction) &&
        currentUser.can(action as PermissionAction)) ? (
        <Fragment>{children}</Fragment>
      ) : (
        <Fragment>
          <Flex
            w="100vw"
            h="100vh"
            bg="#F7F7F7"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Stack
              p={6}
              w="100%"
              spacing={6}
              overflow="auto"
              alignItems="center"
            >
              <Flex w="100%" maxW="600px" justifyContent="center">
                <Logo />
              </Flex>

              <Flex
                w="100%"
                gap={10}
                maxW="600px"
                display="flex"
                alignItems="center"
                flexDirection="column"
                justifyContent="center"
                bg={{ sm: 'white' }}
                p={{ sm: 8, lg: 12 }}
              >
                <Error403Icon />

                <Stack spacing={4} maxW={'432px'}>
                  <Heading level="h3" textAlign="center">
                    Access Forbidden
                  </Heading>
                  <Text
                    fontSize="15px"
                    lineHeight="2"
                    color="gray.600"
                    textAlign="center"
                  >
                    <>
                      {console.log('CheckPermissionLayout', {
                        appAction,
                        action
                      })}
                      Access {currentUser.name ? `for ${currentUser.name}` : ''}{' '}
                      to requested resource is forbidden. If you think this is a
                      mistake,{' '}
                      <PureNextLinkHelper
                        type="text"
                        as="a"
                        href={MainRoutes.index()}
                        fontWeight="medium"
                        i18nText="contact us"
                      />
                    </>
                  </Text>
                  .
                </Stack>

                <PureNextLinkHelper
                  type="button"
                  href={MainRoutes.index()}
                  as="a"
                  size="medium"
                  _hover={{ textDecoration: 'none' }}
                  i18nText="Take me home"
                />
              </Flex>
            </Stack>
          </Flex>
        </Fragment>
      )}
    </LoadingSkeleton>
  );
}

export default CheckPermissionLayout;
