import { Icon, IconProps } from '@chakra-ui/icon';

const MoreIcon = ({ w = '24px', h = '24px', fill, ...props }: IconProps) => (
  <Icon
    viewBox="0 0 24 24"
    w={w}
    h={h}
    fill={fill || 'currentColor'}
    {...props}
  >
    <path d="M12.001 13.335a1.333 1.333 0 1 0 0-2.667 1.333 1.333 0 0 0 0 2.667ZM6.001 13.335a1.333 1.333 0 1 0 0-2.667 1.333 1.333 0 0 0 0 2.667ZM18.001 13.335a1.333 1.333 0 1 0 0-2.667 1.333 1.333 0 0 0 0 2.667Z" />
  </Icon>
);

export default MoreIcon;
