import {
  FetchItemsPage,
  FetchItemsPageSize,
  FetchItemsFilters,
  FetchItemsSort,
  FetchItemsIndexPage,
  FetchItemsIndexPageSize,
  FetchItemsIndexFilters,
  FetchItemsIndexSort
} from './types';

export const DEFAULT_TIME_ZONE = 'America/Chicago';

export const INITIAL_PAGE = 1 as FetchItemsPage;
export const INITIAL_PAGE_SIZE = 50 as FetchItemsPageSize;
export const INITIAL_FILTERS = {} as FetchItemsFilters;
export const INITIAL_SORT = {
  id: { ascending: false }
} as unknown as FetchItemsSort;

export const INITIAL_INDEX_PAGE = 1 as FetchItemsIndexPage;
export const INITIAL_INDEX_PAGE_SIZE = 50 as FetchItemsIndexPageSize;
export const INITIAL_INDEX_FILTERS = {} as FetchItemsIndexFilters;
export const INITIAL_INDEX_SORT = {
  createdAt: { ascending: false }
} as unknown as FetchItemsIndexSort;
