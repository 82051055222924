import {
  FetchEmploymentsQuery,
  FetchEmploymentsCountType,
  FetchEmploymentsPage,
  FetchEmploymentsPageSize,
  FetchEmploymentsFilters,
  FetchEmploymentsSort,
  FetchEmploymentsIndexQueryName,
  FetchEmploymentsIndexCountType,
  FetchEmploymentsIndexPage,
  FetchEmploymentsIndexPageSize,
  FetchEmploymentsIndexFilters,
  FetchEmploymentsIndexSort,
  FetchEmploymentQuery,
  FetchEmploymentIndexQueryName,
  CreateEmploymentError,
  UpdateEmploymentError,
  DeleteEmploymentError,
  EmploymentId,
  EmploymentNanoId,
  EmploymentName,
  MayBeEmploymentStartDate,
  MayBeEmploymentEndDate,
  EmploymentUserId,
  MayBeEmploymentCompanyId,
  MayBeEmploymentJobCandidateId,
  MayBeEmploymentJobResumeId,
  MayBeEmploymentJobResumeRecruiterId
} from './employmentsTypes';

import { BaseBffRequests } from '../BaseBffRequests';

import { EmploymentsBffRoutes } from './EmploymentsBffRoutes';

interface FetchEmploymentsRequestParams {
  query: FetchEmploymentsQuery;
  countType?: FetchEmploymentsCountType;
  page?: FetchEmploymentsPage;
  pageSize?: FetchEmploymentsPageSize;
  filters?: FetchEmploymentsFilters;
  sort?: FetchEmploymentsSort;
}

interface FetchEmploymentsIndexRequestParams {
  queryName: FetchEmploymentsIndexQueryName;
  countType?: FetchEmploymentsIndexCountType;
  page?: FetchEmploymentsIndexPage;
  pageSize?: FetchEmploymentsIndexPageSize;
  filters?: FetchEmploymentsIndexFilters;
  sort?: FetchEmploymentsIndexSort;
}

interface FetchEmploymentRequestParams {
  query: FetchEmploymentQuery;
}

interface FetchEmploymentIndexRequestParams {
  queryName: FetchEmploymentIndexQueryName;
}

export interface EmploymentRequestData {
  name?: EmploymentName;
  startDate?: MayBeEmploymentStartDate;
  endDate?: MayBeEmploymentEndDate;
}

export interface CreateEmploymentDefaultRequestData
  extends EmploymentRequestData {
  id?: EmploymentId;
  userId: EmploymentUserId;
  companyId: MayBeEmploymentCompanyId;
  nanoId: EmploymentNanoId;
}

interface CreateEmploymentWithJobCandidateIdRequestData
  extends CreateEmploymentDefaultRequestData {
  jobCandidateId: MayBeEmploymentJobCandidateId;
}

interface CreateEmploymentWithJobResumeIdRequestData
  extends CreateEmploymentDefaultRequestData {
  jobResumeId: MayBeEmploymentJobResumeId;
}

interface CreateEmploymentWithJobResumeRecruiterIdRequestData
  extends CreateEmploymentDefaultRequestData {
  jobResumeRecruiterId: MayBeEmploymentJobResumeRecruiterId;
}

export type CreateEmploymentRequestData =
  | CreateEmploymentWithJobCandidateIdRequestData
  | CreateEmploymentWithJobResumeIdRequestData
  | CreateEmploymentWithJobResumeRecruiterIdRequestData;

export interface UpdateEmploymentRequestData extends EmploymentRequestData {
  name?: EmploymentName;
}

export interface DeleteEmploymentRequestData {
  employmentNanoId: EmploymentNanoId;
}

export interface CreateEmploymentErrorResponse {
  message: CreateEmploymentError;
}

export interface UpdateEmploymentErrorResponse {
  message: UpdateEmploymentError;
}

export interface DeleteEmploymentErrorResponse {
  message: DeleteEmploymentError;
}

export class EmploymentsBffRequests extends BaseBffRequests {
  static fetchEmployments<T>(params: FetchEmploymentsRequestParams) {
    return this.fetchItems<T>(
      EmploymentsBffRoutes.fetchEmploymentsRoute(),
      params
    );
  }

  static fetchEmploymentsIndex<T>(params: FetchEmploymentsIndexRequestParams) {
    return this.fetchItemsIndex<T>(
      EmploymentsBffRoutes.fetchEmploymentsIndexRoute(),
      params
    );
  }

  static fetchEmployment<T>(
    employmentId: EmploymentId,
    params: FetchEmploymentRequestParams
  ) {
    return this.fetchItem<T>(
      EmploymentsBffRoutes.fetchEmploymentRoute(employmentId),
      params
    );
  }

  static fetchEmploymentIndex<T>(
    employmentNanoId: EmploymentNanoId,
    params: FetchEmploymentIndexRequestParams
  ) {
    return this.fetchItemIndex<T>(
      EmploymentsBffRoutes.fetchEmploymentIndexRoute(employmentNanoId),
      params
    );
  }

  static fetchEmploymentByNanoId<T>(
    employmentNanoId: EmploymentNanoId,
    params: FetchEmploymentRequestParams
  ) {
    return this.fetchItemByNanoId<T>(
      EmploymentsBffRoutes.fetchEmploymentByNanoIdRoute(employmentNanoId),
      params
    );
  }

  static createEmployment<T>(
    createEmploymentData: CreateEmploymentRequestData
  ) {
    return this.createItem<T, CreateEmploymentRequestData>(
      EmploymentsBffRoutes.createEmploymentRoute(),
      createEmploymentData
    );
  }

  static async updateEmployment<T>(
    updateEmploymentNanoId: EmploymentNanoId,
    updateEmploymentData: UpdateEmploymentRequestData
  ) {
    return this.updateItem<T, UpdateEmploymentRequestData>(
      EmploymentsBffRoutes.updateEmploymentRoute(updateEmploymentNanoId),
      updateEmploymentData
    );
  }

  static async deleteEmployment<T>(deleteEmploymentNanoId: EmploymentNanoId) {
    return this.deleteItem<T>(
      EmploymentsBffRoutes.deleteEmploymentRoute(deleteEmploymentNanoId)
    );
  }
}
