import { Fragment } from 'react';
import { Box, Flex, Stack } from '@chakra-ui/react';
import size from 'lodash/size';

import { JobSkillBadges } from '../../../../jobsTypes';
import { SkillKindValues } from '../../../../../skills/skillsTypes';
import { TalentPoolSkillBadges } from '../../../../../talentPools/talentPoolsTypes';

import { SkillBadgeOnly } from '../../../../../skillBadges/helpers/SkillBadgeOnly';

import { Heading } from '../../../../../../helpers/Heading';
import { Text } from '../../../../../../helpers/Text';

import { CompletedFieldIcon } from '../../../../../../icons/CompletedFieldIcon';
import { PendingCompletionIcon } from '../../../../../../icons/PendingCompletionIcon';

interface ViewJobSkillsJobProps {
  job: {
    skillBadges: JobSkillBadges;
  };
  talentPool?: never;
}

interface ViewJobSkillsTalentProps {
  talentPool: {
    skillBadges: TalentPoolSkillBadges;
  };
  job?: never;
}

type ViewJobSkillsProps = {
  showCheckIcon?: boolean;
  isCareerSiteView?: boolean;
} & (ViewJobSkillsJobProps | ViewJobSkillsTalentProps);

function ViewJobSkills({
  job,
  talentPool,
  showCheckIcon,
  isCareerSiteView
}: ViewJobSkillsProps) {
  const computerSkills = job
    ? job.skillBadges?.filter(
        (skillBadge) => skillBadge.skill?.kind === SkillKindValues.COMPUTER
      )
    : talentPool?.skillBadges?.filter(
        (skillBadge) => skillBadge.skill?.kind === SkillKindValues.COMPUTER
      );

  const industrySkills = job
    ? job.skillBadges?.filter(
        (skillBadge) => skillBadge.skill?.kind === SkillKindValues.INDUSTRY
      )
    : talentPool?.skillBadges?.filter(
        (skillBadge) => skillBadge.skill?.kind === SkillKindValues.INDUSTRY
      );

  if (size(computerSkills) === 0 && size(industrySkills) === 0) {
    return null;
  }

  return (
    <Stack
      spacing={4}
      bg={{ base: isCareerSiteView ? 'gray.50' : 'white', lg: 'unset' }}
      p={{ base: isCareerSiteView ? '19px 24px' : 6, lg: 0 }}
      border={{ base: '1px solid', lg: 'none' }}
      borderColor={{
        base: isCareerSiteView ? 'gray.200' : 'gray.100',
        lg: 'none'
      }}
      borderRadius="base"
    >
      <Heading
        level="h5"
        color="gray.900"
        fontWeight="600"
        alignItems="center"
        display="inline-flex"
      >
        <Box as="span">Skills and Knowledge</Box>
        {showCheckIcon && (
          <Fragment>
            {size(computerSkills) < 2 || size(industrySkills) < 2 ? (
              <PendingCompletionIcon ml="2px" />
            ) : (
              <CompletedFieldIcon ml="2px" />
            )}
          </Fragment>
        )}
      </Heading>

      {size(computerSkills) > 0 ? (
        <Fragment>
          <Text textStyle="upperCase1Regular" color="gray.600">
            computer skills
          </Text>
          <Flex gap={3} flexFlow="row wrap">
            {computerSkills.map((skillBadge) => (
              <SkillBadgeOnly
                key={skillBadge.nanoId}
                name={skillBadge.skill.name}
                experience={skillBadge.experience}
                rating={skillBadge.rating}
                certified={skillBadge.certified}
                requirements={skillBadge.requirements}
                skillLicense={skillBadge.skillLicense}
                withoutPortal
              />
            ))}
          </Flex>
        </Fragment>
      ) : null}

      {size(industrySkills) > 0 ? (
        <Fragment>
          <Text textStyle="upperCase1Regular" color="gray.600">
            industry knowledge
          </Text>
          <Flex gap={3} flexFlow="row wrap">
            {industrySkills.map((skillBadge) => (
              <SkillBadgeOnly
                key={skillBadge.nanoId}
                name={skillBadge.skill.name}
                experience={skillBadge.experience}
                rating={skillBadge.rating}
                certified={skillBadge.certified}
                requirements={skillBadge.requirements}
                skillLicense={skillBadge.skillLicense}
                withoutPortal
              />
            ))}
          </Flex>
        </Fragment>
      ) : null}
    </Stack>
  );
}

export default ViewJobSkills;
