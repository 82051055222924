import { Flex, Stack, useDisclosure } from '@chakra-ui/react';
import { useEffect, useRef } from 'react';
import { Button } from '../../../../../../helpers/Button';
import { Text } from '../../../../../../helpers/Text';
import { WorkflowStage } from '../../../../components/modals/ViewJobCandidateModal/helpers/VewiJobCandidateModalHeader/ViewJobCandidateModalHeader.types';
import {
  ViewJobCandidateDetailsCandidate,
  ViewJobCandidateDetailsJobType
} from '../../pages/ViewJobCandidateDetailsProfilePage/ViewJobCandidateDetailsProfilePage.types';
import { ViewJobCandidateDetailsProfileOverviewAccess } from './components/ViewJobCandidateDetailsProfileOverviewAccess';
import { ViewJobCandidateDetailsProfileOverviewActivities } from './components/ViewJobCandidateDetailsProfileOverviewActivities';
import { ViewJobCandidateDetailsProfileOverviewCalendar } from './components/ViewJobCandidateDetailsProfileOverviewCalendar';
import { ViewJobCandidateDetailsProfileOverviewPreferences } from './components/ViewJobCandidateDetailsProfileOverviewPreferences';
import { ViewJobCandidateDetailsProfileOverviewQualifications } from './components/ViewJobCandidateDetailsProfileOverviewQualifications';
import { ViewJobCandidateDetailsProfileOverviewSource } from './components/ViewJobCandidateDetailsProfileOverviewSource';
import { ViewJobCandidateDetailsProfileOverviewTasks } from './components/ViewJobCandidateDetailsProfileOverviewTasks';
import {
  CandidateSubmissionShareSettingsFields,
  CandidateSubmissionShareSettingsField
} from '../../../../../candidateSubmissions/candidateSubmissionsTypes';
import { useViewJobCandidateDetailsSubmissionContext } from '../../contexts/ViewJobCandidateDetailsSubmissionContext';

interface ViewJobCandidateDetailsProfileOverviewProps {
  isOpen: boolean;
  onToggle: () => void;
  workflowStages: WorkflowStage[];
  job: ViewJobCandidateDetailsJobType;
  jobCandidate: ViewJobCandidateDetailsCandidate;
  isHiringPortalPage: boolean;
  prospect?: boolean;
}

export default function ViewJobCandidateDetailsProfileOverview({
  job,
  isOpen,
  onToggle,
  jobCandidate,
  isHiringPortalPage,
  prospect
}: ViewJobCandidateDetailsProfileOverviewProps) {
  const overviewContainerRef = useRef<HTMLDivElement>(null);
  const sourceRef = useRef<HTMLDivElement>(null);
  const { sharedFields } = useViewJobCandidateDetailsSubmissionContext();

  const {
    isOpen: isHovered,
    onOpen: onMouseHover,
    onClose: onMouseLeave
  } = useDisclosure();

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        if (sourceRef.current) {
          sourceRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  return (
    <Stack
      top="120px"
      spacing={0}
      overflow="auto"
      position="sticky"
      overflowX="hidden"
      transitionDuration="slow"
      ref={overviewContainerRef}
      height="calc(100vh - 193px)"
      overflowY={isOpen && isHovered ? 'auto' : 'hidden'}
      onMouseOver={isOpen ? onMouseHover : undefined}
      onMouseLeave={isOpen ? onMouseLeave : undefined}
      minW={isOpen ? 352 : 6}
      w={isOpen ? 352 : 6}
      flexShrink={0}
    >
      <Stack pos="relative" spacing={0}>
        <Flex
          w="full"
          overflow="hidden"
          h={isHovered || !isOpen ? 10 : 0}
          transitionDuration="slow"
          alignItems="flex-start"
        >
          <Button
            p={0}
            minW={0}
            left={0}
            zIndex={1}
            display="flex"
            hierarchy="link"
            onClick={onToggle}
            _hover={{
              textDecoration: 'none'
            }}
          >
            <Flex
              w={6}
              h={6}
              transitionDuration="slow"
              bg={isOpen ? 'primary.500' : 'gray.200'}
              borderRadius="sm"
              p="1px"
              _before={{
                w: 2.5,
                bg: 'white',
                content: "''",
                position: 'relative',
                borderRadius: 'sm',
                left: isOpen ? 3 : 0
              }}
            />

            <Text
              pl={3}
              color="gray.700"
              textStyle="body1Regular"
              opacity={isOpen ? 1 : 0}
              display={isOpen ? 'block' : 'none'}
              visibility={isOpen ? 'visible' : 'hidden'}
            >
              Profile Overview
            </Text>
          </Button>
        </Flex>

        <Stack
          w="340px"
          spacing={4}
          position="relative"
          left={isOpen ? 0 : '-340px'}
          transitionDuration="slow"
        >
          <ViewJobCandidateDetailsProfileOverviewCalendar />

          <ViewJobCandidateDetailsProfileOverviewTasks
            jobCandidate={jobCandidate}
            isHiringPortalPage={isHiringPortalPage}
            prospect={prospect}
          />

          <ViewJobCandidateDetailsProfileOverviewSource
            jobCandidate={jobCandidate}
          />

          {sharedFields.includes(
            CandidateSubmissionShareSettingsFields.AssessmentQualifications as CandidateSubmissionShareSettingsField
          ) && (
            <ViewJobCandidateDetailsProfileOverviewQualifications
              jobCandidate={jobCandidate}
              job={job}
            />
          )}

          {sharedFields.includes(
            CandidateSubmissionShareSettingsFields.AssessmentPreferences as CandidateSubmissionShareSettingsField
          ) && <ViewJobCandidateDetailsProfileOverviewPreferences />}

          <ViewJobCandidateDetailsProfileOverviewAccess />

          <ViewJobCandidateDetailsProfileOverviewActivities />
        </Stack>
      </Stack>
    </Stack>
  );
}
