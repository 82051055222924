import {
  Avatar,
  Box,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  Show,
  Stack
} from '@chakra-ui/react';

import { HeaderOnSignOut } from '../Header/Header.types';

import { AnonymousUser } from '../../../../auth/hooks/useAuth/AnonymousUser';
import { AuthenticatedUser } from '../../../../auth/hooks/useAuth/AuthenticatedUser';

import { SettingsRoutes } from '../../../../main/settings/SettingsRoutes';

import { ChevronDownIcon } from '../../../../icons/ChevronDownIcon';

import { HeaderAvatar } from '../../../../helpers/HeaderAvatar';
import { NextLinkHelper } from '../../../../helpers/NextLinkHelper';
import { Text } from '../../../../helpers/Text';
import { useRecruiterContext } from '../../../../context';

interface HeaderAccountDropdownMenuProps {
  currentUser: AnonymousUser | AuthenticatedUser;
  onSignOut: HeaderOnSignOut;
  showSettings?: boolean;
  showProfile?: boolean;
  settingsRoute?: string;
}

const HeaderAccountDropdownMenu = ({
  currentUser,
  onSignOut,
  showSettings = true,
  showProfile = true,
  settingsRoute = SettingsRoutes.personalAbout()
}: HeaderAccountDropdownMenuProps) => {
  const { toggleViewRecruiter } = useRecruiterContext();
  return (
    <Box zIndex={99}>
      <Menu isLazy placement="bottom-end">
        <MenuButton>
          <Stack direction="row" alignItems="center">
            <Show above="xl">
              <Text w={32} maxW={32} noOfLines={1} textAlign="right">
                {currentUser.name || currentUser.email}
              </Text>
            </Show>

            {currentUser.image ? (
              <HeaderAvatar image={currentUser.image} />
            ) : (
              <Avatar w="40px" h="40px" />
            )}

            <ChevronDownIcon />
          </Stack>
        </MenuButton>

        <Portal>
          <MenuList>
            {showProfile && (
              <NextLinkHelper href={SettingsRoutes.personal()} passHref>
                <MenuItem
                  as="a"
                  _hover={{ textDecoration: 'none' }}
                  onClick={toggleViewRecruiter}
                >
                  My Profile
                </MenuItem>
              </NextLinkHelper>
            )}

            {showSettings && (
              <NextLinkHelper href={settingsRoute} passHref>
                <MenuItem as="a" _hover={{ textDecoration: 'none' }}>
                  Account
                </MenuItem>
              </NextLinkHelper>
            )}

            {showProfile || (showSettings && <MenuDivider />)}

            <MenuItem color="red.500" onClick={onSignOut}>
              Logout
            </MenuItem>
          </MenuList>
        </Portal>
      </Menu>
    </Box>
  );
};

export default HeaderAccountDropdownMenu;
