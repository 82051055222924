import map from 'lodash/map';
import uniq from 'lodash/uniq';

import {
  useReactHookForm,
  ReactHookFormFieldError
} from '../../../../../../../common/hooks/base/useReactHookForm';

import {
  ContactFields,
  MayBeContactFirstName,
  MayBeContactLastName,
  MayBeContactLinkedinUrl,
  MayBeContactImageId,
  MayBeContactCityId,
  MayBeContactZipCode,
  MayBeContactSource,
  MayBeContactOtherDetails,
  MayBeContactExperience,
  MayBeContactStateId,
  MayBeContactCountryId,
  ContactContactEmails,
  ContactContactPhoneNumbers,
  ContactEmployments,
  ContactEducations,
  ContactCertifications
} from '../../../../../contactsTypes';

import { useAddEditContactValidationRules } from '../useAddEditContactValidationRules';

import {
  OnAddEditContactForm,
  AddEditContactFormData,
  AddEditContactActiveContactLink
} from '../../AddEditContactForm.types';
import { DateUtils } from '../../../../../../../utils/DateUtils';

const defaultAddEditContactValues: AddEditContactFormData = {
  firstName: '' as MayBeContactFirstName,
  lastName: '' as MayBeContactLastName,
  linkedinUrl: '' as MayBeContactLinkedinUrl,
  zipCode: '' as MayBeContactZipCode,
  source: '' as MayBeContactSource,
  otherDetails: '' as MayBeContactOtherDetails,
  imageId: null,
  cityId: null,
  countryId: null,
  stateId: null,
  experience: null as MayBeContactExperience,
  contactEmails: [],
  contactPhoneNumbers: [],
  profileEducations: [],
  profileEmployments: [],
  profileCertifications: []
};

interface AddEditContactFormOptions {
  defaultValues?: Partial<AddEditContactFormData>;
  onAddEditContact: OnAddEditContactForm;
  activeContactLink?: AddEditContactActiveContactLink;
}

function useAddEditContactForm({
  defaultValues = {},
  onAddEditContact,
  activeContactLink
}: AddEditContactFormOptions) {
  const {
    control,
    watch,
    setValue,
    errors,
    isLoading,
    errorMessage,
    register,
    resetForm,
    handleSubmitReactHookForm
  } = useReactHookForm<AddEditContactFormData>({
    defaultValues: { ...defaultAddEditContactValues, ...defaultValues }
  });

  const {
    firstNameRules,
    lastNameRules,
    workEmailsRules,
    personalEmailsRules
  } = useAddEditContactValidationRules();

  const contactEmailRules = () => {
    if (activeContactLink === 'employees') return workEmailsRules;

    if (activeContactLink === 'candidates') return personalEmailsRules;

    return undefined;
  };

  return {
    control,
    watch,
    validationErrors: {
      firstNameValidationError: (errors.firstName as ReactHookFormFieldError)
        ?.message,
      lastNameValidationError: (errors.lastName as ReactHookFormFieldError)
        ?.message,
      linkedinUrlValidationError: (
        errors.linkedinUrl as ReactHookFormFieldError
      )?.message,
      imageIdValidationError: (errors.imageId as ReactHookFormFieldError)
        ?.message,
      cityIdValidationError: (errors.cityId as ReactHookFormFieldError)
        ?.message,
      zipCodeValidationError: (errors.zipCode as ReactHookFormFieldError)
        ?.message,
      sourceValidationError: (errors.source as ReactHookFormFieldError)
        ?.message,
      otherDetailsValidationError: (
        errors.otherDetails as ReactHookFormFieldError
      )?.message,
      stateIdValidationError: (errors.stateId as ReactHookFormFieldError)
        ?.message,
      countryIdValidationError: (errors.countryId as ReactHookFormFieldError)
        ?.message,
      industryExperienceValidationError: (
        errors.experience as ReactHookFormFieldError
      )?.message,
      contactEmailsValidationErrors: errors.contactEmails as {
        [key: string]: ReactHookFormFieldError;
      }[],
      contactPhoneNumbersValidationErrors: errors.contactPhoneNumbers as {
        [key: string]: ReactHookFormFieldError;
      }[],
      profileEmploymentsValidationErrors: errors.profileEmployments as {
        [key: string]: ReactHookFormFieldError;
      }[],
      profileEducationsValidationErrors: errors.profileEducations as {
        [key: string]: ReactHookFormFieldError;
      }[],
      profileCertificationsValidationErrors: errors.profileCertifications as {
        [key: string]: ReactHookFormFieldError;
      }[]
    },
    setAddEditContactFormValue: setValue,
    addEditContactFormIsLoading: isLoading,
    addEditContactFormErrorMessage: errorMessage,
    resetAddEditContactForm: resetForm,
    registerFields: {
      registerFirstName: register(ContactFields.FIRST_NAME, firstNameRules),
      registerLastName: register(ContactFields.LAST_NAME, lastNameRules),
      registerLinkedinUrl: register(ContactFields.LINKEDIN_URL),
      registerImageId: register(ContactFields.IMAGE_ID),
      registerSource: register(ContactFields.SOURCE),
      registerZipCode: register(ContactFields.ZIP_CODE),
      registerCityId: register(ContactFields.CITY_ID),
      registerStateId: register(ContactFields.STATE_ID),
      registerCountryId: register(ContactFields.COUNTRY_ID),
      registerOtherDetails: register(ContactFields.OTHER_DETAILS),
      registerIndustryExperience: register(ContactFields.EXPERIENCE),
      registerContactEmails: register(
        ContactFields.CONTACT_EMAILS,
        contactEmailRules()
      ),
      registeContactPhoneNumbers: register(ContactFields.CONTACT_PHONE_NUMBERS),
      registerEmployments: register(ContactFields.EMPLOYMENTS),
      registerEducations: register(ContactFields.EDUCATIONS),
      registerCertifications: register(ContactFields.CERTIFICATIONS)
    },
    handleAddEditContact: handleSubmitReactHookForm({
      onSubmit: (data) =>
        onAddEditContact?.({
          firstName: data.firstName as MayBeContactFirstName,
          lastName: data.lastName as MayBeContactLastName,
          linkedinUrl: data.linkedinUrl as MayBeContactLinkedinUrl,
          source: data.source as MayBeContactSource,
          otherDetails: data.otherDetails as MayBeContactOtherDetails,
          imageId: data.imageId as MayBeContactImageId,
          zipCode: data.zipCode as MayBeContactZipCode,
          cityId: data.cityId as MayBeContactCityId,
          stateId: data.stateId as MayBeContactStateId,
          countryId: data.countryId as MayBeContactCountryId,
          experience: data.experience as MayBeContactExperience,
          contactEmails: data.contactEmails as ContactContactEmails,
          contactPhoneNumbers:
            data.contactPhoneNumbers as ContactContactPhoneNumbers,
          profileEmployments: map(data.profileEmployments, (employment) => ({
            ...employment,
            jobFunctionIds: uniq(employment.jobFunctionIds),
            startDate: DateUtils.formatDate(employment.startDate),
            endDate: employment.currently
              ? null
              : DateUtils.formatDate(employment.endDate)
          })) as ContactEmployments,
          profileEducations: map(data.profileEducations, (education) => ({
            ...education,
            startDate: DateUtils.formatDate(education.startDate),
            endDate: DateUtils.formatDate(education.endDate)
          })) as ContactEducations,
          profileCertifications: map(
            data.profileCertifications,
            (certification) => ({
              ...certification,
              issueDate: DateUtils.formatDate(certification.issueDate)
            })
          ) as ContactCertifications
        })
    })
  };
}

export default useAddEditContactForm;
