import {
  SkillBadgeRequirements,
  SkillBadgeRequirementsTypes
} from '../../skillBadgesTypes';

function renderSkillBadgeRequirements(
  skillBadgeRequirements: SkillBadgeRequirements
) {
  switch (skillBadgeRequirements) {
    case SkillBadgeRequirementsTypes.NONE:
      return 'None';
    case SkillBadgeRequirementsTypes.REQUIRED:
      return 'Very Important';
    case SkillBadgeRequirementsTypes.IMPORTANT:
      return 'Important';
    case SkillBadgeRequirementsTypes.NICE_TO_HAVE:
      return 'Nice to have';
    case SkillBadgeRequirementsTypes.NOT_REQUIRED:
      return 'Not required';
    default:
      return 'Unknown';
  }
}

export default renderSkillBadgeRequirements;
