import React from 'react';
import { Stack, StackDivider, ButtonGroup } from '@chakra-ui/react';
import every from 'lodash/every';

import { Button } from '../../../../../../helpers/Button';

import {
  ShareProfileFieldsToShare,
  ShareProfileFieldsToShareFields,
  ShareProfileFieldsToShareHandleCheckField
} from '../ShareProfileFieldsToShare';

type ShareCandidateProfileScreenOneProps = {
  cancel: () => void;
  goToNext: () => void;
  fieldsToShare: ShareProfileFieldsToShareFields;
  handleCheckFieldToShare: ShareProfileFieldsToShareHandleCheckField;
};

const ShareCandidateProfileScreenOne = ({
  cancel,
  goToNext,
  fieldsToShare,
  handleCheckFieldToShare
}: ShareCandidateProfileScreenOneProps) => {
  const noFieldChecked = every(fieldsToShare, (field) => !field.checked);

  return (
    <Stack spacing={6} divider={<StackDivider />}>
      <ShareProfileFieldsToShare
        fieldsToShare={fieldsToShare}
        handleCheckFieldToShare={handleCheckFieldToShare}
      />

      <ButtonGroup w="full" spacing={6}>
        <Button w="full" hierarchy="secondary" onClick={cancel}>
          Cancel
        </Button>

        <Button w="full" onClick={goToNext} isDisabled={noFieldChecked}>
          Continue
        </Button>
      </ButtonGroup>
    </Stack>
  );
};
export default ShareCandidateProfileScreenOne;
