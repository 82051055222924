import {
  FetchEmploymentSkillBadgesCacheKey,
  FetchEmploymentSkillBadgeCacheKey,
  UpdateEmploymentSkillBadgeErrorMessage,
  UpdateEmploymentSkillBadgeIsLoading,
  UpdateEmploymentSkillBadgeID
} from '../../employmentSkillBadgesTypes';

import {
  EmploymentSkillBadgesBffRequests,
  UpdateEmploymentSkillBadgeRequestData,
  UpdateEmploymentSkillBadgeErrorResponse
} from '../../EmploymentSkillBadgesBffRequests';

import { useUpdateItem } from '../../../common/hooks/useUpdateItem';

interface UpdateEmploymentSkillBadgeOptions {
  employmentSkillBadgeId: UpdateEmploymentSkillBadgeID;
  cacheKeys?: (
    | FetchEmploymentSkillBadgesCacheKey
    | FetchEmploymentSkillBadgeCacheKey
  )[];
}

function useUpdateEmploymentSkillBadge({
  employmentSkillBadgeId,
  cacheKeys = []
}: UpdateEmploymentSkillBadgeOptions) {
  const {
    updateItemData,
    updateItemError,
    updateItemErrorMessage,
    updateItemIsLoading,
    updateItem,
    updateItemReset
  } = useUpdateItem<
    UpdateEmploymentSkillBadgeRequestData,
    UpdateEmploymentSkillBadgeErrorResponse
  >({
    mutationFn: (queryInput) =>
      EmploymentSkillBadgesBffRequests.updateEmploymentSkillBadge(
        employmentSkillBadgeId,
        queryInput
      ),
    // EmploymentSkillBadgesRequests.updateEmploymentSkillBadge(employmentSkillBadgeId, queryInput),
    cacheKeys
  });

  return {
    updateEmploymentSkillBadgeData: updateItemData,
    updateEmploymentSkillBadgeError: updateItemError,
    updateEmploymentSkillBadgeErrorMessage:
      updateItemErrorMessage as UpdateEmploymentSkillBadgeErrorMessage,
    updateEmploymentSkillBadgeIsLoading:
      updateItemIsLoading as UpdateEmploymentSkillBadgeIsLoading,
    updateEmploymentSkillBadge: updateItem,
    updateEmploymentSkillBadgeReset: updateItemReset
  };
}

export default useUpdateEmploymentSkillBadge;
