import {
  EducationItemBffID,
  EducationItemBffNanoID,
  FetchEducationsBffURL,
  FetchEducationBffURL,
  FetchEducationByNanoIdBffURL,
  CreateEducationBffURL,
  UpdateEducationBffURL,
  DeleteEducationBffURL
} from './educationsTypes';

import { BaseBffRoutes } from '../BaseBffRoutes';

export class EducationsBffRoutes extends BaseBffRoutes {
  protected static route = 'educations';

  static fetchEducationsRoute() {
    return this.fetchItemsRoute<FetchEducationsBffURL>();
  }

  static fetchEducationsIndexRoute() {
    return this.fetchItemsIndexRoute<FetchEducationsBffURL>();
  }

  static fetchEducationRoute(educationItemBffID: EducationItemBffID) {
    return this.fetchItemRoute<FetchEducationBffURL>(educationItemBffID);
  }

  static fetchEducationIndexRoute(
    educationItemBffNanoID: EducationItemBffNanoID
  ) {
    return this.fetchItemIndexRoute<FetchEducationBffURL>(
      educationItemBffNanoID
    );
  }

  static fetchEducationByNanoIdRoute(
    educationItemBffNanoID: EducationItemBffNanoID
  ) {
    return this.fetchItemByNanoIdRoute<FetchEducationByNanoIdBffURL>(
      educationItemBffNanoID
    );
  }

  static createEducationRoute() {
    return this.createItemRoute<CreateEducationBffURL>();
  }

  static updateEducationRoute(
    educationItemBffNanoID: EducationItemBffID | EducationItemBffNanoID
  ) {
    return this.updateItemRoute<UpdateEducationBffURL>(educationItemBffNanoID);
  }

  static deleteEducationRoute(educationItemBffNanoID: EducationItemBffNanoID) {
    return this.deleteItemRoute<DeleteEducationBffURL>(educationItemBffNanoID);
  }
}
