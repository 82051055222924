import { Icon, IconProps } from '@chakra-ui/icon';

const IncomingIcon = ({
  w = '16px',
  h = '16px',
  fill,
  ...props
}: IconProps) => (
  <Icon
    viewBox="0 0 16 16"
    w={w}
    h={h}
    fill={fill || 'currentColor'}
    {...props}
  >
    <path
      fill="#287CC9"
      fillRule="evenodd"
      d="M7.333 11.333V8.667h5.334V7.333H7.333V4.667L3.333 8l4 3.333zM8 0c4.411 0 8 3.589 8 8s-3.589 8-8 8-8-3.589-8-8 3.589-8 8-8z"
      clipRule="evenodd"
    />
  </Icon>
);
export default IncomingIcon;
