import {
  JobTitleItemBffID,
  JobTitleItemBffNanoID,
  FetchJobTitlesBffURL,
  FetchJobTitleBffURL,
  FetchJobTitleByNanoIdBffURL,
  CreateJobTitleBffURL,
  UpdateJobTitleBffURL,
  DeleteJobTitleBffURL
} from './jobTitlesTypes';

import { BaseBffRoutes } from '../BaseBffRoutes';

export class JobTitlesBffRoutes extends BaseBffRoutes {
  protected static route = 'jobTitles';

  static fetchJobTitlesRoute() {
    return this.fetchItemsRoute<FetchJobTitlesBffURL>();
  }

  static fetchJobTitlesIndexRoute() {
    return this.fetchItemsIndexRoute<FetchJobTitlesBffURL>();
  }

  static fetchJobTitleRoute(jobTitleItemBffID: JobTitleItemBffID) {
    return this.fetchItemRoute<FetchJobTitleBffURL>(jobTitleItemBffID);
  }

  static fetchJobTitleIndexRoute(jobTitleItemBffNanoID: JobTitleItemBffNanoID) {
    return this.fetchItemIndexRoute<FetchJobTitleBffURL>(jobTitleItemBffNanoID);
  }

  static fetchJobTitleByNanoIdRoute(
    jobTitleItemBffNanoID: JobTitleItemBffNanoID
  ) {
    return this.fetchItemByNanoIdRoute<FetchJobTitleByNanoIdBffURL>(
      jobTitleItemBffNanoID
    );
  }

  static createJobTitleRoute() {
    return this.createItemRoute<CreateJobTitleBffURL>();
  }

  static updateJobTitleRoute(
    jobTitleItemBffID: JobTitleItemBffID | JobTitleItemBffNanoID
  ) {
    return this.updateItemRoute<UpdateJobTitleBffURL>(jobTitleItemBffID);
  }

  static deleteJobTitleRoute(
    jobTitleItemBffID: JobTitleItemBffID | JobTitleItemBffNanoID
  ) {
    return this.deleteItemRoute<DeleteJobTitleBffURL>(jobTitleItemBffID);
  }
}
