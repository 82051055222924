import { useEffect } from 'react';
import { FieldValues } from 'react-hook-form';
import debounce from 'lodash/debounce';

import { ErrorMessage, IsDisabled, IsRequired } from '../../../../../types';

import { JobFields, JobHiringManagerId } from '../../../jobsTypes';

import {
  UserFields,
  UserActionTypes,
  FetchUsersFilters,
  FetchUsersSort
} from '../../../../users/usersTypes';

import {
  fetchUsersQuery,
  FetchUsersResponse
} from '../../../../users/queries/fetchUsers.query';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';
import { usePaginatedUsers } from '../../../../users/hooks/usePaginatedUsers';

import { UsersCache } from '../../../../users/UsersCache';

import {
  SelectField,
  SelectFieldReactHookFormControl,
  SelectFieldReactHookFormFieldPath
} from '../../../../../helpers/forms/formFields/SelectField';

import { ImagesUrl } from '../../../../images/ImagesUrl';

import { JobSelectHiringManagerFieldRequiredProps } from './JobSelectHiringManagerField.types';

interface JobSelectHiringManagerFieldProps<T extends FieldValues> {
  isRequired?: IsRequired;
  isDisabled?: IsDisabled;
  control: SelectFieldReactHookFormControl<T>;
  currentJobHiringManagerId?: JobHiringManagerId | null;
  errorMessage: ErrorMessage;
  showAddForm?: () => void;
  emailRequired?: boolean;
}

function JobSelectHiringManagerField<T extends FieldValues>({
  isRequired,
  isDisabled,
  control,
  job,
  currentJobHiringManagerId,
  errorMessage,
  showAddForm
}: JobSelectHiringManagerFieldRequiredProps &
  JobSelectHiringManagerFieldProps<T>) {
  const currentUser = useCurrentUser();

  const { users, usersIsLoading, usersErrorMessage, changeUsersFilters } =
    usePaginatedUsers<FetchUsersResponse>({
      query: fetchUsersQuery,
      cacheKey: UsersCache.postJobCacheKey(),
      initialFilters: {
        [UserFields.ACTION_TYPE]: {
          operator: 'eq',
          value: UserActionTypes.HIRING_MANAGER
        },
        [UserFields.COMPANY_ID]: {
          operator: 'eq',
          value: currentUser.companyId
        }
      } as unknown as FetchUsersFilters,
      initialSort: {
        [UserFields.NAME]: { ascending: true }
      } as unknown as FetchUsersSort
    });

  useEffect(() => {
    if (!currentJobHiringManagerId && job?.hiringManager?.name) {
      changeUsersFilters({
        [UserFields.NAME]: {
          operator: 'ilike',
          value: `%${job?.hiringManager?.name}%`
        }
      });
    }
  }, [currentJobHiringManagerId, job?.hiringManager?.name, changeUsersFilters]);

  const defaultJobHiringManager = job?.hiringManager
    ? {
        value: job.hiringManager.id,
        label: job.hiringManager.name,
        image: ImagesUrl.file(job.hiringManager.image),
        withCheckIcon: true
      }
    : undefined;

  const debouncedFilterUsers = debounce<(updatedValue: string) => void>(
    (updatedValue) =>
      changeUsersFilters({
        [UserFields.NAME]: {
          operator: 'ilike',
          value: `%${updatedValue}%`
        }
      }),
    500
  );

  return (
    <SelectField
      withImages
      withAddNewButton
      addNewButtonLabel="Add Hiring Manager"
      addNewButtonAction={showAddForm}
      isRequired={isRequired}
      isDisabled={isDisabled}
      control={control}
      label="Hiring Manager"
      placeholder="Select"
      name={JobFields.HIRING_MANAGER_ID as SelectFieldReactHookFormFieldPath<T>}
      options={users.map((user) => ({
        value: user.id,
        label: user.name || user.email,
        image: ImagesUrl.file(user.image),
        withCheckIcon: true
      }))}
      defaultValue={defaultJobHiringManager}
      errorMessage={errorMessage || usersErrorMessage}
      isLoading={usersIsLoading}
      onInputChange={debouncedFilterUsers}
    />
  );
}

export default JobSelectHiringManagerField;
