import { IndustryExpertiseExperience } from '../../industryExpertisesTypes';

import { removeUnderscore } from '../../../../utils/removeUnderscore';

function renderIndustryExpertiseExperience(
  experience: IndustryExpertiseExperience
) {
  if (!experience || experience === 'none') return null;

  return removeUnderscore(experience);
}

export default renderIndustryExpertiseExperience;
