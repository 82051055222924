import {
  StateItemBffID,
  StateItemBffNanoID,
  FetchStatesBffURL,
  FetchStateBffURL,
  FetchStateByNanoIdBffURL,
  CreateStateBffURL,
  UpdateStateBffURL,
  DeleteStateBffURL
} from './statesTypes';

import { BaseBffRoutes } from '../BaseBffRoutes';

export class StatesBffRoutes extends BaseBffRoutes {
  protected static route = 'states';

  static fetchStatesRoute() {
    return this.fetchItemsRoute<FetchStatesBffURL>();
  }

  static fetchStatesIndexRoute() {
    return this.fetchItemsIndexRoute<FetchStatesBffURL>();
  }

  static fetchStateRoute(stateItemBffID: StateItemBffID) {
    return this.fetchItemRoute<FetchStateBffURL>(stateItemBffID);
  }

  static fetchStateIndexRoute(stateItemBffNanoID: StateItemBffNanoID) {
    return this.fetchItemIndexRoute<FetchStateBffURL>(stateItemBffNanoID);
  }

  static fetchStateByNanoIdRoute(stateItemBffNanoID: StateItemBffNanoID) {
    return this.fetchItemByNanoIdRoute<FetchStateByNanoIdBffURL>(
      stateItemBffNanoID
    );
  }

  static createStateRoute() {
    return this.createItemRoute<CreateStateBffURL>();
  }

  static updateStateRoute(
    stateItemBffNanoID: StateItemBffID | StateItemBffNanoID
  ) {
    return this.updateItemRoute<UpdateStateBffURL>(stateItemBffNanoID);
  }

  static deleteStateRoute(stateItemBffNanoID: StateItemBffNanoID) {
    return this.deleteItemRoute<DeleteStateBffURL>(stateItemBffNanoID);
  }
}
