import { useDisclosure } from '@chakra-ui/hooks';
import { Box, Flex, Stack } from '@chakra-ui/layout';
import {
  Popover,
  PopoverAnchor,
  PopoverBody,
  PopoverContent
} from '@chakra-ui/popover';
import { Portal } from '@chakra-ui/react';
import { Fragment, useCallback } from 'react';

import {
  IndustryExpertiseExperience,
  IndustryExpertiseIndustry,
  IndustryExpertiseRequirements
} from '../../industryExpertisesTypes';

import { JobsIcon } from '../../../../icons/JobsIcon';
import { StarIcon } from '../../../../icons/StarIcon';

import { EditDeleteButtonGroup } from '../../../../helpers/EditDeleteButtonGroup';
import { Heading } from '../../../../helpers/Heading';
import { Text } from '../../../../helpers/Text';

import { renderIndustryExpertiseExperience } from '../../utils/renderIndustryExpertiseExperience';
import { renderIndustryExpertiseRequirementsBackground } from '../../utils/renderIndustryExpertiseRequirementsBackground';
import { renderIndustryExpertiseRequirements } from '../../utils/renderIndustryExpertiseRequirements';

import { IndustryExpertiseRequirementItem } from '../IndustryExpertiseRequirementItem';

interface IndustryExpertiseProps {
  withoutEditButton?: boolean;
  withoutDeleteButton?: boolean;
  withoutPopover?: boolean;
  name: IndustryExpertiseIndustry['name'] | null;
  experience: IndustryExpertiseExperience;
  requirements: IndustryExpertiseRequirements;
  onEdit: () => void;
  onDelete: () => void;
  isEditing?: boolean;
  allowEditing?: boolean;
  withoutPortal?: boolean;
}

function IndustryExpertise({
  withoutEditButton,
  withoutDeleteButton,
  withoutPopover,
  name,
  experience,
  requirements,
  onEdit,
  onDelete,
  isEditing,
  allowEditing = true,
  withoutPortal
}: IndustryExpertiseProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleEdit = useCallback(() => {
    return allowEditing ? onEdit() : null;
  }, [onEdit, allowEditing]);

  const experienceLabel = renderIndustryExpertiseExperience(experience);

  return (
    <Fragment>
      <Popover
        returnFocusOnClose={false}
        onClose={onClose}
        isOpen={isOpen}
        placement="top-start"
        offset={[0, 8]}
        isLazy
      >
        <PopoverAnchor>
          <Stack spacing={0.5} direction="row">
            <Flex
              as="button"
              bg={
                isEditing
                  ? 'primary.500'
                  : renderIndustryExpertiseRequirementsBackground(requirements)
                      ?.main
              }
              borderRadius={4}
              alignItems="center"
              overflow="hidden"
              type="button"
              color="white"
              onClick={handleEdit}
              onMouseEnter={onOpen}
              onMouseLeave={onClose}
            >
              <Flex px={2} alignItems="center">
                <Text textStyle="body1Medium" textTransform="capitalize">
                  {name}
                </Text>
              </Flex>
              {experienceLabel ? (
                <Flex
                  bg={
                    isEditing
                      ? 'primary.500'
                      : renderIndustryExpertiseRequirementsBackground(
                          requirements
                        )?.experience
                  }
                  borderLeft="1px solid"
                  borderLeftColor={
                    isEditing
                      ? 'white'
                      : renderIndustryExpertiseRequirementsBackground(
                          requirements
                        )?.experience
                  }
                  px={2}
                >
                  <Text textStyle="body1Medium">{experienceLabel}</Text>
                </Flex>
              ) : null}
            </Flex>

            {allowEditing && withoutEditButton && !withoutDeleteButton ? (
              <EditDeleteButtonGroup
                withoutEditButton={withoutEditButton}
                isDisabled={isEditing}
                onDelete={onDelete}
              />
            ) : null}
            {allowEditing && !withoutEditButton && !withoutDeleteButton ? (
              <EditDeleteButtonGroup
                isDisabled={isEditing}
                onDelete={onDelete}
                onEdit={onEdit}
              />
            ) : null}
            {allowEditing && withoutDeleteButton && !withoutEditButton ? (
              <EditDeleteButtonGroup
                isDisabled={isEditing}
                onEdit={onEdit}
                withoutDeleteButton={withoutDeleteButton}
              />
            ) : null}
          </Stack>
        </PopoverAnchor>
        {withoutPopover ? null : (
          <Box as={withoutPortal ? undefined : Portal}>
            <PopoverContent
              w="auto"
              color="white"
              bg="gray.900"
              borderColor="gray.200"
              boxShadow="0px 2px 2px rgba(23, 25, 26, 0.06)"
            >
              <PopoverBody p={4} minW="160px">
                <Flex flexDir="column" gap={1}>
                  <Heading level="h4" textTransform="capitalize">
                    {name}
                  </Heading>

                  <Flex flexDir="column" gap={2}>
                    {experience ? (
                      <IndustryExpertiseRequirementItem
                        label={renderIndustryExpertiseExperience(experience)}
                        icon={<JobsIcon fill="white" w={5} h={5} />}
                      />
                    ) : null}

                    {requirements ? (
                      <IndustryExpertiseRequirementItem
                        label={renderIndustryExpertiseRequirements(
                          requirements
                        )}
                        icon={<StarIcon stroke="" fill="white" w={5} h={5} />}
                      />
                    ) : null}
                  </Flex>
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </Box>
        )}
      </Popover>
    </Fragment>
  );
}

export default IndustryExpertise;
