import { Icon, IconProps } from '@chakra-ui/icon';

const UpdatesButtonIcon = ({ w = '40px', h = '40px', ...props }: IconProps) => (
  <Icon viewBox="0 0 40 40" w={w} h={h} {...props}>
    <circle cx={20} cy={20} r={20} fill="#0BAD90" />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M20 13.25c1.627 0 3.169.488 4.443 1.375l-2.95 2.544 7.501.738L28.642 11l-2.99 2.582c-1.596-1.186-3.57-1.832-5.652-1.832-4.962 0-9 3.701-9 8.25h1.636c0-3.722 3.303-6.75 7.364-6.75ZM20 26.75a7.749 7.749 0 0 1-4.443-1.375l2.95-2.544-7.501-.738.352 6.907 2.99-2.581c1.596 1.184 3.57 1.831 5.652 1.831 4.962 0 9-3.701 9-8.25h-1.636c0 3.722-3.303 6.75-7.364 6.75Z"
      clipRule="evenodd"
    />
  </Icon>
);

export default UpdatesButtonIcon;
