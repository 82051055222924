import { tableAnatomy as parts } from '@chakra-ui/anatomy';
import type {
  PartsStyleFunction,
  PartsStyleObject,
  SystemStyleObject
} from '@chakra-ui/theme-tools';

const numericStyles: SystemStyleObject = {
  '&[data-is-numeric=true]': {
    textAlign: 'end'
  }
};

const variantNxmoov: PartsStyleFunction<typeof parts> = () => {
  return {
    table: {
      borderCollapse: 'separate',
      borderSpacing: '0 2px'
    },
    th: {
      border: '1px solid',
      borderColor: 'gray.100',
      py: 2,
      px: 4,
      m: 0,
      color: 'gray.900',
      lineHeight: '24px',
      fontWeight: '500',

      _notFirst: {
        borderLeft: 0,
        pl: 0
      },
      _notLast: {
        borderRight: 0
      },
      ...numericStyles
    },
    tbody: {
      _before: {
        height: '12px',
        content: '""',
        display: 'block',
        bg: 'transparent',
        w: '100%'
      }
    },
    td: {
      bg: 'white',
      border: '1px',
      borderColor: 'gray.100',
      color: 'gray.900',
      p: 4,
      _notFirst: {
        borderLeft: 0,
        pl: 0
      },
      _notLast: {
        borderRight: 0
      },
      ...numericStyles
    },
    caption: {
      color: 'gray.600'
    },
    tfoot: {
      tr: {
        '&:last-of-type': {
          th: { borderBottomWidth: 0 }
        }
      }
    }
  };
};

const variants = {
  nxmoov: variantNxmoov,
  unstyled: {}
};

const sizes: Record<string, PartsStyleObject<typeof parts>> = {
  sm: {
    th: {
      px: '4',
      py: '1',
      lineHeight: '4',
      fontSize: 'xs'
    },
    td: {
      px: '4',
      py: '2',
      fontSize: 'sm',
      lineHeight: '4'
    },
    caption: {
      px: '4',
      py: '2',
      fontSize: 'xs'
    }
  },
  md: {
    th: {
      px: '6',
      py: '3',
      lineHeight: '4',
      fontSize: 'xs'
    },
    td: {
      px: '6',
      py: '4',
      lineHeight: '5'
    },
    caption: {
      px: '6',
      py: '2',
      fontSize: 'sm'
    }
  },
  lg: {
    th: {
      px: '8',
      py: '4',
      lineHeight: '5',
      fontSize: 'sm'
    },
    td: {
      px: '8',
      py: '5',
      lineHeight: '6'
    },
    caption: {
      px: '6',
      py: '2',
      fontSize: 'md'
    }
  }
};

const defaultProps = {
  variant: 'simple',
  size: 'md',
  colorScheme: 'gray'
};

export const Table = {
  parts: parts.keys,
  variants,
  sizes,
  defaultProps
};
