import { usePaginatedItems } from '../../../common/hooks/usePaginatedItems';

import {
  FetchSkillsQuery,
  FetchSkillsCacheKey,
  FetchSkillsTotalCount,
  FetchSkillsPage,
  FetchSkillsPageSize,
  FetchSkillsError,
  FetchSkillsErrorMessage,
  FetchSkillsIsLoading,
  FetchSkillsIsFetched,
  FetchSkillsIsPlaceholderData,
  FetchSkillsEnabled,
  FetchSkillsPrefetchNextPage,
  FetchSkillsCountType,
  FetchSkillsFilters,
  FetchSkillsSort,
  FetchSkillID,
  FetchSkillQuery,
  FetchSkillCacheKey
} from '../../skillsTypes';

import { SkillsBffRequests } from '../../SkillsBffRequests';

interface PaginatedSkillsBaseOptions<PaginatedSkillsResponseType> {
  cacheKey: FetchSkillsCacheKey;
  query: FetchSkillsQuery;
  countType?: FetchSkillsCountType;
  initialPage?: FetchSkillsPage;
  initialPageSize?: FetchSkillsPageSize;
  initialFilters?: FetchSkillsFilters;
  initialSort?: FetchSkillsSort;
  enabled?: FetchSkillsEnabled;
  prefetchNextPage?: FetchSkillsPrefetchNextPage;
  placeholderSkills?: PaginatedSkillsResponseType[];
  placeholderSkillsCount?: FetchSkillsTotalCount;
  placeholderData?: () => PaginatedSkillsResponse<PaginatedSkillsResponseType>;
}

interface PaginatedSkillsOptionsWithoutItem {
  fetchItemCacheKey?: never;
  itemQuery?: never;
}

interface PaginatedSkillsOptionsWithItem {
  fetchItemCacheKey: FetchSkillCacheKey;
  itemQuery: FetchSkillQuery;
}

type PaginatedSkillsOptions<PaginatedSkillsResponseType> =
  PaginatedSkillsBaseOptions<PaginatedSkillsResponseType> &
    (PaginatedSkillsOptionsWithoutItem | PaginatedSkillsOptionsWithItem);

interface PaginatedSkillsResponse<PaginatedSkillsResponseType> {
  data: PaginatedSkillsResponseType[];
  totalCount: FetchSkillsTotalCount;
}

function usePaginatedSkills<PaginatedSkillsResponseType>({
  cacheKey,
  query,
  countType,
  initialPage,
  initialPageSize,
  initialFilters,
  initialSort,
  enabled,
  prefetchNextPage,
  placeholderSkills,
  placeholderSkillsCount,
  placeholderData,
  fetchItemCacheKey,
  itemQuery
}: PaginatedSkillsOptions<PaginatedSkillsResponseType>) {
  const {
    items,
    itemsTotalCount,
    itemsError,
    itemsErrorMessage,
    itemsIsFetched,
    itemsIsLoading,
    itemsIsPlaceholderData,
    itemsCurrentPage,
    itemsCurrentPageSize,
    itemsCurrentFilters,
    itemsCurrentSort,
    fetchItems,
    paginateItems,
    changeItemsPageSize,
    filterItems,
    changeItemsFilters,
    clearItemsFilters,
    sortItems,
    prefetchItems,
    prefetchItem
  } = usePaginatedItems<PaginatedSkillsResponseType>({
    cacheKey,
    initialPage,
    initialPageSize,
    initialFilters,
    initialSort,
    enabled,
    prefetchNextPage,
    placeholderItems: placeholderSkills,
    placeholderItemsCount: placeholderSkillsCount,
    placeholderData,
    queryFn: (params) =>
      SkillsBffRequests.fetchSkills<PaginatedSkillsResponseType>({
        query,
        countType,
        page: params.page as FetchSkillsPage,
        pageSize: params.pageSize as FetchSkillsPageSize,
        filters: params.filters as FetchSkillsFilters,
        sort: params.sort as FetchSkillsSort
      }),
    ...(itemQuery
      ? {
          fetchItemCacheKey,
          queryItemFn: (itemId) =>
            SkillsBffRequests.fetchSkill(itemId as FetchSkillID, {
              query: itemQuery
            })
        }
      : {})
  });

  return {
    skills: items as PaginatedSkillsResponseType[],
    skillsTotalCount: itemsTotalCount as FetchSkillsTotalCount,
    skillsError: itemsError as FetchSkillsError,
    skillsErrorMessage: itemsErrorMessage as FetchSkillsErrorMessage,
    skillsIsLoading: itemsIsLoading as FetchSkillsIsLoading,
    skillsIsFetched: itemsIsFetched as FetchSkillsIsFetched,
    skillsIsPlaceholderData:
      itemsIsPlaceholderData as FetchSkillsIsPlaceholderData,
    skillsCurrentPage: itemsCurrentPage as FetchSkillsPage,
    skillsCurrentPageSize: itemsCurrentPageSize as FetchSkillsPageSize,
    skillsCurrentFilters: itemsCurrentFilters as FetchSkillsFilters,
    skillsCurrentSort: itemsCurrentSort as FetchSkillsSort,
    fetchSkills: fetchItems,
    paginateSkills: paginateItems,
    changeSkillsPageSize: changeItemsPageSize,
    filterSkills: filterItems,
    changeSkillsFilters: changeItemsFilters,
    clearSkillsFilters: clearItemsFilters,
    sortSkills: sortItems,
    prefetchSkills: prefetchItems,
    prefetchSkill: prefetchItem
  };
}

export default usePaginatedSkills;
