import {
  FetchCandidateSubmissionJobCandidatesQuery,
  FetchCandidateSubmissionJobCandidatesCountType,
  FetchCandidateSubmissionJobCandidatesPage,
  FetchCandidateSubmissionJobCandidatesPageSize,
  FetchCandidateSubmissionJobCandidatesFilters,
  FetchCandidateSubmissionJobCandidatesSort,
  FetchCandidateSubmissionJobCandidatesIndexQueryName,
  FetchCandidateSubmissionJobCandidatesIndexCountType,
  FetchCandidateSubmissionJobCandidatesIndexPage,
  FetchCandidateSubmissionJobCandidatesIndexPageSize,
  FetchCandidateSubmissionJobCandidatesIndexFilters,
  FetchCandidateSubmissionJobCandidatesIndexSort,
  FetchCandidateSubmissionJobCandidateQuery,
  FetchCandidateSubmissionJobCandidateIndexQueryName,
  CreateCandidateSubmissionJobCandidateError,
  DeleteCandidateSubmissionJobCandidateError,
  CandidateSubmissionJobCandidateId,
  CandidateSubmissionJobCandidateNanoId,
  CandidateSubmissionJobCandidateCandidateSubmissionId,
  CandidateSubmissionJobCandidateJobCandidateId
} from './candidateSubmissionJobCandidatesTypes';

import { BaseBffRequests } from '../BaseBffRequests';

import { CandidateSubmissionJobCandidatesBffRoutes } from './CandidateSubmissionJobCandidatesBffRoutes';

interface FetchCandidateSubmissionJobCandidatesRequestParams {
  query: FetchCandidateSubmissionJobCandidatesQuery;
  countType?: FetchCandidateSubmissionJobCandidatesCountType;
  page?: FetchCandidateSubmissionJobCandidatesPage;
  pageSize?: FetchCandidateSubmissionJobCandidatesPageSize;
  filters?: FetchCandidateSubmissionJobCandidatesFilters;
  sort?: FetchCandidateSubmissionJobCandidatesSort;
}

interface FetchCandidateSubmissionJobCandidatesIndexRequestParams {
  queryName: FetchCandidateSubmissionJobCandidatesIndexQueryName;
  countType?: FetchCandidateSubmissionJobCandidatesIndexCountType;
  page?: FetchCandidateSubmissionJobCandidatesIndexPage;
  pageSize?: FetchCandidateSubmissionJobCandidatesIndexPageSize;
  filters?: FetchCandidateSubmissionJobCandidatesIndexFilters;
  sort?: FetchCandidateSubmissionJobCandidatesIndexSort;
}

interface FetchCandidateSubmissionJobCandidateRequestParams {
  query: FetchCandidateSubmissionJobCandidateQuery;
}

interface FetchCandidateSubmissionJobCandidateIndexRequestParams {
  queryName: FetchCandidateSubmissionJobCandidateIndexQueryName;
}

export type CreateCandidateSubmissionJobCandidateRequestData = {
  id?: CandidateSubmissionJobCandidateId;
  candidateSubmissionId: CandidateSubmissionJobCandidateCandidateSubmissionId;
  recipientId: CandidateSubmissionJobCandidateJobCandidateId;
};

export interface CreateCandidateSubmissionJobCandidateErrorResponse {
  message: CreateCandidateSubmissionJobCandidateError;
}

export interface DeleteCandidateSubmissionJobCandidateErrorResponse {
  message: DeleteCandidateSubmissionJobCandidateError;
}

export class CandidateSubmissionJobCandidatesBffRequests extends BaseBffRequests {
  static fetchCandidateSubmissionJobCandidates<T>(
    params: FetchCandidateSubmissionJobCandidatesRequestParams
  ) {
    return this.fetchItems<T>(
      CandidateSubmissionJobCandidatesBffRoutes.fetchCandidateSubmissionJobCandidatesRoute(),
      params
    );
  }

  static fetchCandidateSubmissionJobCandidatesIndex<T>(
    params: FetchCandidateSubmissionJobCandidatesIndexRequestParams
  ) {
    return this.fetchItemsIndex<T>(
      CandidateSubmissionJobCandidatesBffRoutes.fetchCandidateSubmissionJobCandidatesIndexRoute(),
      params
    );
  }

  static fetchCandidateSubmissionJobCandidate<T>(
    candidateSubmissionJobCandidateId: CandidateSubmissionJobCandidateId,
    params: FetchCandidateSubmissionJobCandidateRequestParams
  ) {
    return this.fetchItem<T>(
      CandidateSubmissionJobCandidatesBffRoutes.fetchCandidateSubmissionJobCandidateRoute(
        candidateSubmissionJobCandidateId
      ),
      params
    );
  }

  static fetchCandidateSubmissionJobCandidateIndex<T>(
    candidateSubmissionJobCandidateNanoId: CandidateSubmissionJobCandidateNanoId,
    params: FetchCandidateSubmissionJobCandidateIndexRequestParams
  ) {
    return this.fetchItemIndex<T>(
      CandidateSubmissionJobCandidatesBffRoutes.fetchCandidateSubmissionJobCandidateIndexRoute(
        candidateSubmissionJobCandidateNanoId
      ),
      params
    );
  }

  static fetchCandidateSubmissionJobCandidateByNanoId<T>(
    candidateSubmissionJobCandidateNanoId: CandidateSubmissionJobCandidateNanoId,
    params: FetchCandidateSubmissionJobCandidateRequestParams
  ) {
    return this.fetchItemByNanoId<T>(
      CandidateSubmissionJobCandidatesBffRoutes.fetchCandidateSubmissionJobCandidateByNanoIdRoute(
        candidateSubmissionJobCandidateNanoId
      ),
      params
    );
  }

  static createCandidateSubmissionJobCandidate<T>(
    createCandidateSubmissionJobCandidateData: CreateCandidateSubmissionJobCandidateRequestData
  ) {
    return this.createItem<T, CreateCandidateSubmissionJobCandidateRequestData>(
      CandidateSubmissionJobCandidatesBffRoutes.createCandidateSubmissionJobCandidateRoute(),
      createCandidateSubmissionJobCandidateData
    );
  }

  static async deleteCandidateSubmissionJobCandidate<T>(
    deleteCandidateSubmissionJobCandidateId:
      | CandidateSubmissionJobCandidateId
      | CandidateSubmissionJobCandidateNanoId
  ) {
    return this.deleteItem<T>(
      CandidateSubmissionJobCandidatesBffRoutes.deleteCandidateSubmissionJobCandidateRoute(
        deleteCandidateSubmissionJobCandidateId
      )
    );
  }
}
