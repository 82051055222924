import { ReactNode, useCallback } from 'react';
import { useRouter } from 'next/router';

import { MainAppLayout } from '../MainAppLayout';
import { useSignOut } from '../../../../auth/hooks/useSignOut';
import { HiringPortalLayout } from '../../../../layouts/HiringPortalLayout';
import { ControlPanelLayout } from '../../../../layouts/ControlPanelLayout';
import { EmployeesRoutes } from '../../../control-panel/pages/People/EmployeesRoutes';
import { useActiveLink, useTotalTalentCredits } from '../../../../context';
import { AppLayout } from '../../../../layouts/AppLayout';
import { JobNanoId } from '../../../jobs/jobsTypes';
import { JobCandidateNanoId } from '../../../jobCandidates/jobCandidatesTypes';
import { ViewJobCandidateDetailsPageLayout } from '../../../jobs/pages/ViewJobCandidateDetailsPage/layouts/ViewJobCandidateDetailsPageLayout';
import { SourcingTalentProfilePage } from '../../../sourcing/pages/SourcingTalentProfilePage';
import { JobsRoutes } from '../../../jobs/JobsRoutes';
import { Box } from '@chakra-ui/react';

interface AppLayoutProps {
  children: ReactNode;
}

function RootLayout({ children }: AppLayoutProps) {
  const router = useRouter();
  const handleSignOut = useSignOut();
  const { activeLink } = useActiveLink();
  const { totalTalentCredits } = useTotalTalentCredits();
  const { jobNanoId, jobCandidateNanoId } = router.query;
  const { poolNanoId, personNanoId } = router.query;

  const sourcingBasePath =
    router.pathname === '/sourcing/prospects/view/[poolNanoId]/[personNanoId]';

  const onSaveContact = useCallback<() => void>(
    () => router.push(EmployeesRoutes.contacts()),
    [router]
  );

  if (router.pathname.includes('layout-example')) {
    return <MainAppLayout>{children}</MainAppLayout>;
  }

  // <<<==== Hiring portal job candidate profile pages ====>>>
  if (
    router.pathname.includes(
      '/hiring-portal/candidates/[jobNanoId]/[jobCandidateNanoId]'
    )
  ) {
    return (
      <HiringPortalLayout activeLink="pipeline" onSignOut={handleSignOut}>
        <ViewJobCandidateDetailsPageLayout
          jobNanoId={jobNanoId as JobNanoId}
          jobCandidateNanoId={jobCandidateNanoId as JobCandidateNanoId}
          isHiringPortalPath
        >
          {children}
        </ViewJobCandidateDetailsPageLayout>
      </HiringPortalLayout>
    );
  }

  // <<<==== HiringPortal Layout ====>>>
  if (router.pathname.includes('hiring-portal')) {
    return (
      <HiringPortalLayout activeLink={activeLink} onSignOut={handleSignOut}>
        {children}
      </HiringPortalLayout>
    );
  }

  console.log(router.pathname);

  // <<<==== Control Panel Layout ====>>>
  if (
    router.pathname.includes('control-panel') ||
    router.pathname.includes('permissions') ||
    router.pathname.includes('account-types') ||
    router.pathname.includes('file-attachments') ||
    router.pathname.includes('companies')
  ) {
    return (
      <ControlPanelLayout
        onSignOut={handleSignOut}
        onSaveContact={onSaveContact}
        activeLink={activeLink}
      >
        {children}
      </ControlPanelLayout>
    );
  }

  // <<<==== Ordinary Layout without a wrapper ====>>>
  if (
    router.pathname.includes('welcome-back') ||
    router.pathname.includes('sign-in') ||
    router.pathname.includes('sign-up') ||
    router.pathname.includes('accept-invitation') ||
    router.pathname.includes('confirm-email') ||
    router.pathname.includes('create-profile') ||
    router.pathname.includes('request-access') ||
    router.pathname.includes('get-started') ||
    router.pathname.includes('work-email') ||
    router.pathname.includes('/career-site/jobs') ||
    router.pathname.includes('/career-site/[recruiterNanoId]/jobs') ||
    router.pathname.includes(
      '/career-site/[recruiterNanoId]/job/[jobNanoId]s'
    ) ||
    router.pathname.includes('pipeline/kanban/preview/[jobNanoId]') ||
    router.pathname.includes(
      '/career-site/jobs/[jobNanoId]/[jobResumeRecruiterNanoId]'
    ) ||
    router.pathname.includes('career-site/jobs/[jobNanoId]')
  ) {
    return <>{children}</>;
  }

  // <<<==== Recruiter portal job candidate profile pages ====>>>
  if (
    router.pathname.includes(
      '/pipeline/kanban/view/[jobNanoId]/candidates/[jobCandidateNanoId]'
    )
  ) {
    return (
      <AppLayout
        onSignOut={handleSignOut}
        activeLink="pipeline"
        showRightSidebar={false}
      >
        <ViewJobCandidateDetailsPageLayout
          jobNanoId={jobNanoId as JobNanoId}
          jobCandidateNanoId={jobCandidateNanoId as JobCandidateNanoId}
        >
          {children}
        </ViewJobCandidateDetailsPageLayout>
      </AppLayout>
    );
  }

  if (
    router.pathname.includes('/pipeline/kanban/view/[jobNanoId]') ||
    router.pathname.includes('/pipeline/kanban/edit/[jobNanoId]')
  ) {
    const headerMenuItems = [
      {
        title: 'job',
        link: JobsRoutes.post()
      },
      {
        title: 'candidate',
        link: JobsRoutes.postJobCandidates(jobNanoId as JobNanoId)
      },
      {
        title: 'hiring team',
        link: JobsRoutes.editTeam(jobNanoId as JobNanoId)
      },
      { title: 'meeting' },
      { title: 'comment' },
      { title: 'task' }
    ];
    return (
      <AppLayout
        onSignOut={handleSignOut}
        activeLink={activeLink}
        headerMenuItems={headerMenuItems}
      >
        {children}
      </AppLayout>
    );
  }

  if (
    router.pathname.includes(
      '/sourcing/prospects/view/[poolNanoId]/[personNanoId]'
    )
  ) {
    return (
      <AppLayout
        onSignOut={handleSignOut}
        activeLink="prospects"
        showRightSidebar={false}
        // menuItems={headerMenuItems}
      >
        <Box pr={2}>
          <SourcingTalentProfilePage
            poolNanoId={poolNanoId as JobNanoId}
            personNanoId={personNanoId as JobCandidateNanoId}
            onSignOut={handleSignOut}
            isBasePath={sourcingBasePath}
          >
            {children}
          </SourcingTalentProfilePage>
        </Box>
      </AppLayout>
    );
  }

  // <<<==== Recruiter Layout ====>>>
  return (
    <AppLayout
      onSignOut={handleSignOut}
      activeLink={activeLink}
      totalTalentCredits={totalTalentCredits}
    >
      {children}
    </AppLayout>
  );
}

export default RootLayout;
