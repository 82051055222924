import { Icon, IconProps } from '@chakra-ui/icon';

const ClockIcon = ({
  w = '16px',
  h = '16px',
  fill = 'gray.500',
  ...props
}: IconProps) => (
  <Icon viewBox="0 0 16 16" w={w} h={h} fill={fill} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.67 8.665H7.335V3.332h1.333v4h4v1.333ZM8 0C3.589 0 0 3.589 0 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8Z"
    />
  </Icon>
);
export default ClockIcon;
