import {
  FetchJobCacheKey,
  FetchJobNanoID,
  FetchJobQuery,
  FetchJobEnabled,
  FetchJobError,
  FetchJobErrorMessage,
  FetchJobIsFetched,
  FetchJobIsRefetching,
  FetchJobIsLoading,
  FetchJobIsPlaceholderData
} from '../../jobsTypes';

import { useFetchItemByNanoId } from '../../../common/hooks/useFetchItemByNanoId';

// import { JobsRequests } from '../../JobsRequests';
import { JobsBffRequests } from '../../JobsBffRequests';

interface FetchJobByNanoIdOptions<FetchJobResponseType> {
  jobNanoId: FetchJobNanoID;
  query: FetchJobQuery;
  cacheKey: FetchJobCacheKey;
  enabled?: FetchJobEnabled;
  placeholderJob?: FetchJobResponseType;
  placeholderData?: () => FetchJobResponseType;
  onSuccess?: (data: FetchJobResponseType) => void;
}

function useFetchJobByNanoId<FetchJobResponseType>({
  jobNanoId,
  query,
  cacheKey,
  enabled,
  placeholderJob,
  placeholderData,
  onSuccess
}: FetchJobByNanoIdOptions<FetchJobResponseType>) {
  const {
    item,
    itemError,
    itemErrorMessage,
    itemIsFetched,
    itemIsRefetching,
    itemIsLoading,
    itemIsPlaceholderData,
    refetchItem
  } = useFetchItemByNanoId<FetchJobResponseType, FetchJobError>({
    itemNanoId: jobNanoId,
    cacheKey,
    enabled,
    placeholderItem: placeholderJob,
    placeholderData,
    onSuccess: (data) => onSuccess?.(data as FetchJobResponseType),
    queryFn: () => {
      // return JobsRequests.fetchJobByNanoId<FetchJobResponseType>(jobNanoId, {
      //   query
      // });
      return JobsBffRequests.fetchJobByNanoId<FetchJobResponseType>(jobNanoId, {
        query
      });
    }
  });

  return {
    job: item,
    jobError: itemError,
    jobErrorMessage: itemErrorMessage as FetchJobErrorMessage,
    jobIsFetched: itemIsFetched as FetchJobIsFetched,
    jobIsRefetching: itemIsRefetching as FetchJobIsRefetching,
    jobIsLoading: itemIsLoading as FetchJobIsLoading,
    jobIsPlaceholderData: itemIsPlaceholderData as FetchJobIsPlaceholderData,
    refetchJob: refetchItem
  };
}

export default useFetchJobByNanoId;
