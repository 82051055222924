import { ReactNode, useCallback, useEffect } from 'react';
import { FieldValues } from 'react-hook-form';
import { SystemStyleObject } from '@chakra-ui/react';
import debounce from 'lodash/debounce';
import find from 'lodash/find';
import last from 'lodash/last';
import size from 'lodash/size';

import { ErrorMessage, IsDisabled, IsRequired } from '../../../../../types';

import {
  FetchJobFunctionsSort,
  JobFunctionFields,
  JobFunctionId,
  JobFunctionIds,
  JobFunctionName
} from '../../../../jobFunctions/jobFunctionsTypes';

import {
  fetchJobFunctionsQuery,
  FetchJobFunctionsResponse
} from '../../../../jobFunctions/queries/fetchJobFunctions.query';

import { usePaginatedJobFunctions } from '../../../../jobFunctions/hooks/usePaginatedJobFunctions';

import { JobFunctionsCache } from '../../../../jobFunctions/JobFunctionsCache';

import {
  MultiSelectField,
  MultiSelectFieldReactHookFormControl,
  MultiSelectFieldReactHookFormFieldPath
} from '../../../../../helpers/forms/formFields/MultiSelectField';
import { JobTitleId } from '../../../../jobTitles/jobTitlesTypes';
import { useUpdateJobTitle } from '../../../../jobTitles/hooks/useUpdateJobTitle';
import { JobTitlesCache } from '../../../../jobTitles/JobTitlesCache';
import { useChakraToast } from '../../../../../helpers/useChakraToast';
import { JobJobFunctionId } from '../../../../jobs/jobsTypes';
import { SelectJobTitleSelectJobTitleAssociatesProps } from '../SelectJobTitleFormField/SelectJobTitle.types';

type SelectJobFunctionsFormFieldWithAssociatedJobTitleProps = {
  selectedFunctionIds: JobFunctionIds;
  selectedJobTitleId: JobTitleId;
  isNewJobtitle: boolean;
  selectJobTitleAssocaites: (
    jobTitleAssociates: SelectJobTitleSelectJobTitleAssociatesProps
  ) => void;
};

type SelectJobFunctionsFormFieldWithoutAssociatedJobTitleProps = {
  selectedFunctionIds?: never;
  selectedJobTitleId?: never;
  isNewJobtitle?: never;
  selectJobTitleAssocaites?: never;
};

type SelectJobFunctionsFormFieldRequiredProps<T extends FieldValues> = {
  isRequired?: IsRequired;
  isDisabled?: IsDisabled;
  control: MultiSelectFieldReactHookFormControl<T>;
  item?: {
    jobFunctions: {
      id: JobFunctionId;
      name: JobFunctionName;
    }[];
  } | null;
  errorMessage: ErrorMessage;
  labelAddon?: ReactNode;
  label?: string;
  placeholder?: string;
  withoutLabel?: boolean;
  name?: MultiSelectFieldReactHookFormFieldPath<T>;
  reactSelectStyles?: Record<string, unknown>;
  isBorderless?: boolean;
} & (
  | SelectJobFunctionsFormFieldWithAssociatedJobTitleProps
  | SelectJobFunctionsFormFieldWithoutAssociatedJobTitleProps
);

function SelectJobFunctionsFormField<T extends FieldValues>({
  isRequired,
  isDisabled,
  control,
  item,
  errorMessage,
  labelAddon,
  label,
  placeholder,
  selectedFunctionIds,
  selectedJobTitleId,
  isNewJobtitle,
  selectJobTitleAssocaites,
  withoutLabel,
  name,
  reactSelectStyles,
  isBorderless
}: SelectJobFunctionsFormFieldRequiredProps<T>) {
  const {
    jobFunctions,
    jobFunctionsIsLoading,
    jobFunctionsErrorMessage,
    changeJobFunctionsFilters
  } = usePaginatedJobFunctions<FetchJobFunctionsResponse>({
    query: fetchJobFunctionsQuery,
    cacheKey: JobFunctionsCache.indexCacheKey(),
    initialSort: {
      [JobFunctionFields.NAME]: { ascending: true }
    } as unknown as FetchJobFunctionsSort
  });

  const {
    updateJobTitle,
    updateJobTitleData,
    updateJobTitleIsLoading,
    updateJobTitleErrorMessage
  } = useUpdateJobTitle({
    jobTitleId: selectedJobTitleId as JobTitleId,
    cacheKeys: [JobTitlesCache.indexCacheKey()]
  });

  const toast = useChakraToast();

  const defaultItemUserJobFunctions = item?.jobFunctions
    ? item?.jobFunctions.map((userJobFunction) => ({
        value: userJobFunction.id,
        label: userJobFunction.name
      }))
    : undefined;

  const debouncedFilterJobFunctions = debounce<(updatedValue: string) => void>(
    (updatedValue) =>
      changeJobFunctionsFilters({
        [JobFunctionFields.NAME]: {
          operator: 'ilike',
          value: `${updatedValue}%`
        }
      }),
    500
  );

  const handleUpdateJobTitle = useCallback<
    (jobFunctionId: JobFunctionId) => void
  >(
    (jobFunctionId) => {
      if (!updateJobTitleData && !updateJobTitleIsLoading) {
        updateJobTitle({
          jobFunctionId: jobFunctionId as JobJobFunctionId
        });
        selectJobTitleAssocaites?.({ isNewJobTitle: false });
      }
    },
    [
      updateJobTitle,
      updateJobTitleData,
      updateJobTitleIsLoading,
      selectJobTitleAssocaites
    ]
  );

  useEffect(() => {
    if (isNewJobtitle && size(selectedFunctionIds)) {
      const lastJobFunctionId = last(selectedFunctionIds);
      const selectedJobFunction = find(
        jobFunctions,
        ({ id }) => id === lastJobFunctionId
      );

      if (
        selectedJobFunction &&
        selectedJobFunction?.id !== lastJobFunctionId
      ) {
        handleUpdateJobTitle(lastJobFunctionId as JobFunctionId);
      }
    }
  }, [selectedFunctionIds, jobFunctions, handleUpdateJobTitle, isNewJobtitle]);

  useEffect(() => {
    updateJobTitleErrorMessage &&
      toast({ title: updateJobTitleErrorMessage, status: 'error' });
  }, [updateJobTitleErrorMessage, toast]);

  return (
    <MultiSelectField
      isRequired={isRequired}
      isDisabled={isDisabled}
      control={control}
      label={withoutLabel ? undefined : label || 'Job Functions'}
      labelAddon={labelAddon}
      placeholder={placeholder || 'Select job function'}
      name={
        name || ('jobFunctionIds' as MultiSelectFieldReactHookFormFieldPath<T>)
      }
      options={jobFunctions.map((jobFunction) => ({
        value: jobFunction.id,
        label: jobFunction.name
      }))}
      defaultValue={defaultItemUserJobFunctions}
      errorMessage={errorMessage || jobFunctionsErrorMessage}
      isLoading={jobFunctionsIsLoading}
      onInputChange={debouncedFilterJobFunctions}
      reactSelectStyles={
        isBorderless
          ? {
              control: (base: SystemStyleObject) => ({
                ...base,
                borderColor: 'transparent',
                p: 0
              })
            }
          : reactSelectStyles
      }
    />
  );
}

export default SelectJobFunctionsFormField;
