import snakeCase from 'lodash/snakeCase';

import {
  FetchUsersQuery,
  UserCreatedAt,
  UserFields,
  UserId,
  UserNanoId,
  UserName,
  UserFullName,
  UserEmail,
  UserPhone,
  UserPersonalEmail,
  UserCompanyPhone,
  UserCompanyEmail,
  UserImage,
  UserCompany,
  UserCity,
  UserState,
  UserFirstName,
  UserLastName,
  MayBeUserLinkedinUrl,
  UserJobFunctionId,
  UserJobFunction,
  UserJobTitleId,
  UserJobTitle,
  UserAccountType,
  UserAccountTypeId
} from '../usersTypes';

export interface FetchUsersResponse {
  [UserFields.ID]: UserId;
  [UserFields.NANO_ID]: UserNanoId;
  [UserFields.NAME]: UserName;
  [UserFields.FULL_NAME]: UserFullName;
  [UserFields.FIRST_NAME]: UserFirstName;
  [UserFields.LAST_NAME]: UserLastName;
  [UserFields.LINKEDIN_URL]: MayBeUserLinkedinUrl;
  [UserFields.EMAIL]: UserEmail;
  [UserFields.PHONE]: UserPhone;
  [UserFields.PERSONAL_EMAIL]: UserPersonalEmail;
  [UserFields.COMPANY_PHONE]: UserCompanyPhone;
  [UserFields.COMPANY_EMAIL]: UserCompanyEmail;
  [UserFields.IMAGE]: UserImage;
  [UserFields.COMPANY]: UserCompany;
  [UserFields.STATE]: UserState;
  [UserFields.CITY]: UserCity;
  [UserFields.CREATED_AT]: UserCreatedAt;
  [UserFields.JOB_FUNCTION_ID]: UserJobFunctionId;
  [UserFields.JOB_FUNCTION]: UserJobFunction;
  [UserFields.JOB_TITLE_ID]: UserJobTitleId;
  [UserFields.JOB_TITLE]: UserJobTitle;
  [UserFields.ACCOUNT_TYPE_ID]: UserAccountTypeId;
  [UserFields.ACCOUNT_TYPE]: UserAccountType;
}

export const fetchUsersColumns = [
  UserFields.ID,
  UserFields.NANO_ID,
  UserFields.NAME,
  UserFields.FULL_NAME,
  UserFields.FIRST_NAME,
  UserFields.LAST_NAME,
  UserFields.LINKEDIN_URL,
  UserFields.EMAIL,
  UserFields.PHONE,
  UserFields.PERSONAL_EMAIL,
  UserFields.COMPANY_PHONE,
  UserFields.COMPANY_EMAIL,
  UserFields.CREATED_AT
];

const fetchUsersSBColumns = fetchUsersColumns.map((fetchUsersColumn) =>
  snakeCase(fetchUsersColumn)
);

export const fetchUsersQuery = `
  ${fetchUsersSBColumns.join(',')},
  image:image_id(id,nano_id,name,file,key,created_at),
  company:company_id(id,nano_id,name,created_at,image:image_id(id,nano_id,name,file,key,created_at)),
  state:state_id(id,nano_id,name,iso_code),
  city:city_id(id,nano_id,name,label)
` as FetchUsersQuery;
