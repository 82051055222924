import { Flex, Popover, PopoverTrigger, useDisclosure } from '@chakra-ui/react';
import lowerCase from 'lodash/lowerCase';
import startCase from 'lodash/startCase';
import { AvatarHelper } from '../../../../../../../../helpers/AvatarHelper';
import { Heading } from '../../../../../../../../helpers/Heading';
import { Text } from '../../../../../../../../helpers/Text';
import { LinkedInIcon } from '../../../../../../../../icons/LinkedInIcon';
import { LinkedinLinkHelper } from '../../../../../../../common/components/helpers/LinkedinLinkHelper';
import { WorkflowStage } from '../../../../../../components/modals/ViewJobCandidateModal/helpers/VewiJobCandidateModalHeader/ViewJobCandidateModalHeader.types';
import { CandidateTaskStatusBadge } from '../../../../../ViewJobCandidatesPage/helpers/CandidateTaskStatusBadge';
import { JobCandidateDetailsJobCandidate } from '../../../../ViewJobCandidateDetailsPage.types';
import { ViewJobCandidateDetailsRoutes } from '../../../../ViewJobCandidateDetailsRoutes';
import { PipelineStageCandidatePopoverContent } from '../PipelineStageCandidatePopoverContent';
import { HiringPortalJobCandidateDetailsRoutes } from '../../../../../../../hiringPortal/HiringPortalJobCandidateDetailsRoutes';
import { useRouter } from 'next/router';

interface PipelineStageCandidateProps {
  workflowStage: WorkflowStage[];
  jobCandidate: JobCandidateDetailsJobCandidate;
  isHiringPortalPage: boolean;
}

function PipelineStageCandidate({
  jobCandidate,
  isHiringPortalPage
}: PipelineStageCandidateProps) {
  const router = useRouter();

  const {
    isOpen: isHovered,
    onOpen: onMouseHover,
    onClose: onMouseLeave
  } = useDisclosure();

  const {
    isOpen: isOpenPopover,
    onOpen: onOpenPopover,
    onClose: onClosePopover
  } = useDisclosure();

  const title = startCase(lowerCase(jobCandidate.jobTitle));

  const route = isHiringPortalPage
    ? HiringPortalJobCandidateDetailsRoutes
    : ViewJobCandidateDetailsRoutes;

  const openProfile = () => {
    onClosePopover();
    router.push(route.index(jobCandidate.job.nanoId, jobCandidate.nanoId));
  };

  return (
    <Popover
      isLazy
      trigger="hover"
      offset={[0, 24]}
      placement="left-start"
      key={jobCandidate.nanoId}
      onClose={onClosePopover}
      onOpen={onOpenPopover}
    >
      <PopoverTrigger>
        <Flex as="button" textAlign="left" onClick={openProfile}>
          <Flex
            flex={1}
            px={2.5}
            py={1.5}
            gap={2}
            color="gray.800"
            alignItems="center"
            transitionDuration="slow"
            onMouseOver={onMouseHover}
            onMouseLeave={onMouseLeave}
            bg={isHovered ? 'gray.200' : 'transparent'}
          >
            <AvatarHelper image={jobCandidate.image} />

            <Flex gap={1} flex={1} flexDir="column">
              <Flex gap={2} alignItems="center">
                <Heading
                  m={0}
                  p={0}
                  level="h4"
                  fontSize="sm"
                  lineHeight={1.25}
                  fontWeight="medium"
                >
                  {jobCandidate.name}
                </Heading>

                <LinkedinLinkHelper
                  w={5}
                  h={5}
                  display="flex"
                  item={jobCandidate}
                  opacity={isHovered ? 1 : 0}
                  onClick={(e) => e.stopPropagation()}
                  visibility={isHovered ? 'visible' : 'hidden'}
                  transitionDuration="slow"
                  justifyContent="center"
                  borderRadius="full"
                  alignItems="center"
                  bg="#069"
                >
                  <LinkedInIcon w={3} h={3} />
                </LinkedinLinkHelper>
              </Flex>

              <Flex h={6} alignItems="center">
                {isHovered ? (
                  <Text textStyle="body2Regular" noOfLines={1}>
                    {title}
                  </Text>
                ) : (
                  <CandidateTaskStatusBadge
                    jobCandidate={jobCandidate}
                    workflowStage={jobCandidate.workflowStage}
                    pendingWorkflowStageTasks={[]}
                    completedWorkflowStageTasksIds={[]}
                  />
                )}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </PopoverTrigger>

      {isOpenPopover && (
        <PipelineStageCandidatePopoverContent jobCandidate={jobCandidate} />
      )}
    </Popover>
  );
}

export default PipelineStageCandidate;
