import { usePaginatedItems } from '../../../common/hooks/usePaginatedItems';

import {
  FetchIndustryExpertisesQuery,
  FetchIndustryExpertisesCacheKey,
  FetchIndustryExpertisesTotalCount,
  FetchIndustryExpertisesPage,
  FetchIndustryExpertisesPageSize,
  FetchIndustryExpertisesError,
  FetchIndustryExpertisesErrorMessage,
  FetchIndustryExpertisesIsLoading,
  FetchIndustryExpertisesIsFetched,
  FetchIndustryExpertisesIsPlaceholderData,
  FetchIndustryExpertisesEnabled,
  FetchIndustryExpertisesPrefetchNextPage,
  FetchIndustryExpertisesCountType,
  FetchIndustryExpertisesFilters,
  FetchIndustryExpertisesSort,
  FetchIndustryExpertiseID,
  FetchIndustryExpertiseQuery,
  FetchIndustryExpertiseCacheKey
} from '../../industryExpertisesTypes';

import { IndustryExpertisesBffRequests } from '../../IndustryExpertisesBffRequests';

interface PaginatedIndustryExpertisesBaseOptions<
  PaginatedIndustryExpertisesResponseType
> {
  cacheKey: FetchIndustryExpertisesCacheKey;
  query: FetchIndustryExpertisesQuery;
  countType?: FetchIndustryExpertisesCountType;
  initialPage?: FetchIndustryExpertisesPage;
  initialPageSize?: FetchIndustryExpertisesPageSize;
  initialFilters?: FetchIndustryExpertisesFilters;
  initialSort?: FetchIndustryExpertisesSort;
  enabled?: FetchIndustryExpertisesEnabled;
  prefetchNextPage?: FetchIndustryExpertisesPrefetchNextPage;
  placeholderIndustryExpertises?: PaginatedIndustryExpertisesResponseType[];
  placeholderIndustryExpertisesCount?: FetchIndustryExpertisesTotalCount;
  placeholderData?: () => PaginatedIndustryExpertisesResponse<PaginatedIndustryExpertisesResponseType>;
  onSuccess?: (
    data: PaginatedIndustryExpertisesResponse<PaginatedIndustryExpertisesResponseType>
  ) => void;
}

interface PaginatedIndustryExpertisesOptionsWithoutItem {
  fetchItemCacheKey?: never;
  itemQuery?: never;
}

interface PaginatedIndustryExpertisesOptionsWithItem {
  fetchItemCacheKey: FetchIndustryExpertiseCacheKey;
  itemQuery: FetchIndustryExpertiseQuery;
}

type PaginatedIndustryExpertisesOptions<
  PaginatedIndustryExpertisesResponseType
> = PaginatedIndustryExpertisesBaseOptions<PaginatedIndustryExpertisesResponseType> &
  (
    | PaginatedIndustryExpertisesOptionsWithoutItem
    | PaginatedIndustryExpertisesOptionsWithItem
  );

interface PaginatedIndustryExpertisesResponse<
  PaginatedIndustryExpertisesResponseType
> {
  data: PaginatedIndustryExpertisesResponseType[];
  totalCount?: FetchIndustryExpertisesTotalCount;
  meta?: { totalCount?: FetchIndustryExpertisesTotalCount };
}

function usePaginatedIndustryExpertises<
  PaginatedIndustryExpertisesResponseType
>({
  cacheKey,
  query,
  countType,
  initialPage,
  initialPageSize,
  initialFilters,
  initialSort,
  enabled,
  prefetchNextPage,
  placeholderIndustryExpertises,
  placeholderIndustryExpertisesCount,
  placeholderData,
  fetchItemCacheKey,
  itemQuery,
  onSuccess
}: PaginatedIndustryExpertisesOptions<PaginatedIndustryExpertisesResponseType>) {
  const {
    items,
    itemsTotalCount,
    itemsError,
    itemsErrorMessage,
    itemsIsFetched,
    itemsIsLoading,
    itemsIsPlaceholderData,
    itemsCurrentPage,
    itemsCurrentPageSize,
    itemsCurrentFilters,
    itemsCurrentSort,
    fetchItems,
    paginateItems,
    changeItemsPageSize,
    filterItems,
    changeItemsFilters,
    clearItemsFilters,
    sortItems,
    prefetchItems,
    prefetchItem
  } = usePaginatedItems<PaginatedIndustryExpertisesResponseType>({
    cacheKey,
    initialPage,
    initialPageSize,
    initialFilters,
    initialSort,
    enabled,
    prefetchNextPage,
    placeholderItems: placeholderIndustryExpertises,
    placeholderItemsCount: placeholderIndustryExpertisesCount,
    placeholderData,
    onSuccess: (data) =>
      onSuccess?.(
        data as PaginatedIndustryExpertisesResponse<PaginatedIndustryExpertisesResponseType>
      ),
    queryFn: (params) =>
      IndustryExpertisesBffRequests.fetchIndustryExpertises<PaginatedIndustryExpertisesResponseType>(
        {
          query,
          countType,
          page: params.page as FetchIndustryExpertisesPage,
          pageSize: params.pageSize as FetchIndustryExpertisesPageSize,
          filters: params.filters as FetchIndustryExpertisesFilters,
          sort: params.sort as FetchIndustryExpertisesSort
        }
      ),
    ...(itemQuery
      ? {
          fetchItemCacheKey,
          queryItemFn: (itemId) =>
            IndustryExpertisesBffRequests.fetchIndustryExpertise(
              itemId as FetchIndustryExpertiseID,
              {
                query: itemQuery
              }
            )
        }
      : {})
  });

  return {
    industryExpertises: items as PaginatedIndustryExpertisesResponseType[],
    industryExpertisesTotalCount:
      itemsTotalCount as FetchIndustryExpertisesTotalCount,
    industryExpertisesError: itemsError as FetchIndustryExpertisesError,
    industryExpertisesErrorMessage:
      itemsErrorMessage as FetchIndustryExpertisesErrorMessage,
    industryExpertisesIsLoading:
      itemsIsLoading as FetchIndustryExpertisesIsLoading,
    industryExpertisesIsFetched:
      itemsIsFetched as FetchIndustryExpertisesIsFetched,
    industryExpertisesIsPlaceholderData:
      itemsIsPlaceholderData as FetchIndustryExpertisesIsPlaceholderData,
    industryExpertisesCurrentPage:
      itemsCurrentPage as FetchIndustryExpertisesPage,
    industryExpertisesCurrentPageSize:
      itemsCurrentPageSize as FetchIndustryExpertisesPageSize,
    industryExpertisesCurrentFilters:
      itemsCurrentFilters as FetchIndustryExpertisesFilters,
    industryExpertisesCurrentSort:
      itemsCurrentSort as FetchIndustryExpertisesSort,
    fetchIndustryExpertises: fetchItems,
    paginateIndustryExpertises: paginateItems,
    changeIndustryExpertisesPageSize: changeItemsPageSize,
    filterIndustryExpertises: filterItems,
    changeIndustryExpertisesFilters: changeItemsFilters,
    clearIndustryExpertisesFilters: clearItemsFilters,
    sortIndustryExpertises: sortItems,
    prefetchIndustryExpertises: prefetchItems,
    prefetchIndustryExpertise: prefetchItem
  };
}

export default usePaginatedIndustryExpertises;
