import {
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure
} from '@chakra-ui/react';
import {
  Button,
  ButtonProps
} from '../../../../../../../../../../../helpers/Button';
import { IconButton } from '../../../../../../../../../../../helpers/buttons/IconButton';
import { ChevronDownIcon } from '../../../../../../../../../../../icons/ChevronDownIcon';

interface ViewJobCandidateModalPhoneSidebarNotInterestedButtonProps
  extends ButtonProps {
  selectedOption?: string | null;
}

const ViewJobCandidateModalPhoneSidebarNotInterestedButton = ({
  selectedOption,
  onClick
}: ViewJobCandidateModalPhoneSidebarNotInterestedButtonProps) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Flex
      gap={0.5}
      borderRadius={4}
      alignItems="center"
      border="1px solid"
      onMouseEnter={onToggle}
      onMouseLeave={onToggle}
      bg={selectedOption === 'not-interested' ? 'red.100' : 'white'}
      boxShadow="0px 2px 2px rgba(23, 25, 26, 0.06)"
      borderColor={selectedOption === 'not-interested' ? 'red.500' : 'gray.300'}
      _hover={{
        borderColor: 'red.500',
        bg: selectedOption === 'not-interested' ? 'red.100' : 'white',
        color: 'red.500'
      }}
    >
      <Button
        pl={2}
        h="38px"
        minH="auto"
        border="none"
        size="medium"
        hierarchy="unstyled"
        value="not-interested"
        onClick={onClick}
        color={
          selectedOption === 'not-interested' || isOpen ? 'red.500' : 'gray.900'
        }
      >
        Not interested
      </Button>

      <Menu isLazy placement="bottom-end">
        <MenuButton
          minW={0}
          pr={1}
          size="small"
          border="none"
          as={IconButton}
          boxShadow="none"
          hierarchy="unstyled"
          aria-label="Dropdown"
          icon={
            <ChevronDownIcon
              fill={
                selectedOption === 'not-interested' || isOpen
                  ? 'red.500'
                  : 'gray.900'
              }
            />
          }
        />
        <MenuList>
          <MenuItem value="not-a-fit" onClick={onClick}>
            Not a fit
          </MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default ViewJobCandidateModalPhoneSidebarNotInterestedButton;
