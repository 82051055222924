import { switchAnatomy as parts } from '@chakra-ui/anatomy';
import type {
  PartsStyleFunction,
  SystemStyleFunction,
  SystemStyleObject
} from '@chakra-ui/theme-tools';
import { calc, cssVar, mode } from '@chakra-ui/theme-tools';

const $width = cssVar('switch-track-width');
const $height = cssVar('switch-track-height');

const $diff = cssVar('switch-track-diff');
const diffValue = '12px';

const $translateX = cssVar('switch-thumb-x');

const customTrack: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props;

  return {
    borderRadius: '2px',
    p: '1px',
    boxSizing: 'border-box',
    width: [$width.reference],
    height: [$height.reference],
    transitionProperty: 'common',
    transitionDuration: 'fast',
    bg: mode('gray.300', 'whiteAlpha.400')(props),
    _focus: {
      boxShadow: 'outline'
    },
    _disabled: {
      opacity: 0.4,
      cursor: 'not-allowed'
    },
    _checked: {
      bg: mode(`${c}.500`, `${c}.200`)(props)
    }
  };
};

const customThumb: SystemStyleObject = {
  bg: 'white',
  transitionProperty: 'transform',
  transitionDuration: 'normal',
  borderRadius: '2px',
  width: '10px',
  height: '22px',
  _checked: {
    transform: `translateX(${$translateX.reference})`
  }
};

const customVariant: PartsStyleFunction<typeof parts> = (props) => ({
  container: {
    [$width.variable]: '1.5rem',
    [$height.variable]: '1.5rem',
    [$diff.variable]: diffValue,
    [$translateX.variable]: $diff.reference,
    _rtl: {
      [$translateX.variable]: calc($diff).negate().toString()
    }
  },
  track: customTrack(props),
  thumb: customThumb
});

export const Switch = {
  variants: {
    custom: customVariant
  }
};
