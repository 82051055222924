import { FieldBrand, ItemRecordID } from '../../../types';

export type RoleRecordId = FieldBrand<ItemRecordID, 'RoleRecordId'>;

export const enum RoleIndexFields {
  ID = 'id',
  RECORD_ID = 'recordId',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  NAME = 'name',
  CREATED_AT = 'createdAt',

  PERMISSIONS = 'permissions',
  PERMISSIONS_NANO_ID = 'permissions.nanoId',
  PERMISSIONS_ACTION = 'permissions.action',
  PERMISSIONS_FRONTEND = 'permissions.frontend',

  USERS_COUNT = 'usersCount',

  USER_IDS = 'userIds'
}
