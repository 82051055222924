import { usePaginatedItems } from '../../../common/hooks/usePaginatedItems';

import {
  FetchAccountTypesQuery,
  FetchAccountTypesCacheKey,
  FetchAccountTypesTotalCount,
  FetchAccountTypesPage,
  FetchAccountTypesPageSize,
  FetchAccountTypesError,
  FetchAccountTypesErrorMessage,
  FetchAccountTypesIsLoading,
  FetchAccountTypesIsFetched,
  FetchAccountTypesIsPlaceholderData,
  FetchAccountTypesEnabled,
  FetchAccountTypesPrefetchNextPage,
  FetchAccountTypesCountType,
  FetchAccountTypesFilters,
  FetchAccountTypesSort,
  FetchAccountTypeID,
  FetchAccountTypeQuery,
  FetchAccountTypeCacheKey
} from '../../accountTypesTypes';

import { AccountTypesBffRequests } from '../../AccountTypesBffRequests';

interface PaginatedAccountTypesBaseOptions<PaginatedAccountTypesResponseType> {
  cacheKey: FetchAccountTypesCacheKey;
  query: FetchAccountTypesQuery;
  countType?: FetchAccountTypesCountType;
  initialPage?: FetchAccountTypesPage;
  initialPageSize?: FetchAccountTypesPageSize;
  initialFilters?: FetchAccountTypesFilters;
  initialSort?: FetchAccountTypesSort;
  enabled?: FetchAccountTypesEnabled;
  prefetchNextPage?: FetchAccountTypesPrefetchNextPage;
  placeholderAccountTypes?: PaginatedAccountTypesResponseType[];
  placeholderAccountTypesCount?: FetchAccountTypesTotalCount;
  placeholderData?: () => PaginatedAccountTypesResponse<PaginatedAccountTypesResponseType>;
}

interface PaginatedAccountTypesOptionsWithoutItem {
  fetchItemCacheKey?: never;
  itemQuery?: never;
}

interface PaginatedAccountTypesOptionsWithItem {
  fetchItemCacheKey: FetchAccountTypeCacheKey;
  itemQuery: FetchAccountTypeQuery;
}

type PaginatedAccountTypesOptions<PaginatedAccountTypesResponseType> =
  PaginatedAccountTypesBaseOptions<PaginatedAccountTypesResponseType> &
    (
      | PaginatedAccountTypesOptionsWithoutItem
      | PaginatedAccountTypesOptionsWithItem
    );

interface PaginatedAccountTypesResponse<PaginatedAccountTypesResponseType> {
  data: PaginatedAccountTypesResponseType[];
  totalCount: FetchAccountTypesTotalCount;
}

function usePaginatedAccountTypes<PaginatedAccountTypesResponseType>({
  cacheKey,
  query,
  countType,
  initialPage,
  initialPageSize,
  initialFilters,
  initialSort,
  enabled,
  prefetchNextPage,
  placeholderAccountTypes,
  placeholderAccountTypesCount,
  placeholderData,
  fetchItemCacheKey,
  itemQuery
}: PaginatedAccountTypesOptions<PaginatedAccountTypesResponseType>) {
  const {
    items,
    itemsTotalCount,
    itemsError,
    itemsErrorMessage,
    itemsIsFetched,
    itemsIsLoading,
    itemsIsPlaceholderData,
    itemsCurrentPage,
    itemsCurrentPageSize,
    itemsCurrentFilters,
    itemsCurrentSort,
    fetchItems,
    paginateItems,
    changeItemsPageSize,
    filterItems,
    changeItemsFilters,
    clearItemsFilters,
    sortItems,
    prefetchItems,
    prefetchItem
  } = usePaginatedItems<PaginatedAccountTypesResponseType>({
    cacheKey,
    initialPage,
    initialPageSize,
    initialFilters,
    initialSort,
    enabled,
    prefetchNextPage,
    placeholderItems: placeholderAccountTypes,
    placeholderItemsCount: placeholderAccountTypesCount,
    placeholderData,
    queryFn: (params) =>
      AccountTypesBffRequests.fetchAccountTypes<PaginatedAccountTypesResponseType>(
        {
          query,
          countType,
          page: params.page as FetchAccountTypesPage,
          pageSize: params.pageSize as FetchAccountTypesPageSize,
          filters: params.filters as FetchAccountTypesFilters,
          sort: params.sort as FetchAccountTypesSort
        }
      ),
    ...(itemQuery
      ? {
          fetchItemCacheKey,
          queryItemFn: (itemId) =>
            AccountTypesBffRequests.fetchAccountType(
              itemId as FetchAccountTypeID,
              {
                query: itemQuery
              }
            )
        }
      : {})
  });

  return {
    accountTypes: items as PaginatedAccountTypesResponseType[],
    accountTypesTotalCount: itemsTotalCount as FetchAccountTypesTotalCount,
    accountTypesError: itemsError as FetchAccountTypesError,
    accountTypesErrorMessage:
      itemsErrorMessage as FetchAccountTypesErrorMessage,
    accountTypesIsLoading: itemsIsLoading as FetchAccountTypesIsLoading,
    accountTypesIsFetched: itemsIsFetched as FetchAccountTypesIsFetched,
    accountTypesIsPlaceholderData:
      itemsIsPlaceholderData as FetchAccountTypesIsPlaceholderData,
    accountTypesCurrentPage: itemsCurrentPage as FetchAccountTypesPage,
    accountTypesCurrentPageSize:
      itemsCurrentPageSize as FetchAccountTypesPageSize,
    accountTypesCurrentFilters: itemsCurrentFilters as FetchAccountTypesFilters,
    accountTypesCurrentSort: itemsCurrentSort as FetchAccountTypesSort,
    fetchAccountTypes: fetchItems,
    paginateAccountTypes: paginateItems,
    changeAccountTypesPageSize: changeItemsPageSize,
    filterAccountTypes: filterItems,
    changeAccountTypesFilters: changeItemsFilters,
    clearAccountTypesFilters: clearItemsFilters,
    sortAccountTypes: sortItems,
    prefetchAccountTypes: prefetchItems,
    prefetchAccountType: prefetchItem
  };
}

export default usePaginatedAccountTypes;
