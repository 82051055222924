import { usePaginatedItems } from '../../../common/hooks/usePaginatedItems';

import {
  FetchEducationsQuery,
  FetchEducationsCacheKey,
  FetchEducationsTotalCount,
  FetchEducationsPage,
  FetchEducationsPageSize,
  FetchEducationsError,
  FetchEducationsErrorMessage,
  FetchEducationsIsLoading,
  FetchEducationsIsFetched,
  FetchEducationsIsPlaceholderData,
  FetchEducationsEnabled,
  FetchEducationsPrefetchNextPage,
  FetchEducationsCountType,
  FetchEducationsFilters,
  FetchEducationsSort,
  FetchEducationID,
  FetchEducationQuery,
  FetchEducationCacheKey
} from '../../educationsTypes';

import { EducationsBffRequests } from '../../EducationsBffRequests';

interface PaginatedEducationsBaseOptions<PaginatedEducationsResponseType> {
  cacheKey: FetchEducationsCacheKey;
  query: FetchEducationsQuery;
  countType?: FetchEducationsCountType;
  initialPage?: FetchEducationsPage;
  initialPageSize?: FetchEducationsPageSize;
  initialFilters?: FetchEducationsFilters;
  initialSort?: FetchEducationsSort;
  enabled?: FetchEducationsEnabled;
  prefetchNextPage?: FetchEducationsPrefetchNextPage;
  placeholderEducations?: PaginatedEducationsResponseType[];
  placeholderEducationsCount?: FetchEducationsTotalCount;
  placeholderData?: () => PaginatedEducationsResponse<PaginatedEducationsResponseType>;
}

interface PaginatedEducationsOptionsWithoutItem {
  fetchItemCacheKey?: never;
  itemQuery?: never;
}

interface PaginatedEducationsOptionsWithItem {
  fetchItemCacheKey: FetchEducationCacheKey;
  itemQuery: FetchEducationQuery;
}

type PaginatedEducationsOptions<PaginatedEducationsResponseType> =
  PaginatedEducationsBaseOptions<PaginatedEducationsResponseType> &
    (
      | PaginatedEducationsOptionsWithoutItem
      | PaginatedEducationsOptionsWithItem
    );

interface PaginatedEducationsResponse<PaginatedEducationsResponseType> {
  data: PaginatedEducationsResponseType[];
  totalCount: FetchEducationsTotalCount;
}

function usePaginatedEducations<PaginatedEducationsResponseType>({
  cacheKey,
  query,
  countType,
  initialPage,
  initialPageSize,
  initialFilters,
  initialSort,
  enabled,
  prefetchNextPage,
  placeholderEducations,
  placeholderEducationsCount,
  placeholderData,
  fetchItemCacheKey,
  itemQuery
}: PaginatedEducationsOptions<PaginatedEducationsResponseType>) {
  const {
    items,
    itemsTotalCount,
    itemsError,
    itemsErrorMessage,
    itemsIsFetched,
    itemsIsLoading,
    itemsIsPlaceholderData,
    itemsCurrentPage,
    itemsCurrentPageSize,
    itemsCurrentFilters,
    itemsCurrentSort,
    fetchItems,
    paginateItems,
    changeItemsPageSize,
    filterItems,
    changeItemsFilters,
    clearItemsFilters,
    sortItems,
    prefetchItems,
    prefetchItem
  } = usePaginatedItems<PaginatedEducationsResponseType>({
    cacheKey,
    initialPage,
    initialPageSize,
    initialFilters,
    initialSort,
    enabled,
    prefetchNextPage,
    placeholderItems: placeholderEducations,
    placeholderItemsCount: placeholderEducationsCount,
    placeholderData,
    queryFn: (params) =>
      EducationsBffRequests.fetchEducations<PaginatedEducationsResponseType>({
        query,
        countType,
        page: params.page as FetchEducationsPage,
        pageSize: params.pageSize as FetchEducationsPageSize,
        filters: params.filters as FetchEducationsFilters,
        sort: params.sort as FetchEducationsSort
      }),
    ...(itemQuery
      ? {
          fetchItemCacheKey,
          queryItemFn: (itemId) =>
            EducationsBffRequests.fetchEducation(itemId as FetchEducationID, {
              query: itemQuery
            })
        }
      : {})
  });

  return {
    educations: items as PaginatedEducationsResponseType[],
    educationsTotalCount: itemsTotalCount as FetchEducationsTotalCount,
    educationsError: itemsError as FetchEducationsError,
    educationsErrorMessage: itemsErrorMessage as FetchEducationsErrorMessage,
    educationsIsLoading: itemsIsLoading as FetchEducationsIsLoading,
    educationsIsFetched: itemsIsFetched as FetchEducationsIsFetched,
    educationsIsPlaceholderData:
      itemsIsPlaceholderData as FetchEducationsIsPlaceholderData,
    educationsCurrentPage: itemsCurrentPage as FetchEducationsPage,
    educationsCurrentPageSize: itemsCurrentPageSize as FetchEducationsPageSize,
    educationsCurrentFilters: itemsCurrentFilters as FetchEducationsFilters,
    educationsCurrentSort: itemsCurrentSort as FetchEducationsSort,
    fetchEducations: fetchItems,
    paginateEducations: paginateItems,
    changeEducationsPageSize: changeItemsPageSize,
    filterEducations: filterItems,
    changeEducationsFilters: changeItemsFilters,
    clearEducationsFilters: clearItemsFilters,
    sortEducations: sortItems,
    prefetchEducations: prefetchItems,
    prefetchEducation: prefetchItem
  };
}

export default usePaginatedEducations;
