import { Icon, IconProps } from '@chakra-ui/icon';

const CancelIcon = ({ w = '16px', h = '16px', ...props }: IconProps) => (
  <Icon viewBox="0 0 16 16" w={w} h={h} fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m9.08 8 3.24-3.24-1.12-1.12L8 6.84l-3.2-3.2-1.12 1.12L6.92 8l-3.24 3.24 1.12 1.12L8 9.16l3.2 3.2 1.12-1.12L9.08 8Z"
    />
  </Icon>
);
export default CancelIcon;
