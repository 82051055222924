import React from 'react';

import { I18nText, IsDisabled, IsLoading } from '../../../types';

import { Button } from '../../Button';
import { Text } from '../../Text';

interface PureSubmitButtonHelperProps {
  size?: 'small' | 'medium' | 'large';
  hierarchy?:
    | 'primary'
    | 'danger'
    | 'secondary'
    | 'tertiary'
    | 'link'
    | 'unstyled';
  isLoading: IsLoading;
  disabled?: IsDisabled;
  i18nText: I18nText;
  rightIcon?: JSX.Element;
  px?: number;
  w?: string | number;
}

function PureSubmitButtonHelper({
  size,
  hierarchy,
  isLoading,
  disabled = false as IsDisabled,
  i18nText,
  rightIcon,
  px,
  w
}: PureSubmitButtonHelperProps) {
  return (
    <Button
      size={size}
      isDisabled={isLoading || disabled}
      hierarchy={hierarchy}
      isLoading={isLoading}
      px={px}
      w={w}
      rightIcon={rightIcon}
      type="submit"
    >
      {isLoading ? (
        <>
          <Text opacity={0}>{i18nText}</Text>

          <Text
            top="0"
            h="100%"
            display="flex"
            position="absolute"
            alignItems="center"
          >
            &bull; &bull; &bull;
          </Text>
        </>
      ) : (
        i18nText
      )}
    </Button>
  );
}

export default PureSubmitButtonHelper;
