import React from 'react';
import {
  Avatar as ChakraAvatar,
  AvatarBadge,
  AvatarProps
} from '@chakra-ui/avatar';
import { Flex, Icon } from '@chakra-ui/react';

import { Link } from '../Link';
import { UserIcon } from '../../icons/UserIcon';

interface AvatarWithBadgeProps extends AvatarProps {
  badgeSize?: string;
}
interface LinkedInAvatarProps extends AvatarWithBadgeProps {
  linkedInUrl?: string;
}

const LinkedInAvatar = ({ linkedInUrl, ...props }: LinkedInAvatarProps) => {
  return (
    <>
      {linkedInUrl ? (
        <Link to={linkedInUrl} isExternal>
          <Avatar icon={<UserIcon w="100%" h="100%" />} {...props} />
        </Link>
      ) : (
        <ChakraAvatar icon={<UserIcon w="100%" h="100%" />} {...props} />
      )}
    </>
  );
};

const Avatar = ({ badgeSize = '1.25em', ...props }: AvatarWithBadgeProps) => (
  <ChakraAvatar {...props}>
    <AvatarBadge boxSize={badgeSize}></AvatarBadge>
    <Flex
      boxSize="0.9em"
      alignItems="center"
      justifyContent="center"
      borderRadius="full"
      overflow="hidden"
      position="absolute"
      bottom={0}
      right={0}
      transform="translate(15%, 15%)"
    >
      <LinkedInIcon width="100%" height="100%" />
    </Flex>
  </ChakraAvatar>
);

const LinkedInIcon = ({ width = '12px', height = '12px', ...props }) => (
  <Icon viewBox="0 0 12 12" w={width} h={height} fill="none" {...props}>
    <path
      d="M0 .88C0 .407.397.023.887.023h10.226c.49 0 .887.384.887.857v10.24c0 .472-.397.855-.887.855H.887c-.49 0-.887-.383-.887-.855V.88Z"
      fill="#069"
    />
    <path
      d="M4.362 9V4.952H3.076V9h1.286Zm-.643-4.601c.449 0 .728-.311.728-.7-.009-.397-.28-.699-.72-.699-.44 0-.727.302-.727.7 0 .388.28.699.71.699h.01Zm1.355 4.6H6.36V6.74a.96.96 0 0 1 .042-.328c.093-.242.305-.492.66-.492.466 0 .652.371.652.915V9H9V6.679c0-1.244-.634-1.822-1.48-1.822-.694 0-1 .405-1.168.682h.008v-.587H5.074c.017.38 0 4.048 0 4.048Z"
      fill="#fff"
    />
  </Icon>
);

export default LinkedInAvatar;
