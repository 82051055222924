import {
  FetchCitiesQuery,
  FetchCitiesCountType,
  FetchCitiesPage,
  FetchCitiesPageSize,
  FetchCitiesFilters,
  FetchCitiesSort,
  FetchCitiesIndexQueryName,
  FetchCitiesIndexCountType,
  FetchCitiesIndexPage,
  FetchCitiesIndexPageSize,
  FetchCitiesIndexFilters,
  FetchCitiesIndexSort,
  FetchCityQuery,
  FetchCityIndexQueryName,
  CreateCityError,
  UpdateCityError,
  DeleteCityError,
  CityId,
  CityNanoId,
  CityName
} from './citiesTypes';

import { BaseBffRequests } from '../BaseBffRequests';

import { CitiesBffRoutes } from './CitiesBffRoutes';

interface FetchCitiesRequestParams {
  query: FetchCitiesQuery;
  countType?: FetchCitiesCountType;
  page?: FetchCitiesPage;
  pageSize?: FetchCitiesPageSize;
  filters?: FetchCitiesFilters;
  sort?: FetchCitiesSort;
}

interface FetchCitiesIndexRequestParams {
  queryName: FetchCitiesIndexQueryName;
  countType?: FetchCitiesIndexCountType;
  page?: FetchCitiesIndexPage;
  pageSize?: FetchCitiesIndexPageSize;
  filters?: FetchCitiesIndexFilters;
  sort?: FetchCitiesIndexSort;
}

interface FetchCityRequestParams {
  query: FetchCityQuery;
}

interface FetchCityIndexRequestParams {
  queryName: FetchCityIndexQueryName;
}

export interface CityRequestData {
  name?: CityName;
}

export interface CreateCityDefaultRequestData extends CityRequestData {
  id?: CityId;
  nanoId: CityNanoId;
}

export type CreateCityRequestData = CreateCityDefaultRequestData;

export type UpdateCityRequestData = CityRequestData;

export interface DeleteCityRequestData {
  cityNanoId: CityNanoId;
}

export interface CreateCityErrorResponse {
  message: CreateCityError;
}

export interface UpdateCityErrorResponse {
  message: UpdateCityError;
}

export interface DeleteCityErrorResponse {
  message: DeleteCityError;
}

export class CitiesBffRequests extends BaseBffRequests {
  static fetchCities<T>(params: FetchCitiesRequestParams) {
    return this.fetchItems<T>(CitiesBffRoutes.fetchCitiesRoute(), params);
  }

  static fetchCitiesIndex<T>(params: FetchCitiesIndexRequestParams) {
    return this.fetchItemsIndex<T>(
      CitiesBffRoutes.fetchCitiesIndexRoute(),
      params
    );
  }

  static fetchCity<T>(cityId: CityId, params: FetchCityRequestParams) {
    return this.fetchItem<T>(CitiesBffRoutes.fetchCityRoute(cityId), params);
  }

  static fetchCityIndex<T>(
    cityNanoId: CityNanoId,
    params: FetchCityIndexRequestParams
  ) {
    return this.fetchItemIndex<T>(
      CitiesBffRoutes.fetchCityIndexRoute(cityNanoId),
      params
    );
  }

  static fetchCityByNanoId<T>(
    cityNanoId: CityNanoId,
    params: FetchCityRequestParams
  ) {
    return this.fetchItemByNanoId<T>(
      CitiesBffRoutes.fetchCityByNanoIdRoute(cityNanoId),
      params
    );
  }

  static createCity<T>(createCityData: CreateCityRequestData) {
    return this.createItem<T, CreateCityRequestData>(
      CitiesBffRoutes.createCityRoute(),
      createCityData
    );
  }

  static async updateCity<T>(
    updateCityNanoId: CityNanoId,
    updateCityData: UpdateCityRequestData
  ) {
    return this.updateItem<T, UpdateCityRequestData>(
      CitiesBffRoutes.updateCityRoute(updateCityNanoId),
      updateCityData
    );
  }

  static async deleteCity<T>(deleteCityNanoId: CityNanoId) {
    return this.deleteItem<T>(
      CitiesBffRoutes.deleteCityRoute(deleteCityNanoId)
    );
  }
}
