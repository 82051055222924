import { SystemStyleObject } from '@chakra-ui/system';

const size: Record<string, SystemStyleObject> = {
  large: {
    px: 4,
    h: 12,
    borderRadius: 4
  },

  medium: {
    px: 4,
    h: 10,
    fontSize: 'sm',
    borderRadius: 4
  },

  small: {
    px: 4,
    h: 8,
    borderRadius: 4
  }
};

const sizes = {
  lg: size.large,
  md: size.medium,
  sm: size.small
};

export const Textarea = {
  sizes
};
