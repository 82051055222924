import { Icon, IconProps } from '@chakra-ui/icon';

const QualificationsIcon = ({
  w = '16px',
  h = '16px',
  ...props
}: IconProps) => (
  <Icon viewBox="0 0 16 16" w={w} h={h} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.688 12.656l1.743-.26.91 1.511c.109.181.304.29.514.29h.036a.599.599 0 00.511-.355l1.452-3.25A5.389 5.389 0 013.667 8.41l-1.61 3.396a.599.599 0 00.63.85zM8.148 10.592l1.453 3.251a.599.599 0 001.06.065l.91-1.51 1.744.259a.6.6 0 00.63-.85l-1.61-3.397a5.388 5.388 0 01-4.187 2.182zM7.996 4a1.2 1.2 0 11.001 2.398 1.2 1.2 0 010-2.398zm0 5.397a4.203 4.203 0 004.198-4.198A4.203 4.203 0 007.995 1a4.204 4.204 0 00-4.198 4.199 4.204 4.204 0 004.198 4.198z"
      fill="currentColor"
    />
  </Icon>
);
export default QualificationsIcon;
