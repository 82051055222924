import {
  ContactItemBffID,
  ContactItemBffNanoID,
  FetchContactsBffURL,
  FetchContactBffURL,
  FetchContactByNanoIdBffURL,
  CreateContactBffURL,
  UpdateContactBffURL,
  DeleteContactBffURL,
  SendLeadInvitationContactBffURL,
  ToggleRemovedContactBffURL,
  SendLeadInvitationsContactBffURL
} from './contactsTypes';

import { BaseBffRoutes } from '../BaseBffRoutes';

export class ContactsBffRoutes extends BaseBffRoutes {
  protected static route = 'contacts';

  static fetchContactsRoute() {
    return this.fetchItemsRoute<FetchContactsBffURL>();
  }

  static fetchContactsIndexRoute() {
    return this.fetchItemsIndexRoute<FetchContactsBffURL>();
  }

  static fetchContactRoute(contactItemBffID: ContactItemBffID) {
    return this.fetchItemRoute<FetchContactBffURL>(contactItemBffID);
  }

  static fetchContactIndexRoute(contactItemBffNanoID: ContactItemBffNanoID) {
    return this.fetchItemIndexRoute<FetchContactBffURL>(contactItemBffNanoID);
  }

  static fetchContactByNanoIdRoute(contactItemBffNanoID: ContactItemBffNanoID) {
    return this.fetchItemByNanoIdRoute<FetchContactByNanoIdBffURL>(
      contactItemBffNanoID
    );
  }

  static createContactRoute() {
    return this.createItemRoute<CreateContactBffURL>();
  }

  static updateContactRoute(
    contactItemBffNanoID: ContactItemBffID | ContactItemBffNanoID
  ) {
    return this.updateItemRoute<UpdateContactBffURL>(contactItemBffNanoID);
  }

  static deleteContactRoute(contactItemBffNanoID: ContactItemBffNanoID) {
    return this.deleteItemRoute<DeleteContactBffURL>(contactItemBffNanoID);
  }

  static sendLeadInvitationContactRoute(
    contactItemBffNanoID: ContactItemBffNanoID
  ) {
    return this.postItemRoute<SendLeadInvitationContactBffURL>(
      'send-lead-invitation',
      contactItemBffNanoID
    );
  }

  static sendLeadInvitationsContactRoute() {
    return this.postItemsRoute<SendLeadInvitationsContactBffURL>(
      'send-lead-invitations'
    );
  }

  static toggleRemovedContactRoute(contactItemBffNanoID: ContactItemBffNanoID) {
    return this.postItemRoute<ToggleRemovedContactBffURL>(
      'toggle-removed',
      contactItemBffNanoID
    );
  }
}
