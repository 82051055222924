import { useQuery } from 'react-query';

import {
  ItemID,
  ItemUUID,
  ItemNanoId,
  FetchItemCacheKey,
  FetchItemEnabled,
  FetchItemError,
  FetchItemErrorMessage,
  FetchItemIsLoading,
  FetchItemIsFetched,
  FetchItemIsRefetching,
  FetchItemIsPlaceholderData,
  FetchItemIndexCacheKey,
  FetchItemIndexEnabled,
  FetchItemIndexError,
  FetchItemIndexErrorMessage
} from '../../../../types';

import { parseRequestError } from '../../../../utils/parseRequestError';

interface FetchItemOptions<FetchItemResponseType> {
  itemId: ItemID | ItemNanoId | ItemUUID;
  cacheKey: FetchItemCacheKey | FetchItemIndexCacheKey;
  enabled?: FetchItemEnabled | FetchItemIndexEnabled;
  placeholderItem?: FetchItemResponseType;
  placeholderData?: () => FetchItemResponseType;
  cacheTime?: number;
  queryFn: () => Promise<FetchItemResponseType | null>;
  onSettled?: (data: FetchItemResponseType | null | undefined) => void;
  onSuccess?: (data: FetchItemResponseType | null) => void;
}

function useFetchItem<
  FetchItemResponseType,
  FetchItemErrorType extends FetchItemError | FetchItemIndexError
>({
  cacheKey,
  itemId,
  enabled,
  placeholderItem,
  placeholderData,
  cacheTime,
  queryFn,
  onSettled,
  onSuccess
}: FetchItemOptions<FetchItemResponseType>) {
  const {
    data,
    error,
    isFetched,
    isRefetching,
    isLoading,
    isPlaceholderData,
    refetch
  } = useQuery<FetchItemResponseType | null, FetchItemErrorType>(
    [cacheKey, itemId],
    queryFn,
    {
      enabled,
      placeholderData: placeholderData || placeholderItem,
      cacheTime,
      onSettled,
      onSuccess
    }
  );

  return {
    item: data,
    itemError: error,
    itemErrorMessage: parseRequestError(error) as
      | FetchItemErrorMessage
      | FetchItemIndexErrorMessage,
    itemIsFetched: isFetched as FetchItemIsFetched,
    itemIsRefetching: isRefetching as FetchItemIsRefetching,
    itemIsLoading: isLoading as FetchItemIsLoading,
    itemIsPlaceholderData: isPlaceholderData as FetchItemIsPlaceholderData,
    refetchItem: () => refetch()
  };
}

export default useFetchItem;
