import { Select as ReactSelect } from 'chakra-react-select';
import size from 'lodash/size';

import { ItemNanoId } from '../../../../types';

import { Image, ImagesUrl } from '../../../../main/images/ImagesUrl';

import { FormatOptionLabel } from '../FormatOptionLabel';

interface StaticSelectProps {
  currentItem: {
    nanoId: ItemNanoId;
    name: string;
    image: Image;
  } | null;
  options?: {
    nanoId: ItemNanoId;
    name: string;
    image: Image;
  }[];
  onChange?: (
    value: {
      nanoId: ItemNanoId;
      label: string;
    } | null
  ) => void;
  isLoading?: boolean;
  isDisabled?: boolean;
}

function StaticSelect({
  currentItem,
  options,
  isLoading = false,
  isDisabled = false,
  onChange
}: StaticSelectProps) {
  return (
    <ReactSelect
      id="header-company-list"
      instanceId={'header-company-list'}
      value={
        currentItem
          ? {
              nanoId: currentItem.nanoId,
              label: currentItem.name,
              image: ImagesUrl.file(currentItem.image) || undefined
            }
          : undefined
      }
      options={(options || []).map((option) => ({
        nanoId: option.nanoId,
        label: option.name,
        image: ImagesUrl.file(option.image) || undefined
      }))}
      isLoading={isLoading}
      isDisabled={isDisabled || size(options) <= 1}
      onChange={onChange}
      formatOptionLabel={FormatOptionLabel}
      focusBorderColor="primary.500"
      selectedOptionColor="primary"
      chakraStyles={{
        dropdownIndicator: (provided) => ({
          ...provided,
          bg: 'transparent',
          w: 6
        }),
        option: (provided) => ({
          ...provided,
          h: 10,
          px: 4
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          display: 'none'
        })
      }}
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary: '#0580A4'
        }
      })}
    />
  );
}

export default StaticSelect;
