import {
  FetchLikedJobCandidateCacheKey,
  FetchLikedJobCandidatesCacheKey
} from '../../likedJobCandidatesTypes';

import { useCreateItem } from '../../../common/hooks/useCreateItem';

import {
  CreateLikedJobCandidateRequestData,
  CreateLikedJobCandidateErrorResponse
} from '../../LikedJobCandidatesRequests';
import { LikedJobCandidatesBffRequests } from '../../LikedJobCandidatesBffRequests';

interface CreateLikedJobCandidateOptions {
  cacheKeys?: (
    | FetchLikedJobCandidatesCacheKey
    | FetchLikedJobCandidateCacheKey
  )[];
}

function useCreateLikedJobCandidate({
  cacheKeys = []
}: CreateLikedJobCandidateOptions) {
  const {
    createItemData,
    createItemError,
    createItemErrorMessage,
    createItemIsLoading,
    createItem,
    createItemReset
  } = useCreateItem<
    CreateLikedJobCandidateRequestData,
    CreateLikedJobCandidateErrorResponse
  >({
    mutationFn: (queryInput) =>
      LikedJobCandidatesBffRequests.createLikedJobCandidate(queryInput),
    cacheKeys
  });

  return {
    createLikedJobCandidateData: createItemData,
    createLikedJobCandidateError: createItemError,
    createLikedJobCandidateErrorMessage: createItemErrorMessage,
    createLikedJobCandidateIsLoading: createItemIsLoading,
    createLikedJobCandidate: createItem,
    createLikedJobCandidateReset: createItemReset
  };
}

export default useCreateLikedJobCandidate;
