import snakeCase from 'lodash/snakeCase';

import {
  FetchAccountTypesQuery,
  AccountTypeFields,
  AccountTypeId,
  AccountTypeNanoId,
  AccountTypeName,
  AccountTypeScope,
  AccountTypeUser,
  AccountTypeCreatedAt
} from '../accountTypesTypes';

export interface FetchAccountTypesResponse {
  [AccountTypeFields.ID]: AccountTypeId;
  [AccountTypeFields.NANO_ID]: AccountTypeNanoId;
  [AccountTypeFields.NAME]: AccountTypeName;
  [AccountTypeFields.SCOPE]: AccountTypeScope;
  [AccountTypeFields.USER]: AccountTypeUser;
  [AccountTypeFields.CREATED_AT]: AccountTypeCreatedAt;
}

export const fetchAccountTypesColumns = [
  AccountTypeFields.ID,
  AccountTypeFields.NANO_ID,
  AccountTypeFields.NAME,
  AccountTypeFields.SCOPE,
  AccountTypeFields.CREATED_AT
];

const fetchAccountTypesSBColumns = fetchAccountTypesColumns.map(
  (fetchAccountTypesColumn) => snakeCase(fetchAccountTypesColumn)
);

export const fetchAccountTypesQuery = `
  ${fetchAccountTypesSBColumns.join(',')},
  user:user_id(id,nano_id,name,image:image_id(id,nano_id,name,file,key,created_at))
` as FetchAccountTypesQuery;
