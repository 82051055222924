import { ReactNode } from 'react';
import {
  Input as ChakraInput,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement
} from '@chakra-ui/input';
import { forwardRef } from '@chakra-ui/react';

import { SearchIcon } from '../../icons/SearchIcon';

type SearchProps = InputProps & {
  rightElement?: ReactNode;
  isClickableRightElement?: boolean;
};

const Search = forwardRef(
  ({ rightElement, isClickableRightElement, ...props }: SearchProps, ref) => (
    <InputGroup>
      <InputLeftElement
        pointerEvents="none"
        h="36px"
        w="36px"
        justifyContent="flex-end"
      >
        <SearchIcon />
      </InputLeftElement>

      <ChakraInput
        type="search"
        h="36px"
        borderColor="gray.200"
        focusBorderColor="primary.500"
        placeholder="Search"
        borderRadius="30"
        pl="44px"
        w="100%"
        {...props}
        ref={ref}
      />

      {rightElement && (
        <InputRightElement
          pointerEvents={isClickableRightElement ? 'unset' : 'none'}
          h="36px"
          w="36px"
          alignItems="center"
        >
          {rightElement}
        </InputRightElement>
      )}
    </InputGroup>
  )
);

Search.displayName = 'InputField';

export default Search;
