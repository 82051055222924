import {
  FieldBrand,
  ItemID,
  ItemIDs,
  ItemUUID,
  ItemNanoId,
  ItemCreatedAt,
  MayBe
} from '../../../types';

import {
  CountryId,
  CountryNanoId,
  CountryName
} from '../../countries/countriesTypes';

import {
  UserId,
  UserNanoId,
  UserName,
  UserFullName,
  UserEmail,
  UserImage,
  UserRoles
} from '../../users/usersTypes';

export type StateId = FieldBrand<ItemID, 'StateId'>;
export type MayBeStateId = MayBe<StateId>;
export type StateIds = FieldBrand<ItemIDs, 'StateIds'>;
export type StateUuid = FieldBrand<ItemUUID, 'StateUuid'>;
export type MayBeStateUuid = MayBe<StateUuid>;
export type StateNanoId = FieldBrand<ItemNanoId, 'StateNanoId'>;
export type MayBeStateNanoId = MayBe<StateNanoId>;
export type StateName = FieldBrand<string, 'StateName'>;
export type StateLabel = FieldBrand<string, 'StateLabel'>;
export type StateIsoCode = FieldBrand<string, 'StateIsoCode'>;
export type StateUserId = FieldBrand<UserId, 'StateUserId'>;
export type MayBeStateUserId = MayBe<StateUserId>;
export type StateUser = FieldBrand<
  {
    id: UserId;
    nanoId: UserNanoId;
    name: UserName;
    fullName: UserFullName;
    email: UserEmail;
    image: UserImage;
    roles: UserRoles;
  },
  'StateUser'
>;
export type MayBeStateUser = MayBe<StateUser>;
export type StateCountryId = FieldBrand<CountryId, 'StateCountryId'>;
export type MayBeStateCountryId = MayBe<StateCountryId>;
export type StateCountry = FieldBrand<
  {
    id: CountryId;
    nanoId: CountryNanoId;
    name: CountryName;
  },
  'CountryState'
>;
export type MayBeStateCountry = MayBe<StateCountry>;
export type StateCreatedAt = FieldBrand<ItemCreatedAt, 'StateCreatedAt'>;

export const enum StateFields {
  ID = 'id',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  NAME = 'name',
  LABEL = 'label',
  COUNTRY_ID = 'countryId',
  COUNTRY = 'country',
  CREATED_AT = 'createdAt'
}
