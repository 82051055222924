import {
  FetchCompanyMembersCacheKey,
  FetchCompanyMemberCacheKey
} from './companyMembersTypes';

export class CompanyMembersCache {
  static indexCacheKey() {
    return 'companyMembers' as FetchCompanyMembersCacheKey;
  }

  static showCacheKey() {
    return 'companyMember' as FetchCompanyMemberCacheKey;
  }
}
