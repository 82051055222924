import {
  FetchSkillLicensesQuery,
  FetchSkillLicensesCountType,
  FetchSkillLicensesPage,
  FetchSkillLicensesPageSize,
  FetchSkillLicensesFilters,
  FetchSkillLicensesSort,
  FetchSkillLicensesIndexQueryName,
  FetchSkillLicensesIndexCountType,
  FetchSkillLicensesIndexPage,
  FetchSkillLicensesIndexPageSize,
  FetchSkillLicensesIndexFilters,
  FetchSkillLicensesIndexSort,
  FetchSkillLicenseQuery,
  FetchSkillLicenseIndexQueryName,
  CreateSkillLicenseError,
  UpdateSkillLicenseError,
  DeleteSkillLicenseError,
  SkillLicenseId,
  SkillLicenseNanoId,
  SkillLicenseName,
  SkillLicenseUserId
} from './skillLicensesTypes';

import { BaseBffRequests } from '../BaseBffRequests';

import { SkillLicensesBffRoutes } from './SkillLicensesBffRoutes';

interface FetchSkillLicensesRequestParams {
  query: FetchSkillLicensesQuery;
  countType?: FetchSkillLicensesCountType;
  page?: FetchSkillLicensesPage;
  pageSize?: FetchSkillLicensesPageSize;
  filters?: FetchSkillLicensesFilters;
  sort?: FetchSkillLicensesSort;
}

interface FetchSkillLicensesIndexRequestParams {
  queryName: FetchSkillLicensesIndexQueryName;
  countType?: FetchSkillLicensesIndexCountType;
  page?: FetchSkillLicensesIndexPage;
  pageSize?: FetchSkillLicensesIndexPageSize;
  filters?: FetchSkillLicensesIndexFilters;
  sort?: FetchSkillLicensesIndexSort;
}

interface FetchSkillLicenseRequestParams {
  query: FetchSkillLicenseQuery;
}

interface FetchSkillLicenseIndexRequestParams {
  queryName: FetchSkillLicenseIndexQueryName;
}

export interface SkillLicenseRequestData {
  name?: SkillLicenseName;
}

export interface CreateSkillLicenseDefaultRequestData
  extends SkillLicenseRequestData {
  id?: SkillLicenseId;
  nanoId: SkillLicenseNanoId;
  userId: SkillLicenseUserId;
}

export type CreateSkillLicenseRequestData =
  CreateSkillLicenseDefaultRequestData;

export type UpdateSkillLicenseRequestData = SkillLicenseRequestData;

export interface DeleteSkillLicenseRequestData {
  skillLicenseNanoId: SkillLicenseNanoId;
}

export interface CreateSkillLicenseErrorResponse {
  message: CreateSkillLicenseError;
}

export interface UpdateSkillLicenseErrorResponse {
  message: UpdateSkillLicenseError;
}

export interface DeleteSkillLicenseErrorResponse {
  message: DeleteSkillLicenseError;
}

export class SkillLicensesBffRequests extends BaseBffRequests {
  static fetchSkillLicenses<T>(params: FetchSkillLicensesRequestParams) {
    return this.fetchItems<T>(
      SkillLicensesBffRoutes.fetchSkillLicensesRoute(),
      params
    );
  }

  static fetchSkillLicensesIndex<T>(
    params: FetchSkillLicensesIndexRequestParams
  ) {
    return this.fetchItemsIndex<T>(
      SkillLicensesBffRoutes.fetchSkillLicensesIndexRoute(),
      params
    );
  }

  static fetchSkillLicense<T>(
    skillLicenseId: SkillLicenseId,
    params: FetchSkillLicenseRequestParams
  ) {
    return this.fetchItem<T>(
      SkillLicensesBffRoutes.fetchSkillLicenseRoute(skillLicenseId),
      params
    );
  }

  static fetchSkillLicenseIndex<T>(
    skillLicenseNanoId: SkillLicenseNanoId,
    params: FetchSkillLicenseIndexRequestParams
  ) {
    return this.fetchItemIndex<T>(
      SkillLicensesBffRoutes.fetchSkillLicenseIndexRoute(skillLicenseNanoId),
      params
    );
  }

  static fetchSkillLicenseByNanoId<T>(
    skillLicenseNanoId: SkillLicenseNanoId,
    params: FetchSkillLicenseRequestParams
  ) {
    return this.fetchItemByNanoId<T>(
      SkillLicensesBffRoutes.fetchSkillLicenseByNanoIdRoute(skillLicenseNanoId),
      params
    );
  }

  static createSkillLicense<T>(
    createSkillLicenseData: CreateSkillLicenseRequestData
  ) {
    return this.createItem<T, CreateSkillLicenseRequestData>(
      SkillLicensesBffRoutes.createSkillLicenseRoute(),
      createSkillLicenseData
    );
  }

  static async updateSkillLicense<T>(
    updateSkillLicenseNanoId: SkillLicenseNanoId,
    updateSkillLicenseData: UpdateSkillLicenseRequestData
  ) {
    return this.updateItem<T, UpdateSkillLicenseRequestData>(
      SkillLicensesBffRoutes.updateSkillLicenseRoute(updateSkillLicenseNanoId),
      updateSkillLicenseData
    );
  }

  static async deleteSkillLicense<T>(
    deleteSkillLicenseNanoId: SkillLicenseNanoId
  ) {
    return this.deleteItem<T>(
      SkillLicensesBffRoutes.deleteSkillLicenseRoute(deleteSkillLicenseNanoId)
    );
  }
}
