import { Flex, Tag } from '@chakra-ui/react';
import lowerCase from 'lodash/lowerCase';
import lodashSize from 'lodash/size';
import { Fragment, useMemo } from 'react';

import { RelativeDateHelper } from '../../../../../../helpers/RelativeDateHelper';

import { DateTimeType, DateUtils } from '../../../../../../utils/DateUtils';

import { Text } from '../../../../../../helpers/Text';
import { ClockOutlineIcon } from '../../../../../../icons/ClockOutlineIcon';
import {
  CandidateStatusBadgeTaskTagProps,
  CandidateTaskStatusBadgeProps
} from './CandidateTaskStatusBadge.types';
import { JobCandidateWorkflowStageTasksList } from '../../components/ViewJobCandidatesTableRow/components/JobCandidateWorkflowStageTasksList';

function CandidateTaskStatusBadge({
  jobCandidate,
  pendingWorkflowStageTasks,
  workflowStage,
  completedWorkflowStageTasksIds,
  size,
  isFullCard,
  withJobCandidateWorkflowStageTasksList,
  allWorkflowStageTasks,
  completedWorkflowStageTasks,
  job
}: CandidateTaskStatusBadgeProps) {
  const dueDay = DateUtils.calendarDay(jobCandidate.dueDate) as DateTimeType;

  const scheduledDay = DateUtils.calendarDay(
    jobCandidate.workflowStageTaskScheduledAt
  ) as DateTimeType;

  const today = DateUtils.startOfToday();

  const lastViewedDay = DateUtils.calendarDay(
    jobCandidate.viewedAt
  ) as DateTimeType;

  const newTaskDay = DateUtils.calendarDay(
    jobCandidate.newWorkflowStageTaskAt
  ) as DateTimeType;

  const dateToCompare = newTaskDay > lastViewedDay ? newTaskDay : lastViewedDay;

  const dateDifference =
    today && dateToCompare ? today.diff(dateToCompare, 'days')?.toObject() : {};

  const defaultStatus = useMemo(() => {
    const workflowStageName = lowerCase(workflowStage?.name);

    switch (workflowStageName) {
      case 'sourcing':
        return jobCandidate.viewedAt ? 'Reviewing' : 'New Task';

      case 'recruiting':
        return 'Recruit';

      case 'phone screen':
        return 'Screen';

      case 'interview':
        return 'Schedule';

      default:
        return 'New Task';
    }
  }, [workflowStage?.name, jobCandidate.viewedAt]);

  return (
    <Flex
      alignItems="flex-start"
      gap={isFullCard ? 4 : 1}
      mt={isFullCard ? 1 : 0}
      flex={1}
    >
      <Flex gap={1} mr={1} alignItems="center">
        <ClockOutlineIcon w={size === 'sm' ? 3 : 3.5} stroke="gray.500" />

        <Text
          textStyle={size === 'sm' ? 'body2Regular' : 'body1Regular'}
          color="gray.600"
        >
          {dateDifference.days}d
        </Text>
      </Flex>

      <Flex flexDir="column" gap={1}>
        {jobCandidate.disqualifiedAt ? (
          <TaskTag bg="red.500" size={size}>
            Disqualified
          </TaskTag>
        ) : lowerCase(workflowStage?.name) === 'hired' ? (
          <TaskTag bg="green.900" size={size}>
            Hired
          </TaskTag>
        ) : lodashSize(pendingWorkflowStageTasks) === 0 ||
          lodashSize(completedWorkflowStageTasksIds) ===
            lodashSize(workflowStage?.workflowStageTasks) ? (
          <TaskTag bg="#2C273D" size={size}>
            nxmoov
          </TaskTag>
        ) : jobCandidate.workflowStageTaskScheduledAt ? (
          <Fragment>
            {today.equals(scheduledDay) ? (
              <TaskTag bg="green.900" size={size}>
                In progress
              </TaskTag>
            ) : today > dueDay && jobCandidate.viewedAt ? (
              <TaskTag bg="red.500" size={size}>
                Over due
              </TaskTag>
            ) : today > dueDay && !jobCandidate.viewedAt ? (
              <TaskTag bg="#EEA401" size={size}>
                Follow up
              </TaskTag>
            ) : today.equals(dueDay) ? (
              <TaskTag bg="purple.400" size={size}>
                Due{' '}
                <RelativeDateHelper
                  date={jobCandidate.dueDate as string}
                  calendarDate
                />
              </TaskTag>
            ) : (
              <TaskTag bg="#EEA401" size={size}>
                Due{' '}
                <RelativeDateHelper
                  date={jobCandidate.dueDate as string}
                  calendarDate
                />
              </TaskTag>
            )}
          </Fragment>
        ) : jobCandidate.newWorkflowStageTaskAt ? (
          <Fragment>
            {today > newTaskDay && jobCandidate.viewedAt ? (
              <TaskTag
                bg={
                  (dateDifference?.days as number) >= 2 ? 'red.500' : '#EEA401'
                }
                size={size}
              >
                Stagnant
              </TaskTag>
            ) : (
              <TaskTag bg="#287CC9" size={size}>
                {defaultStatus}
              </TaskTag>
            )}
          </Fragment>
        ) : (
          <TaskTag bg="#287CC9" size={size}>
            {defaultStatus}
          </TaskTag>
        )}

        {withJobCandidateWorkflowStageTasksList ? (
          <JobCandidateWorkflowStageTasksList
            jobCandidate={jobCandidate}
            allWorkflowStageTasks={allWorkflowStageTasks}
            completedWorkflowStageTasks={completedWorkflowStageTasks}
            pendingWorkflowStageTasks={pendingWorkflowStageTasks}
            job={job}
            size={size}
            withoutDropdownIcon
          />
        ) : null}
      </Flex>
    </Flex>
  );
}

const TaskTag = ({ bg, size, children }: CandidateStatusBadgeTaskTagProps) => {
  return (
    <Tag
      bg={bg}
      color="white"
      borderRadius="base"
      size={size}
      width="fit-content"
    >
      {children}
    </Tag>
  );
};

export default CandidateTaskStatusBadge;
