import {
  MayBe,
  FieldBrand,
  ItemID,
  ItemIDs,
  ItemUUID,
  ItemNanoId,
  ItemCreatedAt
} from '../../../types';

import {
  UserId,
  UserNanoId,
  UserName,
  UserImage
} from '../../users/usersTypes';

export type IndustryId = FieldBrand<ItemID, 'IndustryId'>;
export type IndustryIds = FieldBrand<ItemIDs, 'IndustryIds'>;
export type IndustryUuid = FieldBrand<ItemUUID, 'IndustryUuid'>;
export type MayBeIndustryUuid = MayBe<IndustryUuid>;
export type IndustryNanoId = FieldBrand<ItemNanoId, 'IndustryNanoId'>;
export type MayBeIndustryNanoId = MayBe<IndustryNanoId>;
export type IndustryName = FieldBrand<string, 'IndustryName'>;
export type IndustryUserId = FieldBrand<UserId, 'IndustryUserId'>;
export type IndustryUser = FieldBrand<
  {
    id: UserId;
    nanoId: UserNanoId;
    name: UserName;
    image: UserImage;
  },
  'IndustryUser'
>;
export type IndustryCreatedAt = FieldBrand<ItemCreatedAt, 'IndustryCreatedAt'>;

export const enum IndustryFields {
  ID = 'id',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  NAME = 'name',
  USER_ID = 'userId',
  USER = 'user',
  CREATED_AT = 'createdAt'
}
