import {
  Box,
  ButtonGroup,
  Collapse,
  Flex,
  Stack,
  useDisclosure
} from '@chakra-ui/react';
import { useCurrentUser } from '../../../../../../../../../../../auth/hooks/useAuth';
import { AvatarHelper } from '../../../../../../../../../../../helpers/AvatarHelper';
import { Button } from '../../../../../../../../../../../helpers/Button';
import { Text } from '../../../../../../../../../../../helpers/Text';
import { AlarmIcon } from '../../../../../../../../../../../icons/AlarmIcon';
import {
  ViewJobCandidateModalPhoneSidebarCallbackDropdown,
  ViewJobCandidateModalPhoneSidebarTextArea
} from '../../components';

const ViewJobCandidateModalPhoneSidebarAddReminderHelper = ({
  onDiscard,
  onSave
}: {
  onDiscard?: () => void;
  onSave: () => void;
}) => {
  const currentUser = useCurrentUser();

  const { onToggle: onToggleComment, isOpen: isOpenComment } = useDisclosure();

  return (
    <Stack spacing={4}>
      <Flex justifyContent="space-between">
        <Flex alignItems="center" gap={2}>
          <AvatarHelper image={currentUser.image} />

          <Text textStyle="body1Medium" noOfLines={1}>
            {currentUser.name}
          </Text>
        </Flex>

        <Flex
          w={9}
          h={9}
          pt="9px"
          pb="11px"
          bg="green.100"
          alignItems="center"
          justifyContent="center"
          borderRadius="full"
        >
          <AlarmIcon fill="green.400" w={4} h={4} />
        </Flex>
      </Flex>

      <Box>
        <Flex alignItems="center" gap={2} justifyContent="space-between">
          <Text textStyle="body1Medium" flex={1}>
            Callback:
          </Text>

          <Flex alignItems="center" gap={1}>
            <ViewJobCandidateModalPhoneSidebarCallbackDropdown value="Tomorrow" />
          </Flex>
        </Flex>

        <Collapse in={isOpenComment} unmountOnExit>
          <Stack pt={4}>
            <ViewJobCandidateModalPhoneSidebarTextArea />
          </Stack>
        </Collapse>
      </Box>

      <ButtonGroup justifyContent="flex-end">
        <Button
          size="small"
          hierarchy="link"
          mr="auto"
          onClick={onToggleComment}
        >
          {!isOpenComment ? 'Add comment' : 'Close comment'}
        </Button>

        {!!onDiscard && (
          <Button size="small" hierarchy="link" onClick={onDiscard}>
            Cancel
          </Button>
        )}

        <Button size="small" onClick={onSave}>
          {isOpenComment ? 'Comment' : 'Save'}
        </Button>
      </ButtonGroup>
    </Stack>
  );
};

export default ViewJobCandidateModalPhoneSidebarAddReminderHelper;
