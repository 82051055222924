import {
  FetchWorkflowStageTasksTable,
  FetchWorkflowStageTasksQuery,
  FetchWorkflowStageTasksCountType,
  FetchWorkflowStageTasksPage,
  FetchWorkflowStageTasksPageSize,
  FetchWorkflowStageTasksFilters,
  FetchWorkflowStageTasksSort,
  FetchWorkflowStageTaskTable,
  FetchWorkflowStageTaskQuery,
  FetchWorkflowStageTaskID,
  FetchWorkflowStageTaskNanoID,
  CreateWorkflowStageTaskTable,
  CreateWorkflowStageTaskError,
  UpdateWorkflowStageTaskTable,
  UpdateWorkflowStageTaskID,
  UpdateWorkflowStageTaskError,
  DeleteWorkflowStageTaskTable,
  DeleteWorkflowStageTaskID,
  DeleteWorkflowStageTaskError,
  WorkflowStageTaskNanoId,
  WorkflowStageTaskName,
  WorkflowStageTaskArrangement,
  WorkflowStageTaskUserId,
  WorkflowStageTaskId,
  UpdateWorkflowStageTaskNanoId,
  WorkflowStageTaskWorkflowStageId,
  WorkflowStageTaskDueDate,
  WorkflowStageTaskApplication,
  WorkflowStageTaskAutomation
} from './workflowStageTasksTypes';

import { BaseRequests } from '../BaseRequests';

export interface FetchWorkflowStageTasksOptions {
  query: FetchWorkflowStageTasksQuery;
  countType?: FetchWorkflowStageTasksCountType;
  page?: FetchWorkflowStageTasksPage;
  pageSize?: FetchWorkflowStageTasksPageSize;
  filters?: FetchWorkflowStageTasksFilters;
  sort?: FetchWorkflowStageTasksSort;
}

export interface FetchWorkflowStageTaskOptions {
  query: FetchWorkflowStageTaskQuery;
}

export interface WorkflowStageTaskRequestData {
  name?: WorkflowStageTaskName;
  arrangement?: WorkflowStageTaskArrangement;
  dueDate?: WorkflowStageTaskDueDate;
  application?: WorkflowStageTaskApplication;
  automation?: WorkflowStageTaskAutomation;
}

export interface CreateWorkflowStageTaskRequestData
  extends WorkflowStageTaskRequestData {
  id?: WorkflowStageTaskId;
  workflowStageId: WorkflowStageTaskWorkflowStageId;
  userId: WorkflowStageTaskUserId;
  nanoId: WorkflowStageTaskNanoId;
}
export interface UpdateWorkflowStageTaskRequestData
  extends WorkflowStageTaskRequestData {
  id?: WorkflowStageTaskId;
  nanoId?: WorkflowStageTaskNanoId;
}

export interface CreateWorkflowStageTaskErrorResponse {
  message: CreateWorkflowStageTaskError;
}

export interface UpdateWorkflowStageTaskErrorResponse {
  message: UpdateWorkflowStageTaskError;
}

export interface DeleteWorkflowStageTaskErrorResponse {
  message: DeleteWorkflowStageTaskError;
}

export class WorkflowStageTasksRequests extends BaseRequests {
  static async fetchWorkflowStageTasks<T>(
    options: FetchWorkflowStageTasksOptions
  ) {
    return this.fetchItems<T>(
      'workflowStageTasks' as FetchWorkflowStageTasksTable,
      options
    );
  }

  static async fetchWorkflowStageTask<T>(
    fetchWorkflowStageTaskId: FetchWorkflowStageTaskID,
    options: FetchWorkflowStageTaskOptions
  ) {
    return this.fetchItem<T>(
      'workflowStageTasks' as FetchWorkflowStageTaskTable,
      fetchWorkflowStageTaskId,
      options
    );
  }

  static async fetchWorkflowStageTaskByNanoId<T>(
    fetchWorkflowStageTaskNanoId: FetchWorkflowStageTaskNanoID,
    options: FetchWorkflowStageTaskOptions
  ) {
    return this.fetchItemByNanoId<T>(
      'workflowStageTasks' as FetchWorkflowStageTaskTable,
      fetchWorkflowStageTaskNanoId,
      options
    );
  }

  static async createWorkflowStageTask(
    createWorkflowStageTaskData: CreateWorkflowStageTaskRequestData
  ) {
    return this.createItem<CreateWorkflowStageTaskRequestData>(
      'workflowStageTasks' as CreateWorkflowStageTaskTable,
      createWorkflowStageTaskData
    );
  }

  static async updateWorkflowStageTask(
    updateWorkflowStageTaskId:
      | UpdateWorkflowStageTaskID
      | UpdateWorkflowStageTaskNanoId,
    updateWorkflowStageTaskData: UpdateWorkflowStageTaskRequestData,
    updateWorkflowStageTaskOptions: {
      filterColumn?: keyof UpdateWorkflowStageTaskRequestData;
    } = {}
  ) {
    return this.updateItem<UpdateWorkflowStageTaskRequestData>(
      'workflowStageTasks' as UpdateWorkflowStageTaskTable,
      updateWorkflowStageTaskId,
      updateWorkflowStageTaskData,
      updateWorkflowStageTaskOptions
    );
  }

  static async deleteWorkflowStageTask(
    deleteWorkflowStageTaskId: DeleteWorkflowStageTaskID
  ) {
    return this.deleteItem(
      'workflowStageTasks' as DeleteWorkflowStageTaskTable,
      deleteWorkflowStageTaskId,
      {}
    );
  }
}
