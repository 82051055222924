import { Icon, IconProps } from '@chakra-ui/icon';

const ActivitiesButtonIcon = ({
  w = '40px',
  h = '40px',
  ...props
}: IconProps) => (
  <Icon viewBox="0 0 40 40" w={w} h={h} {...props}>
    <circle cx={20} cy={20} r={20} fill="#507D7B" />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M25.25 20.75h-6v-6h1.5v4.5h4.5v1.5zM20 11c-4.963 0-9 4.037-9 9s4.037 9 9 9 9-4.037 9-9-4.037-9-9-9z"
      clipRule="evenodd"
    />
  </Icon>
);

export default ActivitiesButtonIcon;
