import {
  Box,
  CloseButton,
  Flex,
  ScaleFade,
  Stack,
  useDisclosure
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { Heading } from '../../../../../../../../../../../helpers/Heading';
import { useViewJobCandidateModalPhoneSidebarContext } from '../../../context';

type ViewJobCandidateModalPhoneSidebarSnoozedPopoverProps = {
  children: ReactNode;
};

const ViewJobCandidateModalPhoneSidebarSnoozedPopover = ({
  children
}: ViewJobCandidateModalPhoneSidebarSnoozedPopoverProps) => {
  const { isCallSnoozed, onToggleIsCallSnooze } =
    useViewJobCandidateModalPhoneSidebarContext();

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Flex
      position="relative"
      onMouseLeave={() => {
        if (isOpen) onClose();
      }}
    >
      <Box
        onMouseEnter={() => {
          if (isCallSnoozed) onOpen();
        }}
      >
        {children}
      </Box>

      {isCallSnoozed && (
        <Box
          pt={2.5}
          top="100%"
          left="50%"
          position="absolute"
          boxSizing="border-box"
          transform="translateX(-50%)"
        >
          <ScaleFade initialScale={0.75} in={isOpen}>
            <Stack
              px="4"
              py="3"
              minW="80"
              bg="white"
              boxShadow="md"
              direction="row"
              border="1px solid"
              borderRadius="base"
              borderColor="gray.200"
            >
              <Flex flex={1} alignItems="center">
                <Heading level="h6" fontSize="sm" fontWeight="medium" flex={1}>
                  <Box as="span">Snoozed:</Box>{' '}
                  <Box as="span" color="blue.500">
                    Tomorrow 10:00am
                  </Box>
                </Heading>

                <CloseButton
                  color="gray.500"
                  size="sm"
                  onClick={() => {
                    onToggleIsCallSnooze();
                    onClose();
                  }}
                />
              </Flex>
            </Stack>
          </ScaleFade>
        </Box>
      )}
    </Flex>
  );
};

export default ViewJobCandidateModalPhoneSidebarSnoozedPopover;
