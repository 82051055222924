import { usePaginatedItems } from '../../../common/hooks/usePaginatedItems';

import {
  FetchSpecializationsQuery,
  FetchSpecializationsCacheKey,
  FetchSpecializationsTotalCount,
  FetchSpecializationsPage,
  FetchSpecializationsPageSize,
  FetchSpecializationsError,
  FetchSpecializationsErrorMessage,
  FetchSpecializationsIsLoading,
  FetchSpecializationsIsFetched,
  FetchSpecializationsIsPlaceholderData,
  FetchSpecializationsEnabled,
  FetchSpecializationsPrefetchNextPage,
  FetchSpecializationsCountType,
  FetchSpecializationsFilters,
  FetchSpecializationsSort,
  FetchSpecializationID,
  FetchSpecializationQuery,
  FetchSpecializationCacheKey
} from '../../specializationsTypes';

import { SpecializationsBffRequests } from '../../SpecializationsBffRequests';

interface PaginatedSpecializationsBaseOptions<
  PaginatedSpecializationsResponseType
> {
  cacheKey: FetchSpecializationsCacheKey;
  query: FetchSpecializationsQuery;
  countType?: FetchSpecializationsCountType;
  initialPage?: FetchSpecializationsPage;
  initialPageSize?: FetchSpecializationsPageSize;
  initialFilters?: FetchSpecializationsFilters;
  initialSort?: FetchSpecializationsSort;
  enabled?: FetchSpecializationsEnabled;
  prefetchNextPage?: FetchSpecializationsPrefetchNextPage;
  placeholderSpecializations?: PaginatedSpecializationsResponseType[];
  placeholderSpecializationsCount?: FetchSpecializationsTotalCount;
  placeholderData?: () => PaginatedSpecializationsResponse<PaginatedSpecializationsResponseType>;
}

interface PaginatedSpecializationsOptionsWithoutItem {
  fetchItemCacheKey?: never;
  itemQuery?: never;
}

interface PaginatedSpecializationsOptionsWithItem {
  fetchItemCacheKey: FetchSpecializationCacheKey;
  itemQuery: FetchSpecializationQuery;
}

type PaginatedSpecializationsOptions<PaginatedSpecializationsResponseType> =
  PaginatedSpecializationsBaseOptions<PaginatedSpecializationsResponseType> &
    (
      | PaginatedSpecializationsOptionsWithoutItem
      | PaginatedSpecializationsOptionsWithItem
    );

interface PaginatedSpecializationsResponse<
  PaginatedSpecializationsResponseType
> {
  data: PaginatedSpecializationsResponseType[];
  totalCount: FetchSpecializationsTotalCount;
}

function usePaginatedSpecializations<PaginatedSpecializationsResponseType>({
  cacheKey,
  query,
  countType,
  initialPage,
  initialPageSize,
  initialFilters,
  initialSort,
  enabled,
  prefetchNextPage,
  placeholderSpecializations,
  placeholderSpecializationsCount,
  placeholderData,
  fetchItemCacheKey,
  itemQuery
}: PaginatedSpecializationsOptions<PaginatedSpecializationsResponseType>) {
  const {
    items,
    itemsTotalCount,
    itemsError,
    itemsErrorMessage,
    itemsIsFetched,
    itemsIsLoading,
    itemsIsPlaceholderData,
    itemsCurrentPage,
    itemsCurrentPageSize,
    itemsCurrentFilters,
    itemsCurrentSort,
    fetchItems,
    paginateItems,
    changeItemsPageSize,
    filterItems,
    changeItemsFilters,
    clearItemsFilters,
    sortItems,
    prefetchItems,
    prefetchItem
  } = usePaginatedItems<PaginatedSpecializationsResponseType>({
    cacheKey,
    initialPage,
    initialPageSize,
    initialFilters,
    initialSort,
    enabled,
    prefetchNextPage,
    placeholderItems: placeholderSpecializations,
    placeholderItemsCount: placeholderSpecializationsCount,
    placeholderData,
    queryFn: (params) =>
      SpecializationsBffRequests.fetchSpecializations<PaginatedSpecializationsResponseType>(
        {
          query,
          countType,
          page: params.page as FetchSpecializationsPage,
          pageSize: params.pageSize as FetchSpecializationsPageSize,
          filters: params.filters as FetchSpecializationsFilters,
          sort: params.sort as FetchSpecializationsSort
        }
      ),
    // SpecializationsRequests.fetchSpecializations<PaginatedSpecializationsResponseType>(
    //   {
    //     query,
    //     countType,
    //     page: params.page as FetchSpecializationsPage,
    //     pageSize: params.pageSize as FetchSpecializationsPageSize,
    //     filters: params.filters as FetchSpecializationsFilters,
    //     sort: params.sort as FetchSpecializationsSort
    //   }
    // ),
    ...(itemQuery
      ? {
          fetchItemCacheKey,
          queryItemFn: (itemId) =>
            SpecializationsBffRequests.fetchSpecialization(
              itemId as FetchSpecializationID,
              {
                query: itemQuery
              }
            )
          // SpecializationsRequests.fetchSpecialization(itemId as FetchSpecializationID, {
          //   query: itemQuery
          // })
        }
      : {})
  });

  return {
    specializations: items as PaginatedSpecializationsResponseType[],
    specializationsTotalCount:
      itemsTotalCount as FetchSpecializationsTotalCount,
    specializationsError: itemsError as FetchSpecializationsError,
    specializationsErrorMessage:
      itemsErrorMessage as FetchSpecializationsErrorMessage,
    specializationsIsLoading: itemsIsLoading as FetchSpecializationsIsLoading,
    specializationsIsFetched: itemsIsFetched as FetchSpecializationsIsFetched,
    specializationsIsPlaceholderData:
      itemsIsPlaceholderData as FetchSpecializationsIsPlaceholderData,
    specializationsCurrentPage: itemsCurrentPage as FetchSpecializationsPage,
    specializationsCurrentPageSize:
      itemsCurrentPageSize as FetchSpecializationsPageSize,
    specializationsCurrentFilters:
      itemsCurrentFilters as FetchSpecializationsFilters,
    specializationsCurrentSort: itemsCurrentSort as FetchSpecializationsSort,
    fetchSpecializations: fetchItems,
    paginateSpecializations: paginateItems,
    changeSpecializationsPageSize: changeItemsPageSize,
    filterSpecializations: filterItems,
    changeSpecializationsFilters: changeItemsFilters,
    clearSpecializationsFilters: clearItemsFilters,
    sortSpecializations: sortItems,
    prefetchSpecializations: prefetchItems,
    prefetchSpecialization: prefetchItem
  };
}

export default usePaginatedSpecializations;
