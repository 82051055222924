import {
  FetchCandidateSubmissionsCacheKey,
  FetchCandidateSubmissionCacheKey
} from './candidateSubmissionsTypes';

export class CandidateSubmissionsCache {
  static indexCacheKey() {
    return 'candidateSubmissions' as FetchCandidateSubmissionsCacheKey;
  }

  static showCacheKey() {
    return 'candidateSubmission' as FetchCandidateSubmissionCacheKey;
  }
}
