import {
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useDisclosure
} from '@chakra-ui/react';
import range from 'lodash/range';
import { Fragment, MouseEvent, useState } from 'react';
import {
  Button,
  ButtonProps
} from '../../../../../../../../../../../helpers/Button';
import { Text } from '../../../../../../../../../../../helpers/Text';
import { ChevronDownIcon } from '../../../../../../../../../../../icons/ChevronDownIcon';

interface ViewJobCandidateModalPhoneSidebarChooseAvailabilityTimeProps
  extends ButtonProps {
  time: string;
}

const ViewJobCandidateModalPhoneSidebarChooseAvailabilityTime = ({
  time,
  onClick
}: ViewJobCandidateModalPhoneSidebarChooseAvailabilityTimeProps) => {
  const [selectedTime, setSelectedTime] = useState(time);
  const { isOpen, onToggle, onClose } = useDisclosure();

  const hoursArray = range(8, 21);

  const addLeadingZero = (num: number) => {
    return num < 10 ? `0${num}` : `${num}`;
  };

  const changeTime = (e: MouseEvent<HTMLButtonElement>) => {
    const { value } = e.currentTarget;

    setSelectedTime(value);

    !!onClick && onClick(e);

    onClose();
  };

  return (
    <Fragment>
      <Popover isOpen={isOpen} onClose={onClose} isLazy>
        <PopoverTrigger>
          <Flex alignItems="stretch">
            <Button
              p={0}
              h="100%"
              minH={6}
              minW={0}
              size="small"
              onClick={onToggle}
              fontSize="inherit"
              border="none"
              boxShadow="none"
              hierarchy="unstyled"
              aria-label="Dropdown"
            >
              <Flex gap={1} alignItems="center" fontSize="inherit">
                <Text>{selectedTime}</Text>
                <ChevronDownIcon fill="gray.500" />
              </Flex>
            </Button>
          </Flex>
        </PopoverTrigger>

        <Portal>
          <PopoverContent minW={24} w={24} p={0} maxH={60} overflow="hidden">
            <Flex flexDir="column" overflow="auto">
              {hoursArray.map((hour) => {
                const value =
                  hour < 12
                    ? `${addLeadingZero(hour)}:00 am`
                    : hour === 12
                    ? `12:00 pm`
                    : `${addLeadingZero(hour % 12)}:00 pm`;

                return (
                  <Button
                    pt={2}
                    pb={2}
                    h="auto"
                    size="small"
                    color="inherit"
                    lineHeight={1.5}
                    fontSize="inherit"
                    borderRadius="none"
                    fontWeight="inherit"
                    hierarchy="unstyled"
                    isDisabled={selectedTime === value}
                    onClick={changeTime}
                    value={value}
                    key={hour}
                    _hover={{
                      bg: 'gray.100'
                    }}
                  >
                    {value}
                  </Button>
                );
              })}
            </Flex>
          </PopoverContent>
        </Portal>
      </Popover>
    </Fragment>
  );
};

export default ViewJobCandidateModalPhoneSidebarChooseAvailabilityTime;
