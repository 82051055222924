import { CandidateSubmissionEvaluationsEnum } from '../../candidateSubmissionsTypes';

function renderCandidateSubmissionEvaluation(
  candidateSubmissionEvaluation: CandidateSubmissionEvaluationsEnum
) {
  switch (candidateSubmissionEvaluation) {
    case CandidateSubmissionEvaluationsEnum.JobFit:
      return `Job fit / Relevant background?`;
    case CandidateSubmissionEvaluationsEnum.Communication:
      return `Interpersonal / Communication?`;
    case CandidateSubmissionEvaluationsEnum.Impression:
      return `Professional Impression?`;
    case CandidateSubmissionEvaluationsEnum.Motivation:
      return `Motivation / Intuitive?`;
    case CandidateSubmissionEvaluationsEnum.Overall:
      return `Overall Evaluation?`;
    default:
      return 'Unknown';
  }
}

export default renderCandidateSubmissionEvaluation;
