import { FieldBrand, ItemRecordID } from '../../../types';

import { UserUuid } from '../../users/usersTypes';

export type AccountTypeRecordId = FieldBrand<
  ItemRecordID,
  'AccountTypeRecordId'
>;

export type AccountTypeUserUuid = FieldBrand<UserUuid, 'AccountTypeUserUuid'>;

export const enum AccountTypeIndexFields {
  ID = 'id',
  RECORD_ID = 'recordId',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  CREATED_AT = 'createdAt',

  USER_UUID = 'userUuid'
}
