import { Icon, IconProps } from '@chakra-ui/icon';

const AttachIcon = ({ w = '16px', h = '16px', fill, ...props }: IconProps) => (
  <Icon
    viewBox="0 0 16 16"
    w={w}
    h={h}
    fill={fill || 'currentColor'}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.48 16a5.454 5.454 0 0 1-3.873-1.587A5.372 5.372 0 0 1 0 10.588c0-1.443.584-2.813 1.607-3.824l5.699-5.628c1.534-1.515 4.091-1.515 5.699 0 1.607 1.516 1.534 4.041 0 5.628l-5.188 5.123a2.58 2.58 0 0 1-3.653 0 2.504 2.504 0 0 1 0-3.607l4.384-4.33a.712.712 0 0 1 1.023 0 .692.692 0 0 1 0 1.01L5.187 9.29a1.037 1.037 0 0 0 0 1.515 1.069 1.069 0 0 0 1.534 0l5.188-5.123c.511-.505.73-1.082.73-1.804 0-.65-.292-1.299-.73-1.804-1.023-1.01-2.63-1.01-3.58 0L2.63 7.702a3.958 3.958 0 0 0-1.169 2.814c0 1.083.439 2.02 1.17 2.814 1.533 1.515 4.09 1.515 5.698 0l6.429-6.35a.712.712 0 0 1 1.023 0 .691.691 0 0 1 0 1.011l-6.43 6.35C8.33 15.495 6.942 16 5.48 16Z"
    />
  </Icon>
);
export default AttachIcon;
