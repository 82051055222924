import { useQuery } from 'react-query';

import {
  FetchItemCacheKey,
  FetchItemEnabled,
  FetchItemCacheTime,
  FetchItemError,
  FetchItemErrorMessage,
  FetchItemIsFetched,
  FetchItemIsLoading,
  FetchItemIsPlaceholderData,
  FetchItemIsRefetching,
  ItemNanoId
} from '../../../../types';

import { parseRequestError } from '../../../../utils/parseRequestError';

interface FetchItemByNanoIdOptions<FetchItemResponseType> {
  itemNanoId: ItemNanoId;
  cacheKey: FetchItemCacheKey;
  enabled?: FetchItemEnabled;
  cacheTime?: FetchItemCacheTime;
  placeholderItem?: FetchItemResponseType;
  placeholderData?: () => FetchItemResponseType;
  queryFn: () => Promise<FetchItemResponseType | null>;
  onSuccess?: (data: FetchItemResponseType | null) => void;
}

function useFetchItemByNanoId<
  FetchItemResponseType,
  FetchItemErrorType extends FetchItemError
>({
  itemNanoId,
  cacheKey,
  enabled,
  cacheTime,
  placeholderItem,
  placeholderData,
  queryFn,
  onSuccess
}: FetchItemByNanoIdOptions<FetchItemResponseType>) {
  const {
    data,
    error,
    isFetched,
    isRefetching,
    isLoading,
    isPlaceholderData,
    refetch
  } = useQuery<FetchItemResponseType | null, FetchItemErrorType>(
    [cacheKey, itemNanoId],
    queryFn,
    {
      enabled,
      cacheTime,
      placeholderData: placeholderData || placeholderItem,
      onSuccess
    }
  );

  return {
    item: data,
    itemError: error,
    itemErrorMessage: parseRequestError(error) as FetchItemErrorMessage,
    itemIsFetched: isFetched as FetchItemIsFetched,
    itemIsRefetching: isRefetching as FetchItemIsRefetching,
    itemIsLoading: isLoading as FetchItemIsLoading,
    itemIsPlaceholderData: isPlaceholderData as FetchItemIsPlaceholderData,
    refetchItem: () => refetch()
  };
}

export default useFetchItemByNanoId;
