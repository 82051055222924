import {
  Box,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useDisclosure
} from '@chakra-ui/react';
import { Fragment, MouseEvent, useState } from 'react';
import { Button } from '../../../../../../../../../../../helpers/Button';
import { Text } from '../../../../../../../../../../../helpers/Text';
import { ChevronDownIcon } from '../../../../../../../../../../../icons/ChevronDownIcon';

type ViewJobCandidateModalPhoneSidebarChooseAvailabilityTimeProps = {
  value: Value;
};

const optionsArray = [
  'Later today',
  'Tomorrow',
  'This weekend',
  'Next week'
] as const;

type Value = typeof optionsArray[number];

const ViewJobCandidateModalPhoneSidebarCallbackDropdown = ({
  value
}: ViewJobCandidateModalPhoneSidebarChooseAvailabilityTimeProps) => {
  const [selectedOption, setSelectedOption] = useState<Value>(value);
  const { isOpen, onToggle, onClose } = useDisclosure();

  const selectOption = (e: MouseEvent<HTMLButtonElement>) => {
    const { value } = e.currentTarget;

    setSelectedOption(value as Value);
    onClose();
  };

  return (
    <Fragment>
      <Popover isOpen={isOpen} onClose={onClose} isLazy>
        <PopoverTrigger>
          <Box>
            <Button
              p={0}
              h={6}
              onClick={onToggle}
              minH={0}
              minW={0}
              size="small"
              fontSize="inherit"
              border="none"
              boxShadow="none"
              hierarchy="unstyled"
              aria-label="Dropdown"
            >
              <Flex gap={1} alignItems="center" fontSize="inherit">
                <Text>{selectedOption}</Text>
                <ChevronDownIcon fill="gray.500" />
              </Flex>
            </Button>
          </Box>
        </PopoverTrigger>

        <Portal>
          <PopoverContent minW={32} w={32} p={2} maxH={60} overflow="hidden">
            <Flex flexDir="column" overflow="auto">
              {optionsArray.map((option) => (
                <Button
                  pt={2}
                  pb={2}
                  h="auto"
                  size="small"
                  color="inherit"
                  lineHeight={1.5}
                  fontSize="inherit"
                  borderRadius="none"
                  fontWeight="inherit"
                  hierarchy="unstyled"
                  isDisabled={selectedOption === option}
                  onClick={selectOption}
                  value={option}
                  key={option}
                  _hover={{
                    bg: 'gray.100'
                  }}
                >
                  {option}
                </Button>
              ))}
            </Flex>
          </PopoverContent>
        </Portal>
      </Popover>
    </Fragment>
  );
};

export default ViewJobCandidateModalPhoneSidebarCallbackDropdown;
