import { FetchCandidateSharesCacheKey } from '../../candidateSharesTypes';

import { useCreateItem } from '../../../common/hooks/useCreateItem';

import {
  CandidateSharesBffRequests,
  CreateCandidateShareRequestData,
  CreateCandidateShareErrorResponse
} from '../../CandidateSharesBffRequests';

interface CreateCandidateShareOptions {
  cacheKeys?: FetchCandidateSharesCacheKey[];
}

function useCreateCandidateShare({
  cacheKeys = []
}: CreateCandidateShareOptions) {
  const {
    createItemData,
    createItemError,
    createItemErrorMessage,
    createItemIsLoading,
    createItem,
    createItemReset
  } = useCreateItem<
    CreateCandidateShareRequestData,
    CreateCandidateShareErrorResponse
  >({
    mutationFn: (queryInput) =>
      CandidateSharesBffRequests.createCandidateShare(queryInput),
    cacheKeys
  });

  return {
    createCandidateShareData: createItemData,
    createCandidateShareError: createItemError,
    createCandidateShareErrorMessage: createItemErrorMessage,
    createCandidateShareIsLoading: createItemIsLoading,
    createCandidateShare: createItem,
    createCandidateShareReset: createItemReset
  };
}

export default useCreateCandidateShare;
