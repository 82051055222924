import {
  Avatar,
  Box,
  Collapse,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  GridItem,
  SimpleGrid,
  Stack,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import { Select as ReactSelect } from 'chakra-react-select';
import { useState } from 'react';
import { Button } from '../../../../../../helpers/Button';
import { CollapseFormBox } from '../../../../../../helpers/CollapseFormBox';
import { InputField } from '../../../../../../helpers/forms/formFields/InputField';
import { SelectFieldControl } from '../../../../../../helpers/forms/formFields/SelectField/components/SelectFieldControl';
import { WysiwygFieldControl } from '../../../../../../helpers/forms/formFields/WysiwygField/components/WysiwygFieldControl';
import { Heading } from '../../../../../../helpers/Heading';
import { Text } from '../../../../../../helpers/Text';
import { CancelIcon } from '../../../../../../icons/CancelIcon';
import { PlusIcon } from '../../../../../../icons/PlusIcon';
import { UserActionTypes } from '../../../../../users/usersTypes';
import { InviteUserForm } from '../../../../components/forms/InviteUserForm';
import { WizardIndicator } from '../../../../helpers/WizardIndicator';
import { DateInputField } from '../../../../../../helpers/forms/formFields/DateInputField';

interface ScheduleMeetingProps {
  isOpen: boolean;
  onClose: () => void;
  withoutMargin?: boolean;
}

type SelectOptionType = {
  image: string;
  label: string;
  value?: string;
  id?: string;
};

const ScheduleMeeting = ({
  isOpen,
  onClose,
  withoutMargin
}: ScheduleMeetingProps) => {
  const [step, setStep] = useState(1);

  const {
    isOpen: isOpenAddRecruiter,
    onOpen: onOpenAddRecruiter,
    onClose: onCloseAddRecruiter
  } = useDisclosure();

  const toast = useToast();

  const sendInvite = () => {
    toast.closeAll();
    onClose();

    toast({
      title: `Email Sent`,
      status: 'success',
      position: 'top',
      duration: 2000,
      isClosable: true
    });
  };

  const goToStep = (stepNumber: number) => {
    setStep(stepNumber);
  };

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      autoFocus={false}
      closeOnOverlayClick={false}
    >
      <DrawerOverlay width={withoutMargin ? undefined : 'calc(100% - 72px)'} />

      <DrawerContent
        w="100%"
        maxW="54rem"
        boxShadow="none"
        bg="gray.50"
        marginRight={withoutMargin ? undefined : { base: 0, lg: '72px' }}
      >
        <DrawerHeader px={8} pt={8} pb={4}>
          <Flex flexDir="column" alignItems="flex-start">
            <Button
              hierarchy="unstyled"
              onClick={onClose}
              display="flex"
              flexDirection="row"
              alignItems="center"
              h={6}
              mb={8}
            >
              <CancelIcon />
            </Button>

            <Heading level="h2">New Meeting</Heading>
          </Flex>
        </DrawerHeader>

        <DrawerBody px={8} pb={8} pt={2}>
          <Stack flex={1} spacing={6} h="100%">
            <Box>
              <WizardIndicator currentStep={step} totalSteps={2} />
            </Box>

            {step === 1 && (
              <Flex
                p={6}
                bg="white"
                borderRadius={4}
                border="1px solid"
                borderColor="gray.200"
              >
                <Stack spacing={6} flex={1}>
                  <Stack spacing={6}>
                    <Heading level="h4">Meeting details</Heading>

                    <InputField label="Title" />

                    <SimpleGrid columns={2} gap={6}>
                      <SelectFieldControl
                        options={[]}
                        label="Time range"
                        onChange={() => console.log('change')}
                      />

                      <SelectFieldControl
                        options={[]}
                        label="Repeat"
                        onChange={() => console.log('change')}
                      />
                    </SimpleGrid>

                    <SimpleGrid columns={4} gap={6}>
                      <DateInputField type="month" label="Start date " />
                      <DateInputField type="time" label="Start time" />
                      <DateInputField type="month" label="End date " />
                      <DateInputField type="time" label="End time" />
                    </SimpleGrid>

                    <Stack>
                      <Text fontSize="md" fontWeight="medium">
                        Meeting Type
                      </Text>

                      <SimpleGrid columns={4} gap={6}>
                        <SelectFieldControl
                          options={[]}
                          placeholder="Meeting Type"
                          onChange={() => console.log('change')}
                        />

                        <GridItem colSpan={3}>
                          <InputField />
                        </GridItem>
                      </SimpleGrid>
                    </Stack>
                  </Stack>

                  <Flex gap={4} justifyContent="flex-end" alignItems="flex-end">
                    <Button
                      hierarchy="tertiary"
                      size="medium"
                      value={1}
                      disabled
                    >
                      Back
                    </Button>
                    <Button
                      size="medium"
                      onClick={() => {
                        goToStep(2);
                      }}
                    >
                      Next
                    </Button>
                  </Flex>
                </Stack>
              </Flex>
            )}

            {step === 2 && (
              <Flex
                p={6}
                flex={1}
                bg="white"
                borderRadius={4}
                border="1px solid"
                borderColor="gray.200"
              >
                <Stack spacing={6} flex={1}>
                  <Stack spacing={4}>
                    <Heading level="h4">Candidate details</Heading>

                    <Stack zIndex={9} position="relative">
                      <Text fontSize="md" textStyle="body1Medium">
                        Select Candidate
                      </Text>

                      <ReactSelect
                        isMulti
                        options={panels}
                        placeholder=""
                        menuPlacement="auto"
                        focusBorderColor="primary.500"
                        formatOptionLabel={FormatOptionLabel}
                        selectedOptionColor="primary"
                        chakraStyles={{
                          dropdownIndicator: (provided) => ({
                            ...provided,
                            bg: 'transparent',
                            display: 'none',
                            w: 6
                          }),
                          option: (provided) => ({
                            ...provided,
                            h: 10,
                            px: 4
                          }),
                          indicatorSeparator: (provided) => ({
                            ...provided,
                            display: 'none'
                          })
                        }}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 0,
                          colors: {
                            ...theme.colors,
                            primary: '#0580A4'
                          }
                        })}
                      />
                    </Stack>

                    <Flex gap={2} flexDir="column" flex={1}>
                      <Text fontSize="md" textStyle="body1Medium">
                        Description
                      </Text>

                      <Box flex={1} minH="300px">
                        <WysiwygFieldControl
                          name="Test"
                          value=""
                          defaultValue=""
                          onChange={() => {
                            //
                          }}
                        />
                      </Box>
                    </Flex>
                  </Stack>

                  <Stack flex="1" spacing={4}>
                    <Heading level="h4">Panelist details</Heading>

                    <Stack zIndex={9} position="relative">
                      <Text fontSize="md" textStyle="body1Medium">
                        Panelists
                      </Text>

                      <ReactSelect
                        isMulti
                        options={panels}
                        placeholder=""
                        menuPlacement="auto"
                        focusBorderColor="primary.500"
                        formatOptionLabel={FormatOptionLabel}
                        selectedOptionColor="primary"
                        chakraStyles={{
                          dropdownIndicator: (provided) => ({
                            ...provided,
                            bg: 'transparent',
                            display: 'none',
                            w: 6
                          }),
                          option: (provided) => ({
                            ...provided,
                            h: 10,
                            px: 4
                          }),
                          indicatorSeparator: (provided) => ({
                            ...provided,
                            display: 'none'
                          })
                        }}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 0,
                          colors: {
                            ...theme.colors,
                            primary: '#0580A4'
                          }
                        })}
                      />

                      <Box>
                        <Stack>
                          <Button
                            size="medium"
                            hierarchy="link"
                            onClick={onOpenAddRecruiter}
                            ml="auto"
                          >
                            <PlusIcon />
                            <Box as="span">Add new panelist</Box>
                          </Button>
                        </Stack>

                        <Box
                          as={Collapse}
                          in={isOpenAddRecruiter}
                          unmountOnExit={true}
                        >
                          <Box pt={6}>
                            <CollapseFormBox
                              title="Add Panelist"
                              isOpen={isOpenAddRecruiter}
                            >
                              <InviteUserForm
                                key={UserActionTypes.INTERVIEW_PANELIST}
                                actionType={UserActionTypes.INTERVIEW_PANELIST}
                                onCancel={onCloseAddRecruiter}
                                onSave={onCloseAddRecruiter}
                              />
                            </CollapseFormBox>
                          </Box>
                        </Box>
                      </Box>
                    </Stack>

                    <Flex gap={2} flexDir="column" flex={1}>
                      <Text fontSize="md" textStyle="body1Medium">
                        Description
                      </Text>

                      <Box flex={1} minH="300px">
                        <WysiwygFieldControl
                          name="Test"
                          value=""
                          defaultValue=""
                          onChange={() => {
                            //
                          }}
                        />
                      </Box>
                    </Flex>
                  </Stack>

                  <Flex gap={4} justifyContent="flex-end" alignItems="flex-end">
                    <Button
                      hierarchy="tertiary"
                      size="medium"
                      onClick={() => {
                        goToStep(1);
                      }}
                      value={1}
                    >
                      Back
                    </Button>
                    <Button size="medium" onClick={sendInvite}>
                      Send Invites
                    </Button>
                  </Flex>
                </Stack>
              </Flex>
            )}
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default ScheduleMeeting;

const FormatOptionLabel = ({
  image,
  label
}: {
  image: string;
  label: string;
}) => (
  <Flex gap={3} direction="row" zIndex={1} w="100%" alignItems="center">
    <Avatar size="2xs" src={image} />
    <Text isTruncated>{label}</Text>
  </Flex>
);

const panels: SelectOptionType[] = [
  {
    label: 'Emily Barboza',
    value: 'Emily Barboza',
    image: '/images/team-mate-3.png'
  },
  {
    label: 'Arnold Nunez',
    value: 'Arnold Nunez',
    image: '/images/team-mate-1.png'
  },
  {
    label: 'Julianna pena',
    value: 'Julianna pena',
    image: '/images/team-mate-2.png'
  },
  {
    label: 'Rhonda Rousey',
    value: 'Rhonda Rousey',
    image: '/images/team-mate-4.png'
  }
];
