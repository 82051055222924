import {
  FetchJobsCacheKey,
  FetchJobCacheKey,
  UpdateJobErrorMessage,
  UpdateJobIsLoading,
  UpdateJobNanoId
} from '../../jobsTypes';

import { useUpdateItem } from '../../../common/hooks/useUpdateItem';

import {
  JobsBffRequests,
  UpdateJobRequestData,
  UpdateJobErrorResponse
} from '../../JobsBffRequests';

interface UpdateJobOptions {
  jobNanoId: UpdateJobNanoId;
  cacheKeys?: (FetchJobsCacheKey | FetchJobCacheKey)[];
}

function useUpdateJob({ jobNanoId, cacheKeys = [] }: UpdateJobOptions) {
  const {
    updateItemData,
    updateItemError,
    updateItemErrorMessage,
    updateItemIsLoading,
    updateItem,
    updateItemReset
  } = useUpdateItem<UpdateJobRequestData, UpdateJobErrorResponse>({
    mutationFn: (queryInput) =>
      JobsBffRequests.updateJob(jobNanoId, queryInput),
    cacheKeys
  });

  return {
    updateJobData: updateItemData,
    updateJobError: updateItemError,
    updateJobErrorMessage: updateItemErrorMessage as UpdateJobErrorMessage,
    updateJobIsLoading: updateItemIsLoading as UpdateJobIsLoading,
    updateJob: updateItem,
    updateJobReset: updateItemReset
  };
}

export default useUpdateJob;
