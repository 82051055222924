import { GridItem } from '@chakra-ui/react';

import { usePaginatedJobCandidates } from '../../../../jobCandidates/hooks/usePaginatedJobCandidates';

import { JobCandidatesCache } from '../../../../jobCandidates/JobCandidatesCache';

import {
  FetchJobCandidatesCountType,
  FetchJobCandidatesEnabled,
  FetchJobCandidatesFilters,
  JobCandidateFields,
  JobCandidateNanoId
} from '../../../../jobCandidates/jobCandidatesTypes';
import { JobId, JobNanoId } from '../../../../jobs/jobsTypes';
import { UserId } from '../../../../users/usersTypes';

import {
  fetchJobCandidatesQuery,
  FetchJobCandidatesResponse
} from '../../../../jobCandidates/queries/fetchJobCandidates.query';

import { TalentPoolCandidatesList } from '../../lists/TalentPoolCandidatesList';

interface TalentPoolCandidatesListContainerProps {
  job: {
    nanoId: JobNanoId;
    id: JobId;
  };
  currentUser: {
    id: UserId;
  };
  personNanoId?: JobCandidateNanoId;
}

function TalentPoolCandidatesListContainer({
  job,
  personNanoId,
  currentUser
}: TalentPoolCandidatesListContainerProps) {
  const {
    jobCandidates,
    jobCandidatesIsFetched,
    jobCandidatesTotalCount,
    jobCandidatesErrorMessage
  } = usePaginatedJobCandidates<FetchJobCandidatesResponse>({
    query: fetchJobCandidatesQuery,
    cacheKey: JobCandidatesCache.jobIndexCacheKey(job.nanoId),
    enabled: !!job.id as FetchJobCandidatesEnabled,
    countType: 'exact' as FetchJobCandidatesCountType,
    initialFilters: {
      [JobCandidateFields.JOB_ID]: { operator: 'eq', value: job.id }
    } as unknown as FetchJobCandidatesFilters
  });

  return (
    <GridItem w="full" h="full" pos="relative" overflow="hidden">
      <TalentPoolCandidatesList
        jobCandidates={jobCandidates}
        jobCandidatesTotalCount={jobCandidatesTotalCount}
        jobCandidatesIsFetched={jobCandidatesIsFetched}
        jobCandidatesErrorMessage={jobCandidatesErrorMessage}
        job={job}
        personNanoId={personNanoId}
        currentUser={currentUser}
      />
    </GridItem>
  );
}

export default TalentPoolCandidatesListContainer;
