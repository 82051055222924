import {
  WorkflowStageTaskItemBffID,
  WorkflowStageTaskItemBffNanoID,
  FetchWorkflowStageTasksBffURL,
  FetchWorkflowStageTaskBffURL,
  FetchWorkflowStageTaskByNanoIdBffURL,
  CreateWorkflowStageTaskBffURL,
  UpdateWorkflowStageTaskBffURL,
  DeleteWorkflowStageTaskBffURL
} from './workflowStageTasksTypes';

import { BaseBffRoutes } from '../BaseBffRoutes';

export class WorkflowStageTasksBffRoutes extends BaseBffRoutes {
  protected static route = 'workflowStageTasks';

  static fetchWorkflowStageTasksRoute() {
    return this.fetchItemsRoute<FetchWorkflowStageTasksBffURL>();
  }

  static fetchWorkflowStageTaskRoute(
    workflowStageTaskItemBffID: WorkflowStageTaskItemBffID
  ) {
    return this.fetchItemRoute<FetchWorkflowStageTaskBffURL>(
      workflowStageTaskItemBffID
    );
  }

  static fetchWorkflowStageTaskByNanoIdRoute(
    workflowStageTaskItemBffNanoID: WorkflowStageTaskItemBffNanoID
  ) {
    return this.fetchItemByNanoIdRoute<FetchWorkflowStageTaskByNanoIdBffURL>(
      workflowStageTaskItemBffNanoID
    );
  }

  static createWorkflowStageTaskRoute() {
    return this.createItemRoute<CreateWorkflowStageTaskBffURL>();
  }

  static updateWorkflowStageTaskRoute(
    workflowStageTaskItemBffNanoID: WorkflowStageTaskItemBffNanoID
  ) {
    return this.updateItemRoute<UpdateWorkflowStageTaskBffURL>(
      workflowStageTaskItemBffNanoID
    );
  }

  static deleteWorkflowStageTaskRoute(
    workflowStageTaskItemBffNanoID: WorkflowStageTaskItemBffNanoID
  ) {
    return this.deleteItemRoute<DeleteWorkflowStageTaskBffURL>(
      workflowStageTaskItemBffNanoID
    );
  }
}
