import React from 'react';
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';

import { UploadImageFieldControl } from './components/UploadImageFieldControl';

import { UploadImageFieldDefaultImage } from './UploadImageField.types';

interface UploadImageFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: FieldPath<T>;
  errorMessage?: string;
  label?: string;
  size?: number | string;
  defaultImage?: UploadImageFieldDefaultImage | null;
  showDefaultAvatars?: boolean;
  placeholder?: string;
  defaultAvatarLabel?: string;
}

function UploadImageField<T extends FieldValues>({
  control,
  name,
  errorMessage,
  label,
  size,
  showDefaultAvatars,
  defaultImage,
  placeholder,
  defaultAvatarLabel
}: UploadImageFieldProps<T>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <UploadImageFieldControl
          errorMessage={errorMessage}
          value={value as string | null}
          onChange={onChange}
          defaultImage={defaultImage}
          label={label}
          size={size}
          placeholder={placeholder}
          showDefaultAvatars={showDefaultAvatars}
          defaultAvatarLabel={defaultAvatarLabel}
        />
      )}
    />
  );
}

export default UploadImageField;
