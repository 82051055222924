import {
  SkillItemBffID,
  SkillItemBffNanoID,
  FetchSkillsBffURL,
  FetchSkillBffURL,
  FetchSkillByNanoIdBffURL,
  CreateSkillBffURL,
  UpdateSkillBffURL,
  DeleteSkillBffURL
} from './skillsTypes';

import { BaseBffRoutes } from '../BaseBffRoutes';

export class SkillsBffRoutes extends BaseBffRoutes {
  protected static route = 'skills';

  static fetchSkillsRoute() {
    return this.fetchItemsRoute<FetchSkillsBffURL>();
  }

  static fetchSkillsIndexRoute() {
    return this.fetchItemsIndexRoute<FetchSkillsBffURL>();
  }

  static fetchSkillRoute(skillItemBffID: SkillItemBffID) {
    return this.fetchItemRoute<FetchSkillBffURL>(skillItemBffID);
  }

  static fetchSkillIndexRoute(skillItemBffNanoID: SkillItemBffNanoID) {
    return this.fetchItemIndexRoute<FetchSkillBffURL>(skillItemBffNanoID);
  }

  static fetchSkillByNanoIdRoute(skillItemBffNanoID: SkillItemBffNanoID) {
    return this.fetchItemByNanoIdRoute<FetchSkillByNanoIdBffURL>(
      skillItemBffNanoID
    );
  }

  static createSkillRoute() {
    return this.createItemRoute<CreateSkillBffURL>();
  }

  static updateSkillRoute(
    skillItemBffNanoID: SkillItemBffID | SkillItemBffNanoID
  ) {
    return this.updateItemRoute<UpdateSkillBffURL>(skillItemBffNanoID);
  }

  static deleteSkillRoute(skillItemBffNanoID: SkillItemBffNanoID) {
    return this.deleteItemRoute<DeleteSkillBffURL>(skillItemBffNanoID);
  }
}
