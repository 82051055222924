import { MouseEvent, useCallback } from 'react';

interface PureLinkHelperProps {
  href: string;
  i18nText: string;
  target?: '_blank';
  onClick?: () => void;
}

function PureLinkHelper({
  href,
  i18nText,
  target,
  onClick
}: PureLinkHelperProps) {
  const handleClick = useCallback<(e: MouseEvent<HTMLAnchorElement>) => void>(
    (e) => {
      e.preventDefault();
      onClick?.();
    },
    [onClick]
  );

  return (
    <a href={href} target={target} onClick={handleClick}>
      {i18nText}
    </a>
  );
}

export default PureLinkHelper;
