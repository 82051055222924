import {
  CompanyItemBffID,
  CompanyItemBffNanoID,
  FetchCompaniesBffURL,
  FetchCompanyBffURL,
  FetchCompanyByNanoIdBffURL,
  CreateCompanyBffURL,
  UpdateCompanyBffURL,
  DeleteCompanyBffURL
} from './companiesTypes';

import { BaseBffRoutes } from '../BaseBffRoutes';

export class CompaniesBffRoutes extends BaseBffRoutes {
  protected static route = 'companies';

  static fetchCompaniesRoute() {
    return this.fetchItemsRoute<FetchCompaniesBffURL>();
  }

  static fetchCompaniesIndexRoute() {
    return this.fetchItemsIndexRoute<FetchCompaniesBffURL>();
  }

  static fetchCompanyRoute(cityItemBffID: CompanyItemBffID) {
    return this.fetchItemRoute<FetchCompanyBffURL>(cityItemBffID);
  }

  static fetchCompanyIndexRoute(cityItemBffNanoID: CompanyItemBffNanoID) {
    return this.fetchItemIndexRoute<FetchCompanyBffURL>(cityItemBffNanoID);
  }

  static fetchCompanyByNanoIdRoute(cityItemBffNanoID: CompanyItemBffNanoID) {
    return this.fetchItemByNanoIdRoute<FetchCompanyByNanoIdBffURL>(
      cityItemBffNanoID
    );
  }

  static createCompanyRoute() {
    return this.createItemRoute<CreateCompanyBffURL>();
  }

  static updateCompanyRoute(
    cityItemBffNanoID: CompanyItemBffID | CompanyItemBffNanoID
  ) {
    return this.updateItemRoute<UpdateCompanyBffURL>(cityItemBffNanoID);
  }

  static deleteCompanyRoute(cityItemBffNanoID: CompanyItemBffNanoID) {
    return this.deleteItemRoute<DeleteCompanyBffURL>(cityItemBffNanoID);
  }
}
