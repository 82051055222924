import React from 'react';
import { Box, Flex } from '@chakra-ui/layout';

interface WizardIndicatorProps {
  currentStep: number;
  totalSteps?: number;
}

const WizardIndicator = ({
  currentStep,
  totalSteps = 5
}: WizardIndicatorProps) => {
  const stepsArray = Array.from({ length: totalSteps }, (_v, i) => i + 1);

  return (
    <Flex mt={12} mb={4} maxW="27rem" w="100%" mx="auto" color="gray.400">
      {stepsArray.map((item) => (
        <Flex
          key={item}
          flex={item !== 1 ? 1 : 0}
          alignItems="center"
          color={currentStep >= item ? 'primary.500' : 'gray.400'}
          transitionProperty="all"
          transitionDuration="slow"
          justifyContent="flex-end"
        >
          {item > 1 && <Box height="1px" bg="currentColor" flex={1}></Box>}
          <Flex
            height={3}
            width={3}
            position="relative"
            alignItems="center"
            justifyContent="center"
            boxShadow={
              item === currentStep
                ? '0px 7px 14px rgba(0, 0, 0, 0.148834)'
                : 'none'
            }
            _before={{
              content: "''",
              height: '28px',
              borderRadius: '28px',
              width: '28px',
              position: 'absolute',
              bg: 'primary.200',
              opacity: `${item === currentStep ? '0.22' : 0}`,
              transitionProperty: 'opacity',
              transitionDuration: 'slow',
              zIndex: 0
            }}
          >
            <Flex
              bg="currentColor"
              borderRadius="12px"
              height={3}
              width={3}
              alignItems="center"
              justifyContent="center"
            >
              {item === currentStep && (
                <Box w="2px" h="2px" bg="white" borderRadius="4px"></Box>
              )}
            </Flex>
          </Flex>
        </Flex>
      ))}
    </Flex>
  );
};

export default WizardIndicator;
