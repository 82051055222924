import React from 'react';
import {
  Button,
  ButtonProps
} from '../../../../../../../../../../../helpers/Button';
import { ViewJobCandidateModalPhoneSidebarCallCategoryType } from '../../../../ViewJobCandidateModalPhoneSidebarHelper.types';
import { Box } from '@chakra-ui/react';

interface ViewJobCandidateModalPhoneSidebarCallCategoryButtonProps
  extends ButtonProps {
  label: string;
  selectedCallCategory: ViewJobCandidateModalPhoneSidebarCallCategoryType | null;
  value: string;
  notifications?: number;
}

const ViewJobCandidateModalPhoneSidebarCallCategoryButton = ({
  label,
  value,
  onClick,
  notifications,
  selectedCallCategory
}: ViewJobCandidateModalPhoneSidebarCallCategoryButtonProps) => {
  return (
    <Button
      h={16}
      w="full"
      gap={1}
      size="medium"
      border="none"
      display="flex"
      borderRadius={0}
      boxShadow="none"
      alignItems="center"
      hierarchy={selectedCallCategory === value ? 'primary' : 'tertiary'}
      onClick={onClick}
      value={value}
    >
      <Box as="span">{label}</Box>
      {notifications ? (
        <Box
          h={6}
          minW={6}
          px={1.5}
          w="auto"
          as="span"
          color="white"
          fontSize="sm"
          bg="blue.400"
          fontWeight="medium"
          borderRadius="full"
          alignItems="center"
          display="inline-flex"
          justifyContent="center"
        >
          {notifications}
        </Box>
      ) : null}
    </Button>
  );
};

export default ViewJobCandidateModalPhoneSidebarCallCategoryButton;
