import { Flex, Grid, Stack, StackDivider } from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { useCurrentUser } from '../../../../../../auth/hooks/useAuth';
import { Button } from '../../../../../../helpers/Button';
import { Heading } from '../../../../../../helpers/Heading';
import { Link } from '../../../../../../helpers/Link';
import { Loader } from '../../../../../../helpers/Loader';
import { IconButton } from '../../../../../../helpers/buttons/IconButton';
import { MoreIcon } from '../../../../../../icons/MoreIcon';
import { HiringPortalSourcingRoutes } from '../../../../../hiringPortal/HirngPortalSourcingRoutes';
import { HiringPortalLeadDrawer } from '../../../../../hiringPortal/components/HiringPortalLeadsPageViews/HiringPortalLeadDrawer';
import { JobCandidateId } from '../../../../../jobCandidates/jobCandidatesTypes';
import { JobFunctionId } from '../../../../../jobFunctions/jobFunctionsTypes';
import { TalentPoolJobCandidatesCache } from '../../../../../talentPoolJobCandidates/TalentPoolJobCandidatesCache';
import { usePaginatedTalentPoolJobCandidates } from '../../../../../talentPoolJobCandidates/hooks/usePaginatedTalentPoolJobCandidates';
import {
  FetchTalentPoolJobCandidatesResponse,
  fetchTalentPoolJobCandidatesQuery
} from '../../../../../talentPoolJobCandidates/queries/fetchTalentPoolJobCandidates.query';
import {
  FetchTalentPoolJobCandidatesCountType,
  FetchTalentPoolJobCandidatesEnabled,
  FetchTalentPoolJobCandidatesFilters,
  FetchTalentPoolJobCandidatesPageSize,
  TalentPoolJobCandidateFields
} from '../../../../../talentPoolJobCandidates/talentPoolJobCandidatesTypes';
import { JobId, JobNanoId } from '../../../../jobsTypes';
import { ViewJobCandidateDetailsTalentPoolCandidate } from '../ViewJobCandidateDetailsTalentPoolCandidate';

interface ViewJobCandidateDetailsTalentPoolProps {
  job: {
    id: JobId;
    nanoId: JobNanoId;
    jobFunctionId: JobFunctionId;
  };
}

function ViewJobCandidateDetailsTalentPool({
  job
}: ViewJobCandidateDetailsTalentPoolProps) {
  const currentUser = useCurrentUser();

  const [modalJobCandidateId, setModalJobCandidateId] =
    useState<JobCandidateId | null>();

  const handleJobCandidateModalOpen = useCallback<
    (jobCandidateId: JobCandidateId) => void
  >(
    (jobCandidateId) => setModalJobCandidateId(jobCandidateId),
    [setModalJobCandidateId]
  );

  const handleJobCandidateModalClose = useCallback<() => void>(() => {
    setModalJobCandidateId(null);
  }, [setModalJobCandidateId]);

  const {
    talentPoolJobCandidates,
    talentPoolJobCandidatesIsFetched,
    talentPoolJobCandidatesTotalCount
  } = usePaginatedTalentPoolJobCandidates<FetchTalentPoolJobCandidatesResponse>(
    {
      query: fetchTalentPoolJobCandidatesQuery,
      cacheKey: TalentPoolJobCandidatesCache.indexCacheKey(),
      enabled: !!job.jobFunctionId as FetchTalentPoolJobCandidatesEnabled,
      initialPageSize: 5 as FetchTalentPoolJobCandidatesPageSize,
      countType: 'exact' as FetchTalentPoolJobCandidatesCountType,
      initialFilters: {
        [TalentPoolJobCandidateFields.JOB_FUNCTION_ID]: {
          operator: 'eq',
          value: job.jobFunctionId
        }
      } as unknown as FetchTalentPoolJobCandidatesFilters
    }
  );

  return (
    <>
      <Grid
        py={4}
        h="full"
        px={3.5}
        flex={1}
        overflow="hidden"
        flexDirection="column"
        gridTemplateRows="max-content 1fr max-content"
      >
        <Flex
          pb={3}
          gap={2}
          alignItems="center"
          borderBottom="1px solid"
          borderBottomColor="gray.200"
        >
          <Heading
            m={0}
            p={0}
            level="h3"
            fontSize="md"
            lineHeight={1.25}
            fontWeight="semibold"
          >
            Talent Pool
          </Heading>

          {talentPoolJobCandidatesTotalCount && (
            <Flex
              h={5}
              px={1.5}
              minW={5}
              color="white"
              fontSize="xs"
              lineHeight={1}
              alignItems="center"
              borderRadius="full"
              justifyContent="center"
              fontWeight="medium"
              bg="primary.500"
            >
              {talentPoolJobCandidatesTotalCount}
            </Flex>
          )}

          <IconButton
            h={6}
            w={6}
            ml="auto"
            aria-label=""
            boxShadow="none"
            color="gray.600"
            hierarchy="ghost"
            minW={0}
            minH={0}
          >
            <MoreIcon />
          </IconButton>
        </Flex>

        <Flex
          pt={3}
          gap={3}
          flex={1}
          px={3.5}
          mx={-3.5}
          flexDirection="column"
          overflow="auto"
        >
          <Loader loaded={talentPoolJobCandidatesIsFetched}>
            <Stack spacing={0.5} divider={<StackDivider />}>
              {talentPoolJobCandidates.map((candidate) => (
                <ViewJobCandidateDetailsTalentPoolCandidate
                  viewJobCandidate={handleJobCandidateModalOpen}
                  jobCandidate={candidate.jobCandidate}
                  key={candidate.id}
                />
              ))}
            </Stack>
          </Loader>
        </Flex>

        <Link
          to={HiringPortalSourcingRoutes.index()}
          _hover={{
            textDecoration: 'none'
          }}
        >
          <Flex flex={1} pt={2}>
            <Button flex={1} h={9} size="small" hierarchy="secondary">
              Request Profiles
            </Button>
          </Flex>
        </Link>
      </Grid>

      {modalJobCandidateId && currentUser ? (
        <HiringPortalLeadDrawer
          isOpen={!!modalJobCandidateId}
          onClose={handleJobCandidateModalClose}
          leadId={modalJobCandidateId}
        />
      ) : null}
    </>
  );
}

export default ViewJobCandidateDetailsTalentPool;
