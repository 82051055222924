import { Collapse, Flex, FormLabel, Stack } from '@chakra-ui/react';
import { Control, useFieldArray } from 'react-hook-form';
import {
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useCallback
} from 'react';
import size from 'lodash/size';
import find from 'lodash/find';

import { Text } from '../../../../../../helpers/Text';
import { MayBeJobCompany } from '../../../../../jobs/jobsTypes';
import { AddCandidateShareFormData } from '../../../forms/AddCandidateShareForm/AddCandidateShareForm.types';
import { CandidateShareCandidateShareInvitations } from '../../../../candidateSharesTypes';
import { ShareCandidateProfileInviteUserForm } from '../ShareCandidateProfileInviteUserForm';
import { PureButtonHelper } from '../../../../../../helpers/buttons/PureButtonHelper';
import {
  CandidateShareInvitationAccountTypeId,
  CandidateShareInvitationEmail,
  CandidateShareInvitationName
} from '../../../../../candidateShareInvitations/candidateShareInvitationsTypes';

export interface ShareCandidateProfileInviteUserCollapseContainerRefProps {
  openNewInviteUserForm: (newUser?: string) => void;
}

interface ShareCandidateProfileInviteUserCollapseContainerProps {
  isOpen: boolean;
  onClose: () => void;
  job: {
    company: MayBeJobCompany;
  };
  control: Control<AddCandidateShareFormData>;
  currentCandidateShareInvitations: CandidateShareCandidateShareInvitations;
  errorMessages: { [key: string]: { message?: string } }[];
}

const ShareCandidateProfileInviteUserCollapseContainer = forwardRef(
  (
    {
      isOpen,
      onClose,
      control,
      job,
      currentCandidateShareInvitations,
      errorMessages
    }: ShareCandidateProfileInviteUserCollapseContainerProps,
    ref: ForwardedRef<ShareCandidateProfileInviteUserCollapseContainerRefProps>
  ) => {
    const { append, fields, remove } = useFieldArray<
      AddCandidateShareFormData,
      'candidateShareInvitations'
    >({
      control,
      name: 'candidateShareInvitations'
    });

    const handleAddNew = useCallback<(newName?: string) => void>(
      (newName) => {
        append(
          {
            accountTypeId: '' as CandidateShareInvitationAccountTypeId,
            name: (newName || '') as CandidateShareInvitationName,
            email: '' as CandidateShareInvitationEmail
          },
          { shouldFocus: true }
        );
      },
      [append]
    );

    useImperativeHandle(ref, () => ({
      openNewInviteUserForm: (newUser) => {
        handleAddNew(newUser);
      }
    }));

    const handleRemoveItem = useCallback<(index: number) => void>(
      (index) => {
        remove(index);
        if (!size(fields)) {
          onClose();
        }
      },
      [fields, remove, onClose]
    );

    return (
      <Collapse in={isOpen}>
        <Stack spacing={2}>
          <FormLabel>Add new recipients</FormLabel>

          {fields?.map((field, index) => (
            <ShareCandidateProfileInviteUserForm
              key={field.id}
              index={index}
              onRemove={handleRemoveItem}
              control={control}
              currentAccountTypeId={
                find(
                  currentCandidateShareInvitations,
                  (csh, ind) => ind === index
                )?.accountTypeId
              }
              name="candidateShareInvitations"
              error={errorMessages?.[index]}
            />
          ))}

          <Flex alignItems="center" justifyContent="space-between">
            <Text textStyle="body1Regular">
              New recipients will be granted access to the{' '}
              {job.company?.name || 'Company'} nxTeammate portal to review
              profiles
            </Text>
            {size(currentCandidateShareInvitations) ? (
              <PureButtonHelper
                size="small"
                hierarchy="link"
                onClick={() => handleAddNew()}
                i18nText="Add more recipients"
              />
            ) : null}
          </Flex>
        </Stack>
      </Collapse>
    );
  }
);

ShareCandidateProfileInviteUserCollapseContainer.displayName =
  'ShareCandidateProfileInviteUserCollapseContainer';

export default ShareCandidateProfileInviteUserCollapseContainer;
