import { ButtonGroup } from '@chakra-ui/button';
import { Box, Flex, HStack, Stack } from '@chakra-ui/layout';
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay
} from '@chakra-ui/modal';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/tabs';
import { useRecruiterContext } from '../../../../../context/RecruiterContext';
import { Button } from '../../../../../helpers/Button';
import { InputField } from '../../../../../helpers/forms/formFields/InputField';
import { SelectFieldControl } from '../../../../../helpers/forms/formFields/SelectField/components/SelectFieldControl';
import { UploadAvatarFieldControl } from '../../../../../helpers/forms/formFields/UploadAvatarField/components/UploadAvatarFieldControl';
import { Heading } from '../../../../../helpers/Heading';
import { Text } from '../../../../../helpers/Text';
import { ChevronLeftIcon } from '../../../../../icons/ChevronLeftIcon';

interface AddRecruiterTabsProps {
  closeModal: () => void;
}

const AddRecruiter = () => {
  const { isAddRecruiterOpened, toggleAddRecruiter } = useRecruiterContext();

  return (
    <>
      <Drawer
        isOpen={isAddRecruiterOpened}
        onClose={toggleAddRecruiter}
        autoFocus={false}
        closeOnOverlayClick={false}
      >
        <DrawerOverlay width="calc(100% - 72px)" />

        <DrawerContent
          w="100%"
          maxW="54rem"
          boxShadow="none"
          marginRight={{ base: 0, lg: '72px' }}
        >
          <DrawerHeader px={8} pt={8} pb={4}>
            <Flex flexDir="column" alignItems="flex-start">
              <Button
                display="flex"
                flexDirection="row"
                alignItems="center"
                hierarchy="unstyled"
                onClick={toggleAddRecruiter}
                h={6}
                mb={8}
              >
                <ChevronLeftIcon />

                <Text
                  ml={1}
                  color="#697275"
                  fontSize="14px"
                  textStyle="regular"
                >
                  Close
                </Text>
              </Button>

              <Heading level="h2">
                <HStack>
                  <Box as="span" color="gray.600">
                    Add Recruiter
                  </Box>
                </HStack>
              </Heading>
            </Flex>
          </DrawerHeader>

          <DrawerBody px={0} pt={0} pb={8}>
            <Tabs>
              <TabList
                mb={2}
                borderBottom="1px solid"
                borderBottomColor="gray.200"
                px={8}
                position="sticky"
                top={0}
                bg="white"
                zIndex={1}
              >
                <Tab mb="-1px" px="0" fontWeight="medium">
                  Profile
                </Tab>
              </TabList>

              <TabPanels px={4}>
                <TabPanel>
                  <ProfileTab closeModal={toggleAddRecruiter} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default AddRecruiter;

const ProfileTab = ({ closeModal }: AddRecruiterTabsProps) => (
  <Tabs orientation="vertical" isLazy>
    <TabList alignItems="flex-start" border="none" flex="1" w="224px">
      <Box pos="sticky" top="65px">
        <Tab
          color="gray.600"
          p="0"
          mb="4"
          textAlign="left"
          justifyContent="flex-start"
          w="240px"
          _active={{ bg: 'transparent' }}
          _selected={{ color: 'gray.900' }}
        >
          About
        </Tab>
      </Box>
    </TabList>

    <TabPanels px={8}>
      <TabPanel p={0}>
        <Stack spacing={6}>
          <InputField label="Recruiter Name" />

          <Stack spacing={6} direction={{ base: 'column', lg: 'row' }}>
            <InputField label="Personal phone number" />

            <InputField label="Personal  email Id" />
          </Stack>

          <Stack spacing={6} direction={{ base: 'column', lg: 'row' }}>
            <InputField label="Position Title" />

            <SelectFieldControl
              label="Recruiter Type"
              options={[
                {
                  label: 'Corporate Talent Acquisition',
                  value: 'independent-recruiter'
                },
                {
                  label: 'Agency Recruiter',
                  value: 'agency-recruiter'
                },
                {
                  label: 'Independent Recruiter',
                  value: 'independent-recruiter'
                }
              ]}
              onChange={(e) => console.log(e)}
            />
          </Stack>

          <UploadAvatarFieldControl
            value={null}
            onChange={(e) => console.log(e)}
            size={20}
            label="Photos"
          />

          <Stack spacing={6} direction={{ base: 'column', lg: 'row' }}>
            <SelectFieldControl
              label="Company"
              options={[]}
              onChange={(e) => console.log(e)}
            />
            <SelectFieldControl
              label="Industry"
              options={[]}
              onChange={(e) => console.log(e)}
            />
          </Stack>

          <Stack spacing={6} direction={{ base: 'column', lg: 'row' }}>
            <InputField label="Company phone number" />
            <InputField label="Company email Id" />
          </Stack>

          <InputField label="Location" />

          <ButtonGroup spacing="6" justifyContent="flex-end">
            <Button hierarchy="tertiary" onClick={closeModal}>
              Cancel
            </Button>
            <Button>Save</Button>
          </ButtonGroup>
        </Stack>
      </TabPanel>
    </TabPanels>
  </Tabs>
);
