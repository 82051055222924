import {
  FetchCandidateShareRecipientsQuery,
  FetchCandidateShareRecipientsCountType,
  FetchCandidateShareRecipientsPage,
  FetchCandidateShareRecipientsPageSize,
  FetchCandidateShareRecipientsFilters,
  FetchCandidateShareRecipientsSort,
  FetchCandidateShareRecipientsIndexQueryName,
  FetchCandidateShareRecipientsIndexCountType,
  FetchCandidateShareRecipientsIndexPage,
  FetchCandidateShareRecipientsIndexPageSize,
  FetchCandidateShareRecipientsIndexFilters,
  FetchCandidateShareRecipientsIndexSort,
  FetchCandidateShareRecipientQuery,
  FetchCandidateShareRecipientIndexQueryName,
  CreateCandidateShareRecipientError,
  DeleteCandidateShareRecipientError,
  CandidateShareRecipientId,
  CandidateShareRecipientNanoId,
  CandidateShareRecipientCandidateShareId,
  CandidateShareRecipientRecipientId
} from './candidateShareRecipientsTypes';

import { BaseBffRequests } from '../BaseBffRequests';

import { CandidateShareRecipientsBffRoutes } from './CandidateShareRecipientsBffRoutes';

interface FetchCandidateShareRecipientsRequestParams {
  query: FetchCandidateShareRecipientsQuery;
  countType?: FetchCandidateShareRecipientsCountType;
  page?: FetchCandidateShareRecipientsPage;
  pageSize?: FetchCandidateShareRecipientsPageSize;
  filters?: FetchCandidateShareRecipientsFilters;
  sort?: FetchCandidateShareRecipientsSort;
}

interface FetchCandidateShareRecipientsIndexRequestParams {
  queryName: FetchCandidateShareRecipientsIndexQueryName;
  countType?: FetchCandidateShareRecipientsIndexCountType;
  page?: FetchCandidateShareRecipientsIndexPage;
  pageSize?: FetchCandidateShareRecipientsIndexPageSize;
  filters?: FetchCandidateShareRecipientsIndexFilters;
  sort?: FetchCandidateShareRecipientsIndexSort;
}

interface FetchCandidateShareRecipientRequestParams {
  query: FetchCandidateShareRecipientQuery;
}

interface FetchCandidateShareRecipientIndexRequestParams {
  queryName: FetchCandidateShareRecipientIndexQueryName;
}

export type CreateCandidateShareRecipientRequestData = {
  id?: CandidateShareRecipientId;
  candidateShareId: CandidateShareRecipientCandidateShareId;
  recipientId: CandidateShareRecipientRecipientId;
};

export interface CreateCandidateShareRecipientErrorResponse {
  message: CreateCandidateShareRecipientError;
}

export interface DeleteCandidateShareRecipientErrorResponse {
  message: DeleteCandidateShareRecipientError;
}

export class CandidateShareRecipientsBffRequests extends BaseBffRequests {
  static fetchCandidateShareRecipients<T>(
    params: FetchCandidateShareRecipientsRequestParams
  ) {
    return this.fetchItems<T>(
      CandidateShareRecipientsBffRoutes.fetchCandidateShareRecipientsRoute(),
      params
    );
  }

  static fetchCandidateShareRecipientsIndex<T>(
    params: FetchCandidateShareRecipientsIndexRequestParams
  ) {
    return this.fetchItemsIndex<T>(
      CandidateShareRecipientsBffRoutes.fetchCandidateShareRecipientsIndexRoute(),
      params
    );
  }

  static fetchCandidateShareRecipient<T>(
    candidateShareRecipientId: CandidateShareRecipientId,
    params: FetchCandidateShareRecipientRequestParams
  ) {
    return this.fetchItem<T>(
      CandidateShareRecipientsBffRoutes.fetchCandidateShareRecipientRoute(
        candidateShareRecipientId
      ),
      params
    );
  }

  static fetchCandidateShareRecipientIndex<T>(
    candidateShareRecipientNanoId: CandidateShareRecipientNanoId,
    params: FetchCandidateShareRecipientIndexRequestParams
  ) {
    return this.fetchItemIndex<T>(
      CandidateShareRecipientsBffRoutes.fetchCandidateShareRecipientIndexRoute(
        candidateShareRecipientNanoId
      ),
      params
    );
  }

  static fetchCandidateShareRecipientByNanoId<T>(
    candidateShareRecipientNanoId: CandidateShareRecipientNanoId,
    params: FetchCandidateShareRecipientRequestParams
  ) {
    return this.fetchItemByNanoId<T>(
      CandidateShareRecipientsBffRoutes.fetchCandidateShareRecipientByNanoIdRoute(
        candidateShareRecipientNanoId
      ),
      params
    );
  }

  static createCandidateShareRecipient<T>(
    createCandidateShareRecipientData: CreateCandidateShareRecipientRequestData
  ) {
    return this.createItem<T, CreateCandidateShareRecipientRequestData>(
      CandidateShareRecipientsBffRoutes.createCandidateShareRecipientRoute(),
      createCandidateShareRecipientData
    );
  }

  static async deleteCandidateShareRecipient<T>(
    deleteCandidateShareRecipientId:
      | CandidateShareRecipientId
      | CandidateShareRecipientNanoId
  ) {
    return this.deleteItem<T>(
      CandidateShareRecipientsBffRoutes.deleteCandidateShareRecipientRoute(
        deleteCandidateShareRecipientId
      )
    );
  }
}
