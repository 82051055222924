import { useCallback } from 'react';
import { Flex, Stack, StackDivider, Tooltip } from '@chakra-ui/react';
import { FieldValues, Path } from 'react-hook-form';

import { ErrorMessage } from '../../../../../../../../../types';

import { UserEmailLabel } from '../../../../../../../../userEmails/userEmailsTypes';

import { RemoveIcon } from '../../../../../../../../../icons/RemoveIcon';

import { IconButton } from '../../../../../../../../../helpers/buttons/IconButton';
import { InputField } from '../../../../../../../../../helpers/forms/formFields/InputField';

import { EditUserFormAddEducationFieldControl } from './EditUserFormAddEducationField.types';
import { DateInputField } from '../../../../../../../../../helpers/forms/formFields/DateInputField';

interface EditUserFormAddEducationFieldProps<T extends FieldValues> {
  control: EditUserFormAddEducationFieldControl<T>;
  name: string;
  index: number;
  withoutRemove?: boolean;
  error?: { [key: string]: { message?: ErrorMessage } };
  labelValue?: UserEmailLabel;
  onRemove: (index: number) => void;
}

function EditUserFormAddEducationField<T extends FieldValues>({
  control,
  name,
  index,
  error,
  withoutRemove,
  onRemove
}: EditUserFormAddEducationFieldProps<T>) {
  const handleRemove = useCallback<() => void>(
    () => onRemove(index),
    [index, onRemove]
  );

  return (
    <Stack
      spacing={0}
      border="1px solid"
      borderRadius="base"
      position="relative"
      borderColor="gray.200"
      divider={<StackDivider />}
    >
      <Flex alignItems="center">
        <InputField
          flex={1}
          border="none"
          borderRadius={0}
          borderRight="1px solid"
          borderRightColor="gray.200"
          placeholder="Enter school name"
          {...control.register(`${name}.${index}.schoolName` as Path<T>)}
          errorMessage={error?.schoolName?.message}
        />

        <Tooltip
          label={withoutRemove ? undefined : 'Remove education'}
          placement="end"
          hasArrow
        >
          <IconButton
            mx={1}
            size="small"
            value={index}
            aria-label={''}
            hierarchy="link"
            colorScheme="red"
            onClick={handleRemove}
            cursor={withoutRemove ? 'not-allowed' : 'pointer'}
            icon={
              <RemoveIcon
                fill={withoutRemove ? 'red.200' : 'red.500'}
                w={5}
                h={5}
              />
            }
          />
        </Tooltip>
      </Flex>

      <InputField
        border="none"
        placeholder="Enter degree"
        {...control.register(`${name}.${index}.degree` as Path<T>)}
        errorMessage={error?.degree?.message}
      />

      <InputField
        border="none"
        placeholder="Specialization"
        {...control.register(`${name}.${index}.studyField` as Path<T>)}
        errorMessage={error?.studyField?.message}
      />

      <Stack spacing={0} direction="row" divider={<StackDivider />}>
        <Flex flex={1} position="relative">
          <DateInputField
            border="none"
            placeholder="From date"
            type="month"
            {...control.register(`${name}.${index}.startDate` as Path<T>)}
            errorMessage={error?.startDate?.message}
          />
        </Flex>

        <Flex flex={1} position="relative">
          <DateInputField
            border="none"
            placeholder="To date"
            type="month"
            {...control.register(`${name}.${index}.endDate` as Path<T>)}
            errorMessage={error?.endDate?.message}
          />
        </Flex>
      </Stack>
    </Stack>
  );
}

export default EditUserFormAddEducationField;
