import {
  FetchJobsCacheKey,
  FetchJobCacheKey,
  JobNanoId,
  FetchJobsCitiesCacheKey,
  FetchJobsHiringManagersCacheKey
} from './jobsTypes';

import { UserId } from '../users/usersTypes';
import { JobCandidateId } from '../jobCandidates/jobCandidatesTypes';

export class JobsCache {
  static indexCacheKey() {
    return 'jobs' as FetchJobsCacheKey;
  }

  static recruiterIndexCacheKey(recruiterId: UserId) {
    return `jobs-${recruiterId}` as FetchJobsCacheKey;
  }

  static selectionCacheKey() {
    return 'jobs-selection' as FetchJobsCacheKey;
  }

  static showCacheKey() {
    return 'job' as FetchJobCacheKey;
  }

  static showJobCacheKey(jobNanoId: JobNanoId) {
    return `job-${jobNanoId}` as FetchJobCacheKey;
  }

  static bffShowCacheKey() {
    return 'job-bff' as FetchJobCacheKey;
  }

  static showAnonymousCacheKey() {
    return 'job-anonymous' as FetchJobCacheKey;
  }

  static postJobDetailsCacheKey() {
    return 'post-job-details' as FetchJobCacheKey;
  }

  static postJobIntakeCacheKey() {
    return 'post-job-intake' as FetchJobCacheKey;
  }

  static signUpCacheKey() {
    return 'jobs-signUp' as FetchJobsCacheKey;
  }

  static jobsCitiesCacheKey() {
    return 'jobs-cities' as FetchJobsCitiesCacheKey;
  }

  static jobsHiringManagersCacheKey() {
    return 'jobs-hiring-managers' as FetchJobsHiringManagersCacheKey;
  }

  static jobJobCandidateIndexCacheKey(jobCandidateId: JobCandidateId) {
    return `jobs-jobCandidate-${jobCandidateId}` as FetchJobsCacheKey;
  }
}
