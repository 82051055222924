import { JobExperience } from '../../jobsTypes';
import { TalentPoolExperience } from '../../../talentPools/talentPoolsTypes';

import { experienceOptionsHash } from '../../jobs.data';

function renderJobExperience(experience: JobExperience | TalentPoolExperience) {
  return experienceOptionsHash[experience];
}

export default renderJobExperience;
