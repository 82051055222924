import React from 'react';
import { FieldValues } from 'react-hook-form';
import debounce from 'lodash/debounce';
import uniq from 'lodash/uniq';
import flatMap from 'lodash/flatMap';

import { ErrorMessage, I18nText } from '../../../../../types';

import {
  FetchEducationsFilters,
  FetchEducationsPageSize,
  FetchEducationsSort,
  EducationFields
} from '../../../../educations/educationsTypes';

import {
  fetchEducationsQuery,
  FetchEducationsResponse
} from '../../../../educations/queries/fetchEducations.query';

import { usePaginatedEducations } from '../../../../educations/hooks/usePaginatedEducations';

import { EducationsCache } from '../../../../educations/EducationsCache';

import {
  SelectField,
  SelectFieldReactHookFormControl,
  SelectFieldReactHookFormFieldPath
} from '../../../../../helpers/forms/formFields/SelectField';

interface SelectStudyFieldFormFieldItem {
  studyField: string;
}

interface SelectStudyFieldFormFieldProps<T extends FieldValues> {
  control: SelectFieldReactHookFormControl<T>;
  label?: I18nText;
  placeholder?: string;
  item?: SelectStudyFieldFormFieldItem;
  errorMessage: ErrorMessage;
  withoutLabel?: boolean;
}

function SelectStudyFieldFormField<T extends FieldValues>({
  control,
  label = 'Study Field',
  placeholder,
  withoutLabel,
  item,
  errorMessage
}: SelectStudyFieldFormFieldProps<T>) {
  const {
    educations,
    educationsIsLoading,
    educationsErrorMessage,
    changeEducationsFilters
  } = usePaginatedEducations<FetchEducationsResponse>({
    query: fetchEducationsQuery,
    cacheKey: EducationsCache.studyFieldCacheKey(),
    initialPageSize: 100 as FetchEducationsPageSize,
    initialFilters: {
      ...(item?.studyField
        ? {
            [EducationFields.STUDY_FIELD]: {
              operator: 'ilike',
              value: `${item.studyField}%`
            }
          }
        : {})
    } as unknown as FetchEducationsFilters,
    initialSort: {
      [EducationFields.STUDY_FIELD]: { ascending: true }
    } as unknown as FetchEducationsSort
  });

  const defaultCurrentStudyField = item?.studyField
    ? {
        value: item?.studyField,
        label: item?.studyField
      }
    : undefined;

  const debouncedFilterEducations = debounce<(updatedValue: string) => void>(
    (updatedValue) =>
      changeEducationsFilters({
        [EducationFields.STUDY_FIELD]: {
          operator: 'ilike',
          value: `${updatedValue}%`
        }
      })
  );

  const options = uniq(
    flatMap(educations, (education) => education.studyField)
  );

  return (
    <SelectField
      label={withoutLabel ? undefined : label}
      options={options.map((studyField) => ({
        value: studyField,
        label: studyField
      }))}
      placeholder={placeholder || 'Select study field'}
      control={control}
      name={'studyField' as SelectFieldReactHookFormFieldPath<T>}
      isLoading={educationsIsLoading}
      defaultValue={defaultCurrentStudyField}
      errorMessage={errorMessage || educationsErrorMessage}
      onInputChange={debouncedFilterEducations}
    />
  );
}

export default SelectStudyFieldFormField;
