import React from 'react';

import { ErrorMessage, I18nText } from '../../../../../types';

import { IndustryExpertiseJobId } from '../../../../industryExpertises/industryExpertisesTypes';
import { JobSelectIndustryExpertisesFieldJob } from './JobSelectIndustryExpertisesField.types';

import { CommonIndustriesBox } from '../../../../industries/components/boxes/CommonIndustriesBox';

import { IndustryExpertisesCache } from '../../../../industryExpertises/IndustryExpertisesCache';

interface JobSelectIndustryExpertisesFieldProps {
  label: I18nText;
  job: JobSelectIndustryExpertisesFieldJob;
  errorMessage?: ErrorMessage;
}

function JobSelectIndustryExpertisesField({
  label,
  job,
  errorMessage
}: JobSelectIndustryExpertisesFieldProps) {
  return (
    <CommonIndustriesBox
      label={label}
      errorMessage={errorMessage}
      job={job}
      industryExpertisesCacheKey={IndustryExpertisesCache.postJobCacheKey(
        job.id as IndustryExpertiseJobId
      )}
    />
  );
}

export default JobSelectIndustryExpertisesField;
