import React from 'react';
import { FieldValues } from 'react-hook-form';
import debounce from 'lodash/debounce';
import first from 'lodash/first';
import find from 'lodash/find';

import { ErrorMessage, I18nText } from '../../../../../types';

import {
  FetchAccountTypesFilters,
  FetchAccountTypesPageSize,
  FetchAccountTypesSort,
  AccountTypeFields,
  AccountTypeId,
  AccountTypeName
} from '../../../../accountTypes/accountTypesTypes';

import {
  FetchAccountTypesResponse,
  fetchAccountTypesQuery
} from '../../../../accountTypes/queries/fetchAccountTypes.query';

import { usePaginatedAccountTypes } from '../../../../accountTypes/hooks/usePaginatedAccountTypes';

import { AccountTypesCache } from '../../../../accountTypes/AccountTypesCache';

import {
  SelectField,
  SelectFieldReactHookFormControl,
  SelectFieldReactHookFormFieldPath,
  SelectFieldReactHookFormRules
} from '../../../../../helpers/forms/formFields/SelectField';

interface SelectAccountTypeFormFieldItem {
  accountType: {
    id: AccountTypeId;
    name: AccountTypeName;
  } | null;
}

interface SelectAccountTypeFormFieldProps<T extends FieldValues> {
  control: SelectFieldReactHookFormControl<T>;
  label?: I18nText;
  placeholder?: I18nText;
  withoutLabel?: boolean;
  errorMessage: ErrorMessage;
  item?: SelectAccountTypeFormFieldItem;
  currentAccountTypeId?: AccountTypeId;
  isDisabled?: boolean;
  isRequired?: boolean;
  rules?: SelectFieldReactHookFormRules<T>;
  name?: SelectFieldReactHookFormFieldPath<T>;
  withoutSearchInput?: boolean;
}

function SelectAccountTypeFormField<T extends FieldValues>({
  control,
  label = 'Account Type',
  placeholder,
  withoutLabel,
  errorMessage,
  item,
  name,
  currentAccountTypeId,
  isDisabled,
  isRequired,
  rules,
  withoutSearchInput
}: SelectAccountTypeFormFieldProps<T>) {
  const {
    accountTypes,
    accountTypesIsLoading,
    accountTypesErrorMessage,
    changeAccountTypesFilters
  } = usePaginatedAccountTypes<FetchAccountTypesResponse>({
    query: fetchAccountTypesQuery,
    cacheKey: AccountTypesCache.indexCacheKey(),
    initialPageSize: 100 as FetchAccountTypesPageSize,
    initialFilters: {
      ...(item?.accountType
        ? {
            [AccountTypeFields.NAME]: {
              operator: 'ilike',
              value: `${first(item.accountType.name)}%`
            }
          }
        : {})
    } as unknown as FetchAccountTypesFilters,
    initialSort: {
      [AccountTypeFields.NAME]: { ascending: true }
    } as unknown as FetchAccountTypesSort
  });

  const defaultCurrentAccountType = item?.accountType
    ? { value: item.accountType.id, label: item.accountType.name }
    : undefined;

  const defaultAccountTypeFromAccountTypes =
    withoutSearchInput && currentAccountTypeId
      ? find(accountTypes, ({ id }) => id === currentAccountTypeId)
      : undefined;

  const defaultCurrentAccountTypeFromAccountTypes =
    defaultAccountTypeFromAccountTypes
      ? {
          value: defaultAccountTypeFromAccountTypes.id,
          label: defaultAccountTypeFromAccountTypes.name
        }
      : undefined;

  const debouncedFilterAccountTypes = debounce<(updatedValue: string) => void>(
    (updatedValue) =>
      changeAccountTypesFilters({
        [AccountTypeFields.NAME]: {
          operator: 'ilike',
          value: `${updatedValue}%`
        }
      })
  );

  return (
    <SelectField
      label={withoutLabel ? '' : label}
      placeholder={placeholder}
      options={accountTypes.map((accountType) => ({
        value: accountType.id,
        label: accountType.name
      }))}
      control={control}
      name={name || ('accountTypeId' as SelectFieldReactHookFormFieldPath<T>)}
      isLoading={accountTypesIsLoading}
      isDisabled={isDisabled}
      isRequired={isRequired}
      defaultValue={
        defaultCurrentAccountType || defaultCurrentAccountTypeFromAccountTypes
      }
      errorMessage={errorMessage || accountTypesErrorMessage}
      onInputChange={debouncedFilterAccountTypes}
      rules={rules}
      isSearchable={!withoutSearchInput}
    />
  );
}

export default SelectAccountTypeFormField;
