import { Alert, AlertIcon } from '@chakra-ui/alert';
import React from 'react';

import { SuccessMessage, WarningMessage, ErrorMessage } from '../../types';
import { CloseButton, CloseButtonProps } from '@chakra-ui/react';

const mapAlertColor = (textColor: AlertMessageProps['textColor']) => {
  switch (textColor) {
    case 'green':
      return 'success';

    case 'yellow':
      return 'warning';

    case 'red':
      return 'error';

    case 'blue':
      return 'info';
  }
};

interface AlertMessageProps {
  textColor?: 'green' | 'yellow' | 'red' | 'blue';
  message: SuccessMessage | WarningMessage | ErrorMessage;
  color?: string;
  onClose?: CloseButtonProps['onClick'];
}

function AlertMessage({
  textColor = 'red',
  message,
  color,
  onClose
}: AlertMessageProps) {
  return message ? (
    <Alert
      status={mapAlertColor(textColor)}
      color={color}
      wordBreak="break-all"
    >
      <AlertIcon />
      {message}

      {onClose ? (
        <CloseButton
          alignSelf="flex-start"
          position="relative"
          size="sm"
          right={-1}
          top={-1}
          onClick={onClose}
        />
      ) : null}
    </Alert>
  ) : null;
}

export default AlertMessage;
