import {
  MayBe,
  FieldBrand,
  ItemID,
  ItemUUID,
  ItemNanoId,
  ItemCreatedAt
} from '../../../types';

import { RoleId, RoleNanoId, RoleName } from '../../roles/rolesTypes';

import { UserId, UserNanoId, UserName } from '../../users/usersTypes';

export type AccountTypeId = FieldBrand<ItemID, 'AccountTypeId'>;
export type MayBeAccountTypeId = MayBe<AccountTypeId>;
export type AccountTypeUuid = FieldBrand<ItemUUID, 'AccountTypeUuid'>;
export type MayBeAccountTypeUuid = MayBe<AccountTypeUuid>;
export type AccountTypeNanoId = FieldBrand<ItemNanoId, 'AccountTypeNanoId'>;
export type MayBeAccountTypeNanoId = MayBe<AccountTypeNanoId>;
export type AccountTypeName = FieldBrand<string, 'AccountTypeName'>;
export type AccountTypeScope = FieldBrand<string, 'AccountTypeScope'>;
export type AccountTypeUserId = FieldBrand<UserId, 'AccountTypeUserId'>;
export type AccountTypeUser = FieldBrand<
  {
    id: UserId;
    nanoId: UserNanoId;
    name: UserName;
  },
  'AccountTypeUser'
>;
export type AccountTypeRoles = FieldBrand<
  {
    id: RoleId;
    nanoId: RoleNanoId;
    name: RoleName;
  }[],
  'AccountTypeRoles'
>;
export type AccountTypeCreatedAt = FieldBrand<
  ItemCreatedAt,
  'AccountTypeCreatedAt'
>;

export const enum AccountTypeScopes {
  NONE = 'none',
  SIGN_UP = 'sign_up',
  INVITATION = 'invitation'
}

export const RecruiterAccountTypes = [
  'Recruiter',
  'Recruiter Admin',
  'Recruiter Coordinator'
];

export const enum AccountTypeFields {
  ID = 'id',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  NAME = 'name',
  SCOPE = 'scope',
  USER_ID = 'userId',
  USER = 'user',
  CREATED_AT = 'createdAt'
}
