import {
  FetchHtmlPagesQuery,
  FetchHtmlPagesCountType,
  FetchHtmlPagesPage,
  FetchHtmlPagesPageSize,
  FetchHtmlPagesFilters,
  FetchHtmlPagesSort,
  FetchHtmlPagesIndexQueryName,
  FetchHtmlPagesIndexCountType,
  FetchHtmlPagesIndexPage,
  FetchHtmlPagesIndexPageSize,
  FetchHtmlPagesIndexFilters,
  FetchHtmlPagesIndexSort,
  FetchHtmlPageQuery,
  FetchHtmlPageIndexQueryName,
  CreateHtmlPageError,
  UpdateHtmlPageError,
  DeleteHtmlPageError,
  HtmlPageId,
  HtmlPageNanoId,
  HtmlPageName,
  HtmlPageTitle,
  HtmlPageBody,
  HtmlPageUserId
} from './htmlPagesTypes';

import { BaseBffRequests } from '../BaseBffRequests';

import { HtmlPagesBffRoutes } from './HtmlPagesBffRoutes';

interface FetchHtmlPagesRequestParams {
  query: FetchHtmlPagesQuery;
  countType?: FetchHtmlPagesCountType;
  page?: FetchHtmlPagesPage;
  pageSize?: FetchHtmlPagesPageSize;
  filters?: FetchHtmlPagesFilters;
  sort?: FetchHtmlPagesSort;
}

interface FetchHtmlPagesIndexRequestParams {
  queryName: FetchHtmlPagesIndexQueryName;
  countType?: FetchHtmlPagesIndexCountType;
  page?: FetchHtmlPagesIndexPage;
  pageSize?: FetchHtmlPagesIndexPageSize;
  filters?: FetchHtmlPagesIndexFilters;
  sort?: FetchHtmlPagesIndexSort;
}

interface FetchHtmlPageRequestParams {
  query: FetchHtmlPageQuery;
}

interface FetchHtmlPageIndexRequestParams {
  queryName: FetchHtmlPageIndexQueryName;
}

export interface HtmlPageRequestData {
  name?: HtmlPageName;
  title?: HtmlPageTitle;
  body?: HtmlPageBody;
}

export interface CreateHtmlPageDefaultRequestData extends HtmlPageRequestData {
  id?: HtmlPageId;
  userId: HtmlPageUserId;
  nanoId: HtmlPageNanoId;
}

export type CreateHtmlPageRequestData = CreateHtmlPageDefaultRequestData;

export type UpdateHtmlPageRequestData = HtmlPageRequestData;

export interface DeleteHtmlPageRequestData {
  htmlPageNanoId: HtmlPageNanoId;
}

export interface CreateHtmlPageErrorResponse {
  message: CreateHtmlPageError;
}

export interface UpdateHtmlPageErrorResponse {
  message: UpdateHtmlPageError;
}

export interface DeleteHtmlPageErrorResponse {
  message: DeleteHtmlPageError;
}

export class HtmlPagesBffRequests extends BaseBffRequests {
  static fetchHtmlPages<T>(params: FetchHtmlPagesRequestParams) {
    return this.fetchItems<T>(HtmlPagesBffRoutes.fetchHtmlPagesRoute(), params);
  }

  static fetchHtmlPagesIndex<T>(params: FetchHtmlPagesIndexRequestParams) {
    return this.fetchItemsIndex<T>(
      HtmlPagesBffRoutes.fetchHtmlPagesIndexRoute(),
      params
    );
  }

  static fetchHtmlPage<T>(
    htmlPageId: HtmlPageId,
    params: FetchHtmlPageRequestParams
  ) {
    return this.fetchItem<T>(
      HtmlPagesBffRoutes.fetchHtmlPageRoute(htmlPageId),
      params
    );
  }

  static fetchHtmlPageIndex<T>(
    htmlPageNanoId: HtmlPageNanoId,
    params: FetchHtmlPageIndexRequestParams
  ) {
    return this.fetchItemIndex<T>(
      HtmlPagesBffRoutes.fetchHtmlPageIndexRoute(htmlPageNanoId),
      params
    );
  }

  static fetchHtmlPageByNanoId<T>(
    htmlPageNanoId: HtmlPageNanoId,
    params: FetchHtmlPageRequestParams
  ) {
    return this.fetchItemByNanoId<T>(
      HtmlPagesBffRoutes.fetchHtmlPageByNanoIdRoute(htmlPageNanoId),
      params
    );
  }

  static createHtmlPage<T>(createHtmlPageData: CreateHtmlPageRequestData) {
    return this.createItem<T, CreateHtmlPageRequestData>(
      HtmlPagesBffRoutes.createHtmlPageRoute(),
      createHtmlPageData
    );
  }

  static async updateHtmlPage<T>(
    updateHtmlPageNanoId: HtmlPageNanoId,
    updateHtmlPageData: UpdateHtmlPageRequestData
  ) {
    return this.updateItem<T, UpdateHtmlPageRequestData>(
      HtmlPagesBffRoutes.updateHtmlPageRoute(updateHtmlPageNanoId),
      updateHtmlPageData
    );
  }

  static async deleteHtmlPage<T>(deleteHtmlPageNanoId: HtmlPageNanoId) {
    return this.deleteItem<T>(
      HtmlPagesBffRoutes.deleteHtmlPageRoute(deleteHtmlPageNanoId)
    );
  }
}
