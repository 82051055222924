import { FetchItemsFilters } from '../../../../../../types';

import { PaginatedItemsReducerAction } from '../../reducers/paginatedItemsReducer';

import { FILTER_ITEMS } from '../../usePaginatedItems.constants';

function filterItemsAction(
  nextFilters: FetchItemsFilters
): PaginatedItemsReducerAction {
  return {
    type: FILTER_ITEMS,
    nextFilters
  };
}

export default filterItemsAction;
