import { useDisclosure, useToast } from '@chakra-ui/react';
import startCase from 'lodash/startCase';
import {
  MouseEvent,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react';
import {
  StageTaskStatusType,
  StageTaskType
} from '../../ViewJobCandidateDetailsPage.types';
import {
  TaskStatusType,
  ViewJobCandidateDetailsTasksWidgetCandidate,
  ViewJobCandidateDetailsTasksWidgetContextType,
  ViewJobCandidateDetailsTasksWidgetProviderProps
} from './ViewJobCandidateDetailsTasksWidgetContext.types';

const ViewJobCandidateDetailsTasksWidgetContext =
  createContext<ViewJobCandidateDetailsTasksWidgetContextType>({
    skipTask: function (): void {
      /*  */
    },
    cancelTask: function (): void {
      /*  */
    },
    stageTasks: [],
    selectedTask: undefined,
    handleSelectTask: function (): void {
      /*  */
    },
    selectedTaskAction: undefined,
    handleSelectTaskAction: function (): void {
      /*  */
    },

    isCandidateDisqualified: false,
    disqualificationReason: undefined,
    disqualifyCandidate: function (): void {
      /*  */
    },
    revertDisqualifyCandidate: function (): void {
      /*  */
    },

    handleCompleteTaskAction: function (): void {
      /*  */
    },
    handleReopenTaskAction: function (): void {
      /*  */
    },

    closeSubActionsDropdown: function (): void {
      /*  */
    },
    openSubActionsDropdown: function (): void {
      /*  */
    },
    isSubActionsDropdownOpen: false,

    taskStatus: { label: 'new-task', color: 'blue.500' },

    jobCandidate: {} as ViewJobCandidateDetailsTasksWidgetCandidate
  });

export function ViewJobCandidateDetailsTasksWidgetProvider({
  children,
  jobCandidate
}: ViewJobCandidateDetailsTasksWidgetProviderProps) {
  const toast = useToast();

  const [stageTasks, setStageTasks] = useState<StageTaskType[]>([
    {
      name: 'Schedule 1st interview session',
      nanoId: 'schedule',
      status: 'not-started',
      completed: ''
    },
    {
      name: 'Debrief',
      nanoId: 'debrief',
      status: 'not-started',
      completed: ''
    },
    {
      name: 'Feedback',
      nanoId: 'feedback',
      status: 'not-started',
      completed: ''
    }
  ]);

  const [selectedTask, setSelectedTask] = useState<string | undefined>();

  const [selectedTaskAction, setSelectedTaskAction] = useState<
    string | undefined
  >();

  const [disqualificationReason, setDisqualificationReason] = useState<
    string | undefined
  >();

  const { onOpen: completeTaskAction, onClose: reOpenCompletedTaskAction } =
    useDisclosure({
      onOpen: () => {
        toast.closeAll();

        toast({
          title: `${startCase(selectedTaskAction)} action completed`,
          status: 'success',
          position: 'top-right',
          duration: 2000,
          isClosable: true
        });
      },
      onClose: () => {
        toast.closeAll();

        toast({
          title: `${startCase(selectedTaskAction)} action marked as open`,
          status: 'success',
          position: 'top-right',
          duration: 2000,
          isClosable: true
        });

        setDisqualificationReason(undefined);
        setSelectedTaskAction(undefined);
      }
    });

  const {
    isOpen: isCandidateDisqualified,
    onOpen: onDisqualifyCandidate,
    onClose: revertDisqualifyCandidate
  } = useDisclosure({
    onClose: () => {
      setDisqualificationReason(undefined);
      setSelectedTaskAction(undefined);
      setSelectedTask(undefined);
    }
  });

  const {
    isOpen: isSubActionsDropdownOpen,
    onOpen: openSubActionsDropdown,
    onClose: closeSubActionsDropdown
  } = useDisclosure();

  const handleSelectTask = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    setSelectedTask(e.currentTarget.value);
  }, []);

  const changeStageTaskStatus = useCallback(
    (stageTaskId: string, status: StageTaskStatusType) => {
      setStageTasks((prevStageTasks) => {
        const updatedTasks = prevStageTasks.map((task) =>
          task.nanoId === stageTaskId
            ? {
                ...task,
                status,
                ...(status === 'completed'
                  ? {
                      completed: selectedTaskAction ?? ''
                    }
                  : {
                      completed: ''
                    })
              }
            : task
        );
        return updatedTasks;
      });
    },
    [selectedTaskAction]
  );

  const gotoNextTask = useCallback(
    (stageTaskId: string) => {
      const currentTaskIndex = stageTasks.findIndex(
        (task) => task.nanoId === stageTaskId
      );

      if (currentTaskIndex !== -1 && currentTaskIndex < stageTasks.length - 1) {
        const nextTaskIndex = currentTaskIndex + 1;

        if (stageTasks[nextTaskIndex].status === 'not-started') {
          setSelectedTask(stageTasks[nextTaskIndex].nanoId);

          return;
        }
      }

      const nextNanoId = stageTasks.find(
        (task) => task.status === 'not-started'
      )?.nanoId;

      if (nextNanoId) {
        setSelectedTask(nextNanoId);
      }

      setSelectedTaskAction(undefined);
    },
    [stageTasks]
  );

  const handleCompleteTaskAction = useCallback(
    (stageTaskId) => {
      completeTaskAction();
      changeStageTaskStatus(stageTaskId, 'completed');
      gotoNextTask(stageTaskId);
    },
    [changeStageTaskStatus, completeTaskAction, gotoNextTask]
  );

  const handleReopenTaskAction = useCallback(
    (stageTaskId) => {
      reOpenCompletedTaskAction();
      changeStageTaskStatus(stageTaskId, 'not-started');
    },
    [changeStageTaskStatus, reOpenCompletedTaskAction]
  );

  const skipTask = useCallback(() => {
    handleCompleteTaskAction(selectedTask);
  }, [handleCompleteTaskAction, selectedTask]);

  const cancelTask = useCallback(() => {
    changeStageTaskStatus(selectedTask ?? '', 'not-started');
    setSelectedTask(undefined);
    setSelectedTaskAction(undefined);
  }, [changeStageTaskStatus, selectedTask]);

  const handleSelectTaskAction = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      if (selectedTask) {
        const actionValue = `${e.currentTarget.value}__${selectedTask}`;
        setSelectedTaskAction(actionValue);
        changeStageTaskStatus(selectedTask, 'in-progress');
      }
    },
    [selectedTask, changeStageTaskStatus]
  );

  const taskStatus: TaskStatusType = useMemo(() => {
    if (selectedTask) {
      const selectedTaskIndex = stageTasks.findIndex(
        (task) => task.nanoId === selectedTask
      );

      const selectedTaskStatus = stageTasks[selectedTaskIndex].status;

      if (selectedTaskStatus === 'completed')
        return { label: 'completed', color: 'green.500' };
      if (selectedTaskStatus === 'in-progress')
        return { label: 'in-progress', color: 'green.500' };
      return { label: 'start-task', color: 'yellow.500' };
    }
    return { label: 'new-task', color: 'blue.500' };
  }, [selectedTask, stageTasks]);

  const disqualifyCandidate = useCallback(
    (reason?: string) => {
      setDisqualificationReason(reason ?? '');
      onDisqualifyCandidate();
    },
    [onDisqualifyCandidate]
  );

  const value: ViewJobCandidateDetailsTasksWidgetContextType = useMemo(
    () => ({
      disqualifyCandidate,
      disqualificationReason,
      revertDisqualifyCandidate,
      isCandidateDisqualified,

      closeSubActionsDropdown,
      openSubActionsDropdown,
      isSubActionsDropdownOpen,

      skipTask,
      stageTasks,
      selectedTask,
      cancelTask,

      handleSelectTask,

      selectedTaskAction,
      handleSelectTaskAction,

      handleCompleteTaskAction,
      handleReopenTaskAction,

      taskStatus,

      jobCandidate
    }),
    [
      disqualifyCandidate,
      disqualificationReason,
      revertDisqualifyCandidate,
      isCandidateDisqualified,

      closeSubActionsDropdown,
      openSubActionsDropdown,
      isSubActionsDropdownOpen,

      skipTask,
      stageTasks,
      selectedTask,
      cancelTask,

      handleSelectTask,

      selectedTaskAction,
      handleSelectTaskAction,

      handleCompleteTaskAction,
      handleReopenTaskAction,

      taskStatus,

      jobCandidate
    ]
  );

  return (
    <ViewJobCandidateDetailsTasksWidgetContext.Provider value={value}>
      {children}
    </ViewJobCandidateDetailsTasksWidgetContext.Provider>
  );
}

export const useViewJobCandidateDetailsTasksWidgetContext = () =>
  useContext(ViewJobCandidateDetailsTasksWidgetContext);
