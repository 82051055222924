import {
  FetchJobCandidatesTable,
  FetchJobCandidatesQuery,
  FetchJobCandidatesCountType,
  FetchJobCandidatesPage,
  FetchJobCandidatesPageSize,
  FetchJobCandidatesFilters,
  FetchJobCandidatesSort,
  FetchJobCandidateTable,
  FetchJobCandidateQuery,
  FetchJobCandidateID,
  FetchJobCandidateNanoID,
  CreateJobCandidateTable,
  CreateJobCandidateError,
  UpdateJobCandidateTable,
  UpdateJobCandidateID,
  UpdateJobCandidateError,
  DeleteJobCandidateTable,
  DeleteJobCandidateID,
  DeleteJobCandidateError,
  UpdateJobCandidateNanoId,
  JobCandidateId,
  JobCandidateNanoId,
  JobCandidateName,
  JobCandidateEmail,
  JobCandidatePhone,
  JobCandidateJobTitle,
  JobCandidateUserId,
  JobCandidateJobId,
  JobCandidateWorkflowStageId,
  JobCandidateWorkflowStageTaskApplicationType,
  MayBeJobCandidateWorkflowStageTaskScheduledAt,
  MayBeJobCandidateDisqualifiedAt,
  JobCandidateDisqualificationReason,
  JobCandidateUpdatedAt,
  JobCandidateArrangement,
  JobCandidateSearchTypeLabel,
  MayBeJobCandidateDueDate,
  MayBeJobCandidateViewedAt,
  MayBeJobCandidateNewWorkflowStageTaskAt
} from './jobCandidatesTypes';

import { BaseRequests } from '../BaseRequests';

export interface FetchJobCandidatesOptions {
  query: FetchJobCandidatesQuery;
  countType?: FetchJobCandidatesCountType;
  page?: FetchJobCandidatesPage;
  pageSize?: FetchJobCandidatesPageSize;
  filters?: FetchJobCandidatesFilters;
  sort?: FetchJobCandidatesSort;
}

export interface FetchJobCandidateOptions {
  query: FetchJobCandidateQuery;
}

export interface JobCandidateRequestData {
  name?: JobCandidateName;
  email?: JobCandidateEmail;
  phone?: JobCandidatePhone;
  searchTypeLabel?: JobCandidateSearchTypeLabel;
  jobTitle?: JobCandidateJobTitle;
  workflowStageId?: JobCandidateWorkflowStageId;
  workflowStageTaskId?: JobCandidateWorkflowStageId;
  workflowStageTaskApplicationType?: JobCandidateWorkflowStageTaskApplicationType;
  workflowStageTaskScheduledAt?: MayBeJobCandidateWorkflowStageTaskScheduledAt;
  newWorkflowStageTaskAt?: MayBeJobCandidateNewWorkflowStageTaskAt;
  viewedAt?: MayBeJobCandidateViewedAt;
  disqualifiedAt?: MayBeJobCandidateDisqualifiedAt;
  disqualificationReason?: JobCandidateDisqualificationReason;
  updatedAt?: JobCandidateUpdatedAt;
  arrangement?: JobCandidateArrangement;
  dueDate?: MayBeJobCandidateDueDate;
}

export interface CreateJobCandidateRequestData extends JobCandidateRequestData {
  id?: JobCandidateId;
  userId: JobCandidateUserId;
  jobId?: JobCandidateJobId;
  nanoId: JobCandidateNanoId;
}

export interface UpdateJobCandidateRequestData extends JobCandidateRequestData {
  id?: JobCandidateId;
  nanoId?: JobCandidateNanoId;
}

export interface CreateJobCandidateErrorResponse {
  message: CreateJobCandidateError;
}

export interface UpdateJobCandidateErrorResponse {
  message: UpdateJobCandidateError;
}

export interface DeleteJobCandidateErrorResponse {
  message: DeleteJobCandidateError;
}

export class JobCandidatesRequests extends BaseRequests {
  static async fetchJobCandidates<T>(options: FetchJobCandidatesOptions) {
    return this.fetchItems<T>(
      'jobCandidates' as FetchJobCandidatesTable,
      options
    );
  }

  static async fetchJobCandidate<T>(
    fetchJobCandidateId: FetchJobCandidateID,
    options: FetchJobCandidateOptions
  ) {
    return this.fetchItem<T>(
      'jobCandidates' as FetchJobCandidateTable,
      fetchJobCandidateId,
      options
    );
  }

  static async fetchJobCandidateByNanoId<T>(
    fetchJobCandidateNanoId: FetchJobCandidateNanoID,
    options: FetchJobCandidateOptions
  ) {
    return this.fetchItemByNanoId<T>(
      'jobCandidates' as FetchJobCandidateTable,
      fetchJobCandidateNanoId,
      options
    );
  }

  static async createJobCandidate(
    createJobCandidateData: CreateJobCandidateRequestData
  ) {
    return this.createItem<CreateJobCandidateRequestData>(
      'jobCandidates' as CreateJobCandidateTable,
      createJobCandidateData
    );
  }

  static async updateJobCandidate(
    updateJobCandidateId: UpdateJobCandidateID | UpdateJobCandidateNanoId,
    updateJobCandidateData: UpdateJobCandidateRequestData,
    updateJobCandidateOptions: {
      filterColumn?: keyof UpdateJobCandidateRequestData;
    } = {}
  ) {
    return this.updateItem<UpdateJobCandidateRequestData>(
      'jobCandidates' as UpdateJobCandidateTable,
      updateJobCandidateId,
      updateJobCandidateData,
      updateJobCandidateOptions
    );
  }

  static async deleteJobCandidate(deleteJobCandidateId: DeleteJobCandidateID) {
    return this.deleteItem(
      'jobCandidates' as DeleteJobCandidateTable,
      deleteJobCandidateId,
      {}
    );
  }
}
