import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import compact from 'lodash/compact';
import { SystemStyleObject } from '@chakra-ui/react';
import { DEFAULT_COUNTRY_ID } from '../../../../../config';
import { ErrorMessage } from '../../../../../types';
import {
  CityFields,
  CityId,
  FetchCitiesFilters,
  FetchCitiesSort
} from '../../../../cities/citiesTypes';
import {
  fetchCitiesQuery,
  FetchCitiesResponse
} from '../../../../cities/queries/fetchCities.query';

import { usePaginatedCities } from '../../../../../main/cities/hooks/usePaginatedCities';

import { CitiesCache } from '../../../../cities/CitiesCache';

import {
  SelectFieldControl,
  SelectOptionType
} from '../../../../../helpers/forms/formFields/SelectField/components/SelectFieldControl';

import {
  SelectLocationFormFieldFieldValue,
  SelectLocationFormFieldItem
} from './SelectLocationFormField.types';

interface SelectLocationFormFieldProps {
  isRequired?: boolean;
  item?: SelectLocationFormFieldItem;
  errorMessage: ErrorMessage;
  label?: string;
  placeholder?: string;
  showLabel?: boolean;
  handleSelectLocation: (location: SelectLocationFormFieldFieldValue) => void;
  isBorderless?: boolean;
  currentyCityId?: CityId | null;
}

function SelectLocationFormField({
  isRequired = false,
  showLabel = true,
  label,
  placeholder,
  item,
  handleSelectLocation,
  errorMessage,
  isBorderless
}: SelectLocationFormFieldProps) {
  const { cities, citiesIsLoading, citiesErrorMessage, changeCitiesFilters } =
    usePaginatedCities<FetchCitiesResponse>({
      query: fetchCitiesQuery,
      cacheKey: CitiesCache.signUpCacheKey(),
      initialFilters: {
        [CityFields.COUNTRY_ID]: {
          operator: 'eq',
          value: item?.country?.id || DEFAULT_COUNTRY_ID
        },
        ...(item?.city?.label
          ? {
              [CityFields.LABEL]: {
                operator: 'ilike',
                value: `%${item?.city?.label}%`
              }
            }
          : {})
      } as unknown as FetchCitiesFilters,
      initialSort: {
        [CityFields.NAME]: { ascending: true }
      } as unknown as FetchCitiesSort
    });

  const [, setLocationCityId] = useState<CityId | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<
    SelectOptionType | undefined
  >(undefined);

  const defaultCurrentUserCity =
    item?.city && item?.country && item?.state
      ? {
          value: JSON.stringify({
            city: item.city,
            state: item.state,
            country: item.country
          }),
          label: compact([item.city.label, item.state.label]).join(', ')
        }
      : undefined;

  const debouncedFilterCities = debounce<(updatedValue: string) => void>(
    (updatedValue) =>
      changeCitiesFilters({
        [CityFields.LABEL]: {
          operator: 'ilike',
          value: `${updatedValue}%`
        }
      }),
    500
  );

  const handleChange = useCallback<
    (
      value: string | number | null,
      selectedOption?: SelectOptionType | undefined
    ) => void
  >(
    (value, selectedOption) => {
      const parsedValue: SelectLocationFormFieldFieldValue = JSON.parse(
        value as string
      );

      handleSelectLocation(parsedValue);
      setSelectedLocation(selectedOption);
      setLocationCityId(parsedValue.city.id);
    },
    [handleSelectLocation]
  );

  /* const handleChangeSelectedLocation = useCallback(async () => {
    console.log('handleChangeSelectedLocation');
    const newCities = await fetchCities({
      nextFilters: {
        [CityFields.ID]: {
          operator: 'eq',
          value: currentyCityId as string
        }
      },
      removeFilters: [CityFields.LABEL, CityFields.COUNTRY_ID]
    });

    const newCity = first(newCities.data);

    if (newCity) {
      const newCityOption = {
        value: JSON.stringify({
          city: { id: newCity.id, label: newCity.label },
          state: newCity.state,
          country: newCity.country
        }),
        label: compact([newCity.label, newCity.state.label]).join(', ')
      };

      setSelectedLocation(newCityOption);
      setLocationCityId(newCity.id);
    }
  }, [fetchCities, currentyCityId]); */

  useEffect(() => {
    if (item?.city?.id) setLocationCityId(item.city.id);
  }, [item]);

  // useEffect(() => {
  //   if (currentyCityId && currentyCityId !== locationCityId) {
  //     handleChangeSelectedLocation();
  //   }
  // }, [
  //   currentyCityId,
  //   locationCityId,
  //   changeCitiesFilters,
  //   handleChangeSelectedLocation
  // ]);

  return (
    <SelectFieldControl
      isRequired={isRequired}
      label={showLabel ? label || 'Search Location' : undefined}
      placeholder={placeholder || 'Search Location'}
      name="Location"
      options={cities.map(({ id, label, state, country }) => ({
        value: JSON.stringify({
          city: { id, label },
          state: { id: state.id, label: state.label },
          country: { id: country.id, label: country.label, name: country.name }
        }),
        label: compact([label, state.label]).join(', ')
      }))}
      defaultValue={defaultCurrentUserCity}
      onChange={handleChange}
      errorMessage={errorMessage || citiesErrorMessage}
      isLoading={citiesIsLoading}
      onInputChange={debouncedFilterCities}
      selectedValue={selectedLocation}
      reactSelectStyles={
        isBorderless
          ? {
              control: (base: SystemStyleObject) => ({
                ...base,
                borderColor: 'transparent',
                p: 0
              })
            }
          : undefined
      }
    />
  );
}

export default SelectLocationFormField;
