import snakeCase from 'lodash/snakeCase';

import {
  FetchIndustryExpertisesQuery,
  IndustryExpertiseFields,
  IndustryExpertiseId,
  IndustryExpertiseNanoId,
  IndustryExpertiseUser,
  IndustryExpertiseCreatedAt,
  IndustryExpertiseIndustry,
  IndustryExpertiseIndustryId,
  IndustryExpertiseJob,
  IndustryExpertiseJobId,
  IndustryExpertiseRequirements,
  IndustryExpertiseExperience,
  IndustryExpertiseJobCandidateId,
  IndustryExpertiseTalentPoolId,
  IndustryExpertiseJobResumeRecruiterId
} from '../industryExpertisesTypes';

export interface FetchIndustryExpertisesResponse {
  [IndustryExpertiseFields.ID]: IndustryExpertiseId;
  [IndustryExpertiseFields.NANO_ID]: IndustryExpertiseNanoId;
  [IndustryExpertiseFields.USER]: IndustryExpertiseUser;
  [IndustryExpertiseFields.CREATED_AT]: IndustryExpertiseCreatedAt;
  [IndustryExpertiseFields.REQUIREMENTS]: IndustryExpertiseRequirements;
  [IndustryExpertiseFields.EXPERIENCE]: IndustryExpertiseExperience;
  [IndustryExpertiseFields.INDUSTRY]: IndustryExpertiseIndustry;
  [IndustryExpertiseFields.INDUSTRY_ID]: IndustryExpertiseIndustryId;
  [IndustryExpertiseFields.JOB]: IndustryExpertiseJob;
  [IndustryExpertiseFields.JOB_ID]: IndustryExpertiseJobId;
  [IndustryExpertiseFields.JOB_CANDIDATE_ID]: IndustryExpertiseJobCandidateId;
  [IndustryExpertiseFields.JOB_RESUME_RECRUITER_ID]: IndustryExpertiseJobResumeRecruiterId;
  [IndustryExpertiseFields.TALENT_POOL_ID]: IndustryExpertiseTalentPoolId;
}

export const fetchIndustryExpertisesColumns = [
  IndustryExpertiseFields.ID,
  IndustryExpertiseFields.NANO_ID,
  IndustryExpertiseFields.CREATED_AT,
  IndustryExpertiseFields.REQUIREMENTS,
  IndustryExpertiseFields.EXPERIENCE,
  IndustryExpertiseFields.INDUSTRY_ID,
  IndustryExpertiseFields.JOB_ID,
  IndustryExpertiseFields.TALENT_POOL_ID,
  IndustryExpertiseFields.JOB_CANDIDATE_ID,
  IndustryExpertiseFields.JOB_RESUME_RECRUITER_ID
];

const fetchIndustryExpertisesSBColumns = fetchIndustryExpertisesColumns.map(
  (fetchIndustryExpertisesColumn) => snakeCase(fetchIndustryExpertisesColumn)
);

export const fetchIndustryExpertisesQuery =
  fetchIndustryExpertisesSBColumns.join(',') as FetchIndustryExpertisesQuery;
