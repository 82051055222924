import { ViewJobCandidateDetailsProfileCalendarEventType } from './components/ViewJobCandidateDetailsProfileCalendarEventItem/ViewJobCandidateDetailsProfileCalendarEventItem';

type CalendarEventsType = {
  [date: string]: ViewJobCandidateDetailsProfileCalendarEventType[];
};

export const calendarEvents: CalendarEventsType = {
  '2024-09-04': [
    {
      id: 'evt-20240603-001',
      title: 'Quarterly Review',
      start: '9:00 am',
      end: '10:00 am',
      type: 'meeting'
    }
  ],
  '2024-09-05': [
    {
      id: 'evt-20240604-001',
      title: 'Client Presentation',
      start: '11:00 am',
      end: '12:00 pm',
      type: 'presentation'
    }
  ],
  '2024-09-06': [
    {
      id: 'evt-20240528-001',
      title: 'Project Kickoff',
      start: '9:00 am',
      end: '10:00 am',
      type: 'meeting'
    },
    {
      id: 'evt-20240525-001',
      title: 'Team Building Activity',
      start: '10:00 am',
      end: '12:00 pm',
      type: 'team'
    }
  ],
  '2024-09-07': [],
  '2024-09-08': [],
  '2024-09-09': [
    {
      id: 'evt-20240526-001',
      title: 'Departmental Meeting',
      start: '1:00 pm',
      end: '2:00 pm',
      type: 'meeting'
    },
    {
      id: 'evt-20240528-002',
      title: 'Tech Talk',
      start: '11:00 am',
      end: '12:00 pm',
      type: 'seminar'
    },
    {
      id: 'evt-20240530-001',
      title: 'HR Meeting',
      start: '10:00 am',
      end: '11:00 am',
      type: 'meeting'
    },
    {
      id: 'evt-20240530-002',
      title: 'Team Standup',
      start: '11:30 am',
      end: '12:00 pm',
      type: 'meeting'
    }
  ],
  '2024-09-10': [],
  '2024-09-11': [],
  '2024-09-12': [
    {
      id: 'evt-20240610-001',
      title: 'Team Building Activity',
      start: '10:00 am',
      end: '12:00 pm',
      type: 'team'
    },
    {
      id: 'evt-20240610-002',
      title: 'Strategy Session',
      start: '1:00 pm',
      end: '2:00 pm',
      type: 'meeting'
    }
  ],
  '2024-09-13': [
    {
      id: 'evt-20240531-001',
      title: 'Client Presentation',
      start: '9:00 am',
      end: '10:00 am',
      type: 'presentation'
    },
    {
      id: 'evt-20240531-002',
      title: 'Seminar on HR Management Techniques',
      start: '11:00 am',
      end: '12:00 pm',
      type: 'seminar'
    },
    {
      id: 'evt-20240531-003',
      title: 'Lunch with CEO',
      start: '12:30 pm',
      end: '1:30 pm',
      type: 'team'
    }
  ],
  '2024-09-14': [],
  '2024-09-15': [
    {
      id: 'evt-20240613-001',
      title: 'Seminar on HR Management Techniques',
      start: '11:00 am',
      end: '12:00 pm',
      type: 'seminar'
    },
    {
      id: 'evt-20240613-002',
      title: 'Tech Talk',
      start: '2:00 pm',
      end: '3:00 pm',
      type: 'seminar'
    }
  ],
  '2024-09-16': [
    {
      id: 'evt-20240614-001',
      title: 'HR Meeting',
      start: '10:00 am',
      end: '11:00 am',
      type: 'meeting'
    }
  ],
  '2024-09-24': [
    {
      id: 'evt-20240615-001',
      title: 'Team Standup',
      start: '11:30 am',
      end: '12:00 pm',
      type: 'meeting'
    }
  ]
};
