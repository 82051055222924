import {
  FetchJobTitlesCacheKey,
  FetchJobTitleCacheKey,
  UpdateJobTitleErrorMessage,
  UpdateJobTitleIsLoading,
  UpdateJobTitleID
} from '../../jobTitlesTypes';

import {
  JobTitlesBffRequests,
  UpdateJobTitleRequestData,
  UpdateJobTitleErrorResponse
} from '../../JobTitlesBffRequests';

import { useUpdateItem } from '../../../common/hooks/useUpdateItem';

interface UpdateJobTitleOptions {
  jobTitleId: UpdateJobTitleID;
  cacheKeys?: (FetchJobTitlesCacheKey | FetchJobTitleCacheKey)[];
}

function useUpdateJobTitle({
  jobTitleId,
  cacheKeys = []
}: UpdateJobTitleOptions) {
  const {
    updateItemData,
    updateItemError,
    updateItemErrorMessage,
    updateItemIsLoading,
    updateItem,
    updateItemReset
  } = useUpdateItem<UpdateJobTitleRequestData, UpdateJobTitleErrorResponse>({
    mutationFn: (queryInput) =>
      JobTitlesBffRequests.updateJobTitle(jobTitleId, queryInput),
    // JobTitlesRequests.updateJobTitle(jobTitleId, queryInput),
    cacheKeys
  });

  return {
    updateJobTitleData: updateItemData,
    updateJobTitleError: updateItemError,
    updateJobTitleErrorMessage:
      updateItemErrorMessage as UpdateJobTitleErrorMessage,
    updateJobTitleIsLoading: updateItemIsLoading as UpdateJobTitleIsLoading,
    updateJobTitle: updateItem,
    updateJobTitleReset: updateItemReset
  };
}

export default useUpdateJobTitle;
