import { JobEmployment } from '../../jobsTypes';
import { TalentPoolEmploymentType } from '../../../talentPools/talentPoolsTypes';

import { employmentOptionsHash } from '../../jobs.data';

function renderJobEmployment(
  employment: JobEmployment | TalentPoolEmploymentType
) {
  return employmentOptionsHash[employment];
}

export default renderJobEmployment;
