import { Grid } from '@chakra-ui/react';
import { ActivitiesIcon } from '../../../../../../../../icons/ActivitiesIcon';
import { CalendarIcon } from '../../../../../../../../icons/CalendarIcon';
import { EmailIcon } from '../../../../../../../../icons/EmailIcon';
import { ListItemIcon } from '../../../../../../../../icons/ListItemIcon';
import { MessageIcon } from '../../../../../../../../icons/MessageIcon';
import { PhoneIcon } from '../../../../../../../../icons/PhoneIcon';
import { JobCandidateNanoId } from '../../../../../../../jobCandidates/jobCandidatesTypes';
import { JobNanoId } from '../../../../../../jobsTypes';
import { ViewJobCandidateDetailsActivitiesRoutes } from '../../ViewJobCandidateDetailsActivitiesRoutes';
import { ViewJobCandidateDetailsActivityGridItem } from '../ViewJobCandidateDetailsActivityGridItem';
import { ArrowLeftRightIcon } from '../../../../../../../../icons/ArrowLeftRightIcon';
import { HiringPortalJobCandidateDetailsActivitiesRoutes } from '../../../../../../../hiringPortal/HiringPortalJobCandidateDetailsActivitiesRoutes';
import { useViewJobCandidateDetailsSubmissionContext } from '../../../../contexts/ViewJobCandidateDetailsSubmissionContext';
import {
  CandidateSubmissionShareSettingsField,
  CandidateSubmissionShareSettingsFields
} from '../../../../../../../candidateSubmissions/candidateSubmissionsTypes';

interface ViewJobCandidateDetailsActivityGridProps {
  jobNanoId: JobNanoId;
  jobCandidateNanoId: JobCandidateNanoId;
  isHiringPortalPage: boolean;
}

export default function ViewJobCandidateDetailsActivityGrid({
  jobNanoId,
  jobCandidateNanoId,
  isHiringPortalPage
}: ViewJobCandidateDetailsActivityGridProps) {
  const route = isHiringPortalPage
    ? HiringPortalJobCandidateDetailsActivitiesRoutes
    : ViewJobCandidateDetailsActivitiesRoutes;

  const { shareSettings } = useViewJobCandidateDetailsSubmissionContext();

  return (
    <Grid
      w="full"
      gap={{ base: 2, md: 3 }}
      gridTemplateColumns={{
        lg: 'repeat(auto-fill, minmax(240px, 1fr))'
      }}
    >
      {shareSettings.includes(
        CandidateSubmissionShareSettingsFields.ActivitiesAllActivity as CandidateSubmissionShareSettingsField
      ) && (
        <ViewJobCandidateDetailsActivityGridItem
          title="All Activity"
          subtitle="Last week analytics"
          percentChange={-29}
          icon={ActivitiesIcon}
          value="58"
          iconColor="#6872E5"
          url={route.all(jobNanoId, jobCandidateNanoId)}
        />
      )}

      {shareSettings.includes(
        CandidateSubmissionShareSettingsFields.ActivitiesPhoneCalls as CandidateSubmissionShareSettingsField
      ) && (
        <ViewJobCandidateDetailsActivityGridItem
          title="Phone Calls"
          subtitle="Last week analytics"
          percentChange={+18}
          icon={PhoneIcon}
          value="28"
          iconColor="#287CC9"
          url={route.phoneCalls(jobNanoId, jobCandidateNanoId)}
        />
      )}

      {shareSettings.includes(
        CandidateSubmissionShareSettingsFields.ActivitiesCallConnections as CandidateSubmissionShareSettingsField
      ) && (
        <ViewJobCandidateDetailsActivityGridItem
          title="Call Connections"
          subtitle="Last week analytics"
          percentChange={-18}
          icon={PhoneIcon}
          value="28"
          iconColor="#BA3724"
          url={route.callConnections(jobNanoId, jobCandidateNanoId)}
        />
      )}

      {shareSettings.includes(
        CandidateSubmissionShareSettingsFields.ActivitiesEmails as CandidateSubmissionShareSettingsField
      ) && (
        <ViewJobCandidateDetailsActivityGridItem
          title="Emails"
          subtitle="Last week analytics"
          percentChange={+42}
          icon={EmailIcon}
          value="4"
          iconColor="#1DC67D"
          url={route.emails(jobNanoId, jobCandidateNanoId)}
        />
      )}

      {shareSettings.includes(
        CandidateSubmissionShareSettingsFields.ActivitiesMeetings as CandidateSubmissionShareSettingsField
      ) && (
        <ViewJobCandidateDetailsActivityGridItem
          title="Meetings"
          subtitle="Last meeting scheduled: Yesterday"
          percentChange={+29}
          icon={CalendarIcon}
          value="58"
          iconColor="#507D7B"
          url={route.meetings(jobNanoId, jobCandidateNanoId)}
        />
      )}

      {shareSettings.includes(
        CandidateSubmissionShareSettingsFields.ActivitiesStageChanges as CandidateSubmissionShareSettingsField
      ) && (
        <ViewJobCandidateDetailsActivityGridItem
          title="Stage Changes"
          subtitle="Last week analytics"
          percentChange={+29}
          icon={ArrowLeftRightIcon}
          value="58"
          iconColor="#EEA401"
          url={route.stageChanges(jobNanoId, jobCandidateNanoId)}
        />
      )}

      {shareSettings.includes(
        CandidateSubmissionShareSettingsFields.ActivitiesComments as CandidateSubmissionShareSettingsField
      ) && (
        <ViewJobCandidateDetailsActivityGridItem
          title="Comments"
          subtitle="Last week analytics"
          percentChange={+29}
          icon={MessageIcon}
          value="8"
          iconColor="#AB56B2"
          url={route.comments(jobNanoId, jobCandidateNanoId)}
        />
      )}

      {shareSettings.includes(
        CandidateSubmissionShareSettingsFields.ActivitiesTasks as CandidateSubmissionShareSettingsField
      ) && (
        <ViewJobCandidateDetailsActivityGridItem
          title="Tasks"
          subtitle="Last week analytics"
          percentChange={+89}
          icon={ListItemIcon}
          value="28"
          iconColor="#507D7B"
          url={route.tasks(jobNanoId, jobCandidateNanoId)}
        />
      )}
    </Grid>
  );
}
