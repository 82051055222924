import { useMutation, useQueryClient } from 'react-query';

import {
  FetchItemsCacheKey,
  FetchItemsIndexCacheKey,
  FetchItemCacheKey,
  FetchItemIndexCacheKey,
  DeleteItemError,
  DeleteItemErrorMessage,
  DeleteItemIsLoading
} from '../../../../types';

import { parseRequestError } from '../../../../utils/parseRequestError';

interface DeleteItemOptions<DeleteItemRequestData> {
  mutationFn: (
    queryInput: DeleteItemRequestData
  ) => Promise<DeleteItemRequestData>;
  cacheKeys?: (
    | FetchItemsCacheKey
    | FetchItemsIndexCacheKey
    | FetchItemCacheKey
    | FetchItemIndexCacheKey
  )[];
}

function useDeleteItem<
  DeleteItemRequestData,
  DeleteItemErrorResponse extends { message: DeleteItemError }
>({ mutationFn, cacheKeys = [] }: DeleteItemOptions<DeleteItemRequestData>) {
  const queryClient = useQueryClient();

  const { data, error, isLoading, mutateAsync, reset } = useMutation<
    DeleteItemRequestData,
    DeleteItemErrorResponse,
    DeleteItemRequestData
  >((queryInput) => mutationFn(queryInput), {
    onSettled: async () =>
      cacheKeys?.map((cacheKey) => queryClient.invalidateQueries(cacheKey))
  });

  return {
    deleteItemData: data,
    deleteItemError: error,
    deleteItemErrorMessage: parseRequestError(error) as DeleteItemErrorMessage,
    deleteItemIsLoading: isLoading as DeleteItemIsLoading,
    deleteItem: (deleteItemData: DeleteItemRequestData) =>
      mutateAsync(deleteItemData),
    deleteItemReset: () => reset()
  };
}

export default useDeleteItem;
