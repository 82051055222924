import {
  ReactHookFormFieldError,
  useReactHookForm
} from '../../../../../../common/hooks/base/useReactHookForm';

import {
  SkillBadgeCertified,
  SkillBadgeEmploymentIds,
  SkillBadgeExperience,
  SkillBadgeExperienceType,
  SkillBadgeFields,
  SkillBadgeRating,
  SkillBadgeRequirements,
  SkillBadgeSkillLicenseId
} from '../../../../skillBadgesTypes';

import { useAddEditSkillBadgeFormValidationRules } from '../useAddEditSkillBadgeFormValidationRules';

import {
  AddEditSkillBadgeFormData,
  OnAddEditSkillBadgeForm
} from '../../AddEditSkillBadgeForm.types';

const defaultAddEditSkillBadgeFormValues: AddEditSkillBadgeFormData = {
  rating: '' as SkillBadgeRating,
  experience: '' as SkillBadgeExperience,
  experienceType: '' as SkillBadgeExperienceType,
  certified: false as SkillBadgeCertified,
  requirements: '' as SkillBadgeRequirements,
  skillLicenseId: null
};

interface AddEditSkillBadgeFormOptions {
  defaultValues?: Partial<AddEditSkillBadgeFormData>;
  onAddEditSkillBadgeForm: OnAddEditSkillBadgeForm;
  isApplicantSkillBadgeForm?: boolean;
  isWorkHistoryScreen?: boolean;
  isIntakeForm?: boolean;
}

function useAddEditSkillBadgeForm({
  defaultValues = {},
  onAddEditSkillBadgeForm,
  isApplicantSkillBadgeForm,
  isWorkHistoryScreen,
  isIntakeForm
}: AddEditSkillBadgeFormOptions) {
  const {
    control,
    watch,
    errors,
    isLoading,
    isValid,
    isSubmitted,
    isSubmitSuccessful,
    errorMessage,
    register,
    resetForm,
    handleSubmitReactHookForm,
    setValue
  } = useReactHookForm<AddEditSkillBadgeFormData>({
    defaultValues: { ...defaultAddEditSkillBadgeFormValues, ...defaultValues }
  });

  const {
    ratingRules,
    experienceRules,
    experienceTypeRules,
    requirementsRules,
    employmentIdsRules
  } = useAddEditSkillBadgeFormValidationRules();

  return {
    control,
    watch,
    validationErrors: {
      ratingValidationError: (errors.rating as ReactHookFormFieldError)
        ?.message,
      experienceValidationError: (errors.experience as ReactHookFormFieldError)
        ?.message,
      certifiedValidationError: (errors.certified as ReactHookFormFieldError)
        ?.message,
      requirementsValidationError: (
        errors.requirements as ReactHookFormFieldError
      )?.message,
      experienceTypeValidationError: (
        errors.experienceType as ReactHookFormFieldError
      )?.message,
      skillLicenseIdValidationError: (
        errors.skillLicenseId as ReactHookFormFieldError
      )?.message,
      employmentIdsValidationError: (
        errors.employmentIds as ReactHookFormFieldError
      )?.message
    },
    addEditSkillBadgeFormIsLoading: isLoading,
    addEditSkillBadgeFormIsValid: isValid,
    addEditSkillBadgeFormIsSubmitted: isSubmitted,
    addEditSkillBadgeFormIsSubmitSuccessful: isSubmitSuccessful,
    addEditSkillBadgeFormErrorMessage: errorMessage,
    resetAddEditSkillBadgeForm: resetForm,
    setAddEditSkillBadgeFormValue: setValue,
    registerFields: {
      registerRating: register(SkillBadgeFields.RATING, ratingRules),
      registerExperience: register(
        SkillBadgeFields.EXPERIENCE,
        isApplicantSkillBadgeForm ? undefined : experienceRules
      ),
      registerCertified: register(SkillBadgeFields.CERTIFIED),
      registerRequirements: register(
        SkillBadgeFields.REQUIREMENTS,
        isIntakeForm ? requirementsRules : undefined
      ),
      registerExperienceType: register(
        SkillBadgeFields.EXPERIENCE_TYPE,
        isApplicantSkillBadgeForm || isIntakeForm
          ? undefined
          : experienceTypeRules
      ),
      registerSkillLicenseId: register(SkillBadgeFields.SKILL_LICENSE_ID),
      registerEmploymentIds: register(
        SkillBadgeFields.EMPLOYMENT_IDS,
        isWorkHistoryScreen ? employmentIdsRules : undefined
      )
    },
    handleAddEditSkillBadgeForm: handleSubmitReactHookForm({
      onSubmit: (data) =>
        onAddEditSkillBadgeForm?.({
          rating: data.rating as SkillBadgeRating,
          experience: data.experience as SkillBadgeExperience,
          experienceType: (data.experienceType ||
            'none') as SkillBadgeExperienceType,
          certified: data.certified as SkillBadgeCertified,
          requirements: data.requirements as SkillBadgeRequirements,
          skillLicenseId: data.skillLicenseId as SkillBadgeSkillLicenseId,
          ...(isApplicantSkillBadgeForm
            ? { employmentIds: data.employmentIds as SkillBadgeEmploymentIds }
            : {})
        })
    })
  };
}

export default useAddEditSkillBadgeForm;
