import { Box, Collapse, Flex, Stack } from '@chakra-ui/react';
import { MouseEvent, useState } from 'react';
import { Heading } from '../../../../../../../helpers/Heading';
import { IconButton } from '../../../../../../../helpers/buttons/IconButton';
import { CancelIcon } from '../../../../../../../icons/CancelIcon';
import {
  JobCandidateId,
  JobCandidateNanoId
} from '../../../../../../jobCandidates/jobCandidatesTypes';
import { JobId, JobNanoId } from '../../../../../jobsTypes';
import { ViewJobCandidateModalPhoneSidebarCallCategoryType } from './ViewJobCandidateModalPhoneSidebarHelper.types';
import {
  ViewJobCandidateModalPhoneSidebarCallActionsHelper,
  ViewJobCandidateModalPhoneSidebarCallCategoriesHelper,
  ViewJobCandidateModalPhoneSidebarLastCallNoteHelper,
  ViewJobCandidateModalPhoneSidebarPhoneNumbersHelper
} from './helpers';
import { ViewJobCandidateModalPhoneSidebarContextProvider } from './helpers/context';

interface ViewJobCandidateModalPhoneSidebarHelperProps {
  fullWidth?: boolean;
  isSidebarOpen: boolean;
  sidebarToggle: () => void;
  companyPhoneNumber: string;
  personalPhoneNumber: string;
  withoutBorder?: boolean;
  toggleFollowUpEmail: () => void;
  jobCandidate: {
    nanoId: JobCandidateNanoId;
    id: JobCandidateId;
    job: {
      id: JobId;
      nanoId: JobNanoId;
    };
  };
}

const ViewJobCandidateModalPhoneSidebarHelper = ({
  fullWidth,
  isSidebarOpen,
  sidebarToggle,
  companyPhoneNumber,
  personalPhoneNumber,
  withoutBorder = false,
  toggleFollowUpEmail,
  jobCandidate
}: ViewJobCandidateModalPhoneSidebarHelperProps) => {
  const [selectedCallCategory, setSelectedCallCategory] =
    useState<ViewJobCandidateModalPhoneSidebarCallCategoryType>(null);

  const toggleCallCategory = (e: MouseEvent<HTMLButtonElement>) => {
    const { value } = e.currentTarget;

    if (selectedCallCategory === value) {
      setSelectedCallCategory(null);
      return;
    }

    setSelectedCallCategory(
      value as ViewJobCandidateModalPhoneSidebarCallCategoryType
    );
  };

  return (
    <ViewJobCandidateModalPhoneSidebarContextProvider
      jobCandidate={jobCandidate}
      toggleEmailSidebar={toggleFollowUpEmail}
    >
      <Flex
        h="100vh"
        bg="white"
        overflow="auto"
        transitionDuration="slow"
        transitionProperty="width"
        w={isSidebarOpen && fullWidth ? 'full' : isSidebarOpen ? '400px' : 0}
        borderLeft={withoutBorder ? undefined : '1px solid'}
        borderLeftColor="gray.200"
      >
        <Flex
          px={6}
          py={8}
          gap={4}
          flex={1}
          flexDir="column"
          overflow="auto"
          h="100%"
        >
          <Flex gap={2} alignItems="center">
            <Heading level="h2">Phone call activity</Heading>

            <IconButton
              ml="auto"
              aria-label="Close"
              hierarchy="unstyled"
              onClick={sidebarToggle}
              icon={<CancelIcon fill="gray.600" w={5} h={5} />}
            />
          </Flex>

          <Box gap={4}>
            <Collapse in={!selectedCallCategory} unmountOnExit>
              <ViewJobCandidateModalPhoneSidebarCallCategoriesHelper
                selectedCallCategory={selectedCallCategory}
                toggleCallCategory={toggleCallCategory}
              />
            </Collapse>

            <Collapse in={!!selectedCallCategory} unmountOnExit>
              <Stack spacing={4}>
                <ViewJobCandidateModalPhoneSidebarPhoneNumbersHelper
                  companyPhoneNumber={companyPhoneNumber}
                  personalPhoneNumber={personalPhoneNumber}
                />

                <ViewJobCandidateModalPhoneSidebarCallActionsHelper
                  callCategory={selectedCallCategory}
                  withoutMargin={withoutBorder}
                />
              </Stack>
            </Collapse>
          </Box>

          <ViewJobCandidateModalPhoneSidebarLastCallNoteHelper />
        </Flex>
      </Flex>
    </ViewJobCandidateModalPhoneSidebarContextProvider>
  );
};

export default ViewJobCandidateModalPhoneSidebarHelper;
