import { Control, FieldPath, FieldValues } from 'react-hook-form';
import { useCallback, useMemo, useState } from 'react';
import map from 'lodash/map';
import uniqBy from 'lodash/uniqBy';
import includes from 'lodash/includes';
import debounce from 'lodash/debounce';
import filter from 'lodash/filter';
import lowerCase from 'lodash/lowerCase';
import size from 'lodash/size';

import { useCurrentUser } from '../../../../../../auth/hooks/useAuth';

import {
  MultiSelectField,
  MultiSelectFieldReactHookFormRules
} from '../../../../../../helpers/forms/formFields/MultiSelectField';
import { SelectOptions } from '../../../../../../helpers/forms/formFields/MultiSelectField/components/MultiSelectFieldControl';

import {
  CompanyMemberFields,
  FetchCompanyMembersFilters,
  FetchCompanyMembersSort
} from '../../../../../companyMembers/companyMembersTypes';

import {
  CompanyMemberInvitationFields,
  FetchCompanyMemberInvitationsFilters,
  FetchCompanyMemberInvitationsSort
} from '../../../../../companyMemberInvitations/companyMemberInvitationsTypes';

import { usePaginatedCompanyMembers } from '../../../../../companyMembers/hooks/usePaginatedCompanyMembers';
import { usePaginatedCompanyMemberInvitations } from '../../../../../companyMemberInvitations/hooks/usePaginatedCompanyMemberInvitations';

import {
  fetchCompanyMembersQuery,
  FetchCompanyMembersResponse
} from '../../../../../companyMembers/queries/fetchCompanyMembers.query';
import {
  fetchCompanyMemberInvitationsQuery,
  FetchCompanyMemberInvitationsResponse
} from '../../../../../companyMemberInvitations/queries/fetchCompanyMemberInvitations.query';

import { CompanyMembersCache } from '../../../../../companyMembers/CompanyMembersCache';
import { CompanyMemberInvitationsCache } from '../../../../../companyMemberInvitations/CompanyMemberInvitationsCache';

import { ErrorMessage, IsDisabled, IsRequired } from '../../../../../../types';
import { ShareCandidateProfileRecipientsJobCollaborators } from './ShareCandidateProfileRecipients.types';

import { ImagesUrl } from '../../../../../images/ImagesUrl';
import {
  CandidateShareCandidateShareInvitations,
  CandidateShareCompanyMemberInvitation,
  CandidateShareFields,
  CandidateShareRecipient
} from '../../../../candidateSharesTypes';
import { RecruiterAccountTypes } from '../../../../../accountTypes/accountTypesTypes';
import { RecipientId } from '../../../../../recipients/recipientsTypes';
import { ShareCandidateProfileCandidateShare } from '../../ShareCandidateProfile.types';

interface ShareCandidateProfileRecipientsProps<T extends FieldValues> {
  isRequired?: IsRequired;
  isDisabled?: IsDisabled;
  control: Control<T>;
  jobCollaborators: ShareCandidateProfileRecipientsJobCollaborators;
  defaultJobCollaborators: ShareCandidateProfileRecipientsJobCollaborators;
  jobCandidateRecipients: CandidateShareRecipient[];
  jobCandidateCompanyMemberInvitations: CandidateShareCompanyMemberInvitation[];
  errorMessage: ErrorMessage;
  showAddForm?: (inputValue?: string) => void;
  currentCandidateShareInvitations: CandidateShareCandidateShareInvitations;
  candidateShare: ShareCandidateProfileCandidateShare | null;
}

function ShareCandidateProfileRecipients<T extends FieldValues>({
  isRequired,
  isDisabled,
  control,
  errorMessage,
  jobCollaborators,
  defaultJobCollaborators,
  jobCandidateCompanyMemberInvitations,
  jobCandidateRecipients,
  showAddForm,
  currentCandidateShareInvitations,
  candidateShare
}: ShareCandidateProfileRecipientsProps<T>) {
  const currentUser = useCurrentUser();

  const {
    companyMemberInvitations,
    companyMemberInvitationsIsLoading,
    companyMemberInvitationsErrorMessage,
    changeCompanyMemberInvitationsFilters
  } = usePaginatedCompanyMemberInvitations<FetchCompanyMemberInvitationsResponse>(
    {
      query: fetchCompanyMemberInvitationsQuery,
      cacheKey: CompanyMemberInvitationsCache.indexCacheKey(),
      initialFilters: {
        [CompanyMemberInvitationFields.COMPANY_ID]: {
          operator: 'eq',
          value: currentUser.companyId
        },
        [CompanyMemberInvitationFields.INVITED_USER_ID]: {
          operator: 'is.null',
          value: null
        }
      } as unknown as FetchCompanyMemberInvitationsFilters,
      initialSort: {
        [CompanyMemberInvitationFields.CREATED_AT]: { ascending: false }
      } as unknown as FetchCompanyMemberInvitationsSort
    }
  );

  const {
    companyMembers,
    companyMembersIsLoading,
    companyMembersErrorMessage,
    changeCompanyMembersFilters
  } = usePaginatedCompanyMembers<FetchCompanyMembersResponse>({
    query: fetchCompanyMembersQuery,
    cacheKey: CompanyMembersCache.indexCacheKey(),
    initialFilters: {
      [CompanyMemberFields.COMPANY_ID]: {
        operator: 'eq',
        value: currentUser.companyId
      },
      [CompanyMemberFields.USER_ACCOUNT_TYPE_NAME]: {
        operator: 'not.eq',
        value: RecruiterAccountTypes
      }
    } as unknown as FetchCompanyMembersFilters,
    initialSort: {
      [CompanyMemberFields.CREATED_AT]: { ascending: false }
    } as unknown as FetchCompanyMembersSort
  });

  const [inputValue, setInputValue] = useState<string>('');

  const changeRecipientFilters = useCallback<(updatedValue: string) => void>(
    (updatedValue) => {
      changeCompanyMemberInvitationsFilters(
        updatedValue
          ? {
              [CompanyMemberInvitationFields.NAME]: {
                operator: 'ilike',
                value: updatedValue
              }
            }
          : {},
        updatedValue ? [] : [CompanyMemberInvitationFields.NAME]
      );
      changeCompanyMembersFilters(
        updatedValue
          ? {
              [CompanyMemberFields.USER_NAME]: {
                operator: 'ilike',
                value: updatedValue
              }
            }
          : {},
        updatedValue ? [] : [CompanyMemberFields.USER_NAME]
      );
    },
    [changeCompanyMembersFilters, changeCompanyMemberInvitationsFilters]
  );

  const debouncedFilterRecipients = debounce<(updatedValue: string) => void>(
    (updatedValue) => changeRecipientFilters(updatedValue),
    500
  );

  const handleInputChange = useCallback<(value: string) => void>(
    (value) => {
      setInputValue(value);

      debouncedFilterRecipients(value);
    },
    [debouncedFilterRecipients]
  );

  const handleShowAddForm = useCallback(() => {
    showAddForm?.(inputValue);
    handleInputChange('');
  }, [showAddForm, inputValue, handleInputChange]);

  const recipientIdsValidationRules = {
    validate: {
      isRequired: (value: string[]) =>
        !!size(value) ||
        !!size(currentCandidateShareInvitations) ||
        'Profile recipients is required'
    }
  };

  const jobCandidateRecipientIds = map(
    jobCandidateRecipients,
    (jobCandidateRecipient) => jobCandidateRecipient?.id
  );

  const jobCandidateCompanyMemberInvitationEmails = map(
    jobCandidateCompanyMemberInvitations,
    (jobCandidateCompanyMemberInvitation) =>
      jobCandidateCompanyMemberInvitation?.email
  );

  const jobCollaboratorsOptions = useMemo(
    () =>
      map(
        filter(jobCollaborators, (jobCollaborator) =>
          lowerCase(jobCollaborator?.name)?.includes(inputValue)
        ),
        ({ id, name, image }) => {
          const value = JSON.stringify({
            id,
            name
          });
          const isShared = includes(
            jobCandidateRecipientIds,
            id as unknown as RecipientId
          );
          return {
            id,
            value,
            label: name,
            image: ImagesUrl.file(image),
            withCheckIcon: true,
            isDisabled: isShared,
            withShareIcon: isShared
          };
        }
      ),
    [jobCollaborators, inputValue, jobCandidateRecipientIds]
  );

  const defaultJobCollaboratorsOptions = map(
    defaultJobCollaborators,
    ({ id, name, image }) => {
      const value = JSON.stringify({
        id,
        name
      });
      const isShared = includes(
        jobCandidateRecipientIds,
        id as unknown as RecipientId
      );
      return {
        id,
        value,
        label: name,
        image: ImagesUrl.file(image),
        withCheckIcon: true,
        isDisabled: isShared,
        withShareIcon: isShared
      };
    }
  );

  const defaultCandidateShareRecipeintsOptiopns = map(
    candidateShare?.recipients,
    ({ id, name, image }) => {
      const value = JSON.stringify({
        id,
        name
      });
      const isShared = includes(
        jobCandidateRecipientIds,
        id as unknown as RecipientId
      );
      return {
        id,
        value,
        label: name,
        image: ImagesUrl.file(image),
        withCheckIcon: true,
        isDisabled: isShared,
        withShareIcon: isShared
      };
    }
  );

  const companyMemberInvitationsOptions = map(
    companyMemberInvitations,
    ({ name, email, accountTypeId }) => {
      const value = JSON.stringify({
        name,
        email,
        accountTypeId
      });
      const isShared = includes(
        jobCandidateCompanyMemberInvitationEmails,
        email
      );
      return {
        value,
        label: name || email,
        isDisabled: isShared,
        withShareIcon: isShared
      };
    }
  );

  const defaultCandidateShareCompanyMemberInvitationsOptions = map(
    candidateShare?.companyMemberInvitations,
    ({ name, email, accountTypeId }) => {
      const value = JSON.stringify({
        name,
        email,
        accountTypeId
      });
      const isShared = includes(
        jobCandidateCompanyMemberInvitationEmails,
        email
      );
      return {
        value,
        label: name || email,
        isDisabled: isShared,
        withShareIcon: isShared
      };
    }
  );

  const companyMembersOptions = map(companyMembers, ({ userId, user }) => {
    const value = JSON.stringify({
      id: userId,
      name: user?.fullName
    });
    const isShared = includes(
      jobCandidateRecipientIds,
      userId as unknown as RecipientId
    );
    return {
      id: userId,
      value,
      label: user?.fullName,
      image: ImagesUrl.file(user?.image),
      withCheckIcon: true,
      isDisabled: isShared,
      withShareIcon: isShared
    };
  });

  const defaultOptions = candidateShare
    ? [
        ...defaultCandidateShareRecipeintsOptiopns,
        ...defaultCandidateShareCompanyMemberInvitationsOptions
      ]
    : defaultJobCollaboratorsOptions;

  const uniqActiveUsers = uniqBy(
    [...jobCollaboratorsOptions, ...companyMembersOptions],
    'id'
  );

  const options = [
    ...uniqActiveUsers,
    ...companyMemberInvitationsOptions
  ] as SelectOptions;

  const showAddInputValue =
    !!inputValue &&
    !size(options) &&
    !companyMemberInvitationsIsLoading &&
    !companyMembersIsLoading;

  return (
    <MultiSelectField
      withImages
      withAddNewButton
      control={control}
      defaultValue={defaultOptions as SelectOptions}
      placeholder="Select or Type a name"
      isRequired={isRequired}
      isDisabled={isDisabled}
      label="Profile recipients"
      isLoading={companyMemberInvitationsIsLoading || companyMembersIsLoading}
      onInputChange={handleInputChange}
      addNewButtonAction={handleShowAddForm}
      addNewButtonHierarchy={showAddInputValue ? 'ghost' : undefined}
      addNewButtonLabel={`Add ${
        showAddInputValue ? inputValue : 'New Recipients'
      }`}
      name={CandidateShareFields.RECIPIENT_IDS as FieldPath<T>}
      options={options}
      errorMessage={
        errorMessage ||
        companyMemberInvitationsErrorMessage ||
        companyMembersErrorMessage
      }
      rules={
        recipientIdsValidationRules as MultiSelectFieldReactHookFormRules<T>
      }
    />
  );
}

export default ShareCandidateProfileRecipients;
