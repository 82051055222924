import { CompanyNanoId } from '../companies/companiesTypes';

import {
  UserId,
  UserUuid,
  UserNanoId,
  MayBeUserName,
  UserEmail,
  MayBeUserWorkEmail,
  MayBeUserPhone,
  UserPassword,
  MayBeUserAccountTypeId,
  MayBeUserCompanyId,
  UserSearchType,
  UserCaptureType,
  MayBeUserImageId,
  MayBeUserStateId,
  MayBeUserCityId,
  UserQualifications,
  UserEmailSignature,
  UserEmailSignatureEnabled,
  MayBeUserEmailSyncEnabledAt,
  UserEmailSyncProvider,
  MayBeUserScheduleInterviewsEnabledAt,
  UserScheduleInterviewsProvider,
  CreateUserError,
  DeleteUserError,
  FetchUserQuery,
  FetchUserIndexQueryName,
  FetchUsersCountType,
  FetchUsersFilters,
  FetchUsersPage,
  FetchUsersPageSize,
  FetchUsersQuery,
  FetchUsersSort,
  FetchUsersIndexCountType,
  FetchUsersIndexFilters,
  FetchUsersIndexPage,
  FetchUsersIndexPageSize,
  FetchUsersIndexQueryName,
  FetchUsersIndexSort,
  UpdateUserError,
  SendEmailUsersError,
  UserJobResumeRecruiterId,
  MayBeUserFirstName,
  MayBeUserLastName,
  ToggleBlockedUserError,
  MayBeUserTitle,
  MayBeUserTimeZone,
  MayBeUserProvidedRole,
  MayBeUserCompanyEmail
} from './usersTypes';

import { BaseBffRequests } from '../BaseBffRequests';

import { UsersBffRoutes } from './UsersBffRoutes';

interface FetchUsersRequestParams {
  query: FetchUsersQuery;
  countType?: FetchUsersCountType;
  page?: FetchUsersPage;
  pageSize?: FetchUsersPageSize;
  filters?: FetchUsersFilters;
  sort?: FetchUsersSort;
}

interface FetchUsersIndexRequestParams {
  queryName: FetchUsersIndexQueryName;
  countType?: FetchUsersIndexCountType;
  page?: FetchUsersIndexPage;
  pageSize?: FetchUsersIndexPageSize;
  filters?: FetchUsersIndexFilters;
  sort?: FetchUsersIndexSort;
}

interface FetchUserRequestParams {
  query: FetchUserQuery;
}

interface FetchUserIndexRequestParams {
  queryName: FetchUserIndexQueryName;
}

interface FetchCurrentUserRequestParams {
  queryName: FetchUserIndexQueryName;
}

export interface UpdateUserData {
  id?: UserId;
  nanoId?: UserNanoId;
  name?: MayBeUserName;
  phone?: MayBeUserPhone;
  qualifications?: UserQualifications;
  emailSignature?: UserEmailSignature;
  emailSignatureEnabled?: UserEmailSignatureEnabled;
  emailSyncEnabledAt?: MayBeUserEmailSyncEnabledAt;
  emailSyncProvider?: UserEmailSyncProvider;
  scheduleInterviewsEnabledAt?: MayBeUserScheduleInterviewsEnabledAt;
  scheduleInterviewsProvider?: UserScheduleInterviewsProvider;
  stateId?: MayBeUserStateId;
  cityId?: MayBeUserCityId;
  imageId?: MayBeUserImageId;
}

export interface CreateUserRequestData extends UpdateUserData {
  uuid: UserUuid;
  nanoId: UserNanoId;
  email: UserEmail;
  accountTypeId?: MayBeUserAccountTypeId;
  companyId?: MayBeUserCompanyId;
  searchType?: UserSearchType;
  captureType?: UserCaptureType;
  jobResumeRecruiterId?: UserJobResumeRecruiterId;
}

export interface UpdateUserRequestData extends UpdateUserData {
  firstName?: MayBeUserFirstName;
  lastName?: MayBeUserLastName;
  phone?: MayBeUserPhone;
  stateId?: MayBeUserStateId;
  cityId?: MayBeUserCityId;
  imageId?: MayBeUserImageId;
}

export interface DeleteUserRequestData {
  userNanoId: UserNanoId;
}

export interface CreateUserErrorResponse {
  message: CreateUserError;
}

export interface UpdateUserErrorResponse {
  message: UpdateUserError;
}

export interface DeleteUserErrorResponse {
  message: DeleteUserError;
}

export interface InviteUserRequestData {
  email: UserEmail;
}

export interface UpdateSecuritiesRequestData {
  password: UserPassword;
}

export interface UpdateCompanyRequestData {
  companyNanoId: CompanyNanoId;
}

export interface ApproveUserWorkEmailRequestData {
  emailInvitationNanoId?: string;
  workEmail: MayBeUserWorkEmail;
}

export interface CreateUserProfileRequestData {
  name: MayBeUserName;
  title: MayBeUserTitle;
  companyId: MayBeUserCompanyId;
  accountTypeId: MayBeUserAccountTypeId;
  imageId: MayBeUserImageId;
  cityId: MayBeUserCityId;
  phone: MayBeUserPhone;
  timeZone: MayBeUserTimeZone;
  providedRole: MayBeUserProvidedRole;
  companyEmail: MayBeUserCompanyEmail;
}

export interface SendEmailUsersRequestData {
  email: string;
  subject: string;
  body: string;
}

export interface SendEmailUsersErrorResponse {
  message: SendEmailUsersError;
}

export interface ToggleBlockedUserErrorResponse {
  message: ToggleBlockedUserError;
}

export class UsersBffRequests extends BaseBffRequests {
  static fetchUsers<T>(params: FetchUsersRequestParams) {
    return this.fetchItems<T>(UsersBffRoutes.fetchUsersRoute(), params);
  }

  static fetchUsersIndex<T>(params: FetchUsersIndexRequestParams) {
    return this.fetchItemsIndex<T>(
      UsersBffRoutes.fetchUsersIndexRoute(),
      params
    );
  }

  static fetchUser<T>(userId: UserId, params: FetchUserRequestParams) {
    return this.fetchItem<T>(UsersBffRoutes.fetchUserRoute(userId), params);
  }

  static fetchUserByNanoId<T>(
    userNanoId: UserNanoId,
    params: FetchUserRequestParams
  ) {
    return this.fetchItemByNanoId<T>(
      UsersBffRoutes.fetchUserByNanoIdRoute(userNanoId),
      params
    );
  }

  static fetchUserIndex<T>(
    userUuid: UserUuid | UserNanoId,
    params: FetchUserIndexRequestParams
  ) {
    return this.fetchItemIndex<T>(
      UsersBffRoutes.fetchUserIndexRoute(userUuid),
      params
    );
  }

  static fetchCurrentUser<T>(params: FetchCurrentUserRequestParams) {
    return this.fetchItemIndex<T>(
      UsersBffRoutes.fetchCurrentUserRoute(),
      params
    );
  }

  static createUser<T>(createUserData: CreateUserRequestData) {
    return this.createItem<T, CreateUserRequestData>(
      UsersBffRoutes.createUserRoute(),
      createUserData
    );
  }

  static async updateUser(
    updateUserNanoId: UserId | UserNanoId,
    updateUserData: UpdateUserRequestData
  ) {
    const { data, error } = await this.updateItem<
      { data: UpdateUserRequestData; error: string | null },
      UpdateUserRequestData
    >(UsersBffRoutes.updateUserRoute(updateUserNanoId), updateUserData);

    if (error) {
      throw error;
    }

    return data;
  }

  static async deleteUser(deleteUserNanoId: UserNanoId) {
    const { data, error } = await this.deleteItem<{
      data: DeleteUserRequestData;
      error: string | null;
    }>(UsersBffRoutes.deleteUserRoute(deleteUserNanoId));

    if (error) {
      throw error;
    }

    return data;
  }

  static async inviteUser(inviteUserData: InviteUserRequestData) {
    const { data, error } = await this.postItem<
      { data: { id: string; email: string }; error: string | null },
      InviteUserRequestData
    >(UsersBffRoutes.inviteUserRoute(), inviteUserData);

    if (error) {
      throw error;
    }

    return data;
  }

  static async updateSecurities(
    userId: UserId,
    updateSecuritiesData: UpdateSecuritiesRequestData
  ) {
    const { data, error } = await this.postItem<
      { data: { id: string; password: UserPassword }; error: string | null },
      UpdateSecuritiesRequestData
    >(UsersBffRoutes.updateSecuritiesRoute(userId), updateSecuritiesData);

    if (error) {
      throw error;
    }

    return data;
  }

  static async updateCompany(
    userId: UserId,
    updateCompanyData: UpdateCompanyRequestData
  ) {
    const { data, error } = await this.postItem<
      {
        data: { id: string; companyNanoId: CompanyNanoId };
        error: string | null;
      },
      UpdateCompanyRequestData
    >(UsersBffRoutes.updateCompanyRoute(userId), updateCompanyData);

    if (error) {
      throw error;
    }

    return data;
  }

  static async approveUserWorkEmail(
    userNanoId: UserNanoId,
    approveUserWorkEmailRequestData: ApproveUserWorkEmailRequestData
  ) {
    const { data, error } = await this.postItem<
      {
        data: ApproveUserWorkEmailRequestData;
        error: string | null;
      },
      ApproveUserWorkEmailRequestData
    >(
      UsersBffRoutes.approveUserWorkEmailRoute(userNanoId),
      approveUserWorkEmailRequestData
    );

    if (error) {
      throw error;
    }

    return data;
  }

  static async createUserProfile(
    userNanoId: UserNanoId,
    createUserProfileData: CreateUserProfileRequestData
  ) {
    const { data, error } = await this.postItem<
      {
        data: CreateUserProfileRequestData;
        error: string | null;
      },
      CreateUserProfileRequestData
    >(UsersBffRoutes.createUserProfileRoute(userNanoId), createUserProfileData);

    if (error) {
      throw error;
    }

    return data;
  }

  static sendEmailUsers<T>(sendEmailUsersData: SendEmailUsersRequestData) {
    return this.postItems<T, SendEmailUsersRequestData>(
      UsersBffRoutes.sendEmailUsersRoute(),
      sendEmailUsersData
    );
  }

  static async toggleBlockedUser<T>(toggleBlockedUserId: UserId) {
    return this.postItem<T, Record<string, unknown>>(
      UsersBffRoutes.toggleBlockedUserRoute(toggleBlockedUserId),
      {}
    );
  }
}
