import { useMutation, useQueryClient } from 'react-query';

import {
  FetchItemsCacheKey,
  FetchItemsIndexCacheKey,
  CreateItemError,
  CreateItemErrorMessage,
  CreateItemIsLoading,
  FetchItemCacheKey
} from '../../../../types';

import { parseRequestError } from '../../../../utils/parseRequestError';

interface CreateItemOptions<CreateItemRequestData> {
  mutationFn: (
    queryInput: CreateItemRequestData
  ) => Promise<CreateItemRequestData>;
  cacheKeys?: (
    | FetchItemsCacheKey
    | FetchItemsIndexCacheKey
    | FetchItemCacheKey
  )[];
}

function useCreateItem<
  CreateItemRequestData,
  CreateItemErrorResponse extends { message: CreateItemError }
>({ mutationFn, cacheKeys = [] }: CreateItemOptions<CreateItemRequestData>) {
  const queryClient = useQueryClient();

  const { data, error, isLoading, mutateAsync, reset } = useMutation<
    CreateItemRequestData,
    CreateItemErrorResponse,
    CreateItemRequestData
  >((queryInput) => mutationFn(queryInput), {
    onSettled: async () =>
      cacheKeys?.map((cacheKey) => queryClient.invalidateQueries(cacheKey))
  });

  return {
    createItemData: data,
    createItemError: error,
    createItemErrorMessage: parseRequestError(error) as CreateItemErrorMessage,
    createItemIsLoading: isLoading as CreateItemIsLoading,
    createItem: (createItemData: CreateItemRequestData) =>
      mutateAsync(createItemData),
    createItemReset: () => reset()
  };
}

export default useCreateItem;
