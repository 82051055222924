import { usePaginatedItems } from '../../../common/hooks/usePaginatedItems';

import {
  FetchTalentPoolJobCandidatesQuery,
  FetchTalentPoolJobCandidatesCacheKey,
  FetchTalentPoolJobCandidatesTotalCount,
  FetchTalentPoolJobCandidatesPage,
  FetchTalentPoolJobCandidatesPageSize,
  FetchTalentPoolJobCandidatesError,
  FetchTalentPoolJobCandidatesErrorMessage,
  FetchTalentPoolJobCandidatesIsLoading,
  FetchTalentPoolJobCandidatesIsFetched,
  FetchTalentPoolJobCandidatesIsPlaceholderData,
  FetchTalentPoolJobCandidatesEnabled,
  FetchTalentPoolJobCandidatesPrefetchNextPage,
  FetchTalentPoolJobCandidatesCountType,
  FetchTalentPoolJobCandidatesFilters,
  FetchTalentPoolJobCandidatesSort,
  FetchTalentPoolJobCandidateID,
  FetchTalentPoolJobCandidateQuery,
  FetchTalentPoolJobCandidateCacheKey
} from '../../talentPoolJobCandidatesTypes';

import { TalentPoolJobCandidatesBffRequests } from '../../TalentPoolJobCandidatesBffRequests';

interface PaginatedTalentPoolJobCandidatesBaseOptions<
  PaginatedTalentPoolJobCandidatesResponseType
> {
  cacheKey: FetchTalentPoolJobCandidatesCacheKey;
  query: FetchTalentPoolJobCandidatesQuery;
  countType?: FetchTalentPoolJobCandidatesCountType;
  initialPage?: FetchTalentPoolJobCandidatesPage;
  initialPageSize?: FetchTalentPoolJobCandidatesPageSize;
  initialFilters?: FetchTalentPoolJobCandidatesFilters;
  initialSort?: FetchTalentPoolJobCandidatesSort;
  enabled?: FetchTalentPoolJobCandidatesEnabled;
  prefetchNextPage?: FetchTalentPoolJobCandidatesPrefetchNextPage;
  placeholderTalentPoolJobCandidates?: PaginatedTalentPoolJobCandidatesResponseType[];
  placeholderTalentPoolJobCandidatesCount?: FetchTalentPoolJobCandidatesTotalCount;
  placeholderData?: () => PaginatedTalentPoolJobCandidatesResponse<PaginatedTalentPoolJobCandidatesResponseType>;
}

interface PaginatedTalentPoolJobCandidatesOptionsWithoutItem {
  fetchItemCacheKey?: never;
  itemQuery?: never;
}

interface PaginatedTalentPoolJobCandidatesOptionsWithItem {
  fetchItemCacheKey: FetchTalentPoolJobCandidateCacheKey;
  itemQuery: FetchTalentPoolJobCandidateQuery;
}

type PaginatedTalentPoolJobCandidatesOptions<
  PaginatedTalentPoolJobCandidatesResponseType
> = PaginatedTalentPoolJobCandidatesBaseOptions<PaginatedTalentPoolJobCandidatesResponseType> &
  (
    | PaginatedTalentPoolJobCandidatesOptionsWithoutItem
    | PaginatedTalentPoolJobCandidatesOptionsWithItem
  );

interface PaginatedTalentPoolJobCandidatesResponse<
  PaginatedTalentPoolJobCandidatesResponseType
> {
  data: PaginatedTalentPoolJobCandidatesResponseType[];
  totalCount: FetchTalentPoolJobCandidatesTotalCount;
}

function usePaginatedTalentPoolJobCandidates<
  PaginatedTalentPoolJobCandidatesResponseType
>({
  cacheKey,
  query,
  countType,
  initialPage,
  initialPageSize,
  initialFilters,
  initialSort,
  enabled,
  prefetchNextPage,
  placeholderTalentPoolJobCandidates,
  placeholderTalentPoolJobCandidatesCount,
  placeholderData,
  fetchItemCacheKey,
  itemQuery
}: PaginatedTalentPoolJobCandidatesOptions<PaginatedTalentPoolJobCandidatesResponseType>) {
  const {
    items,
    itemsTotalCount,
    itemsError,
    itemsErrorMessage,
    itemsIsFetched,
    itemsIsLoading,
    itemsIsPlaceholderData,
    itemsCurrentPage,
    itemsCurrentPageSize,
    itemsCurrentFilters,
    itemsCurrentSort,
    fetchItems,
    paginateItems,
    changeItemsPageSize,
    filterItems,
    changeItemsFilters,
    clearItemsFilters,
    sortItems,
    prefetchItems,
    prefetchItem
  } = usePaginatedItems<PaginatedTalentPoolJobCandidatesResponseType>({
    cacheKey,
    initialPage,
    initialPageSize,
    initialFilters,
    initialSort,
    enabled,
    prefetchNextPage,
    placeholderItems: placeholderTalentPoolJobCandidates,
    placeholderItemsCount: placeholderTalentPoolJobCandidatesCount,
    placeholderData,
    queryFn: (params) =>
      TalentPoolJobCandidatesBffRequests.fetchTalentPoolJobCandidates<PaginatedTalentPoolJobCandidatesResponseType>(
        {
          query,
          countType,
          page: params.page as FetchTalentPoolJobCandidatesPage,
          pageSize: params.pageSize as FetchTalentPoolJobCandidatesPageSize,
          filters: params.filters as FetchTalentPoolJobCandidatesFilters,
          sort: params.sort as FetchTalentPoolJobCandidatesSort
        }
      ),
    ...(itemQuery
      ? {
          fetchItemCacheKey,
          queryItemFn: (itemId) =>
            TalentPoolJobCandidatesBffRequests.fetchTalentPoolJobCandidate(
              itemId as FetchTalentPoolJobCandidateID,
              {
                query: itemQuery
              }
            )
        }
      : {})
  });

  return {
    talentPoolJobCandidates:
      items as PaginatedTalentPoolJobCandidatesResponseType[],
    talentPoolJobCandidatesTotalCount:
      itemsTotalCount as FetchTalentPoolJobCandidatesTotalCount,
    talentPoolJobCandidatesError:
      itemsError as FetchTalentPoolJobCandidatesError,
    talentPoolJobCandidatesErrorMessage:
      itemsErrorMessage as FetchTalentPoolJobCandidatesErrorMessage,
    talentPoolJobCandidatesIsLoading:
      itemsIsLoading as FetchTalentPoolJobCandidatesIsLoading,
    talentPoolJobCandidatesIsFetched:
      itemsIsFetched as FetchTalentPoolJobCandidatesIsFetched,
    talentPoolJobCandidatesIsPlaceholderData:
      itemsIsPlaceholderData as FetchTalentPoolJobCandidatesIsPlaceholderData,
    talentPoolJobCandidatesCurrentPage:
      itemsCurrentPage as FetchTalentPoolJobCandidatesPage,
    talentPoolJobCandidatesCurrentPageSize:
      itemsCurrentPageSize as FetchTalentPoolJobCandidatesPageSize,
    talentPoolJobCandidatesCurrentFilters:
      itemsCurrentFilters as FetchTalentPoolJobCandidatesFilters,
    talentPoolJobCandidatesCurrentSort:
      itemsCurrentSort as FetchTalentPoolJobCandidatesSort,
    fetchTalentPoolJobCandidates: fetchItems,
    paginateTalentPoolJobCandidates: paginateItems,
    changeTalentPoolJobCandidatesPageSize: changeItemsPageSize,
    filterTalentPoolJobCandidates: filterItems,
    changeTalentPoolJobCandidatesFilters: changeItemsFilters,
    clearTalentPoolJobCandidatesFilters: clearItemsFilters,
    sortTalentPoolJobCandidates: sortItems,
    prefetchTalentPoolJobCandidates: prefetchItems,
    prefetchTalentPoolJobCandidate: prefetchItem
  };
}

export default usePaginatedTalentPoolJobCandidates;
