import { Flex, HStack, Stack } from '@chakra-ui/react';
import { Fragment } from 'react';
import { Heading } from '../../../../../../../../../../../helpers/Heading';
import { JobsCache } from '../../../../../../../../../JobsCache';
import { useFetchJobByNanoId } from '../../../../../../../../../hooks/useFetchJobByNanoId';
import {
  FetchJobEnabled,
  JobNanoId
} from '../../../../../../../../../jobsTypes';
import {
  FetchJobResponse,
  fetchJobQuery
} from '../../../../../../../../../queries/fetchJob.query';
import { ViewJobCandidateModalPhoneSidebarJobDetailsCard } from '../ViewJobCandidateModalPhoneSidebarJobDetailsCard';

interface ViewJobCandidateModalPhoneSidebarJobDetailsProps {
  jobNanoId: JobNanoId;
}

const ViewJobCandidateModalPhoneSidebarJobDetails = ({
  jobNanoId
}: ViewJobCandidateModalPhoneSidebarJobDetailsProps) => {
  const { job, jobIsFetched } = useFetchJobByNanoId<FetchJobResponse>({
    jobNanoId: jobNanoId as JobNanoId,
    query: fetchJobQuery,
    enabled: !!jobNanoId as FetchJobEnabled,
    cacheKey: JobsCache.showCacheKey()
  });

  return (
    <Fragment>
      {jobIsFetched && job && (
        <Stack spacing={4}>
          <Flex justifyContent="space-between" gap={6}>
            <HStack>
              <Heading level="h5" fontSize="16px">
                Job details
              </Heading>
            </HStack>
          </Flex>

          <ViewJobCandidateModalPhoneSidebarJobDetailsCard job={job} />
        </Stack>
      )}
    </Fragment>
  );
};

export default ViewJobCandidateModalPhoneSidebarJobDetails;
