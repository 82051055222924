import { ReactNode, createContext, useContext, useState } from 'react';
import {
  JobCandidateId,
  JobCandidateNanoId
} from '../../../../../../../../../jobCandidates/jobCandidatesTypes';
import { JobId, JobNanoId } from '../../../../../../../../jobsTypes';

type PhoneSidebarContextJobCandidate = {
  nanoId: JobCandidateNanoId;
  id: JobCandidateId;
  job: {
    id: JobId;
    nanoId: JobNanoId;
  };
};

interface ViewJobCandidateModalPhoneSidebarContextProps {
  openedCategory: string;
  setOpenedCategory: React.Dispatch<React.SetStateAction<string>>;
  isCallSnoozed: boolean;
  showJobDetails: boolean;
  toggleEmailSidebar: () => void;
  onToggleIsCallSnooze: () => void;
  onToggleShowJobDetails: (state?: boolean) => void;
  job?: {
    id: JobId;
    nanoId: JobNanoId;
  };
  jobCandidate?: PhoneSidebarContextJobCandidate;
}

const ViewJobCandidateModalPhoneSidebarContext =
  createContext<ViewJobCandidateModalPhoneSidebarContextProps>({
    openedCategory: '',
    setOpenedCategory: () => {
      /*  */
    },
    isCallSnoozed: false,
    showJobDetails: false,
    toggleEmailSidebar: () => {
      /*  */
    },
    onToggleIsCallSnooze: () => {
      /*  */
    },
    onToggleShowJobDetails: () => {
      /*  */
    }
  });

type ViewJobCandidateModalPhoneSidebarContextProviderProps = {
  children: ReactNode;
  toggleEmailSidebar: () => void;
  jobCandidate: PhoneSidebarContextJobCandidate;
};

export const useViewJobCandidateModalPhoneSidebarContext = () =>
  useContext(ViewJobCandidateModalPhoneSidebarContext);

export const ViewJobCandidateModalPhoneSidebarContextProvider = ({
  children,
  jobCandidate,
  toggleEmailSidebar
}: ViewJobCandidateModalPhoneSidebarContextProviderProps) => {
  const [openedCategory, setOpenedCategory] = useState<string>('');
  const [isCallSnoozed, setIsCallSnoozed] = useState<boolean>(false);
  const [showJobDetails, setShowJobDetails] = useState<boolean>(false);

  const onToggleIsCallSnooze = () => {
    setIsCallSnoozed((prevState) => !prevState);
  };

  const onToggleShowJobDetails = (state?: boolean) => {
    if (state !== undefined) {
      setShowJobDetails(state);
      return;
    }

    setShowJobDetails((prevState) => !prevState);
  };

  return (
    <ViewJobCandidateModalPhoneSidebarContext.Provider
      value={{
        jobCandidate,
        isCallSnoozed,
        openedCategory,
        showJobDetails,
        setOpenedCategory,
        toggleEmailSidebar,
        onToggleIsCallSnooze,
        job: jobCandidate.job,
        onToggleShowJobDetails
      }}
    >
      {children}
    </ViewJobCandidateModalPhoneSidebarContext.Provider>
  );
};
