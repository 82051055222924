import {
  FetchCertificationsCacheKey,
  FetchCertificationCacheKey
} from './certificationsTypes';

import { JobCandidateId } from '../jobCandidates/jobCandidatesTypes';

import { JobResumeRecruiterId } from '../jobResumeRecruiters/jobResumeRecruitersTypes';

export class CertificationsCache {
  static indexCacheKey() {
    return 'certifications' as FetchCertificationsCacheKey;
  }

  static showCacheKey() {
    return 'certification' as FetchCertificationCacheKey;
  }

  static existsCacheKey() {
    return 'certifications-exists' as FetchCertificationsCacheKey;
  }

  static jobCandidateListCacheKey(jobCandidateId: JobCandidateId) {
    return `certifications-${jobCandidateId}` as FetchCertificationsCacheKey;
  }

  static jobResumeRecruiterListCacheKey(
    jobResumeRecruiterId: JobResumeRecruiterId
  ) {
    return `certifications-${jobResumeRecruiterId}` as FetchCertificationsCacheKey;
  }
}
