import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel
} from '@chakra-ui/form-control';
import {
  Props as ChakraSelectProps,
  Select as ReactSelect,
  OptionBase,
  GroupBase
} from 'chakra-react-select';

import { toCamelCase } from '../../utils/toCamelCase';

interface SelectOptionType extends OptionBase {
  value: string;
  label: string;
}

interface SelectProps
  extends ChakraSelectProps<
    SelectOptionType,
    false,
    GroupBase<SelectOptionType>
  > {
  id?: string;
  label?: string;
  hasError?: boolean;
  errorMessage?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  helperText?: string;
  placeholder?: string;
  width?: string;
}

function Select({
  id,
  label,
  width,
  // size = 'medium',
  isRequired,
  isDisabled,
  hasError,
  errorMessage,
  isReadOnly,
  helperText,
  placeholder,
  ...props
}: SelectProps) {
  if (!id && label) {
    id = toCamelCase(label);
  }

  return (
    <FormControl
      isInvalid={hasError || typeof errorMessage === 'string'}
      isDisabled={isDisabled}
      isRequired={isRequired}
      isReadOnly={isReadOnly}
      width={width}
      id={id}
    >
      {label && <FormLabel size="sm">{label}</FormLabel>}
      {/*placeholderColor="gray.900"*/}
      <ReactSelect<SelectOptionType, false, GroupBase<SelectOptionType>>
        inputId={id}
        placeholder={placeholder}
        focusBorderColor="primary.500"
        selectedOptionColor="primary"
        chakraStyles={{
          dropdownIndicator: (provided) => ({
            ...provided,
            bg: 'transparent',
            w: 6
          }),
          option: (provided) => ({
            ...provided,
            h: 10,
            px: 4
          }),
          indicatorSeparator: (provided) => ({
            ...provided,
            display: 'none'
          })
        }}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary: '#0580A4'
          }
        })}
        {...props}
      />

      {errorMessage ? (
        <FormErrorMessage wordBreak="break-all">
          {errorMessage}
        </FormErrorMessage>
      ) : (
        helperText && <FormHelperText>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
}

export default Select;
