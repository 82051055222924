import { useDisclosure } from '@chakra-ui/react';
import { createContext, ReactNode, useContext, useMemo } from 'react';
import { JobCandidateNanoId } from '../../../../../jobCandidates/jobCandidatesTypes';

type ViewJobCandidateDetailsContextType = {
  openProfileOverview: () => void;
  closeProfileOverview: () => void;
  toggleProfileOverview: () => void;
  isProfileOverviewOpen: boolean;
};

type ViewJobCandidateDetailsProviderProps = {
  children: ReactNode;
  jobCandidateNanoId: JobCandidateNanoId;
};

const ViewJobCandidateDetailsContext =
  createContext<ViewJobCandidateDetailsContextType>({
    isProfileOverviewOpen: true,
    openProfileOverview: () => {
      //
    },
    closeProfileOverview: () => {
      //
    },
    toggleProfileOverview: () => {
      //
    }
  });

export function ViewJobCandidateDetailsProvider({
  children
}: ViewJobCandidateDetailsProviderProps) {
  const {
    onOpen: openProfileOverview,
    onClose: closeProfileOverview,
    onToggle: toggleProfileOverview,
    isOpen: isProfileOverviewOpen
  } = useDisclosure({
    defaultIsOpen: true
  });

  const values: ViewJobCandidateDetailsContextType = useMemo(
    () => ({
      openProfileOverview,
      closeProfileOverview,
      toggleProfileOverview,
      isProfileOverviewOpen
    }),
    [
      openProfileOverview,
      closeProfileOverview,
      toggleProfileOverview,
      isProfileOverviewOpen
    ]
  );

  return (
    <ViewJobCandidateDetailsContext.Provider value={values}>
      {children}
    </ViewJobCandidateDetailsContext.Provider>
  );
}

export const useViewJobCandidateDetailsContext = () =>
  useContext(ViewJobCandidateDetailsContext);
