import {
  Collapse,
  Flex,
  HStack,
  Stack,
  Switch,
  useDisclosure
} from '@chakra-ui/react';
import { FunctionComponent, ReactNode, useMemo } from 'react';
import { Text } from '../../../../../../../../helpers/Text';
import { useViewJobCandidateDetailsSubmissionContext } from '../../../../contexts/ViewJobCandidateDetailsSubmissionContext';
import { CandidateSubmissionShareSettingsField } from '../../../../../../../candidateSubmissions/candidateSubmissionsTypes';
import { Button } from '../../../../../../../../helpers/Button';

type CustomiseSubmissionProfileItemProps = {
  label: string;
  children?: ReactNode;
  isDisabled?: boolean;
  icon?: FunctionComponent;
  value: CandidateSubmissionShareSettingsField;
};

export default function CustomiseCandidateSubmissionItem({
  icon,
  label,
  isDisabled,
  value,
  children
}: CustomiseSubmissionProfileItemProps) {
  const { handleShareField, sharedFields } =
    useViewJobCandidateDetailsSubmissionContext();

  const isShared = useMemo(
    () => sharedFields.includes(value),
    [sharedFields, value]
  );

  const { isOpen: isOpenOptions, onToggle: onToggleOptions } = useDisclosure({
    defaultIsOpen: isShared
  });

  const handleChange = () => {
    handleShareField(value);
  };

  return (
    <Stack spacing={0}>
      <Flex
        px={2}
        gap={2}
        py={1.5}
        as="label"
        alignItems="center"
        transitionDuration="slow"
        cursor={isDisabled ? 'not-allowed' : 'pointer'}
        _hover={{ bg: 'gray.100' }}
      >
        {icon && (
          <Flex
            w={4}
            h={4}
            as={icon}
            color="gray.600"
            fill="gray.600"
            opacity={isDisabled ? 0.5 : 1}
            transitionDuration="slow"
            transitionProperty="all"
          />
        )}

        <Text flex={1} opacity={isDisabled ? 0.5 : 1}>
          {label}
        </Text>

        <HStack spacing={6}>
          {isShared && children && (
            <Button size="small" hierarchy="link" onClick={onToggleOptions}>
              {isOpenOptions ? 'Hide' : 'Show'} options
            </Button>
          )}

          <Switch
            value={value}
            name="sharedFields"
            isChecked={isShared}
            isDisabled={isDisabled}
            onChange={handleChange}
          />
        </HStack>
      </Flex>

      {children && (
        <Collapse in={isOpenOptions && isShared}>
          <Stack spacing={1} pl={6}>
            {children}
          </Stack>
        </Collapse>
      )}
    </Stack>
  );
}
