import { AvatarHelper } from '../../../../../../../../../helpers/AvatarHelper';

import { SelectFieldControl } from '../../../../../../../../../helpers/forms/formFields/SelectField/components/SelectFieldControl';

import {
  JobCandidateId,
  JobCandidateName,
  JobCandidateImage
} from '../../../../../../../../../main/jobCandidates/jobCandidatesTypes';

import { renderJobCandidateName } from '../../../../../../../../../main/jobCandidates/utils/renderJobCandidateName';

interface SelectJobCandidateFieldProps {
  handleSelectCandidate: (jobCandidateId: JobCandidateId) => void;
  selectedJobCandidate: {
    id: JobCandidateId;
  };
  jobCandidates: {
    id: JobCandidateId;
    name: JobCandidateName;
    image: JobCandidateImage;
  }[];
}

function SelectJobCandidateField({
  handleSelectCandidate,
  selectedJobCandidate,
  jobCandidates
}: SelectJobCandidateFieldProps) {
  const options = jobCandidates.map((jobCandidate) => ({
    value: jobCandidate.id,
    label: renderJobCandidateName(jobCandidate),
    image: <AvatarHelper size="2xs" image={jobCandidate.image} />
  }));
  return (
    <SelectFieldControl
      withImages
      label="Candidate"
      placeholder="Select Candidate"
      options={options}
      onChange={(value) => handleSelectCandidate(value as JobCandidateId)}
      value={selectedJobCandidate.id}
    />
  );
}

export default SelectJobCandidateField;
