import {
  Box,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useDisclosure
} from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { Button, ButtonProps } from '../../../../../../../../helpers/Button';
import { Text } from '../../../../../../../../helpers/Text';
import { IconButton } from '../../../../../../../../helpers/buttons/IconButton';
import { ChevronDownIcon } from '../../../../../../../../icons/ChevronDownIcon';
import { MoreIcon } from '../../../../../../../../icons/MoreIcon';
import { ViewJobCandidateDetailsDisqualifyCandidate } from '../ViewJobCandidateDetailsDisqualifyCandidate';

interface ViewJobCandidateDetailsTaskSubActionsDropdownProps {
  label?: string;
  actionSelected?: boolean;
  triggerIcon?: FunctionComponent;
  disqualifyCandidate: (reason: string) => void;
  closeDropdown?: () => void;
  openDropdown?: () => void;
  cancelTask?: () => void;
  skipTask?: () => void;
}

export default function ViewJobCandidateDetailsTaskSubActionsDropdown({
  label,
  skipTask,
  cancelTask,
  triggerIcon,
  openDropdown,
  closeDropdown,
  actionSelected,
  disqualifyCandidate
}: ViewJobCandidateDetailsTaskSubActionsDropdownProps) {
  const { isOpen, onToggle, onClose } = useDisclosure({
    onClose: () => closeDropdown && closeDropdown(),
    onOpen: () => openDropdown && openDropdown()
  });

  return (
    <Popover isOpen={isOpen} onClose={onClose} isLazy>
      <PopoverTrigger>
        <Flex alignItems="stretch" w="full">
          {label ? (
            <Button
              p={0}
              w="full"
              h="100%"
              minH={6}
              minW={0}
              size="small"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              wordBreak="break-all"
              onClick={(e) => {
                e.stopPropagation();
                onToggle();
              }}
              border="none"
              textAlign="left"
              boxShadow="none"
              fontSize="inherit"
              hierarchy="unstyled"
              aria-label="Dropdown"
            >
              <Flex
                gap={1}
                w="auto"
                display="flex"
                fontSize="inherit"
                alignItems="center"
                whiteSpace="nowrap"
                wordBreak="break-all"
              >
                <Text
                  noOfLines={1}
                  whiteSpace="nowrap"
                  wordBreak="break-all"
                  textOverflow="ellipsis"
                  textTransform="capitalize"
                  textStyle="body2Regular"
                  display="inline-block"
                >
                  {label}
                </Text>

                <ChevronDownIcon ml="auto" fill="gray.500" />
              </Flex>
            </Button>
          ) : (
            <IconButton
              p={0}
              size="small"
              aria-label=""
              hierarchy="link"
              icon={<Flex w={6} h={6} as={triggerIcon ?? MoreIcon} />}
              onClick={(e) => {
                e.stopPropagation();
                onToggle();
              }}
            />
          )}
        </Flex>
      </PopoverTrigger>

      <Portal>
        <PopoverContent p={3} w="auto" minW="60" overflow="hidden">
          <Flex flexDir="column" overflow="auto" gap={1}>
            {actionSelected && (
              <>
                <SubActionButton
                  value="Skip Task"
                  label="Skip Task"
                  onClick={() => {
                    skipTask && skipTask();
                    onClose();
                  }}
                />

                <SubActionButton
                  value="Cancel Task"
                  label="Cancel Task"
                  onClick={() => {
                    cancelTask && cancelTask();
                    onClose();
                  }}
                />
              </>
            )}

            <SubActionButton
              value="Candidate Withdrew"
              label="Candidate Withdrew"
              onClick={() => {
                disqualifyCandidate('withdrew');
              }}
            />

            <Box px={2}>
              <ViewJobCandidateDetailsDisqualifyCandidate
                onClose={onClose}
                disqualifyCandidate={disqualifyCandidate}
              />
            </Box>
          </Flex>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}

interface SubActionButton extends ButtonProps {
  label: string;
}

function SubActionButton({ label, onClick, ...props }: SubActionButton) {
  return (
    <Button
      px={2}
      py={1}
      h="auto"
      size="small"
      textAlign="left"
      lineHeight={1.5}
      fontSize="inherit"
      borderRadius="none"
      fontWeight="inherit"
      hierarchy="unstyled"
      textTransform="capitalize"
      onClick={(e) => {
        // e.stopPropagation();
        onClick ? onClick(e) : undefined;
      }}
      {...props}
      _hover={{
        bg: 'gray.100'
      }}
    >
      {label}
    </Button>
  );
}
