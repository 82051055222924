import {
  IndustryExpertiseItemBffID,
  IndustryExpertiseItemBffNanoID,
  FetchIndustryExpertisesBffURL,
  FetchIndustryExpertiseBffURL,
  FetchIndustryExpertiseByNanoIdBffURL,
  CreateIndustryExpertiseBffURL,
  UpdateIndustryExpertiseBffURL,
  DeleteIndustryExpertiseBffURL
} from './industryExpertisesTypes';

import { BaseBffRoutes } from '../BaseBffRoutes';

export class IndustryExpertisesBffRoutes extends BaseBffRoutes {
  protected static route = 'industryExpertises';

  static fetchIndustryExpertisesRoute() {
    return this.fetchItemsRoute<FetchIndustryExpertisesBffURL>();
  }

  static fetchIndustryExpertisesIndexRoute() {
    return this.fetchItemsIndexRoute<FetchIndustryExpertisesBffURL>();
  }

  static fetchIndustryExpertiseRoute(
    industryExpertiseItemBffID: IndustryExpertiseItemBffID
  ) {
    return this.fetchItemRoute<FetchIndustryExpertiseBffURL>(
      industryExpertiseItemBffID
    );
  }

  static fetchIndustryExpertiseIndexRoute(
    industryExpertiseItemBffNanoID: IndustryExpertiseItemBffNanoID
  ) {
    return this.fetchItemIndexRoute<FetchIndustryExpertiseBffURL>(
      industryExpertiseItemBffNanoID
    );
  }

  static fetchIndustryExpertiseByNanoIdRoute(
    industryExpertiseItemBffNanoID: IndustryExpertiseItemBffNanoID
  ) {
    return this.fetchItemByNanoIdRoute<FetchIndustryExpertiseByNanoIdBffURL>(
      industryExpertiseItemBffNanoID
    );
  }

  static createIndustryExpertiseRoute() {
    return this.createItemRoute<CreateIndustryExpertiseBffURL>();
  }

  static updateIndustryExpertiseRoute(
    industryExpertiseItemBffID: IndustryExpertiseItemBffID
  ) {
    return this.updateItemRoute<UpdateIndustryExpertiseBffURL>(
      industryExpertiseItemBffID
    );
  }

  static deleteIndustryExpertiseRoute(
    industryExpertiseItemBffID: IndustryExpertiseItemBffID
  ) {
    return this.deleteItemRoute<DeleteIndustryExpertiseBffURL>(
      industryExpertiseItemBffID
    );
  }
}
