import { Box, SimpleGrid, Stack } from '@chakra-ui/react';
import { FieldValues, Path } from 'react-hook-form';
import { useCallback } from 'react';
import trim from 'lodash/trim';

import { InputField } from '../../../../../../helpers/forms/formFields/InputField';
import { IconButton } from '../../../../../../helpers/buttons/IconButton';

import { SelectAccountTypeFormField } from '../../../../../common/components/formFields/SelectAccountTypeFormField';

import { ShareCandidateProfileInviteUserFormFieldControl } from './ShareCandidateProfileInviteUserForm.types';
import { ErrorMessage } from '../../../../../../types';

import { isEmail } from '../../../../../../utils/isEmail';

import { RemoveIcon } from '../../../../../../icons/RemoveIcon';
import { AccountTypeId } from '../../../../../accountTypes/accountTypesTypes';

const nameValidationRules = {
  required: 'Name is required'
};

const roleValidationRules = {
  required: 'Role is required'
};

const emailValidationRules = {
  required: 'Email is required',
  validate: {
    isValid: (value: string) => isEmail(trim(value)) || 'Email is invalid'
  }
};

interface ShareCandidateProfileInviteUserFormProps<T extends FieldValues> {
  control: ShareCandidateProfileInviteUserFormFieldControl<T>;
  name: string;
  index: number;
  onRemove: (index: number) => void;
  error?: { [key: string]: { message?: ErrorMessage } };
  currentAccountTypeId?: AccountTypeId;
}

function ShareCandidateProfileInviteUserForm<T extends FieldValues>({
  control,
  name,
  index,
  error,
  onRemove,
  currentAccountTypeId
}: ShareCandidateProfileInviteUserFormProps<T>) {
  const handleRemove = useCallback<() => void>(
    () => onRemove(index),
    [index, onRemove]
  );

  return (
    <>
      <Stack p={4} spacing={4} direction="row" bg="gray.50" borderRadius="base">
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={6} flex={1}>
          <Box borderRadius="base">
            <InputField
              placeholder="Name"
              id={`${name}.${index}.name`}
              {...control.register(
                `${name}.${index}.name` as Path<T>,
                nameValidationRules
              )}
              errorMessage={error?.name?.message}
            />
          </Box>

          <InputField
            type="email"
            placeholder="Company Email"
            id={`${name}.${index}.email`}
            {...control.register(
              `${name}.${index}.email` as Path<T>,
              emailValidationRules
            )}
            errorMessage={error?.email?.message}
          />

          <Box borderRadius="base">
            <SelectAccountTypeFormField
              control={control}
              name={`${name}.${index}.accountTypeId` as Path<T>}
              rules={roleValidationRules}
              placeholder="Role"
              currentAccountTypeId={currentAccountTypeId}
              withoutSearchInput
              withoutLabel
              errorMessage={error?.accountTypeId?.message}
            />
          </Box>
        </SimpleGrid>
        <IconButton
          size="small"
          value={index}
          aria-label={''}
          hierarchy="link"
          onClick={handleRemove}
          icon={<RemoveIcon fill="red.500" w={5} h={5} />}
        />
      </Stack>
    </>
  );
}

export default ShareCandidateProfileInviteUserForm;
