import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel
} from '@chakra-ui/form-control';
import { Radio as ChakraRadio } from '@chakra-ui/radio';
import {
  RadioGroup,
  Stack,
  StackProps,
  TypographyProps
} from '@chakra-ui/react';
import toString from 'lodash/toString';

import { Text } from '../../../../../Text';

import { mapSize, MapSizeVariant } from '../../../../../../utils/mapSize';

import {
  RadioGroupFieldControlOnInputChange,
  RadioGroupFieldControlOption,
  RadioGroupFieldControlOptions
} from './RadioGroupFieldControl.types';

interface RadioGroupFieldProps {
  name: string;
  options: RadioGroupFieldControlOptions;
  defaultValue?: RadioGroupFieldControlOption;
  id?: string;
  label?: string;
  hasError?: boolean;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  isRequired?: boolean;
  helperText?: string;
  size?: MapSizeVariant;
  value: string;
  onChange: RadioGroupFieldControlOnInputChange;
  errorMessage?: string | null;
  stackDirection?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  fontSize?: TypographyProps['fontSize'];
  spacing?: StackProps['spacing'];
}

function RadioGroupFieldControl({
  options,
  id,
  name,
  label,
  hasError,
  isDisabled,
  isRequired,
  isReadOnly,
  helperText,
  size,
  errorMessage,
  fontSize,
  spacing,
  value,
  onChange,
  stackDirection = 'row'
}: RadioGroupFieldProps) {
  return (
    <FormControl
      isInvalid={hasError || typeof errorMessage === 'string'}
      isDisabled={isDisabled}
      isRequired={isRequired}
      isReadOnly={isReadOnly}
      id={id}
    >
      {label && <FormLabel size="sm">{label}</FormLabel>}
      <RadioGroup name={name} value={value} onChange={onChange}>
        <Stack direction={stackDirection} spacing={spacing}>
          {options.map((option) => (
            <ChakraRadio
              id={id}
              key={`${name}-radio-${option.value}`}
              name={`${name}-${option.value}`}
              isDisabled={isDisabled}
              isReadOnly={isReadOnly}
              size={size ? mapSize(size) : undefined}
              value={toString(option.value)}
            >
              <Text
                textStyle="body2Regular"
                color="gray.900"
                fontSize={fontSize}
              >
                {option.label}
              </Text>
            </ChakraRadio>
          ))}
        </Stack>
      </RadioGroup>

      {errorMessage ? (
        <FormErrorMessage wordBreak="break-all">
          {errorMessage}
        </FormErrorMessage>
      ) : (
        helperText && <FormHelperText>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
}

export default RadioGroupFieldControl;
