import {
  FieldBrand,
  ItemID,
  ItemIDs,
  ItemUUID,
  ItemNanoId,
  ItemCreatedAt,
  MayBe
} from '../../../types';

import {
  CountryId,
  CountryNanoId,
  CountryName,
  CountryLabel
} from '../../countries/countriesTypes';

import {
  StateId,
  StateNanoId,
  StateName,
  StateLabel
} from '../../states/statesTypes';

import {
  UserId,
  UserNanoId,
  UserName,
  UserFullName,
  UserEmail,
  UserImage,
  UserRoles
} from '../../users/usersTypes';

export type CityId = FieldBrand<ItemID, 'CityId'>;
export type CityIds = FieldBrand<ItemIDs, 'CityIds'>;
export type CityUuid = FieldBrand<ItemUUID, 'CityUuid'>;
export type MayBeCityUuid = MayBe<CityUuid>;
export type CityNanoId = FieldBrand<ItemNanoId, 'CityNanoId'>;
export type MayBeCityNanoId = MayBe<CityNanoId>;
export type CityName = FieldBrand<string, 'CityName'>;
export type CityLabel = FieldBrand<string, 'CityLabel'>;
export type CityUserId = FieldBrand<UserId, 'CityUserId'>;
export type MayBeCityUserId = MayBe<CityUserId>;
export type CityUser = FieldBrand<
  {
    id: UserId;
    nanoId: UserNanoId;
    name: UserName;
    fullName: UserFullName;
    email: UserEmail;
    image: UserImage;
    roles: UserRoles;
  },
  'CityUser'
>;
export type MayBeCityUser = MayBe<CityUser>;
export type CityStateId = FieldBrand<StateId, 'CityStateId'>;
export type MayBeCityStateId = MayBe<CityStateId>;
export type CityState = FieldBrand<
  {
    id: StateId;
    nanoId: StateNanoId;
    name: StateName;
    label: StateLabel;
  },
  'CityState'
>;
export type MayBeCityState = MayBe<CityState>;
export type CityCountryId = FieldBrand<CountryId, 'CityCountryId'>;
export type MayBeCityCountryId = MayBe<CityCountryId>;
export type CityCountry = FieldBrand<
  {
    id: CountryId;
    nanoId: CountryNanoId;
    name: CountryName;
    label: CountryLabel;
  },
  'CountryState'
>;
export type MayBeCityCountry = MayBe<CityCountry>;
export type CityCreatedAt = FieldBrand<ItemCreatedAt, 'CityCreatedAt'>;

export const enum CityFields {
  ID = 'id',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  NAME = 'name',
  LABEL = 'label',
  ORDER = 'order',
  COUNTRY_ID = 'countryId',
  COUNTRY = 'country',
  STATE_ID = 'stateId',
  STATE = 'state',
  CREATED_AT = 'createdAt'
}
