import { JobCandidateNanoId } from '../jobCandidates/jobCandidatesTypes';
import { JobNanoId } from '../jobs/jobsTypes';

const baseHiringPortalPath = '/hiring-portal';

export class HiringPortalRoutes {
  static index() {
    return `${baseHiringPortalPath}`;
  }

  static job(jobNanoId: JobNanoId) {
    return `${baseHiringPortalPath}/${jobNanoId}`;
  }

  static jobIntake(jobNanoId: JobNanoId) {
    return `${baseHiringPortalPath}/${jobNanoId}/intake`;
  }

  static jobCandidate(
    jobNanoId: JobNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${baseHiringPortalPath}/${jobNanoId}/${jobCandidateNanoId}`;
  }

  static leads() {
    return `${baseHiringPortalPath}/leads`;
  }

  static jobLeads(jobNanoId: JobNanoId) {
    return `${baseHiringPortalPath}/leads/${jobNanoId}`;
  }

  static reviewingLeads(jobNanoId: JobNanoId) {
    return `${baseHiringPortalPath}/leads/${jobNanoId}/reviewing`;
  }

  static recruitedLeads(jobNanoId: JobNanoId) {
    return `${baseHiringPortalPath}/leads/${jobNanoId}/recruiting`;
  }

  static rejectedLeads(jobNanoId: JobNanoId) {
    return `${baseHiringPortalPath}/leads/${jobNanoId}/rejected`;
  }

  static savedLeads(jobNanoId: JobNanoId) {
    return `${baseHiringPortalPath}/leads/${jobNanoId}/saved`;
  }

  static leadsJobDetails(jobNanoId: JobNanoId) {
    return `${baseHiringPortalPath}/leads/${jobNanoId}/job-details`;
  }

  static candidates() {
    return `${baseHiringPortalPath}/candidates`;
  }

  static jobCandidates(jobNanoId: JobNanoId) {
    return `${baseHiringPortalPath}/candidates/${jobNanoId}`;
  }

  static candidatesJobDetails(jobNanoId: JobNanoId) {
    return `${baseHiringPortalPath}/candidates/${jobNanoId}/job-details`;
  }

  static viewCandidateDetails(
    jobNanoId: JobNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${baseHiringPortalPath}/candidates/${jobNanoId}/${jobCandidateNanoId}`;
  }

  static settings() {
    return `${baseHiringPortalPath}/settings`;
  }

  static talentPools() {
    return `${baseHiringPortalPath}/talent-pools`;
  }

  static sourcing() {
    return `${baseHiringPortalPath}/sourcing`;
  }

  static jobs() {
    return `${baseHiringPortalPath}/jobs`;
  }
}
