import { useMutation, useQueryClient } from 'react-query';

import {
  FetchItemsCacheKey,
  FetchItemsIndexCacheKey,
  FetchItemCacheKey,
  FetchItemIndexCacheKey,
  PostItemError,
  PostItemErrorMessage,
  PostItemIsLoading
} from '../../../../types';

import { parseRequestError } from '../../../../utils/parseRequestError';

interface PostItemOptions<PostItemRequestData> {
  mutationFn: (queryInput: PostItemRequestData) => Promise<PostItemRequestData>;
  cacheKeys?: (
    | FetchItemsCacheKey
    | FetchItemsIndexCacheKey
    | FetchItemCacheKey
    | FetchItemIndexCacheKey
  )[];
}

function usePostItem<
  PostItemRequestData,
  PostItemErrorResponse extends { message: PostItemError }
>({ mutationFn, cacheKeys = [] }: PostItemOptions<PostItemRequestData>) {
  const queryClient = useQueryClient();

  const { data, error, isLoading, mutateAsync, reset } = useMutation<
    PostItemRequestData,
    PostItemErrorResponse,
    PostItemRequestData
  >((queryInput?) => mutationFn(queryInput), {
    onSettled: async () =>
      cacheKeys?.map((cacheKey) => queryClient.invalidateQueries(cacheKey))
  });

  return {
    postItemData: data,
    postItemError: error,
    postItemErrorMessage: parseRequestError(error) as PostItemErrorMessage,
    postItemIsLoading: isLoading as PostItemIsLoading,
    postItem: (postItemData: PostItemRequestData) => mutateAsync(postItemData),
    postItemReset: () => reset()
  };
}

export default usePostItem;
