import { useCallback } from 'react';
import { FormControl, FormLabel, Stack } from '@chakra-ui/react';
import { Control, useFieldArray } from 'react-hook-form';
import size from 'lodash/size';

import {
  UserEmailEmail,
  UserEmailLabel
} from '../../../../../../../../userEmails/userEmailsTypes';

import { EditUserFormAddEmailField } from '../../../../../../../../users/components/forms/AddEditUserForm/components/EditUserForm/components/EditUserFormAddEmailField';

import { PlusIcon } from '../../../../../../../../../icons/PlusIcon';

import { Button } from '../../../../../../../../../helpers/Button';

import { AddEditContactFormData } from '../../../../AddEditContactForm.types';
import { Text } from '../../../../../../../../../helpers/Text';

interface AddEditUserFormAddEmailFieldsProps {
  control: Control<AddEditContactFormData>;
  errorMessages: { [key: string]: { message?: string } }[];
}

function AddEditUserFormAddEmailFields({
  control,
  errorMessages
}: AddEditUserFormAddEmailFieldsProps) {
  const { fields, append, remove } = useFieldArray<
    AddEditContactFormData,
    'contactEmails'
  >({
    control,
    name: 'contactEmails'
  });

  const handleAddNew = useCallback<() => void>(
    () =>
      append(
        {
          email: '' as UserEmailEmail,
          label: '' as UserEmailLabel
        },
        {
          shouldFocus: false
        }
      ),
    [append]
  );

  const handleRemoveItem = useCallback<(index: number) => void>(
    (index) => {
      if (fields.length > 1) {
        remove(index);
      }
    },
    [fields.length, remove]
  );

  // useEffect(() => {
  //   if (fields.length === 0) {
  //     handleAddNew();
  //   }
  // }, [fields.length, handleAddNew]);

  return (
    <FormControl>
      <FormLabel htmlFor={`contactEmails.${0}.email`}>Email</FormLabel>

      <Stack spacing={3}>
        {fields.map((field, index) => (
          <EditUserFormAddEmailField
            key={field.id}
            control={control}
            name="contactEmails"
            index={index}
            error={errorMessages?.[index]}
            labelValue={field.label}
            onRemove={handleRemoveItem}
            withoutRemove={size(fields) === 1}
            withoutRules={
              !errorMessages?.['root' as unknown as number]?.message
            }
          />
        ))}

        {/*{fields.map((field, index) => (*/}
        {/*  <SimpleGrid*/}
        {/*    spacing={3}*/}
        {/*    key={field.id}*/}
        {/*    alignItems="center"*/}
        {/*    columns={{ base: 1, lg: 3 }}*/}
        {/*    gridTemplateColumns={'4fr 1fr max-content'}*/}
        {/*  >*/}
        {/*    <InputField*/}
        {/*      id={`emailAddresses.${index}.email`}*/}
        {/*      {...control.register(*/}
        {/*        `contactEmails.${index}.email`,*/}
        {/*        emailValidationRules*/}
        {/*      )}*/}
        {/*      errorMessage={*/}
        {/*        control._formState.errors.contactEmails?.[index]?.email?.message*/}
        {/*      }*/}
        {/*    />*/}

        {/*    <Select*/}
        {/*      fontSize="sm"*/}
        {/*      placeholder="Select"*/}
        {/*      {...control.register(`contactEmails.${index}.label`)}*/}
        {/*    >*/}
        {/*      <option>Personal Email</option>*/}
        {/*      <option>Work Email</option>*/}
        {/*      <option>Email Alt1</option>*/}
        {/*      <option>Email Alt2</option>*/}
        {/*    </Select>*/}

        {/*    <IconButton*/}
        {/*      size="small"*/}
        {/*      value={index}*/}
        {/*      aria-label={''}*/}
        {/*      hierarchy="link"*/}
        {/*      onClick={() => handleRemoveItem(index)}*/}
        {/*      cursor={fields.length > 1 ? 'pointer' : 'not-allowed'}*/}
        {/*      icon={*/}
        {/*        <RemoveIcon*/}
        {/*          fill={fields.length > 1 ? 'red.500' : 'red.200'}*/}
        {/*          w={5}*/}
        {/*          h={5}*/}
        {/*        />*/}
        {/*      }*/}
        {/*    />*/}
        {/*  </SimpleGrid>*/}
        {/*))}*/}
      </Stack>

      <Text
        color="red.500"
        textStyle="body1Medium"
        wordBreak="break-all"
        role="error"
      >
        {errorMessages?.['root' as unknown as number]?.message}
      </Text>

      <Button
        mt={3}
        size="small"
        hierarchy="link"
        onClick={handleAddNew}
        leftIcon={<PlusIcon color="primary.500" w={4} h={4} mr={-1} />}
      >
        Add email
      </Button>
    </FormControl>
  );
}

export default AddEditUserFormAddEmailFields;
