import { FetchItemsSort } from '../../../../../../types';

import { PaginatedItemsReducerAction } from '../../reducers/paginatedItemsReducer';

import { SORT_ITEMS } from '../../usePaginatedItems.constants';

function sortItemsAction(
  nextSort: FetchItemsSort
): PaginatedItemsReducerAction {
  return {
    type: SORT_ITEMS,
    nextSort
  };
}

export default sortItemsAction;
