import React, { Fragment, ReactNode } from 'react';

import { IsLoading, IsDisabled } from '../../../types';

import { Button } from '../../Button';
import { Text } from '../../Text';

interface ButtonHelperProps {
  isLoading?: IsLoading;
  disabled?: IsDisabled;
  hierarchy?:
    | 'primary'
    | 'danger'
    | 'secondary'
    | 'tertiary'
    | 'outline'
    | 'link'
    | 'unstyled';
  colorScheme?: string;
  minW?: string;
  ml?: 'auto' | number;
  mr?: 'auto' | number;
  px?: number;
  size?: 'small' | 'medium' | 'large';
  justifyContent?: 'left' | 'center' | 'right';
  color?: string;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  onClick?: () => void;
  flexShrink?: number;
  children: ReactNode;
}

function ButtonHelper({
  isLoading,
  disabled,
  hierarchy,
  colorScheme,
  minW,
  ml,
  mr,
  px,
  size,
  justifyContent,
  color,
  leftIcon,
  rightIcon,
  flexShrink = 0,
  onClick,
  children
}: ButtonHelperProps) {
  return (
    <Button
      isLoading={isLoading}
      disabled={isLoading || disabled}
      hierarchy={hierarchy}
      colorScheme={colorScheme}
      minW={minW}
      ml={ml}
      mr={mr}
      px={px}
      size={size}
      justifyContent={justifyContent}
      color={color}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      onClick={onClick}
      flexShrink={flexShrink}
    >
      {isLoading ? (
        <Fragment>
          <Text
            top="0"
            h="100%"
            display="flex"
            position="absolute"
            alignItems="center"
          >
            &bull; &bull; &bull;
          </Text>
        </Fragment>
      ) : (
        children
      )}
    </Button>
  );
}

export default ButtonHelper;
