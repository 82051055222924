import { Icon, IconProps } from '@chakra-ui/icon';

interface StarIconProps extends IconProps {
  filled?: boolean;
}

const StarIcon = ({
  w = '12px',
  h = '12px',
  width,
  height,
  filled,
  stroke = '#EEA401',
  ...props
}: StarIconProps) => (
  <Icon
    viewBox="0 0 12 12"
    w={w || width}
    h={h || height}
    fill="none"
    {...props}
  >
    <path
      d="M6.254 9.387 6 9.237l-.254.15-2.758 1.623.623-3.3.05-.264-.192-.187-2.374-2.308 3.187-.397.282-.036.114-.26L6 1.245l1.322 3.013.114.26.282.036 3.187.397L8.531 7.26l-.192.187.05.264.623 3.3-2.758-1.623Z"
      stroke={stroke as string}
      fill={filled ? '#EEA401' : ('' as string)}
    />
  </Icon>
);

export default StarIcon;
