import { Tag } from '@chakra-ui/react';

import { Text } from '../../../../helpers/Text';

import { renderJobCandidateSearchTypeLabel } from '../../utils/renderJobCandidateSearchTypeLabel';

import {
  JobCandidateSearchType,
  JobCandidateSearchTypeLabel
} from '../../jobCandidatesTypes';

import { labelColors } from './JobCandidateLabelHelper.types';
import { renderJobCandidateSearchType } from '../../utils/renderJobCandidateSearchType';

interface JobCandidateLabelHelperWithSearchType {
  searchTypeLabel?: never;
  searchType: JobCandidateSearchType;
}

interface JobCandidateLabelHelperWithSearchTypeLabel {
  searchTypeLabel: JobCandidateSearchTypeLabel;
  searchType?: never;
}

type JobCandidateLabelHelperProps =
  | JobCandidateLabelHelperWithSearchType
  | JobCandidateLabelHelperWithSearchTypeLabel;

function JobCandidateLabelHelper({
  searchTypeLabel,
  searchType
}: JobCandidateLabelHelperProps) {
  return (
    <Tag
      height="fit-content"
      px={2}
      color={labelColors[searchTypeLabel || searchType]?.color}
      bg={labelColors[searchTypeLabel || searchType]?.bg}
      borderRadius="base"
      border="1px solid"
      borderColor={labelColors[searchTypeLabel || searchType]?.border}
    >
      <Text textStyle="body2Medium" textTransform="capitalize">
        {searchType
          ? renderJobCandidateSearchType(searchType)
          : renderJobCandidateSearchTypeLabel(searchTypeLabel)}
      </Text>
    </Tag>
  );
}

export default JobCandidateLabelHelper;
