import {
  FetchEmploymentsCacheKey,
  FetchEmploymentCacheKey
} from './employmentsTypes';

import { JobCandidateId } from '../jobCandidates/jobCandidatesTypes';

import { JobResumeId } from '../jobResumes/jobResumesTypes';

import { JobResumeRecruiterId } from '../jobResumeRecruiters/jobResumeRecruitersTypes';
import { ContactId } from '../contacts/contactsTypes';

export class EmploymentsCache {
  static indexCacheKey() {
    return 'employments' as FetchEmploymentsCacheKey;
  }

  static allExistingCacheKey() {
    return 'employments-all-existing' as FetchEmploymentsCacheKey;
  }

  static showCacheKey() {
    return 'employment' as FetchEmploymentCacheKey;
  }

  static jobCandidateListCacheKey(jobCandidateId: JobCandidateId) {
    return `employments-${jobCandidateId}` as FetchEmploymentsCacheKey;
  }

  static jobResumeListCacheKey(jobResumeId: JobResumeId) {
    return `employments-${jobResumeId}` as FetchEmploymentsCacheKey;
  }

  static jobResumeRecruiterListCacheKey(
    jobResumeRecruiterId: JobResumeRecruiterId
  ) {
    return `employments-${jobResumeRecruiterId}` as FetchEmploymentsCacheKey;
  }

  static contactListCacheKey(contacId: ContactId) {
    return `employments-${contacId}` as FetchEmploymentsCacheKey;
  }
}
