import {
  FetchHtmlPageCacheKey,
  FetchHtmlPageNanoID,
  FetchHtmlPageQuery,
  FetchHtmlPageEnabled,
  FetchHtmlPageError,
  FetchHtmlPageErrorMessage,
  FetchHtmlPageIsFetched,
  FetchHtmlPageIsRefetching,
  FetchHtmlPageIsLoading,
  FetchHtmlPageIsPlaceholderData
} from '../../htmlPagesTypes';

import { useFetchItemByNanoId } from '../../../common/hooks/useFetchItemByNanoId';

import { HtmlPagesBffRequests } from '../../HtmlPagesBffRequests';

interface FetchHtmlPageByNanoIdOptions<FetchHtmlPageResponseType> {
  htmlPageNanoId: FetchHtmlPageNanoID;
  query: FetchHtmlPageQuery;
  cacheKey: FetchHtmlPageCacheKey;
  enabled?: FetchHtmlPageEnabled;
  placeholderHtmlPage?: FetchHtmlPageResponseType;
  placeholderData?: () => FetchHtmlPageResponseType;
}

function useFetchHtmlPageByNanoId<FetchHtmlPageResponseType>({
  htmlPageNanoId,
  query,
  cacheKey,
  enabled,
  placeholderHtmlPage,
  placeholderData
}: FetchHtmlPageByNanoIdOptions<FetchHtmlPageResponseType>) {
  const {
    item,
    itemError,
    itemErrorMessage,
    itemIsFetched,
    itemIsRefetching,
    itemIsLoading,
    itemIsPlaceholderData,
    refetchItem
  } = useFetchItemByNanoId<FetchHtmlPageResponseType, FetchHtmlPageError>({
    itemNanoId: htmlPageNanoId,
    cacheKey,
    enabled,
    placeholderItem: placeholderHtmlPage,
    placeholderData,
    queryFn: () =>
      HtmlPagesBffRequests.fetchHtmlPageByNanoId<FetchHtmlPageResponseType>(
        htmlPageNanoId,
        {
          query
        }
      )
  });

  return {
    htmlPage: item,
    htmlPageError: itemError,
    htmlPageErrorMessage: itemErrorMessage as FetchHtmlPageErrorMessage,
    htmlPageIsFetched: itemIsFetched as FetchHtmlPageIsFetched,
    htmlPageIsRefetching: itemIsRefetching as FetchHtmlPageIsRefetching,
    htmlPageIsLoading: itemIsLoading as FetchHtmlPageIsLoading,
    htmlPageIsPlaceholderData:
      itemIsPlaceholderData as FetchHtmlPageIsPlaceholderData,
    refetchHtmlPage: refetchItem
  };
}

export default useFetchHtmlPageByNanoId;
