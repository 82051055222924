import { Box, ColorProps, Flex, HStack, Stack } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { NextLinkHelper } from '../../../../../../../../helpers/NextLinkHelper';
import { Text } from '../../../../../../../../helpers/Text';

interface ViewJobCandidateDetailsActivityGridItemProps {
  title: string;
  subtitle: string;
  percentChange: number;
  icon: FunctionComponent;
  iconColor: ColorProps['color'];
  value: string;
  url: string;
}

export default function ViewJobCandidateDetailsActivityGridItem({
  url,
  icon,
  title,
  value,
  subtitle,
  percentChange,
  iconColor
}: ViewJobCandidateDetailsActivityGridItemProps) {
  return (
    <NextLinkHelper href={url} passHref>
      <Stack
        p={4}
        as="a"
        spacing={4}
        bg="white"
        color="gray.700"
        cursor="pointer"
        border="1px solid"
        textDecoration="none"
        borderColor="gray.100"
        borderRadius="base"
        _hover={{
          textDecor: 'none'
        }}
      >
        <Flex gap={4} flex={1}>
          <Text flex={1} w="full" fontSize="md" fontWeight="medium">
            {title}
          </Text>

          <Flex
            w={8}
            h={8}
            ml="auto"
            pos="relative"
            alignItems="center"
            borderRadius="full"
            justifyContent="center"
          >
            <Box
              w="full"
              h="full"
              top={0}
              left={0}
              zIndex={0}
              opacity={0.1}
              borderRadius="full"
              pos="absolute"
              bg={iconColor}
            />
            <Flex
              w={4}
              h={4}
              as={icon}
              fill={iconColor}
              pos="relative"
              zIndex={1}
            />
          </Flex>
        </Flex>

        <Stack spacing={1}>
          <HStack spacing={1}>
            <Text fontSize="lg" fontWeight="semibold">
              {value}
            </Text>

            <Text
              textStyle="body2Medium"
              color={percentChange > 0 ? 'green.500' : 'red.500'}
            >
              {`(${percentChange > 0 ? '+' : ''}${percentChange}%)`}
            </Text>
          </HStack>

          <Text textStyle="body2Regular" color="gray.600">
            {subtitle}
          </Text>
        </Stack>
      </Stack>
    </NextLinkHelper>
  );
}
