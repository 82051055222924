import { Icon, IconProps } from '@chakra-ui/icon';

const PreferencesIcon = ({ w = '16px', h = '16px', ...props }: IconProps) => (
  <Icon viewBox="0 0 16 16" w={w} h={h} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.503 5.5a2.5 2.5 0 10-2.421-3.125H2.96a.625.625 0 000 1.25h6.12A2.5 2.5 0 0011.504 5.5zM7.257 8.625A2.5 2.5 0 012.336 8a2.5 2.5 0 014.92-.625h6.122a.625.625 0 110 1.25H7.257zM11.503 15.5a2.5 2.5 0 10-2.421-3.125H2.96a.625.625 0 100 1.25h6.12a2.5 2.5 0 002.422 1.875z"
      fill="currentColor"
    />
  </Icon>
);
export default PreferencesIcon;
