import React, {
  forwardRef,
  ForwardedRef,
  useCallback,
  useRef,
  useImperativeHandle
} from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel
} from '@chakra-ui/form-control';
import {
  Input as ChakraInput,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  InputRightAddon,
  InputRightElement
} from '@chakra-ui/input';
import { Flex } from '@chakra-ui/layout';

import { mapSize } from '../../../../utils/mapSize';

import { DateInputFieldProps } from './DateInputField.types';

const DateInputField = forwardRef<HTMLInputElement, DateInputFieldProps>(
  (
    {
      size = 'medium',
      label,
      labelFontSize,
      labelAddon,
      id,
      isInvalid,
      errorMessage,
      isRequired,
      isDisabled,
      isReadOnly,
      helperText,
      leftAddon,
      rightAddon,
      leftElement,
      rightElement,
      isClickableLeftElement,
      isClickableRightElement,
      type,
      hidden,
      autoComplete,
      dataLpignore,
      autoFocus,
      ...props
    }: DateInputFieldProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const dateInputRef = useRef<HTMLInputElement | null>(null);

    const handleShowCalenderPicker = useCallback(() => {
      if (dateInputRef?.current) {
        dateInputRef.current.showPicker();
      }
    }, [dateInputRef]);

    useImperativeHandle(
      ref,
      () => dateInputRef.current as HTMLInputElement,
      []
    );

    return (
      <FormControl
        id={id}
        isInvalid={isInvalid || typeof errorMessage === 'string'}
        isDisabled={isDisabled}
        isRequired={isRequired}
        isReadOnly={isReadOnly}
        hidden={hidden}
      >
        {(label || labelAddon) && (
          <Flex alignItems="center">
            {label && (
              <FormLabel display="flex" flex={1} fontSize={labelFontSize}>
                {label}
              </FormLabel>
            )}

            {labelAddon && (
              <FormLabel
                mr={0}
                ml="auto"
                display="flex"
                as="aside"
                fontSize={labelFontSize}
                alignItems="center"
                requiredIndicator={<></>}
              >
                {labelAddon}
              </FormLabel>
            )}
          </Flex>
        )}

        <InputGroup>
          {leftAddon && (
            <InputLeftAddon border="none" marginInlineEnd={0}>
              {leftAddon}
            </InputLeftAddon>
          )}

          {leftElement && (
            <InputLeftElement
              pointerEvents={isClickableLeftElement ? 'unset' : 'none'}
            >
              {leftElement}
            </InputLeftElement>
          )}

          <ChakraInput
            type={type}
            variant="outline"
            size={mapSize(size)}
            borderColor="gray.200"
            focusBorderColor="primary.500"
            className="form-input-class"
            autoComplete={autoComplete}
            data-lpignore={dataLpignore}
            autoFocus={autoFocus}
            ref={dateInputRef}
            // ref={(e) => {
            //   dateInputRef.current = e;
            //   return ref;
            // }}
            onClick={handleShowCalenderPicker}
            {...props}
          />

          {rightElement && (
            <InputRightElement
              pointerEvents={isClickableRightElement ? 'unset' : 'none'}
            >
              {rightElement}
            </InputRightElement>
          )}

          {rightAddon && (
            <InputRightAddon border="none" marginInlineStart={0}>
              {rightAddon}
            </InputRightAddon>
          )}
        </InputGroup>

        {errorMessage ? (
          <FormErrorMessage textStyle="body2Medium" wordBreak="break-all">
            {errorMessage}
          </FormErrorMessage>
        ) : (
          helperText && (
            <FormHelperText textStyle="body2Medium">
              {helperText}
            </FormHelperText>
          )
        )}
      </FormControl>
    );
  }
);

DateInputField.displayName = 'DateInputField';

export default DateInputField;
