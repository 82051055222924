import { Box, Collapse, Flex, useDisclosure } from '@chakra-ui/react';
import {
  ViewJobCandidateModalPhoneSidebarCallSectionType,
  ViewJobCandidateModalPhoneSidebarToggleSectionType
} from '../../ViewJobCandidateModalPhoneSidebarCallActionsHelper';
import { ViewJobCandidateModalPhoneSidebarAddReminderHelper } from '../../actions';
import {
  ViewJobCandidateModalPhoneSidebarCallResponseDiscardButton,
  ViewJobCandidateModalPhoneSidebarCallResponseTrigger,
  ViewJobCandidateModalPhoneSidebarCallResponseWrapper
} from '../../components';
import { ViewJobCandidateModalPhoneSidebarActionCompletedHelper } from '../ViewJobCandidateModalPhoneSidebarActionCompletedHelper';
import { useState } from 'react';

type ViewJobCandidateModalPhoneSidebarSnoozeHelperProps = {
  openedSection: ViewJobCandidateModalPhoneSidebarCallSectionType;
  toggleSection: ViewJobCandidateModalPhoneSidebarToggleSectionType;
};

const ViewJobCandidateModalPhoneSidebarVoiceMailHelper = ({
  openedSection,
  toggleSection
}: ViewJobCandidateModalPhoneSidebarSnoozeHelperProps) => {
  const [isFocused, setIsFocused] = useState(false);

  const { isOpen: isOpenVoicemailComplete, onOpen: onOpenVoicemailComplete } =
    useDisclosure();

  const openVoicemailSection = () => {
    toggleSection({ actionType: 'open', section: 'voicemail' });
  };

  const onDiscard = () => {
    setIsFocused(false);
    toggleSection({ actionType: 'close', section: 'voicemail' });
  };

  return (
    <Flex
      flexDir="column"
      onClick={() => {
        if (!isFocused) {
          setIsFocused(true);
        }
      }}
      onMouseLeave={() => {
        if (!isFocused) {
          onDiscard();
        }
      }}
    >
      <ViewJobCandidateModalPhoneSidebarCallResponseTrigger
        label="Left voicemail"
        onMouseEnter={openVoicemailSection}
      />

      <Collapse in={openedSection === 'voicemail'} unmountOnExit>
        <Box pt={2}>
          <ViewJobCandidateModalPhoneSidebarCallResponseWrapper>
            <Collapse in={!isOpenVoicemailComplete} unmountOnExit>
              <ViewJobCandidateModalPhoneSidebarAddReminderHelper
                onDiscard={onDiscard}
                onSave={onOpenVoicemailComplete}
              />
            </Collapse>

            <Collapse in={isOpenVoicemailComplete} unmountOnExit>
              <ViewJobCandidateModalPhoneSidebarActionCompletedHelper />
            </Collapse>

            {isOpenVoicemailComplete && (
              <ViewJobCandidateModalPhoneSidebarCallResponseDiscardButton
                onClick={onDiscard}
              />
            )}
          </ViewJobCandidateModalPhoneSidebarCallResponseWrapper>
        </Box>
      </Collapse>
    </Flex>
  );
};

export default ViewJobCandidateModalPhoneSidebarVoiceMailHelper;
