import { usePaginatedItems } from '../../../common/hooks/usePaginatedItems';

import {
  FetchJobsQuery,
  FetchJobsCacheKey,
  FetchJobsTotalCount,
  FetchJobsPage,
  FetchJobsPageSize,
  FetchJobsError,
  FetchJobsErrorMessage,
  FetchJobsIsLoading,
  FetchJobsIsFetched,
  FetchJobsIsPlaceholderData,
  FetchJobsEnabled,
  FetchJobsCountType,
  FetchJobsFilters,
  FetchJobsSort,
  FetchJobID,
  FetchJobQuery,
  FetchJobCacheKey
} from '../../jobsTypes';

import { JobsBffRequests } from '../../JobsBffRequests';

interface PaginatedJobsBaseOptions<PaginatedJobsResponseType> {
  cacheKey: FetchJobsCacheKey;
  query: FetchJobsQuery;
  countType?: FetchJobsCountType;
  initialPage?: FetchJobsPage;
  initialPageSize?: FetchJobsPageSize;
  initialFilters?: FetchJobsFilters;
  initialSort?: FetchJobsSort;
  enabled?: FetchJobsEnabled;
  placeholderJobs?: PaginatedJobsResponseType[];
  placeholderJobsCount?: FetchJobsTotalCount;
  placeholderData?: () => PaginatedJobsResponse<PaginatedJobsResponseType>;
  onSuccess?: (data: PaginatedJobsResponse<PaginatedJobsResponseType>) => void;
}

interface PaginatedJobsOptionsWithoutItem {
  fetchItemCacheKey?: never;
  itemQuery?: never;
}

interface PaginatedJobsOptionsWithItem {
  fetchItemCacheKey: FetchJobCacheKey;
  itemQuery: FetchJobQuery;
}

type PaginatedJobsOptions<PaginatedJobsResponseType> =
  PaginatedJobsBaseOptions<PaginatedJobsResponseType> &
    (PaginatedJobsOptionsWithoutItem | PaginatedJobsOptionsWithItem);

interface PaginatedJobsResponse<PaginatedJobsResponseType> {
  data: PaginatedJobsResponseType[];
  totalCount: FetchJobsTotalCount;
}

function usePaginatedJobs<PaginatedJobsResponseType>({
  cacheKey,
  query,
  countType,
  initialPage,
  initialPageSize,
  initialFilters,
  initialSort,
  enabled,
  placeholderJobs,
  placeholderJobsCount,
  placeholderData,
  fetchItemCacheKey,
  itemQuery,
  onSuccess
}: PaginatedJobsOptions<PaginatedJobsResponseType>) {
  const {
    items,
    itemsTotalCount,
    itemsError,
    itemsErrorMessage,
    itemsIsFetched,
    itemsIsLoading,
    itemsIsPlaceholderData,
    itemsCurrentPage,
    itemsCurrentPageSize,
    itemsCurrentFilters,
    itemsCurrentSort,
    paginateItems,
    changeItemsPageSize,
    filterItems,
    clearItemsFilters,
    changeItemsFilters,
    sortItems,
    prefetchItems,
    prefetchItem,
    fetchItems
  } = usePaginatedItems<PaginatedJobsResponseType>({
    cacheKey,
    initialPage,
    initialPageSize,
    initialFilters,
    initialSort,
    enabled,
    placeholderItems: placeholderJobs,
    placeholderItemsCount: placeholderJobsCount,
    placeholderData,
    onSuccess: (data) =>
      onSuccess?.(data as PaginatedJobsResponse<PaginatedJobsResponseType>),
    queryFn: (params) =>
      JobsBffRequests.fetchJobs<PaginatedJobsResponseType>({
        query,
        countType,
        page: params.page as FetchJobsPage,
        pageSize: params.pageSize as FetchJobsPageSize,
        filters: params.filters as FetchJobsFilters,
        sort: params.sort as FetchJobsSort
      }),
    ...(itemQuery
      ? {
          fetchItemCacheKey,
          queryItemFn: (itemId) =>
            JobsBffRequests.fetchJob(itemId as FetchJobID, {
              query: itemQuery
            })
        }
      : {})
  });

  return {
    jobs: items as PaginatedJobsResponseType[],
    jobsTotalCount: itemsTotalCount as FetchJobsTotalCount,
    jobsError: itemsError as FetchJobsError,
    jobsErrorMessage: itemsErrorMessage as FetchJobsErrorMessage,
    jobsIsLoading: itemsIsLoading as FetchJobsIsLoading,
    jobsIsFetched: itemsIsFetched as FetchJobsIsFetched,
    jobsIsPlaceholderData: itemsIsPlaceholderData as FetchJobsIsPlaceholderData,
    jobsCurrentPage: itemsCurrentPage as FetchJobsPage,
    jobsCurrentPageSize: itemsCurrentPageSize as FetchJobsPageSize,
    jobsCurrentFilters: itemsCurrentFilters as FetchJobsFilters,
    jobsCurrentSort: itemsCurrentSort as FetchJobsSort,
    paginateJobs: paginateItems,
    changeJobsPageSize: changeItemsPageSize,
    filterJobs: filterItems,
    changeJobsFilters: changeItemsFilters,
    clearJobsFilters: clearItemsFilters,
    sortJobs: sortItems,
    prefetchJobs: prefetchItems,
    prefetchJob: prefetchItem,
    fetchJobs: fetchItems
  };
}

export default usePaginatedJobs;
