import snakeCase from 'lodash/snakeCase';

import {
  FetchCitiesQuery,
  CityFields,
  CityId,
  CityNanoId,
  CityName,
  CityLabel,
  CityCreatedAt,
  CityState,
  CityCountry
} from '../citiesTypes';

export interface FetchCitiesResponse {
  [CityFields.ID]: CityId;
  [CityFields.NANO_ID]: CityNanoId;
  [CityFields.NAME]: CityName;
  [CityFields.LABEL]: CityLabel;
  [CityFields.CREATED_AT]: CityCreatedAt;
  [CityFields.STATE]: CityState;
  [CityFields.COUNTRY]: CityCountry;
}

export const fetchCitiesColumns = [
  CityFields.ID,
  CityFields.NANO_ID,
  CityFields.NAME,
  CityFields.LABEL,
  CityFields.CREATED_AT
];

const fetchCitiesSBColumns = fetchCitiesColumns.map((fetchCitiesColumn) =>
  snakeCase(fetchCitiesColumn)
);

export const fetchCitiesQuery = fetchCitiesSBColumns.join(
  ','
) as FetchCitiesQuery;
