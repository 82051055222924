import { ForwardedRef, forwardRef } from 'react';

import {
  InputField,
  InputFieldProps
} from '../../../../../helpers/forms/formFields/InputField';
import { Link } from '../../../../../helpers/Link';

import { LinkedInIcon } from '../../../../../icons/LinkedInIcon';

interface LinkedinInputFormFieldProps extends InputFieldProps {
  linkedinUrl: string;
}

const LinkedinInputFormField = forwardRef<
  HTMLInputElement,
  LinkedinInputFormFieldProps
>(
  (
    { linkedinUrl, ...props }: LinkedinInputFormFieldProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <InputField
        ref={ref}
        {...props}
        rightElement={
          linkedinUrl?.toLowerCase()?.includes('linkedin.com') ? (
            <Link isExternal to={linkedinUrl as string}>
              <LinkedInIcon />
            </Link>
          ) : undefined
        }
        isClickableRightElement
      />
    );
  }
);

LinkedinInputFormField.displayName = 'LinkedinInputFormField';

export default LinkedinInputFormField;
