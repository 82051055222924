import { FetchCandidateSubmissionsCacheKey } from '../../candidateSubmissionsTypes';

import { useCreateItem } from '../../../common/hooks/useCreateItem';

import {
  CandidateSubmissionsBffRequests,
  CreateCandidateSubmissionRequestData,
  CreateCandidateSubmissionErrorResponse
} from '../../CandidateSubmissionsBffRequests';

interface CreateCandidateSubmissionOptions {
  cacheKeys?: FetchCandidateSubmissionsCacheKey[];
}

function useCreateCandidateSubmission({
  cacheKeys = []
}: CreateCandidateSubmissionOptions) {
  const {
    createItemData,
    createItemError,
    createItemErrorMessage,
    createItemIsLoading,
    createItem,
    createItemReset
  } = useCreateItem<
    CreateCandidateSubmissionRequestData,
    CreateCandidateSubmissionErrorResponse
  >({
    mutationFn: (queryInput) =>
      CandidateSubmissionsBffRequests.createCandidateSubmission(queryInput),
    cacheKeys
  });

  return {
    createCandidateSubmissionData: createItemData,
    createCandidateSubmissionError: createItemError,
    createCandidateSubmissionErrorMessage: createItemErrorMessage,
    createCandidateSubmissionIsLoading: createItemIsLoading,
    createCandidateSubmission: createItem,
    createCandidateSubmissionReset: createItemReset
  };
}

export default useCreateCandidateSubmission;
