import { Icon, IconProps } from '@chakra-ui/icon';

const GoogleIcon = ({
  w = '20px',
  h = '20px',
  width,
  height,
  ...props
}: IconProps) => (
  <Icon
    viewBox="0 0 20 20"
    w={w || width}
    h={h || height}
    fill="none"
    {...props}
  >
    <path
      d="M19.788 10.225c0-.658-.059-1.283-.159-1.892h-9.417v3.759h5.392c-.241 1.233-.95 2.275-2 2.983v2.5h3.217c1.883-1.742 2.966-4.308 2.966-7.35Z"
      fill="#4285F4"
    />
    <path
      d="M10.213 20c2.7 0 4.958-.9 6.608-2.425l-3.217-2.5c-.9.6-2.041.967-3.391.967-2.609 0-4.817-1.759-5.609-4.134H1.287v2.575C2.93 17.75 6.305 20 10.213 20Z"
      fill="#34A853"
    />
    <path
      d="M4.604 11.908A5.803 5.803 0 0 1 4.287 10c0-.667.117-1.308.317-1.908V5.517H1.287a9.884 9.884 0 0 0 0 8.966l3.317-2.575Z"
      fill="#FBBC05"
    />
    <path
      d="M10.213 3.958c1.475 0 2.791.509 3.833 1.5l2.85-2.85C15.17.992 12.912 0 10.212 0 6.305 0 2.93 2.25 1.287 5.517l3.317 2.575c.792-2.375 3-4.134 5.609-4.134Z"
      fill="#EA4335"
    />
  </Icon>
);

export default GoogleIcon;
