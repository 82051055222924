import { FieldValues } from 'react-hook-form';
import { SystemProps } from '@chakra-ui/react';
import debounce from 'lodash/debounce';
import size from 'lodash/size';

import { INITIAL_PAGE_SIZE } from '../../../../../constants';

import { ErrorMessage, IsHidden } from '../../../../../types';

import { JobFields } from '../../../jobsTypes';

import {
  CityFields,
  FetchCitiesFilters,
  FetchCitiesPageSize,
  FetchCitiesSort
} from '../../../../cities/citiesTypes';

import {
  fetchCitiesQuery,
  FetchCitiesResponse
} from '../../../../cities/queries/fetchCities.query';

import { usePaginatedCities } from '../../../../cities/hooks/usePaginatedCities';

import { CitiesCache } from '../../../../cities/CitiesCache';

import {
  SelectFieldReactHookFormControl,
  SelectFieldReactHookFormFieldPath
} from '../../../../../helpers/forms/formFields/SelectField';

import { JobSelectCitiesFieldRequiredProps } from './JobSelectCitiesField.types';
import { MultiSelectField } from '../../../../../helpers/forms/formFields/MultiSelectField';

import { DEFAULT_COUNTRY_ID } from '../../../../../config';

interface JobSelectCitiesFieldProps<T extends FieldValues> {
  control: SelectFieldReactHookFormControl<T>;
  errorMessage: ErrorMessage;
  isRequired?: boolean;
  visibility?: SystemProps['visibility'];
  isLabelHidden?: IsHidden;
  useCurrentUserCity?: boolean;
  label?: string;
}

function JobSelectCitiesField<T extends FieldValues>({
  control,
  currentUser,
  job,
  label,
  errorMessage,
  isRequired = true,
  visibility,
  isLabelHidden,
  useCurrentUserCity
}: JobSelectCitiesFieldProps<T> & JobSelectCitiesFieldRequiredProps) {
  const { cities, citiesIsLoading, citiesErrorMessage, changeCitiesFilters } =
    usePaginatedCities<FetchCitiesResponse>({
      query: fetchCitiesQuery,
      cacheKey: CitiesCache.postJobCacheKey(),
      initialPageSize: INITIAL_PAGE_SIZE as FetchCitiesPageSize,
      initialFilters: {
        [CityFields.COUNTRY_ID]: {
          operator: 'eq',
          value: currentUser.countryId || DEFAULT_COUNTRY_ID
        },
        [CityFields.LABEL]: {
          operator: 'ilike',
          value: `%${currentUser.city?.label}%`
        }
      } as unknown as FetchCitiesFilters,
      initialSort: {
        [CityFields.NAME]: { ascending: true }
      } as unknown as FetchCitiesSort
    });

  // useEffect(() => {
  //   if (
  //     !currentJobCityId &&
  //     job?.city?.label &&
  //     job?.city?.label !== currentUser.city?.label
  //   ) {
  //     changeCitiesFilters({
  //       [CityFields.LABEL]: {
  //         operator: 'ilike',
  //         value: `%${job?.city?.label}%`
  //       }
  //     });
  //   }
  // }, [
  //   currentJobCityId,
  //   job?.city?.label,
  //   currentUser.city?.label,
  //   changeCitiesFilters
  // ]);

  const defaultJobCities = () => {
    if (size(job?.cities) && job?.cities) {
      return job.cities.map((city) => ({
        value: city.id,
        label: city.label
      }));
    }

    if (useCurrentUserCity && currentUser.city) {
      return [{ value: currentUser.city.id, label: currentUser.city.label }];
    }

    return undefined;
  };

  const debouncedFilterCities = debounce<(updatedValue: string) => void>(
    (updatedValue) =>
      changeCitiesFilters({
        [CityFields.LABEL]: {
          operator: 'ilike',
          value: `%${updatedValue}%`
        }
      }),
    500
  );

  return (
    <MultiSelectField
      isRequired={isRequired}
      control={control}
      label={isLabelHidden ? undefined : label ?? 'Job Location(s)'}
      placeholder="Select job location(s)"
      name={JobFields.CITY_IDS as SelectFieldReactHookFormFieldPath<T>}
      options={cities.map((city) => ({
        value: city.id,
        label: city.label
      }))}
      defaultValue={defaultJobCities()}
      errorMessage={errorMessage || citiesErrorMessage}
      isLoading={citiesIsLoading}
      onInputChange={debouncedFilterCities}
      visibility={visibility}
    />
  );
}

export default JobSelectCitiesField;
