const jobTitleIdRules = {
  required: 'Title is required'
};

const departmentIdRules = {
  required: 'Department is required'
};

const jobFunctionIdRules = {
  required: 'Job function is required'
};

const descriptionRules = {
  required: 'Description is required'
};

const userJobIdRules = {
  required: 'Job Id is required'
};

function usePostJobDetailsFormValidationRules() {
  return {
    jobTitleIdRules,
    departmentIdRules,
    descriptionRules,
    userJobIdRules,
    jobFunctionIdRules
  };
}

export default usePostJobDetailsFormValidationRules;
