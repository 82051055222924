import React from 'react';
import {
  Button,
  ButtonProps
} from '../../../../../../../../../../../helpers/Button';

import { ChevronDownIcon } from '../../../../../../../../../../../icons/ChevronDownIcon';

interface ViewJobCandidateModalPhoneSidebarCallResponseTriggerProps
  extends ButtonProps {
  label: string;
}

const ViewJobCandidateModalPhoneSidebarCallResponseTrigger = ({
  label,
  ...props
}: ViewJobCandidateModalPhoneSidebarCallResponseTriggerProps) => (
  <Button
    {...props}
    h="44px"
    size="medium"
    textAlign="left"
    boxShadow="none"
    hierarchy="tertiary"
    borderColor="gray.300"
    justifyContent="space-between"
    rightIcon={<ChevronDownIcon />}
    _hover={{
      borderColor: 'primary.500'
    }}
  >
    {label}
  </Button>
);

export default ViewJobCandidateModalPhoneSidebarCallResponseTrigger;
