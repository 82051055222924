import { PaginatedItemsReducerAction } from '../../reducers/paginatedItemsReducer';

import { CLEAR_ITEMS_FILTERS } from '../../usePaginatedItems.constants';

function clearItemsFiltersAction(): PaginatedItemsReducerAction {
  return {
    type: CLEAR_ITEMS_FILTERS
  };
}

export default clearItemsFiltersAction;
