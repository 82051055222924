import snakeCase from 'lodash/snakeCase';

import {
  FetchDepartmentsQuery,
  DepartmentFields,
  DepartmentId,
  DepartmentNanoId,
  DepartmentName,
  DepartmentUser,
  DepartmentCompany,
  DepartmentCompanyId,
  DepartmentJobsCount,
  DepartmentCreatedAt
} from '../departmentsTypes';

export interface FetchDepartmentsResponse {
  [DepartmentFields.ID]: DepartmentId;
  [DepartmentFields.NANO_ID]: DepartmentNanoId;
  [DepartmentFields.NAME]: DepartmentName;
  [DepartmentFields.USER]: DepartmentUser;
  [DepartmentFields.CREATED_AT]: DepartmentCreatedAt;
  [DepartmentFields.COMPANY]: DepartmentCompany;
  [DepartmentFields.COMPANY_ID]: DepartmentCompanyId;
  [DepartmentFields.JOBS_COUNT]: DepartmentJobsCount;
}

export const fetchDepartmentsColumns = [
  DepartmentFields.ID,
  DepartmentFields.NANO_ID,
  DepartmentFields.NAME,
  DepartmentFields.CREATED_AT,
  DepartmentFields.COMPANY,
  DepartmentFields.COMPANY_ID,
  DepartmentFields.JOBS_COUNT
];

const fetchDepartmentsSBColumns = fetchDepartmentsColumns.map(
  (fetchDepartmentsColumn) => snakeCase(fetchDepartmentsColumn)
);

export const fetchDepartmentsQuery = fetchDepartmentsSBColumns.join(
  ','
) as FetchDepartmentsQuery;
