import {
  FetchEmploymentSkillBadgesQuery,
  FetchEmploymentSkillBadgesCountType,
  FetchEmploymentSkillBadgesPage,
  FetchEmploymentSkillBadgesPageSize,
  FetchEmploymentSkillBadgesFilters,
  FetchEmploymentSkillBadgesSort,
  FetchEmploymentSkillBadgesIndexQueryName,
  FetchEmploymentSkillBadgesIndexCountType,
  FetchEmploymentSkillBadgesIndexPage,
  FetchEmploymentSkillBadgesIndexPageSize,
  FetchEmploymentSkillBadgesIndexFilters,
  FetchEmploymentSkillBadgesIndexSort,
  FetchEmploymentSkillBadgeQuery,
  FetchEmploymentSkillBadgeIndexQueryName,
  CreateEmploymentSkillBadgeError,
  UpdateEmploymentSkillBadgeError,
  DeleteEmploymentSkillBadgeError,
  EmploymentSkillBadgeId,
  EmploymentSkillBadgeNanoId,
  EmploymentSkillBadgeUsage,
  EmploymentSkillBadgeEmploymentId,
  EmploymentSkillBadgeSkillBadgeId
} from './employmentSkillBadgesTypes';

import { BaseBffRequests } from '../BaseBffRequests';

import { EmploymentSkillBadgesBffRoutes } from './EmploymentSkillBadgeBffRoutes';

interface FetchEmploymentSkillBadgesRequestParams {
  query: FetchEmploymentSkillBadgesQuery;
  countType?: FetchEmploymentSkillBadgesCountType;
  page?: FetchEmploymentSkillBadgesPage;
  pageSize?: FetchEmploymentSkillBadgesPageSize;
  filters?: FetchEmploymentSkillBadgesFilters;
  sort?: FetchEmploymentSkillBadgesSort;
}

interface FetchEmploymentSkillBadgesIndexRequestParams {
  queryName: FetchEmploymentSkillBadgesIndexQueryName;
  countType?: FetchEmploymentSkillBadgesIndexCountType;
  page?: FetchEmploymentSkillBadgesIndexPage;
  pageSize?: FetchEmploymentSkillBadgesIndexPageSize;
  filters?: FetchEmploymentSkillBadgesIndexFilters;
  sort?: FetchEmploymentSkillBadgesIndexSort;
}

interface FetchEmploymentSkillBadgeRequestParams {
  query: FetchEmploymentSkillBadgeQuery;
}

interface FetchEmploymentSkillBadgeIndexRequestParams {
  queryName: FetchEmploymentSkillBadgeIndexQueryName;
}

export interface EmploymentSkillBadgeRequestData {
  employmentId?: EmploymentSkillBadgeEmploymentId;
}

export interface CreateEmploymentSkillBadgeRequestData
  extends EmploymentSkillBadgeRequestData {
  id?: EmploymentSkillBadgeId;
  nanoId?: EmploymentSkillBadgeNanoId;
  skillBadgeId: EmploymentSkillBadgeSkillBadgeId;
  employmentId?: EmploymentSkillBadgeEmploymentId;
}

export interface UpdateEmploymentSkillBadgeRequestData
  extends EmploymentSkillBadgeRequestData {
  usage?: EmploymentSkillBadgeUsage;
}

export interface DeleteEmploymentSkillBadgeRequestData {
  employmentSkillBadgeNanoId: EmploymentSkillBadgeNanoId;
}

export interface CreateEmploymentSkillBadgeErrorResponse {
  message: CreateEmploymentSkillBadgeError;
}

export interface UpdateEmploymentSkillBadgeErrorResponse {
  message: UpdateEmploymentSkillBadgeError;
}

export interface DeleteEmploymentSkillBadgeErrorResponse {
  message: DeleteEmploymentSkillBadgeError;
}

export class EmploymentSkillBadgesBffRequests extends BaseBffRequests {
  static fetchEmploymentSkillBadges<T>(
    params: FetchEmploymentSkillBadgesRequestParams
  ) {
    return this.fetchItems<T>(
      EmploymentSkillBadgesBffRoutes.fetchEmploymentSkillBadgesRoute(),
      params
    );
  }

  static fetchEmploymentSkillBadgesIndex<T>(
    params: FetchEmploymentSkillBadgesIndexRequestParams
  ) {
    return this.fetchItemsIndex<T>(
      EmploymentSkillBadgesBffRoutes.fetchEmploymentSkillBadgesIndexRoute(),
      params
    );
  }

  static fetchEmploymentSkillBadge<T>(
    employmentSkillBadgeId: EmploymentSkillBadgeId,
    params: FetchEmploymentSkillBadgeRequestParams
  ) {
    return this.fetchItem<T>(
      EmploymentSkillBadgesBffRoutes.fetchEmploymentSkillBadgeRoute(
        employmentSkillBadgeId
      ),
      params
    );
  }

  static fetchEmploymentSkillBadgeIndex<T>(
    employmentSkillBadgeNanoId: EmploymentSkillBadgeNanoId,
    params: FetchEmploymentSkillBadgeIndexRequestParams
  ) {
    return this.fetchItemIndex<T>(
      EmploymentSkillBadgesBffRoutes.fetchEmploymentSkillBadgeIndexRoute(
        employmentSkillBadgeNanoId
      ),
      params
    );
  }

  static fetchEmploymentSkillBadgeByNanoId<T>(
    employmentSkillBadgeNanoId: EmploymentSkillBadgeNanoId,
    params: FetchEmploymentSkillBadgeRequestParams
  ) {
    return this.fetchItemByNanoId<T>(
      EmploymentSkillBadgesBffRoutes.fetchEmploymentSkillBadgeByNanoIdRoute(
        employmentSkillBadgeNanoId
      ),
      params
    );
  }

  static createEmploymentSkillBadge<T>(
    createEmploymentSkillBadgeData: CreateEmploymentSkillBadgeRequestData
  ) {
    return this.createItem<T, CreateEmploymentSkillBadgeRequestData>(
      EmploymentSkillBadgesBffRoutes.createEmploymentSkillBadgeRoute(),
      createEmploymentSkillBadgeData
    );
  }

  static async updateEmploymentSkillBadge<T>(
    updateEmploymentSkillBadgeId: EmploymentSkillBadgeId,
    updateEmploymentSkillBadgeData: UpdateEmploymentSkillBadgeRequestData
  ) {
    return this.updateItem<T, UpdateEmploymentSkillBadgeRequestData>(
      EmploymentSkillBadgesBffRoutes.updateEmploymentSkillBadgeRoute(
        updateEmploymentSkillBadgeId
      ),
      updateEmploymentSkillBadgeData
    );
  }

  static async deleteEmploymentSkillBadge<T>(
    deleteEmploymentSkillBadgeId: EmploymentSkillBadgeId
  ) {
    return this.deleteItem<T>(
      EmploymentSkillBadgesBffRoutes.deleteEmploymentSkillBadgeRoute(
        deleteEmploymentSkillBadgeId
      )
    );
  }
}
