import snakeCase from 'lodash/snakeCase';

import {
  FetchWorkflowStagesQuery,
  WorkflowStageFields,
  WorkflowStageId,
  WorkflowStageNanoId,
  WorkflowStageName,
  WorkflowStageArrangement,
  WorkflowStageColor,
  WorkflowStageEditable,
  WorkflowStageMovable,
  WorkflowStageRemovable,
  WorkflowStageWorkflowStageTasks,
  WorkflowStageUser,
  WorkflowStageCreatedAt,
  WorkflowStageJobCandidates
} from '../workflowStagesTypes';

import { FetchWorkflowStagesApiQuery } from '../../../api/workflowStages/workflowStagesApiTypes';

export interface FetchWorkflowStagesResponse {
  [WorkflowStageFields.ID]: WorkflowStageId;
  [WorkflowStageFields.NANO_ID]: WorkflowStageNanoId;
  [WorkflowStageFields.NAME]: WorkflowStageName;
  [WorkflowStageFields.ARRANGEMENT]: WorkflowStageArrangement;
  [WorkflowStageFields.COLOR]: WorkflowStageColor;
  [WorkflowStageFields.EDITABLE]: WorkflowStageEditable;
  [WorkflowStageFields.MOVABLE]: WorkflowStageMovable;
  [WorkflowStageFields.REMOVABLE]: WorkflowStageRemovable;
  [WorkflowStageFields.WORKFLOW_STAGE_TASKS]: WorkflowStageWorkflowStageTasks;
  [WorkflowStageFields.JOB_CANDIDATES]: WorkflowStageJobCandidates;
  [WorkflowStageFields.USER]: WorkflowStageUser;
  [WorkflowStageFields.CREATED_AT]: WorkflowStageCreatedAt;
}

export const fetchWorkflowStagesColumns = [
  WorkflowStageFields.ID,
  WorkflowStageFields.NANO_ID,
  WorkflowStageFields.NAME,
  WorkflowStageFields.ARRANGEMENT,
  WorkflowStageFields.COLOR,
  WorkflowStageFields.EDITABLE,
  WorkflowStageFields.MOVABLE,
  WorkflowStageFields.REMOVABLE,
  WorkflowStageFields.CREATED_AT
];

const fetchWorkflowStagesSBColumns = fetchWorkflowStagesColumns.map(
  (fetchWorkflowStagesColumn) => snakeCase(fetchWorkflowStagesColumn)
);

export const fetchWorkflowStagesQuery = `
  ${fetchWorkflowStagesSBColumns.join(',')},
  user:user_id(id,nano_id,name,image:image_id(id,nano_id,name,file,key,created_at)),
  workflow_stage_tasks!fk_rails_bd60de3db2(id,nano_id,name,arrangement,application,due_date),
  job_candidates!fk_rails_fdc143e851(id,nano_id,name,job_title,arrangement,search_type,search_type_label,disqualified_at,disqualification_reason,workflow_stage_task_scheduled_at,new_workflow_stage_task_at,viewed_at,workflow_stage_task_application_type,created_at,updated_at,due_date,status,job_id,phone,personal_phone,company_phone,workflow_stage_task:workflow_stage_task_id(id,nano_id,name,arrangement,application,due_date),state:state_id(id,nano_id,name,iso_code),city:city_id(id,nano_id,name,label),image:image_id(id,nano_id,name,file,key,created_at),user:user_id(id,name,image:image_id(id,nano_id,name,file,created_at)))
` as FetchWorkflowStagesQuery;

export const fetchWorkflowStagesApiQuery = fetchWorkflowStagesSBColumns.join(
  ','
) as FetchWorkflowStagesApiQuery;
