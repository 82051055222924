import {
  FetchIndustryExpertisesCacheKey,
  FetchIndustryExpertiseCacheKey,
  DeleteIndustryExpertiseErrorMessage,
  DeleteIndustryExpertiseIsLoading,
  DeleteIndustryExpertiseID
} from '../../industryExpertisesTypes';

import { useDeleteItem } from '../../../common/hooks/useDeleteItem';

import {
  IndustryExpertisesBffRequests,
  DeleteIndustryExpertiseErrorResponse
} from '../../IndustryExpertisesBffRequests';

interface DeleteIndustryExpertiseOptions {
  industryExpertiseId: DeleteIndustryExpertiseID;
  cacheKeys?: (
    | FetchIndustryExpertisesCacheKey
    | FetchIndustryExpertiseCacheKey
  )[];
}

function useDeleteIndustryExpertise({
  industryExpertiseId,
  cacheKeys = []
}: DeleteIndustryExpertiseOptions) {
  const {
    deleteItemData,
    deleteItemError,
    deleteItemErrorMessage,
    deleteItemIsLoading,
    deleteItem,
    deleteItemReset
  } = useDeleteItem<unknown, DeleteIndustryExpertiseErrorResponse>({
    mutationFn: () =>
      IndustryExpertisesBffRequests.deleteIndustryExpertise(
        industryExpertiseId
      ),
    cacheKeys
  });

  return {
    deleteIndustryExpertiseData: deleteItemData,
    deleteIndustryExpertiseError: deleteItemError,
    deleteIndustryExpertiseErrorMessage:
      deleteItemErrorMessage as DeleteIndustryExpertiseErrorMessage,
    deleteIndustryExpertiseIsLoading:
      deleteItemIsLoading as DeleteIndustryExpertiseIsLoading,
    deleteIndustryExpertise: deleteItem,
    deleteIndustryExpertiseReset: deleteItemReset
  };
}

export default useDeleteIndustryExpertise;
