import {
  JobCandidateDisqualificationReason,
  JobCandidateDisqualificationReasons
} from '../../jobCandidatesTypes';

function renderJobCandidateDisqualificationReason(
  jobCandidateDisqualificationReason: JobCandidateDisqualificationReason
) {
  switch (jobCandidateDisqualificationReason) {
    case JobCandidateDisqualificationReasons.NOT_FIT:
      return 'Not a fit';
    case JobCandidateDisqualificationReasons.NOT_INTERESTED:
      return 'Not interested';
    case JobCandidateDisqualificationReasons.NOT_AVAILABLE:
      return 'Not available';
    default:
      return 'Unknown';
  }
}

export default renderJobCandidateDisqualificationReason;
