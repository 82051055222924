import { FormLabel, Stack, StackDivider } from '@chakra-ui/react';
import slice from 'lodash/slice';

import { CheckboxField } from '../../../../../../helpers/forms/formFields/CheckboxField';

import {
  ShareProfileFieldsToShareFields,
  ShareProfileFieldsToShareHandleCheckField
} from './ShareProfileFieldsToShare.types';

interface ShareProfileFieldsToShareProps {
  fieldsToShare: ShareProfileFieldsToShareFields;
  handleCheckFieldToShare: ShareProfileFieldsToShareHandleCheckField;
}

const ShareProfileFieldsToShare = ({
  fieldsToShare,
  handleCheckFieldToShare
}: ShareProfileFieldsToShareProps) => {
  const stackOne = slice(fieldsToShare, 0, 5);
  const stackTwo = slice(fieldsToShare, 5, 10);
  const stackThree = slice(fieldsToShare, 10, 15);
  const stackFour = slice(fieldsToShare, 15, 20);

  return (
    <Stack spacing={4}>
      <FormLabel>
        Unselect each component of the profile you do not wish to share
      </FormLabel>

      <Stack
        direction={{ base: 'column', md: 'row' }}
        divider={<StackDivider />}
        spacing={4}
      >
        <Stack direction="row" divider={<StackDivider />} spacing={4} flex={1}>
          <Stack spacing={3} flex={1}>
            {stackOne.map(({ name, label, checked }) => (
              <CheckboxField
                key={name}
                isChecked={checked}
                size="small"
                label={label}
                name={name}
                onChange={handleCheckFieldToShare}
              />
            ))}
          </Stack>
          <Stack spacing={4} flex={1}>
            {stackTwo.map(({ name, label, checked }) => (
              <CheckboxField
                key={name}
                isChecked={checked}
                size="small"
                label={label}
                name={name}
                onChange={handleCheckFieldToShare}
              />
            ))}
          </Stack>
        </Stack>

        <Stack direction="row" divider={<StackDivider />} spacing={4} flex={1}>
          <Stack spacing={4} flex={1}>
            {stackThree.map(({ name, label, checked }) => (
              <CheckboxField
                key={name}
                isChecked={checked}
                size="small"
                label={label}
                name={name}
                onChange={handleCheckFieldToShare}
              />
            ))}
          </Stack>
          <Stack spacing={4} flex={1}>
            {stackFour.map(({ name, label, checked }) => (
              <CheckboxField
                key={name}
                isChecked={checked}
                size="small"
                label={label}
                name={name}
                onChange={handleCheckFieldToShare}
              />
            ))}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ShareProfileFieldsToShare;
