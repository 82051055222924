import {
  FieldBrand,
  ItemID,
  ItemUUID,
  ItemNanoId,
  ItemCreatedAt,
  MayBe,
  ItemUpdatedAt
} from '../../../types';
import { CandidateShelfLife } from '../../common/components/formFields/SelectCandidateShelfLifeFormField/components/SelectCandidateShelfLifeFormFieldControl/SelectCandidateShelfLifeFormFieldContro.types';
import {
  CompanyMemberInvitationAccountTypeId,
  CompanyMemberInvitationEmail,
  CompanyMemberInvitationId,
  CompanyMemberInvitationName
} from '../../companyMemberInvitations/companyMemberInvitationsTypes';
import {
  JobCandidateId,
  JobCandidateName
} from '../../jobCandidates/jobCandidatesTypes';
import { JobId } from '../../jobs/jobsTypes';
import {
  MayBeRecipientImage,
  MayBeRecipientImageId,
  RecipientId,
  RecipientName
} from '../../recipients/recipientsTypes';

import {
  UserId,
  UserNanoId,
  UserName,
  UserFullName,
  UserEmail,
  UserImage,
  UserRoles
} from '../../users/usersTypes';

export type CandidateSubmissionId = FieldBrand<ItemID, 'CandidateSubmissionId'>;
export type CandidateSubmissionUuid = FieldBrand<
  ItemUUID,
  'CandidateSubmissionUuid'
>;
export type MayBeCandidateSubmissionUuid = MayBe<CandidateSubmissionUuid>;
export type CandidateSubmissionNanoId = FieldBrand<
  ItemNanoId,
  'CandidateSubmissionNanoId'
>;
export type MayBeCandidateSubmissionNanoId = MayBe<CandidateSubmissionNanoId>;
export type CandidateSubmissionUserId = FieldBrand<
  UserId,
  'CandidateSubmissionUserId'
>;
export type CandidateSubmissionUser = FieldBrand<
  {
    id: UserId;
    nanoId: UserNanoId;
    name: UserName;
    fullName: UserFullName;
    email: UserEmail;
    image: UserImage;
    roles: UserRoles;
  },
  'CandidateSubmissionUser'
>;
export type CandidateSubmissionStatus = FieldBrand<
  string,
  'CandidateSubmissionStatus'
>;
export type CandidateSubmissionMessage = FieldBrand<
  string,
  'CandidateSubmissionMessage'
>;
export type CandidateSubmissionJobId = FieldBrand<
  JobId,
  'CandidateSubmissionJobId'
>;
export type CandidateSubmissionJobCandidateIds = FieldBrand<
  JobCandidateId[],
  'CandidateSubmissionJobCandidateIds'
>;
export type CandidateSubmissionJobCandidates = FieldBrand<
  { id: JobCandidateId; name: JobCandidateName }[],
  'CandidateSubmissionJobCandidateIds'
>;
export type CandidateSubmissionRecipientIds = FieldBrand<
  RecipientId[],
  'CandidateSubmissionRecipientIds'
>;
export type CandidateSubmissionRecipient = FieldBrand<
  {
    id: RecipientId;
    name: RecipientName;
    image: MayBeRecipientImage;
    imageId: MayBeRecipientImageId;
  },
  'CandidateSubmissionRecipient'
>;
export type CandidateSubmissionRecipients = FieldBrand<
  CandidateSubmissionRecipient[],
  'CandidateSubmissionRecipients'
>;
export type CandidateSubmissionCandidateSubmissionInvitation = FieldBrand<
  {
    name: CompanyMemberInvitationName;
    email: CompanyMemberInvitationEmail;
    accountTypeId: CompanyMemberInvitationAccountTypeId;
    id?: CompanyMemberInvitationId;
  },
  'CandidateSubmissionCandidateSubmissionInvitation'
>;
export type CandidateSubmissionCandidateSubmissionInvitations = FieldBrand<
  {
    name: CompanyMemberInvitationName;
    email: CompanyMemberInvitationEmail;
    accountTypeId: CompanyMemberInvitationAccountTypeId;
    id?: CompanyMemberInvitationId;
  }[],
  'CandidateSubmissionCandidateSubmissionInvitations'
>;
export type CandidateSubmissionCompanyMemberInvitation = FieldBrand<
  {
    name: CompanyMemberInvitationName;
    email: CompanyMemberInvitationEmail;
    accountTypeId: CompanyMemberInvitationAccountTypeId;
    id?: CompanyMemberInvitationId;
  },
  'CandidateSubmissionCompanyMemberInvitation'
>;
export type CandidateSubmissionCompanyMemberInvitations = FieldBrand<
  CandidateSubmissionCompanyMemberInvitation[],
  'CandidateSubmissionCompanyMemberInvitations'
>;
export type CandidateSubmissionCreatedAt = FieldBrand<
  ItemCreatedAt,
  'CandidateSubmissionCreatedAt'
>;
export type CandidateSubmissionUpdatedAt = FieldBrand<
  ItemUpdatedAt,
  'CandidateSubmissionUpdatedAt'
>;

export type CandidateSubmissionComment = FieldBrand<
  string,
  'CandidateSubmissionComment'
>;

export type CandidateSubmissionBaseSalary = FieldBrand<
  string,
  'CandidateSubmissionBaseSalary'
>;

export type CandidateSubmissionCandidateShelfLife = FieldBrand<
  CandidateShelfLife,
  'CandidateSubmissionCandidateShelfLife'
>;

export enum CandidateSubmissionShareSettingsFields {
  Files = 'files',
  Profile = 'profile',
  Activities = 'activities',
  ScoreCards = 'scoreCards',

  ProfileEmail = 'profileEmail',
  ProfilePhotos = 'profilePhotos',
  ProfileJobTitle = 'profileJobTitle',
  ProfileLocation = 'profileLocation',
  ProfileEducation = 'profileEducation',
  ProfileExperience = 'profileExperience',
  ProfileLinkedInUrl = 'profileLinkedInUrl',
  ProfilePhoneNumber = 'profilePhoneNumber',
  ProfileCandidateName = 'profileCandidateName',
  ProfileCurrentCompany = 'profileCurrentCompany',
  ProfileCertificationLicense = 'profileCertificationLicense',

  ActivitiesAllActivity = 'activitiesAllActivity',
  ActivitiesPhoneCalls = 'activitiesPhoneCalls',
  ActivitiesCallConnections = 'activitiesCallConnections',
  ActivitiesEmails = 'activitiesEmails',
  ActivitiesMeetings = 'activitiesMeetings',
  ActivitiesStageChanges = 'activitiesStageChanges',
  ActivitiesComments = 'activitiesComments',
  ActivitiesTasks = 'activitiesTasks',

  AssessmentPreferences = 'assessmentPreferences',
  AssessmentPreferencesEmployment = 'assessmentPreferencesEmployment',
  AssessmentPreferencesWorkplace = 'assessmentPreferencesWorkplace',
  AssessmentPreferencesPreferredLocation = 'assessmentPreferencesPreferredLocation',
  AssessmentPreferencesSalary = 'assessmentPreferencesSalary',

  AssessmentQualifications = 'assessmentQualifications',
  AssessmentQualificationsAbout = 'assessmentQualificationsAbout',
  AssessmentQualificationsJobFunction = 'assessmentQualificationsJobFunction',
  AssessmentQualificationsWorkAuthorization = 'assessmentQualificationsWorkAuthorization',
  AssessmentQualificationsIndustry = 'assessmentQualificationsIndustry',
  AssessmentQualificationsSpecialization = 'assessmentQualificationsSpecialization',
  AssessmentQualificationsIndustryExperience = 'assessmentQualificationsIndustryExperience',
  AssessmentQualificationsCareerPath = 'assessmentQualificationsCareerPath',

  AssessmentSkillsExpertise = 'assessmentSkillsExpertise',
  AssessmentSkillsExpertiseComputerSkills = 'assessmentSkillsExpertiseComputerSkills',
  AssessmentSkillsExpertiseIndustryKnowledge = 'assessmentSkillsExpertiseIndustryKnowledge',

  AssessmentQuestions = 'assessmentQuestions',
  AssessmentProjects = 'assessmentProjects',
  AssessmentReferences = 'assessmentReferences',
  AssessmentUpdatedResume = 'assessmentUpdatedResume'
}

export type CandidateSubmissionShareSettingsField = FieldBrand<
  CandidateSubmissionShareSettingsFields,
  'CandidateSubmissionShareSettingsField'
>;

export enum CandidateSubmissionOverallEvaluationEnums {
  HireNow = 'hire_now',
  GreatFit = 'great_fit',
  GoodFit = 'good_fit',
  OkFit = 'ok_fit',
  NotAFit = 'not_fit'
}

export type CandidateSubmissionOverallEvaluation = FieldBrand<
  CandidateSubmissionOverallEvaluationEnums,
  'CandidateSubmissionOverallEvaluation'
>;

export enum CandidateSubmissionEvaluationsEnum {
  JobFit = 'job_fit',
  Communication = 'interpersonal',
  Impression = 'professional_impression',
  Motivation = 'motivation',
  Overall = 'overall_evaluation'
}

export enum CandidateSubmissionisibilityOptionEnum {
  HiringManagers = 'Hiring Managers',
  Recruiters = 'Hiring Managers, Recruiters',
  AllHiringTeam = 'All hiring team'
}

export type CandidateSubmissionisibilityOption = FieldBrand<
  CandidateSubmissionisibilityOptionEnum,
  'CandidateSubmissionisibilityOption'
>;

export type CandidateSubmissionEvaluations = {
  [key in CandidateSubmissionEvaluationsEnum]: {
    rating: number;
    comment: string;
  };
};

export type CandidateSubmissionEvaluation = {
  evaluationType: CandidateSubmissionEvaluationsEnum;
  rating: number;
  comment: string;
};

export type CandidateSubmissionShareSettings = FieldBrand<
  { [key in CandidateSubmissionShareSettingsFields]?: boolean },
  'CandidateShareShareSettings'
>;

export enum CandidateSubmissionVisibilityOptionTypeEnum {
  HiringManagers = 'Hiring Managers',
  Recruiters = 'Hiring Managers, Recruiters',
  AllHiringTeam = 'All hiring team'
}

export type CandidateSubmissionVisibilityOptionType = FieldBrand<
  CandidateSubmissionVisibilityOptionTypeEnum,
  'CandidateSubmissionVisibilityOptionType'
>;

export const enum CandidateSubmissionFields {
  ID = 'id',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  JOB_ID = 'jobId',
  STATUS = 'status',
  MESSAGE = 'message',
  USER_ID = 'userId',
  USER = 'user',
  JOB_CANDIDATE_IDS = 'jobCandidateIds',
  JOB_CANDIDATES = 'jobCandidates',
  RECIPIENT_IDS = 'recipientIds',
  RECIPIENTS = 'recipients',
  CANDIDATE_SUBMISSION_INVITATIONS = 'candidateSubmissionInvitations',
  COMPANY_MEMBER_INVITATIONS = 'companyMemberInvitations',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  SHARE_SETTINGS = 'shareSettings',
  COMMENT = 'comment',
  EVALUATIONS = 'evaluations',
  OVERALL_EVALUATION = 'overallEvaluation',
  BASE_SALARY = 'baseSalary',
  CANDIDATE_SHELF_LIFE = 'candidateShelfLife',
  VISIBILITY_OPTION_TYPE = 'visibilityOptionType'
}
