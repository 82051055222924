import { SystemStyleObject } from '@chakra-ui/theme-tools';

const baseStyle: SystemStyleObject = {
  borderRadius: 4,
  fontWeight: '500',
  border: '1px solid transparent',
  boxShadow: '0px 2px 2px rgba(5, 128, 164, 0.08)',
  _disabled: {
    boxShadow: '0px 2px 2px rgba(23, 25, 26, 0.06)',
    opacity: 1
  }
};

const defaultProps = {
  variant: 'primary',
  colorScheme: 'primary'
};

const sizes: Record<string, SystemStyleObject> = {
  lg: {
    p: 4,
    fontSize: '16px'
  },
  md: {
    px: 4,
    fontSize: '14px'
  },
  sm: {
    px: 2,
    fontSize: '12px'
  }
};

const variants: Record<string, SystemStyleObject> = {
  primary: {
    bg: 'primary.500',
    color: 'white',
    borderRadius: '4px',
    _hover: {
      background: 'primary.600',
      _disabled: {
        bg: 'gray.400'
      }
    },
    _disabled: {
      bg: 'gray.400'
    }
  },
  danger: {
    bg: 'red.500',
    color: 'white',
    borderRadius: '4px',
    _hover: {
      background: 'red.900',
      _disabled: {
        bg: 'gray.400'
      }
    },
    _disabled: {
      bg: 'gray.400'
    }
  },
  warning: {
    bg: '#FCF7ED',
    color: '#BD8200',
    borderColor: 'rgba(238, 164, 1, 0.2)',
    borderRadius: '4px',
    boxShadow: '0px 2px 2px rgba(238, 164, 1, 0.08)',
    _hover: {
      background: '#F7E0B2',
      _disabled: {
        bg: 'gray.400'
      }
    },
    _disabled: {
      bg: 'gray.400'
    }
  },
  secondary: {
    bg: 'white',
    color: 'primary.500',
    borderColor: 'rgba(5, 128, 164, 0.5)',
    borderRadius: '4px',
    _hover: {
      bg: 'linear-gradient(0deg, rgba(5, 128, 164, 0.03), rgba(5, 128, 164, 0.03)), #FFFFFF',
      color: 'primary.600',
      _disabled: {
        color: 'gray.400'
      }
    },
    _disabled: {
      color: 'gray.400',
      borderColor: 'gray.200'
    }
  },
  tertiary: {
    bg: 'white',
    color: 'gray.900',
    borderColor: 'gray.300',
    borderRadius: '4px',
    boxShadow: '0px 2px 2px rgba(23, 25, 26, 0.06)',
    _hover: {
      bg: 'gray.50'
    },
    _disabled: {
      color: 'gray.400',
      borderColor: 'gray.200'
    }
  },
  link: {
    bg: 'transparent',
    border: 'none',
    boxShadow: 'none',
    _hover: {},
    _disabled: {
      color: 'gray.400',
      boxShadow: 'none'
    }
  },
  unstyled: {
    boxShadow: 'none',
    _disabled: {
      color: 'gray.400',
      boxShadow: 'none'
    }
  }
};

export const Button = {
  baseStyle,
  defaultProps,
  sizes,
  variants
};
