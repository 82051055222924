import { FieldBrand, ItemRecordID } from '../../../types';

import { UserUuid } from '../../users/usersTypes';

export type ContactRecordId = FieldBrand<ItemRecordID, 'ContactRecordId'>;

export type ContactUserUuid = FieldBrand<UserUuid, 'ContactUserUuid'>;

export const enum ContactIndexFields {
  ID = 'id',
  RECORD_ID = 'recordId',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  CREATED_AT = 'createdAt',

  USER_UUID = 'userUuid'
}
