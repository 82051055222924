import { FetchCertificationsCacheKey } from '../../certificationsTypes';

import { useCreateItem } from '../../../common/hooks/useCreateItem';

import {
  CertificationsBffRequests,
  CreateCertificationRequestData,
  CreateCertificationErrorResponse
} from '../../CertificationsBffRequests';

interface CreateCertificationOptions {
  cacheKeys?: FetchCertificationsCacheKey[];
}

function useCreateCertification({
  cacheKeys = []
}: CreateCertificationOptions) {
  const {
    createItemData,
    createItemError,
    createItemErrorMessage,
    createItemIsLoading,
    createItem,
    createItemReset
  } = useCreateItem<
    CreateCertificationRequestData,
    CreateCertificationErrorResponse
  >({
    mutationFn: (queryInput) =>
      CertificationsBffRequests.createCertification(queryInput),
    cacheKeys
  });

  return {
    createCertificationData: createItemData,
    createCertificationError: createItemError,
    createCertificationErrorMessage: createItemErrorMessage,
    createCertificationIsLoading: createItemIsLoading,
    createCertification: createItem,
    createCertificationReset: createItemReset
  };
}

export default useCreateCertification;
