import first from 'lodash/first';
import last from 'lodash/last';
import size from 'lodash/size';
import slice from 'lodash/slice';

import {
  BaseSalaryFrequencyEnums,
  BaseSalaryRadioInputOptionsEnums
} from '../../components/formFields/BaseSlaryInputFormField';

import { FormatNumber } from '../../../../utils/FormatNumber';

export default async function baseSalaryBreakdown(value: string) {
  if (!value) {
    return {
      salary_type: BaseSalaryRadioInputOptionsEnums.RANGE,
      minimum: '',
      maximum: '',
      frequency: BaseSalaryFrequencyEnums.ANNUALLY
    };
  }

  const arr: string[] = value?.split('_');

  if (size(arr) >= 3) {
    const salary_type = first(arr) as BaseSalaryRadioInputOptionsEnums;
    const frequency = last(arr) as BaseSalaryFrequencyEnums;
    const amountArr = slice(arr, 1, -1);
    const minimum =
      size(amountArr) === 1
        ? ''
        : String(FormatNumber.longNumber(first(amountArr) as string));
    const maximum = String(FormatNumber.longNumber(last(amountArr) as string));
    return {
      salary_type,
      minimum,
      maximum,
      frequency
    };
  } else {
    const minimum = String(FormatNumber.longNumber(first(arr) as string));
    const maximum = String(FormatNumber.longNumber(last(arr) as string));
    return {
      salary_type: BaseSalaryRadioInputOptionsEnums.RANGE,
      minimum,
      maximum,
      frequency: BaseSalaryFrequencyEnums.ANNUALLY
    };
  }
}
