import { Icon, IconProps } from '@chakra-ui/icon';

const ChevronDoubleRightIcon = ({
  w = '14px',
  h = '14px',
  ...props
}: IconProps) => (
  <Icon viewBox="0 0 14 14" w={w} h={h} fill="none" {...props}>
    <path
      d="m1 1 5.625 6L1 13M7.375 1 13 7l-5.625 6"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

export default ChevronDoubleRightIcon;
