import { Flex, Stack } from '@chakra-ui/react';
import { useCurrentUser } from '../../../../../../../../../../../../../../../auth/hooks/useAuth';
import { AvatarHelper } from '../../../../../../../../../../../../../../../helpers/AvatarHelper';
import { Text } from '../../../../../../../../../../../../../../../helpers/Text';
import { ThumbUpIcon } from '../../../../../../../../../../../../../../../icons/ThumbUpIcon';
import { ViewJobCandidateModalPhoneSidebarAvailabilityComment } from '../ViewJobCandidateModalPhoneSidebarAvailabilityComment';

type ViewJobCandidateModalPhoneSidebarAvailabilityFormProps = {
  onSave: () => void;
  onDiscard: () => void;
};

const ViewJobCandidateModalPhoneSidebarAvailabilityForm = ({
  onSave,
  onDiscard
}: ViewJobCandidateModalPhoneSidebarAvailabilityFormProps) => {
  const currentUser = useCurrentUser();

  return (
    <Stack spacing={4}>
      <Flex justifyContent="space-between">
        <Flex alignItems="center" gap={2}>
          <AvatarHelper image={currentUser.image} />

          <Text textStyle="body1Medium" noOfLines={1}>
            {currentUser.name}
          </Text>
        </Flex>

        <Flex
          w={9}
          h={9}
          pt="9px"
          pb="11px"
          bg="green.100"
          alignItems="center"
          justifyContent="center"
          borderRadius="full"
        >
          <ThumbUpIcon color="green.400" w={4} h={4} />
        </Flex>
      </Flex>

      <ViewJobCandidateModalPhoneSidebarAvailabilityComment
        onSave={onSave}
        onDiscard={onDiscard}
      />
    </Stack>
  );
};

export default ViewJobCandidateModalPhoneSidebarAvailabilityForm;
