import { Box, Flex, Stack, StackDivider } from '@chakra-ui/react';

import { IsHidden, ModalIsOpen } from '../../../../../types';

import { JobNanoId } from '../../../jobsTypes';

import { JobsRoutes } from '../../../JobsRoutes';

import { Heading } from '../../../../../helpers/Heading';
import { SimpleModal } from '../../../../../helpers/SimpleModal';
import { PureButtonHelper } from '../../../../../helpers/buttons/PureButtonHelper';
import { NextLinkHelper } from '../../../../../helpers/NextLinkHelper';
import { AddLeadIcon } from '../../../../../icons/AddLeadIcon';

interface AddNewLeadModalProps {
  jobNanoId: JobNanoId;
  modalIsOpen: ModalIsOpen;
  onCloseModal: () => void;
}

function AddNewLeadModal({
  jobNanoId,
  modalIsOpen,
  onCloseModal
}: AddNewLeadModalProps) {
  return (
    <SimpleModal
      isOpen={modalIsOpen}
      onCancel={onCloseModal}
      onSubmit={onCloseModal}
      size="md"
      footerIsHidden={true as IsHidden}
      header={
        <Heading level="h4" color="gray.900" fontWeight="600">
          Add new lead
        </Heading>
      }
    >
      <Stack spacing={4} divider={<StackDivider borderColor="gray.200" />}>
        <Flex justifyContent="center">
          <AddLeadIcon />
        </Flex>

        <Flex gap="6" justifyContent="stretch" pb="2">
          <NextLinkHelper
            href={JobsRoutes.postCandidatesLead(jobNanoId)}
            passHref
          >
            <Box as="a" _hover={{ textDecoration: 'none' }} flexGrow={1}>
              <PureButtonHelper
                hierarchy="secondary"
                size="small"
                i18nText="Add lead"
                w="100%"
              />
            </Box>
          </NextLinkHelper>

          <NextLinkHelper
            href={JobsRoutes.postJobCandidates(jobNanoId)}
            passHref
          >
            <Box as="a" _hover={{ textDecoration: 'none' }} flexGrow={1}>
              <PureButtonHelper
                size="small"
                i18nText="Upload resume"
                w="100%"
              />
            </Box>
          </NextLinkHelper>
        </Flex>
      </Stack>
    </SimpleModal>
  );
}

export default AddNewLeadModal;
