import { Flex, Stack } from '@chakra-ui/react';
import { DateTime, Duration, Interval } from 'luxon';
import { useState } from 'react';
import { DateTimeFormats, DateUtils } from '../../../../utils/DateUtils';
import { Button } from '../../../Button';
import { Heading } from '../../../Heading';
import { ScheduleHelperDuration } from '../../ScheduleHelper.types';

interface ScheduleHelperTimeRangeProps {
  maxSlots?: number;
  selectedDate?: string;
  selectedTimeSlots: string[];
  duration: ScheduleHelperDuration;
  handleTimeSelection: (timeSlot: string) => void;
}

export default function ScheduleHelperTimeRange({
  maxSlots,
  selectedDate,
  duration = { hour: 1 },
  handleTimeSelection,
  selectedTimeSlots
}: ScheduleHelperTimeRangeProps) {
  const selectedDateTime = selectedDate
    ? DateTime.fromISO(DateUtils.toISO(selectedDate) as string)
    : DateTime.now();

  const startTime = selectedDateTime.set({ hour: 9, minute: 0 });
  const endTime = selectedDateTime.set({ hour: 18, minute: 0 });
  const durationT = Duration.fromObject(duration);

  const generateTimeRanges = (
    start: DateTime,
    end: DateTime,
    duration: Duration
  ): Interval[] => {
    const timeRanges: Interval[] = [];
    let current = start.startOf('minute');

    while (current.plus(duration).toMillis() <= end.toMillis()) {
      const next = current.plus(duration).startOf('minute');
      timeRanges.push(Interval.fromDateTimes(current, next));
      current = next;
    }

    return timeRanges;
  };

  const timeRanges = generateTimeRanges(startTime, endTime, durationT);

  const [selectedTime, setSelectedTime] = useState('');

  const selectItem = (date: string) => {
    setSelectedTime(date);
  };

  const confirmSelection = () => {
    handleTimeSelection(selectedTime);

    setSelectedTime('');
  };

  const cancelSelection = (timeSlot: string) => {
    handleTimeSelection(timeSlot);
  };

  const maxSlotsSelected = selectedTimeSlots.length === maxSlots;

  return (
    <Stack spacing={4} w="2xs">
      <Heading level="h5" fontWeight="medium" color="gray.600">
        {selectedDate
          ? DateUtils.formatDate(
              DateUtils.toISO(selectedDate),
              DateTimeFormats.CCCC_MMM_DD
            )
          : 'Select date'}
      </Heading>

      <Stack spacing={4} h="304px" overflow="auto" pr={2}>
        {timeRanges.map((range, index) => {
          const isSelected = selectedTime === range.toISO();
          const isPast = DateTime.now() >= range.start;
          const isSelectedSlot = selectedTimeSlots.includes(range.toISO());

          const isDisabled =
            isPast || isSelected || maxSlotsSelected || isSelectedSlot;

          const backgroundColor = isSelected
            ? 'gray.600'
            : (isSelectedSlot && 'green.600') || 'transparent';

          return (
            <Flex key={index} gap={4}>
              <Button
                flex={1}
                onClick={() => selectItem(range.toISO())}
                isDisabled={isDisabled}
                hierarchy={'secondary'}
                opacity={1}
                _disabled={{
                  bg: backgroundColor,
                  color: isSelected || isSelectedSlot ? 'white' : 'gray.700',
                  cursor: 'not-allowed',
                  borderColor: isSelectedSlot ? 'green.600' : 'gray.600',
                  opacity: isSelected || isSelectedSlot ? 1 : 0.5,
                  _hover: {
                    bg: backgroundColor,
                    color: isSelected || isSelectedSlot ? 'white' : 'gray.700',
                    borderColor: isSelectedSlot ? 'green.600' : 'gray.600'
                  }
                }}
              >
                {DateUtils.formatDate(`${range.start}`, DateTimeFormats.H_MM_A)}

                {!(isSelected || isSelectedSlot) && (
                  <>
                    {' '}
                    -{' '}
                    {DateUtils.formatDate(
                      `${range.end}`,
                      DateTimeFormats.H_MM_A
                    )}
                  </>
                )}
              </Button>

              {isSelected && (
                <Button flex={1} onClick={confirmSelection}>
                  Confirm
                </Button>
              )}

              {isSelectedSlot && (
                <Button
                  flex={1}
                  hierarchy="tertiary"
                  onClick={() => cancelSelection(range.toISO())}
                >
                  Cancel
                </Button>
              )}
            </Flex>
          );
        })}
      </Stack>
    </Stack>
  );
}
