import {
  FetchJobCandidatesCacheKey,
  FetchJobCandidateCacheKey,
  AddCandidateToJobErrorMessage,
  AddCandidateToJobIsLoading,
  AddCandidateToJobNanoId
} from '../../jobCandidatesTypes';

import { useUpdateItem } from '../../../common/hooks/useUpdateItem';

import {
  JobCandidatesBffRequests,
  AddCandidateToJobRequestData,
  AddCandidateToJobErrorResponse
} from '../../JobCandidatesBffRequests';

interface AddCandidateToJobOptions {
  jobCandidateNanoId: AddCandidateToJobNanoId;
  cacheKeys?: (FetchJobCandidatesCacheKey | FetchJobCandidateCacheKey)[];
}

function useAddCandidateToJob({
  jobCandidateNanoId,
  cacheKeys = []
}: AddCandidateToJobOptions) {
  const {
    updateItemData,
    updateItemError,
    updateItemErrorMessage,
    updateItemIsLoading,
    updateItem,
    updateItemReset
  } = useUpdateItem<
    AddCandidateToJobRequestData,
    AddCandidateToJobErrorResponse
  >({
    mutationFn: (queryInput) =>
      JobCandidatesBffRequests.addCandidateToJob(
        jobCandidateNanoId,
        queryInput
      ),
    cacheKeys
  });

  return {
    addCandidateToJobData: updateItemData,
    addCandidateToJobError: updateItemError,
    addCandidateToJobErrorMessage:
      updateItemErrorMessage as AddCandidateToJobErrorMessage,
    addCandidateToJobIsLoading:
      updateItemIsLoading as AddCandidateToJobIsLoading,
    addCandidateToJob: updateItem,
    addCandidateToJobReset: updateItemReset
  };
}

export default useAddCandidateToJob;
