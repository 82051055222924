import {
  FetchTalentPoolJobCandidatesQuery,
  FetchTalentPoolJobCandidatesCountType,
  FetchTalentPoolJobCandidatesPage,
  FetchTalentPoolJobCandidatesPageSize,
  FetchTalentPoolJobCandidatesFilters,
  FetchTalentPoolJobCandidatesSort,
  FetchTalentPoolJobCandidatesIndexQueryName,
  FetchTalentPoolJobCandidatesIndexCountType,
  FetchTalentPoolJobCandidatesIndexPage,
  FetchTalentPoolJobCandidatesIndexPageSize,
  FetchTalentPoolJobCandidatesIndexFilters,
  FetchTalentPoolJobCandidatesIndexSort,
  FetchTalentPoolJobCandidateQuery,
  FetchTalentPoolJobCandidateIndexQueryName,
  TalentPoolJobCandidateId,
  TalentPoolJobCandidateUuid
} from './talentPoolJobCandidatesTypes';

import { BaseBffRequests } from '../BaseBffRequests';

import { TalentPoolJobCandidatesBffRoutes } from './TalentPoolJobCandidatesBffRoutes';

interface FetchTalentPoolJobCandidatesRequestParams {
  query: FetchTalentPoolJobCandidatesQuery;
  countType?: FetchTalentPoolJobCandidatesCountType;
  page?: FetchTalentPoolJobCandidatesPage;
  pageSize?: FetchTalentPoolJobCandidatesPageSize;
  filters?: FetchTalentPoolJobCandidatesFilters;
  sort?: FetchTalentPoolJobCandidatesSort;
}

interface FetchTalentPoolJobCandidatesIndexRequestParams {
  queryName: FetchTalentPoolJobCandidatesIndexQueryName;
  countType?: FetchTalentPoolJobCandidatesIndexCountType;
  page?: FetchTalentPoolJobCandidatesIndexPage;
  pageSize?: FetchTalentPoolJobCandidatesIndexPageSize;
  filters?: FetchTalentPoolJobCandidatesIndexFilters;
  sort?: FetchTalentPoolJobCandidatesIndexSort;
}

interface FetchTalentPoolJobCandidateRequestParams {
  query: FetchTalentPoolJobCandidateQuery;
}

interface FetchTalentPoolJobCandidateIndexRequestParams {
  queryName: FetchTalentPoolJobCandidateIndexQueryName;
}
export class TalentPoolJobCandidatesBffRequests extends BaseBffRequests {
  static fetchTalentPoolJobCandidates<T>(
    params: FetchTalentPoolJobCandidatesRequestParams
  ) {
    return this.fetchItems<T>(
      TalentPoolJobCandidatesBffRoutes.fetchTalentPoolJobCandidatesRoute(),
      params
    );
  }

  static fetchTalentPoolJobCandidatesIndex<T>(
    params: FetchTalentPoolJobCandidatesIndexRequestParams
  ) {
    return this.fetchItemsIndex<T>(
      TalentPoolJobCandidatesBffRoutes.fetchTalentPoolJobCandidatesIndexRoute(),
      params
    );
  }

  static fetchTalentPoolJobCandidate<T>(
    talentPoolJobCandidateId: TalentPoolJobCandidateId,
    params: FetchTalentPoolJobCandidateRequestParams
  ) {
    return this.fetchItem<T>(
      TalentPoolJobCandidatesBffRoutes.fetchTalentPoolJobCandidateRoute(
        talentPoolJobCandidateId
      ),
      params
    );
  }

  static fetchTalentPoolJobCandidateIndex<T>(
    talentPoolJobCandidateUuid: TalentPoolJobCandidateUuid,
    params: FetchTalentPoolJobCandidateIndexRequestParams
  ) {
    return this.fetchItemIndex<T>(
      TalentPoolJobCandidatesBffRoutes.fetchTalentPoolJobCandidateIndexRoute(
        talentPoolJobCandidateUuid
      ),
      params
    );
  }
}
