import { CircularProgress, Flex } from '@chakra-ui/react';
import { Fragment, ReactNode } from 'react';

interface LoaderProps {
  loaded: boolean;
  children?: ReactNode;
  loader?: ReactNode;
  size?: number;
}

const Loader = ({ loaded, children, loader, size = 75 }: LoaderProps) => {
  return (
    <Fragment>
      {loaded ? (
        children
      ) : (
        <Flex
          flex={1}
          w="100%"
          h="100%"
          alignItems="center"
          justifyContent="center"
        >
          {loader ? (
            loader
          ) : (
            <CircularProgress size={size} thickness="3px" isIndeterminate />
          )}
        </Flex>
      )}
    </Fragment>
  );
};

export default Loader;
