import {
  FetchWorkflowStageTasksQuery,
  FetchWorkflowStageTasksCountType,
  FetchWorkflowStageTasksPage,
  FetchWorkflowStageTasksPageSize,
  FetchWorkflowStageTasksFilters,
  FetchWorkflowStageTasksSort,
  FetchWorkflowStageTaskQuery,
  CreateWorkflowStageTaskError,
  UpdateWorkflowStageTaskError,
  DeleteWorkflowStageTaskError,
  WorkflowStageTaskId,
  WorkflowStageTaskNanoId,
  WorkflowStageTaskName,
  WorkflowStageTaskArrangement,
  WorkflowStageTaskWorkflowStageId,
  WorkflowStageTaskUserId
} from './workflowStageTasksTypes';

import { BaseBffRequests } from '../BaseBffRequests';

import { WorkflowStageTasksBffRoutes } from './WorkflowStageTasksBffRoutes';

interface FetchWorkflowStageTasksRequestParams {
  query: FetchWorkflowStageTasksQuery;
  countType?: FetchWorkflowStageTasksCountType;
  page?: FetchWorkflowStageTasksPage;
  pageSize?: FetchWorkflowStageTasksPageSize;
  filters?: FetchWorkflowStageTasksFilters;
  sort?: FetchWorkflowStageTasksSort;
}

interface FetchWorkflowStageTaskRequestParams {
  query: FetchWorkflowStageTaskQuery;
}

export interface CreateWorkflowStageTaskRequestData {
  nanoId: WorkflowStageTaskNanoId;
  workflowStageId: WorkflowStageTaskWorkflowStageId;
  userId?: WorkflowStageTaskUserId;
  name?: WorkflowStageTaskName;
  arrangement?: WorkflowStageTaskArrangement;
}

export interface UpdateWorkflowStageTaskRequestData {
  name?: WorkflowStageTaskName;
}

export interface DeleteWorkflowStageTaskRequestData {
  workflowStageTaskNanoId: WorkflowStageTaskNanoId;
}

export interface CreateWorkflowStageTaskErrorResponse {
  message: CreateWorkflowStageTaskError;
}

export interface UpdateWorkflowStageTaskErrorResponse {
  message: UpdateWorkflowStageTaskError;
}

export interface DeleteWorkflowStageTaskErrorResponse {
  message: DeleteWorkflowStageTaskError;
}

export class WorkflowStageTasksBffRequests extends BaseBffRequests {
  static fetchWorkflowStageTasks<T>(
    params: FetchWorkflowStageTasksRequestParams
  ) {
    return this.fetchItems<T>(
      WorkflowStageTasksBffRoutes.fetchWorkflowStageTasksRoute(),
      params
    );
  }

  static fetchWorkflowStageTask<T>(
    workflowStageTaskId: WorkflowStageTaskId,
    params: FetchWorkflowStageTaskRequestParams
  ) {
    return this.fetchItem<T>(
      WorkflowStageTasksBffRoutes.fetchWorkflowStageTaskRoute(
        workflowStageTaskId
      ),
      params
    );
  }

  static fetchWorkflowStageTaskByNanoId<T>(
    workflowStageTaskNanoId: WorkflowStageTaskNanoId,
    params: FetchWorkflowStageTaskRequestParams
  ) {
    return this.fetchItemByNanoId<T>(
      WorkflowStageTasksBffRoutes.fetchWorkflowStageTaskByNanoIdRoute(
        workflowStageTaskNanoId
      ),
      params
    );
  }

  static createWorkflowStageTask<T>(
    createWorkflowStageTaskData: CreateWorkflowStageTaskRequestData
  ) {
    return this.createItem<T, CreateWorkflowStageTaskRequestData>(
      WorkflowStageTasksBffRoutes.createWorkflowStageTaskRoute(),
      createWorkflowStageTaskData
    );
  }

  static async updateWorkflowStageTask(
    updateWorkflowStageTaskNanoId: WorkflowStageTaskNanoId,
    updateWorkflowStageTaskData: UpdateWorkflowStageTaskRequestData
  ) {
    const { data, error } = await this.updateItem<
      { data: UpdateWorkflowStageTaskRequestData; error: string | null },
      UpdateWorkflowStageTaskRequestData
    >(
      WorkflowStageTasksBffRoutes.updateWorkflowStageTaskRoute(
        updateWorkflowStageTaskNanoId
      ),
      updateWorkflowStageTaskData
    );

    if (error) {
      throw error;
    }

    return data;
  }

  static async deleteWorkflowStageTask(
    deleteWorkflowStageTaskNanoId: WorkflowStageTaskNanoId
  ) {
    const { data, error } = await this.deleteItem<{
      data: DeleteWorkflowStageTaskRequestData;
      error: string | null;
    }>(
      WorkflowStageTasksBffRoutes.deleteWorkflowStageTaskRoute(
        deleteWorkflowStageTaskNanoId
      )
    );

    if (error) {
      throw error;
    }

    return data;
  }
}
