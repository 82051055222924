import { Icon, IconProps } from '@chakra-ui/icon';

const CandidatesIcon = ({
  w = '24px',
  h = '24px',
  fill = 'gray.500',
  ...props
}: IconProps) => (
  <Icon viewBox="0 0 24 24" w={w} h={h} fill={fill} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.765 6.346C20.765 4.764 19.289 3 17.943 3c0 0-1.122 0-1.897.927a.22.22 0 0 0 .05.327c.59.39 1.847 1.22 1.847 4.429 0 1.6-.829 2.876-1.315 3.486-.186.234-.199.563.018.77.25.238.658.598 1.297 1.106 1.111.883 1.596 1.302 2.08 2.024.198.296.522.49.879.49h1.061a.622.622 0 0 0 .381-.103c.545-.412.724-1.5.634-2.207-.149-1.168-.944-2.223-3.562-3.8-.213-.091-.213-.59 0-.814 1.19-1.034 1.349-2.158 1.349-3.289Zm-17.538 0C3.227 4.764 4.703 3 6.05 3c0 0 1.098 0 1.872.897a.242.242 0 0 1-.063.372c-.602.36-1.809 1.081-1.809 4.288 0 1.24.885 2.938 1.348 3.742a.616.616 0 0 1-.144.79l-1.204.956c-1.111.883-1.618 1.291-2.106 2.018-.201.298-.527.496-.887.496H2c-.135 0-.274-.02-.38-.105-.527-.42-.62-1.545-.62-2.272 0-.827.958-2.156 3.575-3.734.214-.09.214-.589 0-.813-1.19-1.034-1.348-2.158-1.348-3.289ZM15.983 8.3c0-2.342-1.8-4.175-3.995-4.175-2.195 0-3.996 1.906-3.996 4.175 0 2.221.665 3.027 1.514 4.055l.054.065c.215.261.558.953 0 1.4-.43.331-.844.639-1.238.93-1.917 1.42-3.327 2.464-3.327 3.999 0 1.3.348 1.822 1.7 2.22.07.02.141.03.213.03H16.92a.957.957 0 0 0 .287-.043c1.773-.56 1.773-1.172 1.773-2.207 0-1.504-1.462-2.597-4.654-4.883a.472.472 0 0 1-.113-.104c-.225-.316-.208-1.059.05-1.342 1.093-1.045 1.72-1.779 1.72-4.12Z"
    />
  </Icon>
);
export default CandidatesIcon;
