import { FetchSpecializationsCacheKey } from '../../specializationsTypes';

import { useCreateItem } from '../../../common/hooks/useCreateItem';

import {
  SpecializationsBffRequests,
  CreateSpecializationRequestData,
  CreateSpecializationErrorResponse
} from '../../SpecializationsBffRequests';

interface CreateSpecializationOptions {
  cacheKeys?: FetchSpecializationsCacheKey[];
}

function useCreateSpecialization({
  cacheKeys = []
}: CreateSpecializationOptions) {
  const {
    createItemData,
    createItemError,
    createItemErrorMessage,
    createItemIsLoading,
    createItem,
    createItemReset
  } = useCreateItem<
    CreateSpecializationRequestData,
    CreateSpecializationErrorResponse
  >({
    mutationFn: (queryInput) =>
      SpecializationsBffRequests.createSpecialization(queryInput),
    // SpecializationsRequests.createSpecialization(queryInput),
    cacheKeys
  });

  return {
    createSpecializationData: createItemData,
    createSpecializationError: createItemError,
    createSpecializationErrorMessage: createItemErrorMessage,
    createSpecializationIsLoading: createItemIsLoading,
    createSpecialization: createItem,
    createSpecializationReset: createItemReset
  };
}

export default useCreateSpecialization;
