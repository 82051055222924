import { FieldBrand, ItemRecordID } from '../../../types';

import { UserUuid } from '../../users/usersTypes';

export type CountryRecordId = FieldBrand<ItemRecordID, 'CountryRecordId'>;

export type CountryUserUuid = FieldBrand<UserUuid, 'CountryUserUuid'>;

export const enum CountryIndexFields {
  ID = 'id',
  RECORD_ID = 'recordId',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  CREATED_AT = 'createdAt',

  USER_UUID = 'userUuid'
}
