const jobTitleIdRules = {
  required: 'Title is required'
};

const departmentIdRules = {
  required: 'Department is required'
};

const disciplineIdRules = {
  required: 'Discipline is required'
};

const userJobIdRules = {
  required: 'Job Id is required'
};

const workplaceRules = {
  required: 'Workplace is required'
};

const workAuthorizationRules = {
  required: 'Work Authorization is required'
};

const cityIdsRules = {
  required: 'At least one location is required'
};

const hiringManagerIdRules = {
  required: 'Hiring Manager is required'
};

const recruitingCoordinatorIdRules = {
  required: 'Recruiting Coordinator is required'
};

const interviewerIdsRules = {
  required: 'Interviewer panel is required'
};

const companyIdsRules = {
  required: 'At least one company is required'
};

const specializationIdsRules = {
  required: 'At least one specialization is required'
};

const employmentRules = {
  required: 'Employment is required'
};

const geographyRules = {
  required: 'Geography is required'
};

const baseSalaryRules = {
  required: 'Base salary is required'
};

const experienceRules = {
  required: 'Experience is required'
};

const seniorityLevelRules = {
  required: 'Seniority level is required'
};

const jobFunctionIdRules = {
  required: 'Job Function is required'
};

function usePostJobIntakeFormValidationRules() {
  return {
    jobTitleIdRules,
    departmentIdRules,
    disciplineIdRules,
    userJobIdRules,
    workplaceRules,
    workAuthorizationRules,
    cityIdsRules,
    hiringManagerIdRules,
    recruitingCoordinatorIdRules,
    interviewerIdsRules,
    companyIdsRules,
    employmentRules,
    geographyRules,
    baseSalaryRules,
    experienceRules,
    seniorityLevelRules,
    jobFunctionIdRules,
    specializationIdsRules
  };
}

export default usePostJobIntakeFormValidationRules;
