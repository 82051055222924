import {
  LikedJobCandidateItemBffID,
  FetchLikedJobCandidatesBffURL,
  FetchLikedJobCandidateBffURL,
  CreateLikedJobCandidateBffURL,
  UpdateLikedJobCandidateBffURL,
  DeleteLikedJobCandidateBffURL,
  LikedJobCandidateItemBffUuid
} from './likedJobCandidatesTypes';

import { BaseBffRoutes } from '../BaseBffRoutes';

export class LikedJobCandidatesBffRoutes extends BaseBffRoutes {
  protected static route = 'likedJobCandidates';

  static fetchLikedJobCandidatesRoute() {
    return this.fetchItemsRoute<FetchLikedJobCandidatesBffURL>();
  }

  static fetchLikedJobCandidatesIndexRoute() {
    return this.fetchItemsIndexRoute<FetchLikedJobCandidatesBffURL>();
  }

  static fetchLikedJobCandidateRoute(
    likedJobCandidateItemBffID: LikedJobCandidateItemBffID
  ) {
    return this.fetchItemRoute<FetchLikedJobCandidateBffURL>(
      likedJobCandidateItemBffID
    );
  }

  static fetchLikedJobCandidateIndexRoute(
    likedJobCandidateItemBffUuid: LikedJobCandidateItemBffUuid
  ) {
    return this.fetchItemIndexRoute<FetchLikedJobCandidateBffURL>(
      likedJobCandidateItemBffUuid
    );
  }

  static createLikedJobCandidateRoute() {
    return this.createItemRoute<CreateLikedJobCandidateBffURL>();
  }

  static updateLikedJobCandidateRoute(
    likedJobCandidateItemBffID: LikedJobCandidateItemBffID
  ) {
    return this.updateItemRoute<UpdateLikedJobCandidateBffURL>(
      likedJobCandidateItemBffID
    );
  }

  static deleteLikedJobCandidateRoute(
    likedJobCandidateItemBffID: LikedJobCandidateItemBffID
  ) {
    return this.deleteItemRoute<DeleteLikedJobCandidateBffURL>(
      likedJobCandidateItemBffID
    );
  }
}
