import {
  FetchAccountTypesQuery,
  FetchAccountTypesCountType,
  FetchAccountTypesPage,
  FetchAccountTypesPageSize,
  FetchAccountTypesFilters,
  FetchAccountTypesSort,
  FetchAccountTypesIndexQueryName,
  FetchAccountTypesIndexCountType,
  FetchAccountTypesIndexPage,
  FetchAccountTypesIndexPageSize,
  FetchAccountTypesIndexFilters,
  FetchAccountTypesIndexSort,
  FetchAccountTypeQuery,
  FetchAccountTypeIndexQueryName,
  CreateAccountTypeError,
  UpdateAccountTypeError,
  DeleteAccountTypeError,
  AccountTypeId,
  AccountTypeNanoId,
  AccountTypeName,
  AccountTypeUserId
} from './accountTypesTypes';

import { BaseBffRequests } from '../BaseBffRequests';

import { AccountTypesBffRoutes } from './AccountTypesBffRoutes';

interface FetchAccountTypesRequestParams {
  query: FetchAccountTypesQuery;
  countType?: FetchAccountTypesCountType;
  page?: FetchAccountTypesPage;
  pageSize?: FetchAccountTypesPageSize;
  filters?: FetchAccountTypesFilters;
  sort?: FetchAccountTypesSort;
}

interface FetchAccountTypesIndexRequestParams {
  queryName: FetchAccountTypesIndexQueryName;
  countType?: FetchAccountTypesIndexCountType;
  page?: FetchAccountTypesIndexPage;
  pageSize?: FetchAccountTypesIndexPageSize;
  filters?: FetchAccountTypesIndexFilters;
  sort?: FetchAccountTypesIndexSort;
}

interface FetchAccountTypeRequestParams {
  query: FetchAccountTypeQuery;
}

interface FetchAccountTypeIndexRequestParams {
  queryName: FetchAccountTypeIndexQueryName;
}

export interface AccountTypeRequestData {
  name?: AccountTypeName;
}

export interface CreateAccountTypeRequestData extends AccountTypeRequestData {
  id?: AccountTypeId;
  userId: AccountTypeUserId;
  nanoId: AccountTypeNanoId;
}

export interface UpdateAccountTypeRequestData extends AccountTypeRequestData {
  name?: AccountTypeName;
}

export interface DeleteAccountTypeRequestData {
  accountTypeNanoId: AccountTypeNanoId;
}

export interface CreateAccountTypeErrorResponse {
  message: CreateAccountTypeError;
}

export interface UpdateAccountTypeErrorResponse {
  message: UpdateAccountTypeError;
}

export interface DeleteAccountTypeErrorResponse {
  message: DeleteAccountTypeError;
}

export class AccountTypesBffRequests extends BaseBffRequests {
  static fetchAccountTypes<T>(params: FetchAccountTypesRequestParams) {
    return this.fetchItems<T>(
      AccountTypesBffRoutes.fetchAccountTypesRoute(),
      params
    );
  }

  static fetchAccountTypesIndex<T>(
    params: FetchAccountTypesIndexRequestParams
  ) {
    return this.fetchItemsIndex<T>(
      AccountTypesBffRoutes.fetchAccountTypesIndexRoute(),
      params
    );
  }

  static fetchAccountType<T>(
    accountTypeId: AccountTypeId,
    params: FetchAccountTypeRequestParams
  ) {
    return this.fetchItem<T>(
      AccountTypesBffRoutes.fetchAccountTypeRoute(accountTypeId),
      params
    );
  }

  static fetchAccountTypeIndex<T>(
    accountTypeNanoId: AccountTypeNanoId,
    params: FetchAccountTypeIndexRequestParams
  ) {
    return this.fetchItemIndex<T>(
      AccountTypesBffRoutes.fetchAccountTypeIndexRoute(accountTypeNanoId),
      params
    );
  }

  static fetchAccountTypeByNanoId<T>(
    accountTypeNanoId: AccountTypeNanoId,
    params: FetchAccountTypeRequestParams
  ) {
    return this.fetchItemByNanoId<T>(
      AccountTypesBffRoutes.fetchAccountTypeByNanoIdRoute(accountTypeNanoId),
      params
    );
  }

  static createAccountType<T>(
    createAccountTypeData: CreateAccountTypeRequestData
  ) {
    return this.createItem<T, CreateAccountTypeRequestData>(
      AccountTypesBffRoutes.createAccountTypeRoute(),
      createAccountTypeData
    );
  }

  static async updateAccountType<T>(
    updateAccountTypeNanoId: AccountTypeNanoId,
    updateAccountTypeData: UpdateAccountTypeRequestData
  ) {
    return this.updateItem<T, UpdateAccountTypeRequestData>(
      AccountTypesBffRoutes.updateAccountTypeRoute(updateAccountTypeNanoId),
      updateAccountTypeData
    );
  }

  static async deleteAccountType<T>(
    deleteAccountTypeNanoId: AccountTypeNanoId
  ) {
    return this.deleteItem<T>(
      AccountTypesBffRoutes.deleteAccountTypeRoute(deleteAccountTypeNanoId)
    );
  }
}
