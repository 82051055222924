import { usePaginatedItems } from '../../../common/hooks/usePaginatedItems';

import {
  FetchCompanyMemberInvitationsQuery,
  FetchCompanyMemberInvitationsCacheKey,
  FetchCompanyMemberInvitationsTotalCount,
  FetchCompanyMemberInvitationsPage,
  FetchCompanyMemberInvitationsPageSize,
  FetchCompanyMemberInvitationsError,
  FetchCompanyMemberInvitationsErrorMessage,
  FetchCompanyMemberInvitationsIsLoading,
  FetchCompanyMemberInvitationsIsFetched,
  FetchCompanyMemberInvitationsIsPlaceholderData,
  FetchCompanyMemberInvitationsEnabled,
  FetchCompanyMemberInvitationsPrefetchNextPage,
  FetchCompanyMemberInvitationsRefetchInterval,
  FetchCompanyMemberInvitationsCountType,
  FetchCompanyMemberInvitationsFilters,
  FetchCompanyMemberInvitationsSort,
  FetchCompanyMemberInvitationID,
  FetchCompanyMemberInvitationQuery,
  FetchCompanyMemberInvitationCacheKey
} from '../../companyMemberInvitationsTypes';

import { CompanyMemberInvitationsBffRequests } from '../../CompanyMemberInvitationsBffRequests';

interface PaginatedCompanyMemberInvitationsBaseOptions<
  PaginatedCompanyMemberInvitationsResponseType
> {
  cacheKey: FetchCompanyMemberInvitationsCacheKey;
  query: FetchCompanyMemberInvitationsQuery;
  countType?: FetchCompanyMemberInvitationsCountType;
  initialPage?: FetchCompanyMemberInvitationsPage;
  initialPageSize?: FetchCompanyMemberInvitationsPageSize;
  initialFilters?: FetchCompanyMemberInvitationsFilters;
  initialSort?: FetchCompanyMemberInvitationsSort;
  enabled?: FetchCompanyMemberInvitationsEnabled;
  prefetchNextPage?: FetchCompanyMemberInvitationsPrefetchNextPage;
  refetchInterval?: FetchCompanyMemberInvitationsRefetchInterval;
  placeholderCompanyMemberInvitations?: PaginatedCompanyMemberInvitationsResponseType[];
  placeholderCompanyMemberInvitationsCount?: FetchCompanyMemberInvitationsTotalCount;
  placeholderData?: () => PaginatedCompanyMemberInvitationsResponse<PaginatedCompanyMemberInvitationsResponseType>;
}

interface PaginatedCompanyMemberInvitationsOptionsWithoutItem {
  fetchItemCacheKey?: never;
  itemQuery?: never;
}

interface PaginatedCompanyMemberInvitationsOptionsWithItem {
  fetchItemCacheKey: FetchCompanyMemberInvitationCacheKey;
  itemQuery: FetchCompanyMemberInvitationQuery;
}

type PaginatedCompanyMemberInvitationsOptions<
  PaginatedCompanyMemberInvitationsResponseType
> = PaginatedCompanyMemberInvitationsBaseOptions<PaginatedCompanyMemberInvitationsResponseType> &
  (
    | PaginatedCompanyMemberInvitationsOptionsWithoutItem
    | PaginatedCompanyMemberInvitationsOptionsWithItem
  );

interface PaginatedCompanyMemberInvitationsResponse<
  PaginatedCompanyMemberInvitationsResponseType
> {
  data: PaginatedCompanyMemberInvitationsResponseType[];
  totalCount: FetchCompanyMemberInvitationsTotalCount;
}

function usePaginatedCompanyMemberInvitations<
  PaginatedCompanyMemberInvitationsResponseType
>({
  cacheKey,
  query,
  countType,
  initialPage,
  initialPageSize,
  initialFilters,
  initialSort,
  enabled,
  prefetchNextPage,
  refetchInterval,
  placeholderCompanyMemberInvitations,
  placeholderCompanyMemberInvitationsCount,
  placeholderData,
  fetchItemCacheKey,
  itemQuery
}: PaginatedCompanyMemberInvitationsOptions<PaginatedCompanyMemberInvitationsResponseType>) {
  const {
    items,
    itemsTotalCount,
    itemsError,
    itemsErrorMessage,
    itemsIsFetched,
    itemsIsLoading,
    itemsIsPlaceholderData,
    itemsCurrentPage,
    itemsCurrentPageSize,
    itemsCurrentFilters,
    itemsCurrentSort,
    fetchItems,
    paginateItems,
    changeItemsPageSize,
    filterItems,
    changeItemsFilters,
    clearItemsFilters,
    sortItems,
    prefetchItems,
    prefetchItem
  } = usePaginatedItems<PaginatedCompanyMemberInvitationsResponseType>({
    cacheKey,
    initialPage,
    initialPageSize,
    initialFilters,
    initialSort,
    enabled,
    prefetchNextPage,
    refetchInterval,
    placeholderItems: placeholderCompanyMemberInvitations,
    placeholderItemsCount: placeholderCompanyMemberInvitationsCount,
    placeholderData,
    queryFn: (params) =>
      CompanyMemberInvitationsBffRequests.fetchCompanyMemberInvitations<PaginatedCompanyMemberInvitationsResponseType>(
        {
          query,
          countType,
          page: params.page as FetchCompanyMemberInvitationsPage,
          pageSize: params.pageSize as FetchCompanyMemberInvitationsPageSize,
          filters: params.filters as FetchCompanyMemberInvitationsFilters,
          sort: params.sort as FetchCompanyMemberInvitationsSort
        }
      ),
    ...(itemQuery
      ? {
          fetchItemCacheKey,
          queryItemFn: (itemId) =>
            CompanyMemberInvitationsBffRequests.fetchCompanyMemberInvitation(
              itemId as FetchCompanyMemberInvitationID,
              {
                query: itemQuery
              }
            )
        }
      : {})
  });

  return {
    companyMemberInvitations:
      items as PaginatedCompanyMemberInvitationsResponseType[],
    companyMemberInvitationsTotalCount:
      itemsTotalCount as FetchCompanyMemberInvitationsTotalCount,
    companyMemberInvitationsError:
      itemsError as FetchCompanyMemberInvitationsError,
    companyMemberInvitationsErrorMessage:
      itemsErrorMessage as FetchCompanyMemberInvitationsErrorMessage,
    companyMemberInvitationsIsLoading:
      itemsIsLoading as FetchCompanyMemberInvitationsIsLoading,
    companyMemberInvitationsIsFetched:
      itemsIsFetched as FetchCompanyMemberInvitationsIsFetched,
    companyMemberInvitationsIsPlaceholderData:
      itemsIsPlaceholderData as FetchCompanyMemberInvitationsIsPlaceholderData,
    companyMemberInvitationsCurrentPage:
      itemsCurrentPage as FetchCompanyMemberInvitationsPage,
    companyMemberInvitationsCurrentPageSize:
      itemsCurrentPageSize as FetchCompanyMemberInvitationsPageSize,
    companyMemberInvitationsCurrentFilters:
      itemsCurrentFilters as FetchCompanyMemberInvitationsFilters,
    companyMemberInvitationsCurrentSort:
      itemsCurrentSort as FetchCompanyMemberInvitationsSort,
    fetchCompanyMemberInvitations: fetchItems,
    paginateCompanyMemberInvitations: paginateItems,
    changeCompanyMemberInvitationsPageSize: changeItemsPageSize,
    filterCompanyMemberInvitations: filterItems,
    changeCompanyMemberInvitationsFilters: changeItemsFilters,
    clearCompanyMemberInvitationsFilters: clearItemsFilters,
    sortCompanyMemberInvitations: sortItems,
    prefetchCompanyMemberInvitations: prefetchItems,
    prefetchCompanyMemberInvitation: prefetchItem
  };
}

export default usePaginatedCompanyMemberInvitations;
