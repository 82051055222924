import React from 'react';
import { Text } from '../../../../../../../../helpers/Text';
import { IconButton } from '../../../../../../../../helpers/buttons/IconButton';
import { Flex, Tooltip } from '@chakra-ui/react';
import { useViewJobCandidateDetailsTasksWidgetContext } from '../../../../contexts/ViewJobCandidateDetailsTasksWidgetContext';
import { CalendarIcon } from '../../../../../../../../icons/CalendarIcon';
import { CheckIcon } from '../../../../../../../../icons/CheckIcon';
import { EmailIcon } from '../../../../../../../../icons/EmailIcon';
import { PhoneIcon } from '../../../../../../../../icons/PhoneIcon';
import { ListItemIcon } from '../../../../../../../../icons/ListItemIcon';

function ViewJobCandidateDetailsSelectedTaskTool() {
  const {
    stageTasks,
    selectedTask,
    selectedTaskAction,
    handleReopenTaskAction,
    handleCompleteTaskAction
  } = useViewJobCandidateDetailsTasksWidgetContext();

  const currentTask = stageTasks.find((task) => task.nanoId === selectedTask);

  const isTaskActionCompleted = currentTask?.status === 'completed';
  const currentTaskCompletedAction = currentTask?.completed;

  const isPhone = isTaskActionCompleted
    ? currentTaskCompletedAction?.split('__').includes('phone')
    : selectedTaskAction?.split('__').includes('phone');
  const isEmail = isTaskActionCompleted
    ? currentTaskCompletedAction?.split('__').includes('email')
    : selectedTaskAction?.split('__').includes('email');
  const isCalendar = isTaskActionCompleted
    ? currentTaskCompletedAction?.split('__').includes('calendar')
    : selectedTaskAction?.split('__').includes('calendar');
  const isTasks = isTaskActionCompleted
    ? currentTaskCompletedAction?.split('__').includes('tasks')
    : selectedTaskAction?.split('__').includes('tasks');

  return (
    <Flex w="full" flex={1} alignItems="center" gap={1.5} cursor="pointer">
      <Flex w={4} h={4} alignItems="center" justifyContent="center">
        {isPhone && <PhoneIcon w={3} h={3} fill="#287CC9" />}

        {isEmail && <EmailIcon w={4} h={4} fill="#1DC67D" />}

        {isCalendar && <CalendarIcon w={4} h={4} fill="#507D7B" />}

        {isTasks && <ListItemIcon w={4} h={4} fill="#AB56B2" />}
      </Flex>

      <Text
        flex={1}
        noOfLines={1}
        color="inherit"
        whiteSpace="nowrap"
        fontWeight="medium"
        wordBreak="break-all"
        textOverflow="ellipsis"
        textTransform="capitalize"
        display="inline-block"
      >
        {currentTask?.name}
      </Text>

      <Tooltip
        hasArrow
        textTransform="capitalize"
        label={isTaskActionCompleted ? 'Mark as Open' : undefined}
      >
        <IconButton
          h={4}
          w={4}
          minW={0}
          minH={0}
          onClick={(e) => {
            e.stopPropagation();

            if (selectedTask) {
              isTaskActionCompleted
                ? handleReopenTaskAction(selectedTask)
                : handleCompleteTaskAction(selectedTask);
            }
          }}
          display="flex"
          aria-label=""
          hierarchy="unstyled"
          borderRadius="full"
          alignItems="center"
          justifyContent="center"
          icon={
            isTaskActionCompleted ? (
              <CheckIcon
                w={3.5}
                h={3.5}
                fill="primary.500"
                pointerEvents="none"
              />
            ) : undefined
          }
          border="1px solid"
          borderColor={isTaskActionCompleted ? 'primary.500' : 'gray.200'}
          bg={isTaskActionCompleted ? 'primary.500' : 'transparent'}
        />
      </Tooltip>
    </Flex>
  );
}

export default ViewJobCandidateDetailsSelectedTaskTool;
