import {
  FieldBrand,
  ItemID,
  ItemUUID,
  ItemNanoId,
  ItemCreatedAt,
  MayBe,
  ItemUpdatedAt
} from '../../../types';
import {
  CandidateShareInvitationAccountTypeId,
  CandidateShareInvitationEmail,
  CandidateShareInvitationId,
  CandidateShareInvitationName
} from '../../candidateShareInvitations/candidateShareInvitationsTypes';
import {
  CompanyMemberInvitationAccountTypeId,
  CompanyMemberInvitationEmail,
  CompanyMemberInvitationId,
  CompanyMemberInvitationName
} from '../../companyMemberInvitations/companyMemberInvitationsTypes';
import {
  JobCandidateId,
  JobCandidateName
} from '../../jobCandidates/jobCandidatesTypes';
import { JobId } from '../../jobs/jobsTypes';
import {
  MayBeRecipientImage,
  MayBeRecipientImageId,
  RecipientId,
  RecipientName
} from '../../recipients/recipientsTypes';

import {
  UserId,
  UserNanoId,
  UserName,
  UserFullName,
  UserEmail,
  UserImage,
  UserRoles
} from '../../users/usersTypes';

export type CandidateShareId = FieldBrand<ItemID, 'CandidateShareId'>;
export type CandidateShareUuid = FieldBrand<ItemUUID, 'CandidateShareUuid'>;
export type MayBeCandidateShareUuid = MayBe<CandidateShareUuid>;
export type CandidateShareNanoId = FieldBrand<
  ItemNanoId,
  'CandidateShareNanoId'
>;
export type MayBeCandidateShareNanoId = MayBe<CandidateShareNanoId>;
export type CandidateShareUserId = FieldBrand<UserId, 'CandidateShareUserId'>;
export type CandidateShareUser = FieldBrand<
  {
    id: UserId;
    nanoId: UserNanoId;
    name: UserName;
    fullName: UserFullName;
    email: UserEmail;
    image: UserImage;
    roles: UserRoles;
  },
  'CandidateShareUser'
>;
export type CandidateShareStatus = FieldBrand<string, 'CandidateShareStatus'>;
export type CandidateShareMessage = FieldBrand<string, 'CandidateShareMessage'>;
export type CandidateShareAutoJoin = FieldBrand<
  CandidateShareAutoJoinEnums,
  'CandidateShareAutoJoin'
>;
export type CandidateShareJobId = FieldBrand<JobId, 'CandidateShareJobId'>;
export type CandidateShareShareSettings = FieldBrand<
  {
    currentCompany?: boolean;
    assessment?: boolean;
    resume?: boolean;
    projects?: boolean;
    interview?: boolean;
    candidateName?: boolean;
    jobTitle?: boolean;
    email?: boolean;
    phoneNumber?: boolean;
    photos?: boolean;
    location?: boolean;
    linkedinUrl?: boolean;
    education?: boolean;
    certification?: boolean;
    skills?: boolean;
    about?: boolean;
    jobFunction?: boolean;
    workAuthorization?: boolean;
    specialization?: boolean;
    salary?: boolean;
  },
  'CandidateShareShareSettings'
>;
export type CandidateShareJobCandidateIds = FieldBrand<
  JobCandidateId[],
  'CandidateShareJobCandidateIds'
>;
export type CandidateShareJobCandidates = FieldBrand<
  { id: JobCandidateId; name: JobCandidateName }[],
  'CandidateShareJobCandidateIds'
>;
export type CandidateShareRecipientIds = FieldBrand<
  RecipientId[],
  'CandidateShareRecipientIds'
>;
export type CandidateShareRecipient = FieldBrand<
  {
    id: RecipientId;
    name: RecipientName;
    image: MayBeRecipientImage;
    imageId: MayBeRecipientImageId;
  },
  'CandidateShareRecipient'
>;
export type CandidateShareRecipients = FieldBrand<
  CandidateShareRecipient[],
  'CandidateShareRecipients'
>;
export type CandidateShareCandidateShareInvitation = FieldBrand<
  {
    name: CandidateShareInvitationName;
    email: CandidateShareInvitationEmail;
    accountTypeId: CandidateShareInvitationAccountTypeId;
    id?: CandidateShareInvitationId;
  },
  'CandidateShareCandidateShareInvitation'
>;
export type CandidateShareCandidateShareInvitations = FieldBrand<
  {
    name: CandidateShareInvitationName;
    email: CandidateShareInvitationEmail;
    accountTypeId: CandidateShareInvitationAccountTypeId;
    id?: CandidateShareInvitationId;
  }[],
  'CandidateShareCandidateShareInvitations'
>;
export type CandidateShareCompanyMemberInvitation = FieldBrand<
  {
    name: CompanyMemberInvitationName;
    email: CompanyMemberInvitationEmail;
    accountTypeId: CompanyMemberInvitationAccountTypeId;
    id?: CompanyMemberInvitationId;
  },
  'CandidateShareCompanyMemberInvitation'
>;
export type CandidateShareCompanyMemberInvitations = FieldBrand<
  CandidateShareCompanyMemberInvitation[],
  'CandidateShareCompanyMemberInvitations'
>;
export type CandidateShareCreatedAt = FieldBrand<
  ItemCreatedAt,
  'CandidateShareCreatedAt'
>;
export type CandidateShareUpdatedAt = FieldBrand<
  ItemUpdatedAt,
  'CandidateShareUpdatedAt'
>;

export const enum CandidateShareAutoJoinEnums {
  NONE = 'none',
  ENABLED = 'enabled',
  DISABLED = 'disabled'
}

export const enum CandidateShareFields {
  ID = 'id',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  JOB_ID = 'jobId',
  AUTO_JOIN = 'autoJoin',
  STATUS = 'status',
  MESSAGE = 'message',
  SHARE_SETTINGS = 'shareSettings',
  USER_ID = 'userId',
  USER = 'user',
  JOB_CANDIDATE_IDS = 'jobCandidateIds',
  JOB_CANDIDATES = 'jobCandidates',
  RECIPIENT_IDS = 'recipientIds',
  RECIPIENTS = 'recipients',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  CANDIDATE_SHARE_INVITATIONS = 'candidateShareInvitations',
  COMPANY_MEMBER_INVITATIONS = 'companyMemberInvitations'
}
