import React, { useCallback } from 'react';

import { FetchIndustryExpertisesCacheKey } from '../../industryExpertisesTypes';

import { FetchIndustryExpertisesResponse } from '../../queries/fetchIndustryExpertises.query';

import { useDeleteIndustryExpertise } from '../../hooks/useDeleteIndustryExpertise';

import { IndustryExpertise } from '../../helpers/IndustryExpertise';

interface IndustryExpertiseWrapperProps {
  industryExpertise: FetchIndustryExpertisesResponse;
  isEditing: boolean;
  industryExpertisesCacheKey: FetchIndustryExpertisesCacheKey;
  onEdit: (industryExpertise: FetchIndustryExpertisesResponse) => void;
  withoutPortal?: boolean;
  withoutDeleteButton?: boolean;
  allowEditing?: boolean;
}

function IndustryExpertiseWrapper({
  industryExpertise,
  isEditing,
  industryExpertisesCacheKey,
  onEdit,
  withoutPortal,
  withoutDeleteButton,
  allowEditing
}: IndustryExpertiseWrapperProps) {
  const { deleteIndustryExpertiseIsLoading, deleteIndustryExpertise } =
    useDeleteIndustryExpertise({
      industryExpertiseId: industryExpertise.id,
      cacheKeys: [industryExpertisesCacheKey]
    });

  const handleEdit = useCallback<() => void>(
    () => onEdit(industryExpertise),
    [industryExpertise, onEdit]
  );

  const handleDeleteIndustryExpertise = useCallback<() => void>(
    () => deleteIndustryExpertise({}),
    [deleteIndustryExpertise]
  );

  return (
    <IndustryExpertise
      name={industryExpertise.industry?.name || null}
      experience={industryExpertise.experience}
      requirements={industryExpertise.requirements}
      isEditing={isEditing || deleteIndustryExpertiseIsLoading}
      onEdit={handleEdit}
      onDelete={handleDeleteIndustryExpertise}
      withoutPortal={withoutPortal}
      withoutDeleteButton={withoutDeleteButton}
      allowEditing={allowEditing}
    />
  );
}

export default IndustryExpertiseWrapper;
