import compact from 'lodash/compact';

import { CityName, CityLabel } from '../../../cities/citiesTypes';

import { StateIsoCode } from '../../../states/statesTypes';

interface RenderLocationProps {
  state?: {
    isoCode: StateIsoCode;
  } | null;
  city: {
    name: CityName;
    label: CityLabel;
  } | null;
}

function renderLocation(item: RenderLocationProps) {
  const result = item.city?.label
    ? item.city?.label
    : compact([item.city?.name, item.state?.isoCode]).join(', ');

  return result === '' ? null : result;
}

export default renderLocation;
