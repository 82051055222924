import {
  FetchCompanyMemberInvitationsCacheKey,
  FetchCompanyMemberInvitationCacheKey
} from './companyMemberInvitationsTypes';

export class CompanyMemberInvitationsCache {
  static indexCacheKey() {
    return 'companyMemberInvitations' as FetchCompanyMemberInvitationsCacheKey;
  }

  static showCacheKey() {
    return 'companyMemberInvitation' as FetchCompanyMemberInvitationCacheKey;
  }
}
