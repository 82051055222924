import { FetchCitiesCacheKey, FetchCityCacheKey } from './citiesTypes';

export class CitiesCache {
  static indexCacheKey() {
    return 'cities' as FetchCitiesCacheKey;
  }

  static showCacheKey() {
    return 'city' as FetchCityCacheKey;
  }

  static signUpCacheKey() {
    return 'cities-sign-up' as FetchCitiesCacheKey;
  }

  static postJobCacheKey() {
    return 'cities-post-job' as FetchCitiesCacheKey;
  }

  static talentPoolFormCacheKey() {
    return 'cities-talent-pool-form' as FetchCitiesCacheKey;
  }

  static companyFormCacheKey() {
    return 'cities-company-form' as FetchCitiesCacheKey;
  }
}
