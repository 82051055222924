import { ButtonGroup, Stack } from '@chakra-ui/react';
import { Button } from '../../../../../../../../../../../helpers/Button';
import { DateInputField } from '../../../../../../../../../../../helpers/forms/formFields/DateInputField';

type ViewJobCandidateModalPhoneSidebarChooseAvailabilityDateProps = {
  onDiscard: () => void;
  onSave: () => void;
};

const ViewJobCandidateModalPhoneSidebarChooseAvailabilityDate = ({
  onDiscard,
  onSave
}: ViewJobCandidateModalPhoneSidebarChooseAvailabilityDateProps) => {
  return (
    <Stack spacing={3}>
      <DateInputField label="Date" bg="white" labelFontSize="sm" type="date" />

      <DateInputField label="Time" bg="white" labelFontSize="sm" type="time" />

      <ButtonGroup justifyContent="flex-end">
        <Button size="small" hierarchy="link" onClick={onDiscard}>
          Cancel
        </Button>

        <Button size="small" onClick={onSave}>
          Save
        </Button>
      </ButtonGroup>
    </Stack>
  );
};

export default ViewJobCandidateModalPhoneSidebarChooseAvailabilityDate;
