import get from 'lodash/get';
import * as Sentry from '@sentry/nextjs';
import { AxiomAPIRequest } from 'next-axiom';

function processError(
  err: unknown,
  req: AxiomAPIRequest | null = null
): {
  status: number;
  data: unknown;
} {
  if (get(err, 'response.status') === 404) {
    return {
      status: 404,
      data: get(err, 'response.data')
    };
  } else if (get(err, 'response.status') === 400) {
    return {
      status: 400,
      data: get(err, 'response.data')
    };
  } else if (get(err, 'response.status') === 403) {
    return {
      status: 403,
      data: get(err, 'response.data')
    };
  } else if (get(err, 'response.status') === 422) {
    return {
      status: 422,
      data: get(err, 'response.data')
    };
  } else if (get(err, 'response.status') === 502) {
    return {
      status: 502,
      data: get(err, 'response.data')
    };
  } else {
    Sentry.captureException(err);
    req?.log?.error('processError', { err, body: req.body });

    return {
      status: 500,
      data: {
        error: get(err, 'message'),
        message:
          get(err, 'response.data.message') || get(err, 'response.data.Value')
      }
    };
  }
}

export default processError;
