import {
  FetchCompanyMemberInvitationsQuery,
  FetchCompanyMemberInvitationsCountType,
  FetchCompanyMemberInvitationsPage,
  FetchCompanyMemberInvitationsPageSize,
  FetchCompanyMemberInvitationsFilters,
  FetchCompanyMemberInvitationsSort,
  FetchCompanyMemberInvitationsIndexQueryName,
  FetchCompanyMemberInvitationsIndexCountType,
  FetchCompanyMemberInvitationsIndexPage,
  FetchCompanyMemberInvitationsIndexPageSize,
  FetchCompanyMemberInvitationsIndexFilters,
  FetchCompanyMemberInvitationsIndexSort,
  FetchCompanyMemberInvitationQuery,
  FetchCompanyMemberInvitationIndexQueryName,
  CreateCompanyMemberInvitationError,
  UpdateCompanyMemberInvitationError,
  DeleteCompanyMemberInvitationError,
  CompanyMemberInvitationId,
  CompanyMemberInvitationNanoId,
  CompanyMemberInvitationName,
  CompanyMemberInvitationEmail,
  MayBeCompanyMemberInvitationAccountTypeId,
  CompanyMemberInvitationCompanyId,
  SendCompanyMemberInvitationError
} from './companyMemberInvitationsTypes';

import { BaseBffRequests } from '../BaseBffRequests';

import { CompanyMemberInvitationsBffRoutes } from './CompanyMemberInvitationsBffRoutes';

interface FetchCompanyMemberInvitationsRequestParams {
  query: FetchCompanyMemberInvitationsQuery;
  countType?: FetchCompanyMemberInvitationsCountType;
  page?: FetchCompanyMemberInvitationsPage;
  pageSize?: FetchCompanyMemberInvitationsPageSize;
  filters?: FetchCompanyMemberInvitationsFilters;
  sort?: FetchCompanyMemberInvitationsSort;
}

interface FetchCompanyMemberInvitationsIndexRequestParams {
  queryName: FetchCompanyMemberInvitationsIndexQueryName;
  countType?: FetchCompanyMemberInvitationsIndexCountType;
  page?: FetchCompanyMemberInvitationsIndexPage;
  pageSize?: FetchCompanyMemberInvitationsIndexPageSize;
  filters?: FetchCompanyMemberInvitationsIndexFilters;
  sort?: FetchCompanyMemberInvitationsIndexSort;
}

interface FetchCompanyMemberInvitationRequestParams {
  query: FetchCompanyMemberInvitationQuery;
}

interface FetchCompanyMemberInvitationIndexRequestParams {
  queryName: FetchCompanyMemberInvitationIndexQueryName;
}

export interface CompanyMemberInvitationRequestData {
  name?: CompanyMemberInvitationName;
  email?: CompanyMemberInvitationEmail;
  accountTypeId?: MayBeCompanyMemberInvitationAccountTypeId;
}

export interface CreateCompanyMemberInvitationDefaultRequestData
  extends CompanyMemberInvitationRequestData {
  id?: CompanyMemberInvitationId;
  nanoId: CompanyMemberInvitationNanoId;
  companyId: CompanyMemberInvitationCompanyId;
}

export type CreateCompanyMemberInvitationRequestData =
  CreateCompanyMemberInvitationDefaultRequestData;

export type UpdateCompanyMemberInvitationRequestData =
  CompanyMemberInvitationRequestData;

export interface DeleteCompanyMemberInvitationRequestData {
  companyMemberInvitationNanoId: CompanyMemberInvitationNanoId;
}

export interface CreateCompanyMemberInvitationErrorResponse {
  message: CreateCompanyMemberInvitationError;
}

export interface UpdateCompanyMemberInvitationErrorResponse {
  message: UpdateCompanyMemberInvitationError;
}

export interface DeleteCompanyMemberInvitationErrorResponse {
  message: DeleteCompanyMemberInvitationError;
}

export interface SendCompanyMemberInvitationErrorResponse {
  message: SendCompanyMemberInvitationError;
}

export class CompanyMemberInvitationsBffRequests extends BaseBffRequests {
  static fetchCompanyMemberInvitations<T>(
    params: FetchCompanyMemberInvitationsRequestParams
  ) {
    return this.fetchItems<T>(
      CompanyMemberInvitationsBffRoutes.fetchCompanyMemberInvitationsRoute(),
      params
    );
  }

  static fetchCompanyMemberInvitationsIndex<T>(
    params: FetchCompanyMemberInvitationsIndexRequestParams
  ) {
    return this.fetchItemsIndex<T>(
      CompanyMemberInvitationsBffRoutes.fetchCompanyMemberInvitationsIndexRoute(),
      params
    );
  }

  static fetchCompanyMemberInvitation<T>(
    companyMemberInvitationId: CompanyMemberInvitationId,
    params: FetchCompanyMemberInvitationRequestParams
  ) {
    return this.fetchItem<T>(
      CompanyMemberInvitationsBffRoutes.fetchCompanyMemberInvitationRoute(
        companyMemberInvitationId
      ),
      params
    );
  }

  static fetchCompanyMemberInvitationIndex<T>(
    companyMemberInvitationNanoId: CompanyMemberInvitationNanoId,
    params: FetchCompanyMemberInvitationIndexRequestParams
  ) {
    return this.fetchItemIndex<T>(
      CompanyMemberInvitationsBffRoutes.fetchCompanyMemberInvitationIndexRoute(
        companyMemberInvitationNanoId
      ),
      params
    );
  }

  static fetchCompanyMemberInvitationByNanoId<T>(
    companyMemberInvitationNanoId: CompanyMemberInvitationNanoId,
    params: FetchCompanyMemberInvitationRequestParams
  ) {
    return this.fetchItemByNanoId<T>(
      CompanyMemberInvitationsBffRoutes.fetchCompanyMemberInvitationByNanoIdRoute(
        companyMemberInvitationNanoId
      ),
      params
    );
  }

  static createCompanyMemberInvitation<T>(
    createCompanyMemberInvitationData: CreateCompanyMemberInvitationRequestData
  ) {
    return this.createItem<T, CreateCompanyMemberInvitationRequestData>(
      CompanyMemberInvitationsBffRoutes.createCompanyMemberInvitationRoute(),
      createCompanyMemberInvitationData
    );
  }

  static async updateCompanyMemberInvitation<T>(
    updateCompanyMemberInvitationNanoId:
      | CompanyMemberInvitationNanoId
      | CompanyMemberInvitationId,
    updateCompanyMemberInvitationData: UpdateCompanyMemberInvitationRequestData
  ) {
    return this.updateItem<T, UpdateCompanyMemberInvitationRequestData>(
      CompanyMemberInvitationsBffRoutes.updateCompanyMemberInvitationRoute(
        updateCompanyMemberInvitationNanoId
      ),
      updateCompanyMemberInvitationData
    );
  }

  static async deleteCompanyMemberInvitation<T>(
    deleteCompanyMemberInvitationNanoId:
      | CompanyMemberInvitationNanoId
      | CompanyMemberInvitationId
  ) {
    return this.deleteItem<T>(
      CompanyMemberInvitationsBffRoutes.deleteCompanyMemberInvitationRoute(
        deleteCompanyMemberInvitationNanoId
      )
    );
  }

  static async sendCompanyMemberInvitation<T>(
    sendCompanyMemberInvitationNanoId: CompanyMemberInvitationNanoId
  ) {
    return this.postItem<T, Record<string, unknown>>(
      CompanyMemberInvitationsBffRoutes.sendCompanyMemberInvitationRoute(
        sendCompanyMemberInvitationNanoId
      ),
      {}
    );
  }
}
