import { ReactNode } from 'react';
import { JobCandidateNanoId } from '../../../../../jobCandidates/jobCandidatesTypes';

export type ViewJobCandidateDetailsSendAssessmentContextType = {
  totalSteps: number;
  currentStep: number;
  isLastStep: boolean;
  isFirstStep: boolean;
  goToNextStep: () => void;
  goToPrevStep: () => void;
  sendPhoneAssessment: () => void;
  sendEmailAssessment: () => void;
  isEmailAssessmentSent: boolean;
  isSendEmailAssessmentOpen: boolean;
  isSendPhoneAssessmentOpen: boolean;
  openEmailAssessmentSent: () => void;
  cancelEmailAssessmentSent: () => void;
  openSendEmailAssessment: () => void;
  closeSendEmailAssessment: () => void;
  closeSendPhoneAssessment: () => void;
  openSendPhoneAssessment: () => void;
  assessmentFields: ViewJobCandidateDetailsSendAssessmentFields[];
  handleAssessmentField: (
    field: ViewJobCandidateDetailsSendAssessmentFields
  ) => void;
};

export type ViewJobCandidateDetailsSendAssessmentProviderProps = {
  jobCandidateNanoId: JobCandidateNanoId;
  children: ReactNode;
};

export enum ViewJobCandidateDetailsSendAssessmentFields {
  AssessmentPreferences = 'AssessmentPreferences',
  AssessmentPreferencesEmployment = 'AssessmentPreferencesEmployment',
  AssessmentPreferencesWorkplace = 'AssessmentPreferencesWorkplace',
  AssessmentPreferencesPreferredLocation = 'AssessmentPreferencesPreferredLocation',
  AssessmentPreferencesSalary = 'AssessmentPreferencesSalary',

  AssessmentQualifications = 'AssessmentQualifications',
  AssessmentQualificationsAbout = 'AssessmentQualificationsAbout',
  AssessmentQualificationsJobFunction = 'AssessmentQualificationsJobFunction',
  AssessmentQualificationsWorkAuthorization = 'AssessmentQualificationsWorkAuthorization',
  AssessmentQualificationsIndustry = 'AssessmentQualificationsIndustry',
  AssessmentQualificationsSpecialization = 'AssessmentQualificationsSpecialization',
  AssessmentQualificationsIndustryExperience = 'AssessmentQualificationsIndustryExperience',
  AssessmentQualificationsCareerPath = 'AssessmentQualificationsCareerPath',

  AssessmentSkillsExpertise = 'AssessmentSkillsExpertise',
  AssessmentSkillsExpertiseComputerSkills = 'AssessmentSkillsExpertiseComputerSkills',
  AssessmentSkillsExpertiseIndustryKnowledge = 'AssessmentSkillsExpertiseIndustryKnowledge',

  AssessmentQuestions = 'AssessmentQuestions',
  AssessmentProjects = 'AssessmentProjects',
  AssessmentReferences = 'AssessmentReferences',
  AssessmentUpdatedResume = 'AssessmentUpdatedResume'
}
