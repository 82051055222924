import { ReactNode, useCallback } from 'react';
import { useRouter } from 'next/router';
import { Show, useMediaQuery } from '@chakra-ui/react';
import { Box, Grid, GridItem } from '@chakra-ui/layout';

import {
  ControlPanelProvider,
  MainActiveLink,
  useLeftSidebarContext
} from '../../context';

import { useSubscribeToPreviousRouter } from '../../common/hooks/utils/usePreviousRouter';

import { CompaniesRoutes } from '../../main/companies/CompaniesRoutes';
import { ControlPanelRoutes } from '../../main/control-panel/ControlPanelRoutes';
import { ControlPanelJobsRoutes } from '../../main/control-panel/pages/Jobs/ControlPanelJobsRoutes';
import { ControlPanelPeopleRoutes } from '../../main/control-panel/pages/People/ControlPanelPeopleRoutes';

import { ControlPanelPermissions } from '../../main/control-panel/ControlPanelPermissions';

import { CandidatesIcon } from '../../icons/CandidatesIcon';
import { CompaniesIcon } from '../../icons/CompaniesIcon';
import { DashboardIcon } from '../../icons/DashboardIcon';
import { FilesIcon } from '../../icons/FilesIcon';
import { HelpIcon } from '../../icons/HelpIcon';
import { JobsIcon } from '../../icons/JobsIcon';
import { RecruitersIcon } from '../../icons/RecruitersIcon';
import { SettingsIcon } from '../../icons/SettingsIcon';
import { UserManagementIcon } from '../../icons/UserManagementIcon';

import { CheckPermissionLayout } from '../../main/common/layouts/CheckPermissionLayout';

import { Header } from './components/Header';
import { LeftSidebar } from './components/LeftSidebar';
import { RightSidebar } from './components/RightSidebar';

import { SidebarMenuItem } from '../../helpers/SidebarMenuItem';

import { ControlPanelLayoutRequiredProps } from './ControlPanelLayout.types';
import { ControlPanelSettingsRoutes } from '../../main/control-panel/pages/Settings/ControlPanelSettingsRoutes';

interface ControlPanelLayoutProps {
  action?: string;
  children: ReactNode;
  activeLink?: MainActiveLink;
}

function ControlPanelLayout({
  action,
  activeLink,
  onSignOut,
  onSaveContact,
  children
}: ControlPanelLayoutProps & ControlPanelLayoutRequiredProps) {
  const router = useRouter();

  useSubscribeToPreviousRouter();

  const { isDocked } = useLeftSidebarContext();

  const handleMenuItemOnMouseOver = useCallback<(url: string) => void>(
    (url) => router.prefetch(url),
    [router]
  );

  const [isDesktop] = useMediaQuery('(min-width: 992px)', {
    ssr: true,
    fallback: false
  });

  return (
    <CheckPermissionLayout
      appAction={ControlPanelPermissions.CONTROL_PANEL_APP_ACCESS}
      action={
        action || ControlPanelPermissions.READ_CONTROL_PANEL_DASHBOARD_PAGE
      }
    >
      <ControlPanelProvider>
        <Show above="lg">
          <LeftSidebar colorScheme="light">
            <SidebarMenuItem
              title="Dashboard"
              url={ControlPanelRoutes.index()}
              icon={DashboardIcon}
              isActive={activeLink === 'dashboard'}
              onMouseOver={handleMenuItemOnMouseOver}
            />
            <SidebarMenuItem
              title="Companies"
              url={CompaniesRoutes.index()}
              icon={CompaniesIcon}
              isActive={activeLink === 'companies'}
              onMouseOver={handleMenuItemOnMouseOver}
            />
            <SidebarMenuItem
              title="People"
              url={ControlPanelPeopleRoutes.index()}
              icon={RecruitersIcon}
              isActive={activeLink === 'people'}
              onMouseOver={handleMenuItemOnMouseOver}
            />
            <SidebarMenuItem
              title="User Management"
              url={ControlPanelRoutes.users()}
              icon={UserManagementIcon}
              isActive={activeLink === 'user-management'}
              onMouseOver={handleMenuItemOnMouseOver}
            />
            <SidebarMenuItem
              title="Talent Pools"
              url={ControlPanelRoutes.talentPool()}
              icon={CandidatesIcon}
              isActive={activeLink === 'talent-pools'}
              onMouseOver={handleMenuItemOnMouseOver}
            />
            <SidebarMenuItem
              title="Jobs"
              url={ControlPanelJobsRoutes.index()}
              icon={JobsIcon}
              isActive={activeLink === 'jobs'}
              onMouseOver={handleMenuItemOnMouseOver}
            />
            <SidebarMenuItem
              title="Files"
              url={ControlPanelRoutes.fileAttachments()}
              icon={FilesIcon}
              isActive={activeLink === 'files'}
              onMouseOver={handleMenuItemOnMouseOver}
            />
            <Box mt="auto">
              <SidebarMenuItem
                title="Settings"
                url={ControlPanelSettingsRoutes.index()}
                icon={SettingsIcon}
                isActive={activeLink === 'settings'}
                onMouseOver={handleMenuItemOnMouseOver}
              />
              <SidebarMenuItem
                title="Help"
                url="/help"
                icon={HelpIcon}
                isActive={activeLink === 'help'}
                onMouseOver={handleMenuItemOnMouseOver}
              />
            </Box>
          </LeftSidebar>
        </Show>

        <Grid
          gridTemplateRows="max-content 1fr"
          height="100%"
          gap={6}
          as="section"
          bg="gray.50"
          h="100vh"
          flexDirection="column"
          transitionDuration="slow"
          transitionProperty="margin-left"
          ml={{ lg: isDocked ? 20 : 60 }}
          mr={{ lg: '72px' }}
        >
          {isDesktop && (
            <GridItem display={{ base: 'none', lg: 'unset' }}>
              <Header onSignOut={onSignOut} onSaveContact={onSaveContact} />
            </GridItem>
          )}

          <GridItem overflow="auto">{children}</GridItem>
        </Grid>

        <Show above="lg">
          <RightSidebar />
        </Show>
      </ControlPanelProvider>
    </CheckPermissionLayout>
  );
}

export default ControlPanelLayout;
