import { Icon, IconProps } from '@chakra-ui/icon';

const TasksButtonIcon = ({ w = '40px', h = '40px', ...props }: IconProps) => (
  <Icon viewBox="0 0 40 40" w={w} h={h} {...props}>
    <circle cx={20} cy={20} r={20} fill="#287CC9" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m25.584 17.25-6.666 6.666a.757.757 0 0 1-.584.25.756.756 0 0 1-.583-.25l-3.334-3.333a.805.805 0 0 1 0-1.167.806.806 0 0 1 1.167 0l2.75 2.75 6.084-6.083a.806.806 0 0 1 1.166 0 .805.805 0 0 1 0 1.167ZM20 10c-5.5 0-10 4.5-10 10s4.5 10 10 10 10-4.5 10-10-4.5-10-10-10Z"
      fill="#F8FAFB"
    />
  </Icon>
);

export default TasksButtonIcon;
