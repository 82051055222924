import { usePaginatedItems } from '../../../common/hooks/usePaginatedItems';

import {
  FetchEmploymentsQuery,
  FetchEmploymentsCacheKey,
  FetchEmploymentsTotalCount,
  FetchEmploymentsPage,
  FetchEmploymentsPageSize,
  FetchEmploymentsError,
  FetchEmploymentsErrorMessage,
  FetchEmploymentsIsLoading,
  FetchEmploymentsIsFetched,
  FetchEmploymentsIsPlaceholderData,
  FetchEmploymentsEnabled,
  FetchEmploymentsPrefetchNextPage,
  FetchEmploymentsCountType,
  FetchEmploymentsFilters,
  FetchEmploymentsSort,
  FetchEmploymentID,
  FetchEmploymentQuery,
  FetchEmploymentCacheKey
} from '../../employmentsTypes';

import { EmploymentsBffRequests } from '../../EmploymentsBffRequests';

interface PaginatedEmploymentsBaseOptions<PaginatedEmploymentsResponseType> {
  cacheKey: FetchEmploymentsCacheKey;
  query: FetchEmploymentsQuery;
  countType?: FetchEmploymentsCountType;
  initialPage?: FetchEmploymentsPage;
  initialPageSize?: FetchEmploymentsPageSize;
  initialFilters?: FetchEmploymentsFilters;
  initialSort?: FetchEmploymentsSort;
  enabled?: FetchEmploymentsEnabled;
  prefetchNextPage?: FetchEmploymentsPrefetchNextPage;
  placeholderEmployments?: PaginatedEmploymentsResponseType[];
  placeholderEmploymentsCount?: FetchEmploymentsTotalCount;
  placeholderData?: () => PaginatedEmploymentsResponse<PaginatedEmploymentsResponseType>;
}

interface PaginatedEmploymentsOptionsWithoutItem {
  fetchItemCacheKey?: never;
  itemQuery?: never;
}

interface PaginatedEmploymentsOptionsWithItem {
  fetchItemCacheKey: FetchEmploymentCacheKey;
  itemQuery: FetchEmploymentQuery;
}

type PaginatedEmploymentsOptions<PaginatedEmploymentsResponseType> =
  PaginatedEmploymentsBaseOptions<PaginatedEmploymentsResponseType> &
    (
      | PaginatedEmploymentsOptionsWithoutItem
      | PaginatedEmploymentsOptionsWithItem
    );

interface PaginatedEmploymentsResponse<PaginatedEmploymentsResponseType> {
  data: PaginatedEmploymentsResponseType[];
  totalCount: FetchEmploymentsTotalCount;
}

function usePaginatedEmployments<PaginatedEmploymentsResponseType>({
  cacheKey,
  query,
  countType,
  initialPage,
  initialPageSize,
  initialFilters,
  initialSort,
  enabled,
  prefetchNextPage,
  placeholderEmployments,
  placeholderEmploymentsCount,
  placeholderData,
  fetchItemCacheKey,
  itemQuery
}: PaginatedEmploymentsOptions<PaginatedEmploymentsResponseType>) {
  const {
    items,
    itemsTotalCount,
    itemsError,
    itemsErrorMessage,
    itemsIsFetched,
    itemsIsLoading,
    itemsIsPlaceholderData,
    itemsCurrentPage,
    itemsCurrentPageSize,
    itemsCurrentFilters,
    itemsCurrentSort,
    fetchItems,
    paginateItems,
    changeItemsPageSize,
    filterItems,
    changeItemsFilters,
    clearItemsFilters,
    sortItems,
    prefetchItems,
    prefetchItem
  } = usePaginatedItems<PaginatedEmploymentsResponseType>({
    cacheKey,
    initialPage,
    initialPageSize,
    initialFilters,
    initialSort,
    enabled,
    prefetchNextPage,
    placeholderItems: placeholderEmployments,
    placeholderItemsCount: placeholderEmploymentsCount,
    placeholderData,
    queryFn: (params) =>
      EmploymentsBffRequests.fetchEmployments<PaginatedEmploymentsResponseType>(
        {
          query,
          countType,
          page: params.page as FetchEmploymentsPage,
          pageSize: params.pageSize as FetchEmploymentsPageSize,
          filters: params.filters as FetchEmploymentsFilters,
          sort: params.sort as FetchEmploymentsSort
        }
      ),
    ...(itemQuery
      ? {
          fetchItemCacheKey,
          queryItemFn: (itemId) =>
            EmploymentsBffRequests.fetchEmployment(
              itemId as FetchEmploymentID,
              {
                query: itemQuery
              }
            )
        }
      : {})
  });

  return {
    employments: items as PaginatedEmploymentsResponseType[],
    employmentsTotalCount: itemsTotalCount as FetchEmploymentsTotalCount,
    employmentsError: itemsError as FetchEmploymentsError,
    employmentsErrorMessage: itemsErrorMessage as FetchEmploymentsErrorMessage,
    employmentsIsLoading: itemsIsLoading as FetchEmploymentsIsLoading,
    employmentsIsFetched: itemsIsFetched as FetchEmploymentsIsFetched,
    employmentsIsPlaceholderData:
      itemsIsPlaceholderData as FetchEmploymentsIsPlaceholderData,
    employmentsCurrentPage: itemsCurrentPage as FetchEmploymentsPage,
    employmentsCurrentPageSize:
      itemsCurrentPageSize as FetchEmploymentsPageSize,
    employmentsCurrentFilters: itemsCurrentFilters as FetchEmploymentsFilters,
    employmentsCurrentSort: itemsCurrentSort as FetchEmploymentsSort,
    fetchEmployments: fetchItems,
    paginateEmployments: paginateItems,
    changeEmploymentsPageSize: changeItemsPageSize,
    filterEmployments: filterItems,
    changeEmploymentsFilters: changeItemsFilters,
    clearEmploymentsFilters: clearItemsFilters,
    sortEmployments: sortItems,
    prefetchEmployments: prefetchItems,
    prefetchEmployment: prefetchItem
  };
}

export default usePaginatedEmployments;
