import {
  FetchLikedJobCandidatesCacheKey,
  FetchLikedJobCandidateCacheKey
} from './likedJobCandidatesTypes';

import { UserId } from '../users/usersTypes';

export class LikedJobCandidatesCache {
  static indexCacheKey() {
    return 'LikedJobCandidates' as FetchLikedJobCandidatesCacheKey;
  }

  static showCacheKey() {
    return 'LikedJobCandidate' as FetchLikedJobCandidateCacheKey;
  }

  static userIndexCacheKey(userId: UserId) {
    return `LikedJobCandidates-${userId}` as FetchLikedJobCandidatesCacheKey;
  }
}
