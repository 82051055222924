import { FieldBrand, ItemRecordID } from '../../../types';

import { UserUuid } from '../../users/usersTypes';

export type IndustryExpertiseRecordId = FieldBrand<
  ItemRecordID,
  'IndustryExpertiseRecordId'
>;

export type IndustryExpertiseUserUuid = FieldBrand<
  UserUuid,
  'IndustryExpertiseUserUuid'
>;

export const enum IndustryExpertiseIndexFields {
  ID = 'id',
  RECORD_ID = 'recordId',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  CREATED_AT = 'createdAt',

  USER_UUID = 'userUuid'
}
