import {
  Avatar,
  AvatarGroup,
  ColorProps,
  Flex,
  HStack,
  Stack,
  SystemProps,
  ThemeTypings
} from '@chakra-ui/react';
import compact from 'lodash/compact';
import size from 'lodash/size';
import { ReactNode } from 'react';
import { Button } from '../../../../../../helpers/Button';
import { Heading } from '../../../../../../helpers/Heading';
import { Logo } from '../../../../../../helpers/Logo';
import { NextLinkHelper } from '../../../../../../helpers/NextLinkHelper';
import { Text } from '../../../../../../helpers/Text';

interface ShareCandidateCardEmailItemProps {
  title: string;
  name?: string;
  companyName?: string;
  designation?: string;
  company: { name: string; logoUrl: string };
  hrDepartment: string;
  recruiterAvatarUrl: string;
  recruiterName?: string;
  message: ReactNode;
  isMultiple?: boolean;
  avatarUrls?: string[];
  buttonActionText?: string;
  buttonLinkUrl?: string;
  buttonColor?: ThemeTypings['colorSchemes'];
  titleColor?: ColorProps['color'];
  introMessage?: ReactNode;
  avatarUrlSpacing?: SystemProps['margin'];
}

export const ShareCandidateCardEmailItem = ({
  title,
  name,
  company,
  designation,
  avatarUrls,
  companyName,
  hrDepartment,
  message,
  isMultiple,
  buttonLinkUrl,
  buttonActionText,
  recruiterName,
  recruiterAvatarUrl,
  buttonColor = 'primary',
  titleColor = 'gray.700',
  introMessage,
  avatarUrlSpacing = -5
}: ShareCandidateCardEmailItemProps) => (
  <Flex
    w={96}
    gap={8}
    bg="white"
    borderRadius="4"
    overflow="hidden"
    border="1px solid"
    borderColor="gray.200"
    flexDir="column"
  >
    <Flex px={4} pt={4} gap={2}>
      <Flex gap={1} flex={1}>
        <Avatar size="sm" src={company.logoUrl} />
        <Stack spacing={0}>
          <Text lineHeight="20px">{company.name}</Text>
          <Text
            fontSize="10px"
            color="gray.600"
            lineHeight="12px"
            fontWeight="medium"
          >
            {hrDepartment}
          </Text>
        </Stack>
      </Flex>
      <HStack spacing={2}>
        {recruiterName ? (
          <Text textStyle="body1Medium">{recruiterName}</Text>
        ) : null}
        <Avatar size="sm" src={recruiterAvatarUrl} />
      </HStack>
    </Flex>

    <Stack spacing={8} px={6}>
      <Stack spacing={4}>
        {title ? (
          <Text textStyle="body1Medium" textAlign="center" color={titleColor}>
            {title}
          </Text>
        ) : null}

        {size(avatarUrls) > 0 ||
        name ||
        designation ||
        companyName ||
        introMessage ? (
          <Stack spacing={1}>
            {size(avatarUrls) > 0 ? (
              <Flex justifyContent="center">
                <AvatarGroup spacing={avatarUrlSpacing} max={4} color="red">
                  {avatarUrls?.map((url, index) => (
                    <Avatar
                      src={url}
                      key={index}
                      position="relative"
                      zIndex={index}
                    />
                  ))}
                </AvatarGroup>
              </Flex>
            ) : null}

            {name ? (
              <Heading level="h5" textAlign="center">
                {name}
              </Heading>
            ) : null}

            {!isMultiple && (designation || companyName) ? (
              <Text
                color="gray.600"
                textAlign="center"
                textStyle="body1Regular"
              >
                {compact([designation, companyName]).join(' @ ')}
              </Text>
            ) : null}

            {introMessage ? (
              <Flex
                flex={1}
                color="gray.600"
                textAlign="center"
                alignItems="center"
                justifyContent="center"
                textStyle="body1Regular"
              >
                {introMessage}
              </Flex>
            ) : null}
          </Stack>
        ) : null}

        {message ? (
          <Flex
            fontSize="xs"
            lineHeight="20px"
            textAlign="center"
            justifyContent="center"
          >
            {message}
          </Flex>
        ) : null}
      </Stack>
    </Stack>

    {buttonLinkUrl ? (
      <Flex
        alignItems="center"
        justifyContent="center"
        __css={{
          display: 'flex',
          '> a': {
            textDecoration: 'red'
          }
        }}
      >
        <NextLinkHelper href={buttonLinkUrl} passHref>
          <Button
            as="a"
            w={60}
            size="small"
            colorScheme={buttonColor}
            hierarchy="solid"
          >
            {buttonActionText || 'Open Link'}
          </Button>
        </NextLinkHelper>
      </Flex>
    ) : null}

    <Flex
      h={12}
      gap={2}
      bg="#F7F7F9"
      alignItems="center"
      justifyContent="center"
      display={{ base: 'none', md: 'flex' }}
      boxShadow="0px 0px 2px rgba(0, 0, 0, 0.25)"
    >
      <Flex alignItems="center">
        <Text color="gray.600" mt={-2.5}>
          Powered by
        </Text>
        <Logo h={8} w={20} />
      </Flex>
    </Flex>
  </Flex>
);

export default ShareCandidateCardEmailItem;
