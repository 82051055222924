import { usePaginatedItems } from '../../../common/hooks/usePaginatedItems';

import {
  FetchStatesQuery,
  FetchStatesCacheKey,
  FetchStatesTotalCount,
  FetchStatesPage,
  FetchStatesPageSize,
  FetchStatesError,
  FetchStatesErrorMessage,
  FetchStatesIsLoading,
  FetchStatesIsFetched,
  FetchStatesIsPlaceholderData,
  FetchStatesEnabled,
  FetchStatesPrefetchNextPage,
  FetchStatesRefetchInterval,
  FetchStatesCountType,
  FetchStatesFilters,
  FetchStatesSort,
  FetchStateID,
  FetchStateQuery,
  FetchStateCacheKey
} from '../../statesTypes';

import { StatesBffRequests } from '../../StatesBffRequests';

interface PaginatedStatesBaseOptions<PaginatedStatesResponseType> {
  cacheKey: FetchStatesCacheKey;
  query: FetchStatesQuery;
  countType?: FetchStatesCountType;
  initialPage?: FetchStatesPage;
  initialPageSize?: FetchStatesPageSize;
  initialFilters?: FetchStatesFilters;
  initialSort?: FetchStatesSort;
  enabled?: FetchStatesEnabled;
  prefetchNextPage?: FetchStatesPrefetchNextPage;
  refetchInterval?: FetchStatesRefetchInterval;
  placeholderStates?: PaginatedStatesResponseType[];
  placeholderStatesCount?: FetchStatesTotalCount;
  placeholderData?: () => PaginatedStatesResponse<PaginatedStatesResponseType>;
}

interface PaginatedStatesOptionsWithoutItem {
  fetchItemCacheKey?: never;
  itemQuery?: never;
}

interface PaginatedStatesOptionsWithItem {
  fetchItemCacheKey: FetchStateCacheKey;
  itemQuery: FetchStateQuery;
}

type PaginatedStatesOptions<PaginatedStatesResponseType> =
  PaginatedStatesBaseOptions<PaginatedStatesResponseType> &
    (PaginatedStatesOptionsWithoutItem | PaginatedStatesOptionsWithItem);

interface PaginatedStatesResponse<PaginatedStatesResponseType> {
  data: PaginatedStatesResponseType[];
  totalCount: FetchStatesTotalCount;
}

function usePaginatedStates<PaginatedStatesResponseType>({
  cacheKey,
  query,
  countType,
  initialPage,
  initialPageSize,
  initialFilters,
  initialSort,
  enabled,
  prefetchNextPage,
  refetchInterval,
  placeholderStates,
  placeholderStatesCount,
  placeholderData,
  fetchItemCacheKey,
  itemQuery
}: PaginatedStatesOptions<PaginatedStatesResponseType>) {
  const {
    items,
    itemsTotalCount,
    itemsError,
    itemsErrorMessage,
    itemsIsFetched,
    itemsIsLoading,
    itemsIsPlaceholderData,
    itemsCurrentPage,
    itemsCurrentPageSize,
    itemsCurrentFilters,
    itemsCurrentSort,
    fetchItems,
    paginateItems,
    changeItemsPageSize,
    filterItems,
    changeItemsFilters,
    clearItemsFilters,
    sortItems,
    prefetchItems,
    prefetchItem
  } = usePaginatedItems<PaginatedStatesResponseType>({
    cacheKey,
    initialPage,
    initialPageSize,
    initialFilters,
    initialSort,
    enabled,
    prefetchNextPage,
    refetchInterval,
    placeholderItems: placeholderStates,
    placeholderItemsCount: placeholderStatesCount,
    placeholderData,
    queryFn: (params) =>
      StatesBffRequests.fetchStates<PaginatedStatesResponseType>({
        query,
        countType,
        page: params.page as FetchStatesPage,
        pageSize: params.pageSize as FetchStatesPageSize,
        filters: params.filters as FetchStatesFilters,
        sort: params.sort as FetchStatesSort
      }),
    ...(itemQuery
      ? {
          fetchItemCacheKey,
          queryItemFn: (itemId) =>
            StatesBffRequests.fetchState(itemId as FetchStateID, {
              query: itemQuery
            })
        }
      : {})
  });

  return {
    states: items as PaginatedStatesResponseType[],
    statesTotalCount: itemsTotalCount as FetchStatesTotalCount,
    statesError: itemsError as FetchStatesError,
    statesErrorMessage: itemsErrorMessage as FetchStatesErrorMessage,
    statesIsLoading: itemsIsLoading as FetchStatesIsLoading,
    statesIsFetched: itemsIsFetched as FetchStatesIsFetched,
    statesIsPlaceholderData:
      itemsIsPlaceholderData as FetchStatesIsPlaceholderData,
    statesCurrentPage: itemsCurrentPage as FetchStatesPage,
    statesCurrentPageSize: itemsCurrentPageSize as FetchStatesPageSize,
    statesCurrentFilters: itemsCurrentFilters as FetchStatesFilters,
    statesCurrentSort: itemsCurrentSort as FetchStatesSort,
    fetchStates: fetchItems,
    paginateStates: paginateItems,
    changeStatesPageSize: changeItemsPageSize,
    filterStates: filterItems,
    changeStatesFilters: changeItemsFilters,
    clearStatesFilters: clearItemsFilters,
    sortStates: sortItems,
    prefetchStates: prefetchItems,
    prefetchState: prefetchItem
  };
}

export default usePaginatedStates;
