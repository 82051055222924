import React from 'react';
import { FieldValues } from 'react-hook-form';
import { SystemStyleObject } from '@chakra-ui/react';
import debounce from 'lodash/debounce';
import first from 'lodash/first';

import { ErrorMessage, I18nText } from '../../../../../types';

import {
  FetchCompaniesFilters,
  FetchCompaniesPageSize,
  FetchCompaniesSort,
  CompanyFields,
  CompanyId,
  CompanyName
} from '../../../../companies/companiesTypes';

import {
  FetchCompaniesResponse,
  fetchCompaniesSignUpQuery
} from '../../../../companies/queries/fetchCompanies.query';

import { usePaginatedCompanies } from '../../../../companies/hooks/usePaginatedCompanies';

import { CompaniesCache } from '../../../../companies/CompaniesCache';

import {
  SelectField,
  SelectFieldReactHookFormControl,
  SelectFieldReactHookFormFieldPath
} from '../../../../../helpers/forms/formFields/SelectField';

interface SelectCompanyFormFieldItem {
  company: {
    id: CompanyId;
    name: CompanyName;
  } | null;
}

interface SelectCompanyFormFieldBlankProps<T extends FieldValues> {
  control: SelectFieldReactHookFormControl<T>;
  label?: I18nText;
  withoutLabel?: boolean;
  i18nPlaceholder?: I18nText;
  errorMessage: ErrorMessage;
  item?: never;
  id?: string;
  name?: SelectFieldReactHookFormFieldPath<T>;
  reactSelectStyles?: Record<string, unknown>;
  isBorderless?: boolean;
  isDisabled?: boolean;
}

interface SelectCompanyFormFieldWithItemProps<T extends FieldValues> {
  control: SelectFieldReactHookFormControl<T>;
  label?: I18nText;
  withoutLabel?: boolean;
  i18nPlaceholder?: I18nText;
  errorMessage: ErrorMessage;
  item: SelectCompanyFormFieldItem;
  name?: SelectFieldReactHookFormFieldPath<T>;
  reactSelectStyles?: Record<string, unknown>;
  isBorderless?: boolean;
  id?: string;
  isDisabled?: boolean;
}

type SelectCompanyFormFieldProps<T extends FieldValues> =
  | SelectCompanyFormFieldBlankProps<T>
  | SelectCompanyFormFieldWithItemProps<T>;

function SelectCompanyFormField<T extends FieldValues>({
  control,
  label = 'Company',
  i18nPlaceholder,
  withoutLabel = false,
  errorMessage,
  item,
  name,
  reactSelectStyles,
  isBorderless,
  id,
  isDisabled
}: SelectCompanyFormFieldProps<T>) {
  const {
    companies,
    companiesIsLoading,
    companiesErrorMessage,
    changeCompaniesFilters
  } = usePaginatedCompanies<FetchCompaniesResponse>({
    query: fetchCompaniesSignUpQuery,
    cacheKey: CompaniesCache.indexCacheKey(),
    initialPageSize: 100 as FetchCompaniesPageSize,
    initialFilters: {
      ...(item?.company
        ? {
            [CompanyFields.NAME]: {
              operator: 'ilike',
              value: `${first(item.company.name)}%`
            }
          }
        : {})
    } as unknown as FetchCompaniesFilters,
    initialSort: {
      [CompanyFields.NAME]: { ascending: true }
    } as unknown as FetchCompaniesSort
  });

  const defaultCurrentCompany = item?.company
    ? { value: item.company.id, label: item.company.name }
    : undefined;

  const debouncedFilterCompanies = debounce<(updatedValue: string) => void>(
    (updatedValue) =>
      changeCompaniesFilters({
        [CompanyFields.NAME]: {
          operator: 'ilike',
          value: `${updatedValue}%`
        }
      })
  );

  return (
    <SelectField
      id={id}
      label={withoutLabel ? undefined : label}
      i18nPlaceholder={i18nPlaceholder}
      options={
        companies &&
        companies.map((company) => ({
          value: company.id,
          label: company.name
        }))
      }
      control={control}
      name={name || ('companyId' as SelectFieldReactHookFormFieldPath<T>)}
      isDisabled={isDisabled}
      isLoading={companiesIsLoading}
      defaultValue={defaultCurrentCompany}
      errorMessage={errorMessage || companiesErrorMessage}
      onInputChange={debouncedFilterCompanies}
      reactSelectStyles={
        isBorderless
          ? {
              control: (base: SystemStyleObject) => ({
                ...base,
                borderColor: 'transparent',
                p: 0
              })
            }
          : reactSelectStyles
      }
    />
  );
}

export default SelectCompanyFormField;
