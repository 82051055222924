import { SystemStyleObject } from '@chakra-ui/system';

const fonts = {
  body: `'Euclid Circular B', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`,
  heading: `'Euclid Circular B', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`
};

const textStyles: SystemStyleObject = {
  regular: {
    fontWeight: '400'
  },
  medium: {
    fontWeight: '500'
  },
  semiBold: {
    fontWeight: '600'
  },
  body1Regular: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '24px'
  },
  body1Medium: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '24px'
  },
  body2Regular: {
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '20px'
  },
  body2Medium: {
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '20px'
  },
  upperCase1Regular: {
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '16px',
    textTransform: 'uppercase'
  },
  upperCase1Medium: {
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '16px',
    textTransform: 'uppercase'
  }
};

const typography = {
  fonts,
  textStyles
};

export default typography;
