import { FetchJobTitlesCacheKey } from '../../jobTitlesTypes';

import { useCreateItem } from '../../../common/hooks/useCreateItem';

import {
  JobTitlesBffRequests,
  CreateJobTitleRequestData,
  CreateJobTitleErrorResponse
} from '../../JobTitlesBffRequests';

interface CreateJobTitleOptions {
  cacheKeys?: FetchJobTitlesCacheKey[];
}

function useCreateJobTitle({ cacheKeys = [] }: CreateJobTitleOptions) {
  const {
    createItemData,
    createItemError,
    createItemErrorMessage,
    createItemIsLoading,
    createItem,
    createItemReset
  } = useCreateItem<CreateJobTitleRequestData, CreateJobTitleErrorResponse>({
    mutationFn: (queryInput) => JobTitlesBffRequests.createJobTitle(queryInput),
    // JobTitlesRequests.createJobTitle(queryInput),
    cacheKeys
  });

  return {
    createJobTitleData: createItemData,
    createJobTitleError: createItemError,
    createJobTitleErrorMessage: createItemErrorMessage,
    createJobTitleIsLoading: createItemIsLoading,
    createJobTitle: createItem,
    createJobTitleReset: createItemReset
  };
}

export default useCreateJobTitle;
