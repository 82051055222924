import React from 'react';
import dynamic from 'next/dynamic';
import { FormControl, FormErrorMessage } from '@chakra-ui/form-control';

import { ErrorMessage, IsDisabled } from '../../../../../../types';

const Wysiwyg = dynamic(() => import('../../../../../Wysiwyg/Wysiwyg'), {
  ssr: false
});

interface WysiwygFieldControlProps {
  name: string;
  value: string;
  placeholder?: string;
  defaultValue?: string | null;
  isInvalid?: boolean;
  isDisabled?: IsDisabled;
  isRequired?: boolean;
  errorMessage?: ErrorMessage;
  onChange: (updatedValue: string) => void;
}

function WysiwygFieldControl({
  placeholder,
  defaultValue,
  errorMessage,
  isInvalid,
  isDisabled,
  isRequired,
  onChange
}: WysiwygFieldControlProps) {
  return (
    <FormControl
      isInvalid={isInvalid || typeof errorMessage === 'string'}
      isDisabled={isDisabled}
      isRequired={isRequired}
      h="100%"
    >
      {errorMessage ? (
        <FormErrorMessage
          mb={3}
          className="mt-2"
          textStyle="body2Medium"
          wordBreak="break-all"
        >
          {errorMessage}
        </FormErrorMessage>
      ) : null}
      <Wysiwyg
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={onChange}
      />
    </FormControl>
  );
}

export default WysiwygFieldControl;
