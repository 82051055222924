import { Icon, IconProps } from '@chakra-ui/icon';

const EmailButtonIcon = ({ w = '40px', h = '40px', ...props }: IconProps) => (
  <Icon viewBox="0 0 40 40" w={w} h={h} {...props}>
    <circle cx={20} cy={20} r={20} fill="#EEA401" />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M11.996 13A.996.996 0 0 0 11 14v1.333c0 .312.15.545.375.7l8.25 4.667c.15.078.225.078.375.078s.225 0 .375-.078l8.25-4.667a.824.824 0 0 0 .375-.7V14c0-.552-.441-1-.994-1h-16.01Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M20 22.338c.35 0 .7-.067.988-.266.042-.029.084-.057.129-.082l6.396-3.567a1 1 0 0 1 1.487.873v6.709a1 1 0 0 1-1 1H12a1 1 0 0 1-1-1v-6.709a1 1 0 0 1 1.487-.873l6.396 3.567c.045.025.087.053.129.082.288.199.638.266.988.266Z"
    />
  </Icon>
);

export default EmailButtonIcon;
