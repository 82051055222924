import { Icon, IconProps } from '@chakra-ui/icon';

const SearchIcon = ({
  w = '20px',
  h = '20px',
  stroke = '#697275',
  ...props
}: IconProps) => (
  <Icon viewBox="0 0 20 20" w={w} h={h} stroke={stroke} fill="none" {...props}>
    <circle
      cx={9}
      cy={9}
      r={6}
      strokeWidth={1.33}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m13.5 13.5 4 4"
      strokeWidth={1.33}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

export default SearchIcon;
