import React, { useCallback, useState } from 'react';
import { Flex, Stack, StackDivider, Tooltip } from '@chakra-ui/react';
import { FieldValues, Path, UseFormSetValue } from 'react-hook-form';
import { ErrorMessage } from '../../../../../../../../../types';
import { UserEmailLabel } from '../../../../../../../../userEmails/userEmailsTypes';
import {
  EmploymentCurrently,
  EmploymentJobFunctionIds,
  EmploymentJobTitleId
} from '../../../../../../../../employments/employmentsTypes';
import { RemoveIcon } from '../../../../../../../../../icons/RemoveIcon';
import { IconButton } from '../../../../../../../../../helpers/buttons/IconButton';
import { EditUserFormAddEducationFieldControl } from '../EditUserFormAddEducationField';
import { SelectCompanyFormField } from '../../../../../../../../common/components/formFields/SelectCompanyFormField';
import { SelectCityFormField } from '../../../../../../../../common/components/formFields/SelectCityFormField';
import { UserEmployment } from '../../../../../../../usersTypes';
import { AddEditUserFormData } from '../../../../AddEditUserForm.types';
import { SelectJobTitleSelectJobTitleAssociatesProps } from '../../../../../../../../common/components/formFields/SelectJobTitleFormField/SelectJobTitle.types';
import { SelectJobTitleFormField } from '../../../../../../../../common/components/formFields/SelectJobTitleFormField';
import { SelectJobFunctionsFormField } from '../../../../../../../../common/components/formFields/SelectJobFunctionsFormField';
import { DateInputField } from '../../../../../../../../../helpers/forms/formFields/DateInputField';
import { CheckboxField } from '../../../../../../../../../helpers/forms/formFields/CheckboxField';
import { Text } from '../../../../../../../../../helpers/Text';

export interface EditUserFormAddExperienceFieldCurrentEmployment {
  jobFunctionIds: EmploymentJobFunctionIds;
  jobTitleId: EmploymentJobTitleId;
  currently: EmploymentCurrently;
}

interface EditUserFormAddExperienceFieldProps<T extends FieldValues> {
  control: EditUserFormAddEducationFieldControl<T>;
  name: string;
  index: number;
  withoutRemove?: boolean;
  error?: { [key: string]: { message?: ErrorMessage } };
  labelValue?: UserEmailLabel;
  onRemove: (index: number) => void;
  employment: UserEmployment;
  currentEmployment: EditUserFormAddExperienceFieldCurrentEmployment;
  setAddEditUserFormValue: UseFormSetValue<AddEditUserFormData>;
}

function EditUserFormAddExperienceField<T extends FieldValues>({
  control,
  name,
  index,
  error,
  withoutRemove,
  onRemove,
  employment,
  setAddEditUserFormValue,
  currentEmployment
}: EditUserFormAddExperienceFieldProps<T>) {
  const [selectedJobTitleIsNew, setSelectedJobTitleIsNew] =
    useState<boolean>(false);

  const handleRemove = useCallback<() => void>(
    () => onRemove(index),
    [index, onRemove]
  );

  const currentJobTitleId = currentEmployment.jobTitleId;
  const currentJobFunctionIds = currentEmployment.jobFunctionIds;
  const currently = currentEmployment.currently;

  const handleJobTitleAssociates = useCallback<
    (jobTitleAssociates: SelectJobTitleSelectJobTitleAssociatesProps) => void
  >(
    ({ jobFunctionId, isNewJobTitle }) => {
      setSelectedJobTitleIsNew(isNewJobTitle || false);
      if (jobFunctionId) {
        setAddEditUserFormValue(`profileEmployments.${index}.jobFunctionIds`, [
          ...currentJobFunctionIds,
          jobFunctionId
        ] as EmploymentJobFunctionIds);
      }
    },
    [setAddEditUserFormValue, currentJobFunctionIds, index]
  );

  return (
    <Stack
      spacing={0}
      border="1px solid"
      borderRadius="base"
      position="relative"
      borderColor="gray.200"
      divider={<StackDivider />}
    >
      <Flex alignItems="center">
        <SelectCompanyFormField
          control={control}
          withoutLabel
          item={employment}
          i18nPlaceholder="Company"
          name={`${name}.${index}.companyId` as Path<T>}
          errorMessage={error?.companyId?.message}
          isBorderless
        />

        <Tooltip
          label={withoutRemove ? undefined : 'Remove experience'}
          placement="end"
          hasArrow
        >
          <IconButton
            mx={1}
            size="small"
            value={index}
            aria-label={''}
            hierarchy="link"
            colorScheme="red"
            onClick={handleRemove}
            cursor={withoutRemove ? 'not-allowed' : 'pointer'}
            icon={
              <RemoveIcon
                fill={withoutRemove ? 'red.200' : 'red.500'}
                w={5}
                h={5}
              />
            }
          />
        </Tooltip>
      </Flex>

      <SelectJobTitleFormField
        control={control}
        placeholder="Job Title"
        item={employment}
        name={`${name}.${index}.jobTitleId` as Path<T>}
        selectedJobTitleId={currentJobTitleId}
        selectJobTitleAssocaites={handleJobTitleAssociates}
        errorMessage={error?.jobTitleId?.message}
        withoutLabel
        isBorderless
      />

      <SelectJobFunctionsFormField
        control={control}
        item={employment}
        name={`${name}.${index}.jobFunctionIds` as Path<T>}
        selectedFunctionIds={currentJobFunctionIds}
        selectedJobTitleId={currentJobTitleId}
        isNewJobtitle={selectedJobTitleIsNew}
        selectJobTitleAssocaites={handleJobTitleAssociates}
        errorMessage={error?.jobFunctionIds?.message}
        withoutLabel
        isBorderless
      />

      <SelectCityFormField
        control={control}
        showLabel={false}
        name={`${name}.${index}.cityId` as Path<T>}
        placeholder="City"
        item={employment}
        errorMessage={error?.cityId?.message}
        isBorderless
      />

      <Stack spacing={0} direction="row" divider={<StackDivider />}>
        <Flex flex={1} position="relative">
          <DateInputField
            border="none"
            placeholder="From date"
            type="month"
            {...control.register(`${name}.${index}.startDate` as Path<T>)}
            errorMessage={error?.startDate?.message}
          />
        </Flex>

        <Flex flex={1} position="relative">
          {currently ? (
            <Stack p={2}>
              <CheckboxField
                {...control.register(`${name}.${index}.currently` as Path<T>)}
                size="small"
              >
                <Text textStyle="body2Regular" color="gray.600">
                  I currently work here
                </Text>
              </CheckboxField>
            </Stack>
          ) : (
            <DateInputField
              border="none"
              placeholder="To date"
              type="month"
              {...control.register(`${name}.${index}.endDate` as Path<T>)}
              errorMessage={error?.endDate?.message}
            />
          )}
        </Flex>
      </Stack>
      {currently ? null : (
        <Stack p={2}>
          <CheckboxField
            {...control.register(`${name}.${index}.currently` as Path<T>)}
            size="small"
          >
            <Text textStyle="body2Regular" color="gray.600">
              I currently work here
            </Text>
          </CheckboxField>
        </Stack>
      )}
    </Stack>
  );
}

export default EditUserFormAddExperienceField;
