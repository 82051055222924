const basePath = '/pipeline';

export class PipelineRoutes {
  static index() {
    return `${basePath}`;
  }

  static recruit() {
    return `${basePath}/recruit`;
  }

  static kanban() {
    return `${basePath}/kanban`;
  }

  static updates() {
    return `${basePath}/updates`;
  }
}
