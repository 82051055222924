import { Button as ChakraButton } from '@chakra-ui/button';
import { forwardRef } from '@chakra-ui/react';
import { mapSize } from '../../utils/mapSize';
import { ButtonProps } from '../Button/Button.types';

const ButtonWithRef = forwardRef(
  (
    {
      size = 'large',
      children,
      hierarchy,
      rightIcon,
      leftIcon,
      ...props
    }: ButtonProps,
    ref
  ) => {
    return (
      <ChakraButton
        ref={ref}
        {...props}
        variant={hierarchy}
        size={mapSize(size)}
        rightIcon={rightIcon}
        leftIcon={leftIcon}
      >
        {children}
      </ChakraButton>
    );
  }
);

ButtonWithRef.displayName = 'ButtonWithRef';

export default ButtonWithRef;
