import {
  WorkflowStageItemBffID,
  WorkflowStageItemBffNanoID,
  FetchWorkflowStagesBffURL,
  FetchWorkflowStageBffURL,
  FetchWorkflowStageByNanoIdBffURL,
  CreateWorkflowStageBffURL,
  UpdateWorkflowStageBffURL,
  DeleteWorkflowStageBffURL
} from './workflowStagesTypes';

import { BaseBffRoutes } from '../BaseBffRoutes';

export class WorkflowStagesBffRoutes extends BaseBffRoutes {
  protected static route = 'workflowStages';

  static fetchWorkflowStagesRoute() {
    return this.fetchItemsRoute<FetchWorkflowStagesBffURL>();
  }

  static fetchWorkflowStageRoute(
    workflowStageItemBffID: WorkflowStageItemBffID
  ) {
    return this.fetchItemRoute<FetchWorkflowStageBffURL>(
      workflowStageItemBffID
    );
  }

  static fetchWorkflowStageByNanoIdRoute(
    workflowStageItemBffNanoID: WorkflowStageItemBffNanoID
  ) {
    return this.fetchItemByNanoIdRoute<FetchWorkflowStageByNanoIdBffURL>(
      workflowStageItemBffNanoID
    );
  }

  static createWorkflowStageRoute() {
    return this.createItemRoute<CreateWorkflowStageBffURL>();
  }

  static updateWorkflowStageRoute(
    workflowStageItemBffNanoID: WorkflowStageItemBffNanoID
  ) {
    return this.updateItemRoute<UpdateWorkflowStageBffURL>(
      workflowStageItemBffNanoID
    );
  }

  static deleteWorkflowStageRoute(
    workflowStageItemBffNanoID: WorkflowStageItemBffNanoID
  ) {
    return this.deleteItemRoute<DeleteWorkflowStageBffURL>(
      workflowStageItemBffNanoID
    );
  }
}
