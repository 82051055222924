import { FieldBrand, ItemRecordID } from '../../../types';

import { UserUuid } from '../../users/usersTypes';

export type EducationRecordId = FieldBrand<ItemRecordID, 'EducationRecordId'>;

export type EducationUserUuid = FieldBrand<UserUuid, 'EducationUserUuid'>;

export const enum EducationIndexFields {
  ID = 'id',
  RECORD_ID = 'recordId',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  CREATED_AT = 'createdAt',

  USER_UUID = 'userUuid',

  STATE_ID = 'stateId',
  STATE = 'state',
  STATE_NANO_ID = 'state.nanoId',
  STATE_NAME = 'state.name',
  STATE_ISO_CODE = 'state.isoCode',

  CITY_ID = 'cityId',
  CITY = 'city',
  CITY_NANO_ID = 'city.nanoId',
  CITY_NAME = 'city.name',
  CITY_LABEL = 'city.label',

  JOB_RESUME = 'jobResume'
}
