import fromPairs from 'lodash/fromPairs';

import { FetchJobsPageSize } from './jobsTypes';

export type PostJobPageOptionsType = { label: string; value: string };

// export const departmentOptions: PostJobPageOptionsType[] = [
//   { label: 'Operations', value: 'operations' },
//   { label: 'Business Development', value: 'business_development' },
//   { label: 'Sales', value: 'sales' },
//   { label: 'Education', value: 'education' },
//   { label: 'Engineering', value: 'engineering' },
//   { label: 'Information Technology', value: 'information_technology' },
//   { label: 'Healthcare Services', value: 'healthcare_services' },
//   { label: 'Administrative', value: 'administrative' },
//   { label: 'Arts and Design', value: 'arts_and_design' },
//   { label: 'Customer Support', value: 'customer_support' },
//   { label: 'Finance', value: 'finance' },
//   { label: 'Social Services', value: 'social_services' },
//   { label: 'Media & Communication', value: 'media_communication' },
//   { label: 'Marketing', value: 'marketing' },
//   { label: 'Accounting', value: 'accounting' },
//   { label: 'Human Resources', value: 'human_resources' },
//   { label: 'Research', value: 'research' },
//   { label: 'Project Management', value: 'project_management' },
//   { label: 'Legal', value: 'legal' },
//   { label: 'Consulting', value: 'consulting' },
//   { label: 'Real Estate', value: 'real_estate' },
//   { label: 'Quality Assurance', value: 'quality_assurance' },
//   { label: 'Purchasing', value: 'purchasing' },
//   { label: 'Product Management', value: 'product_management' }
// ];

export const departmentOptions: PostJobPageOptionsType[] = [
  { label: 'C-Suite', value: 'c_suite' },
  { label: 'Engineering & Technical', value: 'engineering_technical' },
  { label: 'Finance', value: 'finance' },
  { label: 'Human Resources', value: 'human_resources' },
  { label: 'Information Technology', value: 'information_technology' },
  { label: 'Legal', value: 'legal' },
  { label: 'Marketing', value: 'marketing' },
  { label: 'Medical & Health', value: 'medical_health' },
  { label: 'Operations', value: 'operations' },
  { label: 'Sales', value: 'sales' }
];

export const jobFunctionOptions = {
  c_suite: [
    {
      label: 'Engineering & Technical Executive',
      value: 'engineering_technical_executive'
    },
    { label: 'Executive', value: 'executive' },
    { label: 'Finance Executive', value: 'finance_executive' },
    { label: 'Human Resources Executive', value: 'human_resources_executive' },
    {
      label: 'Information Technology Executive',
      value: 'information_technology_executive'
    },
    { label: 'Legal Executive', value: 'legal_executive' },
    { label: 'Marketing Executive', value: 'marketing_executive' },
    { label: 'Medical & Health Executive', value: 'medical_health_executive' },
    { label: 'Operations Executive', value: 'operations_executive' },
    { label: 'Sales Executive', value: 'sales_executive' }
  ],
  engineering_technical: [
    {
      label: 'Artificial Intelligence / Machine Learning',
      value: 'artificial_intelligence_machine_learning'
    },
    { label: 'Bioengineering', value: 'bioengineering' },
    { label: 'Biometrics', value: 'biometrics' },
    { label: 'Business Intelligence', value: 'business_intelligence' },
    { label: 'Chemical Engineering', value: 'chemical_engineering' },
    { label: 'Cloud / Mobility', value: 'cloud_mobility' },
    { label: 'Data Science', value: 'data_science' },
    { label: 'DevOps', value: 'devops' },
    { label: 'Digital Transformation', value: 'digital_transformation' },
    { label: 'eCommerce', value: 'ecommerce' },
    {
      label: 'Emerging Technology / Innovation',
      value: 'emerging_technology_innovation'
    },
    { label: 'Engineering & Technical', value: 'engineering_technical' },
    { label: 'Industrial Engineering', value: 'industrial_engineering' },
    { label: 'Mobile Development', value: 'mobile_development' },
    { label: 'Product Development', value: 'product_development' }
  ],
  finance: [
    { label: 'Accounting', value: 'accounting' },
    { label: 'Compliance', value: 'compliance' },
    { label: 'Finance', value: 'finance' },
    {
      label: 'Financial Planning & Analysis',
      value: 'financial_planning_analysis'
    },
    { label: 'Financial Reporting', value: 'financial_reporting' },
    { label: 'Financial Strategy', value: 'financial_strategy' },
    { label: 'Financial Systems', value: 'financial_systems' },
    { label: 'Internal Audit & Control', value: 'internal_audit_control' },
    { label: 'Investor Relations', value: 'investor_relations' },
    { label: 'Mergers & Acquisitions', value: 'mergers_acquisitions' },
    { label: 'Real Estate', value: 'real_estate' },
    { label: 'Risk', value: 'risk' },
    { label: 'Shared Services', value: 'shared_services' },
    { label: 'Sourcing / Procurement', value: 'sourcing_procurement' },
    { label: 'Tax', value: 'tax' },
    { label: 'Treasury', value: 'treasury' }
  ],
  human_resources: [
    { label: 'Compensation & Benefits', value: 'compensation_benefits' },
    { label: 'Diversity & Inclusion', value: 'diversity_inclusion' },
    { label: 'Employee & Labor Relations', value: 'employee_labor_relations' },
    { label: 'Health & Safety', value: 'health_safety' },
    {
      label: 'Human Resource Information System',
      value: 'human_resource_information_system'
    },
    { label: 'Human Resources', value: 'human_resources' },
    { label: 'Learning & Development', value: 'learning_development' },
    {
      label: 'Organizational Development',
      value: 'organizational_development'
    },
    {
      label: 'Recruiting & Talent Acquisition',
      value: 'recruiting_talent_acquisition'
    },
    { label: 'Talent Management', value: 'talent_management' },
    { label: 'Workforce Management', value: 'workforce_management' }
  ],
  information_technology: [
    { label: 'Application Development', value: 'application_development' },
    {
      label: 'Business Service Management / ITSM',
      value: 'business_service_management_itsm'
    },
    { label: 'Cloud / Mobility', value: 'cloud_mobility' },
    { label: 'Collaboration & Web Apps', value: 'collaboration_web_apps' },
    { label: 'Data Center', value: 'data_center' },
    { label: 'Data Warehouse', value: 'data_warehouse' },
    { label: 'Database Administration', value: 'database_administration' },
    { label: 'eCommerce', value: 'ecommerce' },
    { label: 'Enterprise Architecture', value: 'enterprise_architecture' },
    {
      label: 'Help Desk / Desktop Services',
      value: 'help_desk_desktop_services'
    },
    {
      label: 'HR / Financial / ERP Systems',
      value: 'hr_financial_erp_systems'
    },
    { label: 'Information Security', value: 'information_security' },
    { label: 'Information Technology', value: 'information_technology' },
    { label: 'Infrastructure', value: 'infrastructure' },
    { label: 'IT Asset Management', value: 'it_asset_management' },
    { label: 'IT Audit / IT Compliance', value: 'it_audit_it_compliance' },
    { label: 'IT Operations', value: 'it_operations' },
    { label: 'IT Procurement', value: 'it_procurement' },
    { label: 'IT Strategy', value: 'it_strategy' },
    { label: 'IT Training', value: 'it_training' },
    { label: 'Networking', value: 'networking' },
    {
      label: 'Project & Program Management',
      value: 'project_program_management'
    },
    { label: 'Quality Assurance', value: 'quality_assurance' },
    { label: 'Retail / Store Systems', value: 'retail_store_systems' },
    { label: 'Servers', value: 'servers' },
    {
      label: 'Storage & Disaster Recovery',
      value: 'storage_disaster_recovery'
    },
    { label: 'Telecommunications', value: 'telecommunications' },
    { label: 'Virtualization', value: 'virtualization' }
  ],
  legal: [
    { label: 'Acquisitions', value: 'acquisitions' },
    { label: 'Compliance', value: 'compliance' },
    { label: 'Contracts', value: 'contracts' },
    { label: 'Corporate Secretary', value: 'corporate_secretary' },
    { label: 'eDiscovery', value: 'ediscovery' },
    { label: 'Ethics', value: 'ethics' },
    { label: 'Governance', value: 'governance' },
    {
      label: 'Governmental Affairs & Regulatory Law',
      value: 'governmental_affairs_regulatory_law'
    },
    {
      label: 'Intellectual Property & Patent',
      value: 'intellectual_property_patent'
    },
    { label: 'Labor & Employment', value: 'labor_employment' },
    { label: 'Lawyer / Attorney', value: 'lawyer_attorney' },
    { label: 'Legal', value: 'legal' },
    { label: 'Legal Counsel', value: 'legal_counsel' },
    { label: 'Legal Operations', value: 'legal_operations' },
    { label: 'Litigation', value: 'litigation' },
    { label: 'Privacy', value: 'privacy' }
  ],
  marketing: [
    { label: 'Advertising', value: 'advertising' },
    { label: 'Brand Management', value: 'brand_management' },
    { label: 'Content Marketing', value: 'content_marketing' },
    { label: 'Customer Experience', value: 'customer_experience' },
    { label: 'Customer Marketing', value: 'customer_marketing' },
    { label: 'Demand Generation', value: 'demand_generation' },
    { label: 'Digital Marketing', value: 'digital_marketing' },
    { label: 'eCommerce', value: 'ecommerce' },
    { label: 'Event Marketing', value: 'event_marketing' },
    { label: 'Field Marketing', value: 'field_marketing' },
    { label: 'Graphic Design', value: 'graphic_design' },
    { label: 'Lead Generation', value: 'lead_generation' },
    { label: 'Marketing', value: 'marketing' },
    {
      label: 'Marketing Analytics / Insights',
      value: 'marketing_analytics_insights'
    },
    { label: 'Marketing Communications', value: 'marketing_communications' },
    { label: 'Marketing Operations', value: 'marketing_operations' },
    { label: 'Product Marketing', value: 'product_marketing' },
    { label: 'Public Relations (PR)', value: 'public_relations_pr' },
    { label: 'CompanyRevenue Operations', value: 'revenue_operations' },
    {
      label: 'Search Engine Optimization / Pay Per Click',
      value: 'search_engine_optimization_pay_per_click'
    },
    { label: 'Social Media Marketing', value: 'social_media_marketing' },
    { label: 'Strategic Communications', value: 'strategic_communications' },
    { label: 'Technical Marketing', value: 'technical_marketing' }
  ],
  medical_health: [
    { label: 'Anesthesiology', value: 'anesthesiology' },
    { label: 'Chiropractics', value: 'chiropractics' },
    { label: 'Clinical Operations', value: 'clinical_operations' },
    { label: 'Clinical Systems', value: 'clinical_systems' },
    { label: 'Dentistry', value: 'dentistry' },
    { label: 'Dermatology', value: 'dermatology' },
    { label: 'Doctors / Physicians', value: 'doctors_physicians' },
    { label: 'Epidemiology', value: 'epidemiology' },
    { label: 'First Responder', value: 'first_responder' },
    { label: 'Infectious Disease', value: 'infectious_disease' },
    { label: 'Medical Administration', value: 'medical_administration' },
    {
      label: 'Medical Education & Training',
      value: 'medical_education_training'
    },
    { label: 'Medical Research', value: 'medical_research' },
    { label: 'Medicine', value: 'medicine' },
    { label: 'Neurology', value: 'neurology' },
    { label: 'Nursing', value: 'nursing' },
    { label: 'Nutrition & Dietetics', value: 'nutrition_dietetics' },
    { label: 'Obstetrics / Gynecology', value: 'obstetrics_gynecology' },
    { label: 'Oncology', value: 'oncology' },
    { label: 'Ophthalmology', value: 'ophthalmology' },
    { label: 'Optometry', value: 'optometry' },
    { label: 'Orthopedics', value: 'orthopedics' },
    { label: 'Pathology', value: 'pathology' },
    { label: 'Pediatrics', value: 'pediatrics' },
    { label: 'Pharmacy', value: 'pharmacy' },
    { label: 'Physical Therapy', value: 'physical_therapy' },
    { label: 'Psychiatry', value: 'psychiatry' },
    { label: 'Psychology', value: 'psychology' },
    { label: 'Public Health', value: 'public_health' },
    { label: 'Radiology', value: 'radiology' },
    { label: 'Social Work', value: 'social_work' },
    { label: 'Surgery', value: 'surgery' }
  ],
  operations: [
    { label: 'Call Center', value: 'call_center' },
    { label: 'Construction', value: 'construction' },
    { label: 'Corporate Strategy', value: 'corporate_strategy' },
    { label: 'Customer Service / Support', value: 'customer_service_support' },
    {
      label: 'Enterprise Resource Planning',
      value: 'enterprise_resource_planning'
    },
    { label: 'Facilities Management', value: 'facilities_management' },
    { label: 'Leasing', value: 'leasing' },
    { label: 'Logistics', value: 'logistics' },
    { label: 'Office Operations', value: 'office_operations' },
    { label: 'Operations', value: 'operations' },
    { label: 'Physical Security', value: 'physical_security' },
    { label: 'Project Development', value: 'project_development' },
    { label: 'Quality Management', value: 'quality_management' },
    { label: 'Real Estate', value: 'real_estate' },
    { label: 'Safety', value: 'safety' },
    { label: 'Store Operations', value: 'store_operations' },
    { label: 'Supply Chain', value: 'supply_chain' }
  ],
  sales: [
    { label: 'Account Management', value: 'account_management' },
    { label: 'Business Development', value: 'business_development' },
    { label: 'Channel Sales', value: 'channel_sales' },
    {
      label: 'Customer Retention & Development',
      value: 'customer_retention_development'
    },
    { label: 'Customer Success', value: 'customer_success' },
    { label: 'Field / Outside Sales', value: 'field_outside_sales' },
    { label: 'Inside Sales', value: 'inside_sales' },
    { label: 'Partnerships', value: 'partnerships' },
    { label: 'CompanyRevenue Operations', value: 'revenue_operations' },
    { label: 'Sales', value: 'sales' },
    { label: 'Sales Enablement', value: 'sales_enablement' },
    { label: 'Sales Engineering', value: 'sales_engineering' },
    { label: 'Sales Operations', value: 'sales_operations' },
    { label: 'Sales Training', value: 'sales_training' }
  ]
};

export const departmentOptionsHash = fromPairs(
  departmentOptions.map(({ value, label }) => [value, label])
);

export const workplaceOptions: PostJobPageOptionsType[] = [
  { label: 'On-site', value: 'on_site' },
  { label: 'Hybrid', value: 'hybrid' },
  { label: 'Remote', value: 'remote' }
];

export const workplaceOptionsHash = fromPairs(
  workplaceOptions.map(({ value, label }) => [value, label])
);

export const geographyOptions: PostJobPageOptionsType[] = [
  { label: 'North', value: 'north' },
  { label: 'South', value: 'south' },
  { label: 'West', value: 'west' },
  { label: 'East', value: 'east' }
];

export const geographyOptionsHash = fromPairs(
  geographyOptions.map(({ value, label }) => [value, label])
);

export const numberOfOpeningsOptions: PostJobPageOptionsType[] = Array.from(
  Array(10).keys()
)?.map((item) => ({ value: String(item + 1), label: String(item + 1) }));

export const numberOfOpeningsOptionsHash = fromPairs(
  numberOfOpeningsOptions.map(({ value, label }) => [value, label])
);

export const workAuthorizationOptions: PostJobPageOptionsType[] = [
  { label: 'US Citizen', value: 'us_citizen' },
  { label: 'Green Card Holder', value: 'green_card_holder' },
  { label: 'H Visa', value: 'h_visa' },
  { label: 'I Visa', value: 'i_visa' },
  { label: 'L Visa', value: 'l_visa' },
  { label: 'O Visa', value: 'o_visa' },
  { label: 'P Visa', value: 'p_visa' },
  { label: 'R Visa', value: 'r_visa' },
  { label: 'TN Visa', value: 'tn_visa' },
  { label: '1st EB-1', value: '1st_eb_1' },
  { label: '2nd EB-2', value: '2nd_eb_2' },
  { label: '3rd EB-3', value: '3rd_eb_3' },
  { label: '4th Eb-4', value: '4th_eb_4' },
  { label: '5th EB-5', value: '5th_eb_5' },
  { label: 'F Visa', value: 'f_visa' },
  { label: 'M Visa', value: 'm_visa' },
  { label: 'J Visa', value: 'j_visa' },
  { label: 'B-1 Visa', value: 'b_1_visa' }
];

export const workAuthorizationOptionsHash = fromPairs(
  workAuthorizationOptions.map(({ value, label }) => [value, label])
);

export const employmentOptions: PostJobPageOptionsType[] = [
  { label: 'Full-time', value: 'full_time' },
  { label: 'Full-time', value: 'fulltime' },
  { label: 'Part-time', value: 'part_time' },
  { label: 'Contract', value: 'contract' },
  { label: 'Intern', value: 'intern' }
];

export const employmentOptionsHash = fromPairs(
  employmentOptions.map(({ value, label }) => [value, label])
);

export const baseSalaryOptions: PostJobPageOptionsType[] = [
  { label: '$25k - $35k', value: '25k_35k' },
  { label: '$35k - $45k', value: '35k_45k' },
  { label: '$45k - $55k', value: '45k_55k' },
  { label: '$55k - $65k', value: '55k_65k' },
  { label: '$65k - $75k', value: '65k_75k' },
  { label: '$75k - $85k', value: '75k_85k' },
  { label: '$85k - $95k', value: '85k_95k' },
  { label: '$95k - $100k', value: '95k_100k' },
  { label: '$100k - $125k', value: '100k_125k' },
  { label: '$125k - $150k', value: '125k_150k' },
  { label: '$150k - $175k', value: '150k_175k' },
  { label: '$175k - $200k', value: '175k_200k' },
  { label: '$200k - $225k', value: '200k_225k' },
  { label: '$225k - $250K', value: '225k_250k' },
  { label: '$250k - $300k', value: '250k_300k' },
  { label: '$300k<', value: '300k' }
];

export const baseSalaryOptionsHash = fromPairs(
  baseSalaryOptions.map(({ value, label }) => [value, label])
);

export const experienceOptions: PostJobPageOptionsType[] = [
  { label: 'None', value: 'none' },
  { label: '1yr - 3yrs', value: '1yr_3yr' },
  { label: '3yrs - 5yrs', value: '3yrs_5yrs' },
  { label: '5yrs - 8yrs', value: '5yrs_8yrs' },
  { label: '8yrs - 12yrs', value: '8yrs_12yrs' },
  { label: '12yrs - 15 yrs', value: '12yrs_15yrs' },
  { label: '15 yrs+', value: '15_yrs' }
];

export const experienceOptionsHash = fromPairs(
  experienceOptions.map(({ value, label }) => [value, label])
);

export const seniorityLevelOptions: PostJobPageOptionsType[] = [
  { label: 'Intern', value: 'intern' },
  { label: 'Graduate', value: 'graduate' },
  { label: 'Junior', value: 'junior' },
  { label: 'Staff', value: 'staff' },
  { label: 'Senior', value: 'senior' },
  { label: 'Lead', value: 'lead' },
  { label: 'Manager', value: 'manager' },
  { label: 'Director', value: 'director' },
  { label: 'Vice President', value: 'vice_president' },
  { label: 'Executive', value: 'executive' }
];

export const seniorityLevelOptionsHash = fromPairs(
  seniorityLevelOptions.map(({ value, label }) => [value, label])
);

export const jobsInitialPageSize = 18 as FetchJobsPageSize;
