import { useCallback, useEffect, useRef, MutableRefObject } from 'react';

type UseScrollPositionProps = {
  setIsInsideViewport: () => void;
  setIsOutsideViewport: () => void;
};

function useScrollPosition({
  setIsInsideViewport,
  setIsOutsideViewport
}: UseScrollPositionProps): MutableRefObject<HTMLDivElement | null> {
  const containerRef = useRef<HTMLDivElement | null>(null);

  const handleScroll = useCallback(() => {
    if (containerRef.current) {
      const targetPosition =
        containerRef.current.getBoundingClientRect().top + window.scrollY;
      const scrollPosition =
        window.scrollY || document.documentElement.scrollTop;

      if (scrollPosition > targetPosition) {
        setIsOutsideViewport();
      } else {
        setIsInsideViewport();
      }
    }
  }, [setIsInsideViewport, setIsOutsideViewport]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, {
      passive: true,
      capture: true
    });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return containerRef;
}

export default useScrollPosition;
