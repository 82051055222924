import {
  useReactHookForm,
  ReactHookFormFieldError
} from '../../../../../../../common/hooks/base/useReactHookForm';

import {
  CandidateShareFields,
  CandidateShareMessage,
  CandidateShareShareSettings,
  CandidateShareCandidateShareInvitations
} from '../../../../../candidateSharesTypes';

import { useAddCandidateShareFormValidationRules } from '../useAddCandidateShareFormValidationRules';

import {
  OnAddCandidateShareForm,
  AddCandidateShareFormData
} from '../../AddCandidateShareForm.types';

const defaultAddCandidateShareValues: AddCandidateShareFormData = {
  autoJoin: false,
  message: '' as CandidateShareMessage,
  recipientIds: [],
  shareSettings: {} as CandidateShareShareSettings,
  candidateShareInvitations:
    [] as unknown as CandidateShareCandidateShareInvitations
};

interface AddCandidateShareFormOptions {
  defaultValues?: Partial<AddCandidateShareFormData>;
  onAddCandidateShare: OnAddCandidateShareForm;
}

function useAddCandidateShareForm({
  defaultValues = {},
  onAddCandidateShare
}: AddCandidateShareFormOptions) {
  const {
    control,
    watch,
    errors,
    isLoading,
    errorMessage,
    register,
    resetForm,
    handleSubmitReactHookForm
  } = useReactHookForm<AddCandidateShareFormData>({
    defaultValues: { ...defaultAddCandidateShareValues, ...defaultValues }
  });

  const { messageRules } = useAddCandidateShareFormValidationRules();

  return {
    control,
    watch,
    validationErrors: {
      autoJoinValidationError: (errors.autoJoin as ReactHookFormFieldError)
        ?.message,
      messageValidationError: (errors.message as ReactHookFormFieldError)
        ?.message,
      recipientIdsValidationError: (
        errors.recipientIds as ReactHookFormFieldError
      )?.message,
      shareSettingsValidationError: (
        errors.shareSettings as ReactHookFormFieldError
      )?.message,
      candidateShareInvitationsValidationErrors:
        errors.candidateShareInvitations as unknown as {
          [key: string]: ReactHookFormFieldError;
        }[]
    },
    addCandidateShareFormIsLoading: isLoading,
    addCandidateShareFormErrorMessage: errorMessage,
    resetAddCandidateShareForm: resetForm,
    registerFields: {
      registerAutoJoin: register(CandidateShareFields.AUTO_JOIN),
      registerMessage: register(CandidateShareFields.MESSAGE, messageRules),
      registerRecipientIds: register(CandidateShareFields.RECIPIENT_IDS),
      registerShareSettings: register(CandidateShareFields.SHARE_SETTINGS),
      registerCandidateShareInvitations: register(
        CandidateShareFields.CANDIDATE_SHARE_INVITATIONS
      )
    },
    handleAddCandidateShare: handleSubmitReactHookForm({
      onSubmit: (data) =>
        onAddCandidateShare?.({
          autoJoin: data.autoJoin,
          message: data.message as CandidateShareMessage,
          recipientIds: data.recipientIds,
          shareSettings: data.shareSettings as CandidateShareShareSettings,
          candidateShareInvitations:
            data.candidateShareInvitations as CandidateShareCandidateShareInvitations
        })
    })
  };
}

export default useAddCandidateShareForm;
