import { useMutation, useQueryClient } from 'react-query';

import { IsLoading } from '../../../../types';

import { CreateUserErrorResponse } from '../../UsersRequests';

import { UserId, FetchUsersCacheKey } from '../../usersTypes';

import {
  UsersBffRequests,
  UpdateCompanyRequestData
} from '../../UsersBffRequests';

import { parseRequestError } from '../../../../utils/parseRequestError';

interface UpdateCompanyOptions {
  userId: UserId;
  cacheKeys?: (FetchUsersCacheKey | string)[];
}

interface UpdateCompanyData {
  companyNanoId: UpdateCompanyRequestData['companyNanoId'];
}

function useUpdateCompany({ userId, cacheKeys = [] }: UpdateCompanyOptions) {
  const queryClient = useQueryClient();

  const { data, error, isLoading, mutateAsync, reset } = useMutation<
    UpdateCompanyData,
    CreateUserErrorResponse,
    UpdateCompanyData
  >(async (queryInput) => UsersBffRequests.updateCompany(userId, queryInput), {
    onSettled: async () =>
      cacheKeys?.map((cacheKey) => queryClient.invalidateQueries(cacheKey))
  });

  return {
    updateCompanyData: data,
    updateCompanyError: error,
    updateCompanyErrorMessage: parseRequestError(error),
    updateCompanyIsLoading: isLoading as IsLoading,
    updateCompany: (updateCompanyData: UpdateCompanyData) =>
      mutateAsync(updateCompanyData),
    updateCompanyReset: () => reset()
  };
}

export default useUpdateCompany;
