import { customAlphabet } from 'nanoid';

const defaultSize = 8;

const nanoid = customAlphabet(
  '123456789ABCDEFGHIJKLMNPQRSTUVWXYZ',
  defaultSize
);

function generateNanoId<T extends string>(size = defaultSize): T {
  return nanoid(size) as T;
}

export default generateNanoId;
