import {
  FetchJobCandidatesCompletedWorkflowStageTasksQuery,
  FetchJobCandidatesCompletedWorkflowStageTasksCountType,
  FetchJobCandidatesCompletedWorkflowStageTasksPage,
  FetchJobCandidatesCompletedWorkflowStageTasksPageSize,
  FetchJobCandidatesCompletedWorkflowStageTasksFilters,
  FetchJobCandidatesCompletedWorkflowStageTasksSort,
  FetchJobCandidatesCompletedWorkflowStageTaskQuery,
  CreateJobCandidatesCompletedWorkflowStageTaskError,
  UpdateJobCandidatesCompletedWorkflowStageTaskError,
  DeleteJobCandidatesCompletedWorkflowStageTaskError,
  JobCandidatesCompletedWorkflowStageTaskId,
  JobCandidatesCompletedWorkflowStageTaskJobId,
  JobCandidatesCompletedWorkflowStageTaskJobCandidateId,
  JobCandidatesCompletedWorkflowStageTaskWorkflowStageTaskId,
  FetchJobCandidatesCompletedWorkflowStageTasksIndexCountType,
  FetchJobCandidatesCompletedWorkflowStageTasksIndexPage,
  FetchJobCandidatesCompletedWorkflowStageTasksIndexPageSize,
  FetchJobCandidatesCompletedWorkflowStageTasksIndexFilters,
  FetchJobCandidatesCompletedWorkflowStageTasksIndexSort,
  FetchJobCandidatesCompletedWorkflowStageTaskIndexQueryName,
  FetchJobCandidatesCompletedWorkflowStageTasksIndexQueryName,
  JobCandidatesCompletedWorkflowStageTaskUUID
} from './jobCandidatesCompletedWorkflowStageTasksTypes';

import { BaseBffRequests } from '../BaseBffRequests';

import { JobCandidatesCompletedWorkflowStageTasksBffRoutes } from './JobCandidatesCompletedWorkflowStageTasksBffRoutes';

interface FetchJobCandidatesCompletedWorkflowStageTasksRequestParams {
  query: FetchJobCandidatesCompletedWorkflowStageTasksQuery;
  countType?: FetchJobCandidatesCompletedWorkflowStageTasksCountType;
  page?: FetchJobCandidatesCompletedWorkflowStageTasksPage;
  pageSize?: FetchJobCandidatesCompletedWorkflowStageTasksPageSize;
  filters?: FetchJobCandidatesCompletedWorkflowStageTasksFilters;
  sort?: FetchJobCandidatesCompletedWorkflowStageTasksSort;
}

interface FetchJobCandidatesCompletedWorkflowStageTasksIndexRequestParams {
  queryName: FetchJobCandidatesCompletedWorkflowStageTasksIndexQueryName;
  countType?: FetchJobCandidatesCompletedWorkflowStageTasksIndexCountType;
  page?: FetchJobCandidatesCompletedWorkflowStageTasksIndexPage;
  pageSize?: FetchJobCandidatesCompletedWorkflowStageTasksIndexPageSize;
  filters?: FetchJobCandidatesCompletedWorkflowStageTasksIndexFilters;
  sort?: FetchJobCandidatesCompletedWorkflowStageTasksIndexSort;
}

interface FetchJobCandidatesCompletedWorkflowStageTaskRequestParams {
  query: FetchJobCandidatesCompletedWorkflowStageTaskQuery;
}

interface FetchJobCandidatesCompletedWorkflowStageTaskIndexRequestParams {
  queryName: FetchJobCandidatesCompletedWorkflowStageTaskIndexQueryName;
}

export interface JobCandidatesCompletedWorkflowStageTaskRequestData {
  jobId?: JobCandidatesCompletedWorkflowStageTaskJobId;
  jobCandidateId?: JobCandidatesCompletedWorkflowStageTaskJobCandidateId;
  workflowStageTaskId?: JobCandidatesCompletedWorkflowStageTaskWorkflowStageTaskId;
}

export interface CreateJobCandidatesCompletedWorkflowStageTaskRequestData
  extends JobCandidatesCompletedWorkflowStageTaskRequestData {
  id?: JobCandidatesCompletedWorkflowStageTaskId;
}

export interface UpdateJobCandidatesCompletedWorkflowStageTaskRequestData
  extends JobCandidatesCompletedWorkflowStageTaskRequestData {
  id?: JobCandidatesCompletedWorkflowStageTaskId;
}

export interface CreateJobCandidatesCompletedWorkflowStageTaskErrorResponse {
  message: CreateJobCandidatesCompletedWorkflowStageTaskError;
}

export interface UpdateJobCandidatesCompletedWorkflowStageTaskErrorResponse {
  message: UpdateJobCandidatesCompletedWorkflowStageTaskError;
}

export interface DeleteJobCandidatesCompletedWorkflowStageTaskErrorResponse {
  message: DeleteJobCandidatesCompletedWorkflowStageTaskError;
}

export class JobCandidatesCompletedWorkflowStageTasksBffRequests extends BaseBffRequests {
  static fetchJobCandidatesCompletedWorkflowStageTasks<T>(
    params: FetchJobCandidatesCompletedWorkflowStageTasksRequestParams
  ) {
    return this.fetchItems<T>(
      JobCandidatesCompletedWorkflowStageTasksBffRoutes.fetchJobCandidatesCompletedWorkflowStageTasksRoute(),
      params
    );
  }

  static fetchJobCandidatesCompletedWorkflowStageTasksIndex<T>(
    params: FetchJobCandidatesCompletedWorkflowStageTasksIndexRequestParams
  ) {
    return this.fetchItemsIndex<T>(
      JobCandidatesCompletedWorkflowStageTasksBffRoutes.fetchJobCandidatesCompletedWorkflowStageTasksIndexRoute(),
      params
    );
  }

  static fetchJobCandidatesCompletedWorkflowStageTask<T>(
    jobCandidatesCompletedWorkflowStageTaskId: JobCandidatesCompletedWorkflowStageTaskId,
    params: FetchJobCandidatesCompletedWorkflowStageTaskRequestParams
  ) {
    return this.fetchItem<T>(
      JobCandidatesCompletedWorkflowStageTasksBffRoutes.fetchJobCandidatesCompletedWorkflowStageTaskRoute(
        jobCandidatesCompletedWorkflowStageTaskId
      ),
      params
    );
  }

  static fetchJobCandidatesCompletedWorkflowStageTaskIndex<T>(
    jobCandidatesCompletedWorkflowStageTaskUuid: JobCandidatesCompletedWorkflowStageTaskUUID,
    params: FetchJobCandidatesCompletedWorkflowStageTaskIndexRequestParams
  ) {
    return this.fetchItemIndex<T>(
      JobCandidatesCompletedWorkflowStageTasksBffRoutes.fetchJobCandidatesCompletedWorkflowStageTaskIndexRoute(
        jobCandidatesCompletedWorkflowStageTaskUuid
      ),
      params
    );
  }

  static createJobCandidatesCompletedWorkflowStageTask<T>(
    createJobCandidatesCompletedWorkflowStageTaskData: CreateJobCandidatesCompletedWorkflowStageTaskRequestData
  ) {
    return this.createItem<
      T,
      CreateJobCandidatesCompletedWorkflowStageTaskRequestData
    >(
      JobCandidatesCompletedWorkflowStageTasksBffRoutes.createJobCandidatesCompletedWorkflowStageTaskRoute(),
      createJobCandidatesCompletedWorkflowStageTaskData
    );
  }

  static async updateJobCandidatesCompletedWorkflowStageTask<T>(
    updateJobCandidatesCompletedWorkflowStageTaskId: JobCandidatesCompletedWorkflowStageTaskId,
    updateJobCandidatesCompletedWorkflowStageTaskData: UpdateJobCandidatesCompletedWorkflowStageTaskRequestData
  ) {
    return this.updateItem<
      T,
      UpdateJobCandidatesCompletedWorkflowStageTaskRequestData
    >(
      JobCandidatesCompletedWorkflowStageTasksBffRoutes.updateJobCandidatesCompletedWorkflowStageTaskRoute(
        updateJobCandidatesCompletedWorkflowStageTaskId
      ),
      updateJobCandidatesCompletedWorkflowStageTaskData
    );

    // if (error) {
    //   throw error;
    // }

    // return data;
  }

  static async deleteJobCandidatesCompletedWorkflowStageTask<T>(
    deleteJobCandidatesCompletedWorkflowStageTaskId: JobCandidatesCompletedWorkflowStageTaskId
  ) {
    return this.deleteItem<T>(
      JobCandidatesCompletedWorkflowStageTasksBffRoutes.deleteJobCandidatesCompletedWorkflowStageTaskRoute(
        deleteJobCandidatesCompletedWorkflowStageTaskId
      )
    );

    // if (error) {
    //   throw error;
    // }

    // return data;
  }
}
