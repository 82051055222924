import { FetchCompaniesCacheKey, FetchCompanyCacheKey } from './companiesTypes';

export class CompaniesCache {
  static indexCacheKey() {
    return 'companies' as FetchCompaniesCacheKey;
  }

  static showCacheKey() {
    return 'company' as FetchCompanyCacheKey;
  }

  static signUpCacheKey() {
    return 'companies-signUp' as FetchCompaniesCacheKey;
  }

  static postJobCacheKey() {
    return 'companies-post-job' as FetchCompaniesCacheKey;
  }

  static talentPoolFormCacheKey() {
    return 'companies-talent-pool-form' as FetchCompaniesCacheKey;
  }
}
