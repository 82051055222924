import { useEffect } from 'react';
import { FieldValues } from 'react-hook-form';
import debounce from 'lodash/debounce';

import { ErrorMessage, IsDisabled, IsRequired } from '../../../../../types';

import { JobFields, JobRecruiterId } from '../../../jobsTypes';

import {
  UserFields,
  UserCaptureTypes,
  FetchUsersFilters,
  FetchUsersSort
} from '../../../../users/usersTypes';

import {
  fetchUsersQuery,
  FetchUsersResponse
} from '../../../../users/queries/fetchUsers.query';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';
import { usePaginatedUsers } from '../../../../users/hooks/usePaginatedUsers';

import { UsersCache } from '../../../../users/UsersCache';

import {
  SelectField,
  SelectFieldReactHookFormControl,
  SelectFieldReactHookFormFieldPath
} from '../../../../../helpers/forms/formFields/SelectField';

import { ImagesUrl } from '../../../../images/ImagesUrl';

import { JobSelectRecruiterFieldJob } from './JobSelectRecruiterField.types';

interface JobSelectRecruiterFieldProps<T extends FieldValues> {
  isRequired?: IsRequired;
  isDisabled?: IsDisabled;
  control: SelectFieldReactHookFormControl<T>;
  job?: JobSelectRecruiterFieldJob | null;
  currentJobRecruiterId?: JobRecruiterId | null;
  errorMessage: ErrorMessage;
  showAddForm?: () => void;
}

function JobSelectRecruiterField<T extends FieldValues>({
  isRequired,
  isDisabled,
  control,
  job,
  currentJobRecruiterId,
  errorMessage,
  showAddForm
}: JobSelectRecruiterFieldProps<T>) {
  const currentUser = useCurrentUser();

  const { users, usersIsLoading, usersErrorMessage, changeUsersFilters } =
    usePaginatedUsers<FetchUsersResponse>({
      query: fetchUsersQuery,
      cacheKey: UsersCache.postJobCacheKey(),
      initialFilters: {
        [UserFields.CAPTURE_TYPE]: {
          operator: 'eq',
          value: UserCaptureTypes.RECRUITER
        },
        [UserFields.COMPANY_ID]: {
          operator: 'eq',
          value: currentUser.companyId
        }
      } as unknown as FetchUsersFilters,
      initialSort: {
        [UserFields.NAME]: { ascending: true }
      } as unknown as FetchUsersSort
    });

  useEffect(() => {
    if (!currentJobRecruiterId && job?.recruiter?.name) {
      changeUsersFilters({
        [UserFields.NAME]: {
          operator: 'ilike',
          value: `%${job?.recruiter?.name}%`
        }
      });
    }
  }, [currentJobRecruiterId, job?.recruiter?.name, changeUsersFilters]);

  const defaultJobRecruiter = job?.recruiter
    ? {
        value: job.recruiter.id,
        label: job.recruiter.name,
        image: ImagesUrl.file(job.recruiter.image)
      }
    : undefined;

  const debouncedFilterUsers = debounce<(updatedValue: string) => void>(
    (updatedValue) =>
      changeUsersFilters({
        [UserFields.NAME]: {
          operator: 'ilike',
          value: `%${updatedValue}%`
        }
      }),
    500
  );

  return (
    <SelectField
      withImages
      withAddNewButton
      addNewButtonLabel="Add Recruiter"
      addNewButtonAction={showAddForm}
      isRequired={isRequired}
      isDisabled={isDisabled}
      control={control}
      label="Recruiter"
      placeholder="Select"
      name={JobFields.RECRUITER_ID as SelectFieldReactHookFormFieldPath<T>}
      options={users.map((user) => ({
        value: user.id,
        label: user.name,
        image: ImagesUrl.file(user.image)
      }))}
      defaultValue={defaultJobRecruiter}
      errorMessage={errorMessage || usersErrorMessage}
      isLoading={usersIsLoading}
      onInputChange={debouncedFilterUsers}
    />
  );
}

export default JobSelectRecruiterField;
