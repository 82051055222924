import { Icon, IconProps } from '@chakra-ui/icon';

const BellIcon = ({
  w = '20px',
  h = '20px',
  fill = '#697275',
  ...props
}: IconProps) => (
  <Icon viewBox="0 0 24 24" w={w} h={h} fill={fill} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.218 19.172c0 1.288-.963 2.273-2.222 2.273-1.26 0-2.223-.985-2.223-2.273M17.182 12.759V8.97c0-2.955-2.296-5.303-5.185-5.303-2.89 0-5.185 2.348-5.185 5.303v3.788c0 3.03-1.482 5.303-1.482 5.303h13.333s-1.481-2.273-1.481-5.303Z"
      // fill={fill as string}
    />
  </Icon>
);

export default BellIcon;
