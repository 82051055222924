import { Icon, IconProps } from '@chakra-ui/icon';

const AvatarPlaceholderIcon = ({
  w = '128px',
  h = '128px',
  width,
  height,
  borderRadius = 'full',
  ...props
}: IconProps) => (
  <Icon
    viewBox="0 0 128 128"
    fill="none"
    w={w || width}
    h={h || height}
    {...props}
    border="1px dashed"
    borderColor="gray.500"
    boxShadow="0px 7.20502px 12.3515px rgba(0, 0, 0, 0.04)"
    borderRadius={borderRadius}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M54.67 49.328h-5.334v2.667h5.333v-2.667ZM66.667 63.995h-1.334V62.66a1.333 1.333 0 1 0-2.666 0v1.334h-1.334a1.333 1.333 0 1 0 0 2.667h1.334v1.333a1.333 1.333 0 1 0 2.666 0V66.66h1.334a1.333 1.333 0 1 0 0-2.666"
      fill="#98A5A9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.003 71.997a6.667 6.667 0 1 1 .001-13.334 6.667 6.667 0 0 1-.001 13.334Zm14.664-18.669H71.38l-2.27-3.407a1.335 1.335 0 0 0-1.11-.593h-8c-.445 0-.861.223-1.11.593l-2.27 3.407h-7.287c-.737 0-1.333.597-1.333 1.333v22.667c0 .736.596 1.334 1.333 1.334h29.334c.737 0 1.333-.598 1.333-1.334V54.661c0-.736-.596-1.333-1.333-1.333Z"
      fill="#98A5A9"
    />
  </Icon>
);

export default AvatarPlaceholderIcon;
