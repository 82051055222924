import { Icon, IconProps } from '@chakra-ui/icon';

const LinkedInIcon = ({
  w = '20px',
  h = '20px',
  fill = '#069',
  width,
  height,
  ...props
}: IconProps) => (
  <Icon
    viewBox="0 0 20 20"
    w={w || width}
    h={h || height}
    fill={fill}
    {...props}
  >
    <path d="M0 1.466C0 .678.662.039 1.478.039h17.044C19.338.04 20 .68 20 1.466v17.066c0 .788-.662 1.427-1.478 1.427H1.478C.662 19.96 0 19.32 0 18.533V1.466Z" />
    <path
      d="M6.075 16.713V7.745H3.083v8.968h2.993ZM4.58 6.521c1.043 0 1.693-.689 1.693-1.55-.02-.88-.65-1.55-1.673-1.55-1.025 0-1.694.67-1.694 1.55 0 .861.65 1.55 1.654 1.55h.02Zm3.152 10.192h2.993v-5.008c0-.267.02-.536.098-.727.216-.536.709-1.09 1.536-1.09 1.082 0 1.516.822 1.516 2.028v4.797h2.992v-5.142c0-2.754-1.476-4.036-3.445-4.036-1.614 0-2.323.898-2.717 1.51h.02v-1.3H7.732c.04.842 0 8.968 0 8.968Z"
      fill="#fff"
    />
  </Icon>
);
export default LinkedInIcon;
