import {
  supabaseClient,
  SupabaseClient
} from '@supabase/supabase-auth-helpers/nextjs';
import camelcaseKeys from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';
import keys from 'lodash/keys';
import snakeCase from 'lodash/snakeCase';

import {
  FetchItemsTable,
  FetchItemsQuery,
  FetchItemsCountType,
  FetchItemsPage,
  FetchItemsPageSize,
  FetchItemsFilters,
  FetchItemsSort,
  FetchItemsTotalCount,
  FetchItemTable,
  FetchItemQuery,
  FetchItemID,
  FetchItemNanoID,
  CreateItemTable,
  UpdateItemTable,
  UpdateItemID,
  UpdateItemNanoID,
  DeleteItemTable,
  DeleteItemID,
  DeleteItemNanoID
} from '../types';

import {
  INITIAL_FILTERS,
  INITIAL_PAGE,
  INITIAL_PAGE_SIZE,
  INITIAL_SORT
} from '../constants';

interface FetchItemsOptions {
  query: FetchItemsQuery;
  countType?: FetchItemsCountType;
  page?: FetchItemsPage;
  pageSize?: FetchItemsPageSize;
  filters?: FetchItemsFilters;
  sort?: FetchItemsSort;
}

export interface CreateItemOptions {
  providedSupabaseClient?: SupabaseClient;
}

export interface UpdateItemOptions<T> {
  providedSupabaseClient?: SupabaseClient;
  filterColumn?: keyof T;
}

export class BaseRequests {
  static async fetchItems<T>(
    itemsTable: FetchItemsTable,
    {
      query,
      countType,
      page = INITIAL_PAGE,
      pageSize = INITIAL_PAGE_SIZE,
      filters = INITIAL_FILTERS,
      sort = INITIAL_SORT
    }: FetchItemsOptions
  ) {
    const request = supabaseClient
      .from<T>(snakeCase(itemsTable))
      .select(query, { count: countType })
      .range(
        Math.round((page - 1) * pageSize),
        Math.round(page * pageSize) - 1
      );

    keys(filters).map((key) => {
      request.filter(
        (/\./.test(key) ? key : snakeCase(key)) as keyof T,
        filters[key].operator,
        filters[key].value
      );
    });

    keys(sort).map((key) => {
      request.order(snakeCase(key) as keyof T, sort[key]);
    });

    const { data: items, count: itemsCount, error } = await request;

    if (error) {
      throw error;
    }

    return {
      data: (items || ([] as T[])).map((item) =>
        camelcaseKeys(item as Record<string, unknown>, { deep: true })
      ) as T[],
      totalCount: itemsCount as FetchItemsTotalCount
    };
  }

  static async fetchItem<T>(
    fetchItemTable: FetchItemTable,
    fetchItemID: FetchItemID,
    {
      query,
      filterColumn = 'id' as keyof T
    }: {
      query: FetchItemQuery;
      filterColumn?: keyof T;
    }
  ) {
    const { data: item, error } = await supabaseClient
      .from<T>(snakeCase(fetchItemTable))
      .select(query)
      .filter(filterColumn, 'eq', fetchItemID)
      .single();

    if (error) {
      throw error;
    }

    return item ? (camelcaseKeys(item, { deep: true }) as T) : null;
  }

  static async fetchItemByNanoId<T>(
    fetchItemTable: FetchItemTable,
    fetchItemNanoID: FetchItemNanoID,
    {
      query,
      filterColumn = 'nano_id' as keyof T
    }: {
      query: FetchItemQuery;
      filterColumn?: keyof T;
    }
  ) {
    const { data: item, error } = await supabaseClient
      .from<T>(snakeCase(fetchItemTable))
      .select(query)
      .filter(filterColumn, 'eq', fetchItemNanoID)
      .single();

    if (error) {
      throw error;
    }

    return item ? (camelcaseKeys(item, { deep: true }) as T) : null;
  }

  static async createItem<T extends object>(
    createItemTable: CreateItemTable,
    createItemData: T,
    { providedSupabaseClient }: CreateItemOptions = {}
  ) {
    const { data: item, error } = await (
      providedSupabaseClient || supabaseClient
    )
      .from<T>(snakeCase(createItemTable))
      .insert([snakeCaseKeys(createItemData, { deep: true })])
      .single();

    if (error) {
      throw error;
    }

    return camelcaseKeys(item, { deep: true }) as T;
  }

  static async updateItem<T extends object>(
    updateItemTable: UpdateItemTable,
    updateItemID: UpdateItemID | UpdateItemNanoID,
    updateItemData: T,
    { filterColumn = 'id' as keyof T }: UpdateItemOptions<T>
  ) {
    const { data: item, error } = await supabaseClient
      .from<T>(snakeCase(updateItemTable))
      .update(snakeCaseKeys(updateItemData, { deep: true }))
      .match({
        [snakeCase(filterColumn as string)]: updateItemID
      })
      .single();

    if (error) {
      throw error;
    }

    return camelcaseKeys(item, { deep: true }) as T;
  }

  static async upsertItems<T extends object>(
    updateItemTable: UpdateItemTable,
    updateItemID: UpdateItemID | UpdateItemNanoID,
    updateItemData: T,
    { filterColumn = 'id' as keyof T }: UpdateItemOptions<T>
  ) {
    const { data: item, error } = await supabaseClient
      .from<T>(snakeCase(updateItemTable))
      .upsert(snakeCaseKeys(updateItemData, { deep: true }))
      .match({
        [snakeCase(filterColumn as string)]: updateItemID
      })
      .single();

    if (error) {
      throw error;
    }

    return camelcaseKeys(item, { deep: true }) as T;
  }

  static async deleteItem(
    deleteItemTable: DeleteItemTable,
    deleteItemID: DeleteItemID | DeleteItemNanoID,
    {
      filterColumn = 'id'
    }: {
      filterColumn?: string;
    }
  ) {
    const { data: item, error } = await supabaseClient
      .from(snakeCase(deleteItemTable))
      .delete()
      .eq(snakeCase(filterColumn), deleteItemID);

    if (error) {
      throw error;
    }

    return camelcaseKeys(item, { deep: true });
  }
}
