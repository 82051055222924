import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel
} from '@chakra-ui/form-control';
import { Textarea as ChakraTextarea } from '@chakra-ui/textarea';
import without from 'lodash/without';
import React, { ChangeEvent, ForwardedRef, useState } from 'react';
import { mapSize } from '../../../../utils/mapSize';
import { Text } from '../../../Text';
import { TextareaFieldProps } from './TextareaField.types';

const TextareaField = React.forwardRef<HTMLTextAreaElement, TextareaFieldProps>(
  (
    {
      size = 'medium',
      label,
      id,
      isInvalid,
      errorMessage,
      isRequired,
      isDisabled,
      isReadOnly,
      helperText,
      hidden,
      countCharacters,
      resize = 'none',
      maxLength,
      onChange,
      ...props
    }: TextareaFieldProps,
    ref: ForwardedRef<HTMLTextAreaElement>
  ) => {
    const [descriptionCharacters, setDescriptionCharacters] = useState(0);

    const characterCounter = (e: ChangeEvent<HTMLTextAreaElement>) => {
      const { value } = e.target;
      setDescriptionCharacters(value.length);
      onChange && onChange(e);
    };

    return (
      <FormControl
        id={id}
        isInvalid={isInvalid || typeof errorMessage === 'string'}
        isDisabled={isDisabled}
        isRequired={isRequired}
        isReadOnly={isReadOnly}
        hidden={hidden}
      >
        {label && <FormLabel display="flex">{label}</FormLabel>}

        <ChakraTextarea
          variant="outline"
          size={mapSize(size)}
          borderColor="gray.200"
          focusBorderColor="primary.500"
          resize={resize}
          maxLength={maxLength}
          onChange={characterCounter}
          ref={ref}
          {...props}
        />

        {countCharacters && (
          <Text textAlign="right" color="gray.500" textStyle="body2Regular">
            {without([descriptionCharacters, maxLength]).join(' / ')} characters
          </Text>
        )}

        {errorMessage ? (
          <FormErrorMessage textStyle="body2Medium" wordBreak="break-all">
            {errorMessage}
          </FormErrorMessage>
        ) : (
          helperText && (
            <FormHelperText textStyle="body2Medium">
              {helperText}
            </FormHelperText>
          )
        )}
      </FormControl>
    );
  }
);

TextareaField.displayName = 'TextareaField';

export default TextareaField;
