import { Box, Collapse, Flex, useDisclosure } from '@chakra-ui/react';
import { Fragment, MouseEvent, useState } from 'react';
import { ViewJobCandidateModalPhoneSidebarCallCategoryType } from '../../../../ViewJobCandidateModalPhoneSidebarHelper.types';
import { useViewJobCandidateModalPhoneSidebarContext } from '../../../context';
import {
  ViewJobCandidateModalPhoneSidebarCallSectionType,
  ViewJobCandidateModalPhoneSidebarToggleSectionType
} from '../../ViewJobCandidateModalPhoneSidebarCallActionsHelper';
import {
  ViewJobCandidateModalPhoneSidebarCallResponseDiscardButton,
  ViewJobCandidateModalPhoneSidebarCallResponseTrigger,
  ViewJobCandidateModalPhoneSidebarCallResponseWrapper,
  ViewJobCandidateModalPhoneSidebarNotInterestedButton
} from '../../components';
import { ViewJobCandidateModalPhoneSidebarJobDetails } from '../../components/ViewJobCandidateModalPhoneSidebarJobDetails';
import { ViewJobCandidateModalPhoneSidebarActionCompletedHelper } from '../ViewJobCandidateModalPhoneSidebarActionCompletedHelper';
import {
  ViewJobCandidateModalPhoneSidebarConnectedPrimaryButton,
  ViewJobCandidateModalPhoneSidebarConnectedSecondaryButton
} from './components';
import {
  ViewJobCandidateModalPhoneSidebarAssessmentHelper,
  ViewJobCandidateModalPhoneSidebarAvailabilityHelper,
  ViewJobCandidateModalPhoneSidebarInterestedHelper,
  ViewJobCandidateModalPhoneSidebarNotInterestedHelper,
  ViewJobCandidateModalPhoneSidebarQuestionsHelper,
  ViewJobCandidateModalPhoneSidebarScheduleHelper
} from './helpers';

type ViewJobCandidateModalPhoneSidebarConnectedHelperProps = {
  callCategory: ViewJobCandidateModalPhoneSidebarCallCategoryType;
  openedSection: ViewJobCandidateModalPhoneSidebarCallSectionType;
  toggleSection: ViewJobCandidateModalPhoneSidebarToggleSectionType;
  withoutMargin: boolean;
};

export type ViewJobCandidateModalPhoneSidebarConnectedType =
  | 'interested'
  | 'curious'
  | 'not-interested'
  | 'not-a-fit'
  | 'assessment'
  | 'questions'
  | 'followup'
  | 'debrief'
  | 'schedule'
  | 'availability';

const ViewJobCandidateModalPhoneSidebarConnectedHelper = ({
  callCategory,
  openedSection,
  toggleSection,
  withoutMargin
}: ViewJobCandidateModalPhoneSidebarConnectedHelperProps) => {
  const [selectedOption, setSelectedOption] =
    useState<ViewJobCandidateModalPhoneSidebarConnectedType | null>();

  const { job, onToggleShowJobDetails } =
    useViewJobCandidateModalPhoneSidebarContext();

  const toggleSelection = (e: MouseEvent<HTMLButtonElement>) => {
    const { value } = e.currentTarget;

    if (!value || selectedOption === value) {
      setSelectedOption(null);
      onToggleShowJobDetails(false);
      return;
    }

    setSelectedOption(value as ViewJobCandidateModalPhoneSidebarConnectedType);
    onToggleShowJobDetails(true);
  };

  const { isOpen: isOpenConnectedComplete, onOpen: onOpenConnectedComplete } =
    useDisclosure();

  const openConnectedSection = () => {
    toggleSection({ actionType: 'open', section: 'connected' });
  };

  const onDiscard = () => {
    setSelectedOption(null);
    onToggleShowJobDetails(false);
    toggleSection({ actionType: 'close', section: 'connected' });
  };

  return (
    <Flex
      flexDir="column"
      onMouseLeave={() => {
        if (!selectedOption && !isOpenConnectedComplete) {
          onDiscard();
        }
      }}
    >
      <ViewJobCandidateModalPhoneSidebarCallResponseTrigger
        label="Connected"
        onMouseEnter={openConnectedSection}
      />

      <Collapse in={openedSection === 'connected'} unmountOnExit>
        <Box pt={2}>
          <ViewJobCandidateModalPhoneSidebarCallResponseWrapper>
            <Collapse in={!isOpenConnectedComplete} unmountOnExit>
              <Flex justifyContent="space-between">
                {callCategory === 'recruiting-call' ? (
                  <>
                    <ViewJobCandidateModalPhoneSidebarConnectedPrimaryButton
                      label="Interested"
                      value="interested"
                      selectedOption={selectedOption}
                      onClick={toggleSelection}
                    />
                    <ViewJobCandidateModalPhoneSidebarConnectedSecondaryButton
                      label="Curious"
                      value="curious"
                      selectedOption={selectedOption}
                      onClick={toggleSelection}
                    />
                  </>
                ) : null}

                {callCategory === 'screening-call' ? (
                  <>
                    <ViewJobCandidateModalPhoneSidebarConnectedPrimaryButton
                      label="Assessment"
                      value="assessment"
                      selectedOption={selectedOption}
                      onClick={toggleSelection}
                    />

                    <ViewJobCandidateModalPhoneSidebarConnectedSecondaryButton
                      label="Questions"
                      value="questions"
                      selectedOption={selectedOption}
                      onClick={toggleSelection}
                    />
                  </>
                ) : null}

                {callCategory === 'followup-call' ? (
                  <>
                    <ViewJobCandidateModalPhoneSidebarConnectedPrimaryButton
                      label="Follow-up"
                      value="followup"
                      selectedOption={selectedOption}
                      onClick={toggleSelection}
                    />

                    <ViewJobCandidateModalPhoneSidebarConnectedSecondaryButton
                      label="Debrief"
                      value="debrief"
                      selectedOption={selectedOption}
                      onClick={toggleSelection}
                    />
                  </>
                ) : null}

                {callCategory === 'scheduling-call' ? (
                  <>
                    <ViewJobCandidateModalPhoneSidebarConnectedPrimaryButton
                      label="Schedule"
                      value="schedule"
                      selectedOption={selectedOption}
                      onClick={toggleSelection}
                    />

                    <ViewJobCandidateModalPhoneSidebarConnectedSecondaryButton
                      label="Availability"
                      value="availability"
                      selectedOption={selectedOption}
                      onClick={toggleSelection}
                    />
                  </>
                ) : null}

                <ViewJobCandidateModalPhoneSidebarNotInterestedButton
                  selectedOption={selectedOption}
                  onClick={toggleSelection}
                />
              </Flex>

              <Fragment>
                <Collapse
                  in={
                    selectedOption === 'interested' ||
                    selectedOption === 'curious' ||
                    selectedOption === 'followup' ||
                    selectedOption === 'debrief'
                  }
                  unmountOnExit
                >
                  <Box pt={3} pb={2}>
                    <ViewJobCandidateModalPhoneSidebarInterestedHelper
                      onSave={onOpenConnectedComplete}
                    />
                  </Box>
                </Collapse>

                <Collapse in={selectedOption === 'schedule'} unmountOnExit>
                  <Box pt={3} pb={2}>
                    <ViewJobCandidateModalPhoneSidebarScheduleHelper
                      withoutMargin={withoutMargin}
                    />
                  </Box>
                </Collapse>

                <Collapse in={selectedOption === 'availability'} unmountOnExit>
                  <Box pt={3} pb={2}>
                    <ViewJobCandidateModalPhoneSidebarAvailabilityHelper
                      onSave={onOpenConnectedComplete}
                    />
                  </Box>
                </Collapse>

                <Collapse in={selectedOption === 'assessment'} unmountOnExit>
                  <Box pt={3} pb={2}>
                    <ViewJobCandidateModalPhoneSidebarAssessmentHelper />
                  </Box>
                </Collapse>

                <Collapse in={selectedOption === 'questions'} unmountOnExit>
                  <Box pt={3} pb={2}>
                    <ViewJobCandidateModalPhoneSidebarQuestionsHelper />
                  </Box>
                </Collapse>

                <Collapse
                  in={
                    selectedOption === 'not-interested' ||
                    selectedOption === 'not-a-fit'
                  }
                  unmountOnExit
                >
                  <Box pt={3} pb={2}>
                    <ViewJobCandidateModalPhoneSidebarNotInterestedHelper
                      onSave={onOpenConnectedComplete}
                    />
                  </Box>
                </Collapse>
              </Fragment>
            </Collapse>

            <Collapse in={isOpenConnectedComplete} unmountOnExit>
              <ViewJobCandidateModalPhoneSidebarActionCompletedHelper />
            </Collapse>

            {selectedOption || isOpenConnectedComplete ? (
              <ViewJobCandidateModalPhoneSidebarCallResponseDiscardButton
                onClick={onDiscard}
              />
            ) : null}
          </ViewJobCandidateModalPhoneSidebarCallResponseWrapper>
        </Box>
      </Collapse>

      {selectedOption && job?.nanoId ? (
        <Fragment>
          <Box pt={6}>
            <ViewJobCandidateModalPhoneSidebarJobDetails
              jobNanoId={job?.nanoId}
            />
          </Box>
        </Fragment>
      ) : null}
    </Flex>
  );
};

export default ViewJobCandidateModalPhoneSidebarConnectedHelper;
