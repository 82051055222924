import { Icon, IconProps } from '@chakra-ui/icon';

const WorldGlobeIcon = ({ w = '20px', h = '20px', ...props }: IconProps) => (
  <Icon viewBox="0 0 20 20" w={w} h={h} fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.343 9.274a14.042 14.042 0 0 0-.273-2.182h1.784a6.5 6.5 0 0 1 .648 2.182h-2.159Zm1.51 3.634H14.07c.142-.685.237-1.414.273-2.181h2.159a6.501 6.501 0 0 1-.648 2.181Zm-2.822 2.888a8.72 8.72 0 0 0 .643-1.433h1.191a6.582 6.582 0 0 1-1.834 1.433Zm-2.304-6.522V7.092h1.858c.155.66.264 1.39.304 2.182h-2.162Zm1.858 3.634h-1.858v-2.181h2.162c-.04.79-.149 1.52-.303 2.181Zm-1.858 3.394v-1.939h1.399c-.39.933-.884 1.605-1.4 1.939Zm0-12.603c.515.334 1.01 1.006 1.399 1.938h-1.4V3.7Zm4.138 1.937h-1.19a8.717 8.717 0 0 0-.644-1.433 6.581 6.581 0 0 1 1.834 1.433Zm-5.593.001H7.873c.388-.932.884-1.604 1.4-1.938v1.938Zm0 3.637H7.11c.041-.792.15-1.521.304-2.182h1.86v2.182Zm0 3.634h-1.86a12.42 12.42 0 0 1-.303-2.181h2.163v2.181Zm0 3.394c-.515-.334-1.01-1.006-1.399-1.939h1.4v1.939ZM6.325 5.637h-1.19a6.552 6.552 0 0 1 1.833-1.432c-.243.428-.46.908-.643 1.432Zm-1.19 8.726h1.19c.184.525.4 1.005.643 1.432a6.553 6.553 0 0 1-1.833-1.432Zm-.99-1.455a6.481 6.481 0 0 1-.647-2.181h2.158c.036.767.13 1.496.273 2.181H4.145Zm0-5.816H5.93a14.047 14.047 0 0 0-.273 2.182H3.498a6.48 6.48 0 0 1 .647-2.182Zm12.827-1.003c-.01-.022-.026-.039-.037-.06C15.555 3.627 12.965 2 10 2 7.033 2 4.445 3.626 3.064 6.03c-.01.02-.027.037-.036.059A7.941 7.941 0 0 0 2 10c0 1.421.376 2.754 1.028 3.911.01.022.025.038.036.059C4.445 16.373 7.034 18 10 18c2.966 0 5.554-1.626 6.935-4.03.011-.02.027-.037.037-.059a7.948 7.948 0 0 0 0-7.822Z"
    />
  </Icon>
);

export default WorldGlobeIcon;
