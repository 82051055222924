import {
  CountryItemBffID,
  CountryItemBffNanoID,
  FetchCountriesBffURL,
  FetchCountryBffURL,
  FetchCountryByNanoIdBffURL,
  CreateCountryBffURL,
  UpdateCountryBffURL,
  DeleteCountryBffURL
} from './countriesTypes';

import { BaseBffRoutes } from '../BaseBffRoutes';

export class CountriesBffRoutes extends BaseBffRoutes {
  protected static route = 'countries';

  static fetchCountriesRoute() {
    return this.fetchItemsRoute<FetchCountriesBffURL>();
  }

  static fetchCountriesIndexRoute() {
    return this.fetchItemsIndexRoute<FetchCountriesBffURL>();
  }

  static fetchCountryRoute(countryItemBffID: CountryItemBffID) {
    return this.fetchItemRoute<FetchCountryBffURL>(countryItemBffID);
  }

  static fetchCountryIndexRoute(countryItemBffNanoID: CountryItemBffNanoID) {
    return this.fetchItemIndexRoute<FetchCountryBffURL>(countryItemBffNanoID);
  }

  static fetchCountryByNanoIdRoute(countryItemBffNanoID: CountryItemBffNanoID) {
    return this.fetchItemByNanoIdRoute<FetchCountryByNanoIdBffURL>(
      countryItemBffNanoID
    );
  }

  static createCountryRoute() {
    return this.createItemRoute<CreateCountryBffURL>();
  }

  static updateCountryRoute(
    countryItemBffNanoID: CountryItemBffID | CountryItemBffNanoID
  ) {
    return this.updateItemRoute<UpdateCountryBffURL>(countryItemBffNanoID);
  }

  static deleteCountryRoute(countryItemBffNanoID: CountryItemBffNanoID) {
    return this.deleteItemRoute<DeleteCountryBffURL>(countryItemBffNanoID);
  }
}
