import { FetchJobsCacheKey } from '../../jobsTypes';

import { useCreateItem } from '../../../common/hooks/useCreateItem';

import {
  CreateJobErrorResponse,
  CreateJobRequestData,
  JobsBffRequests
} from '../../JobsBffRequests';

interface CreateJobOptions {
  cacheKeys?: FetchJobsCacheKey[];
}

// const defaultWorkflowStages = [
//   {
//     name: 'Sourcing',
//     color: '#257dc9',
//     arrangement: 0,
//     editable: false,
//     movable: false,
//     removable: false
//   },
//   {
//     name: 'Recruiting',
//     color: '#507d7b',
//     arrangement: 2,
//     editable: false,
//     movable: false,
//     removable: false
//   },
//   {
//     name: 'Phone screen',
//     color: '#6871e4',
//     arrangement: 2,
//     editable: true,
//     movable: true,
//     removable: true
//   },
//   {
//     name: 'Interview',
//     color: '#eda403',
//     arrangement: 3,
//     editable: true,
//     movable: true,
//     removable: true
//   },
//   {
//     name: 'Offer Pending',
//     color: '#ab55b2',
//     arrangement: 4,
//     editable: false,
//     movable: false,
//     removable: false
//   },
//   {
//     name: 'Offer Extended',
//     color: '#0aad91',
//     arrangement: 5,
//     editable: false,
//     movable: false,
//     removable: false
//   },
//   {
//     name: 'Hired',
//     color: '#ba3724',
//     arrangement: 6,
//     editable: false,
//     movable: false,
//     removable: false
//   }
// ];

function useCreateJob({ cacheKeys = [] }: CreateJobOptions) {
  const {
    createItemData,
    createItemError,
    createItemErrorMessage,
    createItemIsLoading,
    createItem,
    createItemReset
  } = useCreateItem<CreateJobRequestData, CreateJobErrorResponse>({
    mutationFn: async (queryInput) => {
      return await JobsBffRequests.createJob(queryInput);

      // await ScreeningsRequests.createScreening({
      //   name: 'Pre Screen' as ScreeningName,
      //   kind: ScreeningKinds.PRE_SCREEN as ScreeningKind,
      //   jobId: job.id as ScreeningJobId,
      //   userId: job.userId,
      //   nanoId: generateNanoId<ScreeningNanoId>()
      // });
      //
      // const workflowStageRequests = defaultWorkflowStages.map(
      //   (defaultWorkflowStage) =>
      //     WorkflowStagesRequests.createWorkflowStage({
      //       name: defaultWorkflowStage.name as WorkflowStageName,
      //       color: defaultWorkflowStage.color as WorkflowStageColor,
      //       arrangement:
      //         defaultWorkflowStage.arrangement as WorkflowStageArrangement,
      //       editable: defaultWorkflowStage.editable as WorkflowStageEditable,
      //       movable: defaultWorkflowStage.movable as WorkflowStageMovable,
      //       removable: defaultWorkflowStage.removable as WorkflowStageRemovable,
      //       jobId: job.id as WorkflowStageJobId,
      //       userId: job.userId,
      //       nanoId: generateNanoId<WorkflowStageNanoId>()
      //     })
      // );
      //
      // await Promise.all(workflowStageRequests);
      //
      // return job;
    },
    cacheKeys
  });

  // mutationFn: (queryInput) => JobsBffRequests.createJob(queryInput),

  return {
    createJobData: createItemData,
    createJobError: createItemError,
    createJobErrorMessage: createItemErrorMessage,
    createJobIsLoading: createItemIsLoading,
    createJob: createItem,
    createJobReset: createItemReset
  };
}

export default useCreateJob;
