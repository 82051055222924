import {
  ButtonGroup,
  Collapse,
  Flex,
  Stack,
  useDisclosure
} from '@chakra-ui/react';
import { Button } from '../../../../../../../../../../../helpers/Button';
import { ViewJobCandidateModalPhoneSidebarEvaluateLead } from '..';
import { ViewJobCandidateModalPhoneSidebarTextArea } from '../ViewJobCandidateModalPhoneSidebarTextArea';

interface ViewJobCandidateModalPhoneSidebarEnterNoteFormProps {
  onSave: () => void;
  onDiscard: () => void;
  allowScheduling?: boolean;
}

const ViewJobCandidateModalPhoneSidebarEnterNoteForm = ({
  onSave,
  onDiscard,
  allowScheduling
}: ViewJobCandidateModalPhoneSidebarEnterNoteFormProps) => {
  const { onToggle: onToggleEvaluateLead, isOpen: isOpenEvaluateLead } =
    useDisclosure();

  return (
    <Stack spacing={4}>
      <Flex direction="column">
        <ViewJobCandidateModalPhoneSidebarTextArea
          allowScheduling={allowScheduling}
        />

        <Collapse in={isOpenEvaluateLead} unmountOnExit>
          <ViewJobCandidateModalPhoneSidebarEvaluateLead />
        </Collapse>
      </Flex>

      <ButtonGroup justifyContent="flex-end">
        <Button
          size="small"
          hierarchy="link"
          mr="auto"
          onClick={onToggleEvaluateLead}
        >
          {!isOpenEvaluateLead ? 'Evaluate lead' : 'Cancel lead evaluation'}
        </Button>

        <Button size="small" hierarchy="link" onClick={onDiscard}>
          Cancel
        </Button>

        <Button size="small" onClick={onSave}>
          {!isOpenEvaluateLead ? 'Comment' : 'Save'}
        </Button>
      </ButtonGroup>
    </Stack>
  );
};

export default ViewJobCandidateModalPhoneSidebarEnterNoteForm;
