import { FetchStatesCacheKey, FetchStateCacheKey } from './statesTypes';

export class StatesCache {
  static indexCacheKey() {
    return 'states' as FetchStatesCacheKey;
  }

  static showCacheKey() {
    return 'state' as FetchStateCacheKey;
  }

  static companyFormCacheKey() {
    return 'states-company-form' as FetchStatesCacheKey;
  }
}
