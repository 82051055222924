import React, { useCallback, useState } from 'react';
import { Avatar } from '@chakra-ui/react';
import { Flex, Stack, StackDivider } from '@chakra-ui/layout';
import { motion } from 'framer-motion';
import filter from 'lodash/filter';
import size from 'lodash/size';
import maxBy from 'lodash/maxBy';

import { useRightSidebarContext } from '../../../../../../context';

import { IconButton } from '../../../../../../helpers/buttons/IconButton';
import { Text } from '../../../../../../helpers/Text';

import { ChevronRightIcon } from '../../../../../../icons/ChevronRightIcon';
import { TasksButtonIcon } from '../../../../../../icons/TasksButtonIcon';

import { JobOption } from './components/formFields/SelectJobField/SelectJobField.types';

import { SelectJobField } from './components/formFields/SelectJobField';
import { WorkflowStageOption } from './components/formFields/SelectWorkflowStageField/SelectWorkflowStageField.types';
import { SelectWorkflowStageField } from './components/formFields/SelectWorkflowStageField';
import { CandidateTaskMenu } from './components/CandidateTaskMenu';
import { AddWorkflowStageTaskContainer } from './components/AddWorkflowStageTaskContainer';

import { Job, WorkflowStage } from './TaskPopup.types';

const TasksPopup = () => {
  const { isTasksSidebarOpened, toggleTasksSidebar } = useRightSidebarContext();

  const [hidden, setHidden] = useState(!isTasksSidebarOpened);
  const [isTaskUpdated, setIsTaskUpdated] = useState(false);
  const [selectedJob, setSelectedJob] = useState<Job>({
    id: '',
    name: '',
    nanoId: ''
  } as Job);
  const [selectedWorkflowStage, setSelectedWorkflowStage] =
    useState<WorkflowStage>({
      id: '',
      name: '',
      nanoId: '',
      jobCandidates: [],
      workflowStageTasks: []
    } as unknown as WorkflowStage);

  const handleSelectJob = useCallback<(job: JobOption) => void>(
    ({ nanoId, label, value }) => {
      setSelectedJob({ id: value, name: label, nanoId });
      setSelectedWorkflowStage({
        id: '',
        name: '',
        nanoId: '',
        jobCandidates: [],
        workflowStageTasks: []
      } as unknown as WorkflowStage);
    },
    []
  );

  const handleSelectWorkflowStage = useCallback<
    (workflowStage: WorkflowStageOption) => void
  >(({ jobCandidates, label, value, nanoId, workflowStageTasks }) => {
    setSelectedWorkflowStage({
      id: value,
      name: label,
      nanoId,
      jobCandidates,
      workflowStageTasks
    });
  }, []);

  const handleIsTaskUpdatedTrue = useCallback(() => {
    setIsTaskUpdated(true);
  }, []);

  const handleIsTaskUpdatedFalse = useCallback(() => {
    setIsTaskUpdated(false);
  }, []);

  const activeJobCandidatesWithTask = filter(
    selectedWorkflowStage.jobCandidates,
    (jobCandidate) =>
      Boolean(jobCandidate.workflowStageTaskScheduledAt) &&
      !jobCandidate.disqualifiedAt
  );

  const allActiveJobCandidates = filter(
    selectedWorkflowStage.jobCandidates,
    (jobCandidate) => !jobCandidate.disqualifiedAt
  );

  const lastWorkflowStageTask = maxBy(
    selectedWorkflowStage.workflowStageTasks,
    (workflowStageTask) => workflowStageTask.arrangement
  );

  const arrangement = (lastWorkflowStageTask?.arrangement || 0) + 1;

  return (
    <>
      <motion.div
        hidden={hidden}
        initial={false}
        onAnimationStart={() => setHidden(false)}
        onAnimationComplete={() => setHidden(!isTasksSidebarOpened)}
        animate={{ width: isTasksSidebarOpened ? 432 : 0 }}
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          top: '73px',
          right: '72px',
          position: 'fixed',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          height: 'calc(100vh - 73px)'
        }}
      >
        <Flex
          w="100%"
          bg="white"
          zIndex={2}
          position="relative"
          maxW="400px"
          flexDirection="column"
          borderLeft="1px solid"
          borderLeftColor="gray.200"
          p={6}
        >
          <IconButton
            p={0}
            minW={0}
            top={6}
            left={-4}
            position="absolute"
            hierarchy="link"
            color="gray.500"
            aria-label="Close"
            onClick={toggleTasksSidebar}
            _hover={{ color: 'gray.800' }}
          >
            <Flex
              w={8}
              h={8}
              bg="white"
              borderRadius="full"
              boxShadow="0 6px 14px rgba(0, 0, 0, 0.12)"
              alignItems="center"
              justifyContent="center"
            >
              <ChevronRightIcon />
            </Flex>
          </IconButton>

          <Stack spacing={4} mb={6}>
            <SelectJobField
              selectedJob={selectedJob}
              handleSelectJob={handleSelectJob}
            />
            {selectedJob.id && (
              <SelectWorkflowStageField
                selectedJob={selectedJob}
                selectedWorkflowStage={selectedWorkflowStage}
                handleSelectWorkflowStage={handleSelectWorkflowStage}
                isTaskUpdated={isTaskUpdated}
                handleIsTaskUpdatedFalse={handleIsTaskUpdatedFalse}
              />
            )}
          </Stack>

          <Stack
            w="100%"
            spacing={3}
            overflow="auto"
            direction="column"
            divider={<StackDivider />}
          >
            {!selectedJob.id ? (
              <Text textStyle="body1Medium" color="gray.900" textAlign="center">
                Select job to view active tasks
              </Text>
            ) : size(activeJobCandidatesWithTask) === 0 ? (
              <Flex
                h="100%"
                w="100%"
                flexDir="column"
                justifyContent="center"
                alignItems="center"
                gap={4}
              >
                <Avatar
                  size="sm"
                  icon={<TasksButtonIcon w="100%" h="100%" fill="gray.50" />}
                />
                <Text textStyle="body1Medium" color="gray.900">
                  No active task
                </Text>
              </Flex>
            ) : (
              activeJobCandidatesWithTask?.map((jobCandidate) => (
                <CandidateTaskMenu
                  key={jobCandidate.nanoId}
                  jobCandidate={jobCandidate}
                  job={selectedJob}
                  workflowStage={selectedWorkflowStage}
                  handleIsTaskUpdatedTrue={handleIsTaskUpdatedTrue}
                  allJobCandidates={allActiveJobCandidates}
                />
              ))
            )}

            {selectedWorkflowStage.id ? (
              <AddWorkflowStageTaskContainer
                arrangement={arrangement}
                workflowStageId={selectedWorkflowStage.id}
                workflowStageNanoId={selectedWorkflowStage.nanoId}
                allJobCandidates={allActiveJobCandidates}
                handleIsTaskUpdatedTrue={handleIsTaskUpdatedFalse}
              />
            ) : null}
          </Stack>
        </Flex>
      </motion.div>
    </>
  );
};

export default TasksPopup;
