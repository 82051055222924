import {
  Avatar,
  Box,
  Button as ChakraButton,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Spinner,
  Stack,
  StackDivider,
  Tooltip,
  useDisclosure
} from '@chakra-ui/react';
import { useCallback, useEffect } from 'react';
import first from 'lodash/first';
import flatMap from 'lodash/flatMap';
import find from 'lodash/find';
import size from 'lodash/size';
import uniqBy from 'lodash/uniqBy';
import orderBy from 'lodash/orderBy';

import { IconButton } from '../../../../../../../../helpers/buttons/IconButton';
import { Text } from '../../../../../../../../helpers/Text';

import { PlusIcon } from '../../../../../../../../icons/PlusIcon';
import { RemoveIcon } from '../../../../../../../../icons/RemoveIcon';
import { ShareIcon } from '../../../../../../../../icons/ShareIcon';

import { useShareCandidatesProfileContext } from '../../../../../../../../context';
import { ShareCandidateProfileJobCandidate } from '../../../../../../../candidateShares/components/ShareCandidateProfile';
import {
  JobCandidateCandidateShares,
  JobCandidateNanoId
} from '../../../../../../../jobCandidates/jobCandidatesTypes';
import { ImagesUrl } from '../../../../../../../images/ImagesUrl';
import { JobNanoId } from '../../../../../../jobsTypes';
import { JobCandidatesCache } from '../../../../../../../jobCandidates/JobCandidatesCache';
import { WorkflowStagesCache } from '../../../../../../../workflowStages/WorkflowStagesCache';

import { AlertMessage } from '../../../../../../../../helpers/AlertMessage';
import { useChakraToast } from '../../../../../../../../helpers/useChakraToast';

import {
  MayBeRecipientImage,
  RecipientId,
  RecipientName
} from '../../../../../../../recipients/recipientsTypes';
import {
  CandidateShareId,
  FetchCandidateSharesCacheKey
} from '../../../../../../../candidateShares/candidateSharesTypes';
import { useDeleteRecipientInCandidateShare } from '../../../../../../../candidateShares/hooks/useDeleteRecipientInCandidateShare';
import { useCurrentUser } from '../../../../../../../../auth/hooks/useAuth';
import { usePaginatedCandidateShareRecipients } from '../../../../../../../candidateShareRecipients/hooks/usePaginatedCandidateShareRecipients';
import {
  fetchCandidateShareRecipientsQuery,
  FetchCandidateShareRecipientsResponse
} from '../../../../../../../candidateShareRecipients/queries/fetchCandidateShareRecipients.query';
import {
  CandidateShareRecipientFields,
  FetchCandidateShareRecipientsEnabled,
  FetchCandidateShareRecipientsFilters
} from '../../../../../../../candidateShareRecipients/candidateShareRecipientsTypes';
import { CandidateShareRecipientsCache } from '../../../../../../../candidateShareRecipients/CandidateShareRecipientsCache';
import { LoadingSkeleton } from '../../../../../../../../helpers/LoadingSkeleton';

interface JobCandidateSharedRecipientsJobCandidate
  extends ShareCandidateProfileJobCandidate {
  candidateShares: JobCandidateCandidateShares;
}

type JobCandidateSharedRecipientsProps = {
  isHovered?: boolean;
  jobCandidate: JobCandidateSharedRecipientsJobCandidate;
  job: {
    nanoId: JobNanoId;
  };
  withShareJobCandidateJobNanoId?: boolean;
  withLargeShareIcon?: boolean;
  withSharedRecipientsText?: boolean;
  withoutPopOver?: boolean;
  disableProfileShare?: boolean;
};

export const JobCandidateSharedRecipients = ({
  isHovered,
  jobCandidate,
  job,
  withShareJobCandidateJobNanoId,
  withLargeShareIcon,
  withSharedRecipientsText,
  withoutPopOver,
  disableProfileShare
}: JobCandidateSharedRecipientsProps) => {
  const currentUser = useCurrentUser();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    candidateShareRecipients,
    candidateShareRecipientsIsFetched,
    candidateShareRecipientsErrorMessage
  } = usePaginatedCandidateShareRecipients<FetchCandidateShareRecipientsResponse>(
    {
      query: fetchCandidateShareRecipientsQuery,
      enabled: isHovered as FetchCandidateShareRecipientsEnabled,
      cacheKey: CandidateShareRecipientsCache.jobCandidateIndexCacheKey(
        jobCandidate.nanoId
      ),
      initialFilters: {
        [CandidateShareRecipientFields.WITHOUT_HIDDEN]: true,
        [CandidateShareRecipientFields.JOB_CANDIDATE_ID]: {
          operator: 'eq',
          value: jobCandidate.id
        }
      } as unknown as FetchCandidateShareRecipientsFilters
    }
  );

  const { shareJobCandidate, setSharedJobCandidateJobNanoId } =
    useShareCandidatesProfileContext();

  useEffect(() => {
    !isHovered && onClose();
  }, [isHovered, onClose]);

  const handleShareJobCandidate = useCallback(() => {
    if (withShareJobCandidateJobNanoId) {
      setSharedJobCandidateJobNanoId(job?.nanoId);
    }
    shareJobCandidate(jobCandidate);
  }, [
    jobCandidate,
    shareJobCandidate,
    setSharedJobCandidateJobNanoId,
    job?.nanoId,
    withShareJobCandidateJobNanoId
  ]);

  const sortedCandidateShareRecipients = orderBy(
    candidateShareRecipients,
    ['updatedAt'],
    'desc'
  );

  const allRecipients = flatMap(
    sortedCandidateShareRecipients,
    (candidateShareRecipient) => ({
      ...candidateShareRecipient.recipient,
      candidateShareId: candidateShareRecipient.candidateShare?.id
    })
  );

  const recipientsFromJobCandidate = flatMap(
    jobCandidate.candidateShares,
    (candidateShare) => candidateShare.recipientIds
  );

  const recipients = uniqBy(allRecipients, 'id');

  const isShared = !!size(recipientsFromJobCandidate) || !!size(recipients);
  const moreThanOneRecipient = size(recipients) > 1;
  const firstRecipient = first(recipients)?.name;
  const firstCandidateShare = first(
    sortedCandidateShareRecipients
  )?.candidateShare;
  const candidateShareUser = find(
    jobCandidate.candidateShares,
    (share) => share.id === firstCandidateShare?.id
  )?.user;
  const candidateShareUserId = candidateShareUser?.id as unknown as number;

  const showText = withSharedRecipientsText && !withLargeShareIcon;
  const showLargeIcon = withLargeShareIcon && !withSharedRecipientsText;

  return (
    <>
      {isShared ? (
        <Popover
          isOpen={isOpen}
          onClose={onClose}
          placement={showText ? 'right-start' : 'right-start'}
        >
          <PopoverTrigger>
            {showText ? (
              <ChakraButton
                variant="unstyled"
                p={0}
                minW={0}
                w="fit-content"
                h="fit-content"
              >
                <LoadingSkeleton
                  loaded={candidateShareRecipientsIsFetched}
                  count={1}
                >
                  <Text color="gray.700">
                    <Box as="span" textStyle="body1Medium">
                      {Number(currentUser.id) === candidateShareUserId
                        ? 'You'
                        : candidateShareUser?.name}
                    </Box>{' '}
                    shared with{' '}
                    <Box
                      as="span"
                      color="primary.500"
                      textStyle="body1Medium"
                      onMouseEnter={withoutPopOver ? undefined : onOpen}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      {firstRecipient}{' '}
                      {moreThanOneRecipient ? `+${size(recipients) - 1}` : ''}
                    </Box>
                  </Text>
                </LoadingSkeleton>
              </ChakraButton>
            ) : showLargeIcon ? (
              <IconButton
                w={9}
                h={9}
                p={0}
                minW={0}
                size="small"
                bg="green.100"
                borderRadius="full"
                display="flex"
                alignItems="center"
                justifyContent="center"
                hierarchy="unstyled"
                aria-label="Share"
                cursor="pointer"
                onMouseEnter={withoutPopOver ? undefined : onOpen}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                icon={
                  <Flex
                    w={5}
                    h={5}
                    border="1px solid"
                    borderRadius="full"
                    alignItems="center"
                    borderColor="green.300"
                    justifyContent="center"
                  >
                    <ShareIcon fill="green.500" width={3} h={3} />
                  </Flex>
                }
              />
            ) : (
              <IconButton
                w={4}
                h={4}
                p={0}
                minW={0}
                size="small"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderColor={'green.200'}
                hierarchy="unstyled"
                borderRadius="full"
                aria-label="Share"
                cursor="pointer"
                bg={'green.100'}
                onMouseEnter={withoutPopOver ? undefined : onOpen}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                icon={<ShareIcon w={2.5} h={2.5} fill={'green.500'} />}
              />
            )}
          </PopoverTrigger>

          <Portal>
            <PopoverContent
              width="260px"
              onMouseLeave={onClose}
              onClick={(e) => e.stopPropagation()}
            >
              <PopoverArrow />

              <PopoverHeader p={3}>
                <Flex alignItems="center" gap={4}>
                  <Text textStyle="body1Medium">Sharing profile with</Text>

                  {disableProfileShare ? null : (
                    <IconButton
                      w={7}
                      h={7}
                      minW={0}
                      ml="auto"
                      size="small"
                      display="flex"
                      alignItems="center"
                      hierarchy="tertiary"
                      justifyContent="center"
                      aria-label="Share with"
                      onClick={handleShareJobCandidate}
                      cursor="pointer"
                      color="gray.600"
                      icon={<PlusIcon w={4} h={4} />}
                    />
                  )}
                </Flex>
              </PopoverHeader>

              <PopoverBody p={3} maxH="300px" overflowY="auto">
                <Stack bg="white">
                  <LoadingSkeleton
                    loaded={candidateShareRecipientsIsFetched}
                    count={3}
                  >
                    <AlertMessage
                      message={candidateShareRecipientsErrorMessage}
                    />
                    <Stack
                      spacing={3}
                      flexDir="column"
                      justifyContent="center"
                      divider={<StackDivider />}
                    >
                      {recipients.map((recipient) => (
                        <SharedRecipientItem
                          key={recipient.id}
                          id={recipient.id}
                          name={recipient.name || recipient.fullName}
                          image={recipient.image}
                          job={job}
                          jobCandidate={jobCandidate}
                          candidateShareId={recipient.candidateShareId}
                        />
                      ))}
                    </Stack>
                  </LoadingSkeleton>
                </Stack>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>
      ) : (
        <Tooltip
          label="Share Profile"
          placement="top"
          hasArrow
          isDisabled={disableProfileShare}
        >
          <IconButton
            w={4}
            h={4}
            p={0}
            opacity={isHovered ? 1 : 0}
            minW={0}
            size="small"
            display={disableProfileShare ? 'none' : 'flex'}
            alignItems="center"
            justifyContent="center"
            borderColor={'blue.200'}
            hierarchy="unstyled"
            borderRadius="full"
            aria-label="Share"
            cursor="pointer"
            bg={'blue.100'}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleShareJobCandidate();
            }}
            icon={<ShareIcon w={2.5} h={2.5} fill={'blue.500'} />}
          />
        </Tooltip>
      )}
    </>
  );
};

type SharedRecipientItemType = {
  id: RecipientId;
  name: RecipientName;
  image: MayBeRecipientImage;
  job: {
    nanoId: JobNanoId;
  };
  jobCandidate: {
    nanoId: JobCandidateNanoId;
  };
  candidateShareId: CandidateShareId;
};

const SharedRecipientItem = ({
  id,
  name,
  image,
  job,
  jobCandidate,
  candidateShareId
}: SharedRecipientItemType) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useChakraToast();

  const {
    deleteRecipientInCandidateShare,
    deleteRecipientInCandidateShareErrorMessage,
    deleteRecipientInCandidateShareIsLoading
  } = useDeleteRecipientInCandidateShare({
    candidateShareId,
    recipientId: id,
    cacheKeys: [
      JobCandidatesCache.jobIndexCacheKey(
        job?.nanoId
      ) as unknown as FetchCandidateSharesCacheKey,
      WorkflowStagesCache.jobIndexCacheKey(
        job?.nanoId
      ) as unknown as FetchCandidateSharesCacheKey,
      JobCandidatesCache.indexCacheKey() as unknown as FetchCandidateSharesCacheKey,
      CandidateShareRecipientsCache.jobCandidateIndexCacheKey(
        jobCandidate.nanoId
      ) as unknown as FetchCandidateSharesCacheKey
    ]
  });

  const fileUrl = ImagesUrl.file(image) as string;

  const handleRemoveRecipient = useCallback(async () => {
    await deleteRecipientInCandidateShare({});

    toast({ title: 'Recipient successfuly removed', status: 'success' });
  }, [deleteRecipientInCandidateShare, toast]);

  return (
    <Stack
      h="auto"
      w="100%"
      direction="row"
      spacing={2}
      minW="unset"
      position="relative"
      alignItems="center"
      border="none"
      onMouseEnter={onOpen}
      onMouseLeave={onClose}
    >
      <Avatar
        size="sm"
        src={fileUrl}
        transitionDuration="slow"
        transitionProperty="opacity"
      />

      <Stack spacing={0} flex={1}>
        <Text
          noOfLines={1}
          color="gray.700"
          lineHeight="20px"
          textStyle="body1Medium"
        >
          {name}
        </Text>

        <Box>
          <AlertMessage message={deleteRecipientInCandidateShareErrorMessage} />
        </Box>

        {/* <Box>
          <Text
            w="100%"
            as="span"
            noOfLines={1}
            color="green.600"
            lineHeight="16px"
            wordBreak="break-all"
            textStyle="body2Regular"
          >
            {activity.type} ({activity.count})
          </Text>
        </Box> */}
      </Stack>

      <IconButton
        h={6}
        w={6}
        minW={0}
        ml="auto"
        right={0}
        size="small"
        border="none"
        display="flex"
        pos="absolute"
        boxShadow="none"
        borderRadius="full"
        alignItems="center"
        hierarchy="tertiary"
        justifyContent="center"
        aria-label="Remove Item"
        icon={
          deleteRecipientInCandidateShareIsLoading ? (
            <Spinner size="sm" />
          ) : (
            <RemoveIcon fill="red.600" />
          )
        }
        onClick={handleRemoveRecipient}
        opacity={isOpen ? 1 : 0}
        cursor="pointer"
        color="gray.600"
      />
    </Stack>
  );
};

export default JobCandidateSharedRecipients;
