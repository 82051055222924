import {
  FetchCertificationsQuery,
  FetchCertificationsCountType,
  FetchCertificationsPage,
  FetchCertificationsPageSize,
  FetchCertificationsFilters,
  FetchCertificationsSort,
  FetchCertificationsIndexQueryName,
  FetchCertificationsIndexCountType,
  FetchCertificationsIndexPage,
  FetchCertificationsIndexPageSize,
  FetchCertificationsIndexFilters,
  FetchCertificationsIndexSort,
  FetchCertificationQuery,
  FetchCertificationIndexQueryName,
  CreateCertificationError,
  UpdateCertificationError,
  DeleteCertificationError,
  CertificationId,
  CertificationNanoId,
  CertificationName,
  MayBeCertificationStartDate,
  MayBeCertificationEndDate,
  CertificationUserId,
  MayBeCertificationCompanyId,
  MayBeCertificationJobCandidateId,
  MayBeCertificationJobResumeRecruiterId
} from './certificationsTypes';

import { BaseBffRequests } from '../BaseBffRequests';

import { CertificationsBffRoutes } from './CertificationsBffRoutes';

interface FetchCertificationsRequestParams {
  query: FetchCertificationsQuery;
  countType?: FetchCertificationsCountType;
  page?: FetchCertificationsPage;
  pageSize?: FetchCertificationsPageSize;
  filters?: FetchCertificationsFilters;
  sort?: FetchCertificationsSort;
}

interface FetchCertificationsIndexRequestParams {
  queryName: FetchCertificationsIndexQueryName;
  countType?: FetchCertificationsIndexCountType;
  page?: FetchCertificationsIndexPage;
  pageSize?: FetchCertificationsIndexPageSize;
  filters?: FetchCertificationsIndexFilters;
  sort?: FetchCertificationsIndexSort;
}

interface FetchCertificationRequestParams {
  query: FetchCertificationQuery;
}

interface FetchCertificationIndexRequestParams {
  queryName: FetchCertificationIndexQueryName;
}

export interface CertificationRequestData {
  name?: CertificationName;
  startDate?: MayBeCertificationStartDate;
  endDate?: MayBeCertificationEndDate;
}

export interface CreateCertificationDefaultRequestData
  extends CertificationRequestData {
  id?: CertificationId;
  userId: CertificationUserId;
  companyId: MayBeCertificationCompanyId;
  nanoId: CertificationNanoId;
}

interface CreateCertificationWithJobCandidateIdRequestData
  extends CreateCertificationDefaultRequestData {
  jobCandidateId?: MayBeCertificationJobCandidateId;
}

interface CreateCertificationWithJobResumeRecruiterIdRequestData
  extends CreateCertificationDefaultRequestData {
  jobResumeRecruiterId?: MayBeCertificationJobResumeRecruiterId;
}

export type CreateCertificationRequestData =
  | CreateCertificationWithJobCandidateIdRequestData
  | CreateCertificationWithJobResumeRecruiterIdRequestData;

export interface UpdateCertificationRequestData
  extends CertificationRequestData {
  name?: CertificationName;
}

export interface DeleteCertificationRequestData {
  certificationNanoId: CertificationNanoId;
}

export interface CreateCertificationErrorResponse {
  message: CreateCertificationError;
}

export interface UpdateCertificationErrorResponse {
  message: UpdateCertificationError;
}

export interface DeleteCertificationErrorResponse {
  message: DeleteCertificationError;
}

export class CertificationsBffRequests extends BaseBffRequests {
  static fetchCertifications<T>(params: FetchCertificationsRequestParams) {
    return this.fetchItems<T>(
      CertificationsBffRoutes.fetchCertificationsRoute(),
      params
    );
  }

  static fetchCertificationsIndex<T>(
    params: FetchCertificationsIndexRequestParams
  ) {
    return this.fetchItemsIndex<T>(
      CertificationsBffRoutes.fetchCertificationsIndexRoute(),
      params
    );
  }

  static fetchCertification<T>(
    certificationId: CertificationId,
    params: FetchCertificationRequestParams
  ) {
    return this.fetchItem<T>(
      CertificationsBffRoutes.fetchCertificationRoute(certificationId),
      params
    );
  }

  static fetchCertificationIndex<T>(
    certificationNanoId: CertificationNanoId,
    params: FetchCertificationIndexRequestParams
  ) {
    return this.fetchItemIndex<T>(
      CertificationsBffRoutes.fetchCertificationIndexRoute(certificationNanoId),
      params
    );
  }

  static fetchCertificationByNanoId<T>(
    certificationNanoId: CertificationNanoId,
    params: FetchCertificationRequestParams
  ) {
    return this.fetchItemByNanoId<T>(
      CertificationsBffRoutes.fetchCertificationByNanoIdRoute(
        certificationNanoId
      ),
      params
    );
  }

  static createCertification<T>(
    createCertificationData: CreateCertificationRequestData
  ) {
    return this.createItem<T, CreateCertificationRequestData>(
      CertificationsBffRoutes.createCertificationRoute(),
      createCertificationData
    );
  }

  static async updateCertification<T>(
    updateCertificationNanoId: CertificationNanoId,
    updateCertificationData: UpdateCertificationRequestData
  ) {
    return this.updateItem<T, UpdateCertificationRequestData>(
      CertificationsBffRoutes.updateCertificationRoute(
        updateCertificationNanoId
      ),
      updateCertificationData
    );
  }

  static async deleteCertification<T>(
    deleteCertificationNanoId: CertificationNanoId
  ) {
    return this.deleteItem<T>(
      CertificationsBffRoutes.deleteCertificationRoute(
        deleteCertificationNanoId
      )
    );
  }
}
