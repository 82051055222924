import {
  FetchCandidateSubmissionsQuery,
  FetchCandidateSubmissionsCountType,
  FetchCandidateSubmissionsPage,
  FetchCandidateSubmissionsPageSize,
  FetchCandidateSubmissionsFilters,
  FetchCandidateSubmissionsSort,
  FetchCandidateSubmissionsIndexQueryName,
  FetchCandidateSubmissionsIndexCountType,
  FetchCandidateSubmissionsIndexPage,
  FetchCandidateSubmissionsIndexPageSize,
  FetchCandidateSubmissionsIndexFilters,
  FetchCandidateSubmissionsIndexSort,
  FetchCandidateSubmissionQuery,
  FetchCandidateSubmissionIndexQueryName,
  CreateCandidateSubmissionError,
  DeleteCandidateSubmissionError,
  CandidateSubmissionId,
  CandidateSubmissionNanoId,
  CandidateSubmissionMessage,
  CandidateSubmissionJobId,
  CandidateSubmissionJobCandidateIds,
  CandidateSubmissionRecipientIds,
  CandidateSubmissionRecipients,
  DeleteRecipientInCandidateSubmissionError,
  DeleteJobCandidateInCandidateSubmissionError,
  UpdateCandidateSubmissionError,
  SendEmailsCandidateSubmissionError,
  CandidateSubmissionCandidateSubmissionInvitations,
  CandidateSubmissionCompanyMemberInvitations,
  CandidateSubmissionShareSettings,
  SendEmailsCandidateSubmissionNanoId,
  SendEmailsCandidateSubmissionID,
  CandidateSubmissionComment,
  CandidateSubmissionOverallEvaluation,
  CandidateSubmissionEvaluations,
  CandidateSubmissionBaseSalary,
  CandidateSubmissionCandidateShelfLife
} from './candidateSubmissionsTypes';
import { RecipientId } from '../recipients/recipientsTypes';
import {
  JobCandidateId,
  JobCandidateNanoId
} from '../jobCandidates/jobCandidatesTypes';

import { BaseBffRequests } from '../BaseBffRequests';

import { CandidateSubmissionsBffRoutes } from './CandidateSubmissionsBffRoutes';

interface FetchCandidateSubmissionsRequestParams {
  query: FetchCandidateSubmissionsQuery;
  countType?: FetchCandidateSubmissionsCountType;
  page?: FetchCandidateSubmissionsPage;
  pageSize?: FetchCandidateSubmissionsPageSize;
  filters?: FetchCandidateSubmissionsFilters;
  sort?: FetchCandidateSubmissionsSort;
}

interface FetchCandidateSubmissionsIndexRequestParams {
  queryName: FetchCandidateSubmissionsIndexQueryName;
  countType?: FetchCandidateSubmissionsIndexCountType;
  page?: FetchCandidateSubmissionsIndexPage;
  pageSize?: FetchCandidateSubmissionsIndexPageSize;
  filters?: FetchCandidateSubmissionsIndexFilters;
  sort?: FetchCandidateSubmissionsIndexSort;
}

interface FetchCandidateSubmissionRequestParams {
  query: FetchCandidateSubmissionQuery;
}

interface FetchCandidateSubmissionIndexRequestParams {
  queryName: FetchCandidateSubmissionIndexQueryName;
}

export interface CandidateSubmissionRequestData {
  comment: CandidateSubmissionComment;
  jobId: CandidateSubmissionJobId;
  jobCandidateIds: CandidateSubmissionJobCandidateIds;
  recipientIds: CandidateSubmissionRecipientIds;
  recipients?: CandidateSubmissionRecipients;
  shareSettings: CandidateSubmissionShareSettings;
  overallEvaluation: CandidateSubmissionOverallEvaluation;
  evaluations: CandidateSubmissionEvaluations;
  baseSalary: CandidateSubmissionBaseSalary;
  candidateShelfLife: CandidateSubmissionCandidateShelfLife;
}

export interface CreateCandidateSubmissionRequestData
  extends CandidateSubmissionRequestData {
  id?: CandidateSubmissionId;
}

export interface UpdateCandidateSubmissionRequestData
  extends CandidateSubmissionRequestData {
  id?: CandidateSubmissionId;
}

export interface SendEmailsCandidateSubmissionRequestData {
  candidateSubmissionId:
    | SendEmailsCandidateSubmissionNanoId
    | SendEmailsCandidateSubmissionID;
}

export interface CreateCandidateSubmissionErrorResponse {
  message: CreateCandidateSubmissionError;
}

export interface UpdateCandidateSubmissionErrorResponse {
  message: UpdateCandidateSubmissionError;
}

export interface DeleteCandidateSubmissionErrorResponse {
  message: DeleteCandidateSubmissionError;
}

export interface DeleteRecipientInCandidateSubmissionErrorResponse {
  message: DeleteRecipientInCandidateSubmissionError;
}

export interface DeleteJobCandidateInCandidateSubmissionErrorResponse {
  message: DeleteJobCandidateInCandidateSubmissionError;
}

export interface SendEmailsCandidateSubmissionErrorResponse {
  message: SendEmailsCandidateSubmissionError;
}

export class CandidateSubmissionsBffRequests extends BaseBffRequests {
  static fetchCandidateSubmissions<T>(
    params: FetchCandidateSubmissionsRequestParams
  ) {
    return this.fetchItems<T>(
      CandidateSubmissionsBffRoutes.fetchCandidateSubmissionsRoute(),
      params
    );
  }

  static fetchCandidateSubmissionsIndex<T>(
    params: FetchCandidateSubmissionsIndexRequestParams
  ) {
    return this.fetchItemsIndex<T>(
      CandidateSubmissionsBffRoutes.fetchCandidateSubmissionsIndexRoute(),
      params
    );
  }

  static fetchCandidateSubmission<T>(
    candidateSubmissionId: CandidateSubmissionId,
    params: FetchCandidateSubmissionRequestParams
  ) {
    return this.fetchItem<T>(
      CandidateSubmissionsBffRoutes.fetchCandidateSubmissionRoute(
        candidateSubmissionId
      ),
      params
    );
  }

  static fetchCandidateSubmissionIndex<T>(
    candidateSubmissionNanoId: CandidateSubmissionNanoId,
    params: FetchCandidateSubmissionIndexRequestParams
  ) {
    return this.fetchItemIndex<T>(
      CandidateSubmissionsBffRoutes.fetchCandidateSubmissionIndexRoute(
        candidateSubmissionNanoId
      ),
      params
    );
  }

  static fetchCandidateSubmissionByNanoId<T>(
    candidateSubmissionNanoId: CandidateSubmissionNanoId,
    params: FetchCandidateSubmissionRequestParams
  ) {
    return this.fetchItemByNanoId<T>(
      CandidateSubmissionsBffRoutes.fetchCandidateSubmissionByNanoIdRoute(
        candidateSubmissionNanoId
      ),
      params
    );
  }

  static createCandidateSubmission<T>(
    createCandidateSubmissionData: CreateCandidateSubmissionRequestData
  ) {
    return this.createItem<T, CreateCandidateSubmissionRequestData>(
      CandidateSubmissionsBffRoutes.createCandidateSubmissionRoute(),
      createCandidateSubmissionData
    );
  }

  static async updateCandidateSubmission<T>(
    updateCandidateSubmissionNanoId:
      | CandidateSubmissionNanoId
      | CandidateSubmissionId,
    updateCandidateSubmissionData: UpdateCandidateSubmissionRequestData
  ) {
    return this.updateItem<T, UpdateCandidateSubmissionRequestData>(
      CandidateSubmissionsBffRoutes.updateCandidateSubmissionRoute(
        updateCandidateSubmissionNanoId
      ),
      updateCandidateSubmissionData
    );
  }

  static async deleteCandidateSubmission<T>(
    deleteCandidateSubmissionId:
      | CandidateSubmissionId
      | CandidateSubmissionNanoId
  ) {
    return this.deleteItem<T>(
      CandidateSubmissionsBffRoutes.deleteCandidateSubmissionRoute(
        deleteCandidateSubmissionId
      )
    );
  }

  static async deleteRecipientInCandidateSubmission<T>(
    deleteCandidateSubmissionId:
      | CandidateSubmissionId
      | CandidateSubmissionNanoId,
    deleteRecipientId: RecipientId
  ) {
    return this.deleteItem<T>(
      CandidateSubmissionsBffRoutes.deleteRecipientInCandidateSubmissionRoute(
        deleteCandidateSubmissionId,
        deleteRecipientId
      )
    );
  }

  static async deleteJobCandidateInCandidateSubmission<T>(
    deleteCandidateSubmissionId:
      | CandidateSubmissionId
      | CandidateSubmissionNanoId,
    deleteJobCandidateId: JobCandidateId | JobCandidateNanoId
  ) {
    return this.deleteItem<T>(
      CandidateSubmissionsBffRoutes.deleteJobCandidateInCandidateSubmissionRoute(
        deleteCandidateSubmissionId,
        deleteJobCandidateId
      )
    );
  }

  static async sendEmailsCandidateSubmission<T>(
    sendEmailsCandidateSubmissionId:
      | CandidateSubmissionId
      | CandidateSubmissionNanoId
  ) {
    return this.postItem<T, unknown>(
      CandidateSubmissionsBffRoutes.sendEmailsCandidateSubmissionRoute(
        sendEmailsCandidateSubmissionId
      ),
      {}
    );
  }
}
