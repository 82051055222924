import { Flex, Stack } from '@chakra-ui/react';
import size from 'lodash/size';
import compact from 'lodash/compact';

import { AlertMessage } from '../../../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../../../helpers/LoadingSkeleton';
import { Text } from '../../../../../../../helpers/Text';

import { usePaginatedEmployments } from '../../../../../../employments/hooks/usePaginatedEmployments';

import {
  FetchEmploymentsResponse,
  fetchEmploymentsQuery
} from '../../../../../../employments/queries/fetchEmployments.query';

import { EmploymentsCache } from '../../../../../../employments/EmploymentsCache';

import { JobCandidateId } from '../../../../../../jobCandidates/jobCandidatesTypes';
import {
  EmploymentFields,
  FetchEmploymentsFilters,
  FetchEmploymentsSort
} from '../../../../../../employments/employmentsTypes';

import { DateUtils } from '../../../../../../../utils/DateUtils';
import { renderEmploymentJobTitle } from '../../../../../../employments/utils/renderEmploymentJobTitle';

interface HiringPortalLeadDrawerExperienceProps {
  jobCandidateId: JobCandidateId;
}

function HiringPortalLeadDrawerExperience({
  jobCandidateId
}: HiringPortalLeadDrawerExperienceProps) {
  const { employments, employmentsIsFetched, employmentsErrorMessage } =
    usePaginatedEmployments<FetchEmploymentsResponse>({
      query: fetchEmploymentsQuery,
      cacheKey: EmploymentsCache.jobCandidateListCacheKey(jobCandidateId),
      initialFilters: {
        [EmploymentFields.JOB_CANDIDATE_ID]: {
          operator: 'eq',
          value: jobCandidateId
        }
      } as unknown as FetchEmploymentsFilters,
      initialSort: {
        [EmploymentFields.START_DATE]: {
          ascending: false
        }
      } as unknown as FetchEmploymentsSort
    });

  return (
    <LoadingSkeleton count={3} loaded={employmentsIsFetched}>
      <AlertMessage message={employmentsErrorMessage} />
      {size(employments) ? (
        <Stack p={4} bg="gray.50" spacing={3}>
          <Text textStyle="upperCase1Medium" color="gray.600">
            Experience
          </Text>

          <Stack spacing={4}>
            {employments.map((employment) => (
              <Flex flexDir="column" key={employment.nanoId}>
                <Text textStyle="body1Medium">
                  {compact([
                    renderEmploymentJobTitle(employment),
                    employment.company?.name
                  ]).join(' @ ')}
                </Text>
                <Text textStyle="body2Regular" color="gray.600">
                  {compact([
                    DateUtils.formatMonth(employment.startDate),
                    employment.currently
                      ? 'PRESENT'
                      : DateUtils.formatMonth(employment.endDate)
                  ]).join(' - ')}
                </Text>
              </Flex>
            ))}
          </Stack>
        </Stack>
      ) : null}
    </LoadingSkeleton>
  );
}

export default HiringPortalLeadDrawerExperience;
